import { Action } from 'redux'
import { GAME_FORMATS_FETCH } from './constants'

export interface FetchGameFormatsSuccessAction extends Action, SuccessAction {
  type: 'GAME_FORMATS_FETCH_SUCCESS'
  gameFormats: GameFormat[]
  gameFormatAdditionalOptions: GameFormatAdditionalOptions
  gameFormatPrimaryIdMap: GameFormatPrimaryIdMap
  defaultPerPlayerHandicapPercentages: DefaultPerPlayerHandicapPercentages[]
}

export interface FetchGameFormatsFailureAction extends Action {
  type: 'GAME_FORMATS_FETCH_FAILURE'
  error: ErrorPayload
}

export interface FetchGameFormatsAction extends Action {
  type: 'GAME_FORMATS_FETCH'
}

export const fetchGameFormats = () => {
  return {
    type: GAME_FORMATS_FETCH,
  }
}
