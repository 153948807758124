export const OpenLinkIcon = ({ className = '', fill = '#0BB163' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" className={className}>
    <g clipPath="url(#clip0_3655_13285)">
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
        // eslint-disable-next-line max-len
        d="M8.7 7.3l5.74-5.74m.56 2.8V1h-3.36M7.3 1H5.9C2.4 1 1 2.4 1 5.9v4.2C1 13.6 2.4 15 5.9 15h4.2c3.5 0 4.9-1.4 4.9-4.9V8.7"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_3655_13285">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
)
