import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../..'
import { mapTournamentPlayerToNewStartListPlayer } from '../utils/tournamentStartListsUtils'

const initialState: TournamentStartListState = {
  rounds: [],
  verifyCourseVersionAfterStartListPublish: false,
}

const slice = createSlice({
  name: 'startList',
  initialState,
  reducers: {
    resetStartLists: () => initialState,
    setVerifyCourseVersionAfterStartListPublish: (state, action: PayloadAction<boolean>) => {
      state.verifyCourseVersionAfterStartListPublish = action.payload
    },
    updateStartListField: (state, action: PayloadAction<StartListRoundConfigPayload>) => {
      return {
        ...state,
        rounds: [
          ...state.rounds.slice(0, action.payload.index),
          {
            ...state.rounds[action.payload.index],
            [action.payload.fieldName]: action.payload.value,
          },
          ...state.rounds.slice(action.payload.index + 1),
        ],
      }
    },
    updateStartListRounds: (state, action: PayloadAction<StartListRound>) => {
      let updatedRounds
      const index = state.rounds.findIndex((round) => round.tournamentRoundId === action.payload.tournamentRoundId)
      if (index === -1) {
        // Add new
        updatedRounds = [action.payload, ...state.rounds]
      } else {
        // Replace existing
        updatedRounds = [...state.rounds.slice(0, index), action.payload, ...state.rounds.slice(index + 1)]
      }
      updatedRounds.sort((a: StartListRound, b: StartListRound) => a.tournamentRoundId - b.tournamentRoundId)

      state.rounds = updatedRounds
    },
    removeStartListRound: (state, action: PayloadAction<number>) => {
      state.rounds = state.rounds.filter((round) => round.tournamentRoundId !== action.payload)
    },
    internalRemovePlayerFromGroup: (state, action: PayloadAction<RemovePlayerFromGroupPayload>) => {
      const { roundIndex, groupIndex, playerId } = action.payload
      const players = state.rounds[roundIndex].groups[groupIndex].startListPlayers.filter(
        (item) => item.id !== playerId,
      )
      const teams = (state.rounds[roundIndex].groups[groupIndex].teams || []).map((team) => {
        const filteredPlayers = (team.players && team.players.filter((_player) => _player.id !== playerId)) || []
        return {
          ...team,
          players: filteredPlayers,
        }
      })

      return {
        ...state,
        rounds: [
          ...state.rounds.slice(0, roundIndex),
          {
            ...state.rounds[roundIndex],
            groups: [
              ...state.rounds[roundIndex].groups.slice(0, groupIndex),
              {
                ...state.rounds[roundIndex].groups[groupIndex],
                startListPlayers: players,
                teams,
              },
              ...state.rounds[roundIndex].groups.slice(groupIndex + 1),
            ],
          },
          ...state.rounds.slice(roundIndex + 1),
        ],
      }
    },
    internalAddPlayerToGroup: (state, action: PayloadAction<AddPlayerToGroupPayload>) => {
      const { teamId, roundIndex, sourceGroupIndex, groupIndex, orderIndex = 0 } = action.payload
      const player = { ...action.payload.player }
      let teams: StartListGroupTeam[] = []

      if (teamId || teamId === 0) {
        const group = state.rounds[roundIndex].groups[groupIndex]

        if (group.teams) {
          teams = group.teams.map((team, idx) => {
            if (idx === teamId) {
              const filteredPlayers =
                (team.players && team.players.filter((_player) => _player.id !== player.userId)) || []

              return {
                ...team,
                players: [...filteredPlayers.slice(0, orderIndex), player, ...filteredPlayers.slice(orderIndex)],
              }
            }

            return team
          })
        } else {
          console.warn('could not decide on team')
        }
      }

      // Clean the target of possible duplicate. This will hit when we drop player to group it already exists
      const filteredPlayers = state.rounds[roundIndex].groups[groupIndex].startListPlayers.filter(
        (_player) => _player.id !== player.userId,
      )

      const startListPlayer: StartListPlayer = mapTournamentPlayerToNewStartListPlayer(player)
      const players = [...filteredPlayers.slice(0, orderIndex), startListPlayer, ...filteredPlayers.slice(orderIndex)]

      if (sourceGroupIndex !== null) {
        /**
         * Get players in source group (without the moved player)
         */
        const sourcePlayers = state.rounds[roundIndex].groups[sourceGroupIndex].startListPlayers.filter(
          (_player) => _player.id !== player.userId,
        )

        return {
          ...state,
          rounds: [
            ...state.rounds.slice(0, roundIndex),
            {
              ...state.rounds[roundIndex],
              groups: [
                ...state.rounds[roundIndex].groups.slice(0, sourceGroupIndex),
                {
                  ...state.rounds[roundIndex].groups[sourceGroupIndex],
                  startListPlayers: sourcePlayers,
                  teams,
                },
                ...state.rounds[roundIndex].groups.slice(sourceGroupIndex + 1),
              ],
            },
            ...state.rounds.slice(roundIndex + 1),
          ],
        }
      }

      return {
        ...state,
        rounds: [
          ...state.rounds.slice(0, roundIndex),
          {
            ...state.rounds[roundIndex],
            groups: [
              ...state.rounds[roundIndex].groups.slice(0, groupIndex),
              {
                ...state.rounds[roundIndex].groups[groupIndex],
                startListPlayers: players,
                teams,
              },
              ...state.rounds[roundIndex].groups.slice(groupIndex + 1),
            ],
          },
          ...state.rounds.slice(roundIndex + 1),
        ],
      }
    },
    startListsUpdated: () => {},
  },
})

export const {
  resetStartLists,
  setVerifyCourseVersionAfterStartListPublish,
  updateStartListField,
  updateStartListRounds,
  removeStartListRound,
  internalRemovePlayerFromGroup,
  internalAddPlayerToGroup,
  startListsUpdated,
} = slice.actions

export const selectTournamentStartLists = (state: RootState): TournamentStartListState =>
  state.tournamentStartListsReducer

export default slice.reducer
