import React from 'react'
import { connect } from 'react-redux'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { rem } from '@app/theme/materialUITheme'
import { validFemaleTeeBoxes, validMaleTeeBoxes } from '@utils/clubAndCourseUtils'
import createStyles from '@mui/styles/createStyles'
import OutlinedInput from '@mui/material/OutlinedInput'
import { withStyles, WithStyles } from '@mui/styles'
import {
  Typography,
  Grid,
  FormLabel,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Theme,
  Button,
  TextField,
  FormControl,
  IconButton,
  CircularProgress,
} from '@mui/material'
import { Clear, Search, Description } from '@mui/icons-material'
import { getDefaultTeeBoxIds } from '@utils/clubAndCourseUtils'

import HeadHelmet from '@components/layout/HeadHelmet'
import TopBar from '@components/layout/TopBar'
import ContentWrap from '@components/layout/ContentWrap'
import { OverlayLoader } from '@components/ui/OverlayLoader'
import CountryPicker from '@components/tournament/CountryPicker'
import InfoTooltip from '@components/ui/InfoTooltip'
import SelectBox from '@components/ui/SelectBox'
import { FormattedMessageWrapper } from '@components/ui/FormattedMessageWrapper'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import MiniPlayerCard from '@components/tournament/MiniPlayerCard'
import CourseGuideHelper, { ChildrenPropsArgs } from '@components/dialogs/courseGuideDialog/CourseGuideDialog'
import ButtonLoaderWrap from '@components/ui/ButtonLoaderWrap'
import TextButton from '@components/ui/TextButton'
import { SplitDateTimePicker } from '@components/date-pickers'

import { updatePlayersField, searchOrganizationPlayers, clearPlayersSearch } from '@app/store/players/actions'
import { searchClubs } from '@store/api/thunks/clubsAndCoursesThunks'
import { getCourses } from '@app/store/api/thunks/clubsAndCoursesThunks'
import { fetchOrganization } from '@store/organization/actions'
import { sendPlayerScores } from '@store/hcpRounds/actions'
import { Autocomplete, AutocompleteItem } from '@app/components/forms/Autocomplete'
import { selectCourses } from '@app/store/api/endpoints/clubsAndCoursesApi'
import { RootState } from '@app/store'

const LOCAL_STORAGE_START_TIME = 'hcpRoundStartTime'
const LOCAL_STORAGE_CLUB_ID = 'hcpRoundClubId'

const styles = (theme: Theme) =>
  createStyles({
    relativeBlock: {
      position: 'relative',
      width: '100%',
      marginTop: rem(30),
      marginBottom: rem(50),
    },
    searchTextfield: {
      marginTop: rem(20),
      maxWidth: rem(400),
    },
    tableHeader: {
      background: theme.palette.primary.main,
      '& > td': {
        color: theme.palette.primary.contrastText,
        padding: `${rem(5)} ${rem(16)}`,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: rem(12),
        borderBottom: 0,
      },
    },
    tableContent: {
      '& > td': {
        padding: `${rem(6)} ${rem(16)}`,
        borderColor: 'transparent',
        borderBottom: 0,
        textAlign: 'center',
        fontSize: rem(12),
      },
    },
    titleCell: {
      fontWeight: 600,
      borderColor: 'transparent',
      '&.highlight': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
      fontSize: rem(18),
      width: 58,
    },
    nineInputCell: {
      borderColor: 'transparent',
      fontSize: rem(18),
      width: 58,
      paddingLeft: 0,
    },
    inputField: {
      padding: `${rem(4)} ${rem(6)} 0`,
      textAlign: 'center',
      borderColor: 'transparent',
      '& .MuiOutlinedInput-input': {
        padding: `${rem(15)} 0 ${rem(15)} 0`,
        textAlign: 'center',
      },
    },
    sendBtn: {
      position: 'absolute',
      right: rem(40),
      marginTop: rem(40),
    },
    searchResults: {
      marginTop: theme.spacing(2),
      maxHeight: 400,
      overflowX: 'auto',
    },
    nameContainer: {
      backgroundColor: 'lightgray',
      width: '100%',
      marginBottom: rem(20),
      padding: rem(10),
    },
  })

interface StateProps {
  player: PlayersState
  organization: OrganizationState
  auth: AuthenticationState
  courses: Course[]
}

interface State {
  clubs: Club[]
  searchTerm: string
  clubSearchTerm?: string
  selectedClub?: AutocompleteItem
  error?: string
  loading: boolean
  courses?: Course[]
  maleTeeBoxId: number
  femaleTeeBoxId: number
  countryId?: number
  holes: string
  courseId: number
  searchActive: boolean
  frontNine: {
    holes: Hole[]
    total: number
  }
  backNine: {
    holes: Hole[]
    total: number
  }
  startTime: Date | undefined
  courseTees: CourseTee[]
  isDisabled: boolean
  selectedPlayerId: string
}

interface DispatchProps {
  updatePlayersField(payload: FieldUpdatePayload): void
  searchClubs: (payload: SearchClubsPayload, isPublicSearch?: boolean) => void
  getCourses: (payload: CoursesPayload) => void
  searchOrganizationPlayers(): void
  fetchOrganization: (id: number, force?: boolean, onComplete?: () => void) => void
  clearPlayersSearch(): void
  sendPlayerScores: (args: HcpRound, onComplete?: () => void) => void
}

const defaultStartTime = new Date()
defaultStartTime.setHours(10, 0, 0, 0)

const initialState: State = {
  searchTerm: '',
  clubSearchTerm: '',
  clubs: [],
  loading: false,
  selectedClub: undefined,
  courses: undefined,
  maleTeeBoxId: 0,
  femaleTeeBoxId: 0,
  countryId: undefined,
  holes: '18',
  courseId: 0,
  frontNine: {
    holes: [],
    total: 0,
  },
  backNine: {
    holes: [],
    total: 0,
  },
  searchActive: false,
  startTime: defaultStartTime,
  courseTees: [],
  isDisabled: false,
  selectedPlayerId: '',
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithStyles<typeof styles>

class HcpRounds extends React.Component<Props, State> {
  readonly state: State = initialState

  componentDidMount() {
    const { auth, fetchOrganization } = this.props
    auth.customerInfo && fetchOrganization(auth.customerInfo?.idCustomer)

    const { courses, organization } = this.props
    const course = courses[0]

    if (course) {
      const maleTees = validMaleTeeBoxes(course.teeBoxes)
      const femaleTees = validFemaleTeeBoxes(course.teeBoxes)

      const { maleTeeBoxId, femaleTeeBoxId } = getDefaultTeeBoxIds(maleTees, femaleTees)

      const selectedClub = {
        value: course.id,
        label: course.clubName,
      }

      this.setState({
        maleTeeBoxId,
        femaleTeeBoxId,
        clubSearchTerm: organization.club?.name,
        countryId: organization.countryId,
        selectedClub,
      })
    }
  }

  componentWillUnmount() {
    this.props.clearPlayersSearch()
    window.localStorage.removeItem(LOCAL_STORAGE_CLUB_ID)
    window.localStorage.removeItem(LOCAL_STORAGE_START_TIME)
  }

  private getFirstCourse = () => this.props.courses[0]
  private getSearchResult = () =>
    this.props.player.searchResults.find((result) => result.providerPlayerIdentifier === this.state.selectedPlayerId)
  private getCurrentCourse = () => this.props.courses.find((course) => course.id === this.state.courseId)

  private handleClubChange = (selectedClub?: AutocompleteItem) => {
    if (!selectedClub) {
      return
    }

    this.setState({
      selectedClub,
      isDisabled: false,
    })

    this.fetchCourses(selectedClub.value)
  }

  private updateClubSearchTerm = (clubSearchTerm: string) => {
    this.setState({ clubSearchTerm })
    this.handleFetchClubs(clubSearchTerm)
  }

  private handleFetchClubs = (clubSearchTerm: string) => {
    const { searchClubs } = this.props
    let { countryId } = this.state

    // TODO: Find better way to fix this. This is a temporary fix.
    if (!countryId) {
      countryId = this.props.auth?.customerInfo?.club?.countryId
    }

    if (countryId && clubSearchTerm) {
      searchClubs({
        searchTerm: clubSearchTerm,
        countryId,
        onComplete: (result: ClubsAndCoursesAPICallResult) => {
          if (result.error) {
            this.setState({
              ...this.state,
              error: result.error.message,
            })
          }

          this.setState({
            ...this.state,
            loading: false,
            isDisabled: false,
            ...result.data,
          })
        },
      })
    }
  }

  private clubItems = () => {
    const { clubs } = this.state

    if (clubs) {
      return clubs.map((club) => ({
        value: club.id,
        label: club.name,
      }))
    }

    return [{ value: 0, label: '' }]
  }

  private handleCourseChange = (event) => {
    const { value } = event.target
    const { courses } = this.props
    const course = courses.find((course) => course.id === Number(value))
    const tees = course?.teeBoxes || []

    const maleTees = validMaleTeeBoxes(tees)
    const femaleTees = validFemaleTeeBoxes(tees)
    const { maleTeeBoxId, femaleTeeBoxId } = getDefaultTeeBoxIds(maleTees, femaleTees)

    this.setState({
      ...this.state,
      courseId: Number(value),
      maleTeeBoxId,
      femaleTeeBoxId,
      isDisabled: false,
      courseTees: tees,
    })
  }

  private handleChange = (event) => {
    const { name = '', value } = event.target

    if (name === 'country-picker' && typeof value === 'number') {
      this.setState({
        ...this.state,
        countryId: value,
        isDisabled: false,
      })
      return
    }

    if (name === 'searchTerm') {
      this.props.updatePlayersField({
        fieldName: name,
        value,
      })
    }

    this.setState({
      ...this.state,
      [name]: value,
      isDisabled: false,
    })
  }

  private updatePlayerSearchTerm = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.updatePlayersField({
      fieldName: 'searchTerm',
      value: e.currentTarget.value,
    })
  }

  private fetchPlayers = () => {
    const { searchOrganizationPlayers } = this.props
    searchOrganizationPlayers()
  }

  private getTimestamp = () => {
    const startTime = this.state.startTime ? new Date(this.state.startTime) : new Date()
    return startTime.getTime() / 1000
  }

  private fetchCourses = (clubId?: number) => {
    const { player } = this.props
    const { selectedClub } = this.state

    // 1. Passed clubId, 2. Already selected clubId, 3. User clubId
    const selectedClubId = clubId ? clubId : selectedClub ? selectedClub.value : player.clubId

    if (selectedClubId) {
      this.setState({
        loading: true,
      })

      const timestamp = this.getTimestamp()
      window.localStorage.setItem(LOCAL_STORAGE_CLUB_ID, selectedClubId.toString())
      window.localStorage.setItem(LOCAL_STORAGE_START_TIME, timestamp.toString())

      this.props.getCourses({
        clubId: selectedClubId,
        timestamp,
        onComplete: (res) => {
          const courseId = res.data?.courses?.find((course) => course)?.id || 0

          if (res.data && res.data?.courses && player.clubId) {
            const tees = res.data?.courses.find((course) => course.id === courseId)?.teeBoxes || []
            const course = res.data?.courses.find((course) => course.id === courseId)

            const maleTees = validMaleTeeBoxes(tees)
            const femaleTees = validFemaleTeeBoxes(tees)
            const { maleTeeBoxId, femaleTeeBoxId } = getDefaultTeeBoxIds(maleTees, femaleTees)

            const selectedClub = {
              value: course?.clubId || 0,
              label: course?.clubName || '',
            }

            this.setState({
              ...this.state,
              selectedClub,
              courseId: courseId,
              isDisabled: false,
              loading: false,
              searchActive: false,
              maleTeeBoxId,
              femaleTeeBoxId,
              courseTees: tees,
              ...res.data,
            })
          }
        },
      })
    }
  }

  private padArrayCount = (arr: any[]) => {
    if (arr?.length < 9) {
      return arr?.concat(
        Array.from({ length: 9 - arr.length }, () => ({
          length: 0,
          lengths: [],
        })),
      )
    }

    return arr
  }

  private getHoles = (niner: 'f9' | 'b9'): CourseHole[] => {
    const currentCourse = this.getCurrentCourse()

    if (currentCourse) {
      let holes: any[] = []
      if (niner === 'f9') {
        holes = currentCourse?.holes.slice(0, 9)
      } else {
        holes = currentCourse?.holes.slice(9)
      }

      return this.padArrayCount(holes)
    }

    return []
  }

  private renderCell = (property: string) => (item: CourseHole, idx: number) => {
    return <TableCell key={idx}>{item[property]}</TableCell>
  }

  private getParTotal = (holes?: CourseHole[]): number => {
    return holes?.reduce((acc: number, hole: CourseHole) => acc + hole.par, 0) || 0
  }

  private countNineTotal = (nineInputs) =>
    nineInputs.reduce((prev, curr) => {
      return prev + curr.strokes
    }, 0)

  private handleNineInputChange = (e: React.ChangeEvent<{ name: string; value: string }>) => {
    const { frontNine, backNine } = this.state
    const hole = e.target.name?.split('-')
    const holeId = Number(hole[1])
    const holeName = hole[0]

    if (hole) {
      const idx = Number(holeId)
      let holesList = holeName === 'frontNine' ? [...frontNine.holes] : [...backNine.holes]

      if (!e.target.value) {
        holesList.splice(holeId - 1, 1)
      } else {
        const strokes = e.target.value === '-' ? 0 : Number(e.target.value)
        if (!holesList.find((holes) => holes.hole === idx)) {
          holesList.push({
            hole: idx,
            strokes,
          })
        } else {
          const holeIdx = holesList.findIndex((holes) => holes.hole === idx)
          holesList[holeIdx] = {
            hole: idx,
            strokes,
          }
        }
      }

      holesList.sort((a, b) => a.hole - b.hole)

      holesList = holesList.filter((hole) => hole !== undefined)
      const nineTotal = this.countNineTotal(holesList)

      this.setState({
        ...this.state,
        [holeName]: {
          holes: holesList,
          total: nineTotal,
        },
        isDisabled: false,
      })
    }
  }

  /**
   * Return hole number for front holes (1-9) and back holes (10-18)
   * @param id
   * @param type
   * @returns number
   */
  private getNineHoleInputId = (id: number, type: 'front' | 'back'): number => (type === 'front' ? id + 1 : 10 + id)

  private onFocus = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => event.target.select()

  /**
   * Handle change of field focus to next field
   * @param event
   */
  private onKeyUp = (event) => {
    const { form, value } = event.target
    const index = Array.prototype.indexOf.call(form, event.target) + 2
    if (value > 1) {
      form.elements[index] && form.elements[index].focus()
    }
    event.preventDefault()
  }

  private renderNineInputs = (disabled: boolean, type: 'front' | 'back') => {
    const { classes } = this.props
    const firstCourse = this.getFirstCourse()

    return firstCourse?.holes.map((courseHole: CourseHole, idx: number) => {
      const id = `${type}Nine-${this.getNineHoleInputId(idx, type)}`
      return (
        idx < 9 && (
          <VeryDenseTableCell key={courseHole.id} className={classes.nineInputCell}>
            <TextField
              name={id}
              key={id}
              variant="outlined"
              className={classes.inputField}
              style={{ opacity: disabled ? 0.5 : 1 }}
              disabled={disabled}
              onChange={this.handleNineInputChange}
              inputProps={{
                maxLength: 2,
                onFocus: this.onFocus,
                onKeyUp: this.onKeyUp,
              }}
            ></TextField>
          </VeryDenseTableCell>
        )
      )
    })
  }

  private isFieldDisabled = (nineType: 'f9' | 'b9') => {
    const { holes } = this.state

    if ((nineType === 'f9' && holes === 'b9') || (nineType === 'b9' && holes === 'f9')) {
      return true
    }

    return false
  }

  private courseTee = () => {
    const { courseTees } = this.state

    return courseTees.map(({ id, teeboxName }: CourseTee) => (
      <MenuItem key={id} value={id}>
        {teeboxName}
      </MenuItem>
    ))
  }

  private courseItems = () => {
    const { courses } = this.state

    return courses?.map(({ id, courseName }: Course) => (
      <MenuItem key={id} value={id}>
        {courseName}
      </MenuItem>
    ))
  }

  private handlePlayerSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    this.setState({
      searchActive: true,
    })

    this.fetchPlayers()
  }

  private handleClearSearch = () => {
    this.setState({ searchActive: false }, () => this.props.clearPlayersSearch())
  }

  private handlePlayerSelection = (player: SearchPlayer) => {
    this.setState(
      {
        selectedPlayerId: player.providerPlayerIdentifier,
      },
      () => {
        this.fetchCourses()
      },
    )
  }

  private renderPlayerSearchResults = () => {
    const { player } = this.props
    const { searchActive } = this.state

    if (!searchActive) {
      return null
    }

    if (!player.searchResults.length && !searchActive) {
      return (
        <Grid item xs={12}>
          <Typography variant={'body2'}>
            <FormattedMessageWrapper id={'tournament.noSearchResults'} />
          </Typography>
        </Grid>
      )
    }

    return player.searchResults.map((result, idx) => (
      <Grid item xs={12} key={`${result.firstName}_${result.lastName}_${idx}`}>
        <MiniPlayerCard
          key={`${result.firstName}_${result.lastName}_${idx}`}
          player={result}
          handleAddPlayer={() => this.handlePlayerSelection(result)}
        />
      </Grid>
    ))
  }

  private renderScorecardLink = () => {
    return (
      <CourseGuideHelper>
        {({ showCourseGuide, isLoading }: ChildrenPropsArgs) => (
          <div style={{ float: 'right' }}>
            <ButtonLoaderWrap loading={false}>
              <TextButton
                disabled={isLoading}
                onClick={() => {
                  const course = this.getCurrentCourse()
                  if (course) {
                    showCourseGuide(course.clubId, course.id)
                  }
                }}
                style={{ padding: '3px 0' }}
                labelStyle={{ fontSize: rem(12) }}
              >
                <Description style={{ fontSize: rem(14), marginRight: 3 }} />
                <FormattedMessageWrapper id={'course.scorecard'} />
              </TextButton>
            </ButtonLoaderWrap>
          </div>
        )}
      </CourseGuideHelper>
    )
  }

  /**
   * Check which hole list should be used
   * @param holes
   * @param frontNine
   * @param backNine
   * @returns array
   */
  private checkNineHoles = (holes, frontNine, backNine) => {
    switch (holes) {
      case 'f9':
        return frontNine.holes
      case 'b9':
        return backNine.holes
      default:
        return frontNine.holes.concat(backNine.holes)
    }
  }

  private handleSend = () => {
    const { auth, sendPlayerScores } = this.props
    const { frontNine, backNine, holes, startTime, maleTeeBoxId, femaleTeeBoxId, courseId } = this.state

    const searchResult = this.getSearchResult()

    const holesList = this.checkNineHoles(holes, frontNine, backNine)

    let args: HcpRound = {
      providerPlayerIdentifier: '',
      courseId: 0,
      date: undefined,
      numberOfHoles: '',
      teeboxId: 0,
      gender: '',
      holes: [],
      marker: '',
    }

    if (
      searchResult &&
      searchResult.gender &&
      searchResult.providerPlayerIdentifier &&
      startTime &&
      auth.name &&
      auth.id
    ) {
      this.setState({
        isDisabled: true,
      })

      args = {
        providerPlayerIdentifier: searchResult.providerPlayerIdentifier,
        courseId,
        date: startTime,
        numberOfHoles: holes,
        teeboxId: searchResult.gender === 'male' ? maleTeeBoxId : femaleTeeBoxId,
        gender: searchResult.gender,
        holes: holesList,
        marker: auth.name,
      }

      sendPlayerScores(args, () => {
        this.setState({
          isDisabled: false,
        })
      })
    }
  }

  private isDisabled = () => {
    const { frontNine, backNine, holes, isDisabled } = this.state

    if (holes === '18') {
      return this.checkNineHoles(holes, frontNine, backNine).length < 18 || isDisabled
    }

    return this.checkNineHoles(holes, frontNine, backNine).length < 9 || isDisabled
  }

  private onDateChange = (date: DateTimeValue) => {
    if (date) {
      this.setState({
        startTime: date,
        isDisabled: false,
      })
    }
  }

  render() {
    const { classes, organization, player, auth } = this.props
    const {
      maleTeeBoxId,
      femaleTeeBoxId,
      countryId,
      holes,
      courseId,
      frontNine,
      backNine,
      loading,
      searchActive,
      startTime,
      selectedClub,
    } = this.state

    const frontNineHoles = this.getHoles('f9')
    const backNineHoles = this.getHoles('b9')
    const firstCourse = this.getFirstCourse()
    const playerResults = this.getSearchResult()

    return (
      <>
        <HeadHelmet titleId={'navigation.hcpRounds'} />
        <TopBar />

        <OverlayLoader visible={loading} />

        <ContentWrap style={{ paddingBottom: rem(80) }}>
          <Typography variant={'h2'}>
            <FormattedMessageWrapper id="hcpRounds.searchPlayers" />
          </Typography>

          <form onSubmit={this.handlePlayerSearch} style={{ marginBottom: rem(29) }}>
            <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete={'off'}
                    type="text"
                    disabled={loading}
                    value={player.searchTerm}
                    name="searchTerm"
                    placeholder={this.props.intl.formatMessage({
                      id: 'tournament.searchByName',
                    })}
                    fullWidth
                    inputProps={{
                      'aria-label': 'Description',
                    }}
                    onChange={this.updatePlayerSearchTerm}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <IconButton disabled={false} type="submit" color="primary" size="large">
                  {!player.loading && <Search />}
                  {player.loading && <CircularProgress size={24} />}
                </IconButton>
                {searchActive && (
                  <IconButton disabled={loading} onClick={this.handleClearSearch} size="large">
                    <Clear />
                  </IconButton>
                )}
              </Grid>
              <Grid container spacing={2} className={classes.searchResults}>
                {this.renderPlayerSearchResults()}
              </Grid>
            </Grid>
          </form>

          {!!player.searchResults.length && player.searchTerm && !searchActive && (
            <>
              <div style={{ marginTop: rem(30) }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormLabel>
                      <FormattedMessageWrapper id="tournament.startDateAndTime" />
                    </FormLabel>
                    <SplitDateTimePicker onChange={this.onDateChange} units={auth.units} value={startTime} />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.relativeBlock}>
                <Grid container={true} spacing={2}>
                  <Grid item xs={4}>
                    <FormLabel>
                      <FormattedMessageWrapper id="tournament.country" />
                    </FormLabel>
                    <CountryPicker
                      countryId={countryId || organization.countryId}
                      onChange={this.handleChange}
                      showInnerLabel={false}
                      enableAllOption={true}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormLabel>
                      <FormattedMessageWrapper id="tournament.club" />
                    </FormLabel>
                    <Autocomplete
                      value={selectedClub}
                      onChange={this.handleClubChange}
                      options={this.clubItems()}
                      inputValue={this.state.clubSearchTerm || ''}
                      onInputChange={this.updateClubSearchTerm}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <InfoTooltip
                      size={'large'}
                      style={{ marginTop: rem(26) }}
                      text={<FormattedMessageWrapper id={'tournament.courseInfo'} />}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormLabel>
                      <FormattedMessageWrapper id="tournament.course" />
                      {this.renderScorecardLink()}
                    </FormLabel>
                    <SelectBox
                      id="courseId"
                      name="courseId"
                      value={courseId}
                      onChange={this.handleCourseChange}
                      inputElement={OutlinedInput}
                      margin="none"
                    >
                      {this.courseItems()}
                    </SelectBox>
                  </Grid>
                  <Grid item xs={2}>
                    <FormLabel>
                      <FormattedMessageWrapper id="tournament.mensTee" />
                    </FormLabel>
                    <SelectBox
                      id="maleTeeBoxId"
                      name="maleTeeBoxId"
                      onChange={this.handleChange}
                      value={maleTeeBoxId || ''}
                      inputElement={OutlinedInput}
                      disabled={false}
                      margin="none"
                    >
                      {this.courseTee()}
                    </SelectBox>
                  </Grid>
                  <Grid item xs={2}>
                    <FormLabel>
                      <FormattedMessageWrapper id="tournament.womensTee" />
                    </FormLabel>
                    <SelectBox
                      id="femaleTeeBoxId"
                      name="femaleTeeBoxId"
                      onChange={this.handleChange}
                      value={femaleTeeBoxId || ''}
                      inputElement={OutlinedInput}
                      margin="none"
                      disabled={false}
                    >
                      {this.courseTee()}
                    </SelectBox>
                  </Grid>
                  <Grid item xs={2}>
                    <FormLabel>
                      <FormattedMessageWrapper id="tournament.numberOfHoles" />
                    </FormLabel>
                    <SelectBox
                      id="holes"
                      name="holes"
                      onChange={this.handleChange}
                      value={holes}
                      inputElement={OutlinedInput}
                      margin="none"
                    >
                      <MenuItem value="18">18</MenuItem>
                      <MenuItem value="f9">Front 9</MenuItem>
                      <MenuItem value="b9">Back 9</MenuItem>
                    </SelectBox>
                  </Grid>
                </Grid>
              </div>

              <form>
                <Table>
                  <TableBody>
                    <TableRow className={classes.tableHeader}>
                      <TableCell colSpan={22} style={{ textAlign: 'left' }}>
                        {playerResults?.firstName} {playerResults?.lastName}
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.tableHeader}>
                      <TableCell colSpan={22} style={{ textAlign: 'left' }}>
                        {this.getCurrentCourse()?.clubName} - {this.getCurrentCourse()?.courseName}
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.tableHeader}>
                      <TableCell>
                        <FormattedMessageWrapper id={'course.hole'} />
                      </TableCell>

                      {frontNineHoles?.map(this.renderCell('holeNumber'))}

                      <TableCell>
                        <FormattedMessageWrapper id={'course.out'} />
                      </TableCell>

                      {backNineHoles?.map(this.renderCell('holeNumber'))}

                      <TableCell>
                        <FormattedMessageWrapper id={'course.in'} />
                      </TableCell>

                      <TableCell>
                        <FormattedMessageWrapper id={'course.total'} />
                      </TableCell>
                    </TableRow>

                    <TableRow className={classes.tableContent}>
                      <TableCell>
                        <FormattedMessageWrapper id={'course.par'} />
                      </TableCell>

                      {frontNineHoles?.map(this.renderCell('par'))}

                      <TableCell>{this.getParTotal(frontNineHoles)}</TableCell>

                      {backNineHoles?.map(this.renderCell('par'))}

                      <TableCell>{this.getParTotal(backNineHoles)}</TableCell>

                      <TableCell>{this.getParTotal(firstCourse?.holes)}</TableCell>
                    </TableRow>
                  </TableBody>

                  <TableBody>
                    <TableRow>
                      <TableCell align={'center'} className={classes.titleCell}>
                        <FormattedMessageWrapper id={'tournament.roundWithNumberShort'} values={{ round: 1 }} />
                      </TableCell>

                      {this.renderNineInputs(this.isFieldDisabled('f9'), 'front')}

                      <TableCell align={'center'} className={classes.titleCell}>
                        {frontNine.total}
                      </TableCell>

                      {this.renderNineInputs(this.isFieldDisabled('b9'), 'back')}

                      <TableCell align={'center'} className={classes.titleCell}>
                        {backNine.total}
                      </TableCell>

                      <TableCell align={'center'} className={classes.titleCell}>
                        {frontNine.total + backNine.total}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </form>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSend}
                    className={classes.sendBtn}
                    disabled={this.isDisabled()}
                  >
                    <FormattedMessageWrapper id="buttons.send" />
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </ContentWrap>
      </>
    )
  }
}

export default connect<StateProps, DispatchProps, {}, RootState>(
  (store) => {
    const clubId = Number(window.localStorage.getItem(LOCAL_STORAGE_CLUB_ID))
    const timestamp = Number(window.localStorage.getItem(LOCAL_STORAGE_START_TIME))
    return {
      auth: store.authenticationReducer,
      player: store.playersReducer,
      organization: store.organizationReducer,
      courses: selectCourses(store, { clubId, timestamp }),
    }
  },
  {
    searchClubs,
    fetchOrganization,
    getCourses,
    updatePlayersField,
    clearPlayersSearch,
    searchOrganizationPlayers,
    sendPlayerScores,
  },
)(withStyles(styles)(injectIntl(HcpRounds as any)))
