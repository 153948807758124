import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const useBracketStyles = makeStyles((theme: Theme) => ({
  bracketContainer: {
    overflow: 'scroll',
    minHeight: 'calc(100vh - 220px)',
  },
  tournamentHeaders: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '82px',
    margin: '0',
    position: 'sticky',
    top: '0',
    zIndex: '12',

    '& > div': {
      minWidth: '310px',
      width: '310px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#004129',

      '&:first-of-type': {
        borderTopLeftRadius: '31px',
        borderBottomLeftRadius: '31px',
      },
      '&:last-of-type': {
        borderTopRightRadius: '31px',
        borderBottomRightRadius: '31px',
      },

      '& > h3': {
        fontWeight: 700,
        fontSize: '26px',
        lineHeight: '30px',
        margin: 0,
        color: '#fff',
        display: 'flex',
        overflow: 'hidden',
        maxWidth: '90%',
      },
      '& > span': {
        fontWeight: 500,
        fontSize: '16px',
        color: '#fff',

        '& svg': {
          cursor: 'pointer',
          width: '16px',
          height: '16px',
          marginLeft: '5px',
          marginBottom: '-2px',
        },
      },
    },
  },

  tournamentBrackets: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    position: 'relative',
  },

  final: {
    '&&': {
      justifyContent: 'center',
      minWidth: '500px',
      '& > div': {
        zIndex: '11',
        position: 'relative',
        '&:nth-of-type(odd):after': {
          height: 'var(--final-stage-line-p1-height)',
          width: '150px',
          minWidth: '150px',
          left: '-21px',
          top: 'var(--final-stage-line-p1-top)',
          borderRightStyle: 'solid',
          borderColor: '#D9D9D9',
          borderWidth: '2px',
        },
        '&:nth-of-type(even):after': {
          height: 'var(--final-stage-line-p2-height)',
          width: '150px',
          minWidth: '150px',
          left: '-21px',
          top: '50%',
          borderRightStyle: 'solid',
          borderColor: '#D9D9D9',
          borderWidth: '2px',
        },
        '&:before': {
          content: 'none',
        },
      },
    },
  },

  bronzeMatch: {
    '&&': {
      position: 'absolute !important',
      zIndex: '1 !important',
      bottom: '-100px',

      '&:before, &:after': {
        content: 'none',
      },

      '& > div': {
        '&:before, &:after': {
          content: 'none',
        },
      },
    },
  },

  bracket: {
    paddingLeft: 0,
    display: 'flex',
    margin: '50px 0 0 0',
    minWidth: '310px',
    width: '310px',
    flexDirection: 'column',
    justifyContent: 'space-around',

    '&:first-of-type': {
      '& > div:nth-of-type(even)': {
        margin: '5px 10px 25px',
      },
    },

    '&:nth-of-type(n+2)': {
      '&:not(:nth-last-of-type(-n+2)) > div': {
        margin: '-25px 10px 0',
        '&.first-stage-hidden': {
          margin: '5px 10px',
        },
      },
    },

    '& > div': {
      margin: '5px 10px',
      position: 'relative',
      verticalAlign: 'middle',
      textAlign: 'center',

      '&:after': {
        content: '""',
        borderColor: '#D9D9D9',
        borderWidth: '2px',
        position: 'absolute',
        display: 'block',
        width: '40px',
        minWidth: '40px',
        right: '0',
      },

      '&:before': {
        content: '""',
        borderTop: ' 2px solid #D9D9D9',
        position: 'absolute',
        height: '2px',
        width: '20px',
        minWidth: '20px',
        left: '-21px',
        top: '50%',
      },

      '&:nth-of-type(odd):after': {
        borderRightStyle: 'solid',
        borderTopStyle: 'solid',
        height: 'var(--distance-to-next)',
        top: '50%',
        borderTopRightRadius: '11px',
      },

      '&:nth-of-type(even):after': {
        borderRightStyle: 'solid',
        borderBottomStyle: 'solid',
        height: '100%',
        top: '-50%',
        borderBottomRightRadius: '11px',
      },
    },

    '&:first-of-type > div': {
      '&:before': {
        content: 'none',
      },
    },
  },
  expandCollapse: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  stageDescriptionInput: {
    display: 'flex',
    position: 'absolute',
    zIndex: '12',
    height: '30px',
    width: '200px',
    top: '75px',

    '& > input': {
      height: '30px',
      width: '170px',
    },
    '& > button': {
      height: '30px',
      width: '30px',
      backgroundColor: theme.palette.primary.main,
      color: theme.customPalette.light,
      border: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  addDate: {
    textDecoration: 'underline',
  },
}))
