import React from 'react'
import { Button, Grid, Theme, Tooltip } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'

import { updateTournamentRoundStatus } from '@app/store/api/thunks/tournamentRoundThunks'
import { loadTournamentLeaderboard, LoadTournamentLeaderboardArgs } from '@store/tournamentLeaderboard/actions'
import { EndRoundSwitch } from './EndRoundSwitch'
import SectionTitle from '@app/components/ui/SectionTitle'
import { rem } from '@app/theme/materialUITheme'
import HandicappingDialog from '@app/components/dialogs/handicappingDialog/HandicappingDialog'
import { isCountryWithPlayerRegistry } from '@app/utils/organizationUtils'
import { verifyIfRoundIsCompleted } from '@app/utils/tournamentUtils'
import { tournamentApi } from '@app/store/api/endpoints/tournamentApi'
import { RootState } from '@app/store'
import { selectTournamentSettings } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
    },
    sectionTitle: {
      color: '#fff',
      marginBottom: '0 !important',
    },
    sectionSubTitle: {
      color: '#fff',
      fontSize: rem(18),
      justifyContent: 'flex-end',
      marginRight: '2rem',
    },
    roundsWrapper: {
      display: 'flex',
    },
    calculateCutButton: {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })

interface StateProps {
  tournamentState: TournamentState
  tournamentSettings: TournamentSettings
  organization: OrganizationState
  isCountryWithRegistry: boolean
}

interface DispatchProps {
  updateTournamentRoundStatus(payload: UpdateRoundStatusPayload): void
  calculateCut(payload: CalculateTournamentCutPayload): void
  loadTournamentLeaderboard: (args: LoadTournamentLeaderboardArgs) => void
}

interface State {
  handicappingDialogOpen: boolean
  loading: boolean
}

const initialState = {
  handicappingDialogOpen: false,
  loading: false,
}

type MultiRoundEndRoundsComponentProps = StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

class MultiRoundEndRoundsComponent extends React.Component<MultiRoundEndRoundsComponentProps, State> {
  readonly state = initialState

  public render() {
    const { classes, tournamentState, tournamentSettings, organization, isCountryWithRegistry } = this.props
    const { rounds, hcpRound } = tournamentState

    return (
      <>
        <div className={classes.root}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <SectionTitle className={classes.sectionTitle}>
                <FormattedMessageWrapper id="tournament.scoringDone" />
              </SectionTitle>
            </Grid>
            <Grid item xs={10}>
              <span style={{ color: '#fff' }}>
                <FormattedMessageWrapper id="tournament.scoringDoneInfo" />
              </span>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ marginTop: '2rem' }}>
            <Grid item xs={2}>
              <SectionTitle className={classes.sectionSubTitle}>
                <FormattedMessageWrapper id="tournament.round" />
              </SectionTitle>
            </Grid>
            <Grid item xs>
              <div className={classes.roundsWrapper}>
                {rounds.map((round, idx) => (
                  <EndRoundSwitch
                    key={round.id}
                    roundIndex={idx}
                    disabled={round.status?.isConfigured === false}
                    checked={verifyIfRoundIsCompleted(idx, rounds) === true}
                    onChange={this.onEndRoundSwitchChange}
                    style={{ marginRight: '2rem' }}
                  />
                ))}
              </div>
            </Grid>
            {tournamentSettings.cutEnabled && (
              <Grid item xs={2}>
                <Tooltip
                  disableFocusListener={this.isCalculateCutEnabled()}
                  disableHoverListener={this.isCalculateCutEnabled()}
                  disableTouchListener={this.isCalculateCutEnabled()}
                  title={
                    <FormattedMessageWrapper
                      id={'tournament.calculateCutTooltip'}
                      values={{ round: tournamentSettings.roundsBeforeCut }}
                    />
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!this.isCalculateCutEnabled()}
                      onClick={this.handleCalculateCut}
                      className={classes.calculateCutButton}
                    >
                      <FormattedMessageWrapper id="buttons.calculateCut" />
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={2}>
              {organization.isClub && isCountryWithRegistry && hcpRound && (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={this.isSendScoresForHandicappingDisabled()}
                  onClick={this.onSendScoresForHandicappingClick}
                >
                  <FormattedMessageWrapper id="buttons.sendScoresForHandicapping" />
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        {this.props.tournamentState.id && this.props.tournamentState.hcpRound && (
          <HandicappingDialog
            open={this.state.handicappingDialogOpen}
            onClose={() => this.setState({ handicappingDialogOpen: false })}
            tournamentId={this.props.tournamentState.id}
          />
        )}
      </>
    )
  }

  onEndRoundSwitchChange = (roundIndex: number, selected: boolean): void => {
    const { rounds, id } = this.props.tournamentState
    if (id) {
      this.props.updateTournamentRoundStatus({
        tournamentId: id,
        roundId: rounds[roundIndex].id,
        body: {
          isCompleted: selected,
        },
      })
    }
  }

  isSendScoresForHandicappingDisabled = (): boolean => {
    const { rounds } = this.props.tournamentState
    /**
     * Disable send for handicapping if none of the rounds finished
     */
    return rounds.some((_, index) => verifyIfRoundIsCompleted(index, rounds) === true) === false
  }

  isCalculateCutEnabled = (): boolean => {
    const { roundsBeforeCut } = this.props.tournamentSettings
    const { rounds } = this.props.tournamentState

    if (!roundsBeforeCut) {
      return false
    }

    if (roundsBeforeCut > rounds.length) {
      return false
    }

    /**
     * Check if all rounds from 0 up to the round indicated in tournamentSettings.roundsBeforeCut are ended
     */
    const isAllCutRoundsEnded: (boolean | undefined)[] = Array.from({ length: roundsBeforeCut }).map((_, idx) =>
      verifyIfRoundIsCompleted(idx, rounds),
    )

    return !isAllCutRoundsEnded.some((bool) => bool === false)
  }

  handleCalculateCut = (): void => {
    const { calculateCut, loadTournamentLeaderboard, tournamentState } = this.props

    calculateCut({
      tournamentId: tournamentState.id,
      onSuccess: () => tournamentState.id && loadTournamentLeaderboard({ id: tournamentState.id }),
    })
  }

  onSendScoresForHandicappingClick = (): void => {
    this.setState({ handicappingDialogOpen: true })
  }
}

export const MultiRoundEndRounds = connect<StateProps, DispatchProps, {}, RootState>(
  (store) => {
    const tournament = selectTournament(store)
    return {
      tournamentState: tournament,
      tournamentSettings: selectTournamentSettings(store),
      organization: store.organizationReducer,
      isCountryWithRegistry: isCountryWithPlayerRegistry(tournament.tournamentOrganization),
    }
  },
  {
    updateTournamentRoundStatus,
    calculateCut: tournamentApi.endpoints.calculateTournamentCut.initiate,
    loadTournamentLeaderboard,
  },
)(withStyles(styles)(injectIntl(MultiRoundEndRoundsComponent as any)))
