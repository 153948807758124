//LIBRARIES
import { FormLabel, Select, MenuItem, Grid, OutlinedInput } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import get from 'lodash/get'
import { useTheme } from '@mui/material/styles'

//COMPONENTS
import SectionTitle from '@components/ui/SectionTitle'
import InfoTooltip from '@components/ui/InfoTooltip'
import RankingConfig from '@components/dialogs/rankingDialog/RankingConfig'

//ENUMS & TYPES & UTILS & OTHER
import { Placeholder } from '../utils'

export interface RankingsProps {
  tournamentState?: TournamentState
  activeRankings: Ranking[]
  gameType: string
  updateTournamentField(payload: FieldUpdatePayload): void
}

export const Rankings: React.FC<RankingsProps> = ({
  tournamentState,
  activeRankings,
  gameType,
  updateTournamentField,
}) => {
  const theme = useTheme()

  const onChange = (event) => {
    updateTournamentField({
      fieldName: `${gameType}Ranking`,
      value: event.target.value === '' ? null : event.target.value,
    })

    const ranking = activeRankings.find((r) => r.id === event.target.value)
    const template = ranking ? [...get(ranking, 'configTemplate')] : null

    updateTournamentField({
      fieldName: `${gameType}RankingConfig`,
      value: template,
    })
  }

  if (activeRankings.length === 0) {
    return null
  }

  return (
    <>
      <Grid item xs={12}>
        <SectionTitle style={{ marginBottom: theme.spacing(2) }}>
          <FormattedMessageWrapper id={`tournament.${gameType}GameRanking`} />
          <InfoTooltip
            style={{ marginLeft: theme.spacing(1) }}
            text={
              <FormattedMessageWrapper
                id={gameType === 'primary' ? 'tournament.primaryRankingInfo' : 'tournament.sideRankingInfo'}
              />
            }
          />
        </SectionTitle>
      </Grid>
      <Grid item xs={10} style={{ marginBottom: theme.spacing(2) }}>
        <FormLabel>
          <FormattedMessageWrapper id="tournament.connectToRanking" />
        </FormLabel>
        <Select
          variant="filled"
          name={`${gameType}Ranking`}
          id={`${gameType}Ranking`}
          fullWidth
          value={tournamentState?.[`${gameType}Ranking`] || ''}
          displayEmpty
          input={<OutlinedInput name={`${gameType}Ranking`} id={`${gameType}Ranking`} fullWidth />}
          onChange={onChange}
          renderValue={
            Number.isInteger(tournamentState?.[`${gameType}Ranking`])
              ? undefined
              : () => (
                  <Placeholder>
                    <FormattedMessageWrapper id="tournament.noRanking" />
                  </Placeholder>
                )
          }
        >
          <MenuItem value="" style={{ fontStyle: 'italic' }}>
            <FormattedMessageWrapper id="tournament.noRanking" />
          </MenuItem>
          {activeRankings.map((r) => (
            <MenuItem key={r.name} value={r.id}>
              {r.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={10}>
        {tournamentState?.[`${gameType}Ranking`] && tournamentState?.[`${gameType}RankingConfig`] && (
          <>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.rankingConfig" />
            </FormLabel>
            <RankingConfig
              template={tournamentState[`${gameType}RankingConfig`]}
              updateRankingConfig={(config) => {
                updateTournamentField({
                  fieldName: `${gameType}RankingConfig`,
                  value: config,
                })
              }}
            />
          </>
        )}
      </Grid>
    </>
  )
}
