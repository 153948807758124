import React from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Button,
  Typography,
  Theme,
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { IntlShape } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { AddCircle, Delete } from '@mui/icons-material'
import { get } from 'lodash'
import Groups from '@components/tournament/Groups'
import { confirm } from '@components/dialogs/confirm/Confirm'
import SectionTitle from '@components/ui/SectionTitle'
import { EditPlayerDialogWrapper } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import { GroupsList } from './GroupsList'
import BorderButton from '@components/ui/BorderButton'
import { selectTournamentStartLists, updateStartListField } from '@store/api/slices/tournamentStartListsSlice'
import {
  getAssignedPlayerIdsForCurrentRound,
  getUnassignedTeamsCount,
} from '@app/store/api/selectors/tournamentStartListsSelectors'
import { rem } from '@app/theme/materialUITheme'
import DeleteConfirm, { DeleteConfirmChildren } from '@app/components/dialogs/deleteConfirm/DeleteConfirm'
import { getUnassignedPlayers } from '@app/store/api/selectors/tournamentStartListsSelectors'
import { StartTypes } from '@config/index'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { calculateShotgunNeededGroupsToFill } from '@app/utils/startListUtils'
import { PlayerPoolEditContext } from '../players/enums'
import { verifyIfRoundIsCompleted } from '@app/utils/tournamentUtils'
import { RootState } from '@app/store'
import { selectTournamentPlayers } from '@app/store/api/endpoints/tournamentPlayersApi'
import { selectTournamentTeams } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournamentSettings } from '@app/store/api/endpoints/tournamentSettingsApi'
import { tournamentStartListsApi } from '@app/store/api/endpoints/tournamentStartListsApi'
import { tournamentStartListGroupApi } from '@app/store/api/endpoints/tournamentStartListGroupApi'
import { removePlayerFromGroup } from '@app/store/api/thunks/tournamentStartListsThunks'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

interface OwnProps {
  currentRound: StartListRound
  currentTournamentRound: Round
  hasGroups: boolean
  intl: IntlShape
  isStartListPublished: boolean
}

interface StateProps {
  assignedPlayerIds: number[]
  isTeamFormat: boolean
  isTwoManTeamFormat: boolean
  players: TournamentPlayer[]
  selectedRoundIndex: number
  teams: TournamentTeam[]
  tournament: TournamentState
  tournamentSettings: TournamentSettings
  tournamentRounds: Round[]
  startListRounds: StartListRound[]
  unassignedPlayers: TournamentPlayer[]
  unassignedTeamsCount: number
}

interface DispatchProps {
  deleteStartList(payload: StartListPayload): void
  copyStartList(payload: CopyStartListPayload): void
  createStartGroup(payload: CreateStartListGroupPayload): void
  createStartList(payload: CreateStartListPayload): void
  removePlayerFromGroup(payload: RemovePlayerFromGroupPayload): void
  updateStartListField(payload: UpdateRoundFieldPayload): void
}

const styles = (theme: Theme) =>
  createStyles({
    createStartGroupsButton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: rem(50),
      alignContent: 'center',
      height: '100%',
      '& .Mui-disabled': {
        color: 'grey',
        borderColor: 'rgba(255, 255, 255, 0.3)',
      },
    },
    actionButton: {
      color: theme.palette.primary.contrastText,
      marginBottom: 10,
      '&.Mui-disabled': {
        color: theme.palette.common.white,
        opacity: 0.5,
      },
    },
    hideFullGroupsLabel: {
      marginBottom: 10,
      marginLeft: 20,
      '& .MuiTypography-root': {
        color: `${theme.palette.common.white}`,
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.common.white,
      },
    },
    errorText: {
      color: theme.customPalette.light,
      fontWeight: 'bold',
      textAlign: 'center',
      margin: 50,
    },
    orDivider: {
      margin: `${rem(30)} 0`,
      display: 'flex',
      alignItems: 'center',
      '&::before, &::after': {
        content: '""',
        flexGrow: 1,
        backgroundColor: theme.palette.common.white,
        height: '1px',
        fontSize: 0,
        lineHeight: 0,
        margin: `0 ${rem(20)}`,
      },
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      fontSize: rem(20),
    },
    selectFillCriteria: {
      width: rem(400),
      height: rem(60),
      alignSelf: 'center',
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        color: theme.palette.common.white,
        fontSize: rem(16),
      },
      '& .MuiInputBase-root.Mui-disabled .MuiSelect-outlined.MuiSelect-outlined': {
        color: 'grey',
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.3)',
      },

      '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.white,
        borderWidth: '2px',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.white,
        borderWidth: '2px',
      },
      '& .MuiSelect-icon': {
        color: theme.palette.common.white,
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '10px',
      position: 'relative',
    },
    headerButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0.5rem',
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    startListButton: { borderColor: '#FFFFFF', color: '#FFFFFF', margin: '15px auto 0 auto', width: 400 },
    clearSaveButton: {
      borderColor: '#0154C1',
      color: '#FFFFFF',
      minWidth: 70,
      width: 70,
      '&.Mui-disabled': {
        color: theme.palette.common.white,
        opacity: 0.5,
      },
    },
    roundNumber: {
      color: theme.palette.secondary.main,
      marginLeft: '1rem',
    },
  })

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>

interface State {
  hideFullGroups: boolean
}

class StartListComponent extends React.Component<Props, State> {
  readonly state: State = {
    hideFullGroups: false,
  }

  render() {
    const { classes, currentRound, tournament, selectedRoundIndex } = this.props

    return (
      <>
        <div className={classes.headerContainer}>
          <SectionTitle style={{ margin: 0 }}>
            <FormattedMessageWrapper id="tournament.startList" />
            {tournament?.tournamentType === TournamentTypes.multiRound && (
              <span className={classes.roundNumber}>
                <FormattedMessageWrapper id="tournament.roundCount" values={{ round: selectedRoundIndex + 1 }} />
              </span>
            )}
          </SectionTitle>
        </div>
        {this._isTeamsInvalid && currentRound.groups.length === 0 ? (
          <Groups>
            <Typography variant="body1" className={classes.errorText}>
              <FormattedMessageWrapper id="tournament.errorInTeamValidation" />
            </Typography>
          </Groups>
        ) : (
          <EditPlayerDialogWrapper editContext={PlayerPoolEditContext.GROUP} roundIndex={selectedRoundIndex}>
            {(args) => <Groups>{this._renderStartGroups(args)}</Groups>}
          </EditPlayerDialogWrapper>
        )}
      </>
    )
  }

  private get _isTeamsInvalid() {
    const { teams, isTwoManTeamFormat } = this.props
    const teamInValid =
      teams &&
      teams.some((team) => {
        if (isTwoManTeamFormat && team.players.length > 2) {
          return true
        } else if (team.players.length > 5) {
          return true
        } else {
          return false
        }
      })

    return teamInValid
  }

  private _renderStartGroups(dialogArgs) {
    const {
      currentRound,
      currentTournamentRound,
      isStartListPublished,
      players,
      removePlayerFromGroup,
      selectedRoundIndex,
    } = this.props

    const { groups, basicGroupSize } = currentRound
    const { scoringStarted } = currentTournamentRound

    const fullGroupSize =
      this.props.isTeamFormat && !this.props.isTwoManTeamFormat
        ? this.props.tournamentSettings.defaultTeamSize
        : basicGroupSize

    if (groups && groups.length > 0) {
      return (
        <>
          {this._renderGroupActions()}
          {/**
           * Groups list settings component
           */}
          <GroupsList
            groups={groups}
            hideFullGroups={this.state.hideFullGroups}
            fullGroupSize={fullGroupSize}
            basicGroupSize={basicGroupSize}
            confirmPlayerRemove={scoringStarted}
            primaryGameFormatId={this.primaryGameFormatId}
            dialogArgs={dialogArgs}
            removePlayerAction={(playerId: number, index: number) => {
              removePlayerFromGroup({ roundIndex: selectedRoundIndex, groupIndex: index, playerId })
            }}
            isStartListPublished={isStartListPublished}
            disableTeamPlayerEdit={isStartListPublished || selectedRoundIndex > 0}
          />
        </>
      )
    }

    // print "Create Start List" button
    const { classes, startListRounds } = this.props

    return (
      <div className={classes.createStartGroupsButton}>
        <BorderButton
          buttonProps={{
            onClick: () => this.onCreateStartList(false),
            disabled: !players.length || this.isStartListCreateDisabled,
            className: classes.startListButton,
            style: { margin: '0 auto' },
          }}
          jumbo
        >
          {startListRounds.length > 1 ? (
            <FormattedMessageWrapper
              id="tournament.createStartListForRound"
              values={{ round: selectedRoundIndex + 1 }}
            />
          ) : (
            <FormattedMessageWrapper id="tournament.createEmptyStartList" />
          )}
        </BorderButton>

        <div className={classes.orDivider}>
          <FormattedMessageWrapper id="tournament.or" />
        </div>
        <FormControl
          variant="outlined"
          className={classes.selectFillCriteria}
          disabled={!players.length || this.isStartListCreateDisabled}
        >
          <Select value="1" onChange={this.onSelectFillCriteria}>
            <MenuItem key={1} value="1">
              <FormattedMessageWrapper id="tournament.selectFillCriteria" />
            </MenuItem>
            <MenuItem value="poolOrder">
              <FormattedMessageWrapper id={'options.poolOrder'} />
            </MenuItem>
            <MenuItem value="random">
              <FormattedMessageWrapper id={'options.random'} />
            </MenuItem>
            {selectedRoundIndex > 0 && !this.isPreviousRoundTypeShotgun(selectedRoundIndex) && (
              <MenuItem value="copyAndReverseStartListFromRound">
                <FormattedMessageWrapper
                  id="tournament.copyAndReverseStartListFromRound"
                  values={{ round: selectedRoundIndex - 1 }}
                />
              </MenuItem>
            )}
            {selectedRoundIndex > 0 && !this.isPreviousRoundTypeShotgun(selectedRoundIndex) && (
              <MenuItem value="reverseResultOrder">
                <FormattedMessageWrapper id={'options.reverseLeaderboardOrder'} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    )
  }

  private get isAllGroupsAllocated(): boolean {
    const { unassignedPlayers, currentRound, unassignedTeamsCount, isTeamFormat, isTwoManTeamFormat, tournament } =
      this.props
    const { basicGroupSize } = currentRound

    const defaultTeamSize =
      tournament.rounds[0].primaryGameOptions?.defaultTeamSize ||
      tournament.rounds[0].sideGameOptions?.defaultTeamSize ||
      4

    const neededGroupsToFill = calculateShotgunNeededGroupsToFill(
      isTeamFormat,
      isTwoManTeamFormat,
      basicGroupSize,
      unassignedTeamsCount,
      unassignedPlayers.length,
      Number(defaultTeamSize),
    )

    const assignedGroupsCount =
      (currentRound.groupStartTees.length > 0 && currentRound.groupStartTees.reduce((acc, current) => acc + current)) ||
      0

    return assignedGroupsCount / neededGroupsToFill === 1 ? true : false
  }

  getRoundStatus = (roundIndex: number): RoundStatus => {
    const { tournamentRounds } = this.props
    return tournamentRounds[roundIndex].status as RoundStatus
  }

  isStartListPublishedForRound = (roundIndex: number): boolean => {
    const roundStatus = this.getRoundStatus(roundIndex)
    return roundStatus.isConfigured
  }

  isRoundEnded = (): boolean => {
    const { selectedRoundIndex, tournamentRounds } = this.props
    return verifyIfRoundIsCompleted(selectedRoundIndex, tournamentRounds)
  }

  isPreviousRoundTypeShotgun = (roundIndex: number): boolean => {
    const { startType } = this.props.startListRounds[roundIndex - 1]
    return startType === StartTypes.SHOTGUN.value || startType === StartTypes.SHOTGUN_REVERSED.value
  }

  private get isStartListCreateDisabled() {
    const { startType } = this.props.currentRound
    return (
      (startType === StartTypes.SHOTGUN.value || startType === StartTypes.SHOTGUN_REVERSED.value) &&
      !this.isAllGroupsAllocated
    )
  }

  private onCreateStartList = (autofill: boolean) => {
    const { createStartList, tournament, currentRound } = this.props
    createStartList({ tournamentId: tournament.id, roundId: currentRound.tournamentRoundId, autofill })
  }

  private onSelectFillCriteria = (event) => {
    const { selectedRoundIndex } = this.props
    const criteria = event.target.value
    if (criteria === 'copyAndReverseStartListFromRound') {
      this.onCopyStartList(selectedRoundIndex - 1, true)
    } else {
      this.props.updateStartListField({
        index: selectedRoundIndex,
        fieldName: 'fillStartListByType',
        value: criteria,
      })
      this.onCreateStartList(true)
    }
  }

  private onCopyStartList = (previousRoundIndex: number, reverse: boolean) => {
    const { startListRounds, copyStartList, currentRound, tournament } = this.props
    const prevRoundId = startListRounds[previousRoundIndex].tournamentRoundId
    copyStartList({
      tournamentId: tournament.id,
      roundId: currentRound.tournamentRoundId,
      body: { copyFromRound: prevRoundId, reverse },
    })
  }

  private get primaryGameFormatId() {
    const { currentTournamentRound } = this.props
    const round: Round = currentTournamentRound
    return round.primaryGameId
  }

  private _renderGroupActions = () => {
    const { classes, hasGroups } = this.props

    return (
      <Grid
        container
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Grid item>
          <div style={{ display: 'flex' }}>
            <Button onClick={this.onCreateGroup} className={classes.actionButton}>
              <AddCircle style={{ marginRight: 10 }} />
              <FormattedMessageWrapper id="tournament.addStartGroup" />
            </Button>
            <FormControlLabel
              className={classes.hideFullGroupsLabel}
              control={
                <Checkbox
                  name={'hideFullGroups'}
                  checked={this.state.hideFullGroups}
                  onChange={(_, checked) => this.setState({ hideFullGroups: checked })}
                />
              }
              label={<FormattedMessageWrapper id="tournament.hideFullGroups" />}
            />
          </div>
        </Grid>
        <Grid item>
          <div style={{ marginBottom: 10 }}>
            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                <Button
                  onClick={() => showConfirm({ callback: this.onClickClear, messageId: 'strings.groupDeleteConfirm' })}
                  disabled={!hasGroups}
                  className={classes.actionButton}
                >
                  <Delete style={{ marginRight: 10 }} />
                  <FormattedMessageWrapper id="buttons.clear" />
                </Button>
              )}
            </DeleteConfirm>
          </div>
        </Grid>
      </Grid>
    )
  }

  private onClickClear = () => {
    const { currentTournamentRound, tournament, deleteStartList, startListRounds } = this.props
    const scoringStarted = get(currentTournamentRound, 'scoringStarted', false)
    const { intl } = this.props
    const roundId = startListRounds[this.props.selectedRoundIndex]?.id

    const action = () => deleteStartList({ tournamentId: tournament.id, roundId })

    if (!scoringStarted) {
      action()
      return
    }

    confirm({
      message: intl.formatMessage({ id: 'tournament.scoringStartedClearStartListConfirm' }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      },
    }).then(() => {
      action()
    })
  }

  private onCreateGroup = () => {
    const { createStartGroup, tournament, currentRound } = this.props
    createStartGroup({ tournamentId: tournament.id, roundId: currentRound.tournamentRoundId })
  }
}

export const StartList = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state): StateProps => {
    const tournament = selectTournament(state)
    return {
      assignedPlayerIds: getAssignedPlayerIdsForCurrentRound(state),
      isTeamFormat: tournament.isTeamFormat,
      isTwoManTeamFormat: tournament.isTwoManTeamFormat,
      players: selectTournamentPlayers(state).data?.players || [],
      selectedRoundIndex: selectTournamentConfig(state).selectedRoundIndex,
      teams: selectTournamentTeams(state).data?.teams || [],
      tournament: tournament,
      tournamentSettings: selectTournamentSettings(state),
      tournamentRounds: tournament.rounds,
      startListRounds: selectTournamentStartLists(state).rounds,
      unassignedPlayers: getUnassignedPlayers(state),
      unassignedTeamsCount: getUnassignedTeamsCount(state),
    }
  },
  {
    deleteStartList: tournamentStartListsApi.endpoints.deleteStartList.initiate,
    copyStartList: tournamentStartListsApi.endpoints.copyStartList.initiate,
    createStartGroup: tournamentStartListGroupApi.endpoints.createStartListGroup.initiate,
    createStartList: tournamentStartListsApi.endpoints.createStartList.initiate,
    removePlayerFromGroup,
    updateStartListField,
  },
)(withStyles(styles)(StartListComponent as any))
