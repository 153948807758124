import {
  FetchToursAction,
  FetchToursSuccessAction,
  FetchToursFailureAction,
  FetchRankingsAction,
  FetchRankingsSuccessAction,
  FetchRankingsFailureAction,
  CreateRankingFailureAction,
  CreateRankingAction,
  CreateRankingSuccessAction,
  UpdateRankingAction,
  UpdateRankingSuccessAction,
  UpdateRankingFailureAction,
  DeleteRankingAction,
  DeleteRankingSuccessAction,
  DeleteRankingFailureAction,
  FetchTourAction,
  FetchTourSuccessAction,
  FetchTourFailureAction,
  ResetTourAction,
  SaveTourAction,
  SaveTourSuccessAction,
  SaveTourFailureAction,
  DeleteTourAction,
  DeleteTourSuccessAction,
  DeleteTourFailureAction,
  UpdateTourFieldAction,
  DeleteTourImageAction,
  UploadTourImageFinishedAction,
  DeleteTourImageFinishedAction,
  UploadTourImageAction,
  SendRankingPointsAction,
  SendRankingPointsSuccessAction,
  SendRankingPointsFailureAction,
} from './actions'
import {
  FETCH_TOURS,
  FETCH_RANKINGS,
  FETCH_TOURS_FAILURE,
  FETCH_RANKINGS_FAILURE,
  FETCH_TOURS_SUCCESS,
  FETCH_RANKINGS_SUCCESS,
  CREATE_RANKING,
  CREATE_RANKING_FAILURE,
  CREATE_RANKING_SUCCESS,
  UPDATE_RANKING,
  UPDATE_RANKING_FAILURE,
  UPDATE_RANKING_SUCCESS,
  DELETE_RANKING,
  DELETE_RANKING_FAILURE,
  DELETE_RANKING_SUCCESS,
  FETCH_TOUR,
  FETCH_TOUR_FAILURE,
  FETCH_TOUR_SUCCESS,
  RESET_TOUR,
  SAVE_TOUR,
  SAVE_TOUR_FAILURE,
  SAVE_TOUR_SUCCESS,
  DELETE_TOUR,
  DELETE_TOUR_FAILURE,
  DELETE_TOUR_SUCCESS,
  UPDATE_TOUR_FIELD,
  UPLOAD_TOUR_IMAGE,
  DELETE_TOUR_IMAGE,
  UPLOAD_TOUR_IMAGE_FINISHED,
  DELETE_TOUR_IMAGE_FINISHED,
  SEND_RANKING_POINTS,
  SEND_RANKING_POINTS_FAILURE,
  SEND_RANKING_POINTS_SUCCESS,
} from './constants'
import { emptyTour } from '../api/emptyObjects'

const initialState: TourAndRankingState = {
  loading: false,
  error: null,
  tours: [],
  rankings: [],
  tour: emptyTour,
}

type TourAndRankingAction =
  | FetchToursAction
  | FetchToursSuccessAction
  | FetchToursFailureAction
  | FetchRankingsAction
  | FetchRankingsSuccessAction
  | FetchRankingsFailureAction
  | CreateRankingAction
  | CreateRankingSuccessAction
  | CreateRankingFailureAction
  | UpdateRankingAction
  | UpdateRankingSuccessAction
  | UpdateRankingFailureAction
  | DeleteRankingAction
  | DeleteRankingSuccessAction
  | DeleteRankingFailureAction
  | FetchTourAction
  | FetchTourSuccessAction
  | FetchTourFailureAction
  | ResetTourAction
  | SaveTourAction
  | SaveTourSuccessAction
  | SaveTourFailureAction
  | DeleteTourAction
  | DeleteTourSuccessAction
  | DeleteTourFailureAction
  | UpdateTourFieldAction
  | UploadTourImageAction
  | UploadTourImageFinishedAction
  | DeleteTourImageAction
  | DeleteTourImageFinishedAction
  | SendRankingPointsAction
  | SendRankingPointsSuccessAction
  | SendRankingPointsFailureAction

export const tourAndRankingReducer = (
  state: TourAndRankingState = initialState,
  action: TourAndRankingAction,
): TourAndRankingState => {
  switch (action.type) {
    case FETCH_TOURS:
    case FETCH_RANKINGS:
    case CREATE_RANKING:
    case UPDATE_RANKING:
    case DELETE_RANKING:
    case FETCH_TOUR:
    case SAVE_TOUR:
    case DELETE_TOUR:
    case UPLOAD_TOUR_IMAGE:
    case DELETE_TOUR_IMAGE:
    case SEND_RANKING_POINTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_TOURS_FAILURE:
    case FETCH_RANKINGS_FAILURE:
    case CREATE_RANKING_FAILURE:
    case UPDATE_RANKING_FAILURE:
    case DELETE_RANKING_FAILURE:
    case FETCH_TOUR_FAILURE:
    case SAVE_TOUR_FAILURE:
    case DELETE_TOUR_FAILURE:
    case SEND_RANKING_POINTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    case FETCH_TOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        tours: action.tours,
      }
    case FETCH_RANKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        rankings: action.rankings,
      }
    case CREATE_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        rankings: [...state.rankings, action.ranking],
      }
    case UPDATE_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        rankings: [...state.rankings.filter((r) => r.id !== action.ranking.id), action.ranking],
      }
    case DELETE_RANKING_SUCCESS:
      return {
        ...state,
        loading: false,
        rankings: [...state.rankings.filter((r) => r.id !== action.rankingId)],
      }
    case FETCH_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        tour: action.tour,
      }
    case SAVE_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        tour: action.tour,
      }
    case DELETE_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        tours: [...state.tours.filter((t) => t.id !== action.tourId)],
      }
    case RESET_TOUR:
      return {
        ...state,
        loading: false,
        tour: emptyTour,
      }
    case UPDATE_TOUR_FIELD:
      return {
        ...state,
        loading: false,
        tour: {
          ...state.tour,
          [action.payload.fieldName]: action.payload.value,
        },
      }
    case UPLOAD_TOUR_IMAGE_FINISHED:
    case DELETE_TOUR_IMAGE_FINISHED:
    case SEND_RANKING_POINTS_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
