import React, { RefObject } from 'react'
import { connect } from 'react-redux'
import {
  Theme,
  Select,
  FormLabel,
  Tooltip,
  Typography,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Stack,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import FilePicker from '../ui/FilePicker'
import BorderButton from '../ui/BorderButton'
import UploadedImage from '../ui/UploadedImage'
import SectionTitle from '../ui/SectionTitle'
import { CloudUpload, ErrorOutline } from '@mui/icons-material'

import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'

import {
  updateOrganization,
  UpdateOrganizationPayload,
  uploadOrganizationLogoImage,
  UploadOrganizationLogoImagePayload,
  uploadOrganizationCoverImage,
  UploadOrganizationCoverImagePayload,
} from '@store/organization/actions'
import { fetchTimeZones } from '@store/timeZones/actions'
import { enqueueNotification } from '@store/notifications/actions'
import { searchClubs } from '@store/api/thunks/clubsAndCoursesThunks'
import { checkAuth } from '@store/authentication/actions'

import {
  EditableOrganizationItem,
  showPublicEmailWarning,
  formEditableOrganizationItem,
} from '@utils/organizationUtils'
import { isRoleInfoProviderAdmin } from '@utils/authUtils'

import get from 'lodash/get'
import debounce from 'lodash/debounce'

import CountryPicker from '../tournament/CountryPicker'
import CountryStatePicker from '../tournament/CountryStatePicker'
import { Units } from '@app/store/api/enums/tournamentEnums'
import CropImageDialog from '../dialogs/cropImageDialog/CropImageDialog'
import { Crop } from 'react-image-crop'
import {
  BusinessProfile,
  BusinessProfilePic,
  BusinessProfilePhone,
  BusinessProfileEmail,
  BusinessProfileWebsite,
  BusinessProfileMessage,
  BusinessProfileBg,
  BusinessProfileBg2,
} from '@assets/images'

import InfoTooltip from '../ui/InfoTooltip'
import { Autocomplete } from '../forms/Autocomplete'
import { RootState } from '@app/store'
import { selectClubs } from '@app/store/api/slices/clubsAndCoursesSlice'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      marginBottom: 18,
    },
    formLabel: {
      marginBottom: 15,
      marginTop: 0,
    },
    phone: {
      width: 300,
      height: 541,
      margin: '0 auto',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      padding: '66px 40px 87px 41px',
      overflow: 'hidden',
    },
    wrapper: {
      width: 219,
      height: 388,
      overflow: 'hidden',
      backgroundColor: theme.customPalette.light,
    },
    coverImage: {
      width: 220,
      height: 110,
      backgroundColor: theme.palette.primary.dark,
      position: 'absolute',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    profilePic: {
      width: 80,
      height: 80,
      borderRadius: 80,
      position: 'relative',
      margin: '45px auto 5px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.customPalette.light,
    },
    info: {
      width: 190,
      position: 'relative',
      margin: '0 auto',
    },
    organizationName: {
      color: theme.customPalette.darkGray2,
      fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
      fontSize: 15,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    subtitle: {
      color: theme.customPalette.darkGray2,
      fontSize: 10,
      lineHeight: '1.1em',
      fontWeight: 400,
      textAlign: 'center',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-around',
      margin: '15px 0',
    },
    button: {
      width: 25,
      height: 25,
    },
    description: {
      position: 'relative',
      fontSize: 10,
      lineHeight: '1.1em',
      width: 190,
      marginLeft: 11,
      height: 46,
    },
    fillerBackground: {
      position: 'relative',
      width: 220,
      '& img': {
        maxWidth: '100%',
      },
    },
    fillerBackground2: {
      position: 'relative',
      width: 220,
      '& img': {
        maxWidth: '100%',
      },
    },
    warningIcon: {
      color: theme.customPalette.error,
      width: '1.1rem',
      height: '1.1rem',
      fontSize: '1rem',
      marginRight: 5,
      top: 3,
      position: 'relative',
    },
    warning: {
      color: theme.customPalette.error,
    },
  })

interface OwnProps {
  showSaveAction?: boolean
  ref?: any
  enableOrganizationNameEdit?: boolean
  onChange?(any: any): void
  onEditComplete?(args?: APICallResult): void
}

interface StateProps {
  auth?: AuthenticationState
  clubs?: Club[]
  organization: EditableOrganizationItem
  timeZones: TimeZone[]
  orgTimeZoneId?: number
}

interface DispatchProps {
  uploadOrganizationLogoImage(payload: UploadOrganizationLogoImagePayload): void
  updateOrganization(payload: UpdateOrganizationPayload): void
  uploadOrganizationCoverImage(payload: UploadOrganizationCoverImagePayload): void
  enqueueNotification(error: any, variant?: string): void
  searchClubs: (payload: SearchClubsPayload, isPublicSearch: boolean) => void
  fetchTimeZones: (onComplete?: () => void) => void
  checkAuth(): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>

interface State {
  tempLogo?: string
  tempCoverImage?: string
  coverImageCropDialogOpen: boolean
  logoImageCropDialogOpen: boolean
  coverImageInput?: null | string
  logoImageInput?: null | string
  coverImageCrop: Crop
  logoImageCrop: Crop
  countryId?: number
  stateId?: number
  club?: { label: string; value: number }
  clubSearchTerm: string
  nameMarketing: string
  phone: string
  email: string
  homepage: string
  additionalInfo: string
  description: string
  units: string
  timeZoneId: number
}

const defaultCoverImageCrop: Crop = {
  unit: '%',
  x: 0,
  y: 0,
  width: 100,
  height: 100,
}

const defaultLogoImageCrop: Crop = {
  unit: '%',
  x: 0,
  y: 0,
  height: 100,
  width: 100,
}

class OrganizationProfileManagement extends React.Component<Props & WrappedComponentProps, State> {
  organizationForm: RefObject<HTMLFormElement>

  readonly state: State = {
    tempLogo: '',
    tempCoverImage: '',
    coverImageCropDialogOpen: false,
    coverImageCrop: defaultCoverImageCrop,
    logoImageCropDialogOpen: false,
    logoImageCrop: defaultLogoImageCrop,
    club: undefined,
    clubSearchTerm: '',
    nameMarketing: '',
    phone: '',
    email: '',
    homepage: '',
    additionalInfo: '',
    description: '',
    units: 'metric',
    timeZoneId: 0,
  }

  constructor(props) {
    super(props)
    this.organizationForm = React.createRef()

    const { nameMarketing, club, phone, email, homepage, additionalInfo, description, units } = props.organization

    this.state = {
      ...this.state,
      nameMarketing,
      club: club ? { label: club.name, value: club.id } : undefined,
      phone,
      email,
      homepage,
      additionalInfo,
      description,
      units,
    }
  }

  componentDidMount() {
    const { timeZones, fetchTimeZones } = this.props

    if (!timeZones.length) {
      fetchTimeZones()
    }
  }

  private handleTimeZoneChange = (event) => {
    this.setState({
      timeZoneId: event.target.value as number,
    })
  }

  render() {
    const { organization, classes, showSaveAction = true, enableOrganizationNameEdit, onChange, auth } = this.props

    const { nameMarketing, phone, email, homepage, club, clubSearchTerm, additionalInfo, description, units } =
      this.state

    const showWarning = showPublicEmailWarning(this.props.organization)

    return (
      <>
        <div className={classes.root}>
          <form onSubmit={this._handleOrganizationUpdate} ref={this.organizationForm} onChange={onChange}>
            <Grid container={true} spacing={5}>
              <Grid item={true} xs={8}>
                <FormLabel disabled={!enableOrganizationNameEdit}>
                  <FormattedMessageWrapper id={'tournament.organizationName'} />
                </FormLabel>
                <TextField
                  id={'nameOrganization'}
                  name={'nameOrganization'}
                  variant="standard"
                  fullWidth={true}
                  value={nameMarketing}
                  disabled={!enableOrganizationNameEdit}
                  autoComplete={'off'}
                  className={classes.formLabel}
                  onChange={(e) => this.setState({ nameMarketing: e.target.value })}
                />

                {auth && isRoleInfoProviderAdmin(auth.roleInfo) ? (
                  <>
                    <FormControl margin="normal" fullWidth>
                      <FormLabel>
                        <FormattedMessageWrapper id="register.club" />
                      </FormLabel>
                      <Autocomplete
                        value={club}
                        onChange={(val) => val && this.setState({ club: val })}
                        options={this._clubItems}
                        inputValue={clubSearchTerm}
                        onInputChange={(val) => {
                          this.setState({ clubSearchTerm: val })
                          this._fetchClubs()
                        }}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormLabel disabled>
                      <FormattedMessageWrapper id={'tournament.club'} />
                    </FormLabel>
                    <TextField
                      disabled
                      id={'club'}
                      name={'club'}
                      variant="standard"
                      fullWidth={true}
                      value={organization.club ? organization.club.name : ''}
                      autoComplete={'off'}
                      className={classes.formLabel}
                    />
                  </>
                )}

                <FormLabel>
                  <FormattedMessageWrapper id={'dashboard.telephone'} />
                </FormLabel>
                <TextField
                  id={'phone'}
                  name={'phone'}
                  variant="standard"
                  margin={'normal'}
                  fullWidth={true}
                  value={phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  autoComplete={'off'}
                  className={classes.formLabel}
                />

                <Tooltip
                  disableTouchListener={!showWarning}
                  disableHoverListener={!showWarning}
                  disableFocusListener={!showWarning}
                  title={<FormattedMessageWrapper id="tournament.publicEmailTooltip" />}
                  placement="top-start"
                >
                  <div>
                    <FormLabel className={showWarning ? classes.warning : ''}>
                      {showWarning && <ErrorOutline className={classes.warningIcon} />}
                      <FormattedMessageWrapper id={'dashboard.email'} />
                    </FormLabel>
                    <TextField
                      id={'email'}
                      name={'email'}
                      variant="standard"
                      margin={'normal'}
                      fullWidth={true}
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      autoComplete={'off'}
                      className={classes.formLabel}
                    />
                  </div>
                </Tooltip>

                <FormLabel>
                  <FormattedMessageWrapper id={'dashboard.website'} />
                </FormLabel>
                <TextField
                  id={'homepage'}
                  name={'homepage'}
                  variant="standard"
                  margin={'normal'}
                  fullWidth={true}
                  value={homepage}
                  onChange={(e) => this.setState({ homepage: e.target.value })}
                  autoComplete={'off'}
                  className={classes.formLabel}
                />

                <FormLabel>
                  <FormattedMessageWrapper id={'dashboard.additionalInfo'} />
                </FormLabel>
                <TextField
                  id={'additionalInfo'}
                  name={'additionalInfo'}
                  variant="standard"
                  margin={'normal'}
                  fullWidth={true}
                  multiline={true}
                  value={additionalInfo || ''}
                  onChange={(e) => this.setState({ additionalInfo: e.target.value })}
                  autoComplete={'off'}
                  className={classes.formLabel}
                />

                <FormLabel>
                  <FormattedMessageWrapper id={'dashboard.description'} />
                </FormLabel>
                <TextField
                  id={'description'}
                  name={'description'}
                  variant="standard"
                  margin={'normal'}
                  fullWidth={true}
                  multiline={true}
                  value={description || ''}
                  onChange={(e) => this.setState({ description: e.target.value })}
                  autoComplete={'off'}
                  className={classes.formLabel}
                />

                {showSaveAction && (
                  <BorderButton
                    buttonProps={{
                      type: 'submit',
                      style: {
                        marginTop: 20,
                        marginBottom: 40,
                      },
                    }}
                  >
                    <FormattedMessageWrapper id={'buttons.save'} />
                  </BorderButton>
                )}

                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={6}>
                    <FormLabel>
                      <FormattedMessageWrapper id={'tournament.organizationLogo'} />
                    </FormLabel>

                    {showSaveAction && (
                      <div style={{ marginTop: 15 }}>
                        {this._renderUploadButton({
                          id: 'organization-logo',
                          name: 'logo',
                          label: <FormattedMessageWrapper id={'buttons.uploadLogo'} />,
                        })}
                      </div>
                    )}

                    <UploadedImage image={this._organizationLogo} round={true} style={{ maxWidth: 100 }} />
                  </Grid>

                  <Grid item={true} xs={6}>
                    <FormLabel>
                      <FormattedMessageWrapper id={'tournament.organizationCoverImage'} />
                    </FormLabel>

                    {showSaveAction && (
                      <div style={{ marginTop: 15 }}>
                        {this._renderUploadButton({
                          id: 'cover-image',
                          name: 'coverImage',
                          label: <FormattedMessageWrapper id={'buttons.uploadCoverImage'} />,
                        })}
                      </div>
                    )}

                    <UploadedImage image={this._organizationCoverImage} style={{ maxWidth: 186 }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={4}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InfoTooltip
                    text={<FormattedMessageWrapper id="tournament.ggbAppProfilePreviewInfo" />}
                    size={'large'}
                  />
                </div>
                <div className={classes.phone} style={{ backgroundImage: `url(${BusinessProfile})` }}>
                  <div className={classes.wrapper}>
                    <div
                      className={classes.coverImage}
                      style={{
                        backgroundImage: `url(${this._organizationCoverImage ? this._organizationCoverImage.url : ''})`,
                      }}
                    ></div>
                    <div
                      className={classes.profilePic}
                      style={{
                        backgroundImage: `url(${
                          this._organizationLogo ? this._organizationLogo.url : BusinessProfilePic
                        })`,
                      }}
                    ></div>
                    <div className={classes.info}>
                      <div className={classes.organizationName}>
                        {nameMarketing ? nameMarketing : <FormattedMessageWrapper id="tournament.organizationName" />}
                      </div>
                      <div className={classes.subtitle}>{additionalInfo ? additionalInfo : ''}</div>
                      <div className={classes.buttons}>
                        {email && (
                          <Tooltip title={email}>
                            <img src={BusinessProfileEmail} alt="" className={classes.button} />
                          </Tooltip>
                        )}
                        {phone && (
                          <Tooltip title={phone}>
                            <img src={BusinessProfilePhone} alt="" className={classes.button} />
                          </Tooltip>
                        )}
                        {homepage && (
                          <Tooltip title={this._setProtocol(homepage)}>
                            <img src={BusinessProfileWebsite} alt="" className={classes.button} />
                          </Tooltip>
                        )}
                        <img src={BusinessProfileMessage} alt="" className={classes.button} />
                      </div>
                    </div>
                    <div className={classes.fillerBackground}>
                      <img src={BusinessProfileBg} alt="" />
                    </div>
                    <div className={classes.description}>
                      {description ? description.substring(0, 140) + '...' : ''}
                    </div>
                    <div className={classes.fillerBackground2}>
                      <img src={BusinessProfileBg2} alt="" />
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container={true} spacing={5}>
              <Grid item={true} xs={8}>
                <SectionTitle style={{ marginBottom: 20, marginTop: 30 }}>
                  <FormattedMessageWrapper id={'dashboard.profileSettings'} />
                </SectionTitle>

                <Stack gap={2}>
                  <CountryPicker
                    margin={'normal'}
                    variant="standard"
                    required={true}
                    countryId={this.state.countryId || organization.countryId}
                    onChange={this._selectCountry}
                    handleStatesFetch={true}
                  />

                  <CountryStatePicker
                    margin={'normal'}
                    required={false}
                    hideIfEmpty={true}
                    stateId={this.state.stateId || organization.stateId}
                    countryId={this.state.countryId || organization.countryId}
                    onChange={(event) => {
                      this.setState({
                        stateId: parseInt(String(event.target.value), 10),
                      })
                    }}
                  />

                  <FormControl variant="standard" fullWidth={true}>
                    <InputLabel id="timezone">
                      <FormattedMessageWrapper id="dashboard.timeZone" />
                    </InputLabel>
                    <Select
                      id="timezone-select"
                      labelId="timezone"
                      value={this.state.timeZoneId || this.props.orgTimeZoneId}
                      onChange={this.handleTimeZoneChange}
                    >
                      {this.props.timeZones
                        .sort((prev, next) => (prev?.offset && next?.offset ? prev.offset - next.offset : 0))
                        .map((timezone) => (
                          <MenuItem key={timezone.id} value={timezone.id}>
                            {timezone.friendlyName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="standard" margin={'normal'} fullWidth={true}>
                    <InputLabel id="units">
                      <FormattedMessageWrapper id={'tournament.unitsAndTimeFormat'} />
                    </InputLabel>
                    <Select
                      labelId="units"
                      value={units}
                      onChange={(event) => {
                        this.setState({
                          units: event.target.value,
                        })
                      }}
                      name="units"
                      id="units"
                    >
                      <MenuItem value={Units.METRIC}>
                        <FormattedMessageWrapper id={'options.metric'} />
                      </MenuItem>
                      <MenuItem value={Units.US}>
                        <FormattedMessageWrapper id={'options.us'} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

                {showSaveAction && (
                  <BorderButton
                    buttonProps={{
                      type: 'submit',
                      style: {
                        marginTop: 30,
                      },
                    }}
                  >
                    <FormattedMessageWrapper id={'buttons.save'} />
                  </BorderButton>
                )}
              </Grid>
            </Grid>
          </form>
        </div>

        <CropImageDialog
          open={this.state.coverImageCropDialogOpen}
          src={this.state.coverImageInput}
          onClose={this._handleCoverImageCropOnClose}
          crop={this.state.coverImageCrop}
          aspect={2}
        />

        <CropImageDialog
          open={this.state.logoImageCropDialogOpen}
          src={this.state.logoImageInput}
          onClose={this._handleLogoImageCropOnClose}
          crop={this.state.logoImageCrop}
          round={true}
          aspect={1}
        />
      </>
    )
  }

  private get _clubItems() {
    if (!this.props.clubs) {
      return []
    }
    const allClubs = this.props.clubs.map((club) => ({
      value: club.id,
      label: club.name,
    }))

    return [{ label: this.props.intl.formatMessage({ id: 'errors.noClub' }), value: 0 }, ...allClubs]
  }

  private _fetchClubs = debounce(() => {
    const { clubSearchTerm } = this.state

    if (this.props.organization.countryId && clubSearchTerm) {
      const isPublicSearch = true
      this.props.searchClubs(
        { searchTerm: clubSearchTerm, countryId: this.props.organization.countryId },
        isPublicSearch,
      )
    }
  }, 400)

  public _handleCoverImageCropOnClose = (image?: string) => {
    this.setState(
      {
        coverImageInput: undefined,
        coverImageCropDialogOpen: false,
        tempCoverImage: undefined,
        coverImageCrop: defaultCoverImageCrop,
      },
      () => {
        const {
          uploadOrganizationCoverImage,
          organization: { id },
          onEditComplete,
        } = this.props

        if (id && image) {
          uploadOrganizationCoverImage({
            organizationId: id,
            image,
            onComplete: (args: APICallResult) => {
              const url = get(args, 'data.url')
              if (url) {
                this.setState({ tempCoverImage: url })
              }

              if (onEditComplete) {
                onEditComplete(args)
              }
            },
          })
        }
      },
    )
  }

  public _handleLogoImageCropOnClose = (image?: string) => {
    this.setState(
      {
        logoImageInput: undefined,
        logoImageCropDialogOpen: false,
        tempLogo: undefined,
        logoImageCrop: defaultLogoImageCrop,
      },
      () => {
        const {
          uploadOrganizationLogoImage,
          organization: { id },
          onEditComplete,
        } = this.props

        if (id && image) {
          uploadOrganizationLogoImage({
            organizationId: id,
            logoImage: image,
            onComplete: (args: APICallResult) => {
              const url = get(args, 'data.url')
              if (url) {
                this.setState({ tempLogo: url })
              }

              if (onEditComplete) {
                onEditComplete(args)
              }
            },
          })
        }
      },
    )
  }

  get _organizationLogo(): TournamentImage | undefined {
    const { organization } = this.props
    const { tempLogo } = this.state

    if (tempLogo) {
      return {
        id: null,
        url: tempLogo,
      }
    }

    if (organization.logoImageUrl) {
      return {
        id: null,
        url: organization.logoImageUrl,
      }
    }

    return undefined
  }

  get _organizationCoverImage(): TournamentImage | undefined {
    const { organization } = this.props
    const { tempCoverImage } = this.state

    if (tempCoverImage) {
      return {
        id: null,
        url: tempCoverImage,
      }
    }

    if (organization.coverImageUrl) {
      return {
        id: null,
        url: organization.coverImageUrl,
      }
    }

    return undefined
  }

  public _selectCountry = (event) => {
    const countryId = parseInt(event.target.value, 10)
    this.setState({ countryId, stateId: undefined })
  }

  public _onFileInputChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'coverImage') {
      this._onCoverImageChange(e)
      return
    } else if (name === 'logo') {
      this._onLogoImageChange(e)
      return
    }
  }

  public _onCoverImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { files } = e.target

    if (files && files.length > 0) {
      const file = files[0]
      if (file.size > 2000000) {
        this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.tooLargeImageFile' }), 'error')
        return
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          coverImageCropDialogOpen: true,
          coverImageInput: reader.result as string,
        })
      })
      reader.readAsDataURL(file)
    }
  }

  public _onLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { files } = e.target

    if (files && files.length > 0) {
      const file = files[0]
      if (file.size > 2000000) {
        this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.tooLargeImageFile' }), 'error')
        return
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          logoImageCropDialogOpen: true,
          logoImageInput: reader.result as string,
        })
      })
      reader.readAsDataURL(file)
    }
  }

  public _handleUpdateComplete = (args) => {
    const { onEditComplete, checkAuth } = this.props
    onEditComplete && onEditComplete(args)
    // Check auth to update units value
    checkAuth()
  }

  public _handleOrganizationUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { organization, updateOrganization, enableOrganizationNameEdit } = this.props

    const name = organization.name
    const nameOrganization = this.state.nameMarketing
    const phone = this.state.phone
    const email = this.state.email
    const homepage = this.state.homepage
    const additionalInfo = this.state.additionalInfo
    const description = this.state.description
    const units = this.state.units
    const countryId = this.state.countryId || organization.countryId
    const stateId = this.state.stateId || organization.stateId
    const club = this.state.club && this.state.club.value ? this.state.club.value : null
    const timeZoneId = this.state.timeZoneId || this.props.orgTimeZoneId

    const showWarning = showPublicEmailWarning(this.props.organization)
    const emailChanged = this.props.organization.email !== email
    if (showWarning && !emailChanged) {
      const confirmMessage = window.confirm(this.props.intl.formatMessage({ id: 'tournament.publicEmailWarning' }))
      if (!confirmMessage) {
        return false
      }
    }

    const organizationObj = {
      organizationId: organization.id,
      name,
      club,
      nameMarketing: enableOrganizationNameEdit ? nameOrganization : organization.nameMarketing,
      phone,
      email,
      homepage,
      additionalInfo,
      description,
      countryId,
      stateId,
      units,
      timeZoneId,
      onComplete: this._handleUpdateComplete,
    }

    if (organization.id) {
      updateOrganization(organizationObj)
    }

    return true
  }

  private _renderUploadButton = ({
    id,
    name,
    label,
    onChange = this._onFileInputChange(name),
  }: {
    name: string
    id: string
    label: React.ReactNode
    onChange?: any
  }) => (
    <FilePicker name={name} id={id} onChange={onChange} accept="image/*">
      <BorderButton
        buttonProps={{
          component: 'span',
        }}
      >
        {label}
        <CloudUpload style={{ marginLeft: 10 }} />
      </BorderButton>
      <Typography variant="body1" style={{ marginTop: 10 }}>
        <FormattedMessageWrapper id="tournament.profileImagesInstructions" />
      </Typography>
    </FilePicker>
  )

  private _setProtocol = (homepage: string) => {
    if (homepage.search(/^http[s]?:\/\//) === -1) {
      homepage = 'http://' + homepage
    }
    return homepage.replace(/\s+/g, '')
  }
}

export default injectIntl(
  withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, RootState>(
      (store): StateProps => ({
        auth: store.authenticationReducer,
        clubs: selectClubs(store),
        organization: formEditableOrganizationItem(store.organizationReducer),
        timeZones: store.timeZonesReducer.timeZones,
        orgTimeZoneId: store.organizationReducer.addressInfo?.timeZoneId,
      }),
      {
        uploadOrganizationLogoImage,
        updateOrganization,
        uploadOrganizationCoverImage,
        enqueueNotification,
        searchClubs,
        fetchTimeZones,
        checkAuth,
      },
    )(OrganizationProfileManagement),
  ),
)
