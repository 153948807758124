import React, { useMemo, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { MatchPlayBracketPlayerDialog } from '../dialogs/matchPlayBracketPlayerDialog/MatchPlayBracketPlayerDialog'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { useCardStyles } from './cardStyles'

interface Props {
  player: MatchPlayBracketPlayer
  players?: MatchPlayBracketPlayer[]
  editPlayer?: (player: MatchPlayBracketPlayer, deletePlayer?: boolean) => void
}

export const MatchPlayBracketPlayer: React.FC<Props> = ({ player, players, editPlayer }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const classes = useCardStyles()

  const opponent: MatchPlayBracketPlayer | undefined = useMemo(() => {
    const isHomePlayer = player.order % 2 !== 0
    const opponentOrder = isHomePlayer ? player.order + 1 : player.order - 1
    return players
      ?.filter((p: MatchPlayBracketPlayer) => p.stage === player.stage)
      ?.find((p: MatchPlayBracketPlayer) => p.order === opponentOrder)
  }, [player, players])

  const getClasses = (): string => {
    const isLoser = player.winner === false && opponent?.winner === true
    let baseClasses = classes.card

    const isFinalOrBronzeMatch: boolean =
      MatchPlayBracketStages.FINAL === player.stage || MatchPlayBracketStages.BRONZE_MATCH === player.stage

    if (isFinalOrBronzeMatch) {
      baseClasses = `${baseClasses} ${classes.finalOrBronzeCard}`
    }

    if (isLoser && player.stage !== MatchPlayBracketStages.POOL) {
      return `${baseClasses} ${classes.loser}`
    }

    return baseClasses
  }

  const getHcpClasses = (): string => {
    const isLoser = player.winner === false && opponent?.winner === true
    return isLoser && player.stage !== MatchPlayBracketStages.POOL ? `${classes.hcp} loser` : classes.hcp
  }

  return (
    <>
      {dialogOpen && editPlayer && (
        <MatchPlayBracketPlayerDialog player={player} close={() => setDialogOpen(false)} editPlayer={editPlayer} />
      )}
      <div className={getClasses()} data-testid="playerCard">
        <div className={classes.content}>
          <div className={classes.info}>
            <div className={classes.name}>{player?.name}</div>
            <div className={getHcpClasses()}>{player?.hcp}</div>
          </div>
          <div className={classes.right}>
            {player?.score}
            {editPlayer && <EditIcon onClick={() => setDialogOpen(true)} data-testid="editPlayerIcon" />}
          </div>
        </div>
      </div>
    </>
  )
}
