import React from 'react'
import { Grid } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import CustomQuestion from './CustomQuestion'
import { connect } from 'react-redux'
import AddRowAction from '@app/components/ui/AddRowAction'
import {
  addNewCustomQuestion,
  deleteCustomQuestion,
  updateCustomQuestionField,
} from '@store/api/thunks/tournamentSiteThunks'
import { RootState } from '@app/store'
import { getTournamentId } from '@app/store/api/slices/configSlice'

interface OwnProps {
  customQuestions: Question[]
}

interface StateProps {
  tournamentId: number
}

interface DispatchProps {
  addNewCustomQuestion(): void
  updateCustomQuestionField(payload: UpdateRoundFieldPayload): void
  deleteCustomQuestion(id: number): void
}

type Props = OwnProps & StateProps & DispatchProps

class UnconnectedCustomQuestionsBlock extends React.Component<Props> {
  get questionsCount() {
    return this.props.customQuestions.length
  }

  get questions() {
    return this.props.customQuestions.map((question, index) => (
      <CustomQuestion
        key={question.id}
        index={index}
        question={question}
        updateCustomQuestionField={this.props.updateCustomQuestionField}
        deleteCustomQuestion={this._handleDelete}
      />
    ))
  }

  render() {
    return (
      <>
        <Grid container={true} style={{ paddingBottom: 15 }}>
          {this.questions}
        </Grid>
        <AddRowAction
          disabled={this.questionsCount > 4}
          onClick={this.props.addNewCustomQuestion}
          label={<FormattedMessageWrapper id="tournament.addCustomQuestion" />}
        />
      </>
    )
  }

  public _handleDelete = (id: number) => {
    this.props.deleteCustomQuestion(id)
  }
}

const CustomQuestionsBlock = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    tournamentId: getTournamentId(store),
  }),
  {
    addNewCustomQuestion,
    updateCustomQuestionField,
    deleteCustomQuestion,
  },
)(UnconnectedCustomQuestionsBlock)

export default CustomQuestionsBlock
