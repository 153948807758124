import React from 'react'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import HelpOutline from '@mui/icons-material/HelpOutline'
import IconButton from '@mui/material/IconButton'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { rem } from '../../theme/materialUITheme'

const styles = () =>
  createStyles({
    wrap: {
      padding: rem(12),
    },
    title: {
      marginBottom: rem(8),
    },
  })

interface ManagerHelpProps extends WithStyles<typeof styles> {
  titleId?: string
  bodyId?: string
  style?: React.CSSProperties
}

const ManagerHelp = ({ classes, titleId, bodyId, style }: ManagerHelpProps) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null)

  if (titleId === 'strings.otsake' || bodyId === 'strings.asdasd') {
    return null
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <IconButton
        style={style}
        aria-label="Help"
        aria-owns={open ? `manager-help-${bodyId}` : undefined}
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}
        size="large"
      >
        <HelpOutline color={'primary'} />
      </IconButton>

      <Popover
        id={`manager-help-${bodyId}`}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.wrap}>
          {titleId && (
            <Typography variant={'body1'} className={classes.title}>
              <FormattedMessageWrapper id={titleId} />
            </Typography>
          )}
          {bodyId && (
            <Typography variant={'body2'}>
              <FormattedMessageWrapper id={bodyId} />
            </Typography>
          )}
        </div>
      </Popover>
    </>
  )
}

export default withStyles(styles)(ManagerHelp)
