import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'

export const callbackMiddleware: Middleware = () => (next) => (action) => {
  if (isFulfilled(action)) {
    const onSuccessCallback = action.meta?.arg?.originalArgs?.onSuccess
    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }
  if (isRejectedWithValue(action)) {
    const onFailureCallback = action.meta?.arg?.originalArgs?.onFailure
    if (onFailureCallback) {
      onFailureCallback()
    }
  }
  return next(action)
}
