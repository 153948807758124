import {
  AddTempPlayerAction,
  AddTempPlayerFailureAction,
  AddTempPlayerSuccessAction,
  ClearPlayersSearchAction,
  SearchPlayersSuccessAction,
  UpdatePlayersFieldAction,
  SearchOrganizationPlayersAction,
  SearchOrganizationPlayersSuccessAction,
  SearchOrganizationPlayersFailureAction,
} from './actions'
import {
  ADD_TEMP_PLAYER,
  ADD_TEMP_PLAYER_FAILURE,
  ADD_TEMP_PLAYER_SUCCESS,
  CLEAR_PLAYERS_SEARCH,
  PLAYERS_SEARCH_SUCCESS,
  UPDATE_PLAYERS_FIELD,
  PLAYERS_SEARCH_ORGANIZATION,
  PLAYERS_SEARCH_ORGANIZATION_SUCCESS,
  PLAYERS_SEARCH_ORGANIZATION_FAILURE,
} from './constants'

const initialState: PlayersState = {
  useFilters: false,
  searchCountryId: undefined,
  searchTerm: '',
  firstName: '',
  lastName: '',
  golferId: '',
  searchResults: [],
  followers: [],
  error: null,
  loading: false,
  clubId: undefined,
}

type PlayersActions =
  | SearchPlayersSuccessAction
  | UpdatePlayersFieldAction
  | AddTempPlayerAction
  | AddTempPlayerSuccessAction
  | AddTempPlayerFailureAction
  | ClearPlayersSearchAction
  | SearchOrganizationPlayersAction
  | SearchOrganizationPlayersSuccessAction
  | SearchOrganizationPlayersFailureAction

const playersReducer = (state: PlayersState = initialState, action: PlayersActions) => {
  switch (action.type) {
    case ADD_TEMP_PLAYER:
    case PLAYERS_SEARCH_ORGANIZATION:
      return {
        ...state,
        loading: true,
      }
    case ADD_TEMP_PLAYER_SUCCESS:
    case ADD_TEMP_PLAYER_FAILURE:
    case PLAYERS_SEARCH_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_PLAYERS_FIELD:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      }
    case PLAYERS_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.players,
      }
    case PLAYERS_SEARCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        searchResults: action.players,
        clubId: action.clubId,
        loading: false,
      }
    case CLEAR_PLAYERS_SEARCH:
      return {
        ...state,
        searchResults: [],
        searchTerm: '',
        firstName: '',
        lastName: '',
        golferId: '',
        useFilters: false,
      }
    default:
      return state
  }
}

export { playersReducer }
