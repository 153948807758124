import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import ButtonLoaderWrap from '@app/components/ui/ButtonLoaderWrap'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { OverlayLoader } from '@app/components/ui/OverlayLoader'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { ResultsPrintout, ResultsPrintoutProps, StartListPrintout, StartListPrintoutProps } from './printouts'
import { Font, PDFViewer } from '@react-pdf/renderer'
import Roboto400 from '@assets/fonts/roboto/Roboto-Regular.ttf'
import Roboto700 from '@assets/fonts/roboto/Roboto-Bold.ttf'
import Roboto700Italic from '@assets/fonts/roboto/Roboto-BoldItalic.ttf'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { PrintoutDialogOptions, PrintoutOptions } from './PrintoutOptions'
import { GameFormatTypes, TournamentTypes, ContestResultType, Units } from '@app/store/api/enums/tournamentEnums'
import { ContestsPrintout, ContestsPrintoutProps, PrintoutContests } from './printouts/contests-printout'
import { setSelectedDivision } from '@store/divisions/actions'
import { CartSignsPrintout, CartSignsPrintoutProps } from './printouts/cart-signs-printout'
import { loadTournamentContestLeaderboard } from '@app/store/tournamentLeaderboard/actions'
import { formatDate } from '@app/utils/dates'
import { fetchAllStartLists } from '@app/store/api/thunks/tournamentStartListsThunks'
import { RootState, useAppDispatch } from '@app/store'
import { useLazyGetPlayersQuery } from '@app/store/api/endpoints/tournamentPlayersApi'
import { useLazyGetTournamentSiteQuery } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'

export interface PrintoutsProps {
  onClose: () => void
  tournament: TournamentState
  defaultPrintoutType: PrintoutDialogOptions['printoutType']
}

Font.register({
  family: 'Roboto',
  fonts: [
    { src: Roboto400, fontStyle: 'normal', fontWeight: 400 },
    { src: Roboto700, fontStyle: 'normal', fontWeight: 700 },
    { src: Roboto700Italic, fontStyle: 'italic', fontWeight: 700 },
  ],
})

/**
 * Dialog for printouts. Visible by default and should
 * be rendered only when wanted to be visible.
 *
 * @param props
 * @returns
 */
export const PrintoutsDialog: React.FC<PrintoutsProps> = (props) => {
  const [isLoading, setLoading] = useState(true)
  const [printoutOptions, setPrintoutOptions] = useState<PrintoutDialogOptions>({
    printoutType: props.defaultPrintoutType,
    gameType: GameFormatTypes.PRIMARY,
    divisionId: 0,
    roundIndex: 0,
  })

  const dispatch = useAppDispatch()

  const [getTournamentPlayers, { data: playersResponse }] = useLazyGetPlayersQuery()
  const [getTournamentSite, { data: tournamentSite }] = useLazyGetTournamentSiteQuery()
  const { players } = playersResponse || { players: [] }
  const startLists = useSelector(selectTournamentStartLists)
  const units = useSelector((state: RootState) => state.authenticationReducer.units)
  const leaderboard = useSelector((state: RootState) => state.tournamentLeaderboardReducer)
  const contests = useSelector((state: RootState) => state.tournamentLeaderboardReducer.contestLeaderboard)
  const divisions = useSelector((state: RootState) => state.divisionsReducer)
  const divisionLeaderboard = useSelector((state: RootState) => state.tournamentDivisionLeaderboardReducer)
  const { isTournamentLoading, isTournamentStartListsLoading } = useSelector(selectTournamentConfig).status

  useEffect(() => {
    if (props.tournament.id) {
      const preferCacheValue = true
      dispatch(fetchAllStartLists())
      getTournamentPlayers(props.tournament.id, preferCacheValue)
      getTournamentSite(props.tournament.id, preferCacheValue)
      if (props.tournament.tournamentType !== TournamentTypes.weekly) {
        dispatch(loadTournamentContestLeaderboard(props.tournament.id))
      }
    }
  }, [dispatch, props.tournament.id, props.tournament.tournamentType])

  useEffect(() => {
    if (isTournamentLoading === false && isTournamentStartListsLoading === false) {
      setLoading(false)
    }
  }, [isTournamentLoading, isTournamentStartListsLoading])

  const intl = useIntl()

  const closeDialog = () => {
    if (props.onClose) {
      props.onClose()

      // Reset division id
      dispatch(setSelectedDivision(0))
    }
  }

  const onCloseClick = () => {
    closeDialog()
  }

  const onPrintClick = () => {
    window.frames[1].print()
  }

  const getStartListPrintoutProps = () => {
    const { tournament } = props
    const { rounds } = tournament
    const { roundIndex } = printoutOptions
    const mappedGroups = startLists.rounds[roundIndex].groups.map((group) => {
      const startListPlayers = group.startListPlayers.map((player) => {
        const tournamentPlayer = players.find((row) => row.id === player.tournamentPlayerId)
        return {
          ...player,
          firstName: tournamentPlayer?.firstName || '',
          lastName: tournamentPlayer?.lastName || '',
        }
      })
      return { ...group, startListPlayers }
    })

    const round = tournament.rounds[roundIndex]

    const printoutProps: StartListPrintoutProps = {
      tournamentName: tournament.rounds.length > 1 ? `${tournament.name} - R${roundIndex + 1}` : tournament.name,
      clubName: round.club?.name || '',
      courseName: round.course?.courseName || '',
      startListGroups: mappedGroups,
      startTime: round.startTime,
      units,
      rounds,
      roundIndex,
    }

    return printoutProps
  }

  const getResultsPrintoutProps = () => {
    const { tournament } = props
    const { rounds } = tournament
    const { roundIndex } = printoutOptions

    const selectedLeaderboard = ():
      | TournamentLeaderboardPrimaryGame
      | TournamentLeaderboardSecondaryGame
      | undefined => {
      const lb = divisions.selectedDivisionId ? divisionLeaderboard : leaderboard
      return printoutOptions.gameType === GameFormatTypes.SIDE ? lb.data?.secondary : lb.data?.primary
    }

    const printoutProps: ResultsPrintoutProps = {
      tournamentName: tournament.name,
      rounds,
      units,
      gameType: printoutOptions.gameType,
      leaderboardRows: selectedLeaderboard()?.players || [],
      divisions,
      roundIndex,
    }

    return printoutProps
  }

  const getCartSignsPrintoutProps = () => {
    const { tournament } = props
    const { roundIndex } = printoutOptions
    const mappedGroups = startLists.rounds[roundIndex].groups.map((group) => {
      const startListPlayers = group.startListPlayers.map((player) => {
        const tournamentPlayer = players.find((row) => row.id === player.tournamentPlayerId)
        return {
          ...player,
          firstName: tournamentPlayer?.firstName || '',
          lastName: tournamentPlayer?.lastName || '',
        }
      })
      return { ...group, startListPlayers }
    })

    const round = tournament.rounds[roundIndex]

    const printoutProps: CartSignsPrintoutProps = {
      tournamentName: tournament.rounds.length > 1 ? `${tournament.name} - R${roundIndex + 1}` : tournament.name,
      clubName: round.club?.name || '',
      courseName: round.course?.courseName || '',
      startListGroups: mappedGroups,
      logoUrl: tournamentSite?.images.logoImage?.url,
    }

    return printoutProps
  }

  const contestsPrintoutProps = useMemo(() => {
    const { roundIndex } = printoutOptions

    const getContestName = (type: ContestResultType) => {
      if (type === ContestResultType.CLOSEST_TO_PIN) {
        return intl.formatMessage({ id: 'tournament.closestToPin' })
      } else if (type === ContestResultType.LONGEST_DRIVE) {
        return intl.formatMessage({ id: 'tournament.longestDrive' })
      } else if (type === ContestResultType.STRAIGHTEST_DRIVE) {
        return intl.formatMessage({ id: 'tournament.straightestDrive' })
      } else {
        return ''
      }
    }

    const mapContest = (contest) => ({
      name: getContestName(contest.type),
      holes: [
        {
          holeNumber: contest.holeNumber.toString(),
          results: contest.entries
            .filter((v) => {
              return v.score !== undefined
            })
            .map((entry) => {
              return {
                position: entry.positionString,
                playerName: `${entry.user.firstName} ${entry.user.lastName}`,
                measurements: contest.measured ? entry.score : '',
                units: props.tournament.tournamentOrganization?.units || Units.METRIC,
              }
            }),
        },
      ],
    })

    const mappedContests: PrintoutContests = contests?.data?.rounds[roundIndex]
      ? contests.data.rounds[roundIndex].contests.map((contest) => mapContest(contest))
      : []

    const round = props.tournament?.rounds[0]
    const courseName = round?.course?.courseName
    const clubName = round?.club?.name
    const printoutProps: ContestsPrintoutProps = {
      units,
      startDate: round ? formatDate(round.startTime, 'date', units) : '',
      clubAndCourse: clubName && courseName ? `${clubName} - ${courseName}` : clubName || courseName || '',
      contests: mappedContests.filter((row) => row.holes.some((hole) => hole.results.length > 0)),
      tournamentName: props.tournament?.name,
    }

    return printoutProps
  }, [
    printoutOptions,
    contests?.data.rounds,
    props.tournament?.rounds,
    props.tournament?.name,
    props.tournament.tournamentOrganization?.units,
    units,
    intl,
  ])

  const renderPrintoutContent = () => {
    switch (printoutOptions.printoutType) {
      case 'STARTLIST': {
        return <StartListPrintout {...getStartListPrintoutProps()} />
      }
      case 'RESULTS': {
        return <ResultsPrintout {...getResultsPrintoutProps()} />
      }
      case 'CONTESTS': {
        return <ContestsPrintout {...contestsPrintoutProps} />
      }
      case 'CART_SIGNS': {
        return <CartSignsPrintout {...getCartSignsPrintoutProps()} />
      }
    }
  }

  const onDialogOptionsChange = useCallback(
    (options: PrintoutDialogOptions) => {
      setPrintoutOptions(options)
    },
    [setPrintoutOptions],
  )

  return (
    <>
      <Dialog open={true} onClose={closeDialog} maxWidth={false}>
        <BaseDialogTitle
          style={{ marginBottom: 0 }}
          id={'printouts-dialog'}
          title={<FormattedMessageWrapper id={'tournament.tournamentPrintouts'} />}
          onClose={onCloseClick}
        />

        <DialogContent style={{ margin: 0, padding: 0, minWidth: '800px' }}>
          {!isLoading && (
            <>
              <PrintoutOptions options={printoutOptions} onChange={onDialogOptionsChange} />
              <PDFViewer style={{ width: '60vw', height: '60vh' }}>{renderPrintoutContent()}</PDFViewer>
            </>
          )}
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onCloseClick}>
            <FormattedMessageWrapper id={'buttons.cancel'} />
          </Button>
          <ButtonLoaderWrap loading={isLoading}>
            <Button disabled={isLoading} onClick={onPrintClick} color="primary" variant={'contained'}>
              <FormattedMessageWrapper id={'buttons.print'} />
            </Button>
          </ButtonLoaderWrap>
        </DialogActions>
      </Dialog>
      <OverlayLoader visible={isLoading} />
    </>
  )
}
