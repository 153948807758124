import { cloneDeep } from 'lodash'

export const handleOnDragStart = () => {
  // document.getElementsByTagName('html')[0].style.overflowX = 'hidden';
  // document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
}

export const handleOnDragEnd = () => {
  // document.getElementsByTagName('html')[0].style.overflowX = null;
  // document.getElementsByTagName('body')[0].style.overflowX = null;
}

export const arrayMove = (array: any, oldIndex: number, newIndex: number) => {
  const clonedArray = cloneDeep(array)
  clonedArray.splice(newIndex, 0, clonedArray.splice(oldIndex, 1)[0])
  return clonedArray
}
