import get from 'lodash/get'
import { FI_COUNTRY_ID, SV_COUNTRY_ID } from '../config'

export type EditableOrganizationItem = Pick<
  OrganizationState,
  | 'id'
  | 'name'
  | 'nameMarketing'
  | 'email'
  | 'phone'
  | 'homepage'
  | 'additionalInfo'
  | 'description'
  | 'logoImageUrl'
  | 'coverImageUrl'
  | 'countryId'
  | 'stateId'
  | 'businessId'
  | 'streetAddress1'
  | 'streetAddress2'
  | 'zip'
  | 'city'
  | 'poBox'
  | 'region'
  | 'country'
  | 'club'
  | 'createdAt'
  | 'updatedAt'
  | 'units'
>

const isOrganizationsItem = (arg: Organization | OrganizationState | OrganizationsItem): arg is OrganizationsItem => {
  return Object.prototype.hasOwnProperty.call(arg, 'idCustomer')
}

export const formEditableOrganizationItem = (organization?: Organization): EditableOrganizationItem => {
  if (organization && isOrganizationsItem(organization)) {
    return {
      id: organization.idCustomer,
      name: organization.nameCompany,
      nameMarketing: organization.nameMarketing,
      email: get(organization, 'addressInfo.email1'),
      phone: get(organization, 'addressInfo.phone1'),
      homepage: get(organization, 'addressInfo.homepage'),
      additionalInfo: organization.additionalInfo,
      description: organization.description,
      logoImageUrl: organization.logoImageUrl,
      coverImageUrl: organization.coverImageUrl,
      countryId: organization.countryId,
      stateId: organization.stateId,
      businessId: organization.businessId,
      streetAddress1: organization.streetAddress1,
      streetAddress2: organization.streetAddress2,
      zip: organization.zip,
      city: organization.city,
      region: organization.region,
      poBox: organization.poBox,
      country: organization.country,
      club: organization.club,
      createdAt: organization.timeCreated,
      updatedAt: organization.timeUpdated,
      units: organization.units,
    }
  }

  return {
    id: get(organization, 'id') || -1,
    name: get(organization, 'name') || '',
    nameMarketing: get(organization, 'nameMarketing') || '',
    email: get(organization, 'email') || '',
    phone: get(organization, 'phone') || '',
    homepage: get(organization, 'homepage') || '',
    additionalInfo: get(organization, 'additionalInfo') || '',
    description: get(organization, 'description') || '',
    logoImageUrl: get(organization, 'logoImageUrl') || '',
    coverImageUrl: get(organization, 'coverImageUrl') || '',
    countryId: get(organization, 'countryId') || -1,
    stateId: get(organization, 'stateId'),
    businessId: get(organization, 'businessId') || '',
    streetAddress1: get(organization, 'streetAddress1') || '',
    streetAddress2: get(organization, 'streetAddress2') || '',
    zip: get(organization, 'zip') || '',
    city: get(organization, 'city') || '',
    region: get(organization, 'region') || '',
    country: get(organization, 'country') || '',
    poBox: get(organization, 'poBox') || '',
    club: get(organization, 'club') || undefined,
    createdAt: get(organization, 'createdAt') || '',
    updatedAt: get(organization, 'updatedAt') || '',
    units: get(organization, 'units') || 'metric',
  }
}

export const showPublicEmailWarning = (organization?: Partial<OrganizationState>) => {
  if (!organization) {
    return false
  }

  if (organization.createdAt !== '' && organization.updatedAt === organization.createdAt) {
    return true
  }

  return false
}

/**
 * Check if organisation is a club and the club is assigned to a specific country.
 * TODO: refactor to loop through countries if more countries are added, maybe change name to something better.
 * @param organization
 * @returns boolean
 */
export const isCountryWithPlayerRegistry = (organization?: OrganizationState | Organization) => {
  if (!organization) {
    return false
  }

  const { club } = organization

  if (!club || !club.id) {
    return false
  }

  if (club.countryId === FI_COUNTRY_ID || club.countryId === SV_COUNTRY_ID) {
    return true
  }

  return false
}

export const licenseName = 'TM_SINGLE_LICENSE'
export const productName = 'TM_FULL'

export const navigateToOrganizationUrl = (id: number) => `/organizations/${id}`
export const navigateToOrganizationTournamentUrl = (id: number) => `/organizations/${id}/tournaments`
