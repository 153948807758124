/*
  localStorageControl for language
 */
import { LOCAL_STORAGE_LANG_KEY } from '../../config'

export const storeSelectedLanguage = (lang: string): void => {
  try {
    window.localStorage.setItem(LOCAL_STORAGE_LANG_KEY, lang)
  } catch (e) {
    window.console.warn('could not store lang')
  }
}

export const getSelectedLanguageFromStorage = (): string | null => {
  try {
    return window.localStorage.getItem(LOCAL_STORAGE_LANG_KEY)
  } catch (e) {
    window.console.warn('could not store lang')
  }

  return null
}
