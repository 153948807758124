import React from 'react'
import { FormControl, FormLabel, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { countriesApi, selectCountryStates } from '@app/store/api/endpoints/countriesApi'
import { RootState } from '@app/store'

interface OwnProps {
  error?: boolean
  required?: boolean
  showLabel?: boolean
  countryId?: number
  stateId?: number
  hideIfEmpty?: boolean
  margin?: 'normal' | 'none' | 'dense' | undefined
  onChange?: (event: SelectChangeEvent<number>) => void
}

interface StateProps {
  states: CountryState[]
  statesLoading: boolean
}

interface DispatchProps {
  fetchCountryStates(countryId: number): void
}

type Props = OwnProps & StateProps & DispatchProps

class CountryStatePicker extends React.Component<Props> {
  render() {
    const {
      error,
      showLabel = false,
      stateId = '',
      required,
      onChange,
      states = [],
      hideIfEmpty = false,
      margin = 'none',
    } = this.props

    if (hideIfEmpty && !states.length) {
      return null
    }

    return (
      <div>
        {showLabel && (
          <FormLabel required={required} error={error}>
            <FormattedMessageWrapper id="tournament.chooseState" />
          </FormLabel>
        )}
        <FormControl
          variant="standard"
          margin={margin}
          fullWidth={true}
          required={required}
          error={error}
          disabled={!states.length}
        >
          <InputLabel id="tournament.countryState.label" disabled={!states.length}>
            <FormattedMessageWrapper id="tournament.countryState" />
          </InputLabel>
          <Select
            labelId="tournament.countryState.label"
            disabled={!states.length}
            error={error}
            required={required}
            value={stateId}
            onChange={onChange}
            inputProps={{
              name: 'country-state-picker',
              id: 'country-state-picker',
            }}
          >
            {this._renderCountryStateItems()}
          </Select>
        </FormControl>
      </div>
    )
  }

  private _renderCountryStateItems = () => {
    const { states = [] } = this.props

    return states.map((state) => (
      <MenuItem key={state.id} value={state.id}>
        {state.stateName}
      </MenuItem>
    ))
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state, props): StateProps => {
    const countryId = props.countryId || 0
    return {
      states: selectCountryStates(state, countryId).data || [],
      statesLoading: selectCountryStates(state, countryId).isLoading,
    }
  },
  {
    fetchCountryStates: countriesApi.endpoints.getCountryStates.initiate,
  },
)(CountryStatePicker)
