import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { IconButton, Grid, Input, CircularProgress } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'
import { OverlayLoader } from '@components/ui/OverlayLoader'
import { makeStyles } from '@mui/styles'
import { fetchOrganizationTournaments } from '@app/store/organizations/actions'
import { pickBy } from 'lodash'
import { DateType, SortType } from '@app/store/api/enums/tournamentEnums'
import { InternalRoute } from '@config/index'
import TournamentsTable from '@components/dashboard/TournamentsTable'
import HeadHelmet from '@components/layout/HeadHelmet'
import queryString from 'query-string'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  label: {
    lineHeight: 'initial',
    fontSize: 'initial',
    backgroundColor: 'white',
  },
  searchContainer: {
    display: 'flex',
    marginBottom: 30,
    justifyContent: 'flex-end',
  },
  inputContainer: {
    flexBasis: 305,
    marginRight: 14,
    alignSelf: 'center',
  },
  searchButtonContainer: {
    alignSelf: 'flex-end',
  },
  formContainer: {
    width: 140,
    marginLeft: 10,
  },
}))

export const OrganizationTournaments = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const [page, setPage] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sortBy, setSortBy] = useState<TournamentSortOption>('year')
  const [direction, setDirection] = useState<SortType>(SortType.desc)
  const [update, updated] = useState<boolean>()

  const organization = useSelector((store: RootState) => store.organizationReducer)
  const { loading, organizationTournaments } = useSelector((store: RootState) => store.organizationsReducer)

  const triggerFetch = useCallback(() => {
    const paramPage = page === 0 ? undefined : page
    dispatch(fetchOrganizationTournaments(searchTerm, sortBy, direction, paramPage, handleResponse))
  }, [page, sortBy, searchTerm, direction, dispatch])

  const handleResponse = ({ data, error }: APICallResult) => {
    if (!error) {
      setTotalCount(Number(data.totalCount))
    }
    updated(false)
  }

  useEffect(() => {
    const params = queryString.parse(location.search)
    const paramPage = params.page ? parseInt(params.page as string, 10) : 1

    if (paramPage !== page) {
      setPage(paramPage)
      triggerFetch()
    }
  }, [page, location.search, triggerFetch])

  useEffect(() => {
    if (update) {
      triggerFetch()
    }
  }, [sortBy, direction, update, triggerFetch])

  const searchTournaments = (e?: React.FormEvent<HTMLFormElement>, page?: number) => {
    e?.preventDefault()

    const params = queryString.parse(location.search)
    const searchParams = queryString.stringify(
      pickBy({
        ...params,
        searchTerm,
        page: page ? page + 1 : 1,
      }),
    )
    navigate(`${location.pathname}?${searchParams}`, { replace: true })
    updated(true)
  }

  const onSearchTermChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.currentTarget
    setSearchTerm(value)
  }

  const onClickClear = () => {
    setSearchTerm('')
    navigate(InternalRoute.ORGANIZATIONS, { replace: true })
    dispatch(fetchOrganizationTournaments('', sortBy, direction, 1, handleResponse))
  }

  const handleSortChange = (_, property) => {
    const { asc, desc } = SortType
    const isDesc = sortBy === property && direction === desc
    const newDirection = isDesc ? asc : desc

    setSortBy(property)
    setDirection(newDirection)
    updated(true)
  }

  const getSearch = () => (
    <form onSubmit={searchTournaments}>
      <Grid item={true} xs={12} xl={12}>
        <div className={classes.searchContainer}>
          <div className={classes.inputContainer}>
            <Input
              autoComplete={'off'}
              disabled={loading}
              value={searchTerm}
              fullWidth={true}
              name="searchTerm"
              placeholder={intl.formatMessage({ id: 'tournaments.searchTournaments' })}
              inputProps={{
                'aria-label': 'Description',
              }}
              onChange={(e) => onSearchTermChange(e)}
            />
          </div>
          <div className={classes.searchButtonContainer}>
            <IconButton disabled={loading || searchTerm === ''} type="submit" color="primary" size="large">
              {!loading && <Search />}
              {loading && <CircularProgress size={24} />}
            </IconButton>
            {searchTerm !== '' && (
              <IconButton onClick={() => onClickClear()} size="large">
                <Clear />
              </IconButton>
            )}
          </div>
        </div>
      </Grid>
    </form>
  )

  const renderTournaments = () => (
    <>
      {getSearch()}
      {organizationTournaments?.tournaments && (
        <TournamentsTable
          page={page - 1}
          sortBy={sortBy}
          direction={direction}
          totalCount={totalCount}
          tournaments={organizationTournaments?.tournaments}
          triggerFetch={triggerFetch}
          onChangePage={(pageNum) => searchTournaments(undefined, pageNum)}
          showCalendarCheckbox={false}
          dateType={DateType.createdAt}
          onRequestSort={handleSortChange}
        />
      )}
    </>
  )
  return (
    <>
      <HeadHelmet titleId={'navigation.tournaments'} />
      <OverlayLoader visible={loading || organization.loading} />
      {renderTournaments()}
    </>
  )
}
