import { all, takeEvery, put } from 'redux-saga/effects'
import { GAME_FORMATS_FETCH, GAME_FORMATS_FETCH_FAILURE, GAME_FORMATS_FETCH_SUCCESS } from './constants'
import { getToken } from '../authentication/sagas'
import { API_ROOT, APIRoute } from '../../config'
import { apiClientRequest } from '../../utils/sagaHelpers'
import { getGameFormatPrimaryIdMap, replaceAllowedSideGamePrimaryIds } from '../../utils/gameFormatUtils'
import { enqueueNotification } from '../notifications/actions'

function* doFetchGameFormats() {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_GAME_FORMATS}`,
      method: 'get',
      token,
    })

    const primaryIdMap = getGameFormatPrimaryIdMap(res.data.formats)
    const gameFormats = replaceAllowedSideGamePrimaryIds(res.data.formats, primaryIdMap)

    yield put({
      type: GAME_FORMATS_FETCH_SUCCESS,
      gameFormats,
      gameFormatAdditionalOptions: res.data.additionalOptions,
      gameFormatPrimaryIdMap: primaryIdMap,
      defaultPerPlayerHandicapPercentages: res.data.defaultPerPlayerHandicapPercentages,
    })
  } catch (error: any) {
    yield put({
      type: GAME_FORMATS_FETCH_FAILURE,
      error: error.toString(),
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchGameFormats() {
  yield all([takeEvery(GAME_FORMATS_FETCH, doFetchGameFormats)])
}
