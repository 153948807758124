export const getNextUrl = (currentUrl: string, nextPathPath: string) => {
  const parts = currentUrl.split('/')
  parts.pop()
  parts.push(nextPathPath)

  return parts.join('/')
}

export const getPathPart = (currentUrl: string) => {
  const parts = currentUrl.split('/')
  return parts.pop()
}
