export const FETCH_ORGANIZATION_PRODUCTS = 'FETCH_ORGANIZATION_PRODUCTS'
export const FETCH_ORGANIZATION_PRODUCTS_SUCCESS = 'FETCH_ORGANIZATION_PRODUCTS_SUCCESS'
export const FETCH_ORGANIZATION_PRODUCTS_FAILURE = 'FETCH_ORGANIZATION_PRODUCTS_FAILURE'

export const CREATE_NEW_ORGANIZATION_PRODUCT = 'CREATE_NEW_ORGANIZATION_PRODUCT'
export const CREATE_NEW_ORGANIZATION_PRODUCT_SUCCESS = 'CREATE_NEW_ORGANIZATION_PRODUCT_SUCCESS'
export const CREATE_NEW_ORGANIZATION_PRODUCT_FAILURE = 'CREATE_NEW_ORGANIZATION_PRODUCT_FAILURE'

export const EDIT_ORGANIZATION_PRODUCT = 'EDIT_ORGANIZATION_PRODUCT'
export const EDIT_ORGANIZATION_PRODUCT_SUCCESS = 'EDIT_ORGANIZATION_PRODUCT_SUCCESS'
export const EDIT_ORGANIZATION_PRODUCT_FAILURE = 'EDIT_ORGANIZATION_PRODUCT_FAILURE'

export const DELETE_ORGANIZATION_PRODUCT = 'DELETE_ORGANIZATION_PRODUCT'
export const DELETE_ORGANIZATION_PRODUCT_SUCCESS = 'DELETE_ORGANIZATION_PRODUCT_SUCCESS'
export const DELETE_ORGANIZATION_PRODUCT_FAILURE = 'DELETE_ORGANIZATION_PRODUCT_FAILURE'

export const FETCH_TOURNAMENT_PRODUCTS = 'FETCH_TOURNAMENT_PRODUCTS'
export const FETCH_TOURNAMENT_PRODUCTS_SUCCESS = 'FETCH_TOURNAMENT_PRODUCTS_SUCCESS'
export const FETCH_TOURNAMENT_PRODUCTS_FAILURE = 'FETCH_TOURNAMENT_PRODUCTS_FAILURE'

export const EDIT_TOURNAMENT_PRODUCTS = 'EDIT_TOURNAMENT_PRODUCTS'
export const EDIT_TOURNAMENT_PRODUCTS_SUCCESS = 'EDIT_TOURNAMENT_PRODUCTS_SUCCESS'
export const EDIT_TOURNAMENT_PRODUCTS_FAILURE = 'EDIT_TOURNAMENT_PRODUCTS_FAILURE'

export const FETCH_REPORT = 'FETCH_REPORT'
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS'
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE'
