import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { formatHandicap } from '@app/utils/playerUtils'
import { formatDate } from '@app/utils/dates'
import { TableHeader, TableHeaderProps, TableRow, TableRowProps, Footer } from '../../elements'
import { isSpecialTeamFormatId } from '@utils/gameFormatUtils'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '16pt',
  },
  header: {
    flexDirection: 'row',
    margin: 0,
  },
  headerLeft: {
    flex: 0.7,
  },
  headerRight: {
    flex: 0.3,
  },
  h1: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16pt',
  },
  h2: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14pt',
  },
  p: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12pt',
    marginTop: '4pt',
  },
  teamName: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontStyle: 'italic',
    fontSize: '12pt',
    marginTop: '5pt',
  },
  content: {
    flex: 1,
    flexDirection: 'column',
  },
})

export interface StartListPrintoutProps {
  units: OrganizationUnits
  tournamentName: string
  startTime: Date
  clubName: string
  courseName: string
  startListGroups: StartListGroup[]
  rounds: Round[]
  roundIndex: number
}

// Create Document Component
export const StartListPrintout = ({
  startListGroups,
  clubName,
  courseName,
  units,
  tournamentName,
  startTime,
  rounds,
  roundIndex,
}: StartListPrintoutProps) => {
  const renderStartListGroup = (group: StartListGroup) => {
    const headerItems: TableHeaderProps['columns'] = [
      { title: group.name, width: 0.25 },
      { title: 'HCP (PHCP)', width: 0.25 },
      { title: 'Tee', width: 0.2 },
      { title: 'Start hole', width: 0.15 },
      { title: 'Start time', width: 0.15 },
    ]

    const renderPlayerRow = (player: StartListPlayer, groupStartTime: Date, team?: StartListGroupTeam) => {
      const playerName = `${player.firstName} ${player.lastName}`
      const hcp = isSpecialTeamFormatId(rounds[roundIndex]?.primaryGameId || 0)
        ? team?.primaryPlayingHcp
        : player.playingHandicap
      const fomattedHcp = `${formatHandicap(String(player.hcp))} (${formatHandicap(String(hcp))})`
      const teeboxName = player.teeBox?.teeboxName || ''
      const formattedStartTime = formatDate(groupStartTime, 'time', 'metric')

      const fields: TableRowProps['fields'] = [
        { value: playerName, width: 0.25 },
        { value: fomattedHcp, width: 0.25 },
        { value: teeboxName, width: 0.2 },
        { value: group.teeNumber, width: 0.15 },
        { value: formattedStartTime, width: 0.15 },
      ]
      return <TableRow key={player.id} fields={fields} />
    }

    const renderRows = () => {
      const orderedStartListPlayers = group.startListPlayers.sort((a: StartListPlayer, b: StartListPlayer) => {
        return a.groupOrder - b.groupOrder
      })
      return orderedStartListPlayers?.map((player) => renderPlayerRow(player, group.startTime))
    }

    const renderTeamRows = () => {
      return group.teams.map((team, idx) => {
        const orderedTeamPlayers = team.players.slice().sort((a: TournamentPlayer, b: TournamentPlayer) => {
          if (a.teamPlayerOrder && b.teamPlayerOrder) {
            return a.teamPlayerOrder - b.teamPlayerOrder
          }
          return 0
        })
        return (
          <View
            key={team.tournamentTeamId}
            wrap={false}
            style={{
              paddingLeft: '5pt',
              paddingBottom: '5pt',
              borderBottom: idx + 1 < group.teams.length ? '1pt solid #e3e3e3' : undefined,
            }}
          >
            <Text style={styles.teamName}>{team.name}</Text>
            <View>
              {orderedTeamPlayers.map((player) => {
                const startListPlayer = group.startListPlayers.find((row) => row.tournamentPlayerId === player.id)
                if (startListPlayer) {
                  return renderPlayerRow(startListPlayer, group.startTime, team)
                }
                return undefined
              })}
            </View>
          </View>
        )
      })
    }

    // wrap=false makes sure that group doesn't break in middle of page change
    return (
      <View key={group.id} wrap={false}>
        <TableHeader columns={headerItems} style={{ marginTop: '15pt' }} />
        {group.teams.length > 0 ? renderTeamRows() : renderRows()}
      </View>
    )
  }

  const clubAndCourse = `${clubName} - ${courseName}`
  const tournamentStartTime = formatDate(startTime, 'date', units)

  return (
    <Document title={tournamentName}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View style={styles.headerLeft}>
            <Text style={styles.h1}>{tournamentName}</Text>
            <Text style={styles.p}>{clubAndCourse}</Text>
          </View>
          <View style={styles.headerRight}>
            <Text
              style={styles.h2}
              render={({ pageNumber, totalPages }) => `Start list (${pageNumber}/${totalPages})`}
            />
            <Text style={styles.p}>{tournamentStartTime}</Text>
          </View>
        </View>
        <View style={styles.content}>{startListGroups.map((group) => renderStartListGroup(group))}</View>
        <Footer />
      </Page>
    </Document>
  )
}
