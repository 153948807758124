import { useSelector } from 'react-redux'
import BorderButton from '../ui/BorderButton'
import { FormattedMessageWrapper } from '../ui/FormattedMessageWrapper'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import makeStyles from '@mui/styles/makeStyles'
import { useIntl } from 'react-intl'
import { formatDate } from '@app/utils/dates'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  updatedTimestamp: {
    marginTop: -23,
    marginLeft: 8,
    paddingBottom: 5,
    fontSize: 12,
    fontWeight: 500,
    color: '#747474',
    textAlign: 'center',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}))

interface Props {
  disabled?: boolean
  onClick?(): void
  style?: React.CSSProperties
}

export const UpdateHandicapsButton: React.FC<Props> = ({ disabled, onClick, style = {} }) => {
  const intl = useIntl()
  const classes = useStyles()
  const tournament = useSelector(selectTournament)
  const { units } = useSelector((state: RootState) => state.authenticationReducer)
  const { handicapsUpdatedAt } = tournament

  const getUpdatedAtLabel = () => {
    return intl.formatMessage({ id: 'tourAndRanking.updatedAt' }).toLowerCase()
  }

  return (
    <>
      {handicapsUpdatedAt && (
        <div className={classes.updatedTimestamp}>
          {getUpdatedAtLabel()} {formatDate(handicapsUpdatedAt, 'datetime', units)}
        </div>
      )}
      <BorderButton
        fgu
        filled
        buttonProps={{
          disabled,
          onClick,
          style: {
            ...style,
          },
        }}
      >
        <FormattedMessageWrapper id={'buttons.updateHcps'} />
      </BorderButton>
    </>
  )
}
