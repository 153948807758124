import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActions, FormLabel, FormControl, Switch, Grid, Typography, OutlinedInput } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import DeleteConfirm, { DeleteConfirmChildren } from '../deleteConfirm/DeleteConfirm'
import { Delete, CloudUpload, Image } from '@mui/icons-material'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { emptyRanking } from '../../../store/api/emptyObjects'
import { RankingPayload, createRanking, updateRanking, deleteRanking } from '../../../store/tourAndRanking/actions'
import UploadedImage from '../../ui/UploadedImage'
import FilePicker from '../../ui/FilePicker'
import BorderButton from '../../ui/BorderButton'
import forEach from 'lodash/forEach'
import { enqueueNotification } from '../../../store/notifications/actions'
import { OverlayLoader } from '../../ui/OverlayLoader'
import RankingConfig from './RankingConfig'
import { RootState } from '@app/store'

interface OwnProps {
  organizationId?: number
  ranking?: Ranking
  open: boolean
  onClose(): void
}

interface StateProps {
  isLoading: boolean
}

interface DispatchProps {
  createRanking: (organizationId: number, payload: RankingPayload, onComplete?: () => void) => void
  updateRanking: (organizationId: number, id: number, payload: RankingPayload, onComplete?: () => void) => void
  deleteRanking: (organizationId: number, id: number, onComplete?: () => void) => void
  enqueueNotification(error: any, variant?: string): void
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps

interface State {
  errors: string[]
  ranking: Ranking
  tempLogo?: File
  bestResultsCountEnabled: boolean
}

class RankingDialog extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      ranking: emptyRanking,
      tempLogo: undefined,
      bestResultsCountEnabled: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.ranking !== state.ranking && !state.ranking) {
      return {
        ranking: props.ranking ? props.ranking : emptyRanking,
        bestResultsCountEnabled: props.ranking && !!props.ranking.bestResultsCount ? true : false,
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ranking !== this.props.ranking && !prevState.ranking.id) {
      this.setState({
        ranking: this.props.ranking ? this.props.ranking : emptyRanking,
        bestResultsCountEnabled: this.props.ranking && !!this.props.ranking.bestResultsCount ? true : false,
      })
    }
  }

  render() {
    const { open = false, ranking } = this.props

    return (
      <>
        <Dialog
          open={open}
          onClose={(_, reason) => reason !== 'backdropClick' && this._handleDismiss()}
          aria-labelledby="ranking"
        >
          <BaseDialogTitle
            style={{ marginBottom: 10 }}
            id={'ranking-dialog'}
            title={
              ranking ? (
                <FormattedMessageWrapper id={'tourAndRanking.editRanking'} />
              ) : (
                <FormattedMessageWrapper id={'tourAndRanking.createNewRanking'} />
              )
            }
            onClose={this._handleDismiss}
          />

          <DialogContent>{this._renderForm()}</DialogContent>

          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={this._handleDismiss} disabled={this.props.isLoading}>
              <FormattedMessageWrapper id={'buttons.cancel'} />
            </Button>
            {this.state.errors.length > 0 && (
              <div>
                <Typography variant="body1" color="error">
                  <FormattedMessageWrapper id="errors.fillRequiredFields" />
                </Typography>
              </div>
            )}
            <div>
              {this.state.ranking && this.state.ranking.id && (
                <DeleteConfirm>
                  {({ showConfirm }: DeleteConfirmChildren) => (
                    <IconButton
                      onClick={() => showConfirm({ callback: this._handleDelete })}
                      style={{ marginRight: 24 }}
                      size="large"
                    >
                      <Delete color={'error'} />
                    </IconButton>
                  )}
                </DeleteConfirm>
              )}
              <ButtonLoaderWrap loading={this.props.isLoading}>
                <Button
                  disabled={this.props.isLoading || this.state.errors.length > 0}
                  onClick={this._handleSave}
                  color="primary"
                  variant={'contained'}
                >
                  <FormattedMessageWrapper id={'buttons.save'} />
                </Button>
              </ButtonLoaderWrap>
            </div>
          </DialogActions>
        </Dialog>
        <OverlayLoader visible={this.props.isLoading} />
      </>
    )
  }

  public _handleDelete = () => {
    const { ranking } = this.state
    if (this.props.organizationId && ranking && ranking.id) {
      this.props.deleteRanking(this.props.organizationId, ranking.id, this._handleClose)
    }
  }

  public _handleSave = () => {
    this._clearErrors()

    if (this.state.ranking && this.props.organizationId) {
      const { updateRanking, createRanking } = this.props
      const { id, name, active, configTemplate } = this.state.ranking

      if (!name) {
        this._addError('name')
        return
      }
      if (configTemplate.length === 0) {
        this._addError('configTemplate')
        return
      }
      if (configTemplate.some((row) => isNaN(row.position) || isNaN(row.points))) {
        this._addError('configTemplate')
        return
      }
      const bestResultsCount =
        this.state.bestResultsCountEnabled && !!this.state.ranking.bestResultsCount
          ? this.state.ranking.bestResultsCount
          : null
      if (id) {
        updateRanking(
          this.props.organizationId,
          id,
          {
            name: name,
            active: active,
            configTemplate: configTemplate,
            logo: this.state.tempLogo,
            bestResultsCount: bestResultsCount,
          },
          this._handleClose,
        )
      } else {
        createRanking(
          this.props.organizationId,
          {
            name: name,
            active: active,
            configTemplate: configTemplate,
            logo: this.state.tempLogo,
            bestResultsCount: bestResultsCount,
          },
          this._handleClose,
        )
      }
    }
  }

  private _clearErrors = () => {
    this.setState({ errors: [] })
  }

  private _addError = (name: string) => {
    this.setState((prevState) => {
      prevState.errors.push(name)
      return { errors: prevState.errors }
    })
  }

  public _handleDismiss = (): void => {
    this._handleClose()
  }

  public _handleClose = (): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
      this.setState({ errors: [], ranking: emptyRanking, tempLogo: undefined })
    }
  }

  public _handleChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    let value: string | undefined = e.target.value

    if (name === 'bestResultsCount') {
      value = value.replace(/[^\d]+/g, '')
      if (value.length === 0) {
        value = undefined
      }
    }

    this.setState({
      ranking: {
        ...this.state.ranking,
        [name]: value,
      },
    })
    if (name.length > 0) {
      this.setState((prevState) => {
        return { errors: prevState.errors.filter((e) => e !== name) }
      })
    }
  }

  private _handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.checked

    this.setState((prevState) => {
      return {
        ranking: {
          ...prevState.ranking,
          [name]: value,
        },
      }
    })
  }

  private _renderForm = () => {
    const { isLoading } = this.props
    const { ranking } = this.state

    return (
      <div style={{ minWidth: 550 }}>
        <FormLabel htmlFor="name" error={this.state.errors.includes('name')}>
          <FormattedMessageWrapper id={'tourAndRanking.name'} /> *
        </FormLabel>
        <TextField
          error={this.state.errors.includes('name')}
          margin={'normal'}
          fullWidth={true}
          disabled={isLoading}
          id={'name'}
          defaultValue={ranking.name}
          onChange={this._handleChange('name')}
          style={{ marginTop: 0, marginBottom: 10 }}
        />

        <FormControl fullWidth className="SwitchControl">
          <Switch checked={ranking.active} onChange={this._handleCheckboxChange} name="active" color="primary" />
          <FormLabel className="SwitchLabel">
            <FormattedMessageWrapper id="tourAndRanking.active" />
          </FormLabel>
        </FormControl>

        <Grid container={true} spacing={5} style={{ marginTop: 15, marginBottom: 15 }}>
          <Grid item={true} xs={ranking.logo ? 6 : 12}>
            {this.state.tempLogo ? (
              <Typography variant="body1">
                <Image color="primary" style={{ position: 'relative', top: 7 }} /> <b>{this.state.tempLogo.name}</b>
              </Typography>
            ) : (
              <>
                {this._renderUploadButton({
                  id: 'ranking-logo',
                  name: 'logo',
                  label: <FormattedMessageWrapper id={'buttons.uploadLogo'} />,
                })}
              </>
            )}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tourAndRanking.rankingLogoInfo'} />
            </Typography>
          </Grid>
          {ranking.logo && (
            <Grid item={true} xs={6}>
              <div style={{ maxWidth: '200px' }}>
                <UploadedImage image={ranking.logo} />
              </div>
            </Grid>
          )}
        </Grid>
        <RankingConfig
          template={ranking.configTemplate}
          updateRankingConfig={(config) => this._updateRankingConfig(config)}
        />

        <FormControl fullWidth className="SwitchControl" style={{ marginTop: 20 }}>
          <Switch
            checked={this.state.bestResultsCountEnabled}
            onChange={(e) => this.setState({ bestResultsCountEnabled: e.currentTarget.checked })}
            name="bestResultsCount"
            color="primary"
          />
          <FormLabel className="SwitchLabel">
            <FormattedMessageWrapper id="tourAndRanking.bestResultsCount" />

            {this.state.bestResultsCountEnabled && (
              <OutlinedInput
                error={this.state.errors.includes('bestResultsCount')}
                disabled={isLoading}
                id={'bestResultsCount'}
                value={ranking.bestResultsCount ? ranking.bestResultsCount : ''}
                onChange={this._handleChange('bestResultsCount')}
                style={{ marginTop: 0, marginBottom: 0, marginLeft: 15, maxWidth: 70 }}
                margin="dense"
              />
            )}
          </FormLabel>
        </FormControl>
      </div>
    )
  }

  private _updateRankingConfig = (configTemplate: RankingTemplateItem[]) => {
    this.setState((prevState) => {
      return {
        ranking: {
          ...prevState.ranking,
          configTemplate,
        },
      }
    })
  }

  private _renderUploadButton = ({
    id,
    name,
    label,
    multiple = false,
    disabled = false,
  }: {
    name: string
    id: string
    label: React.ReactNode
    multiple?: boolean
    disabled?: boolean
  }) => (
    <FilePicker
      name={name}
      id={id}
      onChange={this._onFileInputChange}
      accept="image/*"
      multiple={multiple}
      disabled={disabled}
    >
      <BorderButton
        buttonProps={{
          component: 'span',
          disabled,
        }}
      >
        {label}
        <CloudUpload style={{ marginLeft: 10 }} />
      </BorderButton>
    </FilePicker>
  )

  private _onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (files && files.length > 0) {
      forEach(files, (file) => {
        if (file.size > 2000000) {
          this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.tooLargeImageFile' }), 'error')
          return
        }
        this.setState({ tempLogo: file })
      })
    }
  }
}

export default injectIntl(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (store) => ({
      isLoading: store.tourAndRankingReducer.loading,
    }),
    {
      createRanking,
      updateRanking,
      deleteRanking,
      enqueueNotification,
    },
  )(RankingDialog),
)
