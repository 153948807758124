import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import { MatchPlayBracketCard } from '@app/components/match-play-brackets/MatchPlayBracketCard'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { matchPlayBracketsMap } from '@app/utils/matchPlayBracketUtils'
import { sortByOrderKey } from '@app/utils/sortUtils'
import { useBracketStyles } from './styles'

interface Props {
  bracket: MatchPlayBracket
  players: MatchPlayBracketPlayer[]
  editPlayer: (player: MatchPlayBracketPlayer, deletePlayer?: boolean) => void
  updateBracket: (bracket?: MatchPlayBracket) => void
}

export const MatchPlayBracket: React.FC<Props> = ({ bracket, players, editPlayer, updateBracket }) => {
  const [stages, setStages] = useState<MatchPlayBracketStage[]>([])
  const [showDescriptionField, setShowDescriptionField] = useState<BracketStage | null>(null)
  const classes = useBracketStyles()
  const intl = useIntl()

  useEffect(() => {
    const numberOfPlayers = bracket?.options?.numberOfPlayers || 0
    const playBronzeMatch = bracket?.options?.playBronzeMatch || false
    const stageDescriptions = bracket?.options?.stageDescriptions || []
    let stageNames: BracketStage[] = []
    if (numberOfPlayers > 0) {
      stageNames = Object.values(matchPlayBracketsMap[numberOfPlayers])
    }

    if (playBronzeMatch) {
      stageNames.push(MatchPlayBracketStages.BRONZE_MATCH)
    }

    const bracketStages: MatchPlayBracketStage[] = stageNames.map((stage: BracketStage, i: number) => {
      const stageDescription = stageDescriptions.find(
        (description: MatchPlayBracketStageDescription) => description.stage === stage,
      )
      const stagePlayers = stage === MatchPlayBracketStages.BRONZE_MATCH ? 2 : numberOfPlayers / Math.pow(2, i)
      return {
        stage,
        numberOfPlayers: stagePlayers,
        visible: true,
        description: stageDescription?.description || '',
      }
    })

    setStages(bracketStages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const container = document.getElementById('bracket-container') as HTMLElement
    const poolContainer = document.getElementById('bracket-pool-container') as HTMLElement
    if (container) {
      container.style.width = window.innerWidth - container?.offsetLeft - 30 + 'px'
      container.style.height = poolContainer.offsetHeight + 'px'
      container.style.minHeight = container.offsetHeight < 1000 ? '1000px' : container.offsetHeight + 'px'
    }
  }, [])

  const getStagePlayers = (stage: BracketStage): MatchPlayBracketPlayer[] => {
    return players
      .filter((player: MatchPlayBracketPlayer) => player.stage === stage)
      .sort((a, b) => sortByOrderKey(a.order, b.order))
  }

  const getPlayerInStage = (stage: BracketStage, order: number): MatchPlayBracketPlayer => {
    return getStagePlayers(stage).filter((player: MatchPlayBracketPlayer) => player.order === order)[0] || null
  }

  const getBracketClasses = (stage: MatchPlayBracketStage): string => {
    if (stage.stage === MatchPlayBracketStages.FINAL) {
      return `${classes.bracket} ${classes.final}`
    } else if (stage.stage === MatchPlayBracketStages.BRONZE_MATCH) {
      return `${classes.bracket} ${classes.bronzeMatch}`
    }
    return classes.bracket
  }

  const updateStageVisibility = (stage: MatchPlayBracketStage) => () => {
    const wasVisible = stage.visible
    const stageIndex = stages.findIndex((s: MatchPlayBracketStage) => s.stage === stage.stage)

    const updatedStages = stages.map((s: MatchPlayBracketStage, i: number) => {
      const clickedStage = s.stage === stage.stage
      const shouldMakeVisible = !wasVisible && !s.visible && i > stageIndex
      if (clickedStage || shouldMakeVisible) {
        return { ...s, visible: !s.visible }
      }
      return s
    })
    setStages(updatedStages)
    const brackets = document.getElementById('bracket-container') as HTMLElement
    brackets.scrollLeft += wasVisible ? 310 : -310
  }

  const updateStageDescription = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    const stageIndex = stages.findIndex((s: MatchPlayBracketStage) => s.stage === name)
    const updatedStages = stages.map((s: MatchPlayBracketStage, i: number) => {
      if (i === stageIndex) {
        return { ...s, description: value }
      }
      return s
    })
    setStages(updatedStages)
  }

  const maybeShowExpandCollapseIcon = (stage: MatchPlayBracketStage): React.ReactElement | undefined => {
    const stageIndex = stages.findIndex((s: MatchPlayBracketStage) => s.stage === stage.stage)
    const previousStagesIsVisible = stages[stageIndex - 1]?.visible
    const isAtLeastQuarterFinal = stage.stage.startsWith('ROUND') === false
    if (isAtLeastQuarterFinal || previousStagesIsVisible) {
      return
    }
    const Icon = stage.visible ? ExpandLessIcon : ExpandMoreIcon
    return (
      <div className={classes.expandCollapse} onClick={updateStageVisibility(stage)}>
        <Icon />
      </div>
    )
  }

  const getStages = (): MatchPlayBracketStage[] => {
    return stages.slice().filter((s: MatchPlayBracketStage) => s.stage !== MatchPlayBracketStages.BRONZE_MATCH)
  }

  const renderStage = (stage: MatchPlayBracketStage): React.ReactElement => {
    return (
      <div className={getBracketClasses(stage)} key={stage.stage} id={stage.stage}>
        {stage.visible &&
          Array.from({ length: stage.numberOfPlayers }, (_, index: number) => {
            const player = getPlayerInStage(stage.stage, index + 1)
            const draggableId = player ? `${stage.stage}-${player.playerBracketId}` : `${stage.stage}-${index}`
            return (
              <MatchPlayBracketCard
                player={player}
                key={`${stage.stage}-${index}`}
                index={index}
                draggable={
                  stage.stage === MatchPlayBracketStages.POOL ||
                  (player !== null && stage.stage !== MatchPlayBracketStages.FINAL)
                }
                draggableId={draggableId}
                droppable={player === null}
                stage={stage}
                stages={stages}
                players={players}
                editPlayer={editPlayer}
                updateStageDescription={updateStageDescription}
                submitStageDescription={submitStageDescription}
              />
            )
          })}
        {maybeRenderBronzeMatchBracket(stage)}
      </div>
    )
  }

  const maybeRenderBronzeMatchBracket = (stage: MatchPlayBracketStage): React.ReactElement | undefined => {
    const bronzeMatchStage = stages.find((s: MatchPlayBracketStage) => s.stage === MatchPlayBracketStages.BRONZE_MATCH)
    if (stage.stage !== MatchPlayBracketStages.FINAL || !bronzeMatchStage) {
      return
    }

    return renderStage(bronzeMatchStage)
  }

  const submitStageDescription = (): void => {
    setShowDescriptionField(null)
    const stageDescriptions = stages.map((stage: MatchPlayBracketStage) => ({
      stage: stage.stage,
      description: stage.description || '',
    }))
    if (bracket) {
      const newBracket = {
        ...bracket,
        players,
        options: {
          ...bracket.options,
          stageDescriptions,
        },
      }
      updateBracket(newBracket)
    }
  }

  return (
    <div className={classes.bracketContainer} id="bracket-container">
      <div className={classes.tournamentHeaders}>
        {stages
          .filter((stage: MatchPlayBracketStage) => stage.stage !== MatchPlayBracketStages.BRONZE_MATCH)
          .map((stage: MatchPlayBracketStage) => (
            <div>
              <h3>
                {intl.formatMessage({ id: `matchPlayBracket.${stage.stage}` })}
                {maybeShowExpandCollapseIcon(stage)}
              </h3>
              <span className={!stage.description ? classes.addDate : ''}>
                {stage.description || intl.formatMessage({ id: 'matchPlayBracket.addDateHere' })}
                <EditIcon onClick={() => setShowDescriptionField(stage.stage)} />
              </span>
              {showDescriptionField === stage.stage && (
                <div className={classes.stageDescriptionInput}>
                  <input type="text" name={stage.stage} value={stage.description} onChange={updateStageDescription} />
                  <button onClick={submitStageDescription}>
                    <CheckIcon />
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>

      <div className={classes.tournamentBrackets}>
        {getStages().map((stage: MatchPlayBracketStage) => renderStage(stage))}
      </div>
    </div>
  )
}
