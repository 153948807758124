export const FETCH_TOURS = 'FETCH_TOURS'
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS'
export const FETCH_TOURS_FAILURE = 'FETCH_TOURS_FAILURE'

export const FETCH_RANKINGS = 'FETCH_RANKINGS'
export const FETCH_RANKINGS_SUCCESS = 'FETCH_RANKINGS_SUCCESS'
export const FETCH_RANKINGS_FAILURE = 'FETCH_RANKINGS_FAILURE'

export const CREATE_RANKING = 'CREATE_RANKING'
export const CREATE_RANKING_SUCCESS = 'CREATE_RANKING_SUCCESS'
export const CREATE_RANKING_FAILURE = 'CREATE_RANKING_FAILURE'

export const UPDATE_RANKING = 'UPDATE_RANKING'
export const UPDATE_RANKING_SUCCESS = 'UPDATE_RANKING_SUCCESS'
export const UPDATE_RANKING_FAILURE = 'UPDATE_RANKING_FAILURE'

export const DELETE_RANKING = 'DELETE_RANKING'
export const DELETE_RANKING_SUCCESS = 'DELETE_RANKING_SUCCESS'
export const DELETE_RANKING_FAILURE = 'DELETE_RANKING_FAILURE'

export const FETCH_TOUR = 'FETCH_TOUR'
export const FETCH_TOUR_SUCCESS = 'FETCH_TOUR_SUCCESS'
export const FETCH_TOUR_FAILURE = 'FETCH_TOUR_FAILURE'

export const RESET_TOUR = 'RESET_TOUR'

export const UPDATE_TOUR_FIELD = 'UPDATE_TOUR_FIELD'

export const SAVE_TOUR = 'SAVE_TOUR'
export const SAVE_TOUR_SUCCESS = 'SAVE_TOUR_SUCCESS'
export const SAVE_TOUR_FAILURE = 'SAVE_TOUR_FAILURE'

export const DELETE_TOUR = 'DELETE_TOUR'
export const DELETE_TOUR_SUCCESS = 'DELETE_TOUR_SUCCESS'
export const DELETE_TOUR_FAILURE = 'DELETE_TOUR_FAILURE'

export const UPLOAD_TOUR_IMAGE = 'UPLOAD_TOUR_IMAGE'
export const UPLOAD_TOUR_IMAGE_FINISHED = 'UPLOAD_TOUR_IMAGE_FINISHED'
export const DELETE_TOUR_IMAGE = 'DELETE_TOUR_IMAGE'
export const DELETE_TOUR_IMAGE_FINISHED = 'DELETE_TOUR_IMAGE_FINISHED'

export const SEND_RANKING_POINTS = 'SEND_RANKING_POINTS'
export const SEND_RANKING_POINTS_SUCCESS = 'SEND_RANKING_POINTS_SUCCESS'
export const SEND_RANKING_POINTS_FAILURE = 'SEND_RANKING_POINTS_FAILURE'
