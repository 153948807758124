import React from 'react'
import { FormControl, Switch, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import ContentWrap from '@app/components/layout/ContentWrap'
import SectionTitle from '@app/components/ui/SectionTitle'
import GameFormatSelector from '@app/components/tournament/GameFormatSelector'
import {
  updateGlobalMainGameFormat,
  updateGlobalSideGameFormat,
  updateRoundField,
} from '@app/store/api/thunks/tournamentThunks'
import { RootState } from '@app/store'
import { updateMainGameFormat, updateSideGameFormat } from '@app/store/api/thunks/tournamentThunks'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      background: theme.palette.background.default,
    },
    sectionTitle: {
      color: theme.palette.text.secondary,
    },
    sectionTitleDisabled: {
      color: theme.palette.text.disabled,
    },
    dividerContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '4%',
    },
    divider: {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      height: '100%',
      width: '1px',
    },
    sectionContainer: {
      display: 'flex',
      position: 'relative',
    },
    section: {
      width: '48%',
    },
  })

interface OwnProps {
  disabled?: boolean
}
interface StateProps {
  tournament: TournamentState
  tournamentConfig: TournamentConfig
}

interface DispatchProps {
  updateMainGameFormat: (payload: GameFormatPayload) => void
  updateGlobalMainGameFormat: (payload: GameFormatPayload) => void
  updateSideGameFormat: (payload: GameFormatPayload) => void
  updateGlobalSideGameFormat: (payload: GameFormatPayload) => void
  updateRoundField: (payload: UpdateRoundFieldPayload) => void
}

type GameFormatSelectionProps = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WrappedComponentProps

class GameFormatSelectionComponent extends React.Component<GameFormatSelectionProps> {
  public render() {
    const { classes, tournament, tournamentConfig } = this.props
    const { selectedRoundIndex } = tournamentConfig
    const selectedRound = tournament.rounds[selectedRoundIndex]

    return (
      <ContentWrap divider>
        <div className={classes.sectionContainer}>
          <div className={classes.section}>
            <SectionTitle className={this.props.disabled ? classes.sectionTitleDisabled : classes.sectionTitle}>
              <FormattedMessageWrapper id="tournament.primaryGame" />
            </SectionTitle>
            {selectedRound && (
              <GameFormatSelector
                index={selectedRoundIndex}
                round={selectedRound}
                gameType="primary"
                updateGameFormat={this.props.updateMainGameFormat}
                updateGlobalGameFormat={this.props.updateGlobalMainGameFormat}
                disabled={this.props.disabled}
              />
            )}
          </div>
          <div className={classes.dividerContainer}>
            <div className={classes.divider} />
          </div>
          <div className={classes.section}>
            <SectionTitle className={this.props.disabled ? classes.sectionTitleDisabled : classes.sectionTitle}>
              <FormattedMessageWrapper id="tournament.sideGame" />
              <FormControl style={{ margin: '-5px 0 -5px 15px' }}>
                <Switch
                  checked={selectedRound?.sideGameEnabled || false}
                  name="sideGameEnabled"
                  color="primary"
                  disabled={this.props.disabled}
                  onChange={() => {
                    this.props.updateRoundField({
                      index: selectedRoundIndex,
                      fieldName: 'sideGameEnabled',
                      value: !selectedRound.sideGameEnabled,
                    })
                  }}
                />
              </FormControl>
            </SectionTitle>
            {selectedRound && selectedRound?.sideGameEnabled && (
              <GameFormatSelector
                index={selectedRoundIndex}
                round={selectedRound}
                gameType="side"
                updateGameFormat={this.props.updateSideGameFormat}
                updateGlobalGameFormat={this.props.updateGlobalSideGameFormat}
                disabled={this.props.disabled}
              />
            )}
          </div>
        </div>
      </ContentWrap>
    )
  }
}

export const GameFormatSelection = connect<StateProps, DispatchProps, {}, RootState>(
  (store) => ({
    tournament: selectTournament(store),
    tournamentConfig: selectTournamentConfig(store),
  }),
  {
    updateMainGameFormat,
    updateGlobalMainGameFormat,
    updateSideGameFormat,
    updateGlobalSideGameFormat,
    updateRoundField,
  },
)(withStyles(styles)(injectIntl(GameFormatSelectionComponent as any)))
