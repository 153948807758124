import React from 'react'
import { connect } from 'react-redux'
import { Theme, Button, Grid, Checkbox, FormControlLabel } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { AddCircle, Delete } from '@mui/icons-material'

import { Team } from './Team'
import { EditPlayerDialogWrapper } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import DeleteConfirm, { DeleteConfirmChildren } from '@app/components/dialogs/deleteConfirm/DeleteConfirm'

import { PlayerPoolEditContext } from '../../enums'
import { TeamReserveDialog } from '../../team-reserve-dialog'
import { TeamTarget } from '@app/store/api/enums/tournamentPlayersEnums'
import { isTwoPlayerFormatId } from '@app/utils/gameFormatUtils'
import { selectTournamentTeams, tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { RootState } from '@app/store'
import { selectTournamentSettings } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

interface OwnProps {
  createTeam: () => void
}

interface StateProps {
  teams: TournamentTeam[]
  tournamentSite: TournamentSite
  tournament: TournamentState
  tournamentSettings: TournamentSettings
}

interface DispatchProps {
  deleteTeams(payload: DeleteTeamsPayload): void
  deleteTeam(args: DeleteTeamPayload): void
  addTeamToPool: (payload: AddTeamToPoolPayload) => void
}

interface State {
  teamReserveDialogOpen: boolean
  teamId: number | undefined
  hideFullTeams: boolean
}

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    TeamContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'stretch',
    },
    actionButton: {
      color: theme.palette.primary.contrastText,
      marginBottom: 10,
      '&.Mui-disabled': {
        color: theme.palette.common.white,
        opacity: 0.5,
      },
    },
    hideFullTeamsLabel: {
      marginBottom: 10,
      marginLeft: 20,
      '& .MuiTypography-root': {
        color: `${theme.palette.common.white}`,
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.common.white,
      },
    },
  })

const initialState = {
  teamReserveDialogOpen: false,
  teamId: undefined,
  hideFullTeams: false,
}

class TeamsComponent extends React.Component<Props, State> {
  readonly state = initialState

  render() {
    const { classes, teams } = this.props

    /**
     * Sort teams according to creation order
     */
    const sortedTeams = teams.slice().sort((a, b) => (a.id > b.id ? 1 : -1))

    const filteredTeams = this.state.hideFullTeams
      ? sortedTeams.filter((team) => team.players.length < this._getFullTeamPlayersCount())
      : sortedTeams

    const renderTeams = filteredTeams.map((team, index) => (
      <EditPlayerDialogWrapper key={`teamEditWrapper-${index}`} editContext={PlayerPoolEditContext.GROUP_POOL}>
        {(args) => (
          <Team key={`team-${index}`} onOpenTeamReserveDialog={this._teamReserveDialogOnClick} team={team} {...args} />
        )}
      </EditPlayerDialogWrapper>
    ))

    return (
      <>
        <this.TeamActions />
        <TeamReserveDialog
          isOpen={this.state.teamReserveDialogOpen}
          onClose={this._teamReserveDialogOnClose}
          onDelete={this._teamReserveDialogOnDelete}
          onSendToReserve={this._teamReserveDialogOnSendToReserve}
        />
        <div className={classes.TeamContainer}>{renderTeams}</div>
      </>
    )
  }

  private _getFullTeamPlayersCount = (): number => {
    const { tournament, tournamentSettings } = this.props

    if (isTwoPlayerFormatId(tournament.rounds[0].primaryGameId)) {
      return 2
    }

    return tournamentSettings.defaultTeamSize
  }

  private _teamReserveDialogOnClose = () => {
    this.setState({ teamReserveDialogOpen: false, teamId: undefined })
  }

  private _teamReserveDialogOnClick = (teamId: number | undefined) => {
    const { reserveListEnabled } = this.props.tournamentSite
    reserveListEnabled && this.setState({ teamReserveDialogOpen: !this.state.teamReserveDialogOpen, teamId })
  }

  private _teamReserveDialogOnSendToReserve = () => {
    const { teamId } = this.state
    const newTeamId = teamId || -1
    this.props.addTeamToPool({
      tournamentId: this.props.tournament.id,
      teamId: newTeamId,
      targetPool: TeamTarget.RESERVED,
    })
    this._teamReserveDialogOnClose()
  }

  private _teamReserveDialogOnDelete = () => {
    const { teamId } = this.state
    const newTeamId = teamId || -1
    teamId && this.props.deleteTeam({ tournamentId: this.props.tournament.id, teamId: newTeamId })
    this._teamReserveDialogOnClose()
  }

  /**
   * Teams header actions section
   */
  private TeamActions = (): JSX.Element => {
    const { classes, deleteTeams, createTeam } = this.props
    return (
      <Grid
        container
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Grid item>
          <div style={{ display: 'flex' }}>
            <Button onClick={createTeam} className={classes.actionButton}>
              <AddCircle style={{ marginRight: 10 }} />
              <FormattedMessageWrapper id="buttons.addTeam" />
            </Button>
            <FormControlLabel
              className={classes.hideFullTeamsLabel}
              control={
                <Checkbox
                  name={'hideFullTeams'}
                  checked={this.state.hideFullTeams}
                  onChange={(_, checked) => this.setState({ hideFullTeams: checked })}
                />
              }
              label={<FormattedMessageWrapper id="tournament.hideFullTeams" />}
            />
          </div>
        </Grid>
        <Grid item>
          <div style={{ marginBottom: 10 }}>
            <DeleteConfirm>
              {({ showConfirm }: DeleteConfirmChildren) => (
                <Button
                  onClick={() =>
                    showConfirm({
                      callback: () => deleteTeams({ tournamentId: this.props.tournament.id }),
                      messageId: 'strings.groupDeleteConfirm',
                    })
                  }
                  className={classes.actionButton}
                >
                  <Delete style={{ marginRight: 10 }} />
                  <FormattedMessageWrapper id="buttons.clear" />
                </Button>
              )}
            </DeleteConfirm>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export const Teams = connect<StateProps, DispatchProps, {}, RootState>(
  (state) => {
    return {
      teams: selectTournamentTeams(state).data?.teams || [],
      tournament: selectTournament(state),
      tournamentSettings: selectTournamentSettings(state),
      tournamentSite: selectTournamentSite(state),
    }
  },
  {
    deleteTeams: tournamentTeamsApi.endpoints.deleteTeams.initiate,
    deleteTeam: tournamentTeamsApi.endpoints.deleteTeam.initiate,
    addTeamToPool: tournamentTeamsApi.endpoints.addTeamToPool.initiate,
  },
)(withStyles(styles)(TeamsComponent as any))
