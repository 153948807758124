import { useEffect, useState } from 'react'
import { Dialog, Theme, Typography } from '@mui/material'
import { PlayerImportView, PlayerListView } from './views/index'
import BaseDialogTitle from '@components/dialogs/ui/BaseDialogTitle'
import { makeStyles } from '@mui/styles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { storageTempImportId } from '@app/utils/tournamentUtils'
import { selectTournamentConfig, setGitDialogOpen } from '@app/store/api/slices/configSlice'

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: 680,
      minHeight: 554,
    },
  },
  dialogTitle: {
    color: theme.customPalette.darkGray2,
  },
}))

interface Props {
  onClose: () => void
}

export const GitPlayerImportDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const tempImportId = localStorage.getItem(storageTempImportId)

  const { gitDialogOpen, gitImportError } = useSelector(selectTournamentConfig)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [showImportView, setShowImportView] = useState<boolean>(true)

  useEffect(() => {
    if (gitImportError) {
      setOpenDialog(false)
    }

    setOpenDialog(gitDialogOpen)
  }, [gitDialogOpen, gitImportError])

  useEffect(() => {
    setShowImportView(!tempImportId)
  }, [tempImportId])

  const handleImportClick = () => {
    setShowImportView(false)
  }

  const handleClose = (cancel?: boolean) => {
    if (cancel) {
      setShowImportView(true)
    }
    onClose()
  }

  const getMaxWidth = showImportView ? 'md' : 'sm'

  const getTitle = () =>
    showImportView ? (
      <BaseDialogTitle
        title={
          <Typography variant="h3" className={classes.dialogTitle}>
            <FormattedMessageWrapper id="tournament.gitImport" />
          </Typography>
        }
        onClose={() => dispatch(setGitDialogOpen(false))}
      />
    ) : (
      <BaseDialogTitle
        title={
          <Typography variant="h3" className={classes.dialogTitle}>
            <FormattedMessageWrapper id="tournament.gitImport" />
          </Typography>
        }
      />
    )

  return (
    <Dialog open={openDialog} maxWidth={getMaxWidth} fullWidth className={classes.dialog}>
      {getTitle()}
      {showImportView ? (
        <PlayerImportView onImportClick={handleImportClick} />
      ) : (
        <PlayerListView onClose={handleClose} />
      )}
    </Dialog>
  )
}
