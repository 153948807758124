import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import { Theme } from '@mui/material'
import { rem } from '../../../theme/materialUITheme'
import { Cancel } from '@mui/icons-material'
import { useTheme } from '@mui/system'

interface BaseDialogTitleProps {
  id?: string
  title?: React.ReactNode
  subtitle?: React.ReactNode
  style?: React.CSSProperties
  onClose?: () => void
  largeTitleText?: boolean
  className?: string
}

const BaseDialogTitle = ({ id, title, subtitle, style, onClose, largeTitleText, className }: BaseDialogTitleProps) => {
  const theme: Theme = useTheme()

  const largeTitleStyles = {
    fontSize: '1.6rem',
    marginLeft: 15,
    cursor: 'pointer',
    '&:hover, &:active': {
      opacity: 0.5,
    },
    color: theme.customPalette.darkGray2,
  }

  return (
    <DialogTitle id={id} style={style} className={className}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          {title && <span style={{ display: 'block', ...(largeTitleText ? largeTitleStyles : {}) }}>{title}</span>}

          {subtitle && <span style={{ fontSize: rem(14) }}>{subtitle}</span>}
        </div>

        {onClose && (
          <div>
            <Cancel
              onClick={onClose}
              sx={{
                fs: '1.6rem',
                marginLeft: 15,
                cursor: 'pointer',
                '&:hover, &:active': {
                  opacity: 0.5,
                },
                color: theme.customPalette.darkGray2,
              }}
            />
          </div>
        )}
      </div>
    </DialogTitle>
  )
}

export default BaseDialogTitle
