/**
 * Converts currency minor units to major units as a string
 */
export const currencyFractionsToMains = (value: number, minorDivider: number): string => {
  const convertedValue = Number(value / minorDivider)
  return minorDivider === 1 ? String(convertedValue) : convertedValue.toFixed(2)
}

/**
 * Converts currency major units to minor units as a number
 */
export const currencyMainsToFractions = (value: number, minorDivider: number): number => {
  return Math.round(value * minorDivider)
}

/**
 * Convert organization product to payload
 */
export const convertOrganizationProductToPayload = (formData: Partial<OrganizationProduct>): OrganizationProduct => {
  const payload = {
    ...formData,
    defaultVat: formData.defaultVat ? Number(formData.defaultVat) : 0,
    defaultPrice: formData.defaultPrice ? Number(formData.defaultPrice) : 0,
  }

  return payload as OrganizationProduct
}

/**
 * Convert tournament product to payload
 */
export const convertTournamentProductToPayload = (formData: Partial<TournamentProduct>): TournamentProduct => {
  const payload = {
    ...formData,
    vat: formData.vat ? Number(formData.vat) : 0,
    price: formData.price ? Number(formData.price) : 0,
  }

  return payload as unknown as TournamentProduct
}

export const getDecimalCount = (number: number): number => {
  const numStr = String(number)
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length
  }
  if (numStr.includes(',')) {
    return numStr.split(',')[1].length
  }
  return 0
}

export const validateProductPrice = (price: number, currency?: PaymentCurrency): boolean => {
  if (price === 0) {
    return true
  }
  const minPrice = currency?.minAmount || 0
  const maxPrice = currency?.maxAmount || 0
  return price >= minPrice && price <= maxPrice
}

export const trimVatValue = (value: string): string => {
  return value.replace(/[^0-9]/g, '').slice(0, 2)
}

export const trimPriceValue = (value: string): string => {
  const priceRegex = /^(\d+([.,])?\d{0,2})?$/
  return priceRegex.test(value) ? value : value.slice(0, -1)
}
