import React from 'react'
import { connect } from 'react-redux'
import { MenuItem } from '@mui/material'
import * as localeActions from '../../store/locale/actions'
import { FiFlag, SvFlag, EnFlag, EsFlag } from '../../assets/images'
import { Select } from '../forms/Select'
import { RootState } from '@app/store'

export const flag = (code: string) => {
  switch (code) {
    case 'fi-FI':
      return FiFlag
    case 'fi-SV':
      return SvFlag
    case 'en-US':
      return EnFlag
    case 'sv-SE':
      return SvFlag
    case 'es-ES':
      return EsFlag
    default:
      return ''
  }
}

interface OwnProps {
  style?: React.CSSProperties
}

interface StateProps {
  locale: LocaleState
  country?: string
}

interface DispatchProps {
  setLanguage: (language: LanguageOption) => any
}

type Props = OwnProps & StateProps & DispatchProps

interface State {
  value?: string
}

class LanguageSwitcher extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { locale, country } = this.props
    const defaultLang = country === 'Finland' ? { code: 'fi-FI' } : { code: 'en-US' }
    const { appLanguage = defaultLang } = locale

    this.state = {
      value: appLanguage.code,
    }
  }

  render() {
    const { locale, style } = this.props
    const { appLanguage = { code: 'en-US' }, availableLanguages } = locale

    const languages = availableLanguages

    return (
      <div style={style}>
        {languages && languages.length > 0 && (
          <Select
            id="language-switcher"
            value={appLanguage.code}
            noBorder
            style={{ marginRight: 20, color: '#fff' }}
            onChange={this._handleChange}
          >
            {languages.map((language: LanguageOption, index: number) => (
              <MenuItem key={index} value={language.code}>
                <img src={flag(language.code)} style={{ width: 30, padding: '5px 5px 0 5px' }} alt="" />
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
    )
  }

  private _handleChange = (event: any) => {
    const { locale, setLanguage } = this.props
    const { target } = event
    const language = locale.availableLanguages.find(({ code }) => code === target.value)
    if (language) {
      setLanguage(language)
    }
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({ locale: state.localeReducer, country: state.organizationReducer.country }),
  { setLanguage: localeActions.setLanguage },
)(LanguageSwitcher)
