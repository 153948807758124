import React, { useEffect, useMemo, useState } from 'react'
import { MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { addMonths, startOfMonth, startOfYear } from 'date-fns'
import { useIntl } from 'react-intl'
import { ToggleButton } from './ToggleButton'
import { rem } from '@app/theme/materialUITheme'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFilterYears } from '@store/tournaments/actions'
import { RootState } from '@app/store'

export interface TournamentFilterProps {
  isLoading: boolean
  onChange: (startDate: Date | undefined, endDate: Date | undefined, showAll?: boolean, upcomingState?: boolean) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    height: rem(60),
    alignItems: 'center',
    '& > *': {
      marginRight: rem(15),
    },
  },
  select: {
    height: rem(40),
    fontSize: rem(14),
    fontWeight: 500,
    borderRadius: Number(theme.shape.borderRadius) * 6,
    padding: `${rem(1)} ${rem(1)}`,
    textTransform: 'none',
    color: theme.palette.primary.main,
    border: `${rem(2)} solid ${theme.palette.primary.main}`,
    maxWidth: rem(200),
    '& > .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& > fieldset': {
      border: '0px none',
    },
  },
  selectActive: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
}))

type SelectOption = {
  key: number
  label: string
  value: string | number
}

export const TournamentFilter: React.FC<TournamentFilterProps> = ({ onChange }) => {
  const dispath = useDispatch()
  const classes = useStyles()
  const intl = useIntl()
  const [year, setYear] = useState<number>(0)
  const [month, setMonth] = useState<number>(0)

  const [selectedAllTournaments, changeAllState] = useState<boolean>(false)
  const [showUpcoming, changeUpcomingState] = useState<boolean>(false)

  const { years } = useSelector((store: RootState) => store.tournamentsReducer)
  const organizationId = useSelector((store: RootState) => store.organizationReducer.id)

  useEffect(() => {
    if (organizationId && organizationId !== -1) {
      dispath(fetchFilterYears(organizationId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  const yearOptions: SelectOption[] = useMemo(() => {
    const yearsList = years.map((year, idx) => ({ key: idx + 2, label: year, value: year }))
    return [{ key: 1, label: intl.formatMessage({ id: 'tournaments.year' }), value: 0 }, ...yearsList]
  }, [years, intl])

  const monthOptions: SelectOption[] = useMemo(() => {
    const tmpDate = new Date()
    tmpDate.setDate(1) // Ensure that the day exists in the month

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((monthNum) => {
      tmpDate.setMonth(monthNum - 1)

      // Use sv-FI format to get correct month names in swedish
      const svMonthName = new Intl.DateTimeFormat('sv-FI', { month: 'long' }).format(tmpDate)

      return {
        key: monthNum,
        label: intl.locale === 'fi-SV' ? svMonthName : intl.formatDate(tmpDate, { month: 'long' }),
        value: monthNum,
      }
    })
    return [{ key: 0, label: intl.formatMessage({ id: 'tournaments.month' }), value: 0 }, ...months]
  }, [intl])

  useEffect(() => {
    if (month) {
      const tempDate = new Date()
      tempDate.setDate(1) // Ensure that the day exists in the month
      tempDate.setMonth(month - 1)
      if (year) {
        tempDate.setFullYear(year)
      }
      const startDate = startOfMonth(tempDate)
      const endDate = addMonths(startDate, 1)
      onChange(startDate, endDate)
      return
    }
    if (year) {
      const tempDate = new Date()
      tempDate.setFullYear(year)
      const startDate = startOfYear(tempDate)
      tempDate.setFullYear(year + 1)
      const endDate = startOfYear(tempDate)
      onChange(startDate, endDate)
      return
    }
    onChange(undefined, undefined, selectedAllTournaments, showUpcoming)
  }, [year, month, showUpcoming, selectedAllTournaments, onChange])

  const onUpcomingClick = () => {
    changeAllState(false)
    changeUpcomingState(true)
    setYear(0)
    setMonth(0)
  }

  const onYearChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number
    /**
     * If month selected and de-selecting year,
     * update year to be current year
     */
    if (month && value === 0) {
      setYear(new Date().getFullYear())
    } else {
      setYear(value)
    }
    changeAllState(false)
    changeUpcomingState(false)
  }

  const onMonthChange = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number
    setMonth(value)
    if (year === 0) {
      setYear(new Date().getFullYear())
    }
    changeAllState(false)
    changeUpcomingState(false)
  }

  const handleAllChange = () => {
    if (!selectedAllTournaments) {
      changeAllState(true)
      changeUpcomingState(false)
      setYear(0)
      setMonth(0)
    }
  }

  return (
    <div className={classes.container}>
      <ToggleButton
        onClick={onUpcomingClick}
        value="upcoming"
        selected={year === 0 && month === 0 && !selectedAllTournaments}
      >
        <FormattedMessageWrapper id="tournaments.upcoming" />
      </ToggleButton>
      <Select
        variant="filled"
        className={year > 0 ? `${classes.select} ${classes.selectActive}` : classes.select}
        fullWidth
        value={year}
        input={<OutlinedInput style={{ width: '50%' }} />}
        onChange={onYearChange}
      >
        {yearOptions.map((opt) => {
          return (
            <MenuItem key={opt.key} value={opt.value}>
              {opt.label}
            </MenuItem>
          )
        })}
      </Select>
      <Select
        variant="filled"
        className={month > 0 ? `${classes.select} ${classes.selectActive}` : classes.select}
        fullWidth
        value={month}
        input={<OutlinedInput style={{ width: '50%' }} />}
        onChange={onMonthChange}
      >
        {monthOptions.map((opt) => {
          return (
            <MenuItem key={opt.key} value={opt.value}>
              {opt.label}
            </MenuItem>
          )
        })}
      </Select>
      <ToggleButton onClick={handleAllChange} value="all" selected={selectedAllTournaments} style={{ width: 140 }}>
        <FormattedMessageWrapper id="options.unscheduled" />
      </ToggleButton>
    </div>
  )
}
