import React from 'react'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogActions,
  Card,
  CardContent,
  Typography,
  Grid,
  FormControl,
  Select,
  Input,
  MenuItem,
} from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { flag } from '../../layout/LanguageSwitcher'
import { RootState } from '@app/store'

interface OwnProps {
  open: boolean
  onClose(): void
}

interface StateProps {
  calendarUrl?: string | null
  locale: LocaleState
}

type Props = OwnProps & StateProps & WrappedComponentProps

interface State {
  lang: string
  sort: string
}

class CalendarDialog extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      lang: props.locale.appLanguage.code || 'fi-FI',
      sort: 'ASC',
    }
  }

  render() {
    const { open = false, calendarUrl, locale } = this.props

    if (!calendarUrl) {
      return null
    }

    const languages = locale.availableLanguages

    return (
      <>
        <Dialog
          open={open}
          onClose={(_, reason) => reason !== 'backdropClick' && this._handleDismiss()}
          aria-labelledby="calendar"
          maxWidth="md"
        >
          <BaseDialogTitle
            style={{ marginBottom: 10 }}
            id={'calendar-dialog'}
            title={<FormattedMessageWrapper id={'tournaments.calendarCode'} />}
            onClose={this._handleDismiss}
          />

          <DialogContent>
            <Typography variant="body1">
              <FormattedMessageWrapper id="tournaments.calendarCodeInfo" />
            </Typography>
            <Card style={{ marginTop: 15, marginBottom: 20 }}>
              <CardContent>
                <code style={{ fontFamily: 'monospace', fontSize: '80%' }}>
                  &lt;iframe src="{`${calendarUrl}?lang=${this.state.lang}&sort=${this.state.sort}`}"
                  id=&quot;ggb-tm-calendar&quot; width=&quot;600&quot; height=&quot;300&quot;
                  style=&quot;border:none;overflow:hidden&quot; scrolling=&quot;no&quot; frameborder=&quot;0&quot;
                  allowTransparency=&quot;true&quot; allow=&quot;encrypted-media&quot;&gt;&lt;/iframe&gt; &lt;script
                  type=&quot;text/javascript&quot;&gt;
                  {'var eventMethod=window.addEventListener?"addEventListener":' +
                    '"attachEvent",eventer=window[eventMethod],messageEvent="attachEvent"==eventMethod?"onmessage":' +
                    '"message";eventer(messageEvent,function(e){isNaN(e.data)||(document.getElementById' +
                    '("ggb-tm-calendar").style.height=e.data+"px")},!1);'}
                  &lt;/script&gt;
                </code>
              </CardContent>
            </Card>

            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h3" style={{ marginBottom: 15 }}>
                  <FormattedMessageWrapper id="tournaments.preview" />
                </Typography>

                <iframe
                  id="ggb-tm-calendar"
                  title="calendarPreview"
                  src={`${calendarUrl}?lang=${this.state.lang}&sort=${this.state.sort}`}
                  width="600"
                  height="300"
                  style={{ border: '1px solid #1aa85d', overflow: 'hidden' }}
                  scrolling="no"
                  allowTransparency={true}
                  allow="encrypted-media"
                ></iframe>
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ marginBottom: 15 }}>
                  <FormattedMessageWrapper id="tournaments.calendarSettings" />
                </Typography>

                <Typography variant="body1">
                  <strong>
                    <FormattedMessageWrapper id="tournaments.selectLanguage" />
                  </strong>
                </Typography>

                <FormControl>
                  <Select
                    value={this.state.lang}
                    input={<Input id={'lang'} name={'lang'} />}
                    onChange={(event) => {
                      this.setState({ lang: event.target.value })
                    }}
                  >
                    {languages.map((language: LanguageOption, index: number) => (
                      <MenuItem key={index} value={language.code}>
                        <img src={flag(language.code)} style={{ width: 30, padding: '5px 5px 0 5px' }} alt="" />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography variant="body1" style={{ marginTop: 15 }}>
                  <strong>
                    <FormattedMessageWrapper id="tournaments.selectSortOrder" />
                  </strong>
                </Typography>

                <FormControl>
                  <Select
                    value={this.state.sort}
                    input={<Input id={'sort'} name={'sort'} />}
                    onChange={(event) => {
                      this.setState({ sort: event.target.value })
                    }}
                  >
                    <MenuItem value={'ASC'}>
                      <FormattedMessageWrapper id="tournaments.asc" />
                    </MenuItem>
                    <MenuItem value={'DESC'}>
                      <FormattedMessageWrapper id="tournaments.desc" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={this._handleDismiss}>
              <FormattedMessageWrapper id={'buttons.close'} />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  public _handleDismiss = (): void => {
    this._handleClose()
  }

  public _handleClose = (): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
}

export default injectIntl(
  connect<StateProps, {}, OwnProps, RootState>((store) => ({
    calendarUrl: store.tournamentsReducer.calendarUrl,
    locale: store.localeReducer,
  }))(CalendarDialog),
)
