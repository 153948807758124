import React from 'react'
import { Button, CircularProgress } from '@mui/material'

interface SubmitButtonProps {
  children: React.ReactNode
  isLoading: boolean
  id?: string
  disabled?: boolean
  fullWidth?: boolean
  className?: string
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ children, isLoading, id, disabled, fullWidth, className }) => {
  const elementId = id || Math.random().toString(36).substring(7)
  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      disabled={isLoading || disabled}
      id={elementId}
      className={className}
      fullWidth={fullWidth}
    >
      {children}
      {isLoading && <CircularProgress size={24} style={{ position: 'absolute', color: '#fff' }} />}
    </Button>
  )
}

export default SubmitButton
