import React from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import Add from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import { rem } from '../../theme/materialUITheme'
import classNames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    buttonBase: {
      backgroundColor: theme.customPalette.background,
      padding: '6px 12px',
    },
    label: {
      fontSize: rem(15),
      color: theme.palette.primary.main,
      letterSpacing: 0,
      marginLeft: 13,
      alignSelf: 'center',
    },
    disabled: {
      color: theme.typography.body2.color,
    },
  })

interface Props extends WithStyles<typeof styles> {
  onClick?: any
  style?: React.CSSProperties
  label?: React.ReactNode
  disabled?: boolean
}

class AddRowAction extends React.Component<Props> {
  render(): React.ReactNode {
    const { classes, onClick, style, label, disabled = false } = this.props

    return (
      <span className={classes.root} style={style} onClick={!disabled && onClick}>
        <Fab color="primary" aria-label="Add" size={'small'} disabled={disabled}>
          <Add />
        </Fab>
        {Boolean(label) && <span className={classNames(classes.label, disabled && classes.disabled)}>{label}</span>}
      </span>
    )
  }
}

export default withStyles(styles)(AddRowAction)
