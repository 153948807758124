import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActions, FormLabel } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { createUser, deleteUser, updateUser } from '../../../store/organizationUser/actions'
import IconButton from '@mui/material/IconButton'
import DeleteConfirm, { DeleteConfirmChildren } from '../deleteConfirm/DeleteConfirm'
import { Delete } from '@mui/icons-material'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import OrganizationPicker from '../../user/OrganizationPicker'
import UserRolesPicker from '../../user/UserRolesPicker'
import { hasRoleCustomerEventUser, isRoleInfoProviderAdmin } from '@utils/authUtils'
import { TournamentPicker } from '../../tournament/TournamentPicker'
import { enqueueNotification } from '@store/notifications/actions'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { RootState } from '@app/store'

interface OwnProps {
  showRolePicker?: boolean
  showOrganizationPicker?: boolean
  organizationId?: number
  open?: boolean
  user?: OrganizationUser
  onClose?(refetch?: boolean): void
}

interface StateProps {
  isLoading: boolean
  auth: AuthenticationState
}

interface DispatchProps {
  createUser(user: UserPayload, organizationId: number, onComplete?: (args: APICallResult) => void): void
  updateUser(
    userId: number,
    organizationId: number,
    user: UserPayload,
    onComplete?: (args: APICallResult) => void,
  ): void
  deleteUser(organizationId: number, userId: number, onComplete?: (args: APICallResult) => void): void
  enqueueNotification(error: any, variant?: string): void
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps

interface State {
  user: Partial<UserInfo>
  organizationId?: number
  roles: RoleInfo[]
  events: number[]
  errors: string[]
}

const initialUserState: Partial<UserInfo> = {
  uid: undefined,
  name: '',
  username: '',
  password: '',
  passwordConfirm: '',
  userStatus: 'ENABLED',
}

const initialState: State = {
  user: initialUserState,
  organizationId: undefined,
  roles: [],
  events: [],
  errors: [],
}

class OrganizationUserDialog extends React.Component<Props, State> {
  readonly state: State = initialState

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.user && props.user && state.user.uid !== props.user.userInfo.uid) {
      return {
        user: { ...props.user.userInfo },
        organizationId: props.user.customerInfo.idCustomer,
        roles: props.user.roles,
        events: props.user.events || [],
      }
    }

    return null
  }

  render() {
    const { open = false } = this.props
    const { user } = this.state

    return (
      <Dialog
        open={open}
        onClose={(_, reason) => reason !== 'backdropClick' && this._handleDismiss()}
        aria-labelledby="organization-user-dialog-title"
      >
        <BaseDialogTitle
          style={{ marginBottom: 10 }}
          id={'organization-user-dialog-title'}
          title={
            user.uid ? (
              <FormattedMessageWrapper id={'tournament.updateUser'} />
            ) : (
              <FormattedMessageWrapper id={'tournament.createUser'} />
            )
          }
          onClose={this._handleDismiss}
        />

        <DialogContent>{this._renderForm()}</DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={this._handleDismiss} disabled={this.props.isLoading}>
            <FormattedMessageWrapper id={'buttons.cancel'} />
          </Button>
          <div>
            {this.state.user.uid && (
              <DeleteConfirm>
                {({ showConfirm }: DeleteConfirmChildren) => (
                  <IconButton
                    onClick={() => showConfirm({ callback: this._handleDelete })}
                    style={{ marginRight: 24 }}
                    size="large"
                  >
                    <Delete color={'error'} />
                  </IconButton>
                )}
              </DeleteConfirm>
            )}
            <ButtonLoaderWrap loading={this.props.isLoading}>
              <Button
                disabled={this.props.isLoading}
                onClick={this._handleUpdate}
                color="primary"
                variant={'contained'}
              >
                <FormattedMessageWrapper id={'buttons.save'} />
              </Button>
            </ButtonLoaderWrap>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  public _handleDelete = () => {
    const {
      user: { uid },
    } = this.state
    if (uid) {
      this.props.deleteUser(this._organizationId, uid, () => this._handleClose(true))
    }
  }

  public _handleUpdate = () => {
    this._clearErrors()

    const { updateUser, createUser, showRolePicker = true, enqueueNotification } = this.props
    const {
      user: { uid, userStatus, name, username, password, passwordConfirm },
      events,
    } = this.state
    const roleIds = this.state.roles.map((r) => r.id)

    if (!username || !/^\S+@\S+\.\S+$/.test(username)) {
      this._addError('username')
    }
    if (!name) {
      this._addError('name')
    }
    if (!userStatus) {
      this._addError('userStatus')
    }
    if (showRolePicker && roleIds.length === 0) {
      this._addError('roles')
    }
    if (hasRoleCustomerEventUser(this.state.roles) && events.length === 0) {
      this._addError('tournaments')
    }

    if (password !== passwordConfirm) {
      enqueueNotification(this.props.intl.formatMessage({ id: 'errors.passwordMismatch' }), 'error')
      this.setState((prevState) => {
        prevState.errors.push('password')
        return { errors: prevState.errors }
      })
      return
    } else {
      this.setState((prevState) => {
        return { errors: prevState.errors.filter((e) => e !== 'password') }
      })
    }

    if (
      name &&
      username &&
      userStatus &&
      !(showRolePicker && roleIds.length === 0) &&
      !(hasRoleCustomerEventUser(this.state.roles) && events.length === 0) &&
      /^\S+@\S+\.\S+$/.test(username)
    ) {
      if (uid) {
        updateUser(
          uid,
          this._organizationId,
          { name, email: username, status: userStatus, roleIds, events, password },
          () => this._handleClose(true),
        )
      } else {
        createUser({ name, email: username, status: userStatus, roleIds, events, password }, this._organizationId, () =>
          this._handleClose(true),
        )
      }
    }
  }

  private _clearErrors = () => {
    this.setState({ errors: [] })
  }

  private _addError = (name: string) => {
    this.setState((prevState) => {
      prevState.errors.push(name)
      return { errors: prevState.errors }
    })
  }

  public _handleDismiss = (): void => {
    this._handleClose(false)
  }

  public _handleClose = (refetch = false): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose(refetch)
      this.setState(initialState)
    }
  }

  public _handleChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      user: {
        ...this.state.user,
        [name]: e.target.value,
      },
    })
    if (name.length > 0) {
      this.setState((prevState) => {
        return { errors: prevState.errors.filter((e) => e !== name) }
      })
    }
  }

  get _organizationId() {
    if (this.state.organizationId) {
      return this.state.organizationId
    } else if (this.props.organizationId) {
      return this.props.organizationId
    } else {
      return 1
    }
  }

  private _renderForm = () => {
    const { isLoading, showOrganizationPicker, showRolePicker = true, auth } = this.props
    const { user } = this.state

    return (
      <>
        {showOrganizationPicker && (
          <OrganizationPicker
            margin={'normal'}
            disabled={isLoading}
            organizationId={this.state.organizationId}
            onChange={(organizationId: number) => {
              this.setState({ organizationId })
            }}
          />
        )}

        {showRolePicker && (
          <UserRolesPicker
            error={this.state.errors.includes('roles')}
            margin={'normal'}
            disabled={isLoading}
            value={this.state.roles}
            onChange={(roles) => {
              if (roles) {
                if (roles instanceof Array) {
                  this.setState({ roles })
                } else {
                  this.setState({ roles: [roles] })
                }
              }
            }}
          />
        )}

        {hasRoleCustomerEventUser(this.state.roles) && (
          <TournamentPicker
            error={this.state.errors.includes('tournaments')}
            margin={'normal'}
            disabled={isLoading}
            value={this.state.events}
            onChange={(_, items) => {
              this.setState({ events: items })
            }}
          />
        )}

        <FormLabel htmlFor="name" error={this.state.errors.includes('name')}>
          <FormattedMessageWrapper id={'tournament.name'} /> *
        </FormLabel>
        <TextField
          error={this.state.errors.includes('name')}
          margin={'normal'}
          fullWidth={true}
          disabled={isLoading}
          id={'name'}
          value={this.state.user.name}
          onChange={this._handleChange('name')}
          style={{ marginTop: 0, marginBottom: 10 }}
        />

        <FormLabel htmlFor="username" error={this.state.errors.includes('username')}>
          <FormattedMessageWrapper id={'tournament.emailUsername'} /> *
        </FormLabel>
        <TextField
          error={this.state.errors.includes('username')}
          margin={'normal'}
          fullWidth={true}
          disabled={isLoading}
          id={'username'}
          name={'username'}
          value={this.state.user.username}
          onChange={this._handleChange('username')}
          style={{ marginTop: 0, marginBottom: 10 }}
        />

        {isRoleInfoProviderAdmin(auth.roleInfo) && (
          <>
            <FormLabel htmlFor="password" error={this.state.errors.includes('password')}>
              <FormattedMessageWrapper id="login.password" />
            </FormLabel>
            <TextField
              error={this.state.errors.includes('password')}
              margin={'normal'}
              fullWidth={true}
              disabled={isLoading}
              id={'password'}
              name={'password'}
              defaultValue={''}
              type={'password'}
              autoComplete={'off'}
              value={this.state.user.password}
              onChange={this._handleChange('password')}
              style={{ marginTop: 0, marginBottom: 10 }}
            />
            <FormLabel htmlFor="passwordConfirm" error={this.state.errors.includes('password')}>
              <FormattedMessageWrapper id="tournament.passwordConfirm" />
            </FormLabel>
            <TextField
              error={this.state.errors.includes('password')}
              margin={'normal'}
              fullWidth={true}
              disabled={isLoading}
              id={'passwordConfirm'}
              name={'passwordConfirm'}
              defaultValue={''}
              type={'password'}
              autoComplete={'off'}
              value={this.state.user.passwordConfirm}
              onChange={this._handleChange('passwordConfirm')}
              style={{ marginTop: 0, marginBottom: 10 }}
            />
          </>
        )}
        <FormControl
          style={{ display: 'none' }}
          fullWidth={true}
          disabled={isLoading}
          margin={'normal'}
          error={this.state.errors.includes('userStatus')}
        >
          <FormLabel htmlFor={'user-status'}>
            <FormattedMessageWrapper id={'tournament.userStatus'} /> *
          </FormLabel>
          <Select
            value={user.userStatus}
            input={<Input id={'user-status'} name={'user-status'} />}
            onChange={(event) => {
              this.setState({
                user: {
                  ...this.state.user,
                  userStatus: event.target.value as OrganizationUserStatus,
                },
              })
            }}
            style={{ marginTop: 0, marginBottom: 10 }}
          >
            <MenuItem value={'ENABLED'}>
              <FormattedMessageWrapper id={'options.enabled'} />
            </MenuItem>
            <MenuItem value={'DISABLED'}>
              <FormattedMessageWrapper id={'options.disabled'} />
            </MenuItem>
          </Select>
        </FormControl>
      </>
    )
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    isLoading: store.organizationUserReducer.loading,
    auth: store.authenticationReducer,
  }),
  {
    createUser,
    updateUser,
    deleteUser,
    enqueueNotification,
  },
)(injectIntl(OrganizationUserDialog))
