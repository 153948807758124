import { useCallback, useEffect, useState } from 'react'
import UploadedImage from './UploadedImage'
import { Grid, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SortableContainer, SortableElement, SortableElementProps, SortableContainerProps } from 'react-sortable-hoc'
import { Cancel } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: 'contents',
  },
  emptyImage: {
    border: '1px dashed',
    marginRight: 10,
    width: 100,
    height: 100,
  },
  sortableHelper: {
    zIndex: 999,
  },
  sortableItem: {
    display: 'inline-flex',
    position: 'relative',
    marginTop: 20,
    marginRight: 20,
    cursor: 'grab',
  },
  cancelBtn: {
    position: 'absolute',
    top: -15,
    right: -10,
    zIndex: 1,
    cursor: 'default',
  },
}))

interface Props {
  images: any[]
  image?: TournamentImage
  onDelete: (item: any) => void
  onDragEnd: (movedImageId: number, imageType: string, order: number) => void
}

interface SortableItemProps extends SortableElementProps {
  value: GameFeedImage
}

interface SortableListProps extends SortableContainerProps {
  items: GameFeedImage[]
}

export const DraggableImages = ({ images, image, onDelete, onDragEnd }: Props) => {
  const classes = useStyles()
  const [imageList, updateImageList] = useState<GameFeedImage[]>([])

  const sortImages = useCallback(
    (imgs) =>
      imgs.slice().sort((prev: any, next: any) => {
        if (!prev.order) {
          return 1
        }
        if (prev.order < next.order) {
          return -1
        }
        return 0
      }),
    [],
  )

  useEffect(() => {
    updateImageList(sortImages(images))
  }, [images, sortImages])

  const SortableItem: React.ComponentClass<SortableItemProps, any> = SortableElement(({ value }) => (
    <Tooltip title={value.isRemoved ? <FormattedMessage id="tournament.imageIsRemoved" /> : ''}>
      <div
        className={classes.sortableItem}
        style={{ opacity: value.isRemoved ? 0.5 : 1, cursor: value.isRemoved ? 'default' : undefined }}
      >
        {!value.isRemoved && (
          <Cancel
            sx={{ fs: 'default' }}
            className={classes.cancelBtn}
            onClick={() => {
              if (onDelete) {
                onDelete(image || value)
              }
            }}
          />
        )}
        <UploadedImage image={image || value} onDelete={onDelete} droppableImage={true} />
      </div>
    </Tooltip>
  ))

  const SortableList: React.ComponentClass<SortableListProps, any> = SortableContainer(({ items }) => {
    return (
      <Grid container>
        {items.map((img: GameFeedImage, idx: number) => {
          return <SortableItem key={img.id} index={idx} value={img} disabled={img.isRemoved} />
        })}
      </Grid>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (newIndex !== oldIndex) {
      const oldImgPos = imageList[oldIndex]
      const newImgPos = imageList[newIndex]
      const newImageList = [...imageList]
      const [removedItem]: GameFeedImage[] = newImageList.splice(oldIndex, 1)
      newImageList.splice(newIndex, 0, removedItem)
      updateImageList(newImageList)
      onDragEnd(oldImgPos.id, oldImgPos.type, newImgPos.order)
    }
    document.body.style.cursor = ''
  }

  const onSortStart = () => {
    document.body.style.cursor = 'grabbing'
  }

  return (
    <SortableList
      items={imageList}
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      axis="xy"
      helperClass={classes.sortableHelper}
      distance={1}
    />
  )
}
