import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import get from 'lodash/get'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Grid, FormControl, Theme, OutlinedInput, MenuItem, Switch, FormLabel, Button, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { updateRoundField, removeRound, getTournament } from '@app/store/api/thunks/tournamentThunks'
import { loadTournamentLeaderboard, LoadTournamentLeaderboardArgs } from '@store/tournamentLeaderboard/actions'

import { fetchGameFormats } from '@app/store/gameFormats/actions'
import TournamentFormActions from '@app/components/tournament/TournamentFormActions'
import SectionTitle from '@app/components/ui/SectionTitle'
import { scrollToTop } from '@app/components/ui'
import HeadHelmet from '@app/components/layout/HeadHelmet'
import { enqueueNotification } from '@app/store/notifications/actions'
import FormLabelWithInfo from '@app/components/ui/FormLabelWithInfo'
import SelectBox from '@app/components/ui/SelectBox'
import ContentWrap from '@app/components/layout/ContentWrap'
import InfoTooltip from '@app/components/ui/InfoTooltip'
import { RoundTiming } from './round-timing'
import { Contests } from './contests'
import { CourseSettings } from './CourseSettings'
import { rem } from '@app/theme/materialUITheme'
import { SectionWrapper } from '@app/components/layout/SectionWrapper'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { getNextUrl } from '@app/utils/nextUrlHelper'
import { RoundSelection } from '@app/components/tournament/round-selection'
import { GameFormatSelection } from './game-format-selection'
import { confirm } from '@app/components/dialogs/confirm/Confirm'
import { setTournamentNeedsUpdate, setTournamentDirty, selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { updateTournamentField } from '@app/store/api/thunks/tournamentThunks'
import { fetchAllStartLists } from '@app/store/api/thunks/tournamentStartListsThunks'
import { tournamentRoundApi } from '@app/store/api/endpoints/tournamentRoundApi'
import { selectTournamentTeams, tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { saveTournamentRound } from '@app/store/api/thunks/tournamentRoundThunks'
import { RootState } from '@app/store'
import { selectTournamentSettings, tournamentSettingsApi } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'
import { setSelectedCourseVersion } from '@app/store/api/thunks/clubsAndCoursesThunks'
import { removeTimeZoneFromDate } from '@app/utils/dates'
import { OverlayLoader } from '@app/components/ui/OverlayLoader'

const styles = (theme: Theme) =>
  createStyles({
    codesLabel: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
    sectionTitle: {
      color: theme.palette.text.secondary,
    },
    sectionTitleDisabled: {
      color: theme.palette.text.disabled,
    },
    codeWrapper: {
      marginBottom: theme.spacing(1),
      border: `1px solid ${theme.customPalette.border}`,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
    },
    codeContent: {
      display: 'block',
    },
    codeCopyWrapper: {
      display: 'block',
      '& > button': {
        fontSize: rem(12),
        minWidth: '0',
        padding: `${theme.spacing(0.4, 1)}`,
      },
    },
    selectBox: {
      width: 60,
      margin: 0,
    },
    gridBox: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    sectionTitleMultiRound: {
      marginBottom: rem(15),
      color: theme.palette.secondary.main,
    },
  })

interface StateProps {
  auth: AuthenticationState
  tournament: TournamentState
  tournamentConfig: TournamentConfig
  tournamentStartLists: TournamentStartListState
  tournamentLeaderboard: TournamentLeaderboardState
  tournamentSettings: TournamentSettings
  teams: TournamentTeam[]
  settings: TournamentSettings
  organization: OrganizationState
}

interface DispatchProps {
  updateRoundField: (payload: UpdateRoundFieldPayload, setDirty?: boolean) => void
  updateTournamentField(payload: FieldUpdatePayload): void
  fetchAllStartLists(): void
  getTournament(payload: TournamentPayload): void
  fetchGameFormats: () => void
  saveTournamentRound: (payload: TournamentRoundPayload) => void
  enqueueNotification(error: any, variant?: string): void
  loadTournamentTeams(id: number): void
  setTournamentDirty(dirty: boolean): void
  saveTournamentSettings(payload: UpdateTournamentSettingsPayload): void
  deleteRound: (payload: DeleteRoundPayload) => void
  setTournamentNeedsUpdate: (needsUpdate: boolean) => void
  removeRound: () => void
  loadTournamentLeaderboard: (args: LoadTournamentLeaderboardArgs) => void
  setSelectedCourseVersion: (version: number) => void
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps & WithStyles<typeof styles>

interface State {
  isJoinCodeCopied: boolean
  isViewCodeCopied: boolean
  isFormDisabled: boolean
  isManuallySavedRound: boolean
  isLeaderboardFetched: boolean
}

class UnroutedRoundSetup extends React.Component<Props, State> {
  readonly state: State = {
    isJoinCodeCopied: false,
    isViewCodeCopied: false,
    isFormDisabled: false,
    isManuallySavedRound: false,
    isLeaderboardFetched: false,
  }

  componentDidMount(): void {
    const id = get(this.props, 'params.id')
    const tournamentId = typeof id === 'string' ? parseInt(id, 10) : id
    this.props.fetchGameFormats()
    if (tournamentId) {
      this.props.getTournament({
        id: tournamentId,
        onSuccess: () => {
          this.props.setTournamentDirty(false)
        },
      })
      this.props.loadTournamentTeams(tournamentId)
      this.props.fetchAllStartLists()
    }

    this._maybeFetchLeaderboard()
    this._maybeSetTournamentDirty()
  }

  componentDidUpdate(prevProps: Props): void {
    this._maybeFetchLeaderboard()
    this._maybeSetTournamentDirty(prevProps)
    this._maybeShowCourseUpdatedDialog(prevProps.tournament)
  }

  render() {
    const { tournament, tournamentConfig, classes } = this.props
    const selectedRound = this._getSelectedRound()
    const { selectedRoundIndex, status } = tournamentConfig
    const { isTournamentLoading, isTournamentStartListsLoading } = status
    const shouldShowOverLay = isTournamentLoading || !selectedRound || isTournamentStartListsLoading
    const removeRoundHandler =
      tournament.tournamentType === TournamentTypes.multiRound && tournament.rounds.length > 1
        ? this.onRemoveRoundClick
        : undefined

    return (
      <>
        <OverlayLoader visible={shouldShowOverLay} />
        {tournament.tournamentType === TournamentTypes.multiRound && (
          <SectionWrapper>
            <ContentWrap>
              <RoundSelection />
            </ContentWrap>
          </SectionWrapper>
        )}
        <SectionWrapper>
          <HeadHelmet titleId={'progress.roundSetup'} />

          <ContentWrap divider>
            {tournament.tournamentType === TournamentTypes.multiRound ? (
              <SectionTitle className={classes.sectionTitleMultiRound}>
                <FormattedMessageWrapper id="tournament.roundCount" values={{ round: selectedRoundIndex + 1 }} />
              </SectionTitle>
            ) : (
              <SectionTitle style={{ marginBottom: 15 }}>
                <FormattedMessageWrapper id="tournament.roundSetup" />
              </SectionTitle>
            )}
            {this._renderRoundConfig(selectedRound)}
          </ContentWrap>

          <ContentWrap divider>
            <SectionTitle
              style={{ marginBottom: 15 }}
              className={this.state.isFormDisabled ? classes.sectionTitleDisabled : classes.sectionTitle}
            >
              <FormattedMessageWrapper id="tournament.courseSettings" />
            </SectionTitle>
            <CourseSettings
              selectedRoundIndex={selectedRoundIndex}
              selectedRound={selectedRound}
              disabled={this.state.isFormDisabled}
            />
          </ContentWrap>

          <GameFormatSelection disabled={this.state.isFormDisabled} />

          {selectedRound &&
            [TournamentTypes.weekly, TournamentTypes.multiRound].includes(
              tournament.tournamentType as TournamentTypes,
            ) === false && (
              <ContentWrap>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <div>
                    <SectionTitle className={classes.sectionTitle}>
                      <FormattedMessageWrapper id="tournament.contests" />
                      <InfoTooltip
                        style={{ marginLeft: 10 }}
                        text={<FormattedMessageWrapper id="tournament.contestInfo" />}
                      />
                      <FormControl style={{ marginLeft: 15 }}>
                        <Switch
                          checked={selectedRound.contestsEnabled}
                          name="contestsEnabled"
                          color="primary"
                          onChange={() => {
                            this.props.updateRoundField({
                              index: selectedRoundIndex,
                              fieldName: 'contestsEnabled',
                              value: !selectedRound.contestsEnabled,
                            })
                          }}
                        />
                      </FormControl>
                    </SectionTitle>
                  </div>
                </Grid>
              </ContentWrap>
            )}
          {selectedRound && selectedRound.contestsEnabled && (
            <ContentWrap>
              <Contests round={selectedRound} />
            </ContentWrap>
          )}

          <ContentWrap style={{ background: 'transparent' }}>
            <TournamentFormActions
              nextLabelId={'buttons.tournamentSite'}
              style={{ marginTop: 0 }}
              onClickNext={() => this.onClick(true)}
              onClickSaveAndPublish={() => this.onClick(false)}
              onClickRemoveRound={removeRoundHandler}
            />
          </ContentWrap>
        </SectionWrapper>
      </>
    )
  }

  private _saveRounds = (moveToNextTab = false): void => {
    if (!this._validatePage) {
      return
    }
    this.setState({ isManuallySavedRound: true })
    this.props.saveTournamentRound({
      tournamentId: this.props.tournament.id,
      body: this._getSelectedRound(),
      onSuccess: () => {
        if (moveToNextTab) {
          const nextUrl = getNextUrl(this.props.location.pathname, 'tournament-site')
          this.props.navigate(nextUrl)
          scrollToTop()
        }
      },
    })
  }

  private _maybeShowCourseUpdatedDialog = (originalTournament: TournamentState) => {
    const { tournamentConfig } = this.props
    const { selectedRoundIndex } = tournamentConfig
    const originalCourse = originalTournament.rounds[selectedRoundIndex]?.course
    const newCourse = this._getSelectedRound()?.course
    const differentCourseVersion =
      originalCourse?.id === newCourse?.id && originalCourse?.version !== newCourse?.version

    if (differentCourseVersion && newCourse?.version && this.state.isManuallySavedRound) {
      this.props.setSelectedCourseVersion(newCourse.version)
      this.setState({ isManuallySavedRound: false })
      confirm({
        message: this.props.intl.formatMessage({ id: 'tournament.courseUpdated' }),
        options: {
          okText: this.props.intl.formatMessage({ id: 'buttons.ok' }),
        },
      })
    }
  }

  onClick = (moveToNextTab: boolean) => {
    const tournamentId = this.props.tournament.id
    this.props.saveTournamentSettings({
      id: tournamentId,
      body: this.props.tournamentSettings,
      onSuccess: () => {
        this._saveRounds(moveToNextTab)
      },
    })
  }

  onRemoveRoundClick = () => {
    const tournamentId = this.props.tournament.id
    const selectedRound = this._getSelectedRound()

    if (!tournamentId) {
      return
    }
    confirm({
      message: this.props.intl.formatMessage(
        { id: 'tournament.removeRoundConfirm' },
        { round: selectedRound.roundNumber },
      ),
      options: {
        title: this.props.intl.formatMessage({ id: 'tournament.removeRoundConfirmTitle' }),
        cancelText: this.props.intl.formatMessage({ id: 'buttons.cancel' }),
        okText: this.props.intl.formatMessage({ id: 'buttons.ok' }),
      },
    }).then(() => {
      const roundId = selectedRound.id
      //Remove round from store
      this.props.removeRound()
      const isUnsavedRound = roundId === -1
      if (isUnsavedRound) {
        return
      }
      this.props.deleteRound({
        tournamentId,
        roundId: roundId,
        onSuccess: () => {
          this.props.fetchAllStartLists()
          this.props.setTournamentNeedsUpdate(true)
        },
      })
    })
  }

  private get _validatePage() {
    const { selectedRoundIndex, invalidStartTime } = this.props.tournamentConfig
    if (
      this.props.tournament.rounds[selectedRoundIndex].sideGameEnabled &&
      !this.props.tournament.rounds[selectedRoundIndex].sideGameId
    ) {
      this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'gameFormats.sideGameWarning' }), 'error')
      return false
    }

    const invalidRound = this.props.tournament.rounds.filter((round) => !round.courseId || !round.countryId)
    if (invalidRound.length > 0) {
      this.props.enqueueNotification(
        this.props.intl.formatMessage({ id: 'errors.invalidClubAndCourseSettings' }),
        'error',
      )
      return false
    }

    if (invalidStartTime) {
      this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'tournament.invalidDate' }), 'error')
      return false
    }

    return true
  }

  private _maybeSetTournamentDirty = (prevProps?: Props) => {
    const isNewTournament = this.props.tournament.rounds[0]?.id < 0
    if (isNewTournament && !this.props.tournamentConfig.isDirty) {
      this.props.setTournamentDirty(true)
    }

    const firstRoundSaved = prevProps?.tournament.rounds[0]?.id !== this.props.tournament.rounds[0]?.id
    if (firstRoundSaved) {
      this.props.setTournamentDirty(false)
    }
  }

  private _maybeFetchLeaderboard = () => {
    const isWeeklyTournament = this.props.tournament.tournamentType === TournamentTypes.weekly
    if (this.props.tournament.id > 0 && isWeeklyTournament && !this.state.isLeaderboardFetched) {
      this.props.loadTournamentLeaderboard({ id: this.props.tournament.id, onComplete: this._maybeDisableForm })
      this.setState({ isLeaderboardFetched: true })
    }
  }

  private _maybeDisableForm = (result: APICallResult) => {
    const leaderboard: TournamentLeaderboard = result.data.data
    const isWeeklyTournament = this.props.tournament.tournamentType === TournamentTypes.weekly

    if (isWeeklyTournament && leaderboard && this.state.isFormDisabled === false) {
      const players = leaderboard?.primary?.players || []
      const shouldDisableForm = players.some((p: TournamentLeaderboardPlayer) => p.toPar !== '')
      if (shouldDisableForm) {
        this.setState({ isFormDisabled: true })
      }
    }
  }

  private _getSelectedRound = (): Round => {
    const { tournamentConfig, tournament } = this.props
    const { selectedRoundIndex } = tournamentConfig
    return tournament.rounds[selectedRoundIndex]
  }

  private _onDateChange = (fieldName: string) => {
    const { selectedRoundIndex } = this.props.tournamentConfig
    return (date: DateTimeValue) => {
      if (date) {
        const payload = { fieldName, value: date }

        this.props.updateRoundField({
          index: selectedRoundIndex,
          ...payload,
        })

        this.props.updateTournamentField(payload)
      }
    }
  }

  private _onHideResultsFromLastHolesChange = (event): void => {
    const { selectedRoundIndex } = this.props.tournamentConfig
    const value = event.target.value
    this.props.updateRoundField({
      index: selectedRoundIndex,
      fieldName: 'hideResultsFromLastHoles',
      value: value,
    })
  }

  private _renderTournamentCodes = (tournament: TournamentState) => {
    const { classes, intl } = this.props
    if (!tournament) {
      return null
    }
    return (
      <>
        {
          <div className={classes.codeWrapper}>
            <div className={classes.codeContent}>
              <FormLabel className={classes.codesLabel}>
                <FormattedMessageWrapper id={`tournaments.joinCode`} />:
              </FormLabel>
              <Typography>
                {tournament.joinCode ? tournament.joinCode : intl.formatMessage({ id: 'tournament.emptyCode' })}
              </Typography>
            </div>
            {tournament.joinCode && (
              <div className={classes.codeCopyWrapper}>
                <CopyToClipboard text={tournament.joinCode} onCopy={() => this.setState({ isJoinCodeCopied: true })}>
                  <Button variant="outlined">
                    {this.state.isJoinCodeCopied
                      ? intl.formatMessage({ id: 'tournament.copied' })
                      : intl.formatMessage({ id: 'tournament.copy' })}
                  </Button>
                </CopyToClipboard>
              </div>
            )}
          </div>
        }
        {
          <div className={classes.codeWrapper}>
            <div className={classes.codeContent}>
              <FormLabel className={classes.codesLabel}>
                <FormattedMessageWrapper id={`tournaments.viewCode`} />:
              </FormLabel>
              <Typography>
                {tournament.viewCode ? tournament.viewCode : intl.formatMessage({ id: 'tournament.emptyCode' })}
              </Typography>
            </div>
            {tournament.viewCode && (
              <div className={classes.codeCopyWrapper}>
                <CopyToClipboard text={tournament.viewCode} onCopy={() => this.setState({ isViewCodeCopied: true })}>
                  <Button variant="outlined">
                    {this.state.isViewCodeCopied
                      ? intl.formatMessage({ id: 'tournament.copied' })
                      : intl.formatMessage({ id: 'tournament.copy' })}
                  </Button>
                </CopyToClipboard>
              </div>
            )}
          </div>
        }
      </>
    )
  }

  private _renderRoundConfig = (round: Round) => {
    const { tournamentType } = this.props.tournament
    const { startTime, endTime, hideResultsFromLastHoles } = round
    const { units = 'metric' } = this.props.auth
    const { classes, auth } = this.props
    const { subscription, license } = auth
    const subscriptionEndDate = subscription.validTo
      ? new Date(removeTimeZoneFromDate(subscription.validTo))
      : undefined
    const startTimeDisabled = subscription.active === false && license.active === false

    return (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <RoundTiming
            startTime={startTime}
            endTime={endTime}
            isWeekly={tournamentType === TournamentTypes.weekly}
            units={units}
            maxDate={subscriptionEndDate}
            onDateChange={this._onDateChange}
            startTimeDisabled={startTimeDisabled || this.state.isFormDisabled}
          />
        </Grid>
        <Grid item xs={4}>
          {tournamentType !== TournamentTypes.weekly && this._renderTournamentCodes(this.props.tournament)}
        </Grid>
        {[TournamentTypes.multiRound, TournamentTypes.weekly].includes(tournamentType as TournamentTypes) === false && (
          <Grid item xs={4} className={classes.gridBox}>
            <FormControl>
              <FormLabelWithInfo
                text={<FormattedMessageWrapper id="tournament.hideLastHoles" />}
                infoText={<FormattedMessageWrapper id="tournament.hideResultsFromLastHolesInfo" />}
              />
              <SelectBox
                id="hideResultsFromLastHoles"
                name="hideResultsFromLastHoles"
                onChange={this._onHideResultsFromLastHolesChange}
                value={hideResultsFromLastHoles || 0}
                inputElement={OutlinedInput}
                autoWidth
                className={classes.selectBox}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </SelectBox>
            </FormControl>
          </Grid>
        )}
      </Grid>
    )
  }

  public _mapHoleNumberToHoleId = (holeNumber: number): number | undefined => {
    const { tournament, tournamentConfig } = this.props
    const { selectedRoundIndex } = tournamentConfig

    const selectedRound = tournament.rounds[selectedRoundIndex]
    const courseHoles = selectedRound.course?.holes

    const findId = courseHoles?.find((hole) => hole.holeNumber === holeNumber)
    return findId && findId.id
  }
}

const RoutedRoundSetup = injectIntl(withRouter(UnroutedRoundSetup))
const StyledRoundSetup = withStyles(styles)(RoutedRoundSetup)
export const RoundSetup = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => {
    return {
      auth: state.authenticationReducer,
      settings: selectTournamentSettings(state),
      tournament: selectTournament(state),
      tournamentConfig: selectTournamentConfig(state),
      tournamentStartLists: selectTournamentStartLists(state),
      tournamentLeaderboard: state.tournamentLeaderboardReducer,
      tournamentSettings: selectTournamentSettings(state),
      teams: selectTournamentTeams(state).data?.teams || [],
      organization: state.organizationReducer,
    }
  },
  {
    deleteRound: tournamentRoundApi.endpoints.deleteTournamentRound.initiate,
    updateRoundField,
    updateTournamentField,
    saveTournamentRound,
    fetchGameFormats,
    fetchAllStartLists,
    getTournament,
    enqueueNotification,
    loadTournamentTeams: tournamentTeamsApi.endpoints.getTeams.initiate,
    setTournamentDirty,
    saveTournamentSettings: tournamentSettingsApi.endpoints.updateTournamentSettings.initiate,
    setTournamentNeedsUpdate,
    removeRound,
    loadTournamentLeaderboard,
    setSelectedCourseVersion,
  },
)(StyledRoundSetup as any)
