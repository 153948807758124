import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { DialogActions } from '@mui/material'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import InputsValidator from '../../forms/InputsValidator'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Input from '@mui/material/Input'
import {
  updateOrganizationApplication,
  UpdateOrganizationApplicationPayload,
} from '../../../store/organization/actions'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { RootState } from '@app/store'

interface OwnProps {
  open?: boolean
  application?: OrganizationApplication
  onClose?(refetch?: boolean): void
}

interface DispatchProps {
  updateOrganizationApplication(args: UpdateOrganizationApplicationPayload): void
}

type Props = OwnProps & DispatchProps

interface State {
  isLoading: boolean
  application: OrganizationApplication
}

const initialApplicationState: OrganizationApplication = {
  id: 0,
  contactPersonEmail: '',
  contactPersonName: '',
  contactPersonPhone: '',
  contactPersonTitle: '',
  email: '',
  handledDate: '',
  homepage: '',
  name: '',
  phone: '',
  status: 'PENDING',
  updatedAt: '',
  createdAt: '',
  isGolfClub: false,
}

class OrganizationApplicationDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isLoading: false,
      application: props.application || initialApplicationState,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.application && props.application && state.application.id !== props.application.id) {
      return {
        application: { ...props.application },
      }
    }

    return null
  }

  render() {
    const { open = false } = this.props
    const { application } = this.state

    return (
      <Dialog
        open={open}
        onClose={(_, reason) => reason !== 'backdropClick' && this._handleDismiss()}
        aria-labelledby="organization-dialog-title"
        fullWidth
        maxWidth={'xs'}
      >
        <BaseDialogTitle
          id="organization-dialog-title"
          title={<FormattedMessageWrapper id={'register.statusFor'} values={{ name: application.name }} />}
        />

        <InputsValidator values={{}}>
          {() => (
            <>
              <DialogContent>{this._renderForm()}</DialogContent>

              <DialogActions>
                <Button onClick={this._handleDismiss} disabled={this.state.isLoading}>
                  <FormattedMessageWrapper id={'buttons.cancel'} />
                </Button>
                <ButtonLoaderWrap loading={this.state.isLoading}>
                  <Button
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.setState({ isLoading: true }, this._handleUpdate)
                    }}
                    color="primary"
                    variant={'contained'}
                  >
                    <FormattedMessageWrapper id={'buttons.save'} />
                  </Button>
                </ButtonLoaderWrap>
              </DialogActions>
            </>
          )}
        </InputsValidator>
      </Dialog>
    )
  }

  public _handleUpdate = () => {
    const { updateOrganizationApplication } = this.props
    const { application } = this.state

    updateOrganizationApplication({
      application,
      onComplete: this._handleOnComplete,
    })
  }

  public _handleDismiss = (): void => {
    this._handleClose(false)
  }

  public _handleClose = (refetch = false): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose(refetch)
    }
  }

  public _handleOnComplete = ({ error }: APICallResult): void => {
    this.setState({ isLoading: false }, () => {
      if (!error) {
        this._handleClose(true)
      }
    })
  }

  private _renderForm = () => {
    const { isLoading, application } = this.state

    return (
      <>
        <FormControl fullWidth={true} disabled={isLoading}>
          <Select
            value={application.status}
            input={<Input id={'application-status'} name={'application-status'} />}
            onChange={(event) => {
              this.setState({
                application: {
                  ...this.state.application,
                  status: event.target.value as OrganizationApplicationStatus,
                },
              })
            }}
          >
            <MenuItem value={'PENDING'}>PENDING</MenuItem>
            <MenuItem value={'ACCEPTED'}>ACCEPTED</MenuItem>
            <MenuItem value={'REJECTED'}>REJECTED</MenuItem>
          </Select>
        </FormControl>
      </>
    )
  }
}

export default connect<{}, DispatchProps, OwnProps, RootState>(null, {
  updateOrganizationApplication,
})(OrganizationApplicationDialog)
