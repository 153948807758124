import { isAnyOf } from '@reduxjs/toolkit'
import { tournamentApi } from '../endpoints/tournamentApi'
import { tournamentContestApi } from '../endpoints/tournamentContestApi'
import { tournamentPlayersApi } from '../endpoints/tournamentPlayersApi'
import { tournamentRoundApi } from '../endpoints/tournamentRoundApi'
import { tournamentScoringApi } from '../endpoints/tournamentScoringApi'
import { tournamentSettingsApi } from '../endpoints/tournamentSettingsApi'
import { tournamentSiteApi } from '../endpoints/tournamentSiteApi'
import { tournamentStartListGroupApi } from '../endpoints/tournamentStartListGroupApi'
import { tournamentStartListsApi } from '../endpoints/tournamentStartListsApi'
import { tournamentStartListsEmailApi } from '../endpoints/tournamentStartListsEmailApi'
import { tournamentTeamsApi } from '../endpoints/tournamentTeamsApi'
import { tournamentWeeklyApi } from '../endpoints/tournamentWeeklyApi'
import { startListsUpdated } from '../slices/tournamentStartListsSlice'
import { clubsAndCoursesApi } from '../endpoints/clubsAndCoursesApi'
import { tournamentGitApi } from '../endpoints/tournamentGitApi'

const {
  getTournament,
  createTournament,
  updateTournament,
  calculateTournamentCut,
  updateTournamentNotes,
  syncTournament,
  copyTournament,
} = tournamentApi.endpoints
const {
  createTournamentRound,
  updateTournamentRound,
  updateTournamentRoundStatus,
  updateTournamentRoundStatusWeekly,
  deleteTournamentRound,
} = tournamentRoundApi.endpoints
const { getPlayers, updatePlayer, updateHandicaps, deletePlayer, addPlayerToPool, deletePlayers, movePlayers } =
  tournamentPlayersApi.endpoints
const {
  getTeams,
  deleteTeams,
  updateTeam,
  deleteTeam,
  addTeamToPool,
  createTeamsAutofill,
  addTeam,
  addTeams,
  addPlayerToTeam,
  removePlayerFromTeam,
} = tournamentTeamsApi.endpoints
const {
  updateStartListGroup,
  deleteStartListGroup,
  createStartListGroup,
  addTeamToGroup,
  moveTeamToGroup,
  deleteTeamFromGroup,
  updatePlayerGroupOrder,
  addPlayerToGroup,
  updateStartListPlayer,
  removePlayerFromGroup,
} = tournamentStartListGroupApi.endpoints
const { getStartList, createStartList, updateStartList, deleteStartList, copyStartList } =
  tournamentStartListsApi.endpoints
const { getTournamentSettings, updateTournamentSettings } = tournamentSettingsApi.endpoints
const {
  getTournamentSite,
  updateTournamentSite,
  uploadTournamentInvitationFile,
  uploadTournamentSummaryFile,
  uploadTournamentImage,
  deleteTournamentImage,
  getTournamentFeedPictureList,
  fetchTournamentFeedPictures,
} = tournamentSiteApi.endpoints
const { sendMeasuredContestResults, sendNonMeasuredContestResults, deleteContestResult } =
  tournamentContestApi.endpoints
const { getWeeklyPlayerScores } = tournamentWeeklyApi.endpoints
const { getStartListEmailPreview, sendStartListEmail } = tournamentStartListsEmailApi.endpoints
const { updatePlayerScores, updateResultsPublishedStatus, sendWeeklyHcpScores } = tournamentScoringApi.endpoints
const { getClub, getCourses, searchClubs, searchClubsPublic } = clubsAndCoursesApi.endpoints
const { gitImportStart } = tournamentGitApi.endpoints

export const needsUpdateTrueMatcher = isAnyOf(
  createTournament.matchFulfilled,
  updateTournament.matchFulfilled,
  updateTournamentRound.matchFulfilled,
  createTournamentRound.matchFulfilled,
  deletePlayer.matchFulfilled,
  addPlayerToPool.matchFulfilled,
  deletePlayers.matchFulfilled,
  movePlayers.matchFulfilled,
  deleteTeams.matchFulfilled,
  updateTeam.matchFulfilled,
  deleteTeam.matchFulfilled,
  addTeamToPool.matchFulfilled,
  createTeamsAutofill.matchFulfilled,
  updateStartListGroup.matchFulfilled,
  deleteStartListGroup.matchFulfilled,
  createStartListGroup.matchFulfilled,
  addTeamToGroup.matchFulfilled,
  moveTeamToGroup.matchFulfilled,
  deleteTeamFromGroup.matchFulfilled,
  updatePlayerGroupOrder.matchFulfilled,
  createStartList.matchFulfilled,
  deleteStartList.matchFulfilled,
  updateTournamentSettings.matchFulfilled,
  addPlayerToGroup.matchFulfilled,
  updateStartListPlayer.matchFulfilled,
  removePlayerFromGroup.matchFulfilled,
  addPlayerToTeam.matchFulfilled,
  removePlayerFromTeam.matchFulfilled,
)

export const isDirtyFalseMatcher = isAnyOf(
  getTournament.matchFulfilled,
  createTournament.matchFulfilled,
  updateTournament.matchFulfilled,
  updateTournamentRound.matchFulfilled,
  createTournamentRound.matchFulfilled,
  updateTournamentSite.matchFulfilled,
  updateTournamentRoundStatus.matchFulfilled,
  updateTournamentRoundStatusWeekly.matchFulfilled,
)

export const isTournamentLoadingTrueMatcher = isAnyOf(
  getTournament.matchPending,
  createTournament.matchPending,
  updateTournament.matchPending,
  createTournamentRound.matchPending,
  deleteTournamentRound.matchPending,
  updateTournamentRound.matchPending,
  updateTournamentRoundStatus.matchPending,
  updateTournamentRoundStatusWeekly.matchPending,
  calculateTournamentCut.matchPending,
  updateTournamentNotes.matchPending,
  syncTournament.matchPending,
  copyTournament.matchPending,
  sendMeasuredContestResults.matchPending,
  sendNonMeasuredContestResults.matchPending,
  deleteContestResult.matchPending,
  getWeeklyPlayerScores.matchPending,
)

export const isTournamentLoadingFalseMatcher = isAnyOf(
  getTournament.matchFulfilled,
  getTournament.matchRejected,
  createTournament.matchFulfilled,
  createTournament.matchRejected,
  updateTournament.matchFulfilled,
  updateTournament.matchRejected,
  createTournamentRound.matchFulfilled,
  createTournamentRound.matchRejected,
  deleteTournamentRound.matchFulfilled,
  deleteTournamentRound.matchRejected,
  updateTournamentRound.matchFulfilled,
  updateTournamentRound.matchRejected,
  updateTournamentRoundStatus.matchFulfilled,
  updateTournamentRoundStatus.matchRejected,
  updateTournamentRoundStatusWeekly.matchFulfilled,
  updateTournamentRoundStatusWeekly.matchRejected,
  calculateTournamentCut.matchFulfilled,
  calculateTournamentCut.matchRejected,
  updateTournamentNotes.matchFulfilled,
  updateTournamentNotes.matchRejected,
  syncTournament.matchFulfilled,
  syncTournament.matchRejected,
  copyTournament.matchFulfilled,
  copyTournament.matchRejected,
  sendMeasuredContestResults.matchFulfilled,
  sendMeasuredContestResults.matchRejected,
  sendNonMeasuredContestResults.matchFulfilled,
  sendNonMeasuredContestResults.matchRejected,
  deleteContestResult.matchFulfilled,
  deleteContestResult.matchRejected,
  getWeeklyPlayerScores.matchFulfilled,
  getWeeklyPlayerScores.matchRejected,
)

export const isTournamentSiteLoadingTrueMatcher = isAnyOf(
  getTournamentSite.matchPending,
  updateTournamentSite.matchPending,
  uploadTournamentInvitationFile.matchPending,
  uploadTournamentSummaryFile.matchPending,
  uploadTournamentImage.matchPending,
  deleteTournamentImage.matchPending,
  getTournamentFeedPictureList.matchPending,
  fetchTournamentFeedPictures.matchPending,
)

export const isTournamentSiteLoadingFalseMatcher = isAnyOf(
  getTournamentSite.matchFulfilled,
  getTournamentSite.matchRejected,
  updateTournamentSite.matchFulfilled,
  updateTournamentSite.matchRejected,
  uploadTournamentInvitationFile.matchFulfilled,
  uploadTournamentInvitationFile.matchRejected,
  uploadTournamentSummaryFile.matchFulfilled,
  uploadTournamentSummaryFile.matchRejected,
  uploadTournamentImage.matchFulfilled,
  uploadTournamentImage.matchRejected,
  deleteTournamentImage.matchFulfilled,
  deleteTournamentImage.matchRejected,
  getTournamentFeedPictureList.matchFulfilled,
  getTournamentFeedPictureList.matchRejected,
  fetchTournamentFeedPictures.matchFulfilled,
  fetchTournamentFeedPictures.matchRejected,
)

export const isTournamentStartListsLoadingTrueMatcher = isAnyOf(
  getStartList.matchPending,
  updateStartList.matchPending,
  deleteStartList.matchPending,
  createStartList.matchPending,
  copyStartList.matchPending,
  updateTeam.matchPending,
  createStartListGroup.matchPending,
  updateStartListGroup.matchPending,
  deleteStartListGroup.matchPending,
  updatePlayerGroupOrder.matchPending,
  getStartListEmailPreview.matchPending,
  sendStartListEmail.matchPending,
  // Publish start list
  updateTournamentRoundStatus.matchPending,
  addPlayerToGroup.matchPending,
  updateStartListPlayer.matchPending,
  removePlayerFromGroup.matchPending,
  addPlayerToTeam.matchPending,
  removePlayerFromTeam.matchPending,
)

export const isTournamentStartListsLoadingFalseMatcher = isAnyOf(
  getStartList.matchFulfilled,
  getStartList.matchRejected,
  deleteStartList.matchFulfilled,
  deleteStartList.matchRejected,
  startListsUpdated,
  createStartList.matchFulfilled,
  createStartList.matchRejected,
  copyStartList.matchFulfilled,
  copyStartList.matchRejected,
  updateTeam.matchFulfilled,
  updateTeam.matchRejected,
  createStartListGroup.matchFulfilled,
  createStartListGroup.matchRejected,
  updateStartListGroup.matchFulfilled,
  updateStartListGroup.matchRejected,
  deleteStartListGroup.matchFulfilled,
  deleteStartListGroup.matchRejected,
  updatePlayerGroupOrder.matchFulfilled,
  updatePlayerGroupOrder.matchRejected,
  getStartListEmailPreview.matchFulfilled,
  getStartListEmailPreview.matchRejected,
  sendStartListEmail.matchFulfilled,
  sendStartListEmail.matchRejected,
  // Publish start list
  updateTournamentRoundStatus.matchFulfilled,
  updateTournamentRoundStatus.matchRejected,
  addPlayerToGroup.matchFulfilled,
  addPlayerToGroup.matchRejected,
  updateStartListPlayer.matchFulfilled,
  updateStartListPlayer.matchRejected,
  removePlayerFromGroup.matchFulfilled,
  removePlayerFromGroup.matchRejected,
  addPlayerToTeam.matchFulfilled,
  addPlayerToTeam.matchRejected,
  removePlayerFromTeam.matchFulfilled,
  removePlayerFromTeam.matchRejected,
)

export const isTournamentPlayersLoadingTrueMatcher = isAnyOf(
  getPlayers.matchPending,
  updatePlayer.matchPending,
  deletePlayer.matchPending,
  deletePlayers.matchPending,
  updateHandicaps.matchPending,
  addPlayerToPool.matchPending,
  getTeams.matchPending,
  addTeam.matchPending,
  addTeams.matchPending,
  addTeamToPool.matchPending,
  deleteTeams.matchPending,
  createTeamsAutofill.matchPending,
)

export const isTournamentPlayersLoadingFalseMatcher = isAnyOf(
  getPlayers.matchFulfilled,
  getPlayers.matchRejected,
  updatePlayer.matchFulfilled,
  updatePlayer.matchRejected,
  deletePlayer.matchFulfilled,
  deletePlayer.matchRejected,
  deletePlayers.matchFulfilled,
  deletePlayers.matchRejected,
  updateHandicaps.matchFulfilled,
  updateHandicaps.matchRejected,
  addPlayerToPool.matchFulfilled,
  addPlayerToPool.matchRejected,
  getTeams.matchFulfilled,
  getTeams.matchRejected,
  addTeam.matchFulfilled,
  addTeam.matchRejected,
  addTeams.matchFulfilled,
  addTeams.matchRejected,
  addTeamToPool.matchFulfilled,
  addTeamToPool.matchRejected,
  deleteTeams.matchFulfilled,
  deleteTeams.matchRejected,
  createTeamsAutofill.matchFulfilled,
  createTeamsAutofill.matchRejected,
)

export const isTournamentSettingsLoadingTrueMatcher = isAnyOf(
  getTournamentSettings.matchPending,
  updateTournamentSettings.matchPending,
)

export const isTournamentSettingsLoadingFalseMatcher = isAnyOf(
  getTournamentSettings.matchFulfilled,
  getTournamentSettings.matchRejected,
  updateTournamentSettings.matchFulfilled,
  updateTournamentSettings.matchRejected,
)

export const isTournamentScoringLoadingTrueMatcher = isAnyOf(
  updatePlayerScores.matchPending,
  updateResultsPublishedStatus.matchPending,
  sendWeeklyHcpScores.matchPending,
)

export const isTournamentScoringLoadingFalseMatcher = isAnyOf(
  updatePlayerScores.matchFulfilled,
  updatePlayerScores.matchRejected,
  updateResultsPublishedStatus.matchFulfilled,
  updateResultsPublishedStatus.matchRejected,
  sendWeeklyHcpScores.matchFulfilled,
  sendWeeklyHcpScores.matchRejected,
)

export const isClubsAndCoursesLoadingTrueMatcher = isAnyOf(
  getClub.matchPending,
  getCourses.matchPending,
  searchClubs.matchPending,
  searchClubsPublic.matchPending,
)

export const isClubsAndCoursesLoadingFalseMatcher = isAnyOf(
  getClub.matchFulfilled,
  getClub.matchRejected,
  getCourses.matchFulfilled,
  getCourses.matchRejected,
  searchClubs.matchFulfilled,
  searchClubs.matchRejected,
  searchClubsPublic.matchFulfilled,
  searchClubsPublic.matchRejected,
)

export const gitImportAddErrorMatcher = isAnyOf(gitImportStart.matchRejected)
export const gitImportClearErrorMatcher = isAnyOf(gitImportStart.matchPending)
