import React from 'react'
import { connect } from 'react-redux'
import { loadTournamentLeaderboard, LoadTournamentLeaderboardArgs } from '@store/tournamentLeaderboard/actions'
import { Collapse, darken, Table, TableBody, TableHead, TableRow, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Edit, ArrowDropDown } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import ScoreDialog from '@components/dialogs/scoreDialog/ScoreDialog'
import withStyles from '@mui/styles/withStyles'
import { rem } from '@theme/materialUITheme'
import { formatHandicap } from '@utils/playerUtils'
import { GameFormats, GameFormatTypes, TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import LeaderboardScoreTable from './LeaderboardScoreTable'
import { enqueueNotification } from '@store/notifications/actions'
import { currentlyOngoingRoundIndex } from '@utils/tournamentUtils'
import { isTeamFormatId, isSpecialTeamFormatId } from '@utils/gameFormatUtils'
import { LeaderboardSettings } from './LeaderboardSettings'
import { LeaderboardTabs } from './LeaderboardTabs'
import { LeaderboardContests } from './LeaderboardContests'
import { PlayerStatus } from '@app/store/api/enums/tournamentPlayersEnums'
import { PrintoutsDialog } from '@app/components/dialogs/printouts-dialog'
import { RootState } from '@app/store'
import { tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { getTournament } from '@app/store/api/thunks/tournamentThunks'
import { getTournamentWeeklyPlayerScores } from '@app/store/api/thunks/tournamentWeeklyThunks'
import { setWeeklyScoreParams } from '@app/store/api/slices/configSlice'
import { selectRoundCoursesForAllRounds } from '@app/store/api/slices/clubsAndCoursesSlice'
import {
  fetchTournamentDivisionLeaderboard,
  FetchTournamentDivisionLeaderboardArgs,
} from '@app/store/tournamentDivisionLeaderboard/actions'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    tableHeader: {
      display: 'flex',
    },
    tableSubHeader: {
      display: 'flex',
      marginBottom: 20,
    },
    teamName: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    teamPlayerNames: {
      color: 'initial',
      fontSize: rem(12),
    },
    cutBreakTable: {
      backgroundColor: theme.palette.primary.main,
    },
    cutBreakRow: {
      color: theme.palette.common.white,
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontSize: `${rem(20)} !important`,
      padding: rem(8),
    },
    leaderboardTable: {
      '& th': {
        backgroundColor: theme.customPalette.darkGreen,
        color: theme.palette.common.white,
      },
      '& .withBg': {
        background: darken(theme.palette.background.default, 0.02),
      },
    },
  })

interface OwnProps {
  tournament: TournamentState
  startlists: TournamentStartListState
  settings: TournamentSettings
  style?: React.CSSProperties
}

interface StateProps {
  leaderboard: TournamentLeaderboardState
  selectedDivisionId: number
  divisionLeaderboard: TournamentLeaderboardState
  units: OrganizationUnits
  roundCourses: RoundCourse[]
}

interface DispatchProps {
  getTournament(payload: TournamentPayload): void
  loadTournamentLeaderboard: (args: LoadTournamentLeaderboardArgs) => void
  fetchTournamentDivisionLeaderboard: (args: FetchTournamentDivisionLeaderboardArgs) => void
  enqueueNotification(error: any, variant?: string): void
  loadTournamentTeams(tournamentId: number): void
  updateTeam(payload: UpdateTeamPayload): void
  getTournamentWeeklyPlayerScores(payload: TournamentWeeklyScoresPayload, forceRefetch?: boolean): void
  setWeeklyScoreParams(payload: Partial<TournamentScoringState>): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

interface State {
  editModalOpen: boolean
  scoreToEdit?: TournamentLeaderboardPlayer
  gameType: string
  scoreExpanded?: string
  currentPlayerId?: string
  currentTeamId?: string
  printoutsDialogOpen: boolean
}

const initialState: State = {
  editModalOpen: false,
  gameType: GameFormatTypes.PRIMARY,
  scoreExpanded: undefined,
  currentPlayerId: undefined,
  currentTeamId: undefined,
  printoutsDialogOpen: false,
}

class LeaderboardComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = initialState
  }

  componentDidMount() {
    const { tournament, loadTournamentTeams } = this.props
    // Fetch scoring data
    if (tournament && tournament.id) {
      loadTournamentTeams(tournament.id)

      // TODO: Return this code and remove getTournament. This is a temporary fix for TM-5260.
      // Fetch course data for all the rounds
      //tournament.rounds.forEach((round) => {
      //  const clubId = round.club?.id || 0
      //  const courseId = round.courseId
      //  const found = roundCourses.find((c) => c.id === courseId)
      //  if (clubId && !found) {
      //    getCourses({
      //      clubId: round.clubId?.value || 0,
      //      timestamp: getRoundStartTime(round),
      //    })
      //  }
      //})

      getTournament({
        id: tournament.id,
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!prevState.editModalOpen && this.state.editModalOpen) {
      const players = this._getLeaderboardDataPlayers
      const playerOrTeamScores = players.find((player) =>
        this.state.currentPlayerId
          ? this.state.currentPlayerId === String(player.userId)
          : this.state.currentTeamId === player.teamId,
      )
      this.setState({ scoreToEdit: playerOrTeamScores })
    }
  }

  fetchWeeklyPlayerScores = (tournament, player: TournamentLeaderboardPlayer) => {
    const forceRefetch = true
    this.props.getTournamentWeeklyPlayerScores(
      {
        tournamentId: tournament.id,
        playerId: Number(player.userId),
        onSuccess: () => {
          this._handleOnClick(player)
        },
      },
      forceRefetch,
    )
  }

  render() {
    const { classes, tournament, leaderboard, startlists, units, intl } = this.props

    return (
      <div className={classes.root}>
        <LeaderboardSettings onRefreshClick={this._refresh} onPrintClick={this._print} gameType={this.state.gameType} />

        {this._showLeaderboard && (
          <LeaderboardTabs
            completed={!!(this._roundCompleted && leaderboard.data)}
            // Enabled if any round has condition
            sideGameEnabled={tournament.rounds.some(
              (round) => round.sideGameEnabled && round.sideGameId !== GameFormats.NO_SIDE,
            )}
            isTeamFormat={tournament.rounds.some((_, index) => isTeamFormatId(this._gameFormatId(index)))}
            contestsEnabled={tournament.rounds.some((r) => r.contestsEnabled)}
            gameType={this.state.gameType as GameFormatTypes}
            onGameTypeChange={(gameType) => {
              this.setState({ gameType })
              this._refresh()
            }}
          />
        )}

        {this.state.gameType === GameFormatTypes.CONTESTS ? this._renderContests() : this._renderLeaderboardScores()}

        {this.state.printoutsDialogOpen && (
          <PrintoutsDialog
            tournament={tournament}
            defaultPrintoutType="RESULTS"
            onClose={() => this.setState({ printoutsDialogOpen: false })}
          />
        )}

        <ScoreDialog
          tournament={tournament}
          units={units}
          open={this.state.editModalOpen}
          playerScore={this.state.scoreToEdit}
          teamScores={this.state.scoreToEdit?.team}
          roundCourses={this.props.roundCourses}
          team={this.state.scoreToEdit}
          startlists={startlists}
          isSpecialTeam={this._isSpecialTeamFormat}
          onClose={(refetch = false) => {
            this.setState({
              editModalOpen: false,
              scoreToEdit: undefined,
              currentPlayerId: undefined,
              currentTeamId: undefined,
            })

            if (refetch) {
              this._refresh()
            }
          }}
          onTeamUpdate={(teamId, name) => {
            if (tournament.id) {
              this.props.updateTeam({ tournamentId: this.props.tournament.id, teamId, name })
            }
          }}
          intl={intl}
        />
      </div>
    )
  }

  public get _getLeaderboardDataPlayers() {
    const { data } = this.props.selectedDivisionId > 0 ? this.props.divisionLeaderboard : this.props.leaderboard

    if (!data) {
      return []
    }

    const { gameType } = this.state
    return gameType === GameFormatTypes.PRIMARY ? data.primary.players : data.secondary.players
  }

  private _getLeaderboard = () => {
    const { selectedDivisionId } = this.props
    return selectedDivisionId ? this.props.divisionLeaderboard : this.props.leaderboard
  }

  private _renderContests = () => {
    return <LeaderboardContests />
  }

  private _print = () => {
    this.setState({ printoutsDialogOpen: true })
  }

  private _renderLeaderboardScores = () => {
    const { style, tournament, classes } = this.props

    const gameFormatId = this._gameFormatId
    return (
      <Table style={style} className={classes.leaderboardTable}>
        <TableHead>
          <TableRow>
            {this._showLeaderboard && (
              <VeryDenseTableCell style={{ paddingLeft: '23px' }}>
                <FormattedMessageWrapper id={'tournament.position'} />
              </VeryDenseTableCell>
            )}
            {tournament.rounds.some((_, index) => !isTeamFormatId(gameFormatId(index))) && (
              <>
                <VeryDenseTableCell>
                  <FormattedMessageWrapper id={'tournament.country'} />
                </VeryDenseTableCell>
              </>
            )}
            <VeryDenseTableCell style={{ textAlign: 'left' }}>
              <FormattedMessageWrapper id={'tournament.name'} />
              {this._showPhcpTitle() && (
                <span style={{ fontSize: '80%', opacity: 0.7 }}>
                  &nbsp;(
                  <FormattedMessageWrapper id="tournament.playingHCP" />)
                </span>
              )}
            </VeryDenseTableCell>
            <VeryDenseTableCell />
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.toPar'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.thru'} />
            </VeryDenseTableCell>
            {tournament.tournamentType === TournamentTypes.multiRound && (
              <VeryDenseTableCell>
                <FormattedMessageWrapper id={'tournament.today'} />
              </VeryDenseTableCell>
            )}
            {/* Rounds if more than one */}
            {tournament.rounds.length > 1 &&
              [...Array(tournament.rounds.length)].map((_, i: number) => (
                <VeryDenseTableCell key={`round-${i + 1}`}>
                  <FormattedMessageWrapper id="tournament.roundWithNumberShort" values={{ round: i + 1 }} />
                </VeryDenseTableCell>
              ))}
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.score'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tournament.rounds.some((_, index) => isTeamFormatId(gameFormatId(index)))
            ? this._teamResults
            : this._playerResults}
        </TableBody>
      </Table>
    )
  }

  private _showPhcpTitle() {
    const { rounds, isTeamFormat } = this.props.tournament

    // Individual, one round and handicaps in use
    if (!isTeamFormat && rounds.length === 1 && rounds[0].primaryGameOptions.useHandicaps) {
      return true
    }

    // Special team format, one round and handicaps in use
    if (isTeamFormat && this._isSpecialTeamFormat && rounds.length === 1 && rounds[0].primaryGameOptions.useHandicaps) {
      return true
    }

    return false
  }

  private get _showLeaderboard() {
    return this.props.leaderboard.data !== undefined || this.props.divisionLeaderboard.data !== undefined
  }

  private get _roundCompleted() {
    const { tournament } = this.props
    const roundIndex = currentlyOngoingRoundIndex(tournament.rounds)
    return tournament.rounds[roundIndex] ? tournament.rounds[roundIndex].completed : false
  }

  private _gameFormatId = (roundIndex: number) => {
    const { rounds } = this.props.tournament
    const id =
      this.state.gameType === GameFormatTypes.PRIMARY ? rounds[roundIndex].primaryGameId : rounds[roundIndex].sideGameId

    return id ? id : 0
  }

  private get _isSpecialTeamFormat() {
    return this.props.tournament.rounds.some((_, index) => isSpecialTeamFormatId(this._gameFormatId(index)))
  }

  private get _playerResults() {
    const playerScores = this._getLeaderboardDataPlayers
    if (playerScores) {
      const cutBreakPlayer = playerScores.find((player) => player.status && player.status === PlayerStatus.CUT)
      return playerScores.map((player, index) => this._renderPlayerRow(player, index, cutBreakPlayer))
    }
    return null
  }

  /**
   *
   * @param player
   * @param idx
   * @param cutBreakPlayer First player to have CUT status. Apply cut indicator before this player
   * @returns React component
   */
  private _renderPlayerRow = (
    player: TournamentLeaderboardPlayer,
    idx: number,
    cutBreakPlayer?: TournamentLeaderboardPlayer,
  ) => {
    const { tournament, classes } = this.props

    const playerId = player.userId
    const playerPosition = player.position
    const playerToPar = player.toPar
    const playerThru = player.thruToday
    const { toParToday } = player
    const playerTotalScore = player.score
    const playerScores = player.rounds[0]
    const playerCountryCode = player.countryCode
    const playerName = player.name
    const playerPlayingHcp = formatHandicap(player.rounds[0].playingHcp.toString())

    const applyCutIndicator = player.userId === cutBreakPlayer?.userId

    return (
      <React.Fragment key={playerId}>
        {applyCutIndicator && (
          <TableRow className={classes.cutBreakTable}>
            <VeryDenseTableCell className={classes.cutBreakRow} colSpan={9999}>
              <FormattedMessageWrapper id="tournament.missedCut" />
            </VeryDenseTableCell>
          </TableRow>
        )}
        <TableRow className={idx % 2 === 0 ? 'withBg' : ''}>
          {/* Player position */}
          {this._showLeaderboard && (
            <VeryDenseTableCell style={{ paddingLeft: '23px' }}>{playerPosition}</VeryDenseTableCell>
          )}

          {/* Country */}
          <VeryDenseTableCell>
            {playerCountryCode && (
              <img
                src={`//static.golfgamebook.com/flags/${playerCountryCode}.png`}
                alt={player.countryCode}
                width="24"
              />
            )}
          </VeryDenseTableCell>
          {/* Player name */}
          <VeryDenseTableCell style={{ textAlign: 'left' }}>
            {playerScores.strokes && playerScores.strokes[0] && playerScores.strokes.length > 0 ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const id = this.state.scoreExpanded === String(playerId) ? '0' : playerId
                  this.setState({ scoreExpanded: String(id) })
                }}
              >
                {`${playerName}`} ({playerPlayingHcp})
              </span>
            ) : (
              <span>
                {`${playerName}`} ({playerPlayingHcp})
              </span>
            )}
          </VeryDenseTableCell>

          {/* Collapse */}
          <VeryDenseTableCell>
            {playerScores.strokes && playerScores.strokes.length > 0 && (
              <IconButton
                style={{ padding: 4, margin: 2 }}
                onClick={() => {
                  const id = this.state.scoreExpanded === String(playerId) ? '0' : playerId
                  this.setState({ scoreExpanded: String(id) })
                  tournament.id &&
                    tournament.tournamentType === TournamentTypes.weekly &&
                    this.props.getTournamentWeeklyPlayerScores({
                      tournamentId: tournament.id,
                      playerId,
                    })
                }}
                size="large"
              >
                <ArrowDropDown />
              </IconButton>
            )}
          </VeryDenseTableCell>

          {/* To Par */}
          <VeryDenseTableCell>{playerToPar ? playerToPar : ''}</VeryDenseTableCell>

          {/* Thru */}
          <VeryDenseTableCell>{playerThru ? playerThru : ''}</VeryDenseTableCell>

          {/* Today */}
          {tournament.tournamentType === TournamentTypes.multiRound && (
            <VeryDenseTableCell>{toParToday}</VeryDenseTableCell>
          )}

          {tournament.rounds.length > 1 &&
            [...Array(tournament.rounds.length)].map((_, i: number) => (
              <VeryDenseTableCell key={`round-${i + 1}`}>{player.rounds[i]?.scoreTotal}</VeryDenseTableCell>
            ))}

          {/* Player total score */}
          <VeryDenseTableCell>{playerTotalScore ? playerTotalScore : ''}</VeryDenseTableCell>

          {/* Edit player action */}
          <VeryDenseTableCell align={'right'} style={{ width: '5%', paddingRight: '20px' }}>
            {tournament.tournamentType === TournamentTypes.weekly ? (
              <div>
                <IconButton
                  style={{ padding: 4, margin: 2 }}
                  onClick={() => this.fetchWeeklyPlayerScores(tournament, player)}
                  size="large"
                >
                  <Edit color={'primary'} />
                </IconButton>
              </div>
            ) : (
              <IconButton style={{ padding: 4, margin: 2 }} onClick={() => this._handleOnClick(player)} size="large">
                <Edit color={'primary'} />
              </IconButton>
            )}
          </VeryDenseTableCell>
        </TableRow>
        <TableRow className={idx % 2 === 0 ? 'withBg' : ''}>
          <VeryDenseTableCell colSpan={14} style={{ padding: 0, borderBottom: 0 }}>
            <Collapse in={this.state.scoreExpanded === String(playerId)}>
              <LeaderboardScoreTable
                player={player}
                tournament={tournament}
                roundCourses={this.props.roundCourses}
                leaderboard={this._getLeaderboard()}
                gameType={this.state.gameType}
              />
            </Collapse>
          </VeryDenseTableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  private _handleOnClick = (player: TournamentLeaderboardPlayer) => {
    this._refresh(() => {
      this.setState({
        editModalOpen: true,
        currentPlayerId: player.userId ? String(player.userId) : undefined,
        currentTeamId: player.teamId,
      })
    })
  }

  private get _teamResults() {
    const playerScores = this._getLeaderboardDataPlayers

    if (playerScores) {
      return playerScores.map(this._renderTeamRow)
    }
    return null
  }

  private _renderTeamRow = (team: TournamentLeaderboardPlayer, idx: number) => {
    const { tournament, classes } = this.props

    const playerData = this._getLeaderboardDataPlayers
    const teamPlayerNames = playerData[idx]?.team?.map((player) => player.name).join(', ')

    const expand = () => {
      const id = this.state.scoreExpanded === team.teamId ? '0' : team.teamId
      this.setState({ scoreExpanded: id })
    }

    return (
      <React.Fragment key={team.teamId}>
        <TableRow className={idx % 2 === 0 ? 'withBg' : ''}>
          {/* Team position */}
          {this._showLeaderboard && (
            <VeryDenseTableCell style={{ paddingLeft: '23px' }}>{team.position || '-'}</VeryDenseTableCell>
          )}
          {/* Team name */}
          <VeryDenseTableCell style={{ textAlign: 'left' }}>
            <span className={classes.teamName} style={{ cursor: 'pointer' }} onClick={expand}>
              {team.name}
            </span>

            <br />
            <small className={classes.teamPlayerNames}>{teamPlayerNames}</small>
          </VeryDenseTableCell>
          {/* Collapse */}
          <VeryDenseTableCell>
            <IconButton style={{ padding: 4, margin: 2 }} onClick={expand} size="large">
              <ArrowDropDown />
            </IconButton>
          </VeryDenseTableCell>
          {/* To Par */}
          <VeryDenseTableCell>{team.toPar}</VeryDenseTableCell>
          {/* Thru */}
          <VeryDenseTableCell>{team.thruToday}</VeryDenseTableCell>
          {/* Today */}
          {tournament.tournamentType === TournamentTypes.multiRound && (
            <VeryDenseTableCell>{team.toParToday}</VeryDenseTableCell>
          )}
          {/* Rounds */}
          {tournament.rounds.length > 1 &&
            [...Array(tournament.rounds.length)].map((_, i: number) => {
              if (team.rounds[i]) {
                return <VeryDenseTableCell key={`round-${i + 1}`}>{team.rounds[i].scoreTotal}</VeryDenseTableCell>
              }
              return <VeryDenseTableCell key={`round-${i + 1}`}></VeryDenseTableCell>
            })}
          {/* Team total score */}
          <VeryDenseTableCell>{team.score}</VeryDenseTableCell>
          {/* Edit team scores action */}
          <VeryDenseTableCell>
            <IconButton style={{ padding: 4, margin: 2 }} onClick={() => this._handleOnClick(team)} size="large">
              <Edit color={'primary'} />
            </IconButton>
          </VeryDenseTableCell>
        </TableRow>
        <TableRow className={idx % 2 === 0 ? 'withBg' : ''}>
          <VeryDenseTableCell colSpan={14} style={{ padding: 0 }}>
            <Collapse in={this.state.scoreExpanded === team.teamId}>
              {this._isSpecialTeamFormat ? (
                <LeaderboardScoreTable
                  key={`scoretable`}
                  player={team}
                  tournament={tournament}
                  roundCourses={this.props.roundCourses}
                  gameType={this.state.gameType}
                  leaderboard={this._getLeaderboard()}
                  showName
                  isSpecialTeamFormat
                />
              ) : (
                team.team.map((player, i) => (
                  <LeaderboardScoreTable
                    key={`scoretable-${i}`}
                    player={player}
                    tournament={tournament}
                    roundCourses={this.props.roundCourses}
                    gameType={this.state.gameType}
                    leaderboard={this._getLeaderboard()}
                    showName
                  />
                ))
              )}
            </Collapse>
          </VeryDenseTableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  public _refresh = (onComplete?: () => void) => {
    const { tournament, loadTournamentLeaderboard, fetchTournamentDivisionLeaderboard, selectedDivisionId } = this.props
    if (tournament && tournament.id) {
      if (selectedDivisionId > 0) {
        const payload =
          typeof onComplete === 'function'
            ? { id: tournament.id, divisionId: selectedDivisionId, onComplete }
            : { id: tournament.id, divisionId: selectedDivisionId }
        fetchTournamentDivisionLeaderboard(payload)
      } else {
        const payload = typeof onComplete === 'function' ? { id: tournament.id, onComplete } : { id: tournament.id }
        loadTournamentLeaderboard(payload)
      }
    }
  }
}

const LocalizedLeaderboard = injectIntl(LeaderboardComponent)
export const Leaderboard = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    leaderboard: state.tournamentLeaderboardReducer,
    divisionLeaderboard: state.tournamentDivisionLeaderboardReducer,
    units: state.authenticationReducer.units,
    roundCourses: selectRoundCoursesForAllRounds(state),
    selectedDivisionId: state.divisionsReducer.selectedDivisionId,
  }),
  {
    getTournament,
    loadTournamentLeaderboard,
    fetchTournamentDivisionLeaderboard,
    enqueueNotification,
    loadTournamentTeams: tournamentTeamsApi.endpoints.getTeams.initiate,
    updateTeam: tournamentTeamsApi.endpoints.updateTeam.initiate,
    getTournamentWeeklyPlayerScores,
    setWeeklyScoreParams,
  },
)(withStyles(styles)(LocalizedLeaderboard as any))
