import React from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { get } from 'lodash'
import { getNextUrl, getPathPart } from '../../utils/nextUrlHelper'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { connect } from 'react-redux'
import { saveTour } from '../../store/tourAndRanking/actions'
import { RootState } from '@app/store'

export type ProgressStep = {
  path: string
  label: React.ReactNode
  action?: string
}

const STEPS: ProgressStep[] = [
  {
    path: `setup`,
    label: <FormattedMessageWrapper id="progress.tourSetup" />,
    action: 'saveTour',
  },
  {
    path: `branding`,
    label: <FormattedMessageWrapper id="progress.branding" />,
    action: undefined,
  },
]

export interface ProgressChildrenArgs {
  steps: ProgressStep[]
  tourName?: string
  getActiveStepIndex(): number
  handleOnClick(stepIndex: number): any
}

interface OwnProps {
  children: (args: ProgressChildrenArgs) => any
}

interface StateProps {
  tour?: Tour
}

interface DispatchProps {
  saveTour(onComplete?: () => void): void
}

type Props = OwnProps & StateProps & DispatchProps & WithRouterProps

class TourProgressLogic extends React.Component<Props> {
  render() {
    return this.props.children({
      tourName: this._getTourName(),
      steps: STEPS,
      getActiveStepIndex: this._getActiveStepIndex,
      handleOnClick: this._handleOnClick,
    })
  }

  private _getActiveStepIndex = () => {
    const pathParts = this.props.location.pathname.split('/')
    const partsCombined = `${pathParts[2]}--${pathParts.pop()}`
    const idx = STEPS.findIndex((item) => `tour--${item.path}` === partsCombined)

    if (idx >= 0) {
      return idx
    }

    return 0
  }

  private _handleOnClick = (stepIndex: number) => {
    return () => {
      // get current path and fire up save actions
      const pathPart = getPathPart(this.props.location.pathname)
      const currentStep = STEPS.find((step) => step.path === pathPart)
      const action = get(currentStep, 'action', null)

      const step = STEPS[stepIndex]
      const url = getNextUrl(this.props.location.pathname, step.path)
      this.props.navigate(url)
      if (action === 'saveTour') {
        this.props.saveTour()
      }
    }
  }

  private _getTourName = (): string | undefined => {
    const { tour } = this.props

    if (!tour) {
      return undefined
    }

    return tour.name
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    tour: state.tourAndRankingReducer.tour,
  }),
  {
    saveTour,
  },
)(withRouter(TourProgressLogic))
