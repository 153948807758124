import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { IconButton, Theme, Menu, MenuItem } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { ArrowDropDown, DeleteOutline } from '@mui/icons-material'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { find } from 'lodash'
import DeleteConfirm, { DeleteConfirmChildren } from '@components/dialogs/deleteConfirm/DeleteConfirm'
import { TeamName } from './TeamName'
import TeamHeader from './TeamHeader'
import TeamHCP from './TeamHCP'
import ActiveRound, { ActiveRoundArgs } from '@components/headless/ActiveRound'
import { rem } from '@theme/materialUITheme'
import { createReferenceIdForGroup } from '@utils/startListUtils'
import { startGroupConflictDialog } from '@components/dialogs/startGroupConflictDialog/StartGroupConflictDialog'
import { StartListSourceTarget } from '@app/store/api/enums/tournamentStartListsEnums'
import { moveTeam } from '@app/store/api/thunks/tournamentStartListsThunks'
import { TimePicker } from '../date-pickers'
import { isValidDate } from '@app/utils/dates'
import { RootState } from '@app/store'
import { selectTournamentTeams, tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { tournamentStartListGroupApi } from '@app/store/api/endpoints/tournamentStartListGroupApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'
import { selectSelectedRoundCourse } from '@app/store/api/slices/clubsAndCoursesSlice'

enum ConflictAction {
  addTeetimeInterval = 'addTeetimeInterval',
  removeExisting = 'removeExisting',
  switchGroups = 'switchGroups',
}

const styles = (theme: Theme) =>
  createStyles({
    groupWrap: {
      display: 'flex',
      flexDirection: 'column',
      background: theme.customPalette.darkBlue,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      overflow: 'hidden',
      justifyContent: 'space-between',
      color: theme.palette.primary.contrastText,
    },
    groupHeader: {
      color: theme.palette.primary.main,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: rem(14),
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    groupName: {
      color: theme.palette.primary.contrastText,
      whiteSpace: 'pre',
      textOverflow: 'ellipsis',
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontSize: rem(16),
      display: 'flex',
      alignItems: 'center',
      '@global': {
        a: {
          borderBottom: 0,
        },
      },
    },
    groupTextContainer: {
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(3),
    },
    teamHeader: {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      whiteSpace: 'pre',
      textOverflow: 'ellipsis',
      display: 'flex',
      flex: 1,
    },
    actionItem: {
      cursor: 'pointer',
      color: theme.palette.primary.contrastText,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 25,
    },
    popupContainer: {
      padding: `16px 18px`,
    },
    selectedTee: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    expandIcon: {
      color: theme.palette.primary.contrastText,
      fontSize: rem(28),
      cursor: 'pointer',
    },
    deleteIcon: {
      color: theme.palette.primary.contrastText,
    },
    removeTeamIcon: {
      color: theme.palette.action.active,
      fontSize: rem(24),
      width: '1em',
      height: '1em',
      overflow: 'hidden',
      marginRight: '8px',
    },
  })

interface OwnProps {
  index: number
  group: StartListGroup
  team?: StartListGroupTeam
  hideGroupInfo?: boolean
  players?: StartListPlayer[]
  style?: React.CSSProperties
  expanded?: boolean
}

interface StateProps {
  tournament: TournamentState
  startList: TournamentStartListState
  courseHoles: CourseHole[]
  units: OrganizationUnits
  tournamentTeams: TournamentTeam[]
  tournamentConfig: TournamentConfig
}

interface DispatchProps {
  updateStartListGroup(payload: UpdateStartListGroupPayload): void
  swapStartListGroups(payload: SwapStartListGroupsPayload): void
  deleteStartListGroup(payload: DeleteStartListGroupPayload): void
  updateTeam(payload: UpdateTeamPayload): void
  moveTeam(payload: MoveTournamentTeamAction): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

class StartGroupHeader extends React.Component<Props> {
  componentDidMount() {
    const { group } = this.props
    this.setState({
      startTime: group.startTime,
    })
  }

  render() {
    const { classes, hideGroupInfo = false, style, team } = this.props
    return (
      <>
        {!hideGroupInfo && (
          <div className={classes.groupWrap} style={style}>
            <div className={classes.groupHeader}>
              <div className={classes.groupTextContainer}>
                {this._renderGroupName()}
                {this._renderTeeBox()}
                {this._renderStartTime()}
              </div>
              <div>{this._renderDeleteButton()}</div>
            </div>
          </div>
        )}
        {team && (
          <div className={classes.teamHeader}>
            <TeamHeader>
              {this._renderTeamName()}
              {this._renderTeamHCP()}
              {this._renderRemoveTeam()}
            </TeamHeader>
          </div>
        )}
      </>
    )
  }

  get _hcpModifier() {
    return this._activeRound.primaryGameOptions.handicapPercentage
  }

  get _roundId() {
    return this._activeRound.id
  }

  get _activeRound(): Round {
    const { tournament, tournamentConfig } = this.props
    return tournament.rounds[tournamentConfig.selectedRoundIndex]
  }

  private _renderGroupName = () => {
    const { index, intl } = this.props

    return (
      <span className={this.props.classes.groupName}>
        {intl.formatMessage({ id: 'options.group' })} {index + 1}
      </span>
    )
  }

  private _renderDeleteButton = () => {
    const { classes } = this.props
    return (
      <DeleteConfirm>
        {({ showConfirm }: DeleteConfirmChildren) => (
          <IconButton
            size="small"
            className={classes.deleteIcon}
            onClick={() => {
              showConfirm({
                messageId: 'tournament.removeGroupConfirm',
                values: { groupName: this.props.group.name },
                callback: this._removeGroup,
              })
            }}
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        )}
      </DeleteConfirm>
    )
  }

  private _renderTeeBox = () => {
    const { group, courseHoles } = this.props
    const { teeNumber } = group

    return (
      <ActiveRound>
        {({ isBackNine, isFrontNine }: ActiveRoundArgs) => (
          <PopupState variant="popover" popupId="course-tee-popup-menu">
            {(popupState) => (
              <>
                <a className={this.props.classes.actionItem} {...bindTrigger(popupState)}>
                  <FormattedMessageWrapper id={'tournament.groupHole'} values={{ teeNumber }} />
                  <ArrowDropDown style={{ marginLeft: 5 }} />
                </a>
                <Menu {...bindMenu(popupState)}>
                  {courseHoles.map((courseHole) => (
                    <MenuItem
                      className={teeNumber === courseHole.holeNumber ? this.props.classes.selectedTee : undefined}
                      onClick={this._selectTee(courseHole.holeNumber, popupState.close)}
                      key={courseHole.id}
                      disabled={
                        (courseHole.holeNumber < 10 && isBackNine) || (courseHole.holeNumber > 9 && isFrontNine)
                      }
                    >
                      {courseHole.holeNumber}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </PopupState>
        )}
      </ActiveRound>
    )
  }

  private _renderStartTime = () => {
    const { group } = this.props
    return (
      <TimePicker
        name="startTime"
        id="startTime"
        inputProps={{
          sx: {
            '& .MuiOutlinedInput-input': {
              paddingTop: '8px',
              paddingBottom: '8px',
              color: '#fff',
            },
          },
        }}
        value={group.startTime}
        units={this.props.units}
        onAccept={this.onAcceptGroupStartTime}
        noBorder
      />
    )
  }

  private _renderTeamName = () => {
    const { team } = this.props

    const id = team?.tournamentTeamId
    const name = team?.name || ''

    return (
      <TeamName
        name={name}
        showEdit={false}
        style={{ flex: 0.5 }}
        onChange={(name) => {
          if (id) {
            this._updateTeam(id, name)
          }
        }}
      />
    )
  }

  public _renderRemoveTeam = () => {
    const { classes, moveTeam, startList, team, group, tournamentTeams, tournamentConfig } = this.props
    const round = startList.rounds[tournamentConfig.selectedRoundIndex]
    const groupIndex = round.groups.findIndex((item) => item.id === group.id)
    const tournamentTeam = tournamentTeams.find((tTeam) => tTeam.id === team?.tournamentTeamId)

    return (
      <IconButton
        size="small"
        className={classes.removeTeamIcon}
        onClick={() => {
          team &&
            tournamentTeam &&
            moveTeam({
              roundIndex: tournamentConfig.selectedRoundIndex,
              groupIndex,
              source: StartListSourceTarget.GROUP,
              target: StartListSourceTarget.TEAM_POOL,
              team: tournamentTeam,
            })
        }}
      >
        &times;
      </IconButton>
    )
  }

  public _removeGroup = () => {
    const { group, deleteStartListGroup, tournament } = this.props
    const roundId = this._roundId
    if (tournament.id) {
      deleteStartListGroup({
        tournamentId: tournament.id,
        groupId: group.id,
        roundId,
      })
    }
  }

  private _handleGroupUpdate = (arg: Partial<{ name: string; startTime: Date; teeNumber: number }>) => {
    const { updateStartListGroup, swapStartListGroups, tournament, group, intl, startList, tournamentConfig } =
      this.props
    const roundId = this._roundId
    const { id, startTime, teeNumber } = group
    const selectedStartTime = arg.startTime || startTime
    const selectedTeeNumber = arg.teeNumber || teeNumber

    const checkParams = { teeNumber, startTime, ...arg }

    const getLastTeeTime = (): Date => {
      const round = startList.rounds[tournamentConfig.selectedRoundIndex]
      let lastTeeTime: Date = new Date(0)
      round?.groups.forEach((group) => {
        if (group.startTime > lastTeeTime) {
          lastTeeTime = group.startTime
        }
      })
      lastTeeTime.setMinutes(lastTeeTime.getMinutes() + round.teeInterval)
      return lastTeeTime
    }

    const performUpdate = (conflictArgs?: { conflictingGroupId: number; conflictAction: string }) => {
      if (tournament.id) {
        const payload = {
          tournamentId: tournament.id,
          roundId,
          groupId: id,
          body: {
            startTime: selectedStartTime,
            teeNumber: selectedTeeNumber,
          },
        }

        switch (conflictArgs?.conflictAction) {
          case ConflictAction.addTeetimeInterval:
            updateStartListGroup({
              tournamentId: tournament.id,
              roundId,
              groupId: id,
              body: {
                startTime: getLastTeeTime(),
                teeNumber: selectedTeeNumber,
              },
            })
            break
          case ConflictAction.removeExisting:
            updateStartListGroup(payload)
            break
          case ConflictAction.switchGroups:
            if (conflictArgs) {
              swapStartListGroups({
                tournamentId: tournament.id,
                roundId,
                groupId: id,
                body: { conflictingGroupId: conflictArgs.conflictingGroupId },
              })
            }
            break
          default:
            updateStartListGroup(payload)
            break
        }
      }
    }

    const conflictingGroup = this._checkConflicts(checkParams.teeNumber, checkParams.startTime)

    if (conflictingGroup) {
      startGroupConflictDialog({
        options: {
          cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
          okText: intl.formatMessage({ id: 'buttons.ok' }),
          [ConflictAction.addTeetimeInterval]: intl.formatMessage({ id: 'options.groupConflict.addTeetimeInterval' }),
          [ConflictAction.removeExisting]: intl.formatMessage({ id: 'options.groupConflict.removeExisting' }),
          [ConflictAction.switchGroups]: intl.formatMessage({ id: 'options.groupConflict.switchGroups' }),
        },
        title: intl.formatMessage({ id: 'tournament.groupSettingsConflict' }),
      }).then((conflictAction) => {
        performUpdate({
          conflictAction,
          conflictingGroupId: conflictingGroup.id,
        })
      })
    } else {
      performUpdate()
    }
  }

  private _renderTeamHCP = () => {
    const { team } = this.props
    if (!team) {
      return null
    }

    const id = team.tournamentTeamId
    const hcp = team.primaryPlayingHcp

    const activeRound = this._activeRound

    const gameFormat = [activeRound.primaryGameId]

    return (
      <TeamHCP
        id={id}
        hcp={hcp}
        gameFormats={gameFormat}
        onChange={() => {
          if (id) {
            this._updateTeam(id, team.name)
          }
        }}
      />
    )
  }

  private onAcceptGroupStartTime = (startTime: DateTimeValue) => {
    if (isValidDate(startTime)) {
      this._handleGroupUpdate({ startTime: startTime as Date })
    }
  }

  public _selectTee = (teeNumber: number, cb: () => void) => () => {
    cb()
    const { group } = this.props
    if (teeNumber !== group.teeNumber) {
      this._handleGroupUpdate({ teeNumber })
    }
  }

  public _updateTeam = (teamId: number, name = '') => {
    const { updateTeam, tournament } = this.props
    if (tournament.id) {
      updateTeam({
        tournamentId: tournament.id,
        teamId,
        name,
      })
    }
  }

  get _currentGroups(): StartListGroup[] {
    const { startList, tournamentConfig } = this.props
    const round = startList.rounds[tournamentConfig.selectedRoundIndex]
    return round.groups
  }

  public _hasConflictingGroups = (referenceId: string): StartListGroup | undefined => {
    return find(this._currentGroups, { referenceId })
  }

  public _checkConflicts = (teeNumber: number, startTime: Date) => {
    const referenceId = createReferenceIdForGroup({ teeNumber, startTime })
    return this._hasConflictingGroups(referenceId)
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (state) => ({
      tournament: selectTournament(state),
      startList: selectTournamentStartLists(state),
      courseHoles: selectSelectedRoundCourse(state)?.holes || [],
      units: state.authenticationReducer.units,
      tournamentTeams: selectTournamentTeams(state).data?.teams || [],
      tournamentConfig: selectTournamentConfig(state),
    }),
    {
      updateStartListGroup: tournamentStartListGroupApi.endpoints.updateStartListGroup.initiate,
      swapStartListGroups: tournamentStartListGroupApi.endpoints.swapStartListGroups.initiate,
      deleteStartListGroup: tournamentStartListGroupApi.endpoints.deleteStartListGroup.initiate,
      updateTeam: tournamentTeamsApi.endpoints.updateTeam.initiate,
      moveTeam,
    },
  )(injectIntl(StartGroupHeader)),
)
