import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Grid, MenuItem, Typography, OutlinedInput } from '@mui/material'
import { get } from 'lodash'
import SectionTitle from '@components/ui/SectionTitle'
import { HelpFormLabel } from '@components/forms/HelpFormLabel'
import SelectBox from '@components/ui/SelectBox'
import ActiveRound, { ActiveRoundArgs } from '@components/headless/ActiveRound'
import { StartTypes } from '@config/index'
import { emptyStartListRound } from '@app/store/api/emptyObjects'
import { selectTournamentStartLists, updateStartListField } from '@app/store/api/slices/tournamentStartListsSlice'
import { updateRoundField } from '@app/store/api/thunks/tournamentThunks'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { TimePicker } from '@app/components/date-pickers'
import { isValidDate } from '@app/utils/dates'
import { saveAllStartLists } from '@app/store/api/thunks/tournamentStartListsThunks'
import { saveTournamentRound } from '@app/store/api/thunks/tournamentRoundThunks'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

interface OwnProps {
  hasGroups: boolean
}

interface StateProps {
  isTeamFormat: boolean
  isTwoTeamFormat: boolean
  rounds: StartListRound[]
  selectedRoundIndex: number
  tournament: TournamentState
  tournamentRounds: Round[]
  tournamentStartLists: TournamentStartListState
  units: OrganizationUnits
}

interface DispatchProps {
  saveTournamentRound: (payload: TournamentRoundPayload) => void
  saveAllStartLists(onSuccess?: () => void): void
  updateRoundField: (payload: UpdateRoundFieldPayload) => void
  updateStartListField(payload: UpdateRoundFieldPayload): void
}

type Props = OwnProps & StateProps & DispatchProps

class StartListSettingsComponent extends React.Component<Props> {
  render() {
    const { hasGroups, units } = this.props
    const currentRound = this.getCurrentStartListRound()
    const { teeInterval } = currentRound

    const teeIntervals: any[] = []
    for (let i = 1; i <= 30; i++) {
      teeIntervals.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>,
      )
    }

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
          <SectionTitle style={{ flexShrink: 0, marginRight: '15px' }}>
            <FormattedMessageWrapper id={'tournament.startListForRound'} />
          </SectionTitle>
        </div>

        <Grid container spacing={3}>
          {hasGroups && (
            <Grid item xs={12}>
              <Typography>
                <FormattedMessageWrapper id="tournament.poolOrderNotEditable" />
              </Typography>
            </Grid>
          )}
          <Grid item>
            <HelpFormLabel>
              <FormattedMessageWrapper id={'tournament.startTime'} />
            </HelpFormLabel>
            <TimePicker
              key={this.props.selectedRoundIndex}
              name="startTime"
              id="startTime"
              value={this.getStartTime()}
              units={units}
              onAccept={this.saveStartTime}
            />
          </Grid>
          <Grid item>{this._renderStartType()}</Grid>
          <Grid item>{this._renderGroupSizeSelector()}</Grid>
          <Grid item>
            <HelpFormLabel>
              <FormattedMessageWrapper id={'tournament.teeInterval'} />
            </HelpFormLabel>
            <SelectBox
              name="teeInterval"
              id="teeInterval"
              value={teeInterval || emptyStartListRound.teeInterval}
              onChange={this.onSelectChange}
              disabled={hasGroups}
              inputElement={OutlinedInput}
              margin="none"
            >
              {teeIntervals}
            </SelectBox>
          </Grid>
        </Grid>
      </>
    )
  }

  private getStartTime = (): Date => {
    const { rounds } = this.props.tournament
    const selectedRound = rounds[this.props.selectedRoundIndex]
    return selectedRound.startTime
  }

  private _renderStartType = () => {
    const { hasGroups, tournament } = this.props
    const currentRound = this.getCurrentStartListRound()
    const { startType } = currentRound

    return (
      <ActiveRound>
        {({ isBackNine, isFrontNine }: ActiveRoundArgs) => (
          <>
            <HelpFormLabel>
              <FormattedMessageWrapper id={'tournament.startType'} />
            </HelpFormLabel>
            <SelectBox
              name="startType"
              id="startType"
              disabled={hasGroups}
              value={startType || emptyStartListRound.startType}
              onChange={this.onSelectChange}
              inputElement={OutlinedInput}
              margin="none"
            >
              <MenuItem disabled={isBackNine} value={StartTypes.FIRST_HOLE.value}>
                {StartTypes.FIRST_HOLE.label}
              </MenuItem>
              <MenuItem disabled={isFrontNine} value={StartTypes.TENTH_HOLE.value}>
                {StartTypes.TENTH_HOLE.label}
              </MenuItem>
              {tournament.tournamentType !== TournamentTypes.weekly && (
                <MenuItem disabled={isBackNine || isFrontNine} value={StartTypes.FIRST_AND_TENTH_HOLE.value}>
                  {StartTypes.FIRST_AND_TENTH_HOLE.label}
                </MenuItem>
              )}
              {tournament.tournamentType !== TournamentTypes.weekly && (
                <MenuItem value={StartTypes.SHOTGUN.value}>{StartTypes.SHOTGUN.label}</MenuItem>
              )}
              {tournament.tournamentType !== TournamentTypes.weekly && (
                <MenuItem value={StartTypes.SHOTGUN_REVERSED.value}>{StartTypes.SHOTGUN_REVERSED.label}</MenuItem>
              )}
            </SelectBox>
          </>
        )}
      </ActiveRound>
    )
  }

  private _renderGroupSizeSelector = () => {
    const { isTeamFormat, isTwoTeamFormat, hasGroups } = this.props

    const currentRound = this.getCurrentStartListRound()
    const { basicGroupSize } = currentRound

    let options: number[] = []

    if (isTwoTeamFormat) {
      options = [2, 4]
    } else if (isTeamFormat) {
      options = [3, 4, 5]
    } else {
      options = [1, 2, 3, 4, 5]
    }

    return (
      <>
        <HelpFormLabel>
          <FormattedMessageWrapper id={'tournament.basicGroupSize'} />
        </HelpFormLabel>
        <SelectBox
          id="basicGroupSize"
          name="basicGroupSize"
          value={basicGroupSize || emptyStartListRound.basicGroupSize}
          onChange={this.onSelectChange}
          disabled={hasGroups}
          inputElement={OutlinedInput}
          margin="none"
        >
          {options.map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </SelectBox>
      </>
    )
  }

  /**
   * Updates start time in round and start list
   * @param startTime
   */
  private updateRoundStartTime = (startTime: Date) => {
    const { updateRoundField, updateStartListField, selectedRoundIndex } = this.props

    updateRoundField({
      index: selectedRoundIndex,
      fieldName: 'startTime',
      value: startTime,
    })
    updateStartListField({
      index: selectedRoundIndex,
      fieldName: 'startTime',
      value: startTime,
    })
  }

  /**
   * Handles start time saving flow
   * @param date
   */
  private saveStartTime = (startTime: DateTimeValue) => {
    if (!isValidDate(startTime)) {
      return
    }

    // Check the value has actually changed
    const currentStartTime = this.getStartTime()

    if (startTime?.getTime() === currentStartTime.getTime()) {
      return
    }

    const { saveTournamentRound } = this.props
    // update round start time
    this.updateRoundStartTime(startTime as Date)
    // Save rounds
    const round = { ...this.getCurrentSelectedRound(), startTime: startTime as Date }
    saveTournamentRound({ tournamentId: this.props.tournament.id, body: round })
  }

  private getCurrentStartListRound = (): StartListRound => {
    const { tournamentStartLists, selectedRoundIndex } = this.props
    const { rounds } = tournamentStartLists
    return rounds[selectedRoundIndex]
  }

  private getCurrentSelectedRound = (): Round => {
    const { tournamentRounds, selectedRoundIndex } = this.props
    return tournamentRounds[selectedRoundIndex]
  }

  private onSelectChange = (event) => {
    const { selectedRoundIndex, updateStartListField, saveAllStartLists } = this.props
    const name = get(event, 'target.name')
    if (name) {
      updateStartListField({
        index: selectedRoundIndex,
        fieldName: name,
        value: event.target.value,
      })
      saveAllStartLists()
    }
  }
}

export const StartListSettings = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => {
    const tournament = selectTournament(state)
    return {
      isTeamFormat: tournament.isTeamFormat,
      isTwoTeamFormat: tournament.isTwoManTeamFormat,
      rounds: selectTournamentStartLists(state).rounds,
      selectedRoundIndex: selectTournamentConfig(state).selectedRoundIndex,
      tournament: tournament,
      tournamentRounds: tournament.rounds,
      tournamentStartLists: selectTournamentStartLists(state),
      units: state.authenticationReducer.units,
    }
  },
  {
    saveTournamentRound,
    saveAllStartLists,
    updateRoundField,
    updateStartListField,
  },
)(StartListSettingsComponent)
