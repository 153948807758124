import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import {
  processTournamentImageUpload,
  processTournamentFileUpload,
  processTournamentSiteResponse,
  processUpdateSitePayload,
} from '../utils/tournamentSiteUtils'
import {
  saveTournamentSiteData,
  updateTournamentFeedPictureList,
  updateTournamentSiteField,
  updateTournamentSiteImageField,
  updateTournamentSiteImages,
} from '../thunks/tournamentSiteThunks'
import { emptyTournamentSite } from '../emptyObjects'
import { RootState } from '@app/store'
import { getTournamentId } from '../slices/configSlice'
import { enqueueNotification } from '@app/store/notifications/actions'

export const tournamentSiteApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTournamentSite: build.query<TournamentSite, number>({
      query: (tournamentId: number) => APIRoute.GET_TOURNAMENT_SITE(tournamentId),
      transformResponse: (response: TournamentSite) => {
        return processTournamentSiteResponse(response)
      },
      providesTags: [CacheTag.TOURNAMENT_SITE],
    }),
    updateTournamentSite: build.mutation<TournamentSite, TournamentSiteUpdatePayload>({
      query: (payload) => ({
        url: APIRoute.PUT_TOURNAMENT_SITE(payload.id),
        method: HttpMethod.PUT,
        body: processUpdateSitePayload(payload.body),
      }),
      transformResponse: (response: TournamentSite) => {
        return processTournamentSiteResponse(response)
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(saveTournamentSiteData(data))
        } catch {
          console.error('Error updating tournament site')
        }
      },
    }),
    getTournamentFeedPictureList: build.query<GameFeedImage[], number>({
      query: (tournamentId: number) => APIRoute.GET_FEED_PICTURE_LIST(tournamentId),
      transformResponse: (response: { gameFeedImages: GameFeedImage[] }) => {
        return response.gameFeedImages
      },
    }),
    fetchTournamentFeedPictures: build.mutation<{ gameFeedImages: GameFeedImage[] }, number>({
      query: (tournamentId: number) => ({
        url: APIRoute.GET_FEED_PICTURES(tournamentId),
        method: HttpMethod.POST,
      }),
      async onQueryStarted(tournamentId, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateTournamentFeedPictureList(tournamentId, data.gameFeedImages))
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_SITE]))
          console.error('Error fetching tournament feed pictures')
        }
      },
    }),
    sortAdPictures: build.mutation<TournamentImages, SortPicturesPayload>({
      query: (payload) => ({
        url: APIRoute.SORT_PICTURES(payload.tournamentId, payload.imageId),
        method: HttpMethod.PUT,
        body: { order: payload.order },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: images } = await queryFulfilled
          dispatch(updateTournamentSiteImages(payload.tournamentId, images))
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_SITE]))
          console.error('Error sorting ad pictures')
        }
      },
    }),
    sortFeedPictures: build.mutation<{ gameFeedImages: GameFeedImage[] }, SortPicturesPayload>({
      query: (payload) => ({
        url: APIRoute.SORT_FEED_PICTURES(payload.tournamentId, payload.imageId),
        method: HttpMethod.PUT,
        body: { order: payload.order },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateTournamentFeedPictureList(payload.tournamentId, data.gameFeedImages))
        } catch {
          console.error('Error sorting feed pictures')
        }
      },
    }),
    deleteFeedPicture: build.mutation<{ gameFeedImages: GameFeedImage[] }, DeleteFeedPicturePayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_FEED_PICTURE(payload.tournamentId, payload.imageId),
        method: HttpMethod.DELETE,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateTournamentFeedPictureList(payload.tournamentId, data.gameFeedImages))
        } catch {
          console.error('Error deleting feed picture')
        }
      },
    }),
    uploadTournamentImage: build.mutation<TournamentImage, UploadTournamentImagePayload>({
      query: (payload) => ({
        url: APIRoute.POST_TOURNAMENT_IMAGE(payload.tournamentId),
        method: HttpMethod.POST,
        body: processTournamentImageUpload(payload),
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: image } = await queryFulfilled
          dispatch(updateTournamentSiteImageField(payload, image))
        } catch (error) {
          console.error('Error uploading tournament image', error)
          enqueueNotification('Error uploading tournament image', 'error')
        }
      },
    }),
    deleteTournamentImage: build.mutation<TournamentImages, DeleteTournamentImagePayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_TOURNAMENT_IMAGE(payload.tournamentId, payload.imageId),
        method: HttpMethod.DELETE,
      }),
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data: images } = await queryFulfilled
          dispatch(updateTournamentSiteImages(payload.tournamentId, images))
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_SITE]))
          console.error('Error deleting tournament image')
        }
      },
    }),
    uploadTournamentInvitationFile: build.mutation<TournamentImage, UploadTournamentFilePayload>({
      query: (payload) => ({
        url: APIRoute.POST_TOURNAMENT_SITE_INVITATION_FILE(payload.tournamentId),
        method: HttpMethod.POST,
        body: processTournamentFileUpload(payload.file),
      }),
    }),
    uploadTournamentSummaryFile: build.mutation<TournamentImage, UploadTournamentFilePayload>({
      query: (payload) => ({
        url: APIRoute.POST_TOURNAMENT_SITE_SUMMARY_FILE(payload.tournamentId),
        method: HttpMethod.POST,
        body: processTournamentFileUpload(payload.file),
      }),
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateTournamentSiteField({ fieldName: 'summaryFile', value: data }))
        } catch {
          console.error('Error uploading tournament summary file')
        }
      },
    }),
  }),
})

export const selectTournamentSite = (state: RootState) =>
  tournamentSiteApi.endpoints.getTournamentSite.select(getTournamentId(state))(state).data || emptyTournamentSite

export const selectTournamentFeedPictures = (state: RootState) =>
  tournamentSiteApi.endpoints.getTournamentFeedPictureList.select(getTournamentId(state))(state).data || []

export const {
  useGetTournamentSiteQuery,
  useLazyGetTournamentSiteQuery,
  useUpdateTournamentSiteMutation,
  useGetTournamentFeedPictureListQuery,
  useFetchTournamentFeedPicturesMutation,
  useSortAdPicturesMutation,
  useSortFeedPicturesMutation,
  useDeleteFeedPictureMutation,
  useUploadTournamentImageMutation,
  useDeleteTournamentImageMutation,
  useUploadTournamentInvitationFileMutation,
} = tournamentSiteApi
