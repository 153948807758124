import React from 'react'
import { CircularProgress, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'

interface DeleteButtonProps {
  isDeleting: boolean
  id?: string
  disabled?: boolean
  className?: string
  onClick?: () => void
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ isDeleting, id, disabled, className, onClick }) => {
  const elementId = id || Math.random().toString(36).substring(7)
  return (
    <IconButton
      disabled={isDeleting || disabled}
      id={elementId}
      className={className}
      onClick={onClick}
      size="large"
      data-testid="delete"
    >
      {!isDeleting && <Delete color="error" />}
      {isDeleting && <CircularProgress size={20} />}
    </IconButton>
  )
}

export default DeleteButton
