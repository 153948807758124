import { Action } from 'redux'
import {
  TOURNAMENT_LOAD_LEADERBOARD,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD,
  TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING,
  TOURNAMENT_FETCH_SCORE_SENDING_STATUS,
} from './constants'

/*
 * TOURNAMENT LOAD LEADERBOARD
 */
export interface LoadTournamentLeaderboardArgs extends APICall {
  id: number
}

export interface LoadTournamentLeaderboardAction extends Action, LoadTournamentLeaderboardArgs {
  type: 'TOURNAMENT_LOAD_LEADERBOARD'
}

export interface LoadTournamentLeaderboardSuccessAction extends Action {
  type: 'TOURNAMENT_LOAD_LEADERBOARD_SUCCESS'
  data: TournamentLeaderboard
}
export interface LoadTournamentLeaderboardFailureAction extends Action {
  type: 'TOURNAMENT_LOAD_LEADERBOARD_FAILURE'
  error: ErrorPayload
}
export const loadTournamentLeaderboard = ({
  id,
  onComplete,
}: LoadTournamentLeaderboardArgs): LoadTournamentLeaderboardAction => ({
  type: TOURNAMENT_LOAD_LEADERBOARD,
  id,
  onComplete,
})

/*
 * TOURNAMENT RESET LEADERBOARD
 */
export interface ResetTournamentLeaderboardAction extends Action {
  type: 'TOURNAMENT_RESET_LEADERBOARD'
}
export const resetTournamentLeaderboard = (): ResetTournamentLeaderboardAction => ({
  type: 'TOURNAMENT_RESET_LEADERBOARD',
})

/*
 * TOURNAMENT LOAD CONTEST LEADERBOARD
 */
export interface LoadTournamentContestLeaderboardAction extends Action {
  type: 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD'
  id: number
  divisionId?: number
}

export interface LoadTournamentContestLeaderboardSuccessAction extends Action {
  type: 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS'
  contestLeaderboard: ContestsLeaderboard
}
export interface LoadTournamentContestLeaderboardFailureAction extends Action {
  type: 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE'
  error: ErrorPayload
}
export const loadTournamentContestLeaderboard = (id: number, divisionId?: number) => ({
  type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD,
  id,
  divisionId,
})

/*
 * SEND SCORES FOR HANDICAPPING
 */

export interface SendScoresForHandicappingAction extends Action {
  type: 'TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING'
  id: number
}

export interface SendScoresForHandicappingSuccessAction extends SuccessAction {
  type: 'TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_SUCCESS'
  scoreStatus: ScoreStatus[]
}

export interface SendScoresForHandicappingFailureAction extends Action {
  type: 'TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_FAILURE'
  error: ErrorPayload
}

export const sendScoresForHandicapping = (id: number) => ({
  type: TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING,
  id,
})

/*
 * FETCH SCORE SENDING STATUS
 */

export interface FetchScoreSendingStatusAction extends Action {
  type: 'TOURNAMENT_FETCH_SCORE_SENDING_STATUS'
  id: number
}

export interface FetchScoreSendingStatusSuccessAction extends SuccessAction {
  type: 'TOURNAMENT_FETCH_SCORE_SENDING_STATUS_SUCCESS'
  scoreStatus: ScoreStatus[]
}

export interface FetchScoreSendingStatusFailureAction extends Action {
  type: 'TOURNAMENT_FETCH_SCORE_SENDING_STATUS_FAILURE'
  error: ErrorPayload
}

export const fetchScoreSendingStatus = (id: number) => ({
  type: TOURNAMENT_FETCH_SCORE_SENDING_STATUS,
  id,
})
