export const ReportsIcon = ({
  style = {},
  fill = '#1AA85D',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 55 55',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
  >
    <polygon
      style={{ fill: 'none' }}
      points="8.3 42.83 9.41 43.94 16.86 43.94 16.86 51.4 17.97 52.5 17.97
    42.83 8.3 42.83"
    />
    <polygon
      style={{ fill }}
      points="8.3 42.83 17.97 52.5 17.97 42.83 8.3
    42.83"
    />
    <path
      style={{ fill }}
      d="M8.3,2.5v39H19.35v11H46.7V2.5ZM31,
    30.68H14.38V28.47H31Zm8.36-6.45H14.45V22H39.31Zm0-6.44H14.45V15.58H39.31Zm0-6.45H14.45V9.13H39.31Z"
    />
  </svg>
)
