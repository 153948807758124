import { Action } from 'redux'
import {
  CONDENSED_ORGANIZATIONS_FETCH,
  CONDENSED_ORGANIZATIONS_FETCH_SUCCESS,
  ORGANIZATION_APPLICATIONS_FETCH,
  ORGANIZATION_APPLICATIONS_FETCH_ERROR,
  ORGANIZATION_APPLICATIONS_FETCH_SUCCESS,
  ORGANIZATIONS_FETCH,
  ORGANIZATIONS_FETCH_ERROR,
  ORGANIZATIONS_FETCH_SUCCESS,
  FETCH_ORGANIZATIONS_TOURNAMENTS,
} from './constants'

export interface FetchOrganizationsAction extends Action, PagedAction, APICall {
  type: 'ORGANIZATIONS_FETCH'
  searchTerm: string
  filter: string
}

export const fetchOrganizations = (
  page: number,
  searchTerm: string,
  filter: string,
  onComplete?: (args: APICallResult) => void,
): FetchOrganizationsAction => ({
  type: ORGANIZATIONS_FETCH,
  page,
  searchTerm,
  filter,
  onComplete,
})

export interface OrganizationsFetchSuccessAction extends Action {
  type: 'ORGANIZATIONS_FETCH_SUCCESS'
  organizations: OrganizationsItem[]
}

export const putOrganizationsSuccess = (organizations: OrganizationsItem[]): OrganizationsFetchSuccessAction => ({
  type: ORGANIZATIONS_FETCH_SUCCESS,
  organizations,
})

export interface OrganizationsFetchErrorAction extends Action {
  type: 'ORGANIZATIONS_FETCH_ERROR'
  error: ErrorPayload
}

export const putOrganizationsError = (error: string): OrganizationsFetchErrorAction => ({
  type: ORGANIZATIONS_FETCH_ERROR,
  error: {
    error,
  },
})

export interface FetchOrganizationApplicationsAction extends Action, PagedAction, APICall {
  type: 'ORGANIZATION_APPLICATIONS_FETCH'
}

export const fetchOrganizationApplications = (page, onComplete): FetchOrganizationApplicationsAction => ({
  type: ORGANIZATION_APPLICATIONS_FETCH,
  page,
  onComplete,
})

export interface OrganizationApplicationsFetchSuccessAction extends Action {
  type: 'ORGANIZATION_APPLICATIONS_FETCH_SUCCESS'
  organizationApplications: OrganizationApplication[]
}

export const putOrganizationApplicationsSuccess = (
  organizationApplications: OrganizationApplication[],
): OrganizationApplicationsFetchSuccessAction => ({
  type: ORGANIZATION_APPLICATIONS_FETCH_SUCCESS,
  organizationApplications,
})

export interface OrganizationApplicationsFetchErrorAction extends Action {
  type: 'ORGANIZATION_APPLICATIONS_FETCH_ERROR'
  error: ErrorPayload
}

export const putOrganizationApplicationsError = (error: string): OrganizationApplicationsFetchErrorAction => ({
  type: ORGANIZATION_APPLICATIONS_FETCH_ERROR,
  error: {
    error,
  },
})

export interface FetchCondensedOrganizationsAction extends Action {
  type: 'CONDENSED_ORGANIZATIONS_FETCH'
}

export interface PutCondensedOrganizationsSuccessAction extends Action {
  type: 'CONDENSED_ORGANIZATIONS_FETCH_SUCCESS'
  organizations: CondensedItem[]
}

export const fetchCondensedOrganizations = (): FetchCondensedOrganizationsAction => ({
  type: CONDENSED_ORGANIZATIONS_FETCH,
})

export const putCondensedOrganizationsSuccess = (
  organizations: CondensedItem[],
): PutCondensedOrganizationsSuccessAction => ({
  type: CONDENSED_ORGANIZATIONS_FETCH_SUCCESS,
  organizations,
})

/**
 * FETCH TOURNAMENTS
 */

export interface FetchOrganizationTournaments extends Action {
  type: 'FETCH_ORGANIZATIONS_TOURNAMENTS'
  searchTerm: string
  sortBy?: string
  direction?: string
  page?: number
  onComplete?: (args: APICallResult) => void
}

export interface FetchOrganizationTournamentsSuccess extends Action {
  type: 'FETCH_ORGANIZATIONS_TOURNAMENTS_SUCCESS'
  organizationTournaments: OrganizationTournaments
}

export interface FetchOrganizationTournamentsFailure extends Action {
  type: 'FETCH_ORGANIZATIONS_TOURNAMENTS_FAILURE'
  error: ErrorPayload
}

export const fetchOrganizationTournaments = (
  searchTerm,
  sortBy,
  direction,
  page,
  onComplete,
): FetchOrganizationTournaments => ({
  type: FETCH_ORGANIZATIONS_TOURNAMENTS,
  searchTerm,
  sortBy,
  direction,
  page,
  onComplete,
})
