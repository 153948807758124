// LIBRARIES
import React from 'react'
import { Grid, Theme, SwitchProps } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import Button from '@mui/material/Button'
import { Email } from '@mui/icons-material'

// COMPONENTS
import ContentWrap from '@components/layout/ContentWrap'
import TournamentFormActions from '@components/tournament/TournamentFormActions'
import { scrollToTop } from '@components/ui'
import { confirm } from '@components/dialogs/confirm/Confirm'
import HeadHelmet from '@components/layout/HeadHelmet'
import { StartListSettings } from './StartListSettings'
import { NoStartTimes } from './NoStartTimes'
import { SectionWrapper } from '@components/layout/SectionWrapper'
import { StartList } from './StartList'
import { PlayerPool } from './PlayerPool'
import { SettingCard } from '@app/components/cards'
import ShotgunStartHolePicker from '@app/components/tournament/ShotgunStartHolePicker'
import { SendEmailDialog } from '@components/dialogs/sendEmailDialog/sendEmailDialog'

// STORE & UTILS
import { enqueueNotification } from '@store/notifications/actions'
import { loadAllListsInSync } from '@app/store/api/thunks/tournamentThunks'
import { updateTournamentRoundStatus } from '@app/store/api/thunks/tournamentRoundThunks'
import {
  addPlayerToGroup,
  removePlayerFromGroup,
  saveAllStartLists,
  moveTeam,
  publishStartList,
  fetchAllStartLists,
  getStartListEmailPreview,
} from '@store/api/thunks/tournamentStartListsThunks'
import { tournamentPlayersReorderUnassigned } from '@store/api/thunks/tournamentPlayersThunks'
import { getNextUrl } from '@utils/nextUrlHelper'
import { handleOnDragEnd, handleOnDragStart } from '@utils/dragUtils'
import { StartTypes } from '@config/index'
import { StartListSourceTarget } from '@app/store/api/enums/tournamentStartListsEnums'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { RoundSelection } from '@app/components/tournament/round-selection'
import { createEmailPlayerList, verifyIfRoundIsCompleted } from '@app/utils/tournamentUtils'
import { loadTournamentLeaderboard, LoadTournamentLeaderboardArgs } from '@app/store/tournamentLeaderboard/actions'
import { tournamentStartListsEmailApi } from '@app/store/api/endpoints/tournamentStartListsEmailApi'
import { selectTournamentStartLists, updateStartListField } from '@app/store/api/slices/tournamentStartListsSlice'
import { tournamentStartListGroupApi } from '@app/store/api/endpoints/tournamentStartListGroupApi'
import { tournamentSettingsApi } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournamentTeams } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournamentPlayers } from '@app/store/api/endpoints/tournamentPlayersApi'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { MixpanelEvents, mixpanelActions } from '@app/utils/mixpanel'
import { getGameCreatedEventData } from '@app/utils/mixpanelData'

interface StateProps {
  players: TournamentPlayer[]
  selectedRoundIndex: number
  tournamentTeams: TournamentTeam[]
  tournament: TournamentState
  tournamentRounds: Round[]
  tournamentStartLists: TournamentStartListState
  units: OrganizationUnits
  selectedRoundId: number
}

interface State {
  isStatusChangingCooldown: boolean
  sendEmailDialogOpen: boolean
  emailBody: string
  startListsFetched: boolean
}

interface DispatchProps {
  addPlayerToGroup(payload: AddPlayerToGroupPayload): void
  enqueueNotification(error: any, variant?: string): void
  moveTeam(payload: MoveTournamentTeamAction): void
  publishStartList(payload: PublishStartListPayload): void
  removePlayerFromGroup(payload: RemovePlayerFromGroupPayload): void
  saveAllStartLists(onSuccess?: () => void): void
  updateStartListField(payload: UpdateRoundFieldPayload): void
  tournamentPlayersReorderUnassigned: (oldIndex: number, newIndex: number) => void
  updateTournamentRoundStatus: (payload: UpdateRoundStatusPayload) => void
  loadAllListsInSync(tournamentId: number): void
  getTournamentSettings(id: number): void
  loadTournamentLeaderboard(args: LoadTournamentLeaderboardArgs): void
  updateTournamentPlayerGroupOrder(payload: UpdatePlayerGroupOrderPayload): void
  emailSend(payload: StartListEmailPayload): void
  getStartListEmailPreview(payload: StartListEmailPreviewPayload): void
  fetchAllStartLists(): void
}

const styles = (theme: Theme) =>
  createStyles({
    startListSettingsWrapper: { marginRight: 0, marginBottom: 0 },
    startListCard: { backgroundColor: theme.customPalette.blueBackground },
    cardWrapper: {
      padding: 0,
      marginBottom: 0,
      marginLeft: 0,
      height: `calc(100% - ${theme.spacing(3)})`,
    },
    notifyButton: {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.light,
        opacity: 0.5,
      },
    },
  })

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps & WithStyles<typeof styles>

const MAX_PLAYERS_IN_GROUP = 5

class GroupsAndStartListComponent extends React.Component<Props> {
  readonly state: State = {
    isStatusChangingCooldown: false,
    sendEmailDialogOpen: false,
    emailBody: '',
    startListsFetched: false,
  }

  public componentDidMount(): void {
    const { loadAllListsInSync, getTournamentSettings, loadTournamentLeaderboard, params } = this.props
    const tournamentId = Number(params.id)
    if (tournamentId) {
      getTournamentSettings(tournamentId)
      loadAllListsInSync(tournamentId)
      loadTournamentLeaderboard({ id: tournamentId })
    }
    this._fetchStartLists()
  }

  public componentDidUpdate(): void {
    this._fetchStartLists()
  }

  public handleSendEmailDialogState = () => {
    const { sendEmailDialogOpen } = this.state
    this.setState({ sendEmailDialogOpen: !sendEmailDialogOpen })
  }

  checkStartListsGroups = () => {
    const { tournamentStartLists, selectedRoundId } = this.props
    return (
      tournamentStartLists.rounds
        .find((rounds) => rounds.tournamentRoundId === selectedRoundId)
        ?.groups.find((groups) => groups)?.startListPlayers.length === 0
    )
  }

  private handleSendEmail = (language: string, startListPlayerIds: number[], organizerNotice: string) => {
    const { tournament, selectedRoundId: roundId, emailSend, intl } = this.props
    const tournamentId = tournament.id
    const body = { language, startListPlayerIds, organizerNotice }
    const successMessage = intl.formatMessage({ id: 'tournament.emailsSendSuccessfullyText' })
    const errorMessage = intl.formatMessage({ id: 'errors.sendEmailError' })
    emailSend({ tournamentId, roundId, body, successMessage, errorMessage })
    this.handleSendEmailDialogState()
  }

  private handlePreviewFetch = (language: string) => {
    const { tournament, selectedRoundId: roundId, getStartListEmailPreview } = this.props
    const tournamentId = tournament.id
    getStartListEmailPreview({
      tournamentId,
      roundId,
      language,
      onComplete: (emailBody) => {
        this.setState({ emailBody })
      },
    })
  }

  public render() {
    const { tournament, intl, classes, tournamentStartLists, players, units, selectedRoundId } = this.props
    const { sendEmailDialogOpen, emailBody } = this.state

    if (!tournament || !this.currentRound) {
      return null
    }

    /**
     * No start times component
     */
    if (tournament && tournament.tournamentType === TournamentTypes.weekly) {
      return <NoStartTimes onClickNext={this.onClickNext} />
    }

    const currentRound = this.currentRound
    const { startType } = currentRound

    const startListSwitchProps: SwitchProps = {
      onChange: this._onStartListSwitchChange,
      checked: this.isStartListPublished,
      disabled: this.state.isStatusChangingCooldown || this.isScoringEnabled || this.isRoundEnded,
    }

    const scoringSwitchProps: SwitchProps = {
      onChange: this._onScoringSwitchChange,
      checked: this.isScoringEnabled,
      disabled: this.state.isStatusChangingCooldown || this.isStartListPublished === false || this.isRoundEnded,
    }

    const playerEmailList = createEmailPlayerList(tournamentStartLists.rounds, players, selectedRoundId, units)

    return (
      <>
        <HeadHelmet titleId={'progress.groups'} />

        {sendEmailDialogOpen && (
          <SendEmailDialog
            selectedRoundId={selectedRoundId}
            open={sendEmailDialogOpen}
            textAreaTitle={<FormattedMessageWrapper id="tournament.addNote" />}
            sendEmailFn={this.handleSendEmail}
            fetchPreviewEmailFn={this.handlePreviewFetch}
            previewEmailBody={emailBody}
            onClose={this.handleSendEmailDialogState}
            listItems={playerEmailList}
            showStartTimes={true}
          />
        )}

        {tournament.tournamentType === TournamentTypes.multiRound && (
          <SectionWrapper style={{ marginBottom: 0 }}>
            <ContentWrap>
              <RoundSelection isAddRoundVisible={false} />
            </ContentWrap>
          </SectionWrapper>
        )}

        <DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={handleOnDragStart}>
          {/**
           * Start list settings component
           */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SectionWrapper className={classes.startListSettingsWrapper}>
                <ContentWrap>
                  <StartListSettings hasGroups={this.hasGroups} />
                </ContentWrap>
              </SectionWrapper>
            </Grid>

            <Grid item xs>
              <SectionWrapper className={classes.cardWrapper} style={{ marginRight: 0 }}>
                <SettingCard
                  style={{ height: '100%' }}
                  titleId={'tournament.startList'}
                  descriptionId={'tournament.startListSettingDescription'}
                  className={classes.startListCard}
                  switchProps={startListSwitchProps}
                  children={
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.notifyButton}
                      onClick={this.handleSendEmailDialogState}
                      startIcon={<Email />}
                      disabled={this.checkStartListsGroups()}
                    >
                      <FormattedMessageWrapper id={'buttons.notifyPlayers'} />
                    </Button>
                  }
                />
              </SectionWrapper>
            </Grid>

            <Grid item xs>
              <SectionWrapper className={classes.cardWrapper}>
                <SettingCard
                  style={{ height: '100%', backgroundColor: '#0b8e50' }}
                  titleId={'tournament.scoring'}
                  descriptionId={'tournament.scoringSettingDescription'}
                  switchProps={scoringSwitchProps}
                  tooltipTitle={
                    scoringSwitchProps.disabled &&
                    !scoringSwitchProps.checked && (
                      <FormattedMessageWrapper id="tournament.switchDisabledTooltips.Scoring" />
                    )
                  }
                />
              </SectionWrapper>
            </Grid>
          </Grid>

          {(startType === StartTypes.SHOTGUN.value || startType === StartTypes.SHOTGUN_REVERSED.value) && (
            <SectionWrapper>
              <ContentWrap>
                <ShotgunStartHolePicker />
              </ContentWrap>
            </SectionWrapper>
          )}

          <SectionWrapper>
            <ContentWrap>
              <Grid container spacing={2}>
                <Grid xs={7} item style={{ display: 'flex', flexDirection: 'column' }}>
                  {/**
                   * Start list component
                   */}
                  <StartList
                    isStartListPublished={this.isStartListPublished}
                    currentRound={this.currentRound}
                    hasGroups={this.hasGroups}
                    currentTournamentRound={this.currentTournamentRound}
                    intl={intl}
                  />
                </Grid>
                <Grid xs={5} xl={5} item style={{ display: 'flex', flexDirection: 'column' }}>
                  {/**
                   * Player pool component
                   */}
                  <PlayerPool isStartListPublished={this.isStartListPublished} currentRound={this.currentRound} />
                </Grid>
              </Grid>
            </ContentWrap>

            <ContentWrap style={{ background: 'transparent' }}>
              <TournamentFormActions onClickNext={this.onClickNext} nextLabelId={'buttons.resultsAndOptions'} />
            </ContentWrap>
          </SectionWrapper>
        </DragDropContext>
      </>
    )
  }

  /**
   * Disable switches from changing status when double clicked
   */
  triggerStatusChangeCooldown = (): void => {
    this.setState({
      isStatusChangingCooldown: true,
    })
    setTimeout(() => {
      this.setState({
        isStatusChangingCooldown: false,
      })
    }, 2000)
  }

  private _onStartListSwitchChange = (_: any, checked: boolean): void => {
    const {
      tournament,
      tournamentRounds,
      selectedRoundIndex,
      players,
      publishStartList,
      updateStartListField,
      loadAllListsInSync,
    } = this.props

    if (tournament && tournament.id) {
      this.triggerStatusChangeCooldown()
      publishStartList({
        tournamentId: tournament.id,
        roundId: tournamentRounds[selectedRoundIndex].id,
        isPublic: checked,
        onSuccess: () => {
          updateStartListField({
            index: selectedRoundIndex,
            fieldName: 'public',
            value: checked,
          })
          if (tournament.id && checked) {
            loadAllListsInSync(tournament.id)
            const currentRound = tournamentRounds[selectedRoundIndex]
            const eventData = getGameCreatedEventData(tournament, currentRound, players.length)
            mixpanelActions.trackEvent(MixpanelEvents.STARTLIST_PUBLISHED, eventData)
          }
        },
      })
    }
  }

  private _fetchStartLists = () => {
    const { tournament, fetchAllStartLists } = this.props
    if (tournament && tournament.id && !this.state.startListsFetched) {
      this.setState({ startListsFetched: true })
      fetchAllStartLists()
    }
  }

  private _onScoringSwitchChange = (_: any, checked: boolean): void => {
    const { selectedRoundIndex, updateTournamentRoundStatus, tournament, tournamentRounds } = this.props
    if (tournament && tournament.id) {
      this.triggerStatusChangeCooldown()
      updateTournamentRoundStatus({
        tournamentId: tournament.id,
        roundId: tournamentRounds[selectedRoundIndex].id,
        body: {
          isScoringDisabled: !checked,
        },
      })
    }
  }

  public get isStartListPublished(): boolean {
    const { selectedRoundIndex, tournamentRounds } = this.props
    const round = tournamentRounds[selectedRoundIndex]
    return (round && round.status && round.status.isConfigured) || false
  }

  public get isScoringEnabled(): boolean {
    const { selectedRoundIndex, tournamentRounds } = this.props
    const round = tournamentRounds[selectedRoundIndex]
    const scoringEnabledStatus = round && round.status && round.status.isScoringDisabled === false ? true : false
    const enableScoring = this.isAllStatusesDisabled ? false : scoringEnabledStatus
    return enableScoring && !this.isRoundEnded
  }

  public get isRoundEnded(): boolean {
    const { selectedRoundIndex, tournamentRounds } = this.props
    return verifyIfRoundIsCompleted(selectedRoundIndex, tournamentRounds)
  }

  /**
   * This check is to allow publish start list for new rounds with "erroneus" status combination.
   * isScoringDisabled should be by default "true" in new rounds coming from backend.
   */
  public get isAllStatusesDisabled(): boolean {
    const { selectedRoundIndex, tournamentRounds } = this.props
    const round = tournamentRounds[selectedRoundIndex]
    const { status } = round
    const isCompleted = verifyIfRoundIsCompleted(selectedRoundIndex, tournamentRounds)
    return !isCompleted && !status?.isConfigured && !status?.isScoringDisabled
  }

  public onClickNext = () => {
    const { location, navigate, saveAllStartLists } = this.props
    saveAllStartLists(() => {
      const nextUrl = getNextUrl(location.pathname, 'results-and-options')
      navigate(nextUrl)
      scrollToTop()
    })
  }

  private confirmAction = (callback: () => void) => {
    const scoringStarted = this.isScoringEnabled
    const { intl } = this.props
    if (!scoringStarted) {
      callback()
      return
    }
    confirm({
      message: intl.formatMessage({ id: 'tournament.scoringStartedPlayerDeleteConfirm' }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      },
    }).then(() => {
      callback()
    })
  }

  public onDragEnd = (result: DropResult) => {
    const {
      players,
      tournamentTeams,
      addPlayerToGroup,
      removePlayerFromGroup,
      tournamentPlayersReorderUnassigned,
      moveTeam,
      selectedRoundIndex,
      updateTournamentPlayerGroupOrder,
      tournament,
    } = this.props
    handleOnDragEnd()

    const destinationDroppableId = result?.destination?.droppableId || ''
    const destinationOrderIndex = result?.destination?.index || 0
    const sourceDroppableId = result?.source?.droppableId || ''
    const sourceOrderIndex = result?.source?.index

    switch (result.type) {
      case 'droppablePlayer': {
        const draggableItemId = get(result.draggableId.split('-'), '[1]', -1)
        const [destinationType, destinationId, auxType, auxId] = destinationDroppableId.split('-')
        const [sourceType, sourceId, sourceAuxType, sourceAuxId] = sourceDroppableId.split('-')

        let sourceIdInt = parseInt(sourceId, 10)
        if (sourceType === 'newgroup') {
          sourceIdInt = sourceIdInt * -1
        }
        const sourceIndex = this.currentRound.groups.map((group) => group.id).indexOf(sourceIdInt)

        let destinationIdInt = parseInt(destinationId, 10)
        if (destinationType === 'newgroup') {
          destinationIdInt = destinationIdInt * -1
        }
        const playerIdInt = typeof draggableItemId === 'string' ? parseInt(draggableItemId, 10) : draggableItemId

        let propPlayer = players.find((p) => p.userId === playerIdInt)
        if (!propPlayer) {
          break
        }

        // pull players teebox data from group player if possible so multirounds work correctly
        const group = this.currentRound.groups.find((g) => g.startListPlayers.find((p) => p.id === playerIdInt))
        if (group) {
          const groupPlayer = group.startListPlayers.find((p) => p.id === playerIdInt)
          if (groupPlayer) {
            propPlayer = { ...propPlayer, preferredTeeBox: groupPlayer.teeBox }
          }
        } else {
          switch (propPlayer.gender) {
            case 'male':
              propPlayer = {
                ...propPlayer,
                preferredTeeBox: this.currentTournamentRound.teeBoxMen || propPlayer.preferredTeeBox,
              }
              break
            default:
              propPlayer = {
                ...propPlayer,
                preferredTeeBox: this.currentTournamentRound.teeBoxWomen || propPlayer.preferredTeeBox,
              }
              break
          }
        }
        const player = propPlayer
        if (!player) {
          return
        }

        // destination index is always 0, find out group index by some other means
        const destinationIndex = this.currentRound.groups.map((group) => group.id).indexOf(destinationIdInt)
        if (sourceDroppableId === 'unassigned' && destinationDroppableId !== 'unassigned' && destinationIndex > -1) {
          // add player to group
          addPlayerToGroup({
            roundIndex: selectedRoundIndex,
            sourceGroupIndex: null,
            groupIndex: destinationIndex,
            player,
            teamId: auxType === 'team' && auxId !== 'null' ? parseInt(auxId, 10) : undefined,
            orderIndex: destinationOrderIndex,
          })
        } else if (destinationDroppableId === 'unassigned' && sourceType === StartListSourceTarget.GROUP) {
          // remove from group
          this.confirmAction(() => {
            removePlayerFromGroup({ roundIndex: selectedRoundIndex, groupIndex: sourceIndex, playerId: player.id })
          })
        } else if (
          (sourceType === StartListSourceTarget.GROUP || sourceType === 'newgroup') &&
          (destinationType === StartListSourceTarget.GROUP || destinationType === 'newgroup')
        ) {
          if (sourceId === destinationId) {
            if (auxType !== 'team') {
              // If player dragged inside same group, but different order..
              if (sourceIndex === destinationIndex && tournament?.id && tournament.rounds[selectedRoundIndex]) {
                if (sourceOrderIndex !== destinationOrderIndex) {
                  updateTournamentPlayerGroupOrder({
                    tournamentId: tournament.id,
                    roundId: tournament.rounds[selectedRoundIndex].id,
                    playerId: player.id,
                    order: destinationOrderIndex + 1, // order starts from 1
                  })
                }
              } else {
                addPlayerToGroup({
                  roundIndex: selectedRoundIndex,
                  sourceGroupIndex: null,
                  groupIndex: destinationIndex,
                  player,
                  orderIndex: destinationOrderIndex,
                })
              }
              break
            } else if (auxType === sourceAuxType && auxId === sourceAuxId) {
              addPlayerToGroup({
                roundIndex: selectedRoundIndex,
                sourceGroupIndex: null,
                groupIndex: destinationIndex,
                player,
                teamId: auxType === 'team' && auxId !== 'null' ? parseInt(auxId, 10) : undefined,
                orderIndex: destinationOrderIndex,
              })
              break
            }
          }

          // Move player from one group to another
          addPlayerToGroup({
            roundIndex: selectedRoundIndex,
            sourceGroupIndex: sourceIndex,
            groupIndex: destinationIndex,
            player,
            teamId: auxType === 'team' && auxId !== 'null' ? parseInt(auxId, 10) : undefined,
            orderIndex: destinationOrderIndex,
          })
        } else if (sourceDroppableId === 'unassigned' && destinationDroppableId === 'unassigned') {
          if (result.destination && result.destination.index !== result.source.index) {
            tournamentPlayersReorderUnassigned(result.source.index, result.destination.index)
          }
        }
        break
      }
      case 'droppableTeam': {
        const id = get(result.draggableId.split('-'), '[3]', -1)
        const draggableItemId = typeof id === 'string' ? parseInt(id, 10) : id
        const tournamentTeam = tournamentTeams.find((team) => team.id === draggableItemId)

        if (!tournamentTeam) {
          break
        }
        const [destinationType, destinationId] = destinationDroppableId.split('-')
        const [sourceType, sourceId] = sourceDroppableId.split('-')

        const destinationIdInt = parseInt(destinationId)
        const destinationIndex = this.currentRound.groups.map((group) => group.id).indexOf(destinationIdInt)
        const sourceIndex = this.currentRound.groups.map((group) => group.id).indexOf(parseInt(sourceId))

        /**
         * Check that players count in destination group doesn't exceed 5 players.
         */
        if (destinationType === StartListSourceTarget.GROUP) {
          const playersCountInDestGroup =
            this.currentRound.groups[destinationIndex].teams
              .map((groupTeam) => groupTeam.players && groupTeam.players.length)
              .reduce((accu, current) => accu && current && accu + current, 0) || 0
          if (
            tournamentTeam.players &&
            playersCountInDestGroup + tournamentTeam.players.length > MAX_PLAYERS_IN_GROUP
          ) {
            this.props.enqueueNotification(
              this.props.intl.formatMessage({ id: 'errors.maximumGroupSizeExceeded' }),
              'error',
            )
            break
          }
        }

        if (destinationType === StartListSourceTarget.GROUP && sourceType === StartListSourceTarget.GROUP) {
          // From one group to another
          const sourceIdInt = parseInt(sourceId)
          const sourceIndex = this.currentRound.groups.map((group) => group.id).indexOf(sourceIdInt)
          if (sourceIndex === destinationIndex) {
            break
          }
          moveTeam({
            roundIndex: selectedRoundIndex,
            groupIndex: destinationIndex,
            source: StartListSourceTarget.GROUP,
            target: StartListSourceTarget.GROUP,
            team: tournamentTeam,
          })
        } else if (destinationType === StartListSourceTarget.TEAM_POOL && sourceType === StartListSourceTarget.GROUP) {
          // From group to teampool
          moveTeam({
            roundIndex: selectedRoundIndex,
            groupIndex: sourceIndex,
            source: StartListSourceTarget.GROUP,
            target: StartListSourceTarget.TEAM_POOL,
            team: tournamentTeam,
          })
        } else if (destinationType === StartListSourceTarget.GROUP && sourceType === StartListSourceTarget.TEAM_POOL) {
          // From teampool to group
          moveTeam({
            roundIndex: selectedRoundIndex,
            groupIndex: destinationIndex,
            source: StartListSourceTarget.TEAM_POOL,
            target: StartListSourceTarget.GROUP,
            team: tournamentTeam,
          })
        }
        break
      }
    }
  }

  private get currentRound() {
    const { selectedRoundIndex, tournamentStartLists } = this.props
    return tournamentStartLists.rounds[selectedRoundIndex]
  }

  private get currentTournamentRound() {
    const { selectedRoundIndex, tournamentRounds } = this.props
    return tournamentRounds[selectedRoundIndex]
  }

  private get hasGroups() {
    return this.currentRound.groups.length > 0
  }
}

export const GroupsAndStartList = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => {
    const tournament = selectTournament(state)
    const tournamentConfig = selectTournamentConfig(state)
    return {
      players: selectTournamentPlayers(state).data?.players || [],
      selectedRoundIndex: tournamentConfig.selectedRoundIndex,
      tournamentTeams: selectTournamentTeams(state).data?.teams || [],
      tournament: tournament,
      tournamentRounds: tournament.rounds,
      tournamentStartLists: selectTournamentStartLists(state),
      units: state.authenticationReducer.units,
      selectedRoundId: tournamentConfig.selectedRoundId,
    }
  },
  {
    addPlayerToGroup,
    enqueueNotification,
    moveTeam,
    publishStartList,
    removePlayerFromGroup,
    saveAllStartLists,
    tournamentPlayersReorderUnassigned,
    updateStartListField,
    updateTournamentRoundStatus,
    loadAllListsInSync,
    getTournamentSettings: tournamentSettingsApi.endpoints.getTournamentSettings.initiate,
    loadTournamentLeaderboard,
    updateTournamentPlayerGroupOrder: tournamentStartListGroupApi.endpoints.updatePlayerGroupOrder.initiate,
    emailSend: tournamentStartListsEmailApi.endpoints.sendStartListEmail.initiate,
    getStartListEmailPreview,
    fetchAllStartLists,
  },
)(withStyles(styles)(withRouter(injectIntl(GroupsAndStartListComponent as any) as any)))
