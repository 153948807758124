import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { processPlayersImportFile, processPlayersResponse } from '../utils/tournamentPlayersUtils'
import { setTournamentDirty, setTournamentNeedsUpdate } from '../slices/configSlice'
import { hideAutoSave } from '../../notifications/actions'
import { RootState } from '@app/store'
import { getTournamentId } from '../slices/configSlice'
import { updateTournamentField } from '../thunks/tournamentThunks'
import { saveTournamentPlayersData } from '../thunks/tournamentPlayersThunks'
import { getActiveStartListCacheTags } from '../utils/tournamentStartListsUtils'

export const tournamentPlayersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlayers: build.query<TournamentPlayers, number>({
      query: (tournamentId: number) => APIRoute.GET_PLAYERS(tournamentId),
      transformResponse: (response: TournamentPlayersUnprocessed): TournamentPlayers => {
        return processPlayersResponse(response)
      },
      providesTags: [CacheTag.TOURNAMENT_PLAYERS],
    }),
    addPlayerToTournament: build.mutation<void, AddPlayerToTournamentPayload>({
      query: (payload) => ({
        url: APIRoute.POST_ADD_PLAYER_TO_TOURNAMENT(payload.tournamentId),
        method: HttpMethod.POST,
        body: { providerPlayerIdentifier: payload.providerPlayerIdentifier },
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS],
    }),
    addPlayerToPool: build.mutation<TournamentPlayers, AddPlayerToPoolPayload>({
      query: (payload) => ({
        url: APIRoute.POST_ADD_PLAYER_TO_POOL(payload.tournamentId),
        method: HttpMethod.POST,
        body: payload.body,
      }),
      transformResponse: (response: TournamentPlayersUnprocessed): TournamentPlayers => {
        return processPlayersResponse(response)
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(saveTournamentPlayersData(data))
        } catch {
          console.error('Error adding player to pool')
        }
      },
      invalidatesTags: [CacheTag.TOURNAMENT_TEAMS],
    }),
    deletePlayer: build.mutation<void, DeletePlayerPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_PLAYER(payload.tournamentId, payload.playerId),
        method: HttpMethod.DELETE,
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS, CacheTag.TOURNAMENT_TEAMS],
    }),
    updatePlayer: build.mutation<void, UpdatePlayerPayload>({
      query: (payload) => ({
        url: APIRoute.PATCH_PLAYER_DETAILS(payload.tournamentId, payload.playerId),
        method: HttpMethod.PATCH,
        body: {
          ...payload.body,
          handicap: Number(payload.body.handicap),
        },
      }),
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled
          if (payload.body.isArrived === undefined || payload.forceNeedsUpdate) {
            dispatch(setTournamentNeedsUpdate(true))
            dispatch(hideAutoSave())
          }
        } catch {
          console.error('Error updating player details')
        }
      },
      invalidatesTags: () => [CacheTag.TOURNAMENT_PLAYERS, CacheTag.TOURNAMENT_TEAMS, ...getActiveStartListCacheTags()],
    }),
    updatePlayerStatus: build.mutation<void, UpdatePlayerStatusPayload>({
      query: (payload) => ({
        url: APIRoute.UPDATE_PLAYER_STATUS(payload.tournamentId, payload.playerId, payload.status),
        method: HttpMethod.PUT,
      }),
      invalidatesTags: () => [CacheTag.TOURNAMENT_PLAYERS, CacheTag.TOURNAMENT_TEAMS, ...getActiveStartListCacheTags()],
    }),
    importPlayersPreview: build.mutation<ImportPlayerPreviews, ImportPlayerPreviewPayload>({
      query: (payload) => ({
        url: APIRoute.POST_EXCEL_PREVIEW(payload.tournamentId),
        method: HttpMethod.POST,
        body: processPlayersImportFile(payload.file),
      }),
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (payload.onComplete) {
            payload.onComplete(data)
          }
        } catch {
          console.error('Error importing players')
        }
      },
    }),
    importPlayers: build.mutation<void, TournamentPlayerExcelImportPayload>({
      query: (payload) => ({
        url: APIRoute.POST_EXCEL(payload.tournamentId),
        method: HttpMethod.POST,
        body: processPlayersImportFile(payload.file),
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS, CacheTag.TOURNAMENT_TEAMS],
    }),
    deletePlayers: build.mutation<void, DeletePlayersPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_SELECTED_PLAYERS(payload.tournamentId),
        method: HttpMethod.DELETE,
        body: { playerIds: payload.playerIds },
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS, CacheTag.TOURNAMENT_TEAMS],
    }),
    updateHandicaps: build.mutation<TournamentPlayers, UpdateHandicapsPayload>({
      query: (payload) => ({
        url: APIRoute.UPDATE_HCPS(payload.tournamentId),
        method: HttpMethod.PUT,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(saveTournamentPlayersData(data))
          dispatch(updateTournamentField({ fieldName: 'handicapsUpdatedAt', value: new Date() }))
          dispatch(setTournamentDirty(false))
          if (payload.isPublished) {
            dispatch(setTournamentNeedsUpdate(true))
          }
        } catch {
          console.error('Error updating handicaps')
        }
      },
      invalidatesTags: () => [...getActiveStartListCacheTags()],
    }),
    movePlayers: build.mutation<void, MovePlayersPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_MOVE_SELECTED_PLAYERS(payload.tournamentId),
        method: HttpMethod.PUT,
        body: { playerIds: payload.playerIds, list: payload.list },
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS, CacheTag.TOURNAMENT_TEAMS],
    }),
  }),
})

export const selectTournamentPlayers = (state: RootState) =>
  tournamentPlayersApi.endpoints.getPlayers.select(getTournamentId(state))(state)

export const {
  useGetPlayersQuery,
  useLazyGetPlayersQuery,
  useAddPlayerToTournamentMutation,
  useAddPlayerToPoolMutation,
  useDeletePlayerMutation,
  useUpdatePlayerMutation,
  useUpdatePlayerStatusMutation,
  useImportPlayersPreviewMutation,
  useImportPlayersMutation,
  useDeletePlayersMutation,
  useUpdateHandicapsMutation,
  useMovePlayersMutation,
} = tournamentPlayersApi
