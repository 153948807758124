import { formatMeasurement } from '@app/utils/tournamentUtils'
import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import { Footer } from '../../elements'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '32pt',
  },
  content: {
    flex: 1,
    flexDirection: 'column',
  },
  contestName: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '13pt',
    textAlign: 'center',
  },
  header: {
    flexDirection: 'row',
    margin: 0,
  },
  headerLeft: {
    flex: 0.7,
  },
  headerRight: {
    flex: 0.3,
    textAlign: 'right',
  },
  p: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12pt',
    marginTop: '4pt',
  },
  h1: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16pt',
  },
  h2: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14pt',
  },
  contestTable: {
    marginTop: '15pt',
    border: '1pt solid #585858',
  },
  contestTableHeader: {
    padding: '5pt',
    flexDirection: 'row',
    backgroundColor: '#555',
  },
  contestTableHeaderCol: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '12pt',
    color: '#fff',
  },
  contestTableSubHeader: {
    padding: '5pt',
    flexDirection: 'row',
    backgroundColor: '#ebebeb',
  },
  contestTableSubHeaderCol: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '10pt',
    color: '#333',
  },
  contestTableResultCol: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12pt',
    color: '#333',
  },
  contestTableResultRow: {
    padding: '5pt',
    flexDirection: 'row',
  },
  contestTableResultRowOdd: {
    padding: '5pt',
    flexDirection: 'row',
    backgroundColor: '#f8f8f8',
  },
})

export type PrintoutContest = {
  name: string
  holes: {
    holeNumber: string
    results: {
      position: string
      playerName: string
      measurements?: number
      units: string
    }[]
  }[]
}

export type PrintoutContests = PrintoutContest[]

export interface ContestsPrintoutProps {
  tournamentName: string
  clubAndCourse: string
  startDate: string
  contests?: PrintoutContests
  units: AuthenticationState['units']
}

export const ContestsPrintout = ({
  tournamentName,
  clubAndCourse,
  startDate,
  contests,
  units,
}: ContestsPrintoutProps) => {
  const renderContest = (contest: PrintoutContest) => {
    return (
      <View wrap={false} style={{ marginTop: '20pt', marginLeft: '30pt', marginRight: '30pt' }} key={contest.name}>
        <Text style={styles.contestName}>{contest.name}</Text>
        <View style={styles.contestTable}>
          <View style={styles.contestTableHeader}>
            <Text style={{ ...styles.contestTableHeaderCol, flex: 0.1, paddingLeft: '4pt' }}>Pos</Text>
            <Text style={{ ...styles.contestTableHeaderCol, flex: 0.7 }}>Name</Text>
            <Text style={{ ...styles.contestTableHeaderCol, flex: 0.2 }}>Distance</Text>
          </View>
          {contest.holes.map((hole) => (
            <React.Fragment key={hole.holeNumber}>
              <View style={styles.contestTableSubHeader}>
                <Text style={{ ...styles.contestTableSubHeaderCol, paddingLeft: '4pt' }}>Hole {hole.holeNumber}</Text>
              </View>
              {hole.results.map((result, resultIdx) => (
                <View
                  key={result.position}
                  style={resultIdx % 2 === 0 ? styles.contestTableResultRow : styles.contestTableResultRowOdd}
                >
                  <Text style={{ ...styles.contestTableResultCol, flex: 0.1, paddingLeft: '10pt' }}>
                    {result.position}
                  </Text>
                  <Text style={{ ...styles.contestTableResultCol, flex: 0.7 }}>{result.playerName}</Text>
                  <Text style={{ ...styles.contestTableResultCol, flex: 0.2 }}>
                    {result.measurements ? formatMeasurement(result.measurements, units) : '-'}
                  </Text>
                </View>
              ))}
            </React.Fragment>
          ))}
        </View>
      </View>
    )
  }

  return (
    <Document title={tournamentName}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View style={styles.headerLeft}>
            <Text style={styles.h1}>{tournamentName}</Text>
            <Text style={styles.p}>
              {startDate} {clubAndCourse}
            </Text>
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.h2} render={({ pageNumber, totalPages }) => `Contests (${pageNumber}/${totalPages})`} />
          </View>
        </View>
        <View style={styles.content}>{contests?.map((contest) => renderContest(contest))}</View>
        <Footer />
      </Page>
    </Document>
  )
}
