import React from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { rem } from '@app/theme/materialUITheme'
import { Button, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DivisionsTable } from './DivisionsTable'
import { SelectDivisionsDialog } from './SelectDivisionsDialog'

export interface ViewDivisionsProps {
  divisionsCount: number
}

const useStyles = makeStyles((theme: Theme) => ({
  header: { display: 'flex', alignItems: 'center', color: theme.palette.primary.main, fontWeight: 'bold' },
  numberSelected: { marginLeft: rem(20) },
  button: {
    fontWeight: 'bold',
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2,
    },
  },
}))

const ViewDivisionsComponent: React.FC<ViewDivisionsProps> = ({ divisionsCount }) => {
  const classes = useStyles()
  const [selectDivisionsDialogOpen, setSelectDivisionsDialogOpen] = React.useState<boolean>(false)

  const handleDialogClick = () => setSelectDivisionsDialogOpen(!selectDivisionsDialogOpen)

  return (
    <>
      <div className={classes.header}>
        <Button className={classes.button} color="primary" variant="outlined" onClick={handleDialogClick}>
          <FormattedMessageWrapper id={'divisions.selectDivisions'} />
        </Button>

        <div className={classes.numberSelected}>
          <FormattedMessageWrapper id={'divisions.nSelected'} values={{ value: divisionsCount }} />
        </div>
      </div>

      <DivisionsTable />

      <SelectDivisionsDialog open={selectDivisionsDialogOpen} onClose={handleDialogClick} />
    </>
  )
}

export const ViewDivisions = ViewDivisionsComponent
