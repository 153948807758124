import {
  clubsAndCoursesApi,
  selectClubSearch,
  selectClubSearchPublic,
  selectCourses,
} from '../endpoints/clubsAndCoursesApi'
import { RootState } from '@app/store'
import { internalSetSelectedCourseId, internalSetSelectedCourseVersion } from '../slices/configSlice'
import {
  internalUpdateTournamentCourse,
  internalUpdateTournamentRoundCourses,
  selectRoundCourseOptions,
  setClubs,
} from '../slices/clubsAndCoursesSlice'
import { processRoundCourse } from '../utils/clubsAndCoursesUtils'

export const searchClubs = (payload: SearchClubsPayload, isPublicSearch: boolean = false) => {
  return (dispatch) => {
    return isPublicSearch ? dispatch(doSearchClubsPublic(payload)) : dispatch(doSearchClubs(payload))
  }
}

const doSearchClubs = (payload: SearchClubsPayload) => {
  return (dispatch, getState: () => RootState) => {
    const clubs = selectClubSearch(getState(), payload)
    const isCached = clubs.length > 0
    if (isCached) {
      dispatch(setClubs(clubs))
      payload.onComplete && payload.onComplete({ data: { clubs, currentCountryId: payload.countryId } })
      return
    }
    dispatch(clubsAndCoursesApi.endpoints.searchClubs.initiate(payload))
  }
}

const doSearchClubsPublic = (payload: SearchClubsPublicPayload) => {
  return (dispatch, getState: () => RootState) => {
    const clubs = selectClubSearchPublic(getState(), payload)
    const isCached = clubs.length > 0
    if (isCached) {
      dispatch(setClubs(clubs))
      return
    }
    dispatch(clubsAndCoursesApi.endpoints.searchClubsPublic.initiate(payload))
  }
}

export const setSelectedCourseId = (courseId: number) => {
  return (dispatch, getState: () => RootState) => {
    const courses = selectRoundCourseOptions(getState())
    const course: RoundCourse | undefined = courses?.find((course: RoundCourse) => course.id === courseId)
    if (course) {
      dispatch(internalSetSelectedCourseId(course.id))
      dispatch(internalSetSelectedCourseVersion(course.version))
    }
  }
}

export const setSelectedCourseVersion = (courseVersion: number) => {
  return (dispatch) => {
    dispatch(internalSetSelectedCourseVersion(courseVersion))
  }
}

export const getCourses = (payload: CoursesPayload) => {
  return (dispatch, getState: () => RootState) => {
    const courses = selectCourses(getState(), payload)
    const isCached = courses.length > 0
    if (isCached) {
      dispatch(updateTournamentRoundCourseOptions(courses))
      payload.onComplete && payload.onComplete({ data: { courses, currentClubId: payload.clubId } })
      return
    }
    dispatch(clubsAndCoursesApi.endpoints.getCourses.initiate(payload))
  }
}

export const updateTournamentRoundCourses = (rounds: Round[]) => {
  return (dispatch) => {
    rounds.forEach((round: Round, roundIndex: number) => {
      if (round.course) {
        dispatch(updateCourseDataToStore(round.course, roundIndex))
      }
    })
  }
}

export const updateTournamentRoundCourseOptions = (courses: Course[]) => {
  return (dispatch, getState: () => RootState) => {
    const roundIndex = getState().configReducer.tournament.selectedRoundIndex
    const roundCourses = courses.map((c) => processRoundCourse(c, roundIndex))
    dispatch(internalUpdateTournamentRoundCourses(roundCourses))
  }
}

const updateCourseDataToStore = (course: Course, roundIndex: number) => {
  return (dispatch) => {
    const roundCourse = processRoundCourse(course, roundIndex)
    dispatch(internalUpdateTournamentCourse(roundCourse))
  }
}
