import { Button, FormControl, FormLabel, Switch, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import classNames from 'classnames'

import { rem } from '@app/theme/materialUITheme'

type HoleNumber = number

export interface ContestOptionItem {
  holeNumber: number
  disabled?: boolean
}

export interface ContestProps {
  titleId: string
  options: ContestOptionItem[]
  value: HoleNumber[]
  isMeasurable: boolean
  measureValue?: boolean
  onMeasureChange: (value: boolean) => void
  onItemClick: (value: HoleNumber) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  optionsWrapper: {
    marginTop: rem(10),
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  hole: {
    border: `1px solid ${theme.customPalette.darkGray2}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    minWidth: 'auto',
    width: rem(35),
    height: rem(35),
    marginRight: rem(5),
    marginBottom: rem(5),
    cursor: 'pointer',
    '&:hover:not(.disabled), &:active:not(.disabled), &.active:not(.disabled)': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.customPalette.light,
    },
    '&.disabled': {
      opacity: 0.5,
    },
    '&.disabled.active': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.customPalette.light,
      opacity: 0.5,
    },
  },
  measureWrapper: {
    marginTop: rem(10),
  },
}))

const ContestComponent: React.FC<ContestProps> = ({
  titleId,
  options,
  value,
  onItemClick,
  isMeasurable,
  measureValue,
  onMeasureChange,
}) => {
  const classes = useStyles()

  const onHoleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onItemClick(parseInt(event.currentTarget.value))
  }

  return (
    <div className={classes.root}>
      <FormLabel>
        <FormattedMessageWrapper id={titleId} />
      </FormLabel>
      <div className={classes.optionsWrapper}>
        {options.map((item) => (
          <Button
            key={item.holeNumber}
            onClick={onHoleClick}
            value={item.holeNumber}
            aria-pressed={value.includes(item.holeNumber) ? 'true' : 'false'}
            className={classNames([classes.hole, value.includes(item.holeNumber) && 'active'])}
            disabled={item.disabled}
          >
            {item.holeNumber}
          </Button>
        ))}
      </div>
      {isMeasurable && (
        <div className={classes.measureWrapper}>
          <FormControl margin={'none'} className="SwitchControl">
            <Switch checked={measureValue} color="primary" onChange={() => onMeasureChange(!measureValue)} />
            <FormLabel className="SwitchLabel">
              <FormattedMessageWrapper id="tournament.measureResults" />
            </FormLabel>
          </FormControl>
        </div>
      )}
    </div>
  )
}

export const Contest = ContestComponent
