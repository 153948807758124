import React from 'react'
import { Collapse } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { get } from 'lodash'
import ContentWrap from '../../components/layout/ContentWrap'
import { getNextUrl } from '../../utils/nextUrlHelper'
import TournamentFormActions from '../../components/tournament/TournamentFormActions'
import SectionTitle from '../../components/ui/SectionTitle'
import { scrollToTop } from '../../components/ui'
import HeadHelmet from '../../components/layout/HeadHelmet'
import TournamentCustomization from '../../components/tournament/TournamentCustomization'
import SectionTitleWithCollapse from '../../components/ui/SectionTitleWithCollapse'
import { SectionWrapper } from '@app/components/layout/SectionWrapper'
import { RootState } from '@app/store'
import { selectTournamentSite, tournamentSiteApi } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

const styles = () =>
  createStyles({
    hidden: {
      // When the collapse hides its content, the padding of the item
      // is still taking up space, creating an unwanted space.
      // The 'hidden' style rule is only applied when the collapse finishes
      // hiding its content
      display: 'none !important',
    },
  })

interface StateProps {
  tournamentSite: TournamentSite
  tournament: TournamentState
  ads?: TournamentInAppAds
}

interface DispatchProps {
  getTournamentSite: (id: number) => void
  updateTournamentSite: (payload: TournamentSiteUpdatePayload) => void
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps & WithStyles<typeof styles>

interface State {
  expanded: string
}

class UnconnectedBranding extends React.Component<Props, State> {
  readonly state: State = {
    expanded: '',
  }

  public componentDidMount(): void {
    const id = get(this.props, 'params.id')
    const tournamentId = typeof id === 'string' ? parseInt(id, 10) : id
    const tournamentSiteid = get(this.props.tournamentSite, 'id')
    if (tournamentId && !tournamentSiteid) {
      this.props.getTournamentSite(tournamentId)
    }
  }

  public render() {
    return (
      <SectionWrapper>
        <HeadHelmet titleId={'progress.branding'} />

        <ContentWrap>
          {this._renderTournamentSiteContent()}

          <TournamentFormActions
            nextLabelId={'buttons.entries'}
            onClickNext={this.clickNext}
            onClickSaveAndPublish={this.saveBranding}
          />
        </ContentWrap>
      </SectionWrapper>
    )
  }

  private _renderTournamentSiteContent = () => {
    const { tournament, tournamentSite, classes } = this.props

    return (
      <>
        <SectionTitleWithCollapse
          expanded={this.state.expanded === 'tournament-site'}
          onChange={(expanded) => this.setState({ expanded: expanded ? 'tournament-site' : '' })}
          disabled={!this.props.tournamentSite.enabled}
        >
          <SectionTitle>
            <FormattedMessageWrapper id="tournament.tournamentSiteBranding" />
          </SectionTitle>
        </SectionTitleWithCollapse>

        <Collapse
          in={this.state.expanded === 'tournament-site'}
          classes={this.state.expanded ? undefined : { wrapper: classes.hidden }}
        >
          <TournamentCustomization
            tournament={tournament}
            tournamentSite={tournamentSite}
            showSummaryPictures={false}
          />
        </Collapse>
      </>
    )
  }

  private clickNext = () => {
    const { updateTournamentSite, tournament, tournamentSite } = this.props

    updateTournamentSite({
      id: tournament.id,
      body: tournamentSite,
      onSuccess: () => {
        const nextUrl = getNextUrl(this.props.location.pathname, 'entries')
        this.props.navigate(nextUrl)
        scrollToTop()
      },
    })
  }

  private saveBranding = () => {
    const { updateTournamentSite, tournament, tournamentSite } = this.props
    updateTournamentSite({ id: tournament.id, body: tournamentSite })
  }
}

const Branding = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => ({
    tournamentSite: selectTournamentSite(state),
    tournament: selectTournament(state),
    ads: selectTournamentSite(state).ads,
  }),
  {
    getTournamentSite: tournamentSiteApi.endpoints.getTournamentSite.initiate,
    updateTournamentSite: tournamentSiteApi.endpoints.updateTournamentSite.initiate,
  },
)(withStyles(styles)(withRouter(injectIntl(UnconnectedBranding as any) as any)))

export default Branding
