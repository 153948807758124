export const SingleRoundIcon = ({
  style = {},
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 441 413',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    style={style}
  >
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-29.000000, -43.000000)" fill="inherit">
        <path
          // eslint-disable-next-line max-len
          d="M252.836701,397.064986 C243.970095,399.216742 235.031345,393.757924 232.881427,384.883735 C230.745939,376.016767 236.185663,367.077591 245.052268,364.918615 C253.926088,362.774079 262.85041,368.218456 265.007542,377.107086 C267.150245,385.981275 261.703306,394.91323 252.836701,397.064986 Z M363.651234,308.06315 L363.651234,43 L340.240221,43 L340.240221,52.8128748 C294.031426,34.7684494 248.493579,90.8657447 201.866344,66.2866234 C203.82147,92.5553788 204.658351,139.229715 206.591833,164.798066 C248.803802,190.633582 297.883963,144.9918 340.240221,164.386489 L340.240221,376.666626 C340.240221,383.425162 345.477939,386.212336 351.934906,386.212336 C358.406302,386.212336 363.651234,383.699547 363.651234,376.666626 L363.651234,368.59393 C378.072996,370.825113 388.115563,375.792926 388.15885,381.634439 C388.209351,389.707135 369.256891,396.393465 345.81702,396.55954 C322.391578,396.725615 303.330901,390.335332 303.2804,382.262636 C303.237113,377.446457 309.982659,373.142945 320.364307,370.399094 L320.364307,300.791947 C298.114827,298.055318 274.299802,296.553421 249.489178,296.553421 C127.701409,296.553421 29,332.259577 29,376.27671 C29,420.308285 127.701409,456 249.489178,456 C371.276948,456 470,420.308285 470,376.27671 C470,347.365194 427.398449,322.042345 363.651234,308.06315 L363.651234,308.06315 Z"
          transform="translate(249.500000, 249.500000) scale(-1, 1) translate(-249.500000, -249.500000) "
        ></path>
      </g>
    </g>
  </svg>
)
