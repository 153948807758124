import React from 'react'
import { FormControl, FormLabel, Grid, MenuItem, Select, Switch, TextField, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import GameFormatPicker from './GameFormatPicker'
import { connect } from 'react-redux'
import GameFormatAdditionalInputs from './GameFormatAdditionalInputs'
import { flattenGameFormats, isTwoPlayerFormatId, verifyGameHandicapPercentage } from '../../utils/gameFormatUtils'
import { GameFormatTypes } from '../../store/api/enums/tournamentEnums'
import { rem } from '../../theme/materialUITheme'
import { scoringStartedOnAnyRound } from '../../store/api/selectors/tournamentSelectors'
import { isTeamFormatId } from '../../utils/gameFormatUtils'
import { GameFormats } from '../../store/api/enums/tournamentEnums'
import { updateGlobalHoleConfig, updateRoundField } from '@app/store/api/thunks/tournamentThunks'
import { updateTournamentSettingsField } from '@app/store/api/thunks/tournamentSettingsThunks'
import { confirm } from '@app/components/dialogs/confirm/Confirm'
import { TieBreakMethodSelector } from '@app/scenes/tournament/round-setup/tie-break-method-selector'
import { RootState } from '@app/store'
import { selectTournamentTeams } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournamentSettings } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'

const styles = (theme: Theme) =>
  createStyles({
    switch: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    switchLabel: {
      fontSize: rem(14),
      color: theme.customPalette.darkGray2,
    },
    additionalInputsWrapper: {
      background: theme.customPalette.background,
      marginTop: 10,
      marginBottom: 35,
      '@global': {
        'input, .MuiSelect-root': {
          background: theme.palette.mode === 'light' ? theme.customPalette.light : 'transparent',
        },
      },
    },
    individualHcpContainer: {
      margin: rem(30),
      marginBottom: 0,
      '& > div': {
        marginBottom: rem(20),
      },
    },
    switchTextWrapper: {
      display: 'flex',
      width: '100%',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    gridContainer: {
      backgroundColor: theme.palette.mode === 'light' ? theme.customPalette.greyBackground : theme.customPalette.dark,
      padding: rem(20),
      marginTop: rem(20),
      '& > *': {
        marginBottom: '5px',
      },
    },
    gridLabel: {
      fontSize: rem(14),
      color: theme.customPalette.darkGreen,
    },
    grid: {
      marginRight: rem(10),
    },
    formControl: {
      '& > label': {
        color: theme.customPalette.contrastGrey,
        marginBottom: '3px',
      },
      '& > div': {
        marginTop: 0,
        backgroundColor: theme.palette.mode === 'light' && theme.customPalette.light,
      },
    },
    formInput: {},
  })

interface OwnProps {
  disabled?: boolean
  index: number
  round: Round
  gameType: string
  updateGameFormat: (payload: GameFormatPayload) => void
  updateGlobalGameFormat: (payload: GameFormatPayload) => void
}

interface StateProps {
  gameFormats: GameFormat[]
  scoringStarted: boolean
  tournamentStartLists: TournamentStartListState
  teams: TournamentTeam[]
  defaultPerPlayerHandicapPercentages: DefaultPerPlayerHandicapPercentages
  tournamentSettings: TournamentSettings
  tournament: TournamentState
  selectedRoundIndex: number
}

interface DispatchProps {
  updateTournamentSettingsField(tournamentId: number, payload: FieldUpdatePayload): void
  updateRoundField: (payload: UpdateRoundFieldPayload) => void
  updateGlobalHoleConfig: (payload: UpdateRoundFieldPayload) => void
}

interface State {
  errors: {
    primary?: { [key: number]: boolean }
    side?: { [key: number]: boolean }
  }
}

const initialState: State = {
  errors: {},
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps & WithStyles<any>

class UnconnectedGameFormatSelector extends React.Component<Props, State> {
  readonly state: State = initialState

  public render() {
    const { classes, tournamentSettings, selectedRoundIndex, gameType } = this.props

    if (!this.gameFormatOptions) {
      return null
    }

    const isFirstRound = selectedRoundIndex === 0

    const {
      useHandicaps,
      usePlayingHandicaps,
      handicapPercentage,
      usePerPlayerHandicapPercentages,
      limitToLowestPhcp,
    } = this.gameFormatOptions

    const isTeamFormat = isTeamFormatId(this.gameFormatId)
    const twoPlayerFormat = isTwoPlayerFormatId(this.gameFormatId)
    const useHandicapPercentage = handicapPercentage !== 100

    return (
      <Grid container spacing={2}>
        {/**
         * Common section, for team & individual
         */}
        <Grid item xs={8}>
          <GameFormatPicker
            selectedGameFormatId={this.gameFormatId}
            onChange={this.onGameFormatChange}
            currentGameFormat={this.currentGameFormat}
            sideGamePicker={this.props.gameType !== 'primary'}
            primaryGameFormat={this.primaryGameFormat}
            disabled={this.props.disabled}
          />
        </Grid>
        <Grid item xs={4}>
          {isTeamFormat && !twoPlayerFormat && (
            <FormControl>
              <FormLabel
                style={{ margin: '5px 0 3px 0' } /* Ensures correct alignment. Fix if standard solution exists */}
              >
                <FormattedMessageWrapper id="gameFormats.defaultTeamSize" />
              </FormLabel>
              <Select
                type="number"
                variant={'outlined'}
                name={'defaultTeamSize'}
                disabled={!isFirstRound || this.props.disabled}
                value={tournamentSettings.defaultTeamSize}
                onChange={this.onSelectChange}
              >
                {[3, 4, 5].map((number, index) => (
                  <MenuItem value={number} key={index}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12}>
          <GameFormatAdditionalInputs
            roundItemIndex={this.props.index}
            gameFormatOptions={this.gameFormatOptions}
            onChange={this.onChange}
            onSelectChange={this.onSelectChange}
            onCheckboxChange={this.onCheckboxChange}
            currentGameFormat={this.currentGameFormat}
            disabled={this.props.disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth style={{ marginLeft: rem(20) }} className="SwitchControl">
            <Switch
              checked={useHandicaps}
              onChange={this.onCheckboxChange}
              name="useHandicaps"
              value="true"
              color="primary"
              disabled={this.props.disabled}
            />
            <FormLabel className="SwitchLabel">
              <FormattedMessageWrapper id="tournament.useHandicaps" />
            </FormLabel>
          </FormControl>
          {/**
           * Handicaps enabled
           */}
          {useHandicaps && (
            <>
              <FormControl fullWidth style={{ marginLeft: rem(20) }} className="SwitchControl">
                <Switch
                  checked={usePlayingHandicaps}
                  onChange={this.onCheckboxChange}
                  name="usePlayingHandicaps"
                  value="true"
                  color="primary"
                  disabled={this.props.disabled}
                />
                <FormLabel className="SwitchLabel">
                  <FormattedMessageWrapper id="tournament.usePlayingHandicaps" />
                </FormLabel>
              </FormControl>
              <div className={classes.gridContainer}>
                <FormLabel className={classes.gridLabel}>
                  <FormattedMessageWrapper id="tournament.additionalHcpSettings" />
                </FormLabel>

                <div className={classes.switchTextWrapper}>
                  <FormControl fullWidth className="SwitchControl">
                    <Switch
                      checked={useHandicapPercentage}
                      onChange={this.onCheckboxChange}
                      name="useHandicapPercentage"
                      value="true"
                      color="primary"
                      disabled={this.props.disabled}
                    />
                    <FormLabel className="SwitchLabel">
                      <FormattedMessageWrapper
                        id={
                          isTeamFormat
                            ? 'tournament.useSameHcpPercentageForWholeTeam'
                            : 'tournament.applyHandicapPercentage'
                        }
                      />
                    </FormLabel>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      type="text"
                      required={useHandicapPercentage}
                      disabled={!useHandicapPercentage || this.props.disabled}
                      variant={'outlined'}
                      name="handicapPercentage"
                      onChange={this.onChange}
                      value={handicapPercentage}
                    />
                  </FormControl>
                </div>

                {isTeamFormat && (
                  <>
                    <FormControl fullWidth className="SwitchControl">
                      <Switch
                        checked={limitToLowestPhcp}
                        onChange={this.onCheckboxChange}
                        name="limitToLowestPhcp"
                        value="true"
                        color="primary"
                        disabled={this.props.disabled}
                      />
                      <FormLabel className="SwitchLabel">
                        <FormattedMessageWrapper id="tournament.limitToLowestPhcp" />
                      </FormLabel>
                    </FormControl>

                    <FormControl fullWidth className="SwitchControl">
                      <Switch
                        checked={usePerPlayerHandicapPercentages}
                        onChange={this.onCheckboxChange}
                        name="usePerPlayerHandicapPercentages"
                        value="true"
                        color="primary"
                        disabled={this.props.disabled}
                      />
                      <FormLabel className="SwitchLabel">
                        <FormattedMessageWrapper id="tournament.usePerPlayerHandicapPercentages" />
                      </FormLabel>
                    </FormControl>
                    {this._renderIndividualTeamHandicapInputs()}
                  </>
                )}
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <TieBreakMethodSelector disabled={this.props.disabled} gameType={gameType} />
        </Grid>
      </Grid>
    )
  }

  /**
   * Returns either stored or default handicap values.
   */
  private _getIndividualTeamHandicapValues = (): string[] => {
    const { defaultPerPlayerHandicapPercentages, tournamentSettings } = this.props
    const { perPlayerHandicapPercentages } = this.gameFormatOptions
    const defaultTeamSize = tournamentSettings.defaultTeamSize || 4
    const teamSize = isTwoPlayerFormatId(this.gameFormatId) ? 2 : defaultTeamSize

    return perPlayerHandicapPercentages.length > 0 && perPlayerHandicapPercentages.length === teamSize
      ? perPlayerHandicapPercentages
      : defaultPerPlayerHandicapPercentages[teamSize]
  }

  /**
   * Renders input boxes for individual team handicap percentages according to team size.
   * One box represents a player in a team.
   */
  private _renderIndividualTeamHandicapInputs = () => {
    const { classes, gameType } = this.props
    const { usePerPlayerHandicapPercentages } = this.gameFormatOptions

    if (!usePerPlayerHandicapPercentages) {
      return null
    }

    const values = this._getIndividualTeamHandicapValues()

    if (!values) {
      return null
    }

    return (
      <Grid container className={classes.individualHcpContainer}>
        <Grid item xs={12}>
          <FormLabel>
            <FormattedMessageWrapper
              id="tournament.perPlayerHandicapPercentagesDescription"
              values={{ value: values.length || 4 }}
            />
          </FormLabel>
        </Grid>
        {Array.isArray(values) &&
          values.map((value, index) => (
            <Grid item xs={2} className={classes.grid} key={`input-${index}`}>
              <FormControl className={classes.formControl}>
                <FormLabel>#{index + 1}</FormLabel>
                <TextField
                  type="number"
                  required
                  fullWidth
                  variant={'outlined'}
                  name={`perPlayerHandicapPercentages`}
                  onChange={(e) => this.onChange(e, index)}
                  value={value}
                  error={this.state.errors[gameType] && !!this.state.errors[gameType][index]}
                  disabled={this.props.disabled}
                />
              </FormControl>
            </Grid>
          ))}
      </Grid>
    )
  }

  private onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    fieldIndex?: number,
  ) => {
    const { gameType } = this.props
    const action = this.props.index === 0 ? this.props.updateGlobalGameFormat : this.props.updateGameFormat
    const fieldName = e.currentTarget.name
    const value = e.currentTarget.value

    if (fieldName === 'perPlayerHandicapPercentages' && typeof fieldIndex === 'number') {
      /**
       * Create an error object to be pushed to state. Includes any previous errors.
       */
      const errorObject = (value: boolean) => {
        return {
          [gameType]: { ...this.state.errors[gameType], [fieldIndex]: value },
        }
      }
      /**
       * Validate input value and throw errors according to error state.
       */
      if (Number(value) > 100 || value === '') {
        this.setState({ errors: errorObject(true) })
      } else {
        this.setState({ errors: errorObject(false) })
      }
      const values = this._getIndividualTeamHandicapValues()

      /**
       * Creates a new array where the value in a modified box has changed.
       */
      const newValues = values.map((value, index) => (index === fieldIndex ? e.currentTarget.value : value))

      action({
        index: this.props.index,
        fieldName,
        value: newValues,
      })
    } else if (fieldName === 'handicapPercentage') {
      const cleanedValue = verifyGameHandicapPercentage(value)

      action({
        index: this.props.index,
        fieldName,
        value: cleanedValue,
      })
    } else {
      action({
        index: this.props.index,
        fieldName,
        value,
      })
    }
  }

  private onSelectChange = (event) => {
    const { updateTournamentSettingsField, updateGlobalGameFormat, updateGameFormat, index, tournament } = this.props
    const action = index === 0 ? updateGlobalGameFormat : updateGameFormat
    const { name, value } = event.target

    if (name === 'defaultTeamSize') {
      updateTournamentSettingsField(tournament.id, {
        fieldName: 'defaultTeamSize',
        value: value,
      })
    } else if (name) {
      action({
        index: this.props.index,
        fieldName: name,
        value,
      })
    }
  }

  private onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const action = this.props.index === 0 ? this.props.updateGlobalGameFormat : this.props.updateGameFormat
    const fieldName = e.currentTarget.name
    const value = e.currentTarget.checked

    /**
     * Reset handicap percentage to 100 when "not in use"
     */
    if (fieldName === 'useHandicapPercentage') {
      action({
        index: this.props.index,
        fieldName: 'handicapPercentage',
        value: !value ? 100 : 90,
      })

      if (value) {
        action({
          index: this.props.index,
          fieldName: 'usePerPlayerHandicapPercentages',
          value: false,
        })
      }
    }

    /**
     * Reset handicap percentage to 100 and update player handicap percentage values
     * when per player handicaps are used
     */
    if (fieldName === 'usePerPlayerHandicapPercentages' && value) {
      action({
        index: this.props.index,
        fieldName: 'handicapPercentage',
        value: 100,
      })

      const values = this._getIndividualTeamHandicapValues()
      action({
        index: this.props.index,
        fieldName: 'perPlayerHandicapPercentages',
        value: values,
      })
    }

    action({
      index: this.props.index,
      fieldName,
      value,
    })
  }

  private onGameFormatChange = (gameFormatId: number, gameFormat: GameFormatItem) => {
    const action = this.props.index === 0 ? this.props.updateGlobalHoleConfig : this.props.updateRoundField
    const fieldName = this.props.gameType === GameFormatTypes.PRIMARY ? 'primaryGameId' : 'sideGameId'
    const { defaultHcpPercentage } = gameFormat

    const hcpAction = this.props.index === 0 ? this.props.updateGlobalGameFormat : this.props.updateGameFormat

    const previousGameFormatHasTeams = {
      primary: isTeamFormatId(this.props.round.primaryGameId),
      side: isTeamFormatId(this.props.round.sideGameId),
    }

    const nextGameFormatHasTeams = isTeamFormatId(gameFormatId)

    /**
     * Alert if user is about to change from individual game to team game
     */
    if (
      !previousGameFormatHasTeams.primary &&
      !previousGameFormatHasTeams.side &&
      nextGameFormatHasTeams &&
      this.startListGroups &&
      this.startListGroups.length > 0
    ) {
      this._confirmGroupRemoval(() =>
        this._gameFormatChangeAction(defaultHcpPercentage, gameFormatId, action, hcpAction, fieldName, gameFormat),
      )
      return
    }

    /**
     * Alert if user is about to change from team game to individual game
     */
    if (
      (previousGameFormatHasTeams.primary || previousGameFormatHasTeams.side) &&
      !nextGameFormatHasTeams &&
      this.props.teams &&
      this.props.teams.length > 0
    ) {
      this._confirmTeamRemoval(() =>
        this._gameFormatChangeAction(defaultHcpPercentage, gameFormatId, action, hcpAction, fieldName, gameFormat),
      )
      return
    }

    /**
     * Run if no need for alert
     */
    this._gameFormatChangeAction(defaultHcpPercentage, gameFormatId, action, hcpAction, fieldName, gameFormat)
  }

  private _gameFormatChangeAction = (
    defaultHcpPercentage: number,
    gameFormatId: number,
    action: any,
    hcpAction: any,
    fieldName: string,
    gameFormat: GameFormatItem,
  ) => {
    if (defaultHcpPercentage && this.gameFormatId !== gameFormatId) {
      hcpAction({
        index: this.props.index,
        fieldName: 'handicapPercentage',
        value: defaultHcpPercentage,
      })
    }

    /*
      Special handling for id 14 which is Low Scratch/Net
     */
    if (this.gameFormatId !== gameFormatId && gameFormatId === GameFormats.STROKE_PLAY_LOW_SCRATCH_NET) {
      hcpAction({
        index: this.props.index,
        fieldName: 'useHandicaps',
        value: true,
      })
    }

    /*
      Check validity of sideGameId when primaryGameId is changed
    */
    if (fieldName === 'primaryGameId') {
      const { allowedSideGameFormats = [] } = gameFormat
      const { sideGameId = GameFormats.NO_SIDE } = this.props.round

      // If current sideGameId is not present in the next primaryGame selection's allowedSideGameFormats
      // we reset the side game picker
      if (allowedSideGameFormats.indexOf(sideGameId) === -1) {
        action({
          index: this.props.index,
          fieldName: 'sideGameId',
          value: undefined,
        })
      }
    }

    action({
      index: this.props.index,
      fieldName,
      value: gameFormatId,
    })
  }

  private get gameFormatOptions() {
    if (this.props.gameType === GameFormatTypes.PRIMARY) {
      return this.props.round.primaryGameOptions
    }
    return this.props.round.sideGameOptions
  }

  private get gameFormatId() {
    if (this.props.gameType === GameFormatTypes.PRIMARY) {
      return this.props.round.primaryGameId || 2
    }
    return this.props.round.sideGameId || 0
  }

  private get currentGameFormat() {
    const allFormats = flattenGameFormats(this.props.gameFormats)
    return allFormats.find((gf) => gf.id === this.gameFormatId)
  }

  private get primaryGameFormat() {
    const allFormats = flattenGameFormats(this.props.gameFormats)
    return allFormats.find((gf) => gf.id === this.props.round.primaryGameId)
  }

  private _confirmTeamRemoval = (action: () => void) => {
    confirm({
      message: this.props.intl.formatMessage({ id: 'tournament.removeTeamConfirm' }),
      options: {
        title: this.props.intl.formatMessage({ id: 'tournament.removeTeamConfirmTitle' }),
        cancelText: this.props.intl.formatMessage({ id: 'buttons.cancel' }),
        okText: this.props.intl.formatMessage({ id: 'buttons.ok' }),
      },
    }).then(() => {
      action()
    })
  }

  private _confirmGroupRemoval = (action: () => void) => {
    confirm({
      message: this.props.intl.formatMessage({ id: 'tournament.changeGameFormatGroupWarning' }),
      options: {
        title: this.props.intl.formatMessage({ id: 'tournament.changeGameFormatGroupWarningTitle' }),
        cancelText: this.props.intl.formatMessage({ id: 'buttons.cancel' }),
        okText: this.props.intl.formatMessage({ id: 'buttons.ok' }),
      },
    }).then(() => {
      action()
    })
  }

  public get firstStartListRound() {
    const { rounds } = this.props.tournamentStartLists
    return (rounds && rounds[0]) || false
  }

  public get startListGroups() {
    return (this.firstStartListRound && this.firstStartListRound.groups) || false
  }
}

const GameFormatSelector = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => {
    return {
      gameFormats: state.gameFormatsReducer.gameFormats,
      defaultPerPlayerHandicapPercentages: state.gameFormatsReducer.defaultPerPlayerHandicapPercentages,
      scoringStarted: scoringStartedOnAnyRound(state),
      teams: selectTournamentTeams(state).data?.teams || [],
      tournamentStartLists: selectTournamentStartLists(state),
      tournamentSettings: selectTournamentSettings(state),
      tournament: selectTournament(state),
      selectedRoundIndex: selectTournamentConfig(state).selectedRoundIndex,
    }
  },
  {
    updateTournamentSettingsField,
    updateRoundField,
    updateGlobalHoleConfig,
  },
)(withStyles(styles)(injectIntl(UnconnectedGameFormatSelector as any)))

export default GameFormatSelector
