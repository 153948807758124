import { pickerDateFormats } from '@app/utils/dates'
import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

type WP<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export type ValidationErrorMessages = {
  invalidDate?: string | JSX.Element
  shouldDisableDate?: string | JSX.Element
  disableFuture?: string | JSX.Element
  disablePast?: string | JSX.Element
  minDate?: string | JSX.Element
  maxDate?: string | JSX.Element
}

type extraProps = {
  isDateValid?: (date: Date) => boolean
  units: OrganizationUnits
  style?: React.CSSProperties
  errorMessages?: ValidationErrorMessages
}

export type ControlledDatePickerProps = WP<DatePickerProps<Date>, 'value'> &
  React.RefAttributes<HTMLDivElement> &
  extraProps

export function DatePicker(props: ControlledDatePickerProps) {
  const { isDateValid } = props
  const intl = useIntl()
  const [error, setError] = useState<DateValidationError>(null)

  const ref = useRef<HTMLDivElement>(null)
  const refDate = useRef<Date | undefined | null>() // undefined for not set

  const onPropsAccept = props.onAccept
  const onPropsChange = props.onChange

  const onChange = useCallback(
    (date: Date | null, context: PickerChangeHandlerContext<any>) => {
      onPropsChange && onPropsChange(date, context)

      if (date == null || (isDateValid && isDateValid(date))) {
        refDate.current = date
      } else {
        refDate.current = undefined
      }
    },
    [onPropsChange, isDateValid],
  )

  useEffect(() => {
    const current = ref.current
    const input = current?.querySelectorAll('input').item(0)
    const button = current?.querySelectorAll('button').item(0)

    if (current && onPropsAccept) {
      const onEnter = (event: KeyboardEvent) => {
        if (event.code === 'Enter') {
          if (refDate.current !== undefined) {
            onPropsAccept(refDate.current)
          }
        }
      }
      const onBlur = () => {
        if (refDate.current !== undefined) {
          if (!button?.matches(':hover')) {
            onPropsAccept(refDate.current)
          }
        }
      }

      current.addEventListener('keypress', onEnter)
      input?.addEventListener('blur', onBlur)

      return () => {
        current.removeEventListener('keypress', onEnter)
        input?.removeEventListener('blur', onBlur)
      }
    }

    return undefined
  }, [ref, refDate, onPropsAccept])

  const handleOnError = (error: DateValidationError, value: any) => {
    setError(error)
    props.onError && props.onError(error, value)
  }

  // overwrite onChange to push valid dates
  return (
    <MUIDatePicker
      {...props}
      slotProps={{
        textField: {
          style: props.style,
          helperText:
            error && props.errorMessages
              ? props.errorMessages[error] || intl.formatMessage({ id: 'tournament.invalidDate' })
              : undefined,
        },
      }}
      format={pickerDateFormats[props.units]['date']}
      ref={ref}
      onChange={onChange}
      onAccept={onPropsAccept}
      onError={handleOnError}
    />
  )
}
