import React from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import TournamentTuneDialog, {
  TournamentTuneDialogCloseArgs,
} from '../dialogs/tournamentTuneDialog/TournamentTuneDialog'
import NoteDialog from '../dialogs/noteDialog/NoteDialog'
import { connect } from 'react-redux'
import { removeTournament, toggleCalendarTournament } from '../../store/tournaments/actions'
import CustomTablePagination from '../tables/CustomTablePagination'
import TableFooter from '@mui/material/TableFooter'
import { VeryDenseTableCell } from '../tables/tableComponents'
import { OverlayLoader } from '../ui/OverlayLoader'
import TableSortLabel from '@mui/material/TableSortLabel'
import TournamentCopyDialog from '../dialogs/tournamentCopyDialog/TournamentCopyDialog'
import { DateType } from '../../store/api/enums/tournamentEnums'
import TournamentsTableRow from './TournamentsTableRow'
import { updateTournamentRoundStatus } from '@app/store/api/thunks/tournamentRoundThunks'
import { WithRouterProps, withRouter } from '@app/hoc/withRouter'
import { tournamentApi } from '@app/store/api/endpoints/tournamentApi'
import { RootState } from '@app/store'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

interface OwnProps {
  page?: number
  sortBy?: TournamentSortOption
  direction?: 'asc' | 'desc'
  totalCount?: number
  tournaments: Tournament[]
  showCalendarCheckbox?: boolean
  dateType?: DateType
  onChangePage?(page: number): void
  onRequestSort?(event: any, property: string): void
  triggerFetch(): void
}

interface StateProps {
  tournamentLoading: boolean
  units: OrganizationUnits
}

interface DispatchProps {
  removeTournament(tournamentId: number, triggerFetch: () => void): void
  updateTournamentRoundStatus(payload: UpdateRoundStatusPayload): void
  toggleCalendarTournament(id: number, showInCalendar: boolean): void
  copyTournament(args: CopyTournamentPayload): void
}

type Props = OwnProps & StateProps & DispatchProps & WithRouterProps

interface State {
  tuneDialogOpen: boolean
  copyDialogOpen: boolean
  noteDialogOpen: boolean
  tuneDialogTournament?: Tournament
  targetTournament?: Tournament
}

class UnconnectedTournamentsTable extends React.Component<Props, State> {
  readonly state: State = {
    tuneDialogOpen: false,
    copyDialogOpen: false,
    noteDialogOpen: false,
    tuneDialogTournament: undefined,
    targetTournament: undefined,
  }

  render() {
    const { sortBy = 'date', direction = 'desc', showCalendarCheckbox = true } = this.props

    return (
      <>
        <OverlayLoader visible={this.props.tournamentLoading} />

        <Table>
          <TableHead>
            <TableRow>
              {showCalendarCheckbox && <VeryDenseTableCell style={{ width: 40 }} />}
              <VeryDenseTableCell style={{ width: 40 }} sortDirection={sortBy === 'date' ? direction : false}>
                {this._wrapWithSorting('date', <FormattedMessageWrapper id="tournaments.date" />)}
              </VeryDenseTableCell>
              <VeryDenseTableCell style={{ width: 40 }} sortDirection={sortBy === 'year' ? direction : false}>
                {this._wrapWithSorting('year', <FormattedMessageWrapper id="tournaments.year" />)}
              </VeryDenseTableCell>
              <TableCell size={'small'} sortDirection={sortBy === 'name' ? direction : false}>
                {this._wrapWithSorting('name', <FormattedMessageWrapper id="tournaments.name" />)}
              </TableCell>
              <TableCell style={{ width: 40 }} />
              <TableCell size={'small'} sortDirection={sortBy === 'course' ? direction : false}>
                {this._wrapWithSorting('course', <FormattedMessageWrapper id="tournaments.course" />)}
              </TableCell>
              <TableCell size={'small'} sortDirection={sortBy === 'createdBy' ? direction : false}>
                {this._wrapWithSorting('createdBy', <FormattedMessageWrapper id="tournaments.createdBy" />)}
              </TableCell>
              <TableCell size={'small'} sortDirection={sortBy === 'status' ? direction : false}>
                {this._wrapWithSorting('status', <FormattedMessageWrapper id="tournaments.status" />)}
              </TableCell>
              <TableCell size={'small'}>
                <FormattedMessageWrapper id="tournaments.joinSlashViewCode" />
              </TableCell>
              <VeryDenseTableCell style={{ width: 24 }} />
              <VeryDenseTableCell style={{ width: 24 }}>www</VeryDenseTableCell>
              <VeryDenseTableCell style={{ width: 24 }} />
            </TableRow>
          </TableHead>
          <TableBody>{this._renderTournamentRows()}</TableBody>
          <TableFooter>
            <TableRow>
              <CustomTablePagination
                count={this.props.totalCount}
                page={this.props.page ? this.props.page : 0}
                onChangePage={(event, page) => {
                  if (event !== null && this.props.onChangePage) {
                    this.props.onChangePage(page)
                  }
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <TournamentTuneDialog
          open={this.state.tuneDialogOpen}
          tournament={this.state.tuneDialogTournament}
          onClose={this._handleTuneDialogClose}
        />

        <NoteDialog
          tournament={this.state.targetTournament}
          open={this.state.noteDialogOpen}
          onClose={this.handleNoteDialogClose}
        />

        <TournamentCopyDialog
          tournament={this.state.targetTournament}
          open={this.state.copyDialogOpen}
          onClose={() => {
            this.setState({
              copyDialogOpen: false,
              targetTournament: undefined,
            })
            this.props.triggerFetch()
          }}
        />
      </>
    )
  }

  private handleNoteDialogOpen = (tournament: Tournament) => {
    this.setState({
      noteDialogOpen: true,
      targetTournament: tournament,
    })
  }

  private handleNoteDialogClose = (doFetch?: boolean) => {
    this.setState({
      noteDialogOpen: false,
      targetTournament: undefined,
    })
    doFetch && this.props.triggerFetch()
  }

  private _wrapWithSorting = (sortByKey: string, content: any) => {
    const { sortBy = 'date', direction = 'desc' } = this.props

    return (
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortBy === sortByKey ? direction : 'desc'}
        onClick={this._createSortHandler(sortByKey)}
      >
        {content}
      </TableSortLabel>
    )
  }

  private _renderTournamentRows() {
    return this.props.tournaments.map((tournament) => (
      <TournamentsTableRow
        key={tournament.id}
        tournament={tournament}
        units={this.props.units}
        viewCode={this._getViewCode(tournament)}
        joinCode={this._getJoinCode(tournament)}
        toggleCalendar={this._toggleCalendar}
        getTournamentStatus={this._getTournamentStatus}
        tuneTournament={this._tuneTournament}
        openNoteDialog={this.handleNoteDialogOpen}
        showCalendarCheckbox={this.props.showCalendarCheckbox}
      />
    ))
  }

  private _getViewCode = (tournament: Tournament) => {
    return tournament.viewCode || '-'
  }

  private _getJoinCode = (tournament: Tournament) => {
    return tournament.joinCode || '-'
  }

  private _toggleCalendar = (tournamentId: number, checked: boolean) => {
    this.props.toggleCalendarTournament(tournamentId, checked)
  }

  private _getTournamentStatus = (): string => {
    return ''
  }

  private _tuneTournament = (tournament: Tournament) => {
    this.setState({
      tuneDialogOpen: true,
      tuneDialogTournament: tournament,
    })
  }

  private _handleTuneDialogClose = (args?: TournamentTuneDialogCloseArgs) => {
    const closedState = {
      tuneDialogOpen: false,
      tuneDialogTournament: undefined,
    }

    if (args) {
      this.setState(closedState, () => {
        const { action, tournament } = args

        switch (action) {
          case 'remove':
            this.props.removeTournament(tournament.id, this.props.triggerFetch)
            break
          case 'endScoring':
            this.props.updateTournamentRoundStatus({
              tournamentId: tournament.id,
              roundId: tournament.rounds[0].id,
              body: { isScoringDisabled: true },
            })
            break
          case 'copy':
            this.setState({
              copyDialogOpen: true,
              targetTournament: tournament,
            })
            break
          case 'note':
            this.setState({
              noteDialogOpen: true,
              targetTournament: tournament,
            })
            break
          default:
        }
      })
    } else {
      this.setState(closedState)
    }
  }

  public _createSortHandler = (property: string) => (event) => {
    if (this.props.onRequestSort) {
      this.props.onRequestSort(event, property)
    }
  }
}

const TournamentsTable = withRouter(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (store): StateProps => ({
      tournamentLoading: selectTournamentConfig(store).status.isTournamentLoading,
      units: store.authenticationReducer.units,
    }),
    {
      removeTournament,
      updateTournamentRoundStatus,
      toggleCalendarTournament,
      copyTournament: tournamentApi.endpoints.copyTournament.initiate,
    },
  )(UnconnectedTournamentsTable),
)
export default TournamentsTable
