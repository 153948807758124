import React, { SyntheticEvent, useState } from 'react'
import { Dialog, DialogActions, DialogContent, Grid, TextField, Switch, FormControlLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import ContentWrap from '@app/components/layout/ContentWrap'
import { HelpFormLabel } from '@app/components/forms/HelpFormLabel'
import SaveButton from '@app/components/ui/SaveButton'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import DeleteButton from '@app/components/ui/DeleteButton'
import DeleteConfirm, { DeleteConfirmChildren } from '../deleteConfirm/DeleteConfirm'

const useStyles = makeStyles(() => ({
  label: {
    margin: '0 0 5px 10px',
  },
  actionContainer: {
    justifyContent: 'flex-start',
    margin: '0 40px',
  },
  winner: {
    marginLeft: '15px',
  },
}))

interface Props {
  close: () => void
  player: MatchPlayBracketPlayer
  editPlayer: (player: MatchPlayBracketPlayer, deletePlayer?: boolean) => void
}

export const MatchPlayBracketPlayerDialog = ({ close, player, editPlayer }: Props): JSX.Element => {
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [currentPlayer, setCurrentPlayer] = useState<MatchPlayBracketPlayer>(player)
  const classes = useStyles()

  const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    updatePlayer(name, value)
  }

  const updatePlayer = (fieldName: string, value: string | boolean): void => {
    const updatedPlayer: MatchPlayBracketPlayer = { ...currentPlayer, [fieldName]: value }
    setCurrentPlayer(updatedPlayer)
  }

  const submit = (): void => {
    setIsSaving(true)
    setTimeout(() => {
      setIsSaving(false)
      editPlayer(currentPlayer)
      close()
    }, 300)
  }

  const deletePlayer = (): void => {
    setIsDeleting(true)
    setTimeout(() => {
      setIsDeleting(false)
      editPlayer(currentPlayer, true)
      close()
    }, 300)
  }

  return (
    <Dialog open onClose={close} fullWidth maxWidth="md" keepMounted={false} data-testid="playerDialog">
      <BaseDialogTitle
        id="form-dialog-title"
        title={<FormattedMessageWrapper id={'matchPlayBracket.editPlayer'} />}
        onClose={close}
      />

      <DialogContent>
        <ContentWrap>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <HelpFormLabel className={classes.label}>
                <FormattedMessageWrapper id="matchPlayBracket.name" />
              </HelpFormLabel>
              <TextField
                name="name"
                type="text"
                id="name"
                variant="outlined"
                onChange={updateField}
                value={currentPlayer.name}
                fullWidth
                inputProps={{ 'data-testid': 'name' }}
              />
            </Grid>
            <Grid item xs={3}>
              <HelpFormLabel className={classes.label}>
                <FormattedMessageWrapper id="matchPlayBracket.hcp" />
              </HelpFormLabel>
              <TextField
                name="hcp"
                type="text"
                id="name"
                variant="outlined"
                onChange={updateField}
                value={currentPlayer.hcp}
                fullWidth
                inputProps={{ 'data-testid': 'hcp' }}
              />
            </Grid>
            <Grid item xs={3}>
              <HelpFormLabel className={classes.label}>
                <FormattedMessageWrapper id="matchPlayBracket.score" />
              </HelpFormLabel>
              <TextField
                name="score"
                type="text"
                id="name"
                variant="outlined"
                onChange={updateField}
                value={currentPlayer.score}
                fullWidth
                inputProps={{ 'data-testid': 'score' }}
              />
            </Grid>
            {currentPlayer.stage !== MatchPlayBracketStages.POOL && (
              <Grid item xs={6} className={classes.winner}>
                <FormControlLabel
                  name="winner"
                  checked={currentPlayer.winner}
                  control={<Switch />}
                  label={<FormattedMessageWrapper id="matchPlayBracket.winner" />}
                  onChange={(e: SyntheticEvent, checked: boolean) => updatePlayer('winner', checked)}
                  data-testid="winner"
                />
              </Grid>
            )}
          </Grid>
        </ContentWrap>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <SaveButton disabled={isSaving} isSaving={isSaving} onClick={submit}>
          <FormattedMessageWrapper id={'buttons.save'} />
        </SaveButton>
        <DeleteConfirm>
          {({ showConfirm }: DeleteConfirmChildren) => (
            <DeleteButton
              disabled={isDeleting}
              isDeleting={isDeleting}
              onClick={() => {
                showConfirm({
                  messageId: 'matchPlayBracket.deleteConfirm',
                  values: { name: player.name },
                  callback: () => {
                    deletePlayer()
                  },
                })
              }}
            />
          )}
        </DeleteConfirm>
      </DialogActions>
    </Dialog>
  )
}
