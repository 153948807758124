import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import TournamentFlowProgress from '../../components/tournament/TournamentFlowProgress'
import { fetchOrganization } from '../../store/organization/actions'
import TopBar from '../../components/layout/TopBar'
import { fetchTours, fetchRankings } from '../../store/tourAndRanking/actions'
import { PageWrapper } from '@app/components/layout/PageWrapper'
import { fetchDivisions } from '@app/store/divisions/actions'
import { useLazyGetTournamentQuery } from '@app/store/api/endpoints/tournamentApi'
import { resetTournament } from '@app/store/api/thunks/tournamentThunks'
import { setSelectedRound } from '@app/store/api/thunks/configThunks'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@app/store'
import { useLazyGetTournamentSettingsQuery } from '@app/store/api/endpoints/tournamentSettingsApi'
import { useLazyGetTournamentSiteQuery } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import CourseVersionHandler from './CourseVersionHandler'

const TournamentIndex: React.FC = () => {
  const { id } = useParams()
  const tournamentId = Number(id)
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const tournament = useSelector(selectTournament)
  const { selectedRoundId } = useSelector(selectTournamentConfig)
  const { loading: isRankingLoading } = useSelector((state: RootState) => state.tourAndRankingReducer)
  const { loading: isGameFormatsLoading } = useSelector((state: RootState) => state.gameFormatsReducer)
  const { loading: isPlayersLoading } = useSelector((state: RootState) => state.playersReducer)
  const {
    isTournamentLoading,
    isTournamentSiteLoading,
    isTournamentStartListsLoading,
    isTournamentPlayersLoading,
    isTournamentSettingsLoading,
    isTournamentScoringLoading,
  } = useSelector(selectTournamentConfig).status
  const { customerInfo } = useSelector((store: RootState) => store.authenticationReducer)
  const { idCustomer: organizationId } = customerInfo ? customerInfo : { idCustomer: 0 }

  const [getTournament] = useLazyGetTournamentQuery()
  const [getTournamentSettings] = useLazyGetTournamentSettingsQuery()
  const [getTournamentSite] = useLazyGetTournamentSiteQuery()

  useEffect(() => {
    if (!id) {
      dispatch(resetTournament())
    }
    return () => {
      dispatch(resetTournament())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (tournamentId > 0) {
      const preferCacheValue = true
      getTournament({ id: tournamentId }, preferCacheValue)
      getTournamentSettings(tournamentId, preferCacheValue)
      getTournamentSite(tournamentId, preferCacheValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedRoundId === -1 && tournament.id > 0) {
      const rounds = tournament.rounds
      dispatch(setSelectedRound(rounds[0].id))
    }
  }, [tournament.id])

  useEffect(() => {
    if (organizationId > 0) {
      dispatch(fetchOrganization(organizationId))
      dispatch(fetchTours(organizationId))
      dispatch(fetchRankings(organizationId))
      dispatch(fetchDivisions(organizationId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  if (tournament.id > 0 && pathname.includes('create')) {
    const newPath = pathname.replace('create', tournament.id.toString())
    return <Navigate to={newPath} />
  }

  const isLoading = (): boolean => {
    return (
      isTournamentLoading ||
      isTournamentSettingsLoading ||
      isTournamentSiteLoading ||
      isRankingLoading ||
      isGameFormatsLoading ||
      isPlayersLoading ||
      isTournamentStartListsLoading ||
      isTournamentPlayersLoading ||
      isTournamentScoringLoading
    )
  }

  return (
    <>
      <OverlayLoader visible={isLoading()} />
      <TopBar title={tournament?.name} />
      <TournamentFlowProgress />
      <PageWrapper>
        {tournament.id > 0 && <CourseVersionHandler />}
        <Outlet />
      </PageWrapper>
    </>
  )
}

export default TournamentIndex
