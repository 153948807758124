export enum MatchPlayBracketStages {
  POOL = 'POOL',
  ROUND_1 = 'ROUND_1',
  ROUND_2 = 'ROUND_2',
  ROUND_3 = 'ROUND_3',
  ROUND_4 = 'ROUND_4',
  ROUND_5 = 'ROUND_5',
  QUARTER_FINAL = 'QUARTER_FINAL',
  SEMI_FINAL = 'SEMI_FINAL',
  BRONZE_MATCH = 'BRONZE_MATCH',
  FINAL = 'FINAL',
}

export enum MatchPlayBracket_8_Players {
  QUARTER_FINAL = MatchPlayBracketStages.QUARTER_FINAL,
  SEMI_FINAL = MatchPlayBracketStages.SEMI_FINAL,
  FINAL = MatchPlayBracketStages.FINAL,
}

export enum MatchPlayBracket_16_Players {
  ROUND_1 = MatchPlayBracketStages.ROUND_1,
  QUARTER_FINAL = MatchPlayBracketStages.QUARTER_FINAL,
  SEMI_FINAL = MatchPlayBracketStages.SEMI_FINAL,
  FINAL = MatchPlayBracketStages.FINAL,
}

export enum MatchPlayBracket_32_Players {
  ROUND_1 = MatchPlayBracketStages.ROUND_1,
  ROUND_2 = MatchPlayBracketStages.ROUND_2,
  QUARTER_FINAL = MatchPlayBracketStages.QUARTER_FINAL,
  SEMI_FINAL = MatchPlayBracketStages.SEMI_FINAL,
  FINAL = MatchPlayBracketStages.FINAL,
}

export enum MatchPlayBracket_64_Players {
  ROUND_1 = MatchPlayBracketStages.ROUND_1,
  ROUND_2 = MatchPlayBracketStages.ROUND_2,
  ROUND_3 = MatchPlayBracketStages.ROUND_3,
  QUARTER_FINAL = MatchPlayBracketStages.QUARTER_FINAL,
  SEMI_FINAL = MatchPlayBracketStages.SEMI_FINAL,
  FINAL = MatchPlayBracketStages.FINAL,
}

export enum MatchPlayBracket_128_Players {
  ROUND_1 = MatchPlayBracketStages.ROUND_1,
  ROUND_2 = MatchPlayBracketStages.ROUND_2,
  ROUND_3 = MatchPlayBracketStages.ROUND_3,
  ROUND_4 = MatchPlayBracketStages.ROUND_4,
  QUARTER_FINAL = MatchPlayBracketStages.QUARTER_FINAL,
  SEMI_FINAL = MatchPlayBracketStages.SEMI_FINAL,
  FINAL = MatchPlayBracketStages.FINAL,
}

export enum MatchPlayBracket_256_Players {
  ROUND_1 = MatchPlayBracketStages.ROUND_1,
  ROUND_2 = MatchPlayBracketStages.ROUND_2,
  ROUND_3 = MatchPlayBracketStages.ROUND_3,
  ROUND_4 = MatchPlayBracketStages.ROUND_4,
  ROUND_5 = MatchPlayBracketStages.ROUND_5,
  QUARTER_FINAL = MatchPlayBracketStages.QUARTER_FINAL,
  SEMI_FINAL = MatchPlayBracketStages.SEMI_FINAL,
  FINAL = MatchPlayBracketStages.FINAL,
}
