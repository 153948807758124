import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'
import classNames from 'classnames'
import { Theme } from '@mui/material'

export type PlainCardProps = React.HtmlHTMLAttributes<HTMLDivElement>

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: rem(200),
    color: theme.palette.common.white,
    textAlign: 'center',
    boxShadow: theme.shadows[5],
  },
}))

export const PlainCard: React.FC<PlainCardProps> = ({ children, className, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classNames([classes.root, className])} {...rest}>
      {children}
    </div>
  )
}
