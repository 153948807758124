import { SyntheticEvent, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  CircularProgress,
  Switch,
  FormControlLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import BaseDialogTitle from '@components/dialogs/ui/BaseDialogTitle'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCondensedTournaments } from '@app/store/tournaments/actions'
import { Select } from '@app/components/forms/Select'
import SaveButton from '@app/components/ui/SaveButton'
import { generateRandomString } from '@app/utils/stringUtilts'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { convertHcpToString } from '@app/utils/hcpUtils'
import { addMonths, subMonths } from 'date-fns'
import { enqueueNotification } from '@app/store/notifications/actions'
import { useLazyGetPlayersQuery } from '@app/store/api/endpoints/tournamentPlayersApi'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tournamentSelect: {
    minWidth: '250px',
  },
  importHandicaps: {
    margin: '15px 0 0 15px',
  },
  loadingInfoWrapper: {
    marginTop: '20px',
  },
  actionContainer: {
    justifyContent: 'flex-start',
  },
}))

interface Props {
  players: MatchPlayBracketPlayer[]
  updatePlayers: (players: MatchPlayBracketPlayer[]) => void
  close: () => void
}

export const MatchPlayBracketImport = ({ players, updatePlayers, close }: Props) => {
  const [selectedTournamentId, setSelectedTournamentId] = useState<number>(0)
  const [importHandicaps, setImportHandicaps] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [tournamentsFetchStarted, setTournamentsFetchStarted] = useState<boolean>(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const classes = useStyles()
  const [getTournamentPlayers, { isLoading: loadingPlayers, data: playersResponse }] = useLazyGetPlayersQuery()
  const { players: tournamentPlayers } = playersResponse || { players: [] }

  const { allTournaments: filteredTournaments, loading: loadingTournaments } = useSelector(
    (store: RootState) => store.tournamentsReducer,
  )

  const { customerInfo } = useSelector((store: RootState) => store.authenticationReducer)
  const { idCustomer: organizationId } = customerInfo ? customerInfo : { idCustomer: 0 }

  useEffect(() => {
    const today = new Date()
    const startDate = subMonths(today, 2)
    const endDate = addMonths(today, 2)
    dispatch(fetchCondensedTournaments({ id: organizationId, startDate, endDate }))
    setTournamentsFetchStarted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loadingTournaments && filteredTournaments.length === 0 && tournamentsFetchStarted) {
      dispatch(
        enqueueNotification(intl.formatMessage({ id: 'matchPlayBracket.noTournamentsWithinSearchLimit' }), 'error'),
      )
      close()
    }
  }, [filteredTournaments, loadingTournaments, intl, dispatch, close, tournamentsFetchStarted])

  useEffect(() => {
    if (selectedTournamentId) {
      const preferCacheValue = true
      getTournamentPlayers(selectedTournamentId, preferCacheValue)
    }
  }, [selectedTournamentId, dispatch])

  const submit = (): void => {
    const originalPlayers = [...players]
    const newPlayers: MatchPlayBracketPlayer[] = tournamentPlayers.map((player: TournamentPlayer) => {
      const name = `${player.firstName} ${player.lastName}`
      const hcp = importHandicaps ? convertHcpToString(player.hcp) : ''
      return {
        id: null,
        name: name,
        playerBracketId: generateRandomString(),
        hcp: hcp,
        score: '',
        winner: false,
        stage: MatchPlayBracketStages.POOL,
        order: 0,
      }
    })
    originalPlayers.unshift(...newPlayers)
    setIsSaving(true)
    setTimeout(() => {
      setIsSaving(false)
      updatePlayers(originalPlayers)
      close()
    }, 300)
  }

  return (
    <Dialog open onClose={close} fullWidth maxWidth="md" keepMounted={false}>
      <BaseDialogTitle
        id="form-dialog-title"
        title={<FormattedMessageWrapper id={'matchPlayBracket.importPlayers'} />}
        onClose={close}
      />

      <DialogContent>
        <div className={classes.loadingContainer}>{loadingTournaments && <CircularProgress size={64} />}</div>

        {!loadingTournaments && filteredTournaments.length > 0 && (
          <>
            <Select
              className={classes.tournamentSelect}
              onChange={(e: any) => setSelectedTournamentId(e.target.value as number)}
              value={selectedTournamentId}
            >
              <MenuItem value={0}>{intl.formatMessage({ id: 'matchPlayBracket.selectTournament' })}</MenuItem>
              {filteredTournaments.map((tournament: TournamentCondensed) => (
                <MenuItem value={tournament.id} key={tournament.id}>
                  {tournament.name}
                </MenuItem>
              ))}
            </Select>

            <div className={classes.importHandicaps}>
              <FormControlLabel
                name="importHandicaps"
                checked={importHandicaps}
                control={<Switch />}
                label={<FormattedMessageWrapper id="matchPlayBracket.importHandicaps" />}
                onChange={(e: SyntheticEvent, checked: boolean) => setImportHandicaps(checked)}
              />
            </div>
          </>
        )}

        {loadingPlayers && (
          <div className={classes.loadingInfoWrapper}>
            <CircularProgress size={32} />
          </div>
        )}

        {!loadingPlayers && !loadingTournaments && tournamentPlayers.length > 0 && (
          <div className={classes.loadingInfoWrapper}>
            {tournamentPlayers.length} <FormattedMessageWrapper id={'matchPlayBracket.playersToImport'} />
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <SaveButton
          disabled={tournamentPlayers.length === 0 || loadingPlayers || loadingTournaments}
          isSaving={isSaving}
          onClick={submit}
        >
          <FormattedMessageWrapper id={'buttons.import'} />
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}
