export const sortByDateTime = (a: string, b: string, direction: SortDirection = 'asc'): number => {
  const first = direction === 'asc' ? a : b
  const second = direction === 'asc' ? b : a
  return Date.parse(first) > Date.parse(second) ? 1 : -1
}

export const sortByOrderKey = (a: number, b: number, direction: SortDirection = 'asc'): number => {
  const first = direction === 'asc' ? a : b
  const second = direction === 'asc' ? b : a
  return first > second ? 1 : -1
}
