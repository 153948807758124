//LIBRARIES
import {
  FormLabel,
  Select,
  MenuItem,
  TextField,
  Switch,
  Grid,
  OutlinedInput,
  FormControl,
  IconButton,
  SelectChangeEvent,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Add, Remove } from '@mui/icons-material'

//COMPONENTS
import ContentWrap from '@components/layout/ContentWrap'
import SectionTitle from '@components/ui/SectionTitle'

//UTILS
import { Placeholder } from '../utils'
import { Prefix } from '.'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { useSelector } from 'react-redux'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentSettings } from '@app/store/api/endpoints/tournamentSettingsApi'
import { verifyIfRoundIsCompleted } from '@app/utils/tournamentUtils'

const useStyles = makeStyles(() => ({
  controlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& > button': {
      padding: '2px',
    },
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  smallBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
}))

export interface BasicDetailsProps {
  onSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (event: SelectChangeEvent<any>) => void
  onSwitchSettingsChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onInputSettingsChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSelectSettingsChange: (event: SelectChangeEvent<any>) => void
  onWeeklyRoundChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  changeWeeklyRoundsValue: (prefix: Prefix) => void
  isHCPVisible: boolean
  tours: Tour[]
}

const BasicDetailsComponent: React.FC<BasicDetailsProps & WrappedComponentProps> = ({
  onSwitchChange,
  onInputChange,
  onSelectChange,
  onSwitchSettingsChange,
  onInputSettingsChange,
  onSelectSettingsChange,
  onWeeklyRoundChange,
  changeWeeklyRoundsValue,
  isHCPVisible,
  tours,
  intl,
}) => {
  const classes = useStyles()
  const tournament = useSelector(selectTournament)
  const tournamentSettings = useSelector(selectTournamentSettings)

  const renderWeeklyRoundsValue = () => {
    const value = tournament.weeklyRounds
    if (typeof value === 'undefined') {
      return 1
    }
    if (value === null || value < 0) {
      return intl.formatMessage({ id: 'strings.unlimited' })
    }
    return value
  }

  const handleOnChangePlayersThroughCut = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    const validNumber = /^\d+$/

    if (value.match(validNumber) || value === '') {
      onInputSettingsChange(e)
    }
  }

  const cutCount: 'X' | number = (tournamentSettings.cutEnabled && tournamentSettings.roundsBeforeCut) || 'X'

  const cutRoundsCompleted = (): boolean => {
    const { roundsBeforeCut } = tournamentSettings
    const { rounds } = tournament

    if (!roundsBeforeCut) {
      return false
    }

    if (roundsBeforeCut > rounds.length) {
      return false
    }

    /**
     * Check if all rounds from 0 up to the round indicated in roundsBeforeCut are ended
     */
    const isAllCutRoundsEnded: (boolean | undefined)[] = Array.from({ length: roundsBeforeCut }).map((_, idx) =>
      verifyIfRoundIsCompleted(idx, rounds),
    )

    return !isAllCutRoundsEnded.some((bool) => bool === false)
  }

  return (
    <ContentWrap>
      <Grid container spacing={2}>
        {/*
         * Title
         */}
        <Grid item xs={12}>
          <SectionTitle>
            <FormattedMessageWrapper id="tournament.basicDetails" />
          </SectionTitle>
          <FormattedMessageWrapper id="tournament.tournamentTypeDescription" />
        </Grid>

        {/*
         * Tournament name
         */}
        <Grid item xs={6}>
          <FormLabel>
            <FormattedMessageWrapper id="tournament.tournamentName" />
          </FormLabel>
          <TextField
            name="name"
            type="text"
            id="name"
            variant="outlined"
            onChange={onInputChange}
            value={tournament.name}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} className={classes.switchContainer}>
          {isHCPVisible && (
            <FormControl className="SwitchControl">
              <Switch name="hcpRound" color="secondary" checked={tournament.hcpRound} onChange={onSwitchChange} />
              <FormLabel className="SwitchLabel">
                <FormattedMessageWrapper id="tournament.tournamentTypeHCPTournament" />
              </FormLabel>
            </FormControl>
          )}
        </Grid>

        {/*
         * Tour field
         */}
        <Grid item xs={6}>
          <FormLabel>
            <FormattedMessageWrapper id="tournament.tour" />
          </FormLabel>
          <Select
            variant="filled"
            name="tour"
            id="tour"
            fullWidth
            value={tournament.tour || ''}
            displayEmpty
            input={<OutlinedInput name="tour" id="tour" fullWidth />}
            onChange={onSelectChange}
            renderValue={
              Number.isInteger(tournament.tour)
                ? undefined
                : () => (
                    <Placeholder>
                      <FormattedMessageWrapper id="tournament.none" />
                    </Placeholder>
                  )
            }
          >
            <MenuItem value="" style={{ fontStyle: 'italic' }}>
              <FormattedMessageWrapper id="tournament.none" />
            </MenuItem>
            {tours.map((tour) => (
              <MenuItem key={tour.id} value={tour.id}>
                {tour.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/*
         * Weekly number of rounds field
         */}
        {tournament.tournamentType === TournamentTypes.weekly && (
          <>
            <Grid item xs={2} className={classes.smallBoxContainer}>
              <FormLabel>
                <FormattedMessageWrapper id="tournament.numberOfRounds" />
              </FormLabel>
              <TextField
                name="weeklyRounds"
                id="weeklyRounds"
                type="text"
                variant="outlined"
                onChange={onWeeklyRoundChange}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.currentTarget.select()}
                value={renderWeeklyRoundsValue()}
              />
            </Grid>
            <Grid item xs={4} className={classes.controlsContainer}>
              <IconButton onClick={() => changeWeeklyRoundsValue(Prefix.ADD)} size="large">
                <Add fontSize="small" />
              </IconButton>
              <IconButton onClick={() => changeWeeklyRoundsValue(Prefix.SUBSTRACT)} size="large">
                <Remove fontSize="small" />
              </IconButton>
            </Grid>
          </>
        )}

        {/*
         * Cut switch
         */}
        <Grid item xs={6} className={classes.switchContainer}>
          {tournament.tournamentType === TournamentTypes.multiRound && (
            <FormControl className="SwitchControl">
              <Switch
                disabled={cutRoundsCompleted()}
                name="cutEnabled"
                color="primary"
                checked={tournamentSettings.cutEnabled}
                onChange={onSwitchSettingsChange}
              />
              <FormLabel className="SwitchLabel">
                <FormattedMessageWrapper id="tournament.tournamentUseCut" values={{ count: cutCount }} />
              </FormLabel>
            </FormControl>
          )}
        </Grid>
        {tournamentSettings.cutEnabled && tournament.tournamentType === TournamentTypes.multiRound && (
          <>
            <Grid item xs={6} />
            {/*
             * Rounds before cut
             */}
            <Grid item xs={2} className={classes.smallBoxContainer}>
              <FormLabel>
                <FormattedMessageWrapper id="tournament.roundsBeforeCut" />
              </FormLabel>
              <Select
                disabled={cutRoundsCompleted()}
                variant="filled"
                name="roundsBeforeCut"
                id="roundsBeforeCut"
                fullWidth
                value={tournamentSettings.roundsBeforeCut}
                input={<OutlinedInput style={{ width: '50%' }} name="roundsBeforeCut" id="roundsBeforeCut" />}
                onChange={onSelectSettingsChange}
              >
                {Array.from({ length: 8 }).map((_, index: number) => {
                  const roundNumber = index + 1
                  return (
                    <MenuItem key={`round_${roundNumber}`} value={roundNumber}>
                      {roundNumber}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            {/*
             * Players through cut
             */}
            <Grid item xs={2} className={classes.smallBoxContainer}>
              <FormLabel>
                <FormattedMessageWrapper id="tournament.playersThroughCut" />
              </FormLabel>
              <TextField
                style={{ width: '50%' }}
                variant="outlined"
                name="playersThroughCut"
                id="playersThroughCut"
                fullWidth
                value={tournamentSettings.playersThroughCut}
                onChange={handleOnChangePlayersThroughCut}
              ></TextField>
            </Grid>
          </>
        )}
      </Grid>
    </ContentWrap>
  )
}

export const BasicDetails = injectIntl(BasicDetailsComponent)
