import React from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import Typography from '@mui/material/Typography'
import { DashboardGGBApp, GGBAppLogo, DashboardTournamentSite, DashboardRanking } from '../../assets/images'
import { rem } from '../../theme/materialUITheme'

const styles = (theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: theme.customPalette.background,
      display: 'flex',
      flexDirection: 'row',
      boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.3)',
      height: '100%',
      alignItems: 'flex-end',
      maxHeight: 240,
      position: 'relative',
    },
    contentWrapper: {
      maxHeight: 240,
      display: 'flex',
      maxWidth: '100%',
      transition: '0.2s opacity',
    },
    textWrapper: {
      padding: 20,
      paddingRight: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    title: {
      fontWeight: 600,
      fontSize: rem(18),
      lineHeight: rem(20),
      marginBottom: 10,
    },
    text: {
      fontSize: rem(14),
      lineHeight: rem(16),
    },
    imgWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignSelf: 'flex-end',
      paddingTop: 10,
      height: '100%',
    },
    img: {
      maxWidth: '100%',
      maxHeight: 210,
      display: 'block',
      width: 'auto',
      height: 'auto',
    },
    logo: {
      width: 50,
      marginBottom: 10,
    },
  })

interface State {
  showLongText?: string
}

class ManagerFeaturesLift extends React.PureComponent<WithStyles<typeof styles>, State> {
  constructor(props) {
    super(props)

    this.state = {
      showLongText: undefined,
    }
  }

  render() {
    const { classes } = this.props
    const { showLongText } = this.state

    return (
      <>
        <Typography variant="h2" style={{ marginBottom: 15 }}>
          <FormattedMessageWrapper id="dashboard.servicesTitle" />
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <div
              className={classes.box}
              style={{ cursor: 'pointer' }}
              onClick={() => (window.location.href = 'https://golfgamebook.com/app/')}
              onMouseEnter={() => this.setState({ showLongText: 'app' })}
              onMouseLeave={() => this.setState({ showLongText: undefined })}
            >
              <div
                className={classes.contentWrapper}
                style={
                  showLongText === 'app' ? { opacity: 1, position: 'absolute' } : { opacity: 0, position: 'absolute' }
                }
              >
                <div className={classes.textWrapper}>
                  <div className={classes.title}>
                    <FormattedMessageWrapper id={'dashboard.mobileApp'} />
                  </div>

                  <Typography variant="body1" className={classes.text}>
                    <FormattedMessageWrapper id={'dashboard.mobileAppLongText'} />
                  </Typography>
                </div>
              </div>
              <div className={classes.contentWrapper} style={showLongText === 'app' ? { opacity: 0 } : { opacity: 1 }}>
                <div className={classes.textWrapper}>
                  <img className={classes.logo} src={GGBAppLogo} alt="" />

                  <div className={classes.title}>
                    <FormattedMessageWrapper id={'dashboard.mobileApp'} />
                  </div>

                  <Typography variant="body1" className={classes.text}>
                    <FormattedMessageWrapper id={'dashboard.mobileAppShortText'} />
                  </Typography>
                </div>
                <div className={classes.imgWrapper} style={{ width: 470, alignItems: 'flex-end' }}>
                  <img className={classes.img} src={DashboardGGBApp} alt="" />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={classes.box}
              onMouseEnter={() => this.setState({ showLongText: 'site' })}
              onMouseLeave={() => this.setState({ showLongText: undefined })}
            >
              <div
                className={classes.contentWrapper}
                style={
                  showLongText === 'site' ? { opacity: 1, position: 'absolute' } : { opacity: 0, position: 'absolute' }
                }
              >
                <div className={classes.textWrapper}>
                  <div className={classes.title}>
                    <FormattedMessageWrapper id={'dashboard.tournamentSite'} />
                  </div>

                  <Typography variant="body1" className={classes.text}>
                    <FormattedMessageWrapper id={'dashboard.tournamentSiteLongText'} />
                  </Typography>
                </div>
              </div>
              <div className={classes.contentWrapper} style={showLongText === 'site' ? { opacity: 0 } : { opacity: 1 }}>
                <div className={classes.textWrapper}>
                  <div className={classes.title}>
                    <FormattedMessageWrapper id={'dashboard.tournamentSite'} />
                  </div>

                  <Typography variant="body1" className={classes.text}>
                    <FormattedMessageWrapper id={'dashboard.tournamentSiteShortText'} />
                  </Typography>
                </div>
                <div className={classes.imgWrapper} style={{ width: 600, alignItems: 'flex-end' }}>
                  <img className={classes.img} src={DashboardTournamentSite} alt="" />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div
              className={classes.box}
              onMouseEnter={() => this.setState({ showLongText: 'ranking' })}
              onMouseLeave={() => this.setState({ showLongText: undefined })}
            >
              <div
                className={classes.contentWrapper}
                style={showLongText === 'ranking' ? { opacity: 1 } : { opacity: 0 }}
              >
                <div className={classes.textWrapper}>
                  <div className={classes.title}>
                    <FormattedMessageWrapper id={'dashboard.toursAndRankings'} />
                  </div>

                  <Typography variant="body1" className={classes.text}>
                    <FormattedMessageWrapper id={'dashboard.toursAndRankingsLongText'} />
                  </Typography>
                </div>
              </div>
              <div
                className={classes.contentWrapper}
                style={
                  showLongText === 'ranking'
                    ? { opacity: 0, position: 'absolute' }
                    : { opacity: 1, position: 'absolute' }
                }
              >
                <div className={classes.textWrapper}>
                  <div className={classes.title}>
                    <FormattedMessageWrapper id={'dashboard.toursAndRankings'} />
                  </div>

                  <Typography variant="body1" className={classes.text}>
                    <FormattedMessageWrapper id={'dashboard.toursAndRankingsShortText'} />
                  </Typography>
                </div>
                <div className={classes.imgWrapper} style={{ width: 550, alignItems: 'center' }}>
                  <img className={classes.img} src={DashboardRanking} alt="" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(ManagerFeaturesLift)
