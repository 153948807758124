import { DivisionType } from '@app/store/api/enums/tournamentEnums'
import { rem } from '@app/theme/materialUITheme'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

export interface TypeLabelProps {
  type: DivisionType
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '6px',
    padding: `${rem(6)} ${rem(12)}`,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: rem(130),
    textAlign: 'center',
  },
  manual: {
    backgroundColor: 'rgba(190, 245, 35, 0.15)',
    color: '#91B628',
  },
  auto: {
    backgroundColor: 'rgba(26, 168, 151, 0.22)',
    color: '#29ACA4',
  },
  disabled: {
    backgroundColor: theme.customPalette.lightGray,
    color: theme.customPalette.lightGray3,
  },
}))

export const TypeLabel: React.FC<TypeLabelProps> = ({ type = DivisionType.AUTOMATIC, disabled }) => {
  const classes = useStyles()

  return (
    <div
      className={classNames([
        classes.root,
        disabled && classes.disabled,
        type === DivisionType.AUTOMATIC ? classes.auto : classes.manual,
      ])}
    >
      {type.toLowerCase()}
    </div>
  )
}
