import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { DialogContent, Button, TextField, Theme, Typography, IconButton } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogActions from '@mui/material/DialogActions'
import { withStyles, WithStyles } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import { tournamentApi } from '@app/store/api/endpoints/tournamentApi'
import { RootState } from '@app/store'

interface OwnProps {
  tournament: Tournament | undefined
  open: boolean
  onClose(triggerFetch?: boolean): void
}

interface DispatchProps {
  updateInternalNote(args: UpdateTournamentNotePayload): void
}

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>

interface State {
  noteText: string
  tournament: Tournament | undefined
}

const styles = (theme: Theme) =>
  createStyles({
    clearButton: {
      color: theme.customPalette.error,
    },
    saveButton: {
      width: 187,
      height: 50,
    },
    noteText: {
      font: theme.fonts.secondary,
    },
    title: {
      color: theme.customPalette.dark,
      fontSize: 20,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  })

class NoteDialog extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      tournament: this.props.tournament,
      noteText: this.props.tournament?.internalNote || '',
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { tournament } = this.props

    if (tournament?.id !== prevProps.tournament?.id && tournament) {
      this.setState({
        tournament: this.props.tournament,
        noteText: tournament.internalNote,
      })
    }
  }

  render() {
    const { noteText } = this.state
    const { open = false, classes } = this.props

    return (
      <Dialog maxWidth="xs" fullWidth open={open} aria-labelledby="tournament-notes-title">
        <BaseDialogTitle
          id="tournament-notes-title"
          title={
            <Typography variant="h1" className={classes.title}>
              <FormattedMessageWrapper id="tournaments.notesTitle" />
            </Typography>
          }
          onClose={() => this.handleClose()}
        />
        <DialogContent>
          <TextField
            variant="outlined"
            multiline={true}
            minRows={10}
            fullWidth
            value={noteText}
            className={classes.noteText}
            onChange={({ target }) => this.handleChange(target.value)}
          />
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button variant="contained" color="primary" className={classes.saveButton} onClick={() => this.handleSave()}>
            <FormattedMessageWrapper id="buttons.save" />
          </Button>
          <IconButton className={classes.clearButton} onClick={() => this.handleDelete()} size="large">
            <DeleteIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    )
  }

  private handleChange = (text: string) => {
    this.setState({
      noteText: text,
      tournament: this.props.tournament,
    })
  }

  private handleSave = () => {
    const { tournament, updateInternalNote } = this.props

    tournament &&
      updateInternalNote({
        tournamentId: tournament?.id,
        internalNote: this.state.noteText,
        onSuccess: () => this.handleClose(true),
      })
  }

  private handleDelete = () => {
    this.setState(
      {
        noteText: '',
      },
      () => {
        this.handleSave()
      },
    )
  }

  private handleClose = (triggerFetch?: boolean): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose(triggerFetch)
    }
  }
}

export default connect<{}, DispatchProps, OwnProps, RootState>(null, {
  updateInternalNote: tournamentApi.endpoints.updateTournamentNotes.initiate,
})(withStyles(styles)(NoteDialog))
