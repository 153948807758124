import { useEffect } from 'react'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'

export const useSyncBracketFinalAndBronzeMatchPosition = () => {
  useEffect(() => {
    const finalDiv = document.getElementById(MatchPlayBracketStages.FINAL) as HTMLDivElement
    const bronzeDiv = document.getElementById(MatchPlayBracketStages.BRONZE_MATCH) as HTMLDivElement

    if (!finalDiv || !bronzeDiv) {
      return
    }

    bronzeDiv.style.left = `${finalDiv.offsetLeft}px`
  })
}
