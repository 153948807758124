import React from 'react'
import { Theme, DialogContent, Button, Grid, Typography, Divider, Radio, RadioGroup } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import Dialog from '@mui/material/Dialog'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { withStyles, WithStyles } from '@mui/styles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { validateHandicapValue } from '@app/utils/playerUtils'
import { rem } from '@app/theme/materialUITheme'
import { connect } from 'react-redux'
import { createDivision } from '@app/store/divisions/actions'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      color: theme.customPalette.darkGray2,
    },
    dialogContent: {
      margin: rem(10),
      overflow: 'hidden',
    },
    desc: {
      lineHeight: 1,
    },
    title: {
      fontSize: rem(20),
      justifyContent: 'center',
      marginBottom: rem(23),
    },
    text: {
      color: theme.customPalette.main,
      fontSize: rem(16),
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: rem(316),
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: rem(22),
      fontSize: rem(14),
      border: '2px solid ' + theme.customPalette.main,
      borderRadius: rem(6),
      marginBottom: rem(18),
      overflow: 'hidden',
    },
    rangeContainer: {
      display: 'flex',
      width: rem(160),
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: rem(9),
    },
    rangeField: {
      width: rem(71),
    },
    manualContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: rem(66),
    },
    divisionContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: rem(65),
    },
    nameField: {
      width: rem(300),
    },
    divider: {
      backgroundColor: theme.customPalette.lightGray2,
    },
  })

interface OwnProps {
  open: boolean
  onClose: () => void
  children?: JSX.Element
}

interface DispatchProps {
  createDivision: (organizationId: number, division: DivisionPayload, onSuccess: () => void) => void
}

interface StateProps {
  organizationId?: number
  isLoading: boolean
}

enum DivisionTypeEnum {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

interface State {
  divisionType?: DivisionTypeEnum
  name: string
  gender: string
  minHcp: string
  maxHcp: string
  minAge: string
  maxAge: string
  validations: {
    minHcp: boolean
    maxHcp: boolean
    minAge: boolean
    maxAge: boolean
  }
}

const initialState: State = {
  name: '',
  gender: 'all',
  minHcp: '',
  maxHcp: '',
  minAge: '',
  maxAge: '',
  validations: {
    minHcp: false,
    maxHcp: false,
    minAge: false,
    maxAge: false,
  },
}

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>

class CreateNewDivisionDialogComponent extends React.Component<Props, State> {
  readonly state: State = initialState

  handleOnClose = () => {
    const { onClose } = this.props

    setTimeout(() => {
      this.setState({
        ...initialState,
        divisionType: undefined,
      })
    }, 500)

    onClose()
  }

  handleChange = (type: DivisionTypeEnum) => {
    this.setState({
      divisionType: type,
    })
  }

  renderDialogContent = (type?: DivisionTypeEnum) => {
    switch (type) {
      case DivisionTypeEnum.AUTOMATIC:
        return this.renderAutomaticContent()
      case DivisionTypeEnum.MANUAL:
        return this.renderManualContent()
      default:
        return this.renderMainContent()
    }
  }

  isAgeValid = (value: string) => Number.isInteger(Number(value))

  validateFields = (input: string, inputType: string) => {
    const { minAge, maxAge, minHcp, maxHcp } = this.state
    let isInvalid = false

    switch (inputType) {
      case 'minAge':
        if (input === '' || (input !== '' && maxAge === '' && this.isAgeValid(input))) {
          isInvalid = false
        } else {
          isInvalid = input === '' ? false : !(Number(input) < Number(maxAge) && this.isAgeValid(input))
        }
        break
      case 'maxAge':
        isInvalid = input === '' ? false : !(Number(minAge) < Number(input) && this.isAgeValid(input))
        break
      case 'minHcp': {
        isInvalid = this._validateHcp(
          input,
          maxHcp,
          (validatedMaxHcp, value) => Number(validatedMaxHcp) > Number(value),
        )

        break
      }
      case 'maxHcp': {
        isInvalid = this._validateHcp(
          input,
          minHcp,
          (validatedMinHcp, value) => Number(validatedMinHcp) < Number(value),
        )

        break
      }
      default:
        return
    }

    this.setState({
      validations: {
        ...this.state.validations,
        [inputType]: isInvalid,
      },
    })
  }

  public _validateHcp = (
    input: string,
    stateHcp: string,
    compareFn: (validatedStateHcp: string, value: string) => boolean,
  ): boolean => {
    const { value, valid } = validateHandicapValue(input)
    const validatedStateHcp = validateHandicapValue(stateHcp).value || stateHcp

    if (!value) {
      return input === '' ? false : true
    }

    return !valid || !!(validatedStateHcp && compareFn(validatedStateHcp, value))
  }

  handleRadioChange = ({ target }: React.ChangeEvent<{ value: string }>) => {
    this.setState({
      gender: target.value,
    })
  }

  handleTextFieldChange = (type: string, input: string) => {
    this.setState({
      ...this.state,
      [type]: input,
    })

    this.validateFields(input, type)
  }

  handleCreate = () => {
    const { name, gender, minHcp, maxHcp, minAge, maxAge, divisionType, validations } = this.state
    const { organizationId } = this.props
    const isValid = !Object.keys(validations).some((value) => validations[value])

    const division: DivisionPayload = {
      name,
      gender: gender === 'all' ? null : (gender as 'male' | 'female'),
      minHcp: validateHandicapValue(minHcp).value || null,
      maxHcp: validateHandicapValue(maxHcp).value || null,
      minAge: Number(minAge) || null,
      maxAge: Number(maxAge) || null,
      type: divisionType,
    }

    if (name && isValid && organizationId) {
      this.props.createDivision(organizationId, division, () => {
        this.handleOnClose()
      })
    }
  }

  renderAutomaticContent = () => {
    const { classes } = this.props
    const { name, gender, maxAge, maxHcp, minAge, minHcp, validations } = this.state

    return (
      <>
        <BaseDialogTitle
          title={
            <Typography variant="h3" className={classes.dialogTitle}>
              <FormattedMessageWrapper id="divisions.automaticDialogTitle" />
            </Typography>
          }
          onClose={this.handleOnClose}
        />
        <DialogContent style={{ margin: 16 }}>
          <Typography variant="body2" className={classes.text}>
            <FormattedMessageWrapper id="divisions.name" />
          </Typography>
          <FormControl>
            <TextField
              variant="outlined"
              value={name}
              className={classes.nameField}
              onChange={({ target }) => this.handleTextFieldChange('name', target.value)}
            />
          </FormControl>
          <Divider className={classes.divider} />
          <Grid container spacing={2} className={classes.divisionContainer}>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                <FormattedMessageWrapper id="divisions.gender" />
              </Typography>
              <FormControl>
                <RadioGroup value={gender} onChange={this.handleRadioChange}>
                  <FormControlLabel
                    value="all"
                    control={<Radio color="primary" />}
                    label={<FormattedMessageWrapper id="divisions.noSelection" />}
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio color="primary" />}
                    label={<FormattedMessageWrapper id="options.male" />}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio color="primary" />}
                    label={<FormattedMessageWrapper id="options.female" />}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                <FormattedMessageWrapper id="divisions.ageRange" />
              </Typography>
              <div className={classes.rangeContainer}>
                <FormControl>
                  <TextField
                    type="number"
                    value={minAge}
                    className={classes.rangeField}
                    variant="outlined"
                    error={validations.minAge}
                    onChange={({ target }) => this.handleTextFieldChange('minAge', target.value)}
                  />
                </FormControl>
                -
                <FormControl>
                  <TextField
                    type="number"
                    value={maxAge}
                    className={classes.rangeField}
                    variant="outlined"
                    error={validations.maxAge}
                    onChange={({ target }) => this.handleTextFieldChange('maxAge', target.value)}
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.text}>
                <FormattedMessageWrapper id="divisions.hcpRange" />
              </Typography>
              <div className={classes.rangeContainer}>
                <FormControl>
                  <TextField
                    value={minHcp}
                    className={classes.rangeField}
                    variant="outlined"
                    error={validations.minHcp}
                    onChange={({ target }) => this.handleTextFieldChange('minHcp', target.value)}
                  />
                </FormControl>
                -
                <FormControl>
                  <TextField
                    value={maxHcp}
                    className={classes.rangeField}
                    variant="outlined"
                    error={validations.maxHcp}
                    onChange={({ target }) => this.handleTextFieldChange('maxHcp', target.value)}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <Button color="primary" disabled={this.props.isLoading} variant="contained" onClick={this.handleCreate}>
            <FormattedMessageWrapper id="buttons.create" />
          </Button>
        </DialogContent>
      </>
    )
  }

  renderManualContent = () => {
    const { classes } = this.props
    return (
      <>
        <BaseDialogTitle
          title={
            <Typography variant="h3" className={classes.dialogTitle}>
              <FormattedMessageWrapper id="divisions.manualDialogTitle" />
            </Typography>
          }
          onClose={this.handleOnClose}
        />
        <DialogContent className={classes.dialogContent}>
          <div className={classes.manualContainer}>
            <Typography variant="caption" className={classes.text}>
              <FormattedMessageWrapper id="divisions.name" />
            </Typography>
            <FormControl>
              <TextField
                variant="outlined"
                className={classes.nameField}
                onChange={({ target }) => this.handleTextFieldChange('name', target.value)}
              />
            </FormControl>
          </div>
          <Button color="primary" disabled={this.props.isLoading} variant="contained" onClick={this.handleCreate}>
            <FormattedMessageWrapper id="buttons.create" />
          </Button>
        </DialogContent>
      </>
    )
  }

  renderMainContent = () => {
    const { classes } = this.props
    return (
      <>
        <BaseDialogTitle
          title={
            <Typography variant="h3" className={classes.dialogTitle}>
              <FormattedMessageWrapper id="divisions.createNewDivision" />
            </Typography>
          }
          onClose={this.handleOnClose}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div className={classes.container}>
                <div>
                  <Typography variant="h4" className={classes.title}>
                    <FormattedMessageWrapper id="divisions.automaticTitle" />
                  </Typography>
                  <Typography variant="caption" className={classes.desc}>
                    <FormattedMessageWrapper id="divisions.automaticDesc" />
                  </Typography>
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.handleChange(DivisionTypeEnum.AUTOMATIC)}
                  >
                    <FormattedMessageWrapper id="buttons.select" />
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.container}>
                <div>
                  <Typography variant="h4" className={classes.title}>
                    <FormattedMessageWrapper id="divisions.manualTitle" />
                  </Typography>
                  <Typography variant="caption" className={classes.desc}>
                    <FormattedMessageWrapper id="divisions.manualDesc" />
                  </Typography>
                </div>
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.handleChange(DivisionTypeEnum.MANUAL)}
                  >
                    <FormattedMessageWrapper id="buttons.select" />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    )
  }

  render() {
    const { open = false } = this.props
    const { divisionType } = this.state

    return (
      <Dialog fullWidth open={open}>
        {this.renderDialogContent(divisionType)}
      </Dialog>
    )
  }
}

const StyledCreateNewDivisionDialog = withStyles(styles)(CreateNewDivisionDialogComponent)

export const CreateNewDivisionDialog = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    organizationId: store.authenticationReducer.customerInfo?.idCustomer,
    isLoading: store.divisionsReducer.loading,
  }),
  {
    createDivision,
  },
)(StyledCreateNewDivisionDialog as any)
