import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { preProcessRoundForSending } from '@app/utils/sagaHelpers'
import { tournamentApi } from './tournamentApi'
import { setSelectedRoundId } from '../slices/configSlice'
import { syncTournamentDataAfterRoundUpdate } from '../thunks/tournamentRoundThunks'
import { removeStartListRound } from '../slices/tournamentStartListsSlice'

export const tournamentRoundApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateTournamentRound: build.mutation<TournamentRoundResponse, TournamentRoundPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_ROUND(payload.tournamentId, payload.body.id),
        method: HttpMethod.PUT,
        body: preProcessRoundForSending(payload.body),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(syncTournamentDataAfterRoundUpdate(data))
        } catch {
          console.error('Error updating round')
        }
      },
    }),
    createTournamentRound: build.mutation<TournamentRoundResponse, TournamentRoundPayload>({
      query: (payload) => ({
        url: APIRoute.POST_ROUND(payload.tournamentId),
        method: HttpMethod.POST,
        body: preProcessRoundForSending(payload.body),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { rounds } = data.tournament
          dispatch(setSelectedRoundId(rounds[rounds.length - 1].id))
          dispatch(syncTournamentDataAfterRoundUpdate(data))
        } catch {
          console.error('Error creating round')
        }
      },
    }),
    deleteTournamentRound: build.mutation<void, DeleteRoundPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_ROUND(payload.tournamentId, payload.roundId),
        method: HttpMethod.DELETE,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(removeStartListRound(payload.roundId))
          dispatch(tournamentApi.endpoints.syncTournament.initiate(payload.tournamentId))
        } catch {
          console.error('Error deleting round')
        }
      },
      invalidatesTags: [CacheTag.TOURNAMENT_SINGLE],
    }),
    updateTournamentRoundStatusWeekly: build.mutation<void, UpdateRoundStatusWeeklyPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_EDIT_TOURNAMENT_ROUND_STATUS_WEEKLY(payload.tournamentId),
        method: HttpMethod.PUT,
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_SINGLE],
    }),
    updateTournamentRoundStatus: build.mutation<TournamentRoundResponse, UpdateRoundStatusPayload>({
      query: (payload) => ({
        url: APIRoute.PATCH_EDIT_TOURNAMENT_ROUND_STATUS(payload.tournamentId, payload.roundId),
        method: HttpMethod.PATCH,
        body: payload.body,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(syncTournamentDataAfterRoundUpdate(data))
        } catch {
          console.error('Error updating round status')
        }
      },
    }),
  }),
})

export const {
  useUpdateTournamentRoundMutation,
  useCreateTournamentRoundMutation,
  useDeleteTournamentRoundMutation,
  useUpdateTournamentRoundStatusWeeklyMutation,
  useUpdateTournamentRoundStatusMutation,
} = tournamentRoundApi
