import React, { PureComponent } from 'react'
import { Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import createStyles from '@mui/styles/createStyles'
import { Button, Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { getDroppedOutPlayers } from '@app/store/api/selectors/tournamentStartListsSelectors'
import { PlayerDialogChildrenArgs } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import ListPlayer from './ListPlayer'
import { rem } from '@app/theme/materialUITheme'
import { tournamentPlayersChangeSort } from '@store/api/thunks/tournamentPlayersThunks'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { RootState } from '@app/store'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

const styles = (theme: Theme) =>
  createStyles({
    droppedOutPlayerBlock: {
      padding: theme.spacing(0.8),
      backgroundColor: '#ECECEC',
      boxSizing: 'border-box',
      height: 'calc(100vh - 250px)',
      minHeight: 500,
      overflowY: 'auto' as const,
      width: '100%',
    },
    poolHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: rem(12),
      alignItems: 'center',
      padding: `${theme.spacing(0.5)} ${theme.spacing(0.8)}`,
      justifyContent: 'space-evenly',
    },
    extraInfoSelect: {
      color: theme.palette.common.white,
      fontSize: rem(12),
    },
    sortButton: {
      margin: 0,
      padding: 0,
      minWidth: 0,
      color: theme.palette.common.white,
      fontSize: rem(12),
    },
  })

class DroppedOutPlayersInnerList extends PureComponent<any> {
  render() {
    return this.props.players.map((player: TournamentPlayer, index: number) => {
      if (player.team) {
        return null
      }
      return (
        <ListPlayer
          key={player.userId}
          tournamentPlayer={player}
          index={index}
          animationDisabled
          openPlayerDialog={this.props.openPlayerDialog}
          onClosePlayerDialog={this.props.onClosePlayerDialog}
          hideDragHandle={true}
          usedByComponent="DroppedOutPlayersInnerList"
        />
      )
    })
  }
}

interface StateProps {
  droppedOutPlayers: TournamentPlayer[]
  playerExtraInfo: string
  sortBy: PoolOrderBy
  sortDirection: SortDirection
  isLoading: boolean
}

interface DispatchProps {
  tournamentPlayersChangeSort: (sortBy: PoolOrderBy, sortDirection: SortDirection) => void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & PlayerDialogChildrenArgs

class UnconnectedDroppedOutPlayers extends React.PureComponent<Props> {
  render() {
    const { classes, droppedOutPlayers } = this.props

    return (
      <>
        <this.PoolHeader />
        <Droppable droppableId="droppedOut" type="droppablePlayer">
          {(dropProvided: DroppableProvided) => (
            <div ref={dropProvided.innerRef} className={classes.droppedOutPlayerBlock}>
              <DroppedOutPlayersInnerList
                players={droppedOutPlayers}
                openPlayerDialog={this.props.openPlayerDialog}
                onClosePlayerDialog={this.props.onClosePlayerDialog}
                disablePlayerListEdit={true}
              />
            </div>
          )}
        </Droppable>
      </>
    )
  }

  private PoolHeader = () => {
    const { classes } = this.props

    return (
      <Grid container className={classes.poolHeader}>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          {this.renderSortButton('firstName', 'tournament.firstNameShort')}
        </Grid>
        <Grid item xs={3}>
          {this.renderSortButton('lastName', 'tournament.lastNameShort')}
        </Grid>
        <Grid item xs={1}>
          {this.renderSortButton('hcp', 'tournaments.hcpBadge')}
        </Grid>
        <Grid item xs={2}>
          {this.renderSortButton('teeBox', 'options.teebox')}
        </Grid>
        <Grid item xs={2}>
          {this.renderSortButton('status', 'tournament.status')}
        </Grid>
      </Grid>
    )
  }

  private renderSortButton = (id: string, text?: string) => {
    const { classes, sortDirection, sortBy } = this.props

    const sortIcon =
      sortDirection === 'asc' ? (
        <ArrowUpwardIcon style={{ fontSize: 12 }} />
      ) : (
        <ArrowDownwardIcon style={{ fontSize: 12 }} />
      )

    return (
      <Button
        size="small"
        onClick={this.onClickSort}
        className={classes.sortButton}
        id={id}
        endIcon={sortBy === id && sortIcon}
      >
        {text && (
          <span style={{ textTransform: 'uppercase' }}>
            <FormattedMessageWrapper id={text} />
          </span>
        )}
      </Button>
    )
  }

  private onClickSort = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { tournamentPlayersChangeSort, sortBy, sortDirection } = this.props
    const newSortBy = e.currentTarget.id
    let newSortDirection = sortDirection
    if (sortBy === newSortBy) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }
    tournamentPlayersChangeSort(newSortBy as PoolOrderBy, newSortDirection)
  }
}

export const DroppedOutPlayers = withStyles(styles)(
  connect<StateProps, DispatchProps, {}, RootState>(
    (state) => {
      const tournamentConfig = selectTournamentConfig(state)
      return {
        droppedOutPlayers: getDroppedOutPlayers(state),
        playerExtraInfo: tournamentConfig.playerExtraInfo,
        sortBy: tournamentConfig.sortBy,
        sortDirection: tournamentConfig.sortDirection,
        isLoading:
          tournamentConfig.status.isTournamentPlayersLoading || tournamentConfig.status.isTournamentStartListsLoading,
      }
    },
    {
      tournamentPlayersChangeSort,
    },
  )(UnconnectedDroppedOutPlayers),
)
