import React from 'react'
import { FormControl, Select, MenuItem, Grid, FormLabel, OutlinedInput, InputAdornment } from '@mui/material'
import { WithStyles, createStyles, withStyles } from '@mui/styles'
import { debounce } from 'lodash'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { Description } from '@mui/icons-material'
import SelectBox from '@components/ui/SelectBox'
import { searchClubs, setSelectedCourseVersion } from '@store/api/thunks/clubsAndCoursesThunks'
import TextButton from '@components/ui/TextButton'
import CourseGuideHelper, { ChildrenPropsArgs } from '@components/dialogs/courseGuideDialog/CourseGuideDialog'
import ButtonLoaderWrap from '@components/ui/ButtonLoaderWrap'
import { OverlayLoader } from '@components/ui/OverlayLoader'
import { validFemaleTeeBoxes, validMaleTeeBoxes } from '@utils/clubAndCourseUtils'
import { rem } from '@theme/materialUITheme'
import { emptyRoundConfig } from '@app/store/api/emptyObjects'
import InfoTooltip from '@components/ui/InfoTooltip'
import CountryPicker from '@components/tournament/CountryPicker'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import {
  selectClubsAndCoursesConfig,
  selectTournamentConfig,
  setSelectedCountryId,
} from '@app/store/api/slices/configSlice'
import { setSelectedClubId } from '@app/store/api/thunks/configThunks'
import { setSelectedCourseId, getCourses } from '@app/store/api/thunks/clubsAndCoursesThunks'
import { Autocomplete, AutocompleteItem } from '@app/components/forms/Autocomplete'
import { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { RootState } from '@app/store'
import {
  resetContests,
  updateRoundField,
  handleDefaultTeeBoxes,
  handleDefaultHolesCount,
  handleDefaultStartingHole,
  resetCourseSettingsData,
} from '@app/store/api/thunks/tournamentThunks'
import { maybeUpdateSelectedCourseClubData } from '@app/store/api/thunks/configThunks'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'
import {
  selectClubs,
  selectRoundCourseOptions,
  selectSelectedRoundCourse,
  setClubs,
} from '@app/store/api/slices/clubsAndCoursesSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { getRoundStartTime } from '@app/store/api/utils/tournamentRoundUtils'
import { SearchIcon } from '@app/assets/icons'
import { format } from 'date-fns'

const styles = createStyles({
  relativeBlock: {
    position: 'relative',
    width: '100%',
  },
  noResultsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '14px 16px',

    '& > svg': {
      marginLeft: 5,
      marginRight: 10,
    },
  },
})

interface OwnProps {
  selectedRoundIndex: number
  selectedRound: Round
  disabled?: boolean
}

interface StateProps {
  clubs: Club[]
  tournament: TournamentState
  tournamentStartLists: TournamentStartListState
  units?: OrganizationUnits
  tournamentConfig: TournamentConfig
  clubsAndCoursesConfig: ClubsAndCoursesConfig
  currentRoundCourse?: RoundCourse
  roundCourses: RoundCourse[]
  organization: OrganizationState
}

interface DispatchProps {
  updateRoundField: (payload: UpdateRoundFieldPayload, setDirty?: boolean) => void
  setSelectedCountryId: (countryId: number) => void
  setSelectedClubId: (clubId: number) => void
  setSelectedCourseId: (courseId: number) => void
  resetContests: (roundIndex: number) => void
  searchClubs: (payload: SearchClubsPayload, isPublicSearch?: boolean) => void
  getCourses: (payload: CoursesPayload) => void
  maybeUpdateSelectedCourseClubData: (tournament: TournamentState, roundIndex: number) => void
  handleDefaultTeeBoxes: (courseTees: CourseTee[], setDirty?: boolean) => void
  handleDefaultHolesCount: () => void
  handleDefaultStartingHole: () => void
  resetCourseSettingsData: () => void
  setClubs: (clubs: Club[]) => void
  setSelectedCourseVersion: (courseVersion: number) => void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

interface State {
  currentClubId?: number
  currentCourseId?: number
  roundStartTime: DateTimeValue
  clubSearchTerm: string
  error?: string
}

const initialState: State = {
  currentClubId: undefined,
  currentCourseId: undefined,
  roundStartTime: undefined,
  clubSearchTerm: '',
  error: undefined,
}

class UnconnectedCourseSettings extends React.Component<Props, State> {
  readonly state: State = initialState
  mounted = true

  public componentDidMount() {
    this._maybeResetCourseSettingsData()
  }

  public componentDidUpdate() {
    const clubId = this.round.clubId?.value

    if (clubId && this._shouldFetchCourses()) {
      this.setState({
        roundStartTime: this.round.startTime,
      })
      this._getCourses(clubId)
    }
  }

  private _shouldFetchCourses = () => {
    const currentRoundStartTime = format(new Date(this.round.startTime), 'yyyy-MM-dd HH:mm')
    const currentStateStartTime = this.state.roundStartTime
      ? format(new Date(this.state.roundStartTime), 'yyyy-MM-dd HH:mm')
      : undefined

    const startTimeChanged = currentStateStartTime !== currentRoundStartTime
    const correctRound = this.props.selectedRoundIndex === this.round.roundNumber - 1
    const notLoading = !this.state.error && !this.isLoading()

    return correctRound && notLoading && startTimeChanged
  }

  public componentWillUnmount = () => {
    this.mounted = false
  }

  private get round() {
    const { selectedRound } = this.props
    return selectedRound || emptyRoundConfig
  }

  private get courseTees() {
    return this.props.currentRoundCourse?.teeBoxes || []
  }

  private get clubs() {
    return this.props.clubs
  }

  private get courses() {
    return this.props.roundCourses
  }

  private get _clubItems() {
    return (
      this.clubs &&
      this.clubs.map((club) => ({
        value: club.id,
        label: club.name,
      }))
    )
  }

  private get _courseItems() {
    return this.courses.map((course) => (
      <MenuItem key={course.id} value={course.id}>
        {course.courseName}
      </MenuItem>
    ))
  }

  private isLoading() {
    return this.props.clubsAndCoursesConfig.isLoading
  }

  private _maybeResetCourseSettingsData = () => {
    const { tournament, organization, resetCourseSettingsData, setClubs } = this.props
    const isUnsavedRound = tournament.id > 0 && this.round.id === -1

    if (isUnsavedRound && !organization.club) {
      resetCourseSettingsData()
    } else if (isUnsavedRound && organization.club?.id) {
      this._getCourses(organization.club.id)
      setClubs([])
    }
  }

  public onCountryChange = (event) => {
    const { selectedRoundIndex } = this.props
    const value = event.target.value ? parseInt(event.target.value, 10) : event.target.value

    // Changing country should reset the selected club and course
    this.props.setSelectedCountryId(value)
    this.props.setSelectedClubId(-1)
    this.props.setSelectedCourseId(-1)

    // update tournament too
    this.props.updateRoundField({
      index: selectedRoundIndex,
      fieldName: 'countryId',
      value,
    })
    this.props.updateRoundField({
      index: selectedRoundIndex,
      fieldName: 'clubId',
      value: undefined,
    })
    this.props.updateRoundField({
      index: selectedRoundIndex,
      fieldName: 'courseId',
      value: undefined,
    })

    // Changing country should reset the club autocomplete options
    this.props.setClubs([])
  }

  public _updateClubSearchTerm = (clubSearchTerm: string, reason?: AutocompleteInputChangeReason) => {
    if (clubSearchTerm !== this.state.clubSearchTerm) {
      this.setState({
        ...this.state,
        clubSearchTerm,
      })

      // Clear options if search term is empty, but not on reset (causes infinite loop)
      if ('reset' !== reason && '' === clubSearchTerm) {
        this.props.setClubs([])
      }

      if (reason === 'input') {
        this._fetchClubs()
      }
    }
  }

  public _afterGetClubsAndCourses = (result: ClubsAndCoursesAPICallResult) => {
    // if courses are present on the data and course is selected for current round,
    // find out teeboxes
    if (result.data && 'courses' in result.data) {
      const { courses = [] } = result.data
      const newClubId = courses[0]?.clubId
      const tournamentRoundCourse = this.props.currentRoundCourse as unknown as Course
      const roundCourses: Course[] = tournamentRoundCourse ? [...courses, tournamentRoundCourse] : courses
      const selectedCourse = roundCourses.find((c) => c.id === this.round.courseId && c.clubId === newClubId)

      if (roundCourses.length === 0) {
        this._afterCourseUpdate(undefined)
      } else if (!selectedCourse) {
        // selected course was not found, preselect the first and act accordingly
        // this means that the course belongs to other club and therefore is invalid option
        const courseToBeSet = roundCourses[0]
        this._afterCourseUpdate(courseToBeSet)
      } else {
        this._afterCourseUpdate(selectedCourse)
      }
    }

    this.setState({
      ...this.state,
      roundStartTime: this.round.startTime,
      ...result.data,
    })
  }

  private _afterCourseUpdate(course?: Course) {
    const { selectedRoundIndex } = this.props

    // fire the action to the store, course Id
    // Don't touch the dirty state here. Otherwise it will always be dirty when changing rounds
    const dirty = this.props.tournamentConfig.isDirty

    this.props.updateRoundField(
      {
        index: selectedRoundIndex,
        fieldName: 'courseId',
        value: course ? course.id : undefined,
      },
      dirty,
    )

    this.props.setSelectedCourseId(course ? course.id : -1)
    this.props.setSelectedCourseVersion(course?.version || -1)

    // Reset teebox and hole config
    this._handleDefaultTeeboxes(course ? course.teeBoxes : [])
  }

  private _handleDefaultTeeboxes(courseTees: CourseTee[], setDirty = true) {
    this.props.handleDefaultTeeBoxes(courseTees, setDirty)
    this.props.handleDefaultStartingHole()
  }

  private _fetchClubs = debounce(() => {
    if (!this.mounted) {
      return
    }

    const { countryId } = this.round
    const { clubSearchTerm } = this.state

    if (countryId && clubSearchTerm) {
      this.props.searchClubs({
        searchTerm: clubSearchTerm,
        countryId,
        onComplete: (a) => this._afterGetClubsAndCourses(a),
      })
    }
  }, 400)

  private _setDefaultStartingHole = (value?: string, dirty?: boolean) => {
    const { selectedRoundIndex, currentRoundCourse } = this.props

    let holeNumberToAssign: number
    if (!value || value === 'f9') {
      holeNumberToAssign = 1
    } else if (value === 'b9') {
      holeNumberToAssign = 10
    } else {
      holeNumberToAssign = this.round.defaultStartingHole ? this.round.defaultStartingHole.holeNumber : 1
    }

    const holeId = currentRoundCourse?.holes.find((hole) => hole.holeNumber === holeNumberToAssign)?.id

    if (holeId) {
      const payload = { fieldName: 'defaultStartingHole', value: { id: holeId, holeNumber: holeNumberToAssign } }

      this.props.updateRoundField(
        {
          index: selectedRoundIndex,
          ...payload,
        },
        dirty,
      )
    }
  }

  private _onTeeOrHoleConfigChange = (event) => {
    const { selectedRoundIndex, currentRoundCourse } = this.props
    const { name, value } = event.target

    let fieldValue = value

    // Change default starting hole according to selected number of holes
    if (name === 'holes') {
      this._setDefaultStartingHole(value)
    }

    if (name === 'defaultStartingHole') {
      const holeId = currentRoundCourse?.holes.find((hole) => hole.holeNumber === Number(value))?.id
      const holeNumber = Number(value)
      fieldValue = { id: holeId, holeNumber }
    }

    if (name) {
      this.props.updateRoundField({
        index: selectedRoundIndex,
        fieldName: name,
        value: fieldValue,
      })
    }
  }

  private _onClubChange = (item?: AutocompleteItem) => {
    const { selectedRoundIndex } = this.props
    const clubId = item?.value || -1
    this.props.setSelectedClubId(clubId)
    this.props.resetContests(selectedRoundIndex)

    // update tournament too
    this.props.updateRoundField({
      index: selectedRoundIndex,
      fieldName: 'clubId',
      value: clubId > 0 ? item : undefined,
    })

    this._getCourses(clubId)
  }

  private _getCourses = (clubId: number) => {
    if (clubId === -1) {
      return
    }

    const timestamp = getRoundStartTime(this.round)

    this.props.getCourses({
      clubId,
      timestamp,
      onComplete: (res) => {
        this._afterGetClubsAndCourses(res)
      },
      onFailure: () => this.setState({ error: 'failed' }),
    })
  }

  private _onCourseChange = (event) => {
    const { selectedRoundIndex, roundCourses, handleDefaultHolesCount } = this.props
    const value = parseInt(event.target.value, 10)

    this.props.setSelectedCourseId(value)
    this.props.resetContests(selectedRoundIndex)

    const currentRoundCourse = roundCourses.find((c) => c.id === value && c.roundIndex === selectedRoundIndex)

    if (currentRoundCourse) {
      // Default teeboxes when changing course
      this._handleDefaultTeeboxes(currentRoundCourse.teeBoxes)
      // Default holes count when changing course
      handleDefaultHolesCount()
    }

    // update tournament too
    this.props.updateRoundField({
      index: selectedRoundIndex,
      fieldName: 'courseId',
      value,
    })
  }

  private _getClubSearchNoOptionsText = () => {
    const { clubSearchTerm } = this.state
    const { intl, classes } = this.props

    if (!clubSearchTerm) {
      return ''
    }

    return (
      <div className={classes.noResultsContainer}>
        <SearchIcon stroke="#BBBBBD" />
        {intl.formatMessage({ id: 'tournament.typeClubName' })}
      </div>
    )
  }

  private _courseTees(gender: string) {
    const filterFn = gender === 'male' ? validMaleTeeBoxes : validFemaleTeeBoxes
    return filterFn(this.courseTees).map((tee) => (
      <MenuItem key={tee.id} value={tee.id}>
        {tee.teeboxName}
      </MenuItem>
    ))
  }

  private get defaultStartingHoleOptions() {
    const { currentRoundCourse } = this.props
    const courseHoles = currentRoundCourse?.holes || []

    const mapHole = (hole: CourseHole) => (
      <MenuItem key={hole.id} value={hole.holeNumber}>
        {hole.holeNumber}
      </MenuItem>
    )

    switch (this.round.holes) {
      case 'f9': {
        return courseHoles.filter((hole) => hole.holeNumber < 10).map(mapHole)
      }
      case 'b9': {
        return courseHoles.filter((hole) => hole.holeNumber > 9).map(mapHole)
      }
      default: {
        return courseHoles.map(mapHole)
      }
    }
  }

  private _renderScorecardLink = () => {
    const { selectedRoundIndex } = this.props
    return (
      <CourseGuideHelper roundIndex={selectedRoundIndex}>
        {({ showCourseGuide, isLoading }: ChildrenPropsArgs) => (
          <div style={{ float: 'right' }}>
            <ButtonLoaderWrap loading={isLoading}>
              <TextButton
                disabled={isLoading}
                onClick={() => {
                  const { clubId, courseId } = this.round
                  if (clubId && courseId) {
                    showCourseGuide(clubId.value, courseId)
                  }
                }}
                style={{ padding: '3px 0' }}
                labelStyle={{ fontSize: rem(12) }}
              >
                <Description style={{ fontSize: rem(14), marginRight: 3 }} />
                <FormattedMessageWrapper id={'course.scorecard'} />
              </TextButton>
            </ButtonLoaderWrap>
          </div>
        )}
      </CourseGuideHelper>
    )
  }

  public render() {
    const { classes, tournament, clubsAndCoursesConfig, intl } = this.props

    const {
      menTeeBoxId,
      womenTeeBoxId,
      holes,
      courseId: roundCourseId,
      clubId,
      countryId,
      defaultStartingHole,
    } = this.round

    const { clubSearchTerm } = this.state
    const courseId = roundCourseId > 0 ? roundCourseId : clubsAndCoursesConfig.selectedCourseId

    const { tournamentType } = tournament

    return (
      <div className={classes.relativeBlock}>
        {this.isLoading() && <OverlayLoader positionAbsolute />}
        <Grid container={true} spacing={2}>
          <Grid item xs={4}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.country" />
            </FormLabel>
            <CountryPicker
              countryId={countryId}
              onChange={this.onCountryChange}
              showInnerLabel={false}
              enableAllOption={true}
              disabled={tournament.hcpRound || this.props.disabled}
            />
          </Grid>
          <Grid item xs={4}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.club" />
            </FormLabel>
            <Autocomplete
              value={countryId ? clubId : undefined}
              onChange={this._onClubChange}
              options={this._clubItems}
              inputValue={countryId ? clubSearchTerm : intl.formatMessage({ id: 'tournament.selectCountryFirst' })}
              onInputChange={this._updateClubSearchTerm}
              disabled={!countryId || this.props.disabled}
              noOptionsText={this._getClubSearchNoOptionsText()}
              placeholder={intl.formatMessage({ id: 'tournament.searchClub' })}
              startAdornment={
                <InputAdornment position="start" style={{ marginLeft: 10 }}>
                  <SearchIcon stroke={!countryId ? '#BBBBBD' : undefined} />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={1}>
            <InfoTooltip
              size={'large'}
              style={{ marginTop: 26 }}
              text={<FormattedMessageWrapper id={'tournament.courseInfo'} />}
            />
          </Grid>
          <Grid item xs={8}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.course" />
              {clubId && courseId ? this._renderScorecardLink() : null}
            </FormLabel>
            <FormControl fullWidth={true} margin={'none'}>
              <Select
                value={clubId?.value && courseId ? courseId : 0}
                onChange={this._onCourseChange}
                input={<OutlinedInput />}
                disabled={!clubId?.value || this.props.disabled}
              >
                {this._courseItems}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.mensTee" />
            </FormLabel>
            <SelectBox
              id="menTeeBoxId"
              name="menTeeBoxId"
              onChange={this._onTeeOrHoleConfigChange}
              value={clubId?.value && menTeeBoxId ? menTeeBoxId : 0}
              inputElement={OutlinedInput}
              margin="none"
              disabled={!clubId?.value || this.props.disabled}
            >
              {this._courseTees('male')}
            </SelectBox>
          </Grid>
          <Grid item xs={2}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.womensTee" />
            </FormLabel>
            <SelectBox
              id="womenTeeBoxId"
              name="womenTeeBoxId"
              onChange={this._onTeeOrHoleConfigChange}
              value={clubId?.value && womenTeeBoxId ? womenTeeBoxId : 0}
              inputElement={OutlinedInput}
              margin="none"
              disabled={!clubId?.value || this.props.disabled}
            >
              {this._courseTees('female')}
            </SelectBox>
          </Grid>
          <Grid item xs={2}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.numberOfHoles" />
            </FormLabel>
            <SelectBox
              id="holes"
              name="holes"
              onChange={this._onTeeOrHoleConfigChange}
              value={holes || ''}
              inputElement={OutlinedInput}
              margin="none"
              disabled={this.props.disabled}
            >
              <MenuItem value="18">18</MenuItem>
              <MenuItem value="f9">Front 9</MenuItem>
              <MenuItem value="b9">Back 9</MenuItem>
            </SelectBox>
          </Grid>
          <Grid item xs={2}>
            {tournamentType === TournamentTypes.weekly && (
              <>
                <FormLabel>
                  <FormattedMessageWrapper id="tournament.defaultStartingHole" />
                </FormLabel>
                <SelectBox
                  id="defaultStartingHole"
                  name="defaultStartingHole"
                  onChange={this._onTeeOrHoleConfigChange}
                  value={defaultStartingHole?.holeNumber || 1}
                  inputElement={OutlinedInput}
                  margin="none"
                  disabled={this.props.disabled}
                >
                  {this.defaultStartingHoleOptions}
                </SelectBox>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export const CourseSettings = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store): StateProps => {
    return {
      clubs: selectClubs(store),
      tournament: selectTournament(store),
      tournamentStartLists: selectTournamentStartLists(store),
      units: store.authenticationReducer.units,
      tournamentConfig: selectTournamentConfig(store),
      clubsAndCoursesConfig: selectClubsAndCoursesConfig(store),
      currentRoundCourse: selectSelectedRoundCourse(store),
      roundCourses: selectRoundCourseOptions(store),
      organization: store.organizationReducer,
    }
  },
  {
    updateRoundField,
    resetContests,
    setSelectedCountryId,
    setSelectedClubId,
    setSelectedCourseId,
    searchClubs,
    getCourses,
    maybeUpdateSelectedCourseClubData,
    handleDefaultTeeBoxes,
    handleDefaultHolesCount,
    handleDefaultStartingHole,
    resetCourseSettingsData,
    setClubs,
    setSelectedCourseVersion,
  },
)(withStyles(styles)(injectIntl(UnconnectedCourseSettings as any)))
