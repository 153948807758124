import fi from './localizations/fi.json'
import en from './localizations/en.json'
import sv from './localizations/sv.json'
import es from './localizations/es_ES.json'

export interface Localization {
  [code: string]: any
}

export const localizationTable = {
  'fi-FI': 'fi',
  'fi-SV': 'sv',
  'en-US': 'en',
  'es-ES': 'es',
}

const localization: Localization = {
  'fi-FI': fi,
  'fi-SV': sv, // hackity-hack-hack: fi-SV because we need the finnish date formatting for dates
  'en-US': en,
  'es-ES': es,
}

export default localization
