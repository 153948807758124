import { APIRoute, API_ROOT } from '@app/config'
import { apiClientRequest, defaultOnCompleteCall } from '@app/utils/sagaHelpers'
import { all, put, takeLatest } from 'redux-saga/effects'
import { getToken } from '../authentication/sagas'
import { enqueueNotification } from '../notifications/actions'
import { FetchTimeZonesAction } from './actions'
import { TIME_ZONES_FETCH, TIME_ZONES_FETCH_FAILURE, TIME_ZONES_FETCH_SUCCESS } from './constants'

function* doFetchTimeZones({ onComplete }: FetchTimeZonesAction) {
  try {
    const token = yield getToken()

    const { data } = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_TIME_ZONES()}`,
      method: 'get',
      token,
    })

    defaultOnCompleteCall(onComplete, data)
    yield put({
      type: TIME_ZONES_FETCH_SUCCESS,
      timeZones: data.timeZones,
    })
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, undefined, error)
    yield put({ type: TIME_ZONES_FETCH_FAILURE, error: error.toString() })
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchTimeZones() {
  yield all([takeLatest(TIME_ZONES_FETCH, doFetchTimeZones)])
}
