import { FetchTimeZonesAction, FetchTimeZonesFailureAction, FetchTimeZonesSuccessAction } from './actions'
import { TIME_ZONES_FETCH, TIME_ZONES_FETCH_FAILURE, TIME_ZONES_FETCH_SUCCESS } from './constants'

type TimeZoneActions = FetchTimeZonesAction | FetchTimeZonesSuccessAction | FetchTimeZonesFailureAction

const initialState: TimeZonesState = {
  timeZones: [],
  loading: false,
  error: undefined,
}

export const timeZonesReducer = (state: TimeZonesState = initialState, action: TimeZoneActions): TimeZonesState => {
  switch (action.type) {
    case TIME_ZONES_FETCH: {
      return {
        ...state,
        loading: true,
      }
    }

    case TIME_ZONES_FETCH_SUCCESS: {
      const { timeZones } = action
      return {
        ...state,
        loading: false,
        timeZones,
      }
    }

    case TIME_ZONES_FETCH_FAILURE: {
      const { error } = action
      return {
        ...state,
        loading: false,
        error: error.error,
      }
    }

    default: {
      return state
    }
  }
}
