import React from 'react'
import { FormControl, Input, InputLabel, Select, SelectChangeEvent } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'

interface Props {
  labelKey?: string
  id: string
  name: string
  value: string | number
  onChange: (event: SelectChangeEvent<any>) => void
  children: React.ReactNode
  style?: React.CSSProperties
  inputElement?: React.ElementType
  autoWidth?: boolean
  disabled?: boolean
  margin?: 'normal' | 'none' | 'dense' | undefined
  className?: string
}

class SelectBox extends React.Component<Props> {
  render() {
    const {
      name,
      id,
      labelKey,
      value,
      onChange,
      children,
      style,
      inputElement = Input,
      autoWidth = false,
      disabled = false,
      margin = 'normal',
      className,
    } = this.props

    const inputProps: { name: string; id: string; labelWidth?: number } = { name, id }

    return (
      <FormControl margin={margin} fullWidth={!autoWidth} style={style} className={className} disabled={disabled}>
        {labelKey && (
          <InputLabel htmlFor={id} shrink={Boolean(value)} disabled={disabled}>
            <FormattedMessageWrapper id={labelKey} />
          </InputLabel>
        )}
        <Select
          disabled={disabled}
          value={value}
          onChange={onChange}
          input={React.createElement(inputElement as any, inputProps)}
          autoWidth={autoWidth}
        >
          {children}
        </Select>
      </FormControl>
    )
  }
}

export default SelectBox
