import { all, takeEvery, put } from 'redux-saga/effects'
import { getToken } from '../authentication/sagas'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import { API_ROOT, APIRoute, ROWS_PER_PAGE } from '../../config'
import {
  FetchOrganizationApplicationsAction,
  FetchOrganizationsAction,
  FetchOrganizationTournaments,
  putCondensedOrganizationsSuccess,
  putOrganizationApplicationsError,
  putOrganizationApplicationsSuccess,
  putOrganizationsError,
  putOrganizationsSuccess,
} from './actions'
import {
  CONDENSED_ORGANIZATIONS_FETCH,
  ORGANIZATION_APPLICATIONS_FETCH,
  ORGANIZATIONS_FETCH,
  FETCH_ORGANIZATIONS_TOURNAMENTS,
  FETCH_ORGANIZATIONS_TOURNAMENTS_SUCCESS,
  FETCH_ORGANIZATIONS_TOURNAMENTS_FAILURE,
} from './constants'
import { enqueueNotification } from '../notifications/actions'

function* doFetchOrganizations({ page = 1, searchTerm = '', filter = '', onComplete }: FetchOrganizationsAction) {
  try {
    const endpoint = searchTerm.length > 1 ? APIRoute.GET_ORGANIZATIONS_SEARCH : APIRoute.GET_ORGANIZATIONS

    const offset = ROWS_PER_PAGE * (page - 1)
    const limit = ROWS_PER_PAGE

    const params = {
      searchTerm,
      offset,
      limit,
    }

    const paramsWithFilter = {
      searchTerm,
      type: filter,
      offset,
      limit,
    }

    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${endpoint}`,
      method: 'get',
      token,
      params: !filter ? params : paramsWithFilter,
    })

    const { data } = res
    defaultOnCompleteCall(onComplete, data)
    yield put(putOrganizationsSuccess(data.organizations))
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, undefined, error)
    yield put(putOrganizationsError(error))
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchOrganizationApplications({ page = 1, onComplete }: FetchOrganizationApplicationsAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATION_APPLICATIONS}`,
      method: 'get',
      token,
      params: {
        offset: ROWS_PER_PAGE * (page - 1),
        limit: ROWS_PER_PAGE,
      },
    })
    const { data } = res
    defaultOnCompleteCall(onComplete, data)
    yield put(putOrganizationApplicationsSuccess(data.applications))
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, undefined, error)
    yield put(putOrganizationApplicationsError(error))
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchCondensedOrganizations() {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATIONS}`,
      method: 'get',
      token,
      params: {
        type: 'condensed',
      },
    })

    const { data } = res

    const organizations: CondensedItem[] = data.organizations.map((item: any) => ({
      id: item.idCustomer,
      name: item.nameCompany,
    }))
    yield put(putCondensedOrganizationsSuccess(organizations))
  } catch (error: any) {
    yield put(putOrganizationApplicationsError(error))
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchOrganizationTournaments({
  searchTerm,
  sortBy,
  direction,
  page = 1,
  onComplete,
}: FetchOrganizationTournaments) {
  try {
    const token = yield getToken()

    const url = `${API_ROOT}${APIRoute.GET_ORGANIZATION_TOURNAMENTS}`

    const res = yield apiClientRequest({
      url,
      method: 'get',
      token,
      params: {
        name: searchTerm,
        sortBy,
        direction,
        offset: ROWS_PER_PAGE * (page - 1),
        limit: ROWS_PER_PAGE,
      },
    })

    const { data } = res

    defaultOnCompleteCall(onComplete, data)

    yield put({ type: FETCH_ORGANIZATIONS_TOURNAMENTS_SUCCESS, organizationTournaments: data })
  } catch (error: any) {
    yield put({ type: FETCH_ORGANIZATIONS_TOURNAMENTS_FAILURE, error })
  }
}

export function* watchOrganizations() {
  yield all([
    takeEvery(ORGANIZATIONS_FETCH, doFetchOrganizations),
    takeEvery(ORGANIZATION_APPLICATIONS_FETCH, doFetchOrganizationApplications),
    takeEvery(CONDENSED_ORGANIZATIONS_FETCH, doFetchCondensedOrganizations),
    takeEvery(FETCH_ORGANIZATIONS_TOURNAMENTS, doFetchOrganizationTournaments),
  ])
}
