import { formatDateToIsoWithTimeZone } from '@app/utils/dates'
import { RootState } from '../..'
import { TournamentTypes } from '../enums/tournamentEnums'
import { selectTournamentStartList, tournamentStartListsApi } from '../endpoints/tournamentStartListsApi'
import { api } from '../baseApi'
import { CacheTag } from '../cacheTags'
import {
  internalAddPlayerToGroup,
  internalRemovePlayerFromGroup,
  startListsUpdated,
  updateStartListField,
  updateStartListRounds,
} from '../slices/tournamentStartListsSlice'
import { StartListSourceTarget } from '../enums/tournamentStartListsEnums'
import { tournamentStartListGroupApi } from '../endpoints/tournamentStartListGroupApi'
import { updateTournamentRoundStatus } from './tournamentRoundThunks'
import { showAutoSave } from '../../notifications/actions'
import { selectTournamentTeams } from '../endpoints/tournamentTeamsApi'
import { findIndex } from 'lodash'
import { createReferenceIdForGroups } from '@app/utils/startListUtils'
import { processGroups } from '@app/utils/sagaHelpers'
import {
  selectStartListEmailPreview,
  selectSummaryPageEmaillPreview,
  tournamentStartListsEmailApi,
} from '../endpoints/tournamentStartListsEmailApi'
import { selectTournament } from '../endpoints/tournamentApi'
import { processStartListRound } from '../utils/tournamentStartListsUtils'

export const fetchAllStartLists = () => {
  return (dispatch, getState: () => RootState) => {
    const tournament = selectTournament(getState())
    if (tournament.tournamentType === TournamentTypes.weekly) {
      return
    }
    const rounds = tournament.rounds.filter((round) => round.id > 0)
    rounds.forEach((round) => {
      dispatch(fetchStartList({ tournamentId: tournament.id, roundId: round.id }))
    })
  }
}

const fetchStartList = (payload: StartListPayload) => {
  return (dispatch, getState: () => RootState) => {
    const startList = selectTournamentStartList(getState(), payload)
    const isCached = startList !== undefined

    if (isCached) {
      const round = processStartListRound(payload, startList)
      dispatch(updateStartListRounds(round))
    }

    dispatch(
      tournamentStartListsApi.endpoints.getStartList.initiate({
        tournamentId: payload.tournamentId,
        roundId: payload.roundId,
      }),
    )
  }
}

export const saveAllStartLists = (onSuccess?: () => void) => {
  return (dispatch, getState: () => RootState) => {
    const tournament: TournamentState = selectTournament(getState())
    const tournamentId = tournament.id
    const rounds: StartListRound[] = getState().tournamentStartListsReducer.rounds
    if (!tournamentId) {
      return
    }

    for (const round of rounds) {
      const tournamentRound = tournament.rounds.find((_round) => _round.id === round.tournamentRoundId)
      if (!tournamentRound) {
        return
      }
      const roundId = round.tournamentRoundId
      const body: UpdateStartListBody = {
        startTime: formatDateToIsoWithTimeZone(tournamentRound.startTime, tournamentRound.club?.timeZone),
        startType: round.startType,
        basicGroupSize: round.basicGroupSize,
        teeInterval: round.teeInterval,
        fillStartListByType: round.fillStartListByType,
        public: !!tournamentRound.status?.isConfigured,
      }
      dispatch(tournamentStartListsApi.endpoints.updateStartList.initiate({ tournamentId, roundId, body }))
    }

    // set loading false as the other actions do not do that
    dispatch(startListsUpdated())

    if (tournament.isTeamFormat) {
      dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_TEAMS]))
    }

    if (onSuccess) {
      onSuccess()
    }
  }
}

export const moveTeam = (payload: MoveTournamentTeamAction) => {
  return (dispatch, getState: () => RootState) => {
    const tournament: TournamentState = selectTournament(getState())
    const tournamentId = tournament.id
    const teamId = payload.team.id

    const rounds: StartListRound[] = getState().tournamentStartListsReducer.rounds

    const round = rounds[payload.roundIndex]
    const { tournamentRoundId } = round

    const groupId = round.groups[payload.groupIndex].id

    if (!tournamentId || !groupId) {
      throw new Error('params missing')
    }

    const { source, target } = payload
    switch (true) {
      case target === StartListSourceTarget.GROUP && source === StartListSourceTarget.TEAM_POOL:
        dispatch(
          tournamentStartListGroupApi.endpoints.addTeamToGroup.initiate({
            tournamentId,
            roundId: tournamentRoundId,
            groupId,
            teamId,
          }),
        )
        break
      case target === StartListSourceTarget.GROUP:
        dispatch(
          tournamentStartListGroupApi.endpoints.moveTeamToGroup.initiate({
            tournamentId,
            roundId: tournamentRoundId,
            groupId,
            teamId,
          }),
        )
        break
      case target === StartListSourceTarget.TEAM_POOL:
        dispatch(
          tournamentStartListGroupApi.endpoints.deleteTeamFromGroup.initiate({
            tournamentId,
            roundId: tournamentRoundId,
            groupId,
            teamId,
          }),
        )
        break

      default:
        throw new Error('invalid team target')
    }
  }
}

export const publishStartList = (payload: PublishStartListPayload) => {
  return (dispatch) => {
    const roundStatusPayload: UpdateRoundStatusPayload = {
      tournamentId: payload.tournamentId,
      roundId: payload.roundId,
      body: {
        isConfigured: payload.isPublic,
        isScoringDisabled: true,
      },
      onSuccess: payload.onSuccess,
    }
    dispatch(updateTournamentRoundStatus(roundStatusPayload, true))
  }
}

export const removePlayerFromGroup = (payload: RemovePlayerFromGroupPayload) => {
  return (dispatch, getState: () => RootState) => {
    /**
     * Update store
     * Is this internal update for UI usability only? We don't want to wait for the backend to finish.
     * Is this still needed to maintain a team structure?
     * TODO: Find out if there is a better way to do this.
     */
    dispatch(internalRemovePlayerFromGroup(payload))
    const tournament: TournamentState = selectTournament(getState())
    const tournamentId = tournament.id

    dispatch(showAutoSave())

    const rounds: StartListRound[] = getState().tournamentStartListsReducer.rounds

    const round = rounds[payload.roundIndex]

    const destinationGroup = round.groups[payload.groupIndex]

    dispatch(
      tournamentStartListGroupApi.endpoints.removePlayerFromGroup.initiate({
        tournamentId: tournamentId,
        roundId: round.tournamentRoundId,
        groupId: destinationGroup.id,
        playerId: payload.playerId,
      }),
    )
  }
}

export const addPlayerToGroup = (payload: AddPlayerToGroupPayload) => {
  return (dispatch, getState: () => RootState) => {
    /**
     * Update store
     * Is this internal update for UI usability only? We don't want to wait for the backend to finish.
     * Is this still needed to maintain a team structure?
     * TODO: Find out if there is a better way to do this.
     */
    dispatch(internalAddPlayerToGroup(payload))

    const tournament: TournamentState = selectTournament(getState())
    const tournamentId = tournament.id

    dispatch(showAutoSave())

    const rounds: StartListRound[] = getState().tournamentStartListsReducer.rounds

    const round = rounds[payload.roundIndex]

    const destinationGroup = round.groups[payload.groupIndex]

    dispatch(
      tournamentStartListGroupApi.endpoints.addPlayerToGroup.initiate({
        tournamentId: tournamentId,
        roundId: round.tournamentRoundId,
        groupId: destinationGroup.id,
        playerId: payload.player.id,
        groupOrder: payload.orderIndex ? payload.orderIndex + 1 : 1,
      }),
    )
  }
}

export const addStartListGroupsToStore = (payload: StartListGroupsPayload, data: StartListGroup[]) => {
  return (dispatch, getState: () => RootState) => {
    const rounds: StartListRound[] = getState().tournamentStartListsReducer.rounds
    const tournamentTeams: TournamentTeam[] = selectTournamentTeams(getState()).data?.teams || []

    const startListPayload: StartListPayload = {
      tournamentId: payload.tournamentId,
      roundId: payload.roundId,
    }

    const startList = selectTournamentStartList(getState(), startListPayload)
    if (startList) {
      const updatedStartList = { ...startList, groups: data }
      dispatch(saveStartListData(startListPayload, updatedStartList))
      return
    }

    const index = findIndex(rounds, (round: StartListRound) => round.tournamentRoundId === payload.roundId)
    const groups = createReferenceIdForGroups(data)
    const processedGroups = processGroups(groups, tournamentTeams, payload.roundId)
    dispatch(updateStartListField({ index, fieldName: 'groups', value: processedGroups }))
  }
}

export const getStartListEmailPreview = (payload: StartListEmailPreviewPayload) => {
  return (dispatch, getState: () => RootState) => {
    const email = selectStartListEmailPreview(getState(), payload)
    const isCached = email !== undefined
    if (isCached && payload.onComplete) {
      payload.onComplete(email)
      return
    }
    dispatch(tournamentStartListsEmailApi.endpoints.getStartListEmailPreview.initiate(payload))
  }
}

export const getSummaryPageEmailPreview = (payload: SummaryPageEmailPreviewPayload) => {
  return (dispatch, getState: () => RootState) => {
    const email = selectSummaryPageEmaillPreview(getState(), payload)
    const isCached = email !== undefined
    if (isCached && payload.onComplete) {
      payload.onComplete(email.htmlBase64Content)
      return
    }
    dispatch(tournamentStartListsEmailApi.endpoints.getSummaryPageEmailPreview.initiate(payload))
  }
}

export const saveStartListData = (payload: StartListPayload, data: StartListRound) => {
  return (dispatch) => {
    const round = processStartListRound(payload, data)
    dispatch(updateStartListRounds(round))
    dispatch(tournamentStartListsApi.util.upsertQueryData('getStartList', payload, data))
  }
}
