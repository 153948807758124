import React from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { AddCircle } from '@mui/icons-material'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { rem } from '../../theme/materialUITheme'
import NoWrapTooltip from '../ui/NoWrapTooltip'
import { formatHandicap } from '../../utils/playerUtils'
import { RootState } from '@app/store'
import { tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import { getDefaultProfileImage } from '@app/config'

const styles = (theme: Theme) =>
  createStyles({
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 56,
      borderRadius: 8,
      border: '1px solid #EDEFF1',
      boxShadow: '0px 2px 6px 0px #0203031A',
      padding: 20,
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
    },
    image: {
      width: 40,
      height: 40,
      borderRadius: '50%',
    },
    addButton: {
      height: '100%',
      minWidth: 20,
      '@global': {
        svg: {
          fontSize: rem(15),
        },
      },
    },
    name: {
      padding: '5px 8px',
      display: 'flex',
      alignItems: 'center',
      width: '40%',
      flexShrink: 0,
    },
    wrapText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    handicap: {
      padding: '5px 8px',
      flexBasis: 60,
      display: 'flex',
      alignItems: 'center',
      '& > p': {
        color: '#8A8A8E',
      },
    },
    clubName: {
      padding: '5px 8px',
      display: 'flex',
      alignItems: 'center',
      width: '31%',
      '& > p': {
        color: '#8A8A8E',
      },
    },
    action: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      '&&': {
        '& svg': {
          cursor: 'pointer',
          fontSize: 20,
          fill: theme.palette.primary.main,
          '&:hover': {
            opacity: 0.75,
          },
        },
      },
    },
  })

interface OwnProps {
  player: SearchPlayer
  playerExtraInfo?: string
  customQuestionsIdMap?: { [questionId: number]: Question }
  addingPlayer?: boolean
  handleAddPlayer?: () => void
}

interface StateProps {
  organizationCountryId?: number
  tournamentId: number
}

interface DispatchProps {
  addPlayerToTournament: (payload: AddPlayerToTournamentPayload) => void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>

interface State {
  loading: boolean
}

const initialState: State = {
  loading: false,
}

class MiniPlayerCard extends React.Component<Props, State> {
  readonly state = initialState

  public render() {
    const {
      classes,
      player: { firstName, lastName, handicap, clubName, profileImageUrl },
      addingPlayer = false,
    } = this.props
    const { loading } = this.state

    const profileImg = profileImageUrl ? profileImageUrl : getDefaultProfileImage()
    const showLoader = loading || addingPlayer

    return (
      <Grid item xs={6} xl={4}>
        <div className={classes.wrap}>
          <div className={classes.imageContainer}>
            <img className={classes.image} src={profileImg} />
          </div>
          <div className={classes.name}>
            <NoWrapTooltip title={`${firstName} ${lastName}`}>
              <Typography variant="body2" className={classes.wrapText}>
                <strong>
                  {firstName} {lastName}
                </strong>
              </Typography>
            </NoWrapTooltip>
          </div>
          <div className={classes.handicap}>
            <Typography variant={'body2'}>{handicap ? formatHandicap(handicap.toString()) : ''}</Typography>
          </div>
          <div className={classes.clubName}>
            <Typography variant={'body2'} className={classes.wrapText}>
              {clubName}
            </Typography>
          </div>
          <div className={classes.action}>
            {showLoader && <CircularProgress size={15} />}
            {!loading && !addingPlayer && <AddCircle onClick={this.togglePlayer} />}
          </div>
        </div>
      </Grid>
    )
  }

  private togglePlayer = () => {
    const { player, handleAddPlayer, addPlayerToTournament, tournamentId } = this.props

    if (handleAddPlayer) {
      handleAddPlayer()
      return
    }

    addPlayerToTournament({
      tournamentId,
      providerPlayerIdentifier: player.providerPlayerIdentifier,
      onSuccess: this.onComplete,
      onFailure: this.onComplete,
    })
    this.setState({ loading: true })
  }

  private onComplete = () => {
    this.setState({
      loading: false,
    })
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (state) => ({
      organizationCountryId: state.organizationReducer.countryId,
      tournamentId: getTournamentId(state),
    }),
    {
      addPlayerToTournament: tournamentPlayersApi.endpoints.addPlayerToTournament.initiate,
    },
  )(MiniPlayerCard),
)
