import React from 'react'
import { TableFooter, TableRow } from '@mui/material'
import CustomTablePagination from './CustomTablePagination'
import queryString from 'query-string'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'

interface OwnProps {
  count: number
  page: number
}

type Props = OwnProps & WithRouterProps

class DefaultPagedTableFooter extends React.Component<Props> {
  render() {
    return (
      <TableFooter>
        <TableRow>
          <CustomTablePagination
            count={this.props.count}
            page={this.props.page - 1}
            onChangePage={(event, page) => {
              if (event !== null) {
                const params = queryString.parse(this.props.location.search)
                this.props.historyReplace({
                  pathname: this.props.location.pathname,
                  search: queryString.stringify({
                    ...params,
                    page: `${page + 1}`,
                  }),
                })
              }
            }}
          />
        </TableRow>
      </TableFooter>
    )
  }
}

export default withRouter(DefaultPagedTableFooter)
