import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { GGBLogoWideNavDark, GGBLogoWideNavDark2x } from '../../assets/images'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useLocation } from 'react-router-dom'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.customPalette.dark,
      paddingTop: 39,
      paddingBottom: 40,
      zIndex: 2,
      position: 'relative',
    },
    guestRoot: {
      background: 'linear-gradient(180deg, rgba(41, 41, 41, 0.4) 0%, rgba(41, 41, 41, 0) 100%)',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
    },
    wrapper: {
      color: theme.customPalette.footer,
      maxWidth: 600,
      margin: '0 auto',
      padding: '0 24px',
      textAlign: 'center',
    },
    linkList: {
      marginTop: 30,
      listStyle: 'none',
      '@global': {
        li: {
          display: 'inline-block',
          marginRight: 23,
          '&:last-child': {
            marginRight: 0,
          },
        },
        'li a': {
          color: theme.customPalette.mediumGray,
          textDecoration: 'underline',
          fontSize: 14,
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
  })

type Props = WithStyles<typeof styles>

export default withStyles(styles)(({ classes }: Props) => {
  const location = useLocation()

  const isPublicMatchPlayBracketView = (): boolean => {
    const { pathname } = location
    return pathname.includes('/match-play-brackets/') && pathname.includes('/view')
  }

  const isGuestRoute = (): boolean => {
    const { pathname } = location
    const guestRoutes = ['/', '/login', '/reset-password', '/apply']
    return guestRoutes.includes(pathname) || pathname.startsWith('/reset-password')
  }

  const getRootClasses = (): string => {
    if (isGuestRoute()) {
      return `${classes.root} ${classes.guestRoot}`
    }
    return classes.root
  }

  if (isPublicMatchPlayBracketView()) {
    return <></>
  }

  return (
    <footer className={getRootClasses()}>
      <div className={classes.wrapper}>
        <img width={'180px'} alt={'GGB'} src={GGBLogoWideNavDark} srcSet={`${GGBLogoWideNavDark2x} 2x`} />
        <ul className={classes.linkList}>
          <li>
            <a href="https://golfgamebook.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">
              <FormattedMessageWrapper id={'footer.support'} />
            </a>
          </li>
          <li>
            <a href="https://www.golfgamebook.com/Office-terms-of-use" target="_blank" rel="noopener noreferrer">
              <FormattedMessageWrapper id={'footer.termsOfUse'} />
            </a>
          </li>
          <li>
            <a href="https://golfgamebook.com/cookies-policy/" target="_blank" rel="noopener noreferrer">
              <FormattedMessageWrapper id={'footer.cookiePolicy'} />
            </a>
          </li>
          <li>
            <a href="https://www.golfgamebook.com/Office-DPA" target="_blank" rel="noopener noreferrer">
              <FormattedMessageWrapper id={'footer.DPA'} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
})
