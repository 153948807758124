import React, { useEffect, useState } from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { getStorageGolferIds, storageGitResults, storageImportId, storageTempImportId } from '@utils/tournamentUtils'
import {
  useCancelGitImportMutation,
  useConfirmGitImportMutation,
  selectGitImportStatus,
} from '@app/store/api/endpoints/tournamentGitApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  progressBarContainer: {
    marginBottom: 20,
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    justifyContent: 'space-between',
    marginTop: 10,
  },
  table: {
    opacity: 0.5,
  },
  alreadyAddedText: {
    fontSize: 15,
    color: '#747474',
  },
  iconContainer: {
    marginRight: 10,
  },
  tableCell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 400,
    overflow: 'hidden',
  },
  duplicateInfoContainer: {
    marginLeft: 30,
  },
}))

interface Props {
  onClose: (cancel?: boolean) => void
}

export const PlayerListView: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles()

  const tournamentId = useSelector(getTournamentId)

  const taskId = localStorage.getItem(storageImportId) || ''
  const tempTaskId = localStorage.getItem(storageTempImportId) || ''
  const gitImportData = useSelector((state: RootState) =>
    selectGitImportStatus(state, { tournamentId, importId: taskId }),
  )

  const { result = [], progress = 0, alreadyInTournament = [] } = gitImportData || {}
  const [cancelGitImport] = useCancelGitImportMutation()
  const [confirmGitImport] = useConfirmGitImportMutation()

  const [foundPlayerAmount, setFoundAmount] = useState<number>(0)
  const [addedPlayerAmount, setAddedAmount] = useState<number>(0)
  const [notFoundPlayers, setNotFoundPlayers] = useState<ResultList[]>([])
  const [confirmProcessDone, updateConfirmStatus] = useState<boolean>(false)
  const [playerPoolProgress, updateProgressStatus] = useState<boolean>(false)
  const [confirmedPlayerList, setConfirmedPlayerList] = useState<ResultList[]>([])
  const [searchResultData, setData] = useState<ResultList[]>([])

  const [resultState, setResult] = useState<string[]>([])
  const [progressState, setProgress] = useState<number>(0)
  const [alreadyInTournamentState, setAlreadyInTournament] = useState<string[]>([])

  useEffect(() => {
    const results: GitImport = JSON.parse(localStorage.getItem(storageGitResults) || '{}')
    if (!Object.keys(results).length) {
      setResult(result)
      setProgress(progress)
      setAlreadyInTournament(alreadyInTournament)
    }
  }, [result, progress, alreadyInTournament])

  useEffect(() => {
    const results: GitImport = JSON.parse(localStorage.getItem(storageGitResults) || '{}')
    if (Object.keys(results).length) {
      setResult(results.result)
      setProgress(results.progress)
      setAlreadyInTournament(results.alreadyInTournament)
    }
  }, [])

  useEffect(() => {
    const golferIds = getStorageGolferIds()

    if ((resultState || alreadyInTournamentState) && progressState === 100) {
      const searchResultList = createPlayerList(resultState).concat(createPlayerList(alreadyInTournamentState))
      let data: ResultList[] = golferIds.map((value) => {
        if (searchResultList.find((a) => Object.keys(a)[0] === value)) {
          return searchResultList.find((id) => Object.keys(id)[0] === value) || {}
        }
        return { [value]: '' }
      })

      data = data.sort((value) => {
        const id = Object.keys(value)[0]
        if (!golferIds.find((gId) => gId === id) && !value?.added) {
          return -1
        }
        return 1
      })

      setData(data)

      const playerAmount = searchResultList.filter((value) => Object.keys(value)[1]).length
      setFoundAmount(playerAmount)

      const notFoundPlayersList = data.filter((value) => !Object.keys(value)[1])
      setNotFoundPlayers(notFoundPlayersList)
    }
  }, [resultState, progressState, alreadyInTournamentState])

  const handleDone = () => {
    localStorage.clear()
    onClose()
  }

  const handleConfirmResponse = ({ failed = [], alreadyInTournament = [], duplicates = [] }: GitImportConfirm) => {
    const failedList = createPlayerList(failed)
    const alreadyInTournamentList = createPlayerList(alreadyInTournament, true)
    const duplicatesList = createPlayerList(duplicates, false, true)
    const combinedList = failedList.concat(alreadyInTournamentList, notFoundPlayers, duplicatesList)

    //Get amount of added players
    const playerAmount = foundPlayerAmount + notFoundPlayers.length - combinedList.length
    setAddedAmount(playerAmount)

    setConfirmedPlayerList(combinedList)

    updateConfirmStatus(true)
    updateProgressStatus(false)
  }

  const handleAddToPlayerPool = () => {
    updateProgressStatus(true)
    confirmGitImport({ tournamentId, importId: tempTaskId, onComplete: handleConfirmResponse })
  }

  const handleCancelImport = () => {
    cancelGitImport({ tournamentId, importId: tempTaskId })
    onClose(true)
  }

  const createPlayerList = (list, added = false, duplicate = false): ResultList[] =>
    list instanceof Array
      ? list.map((id) => ({
          [id]: list[id] || '',
          added,
          duplicate,
        }))
      : Object.keys(list).map((id) => ({
          [id]: list[id] || '',
          added,
          duplicate,
        }))

  const getTableDataRows = () => {
    if (progressState < 100) {
      return <></>
    }

    const playerNotFound = !!confirmedPlayerList.length
    const dataList = confirmProcessDone ? confirmedPlayerList : searchResultData

    return dataList.map((value, i) => {
      const playerId = Object.keys(value)[0]
      const playerName = value[playerId] || ''
      return (
        <TableRow key={i}>
          <TableCell>{playerId}</TableCell>
          <TableCell className={classes.tableCell}>{playerName}</TableCell>
          <TableCell align="right" size="small">
            {value?.added ? (
              <Typography variant="caption" className={classes.alreadyAddedText}>
                <FormattedMessageWrapper id="tournament.alreadyAddedText" />
              </Typography>
            ) : value.duplicate ? (
              <Typography variant="caption" className={classes.alreadyAddedText}>
                <FormattedMessageWrapper id="tournament.duplicateEmailText" />
              </Typography>
            ) : playerNotFound ? (
              <Typography variant="caption" className={classes.alreadyAddedText}>
                <FormattedMessageWrapper id="tournament.notFound" />
              </Typography>
            ) : (
              <div className={classes.iconContainer}>
                {playerName ? <Check color="primary" /> : <Clear color="error" />}
              </div>
            )}
          </TableCell>
        </TableRow>
      )
    })
  }

  const importProgressText = () => {
    if (!playerPoolProgress && !confirmProcessDone) {
      return progressState < 100 ? (
        <FormattedMessageWrapper id="tournament.searchProgressText" />
      ) : (
        <FormattedMessageWrapper id="tournament.playerSearchCompletedText" />
      )
    }

    return !confirmProcessDone ? (
      <FormattedMessageWrapper id="tournament.gitImportInProgressText" />
    ) : (
      <FormattedMessageWrapper id="tournament.gitImportCompletedText" values={{ amount: addedPlayerAmount }} />
    )
  }

  const progressPercent = () => {
    if (!playerPoolProgress && !confirmProcessDone) {
      return progressState
    }

    return playerPoolProgress ? 0 : 100
  }

  return (
    <>
      <DialogContent>
        <div className={classes.progressBarContainer}>
          <div className={classes.statusContainer}>
            <Typography>{importProgressText()}</Typography>
            <Typography>{progressPercent()}%</Typography>
          </div>
          {!playerPoolProgress ? <LinearProgress variant="determinate" value={progressState} /> : <LinearProgress />}
        </div>
        {confirmProcessDone && (
          <Typography variant="h4">
            <FormattedMessageWrapper id="tournament.failedText" values={{ amount: confirmedPlayerList.length }} />
          </Typography>
        )}
        <Table stickyHeader className={playerPoolProgress ? classes.table : ''}>
          <TableHead>
            <TableRow>
              <TableCell width={135}>
                <FormattedMessageWrapper id="tournament.golfIdText" />
              </TableCell>
              <TableCell>
                <FormattedMessageWrapper id="tournament.name" />
              </TableCell>
              <TableCell align="right" width={180}>
                <FormattedMessageWrapper id="tournament.status" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{progressState === 100 && getTableDataRows()}</TableBody>
        </Table>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        {!confirmProcessDone ? (
          <>
            <Button variant="outlined" onClick={() => onClose()} disabled={playerPoolProgress}>
              <FormattedMessageWrapper id="buttons.hide" />
            </Button>
            <Button color="error" variant="outlined" onClick={handleCancelImport} disabled={playerPoolProgress}>
              <FormattedMessageWrapper id="buttons.cancelImport" />
            </Button>
            <Button
              variant="contained"
              onClick={handleAddToPlayerPool}
              disabled={playerPoolProgress || progressState !== 100}
            >
              <FormattedMessageWrapper id="buttons.addToPlayerPoolText" />
            </Button>
          </>
        ) : (
          <>
            <Button variant="contained" onClick={handleDone} disabled={playerPoolProgress}>
              <FormattedMessageWrapper id="buttons.done" />
            </Button>
            <div className={classes.duplicateInfoContainer}>
              <Typography variant="caption">
                <FormattedMessageWrapper id="tournament.duplicateEmailInfoText" />
              </Typography>
            </div>
          </>
        )}
      </DialogActions>
    </>
  )
}
