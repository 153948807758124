import React, { useState } from 'react'
import { Grid, Button, TextField, Typography } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import makeStyles from '@mui/styles/makeStyles'
import { productName } from '@utils/organizationUtils'
import { DatePicker } from '@app/components/date-pickers/DatePicker'

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'left',
  },
  historyButtonContainer: {
    textAlign: 'left',
  },
  textFieldNormal: {
    width: 400,
  },
  datesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'start',
    gap: 15,
  },
}))

interface OwnProps {
  handleShowSubscriptionHistory(): void
  updateValidFromCallback(validFrom: Date): void
  updateValidToCallback(validTo: Date): void
  updateNotesCallback(notes: string): void
  initialValidFrom?: Date
  initialValidTo?: Date
}

export const SubscriptionAddEditView: React.FC<OwnProps> = ({
  handleShowSubscriptionHistory,
  updateValidFromCallback,
  updateValidToCallback,
  updateNotesCallback,
  initialValidFrom,
  initialValidTo,
}) => {
  const classes = useStyles()

  const [validFrom, setValidFrom] = useState(initialValidFrom)
  const [validTo, setValidTo] = useState(initialValidTo)
  const [notes, setNotes] = useState('')

  const handleOnChangeValidFrom = (date: any) => {
    setValidFrom(date)
    updateValidFromCallback(date)
  }

  const handleOnChangeValidTo = (date: any) => {
    setValidTo(date)
    updateValidToCallback(date)
  }

  const updateNotes = (e) => {
    setNotes(e.target.value)
    updateNotesCallback(e.target.value)
  }

  return (
    <div className={classes.wrap}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary">
            <FormattedMessageWrapper id="organizations.privilegeName" />
          </Typography>
          <TextField className={classes.textFieldNormal} value={productName} disabled />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.datesContainer}>
            <div>
              <Typography variant="body1" color="primary">
                <FormattedMessageWrapper id="organizations.validFrom" />
              </Typography>
              <DatePicker value={validFrom || null} onChange={handleOnChangeValidFrom} units="metric" />
            </div>
            <div>
              <Typography variant="body1" color="primary">
                <FormattedMessageWrapper id="organizations.validTo" />
              </Typography>
              <DatePicker value={validTo || null} onChange={handleOnChangeValidTo} units="metric" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary">
            <FormattedMessageWrapper id="organizations.notes" />
          </Typography>
          <TextField className={classes.textFieldNormal} value={notes} onChange={(e) => updateNotes(e)}></TextField>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.historyButtonContainer}>
            <Button variant="text" onClick={() => handleShowSubscriptionHistory()}>
              <FormattedMessageWrapper id="organizations.showHistory" />
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
