import React from 'react'
import { connect } from 'react-redux'
import { fetchCondensedOrganizations } from '../../store/organizations/actions'
import { Select, FormLabel } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { RootState } from '@app/store'

interface OwnProps {
  margin?: 'normal' | 'none' | 'dense' | undefined
  disabled?: boolean
  organizationId?: number
  onChange?(organizationId?: number): void
}

interface StateProps {
  organizations: CondensedItem[]
}

interface DispatchProps {
  fetchCondensedOrganizations(): void
}

type Props = OwnProps & StateProps & DispatchProps

class OrganizationPicker extends React.Component<Props> {
  componentDidMount(): void {
    const { organizations, fetchCondensedOrganizations } = this.props
    if (!organizations || !organizations.length) {
      fetchCondensedOrganizations()
    }
  }

  render() {
    return (
      <FormControl fullWidth={true} disabled={this.props.disabled} margin={this.props.margin}>
        <FormLabel htmlFor="organization-picker">
          <FormattedMessageWrapper id={'tournament.organization'} />
        </FormLabel>
        <Select
          value={this.props.organizationId || ''}
          onChange={this._handleChange}
          inputProps={{
            name: 'organization',
            id: 'organization-picker',
          }}
          style={{ marginTop: 0 }}
        >
          {this._renderMenuOptions}
        </Select>
      </FormControl>
    )
  }

  get _renderMenuOptions(): React.ReactNode {
    const { organizations } = this.props
    return organizations.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ))
  }

  public _handleChange = (event): void => {
    if (this.props.onChange) {
      if (event.target.value) {
        this.props.onChange(parseInt(event.target.value, 10))
      } else {
        this.props.onChange()
      }
    }
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    organizations: store.organizationsReducer.organizationsCondensed,
  }),
  {
    fetchCondensedOrganizations,
  },
)(OrganizationPicker)
