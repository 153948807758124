import React from 'react'
import { rem } from '@app/theme/materialUITheme'
import TopBar from '@app/components/layout/TopBar'
import ContentWrap from '@app/components/layout/ContentWrap'
import HeadHelmet from '@app/components/layout/HeadHelmet'
import { OrganizationProductsActions } from './OrganizationProductsActions'
import { OrganizationProductsList } from './OrganizationProductsList'
import { Grid } from '@mui/material'
import { OrganizationProductsDialog } from './OrganizationProductsDialog'
import { connect } from 'react-redux'
import {
  createNewOrganizationProduct,
  deleteOrganizationProduct,
  editOrganizationProduct,
  fetchOrganizationProducts,
} from '@app/store/payments/actions'
import { OverlayLoader } from '@app/components/ui/OverlayLoader'
import { PaymentProductsReportDialog } from './PaymentProductsReportDialog'
import { fetchOrganization } from '@app/store/organization/actions'
import { RootState } from '@app/store'

interface StateProps {
  products: OrganizationProduct[]
  loading: boolean
  auth: AuthenticationState
  organization: OrganizationState
}

interface DispatchProps {
  createNewOrganizationProduct(product: OrganizationProduct): void
  editOrganizationProduct(product: OrganizationProduct): void
  deleteOrganizationProduct(product: OrganizationProduct): void
  fetchOrganizationProducts(organizationId: number): void
  fetchOrganization: (id: number, force?: boolean, onComplete?: () => void) => void
}

type OrganizationProductsProps = StateProps & DispatchProps

const OrganizationProductsComponent: React.FC<OrganizationProductsProps> = ({
  createNewOrganizationProduct,
  fetchOrganizationProducts,
  editOrganizationProduct,
  deleteOrganizationProduct,
  fetchOrganization,
  loading,
  products,
  auth,
  organization,
}) => {
  const [activeProduct, setActiveProduct] = React.useState<OrganizationProduct | undefined>(undefined)
  const [openCreateNewDialog, setOpenCreateNewDialog] = React.useState<boolean>(false)
  const [openReportsDialog, setOpenReportsDialog] = React.useState<boolean>(false)

  React.useEffect(() => {
    const { organizationId } = auth.roleInfo
    organizationId && fetchOrganization(organizationId, false, () => fetchOrganizationProducts(organizationId))
  }, [auth.roleInfo, fetchOrganization, fetchOrganizationProducts])

  if (!organization?.paymentEnabled) {
    return null
  }

  const openCreateNewDialogFn = () => {
    setOpenCreateNewDialog(true)
  }

  const closeCreateNewDialogFn = () => {
    setOpenCreateNewDialog(false)
    setActiveProduct(undefined)
  }

  const openReportsDialogFn = () => {
    setOpenReportsDialog(true)
  }

  const closeReportsDialogFn = () => {
    setOpenReportsDialog(false)
  }

  const saveFn = (product: OrganizationProduct) => {
    activeProduct ? editOrganizationProduct(product) : createNewOrganizationProduct(product)
    closeCreateNewDialogFn()
  }

  const editFn = (product: OrganizationProduct) => {
    setActiveProduct(product)
    openCreateNewDialogFn()
  }

  const deleteFn = (product: OrganizationProduct) => {
    deleteOrganizationProduct(product)
    closeCreateNewDialogFn()
  }

  return (
    <>
      <HeadHelmet titleId={'payments.payments'} />
      <TopBar titleId="payments.payments" />
      <OverlayLoader visible={loading} />

      <ContentWrap style={{ paddingBottom: rem(80) }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OrganizationProductsActions
              openCreateNewDialogFn={openCreateNewDialogFn}
              openReportsDialogFn={openReportsDialogFn}
            />
            {openCreateNewDialog && (
              <OrganizationProductsDialog
                open={openCreateNewDialog}
                saveFn={saveFn}
                deleteFn={deleteFn}
                closeCreateNewDialogFn={closeCreateNewDialogFn}
                product={activeProduct}
              />
            )}
            <PaymentProductsReportDialog open={openReportsDialog} closeReportsDialogFn={closeReportsDialogFn} />
          </Grid>
          <Grid item xs={12}>
            <OrganizationProductsList products={products} editFn={editFn} deleteFn={deleteFn} />
          </Grid>
        </Grid>
      </ContentWrap>
    </>
  )
}

export const OrganizationProducts = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => ({
    products: state.paymentsReducer.organizationProducts,
    loading: state.paymentsReducer.loading,
    auth: state.authenticationReducer,
    organization: state.organizationReducer,
  }),
  {
    createNewOrganizationProduct,
    editOrganizationProduct,
    deleteOrganizationProduct,
    fetchOrganizationProducts,
    fetchOrganization,
  },
)(OrganizationProductsComponent)
