import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const styles = (theme: Theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
})

const options = {
  h1: (props) => <Typography gutterBottom={true} variant="h4" {...props} />,
  h2: (props) => <Typography gutterBottom={true} variant="h6" {...props} />,
  h3: (props) => <Typography gutterBottom={true} variant="subtitle1" {...props} />,
  h4: (props) => <Typography gutterBottom={true} variant="caption" paragraph={true} {...props} />,
  p: (props) => <Typography style={{ marginBottom: '1em', whiteSpace: 'pre-wrap' }} variant={'body1'} {...props} />,
  a: Link,
  ul: ({ children, ...rest }) => (
    <ul
      style={{
        listStyleType: 'circle',
        marginLeft: 20,
        marginBottom: '1em',
      }}
      {...rest}
    >
      {children}
    </ul>
  ),
  ol: ({ children, ...rest }) => (
    <ol
      style={{
        listStyleType: 'decimal',
        marginLeft: 20,
        marginBottom: '1em',
        fontFamily: 'Roboto',
      }}
      {...rest}
    >
      {children}
    </ol>
  ),
  li: withStyles(styles)(({ classes, ...props }: any) => (
    <li className={classes.listItem}>
      <Typography variant={'body1'} component={'span'} {...props} />
    </li>
  )),
}

export default function Markdown(props) {
  // Replaces ´\t´ with &ensp;. &ensp; = 2 spaces
  const content = props.children.replace(/\t/g, '&ensp;')
  return <ReactMarkdown children={content} components={options} remarkPlugins={[remarkGfm]} />
}
