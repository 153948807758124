import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { addScheduleEntry, removeScheduleEntry } from '@store/api/thunks/tournamentSiteThunks'
import { Typography } from '@mui/material'
import ScheduleEntryBlock from './ScheduleEntryBlock'
import AddRowAction from '../ui/AddRowAction'
import { RootState } from '@app/store'

interface OwnProps {
  tournamentSchedule: Array<ScheduleEntry[]>
  round: number
  tournament: TournamentState
  updateAction: (payload: FieldUpdatePayload) => void
}

interface DispatchProps {
  addScheduleEntry: (round: number) => void
  removeScheduleEntry: (index: number, round: number) => void
}

type Props = OwnProps & DispatchProps & WrappedComponentProps

interface State {
  expanded: boolean
  customizationModalOpen: boolean
  scheduleUpdated: boolean
}

class UnconnectedSchedule extends React.Component<Props, State> {
  readonly state: State = {
    expanded: false,
    customizationModalOpen: false,
    scheduleUpdated: false,
  }

  render() {
    return (
      <>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          <FormattedMessageWrapper id="tournament.tournamentSchedule" />
        </Typography>

        {this.scheduleEntries}
      </>
    )
  }

  private _addNewScheduleEntry = () => {
    this.props.addScheduleEntry(this.props.round)
  }

  private _removeScheduleEntry = (idx: number): void => {
    this.props.removeScheduleEntry(idx, this.props.round)
  }

  private get scheduleEntries() {
    const roundEntries: ScheduleEntry[] = this.props.tournamentSchedule[this.props.round]
    if (!roundEntries) {
      return null
    }

    return (
      <>
        {roundEntries.map((entry, index) => (
          <ScheduleEntryBlock
            index={index}
            key={`${entry.hours}${entry.minutes}${index}`}
            scheduleEntry={entry}
            onRemoveClick={this._removeScheduleEntry}
            round={this.props.round}
          />
        ))}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: 30,
          }}
        >
          <AddRowAction
            onClick={this._addNewScheduleEntry}
            label={<FormattedMessageWrapper id="tournament.addNewScheduleEntry" />}
          />
        </div>
      </>
    )
  }
}

const Schedule = injectIntl(UnconnectedSchedule)

export default connect<{}, DispatchProps, OwnProps, RootState>(null, {
  addScheduleEntry,
  removeScheduleEntry,
})(Schedule)
