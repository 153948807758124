import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SectionTitle from '@components/ui/SectionTitle'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import {
  FormControl,
  Switch,
  FormLabel,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Button,
  Theme,
} from '@mui/material'
import BorderButton from '@components/ui/BorderButton'
import { YesOrNo, formTournamentSiteUrl } from '@config/index'
import SelectBox from '@components/ui/SelectBox'
import { OpenLinkIcon, ShareIcon } from '@app/assets/icons'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  leftMargin: {
    marginLeft: theme.spacing(2),
  },
  rightMargin: {
    marginRight: theme.spacing(2),
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
  siteLinkButton: {
    padding: '0 20px',
    height: 40,
    fontWeight: 500,
    marginRight: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,

    '&:hover': {
      borderWidth: 1,
    },
  },
  linkCopiedMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: 64,
    backgroundColor: '#222A35',
    color: '#fff',
    borderRadius: 10,
    padding: 20,
    position: 'absolute',
    top: 60,
    right: 15,

    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  tournamentLinkActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
}))

export interface TournamentEnabledSwitchProps {
  tournamentSiteEnabled: boolean
  onEnableTournamentSite: (value: boolean) => void
  classes: Record<string, string>
}

export const TournamentEnabledSwitch: React.FC<TournamentEnabledSwitchProps> = ({
  tournamentSiteEnabled,
  onEnableTournamentSite,
  classes,
}) => (
  <SectionTitle>
    <FormattedMessageWrapper id="tournament.tournamentSiteContent" />
    <FormControl className={classes.leftMargin}>
      <Switch
        checked={tournamentSiteEnabled}
        onChange={() => onEnableTournamentSite(!tournamentSiteEnabled)}
        name="tournamentSiteEnabled"
        color="primary"
      />
    </FormControl>
  </SectionTitle>
)

export interface OpenTournamentSiteLinkProps {
  id: number
  classes: Record<string, string>
}

export const OpenTournamentSiteLink: React.FC<OpenTournamentSiteLinkProps> = ({ id, classes }) => (
  <FormControl className={classes.bottomMargin}>
    <BorderButton
      buttonProps={{ href: formTournamentSiteUrl(id), target: '_blank', className: classes.siteLinkButton }}
    >
      <OpenLinkIcon className={classes.rightMargin} />
      <FormattedMessageWrapper id={'tournament.previewTournamentSite'} />
    </BorderButton>
  </FormControl>
)

export interface ShareTournamentSiteLinkProps {
  id: number
  classes: Record<string, string>
}

export const ShareTournamentSiteLink: React.FC<ShareTournamentSiteLinkProps> = ({ id, classes }) => {
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)

  const handleCopy = () => {
    setIsLinkCopied(true)
    setTimeout(() => {
      setIsLinkCopied(false)
    }, 2500)
  }

  const renderLinkCopiedMessage = () => {
    return (
      <div className={classes.linkCopiedMessage}>
        <CheckCircle className={classes.rightMargin} />
        <FormattedMessageWrapper id={'strings.linkCopied'} />
      </div>
    )
  }

  return (
    <FormControl className={classes.bottomMargin}>
      <CopyToClipboard text={formTournamentSiteUrl(id)} onCopy={handleCopy}>
        <Button variant="outlined" className={classes.siteLinkButton}>
          <ShareIcon className={classes.rightMargin} />
          <FormattedMessageWrapper id={'buttons.share'} />
        </Button>
      </CopyToClipboard>
      {isLinkCopied && renderLinkCopiedMessage()}
    </FormControl>
  )
}

interface TournamentSiteActionButtonsProps {
  id: number
}

export const TournamentSiteActionButtons: React.FC<TournamentSiteActionButtonsProps> = ({ id }) => {
  const classes = useStyles()
  return (
    <div className={classes.tournamentLinkActions}>
      <OpenTournamentSiteLink id={id} classes={classes} />
      <ShareTournamentSiteLink id={id} classes={classes} />
    </div>
  )
}

export interface CountryFlagsSwitchProps {
  updateTournamentStateSwitch: (event: SelectChangeEvent<HTMLSelectElement>) => void
  countryFlagForPlayersEnabled: boolean
  classes: Record<string, string>
}

export const CountryFlagsSwitch: React.FC<CountryFlagsSwitchProps> = ({
  updateTournamentStateSwitch,
  countryFlagForPlayersEnabled,
  classes,
}) => (
  <FormControl className={`${classes.leftMargin} ${classes.topMargin}`}>
    <FormLabel>
      <FormattedMessageWrapper id="tournament.tournamentCountryFlagsEnabled" />
    </FormLabel>
    <SelectBox
      id="countryFlagForPlayersEnabled"
      name="countryFlagForPlayersEnabled"
      onChange={updateTournamentStateSwitch}
      value={countryFlagForPlayersEnabled ? YesOrNo.YES : YesOrNo.NO}
      inputElement={OutlinedInput}
      style={{ width: 200 }}
    >
      <MenuItem value={YesOrNo.YES}>
        <FormattedMessageWrapper id="options.yes" />
      </MenuItem>
      <MenuItem value={YesOrNo.NO}>
        <FormattedMessageWrapper id="options.no" />
      </MenuItem>
    </SelectBox>
  </FormControl>
)

export interface NameDisplayModeDropdownProps {
  onNameDisplayModeChange: (event: SelectChangeEvent<any>) => void
  nameDisplayMode: string | undefined
  classes: Record<string, string>
}

export const NameDisplayModeDropdown: React.FC<NameDisplayModeDropdownProps> = ({
  onNameDisplayModeChange,
  nameDisplayMode,
  classes,
}) => (
  <FormControl className={classes.topMargin}>
    <FormLabel>
      <FormattedMessageWrapper id="tournament.nameDisplayMode" />
    </FormLabel>
    <SelectBox
      id="nameDisplayMode"
      name="nameDisplayMode"
      onChange={onNameDisplayModeChange}
      value={nameDisplayMode || 'full'}
      inputElement={OutlinedInput}
      style={{ width: 200 }}
    >
      <MenuItem value="full">First Last</MenuItem>
      <MenuItem value="init-first">F. Last</MenuItem>
      <MenuItem value="init-last">First L.</MenuItem>
      <MenuItem value="init-both">F.L.</MenuItem>
    </SelectBox>
  </FormControl>
)
