export enum CacheTag {
  MATCH_PLAY_BRACKET = 'match-play-bracket',
  MATCH_PLAY_BRACKET_SINGLE = 'match-play-bracket-single',
  TOURNAMENT_SINGLE = 'tournament-single',
  TOURNAMENT_LIST = 'tournament-list',
  TOURNAMENT_SETTINGS = 'tournament-settings',
  TOURNAMENT_SITE = 'tournament-site',
  TOURNAMENT_PLAYERS = 'tournament-players',
  TOURNAMENT_TEAMS = 'tournament-teams',
  TOURNAMENT_CONTESTS = 'tournament-contests',
  TOURNAMENT_STARTLISTS = 'tournament-startlists',
  TOURNAMENT_WEEKLY_SCORES = 'tournament-weekly-scores',
  COURSES = 'courses',
}

export interface CacheTagSingle {
  type: CacheTag
  id: number
}
