export const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
    <g clipPath="url(#clip0_1912_1309)">
      <path
        fill="#fff"
        // eslint-disable-next-line max-len
        d="M8.5 3.642a1.07 1.07 0 11.001 2.14 1.07 1.07 0 010-2.14zm1.977 9.304H6.854a.659.659 0 010-1.317h1.153V8.006h-.659a.659.659 0 110-1.318h1.318a.659.659 0 01.658.66v4.28h1.153a.658.658 0 110 1.318z"
      ></path>
      <circle cx="8.5" cy="8.5" r="7.57" stroke="#fff" strokeWidth="1.328"></circle>
    </g>
    <defs>
      <clipPath id="clip0_1912_1309">
        <path fill="#fff" d="M0 0H17V17H0z"></path>
      </clipPath>
    </defs>
  </svg>
)
