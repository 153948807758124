import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

const styles = () =>
  createStyles({
    fileInput: {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: 'hidden',
      position: 'absolute',
      zIndex: -1,
    },
  })

interface FilePickerProps extends WithStyles<typeof styles> {
  id: string
  name?: string
  accept?: string
  multiple?: boolean
  children?: any
  disabled?: boolean
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
}

const FilePicker = ({
  id,
  name = id,
  accept = '*/*',
  multiple = false,
  classes,
  children,
  disabled = false,
  onChange,
}: FilePickerProps) => (
  <>
    <label htmlFor={id}>{children}</label>
    {!disabled && (
      <input
        accept={accept}
        name={name}
        id={id}
        className={classes.fileInput}
        type="file"
        onChange={onChange}
        multiple={multiple}
        onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
          e.currentTarget.value = ''
        }}
      />
    )}
  </>
)

export default withStyles(styles)(FilePicker)
