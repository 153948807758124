import React from 'react'
import { FormControl, FormGroup, FormLabel, Grid, MenuItem, Switch, TextField } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import SelectBox from '../ui/SelectBox'
import RemoveRowAction from '../ui/RemoveRowAction'
import { AnswerTypes } from '../../store/api/enums/tournamentEnums'
import InfoTooltip from '../ui/InfoTooltip'

interface Props {
  index: number
  question: Question
  updateCustomQuestionField: (payload: UpdateRoundFieldPayload) => void
  deleteCustomQuestion: (id: number) => void
}

class UnconnectedCustomQuestion extends React.Component<Props & WrappedComponentProps> {
  onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.updateCustomQuestionField({
      index: this.props.index,
      fieldName: e.currentTarget.name,
      value: e.currentTarget.value,
    })
  }

  onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.currentTarget.name
    const value = e.currentTarget.checked

    this.props.updateCustomQuestionField({
      index: this.props.index,
      fieldName,
      value,
    })
  }

  onSelectChange = (event) => {
    const fieldName = event.target.name
    const value = event.target.value

    if (fieldName) {
      this.props.updateCustomQuestionField({
        index: this.props.index,
        fieldName,
        value,
      })
    }
  }

  onDeleteQuestion = () => {
    this.props.deleteCustomQuestion(this.props.question.id)
  }

  render() {
    const {
      question: { question, mustAnswer, answerType, questionNumber },
      intl,
      index,
    } = this.props

    return (
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6}>
          <FormGroup>
            <TextField
              id="question"
              name="question"
              label={intl.formatMessage({ id: 'tournament.question' }, { questionNumber })}
              value={question}
              onChange={this.onChange}
              margin="normal"
              autoComplete={'off'}
            />
          </FormGroup>
        </Grid>
        <Grid item={true} xs={3}>
          <FormGroup>
            <FormControl className="SwitchControl" style={{ paddingTop: 17 }}>
              <Switch
                checked={mustAnswer}
                onChange={this.onCheckboxChange}
                name="mustAnswer"
                value="true"
                color="primary"
              />
              <FormLabel className="SwitchLabel" style={{ display: 'flex', alignItems: 'center' }}>
                <FormattedMessageWrapper id="tournament.mustAnswer" />
                <InfoTooltip
                  size={'small'}
                  style={{ marginLeft: 10 }}
                  text={<FormattedMessageWrapper id="tournament.mustAnswerInfo" />}
                />
              </FormLabel>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item={true} xs={2}>
          <SelectBox
            labelKey="tournament.answerType"
            value={answerType}
            onChange={this.onSelectChange}
            id="answerType"
            name="answerType"
          >
            <MenuItem value={AnswerTypes.YES_NO}>
              <FormattedMessageWrapper id={`tournament.answerTypeYesNo`} />
            </MenuItem>
            <MenuItem value={AnswerTypes.FREE_TEXT}>
              <FormattedMessageWrapper id={`tournament.answerTypeFreeText`} />
            </MenuItem>
          </SelectBox>
        </Grid>
        <Grid item={true} xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <RemoveRowAction index={index} onClick={this.onDeleteQuestion} />
        </Grid>
      </Grid>
    )
  }
}

const CustomQuestion = injectIntl(UnconnectedCustomQuestion)

export default CustomQuestion
