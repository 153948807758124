import React from 'react'
import { Typography } from '@mui/material'

const SectionTitle = ({
  children,
  style,
  className,
  color,
}: {
  children: any
  style?: React.CSSProperties
  className?: string
  color?: any
}) => (
  <Typography
    variant="h2"
    color={color}
    className={className}
    style={{
      minHeight: 30,
      marginBottom: 10,
      ...style,
    }}
  >
    {children}
  </Typography>
)

export default SectionTitle
