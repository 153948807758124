import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.customPalette.pageBackground,
    overflow: 'hidden',
    minHeight: '100%',
  },
}))

interface Props {
  children: React.ReactNode
}

export const PageWrapper: React.FC<Props> = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}
