import React, { useRef } from 'react'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { FormattedMessageWrapper } from '../ui/FormattedMessageWrapper'
import { MatchPlayBracketPlayer } from './MatchPlayBracketPlayer'
import { useCardStyles } from './cardStyles'
import { useBracketCardDistances } from '@app/hooks/useBracketCardDistances'

interface Props {
  player: MatchPlayBracketPlayer | null
  players: MatchPlayBracketPlayer[]
  index: number
  stage: MatchPlayBracketStage
  stages: MatchPlayBracketStage[]
}

export const MatchPlayBracketCardPublic: React.FC<Props> = ({ player, players, index, stage, stages }) => {
  const classes = useCardStyles()
  const elementRef = useRef<HTMLDivElement | null>(null)
  useBracketCardDistances(elementRef, stage, stages)

  const isFinalOrBronzeMatch: boolean =
    MatchPlayBracketStages.FINAL === stage.stage || MatchPlayBracketStages.BRONZE_MATCH === stage.stage

  const renderPlayer = (): React.ReactElement => {
    return player ? <MatchPlayBracketPlayer player={player} players={players} /> : <></>
  }

  const renderEmptyCard = (): React.ReactElement => {
    return (
      <div className={classes.emptyCard}>
        <div></div>
      </div>
    )
  }

  const getFinalOrBronzeTitleKey = (): string => {
    return stage.stage === MatchPlayBracketStages.FINAL
      ? `matchPlayBracket.${MatchPlayBracketStages.FINAL}`
      : `matchPlayBracket.${MatchPlayBracketStages.BRONZE_MATCH}`
  }

  const getFinalOrBronzeClass = (): string => {
    return stage.stage === MatchPlayBracketStages.FINAL ? classes.finalTitle : classes.bronzeTitle
  }

  const getElementClass = (): string => {
    return stages[0]?.visible === false ? 'first-stage-hidden' : ''
  }

  return (
    <div ref={elementRef} className={getElementClass()}>
      {isFinalOrBronzeMatch && index === 0 && (
        <div className={getFinalOrBronzeClass()}>
          <h3>
            <FormattedMessageWrapper id={getFinalOrBronzeTitleKey()} />
          </h3>
          <span>{stage.description}</span>
        </div>
      )}
      {player ? renderPlayer() : renderEmptyCard()}
    </div>
  )
}
