import { isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { enqueueNotification } from '../../notifications/actions'

export const notificationsMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const clientMessage = action.meta?.arg?.originalArgs?.errorMessage
    const serverMessage =
      action.payload?.data?.status ||
      action.payload.data?.error?.message ||
      action.payload.data?.error ||
      action.error?.message ||
      'Something went wrong'
    const message = clientMessage || serverMessage
    api.dispatch(enqueueNotification(message, 'error'))
  }
  if (isFulfilled(action)) {
    const message = action.meta?.arg?.originalArgs?.successMessage
    if (message) {
      api.dispatch(enqueueNotification(message, 'success'))
    }
  }
  return next(action)
}
