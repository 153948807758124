import React from 'react'
import { debounce } from 'lodash'
import isEmail from 'validator/lib/isEmail'

export type ErrorInputs = { [inputName: string]: boolean }
export type InputValue = any

type ChildrenArgs = {
  hasErrors: () => boolean
  errorInputs: ErrorInputs
  validateThenApply: (fn: () => any) => any
  validateValues: () => any
}

interface Props {
  values: { [inputName: string]: InputValue }
  options?: { [inputName: string]: any }
  children: (args: ChildrenArgs) => any
}

type State = {
  errorInputs: ErrorInputs
  onClickValidPassed: boolean
}

class InputsValidator extends React.Component<Props, State> {
  readonly state: State = {
    errorInputs: {},
    onClickValidPassed: false,
  }

  render() {
    return this.props.children({
      hasErrors: this._hasErrors,
      errorInputs: this.state.errorInputs,
      validateThenApply: this._validateThenApply,
      validateValues: this._validateValues,
    })
  }

  private _validateThenApply = (fn: () => any) => {
    this.setState(
      { onClickValidPassed: true },
      this._validateValues.bind(this, () => {
        if (this._hasErrors()) {
          window.console.warn('invalid inputs')
          return window.console.table(this.state.errorInputs)
        }

        return fn()
      }),
    )
  }

  private _validateValues = debounce((fn?: () => any) => {
    const { onClickValidPassed } = this.state
    const { values, options = {} } = this.props
    const errorInputs: ErrorInputs = {}

    if (onClickValidPassed) {
      Object.keys(values).forEach((valueKey: string) => {
        // option test is priority
        if (options[valueKey] && this._valueTestFails(valueKey)) {
          errorInputs[valueKey] = true
        } else if (values[valueKey] === undefined || values[valueKey] === '' || values[valueKey] === null) {
          errorInputs[valueKey] = true
        }
      })
    }

    this.setState({ errorInputs }, fn)
  }, 40)

  private _hasErrors = (): boolean => {
    return Boolean(Object.keys(this.state.errorInputs).length)
  }

  private _valueTestFails = (valueKey: string): boolean => {
    const { options = {}, values } = this.props
    switch (options[valueKey]) {
      case 'email':
        return !isEmail(values[valueKey])
      default:
        console.warn('no-validation-for-option-type', options[valueKey])
        return false
    }
  }
}

export default InputsValidator
