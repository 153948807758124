import React from 'react'
import ContentWrap from '../layout/ContentWrap'
import ProgressBar from './ProgressBar'
import { Theme } from '@mui/material/styles'

import withTheme from '@mui/styles/withTheme'

interface ThemeProps {
  theme: Theme
}

class TournamentFlowProgress extends React.PureComponent<ThemeProps, {}> {
  render() {
    return (
      <>
        <ContentWrap
          style={{
            padding: '5px 0px 10px 0',
            margin: '0 10px',
            position: 'sticky',
            top: 0,
            zIndex: 2,
            background: this.props.theme.customPalette.bodyBackground,
          }}
        >
          <ProgressBar />
        </ContentWrap>
      </>
    )
  }
}

export default withTheme(TournamentFlowProgress as any)
