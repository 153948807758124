import { createSelector } from 'reselect'

/*
  - Filter tees by gender.
 */
const sanitizeCourseTees = (courseTees: CourseTee[]): CourseTee[] =>
  // courseTees.filter(tee => tee.gender === 'male')
  courseTees

export const validMaleTeeBoxes = createSelector(sanitizeCourseTees, (courseTees: CourseTee[]) =>
  courseTees.filter((tee) => tee.crMen > 0 && tee.slopeMen > 0),
)

export const validFemaleTeeBoxes = createSelector(sanitizeCourseTees, (courseTees: CourseTee[]) =>
  courseTees.filter((tee) => tee.crWomen > 0 && tee.slopeWomen > 0),
)

export const isGIR = (strokes: number, putts: number, par): boolean => strokes - putts <= par - 2

/**
 * Get default male and female tee ids
 * @param maleTees CourseTee[]
 * @param femaleTees CourseTee[]
 * @returns object with maleTeeBoxId and femaleTeeBoxId
 */
export const getDefaultTeeBoxIds = (
  maleTees: CourseTee[],
  femaleTees: CourseTee[],
): { maleTeeBoxId: number; femaleTeeBoxId: number } => {
  /*
    - Use second value as default male tee id, if array is not too short
    - Return last value for default female tee id
  */
  let maleTeeBoxId = 0

  if (maleTees.length) {
    maleTeeBoxId = maleTees.length <= 2 ? maleTees[0].id : maleTees[1].id
  }

  const femaleTeeBox = femaleTees.pop()
  const femaleTeeBoxId = femaleTeeBox?.id || 0

  return {
    maleTeeBoxId,
    femaleTeeBoxId,
  }
}
