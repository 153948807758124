import {
  AnswerTypes,
  CapOverflowMethods,
  ColorTheme,
  EntryMethods,
  TieBreakMethod,
  TournamentTypes,
} from './enums/tournamentEnums'
import { createDate } from '@app/utils/dates'

export const emptyGameOptions: GameFormatOptions = {
  useHandicaps: true,
  usePlayingHandicaps: true,
  usePerPlayerHandicapPercentages: false,
  limitToLowestPhcp: false,
  perPlayerHandicapPercentages: ['20', '15', '10', '5'], // Should be same as in store/gameFormats/reducer
  handicapPercentage: 100,
  primaryTieBreakMethod: TieBreakMethod.handicap,
  showTiedPositions: false,
}

export const emptyContestOptions: ContestOptions = {
  closestToPin: [],
  longestDrive: [],
  straightestDrive: [],
  measureResultsLongestDrive: false,
  measureResultsStraightestDrive: false,
}

export const emptyGroupStartTees = Array.from({ length: 18 }).map(() => 0)

export const emptyStartListRound: StartListRound = {
  id: -1,
  tournamentRoundId: -1,
  teeInterval: 10,
  basicGroupSize: 4,
  startType: '1stHole',
  fillStartListByType: 'poolOrder',
  groups: [],
  groupStartTees: [...emptyGroupStartTees],
}

export const emptyRoundConfig: Round = {
  clubId: { value: 0, label: '' },
  completed: false,
  contestOptions: { ...emptyContestOptions },
  contestsEnabled: false,
  countryId: 0,
  courseId: 0,
  defaultStartingHole: { id: null, holeNumber: 1 },
  endTime: createDate({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0, addDays: 7 }),
  eventRoundId: 0,
  genericGameOptions: {},
  hideResultsFromLastHoles: 0,
  holes: '18',
  id: -1,
  menTeeBoxId: 0,
  primaryGameId: 2,
  primaryGameOptions: { ...emptyGameOptions },
  scoringStarted: false,
  sideGameEnabled: false,
  sideGameId: 1,
  sideGameOptions: { ...emptyGameOptions },
  startTime: createDate({ hours: 10, minutes: 0 }),
  status: {
    isCompleted: false,
    isConfigured: false,
    isScoringDisabled: true,
  },
  teeBoxMen: undefined,
  teeBoxWomen: undefined,
  womenTeeBoxId: 0,
  roundNumber: 1,
}

export const emptyTournament: TournamentState = {
  id: 0,
  divisions: [],
  startTime: createDate({ hours: 10, minutes: 0 }),
  endTime: createDate({ hours: 23, minutes: 59, seconds: 0, milliseconds: 0, addDays: 7 }),
  hcpRound: false,
  isTeamFormat: false,
  isTwoManTeamFormat: false,
  name: '',
  public: true,
  resultsPublished: false,
  rounds: [{ ...emptyRoundConfig }],
  tournamentSiteEnabled: false,
  tournamentType: TournamentTypes.singleRound,
  internalNote: '',
  divisionsEnabled: false,
  individualHcpIndexLimitEnabled: false,
  individualHcpIndexLimit: -36,
  weeklyRounds: 1,
}

export const emptyQuestion = {
  id: 0,
  question: '',
  mustAnswer: false,
  answerType: AnswerTypes.YES_NO,
}

export const emptyAds = {
  leaderboardAd: undefined,
  leaderboardIcon: undefined,
  postHoleAds: [],
}

export const emptyScheduleEntry = {
  hours: 10,
  minutes: 0,
  description: '',
}

export const emptyRanking = {
  name: '',
  active: true,
  logo: undefined,
  configTemplate: [
    { position: 1, points: 10 },
    { position: 2, points: 7 },
    { position: 3, points: 5 },
    { position: 4, points: 3 },
    { position: 5, points: 2 },
    { position: 6, points: 1 },
  ],
}

export const emptyTour = {
  name: '',
  info: undefined,
  logo: undefined,
  hero: undefined,
  sponsorLogos: [],
  ads: [],
  rankingIds: [],
  createdAt: undefined,
  updatedAt: undefined,
}

export const emptyTournamentSite: TournamentSite = {
  id: -1,
  registrationModuleEnabled: false,
  calendarPublished: false,
  tournamentInvitationText: undefined,
  invitationFile: undefined,
  termsAndConditionsUrl: undefined,
  primaryColor: '#FFF',
  colorTheme: ColorTheme.LIGHT,
  enabled: false,
  countryFlagForPlayersEnabled: false,
  showInstructions: false,
  tournamentSchedule: [],
  tournamentInformationText: [],
  tournamentSummaryText: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  images: {
    logoImage: undefined,
    heroImage: undefined,
    ads: [],
    sponsorLogos: [],
    summaryPictures: [],
  },
  nameDisplayMode: '',
  summaryFile: null,
  maximumPlayerAmount: 100,
  maximumTeamAmount: 20,
  reserveListEnabled: false,
  onlineEntriesEnabled: false,
  entryMethod: EntryMethods.INDIVIDUAL,
  customQuestionsEnabled: false,
  maximumCapOverflowMethod: CapOverflowMethods.RESERVE_LIST,
  maximumEntryAmountEnabled: false,
  registerAndCancelTimeFramesEnabled: false,
  teeboxSelectionEnabled: false,
  customQuestions: [],
  ads: {
    leaderboardAd: [],
    leaderboardLogo: [],
    postHoleAds: [],
  },
  manualDivisionSelectionEnabled: false,
  manualDivisionSelectionRequired: false,
  paymentEnabled: false,
  allowPostponePayment: false,
  postponePaymentNote: '',
  termsAndConditions: '',
  partialSignupEnabled: false,
}

export const emptyTournamentSettings: TournamentSettings = {
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  cutEnabled: false,
  roundsBeforeCut: 1,
  playersThroughCut: 1,
  defaultTeamSize: 4,
}
