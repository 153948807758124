import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { router } from '@app/App'

export const redirectionMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const status = action.payload?.status || action.payload?.originalStatus

  if (isRejectedWithValue(action) && status === 401) {
    api.dispatch({ type: 'AUTH_LOGOUT' })
    router.navigate('/')
  }
  if (isRejectedWithValue(action) && status === 403) {
    router.navigate('/dashboard')
  }
  const isTournamentRequest = action.meta?.arg?.endpointName === 'getTournament'
  if (isRejectedWithValue(action) && status === 404 && isTournamentRequest) {
    router.navigate('/tournaments')
  }

  return next(action)
}
