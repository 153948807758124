import React from 'react'
import { connect } from 'react-redux'
import { Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'

import { NoTeams } from './NoTeams'
import Groups from '@app/components/tournament/Groups'
import SectionTitle from '@app/components/ui/SectionTitle'
import { EditPlayerDialogWrapper } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import UnassignedPlayers from '@app/components/tournament/UnassignedPlayers'
import { Teams } from './Teams'

import { getUnassignedTeamPlayers } from '@app/store/api/selectors/tournamentStartListsSelectors'
import { PlayerPoolEditContext } from '../../enums'
import { selectTournamentTeams, tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournamentPlayers } from '@app/store/api/endpoints/tournamentPlayersApi'
import { RootState } from '@app/store'
import { getTournamentId } from '@app/store/api/slices/configSlice'

interface StateProps {
  unassignedPlayers: TournamentPlayer[]
  teams: TournamentTeam[]
  players: TournamentPlayer[]
  tournamentId: number
}

interface DispatchProps {
  addNewTeam(payload: AddTeamPayload): void
  addNewTeams(payload: AddTeamsPayload): void
  updateTeam(args: UpdateTeamPayload): void
  addPlayerToTeam(args: EditTeamPlayerPayload): void
  removePlayerFromTeam(args: EditTeamPlayerPayload): void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '10px',
      position: 'relative',
    },
    contentWrapper: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: '16px',
      backgroundColor: theme.customPalette.darkGreen,
    },
    unassignedPoolContainer: {
      border: '2px solid #1AA85D',
      borderRadius: '5px',
    },
    sectionContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    sectionTitle: {
      color: theme.palette.common.white,
    },
  })

class PlayerPoolTeamComponent extends React.Component<Props> {
  render() {
    const { classes, unassignedPlayers, teams } = this.props

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={classes.contentWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={7} className={classes.sectionContainer}>
              {/**
               * Teams component
               */}
              <div className={classes.headerContainer}>
                <SectionTitle className={classes.sectionTitle} style={{ margin: '0 0 0 20px' }}>
                  <FormattedMessageWrapper id="options.teams" />
                </SectionTitle>
              </div>
              <Groups style={{ backgroundColor: '#136042' }}>
                {teams.length > 0 ? (
                  <Teams createTeam={this.createTeam} />
                ) : (
                  <NoTeams createTeams={this.createTeams} createTeam={this.createTeam} />
                )}
              </Groups>
            </Grid>
            <Grid item xs={5} xl={5} className={classes.sectionContainer}>
              {/**
               * Player pool component
               */}
              <SectionTitle className={classes.sectionTitle}>
                <FormattedMessageWrapper
                  id="tournament.unassignedPlayers"
                  values={{
                    value: unassignedPlayers.length,
                  }}
                />
              </SectionTitle>
              <div className={classes.unassignedPoolContainer}>
                <EditPlayerDialogWrapper editContext={PlayerPoolEditContext.GROUP_POOL}>
                  {(args) => <UnassignedPlayers {...args} />}
                </EditPlayerDialogWrapper>
              </div>
            </Grid>
          </Grid>
        </div>
      </DragDropContext>
    )
  }

  public handleEditTeam = (name: string, teamId: number) => {
    const { updateTeam, tournamentId } = this.props
    updateTeam({ tournamentId, name, teamId })
  }

  public onDragEnd = (result: DropResult) => {
    const { addPlayerToTeam, removePlayerFromTeam, teams, players, tournamentId } = this.props

    const playerId = Number(result.draggableId.split('-')[1])

    const destPlayer = players.find((player) => playerId === player.userId)

    /**
     * source: pool, destination: team
     * OR
     * source: team, destination: team
     * AND
     * ignore if source team is the same as destination team
     */
    if (
      (result.source.droppableId === 'unassigned' && result.destination?.droppableId.includes('team')) ||
      (result.source.droppableId.includes('team') &&
        result.destination?.droppableId.includes('team') &&
        result.source.droppableId !== result.destination?.droppableId)
    ) {
      const teamId = Number(result.destination.droppableId.split('-')[1])
      const destTeam = teams.find((team) => teamId === team.id)
      /**
       * Don't allow more than 5 team members
       */
      if (destTeam && destTeam.players.length > 4) {
        return
      }

      /**
       * If team is empty when dragging the first player, name team with that players last name,
       * only if team name is a default one
       */
      if (/^Team \d{1,2}$/g.test(destTeam?.name ?? '') && !destTeam?.players.length) {
        this.handleEditTeam(`Team ${destPlayer?.lastName}`, teamId)
      }

      addPlayerToTeam({ tournamentId: tournamentId, teamId: teamId, playerId: destPlayer?.id ?? 0 })
    }

    /**
     * source: team, destination: pool
     */
    if (result.source.droppableId.includes('team') && result.destination?.droppableId === 'unassigned') {
      const teamId = Number(result.source.droppableId.split('-')[1])
      removePlayerFromTeam({ tournamentId: tournamentId, teamId: teamId, playerId: destPlayer?.id ?? 0 })
    }
  }

  /**
   * Creates a blank new team
   */
  public createTeam = (): void => {
    const { addNewTeam, teams, tournamentId } = this.props
    const newTeamNameIndex = teams.length + 1
    const newTeamName = `Team ${newTeamNameIndex}`
    addNewTeam({ tournamentId, name: newTeamName })
  }

  /**
   * Creates blank new teams to fit all players
   */
  public createTeams = (): void => {
    const { addNewTeams, tournamentId } = this.props
    addNewTeams({ tournamentId, baseName: 'Team' })
  }
}

export const PlayerPoolTeam = connect<StateProps, DispatchProps, {}, RootState>(
  (state) => ({
    unassignedPlayers: getUnassignedTeamPlayers(state),
    teams: selectTournamentTeams(state).data?.teams || [],
    players: selectTournamentPlayers(state).data?.players || [],
    tournamentId: getTournamentId(state),
  }),
  {
    addNewTeam: tournamentTeamsApi.endpoints.addTeam.initiate,
    addNewTeams: tournamentTeamsApi.endpoints.addTeams.initiate,
    updateTeam: tournamentTeamsApi.endpoints.updateTeam.initiate,
    addPlayerToTeam: tournamentTeamsApi.endpoints.addPlayerToTeam.initiate,
    removePlayerFromTeam: tournamentTeamsApi.endpoints.removePlayerFromTeam.initiate,
  },
)(withStyles(styles)(PlayerPoolTeamComponent as any))
