import React from 'react'
import { Theme } from '@mui/material'
import { WithTheme } from '@mui/styles'
import withTheme from '@mui/styles/withTheme'
import { ExpandMore } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'

interface Props {
  expanded?: boolean
  children: any
  style?: React.CSSProperties
  className?: string
  onChange?(expanded: boolean): void
  theme: Theme
  disabled?: boolean
}

class SectionTitleWithCollapse extends React.Component<Props & WithTheme, {}> {
  render() {
    const { theme, children, style, expanded = false, onChange, className, disabled } = this.props

    return (
      <div
        className={className}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          transition: 'border 0.5s',
          borderBottom: expanded ? `1px solid transparent` : `1px solid ${theme.palette.primary.main}`,
          marginBottom: 10,
          marginTop: 5,
          pointerEvents: disabled ? 'none' : 'initial',
          ...style,
        }}
      >
        <div
          onClick={() => onChange && onChange(!expanded)}
          style={{
            display: 'flex',
            flex: '1',
            cursor: 'pointer',
          }}
        >
          {children}
        </div>
        {!disabled && (
          <IconButton onClick={() => onChange && onChange(!expanded)} size="large">
            <ExpandMore
              style={{
                transform: expanded ? `rotate(180deg)` : undefined,
              }}
              fontSize={'large'}
              color={'primary'}
            />
          </IconButton>
        )}
      </div>
    )
  }
}

export default withTheme(SectionTitleWithCollapse as any)
