import React from 'react'
import { connect } from 'react-redux'
import { redirect } from 'react-router-dom'
import { LOCALSTORAGE_ROUTE_NAME } from '../../config'
import HeadHelmet from '../../components/layout/HeadHelmet'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import { RootState } from '@app/store'

interface StateProps {
  auth: AuthenticationState
}

type Props = StateProps

class Loading extends React.Component<Props> {
  componentDidMount(): Response | void {
    const {
      auth: { loggedIn, checkLoading, loading },
    } = this.props
    if (loggedIn) {
      const path = window.localStorage.getItem(LOCALSTORAGE_ROUTE_NAME)
      if (path) {
        window.localStorage.removeItem(LOCALSTORAGE_ROUTE_NAME)
        return redirect(path)
      }
      return redirect('/dashboard')
    } else if (!loading && !checkLoading) {
      return redirect('/login')
    }
  }

  render() {
    return (
      <>
        <HeadHelmet titleId={'navigation.loading'} />
        <OverlayLoader visible={true} />
      </>
    )
  }
}

const ConnectedLoading = connect<StateProps, {}, {}, RootState>(
  (state): StateProps => ({ auth: state.authenticationReducer }),
)(Loading)

export default ConnectedLoading
