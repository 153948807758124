import React from 'react'
import { Button, CircularProgress } from '@mui/material'

interface SaveButtonProps {
  children: React.ReactNode
  isSaving: boolean
  id?: string
  disabled?: boolean
  className?: string
  onClick?: () => void
}

const SaveButton: React.FC<SaveButtonProps> = ({ children, isSaving, id, disabled, className, onClick }) => {
  const elementId = id || Math.random().toString(36).substring(7)
  return (
    <Button
      color="primary"
      variant="contained"
      disabled={isSaving || disabled}
      id={elementId}
      className={className}
      onClick={onClick}
      data-testid="save"
    >
      {children}
      {isSaving && <CircularProgress size={24} style={{ position: 'absolute', color: '#fff' }} />}
    </Button>
  )
}

export default SaveButton
