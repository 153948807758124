import React, { SyntheticEvent, useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  SelectChangeEvent,
  MenuItem,
  OutlinedInput,
  Switch,
  FormControlLabel,
  CircularProgress,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import ContentWrap from '@app/components/layout/ContentWrap'
import { MDEditor } from '@app/scenes/tour/MDEditor'
import {
  useCreateMatchPlayBracketMutation,
  useUpdateMatchPlayBracketMutation,
  useUploadMatchPlayBracketImageMutation,
} from '@app/store/api/endpoints/matchPlayBracketsApi'
import SelectBox from '@app/components/ui/SelectBox'
import { HelpFormLabel } from '@app/components/forms/HelpFormLabel'
import FilePicker from '@app/components/ui/FilePicker'
import BorderButton from '@app/components/ui/BorderButton'
import { CloudUpload } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import SaveButton from '@app/components/ui/SaveButton'
import UploadedImage from '@app/components/ui/UploadedImage'
import { S3_ASSETS_URL } from '@app/config'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: 10,
  },
  logoContainer: {
    maxWidth: '200px',
  },
  fileInfoContainer: {
    fontSize: '14px',
    marginTop: '10px',
    '& > span:first-of-type': {
      display: 'block',
    },
  },
  bronzeToggleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    margin: '5px 25px 0',
  },
  actionContainer: {
    justifyContent: 'flex-start',
    margin: '0 40px',
  },
  lineDivider: {
    height: '1px',
    backgroundColor: '#CECECE',
    margin: '10px 0',
  },
  uploading: {
    marginRight: '10px',
  },
  cloud: {
    marginRight: 10,
  },
}))

interface Props {
  close: () => void
  bracket: MatchPlayBracket | null
  brackets?: MatchPlayBracket[]
}

export const MatchPlayBracketDialog = ({ close, bracket, brackets }: Props): JSX.Element => {
  const intl = useIntl()
  const [currentBracket, setCurrentBracket] = useState<MatchPlayBracket | null>(bracket)
  const [newestBracket, setNewestBracket] = useState<MatchPlayBracket | null>(bracket)
  const [tempImage, setTempImage] = useState<File | null>(null)
  const { customerInfo } = useSelector((store: RootState) => store.authenticationReducer)
  const { idCustomer: organizationId } = customerInfo ? customerInfo : { idCustomer: 0 }
  const [createMatchPlayBracket, { isLoading: isCreating, isSuccess: isCreated }] = useCreateMatchPlayBracketMutation()
  const [updateMatchPlayBracket, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateMatchPlayBracketMutation()
  const [uploadMatchPlayBracketImage, { isLoading: isUploading, isSuccess: isUploaded }] =
    useUploadMatchPlayBracketImageMutation()

  const classes = useStyles()
  const dialogTitle = bracket ? 'matchPlayBracket.editBracket' : 'matchPlayBracket.createBracket'

  const uploadImages = useCallback(
    (files: FileList, bracketId = currentBracket?.id): void => {
      if (bracketId) {
        Array.from(files).forEach((file: File) => {
          const data = new FormData()
          data.append('file', file)
          data.append('fileType', 'logo')
          uploadMatchPlayBracketImage({
            bracketId,
            data,
          })
        })
      }
    },
    [currentBracket, uploadMatchPlayBracketImage],
  )

  useEffect(() => {
    if (isCreated && brackets && currentBracket?.id === undefined) {
      const bracketCreated = brackets[0]?.id !== newestBracket?.id
      if (bracketCreated) {
        const newBracket = brackets[0]
        setCurrentBracket(newBracket)
        setNewestBracket(newBracket)
        const { files } = document.getElementById('match-play-bracket-logo') as HTMLInputElement
        if (files && files.length > 0) {
          uploadImages(files, newBracket.id)
        }
      }
    }
  }, [isCreated, brackets, uploadImages, currentBracket, newestBracket, isUploaded])

  useEffect(() => {
    if (isUploaded && brackets && brackets[0] !== undefined) {
      const bracketIsSame = brackets[0]?.id === newestBracket?.id
      if (bracketIsSame) {
        const newBracket = brackets[0]
        setCurrentBracket(newBracket)
        setNewestBracket(newBracket)
      }
    }
  }, [brackets, currentBracket, newestBracket, isUploaded])

  useEffect(() => {
    if (isUpdated) {
      close()
    }
  }, [isUpdated, close])

  useEffect(() => {
    if (isCreated && isUploaded) {
      close()
    }
  }, [isCreated, isUploaded, close])

  const isSaving: boolean = isCreating || isUpdating

  const getPlayerCountOptions = (): number[] => {
    return [8, 16, 32, 64, 128, 256]
  }

  const updateInfoText = (value: string): void => {
    updateBracket('infoText', value)
  }

  const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    updateBracket(name, value)
  }

  const updatePlayerCount = (e: SelectChangeEvent<any>): void => {
    const { name, value } = e.target
    updateBracketOptions(name, value)
  }

  const updateBracket = (fieldName: string, value: string): void => {
    const originalBracket = currentBracket ? currentBracket : ({} as MatchPlayBracket)
    const updatedBracket: MatchPlayBracket = { ...originalBracket, [fieldName]: value }
    setCurrentBracket(updatedBracket)
  }

  const updateBracketOptions = (fieldName: string, value: string | boolean): void => {
    const originalBracket = currentBracket ? currentBracket : ({} as MatchPlayBracket)
    const originalOptions = originalBracket.options ? originalBracket.options : ({} as MatchPlayBracketOptions)
    const updatedBracket: MatchPlayBracket = {
      ...originalBracket,
      options: { ...originalOptions, [fieldName]: value },
    }
    setCurrentBracket(updatedBracket)
  }

  const renderLogoButton = (): JSX.Element => (
    <>
      <HelpFormLabel className={classes.label}>
        <FormattedMessageWrapper id="matchPlayBracket.logo" />
      </HelpFormLabel>
      <FilePicker
        name="logo"
        id="match-play-bracket-logo"
        onChange={onFileInputChange}
        accept="image/*"
        multiple={false}
      >
        <BorderButton
          buttonProps={{
            component: 'span',
          }}
        >
          {isUploading ? (
            <CircularProgress size={16} className={classes.uploading} />
          ) : (
            <CloudUpload className={classes.cloud} />
          )}
          <FormattedMessageWrapper id="matchPlayBracket.uploadLogo" />
        </BorderButton>
      </FilePicker>
      <div className={classes.fileInfoContainer}>
        <span>
          <FormattedMessageWrapper id="matchPlayBracket.fileType" />: PNG
        </span>
        <span>
          <FormattedMessageWrapper id="matchPlayBracket.suggestedSize" />: 640 x 640px
        </span>
      </div>
    </>
  )

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target
    if (files && files.length > 0) {
      setTempImage(files[0])
      uploadImages(files)
    }
  }

  const getLogoUrl = (): string => {
    if (currentBracket?.options?.logo) {
      return `${S3_ASSETS_URL}${currentBracket.options.logo}`
    } else if (tempImage && (currentBracket?.id === undefined || isUploaded)) {
      return URL.createObjectURL(tempImage)
    }
    return ''
  }

  const deleteLogo = (): void => {
    updateBracketOptions('logo', '')
    setTempImage(null)
  }

  const submit = (): void => {
    const payload = {
      successMessage: intl.formatMessage({ id: 'notifications.saveSuccessful' }),
      body: {
        name: currentBracket?.name || '',
        infoText: currentBracket?.infoText || '',
        options: currentBracket?.options || null,
      },
    }

    if (currentBracket?.id) {
      updateMatchPlayBracket({ ...payload, id: currentBracket.id })
    } else {
      const newest = brackets && brackets.length > 0 ? brackets[0] : null
      setNewestBracket(newest)
      createMatchPlayBracket({ ...payload, organizationId: organizationId })
    }
  }

  return (
    <Dialog open onClose={close} fullWidth maxWidth="md" keepMounted={false}>
      <BaseDialogTitle id="form-dialog-title" title={<FormattedMessageWrapper id={dialogTitle} />} onClose={close} />

      <DialogContent>
        <ContentWrap>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={8}>
              <HelpFormLabel className={classes.label}>
                <FormattedMessageWrapper id="matchPlayBracket.name" />
              </HelpFormLabel>
              <TextField
                name="name"
                type="text"
                id="name"
                variant="outlined"
                onChange={updateField}
                value={currentBracket?.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={2}>
              <HelpFormLabel className={classes.label}>
                <FormattedMessageWrapper id="matchPlayBracket.size" />
              </HelpFormLabel>
              <SelectBox
                id="numberOfPlayers"
                name="numberOfPlayers"
                value={currentBracket?.options?.numberOfPlayers || ''}
                onChange={updatePlayerCount}
                inputElement={OutlinedInput}
              >
                {getPlayerCountOptions().map((count: number, index: number) => (
                  <MenuItem key={index} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </SelectBox>
            </Grid>
            <Grid item xs={10}>
              <div className={classes.bronzeToggleContainer}>
                <FormControlLabel
                  name="playBronzeMatch"
                  checked={currentBracket?.options?.playBronzeMatch === true}
                  control={<Switch />}
                  label={<FormattedMessageWrapper id="matchPlayBracket.showBronzeMatch" />}
                  onChange={(e: SyntheticEvent, checked: boolean) => updateBracketOptions('playBronzeMatch', checked)}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <HelpFormLabel className={classes.label}>
                <FormattedMessageWrapper id="matchPlayBracket.infoText" />
              </HelpFormLabel>
              <MDEditor initialValue={currentBracket?.infoText} saveFn={updateInfoText} maxHeight="150px" />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.lineDivider} />
            </Grid>
            <Grid item xs={6}>
              {renderLogoButton()}
            </Grid>
            <Grid item xs={6}>
              {getLogoUrl() && (
                <div className={classes.logoContainer}>
                  <UploadedImage image={{ id: 1, url: getLogoUrl() }} onDelete={deleteLogo} />
                </div>
              )}
            </Grid>
          </Grid>
        </ContentWrap>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <SaveButton disabled={isSaving} isSaving={isSaving} onClick={submit}>
          <FormattedMessageWrapper id={'buttons.save'} />
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}
