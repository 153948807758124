import React from 'react'
import { connect } from 'react-redux'
import { Select, Input, Chip, FormLabel } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import get from 'lodash/get'
import filter from 'lodash/filter'
import find from 'lodash/find'
import { getPermittedRoles, getPermittedRolesIdMap } from '../../store/authentication/selectors'
import { RootState } from '@app/store'

const styles = () =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })

interface OwnProps {
  disabled?: boolean
  multiple?: boolean
  value?: RoleInfo | RoleInfo[]
  margin?: 'normal' | 'none' | 'dense' | undefined
  onChange?(item?: RoleInfo | RoleInfo[] | null, value?: number | number[] | null): void
  error?: boolean
}

interface StateProps {
  permittedRoles: RoleInfo[]
  permittedRoleMap: { [roleId: number]: RoleInfo }
}

type Props = OwnProps & StateProps & WithStyles<typeof styles>

class UserRolesPicker extends React.Component<Props> {
  render() {
    const { classes, margin, multiple, error } = this.props

    const renderValue = multiple
      ? (selected: any) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={get(this.props.permittedRoleMap, `[${value}].name`)} className={classes.chip} />
            ))}
          </div>
        )
      : undefined

    return (
      <FormControl fullWidth={true} disabled={this.props.disabled} margin={margin} error={error}>
        <FormLabel htmlFor="role-picker">
          <FormattedMessageWrapper id={'tournament.roles'} /> *
        </FormLabel>
        <Select
          multiple={multiple}
          value={this._value}
          onChange={this._handleChange}
          input={<Input name="role-picker" id="role-picker" />}
          renderValue={renderValue}
          style={{ marginTop: 0 }}
        >
          {this._renderMenuOptions}
        </Select>
      </FormControl>
    )
  }

  get _value() {
    const { value } = this.props

    if (!value) {
      return []
    }

    if (value instanceof Array) {
      return value.map((val) => val.idRole)
    } else {
      return value.idRole
    }
  }

  get _renderMenuOptions(): React.ReactNode {
    const { permittedRoles } = this.props
    return permittedRoles.map((item) => (
      <MenuItem key={item.idRole} value={item.idRole}>
        {item.name}
      </MenuItem>
    ))
  }

  public _handleChange = (event): void => {
    if (this.props.onChange) {
      const { value } = event.target
      if (value instanceof Array) {
        const item = filter(this.props.permittedRoles, (r) => value.indexOf(r.idRole) !== -1)
        this.props.onChange(item, value)
      } else {
        const item = find(this.props.permittedRoles, { idRole: value })
        this.props.onChange(item, value)
      }
    }
  }
}

export default connect<StateProps, {}, OwnProps, RootState>((store) => ({
  permittedRoles: getPermittedRoles(store),
  permittedRoleMap: getPermittedRolesIdMap(store),
}))(withStyles(styles)(UserRolesPicker as any))
