import React from 'react'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { Tooltip } from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { VeryDenseTableCell } from '../tables/tableComponents'
import TableBody from '@mui/material/TableBody'
import get from 'lodash/get'
import { Edit, Email } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { confirm } from '../dialogs/confirm/Confirm'
import { connect } from 'react-redux'
import { sendActivationEmail } from '../../store/organizationUser/actions'
import { RootState } from '@app/store'

const styles = () =>
  createStyles({
    table: {
      marginTop: 24,
    },
  })

interface OwnProps {
  users?: OrganizationUser[]
  style?: React.CSSProperties
  tableFooter?: React.ReactNode
  showUserIdColumn?: boolean
  showOrganizationColumn?: boolean
  showRoleColumn?: boolean
  onUserEdit?(user: OrganizationUser): void
}

interface DispatchProps {
  sendActivationEmail(organizationId: number, userId: number): void
}

type Props = OwnProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

class UsersTable extends React.Component<Props> {
  render() {
    const { showUserIdColumn = false, showOrganizationColumn = false, showRoleColumn = false } = this.props

    return (
      <Table className={this.props.classes.table}>
        <TableHead>
          <TableRow>
            {showUserIdColumn && <VeryDenseTableCell>#</VeryDenseTableCell>}
            {showRoleColumn && (
              <VeryDenseTableCell>
                <FormattedMessageWrapper id={'tournament.role'} />
              </VeryDenseTableCell>
            )}
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.name'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.username'} />
            </VeryDenseTableCell>
            {showOrganizationColumn && (
              <VeryDenseTableCell>
                <FormattedMessageWrapper id={'tournament.customer'} />
              </VeryDenseTableCell>
            )}
            <VeryDenseTableCell />
            <VeryDenseTableCell size={'small'} />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.users &&
            this.props.users.map((user) => (
              <TableRow key={user.userInfo.uid}>
                {showUserIdColumn && <VeryDenseTableCell>{get(user, 'userInfo.uid')}</VeryDenseTableCell>}
                {showRoleColumn && <VeryDenseTableCell>{this._getRoles(user.roles)}</VeryDenseTableCell>}
                <VeryDenseTableCell>{get(user, 'userInfo.name')}</VeryDenseTableCell>
                <VeryDenseTableCell>{get(user, 'userInfo.username')}</VeryDenseTableCell>
                {showOrganizationColumn && (
                  <VeryDenseTableCell>{get(user, 'customerInfo.nameCompany')}</VeryDenseTableCell>
                )}
                <VeryDenseTableCell align={'right'} size={'small'}>
                  <Tooltip
                    title={this.props.intl.formatMessage({ id: 'buttons.sendActivation' })}
                    aria-label={this.props.intl.formatMessage({ id: 'buttons.sendActivation' })}
                  >
                    <IconButton
                      onClick={this._handleSendActivationMail(
                        get(user, 'customerInfo.idCustomer'),
                        get(user, 'userInfo.username'),
                        get(user, 'userInfo.uid'),
                      )}
                      size="large"
                    >
                      <Email color={'primary'} />
                    </IconButton>
                  </Tooltip>
                </VeryDenseTableCell>
                <VeryDenseTableCell align={'right'} size={'small'}>
                  {this.props.onUserEdit && (
                    <IconButton
                      onClick={() => {
                        if (this.props.onUserEdit) {
                          this.props.onUserEdit(user)
                        }
                      }}
                      size="large"
                    >
                      <Edit color={'primary'} />
                    </IconButton>
                  )}
                </VeryDenseTableCell>
              </TableRow>
            ))}
        </TableBody>
        {this.props.tableFooter && this.props.tableFooter}
      </Table>
    )
  }

  private _getRoles = (roles: UserRole[]) => {
    return roles.map((role) => role.name).join(', ')
  }

  public _handleSendActivationMail = (organizationId: number, username: string, uid: number) => () => {
    const { intl } = this.props
    confirm({
      message: intl.formatMessage({ id: 'tournament.sendActivationConfirm' }, { username }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      },
    }).then(() => {
      this.props.sendActivationEmail(organizationId, uid)
    })
  }
}

export default connect<{}, DispatchProps, OwnProps, RootState>(null, { sendActivationEmail })(
  injectIntl(withStyles(styles)(UsersTable)),
)
