import React, { useState } from 'react'
import { Clear, Search } from '@mui/icons-material'
import Input from '@mui/material/Input'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import { InputAdornment } from '@mui/material'
import { useIntl } from 'react-intl'

export interface TournamentSearchProps {
  isLoading: boolean
  defaultValue: string
  onSubmit: (searchTerm: string) => void
}

export const TournamentSearch: React.FC<TournamentSearchProps> = ({ isLoading, defaultValue, onSubmit }) => {
  const [searchTerm, setSearchTerm] = useState(defaultValue)
  const intl = useIntl()

  const onClearClick = () => {
    setSearchTerm('')
    onSubmit('')
  }

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(searchTerm)
  }

  return (
    <form style={{ width: '100%' }} onSubmit={onFormSubmit}>
      <Input
        autoComplete={'off'}
        disabled={isLoading}
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.currentTarget.value)}
        fullWidth={true}
        name="searchTerm"
        placeholder={intl.formatMessage({ id: 'tournaments.searchTournaments' })}
        inputProps={{
          'aria-label': 'Description',
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton disabled={isLoading || searchTerm === ''} type="submit" color="primary" size="large">
              {isLoading ? <CircularProgress size={24} /> : <Search />}
            </IconButton>
            {searchTerm !== '' && (
              <IconButton onClick={onClearClick} size="large">
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </form>
  )
}
