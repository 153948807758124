import { bracketsApi } from '../endpoints/matchPlayBracketsApi'

export const updateBracketPlayersToCache = (bracketId: number, players: MatchPlayBracketPlayer[]) => {
  return (dispatch) => {
    dispatch(
      bracketsApi.util.updateQueryData('getMatchPlayBracket', bracketId, (bracket) => {
        return { ...bracket, players }
      }),
    )
  }
}
