import get from 'lodash/get'
import find from 'lodash/find'

export const isRoleAdmin = (roleInfo?: RoleInfo): boolean =>
  get(roleInfo, 'role') === 'ADMIN' || get(roleInfo, 'role') === 'OWNER'

export const isRoleInfoProviderAdmin = (roleInfo?: RoleInfo): boolean =>
  isRoleAdmin(roleInfo) && get(roleInfo, 'scope') === 'PROVIDER'

const isRoleEvent = (roleInfo?: RoleInfo): boolean => get(roleInfo, 'role') === 'EVENT'

export const isCustomerEventUser = (roleInfo?: RoleInfo): boolean =>
  isRoleEvent(roleInfo) && get(roleInfo, 'scope') === 'CUSTOMER'

export const hasRoleCustomerEventUser = (roleInfos: RoleInfo[]): boolean =>
  Boolean(find(roleInfos, isCustomerEventUser))

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}
