import { Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'
import { PlainCard, PlainCardProps } from '../plain-card'
export interface InfoCardProps extends PlainCardProps {
  cardTitle: React.ReactElement<any> | string
  cardDescription: React.ReactElement<any> | string
  cardIcon?: React.ReactElement<any> | false
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    color: theme.palette.common.black,
  },
  title: {
    display: 'block',
    fontFamily: theme.fonts.primary,
    fontSize: rem(20),
    fontWeight: 900,
    fontStyle: 'italic',
    color: '#404040',
    paddingBottom: theme.spacing(0.5),
  },
  top: {
    display: 'flex',
    height: '67%',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '33%',
  },
  infoCol: {
    flex: 1,
    padding: theme.spacing(3),
    justifyContent: 'center',
    textAlign: 'left',

    '& p': {
      textAlign: 'left',
    },
  },
}))

const InfoCard: React.FC<InfoCardProps> = ({ cardTitle, cardDescription, cardIcon, children, ...rest }) => {
  const classes = useStyles()

  return (
    <PlainCard {...rest}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div className={classes.infoCol}>
            {cardIcon}
            <Typography variant="caption" className={classes.title}>
              {cardTitle}
            </Typography>
            {cardDescription}
          </div>
        </div>
        <div className={classes.bottom}>{children}</div>
      </div>
    </PlainCard>
  )
}

export { InfoCard }
