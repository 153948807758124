import {
  ADD_TEMP_PLAYER,
  CLEAR_PLAYERS_SEARCH,
  PLAYERS_SEARCH,
  UPDATE_PLAYERS_FIELD,
  PLAYERS_SEARCH_ORGANIZATION,
} from './constants'
import { Action } from 'redux'

export interface SearchPlayersSuccessAction extends Action, SuccessAction {
  type: 'PLAYERS_SEARCH_SUCCESS'
  players: SearchPlayer[]
}

export const searchPlayers = (onComplete?: (args: APICallResult<SearchPlayer[]>) => void) => ({
  type: PLAYERS_SEARCH,
  onComplete,
})

interface SearchPlayerPayload {
  players: SearchPlayer[]
  clubId: number
}

export interface SearchOrganizationPlayersAction extends Action {
  type: 'PLAYERS_SEARCH_ORGANIZATION'
  payload: SearchPlayerPayload
}
export interface SearchOrganizationPlayersSuccessAction extends Action, SuccessAction {
  type: 'PLAYERS_SEARCH_ORGANIZATION_SUCCESS'
  players: SearchPlayer[]
  clubId: number
}
export interface SearchOrganizationPlayersFailureAction extends Action {
  type: 'PLAYERS_SEARCH_ORGANIZATION_FAILURE'
}

export const searchOrganizationPlayers = (onComplete?: (args: APICallResult<SearchPlayer[]>) => void) => ({
  type: PLAYERS_SEARCH_ORGANIZATION,
  onComplete,
})

export interface UpdatePlayersFieldAction extends Action {
  type: 'UPDATE_PLAYERS_FIELD'
  payload: FieldUpdatePayload
}

export const updatePlayersField = (payload: FieldUpdatePayload) => ({
  type: UPDATE_PLAYERS_FIELD,
  payload,
})

export interface AddTempPlayerAction extends Action {
  type: 'ADD_TEMP_PLAYER'
  player: TempPlayer
  onComplete: (success, error?) => void
}
export interface AddTempPlayerSuccessAction extends Action, SuccessAction {
  type: 'ADD_TEMP_PLAYER_SUCCESS'
}
export interface AddTempPlayerFailureAction extends Action {
  type: 'ADD_TEMP_PLAYER_FAILURE'
}

export const addTempPlayer = (player: TempPlayer, onComplete: (success, error?) => void) => ({
  type: ADD_TEMP_PLAYER,
  player,
  onComplete,
})

export interface ClearPlayersSearchAction extends Action {
  type: 'CLEAR_PLAYERS_SEARCH'
}

export const clearPlayersSearch = (): ClearPlayersSearchAction => ({
  type: CLEAR_PLAYERS_SEARCH,
})
