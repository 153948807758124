import { takeEvery, put, all } from 'redux-saga/effects'
import {
  TOURNAMENT_LOAD_LEADERBOARD,
  TOURNAMENT_LOAD_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
  TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING,
  TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_SUCCESS,
  TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_FAILURE,
  TOURNAMENT_FETCH_SCORE_SENDING_STATUS,
  TOURNAMENT_FETCH_SCORE_SENDING_STATUS_SUCCESS,
  TOURNAMENT_FETCH_SCORE_SENDING_STATUS_FAILURE,
  TOURNAMENT_LOAD_LEADERBOARD_FAILURE,
} from './constants'
import {
  LoadTournamentLeaderboardAction,
  LoadTournamentContestLeaderboardAction,
  SendScoresForHandicappingAction,
  FetchScoreSendingStatusAction,
} from './actions'
import { getToken } from '../authentication/sagas'
import { API_ROOT, APIRoute } from '../../config'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import { enqueueNotification } from '../notifications/actions'

function* doTournamentLoadLeaderboard({ id, onComplete }: LoadTournamentLeaderboardAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_LEADERBOARD(id)}`,
      method: 'get',
      token,
    })
    yield put({
      type: TOURNAMENT_LOAD_LEADERBOARD_SUCCESS,
      data: res.data.data,
    })
    defaultOnCompleteCall(onComplete, res.data)
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, null, error)
    yield put({
      type: TOURNAMENT_LOAD_LEADERBOARD_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doTournamentLoadContestLeaderboard({ id, divisionId }: LoadTournamentContestLeaderboardAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_CONTEST_LEADERBOARD(id, divisionId || 0)}`,
      method: 'get',
      token,
    })

    if (res && !res.error) {
      const rounds = res.data?.data?.rounds || []
      //TODO: Remove this hack when the core https://golfgamebook.atlassian.net/browse/CORE-1318 ticket is done
      const updatedRounds = rounds.map((round: ContestRound) => ({
        ...round,
        contests: (round.contests || []).map((contest: ContestResult) => ({
          ...contest,
          entries: (contest.entries || []).map((entry: ContestEntry) => ({
            ...entry,
            score: entry.scoreString === '0' ? 0.0 : entry.score,
          })),
        })),
      }))

      const data = {
        ...res.data,
        data: {
          ...res.data.data,
          rounds: updatedRounds,
        },
      }

      yield put({
        type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS,
        contestLeaderboard: data ? data : undefined,
      })
    } else {
      yield put({
        type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
        error: res.error,
      })
    }
  } catch (e) {
    yield put({
      type: TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
      error: e,
    })
  }
}

function* doSendScoresForHandicapping({ id }: SendScoresForHandicappingAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.HCP_ROUNDS(id)}`,
      method: 'post',
      token,
    })

    yield put({
      type: TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_SUCCESS,
      scoreStatus: res.data.users,
    })
  } catch (e) {
    yield put({
      type: TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_FAILURE,
      error: e,
    })
  }
}

function* doFetchScoreStatus({ id }: FetchScoreSendingStatusAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.HCP_ROUNDS(id)}`,
      method: 'get',
      token,
    })

    yield put({
      type: TOURNAMENT_FETCH_SCORE_SENDING_STATUS_SUCCESS,
      scoreStatus: res.data.users,
    })
  } catch (e) {
    yield put({
      type: TOURNAMENT_FETCH_SCORE_SENDING_STATUS_FAILURE,
      error: e,
    })
  }
}

export function* watchTournamentLeaderboard() {
  yield all([
    takeEvery(TOURNAMENT_LOAD_LEADERBOARD, doTournamentLoadLeaderboard),
    takeEvery(TOURNAMENT_LOAD_CONTEST_LEADERBOARD, doTournamentLoadContestLeaderboard),
    takeEvery(TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING, doSendScoresForHandicapping),
    takeEvery(TOURNAMENT_FETCH_SCORE_SENDING_STATUS, doFetchScoreStatus),
  ])
}
