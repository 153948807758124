import { put, all, takeEvery, select } from 'redux-saga/effects'
import { API_ROOT, APIRoute } from '@app/config'
import { getToken } from '../authentication/sagas'
import { enqueueNotification } from '../notifications/actions'
import { apiClientRequest, defaultOnCompleteCall } from '@utils/sagaHelpers'
import {
  CreateNewOrganizationProductAction,
  DeleteOrganizationProductAction,
  EditOrganizationProductAction,
  EditTournamentProductsAction,
  FetchOrganizationProductsAction,
  FetchTournamentProductsAction,
  FetchReportAction,
} from './actions'
import {
  CREATE_NEW_ORGANIZATION_PRODUCT,
  CREATE_NEW_ORGANIZATION_PRODUCT_FAILURE,
  CREATE_NEW_ORGANIZATION_PRODUCT_SUCCESS,
  DELETE_ORGANIZATION_PRODUCT,
  DELETE_ORGANIZATION_PRODUCT_FAILURE,
  DELETE_ORGANIZATION_PRODUCT_SUCCESS,
  EDIT_ORGANIZATION_PRODUCT,
  EDIT_ORGANIZATION_PRODUCT_FAILURE,
  EDIT_ORGANIZATION_PRODUCT_SUCCESS,
  EDIT_TOURNAMENT_PRODUCTS,
  EDIT_TOURNAMENT_PRODUCTS_FAILURE,
  EDIT_TOURNAMENT_PRODUCTS_SUCCESS,
  FETCH_ORGANIZATION_PRODUCTS,
  FETCH_ORGANIZATION_PRODUCTS_FAILURE,
  FETCH_ORGANIZATION_PRODUCTS_SUCCESS,
  FETCH_TOURNAMENT_PRODUCTS,
  FETCH_TOURNAMENT_PRODUCTS_FAILURE,
  FETCH_TOURNAMENT_PRODUCTS_SUCCESS,
  FETCH_REPORT,
  FETCH_REPORT_FAILURE,
  FETCH_REPORT_SUCCESS,
} from './constants'
import download from 'downloadjs'
import { convertOrganizationProductToPayload, convertTournamentProductToPayload } from '@app/utils/paymentUtils'
import { RootState } from '..'
import { setTournamentDirty } from '../api/slices/configSlice'

const getOrganizationId = (state: RootState) => state.authenticationReducer.customerInfo?.idCustomer
const getTournamentId = (state: RootState) => state.configReducer.tournament.id

function* doFetchOrganizationProducts({ organizationId, onComplete }: FetchOrganizationProductsAction) {
  try {
    const token = yield getToken()
    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.PAYMENTS_FETCH_PRODUCTS(organizationId)}`,
      method: 'get',
      token,
    })

    defaultOnCompleteCall(onComplete, response)

    const { organizationProducts }: OrganizationProductsResponse = response.data

    yield put({
      type: FETCH_ORGANIZATION_PRODUCTS_SUCCESS,
      products: organizationProducts,
    })
  } catch (error: any) {
    yield put({
      type: FETCH_ORGANIZATION_PRODUCTS_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doCreateNewOrganizationProduct({ product, onComplete }: CreateNewOrganizationProductAction) {
  const organizationId: number = yield select(getOrganizationId)

  try {
    const token = yield getToken()

    const payload = convertOrganizationProductToPayload(product)

    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.PAYMENTS_CREATE_NEW_PRODUCT(organizationId)}`,
      method: 'post',
      token,
      data: payload,
    })

    defaultOnCompleteCall(onComplete, response)

    yield put({ type: CREATE_NEW_ORGANIZATION_PRODUCT_SUCCESS })
    yield put({ type: FETCH_ORGANIZATION_PRODUCTS, organizationId })
  } catch (error: any) {
    yield put({
      type: CREATE_NEW_ORGANIZATION_PRODUCT_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doEditOrganizationProduct({ product, onComplete }: EditOrganizationProductAction) {
  const organizationId: number = yield select(getOrganizationId)

  try {
    const token = yield getToken()

    const payload = convertOrganizationProductToPayload(product)

    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.PAYMENTS_EDIT_DELETE_PRODUCT(organizationId, product.id)}`,
      method: 'put',
      token,
      data: payload,
    })

    defaultOnCompleteCall(onComplete, response)

    yield put({ type: EDIT_ORGANIZATION_PRODUCT_SUCCESS })
    yield put({ type: FETCH_ORGANIZATION_PRODUCTS, organizationId })
  } catch (error: any) {
    yield put({
      type: EDIT_ORGANIZATION_PRODUCT_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doDeleteOrganizationProduct({ product, onComplete }: DeleteOrganizationProductAction) {
  const organizationId: number = yield select(getOrganizationId)

  try {
    const token = yield getToken()
    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.PAYMENTS_EDIT_DELETE_PRODUCT(organizationId, product.id)}`,
      method: 'delete',
      token,
    })

    defaultOnCompleteCall(onComplete, response)

    yield put({ type: DELETE_ORGANIZATION_PRODUCT_SUCCESS })
    yield put({ type: FETCH_ORGANIZATION_PRODUCTS, organizationId })
  } catch (error: any) {
    yield put({
      type: DELETE_ORGANIZATION_PRODUCT_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchTournamentProducts({ tournamentId, onComplete }: FetchTournamentProductsAction) {
  try {
    const token = yield getToken()
    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.PAYMENTS_FETCH_EDIT_TOURNAMENT_PRODUCTS(tournamentId)}`,
      method: 'get',
      token,
    })

    defaultOnCompleteCall(onComplete, response)

    const { tournamentProducts }: TournamentProductsResponse = response.data

    yield put({
      type: FETCH_TOURNAMENT_PRODUCTS_SUCCESS,
      products: tournamentProducts,
    })
  } catch (error: any) {
    yield put({
      type: FETCH_TOURNAMENT_PRODUCTS_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doEditTournamentProduct({ products, onComplete }: EditTournamentProductsAction) {
  const tournamentId: number = yield select(getTournamentId)

  try {
    const token = yield getToken()

    const payload = products.map((product) => convertTournamentProductToPayload(product))

    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.PAYMENTS_FETCH_EDIT_TOURNAMENT_PRODUCTS(tournamentId)}`,
      method: 'put',
      token,
      data: { tournamentProducts: payload },
    })

    const { tournamentProducts }: TournamentProductsResponse = response.data

    defaultOnCompleteCall(onComplete, response)
    yield put(setTournamentDirty(true))
    yield put({ type: EDIT_TOURNAMENT_PRODUCTS_SUCCESS, products: tournamentProducts })
  } catch (error: any) {
    yield put({
      type: EDIT_TOURNAMENT_PRODUCTS_FAILURE,
      error: error,
    })
  }
}

function* doFetchReport({ startTimestamp, endTimestamp, onComplete }: FetchReportAction) {
  const organizationId: number = yield select(getOrganizationId)
  try {
    const token = yield getToken()

    const payload = { startTimestamp, endTimestamp }

    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.PAYMENTS_FETCH_REPORT(organizationId)}`,
      method: 'get',
      token,
      params: payload,
    })

    download(response.data, `products_${organizationId}.csv`, response.headers['content-type'])

    defaultOnCompleteCall(onComplete, response)

    yield put({ type: FETCH_REPORT_SUCCESS })
  } catch (error: any) {
    yield put({
      type: FETCH_REPORT_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchPayments() {
  yield all([takeEvery(FETCH_ORGANIZATION_PRODUCTS, doFetchOrganizationProducts)])
  yield all([takeEvery(CREATE_NEW_ORGANIZATION_PRODUCT, doCreateNewOrganizationProduct)])
  yield all([takeEvery(EDIT_ORGANIZATION_PRODUCT, doEditOrganizationProduct)])
  yield all([takeEvery(DELETE_ORGANIZATION_PRODUCT, doDeleteOrganizationProduct)])
  yield all([takeEvery(FETCH_TOURNAMENT_PRODUCTS, doFetchTournamentProducts)])
  yield all([takeEvery(EDIT_TOURNAMENT_PRODUCTS, doEditTournamentProduct)])
  yield all([takeEvery(FETCH_REPORT, doFetchReport)])
}
