import React from 'react'
import { Cancel } from '@mui/icons-material'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { Theme } from '@mui/material'

interface OwnProps {
  image?: TournamentImage
  className?: string
  style?: React.CSSProperties
  round?: boolean
  deleteIconSize?: 'default' | 'small'
  droppableImage?: boolean
  onDelete?(item?: any): void
}

const styles = (theme: Theme) =>
  createStyles({
    noImage: {
      minWidth: '100%',
      minHeight: '100%',
      backgroundColor: theme.customPalette.lightGray,
      opacity: 0.5,
    },
    image: {
      maxWidth: '100%',
    },
    cancelBtn: {
      position: 'absolute',
      top: -12,
      right: -18,
    },
  })

type Props = OwnProps & WithStyles<typeof styles>

class UploadedImage extends React.Component<Props> {
  render() {
    const {
      image,
      onDelete,
      className,
      style,
      round,
      deleteIconSize = 'default',
      droppableImage = false,
      classes,
    } = this.props

    const imageUri = this._imageUri

    if (!imageUri && !droppableImage) {
      return null
    }

    const customSize = droppableImage
      ? {
          width: 120,
          height: 80,
        }
      : {}

    return (
      <div
        className={className}
        style={{
          display: 'inline-flex',
          marginTop: droppableImage ? 0 : 10,
          maxWidth: !droppableImage ? 400 : 'auto',
          marginRight: droppableImage ? 0 : 20,
          border: '2px dashed',
          padding: 5,
          position: 'relative',
          borderRadius: round ? '50%' : '0',
          ...style,
          ...customSize,
        }}
      >
        {imageUri ? (
          <img alt={imageUri} src={imageUri} className={classes.image} style={{ borderRadius: round ? '50%' : '0' }} />
        ) : (
          <div
            className={classes.noImage}
            style={{
              borderRadius: round ? '50%' : '0',
            }}
          />
        )}
        {onDelete && image && image.id && !droppableImage && (
          <Cancel
            sx={{ fs: deleteIconSize }}
            className={classes.cancelBtn}
            onClick={() => {
              if (onDelete) {
                onDelete(image)
              }
            }}
          />
        )}
      </div>
    )
  }

  private get _imageUri() {
    const { image } = this.props

    if (image && image.url) {
      return image.url
    }

    return ''
  }
}

export default withStyles(styles)(UploadedImage)
