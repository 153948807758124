import {
  CREATE_ORGANIZATION_APPLICATION_ERROR,
  CREATE_ORGANIZATION_APPLICATION_REQUESTING,
  CREATE_ORGANIZATION_APPLICATION_SUCCESS,
  ORGANIZATION_FETCH,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_APPLICATION_REQUESTING,
  UPLOAD_ORGANIZATION_COVER_IMAGE,
  UPLOAD_ORGANIZATION_LOGO,
  ADD_ORGANIZATION_LICENSE,
  FETCH_ORGANIZATION_LICENSE_HISTORY,
  ADD_ORGANIZATION_SUBSCRIPTION,
  FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY,
} from './constants'
import { Action } from 'redux'

/*
  Single organization
 */
export interface FetchOrganizationAction extends Action {
  type: 'ORGANIZATION_FETCH'
  id: number
  force?: boolean
  onComplete?: () => void
}

export const fetchOrganization = (id: number, force?: boolean, onComplete?: () => void) => ({
  type: ORGANIZATION_FETCH,
  id,
  force,
  onComplete,
})

export interface FetchOrganizationFailureAction extends Action {
  type: 'ORGANIZATION_FETCH_FAILURE'
  error: ErrorPayload
}

export const putOrganizationFailure = (error: string) => ({
  type: 'ORGANIZATION_FETCH_FAILURE',
  error,
})

export interface FetchOrganizationSuccessAction extends Action {
  type: 'ORGANIZATION_FETCH_SUCCESS'
  organization: Organization
}

export const putOrganizationSuccess = (organization: Organization) => ({
  type: 'ORGANIZATION_FETCH_SUCCESS',
  organization,
})

/*
  Organization Application actions
 */

export interface CreateOrganizationApplication extends Action, CreateOrganizationApplicationPayload {
  type: 'CREATE_ORGANIZATION_APPLICATION_REQUESTING'
}

export interface CreateOrganizationApplicationPayload extends APICall {
  organizationApplication: OrganizationApplicationForm
  onComplete?: (result: APICallResult) => void
}

export const createOrganizationApplication = ({
  organizationApplication,
  onComplete,
}: CreateOrganizationApplicationPayload): CreateOrganizationApplication => ({
  type: CREATE_ORGANIZATION_APPLICATION_REQUESTING,
  organizationApplication,
  onComplete,
})

export interface CreateOrganizationApplicationError extends Action {
  type: 'CREATE_ORGANIZATION_APPLICATION_ERROR'
  error: ErrorPayload
}

export const putOrganizationApplicationFailure = (error: string): CreateOrganizationApplicationError => ({
  type: CREATE_ORGANIZATION_APPLICATION_ERROR,
  error: {
    error,
  },
})

export interface CreateOrganizationApplicationSuccess extends Action, SuccessAction {
  type: 'CREATE_ORGANIZATION_APPLICATION_SUCCESS'
  payload: any
}

export const putOrganizationApplicationSuccess = (payload: any): CreateOrganizationApplicationSuccess => ({
  type: CREATE_ORGANIZATION_APPLICATION_SUCCESS,
  payload,
})

export interface UpdateOrganizationApplicationAction extends Action, UpdateOrganizationApplicationPayload {
  type: 'UPDATE_ORGANIZATION_APPLICATION_REQUESTING'
}

export interface UpdateOrganizationApplicationPayload extends APICall {
  application: OrganizationApplication
  onComplete?: (result: APICallResult) => void
}

export const updateOrganizationApplication = (
  payload: UpdateOrganizationApplicationPayload,
): UpdateOrganizationApplicationAction => ({
  type: UPDATE_ORGANIZATION_APPLICATION_REQUESTING,
  ...payload,
})

export interface UploadOrganizationLogoImagePayload extends APICall {
  logoImage: File | string
  organizationId: number
}
export interface UploadOrganizationLogoImageAction extends Action, UploadOrganizationLogoImagePayload {
  type: 'UPLOAD_ORGANIZATION_LOGO'
}
export interface UploadOrganizationLogoImageActionSuccess extends Action, SuccessAction {
  type: 'UPLOAD_ORGANIZATION_LOGO_SUCCESS'
}
export interface UploadOrganizationLogoImageActionFailure extends Action, ErrorAction {
  type: 'UPLOAD_ORGANIZATION_LOGO_FAILURE'
}

export const uploadOrganizationLogoImage = (
  payload: UploadOrganizationLogoImagePayload,
): UploadOrganizationLogoImageAction => ({
  type: UPLOAD_ORGANIZATION_LOGO,
  ...payload,
})

export interface UpdateOrganizationPayload extends APICall {
  organizationId: number
  name?: string
  nameMarketing?: string
  phone?: string
  email?: string
  homepage?: string
  additionalInfo?: string
  description?: string
  countryId?: number
  stateId?: number
  businessId?: string
  streetAddress1?: string
  streetAddress2?: string
  zip?: string
  city?: string
  region?: string
  poBox?: string
  country?: string
  club?: number | null
  units?: string
  paymentCompanyId?: string
  paymentEnabled?: boolean
  paymentTaxNumber?: string
  paymentCompanyName?: string
  paymentProvider?: PaymentProviderOptions
  paymentMerchantId?: string
  paymentToken?: string
  stripeApiKey?: string
  paymentCallbackAPIKey?: string
  paymentAddress1?: string
  paymentAddress2?: string
  paymentZipCode?: string
  paymentCity?: string
  paymentCountry?: string
  timeZoneId?: number
}
export interface UpdateOrganizationAction extends Action, UpdateOrganizationPayload {
  type: 'UPDATE_ORGANIZATION'
}
export interface UpdateOrganizationActionSuccess extends Action, SuccessAction {
  type: 'UPDATE_ORGANIZATION_SUCCESS'
  organization: Organization
}
export interface UpdateOrganizationActionFailure extends Action, ErrorAction {
  type: 'UPDATE_ORGANIZATION_FAILURE'
}
export const updateOrganization = (payload: UpdateOrganizationPayload): UpdateOrganizationAction => ({
  type: UPDATE_ORGANIZATION,
  ...payload,
})

export interface UploadOrganizationCoverImagePayload extends APICall {
  image: File | string
  organizationId: number
}
export interface UploadOrganizationCoverImageAction extends Action, UploadOrganizationCoverImagePayload {
  type: 'UPLOAD_ORGANIZATION_COVER_IMAGE'
}
export interface UploadOrganizationCoverImageActionSuccess extends Action, SuccessAction {
  type: 'UPLOAD_ORGANIZATION_COVER_IMAGE_SUCCESS'
}
export interface UploadOrganizationCoverImageActionFailure extends Action, ErrorAction {
  type: 'UPLOAD_ORGANIZATION_COVER_IMAGE_FAILURE'
}

export const uploadOrganizationCoverImage = (
  payload: UploadOrganizationCoverImagePayload,
): UploadOrganizationCoverImageAction => ({
  type: UPLOAD_ORGANIZATION_COVER_IMAGE,
  ...payload,
})

export interface AddOrganizationLicensePayload extends APICall {
  organizationId: number
  licenseCount: number
  notes: string
}

export interface AddOrganizationLicenseAction extends Action, AddOrganizationLicensePayload {
  type: 'ADD_ORGANIZATION_LICENSE'
  onSuccess: () => void
}
export interface AddOrganizationLicenseActionSuccess extends Action, SuccessAction {
  type: 'ADD_ORGANIZATION_LICENSE_SUCCESS'
}
export interface AddOrganizationLicenseActionFailure extends Action, ErrorAction {
  type: 'ADD_ORGANIZATION_LICENSE_FAILURE'
}
export const addOrganizationLicense = (
  payload: AddOrganizationLicensePayload,
  onSuccess,
): AddOrganizationLicenseAction => ({
  type: ADD_ORGANIZATION_LICENSE,
  ...payload,
  onSuccess,
})

export interface AddOrganizationSubscriptionPayload extends APICall {
  organizationId: number
  validFrom: Date
  validTo: Date
  notes: string
}

export interface AddOrganizationSubscriptionAction extends Action, AddOrganizationSubscriptionPayload {
  type: 'ADD_ORGANIZATION_SUBSCRIPTION'
  onSuccess: () => void
}
export interface AddOrganizationSubscriptionActionSuccess extends Action, SuccessAction {
  type: 'ADD_ORGANIZATION_SUBSCRIPTION_SUCCESS'
}
export interface AddOrganizationSubscriptionActionFailure extends Action, ErrorAction {
  type: 'ADD_ORGANIZATION_SUBSCRIPTION_FAILURE'
}
export const addOrganizationSubscription = (
  payload: AddOrganizationSubscriptionPayload,
  onSuccess,
): AddOrganizationSubscriptionAction => ({
  type: ADD_ORGANIZATION_SUBSCRIPTION,
  ...payload,
  onSuccess,
})

export interface FetchOrganizationLicenseHistoryAction extends Action {
  type: 'FETCH_ORGANIZATION_LICENSE_HISTORY'
  organizationId: number
}
export interface FetchOrganizationLicenseHistoryActionSuccess extends Action, SuccessAction {
  type: 'FETCH_ORGANIZATION_LICENSE_HISTORY_SUCCESS'
  history: OrganizationLicenseHistoryState[]
}
export interface FetchOrganizationLicenseHistoryActionFailure extends Action, ErrorAction {
  type: 'FETCH_ORGANIZATION_LICENSE_HISTORY_FAILURE'
}
export const fetchOrganizationLicenseHistory = (organizationId): FetchOrganizationLicenseHistoryAction => ({
  type: FETCH_ORGANIZATION_LICENSE_HISTORY,
  organizationId,
})

export interface FetchOrganizationSubscriptionHistoryAction extends Action {
  type: 'FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY'
  organizationId: number
}
export interface FetchOrganizationSubscriptionHistoryActionSuccess extends Action, SuccessAction {
  type: 'FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_SUCCESS'
  subHistory: OrganizationSubscriptionHistoryState[]
}
export interface FetchOrganizationSubscriptionHistoryActionFailure extends Action, ErrorAction {
  type: 'FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_FAILURE'
}
export const fetchOrganizationSubscriptionHistory = (organizationId): FetchOrganizationSubscriptionHistoryAction => ({
  type: FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY,
  organizationId,
})
