import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

const styles = StyleSheet.create({
  tableRow: {
    height: '26pt',
    padding: '5pt',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableRowFieldTitle: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '12pt',
  },
  tableRowField: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12pt',
  },
})

export type TableRowField = {
  title?: string
  value: string | number
  width: number
  style?: Style | Style[]
}

export type TableRowProps = {
  fields: TableRowField[]
  style?: Style | Style[]
}

const getStyleArray = (style?: Style | Style[]): Style[] => {
  return Array.isArray(style) ? style : style ? [style] : []
}

export const TableRow = ({ fields, style }: TableRowProps) => {
  const arrayStyle = getStyleArray(style)

  const renderFields = () => {
    return fields.map((field, idx) => {
      const fieldStyle = getStyleArray(field.style)

      if (field.title) {
        return (
          <View style={{ flex: field.width, margin: 0, padding: 0 }} key={`title_${idx}`}>
            <Text style={styles.tableRowFieldTitle}>{field.title}</Text>
            <Text style={[...fieldStyle, styles.tableRowField, { flex: field.width }]}>{field.value}</Text>
          </View>
        )
      }

      return (
        <Text key={idx} style={[...fieldStyle, styles.tableRowField, { flex: field.width }]}>
          {field.value}
        </Text>
      )
    })
  }

  return <View style={[styles.tableRow, ...arrayStyle]}>{renderFields()}</View>
}
