import React, { useState } from 'react'
import { Button, DialogActions, DialogContent, Theme, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { setStorageGolferIds, storageImportId, storageTournamentId } from '@utils/tournamentUtils'
import { useGitImportStartMutation } from '@app/store/api/endpoints/tournamentGitApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'

const useStyles = makeStyles((theme: Theme) => ({
  textFieldTitle: {
    marginBottom: 10,
    color: theme.customPalette.darkGreen,
  },
  dialogActionsContainer: {
    justifyContent: 'flex-start',
  },
}))

export interface Props {
  onImportClick: () => void
}

export const PlayerImportView: React.FC<Props> = ({ onImportClick }) => {
  const classes = useStyles()

  const id = useSelector(getTournamentId)
  const [gitImportStart] = useGitImportStartMutation()

  const [playerIds, setPlayerIds] = useState<string>('')

  const handleImportClick = () => {
    setStorageGolferIds(playerIds)

    gitImportStart({
      tournamentId: id,
      onComplete: (importId: string) => {
        //Add import id to storage
        localStorage.setItem(storageImportId, importId)
        localStorage.setItem(storageTournamentId, String(id))
        window.dispatchEvent(new Event('storage'))
      },
    })
    onImportClick()
  }

  return (
    <>
      <DialogContent>
        <Typography variant="body2" className={classes.textFieldTitle}>
          <FormattedMessageWrapper id="tournament.golfIdsText" />
        </Typography>
        <TextField
          multiline
          fullWidth
          minRows={15}
          value={playerIds}
          variant="outlined"
          onChange={(e) => setPlayerIds(e.target.value)}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActionsContainer}>
        <Button variant="contained" onClick={handleImportClick}>
          <FormattedMessageWrapper id="buttons.import" />
        </Button>
      </DialogActions>
    </>
  )
}
