import TablePagination, { TablePaginationProps } from '@mui/material/TablePagination'
import { ROWS_PER_PAGE } from '../../config'
import TablePaginationActions from './TablePaginationActions'

interface TablePaginationComponentProps {
  count?: number
  page?: number
  rowsPerPage?: number
  TablePaginationProps?: TablePaginationProps
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
}

const TablePaginationComponent = ({
  count = 0,
  page = 0,
  rowsPerPage = ROWS_PER_PAGE,
  onChangePage,
  TablePaginationProps,
}: TablePaginationComponentProps) => {
  if (count <= rowsPerPage) {
    return null
  }
  return (
    <TablePagination
      style={{ borderBottom: 'none' }}
      count={count}
      page={page}
      rowsPerPageOptions={[]}
      rowsPerPage={rowsPerPage}
      ActionsComponent={TablePaginationActions}
      onPageChange={onChangePage}
      {...TablePaginationProps}
    />
  )
}

export default TablePaginationComponent
