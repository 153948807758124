import {
  CreateNewOrganizationProductAction,
  CreateNewOrganizationProductFailureAction,
  CreateNewOrganizationProductSuccessAction,
  FetchOrganizationProductsAction,
  FetchOrganizationProductsFailureAction,
  FetchOrganizationProductsSuccessAction,
  EditOrganizationProductAction,
  EditOrganizationProductFailureAction,
  EditOrganizationProductSuccessAction,
  DeleteOrganizationProductAction,
  DeleteOrganizationProductFailureAction,
  DeleteOrganizationProductSuccessAction,
  FetchTournamentProductsAction,
  FetchTournamentProductsFailureAction,
  FetchTournamentProductsSuccessAction,
  EditTournamentProductsAction,
  EditTournamentProductsFailureAction,
  EditTournamentProductsSuccessAction,
  FetchReportAction,
  FetchReportFailureAction,
  FetchReportSuccessAction,
} from './actions'
import {
  CREATE_NEW_ORGANIZATION_PRODUCT,
  CREATE_NEW_ORGANIZATION_PRODUCT_FAILURE,
  CREATE_NEW_ORGANIZATION_PRODUCT_SUCCESS,
  FETCH_ORGANIZATION_PRODUCTS,
  FETCH_ORGANIZATION_PRODUCTS_FAILURE,
  FETCH_ORGANIZATION_PRODUCTS_SUCCESS,
  EDIT_ORGANIZATION_PRODUCT,
  EDIT_ORGANIZATION_PRODUCT_FAILURE,
  EDIT_ORGANIZATION_PRODUCT_SUCCESS,
  DELETE_ORGANIZATION_PRODUCT,
  DELETE_ORGANIZATION_PRODUCT_FAILURE,
  DELETE_ORGANIZATION_PRODUCT_SUCCESS,
  FETCH_TOURNAMENT_PRODUCTS,
  FETCH_TOURNAMENT_PRODUCTS_FAILURE,
  FETCH_TOURNAMENT_PRODUCTS_SUCCESS,
  EDIT_TOURNAMENT_PRODUCTS,
  EDIT_TOURNAMENT_PRODUCTS_FAILURE,
  EDIT_TOURNAMENT_PRODUCTS_SUCCESS,
  FETCH_REPORT,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
} from './constants'

const initialState: PaymentsState = {
  organizationProducts: [],
  tournamentProducts: [],
  loading: false,
  error: undefined,
}

type PaymentsActions =
  | FetchOrganizationProductsAction
  | FetchOrganizationProductsSuccessAction
  | FetchOrganizationProductsFailureAction
  | CreateNewOrganizationProductAction
  | CreateNewOrganizationProductSuccessAction
  | CreateNewOrganizationProductFailureAction
  | EditOrganizationProductAction
  | EditOrganizationProductFailureAction
  | EditOrganizationProductSuccessAction
  | DeleteOrganizationProductAction
  | DeleteOrganizationProductFailureAction
  | DeleteOrganizationProductSuccessAction
  | FetchTournamentProductsAction
  | FetchTournamentProductsFailureAction
  | FetchTournamentProductsSuccessAction
  | EditTournamentProductsAction
  | EditTournamentProductsFailureAction
  | EditTournamentProductsSuccessAction
  | FetchReportAction
  | FetchReportFailureAction
  | FetchReportSuccessAction

const paymentsReducer = (state: PaymentsState = initialState, action: PaymentsActions) => {
  switch (action.type) {
    case CREATE_NEW_ORGANIZATION_PRODUCT:
    case EDIT_ORGANIZATION_PRODUCT:
    case DELETE_ORGANIZATION_PRODUCT:
    case FETCH_ORGANIZATION_PRODUCTS:
    case FETCH_TOURNAMENT_PRODUCTS:
    case EDIT_TOURNAMENT_PRODUCTS:
    case FETCH_REPORT:
      return {
        ...state,
        loading: true,
      }
    case CREATE_NEW_ORGANIZATION_PRODUCT_SUCCESS:
    case EDIT_ORGANIZATION_PRODUCT_SUCCESS:
    case DELETE_ORGANIZATION_PRODUCT_SUCCESS:
    case FETCH_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case FETCH_ORGANIZATION_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationProducts: action.products,
      }
    case EDIT_TOURNAMENT_PRODUCTS_SUCCESS:
    case FETCH_TOURNAMENT_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tournamentProducts: action.products,
      }
    case CREATE_NEW_ORGANIZATION_PRODUCT_FAILURE:
    case EDIT_ORGANIZATION_PRODUCT_FAILURE:
    case DELETE_ORGANIZATION_PRODUCT_FAILURE:
    case EDIT_TOURNAMENT_PRODUCTS_FAILURE:
    case FETCH_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case FETCH_TOURNAMENT_PRODUCTS_FAILURE:
    case FETCH_ORGANIZATION_PRODUCTS_FAILURE:
      return {
        ...state,
        organizationProducts: [],
        tournamentProducts: [],
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export { paymentsReducer }
