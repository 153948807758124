export const APIRoute = {
  POST_LOGIN: 'api/1/login',
  POST_LOGOUT: 'api/1/logout',
  GET_CHECK_TOKEN: (token: string) => `api/1/login/check/${token}`,
  POST_RESET_PASSWORD: () => `api/1/resetPassword`,
  POST_ACTIVATE_ACCOUNT: () => `api/1/activate-account`,
  POST_VALIDATE_TOKEN: () => `api/1/validate-token`,
  GET_COUNTRIES: 'api/1/countries',
  GET_COUNTRY_STATES: (countryId: number) => `api/1/country/${countryId}/states`,
  GET_CLUB: (clubId: number) => `api/1/club/${clubId}`,
  GET_SEARCH_CLUBS: 'api/1/clubs/search',
  GET_SEARCH_CLUBS_PUBLIC: 'api/1/site/clubs/search',
  GET_COURSES: (clubId: number) => `api/1/club/${clubId}/courses`,
  GET_TOURNAMENTS: (organizationId: number) => `api/1/organization/${organizationId}/tournaments`,
  GET_TOURNAMENT: (id: number) => `api/1/tournament/${id}`,
  POST_TOURNAMENT: 'api/1/tournament',
  DELETE_TOURNAMENT: (id: number) => `api/1/tournament/${id}`,
  PUT_TOURNAMENT: (id: number) => `api/1/tournament/${id}`,
  PUT_ROUND: (id: number, roundId: number) => `api/1/tournament/${id}/round/${roundId}`,
  POST_ROUND: (id: number) => `api/1/tournament/${id}/round`,
  DELETE_ROUND: (id: number, roundId: number) => `api/1/tournament/${id}/round/${roundId}`,
  GET_GAME_FORMATS: 'api/1/game-formats',
  PUT_TOURNAMENT_SITE: (id: number) => `api/1/tournament/${id}/site-settings`,
  GET_TOURNAMENT_SITE: (id: number) => `api/1/tournament/site/${id}`,
  POST_TOURNAMENT_SITE_INVITATION_FILE: (id: number) => `api/1/tournament/${id}/invitation`,
  POST_TOURNAMENT_SITE_SUMMARY_FILE: (id: number) => `api/1/tournament/${id}/summary`,
  GET_PLAYER_SEARCH_REGISTRY: (tournamentId: number) => `api/1/tournament/${tournamentId}/players/search/provider`,
  GET_PLAYER_SEARCH_ORGANIZATION: (organizationId: number) =>
    `api/1/organization/${organizationId}/players/search/provider`,
  GET_PLAYERS: (id: number) => `api/1/tournament/${id}/players`,
  POST_ADD_PLAYER_TO_POOL: (id: number) => `api/1/tournament/${id}/player`,
  POST_ADD_TEAM_TO_POOL: (tournamentId: number, teamId: number) =>
    `api/1/tournament/${tournamentId}/team/${teamId}/reserve`,
  POST_ADD_PLAYER_TO_TOURNAMENT: (id: number) => `api/1/tournament/${id}/player/provider`,
  DELETE_PLAYER: (id: number, playerId: number) => `api/1/tournament/${id}/player/${playerId}`,
  DELETE_SELECTED_PLAYERS: (tournamentId: number) => `api/1/tournament/${tournamentId}/players`,
  PUT_MOVE_SELECTED_PLAYERS: (tournamentId: number) => `api/1/tournament/${tournamentId}/players`,
  POST_CREATE_START_LIST: (id: number, roundId: number) => `api/1/tournament/${id}/round/${roundId}/startlist`,
  POST_COPY_START_LIST: (id: number, roundId: number) => `api/1/tournament/${id}/round/${roundId}/startlist/copy`,
  START_LIST: (id: number, roundId: number) => `api/1/tournament/${id}/round/${roundId}/startlist`,
  GET_ORGANIZATION: (id: number) => `api/1/organization/${id}`,
  ORGANIZATION_APPLICATION: (id?: number) => `api/1/organization/application${id ? '/' + id : ''}`,
  GET_ORGANIZATIONS: 'api/1/organizations',
  GET_ORGANIZATIONS_SEARCH: 'api/1/organizations/search',
  GET_ORGANIZATION_APPLICATIONS: 'api/1/organization/applications',
  GET_ORGANIZATION_TOURNAMENTS: 'api/1/tournaments',
  GET_ORGANIZATION_USERS: (id: number) => `api/1/organization/${id}/users`,
  ADD_ORGANIZATION_LICENSE: (id: number) => `api/1/organization/${id}/licenses`,
  ADD_ORGANIZATION_SUBSCRIPTION: (id: number) => `api/1/organization/${id}/subscription`,
  GET_ORGANIZATION_LICENSE_HISTORY: (id: number) => `api/1/organization/${id}/licenses/history`,
  GET_ORGANIZATION_SUBSCRIPTION_HISTORY: (id: number) => `api/1/organization/${id}/subscription/history`,
  GET_ORGANIZATION_MATCH_PLAY_BRACKETS: (id: number) => `api/1/organization/${id}/match-play-brackets`,
  ORGANIZATION_USER: (organizationId: number, id?: number) =>
    `api/1/organization/${organizationId}/user${id ? '/' + id : ''}`,
  POST_TEMP_PLAYER: (tournamentId: number) => `api/1/tournament/${tournamentId}/player/unregistered`,
  PUT_TOURNAMENT_SETTINGS: (tournamentId: number) => `api/1/tournament/${tournamentId}/settings`,
  GET_TOURNAMENT_SETTINGS: (tournamentId: number) => `api/1/tournament/${tournamentId}/settings`,
  POST_TOURNAMENT_IMAGE: (tournamentId: number) => `api/1/tournament/${tournamentId}/image`,
  DELETE_TOURNAMENT_IMAGE: (tournamentId: number, id: number) => `api/1/tournament/${tournamentId}/image/${id}`,
  PATCH_PLAYER_DETAILS: (id: number, playerId: number) => `api/1/tournament/${id}/player/${playerId}`,
  POST_ADD_PLAYER_TO_GROUP: (tournamentId: number, roundId: number, groupId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/round/${roundId}/group/${groupId}/player/${playerId}`,
  PUT_UPDATE_START_LIST_PLAYER: (tournamentId: number, roundId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/round/${roundId}/player/${playerId}`,
  DELETE_PLAYER_FROM_GROUP: (tournamentId: number, roundId: number, groupId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/round/${roundId}/group/${groupId}/player/${playerId}`,
  GET_EXCEL_TEMPLATE: (id: number) => `api/1/tournament/${id}/excel/template`,
  POST_EXCEL_PREVIEW: (id: number) => `api/1/tournament/${id}/excel/preview`,
  POST_EXCEL: (id: number) => `api/1/tournament/${id}/excel`,
  POST_CREATE_START_GROUP: (id: number, roundId: number) => `api/1/tournament/${id}/round/${roundId}/group`,
  PUT_EDIT_START_LIST_GROUP: (id: number, roundId: number, groupId: number) =>
    `api/1/tournament/${id}/round/${roundId}/group/${groupId}`,
  PUT_SWAP_START_LIST_GROUP: (id: number, roundId: number, groupId: number) =>
    `api/1/tournament/${id}/round/${roundId}/group/${groupId}/swap`,
  DELETE_START_LIST_GROUP: (id: number, roundId: number, groupId: number) =>
    `api/1/tournament/${id}/round/${roundId}/group/${groupId}`,
  START_LIST_TEAM_GROUP: (tournamentId: number, roundId: number, groupId: number, teamId: number) =>
    `api/1/tournament/${tournamentId}/round/${roundId}/group/${groupId}/team/${teamId}`,
  DELETE_START_LIST: (tournamentdId: number, startListId: number) =>
    `api/1/tournament/${tournamentdId}/startlist/${startListId}`,
  PATCH_EDIT_TOURNAMENT_ROUND_STATUS: (id: number, roundId: number) => `api/1/tournament/${id}/round/${roundId}/status`,
  PUT_EDIT_TOURNAMENT_ROUND_STATUS_WEEKLY: (id: number) => `api/1/tournament/${id}/publish-weekly-competition`,
  PUT_EDIT_TOURNAMENT_RESULTS_PUBLISHED: (id: number) => `api/1/tournament/${id}/scoring/published`,
  PLAYER_SCORES: (id: number, playerId: number) => `api/1/tournament/${id}/player/${playerId}/scores`,
  UPDATE_PLAYER_STATUS: (tournamentId: number, userId: number, status: string) =>
    `api/1/tournament/${tournamentId}/player/${userId}/status/${status}`,
  CALCULATE_CUT: (tournamentId: number) => `api/1/tournament/${tournamentId}/leaderboard/cut`,
  COPY_TOURNAMENT: (id: number) => `api/1/tournament/${id}/copy`,
  GET_TEAMS: (id: number) => `api/1/tournament/${id}/teams`,
  EDIT_TEAM: (id: number, teamId: number) => `api/1/tournament/${id}/team/${teamId}`,
  POST_TEAM: (id: number) => `api/1/tournament/${id}/team`,
  POST_TEAMS: (id: number) => `api/1/tournament/${id}/teams`,
  POST_CREATE_TEAMS_AUTOFILL: (tournamentId: number) => `api/1/tournament/${tournamentId}/teams/autofill`,
  DELETE_TEAM: (id: number, teamId: number) => `api/1/tournament/${id}/team/${teamId}`,
  DELETE_TEAMS: (id: number) => `api/1/tournament/${id}/teams`,
  POST_ADD_PLAYER_TO_TEAM: (tournamentId: number, teamId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/team/${teamId}/player/${playerId}`,
  DELETE_PLAYER_FROM_TEAM: (tournamentId: number, teamId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/team/${teamId}/player/${playerId}`,
  GET_LEADERBOARD: (id: string | number) => `api/1/tournament/${id}/leaderboard`,
  GET_CONTEST_LEADERBOARD: (id: string | number, divisionId: string | number) =>
    `api/1/tournament/${id}/contest-leaderboard${divisionId ? '?divisionId=' + divisionId : ''}`,
  GET_LEADERBOARD_WITH_DIVISIONS: (tournamentId: number, divisionId: number) =>
    `api/1/tournament/${tournamentId}/division/${divisionId}/leaderboard`,
  TOURS: (organizationId: number) => `api/1/organization/${organizationId}/tours`,
  TOUR: (organizationId: number, id: number) => `api/1/organization/${organizationId}/tour/${id}`,
  POST_TOUR_IMAGE: (organizationId: number, id: number) => `api/1/organization/${organizationId}/tour/${id}/images`,
  DELETE_TOUR_IMAGE: (organizationId: number, tourId: number, id: number) =>
    `api/1/organization/${organizationId}/tour/${tourId}/image/${id}`,
  RANKINGS: (organizationId: number) => `api/1/organization/${organizationId}/rankings`,
  RANKING: (organizationId: number, id: number) => `api/1/organization/${organizationId}/ranking/${id}`,
  SEND_RANKING_POINTS: (id: number) => `api/1/tournament/${id}/ranking-submission`,
  TOURNAMENT_CALENDAR: (id: number) => `api/1/tournament/${id}/calendar`,
  UPDATE_HCPS: (id: number) => `api/1/tournament/${id}/handicaps`,
  HCP_ROUNDS: (id: number) => `api/1/tournament/${id}/hcp-submission`,
  WEEKLY_PLAYER_HCP: (tournamentId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/player/${playerId}/hcp-submission`,
  DIVISIONS: (organizationId: number) => `api/1/organization/${organizationId}/divisions`,
  DIVISION: (organizationId: number, divisionId: number) =>
    `api/1/organization/${organizationId}/division/${divisionId}`,
  UPDATE_NOTES: (tournamentId: number) => `api/1/tournament/${tournamentId}/internal-note`,
  EMAIL_PREVIEW_FETCH: (tournamentId: number, roundId: number) =>
    `api/1/tournament/${tournamentId}/round/${roundId}/startlist/email-preview`,
  EMAIL_SEND: (tournamentId: number, roundId: number) =>
    `api/1/tournament/${tournamentId}/round/${roundId}/startlist/email`,
  SEND_HCP_ROUNDS: (organizationId: number) => `api/1/organization/${organizationId}/player/scores`,
  PAYMENTS_FETCH_PRODUCTS: (organizationId: number) => `api/1/organization/${organizationId}/products`,
  PAYMENTS_CREATE_NEW_PRODUCT: (organizationId: number) => `api/1/organization/${organizationId}/product`,
  PAYMENTS_EDIT_DELETE_PRODUCT: (organizationId: number, productId: number) =>
    `api/1/organization/${organizationId}/product/${productId}`,
  PAYMENTS_FETCH_EDIT_TOURNAMENT_PRODUCTS: (tournamentId: number) => `api/1/tournament/${tournamentId}/products`,
  PAYMENTS_FETCH_REPORT: (organizationId: number) => `api/1/organization/${organizationId}/report`,
  GET_TIME_ZONES: () => `api/1/timezones`,
  GET_WEEKLY_PLAYER_SCORES: (tournamentId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/player/${playerId}/scores`,
  PUT_UPDATE_PLAYER_GROUP_ORDER: (tournamentId: number, roundId: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/round/${roundId}/player/${playerId}/group/order`,
  SYNC_TOURNAMENT: (tournamentId: number) => `api/1/tournament/${tournamentId}/sync`,
  SEND_MEASURED_CONTEST_RESULTS: (tournamentId: number, round: number, holeNumber: number) =>
    `api/1/tournament/${tournamentId}/round/${round}/hole/${holeNumber}/contest-results/measured`,
  SEND_NON_MEASURED_CONTEST_RESULTS: (tournamentId: number, round: number, holeNumber: number) =>
    `api/1/tournament/${tournamentId}/round/${round}/hole/${holeNumber}/contest-results/nonmeasured`,
  DELETE_CONTEST_RESULT: (tournamentId: number, round: number, holeNumber: number, playerId: number) =>
    `api/1/tournament/${tournamentId}/round/${round}/hole/${holeNumber}/contest-results/user/${playerId}`,
  FILTER_YEARS: (organizationId: number) => `api/1/organization/${organizationId}/tournaments/years`,
  GET_REPORTS: (organizationId: number) => `api/1/organization/${organizationId}/reports`,
  GET_WEEKLY_REPORTS: (organizationId: number) => `api/1/organization/${organizationId}/reports/weekly`,
  GIT_IMPORT_START: (tournamentId: number) => `api/1/tournament/${tournamentId}/import/git`,
  GIT_IMPORT_URL: (tournamentId: number, importId: string) => `api/1/tournament/${tournamentId}/import/git/${importId}`,
  GET_MATCH_PLAY_BRACKET: (bracketId: number) => `api/1/match-play-brackets/${bracketId}`,
  POST_CREATE_MATCH_PLAY_BRACKET: 'api/1/match-play-brackets',
  PUT_EDIT_MATCH_PLAY_BRACKET: (bracketId: number) => `api/1/match-play-brackets/${bracketId}`,
  DELETE_MATCH_PLAY_BRACKET: (bracketId: number) => `api/1/match-play-brackets/${bracketId}`,
  POST_UPLOAD_MATCH_PLAY_BRACKET_IMAGE: (bracketId: number) => `api/1/match-play-brackets/${bracketId}/images`,
  DELETE_MATCH_PLAY_BRACKET_PLAYER: (bracketId: number, playerId: number) =>
    `api/1/match-play-brackets/${bracketId}/players/${playerId}`,
  SEND_SUMMARY_PAGE_EMAIL: (tournamentId: number) => `api/1/tournament/${tournamentId}/summary/email`,
  GET_SUMMARY_PAGE_EMAIL_PREVIEW: (tournamentId: number) => `api/1/tournament/${tournamentId}/summary/email/preview`,
  GET_FEED_PICTURES: (tournamentId: number) => `api/1/tournament/${tournamentId}/images/ggb-feed`,
  DELETE_FEED_PICTURE: (tournamentId: number, imageId: number) => `api/1/tournament/${tournamentId}/images/${imageId}`,
  SORT_FEED_PICTURES: (tournamentId: number, imageId: number) => `api/1/tournament/${tournamentId}/images/${imageId}`,
  SORT_PICTURES: (tournamentId: number, imageId: number) => `api/1/tournament/${tournamentId}/image/${imageId}/reorder`,
  GET_FEED_PICTURE_LIST: (tournamentId: number) => `api/1/tournament/${tournamentId}/images`,
  GET_CURRENCIES: 'api/1/currencies',
}

export const InternalRoute = {
  ORGANIZATIONS: '/organizations',
}

export const getAppDomain = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return 'localhost'
  } else {
    return 'golfgamebook.com'
  }
}

const formAPIRoot = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL || 'http://localhost:9981/'
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return 'https://tournament-api.sandbox.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://tournament-api.staging.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return 'https://tournament-api.rc.golfgamebook.com/'
  } else if (process.env.REACT_APP_ENV === 'production') {
    return 'https://tournament-api.golfgamebook.com/'
  } else {
    return 'https://tournament-api.sandbox.golfgamebook.com/'
  }
}

export const formTournamentSiteUrl = (id: number): string => {
  if (process.env.NODE_ENV === 'development') {
    return `http://localhost:3099/tournament/${id}`
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return `https://tournament-site.sandbox.golfgamebook.com/tournament/${id}`
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return `https://tournament-site.staging.golfgamebook.com/tournament/${id}`
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return `https://tournament-site.rc.golfgamebook.com/tournament/${id}`
  } else if (process.env.REACT_APP_ENV === 'production') {
    return `https://tournament-site.golfgamebook.com/tournament/${id}`
  } else {
    return `https://tournament-site.sandbox.golfgamebook.com/tournament/${id}`
  }
}

export const formTourUrl = (id: number): string => {
  if (process.env.NODE_ENV === 'development') {
    return `http://localhost:3099/tour/${id}`
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return `https://tournament-site.sandbox.golfgamebook.com/tour/${id}`
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return `https://tournament-site.staging.golfgamebook.com/tour/${id}`
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return `https://tournament-site.rc.golfgamebook.com/tour/${id}`
  } else if (process.env.REACT_APP_ENV === 'production') {
    return `https://tournament-site.golfgamebook.com/tour/${id}`
  } else {
    return `https://tournament-site.sandbox.golfgamebook.com/tour/${id}`
  }
}

const formS3AssetsUrl = () => {
  const assets = 'assets/'
  if (process.env.NODE_ENV === 'development') {
    return process.env.S3_URL || `https://tournament.staging.golfgamebook.com/${assets}`
  } else if (process.env.REACT_APP_ENV === 'sandbox') {
    return `https://tournament.sandbox.golfgamebook.com/${assets}`
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return `https://tournament.staging.golfgamebook.com/${assets}`
  } else if (process.env.REACT_APP_ENV === 'rc') {
    return `https://tournament.rc.golfgamebook.com/${assets}`
  } else if (process.env.REACT_APP_ENV === 'production') {
    return `https://tournament.golfgamebook.com/${assets}`
  } else {
    return `https://tournament.staging.golfgamebook.com/${assets}`
  }
}

export const API_ROOT: string = formAPIRoot()
export const S3_ASSETS_URL: string = formS3AssetsUrl()

export const LOCAL_STORAGE_LANG_KEY = `tournament_lang_token`
export const LOCALSTORAGE_ROUTE_NAME = `tournament_route`

export const ROWS_PER_PAGE = 15

export const SITE_TITLE = 'Tournament Manager, Golf GameBook'
export const TITLE_DIVIDER = ' | '

//TODO: refactor to use country codes. ID's are primary keys and are unreliable.
export const FI_COUNTRY_ID = 73
export const SV_COUNTRY_ID = 204

export const MAX_AMOUNT_OF_ROUNDS = 8

export const getDownloadMimeType = (type: string): string => {
  switch (type) {
    case 'csv':
      return 'text/csv'
    case 'xls':
      return 'application/vnd.ms-excel'
    case 'excel':
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    case 'pdf':
    default:
      return 'pdf_asia'
  }
}

export class StartTypes {
  static SHOTGUN = { value: 'shotgun', label: 'Shotgun' }
  static SHOTGUN_REVERSED = { value: 'shotgunReversed', label: 'Shotgun Reversed' }
  static FIRST_HOLE = { value: '1stHole', label: 'Hole 1' }
  static TENTH_HOLE = { value: '10thHole', label: 'Hole 10' }
  static FIRST_AND_TENTH_HOLE = { value: '1stAndTenthHole', label: 'Hole 1 and 10' }
}

export enum PaymentProvider {
  NETS = 'nets',
  STRIPE = 'stripe',
}

export const getDefaultProfileImage = () => {
  const env = process.env.REACT_APP_ENV || 'sandbox'
  if (env === 'production') {
    return 'https://static.golfgamebook.com/defaults/avatar.png'
  }
  return `https://static.${env}.golfgamebook.com/defaults/avatar.png`
}

export enum YesOrNo {
  YES = 'yes',
  NO = 'no',
}
