import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import Button from '@mui/material/Button'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material'
import { rem } from '@app/theme/materialUITheme'
import { TournamentCopy } from '@app/store/api/enums/tournamentEnums'
import { tournamentApi } from '@app/store/api/endpoints/tournamentApi'
import { RootState } from '@app/store'

interface OwnProps {
  tournament?: Tournament
  open: boolean
  onClose(): void
}

interface DispatchProps {
  copyTournament(args: CopyTournamentPayload): void
}

type Props = OwnProps & DispatchProps

interface State {
  isLoading: boolean
  options: {
    ROUND: boolean
    SITE: boolean
    PLAYERS: boolean
    GROUPS: boolean
  }
}

class TournamentCopyDialog extends React.Component<Props, State> {
  readonly state: State = {
    isLoading: false,
    options: {
      ROUND: false,
      SITE: false,
      PLAYERS: false,
      GROUPS: false,
    },
  }

  render() {
    const { open = false, tournament } = this.props
    const { options, isLoading } = this.state

    if (!tournament?.id) {
      return null
    }

    /**
     * Array storing checkbox data. OnChange sets the states to form a solid chain of active checkboxes.
     */
    const checkboxList: {
      name: string
      checked: boolean
      onChange: () => void
      label: JSX.Element
    }[] = [
      {
        name: 'ROUND',
        checked: options.ROUND,
        onChange: () =>
          this.setState({
            options: {
              ROUND: !options.ROUND,
              SITE: options.ROUND && options.SITE && false,
              PLAYERS: options.ROUND && options.PLAYERS && false,
              GROUPS: options.ROUND && options.GROUPS && false,
            },
          }),
        label: <FormattedMessageWrapper id={'options.tournamentCopyOptions.settings'} />,
      },
      {
        name: 'SITE',
        checked: options.SITE,
        onChange: () =>
          this.setState({
            options: {
              ...options,
              SITE: !options.SITE,
              PLAYERS: options.SITE && options.PLAYERS && false,
              GROUPS: options.SITE && options.GROUPS && false,
            },
          }),
        label: <FormattedMessageWrapper id={'options.tournamentCopyOptions.siteBranding'} />,
      },
      {
        name: 'PLAYERS',
        checked: options.PLAYERS,
        onChange: () =>
          this.setState({
            options: {
              ...options,
              PLAYERS: !options.PLAYERS,
              GROUPS: options.PLAYERS && options.GROUPS && false,
            },
          }),
        label: <FormattedMessageWrapper id={'options.tournamentCopyOptions.players'} />,
      },
      {
        name: 'GROUPS',
        checked: options.GROUPS,
        onChange: () =>
          this.setState({
            options: {
              ...options,
              GROUPS: !options.GROUPS,
            },
          }),
        label: <FormattedMessageWrapper id={'options.tournamentCopyOptions.startList'} />,
      },
    ]

    return (
      <Dialog maxWidth={'xs'} fullWidth open={open} aria-labelledby={'tournament-copy-title'}>
        <BaseDialogTitle
          id={'tournament-printouts-title'}
          title={tournament.name}
          subtitle={tournament.tournamentOrganization?.name}
          largeTitleText
        />

        <DialogContent>
          <Typography variant="h6">
            <FormattedMessageWrapper id={'tournament.copyTournamentDescription'} />
          </Typography>

          <FormControl fullWidth disabled={isLoading} style={{ marginTop: rem(10) }}>
            {checkboxList.map((item, index) => (
              <FormControlLabel
                key={item.name}
                control={
                  <Checkbox
                    color="primary"
                    name={item.name}
                    checked={item.checked}
                    onChange={item.onChange}
                    disabled={index > 0 && !checkboxList[index - 1]?.checked}
                  />
                }
                label={item.label}
              />
            ))}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button style={{ marginRight: 10 }} onClick={this._handleClose}>
            <FormattedMessageWrapper id={'buttons.close'} />
          </Button>
          <ButtonLoaderWrap loading={this.state.isLoading}>
            <Button disabled={this.state.isLoading} color="primary" variant={'contained'} onClick={this._handleCopy}>
              <FormattedMessageWrapper id={'buttons.copy'} />
            </Button>
          </ButtonLoaderWrap>
        </DialogActions>
      </Dialog>
    )
  }

  public _handleChange = (e: ChangeEvent<{ name?: string; value: unknown }>): void => {
    const { name, value } = e.target
    if (name) {
      this.setState({ [name]: value } as any)
    }
  }

  public _handleClose = () => {
    this.props.onClose()
    this.setState({
      isLoading: false,
      options: { ROUND: false, SITE: false, PLAYERS: false, GROUPS: false },
    })
  }

  public _handleCopy = () => {
    this.setState({ isLoading: true }, () => {
      const { copyTournament } = this.props
      const extent =
        Object.entries(this.state.options)
          .reverse()
          .find((item) => item[1] === true)?.[0] || 'TOURNAMENT'

      if (this._tournamentId) {
        copyTournament({
          tournamentId: this._tournamentId,
          extent: extent as TournamentCopy,
          onComplete: this._handleOnComplete,
        })
      }
    })
  }

  public get _tournamentId() {
    return this.props.tournament?.id
  }

  public _handleOnComplete = ({ error }: APICallResult): void => {
    this.setState({ isLoading: false, options: { ROUND: false, SITE: false, PLAYERS: false, GROUPS: false } }, () => {
      if (!error) {
        this.props.onClose()
      }
    })
  }
}

export default connect<{}, DispatchProps, OwnProps, RootState>(null, {
  copyTournament: tournamentApi.endpoints.copyTournament.initiate,
})(TournamentCopyDialog)
