import { Button, FormControl, FormLabel, MenuItem, OutlinedInput, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Select } from '@components/forms/Select'
import { rem } from '@app/theme/materialUITheme'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedDivision } from '@store/divisions/actions'
import { fetchTournamentDivisionLeaderboard } from '@app/store/tournamentDivisionLeaderboard/actions'
import { loadTournamentLeaderboard, loadTournamentContestLeaderboard } from '@store/tournamentLeaderboard/actions'
import { GameFormatTypes } from '@app/store/api/enums/tournamentEnums'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { RefreshIcon, PrintIcon, EditIcon } from '@app/assets/icons'
// eslint-disable-next-line max-len
import { TournamentLeaderboardInfoDialog } from '@app/components/dialogs/tournamentLeaderboardInfoDialog/TournamentLeaderboardInfoDialog'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'

const useStyles = makeStyles(() => ({
  settingsWrapper: {
    display: 'flex',
    fontWeight: 'normal',
    justifyContent: 'space-between',
    marginBottom: rem(16),
    width: '100%',
  },

  iconButton: {
    minWidth: 67,
    maxWidth: 67,
    width: 67,
    height: 40,
    '&&': {
      '& > span': {
        margin: 0,
        '& > svg': {
          fontSize: 26,
        },
      },
    },
  },
}))

interface LeaderboardSettingsProps {
  onRefreshClick: () => void
  onPrintClick: () => void
  gameType?: string
}

export const LeaderboardSettings = ({ onRefreshClick, onPrintClick, gameType }: LeaderboardSettingsProps) => {
  const [editLeaderboardInfoOpen, setEditLeaderboardInfoOpen] = useState<boolean>(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const tournament = useSelector(selectTournament)
  const tournamentSite = useSelector(selectTournamentSite)
  const tournamentConfig = useSelector(selectTournamentConfig)
  const { divisionsEnabled } = tournament
  const divisions = useSelector((state: RootState) => state.divisionsReducer)

  const onDivisionChange = (event) => {
    const divisionId = event.target.value as number
    dispatch(setSelectedDivision(divisionId))
    if (divisionId > 0) {
      dispatch(fetchTournamentDivisionLeaderboard({ id: tournament.id || 0, divisionId }))
    } else {
      dispatch(loadTournamentLeaderboard({ id: tournament.id || 0 }))
    }
    dispatch(loadTournamentContestLeaderboard(tournament.id || 0, divisionId))
  }

  const toggleLeaderboardInfoDialog = () => {
    setEditLeaderboardInfoOpen(!editLeaderboardInfoOpen)
  }

  const divisionOptions = useMemo(() => {
    const noneOption = { label: '-', value: 0 }
    if (tournament.id && divisions) {
      return [noneOption].concat(
        divisions.divisions
          .filter((division) => tournament.divisions.includes(division.id))
          .map((division) => ({ label: division.name, value: division.id })),
      )
    }
    return [noneOption]
  }, [divisions, tournament.id, tournament.divisions])

  return (
    <div className={classes.settingsWrapper}>
      <div style={{ width: '300px' }}>
        {divisionsEnabled && gameType !== GameFormatTypes.CONTESTS && (
          <FormControl fullWidth>
            <FormLabel htmlFor="division">
              <FormattedMessageWrapper id="tournament.division" />
            </FormLabel>
            <Select
              variant="filled"
              name="division"
              id="division"
              fullWidth
              value={divisions.selectedDivisionId || 0}
              input={<OutlinedInput name="division" id="division" />}
              onChange={onDivisionChange}
            >
              {divisionOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )}
      </div>
      <Stack spacing={2} direction="row">
        {tournamentSite.enabled && (
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleLeaderboardInfoDialog}
            className={classes.iconButton}
            startIcon={<EditIcon />}
          />
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={onPrintClick}
          className={classes.iconButton}
          startIcon={<PrintIcon />}
          disabled={tournamentConfig.contestEditMode}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onRefreshClick}
          sx={{ maxHeight: '45px' }}
          startIcon={<RefreshIcon />}
          disabled={tournamentConfig.contestEditMode}
        >
          <FormattedMessageWrapper id={'buttons.refreshScores'} />
        </Button>
      </Stack>

      {editLeaderboardInfoOpen && <TournamentLeaderboardInfoDialog close={toggleLeaderboardInfoDialog} />}
    </div>
  )
}
