import { View, StyleSheet, Image } from '@react-pdf/renderer'
import ggbLogo from '@assets/images/GGB-logo-bw.png'

const styles = StyleSheet.create({
  footer: {
    alignSelf: 'center',
  },
})

type FooterProps = {
  style?: any
}

export const Footer = ({ style }: FooterProps) => {
  return (
    <View style={{ ...styles.footer, ...style }} fixed>
      <Image src={ggbLogo} style={{ height: '15pt' }} />
    </View>
  )
}
