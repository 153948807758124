import { RootState } from '@app/store'
import { selectTournamentPlayers } from '../endpoints/tournamentPlayersApi'

export const getSelectedPlayers = (store: RootState) => {
  const { players, reserveList } = selectTournamentPlayers(store)?.data || {
    players: [],
    reserveList: [],
  }
  const selectedPoolPlayers = players.filter((player) => player.isSelected === true)
  const selectedReservedPlayers = reserveList.filter((player) => player.isSelected === true)

  return {
    selectedPoolPlayers,
    selectedReservedPlayers,
  }
}

export const getSelectedPlayerIds = (store: RootState) => {
  const { selectedPoolPlayers, selectedReservedPlayers } = getSelectedPlayers(store)

  return selectedPoolPlayers.concat(selectedReservedPlayers).map((player: TournamentPlayer) => player.id)
}
