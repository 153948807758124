import { Theme } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { rem } from '../../theme/materialUITheme'
import { Tooltip } from '@mui/material'
import { TooltipProps } from '@mui/material/Tooltip'

const styles = (theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1000,
    },
    tooltip: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: `rgba(255, 11, 24, 0.67)`,
      padding: rem(8),
      maxWidth: 'none',
      '@global': {
        '.tooltip-text': {
          whiteSpace: 'pre-line',
          fontSize: rem(14),
          lineHeight: 1.45,
          fontFamily: theme.typography.body2.fontFamily,
        },
      },
    },
  })

const ErrorTooltip = ({ classes, children, title, ...rest }: WithStyles<typeof styles> & TooltipProps) => (
  <Tooltip classes={classes} title={<p className={'tooltip-text'}>{title}</p>} {...rest}>
    {children}
  </Tooltip>
)

export default withStyles(styles)(ErrorTooltip)
