import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { sortByDateTime } from '@app/utils/sortUtils'

export const bracketsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationMatchPlayBrackets: build.query<MatchPlayBracket[], number>({
      query: (organizationId: number) => APIRoute.GET_ORGANIZATION_MATCH_PLAY_BRACKETS(organizationId),
      transformResponse: (response: MatchPlayBracket[]) =>
        response.sort((a, b) => sortByDateTime(a.createdAt, b.createdAt, 'desc')),

      providesTags: [CacheTag.MATCH_PLAY_BRACKET],
    }),
    getMatchPlayBracket: build.query<MatchPlayBracket, number>({
      query: (bracketId: number) => APIRoute.GET_MATCH_PLAY_BRACKET(bracketId),

      providesTags: [CacheTag.MATCH_PLAY_BRACKET_SINGLE],
    }),
    createMatchPlayBracket: build.mutation<MatchPlayBracket, CreateMatchPlayBracketPayload>({
      query: (payload: CreateMatchPlayBracketPayload) => ({
        url: APIRoute.POST_CREATE_MATCH_PLAY_BRACKET,
        method: HttpMethod.POST,
        body: payload.body,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: bracket } = await queryFulfilled
          dispatch(
            bracketsApi.util.updateQueryData('getOrganizationMatchPlayBrackets', payload.organizationId, (brackets) => {
              brackets?.unshift(bracket)
            }),
          )
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.MATCH_PLAY_BRACKET]))
          console.error('Error creating match play bracket')
        }
      },
    }),
    updateMatchPlayBracket: build.mutation<MatchPlayBracket, UpdateMatchPlayBracketPayload>({
      query: (payload: UpdateMatchPlayBracketPayload) => ({
        url: APIRoute.PUT_EDIT_MATCH_PLAY_BRACKET(payload.id),
        method: HttpMethod.PUT,
        body: payload.body,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: bracket } = await queryFulfilled
          dispatch(
            bracketsApi.util.updateQueryData('getMatchPlayBracket', payload.id, () => {
              return bracket
            }),
          )
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.MATCH_PLAY_BRACKET_SINGLE]))
          console.error('Error updating match play bracket')
        }
      },
      invalidatesTags: [CacheTag.MATCH_PLAY_BRACKET],
    }),
    deleteMatchPlayBracket: build.mutation<void, number>({
      query: (bracketId: number) => ({
        url: APIRoute.DELETE_MATCH_PLAY_BRACKET(bracketId),
        method: HttpMethod.DELETE,
      }),
      invalidatesTags: [CacheTag.MATCH_PLAY_BRACKET, CacheTag.MATCH_PLAY_BRACKET_SINGLE],
    }),
    uploadMatchPlayBracketImage: build.mutation<void, MatchPlayBracketImageRequest>({
      query: (payload: MatchPlayBracketImageRequest) => ({
        url: APIRoute.POST_UPLOAD_MATCH_PLAY_BRACKET_IMAGE(payload.bracketId),
        method: HttpMethod.POST,
        body: payload.data,
      }),
      invalidatesTags: [CacheTag.MATCH_PLAY_BRACKET, CacheTag.MATCH_PLAY_BRACKET_SINGLE],
    }),
    deleteMatchPlayBracketPlayer: build.mutation<void, DeleteMatchPlayBracketPlayerPayload>({
      query: (paylod: DeleteMatchPlayBracketPlayerPayload) => ({
        url: APIRoute.DELETE_MATCH_PLAY_BRACKET_PLAYER(paylod.bracketId, paylod.playerId),
        method: HttpMethod.DELETE,
      }),
      invalidatesTags: [CacheTag.MATCH_PLAY_BRACKET, CacheTag.MATCH_PLAY_BRACKET_SINGLE],
    }),
  }),
})

export const {
  useGetOrganizationMatchPlayBracketsQuery,
  useGetMatchPlayBracketQuery,
  useCreateMatchPlayBracketMutation,
  useUpdateMatchPlayBracketMutation,
  useDeleteMatchPlayBracketMutation,
  useUploadMatchPlayBracketImageMutation,
  useDeleteMatchPlayBracketPlayerMutation,
} = bracketsApi
