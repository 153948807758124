import { formatDate } from './dates'

export const currentlyOngoingRoundIndex = (rounds: Round[]): number => {
  // Find rounds with ongoing scoring
  let roundIndex: number = rounds.findIndex((r) => r.scoringStarted && !r.completed)

  // Find rounds with scoring not started if not found
  if (roundIndex < 0) {
    roundIndex = rounds.findIndex((r) => !r.scoringStarted && !r.completed)
  }

  // If not found, check if last round scoring has already ended
  if (roundIndex < 0 && rounds[rounds.length - 1].scoringStarted && rounds[rounds.length - 1].completed) {
    roundIndex = rounds.length - 1
  }

  // If no round index found, return first round
  return roundIndex >= 0 ? roundIndex : 0
}

const STABLEFORD_GAME_FORMATS = [3, 9, 15, 16, 18, 7, 8, 20]

export const showStablefordPoints = (id: number) => STABLEFORD_GAME_FORMATS.includes(id)

export const maxNoteLength = 200

/**
 * Get country flag image url by country code.
 *
 * @param countryCode
 */
export const getCountryFlagImageUrl = (countryCode: string): string => {
  return `https://static.golfgamebook.com/flags/${countryCode.toUpperCase()}.png`
}

/**
 * Convert id values from leaderboard to integers that are sendable to backend
 * @param value string | number | undefined
 * @returns number | null
 */
export const convertLBIdStringToNumber = (value: string | number | undefined): number => {
  if (typeof value === 'undefined') {
    return 0
  }
  if (typeof value === 'number') {
    return value
  }
  const returnVal = value.includes(':') ? value.split(':')[1] : value

  return Number(returnVal)
}

export type DateUnits = OrganizationUnits

/**
 * If round is completed without isConfigured being set to true, UI should not handle round as completed
 * @param selectedRoundIndex round index
 * @param rounds all rounds as Round[]
 * @returns boolean
 */
export const verifyIfRoundIsCompleted = (selectedRoundIndex: number, rounds: Round[]): boolean => {
  const round = rounds[selectedRoundIndex]
  if (!round) {
    return false
  }
  if (round.status?.isConfigured === false && round.status?.isCompleted === true) {
    return false
  } else {
    return !!round.status?.isCompleted
  }
}

const INCH_TO_METERS = 0.0254
const INCH_TO_YARDS = 36
const INCH_TO_FEETS = 12
const METERS_TO_INCH = 39.37

/**
 *
 * @param measurement Format contest result measurement
 * @param units
 * @returns string
 */
export const formatMeasurement = (measurement: number, units: ContestUnits): string => {
  if (measurement < 0) {
    return ''
  }

  if (measurement > 2000) {
    if (units === 'metric') {
      return `${Math.round(measurement * INCH_TO_METERS)} m`
    } else {
      return `${Math.round(measurement / INCH_TO_YARDS)} yards`
    }
  } else {
    if (units === 'metric') {
      const meters = (measurement * INCH_TO_METERS).toFixed(2)

      return `${meters} m`
    } else {
      return `${Math.floor(measurement / INCH_TO_FEETS)} ft ${Math.round(measurement % INCH_TO_FEETS)} in`
    }
  }
}

export const parseMeasurement = (measurement: number, units: ContestUnits) => {
  let unit = 0
  let subUnit = 0

  if (measurement > 2000) {
    if (units === 'metric') {
      unit = Math.round(measurement * INCH_TO_METERS)
    } else {
      unit = Math.round(measurement / INCH_TO_YARDS)
    }
  } else {
    if (units === 'metric') {
      const meters = (measurement * INCH_TO_METERS).toFixed(2).split('.')

      const meter = meters[0]
      const cm = meters[1]

      unit = Number(meter)
      subUnit = Number(cm)
    } else {
      unit = Math.floor(measurement / INCH_TO_FEETS)
      subUnit = Math.round(measurement % INCH_TO_FEETS)
    }
  }

  return { unit: unit, subUnit: subUnit }
}

export const createMeasurement = (
  unit: number,
  subUnit: number,
  units: ContestUnits,
  biggerUnits?: boolean,
): number => {
  if (biggerUnits) {
    if (units === 'metric') {
      return Math.round(unit * METERS_TO_INCH)
    } else {
      return Math.round(unit * INCH_TO_YARDS)
    }
  } else {
    if (units === 'metric') {
      const meters = unit + subUnit / 100
      return meters / INCH_TO_METERS
    } else {
      return unit * INCH_TO_FEETS + subUnit
    }
  }
}

/**
 * GGB green
 */
export const DEFAULT_TOURNAMENT_SITE_BRANDING_COLOR = '#1AA85D'

const colorValidationPattern = /^#[0-9A-Fa-f]{6}$/i
export const validatePrimaryColor = (code: string) => colorValidationPattern.test(code)

export const storageImportId = 'importId'
export const storageTempImportId = 'tempImportId'
export const storageTournamentId = 'tournamentId'
export const storageGolferIds = 'storageGolferIds'
export const storageGitResults = 'storageGitResults'

export const getStorageGolferIds = () => (localStorage.getItem(storageGolferIds) || '')?.split(',')
export const removeStorageItems = (storageIds: string[]) => storageIds.forEach((id) => localStorage.removeItem(id))
export const clearGitImportStorage = () => {
  const items = [storageImportId, storageTempImportId, storageTournamentId, storageGolferIds, storageGitResults]
  items.forEach((item) => localStorage.removeItem(item))
}

export const setStorageGolferIds = (playerIdsString: string) => {
  const playerIdsList = playerIdsString.split(/\n/)
  localStorage.setItem(storageGolferIds, playerIdsList.toString())
}

/**
 * Creates new list with player, id, name, email, startTime and teeNumber
 * @param startList
 * @param playerList
 * @param selectedRoundId
 * @param units
 * @returns Array<EmailPlayersList>
 */
export const createEmailPlayerList = (
  startList: StartListRound[],
  tournamentPlayers: TournamentPlayer[],
  selectedRoundId: number,
  units: OrganizationUnits,
): EmailPlayersList[] => {
  const emailPlayerList: EmailPlayersList[] = []
  tournamentPlayers.map(
    (player) =>
      startList
        .find((round) => round.tournamentRoundId === selectedRoundId)
        ?.groups.map((group) =>
          group.startListPlayers.map(
            (startListPlayer) =>
              startListPlayer.tournamentPlayerId === player.id &&
              !emailPlayerList.some((newPlayer) => newPlayer.id === player.id) &&
              emailPlayerList.push({
                id: startListPlayer.id,
                name: `${player.firstName} ${player.lastName}`,
                teeNumber: group.teeNumber,
                startTime: formatDate(group.startTime, 'time', units),
                email: player?.email,
                hasEmail: player.hasEmail,
              }),
          ),
        ),
  )
  return emailPlayerList
}

/**
 * We consider tournament published if at least one of its rounds is published
 */
export const isTournamentPublished = (tournament: TournamentState): boolean => {
  return tournament.rounds.some((round) => !!round.status?.isConfigured)
}
