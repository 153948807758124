import StartGroup from '@components/tournament/StartGroup'
import makeStyles from '@mui/styles/makeStyles'

interface GroupsListProps {
  groups: StartListGroup[]
  basicGroupSize: number
  confirmPlayerRemove: boolean
  primaryGameFormatId: number
  dialogArgs: any
  removePlayerAction: (playerId: number, index: number) => void
  disableTee?: boolean
  isStartListPublished: boolean
  disableTeamPlayerEdit: boolean
  hideFullGroups: boolean
  fullGroupSize: number
}

const useStyles = makeStyles(() => ({
  groupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
  },
}))

export const GroupsList: React.FC<GroupsListProps> = ({
  groups,
  basicGroupSize,
  confirmPlayerRemove,
  primaryGameFormatId,
  dialogArgs,
  removePlayerAction,
  disableTee,
  isStartListPublished,
  disableTeamPlayerEdit,
  hideFullGroups,
  fullGroupSize,
}) => {
  const classes = useStyles()
  let groupTeamIndex = 0
  const result = groups.map((group, index) => {
    // Can't use groups filtering, because index is used in group name.
    if (hideFullGroups && group.startListPlayers.length >= fullGroupSize) {
      return null
    }

    const content = (
      <StartGroup
        group={group}
        key={group.id}
        index={index}
        groupTeamIndex={groupTeamIndex}
        basicGroupSize={basicGroupSize}
        confirmPlayerRemove={confirmPlayerRemove}
        primaryGameFormatId={primaryGameFormatId}
        removePlayerAction={(playerId: number) => {
          removePlayerAction(playerId, index)
        }}
        disableTee={disableTee}
        isStartListPublished={isStartListPublished}
        disableTeamPlayerEdit={disableTeamPlayerEdit}
        {...dialogArgs}
      />
    )

    if (group.teams) {
      groupTeamIndex = groupTeamIndex + group.teams.length
    }

    return content
  })

  return <div className={classes.groupContainer}>{result}</div>
}
