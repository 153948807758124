import { api } from '../baseApi'
import { APIRoute } from '../../../config'
import { setSelectedCountryId } from '../slices/configSlice'
import { RootState } from '@app/store'

export const countriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query<Country[], void>({
      query: () => APIRoute.GET_COUNTRIES,
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: countries } = await queryFulfilled
          const currentCountryId = (getState() as RootState).configReducer.clubsAndCourses.selectedCountryId
          if (countries.length === 1 && currentCountryId < 0) {
            dispatch(setSelectedCountryId(countries[0].id))
          }
        } catch {
          console.error('Error fetching countries')
        }
      },
      transformResponse: (response: { countries: Country[] }) => {
        return response.countries
      },
    }),
    getCountryStates: build.query<CountryState[], number>({
      query: (countryId: number) => APIRoute.GET_COUNTRY_STATES(countryId),
      transformResponse: (response: { states: CountryState[] }) => {
        return response.states
      },
    }),
  }),
})

export const selectCountries = (state: RootState) => countriesApi.endpoints.getCountries.select()(state)
export const selectCountryStates = (state: RootState, countryId: number) =>
  countriesApi.endpoints.getCountryStates.select(countryId)(state)

export const { useGetCountriesQuery, useGetCountryStatesQuery } = countriesApi
