import ContentWrap from '@app/components/layout/ContentWrap'
import TopBar from '@app/components/layout/TopBar'
import { DivisionsList } from './DivisionsList'

export const Divisions: React.FC = () => {
  return (
    <>
      <TopBar titleId="navigation.divisions" />

      <ContentWrap>
        <div style={{ marginTop: 20 }}>
          <DivisionsList isSelecteable={false} isEditable={true} />
        </div>
      </ContentWrap>
    </>
  )
}
