import React from 'react'
import {
  Dialog,
  DialogActions,
  Typography,
  Grid,
  Theme,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { rem } from '@app/theme/materialUITheme'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import { PLAYER_LIST_ITEM_HEIGHT } from '@app/components/tournament/ListPlayer'
import { formatHandicap } from '@app/utils/playerUtils'
import classNames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: rem(24),
      '& h2': {
        color: theme.customPalette.dark,
        fontFamily: theme.fonts.primary,
        fontSize: rem(18),
        fontWeight: 600,
      },
    },
    cancelButton: {
      color: 'red',
      border: '1px solid red',
    },
    selectableBoxContainer: {
      backgroundColor: theme.customPalette.lightGray,
      padding: rem(20),
      borderRadius: '6px',
      border: `1px solid ${theme.palette.common.white}`,
    },
    selectedBox: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    playerRowContainer: {
      height: PLAYER_LIST_ITEM_HEIGHT,
      background: theme.customPalette.bodyBackground,
      borderRadius: '4px',
      marginBottom: theme.spacing(0.5),
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      overflow: 'hidden',
    },
    playerRowText: {
      fontSize: rem(13),
      padding: `0 ${rem(10)}`,
      whiteSpace: 'nowrap',
    },
    playerRowLabel: {
      width: '100%',
    },
    teamHeader: {
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontSize: rem(16),
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      padding: `${rem(7)} ${rem(10)}`,
      borderRadius: '4px',
      marginBottom: theme.spacing(0.8),
    },
  })

export interface AddPlayersDialogProps {
  isOpen: boolean
  onClose(): void
  onSave(mode: string): void
  player: TournamentPlayer
  team?: TournamentTeam
}

type Mode = 'individual' | 'team'

enum Modes {
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

interface State {
  mode: Mode
}

const initialState = {
  mode: 'individual' as Mode,
}

type Props = AddPlayersDialogProps & WrappedComponentProps & WithStyles<typeof styles>

class AddPlayersDialogComponent extends React.Component<Props, State> {
  readonly state = initialState

  render() {
    const { isOpen = false, onClose, onSave, classes } = this.props

    return (
      <>
        <Dialog open={isOpen} onClose={(_, reason) => reason !== 'backdropClick' && onClose()} maxWidth="md">
          <BaseDialogTitle
            id="tournament-remove-players-dialog"
            onClose={onClose}
            title={<FormattedMessageWrapper id={'tournament.addToPlayerPool'} />}
            largeTitleText
          />

          <DialogContent>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="body1">
                  <FormattedMessageWrapper id="tournament.addTeamToPlayerPoolDesc" />
                </Typography>
              </Grid>
              <Grid item>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                  <RadioGroup name="groupAction" onChange={this._onSelectChange} defaultValue={'individual'}>
                    <FormControlLabel
                      value="individual"
                      control={<Radio />}
                      classes={{ label: classes.playerRowLabel }}
                      label={<this.selectableBoxIndividual />}
                    />
                    <FormControlLabel
                      style={{ marginTop: rem(20) }}
                      value="team"
                      control={<Radio />}
                      classes={{ label: classes.playerRowLabel }}
                      label={<this.selectableBoxTeam />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </DialogContent>

          <DialogActions style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button onClick={onClose} variant={'outlined'} classes={{ outlined: classes.cancelButton }}>
              <FormattedMessageWrapper id={'buttons.cancel'} />
            </Button>
            <Button color="primary" variant={'contained'} onClick={() => onSave(this.state.mode)}>
              <FormattedMessageWrapper id={'tournament.addToPlayerPool'} />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  private playerRow = (player: TournamentPlayer, index?: number) => {
    const { classes } = this.props

    return (
      <Grid container className={classes.playerRowContainer} key={`playerRow_${index}`}>
        <Grid item xs={5}>
          <Typography className={classes.playerRowText}>
            {player.firstName} {player.lastName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.playerRowText}>{formatHandicap(String(player.hcp))}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.playerRowText}>{player.club?.name}</Typography>
        </Grid>
      </Grid>
    )
  }

  private selectableBoxIndividual = () => {
    const { mode } = this.state
    const { classes, player } = this.props
    return (
      <div className={classNames([classes.selectableBoxContainer, mode === Modes.INDIVIDUAL && classes.selectedBox])}>
        {this.playerRow(player)}
      </div>
    )
  }

  private selectableBoxTeam = () => {
    const { team, classes } = this.props
    const { mode } = this.state
    const teamPlayerList = team?.players.map((player, index) => this.playerRow(player, index))

    return (
      <div className={classNames([classes.selectableBoxContainer, mode === Modes.TEAM && classes.selectedBox])}>
        <div className={classes.teamHeader}>{team?.name || ''}</div>
        <div>{teamPlayerList}</div>
      </div>
    )
  }

  private _onSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ mode: event.target.value as Mode })
  }
}

export const AddPlayersDialog = injectIntl(withStyles(styles)(AddPlayersDialogComponent))
