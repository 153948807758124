import { SET_LANGUAGE } from './constants'
import { Action } from 'redux'

export interface SetLanguage extends Action {
  type: 'SET_LANGUAGE'
  language: LanguageOption
}

export const setLanguage = (language: LanguageOption) => ({
  type: SET_LANGUAGE,
  language,
})
