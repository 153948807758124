import { RootState } from '@app/store'
import { selectWeeklyScoreRounds, tournamentWeeklyApi } from '../endpoints/tournamentWeeklyApi'

export const getTournamentWeeklyPlayerScores = (payload: TournamentWeeklyScoresPayload, forceRefetch = false) => {
  return (dispatch, getState: () => RootState) => {
    const scores = selectWeeklyScoreRounds(getState(), payload)
    const isCached = scores !== undefined

    if (isCached && payload.onSuccess && !forceRefetch) {
      payload.onSuccess()
      return
    }

    dispatch(tournamentWeeklyApi.endpoints.getWeeklyPlayerScores.initiate(payload, { forceRefetch }))
  }
}
