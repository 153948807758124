import { flattenGameFormats, gameFormatGuardExecutor, getFieldNameFromLabel } from '@app/utils/gameFormatUtils'
import { RootState } from '../..'
import { getToBeDeletedFields } from '@app/utils/sagaHelpers'
import {
  getTournamentId,
  internalSetSelectedClubId,
  internalSetSelectedCourseId,
  internalSetSelectedCourseVersion,
  resetConfigs,
  setSelectedCountryId,
  setSelectedRoundId,
  setSelectedRoundIndex,
  setTournamentDirty,
} from '../slices/configSlice'
import { resetTournamentLeaderboard } from '../../tournamentLeaderboard/actions'
import { clearPlayersSearch } from '../../players/actions'
import { resetStartLists } from '../slices/tournamentStartListsSlice'
import { tournamentSettingsApi } from '../endpoints/tournamentSettingsApi'
import { tournamentSiteApi } from '../endpoints/tournamentSiteApi'
import { tournamentApi, selectTournament } from '../endpoints/tournamentApi'
import { tournamentTeamsApi } from '../endpoints/tournamentTeamsApi'
import { fetchAllStartLists } from './tournamentStartListsThunks'
import { getSelectedRound } from '../selectors/tournamentSelectors'
import { getCourses, setSelectedCourseId } from './clubsAndCoursesThunks'
import {
  internalRemoveTournamentRoundCourses,
  resetClubsAndCourses,
  selectSelectedRoundCourse,
} from '../slices/clubsAndCoursesSlice'
import { clearGitImportStorage } from '@app/utils/tournamentUtils'
import { get, last, omit } from 'lodash'
import { emptyContestOptions, emptyTournament } from '../emptyObjects'
import { getRoundStartTime } from '../utils/tournamentRoundUtils'
import { validMaleTeeBoxes, validFemaleTeeBoxes } from '@app/utils/clubAndCourseUtils'
import { maybeRefetchTournamentPlayers } from './tournamentPlayersThunks'
import { maybeRefetchTournamentTeams } from './tournamentTeamsThunks'
import { TournamentTypes } from '../enums/tournamentEnums'
import { api } from '@store/api/baseApi'
import { CacheTag } from '@store/api/cacheTags'

export const resetTournament = () => {
  return (dispatch) => {
    dispatch(tournamentApi.util.resetApiState())
    dispatch(tournamentSettingsApi.util.resetApiState())
    dispatch(tournamentSiteApi.util.resetApiState())
    dispatch(resetConfigs())
    dispatch(resetTournamentLeaderboard())
    dispatch(clearPlayersSearch())
    dispatch(resetStartLists())
    dispatch(resetClubsAndCourses())
    dispatch(createEmptyTournament())
    clearGitImportStorage()
  }
}

export const createEmptyTournament = () => {
  return (dispatch) => {
    const id = emptyTournament.id
    dispatch(tournamentApi.util.upsertQueryData('getTournament', { id }, emptyTournament))
  }
}

export const updateRoundField = (payload: UpdateRoundFieldPayload, setDirty?: boolean) => {
  return (dispatch, getState: () => RootState) => {
    dispatch(internalUpdateRoundField(payload))

    if (['primaryGameId', 'sideGameId'].includes(payload.fieldName)) {
      const updateAction = payload.fieldName === 'primaryGameId' ? updateMainGameFormat : updateSideGameFormat
      const unsetAction = payload.fieldName === 'primaryGameId' ? unsetMainGameFields : unsetSideGameFields

      const { gameFormats, gameFormatAdditionalOptions } = getState().gameFormatsReducer
      const flattenedGameFormats = flattenGameFormats(gameFormats)
      const currentGameFormat = flattenedGameFormats.find((gf) => gf.id === payload.value)

      if (currentGameFormat && currentGameFormat.additionalOptions) {
        const additionalOptions = gameFormatAdditionalOptions
        const currentOptions = additionalOptions[currentGameFormat.additionalOptions]
        const fieldsToUnset = getToBeDeletedFields(additionalOptions, currentOptions)

        // get current holecount
        const guardValues = {
          holeCount: selectTournament(getState()).rounds[payload.index].holes,
        }

        if (Array.isArray(currentOptions)) {
          // iterate over a set of fields
          currentOptions
            .filter((field) => gameFormatGuardExecutor(guardValues, field.guard))
            .forEach((field) =>
              dispatch(
                updateAction({
                  index: payload.index,
                  fieldName: getFieldNameFromLabel(field.label),
                  value: field.default,
                }),
              ),
            )
        } else {
          // only one field
          if (gameFormatGuardExecutor(guardValues, currentOptions.guard)) {
            dispatch(
              updateAction({
                index: payload.index,
                fieldName: getFieldNameFromLabel(currentOptions.label),
                value: currentOptions.default,
              }),
            )
          }
        }
        dispatch(unsetAction({ index: payload.index, fields: fieldsToUnset }))
      }
    }
    dispatch(setTournamentDirty(setDirty !== false))
  }
}

export const updateGlobalHoleConfig = (payload: UpdateRoundFieldPayload) => {
  return (dispatch, getState: () => RootState) => {
    const numberOfRounds: number = selectTournament(getState()).rounds.length
    Array.from({ length: numberOfRounds }, (c, i) => dispatch(updateRoundField({ ...payload, index: i })))
  }
}

export const updateGlobalMainGameFormat = (payload: GameFormatPayload) => {
  return (dispatch, getState: () => RootState) => {
    const numberOfRounds: number = selectTournament(getState()).rounds.length
    Array.from({ length: numberOfRounds }, (c, i) => dispatch(updateMainGameFormat({ ...payload, index: i })))
  }
}

export const updateGlobalSideGameFormat = (payload: GameFormatPayload) => {
  return (dispatch, getState: () => RootState) => {
    const numberOfRounds: number = selectTournament(getState()).rounds.length
    Array.from({ length: numberOfRounds }, (c, i) => dispatch(updateSideGameFormat({ ...payload, index: i })))
  }
}

export const addRound = () => {
  return (dispatch, getState: () => RootState) => {
    dispatch(internalAddRound())
    const numberOfRounds: number = selectTournament(getState()).rounds.length
    dispatch(setSelectedRoundIndex(numberOfRounds - 1))
    dispatch(setSelectedRoundId(-1))
    dispatch(setTournamentDirty(true))
  }
}

export const removeRound = () => {
  return (dispatch, getState: () => RootState) => {
    const { selectedRoundId, selectedRoundIndex } = getState().configReducer.tournament
    dispatch(internalRemoveRound(selectedRoundId))
    dispatch(internalRemoveTournamentRoundCourses(selectedRoundIndex))

    dispatch(setTournamentDirty(false))
  }
}

export const loadAllListsInSync = (tournamentId: number) => {
  return (dispatch, getState: () => RootState) => {
    dispatch(tournamentApi.endpoints.getTournament.initiate({ id: tournamentId }))
    dispatch(maybeRefetchTournamentPlayers(tournamentId))
    dispatch(tournamentTeamsApi.endpoints.getTeams.initiate(tournamentId))
      .unwrap()
      .then(() => {
        dispatch(fetchAllStartLists())
      })
      .catch(() => {
        console.error('Error loading teams')
        dispatch(fetchAllStartLists())
      })
    dispatch(maybeRefetchTournamentTeams(tournamentId))

    const selectedRound = getSelectedRound(getState())
    if (selectedRound && selectedRound.clubId?.value) {
      const timestamp = getRoundStartTime(selectedRound)
      dispatch(getCourses({ clubId: selectedRound.clubId?.value, timestamp }))
    }
  }
}

export const getTournament = (payload: TournamentPayload) => {
  return (dispatch, getState: () => RootState) => {
    // TODO: Remove this if and dispatch. This is a temporary bubble gum fix for TM-5260.
    if (window.location.href.includes('results-and-options')) {
      dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_SINGLE]))
    }
    const tournament = selectTournament(getState())
    const isCached = tournament !== undefined
    if (isCached && payload.onSuccess) {
      payload.onSuccess()
      return
    }
    dispatch(tournamentApi.endpoints.getTournament.initiate(payload))
  }
}

export const handleDefaultTeeBoxes = (courseTees: CourseTee[], setDirty = false) => {
  return (dispatch, getState: () => RootState) => {
    const selectedRoundIndex = getState().configReducer.tournament.selectedRoundIndex
    const maleTees = validMaleTeeBoxes(courseTees)
    const selectedRound = getSelectedRound(getState())

    // Compare new teebox IDs to the previous selection,
    // fallback to defaults if no match (teeboxes don't exist in new course version)
    const matchingMenTeeBox = maleTees.find((tee) => tee.id === selectedRound.menTeeBoxId)

    if (!matchingMenTeeBox) {
      let mensTeeboxId = get(maleTees, '[1].id')
      if (!mensTeeboxId || maleTees.length === 2) {
        // default to the first, if second isn't available
        mensTeeboxId = get(maleTees, '[0].id')
      }

      dispatch(
        updateRoundField(
          {
            index: selectedRoundIndex,
            fieldName: 'menTeeBoxId',
            value: mensTeeboxId ? mensTeeboxId : undefined,
          },
          setDirty,
        ),
      )
    }

    // shortest or last
    const femaleTees = validFemaleTeeBoxes(courseTees)
    const matchingWomenTeeBox = femaleTees.find((tee) => tee.id === selectedRound.womenTeeBoxId)

    if (!matchingWomenTeeBox) {
      const womensTeeboxId = get(last(femaleTees), 'id')

      dispatch(
        updateRoundField(
          {
            index: selectedRoundIndex,
            fieldName: 'womenTeeBoxId',
            value: womensTeeboxId ? womensTeeboxId : undefined,
          },
          setDirty,
        ),
      )
    }
  }
}

export const handleDefaultHolesCount = () => {
  return (dispatch, getState: () => RootState) => {
    const currentCourse = selectSelectedRoundCourse(getState())
    if (!currentCourse) {
      return
    }

    const selectedRoundIndex = getState().configReducer.tournament.selectedRoundIndex

    // To us all courses have 18 holes (9+9)
    dispatch(
      updateRoundField({
        index: selectedRoundIndex,
        fieldName: 'holes',
        value: '18',
      }),
    )
  }
}

export const handleDefaultStartingHole = () => {
  return (dispatch, getState: () => RootState) => {
    const tournament = selectTournament(getState())
    const currentCourse = selectSelectedRoundCourse(getState())

    if (tournament.tournamentType !== TournamentTypes.weekly || !currentCourse) {
      return
    }

    const selectedRound = getSelectedRound(getState())
    const currentStartingHole = selectedRound.defaultStartingHole

    if (currentStartingHole) {
      const currentStartingHoleStillValid = currentCourse.holes.find((hole) => hole.id === currentStartingHole.id)
      if (currentStartingHoleStillValid) {
        return
      }
    }

    const selectedRoundIndex = getState().configReducer.tournament.selectedRoundIndex
    const firstHoleId = currentCourse.holes[0]?.id || 0

    dispatch(
      updateRoundField({
        index: selectedRoundIndex,
        fieldName: 'defaultStartingHole',
        value: { id: firstHoleId, holeNumber: 1 },
      }),
    )
  }
}

export const setInitialCourseDataToNewTournament = (course: Course) => {
  return (dispatch, getState: () => RootState) => {
    const selectedRoundIndex = getState().configReducer.tournament.selectedRoundIndex
    dispatch(setSelectedCourseId(course.id))
    dispatch(handleDefaultTeeBoxes(course.teeBoxes))
    const setDirty = false
    dispatch(
      updateRoundField(
        {
          index: selectedRoundIndex,
          fieldName: 'courseId',
          value: course.id,
        },
        setDirty,
      ),
    )
  }
}

// Manual cache updates start here
export const updateTournamentField = (payload: FieldUpdatePayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    dispatch(saveTournamentData({ ...data, [payload.fieldName]: payload.value }))
    dispatch(setTournamentDirty(true))
  }
}

export const updateMainGameFormat = (payload: GameFormatPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const updatedData = {
      ...data,
      rounds: data.rounds.map((round, i) => {
        if (i !== payload.index) {
          return round
        }
        if (payload.type === 'generic') {
          return {
            ...round,
            genericGameOptions: {
              ...round.genericGameOptions,
              [payload.fieldName]: payload.value,
            },
          }
        }
        const next = { ...round }
        next.primaryGameOptions = {
          ...round.primaryGameOptions,
          [payload.fieldName]: payload.value,
        }
        if (payload.fieldName === 'usePlayingHandicaps' && payload.value) {
          next.sideGameOptions = {
            ...round.sideGameOptions,
            usePlayingHandicaps: payload.value as boolean,
          }
        }
        return next
      }),
    }

    dispatch(saveTournamentData(updatedData))
    dispatch(setTournamentDirty(true))
  }
}

export const updateSideGameFormat = (payload: GameFormatPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const updatedData = {
      ...data,
      rounds: [
        ...data.rounds.slice(0, payload.index),
        {
          ...data.rounds[payload.index],
          sideGameOptions: {
            ...data.rounds[payload.index].sideGameOptions,
            [payload.fieldName]: payload.value,
          },
        },
        ...data.rounds.slice(payload.index + 1),
      ],
    }
    dispatch(saveTournamentData(updatedData))
    dispatch(setTournamentDirty(true))
  }
}

export const unsetMainGameFields = (payload: UnsetGameFieldsPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const updatedData = {
      ...data,
      rounds: [
        ...data.rounds.slice(0, payload.index),
        {
          ...data.rounds[payload.index],
          primaryGameOptions: {
            ...(omit(data.rounds[payload.index].primaryGameOptions, payload.fields) as GameFormatOptions),
          },
        },
        ...data.rounds.slice(payload.index + 1),
      ],
    }
    dispatch(saveTournamentData(updatedData))
  }
}

export const unsetSideGameFields = (payload: UnsetGameFieldsPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const updatedData = {
      ...data,
      rounds: [
        ...data.rounds.slice(0, payload.index),
        {
          ...data.rounds[payload.index],
          sideGameOptions: {
            ...(omit(data.rounds[payload.index].sideGameOptions, payload.fields) as GameFormatOptions),
          },
        },
        ...data.rounds.slice(payload.index + 1),
      ],
    }
    dispatch(saveTournamentData(updatedData))
  }
}

export const updateContests = (payload: ContestsPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const updatedData = {
      ...data,
      rounds: [
        ...data.rounds.slice(0, payload.index),
        {
          ...data.rounds[payload.index],
          contestOptions: {
            ...data.rounds[payload.index].contestOptions,
            [payload.fieldName]: payload.value,
          },
        },
        ...data.rounds.slice(payload.index + 1),
      ],
    }
    dispatch(saveTournamentData(updatedData))
    dispatch(setTournamentDirty(true))
  }
}

export const resetContests = (roundIndex: number) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const updatedData = {
      ...data,
      rounds: [
        ...data.rounds.slice(0, roundIndex),
        {
          ...data.rounds[roundIndex],
          contestOptions: {
            ...emptyContestOptions,
          },
        },
        ...data.rounds.slice(roundIndex + 1),
      ],
    }
    dispatch(saveTournamentData(updatedData))
  }
}

export const resetCourseSettingsData = () => {
  return (dispatch) => {
    dispatch(resetClubsAndCourses())
    dispatch(setSelectedCountryId(-1))
    dispatch(internalSetSelectedClubId(-1))
    dispatch(internalSetSelectedCourseId(-1))
    dispatch(internalSetSelectedCourseVersion(-1))
  }
}

const internalUpdateRoundField = (payload: UpdateRoundFieldPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())

    if (data.rounds[payload.index].id === undefined) {
      return
    }

    const updatedData = {
      ...data,
      rounds: [
        ...data.rounds.slice(0, payload.index),
        {
          ...data.rounds[payload.index],
          [payload.fieldName]: payload.value,
        },
        ...data.rounds.slice(payload.index + 1),
      ],
    }
    dispatch(saveTournamentData(updatedData))
  }
}

const internalAddRound = () => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const newRound: Round = Object.assign({}, data.rounds[data.rounds.length - 1]) as Round
    newRound.id = -1
    /**
     * Add one day to start time
     */
    const startTime = new Date(newRound.startTime)
    startTime.setDate(startTime.getDate() + 1)
    newRound.startTime = startTime
    newRound.status = {
      isCompleted: false,
      isConfigured: false,
      isScoringDisabled: true,
    }
    newRound.roundNumber = data.rounds.length + 1

    dispatch(saveTournamentData({ ...data, rounds: [...data.rounds, newRound] }))
  }
}

const internalRemoveRound = (roundId: number) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournament(getState())
    const rounds = data.rounds.filter((round) => round.id !== roundId)
    const updatedData = {
      ...data,
      rounds,
    }
    const numberOfRounds: number = rounds.length
    const latestRoundId: number = rounds[numberOfRounds - 1].id
    dispatch(setSelectedRoundIndex(numberOfRounds - 1))
    dispatch(setSelectedRoundId(latestRoundId))

    dispatch(saveTournamentData(updatedData))
  }
}

export const saveTournamentData = (data: TournamentState) => {
  return (dispatch, getState: () => RootState) => {
    const id = getTournamentId(getState())
    dispatch(tournamentApi.util.updateQueryData('getTournament', { id }, () => data))
  }
}
