import React from 'react'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { get } from 'lodash'
import ContentWrap from '../../components/layout/ContentWrap'
import { scrollToTop } from '../../components/ui'
import HeadHelmet from '../../components/layout/HeadHelmet'
import { enqueueNotification } from '../../store/notifications/actions'
import {
  DeleteTourImagePayload,
  fetchTour,
  uploadTourImage,
  deleteTourImage,
  UploadTourImagePayload,
} from '../../store/tourAndRanking/actions'
import { Grid, Typography, Divider } from '@mui/material'
import UploadedImage from '../../components/ui/UploadedImage'
import BorderButton from '../../components/ui/BorderButton'
import FilePicker from '../../components/ui/FilePicker'
import { CloudUpload } from '@mui/icons-material'
import forEach from 'lodash/forEach'
import { RootState } from '@app/store'

interface StateProps {
  tour: Tour
  organizationId?: number
}

interface DispatchProps {
  enqueueNotification(error: any, variant?: string): void
  uploadTourImage(payload: UploadTourImagePayload): void
  deleteTourImage(payload: DeleteTourImagePayload): void
  fetchTour: (organizationId: number, id: number, onComplete?: () => void) => void
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps

class UnconnectedBranding extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <HeadHelmet titleId={'progress.branding'} />

        <ContentWrap>
          {this._renderLogoSection()}
          {this._renderHeroSection()}
          {this._renderSponsorLogosSection()}
          {this._renderAdsSection()}

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 60 }}>
            <BorderButton
              filled
              buttonProps={{
                onClick: this.clickNext,
              }}
            >
              <FormattedMessageWrapper id={'buttons.finish'} />
            </BorderButton>
          </div>
        </ContentWrap>
      </>
    )
  }

  private _renderLogoSection = () => {
    return (
      <>
        <Grid container={true} spacing={5}>
          <Grid item={true} xs={4}>
            <Typography variant="h4" style={{ marginBottom: 20 }}>
              <FormattedMessageWrapper id={'tourAndRanking.tourLogo'} />
            </Typography>
            {this._renderUploadButton({
              id: 'logo',
              name: 'logo',
              label: <FormattedMessageWrapper id={'buttons.uploadTourLogo'} />,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tourAndRanking.tourLogoInfo'} />
            </Typography>
          </Grid>
          <Grid item={true} xs={3}>
            <div style={{ maxWidth: '200px' }}>
              <UploadedImage
                image={this.props.tour.logo ? this.props.tour.logo : { id: null, url: '' }}
                onDelete={this._deleteImage}
              />
            </div>
          </Grid>
        </Grid>

        <Divider />
      </>
    )
  }

  private _renderHeroSection = () => {
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 20 }}>
          <FormattedMessageWrapper id={'tourAndRanking.heroImage'} />
        </Typography>

        <Grid container={true} spacing={2}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'hero-image',
              name: 'hero',
              label: <FormattedMessageWrapper id={'buttons.uploadHero'} />,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteSiteHeroInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {/* Show image once it has been selected */}
            <UploadedImage
              image={this.props.tour.hero ? this.props.tour.hero : { id: null, url: '' }}
              onDelete={this._deleteImage}
            />
          </Grid>
        </Grid>

        <Divider />
      </>
    )
  }

  private _renderSponsorLogosSection = () => {
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 20 }}>
          <FormattedMessageWrapper id={'tournament.sponsorLogos'} />
        </Typography>

        <Grid container={true} spacing={2}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'tournament-sponsor-logo',
              name: 'sponsorLogo',
              label: <FormattedMessageWrapper id={'buttons.uploadLogos'} />,
              multiple: true,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteSponsorLogosInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container={true} spacing={2}>
              {this.props.tour.sponsorLogos.map((sponsorLogo) => (
                <Grid item={true} xs={3} key={sponsorLogo.id as any}>
                  <UploadedImage image={sponsorLogo} onDelete={this._deleteImage} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Divider />
      </>
    )
  }

  private _renderAdsSection = () => {
    const adAmount = this.props.tour.ads.length
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 20 }}>
          <FormattedMessageWrapper id={'tourAndRanking.tourSiteAds'} />
        </Typography>

        <Grid container={true} spacing={2}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'tournament-ad',
              name: 'ad',
              label: <FormattedMessageWrapper id={'buttons.uploadAd'} />,
              multiple: true,
              disabled: adAmount >= 4,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteAdsInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container={true} spacing={2}>
              {this.props.tour.ads.map((ad) => (
                <Grid item={true} xs={3} key={ad.id as any}>
                  <UploadedImage image={ad} onDelete={this._deleteImage} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  private _renderUploadButton = ({
    id,
    name,
    label,
    multiple = false,
    disabled = false,
  }: {
    name: string
    id: string
    label: React.ReactNode
    multiple?: boolean
    disabled?: boolean
  }) => (
    <FilePicker
      name={name}
      id={id}
      onChange={this._onFileInputChange}
      accept="image/*"
      multiple={multiple}
      disabled={disabled}
    >
      <BorderButton
        buttonProps={{
          component: 'span',
          disabled,
        }}
      >
        {label}
        <CloudUpload style={{ marginLeft: 10 }} />
      </BorderButton>
    </FilePicker>
  )

  public _deleteImage = (image: TournamentImage) => {
    const { deleteTourImage, tour, organizationId } = this.props
    const { id } = image

    if (id && tour && tour.id && organizationId) {
      deleteTourImage({
        organizationId,
        tourId: tour.id,
        id,
        onComplete: this._handleLoadingDone,
      })
    }
  }

  public _onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      uploadTourImage,
      tour: { id },
      organizationId,
    } = this.props
    const { name, files } = e.target

    if (files && files.length > 0 && id && organizationId) {
      forEach(files, (file, idx) => {
        if (file.size > 2000000) {
          this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.tooLargeImageFile' }), 'error')
          return
        }
        uploadTourImage({
          organizationId,
          tourId: id,
          file,
          fileType: name as any,
          onComplete: idx + 1 < files.length ? undefined : this._handleLoadingDone,
        })
      })
    }
  }

  public _handleLoadingDone = ({ error }: APICallResult) => {
    if (!error) {
      const {
        tour: { id },
        organizationId,
        fetchTour,
      } = this.props
      if (organizationId && id) {
        fetchTour(organizationId, id)
      }
    }
  }

  private clickNext = () => {
    this.props.navigate('/tour-and-ranking')
    scrollToTop()
  }
}

const Branding = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => ({
    tour: state.tourAndRankingReducer.tour,
    organizationId: get(state.authenticationReducer, 'customerInfo.idCustomer'),
  }),
  {
    enqueueNotification,
    uploadTourImage,
    deleteTourImage,
    fetchTour,
  },
)(withRouter(injectIntl(UnconnectedBranding)))

export default Branding
