import { Checkbox, darken, IconButton, Switch, TableCell, TableRow, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import EditIcon from '@mui/icons-material/Edit'
import { rem } from '@app/theme/materialUITheme'
import { getCountryFlagImageUrl } from '@app/utils/tournamentUtils'
import { SlizeHazard } from '@assets/images'
import { getExtraInfo } from './ExtraInfoSelect'
import { PlayerPoolTableColumn, PlayerPoolTableField } from './PlayerPoolTable'
import { formatHandicap } from '@app/utils/playerUtils'
import { IntlShape } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { PlayerHcpSource, PlayerListStatus } from '../enums'
import NoWrapTooltip from '@app/components/ui/NoWrapTooltip'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { formatDate } from '@app/utils/dates'

export interface PlayerPoolTableRowComponentProps {
  columns: Record<PlayerPoolTableField, PlayerPoolTableColumn>
  extraInfoField: string
  onArrivedChange: (playerId: number, checked: boolean) => void
  onEditClick: (playerId: number) => void
  player: TournamentPlayer
  customQuestionsIdMap: { [questionId: number]: Question }
  teams: TournamentTeam[]
  units: OrganizationUnits
  divisions?: DivisionState[]
  players?: TournamentPlayer[]
  tournament: TournamentState
  startListRounds: StartListRound[]
  payment: PaymentsState
  paymentsEnabled: boolean
  intl: IntlShape
  onSelect: (playerId: number, selected: boolean) => void
}

const hcpBadgeClass = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 41,
  height: 22,
  borderRadius: 30,
  fontSize: 12,
  fontWeight: 500,
  border: '1px solid transparent',
  color: '#373D40',
}

const useStyles = makeStyles((theme: Theme) => ({
  flag: {
    height: rem(32),
  },
  flagNone: {
    height: rem(20),
  },
  gender: {
    textTransform: 'capitalize',
  },
  tableRow: {
    '&:nth-of-type(even)': {
      background: darken(theme.palette.background.default, 0.02),
    },
  },
  hcpTooltip: {
    padding: 10,
  },
  hcpTooltipTimestamp: {
    opacity: 0.7,
    display: 'block',
  },
  hcpNoBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 41,
    height: 22,
  },
  hcpBadgeClass,
  hcpBadgeGgb: {
    ...hcpBadgeClass,
    backgroundColor: '#098F50',
    color: '#fff',
  },
  hcpBadgeFed: {
    ...hcpBadgeClass,
    backgroundColor: '#126EDA',
    color: '#fff',
  },
  hcpBadgeManual: {
    ...hcpBadgeClass,
    borderColor: '#373D40',
  },
}))

const PlayerTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      fontSize: 14,
      padding: `0 ${rem(8)} 0 0`,
    },
  }),
)(TableCell)

const PlayerPoolTableRowComponent: React.FC<PlayerPoolTableRowComponentProps> = ({
  columns,
  customQuestionsIdMap,
  extraInfoField,
  onArrivedChange,
  onEditClick,
  onSelect,
  player,
  teams = [],
  units,
  divisions,
  players,
  tournament,
  startListRounds,
  payment,
  paymentsEnabled,
  intl,
}) => {
  const classes = useStyles()

  const tournamentConfig = useSelector(selectTournamentConfig)

  /**
   * Render flag image by country code.
   *
   * @param countryCode Country code in lowercase
   */
  const renderCountryFlag = (countryCode: string | undefined): React.ReactNode => {
    if (!countryCode) {
      return <img className={classes.flagNone} alt="Logo" src={SlizeHazard} />
    }
    return <img className={classes.flag} alt={countryCode} src={getCountryFlagImageUrl(countryCode)} />
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, playerId: number): void => {
    onArrivedChange(playerId, event.currentTarget.checked)
  }

  const extraInfo = getExtraInfo(
    player,
    tournament,
    tournamentConfig,
    startListRounds,
    extraInfoField,
    customQuestionsIdMap,
    teams,
    units,
    divisions,
    players,
    intl,
  )

  const getPaidItems = () => {
    const { organizationProducts, tournamentProducts } = payment

    if (!player.purchasedTournamentProductIds) {
      return []
    }

    return player.purchasedTournamentProductIds
      .map((id) =>
        organizationProducts.find((organizationProduct) =>
          tournamentProducts.find(
            (tournamentProduct) =>
              organizationProduct.id === tournamentProduct.organizationProductId && id === tournamentProduct.id,
          ),
        ),
      )
      .filter((item) => typeof item !== 'undefined')
  }

  const getPaymentInformation = () => {
    // Sort products by name
    if (getPaidItems().length > 0) {
      return getPaidItems()
        .map((item) => item!.name)
        .sort((a, b) => a.localeCompare(b))
        .join(', ')
    }
    return player.listStatus === PlayerListStatus.UNPAID ? intl.formatMessage({ id: 'payments.listUnpaid' }) : ''
  }

  const getHcpTooltipBase = () => {
    switch (player.hcpSource) {
      case PlayerHcpSource.GGB:
        return <FormattedMessageWrapper id="tournament.hcpIsUpdatedFromGgb" />
      case PlayerHcpSource.FGU:
        return <FormattedMessageWrapper id="tournament.hcpIsUpdatedFromEbirdie" />
      case PlayerHcpSource.SGF:
        return <FormattedMessageWrapper id="tournament.hcpIsUpdatedFromGit" />
      case PlayerHcpSource.MANUAL:
        return <FormattedMessageWrapper id="tournament.hcpIsManuallyEdited" />
      default:
        return <FormattedMessageWrapper id="tournament.hcpHasNotBeenUpdated" />
    }
  }

  const getHcpTooltipText = () => {
    const base = getHcpTooltipBase()
    if (player.hcpSource === PlayerHcpSource.UNCHANGED) {
      return base
    }
    return (
      <div className={classes.hcpTooltip}>
        {base}
        {player.hcpUpdatedAt && (
          <span className={classes.hcpTooltipTimestamp}>{formatDate(player.hcpUpdatedAt, 'datetime', units)}</span>
        )}
      </div>
    )
  }

  const getHcpBadgeClass = () => {
    switch (player.hcpSource) {
      case PlayerHcpSource.GGB:
        return classes.hcpBadgeGgb
      case PlayerHcpSource.FGU:
      case PlayerHcpSource.SGF:
        return classes.hcpBadgeFed
      case PlayerHcpSource.MANUAL:
        return classes.hcpBadgeManual
      default:
        return classes.hcpNoBadge
    }
  }

  const renderHandicap = () => {
    return (
      <NoWrapTooltip title={getHcpTooltipText()} arrow placement="top">
        <div className={getHcpBadgeClass()}>{formatHandicap(String(player.hcp))}</div>
      </NoWrapTooltip>
    )
  }

  return (
    <TableRow className={classes.tableRow}>
      <PlayerTableCell align="center">
        <Checkbox
          checked={player.isSelected}
          onChange={(_, checked) => onSelect(player.userId, checked)}
          name={`selected_${player.userId}`}
          color="primary"
        />
      </PlayerTableCell>
      <PlayerTableCell align="center">{renderCountryFlag(player.countryCode)}</PlayerTableCell>
      <PlayerTableCell>{player.firstName}</PlayerTableCell>
      <PlayerTableCell>{player.lastName}</PlayerTableCell>
      <PlayerTableCell>{renderHandicap()}</PlayerTableCell>
      <PlayerTableCell>{player.club && player.club.name}</PlayerTableCell>
      <PlayerTableCell>{extraInfo}</PlayerTableCell>
      {!columns[PlayerPoolTableField.TEAM].hidden && (
        <PlayerTableCell>{player.team && player.team.name}</PlayerTableCell>
      )}
      {paymentsEnabled && <PlayerTableCell>{getPaymentInformation()}</PlayerTableCell>}
      <PlayerTableCell className={classes.gender} align="center">
        {player.gender && player.gender.slice(0, 1)}
      </PlayerTableCell>
      {!columns[PlayerPoolTableField.ARRIVED].hidden && (
        <PlayerTableCell align="center">
          <Switch
            checked={player.isArrived}
            onChange={(event) => onChange(event, player.userId)}
            name={`arrived_${player.userId}`}
            color="primary"
          />
        </PlayerTableCell>
      )}
      <PlayerTableCell align="center">
        <IconButton color="primary" aria-label="Edit player" onClick={() => onEditClick(player.userId)} size="large">
          <EditIcon />
        </IconButton>
      </PlayerTableCell>
    </TableRow>
  )
}

export const PlayerPoolTableRow = PlayerPoolTableRowComponent
