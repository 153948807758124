import React from 'react'
import { Dialog, DialogActions, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'

const styles = (theme: Theme) =>
  createStyles({
    cancelButton: {
      color: 'red',
      border: '1px solid red',
    },
    reserveListButton: {
      background: theme.customPalette.warning,
      color: theme.palette.common.white,
    },
    removeButton: {
      background: theme.customPalette.error,
      color: theme.palette.common.white,
    },
  })

export interface TeamReserveDialogProps {
  isOpen: boolean
  onClose(): void
  onDelete(): void
  onSendToReserve(): void
}

type Props = TeamReserveDialogProps & WrappedComponentProps & WithStyles<typeof styles>

class TeamReserveDialogComponent extends React.Component<Props> {
  render() {
    const { isOpen = false, onClose, onDelete, onSendToReserve, classes } = this.props

    return (
      <>
        <Dialog open={isOpen} onClose={(_, reason) => reason !== 'backdropClick' && onClose()} maxWidth="md">
          <BaseDialogTitle
            id="tournament-remove-players-dialog"
            onClose={onClose}
            title={<FormattedMessageWrapper id={'tournament.deleteOrMoveTeam'} />}
            largeTitleText
          />

          <DialogContent>
            <FormattedMessageWrapper id={'tournament.moveTeamToReserveOrDelete'} />
          </DialogContent>

          <DialogActions style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Button onClick={onClose} variant={'outlined'} classes={{ outlined: classes.cancelButton }}>
              <FormattedMessageWrapper id={'buttons.cancel'} />
            </Button>
            <Button variant={'contained'} onClick={onDelete} className={classes.removeButton}>
              <FormattedMessageWrapper id={'buttons.delete'} />
            </Button>
            <Button variant={'contained'} onClick={onSendToReserve} className={classes.reserveListButton}>
              <FormattedMessageWrapper id={'buttons.move'} />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export const TeamReserveDialog = injectIntl(withStyles(styles)(TeamReserveDialogComponent))
