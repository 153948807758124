import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { clearGitImportStorage, getStorageGolferIds, storageGitResults } from '@app/utils/tournamentUtils'
import { CacheTag } from '../cacheTags'
import { RootState } from '@app/store'

export const tournamentGitApi = api.injectEndpoints({
  endpoints: (build) => ({
    gitImportStart: build.mutation<{ importId: string }, GitImportStartPayload>({
      query: (payload) => ({
        url: APIRoute.GIT_IMPORT_START(payload.tournamentId),
        method: HttpMethod.POST,
        body: { golferIds: getStorageGolferIds() },
      }),
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          payload.onComplete(data.importId)
        } catch {
          console.error('Error starting git import')
        }
      },
    }),
    gitImportStatus: build.query<GitImport, GitImportStatusPayload>({
      query: (payload) => ({
        url: APIRoute.GIT_IMPORT_URL(payload.tournamentId, payload.importId),
      }),
      transformResponse: (response: GitImport) => {
        const gitImportProgress = Math.floor(response.progress * 100)
        if (gitImportProgress === 100) {
          return { ...response, progress: gitImportProgress }
        }
        return response
      },
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const gitImportProgress = data.progress
          if (gitImportProgress === 100) {
            localStorage.setItem(storageGitResults, JSON.stringify(data))
          }
          if (payload.onComplete) {
            payload.onComplete(gitImportProgress)
          }
        } catch {
          console.error('Error fetching git import status')
        }
      },
    }),
    confirmGitImport: build.mutation<GitImportConfirm, GitImportConfirmPayload>({
      query: (payload) => ({
        url: APIRoute.GIT_IMPORT_URL(payload.tournamentId, payload.importId),
        method: HttpMethod.POST,
      }),
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          payload.onComplete(data)
        } catch {
          console.error('Error confirming git import')
        }
      },
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS],
    }),
    cancelGitImport: build.mutation<void, GitImportCancelPayload>({
      query: (payload) => ({
        url: APIRoute.GIT_IMPORT_URL(payload.tournamentId, payload.importId),
        method: HttpMethod.DELETE,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled
          clearGitImportStorage()
          window.dispatchEvent(new Event('storage'))
        } catch {
          console.error('Error canceling git import')
        }
      },
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS],
    }),
  }),
})

export const selectGitImportStatus = (state: RootState, payload: GitImportStatusPayload) =>
  tournamentGitApi.endpoints.gitImportStatus.select(payload)(state).data || {
    progress: 0,
    result: [],
    alreadyInTournament: [],
  }

export const {
  useGitImportStartMutation,
  useGitImportStatusQuery,
  useLazyGitImportStatusQuery,
  useConfirmGitImportMutation,
  useCancelGitImportMutation,
} = tournamentGitApi
