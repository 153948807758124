import { createSelector } from 'reselect'
import { flatten } from 'lodash'
import { PlayerStatus } from '../enums/tournamentPlayersEnums'
import { RootState } from '@app/store'
import { selectTournamentPlayers } from '../endpoints/tournamentPlayersApi'
import { selectTournamentTeams } from '../endpoints/tournamentTeamsApi'

const getAllPlayers = (store: RootState): TournamentPlayer[] => selectTournamentPlayers(store)?.data?.players || []

const getUnassignedTeams = (store: RootState): TournamentTeam[] => {
  const currentRoundIndex = store.configReducer.tournament.selectedRoundIndex
  const currentRound: StartListRound = store.tournamentStartListsReducer.rounds[currentRoundIndex]
  const assignedTeamIds =
    currentRound?.groups
      .map((group: StartListGroup) => group.teams)
      .reduce((accumulator: StartListGroupTeam[], current: StartListGroupTeam[]) => accumulator.concat(current), [])
      .map((team: StartListGroupTeam) => team.tournamentTeamId) || []
  const teams: TournamentTeam[] = selectTournamentTeams(store)?.data?.teams || []
  return teams.filter((team: TournamentTeam) => assignedTeamIds.includes(team.id) === false)
}

const getAssignedTeamPlayerIds = (store: RootState) => {
  const teams: TournamentTeam[] = selectTournamentTeams(store)?.data?.teams || []
  return flatten(teams.map((team) => team.players.map((player) => player.id)))
}

export const getAssignedPlayerIdsForCurrentRound = (store: RootState) => {
  // get current round
  const currentRoundIndex = store.configReducer.tournament.selectedRoundIndex
  const currentRound: StartListRound = store.tournamentStartListsReducer.rounds[currentRoundIndex]

  if (!currentRound) {
    return []
  }

  return currentRound.groups
    .map((group: StartListGroup) => group.startListPlayers)
    .reduce((accumulator: StartListPlayer[], current: StartListPlayer[]) => accumulator.concat(current), [])
    .map((player: StartListPlayer) => player.tournamentPlayerId)
}

export const getUnassignedPlayers = createSelector(
  getAssignedPlayerIdsForCurrentRound,
  getAllPlayers,
  (assignedPlayerIds, allPlayers) =>
    allPlayers.filter(
      (player) => !assignedPlayerIds.includes(player.id) && (!player.status || player.status === PlayerStatus.OK),
    ),
)

export const getDroppedOutPlayers = createSelector(getAllPlayers, (allPlayers) =>
  allPlayers.filter((player) => player.status && player.status !== PlayerStatus.OK),
)

export const getUnassignedTeamPlayers = createSelector(
  getAssignedTeamPlayerIds,
  getAllPlayers,
  (assignedPlayerIds, allPlayers) => allPlayers.filter((player) => !assignedPlayerIds.includes(player.id)),
)

export const getUnassignedTeamsCount = (store: RootState) => {
  const unassignedTeams = getUnassignedTeams(store)
  return unassignedTeams.length
}
