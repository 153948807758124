export const generateRandomString = (): string => {
  const bytes = new Uint8Array(16)
  window.crypto.getRandomValues(bytes)
  let hexString = ''
  for (let i = 0; i < bytes.length; i++) {
    let hex = bytes[i].toString(16)
    hex = hex.length === 1 ? '0' + hex : hex
    hexString += hex
  }
  return hexString
}
