import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useGetCurrenciesQuery } from '@app/store/api/endpoints/paymentsApi'
import { omit } from 'lodash'
import {
  currencyFractionsToMains,
  currencyMainsToFractions,
  trimPriceValue,
  trimVatValue,
  validateProductPrice,
} from '@app/utils/paymentUtils'

const useStyles = makeStyles((theme: Theme) => ({
  removeButton: {
    background: theme.customPalette.error,
    color: theme.palette.common.white,
  },
  content: {
    '&&': {
      paddingTop: 10,
    },
  },
}))

export interface OrganizationProductsDialogProps {
  open: boolean
  saveFn(formData: OrganizationProduct): void
  deleteFn(formData: OrganizationProduct): void
  closeCreateNewDialogFn(): void
  product?: OrganizationProduct
}

interface OrganizationProductPayload extends Omit<OrganizationProduct, 'defaultPrice' | 'defaultVat'> {
  defaultPrice: string
  defaultVat: string
  minorDivider: number
}

const initialState: OrganizationProductPayload = {
  id: 0,
  name: '',
  sku: '',
  defaultPrice: '',
  defaultCurrency: 'EUR',
  defaultVat: '',
  description: '',
  minorDivider: 0,
}

export const OrganizationProductsDialog: React.FC<OrganizationProductsDialogProps> = ({
  open,
  saveFn,
  deleteFn,
  closeCreateNewDialogFn,
  product,
}) => {
  const classes = useStyles()
  const [formData, setFormData] = useState<OrganizationProductPayload>(initialState)
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const { data: currencies = [] } = useGetCurrenciesQuery()

  const selectedCurrency = useMemo(() => currencies.find((c) => c.code === formData.defaultCurrency), [formData])

  useEffect(() => {
    const payloadProduct = {
      ...product,
      defaultVat: String(product?.defaultVat || ''),
      defaultPrice: currencyFractionsToMains(product?.defaultPrice || 0, selectedCurrency?.minorDivider || 100),
    } as OrganizationProductPayload
    product && setFormData(payloadProduct)
  }, [])

  const handleOnClose = () => {
    setFormData(initialState)
    closeCreateNewDialogFn()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value: inputValue } = e.currentTarget
    let value = inputValue
    if (name === 'defaultVat') {
      value = trimVatValue(value)
    }
    if (name === 'defaultPrice') {
      value = trimPriceValue(value)
    }
    if (validationErrors.includes(name)) {
      setValidationErrors(validationErrors.filter((error) => error !== name))
    }
    setFormData({ ...formData, [name]: value } as OrganizationProductPayload)
  }

  const handleCurrencyChange = (event: SelectChangeEvent<string>): void => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value } as OrganizationProductPayload)
  }

  const convertPayloadToProduct = (productPayload: OrganizationProductPayload): OrganizationProduct => {
    const defaultPrice = Number(productPayload.defaultPrice.replace(',', '.'))
    const minorDivider = selectedCurrency?.minorDivider || 100

    return {
      ...productPayload,
      defaultVat: Number(productPayload?.defaultVat || 0),
      defaultPrice: currencyMainsToFractions(defaultPrice, minorDivider),
    } as OrganizationProduct
  }

  const validateProductPayload = (productPayload: OrganizationProductPayload): ProductPayloadValidation => {
    const product = convertPayloadToProduct(productPayload)
    const productKeys = Object.keys(omit(product, ['id', 'minorDivider']))
    const errors: string[] = []
    productKeys.forEach((key) => {
      const value = product[key]
      const isEmptyString = typeof value === 'string' && value.trim() === ''
      const isPriceInvalid = key === 'defaultPrice' && !validateProductPrice(value, selectedCurrency)
      if (isEmptyString || isPriceInvalid) {
        errors.push(key)
      }
    })
    setValidationErrors(errors)
    return { isValid: errors.length === 0, payload: product }
  }

  const handleOnSave = () => {
    const { isValid, payload } = validateProductPayload(formData)
    if (isValid) {
      saveFn(payload)
      handleOnClose()
    }
  }

  const handleOnDelete = () => {
    deleteFn(convertPayloadToProduct(formData))
    handleOnClose()
  }

  return (
    <Dialog open={open} onClose={handleOnClose} aria-labelledby="organizationProductsDialog">
      <BaseDialogTitle
        style={{ marginBottom: 20 }}
        id={'payment-products-dialog'}
        title={<FormattedMessageWrapper id={'payments.productsDialog.createNewPayment'} />}
        onClose={handleOnClose}
        largeTitleText
      />

      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              name="name"
              variant="outlined"
              fullWidth
              onChange={handleOnChange}
              value={formData.name}
              error={validationErrors.includes('name')}
              label={
                <Typography variant="caption">
                  <FormattedMessageWrapper id="payments.productsDetails.productName" />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="sku"
              variant="outlined"
              onChange={handleOnChange}
              value={formData.sku}
              error={validationErrors.includes('sku')}
              label={
                <Typography variant="caption">
                  <FormattedMessageWrapper id="payments.productsDetails.productSKU" />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="defaultPrice"
              variant="outlined"
              onChange={handleOnChange}
              value={formData.defaultPrice}
              error={validationErrors.includes('defaultPrice')}
              label={
                <Typography variant="caption">
                  <FormattedMessageWrapper id="payments.productsDetails.productDefaultPrice" />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              name={'defaultCurrency'}
              variant="outlined"
              fullWidth
              value={formData.defaultCurrency}
              error={validationErrors.includes('defaultCurrency')}
              onChange={handleCurrencyChange}
            >
              {currencies.map((currency) => (
                <MenuItem value={currency.code} key={currency.code}>
                  {currency.code}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="defaultVat"
              variant="outlined"
              onChange={handleOnChange}
              value={formData.defaultVat}
              error={validationErrors.includes('defaultVat')}
              label={
                <Typography variant="caption">
                  <FormattedMessageWrapper id="payments.productsDetails.productTax" />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              variant="outlined"
              onChange={handleOnChange}
              value={formData.description}
              error={validationErrors.includes('description')}
              multiline
              rows={4}
              fullWidth
              label={
                <Typography variant="caption">
                  <FormattedMessageWrapper id="payments.productsDetails.productDescription" />
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button color="primary" onClick={handleOnSave} variant="contained">
          <FormattedMessageWrapper id={'buttons.save'} />
        </Button>
        <Button onClick={handleOnClose} variant="outlined">
          <FormattedMessageWrapper id={'buttons.cancel'} />
        </Button>
        {product && (
          <Button onClick={handleOnDelete} variant="contained" className={classes.removeButton}>
            <FormattedMessageWrapper id={'buttons.delete'} />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
