import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActions, FormLabel, Typography } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import TextField from '@mui/material/TextField'
import { updateUser } from '../../../store/organizationUser/actions'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { enqueueNotification } from '../../../store/notifications/actions'
import get from 'lodash/get'
import { RootState } from '@app/store'

interface OwnProps {
  open?: boolean
  onClose(refetch?: boolean): void
}

interface StateProps {
  auth: AuthenticationState
  userLoading: boolean
}

interface DispatchProps {
  updateUser(
    userId: number,
    organizationId: number,
    user: UserPayload,
    onComplete?: (args: APICallResult) => void,
  ): void
  enqueueNotification(error: any, variant?: string): void
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps

interface State {
  errors: string[]
  success: boolean
}

const initialState: State = {
  errors: [],
  success: false,
}

class OwnInformationDialog extends React.Component<Props, State> {
  readonly state: State = initialState

  render() {
    const { open = false, onClose } = this.props

    return (
      <Dialog
        open={open}
        onClose={(_, reason) => reason !== 'backdropClick' && this._handleDismiss()}
        aria-labelledby="own-information-dialog-title"
      >
        <form onSubmit={this._handleUpdate}>
          <BaseDialogTitle
            id={'own-information-dialog-title'}
            title={<FormattedMessageWrapper id={'buttons.myInfo'} />}
            onClose={() => onClose()}
          />

          <DialogContent>{this._renderForm()}</DialogContent>

          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {this.state.success && (
                <Typography variant="body1" color="primary">
                  <FormattedMessageWrapper id="tournament.ownInformationSaved" />
                </Typography>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button onClick={this._handleDismiss} disabled={this._isLoading}>
                <FormattedMessageWrapper id={'buttons.cancel'} />
              </Button>
              <div style={{ marginLeft: 10 }}>
                <ButtonLoaderWrap loading={this._isLoading}>
                  <Button type={'submit'} disabled={this._isLoading} color="primary" variant={'contained'}>
                    <FormattedMessageWrapper id={'buttons.save'} />
                  </Button>
                </ButtonLoaderWrap>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    )
  }

  private get _isLoading() {
    return this.props.auth.loading || this.props.userLoading
  }

  public _handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const {
      currentTarget: { elements },
    } = e
    const { updateUser, enqueueNotification, auth } = this.props
    const name = (elements.namedItem('name') as HTMLInputElement).value
    const email = get(this.props, 'auth.email')
    let password: string | null = (elements.namedItem('password') as HTMLInputElement).value
    const passwordConfirm = (elements.namedItem('passwordConfirm') as HTMLInputElement).value

    if (!name || name === '') {
      this.setState((prevState) => {
        prevState.errors.push('name')
        return { errors: prevState.errors }
      })
    } else {
      this.setState((prevState) => {
        return { errors: prevState.errors.filter((e) => e !== 'name') }
      })
    }

    if (password !== passwordConfirm) {
      enqueueNotification(this.props.intl.formatMessage({ id: 'errors.passwordMismatch' }), 'error')
      this.setState((prevState) => {
        prevState.errors.push('password')
        return { errors: prevState.errors }
      })
      return
    } else {
      this.setState((prevState) => {
        return { errors: prevState.errors.filter((e) => e !== 'password') }
      })
    }

    const userId = get(auth, 'id')
    const organizationId = get(auth, 'customerInfo.idCustomer')
    const roleIds = [get(auth, 'roleInfo.idRole')]
    const events = get(auth, 'events') ? get(auth, 'events') : []

    if (password === '') {
      password = null
    }

    if (userId && organizationId && name !== '' && email) {
      updateUser(userId, organizationId, { name, email, status: 'ACTIVE', roleIds, events, password }, () =>
        this.setState({ success: true }),
      )
    }
  }

  public _handleDismiss = (): void => {
    this._handleClose(false)
  }

  public _handleClose = (refetch = false): void => {
    this.setState({ success: false })
    const { onClose } = this.props
    if (onClose) {
      onClose(refetch)
    }
  }

  private _renderForm = () => {
    return (
      <>
        <FormLabel htmlFor="name" error={this.state.errors.includes('name')}>
          <FormattedMessageWrapper id="tournament.name" />
        </FormLabel>
        <TextField
          error={this.state.errors.includes('name')}
          margin={'normal'}
          fullWidth={true}
          disabled={this._isLoading}
          id={'name'}
          name={'name'}
          autoComplete={'off'}
          defaultValue={get(this.props, 'auth.name')}
          style={{ marginTop: 0, marginBottom: 10 }}
        />

        <FormLabel htmlFor="email">
          <FormattedMessageWrapper id="tournament.emailUsername" />
        </FormLabel>
        <TextField
          disabled={true}
          margin={'normal'}
          fullWidth={true}
          id={'email'}
          name={'email'}
          defaultValue={get(this.props, 'auth.email')}
          style={{ marginTop: 0, marginBottom: 10 }}
        />

        <FormLabel htmlFor="password" error={this.state.errors.includes('password')}>
          <FormattedMessageWrapper id="login.password" />
        </FormLabel>
        <TextField
          error={this.state.errors.includes('password')}
          margin={'normal'}
          fullWidth={true}
          disabled={this._isLoading}
          id={'password'}
          name={'password'}
          defaultValue={''}
          type={'password'}
          autoComplete={'off'}
          style={{ marginTop: 0, marginBottom: 10 }}
        />

        <FormLabel htmlFor="passwordConfirm" error={this.state.errors.includes('password')}>
          <FormattedMessageWrapper id="tournament.passwordConfirm" />
        </FormLabel>
        <TextField
          error={this.state.errors.includes('password')}
          margin={'normal'}
          fullWidth={true}
          disabled={this._isLoading}
          id={'passwordConfirm'}
          name={'passwordConfirm'}
          defaultValue={''}
          type={'password'}
          autoComplete={'off'}
          style={{ marginTop: 0, marginBottom: 10 }}
        />
      </>
    )
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    auth: store.authenticationReducer,
    userLoading: store.organizationUserReducer.loading,
  }),
  {
    updateUser,
    enqueueNotification,
  },
)(injectIntl(OwnInformationDialog))
