export const TOURNAMENT_LOAD_LEADERBOARD = 'TOURNAMENT_LOAD_LEADERBOARD'
export const TOURNAMENT_LOAD_LEADERBOARD_SUCCESS = 'TOURNAMENT_LOAD_LEADERBOARD_SUCCESS'
export const TOURNAMENT_LOAD_LEADERBOARD_FAILURE = 'TOURNAMENT_LOAD_LEADERBOARD_FAILURE'
export const TOURNAMENT_RESET_LEADERBOARD = 'TOURNAMENT_RESET_LEADERBOARD'
export const TOURNAMENT_LOAD_CONTEST_LEADERBOARD = 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD'
export const TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS = 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS'
export const TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE = 'TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE'
export const TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING = 'TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING'
export const TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_SUCCESS = 'TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_SUCCESS'
export const TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_FAILURE = 'TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_FAILURE'
export const TOURNAMENT_FETCH_SCORE_SENDING_STATUS = 'TOURNAMENT_FETCH_SCORE_SENDING_STATUS'
export const TOURNAMENT_FETCH_SCORE_SENDING_STATUS_SUCCESS = 'TOURNAMENT_FETCH_SCORE_SENDING_STATUS_SUCCESS'
export const TOURNAMENT_FETCH_SCORE_SENDING_STATUS_FAILURE = 'TOURNAMENT_FETCH_SCORE_SENDING_STATUS_FAILURE'
