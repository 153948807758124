import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { get } from 'lodash'
import { Collapse } from '@mui/material'
import ContentWrap from '../../components/layout/ContentWrap'
import HeadHelmet from '../../components/layout/HeadHelmet'
import TopBar from '../../components/layout/TopBar'
import { fetchOrganization } from '../../store/organization/actions'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import SectionTitle from '../../components/ui/SectionTitle'
import SectionTitleWithCollapse from '../../components/ui/SectionTitleWithCollapse'
import OrganizationProfileManagement from '../../components/organization/OrganizationProfileManagement'
import OrganizationUserManagement from '../../components/user/OrganizationUserManagement'
import OrganizationPaymentSettings from '@components/organization/OrganizationPaymentSettings'
import { formEditableOrganizationItem } from '../../utils/organizationUtils'
import InfoTooltip from '../../components/ui/InfoTooltip'
import { isRoleInfoProviderAdmin } from '@utils/authUtils'
import { RootState } from '@app/store'

interface StateProps {
  auth: AuthenticationState
  organization: OrganizationState
}

interface DispatchProps {
  fetchOrganization: (id: number, force?: boolean, onComplete?: () => void) => void
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps

interface State {
  profileExpanded: boolean
  personalizationExpanded: boolean
  manageUsersExpanded: boolean
  messageBoardExpanded: boolean
  appSettingsExpanded: boolean
  paymentSettingsExpanded: boolean
}

class Profile extends React.Component<Props, State> {
  readonly state: State = {
    profileExpanded: false,
    personalizationExpanded: false,
    manageUsersExpanded: false,
    messageBoardExpanded: false,
    appSettingsExpanded: false,
    paymentSettingsExpanded: false,
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const prevUrl = get(prevProps, 'params.url')
    const currentUrl = get(this.props, 'params.url')
    if (currentUrl !== prevUrl) {
      this._loadOrganization()
    }
  }

  componentDidMount(): void {
    this._loadOrganization()
  }

  render(): React.ReactNode {
    const { organization } = this.props

    return (
      <>
        <HeadHelmet titleId={'navigation.profileSettings'} />
        <TopBar title={organization.name} />

        <OverlayLoader visible={organization.loading} />

        <ContentWrap>
          {this._renderProfileSettings()}

          {this._renderManageUserSettings()}

          {this.renderPaymentSettings()}
        </ContentWrap>
      </>
    )
  }

  private _loadOrganization() {
    const { fetchOrganization, auth, params } = this.props
    const idParam = get(params, 'id')
    const organizationId = idParam ? idParam : get(auth, 'customerInfo.idCustomer')
    if (organizationId) {
      const id = typeof organizationId === 'string' ? parseInt(organizationId, 10) : organizationId
      fetchOrganization(id)
    }
  }

  private get _editableOrganizationItem() {
    const { organization } = this.props
    return formEditableOrganizationItem(organization)
  }

  private _renderProfileSettings = () => {
    const editableOrganizationItem = this._editableOrganizationItem
    const { auth } = this.props
    if (editableOrganizationItem && editableOrganizationItem.name) {
      return (
        <>
          <SectionTitleWithCollapse
            expanded={this.state.profileExpanded}
            onChange={(profileExpanded) => this.setState({ profileExpanded })}
          >
            <SectionTitle>
              <FormattedMessageWrapper id={'profile.profile'} />
              <InfoTooltip text={<FormattedMessageWrapper id="tournament.profileInfo" />} style={{ marginLeft: 10 }} />
            </SectionTitle>
          </SectionTitleWithCollapse>

          <Collapse in={this.state.profileExpanded} mountOnEnter={true} style={{ borderBottomWidth: '1px' }}>
            <OrganizationProfileManagement enableOrganizationNameEdit={isRoleInfoProviderAdmin(auth.roleInfo)} />
          </Collapse>
        </>
      )
    }

    return null
  }

  private _renderManageUserSettings = () => {
    return (
      <>
        <SectionTitleWithCollapse
          expanded={this.state.manageUsersExpanded}
          onChange={(manageUsersExpanded) => this.setState({ manageUsersExpanded })}
        >
          <SectionTitle>
            <FormattedMessageWrapper id={'profile.manageUsers'} />
            <InfoTooltip
              text={<FormattedMessageWrapper id="tournament.manageUsersInfo" />}
              style={{ marginLeft: 10 }}
            />
          </SectionTitle>
        </SectionTitleWithCollapse>

        <Collapse in={this.state.manageUsersExpanded} mountOnEnter={true} style={{ borderBottomWidth: '1px' }}>
          {this.props.organization.id && <OrganizationUserManagement organizationId={this.props.organization.id} />}
        </Collapse>
      </>
    )
  }

  private renderPaymentSettings = () => {
    return (
      <>
        <SectionTitleWithCollapse
          expanded={this.state.paymentSettingsExpanded}
          onChange={(paymentSettingsExpanded) => this.setState({ paymentSettingsExpanded })}
        >
          <SectionTitle>
            <FormattedMessageWrapper id={'profile.paymentSettings'} />
          </SectionTitle>
        </SectionTitleWithCollapse>

        <Collapse in={this.state.paymentSettingsExpanded} mountOnEnter={true} style={{}}>
          <OrganizationPaymentSettings />
        </Collapse>
      </>
    )
  }
}

export default connect<StateProps, DispatchProps, {}, RootState>(
  (store) => ({
    auth: store.authenticationReducer,
    organization: store.organizationReducer,
  }),
  {
    fetchOrganization,
  },
)(withRouter(injectIntl(Profile)))
