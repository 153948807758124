import React from 'react'
import { connect } from 'react-redux'
import { fetchOrganizationApplications } from '@store/organizations/actions'
import { OverlayLoader } from '@components/ui/OverlayLoader'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import { Check, Close, Edit, Remove } from '@mui/icons-material'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
// eslint-disable-next-line max-len
import OrganizationApplicationDialog from '@components/dialogs/organizationApplicationDialog/OrganizationApplicationDialog'
import Table from '@mui/material/Table'
import queryString from 'query-string'
import { isRoleInfoProviderAdmin } from '@utils/authUtils'
import HeadHelmet from '@components/layout/HeadHelmet'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import { formatDate } from '@utils/dates'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DefaultPagedTableFooter from '@components/tables/DefaultPagedTableFooter'
import BorderButton from '@components/ui/BorderButton'
import { RootState } from '@app/store'

interface StateProps {
  roleInfo?: RoleInfo
  isLoading: boolean
  units?: OrganizationUnits
}

interface DispatchProps {
  fetchOrganizationApplications(page?: number, onComplete?: (args: APICallResult) => void): any
}

type Props = StateProps & DispatchProps & WithRouterProps

interface State {
  page: number
  modalOpen: boolean
  applicationToEdit?: OrganizationApplication
  applications: OrganizationApplication[]
  totalCount: number
}

class OrganizationApplications extends React.Component<Props, State> {
  readonly state: State = {
    page: 0,
    modalOpen: false,
    applicationToEdit: undefined,
    applications: [],
    totalCount: 0,
  }

  /*
    Always derive state from props. location.search contains to truth about page and other options
    regarding pagination. Component state is derived from those props.
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const params = queryString.parse(props.location.search)
    const page = params.page ? parseInt(params.page as string, 10) : 1

    if (page !== state.page) {
      return {
        page,
      }
    }

    return null
  }

  /*
    Once appeared and after state has been derived we need to trigger the initial fetch based on
    the current state.
   */
  componentDidMount(): void {
    this._triggerFetch()
  }

  /*
    After didMount we will start handling page changes appearing in the react-router
   */
  componentDidUpdate(_, prevState: State): void {
    if (prevState.page !== this.state.page) {
      this._triggerFetch()
    }
  }

  render() {
    const { roleInfo } = this.props

    if (!isRoleInfoProviderAdmin(roleInfo)) {
      return null
    }

    return (
      <>
        <HeadHelmet titleId={'navigation.applications'} />

        <OverlayLoader visible={this.props.isLoading} />

        {this._renderOrganizationApplications()}

        <OrganizationApplicationDialog
          open={this.state.modalOpen}
          application={this.state.applicationToEdit}
          onClose={(refetch: boolean) => {
            this.setState({ modalOpen: false, applicationToEdit: undefined }, () => {
              if (refetch) {
                this._triggerFetch()
              }
            })
          }}
        />
      </>
    )
  }

  public _triggerFetch = () => {
    this.props.fetchOrganizationApplications(this.state.page, this._setFetchPayload)
  }

  public _setFetchPayload = ({ data, error }: APICallResult) => {
    if (!error) {
      this.setState({ ...data })
    }
  }

  private _renderOrganizationApplications = () => {
    const { units = 'metric' } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            <VeryDenseTableCell />
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.organizationName'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.club'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.status'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.contactPerson'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.email'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id={'tournament.applicationReceivedAt'} />
            </VeryDenseTableCell>
            <VeryDenseTableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {this.state.applications.map((application) => (
            <TableRow key={application.id}>
              <VeryDenseTableCell>
                {application.isGolfClub && (
                  <BorderButton filled dense>
                    CLUB
                  </BorderButton>
                )}
              </VeryDenseTableCell>
              <VeryDenseTableCell>{application.name}</VeryDenseTableCell>
              <VeryDenseTableCell>{application.club ? application.club.name : ''}</VeryDenseTableCell>
              <VeryDenseTableCell>{this._getStatusColor(application.status)}</VeryDenseTableCell>
              <VeryDenseTableCell>
                {application.contactPersonName}, {application.contactPersonTitle}
              </VeryDenseTableCell>
              <VeryDenseTableCell>{application.contactPersonEmail}</VeryDenseTableCell>
              <VeryDenseTableCell style={{ whiteSpace: 'pre' }}>
                {formatDate(application.createdAt, 'datetime', units)}
              </VeryDenseTableCell>
              <VeryDenseTableCell align={'right'}>
                <IconButton
                  disabled={application.status !== 'PENDING'}
                  onClick={() => this.setState({ applicationToEdit: application, modalOpen: true })}
                  size="large"
                >
                  <Edit color={application.status === 'PENDING' ? 'primary' : undefined} />
                </IconButton>
              </VeryDenseTableCell>
            </TableRow>
          ))}
        </TableBody>

        <DefaultPagedTableFooter count={this.state.totalCount} page={this.state.page} />
      </Table>
    )
  }

  private _getStatusColor = (status: OrganizationApplicationStatus) => {
    switch (status) {
      case 'ACCEPTED':
        return <Check color={'primary'} />
      case 'REJECTED':
        return <Close color={'error'} />
      default:
        return <Remove />
    }
  }
}

const RoutedOrganizationApplications = withRouter(OrganizationApplications)
export default connect<StateProps, DispatchProps, {}, RootState>(
  (store): StateProps => ({
    roleInfo: store.authenticationReducer.roleInfo,
    isLoading: store.organizationsReducer.loading,
    units: store.authenticationReducer.units,
  }),
  {
    fetchOrganizationApplications,
  },
)(RoutedOrganizationApplications)
