import React, { useEffect, useState } from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import {
  Collapse,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'
import SectionTitle from '@app/components/ui/SectionTitle'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Add, CheckCircle } from '@mui/icons-material'
import { fetchOrganizationProducts, fetchTournamentProducts, editTournamentProducts } from '@app/store/payments/actions'
import {
  convertTournamentProductToPayload,
  currencyFractionsToMains,
  currencyMainsToFractions,
  trimPriceValue,
  trimVatValue,
  validateProductPrice,
} from '@app/utils/paymentUtils'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from '@app/store'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { updateTournamentSiteField } from '@app/store/api/thunks/tournamentSiteThunks'
import { useGetCurrenciesQuery } from '@app/store/api/endpoints/paymentsApi'
import ContentBlock from '@app/components/tournament/ContentBlock'
import InfoTooltip from '@app/components/ui/InfoTooltip'

const useStyles = makeStyles((theme: Theme) => ({
  formMargin: {
    marginLeft: rem(15),
  },
  productTable: {
    tableLayout: 'fixed',
    width: '100%',
    '& > td': {
      width: '25%',
    },
  },
  activeOrganizationProduct: {
    border: `2px solid ${theme.palette.primary.main}`,
    '& > td': {
      padding: rem(6),
    },
  },
  inactiveOrganizationProduct: {
    border: `2px solid ${theme.customPalette.mediumGray}`,
    '& > td': {
      padding: rem(6),
    },
  },
  subheader: {
    fontStyle: 'italic',
  },
  selected: {
    color: theme.palette.primary.main,
    textAlign: 'right',
  },
  selectedZero: {
    color: theme.customPalette.error,
    textAlign: 'right',
    fontWeight: 700,
  },
  firstCell: { width: '15%' },
  lastCell: { width: '40%' },
  noProductsSelected: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 68,
    border: `2px solid ${theme.customPalette.mediumGray}`,
    paddingLeft: 24,
  },
  infoTooltip: {
    marginLeft: 10,
  },
}))

interface TournamentProductState {
  id?: number
  organizationProductId: number
  vat: string
  price: string
  currency: string
}

interface Params {
  id: string
}

export const Payments: React.FC = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const { id } = useParams<keyof Params>() as Params
  const tournamentSiteSettings = useSelector(selectTournamentSite)
  const organizationProducts = useSelector((state: RootState) => state.paymentsReducer.organizationProducts)
  const tournamentProducts = useSelector((state: RootState) => state.paymentsReducer.tournamentProducts)
  const { organizationId } = useSelector((state: RootState) => state.authenticationReducer.roleInfo)
  const [tournamentProductState, setTournamentProductState] = useState<TournamentProductState | undefined>(undefined)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const { data: currencies = [] } = useGetCurrenciesQuery()

  useEffect(() => {
    dispatch(fetchOrganizationProducts(organizationId))
  }, [dispatch, organizationId])

  useEffect(() => {
    dispatch(fetchTournamentProducts(Number(id)))
  }, [dispatch, id])

  const getCurrency = (currencyCode: string) => {
    return currencies.find((currency) => currency.code === currencyCode)
  }

  const getCurrencyMinorDivider = (currencyCode: string) => {
    return getCurrency(currencyCode)?.minorDivider || 100
  }

  const getItemPrice = (item: OrganizationProduct) => {
    if (tournamentProductState?.organizationProductId === item.id) {
      return tournamentProductState.price
    }
    return getItemDefaultPrice(item)
  }

  const getItemDefaultPrice = (item: OrganizationProduct) => {
    return currencyFractionsToMains(item.defaultPrice, getCurrencyMinorDivider(item.defaultCurrency))
  }

  const getItemVat = (item: OrganizationProduct) => {
    if (tournamentProductState?.organizationProductId === item.id) {
      return tournamentProductState.vat
    }
    return item.defaultVat
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.currentTarget.name
    const value = e.currentTarget.checked ?? e.currentTarget.value

    _updateTournamentSiteField({ fieldName, value })
  }

  const _updateTournamentSiteField = (payload: FieldUpdatePayload) => {
    const { fieldName, value } = payload
    dispatch(
      updateTournamentSiteField({
        fieldName,
        value,
      }),
    )
  }

  const handleOnAdd = (product: OrganizationProduct) => {
    const payload = tournamentProducts.map((product) => convertTournamentProductToPayload(product))
    dispatch(
      editTournamentProducts([
        ...payload,
        {
          organizationProductId: product.id,
          vat: product.defaultVat,
          price: product.defaultPrice,
          currency: product.defaultCurrency,
        },
      ] as TournamentProduct[]),
    )
  }

  const handleOnRemove = (product: OrganizationProduct) => {
    const filteredProducts = tournamentProducts
      .filter((tournamentProduct) => tournamentProduct.organizationProductId !== product.id)
      .map((filteredProduct) => convertTournamentProductToPayload(filteredProduct))
    dispatch(editTournamentProducts(filteredProducts))
  }

  const editActiveProduct = (price: string, vat: string, product: OrganizationProduct) => {
    const defaultPrice = Number(price.replace(',', '.'))
    const minorDivider = getCurrencyMinorDivider(product.defaultCurrency)
    const fixedPrice = currencyMainsToFractions(defaultPrice, minorDivider)
    const isValidPrice = validateProductPrice(fixedPrice, getCurrency(product.defaultCurrency))
    isValidPrice
      ? setValidationErrors(validationErrors.filter((error) => error !== 'price'))
      : setValidationErrors(['price'])

    let updatedPrice: string = price
    if (minorDivider === 1) {
      updatedPrice = price.replace('.', '').replace(',', '')
    }

    setTournamentProductState({
      ...tournamentProductState,
      price: trimPriceValue(String(updatedPrice)),
      vat: trimVatValue(String(vat)),
    } as TournamentProductState)
  }

  const editActiveProductOnFocus = (productId: number) => {
    const currentProduct = tournamentProducts.find((product) => product.organizationProductId === productId)
    if (currentProduct && currentProduct.id !== tournamentProductState?.id) {
      setTournamentProductState({
        ...currentProduct,
        price: currencyFractionsToMains(currentProduct.price, getCurrencyMinorDivider(currentProduct.currency)),
        vat: String(currentProduct.vat),
      } as TournamentProductState)
    }
  }

  const editActiveProductOnBlur = (product: OrganizationProduct, price: string, vat: string) => {
    const productId = product.id
    const currentProduct = tournamentProducts.find((product) => product.organizationProductId === productId)
    const defaultPrice = Number(price.replace(',', '.'))
    const fixedPrice = currencyMainsToFractions(defaultPrice, getCurrencyMinorDivider(product.defaultCurrency))
    const fixedVat = (vat && Number(vat)) || 0
    const isValidPrice = !validationErrors.includes('price')
    if (currentProduct && isValidPrice) {
      const updatedProduct = {
        ...currentProduct,
        price: fixedPrice,
        vat: fixedVat,
      }
      const allProducts = tournamentProducts.slice().filter((product) => product.organizationProductId !== productId)
      allProducts.push(updatedProduct)
      dispatch(editTournamentProducts(allProducts))
    } else if (!isValidPrice) {
      setValidationErrors([])
      setTournamentProductState(undefined)
    }
  }

  const activeProductCount = tournamentProducts.length
  const activeProducts = organizationProducts
    .filter((organizationProduct) =>
      tournamentProducts.find(
        (tournamentProduct) => tournamentProduct.organizationProductId === organizationProduct.id,
      ),
    )
    .map((activeProduct) => {
      const foundProduct = tournamentProducts.find(
        (tournamentProduct) => tournamentProduct.organizationProductId === activeProduct.id,
      )
      if (foundProduct) {
        return {
          ...activeProduct,
          defaultPrice: foundProduct.price,
          defaultVat: foundProduct.vat,
          defaultCurrency: foundProduct.currency,
        }
      }
      return activeProduct
    })

  const inactiveProducts = organizationProducts.filter(
    (organizationProduct) => !activeProducts.some((activeProduct) => activeProduct.id === organizationProduct.id),
  )

  const maybeRenderNoProductsAvailableMessage = () => {
    if ((!activeProducts || activeProducts.length < 1) && (!inactiveProducts || inactiveProducts.length < 1)) {
      return (
        <Typography variant="h6">
          <FormattedMessageWrapper id="payments.entries.noProductsAvailable" />
        </Typography>
      )
    }
    return null
  }

  const maybeRenderNoActiveProductsMessage = () => {
    if (activeProducts && activeProducts.length === 0) {
      return (
        <div className={classes.noProductsSelected}>
          <Typography variant="h6">
            <FormattedMessageWrapper id="payments.entries.noSelectedProducts" />
          </Typography>
        </div>
      )
    }
    return null
  }

  const showPriceError = (item: OrganizationProduct) => {
    return tournamentProductState?.organizationProductId === item.id && validationErrors.includes('price')
  }

  const maybeRenderActiveProducts = () => {
    if (activeProducts && activeProducts.length > 0) {
      return (
        <Table className={classes.productTable}>
          <TableBody>
            {activeProducts.map((item, index) => (
              <TableRow key={`activeProducts_${index}`} className={classes.activeOrganizationProduct}>
                <TableCell className={classes.firstCell}>
                  <IconButton onClick={() => handleOnRemove(item)} size="large">
                    <CheckCircle color="primary" />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <TextField
                    name="price"
                    value={getItemPrice(item)}
                    onFocus={() => editActiveProductOnFocus(item.id)}
                    onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>) =>
                      editActiveProduct(e.currentTarget.value, String(item.defaultVat), item)
                    }
                    onBlur={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>) =>
                      editActiveProductOnBlur(item, e.currentTarget.value, String(item.defaultVat))
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{item.defaultCurrency}</InputAdornment>,
                    }}
                    error={showPriceError(item)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name="tax"
                    value={getItemVat(item)}
                    onFocus={() => editActiveProductOnFocus(item.id)}
                    onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>) =>
                      editActiveProduct(getItemDefaultPrice(item), e.currentTarget.value, item)
                    }
                    onBlur={(e: React.ChangeEvent<{ name?: string | undefined; value: any }>) =>
                      editActiveProductOnBlur(item, getItemDefaultPrice(item), e.currentTarget.value)
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </TableCell>
                <TableCell className={classes.lastCell}>{item.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    }
    return null
  }

  const maybeRenderInactiveProducts = () => {
    if (inactiveProducts && inactiveProducts.length > 0) {
      return (
        <Table className={classes.productTable}>
          <TableBody>
            {inactiveProducts.map((item, index) => (
              <TableRow key={`inactiveProducts_${index}`} className={classes.inactiveOrganizationProduct}>
                <TableCell className={classes.firstCell}>
                  <IconButton onClick={() => handleOnAdd(item)} size="large">
                    <Add />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {getItemDefaultPrice(item)} {item.defaultCurrency}
                </TableCell>
                <TableCell>{item.defaultVat} %</TableCell>
                <TableCell className={classes.lastCell}>{item.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    }
    return null
  }

  return (
    <>
      <SectionTitle>
        <FormattedMessageWrapper id="payments.payments" />
        <FormControl className={classes.formMargin}>
          <Switch
            checked={tournamentSiteSettings.paymentEnabled}
            onChange={handleOnChange}
            name="paymentEnabled"
            value="true"
            color="primary"
          />
        </FormControl>
      </SectionTitle>
      <Collapse in={tournamentSiteSettings.paymentEnabled}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormattedMessageWrapper id="payments.entries.description" />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ marginTop: rem(20), marginBottom: rem(20) }}
              className={classes.subheader}
            >
              <FormattedMessageWrapper id="tournament.entriesSettings" />
            </Typography>
            <FormattedMessageWrapper id="payments.entries.settings" />
          </Grid>
          <Grid item xs={12}>
            <FormControl className="SwitchControl">
              <Switch
                checked={tournamentSiteSettings.allowPostponePayment}
                onChange={handleOnChange}
                name="allowPostponePayment"
                value="true"
                color="primary"
              />
              <FormLabel htmlFor={'allowClubhousePayment'} className="SwitchLabel">
                <FormattedMessageWrapper id="payments.entries.allowClubhousePayment" />
              </FormLabel>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="postponePaymentNote"
              variant="outlined"
              multiline
              minRows={3}
              fullWidth
              value={tournamentSiteSettings.postponePaymentNote}
              onChange={handleOnChange}
              placeholder={intl.formatMessage({ id: 'payments.entries.noteToPlayersInputPlaceholder' })}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" style={{ marginTop: rem(10) }} className={classes.subheader}>
              <FormattedMessageWrapper id="footer.termsOfUse" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ContentBlock
              fieldName="termsAndConditions"
              tournamentSite={tournamentSiteSettings}
              updateAction={_updateTournamentSiteField}
              maxWidth="100%"
              error={tournamentSiteSettings.paymentEnabled && !tournamentSiteSettings.termsAndConditions}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: rem(20), marginBottom: rem(20) }}>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.subheader}>
              <FormattedMessageWrapper id="payments.entries.selectedProducts" />
              <InfoTooltip
                text={<FormattedMessageWrapper id="payments.entries.selectedProductsInfo" />}
                arrow
                className={classes.infoTooltip}
              />
            </Typography>
          </Grid>
          <Grid item xs={2} className={activeProductCount > 0 ? classes.selected : classes.selectedZero}>
            <FormattedMessageWrapper id={'divisions.nSelected'} values={{ value: activeProductCount }} />
          </Grid>
          <Grid item xs={12}>
            {maybeRenderNoProductsAvailableMessage()}
            {maybeRenderNoActiveProductsMessage()}
            {maybeRenderActiveProducts()}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h4" className={classes.subheader}>
              {inactiveProducts.length > 0 && <FormattedMessageWrapper id="payments.entries.availableProducts" />}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {maybeRenderInactiveProducts()}
          </Grid>
        </Grid>
      </Collapse>

      <Divider style={{ marginTop: 30 }} />
    </>
  )
}
