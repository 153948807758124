import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import authStyles from './styles'
import { Card } from '@mui/material'
import { WithStyles } from '@mui/styles'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import withStyles from '@mui/styles/withStyles'
import TextField from '@mui/material/TextField'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import isEmail from 'validator/lib/isEmail'
import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'
import { NavigateBefore } from '@mui/icons-material'
import { API_ROOT, APIRoute } from '../../config'
import { LoginBg } from '../../assets/images'
import HeadHelmet from '../../components/layout/HeadHelmet'
import { getSiteLogo, getSiteLogo2x } from '@app/utils/logoUtils'
import LanguageSwitcher from '@app/components/layout/LanguageSwitcher'
import { RootState } from '@app/store'

type Props = WithRouterProps & WithStyles<typeof authStyles> & StateProps

interface StateProps {
  locale: LocaleState
}

interface State {
  email: string
  loading: boolean
  error: boolean
  success: boolean
  response?: any
}

const initialState = {
  error: false,
  success: false,
  loading: false,
}

class ForgotPassword extends React.Component<Props, State> {
  readonly state: State = {
    email: '',
    ...initialState,
  }

  componentDidMount() {
    document.addEventListener('keydown', (event) => this._handleKeyDown(event))
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyDown)
  }

  _handleForgot = () => {
    const { email } = this.state
    const { locale } = this.props

    const data = new FormData()
    data.append('email', email)
    data.append('lang', locale?.appLanguage.code)

    this.setState({ ...initialState, loading: true }, () => {
      const options: RequestInit = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
        },
        body: data,
      }

      fetch(`${API_ROOT}${APIRoute.POST_RESET_PASSWORD()}`, options)
        .then((response) => response.json())
        .then((json) => {
          if (json.error) {
            this.setState({
              loading: false,
              success: true,
              error: true,
              response: json.error,
            })
          } else {
            this.setState({
              loading: false,
              success: true,
              error: false,
              response: json,
            })
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            success: true,
            error: true,
            response: error,
          })
        })
    })
  }

  _handleToLogin = () => {
    const { navigate } = this.props

    navigate('/login')
  }

  render() {
    const { classes } = this.props
    const { success, loading } = this.state

    return (
      <>
        <HeadHelmet titleId={'navigation.passwordForgotten'} />

        <div className={classes.wrapper} style={{ backgroundImage: `url(${LoginBg})` }}>
          <div className={classes.languageSwitcherContainer}>
            <LanguageSwitcher />
          </div>
          <Card style={{ padding: '1em' }}>
            <CardContent>
              <div style={{ textAlign: 'center' }}>
                <img
                  style={{
                    width: 265,
                    margin: `0 0 2rem`,
                  }}
                  alt={'GGB'}
                  src={getSiteLogo()}
                  srcSet={`${getSiteLogo2x()} 2x`}
                />
              </div>
              <Typography component="h2" style={{ margin: 0 }}>
                <FormattedMessageWrapper id={'login.forgotPassword.helpText'} />
              </Typography>
              <TextField
                disabled={loading}
                name={'email'}
                style={{ marginTop: '1em' }}
                fullWidth={true}
                label={<FormattedMessageWrapper id={'login.forgotPassword.emailFieldLabel'} />}
                value={this.state.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const {
                    currentTarget: { value },
                  } = e
                  this.setState({ email: value })
                }}
              />
            </CardContent>
            <CardActions style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button
                disabled={!isEmail(this.state.email) || loading}
                variant="contained"
                color="primary"
                onClick={this._handleForgot}
              >
                <FormattedMessageWrapper id={'login.forgotPassword.submitButtonLabel'} />
              </Button>
            </CardActions>

            <Collapse in={loading} timeout={'auto'} unmountOnExit={true}>
              <div style={{ margin: '2em 0', textAlign: 'center' }}>
                <CircularProgress size={28} thickness={2} />
              </div>
            </Collapse>

            <Collapse in={success} timeout={'auto'} unmountOnExit={true}>
              <CardContent>
                {success && (
                  <Typography component={'p'}>
                    <FormattedMessageWrapper id={'login.forgotPassword.success'} />
                  </Typography>
                )}
              </CardContent>
            </Collapse>
          </Card>

          <div className={classes.actionsFooter}>
            <Button onClick={this._handleToLogin}>
              <NavigateBefore />
              <FormattedMessageWrapper id={'buttons.backToLogin'} />
            </Button>
          </div>
        </div>
      </>
    )
  }

  private _handleKeyDown = (event: KeyboardEvent) => {
    const { keyCode } = event
    if (keyCode && keyCode === 13 && this.state.email !== '') {
      this._handleForgot()
    }
  }
}

const StyledForgotPassword = withStyles(authStyles)(ForgotPassword)
const RoutedForgetPassword = withRouter(StyledForgotPassword)
const ConnectedForgotPassword = connect<StateProps, {}, {}, RootState>((store) => ({
  locale: store.localeReducer,
}))(RoutedForgetPassword as any)
export default ConnectedForgotPassword
