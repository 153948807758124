import { enqueueNotification } from '@app/store/notifications/actions'
import { OptionsObject } from 'notistack'
import { useDispatch } from 'react-redux'

export const useNotification = () => {
  const dispatch = useDispatch()

  const showNotification = (message: string, variant: OptionsObject['variant']) => {
    dispatch(enqueueNotification(message, variant))
  }

  return showNotification
}
