import React from 'react'
import PlayerScore from './PlayerScore'
import { Collapse, Button } from '@mui/material'
import SectionTitleWithCollapse from '../../ui/SectionTitleWithCollapse'
import SectionTitle from '../../ui/SectionTitle'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'

interface Props {
  scores?: TournamentLeaderboardPlayer[]
  onClose?(refetch?: boolean): void
  tournament: TournamentState
  teamId: string | undefined
}

interface State {
  expanded?: number
}

class TeamScores extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      expanded: undefined,
    }
  }
  render() {
    const { scores, onClose, teamId } = this.props

    const { expanded } = this.state

    if (!scores) {
      return null
    }

    return (
      <div style={{ margin: '0 24px 30px 24px' }}>
        {scores.map((score, i) => (
          <div key={i}>
            <SectionTitleWithCollapse
              expanded={expanded === i}
              onChange={(expanded) => this.setState({ expanded: expanded ? i : undefined })}
            >
              <SectionTitle style={{ marginBottom: 0 }}>{score.name}</SectionTitle>
            </SectionTitleWithCollapse>
            <Collapse in={expanded === i} style={{ borderBottomWidth: '1px' }}>
              <div style={{ margin: '0 -24px' }}>
                <PlayerScore onClose={onClose} score={score} isTeam={true} teamId={teamId} />
              </div>
            </Collapse>
          </div>
        ))}
        {onClose && (
          <div>
            <Button onClick={() => onClose(false)} style={{ marginLeft: 'auto', display: 'block', marginTop: 20 }}>
              <FormattedMessageWrapper id={'buttons.close'} />
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default TeamScores
