import { useDispatch } from 'react-redux'
import reducer from './indexReducer'
import sagas from './indexSagas'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { api } from './api/baseApi'
import { callbackMiddleware } from './api/middleware/callbackMiddleware'
import { notificationsMiddleware } from './api/middleware/notificationsMiddleware'
import { redirectionMiddleware } from './api/middleware/redirectionMiddleware'

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware()
export const store = configureStore({
  reducer: reducer(),
  middleware: (getDefaultMiddleware) =>
    // TODO: fix the actual issue with unserializable data (dates) in store
    getDefaultMiddleware({ serializableCheck: false })
      .concat(sagaMiddleware) // allows redux devtools to watch sagas
      .concat(api.middleware)
      .concat(callbackMiddleware)
      .concat(notificationsMiddleware)
      .concat(redirectionMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
})
sagaMiddleware.run(sagas)

export type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch
const useAppDispatch: () => AppDispatch = useDispatch

export { reducer, sagas, useAppDispatch }
