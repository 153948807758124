import {
  CREATE_ORGANIZATION_APPLICATION_REQUESTING,
  ORGANIZATION_FETCH,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_APPLICATION_REQUESTING,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_SUCCESS,
  UPLOAD_ORGANIZATION_COVER_IMAGE,
  UPLOAD_ORGANIZATION_COVER_IMAGE_FAILURE,
  UPLOAD_ORGANIZATION_COVER_IMAGE_SUCCESS,
  UPLOAD_ORGANIZATION_LOGO,
  UPLOAD_ORGANIZATION_LOGO_FAILURE,
  UPLOAD_ORGANIZATION_LOGO_SUCCESS,
  ADD_ORGANIZATION_LICENSE,
  ADD_ORGANIZATION_LICENSE_SUCCESS,
  ADD_ORGANIZATION_LICENSE_FAILURE,
  FETCH_ORGANIZATION_LICENSE_HISTORY,
  FETCH_ORGANIZATION_LICENSE_HISTORY_SUCCESS,
  FETCH_ORGANIZATION_LICENSE_HISTORY_FAILURE,
  ADD_ORGANIZATION_SUBSCRIPTION,
  ADD_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  ADD_ORGANIZATION_SUBSCRIPTION_FAILURE,
  FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY,
  FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_SUCCESS,
  FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_FAILURE,
} from './constants'
import { all, takeEvery, put, select } from 'redux-saga/effects'
import {
  CreateOrganizationApplication,
  fetchOrganization,
  FetchOrganizationAction,
  putOrganizationApplicationFailure,
  putOrganizationApplicationSuccess,
  putOrganizationFailure,
  putOrganizationSuccess,
  UpdateOrganizationAction,
  UpdateOrganizationApplicationAction,
  UploadOrganizationCoverImageAction,
  UploadOrganizationLogoImageAction,
  AddOrganizationLicenseAction,
  FetchOrganizationLicenseHistoryAction,
  AddOrganizationSubscriptionAction,
} from './actions'
import { getToken } from '../authentication/sagas'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import { API_ROOT, APIRoute } from '../../config'
import { enqueueNotification } from '../notifications/actions'
import { getUserOrganizationId } from '../authentication/selectors'
import { RootState } from '..'

export const getCurrentOrganizationCountry = (store: RootState) => store.organizationReducer.countryId

const getCurrentOrganization = (store: RootState) => store.organizationReducer
function* doFetchOrganization({ id, force, onComplete }: FetchOrganizationAction) {
  try {
    const currentOrganization = yield select(getCurrentOrganization)

    if (currentOrganization.id !== id || force) {
      const token = yield getToken()
      const res = yield apiClientRequest({
        url: `${API_ROOT}${APIRoute.GET_ORGANIZATION(id)}`,
        method: 'get',
        token,
      })
      yield put(putOrganizationSuccess(res.data))
    } else {
      yield put(putOrganizationSuccess(currentOrganization))
    }
    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put(putOrganizationFailure(error))
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doCreateOrganizationApplication({ organizationApplication, onComplete }: CreateOrganizationApplication) {
  try {
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ORGANIZATION_APPLICATION()}`,
      method: 'post',
      data: organizationApplication,
    })

    const { data } = res
    defaultOnCompleteCall(onComplete, data)
    yield put(putOrganizationApplicationSuccess(data))
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, null, error)
    yield put(putOrganizationApplicationFailure(error))
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doUpdateOrganizationApplication({ application, onComplete }: UpdateOrganizationApplicationAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ORGANIZATION_APPLICATION(application.id)}`,
      method: 'put',
      token,
      data: application,
    })

    const { data } = res
    defaultOnCompleteCall(onComplete, data)
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, null, error)
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doUploadOrganizationLogo({ organizationId, logoImage, onComplete }: UploadOrganizationLogoImageAction) {
  try {
    const userOrganizationId = yield select(getUserOrganizationId)
    const token = yield getToken()

    const data = new FormData()
    data.append('logoImage', logoImage)

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATION(organizationId)}/logo-image`,
      method: 'post',
      token,
      data,
    })

    defaultOnCompleteCall(onComplete, res.data)
    yield put({ type: UPLOAD_ORGANIZATION_LOGO_SUCCESS, loading: userOrganizationId === organizationId })
    if (userOrganizationId === organizationId) {
      yield put(fetchOrganization(organizationId, true))
    }
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, null, error)
    yield put(enqueueNotification(error, 'error'))
    yield put({ type: UPLOAD_ORGANIZATION_LOGO_FAILURE, error })
  }
}

function* doUploadOrganizationCoverImage({ organizationId, image, onComplete }: UploadOrganizationCoverImageAction) {
  try {
    const userOrganizationId = yield select(getUserOrganizationId)
    const token = yield getToken()

    const data = new FormData()
    data.append('coverImage', image)

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATION(organizationId)}/cover-image`,
      method: 'post',
      token,
      data,
    })

    defaultOnCompleteCall(onComplete, res.data)
    yield put({ type: UPLOAD_ORGANIZATION_COVER_IMAGE_SUCCESS, loading: userOrganizationId === organizationId })
    if (userOrganizationId === organizationId) {
      yield put(fetchOrganization(organizationId, true))
    }
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, null, error)
    yield put(enqueueNotification(error, 'error'))
    yield put({ type: UPLOAD_ORGANIZATION_COVER_IMAGE_FAILURE, error })
  }
}

function* doUpdateOrganization({ organizationId, onComplete, ...rest }: UpdateOrganizationAction) {
  try {
    const token = yield getToken()

    const { ...data } = rest

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATION(organizationId)}`,
      method: 'patch',
      token,
      data,
    })

    defaultOnCompleteCall(onComplete, res.data)
    yield put({
      type: UPDATE_ORGANIZATION_SUCCESS,
      organization: res.data,
    })
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, null, error)
    yield put(enqueueNotification(error, 'error'))
    yield put({ type: UPDATE_ORGANIZATION_FAILURE, error })
  }
}

function* doAddOrganizationLicense({ organizationId, licenseCount, notes, onSuccess }: AddOrganizationLicenseAction) {
  const token = yield getToken()

  try {
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ADD_ORGANIZATION_LICENSE(organizationId)}`,
      method: 'put',
      token,
      data: { licenseCount, notes },
    })

    yield put({
      type: ADD_ORGANIZATION_LICENSE_SUCCESS,
    })

    onSuccess()
  } catch (error: any) {
    yield put({
      type: ADD_ORGANIZATION_LICENSE_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doAddOrganizationSubscription({
  organizationId,
  validFrom,
  validTo,
  notes,
  onSuccess,
}: AddOrganizationSubscriptionAction) {
  const token = yield getToken()

  try {
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ADD_ORGANIZATION_SUBSCRIPTION(organizationId)}`,
      method: 'put',
      token,
      data: { validFrom, validTo, notes },
    })

    yield put({
      type: ADD_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    })

    onSuccess()
  } catch (error: any) {
    yield put({
      type: ADD_ORGANIZATION_SUBSCRIPTION_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchOrganizationLicenseHistory({ organizationId }: FetchOrganizationLicenseHistoryAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATION_LICENSE_HISTORY(organizationId)}`,
      method: 'get',
      token,
    })
    yield put({
      type: FETCH_ORGANIZATION_LICENSE_HISTORY_SUCCESS,
      history: res.data.history,
    })
  } catch (error: any) {
    yield put(enqueueNotification(error, 'error'))
    yield put({
      type: FETCH_ORGANIZATION_LICENSE_HISTORY_FAILURE,
      error,
    })
  }
}

function* doFetchOrganizationSubscriptionHistory({ organizationId }: FetchOrganizationLicenseHistoryAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATION_SUBSCRIPTION_HISTORY(organizationId)}`,
      method: 'get',
      token,
    })
    yield put({
      type: FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_SUCCESS,
      subHistory: res.data.history,
    })
  } catch (error: any) {
    yield put(enqueueNotification(error, 'error'))
    yield put({
      type: FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_FAILURE,
      error,
    })
  }
}

export function* watchOrganization() {
  yield all([
    takeEvery(ORGANIZATION_FETCH, doFetchOrganization),
    takeEvery(CREATE_ORGANIZATION_APPLICATION_REQUESTING, doCreateOrganizationApplication),
    takeEvery(UPDATE_ORGANIZATION_APPLICATION_REQUESTING, doUpdateOrganizationApplication),
    takeEvery(UPLOAD_ORGANIZATION_LOGO, doUploadOrganizationLogo),
    takeEvery(UPDATE_ORGANIZATION, doUpdateOrganization),
    takeEvery(UPLOAD_ORGANIZATION_COVER_IMAGE, doUploadOrganizationCoverImage),
    takeEvery(ADD_ORGANIZATION_LICENSE, doAddOrganizationLicense),
    takeEvery(ADD_ORGANIZATION_SUBSCRIPTION, doAddOrganizationSubscription),
    takeEvery(FETCH_ORGANIZATION_LICENSE_HISTORY, doFetchOrganizationLicenseHistory),
    takeEvery(FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY, doFetchOrganizationSubscriptionHistory),
  ])
}
