export const UpdatesIcon = ({
  style = {},
  fill = '#1AA85D',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 22 22',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8137 4.59746C7.70302 2.37337 5.93894 9.42753 5.46764
    10.7224C4.63788 13.0021 1.76991 13.6794 1.76991 13.6794L1.21674 15.1993L16.6488 20.8161L17.2019 19.2962C17.2019
    19.2962 15.4403 16.9339 16.2701 14.6542C16.7414 13.3593 19.9243 6.82155 13.8137 4.59746Z"
    />
    <rect
      x="12.9219"
      y="2.24609"
      width="3.28448"
      height="3.23472"
      rx="1.61736"
      transform="rotate(20 12.9219 2.24609)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.84063 20.2996C6.36556 20.8546 7.9923 20.3378 8.75679 19.1432L2.58398 16.8965C2.40173
      18.303 3.31571 19.7445 4.84063 20.2996Z"
    />
  </svg>
)
