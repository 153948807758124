import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Theme, Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { loadTournamentContestLeaderboard } from '@store/tournamentLeaderboard/actions'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { ArrowForward } from '@mui/icons-material'
import { CourseIcon } from '@assets/icons/course'
import { GameIcon } from '@assets/icons/game'
import { formatMeasurement } from '@app/utils/tournamentUtils'
import { ContestResultType, Units } from '@app/store/api/enums/tournamentEnums'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd'
import { DragIndicator, AddCircle } from '@mui/icons-material'
import classNames from 'classnames'
import AddEditContestResultDialog from '@app/components/dialogs/AddEditContestResultDialog/AddEditContestResultDialog'
import { RootState } from '@app/store'
import { selectTournamentConfig, setContestEditMode } from '@app/store/api/slices/configSlice'
import { useSendNonMeasuredContestResultsMutation } from '@app/store/api/endpoints/tournamentContestApi'
import { useSelectedRound } from '@app/hooks'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles((theme: Theme) => ({
  contest: {
    '& thead': {
      backgroundColor: theme.customPalette.darkGray2,
      height: 40,
      borderRadius: '20px 20px 0 0',
    },
    '& th, & td': {
      padding: '10px !important',
    },
    '& td': {
      border: 0,
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: 'rgba(26,168,93,0.08)',
    },
  },
  contestWrapper: {
    borderRadius: '8px',
    overflow: 'hidden',
  },
  contestWrapperEditMode: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  title: {
    color: '#fff',
    fontSize: theme.typography.pxToRem(16),
    marginTop: 2,
  },
  hole: {
    color: '#fff',
    fontWeight: 300,
    textAlign: 'right',
  },
  position: {
    width: 35,
    paddingRight: 0,
  },
  measurement: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  icon: {
    height: '25px !important',
    width: '25px !important',
    marginRight: 10,
    fill: '#fff',
    float: 'left',
  },
  titleWrapper: {
    backgroundColor: theme.customPalette.darkGreen,
    padding: '16px',
    marginBottom: '20px',
    overflow: 'hidden',
  },
  addButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-start',
  },
  addButtonIcon: {
    color: theme.palette.primary.main,
  },
  dragIndicatorCell: {
    display: 'flex',
    alignItems: 'center',
    width: 10,
  },
  editButton: {
    width: 184,
    marginBottom: 20,
  },
}))

export const LeaderboardContests = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const tournament = useSelector(selectTournament)
  const tournamentConfig = useSelector(selectTournamentConfig)
  const isTournamentLoading = tournamentConfig.status.isTournamentLoading
  const contestLeaderboard = useSelector((store: RootState) => store.tournamentLeaderboardReducer.contestLeaderboard)
  const divisions = useSelector((store: RootState) => store.divisionsReducer)

  const [openAddEditContestResultDialog, setOpenAddEditContestResultDialog] = useState<boolean>(false)
  const [selectedContest, setSelectedContest] = useState<ContestResult | undefined>()
  const [selectedEntry, setSelectedEntry] = useState<ContestEntry | undefined>()

  const units: Units = (tournament.tournamentOrganization?.units as Units) || Units.METRIC
  const selectedRoundId = useSelectedRound()?.id || 0
  const [sendNonMeasuredContestResults] = useSendNonMeasuredContestResultsMutation()

  useEffect(() => {
    if (tournament.id) {
      dispatch(loadTournamentContestLeaderboard(tournament.id, divisions.selectedDivisionId))
    }
  }, [tournament.id, divisions.selectedDivisionId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(setContestEditMode(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!contestLeaderboard) {
    return null
  }

  const handleEditModeChange = () => {
    if (tournament.id) {
      dispatch(loadTournamentContestLeaderboard(tournament.id))
    }
    dispatch(setContestEditMode(!tournamentConfig.contestEditMode))
  }

  const renderRound = (round: ContestRound, units: Units) => {
    return (
      <Grid container direction="column">
        <Grid item>
          <Button
            className={classes.editButton}
            variant={tournamentConfig.contestEditMode ? 'contained' : 'outlined'}
            onClick={handleEditModeChange}
          >
            <FormattedMessageWrapper id={tournamentConfig.contestEditMode ? 'buttons.done' : 'buttons.addResult'} />
          </Button>
        </Grid>
        <Grid item>
          <Grid container spacing={{ xs: 2, md: 3, lg: 3 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              {cloneDeep(round.contests)
                .sort(
                  (a, b) =>
                    Object.values(ContestResultType).indexOf(a.type) -
                      Object.values(ContestResultType).indexOf(b.type) || a.holeNumber - b.holeNumber,
                )
                .map((c, i) => renderContest(c, i, units))}
            </DragDropContext>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderContest = (contest: ContestResult, idx: number, units: Units) => {
    //TODO: Remove this sorting when the core https://golfgamebook.atlassian.net/browse/CORE-1318 ticket is done
    let sortedEntries = contest.entries
    if (contest.type === ContestResultType.CLOSEST_TO_PIN) {
      sortedEntries = contest.entries.slice().sort((a, b) => a.score - b.score)
    }
    return (
      <Grid item xs={12} md={6} lg={4} key={`${contest.type}-${contest.holeNumber}`}>
        <div
          className={classNames([
            classes.contestWrapper,
            tournamentConfig.contestEditMode && classes.contestWrapperEditMode,
          ])}
        >
          <Table className={classes.contest}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={tournamentConfig.contestEditMode ? 3 : 2}>{getTitle(contest.type)}</TableCell>
                <TableCell className={classes.hole}>
                  <FormattedMessageWrapper id="tournament.hole" /> {contest.holeNumber}
                </TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId={`droppable-contest-${idx}`} type="droppableContest">
              {(dropProvided: DroppableProvided) => (
                <TableBody ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                  {sortedEntries.map(
                    (r, i) =>
                      r.score !== undefined && (
                        <Draggable
                          draggableId={`draggable-player-${i}-${idx}`}
                          index={i}
                          key={`${r.user.profileId}${idx}${i}`}
                          isDragDisabled={!tournamentConfig.contestEditMode || contest.measured || isTournamentLoading}
                        >
                          {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
                            <TableRow
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                              style={{
                                ...dragProvided.draggableProps.style,
                                width: dragSnapshot.isDragging ? '430px' : 'auto',
                                display: dragSnapshot.isDragging ? 'table' : 'table-row',
                                cursor: tournamentConfig.contestEditMode ? 'pointer' : 'default',
                              }}
                              key={i}
                              onClick={() => handleAddEditContestResultDialogOpenClose(contest, r)}
                            >
                              {tournamentConfig.contestEditMode && !contest.measured && (
                                <TableCell {...dragProvided.dragHandleProps} className={classes.dragIndicatorCell}>
                                  <DragIndicator color="primary" />
                                </TableCell>
                              )}
                              <TableCell className={classes.position} colSpan={1}>
                                {r.position}
                              </TableCell>
                              <TableCell colSpan={1} style={{ alignSelf: 'flex-start' }}>
                                {r.user.firstName} {r.user.lastName}
                              </TableCell>
                              <TableCell className={classes.measurement} colSpan={3}>
                                {contest.measured && formatMeasurement(r.score, units)}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ),
                  )}
                  {dropProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
          {tournamentConfig.contestEditMode && (
            <Button
              startIcon={<AddCircle className={classes.addButtonIcon} />}
              className={classes.addButton}
              onClick={() => handleAddEditContestResultDialogOpenClose(contest)}
            >
              <FormattedMessageWrapper id="buttons.addResult" />
            </Button>
          )}
        </div>
      </Grid>
    )
  }

  const getTitle = (type: ContestResultType) => {
    if (type === ContestResultType.CLOSEST_TO_PIN) {
      return (
        <>
          <CourseIcon className={classes.icon} />
          <Typography variant="h3" className={classes.title}>
            <FormattedMessageWrapper id="tournament.closestToPin" />
          </Typography>
        </>
      )
    } else if (type === ContestResultType.LONGEST_DRIVE) {
      return (
        <>
          <GameIcon className={classes.icon} />
          <Typography variant="h3" className={classes.title}>
            <FormattedMessageWrapper id="tournament.longestDrive" />
          </Typography>
        </>
      )
    } else if (type === ContestResultType.STRAIGHTEST_DRIVE) {
      return (
        <>
          <ArrowForward className={classes.icon} />
          <Typography variant="h3" className={classes.title}>
            <FormattedMessageWrapper id="tournament.straightestDrive" />
          </Typography>
        </>
      )
    } else {
      return <></>
    }
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result

    const draggableIdString = draggableId.split('-')
    const contestIdx = Number(draggableIdString[3])
    const playerIdx = Number(draggableIdString[2])

    const { contests } = contestLeaderboard.data.rounds[tournamentConfig.selectedRoundIndex]
    const { entries, holeNumber } = contests[contestIdx]

    if (source.droppableId === destination?.droppableId) {
      //Save entries with original order, before changing order
      const originalPlayerIdOrder = entries.filter((entrie) => entrie.score).map((a) => Number(a.user.profileId))

      //Chance player position in entries array
      entries.splice(destination.index, 0, entries.splice(playerIdx, 1)[0])
      entries.join()

      const playerIds = entries.filter((entrie) => entrie.score).map((a) => Number(a.user.profileId))

      sendNonMeasuredContestResults({
        tournamentId: tournament.id,
        roundId: selectedRoundId,
        holeNumber,
        body: { userIds: playerIds, originialPlayerIdOrder: originalPlayerIdOrder },
      })
    }
  }

  const handleAddEditContestResultDialogOpenClose = (contest?: ContestResult, selectedEntry?: ContestEntry) => {
    if (tournamentConfig.contestEditMode) {
      if (contest !== undefined) {
        setSelectedContest(contest)
      }
      setSelectedEntry(selectedEntry)
      setOpenAddEditContestResultDialog(!openAddEditContestResultDialog)
    }
  }

  return (
    <>
      {contestLeaderboard?.data.rounds.map((round, i) => {
        return (
          <div key={i} style={{ marginBottom: 30 }}>
            <div className={classes.titleWrapper}>
              <Typography variant="h2" style={{ color: '#fff' }}>
                <FormattedMessageWrapper id="tournament.roundCount" values={{ round: i + 1 }} />
              </Typography>
            </div>
            {renderRound(round, units)}
          </div>
        )
      })}
      <AddEditContestResultDialog
        open={openAddEditContestResultDialog}
        contest={selectedContest}
        selectedEntry={selectedEntry}
        units={units}
        onClose={() => handleAddEditContestResultDialogOpenClose()}
        onContestUpdate={() => {
          if (tournament.id) {
            dispatch(loadTournamentContestLeaderboard(tournament.id))
          }
        }}
      />
    </>
  )
}
