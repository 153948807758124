import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import {
  DialogContent,
  Button,
  Theme,
  Typography,
  TextField,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  FormLabel,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogActions from '@mui/material/DialogActions'
import { withStyles, WithStyles } from '@mui/styles'
import { ContestResultType, Units } from '@app/store/api/enums/tournamentEnums'
import { parseMeasurement, createMeasurement } from '@app/utils/tournamentUtils'
import classNames from 'classnames'
import { tournamentContestApi } from '@app/store/api/endpoints/tournamentContestApi'
import { RootState } from '@app/store'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

interface OwnProps {
  open: boolean
  contest: ContestResult | undefined
  selectedEntry: ContestEntry | undefined
  units: Units
  onClose(): void
  onContestUpdate?(): void
}

interface StateProps {
  tournamentId: number
  selectedRoundId: number
}

interface DispatchProps {
  sendNonMeasuredContestResults(payload: NonMeasuredContestPayload): void
  sendMeasuredContestResults(payload: MeasuredContestPayload): void
  deleteContestResult(payload: DeleteContestResultPayload): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    title: {
      color: theme.customPalette.dark,
      fontSize: 20,
    },
    subtitle: {
      marginTop: 20,
      color: theme.customPalette.darkGreen,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: 16,
      fontWeight: 400,
    },
    textFieldPlayerName: {
      width: '100%',
    },
    textFieldShort: {
      '& .MuiOutlinedInput-input': {
        paddingTop: 10,
        paddingBottom: 10,
      },
      width: 73,
    },
    dialogActions: {
      justifyContent: 'center',
    },
    dialogActionButton: {
      width: 200,
    },
    dialogActionButtonRemoveResult: {
      backgroundColor: theme.customPalette.darkGray2,
    },
    textFieldContainer: {
      display: 'flex',
    },
    unitContainer: {
      alignSelf: 'center',
      paddingLeft: 10,
      paddingRight: 10,
    },
  })

interface State {
  selectedPlayer: string
  unit?: number | undefined
  subUnit?: number | undefined
}

const initialState: State = {
  selectedPlayer: '0',
  unit: undefined,
  subUnit: undefined,
}

interface Options {
  label: string | JSX.Element
  value: string
}

class AddEditContestResultDialog extends React.Component<Props, State> {
  readonly state: State = initialState

  render() {
    const { open = false, classes, contest } = this.props
    const { selectedPlayer } = this.state

    const noneOption: Options = { label: <FormattedMessageWrapper id="tournament.searchPlayer" />, value: '0' }
    const playerOptions: Options[] = [noneOption].concat(
      contest?.entries
        .filter((entry) => entry.score === undefined)
        .map((entry) => ({
          label: `${entry.user.firstName} ${entry.user.lastName}`,
          value: entry.user.profileId,
        })) ?? [],
    )

    return (
      <Dialog maxWidth="xs" fullWidth open={open} aria-labelledby="tournament-notes-title">
        <BaseDialogTitle
          id="tournament-notes-title"
          title={
            <Typography variant="h1" className={classes.title}>
              <FormattedMessageWrapper id={this.dialogTitleId()} />
            </Typography>
          }
          onClose={() => this.handleClose()}
        />
        <DialogContent>
          <Typography variant="h4">
            {this.getContestName(contest?.type || ContestResultType.CLOSEST_TO_PIN)} -{' '}
            <FormattedMessageWrapper id="tournament.hole" /> {contest?.holeNumber || 0}
          </Typography>
          {!this.isEditMode() ? (
            <FormControl fullWidth>
              <FormLabel htmlFor="player" className={classes.subtitle}>
                <FormattedMessageWrapper id="tournament.player" />
              </FormLabel>
              <Select
                variant="filled"
                name="player"
                id="player"
                fullWidth
                value={selectedPlayer}
                input={<OutlinedInput name="player" id="player" />}
                onChange={(e) => this.onPlayerChange(e)}
              >
                {playerOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              className={classes.textFieldPlayerName}
              size="small"
              value={this.playerFullName()}
              disabled={true}
            />
          )}
          {contest?.measured && (
            <>
              <div className={classes.subtitle}>
                <FormattedMessageWrapper id="tournament.results" />
              </div>
              {this.renderInputs()}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {this.isEditMode() && (
            <Button
              color="secondary"
              variant="contained"
              className={classNames([classes.dialogActionButton, classes.dialogActionButtonRemoveResult])}
              onClick={() => this.handleRemoveResult()}
            >
              <FormattedMessageWrapper id="buttons.removeResult" />
            </Button>
          )}
          {(!this.isEditMode() || contest?.measured) && (
            <Button
              color="primary"
              variant="contained"
              className={classes.dialogActionButton}
              onClick={() => this.handleSave()}
            >
              <FormattedMessageWrapper id={this.saveButtonCaptionId()} />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }

  private renderInputs = () => {
    const { classes, contest, selectedEntry, units } = this.props
    const { unit, subUnit } = this.state

    if (contest?.type === ContestResultType.LONGEST_DRIVE) {
      return (
        <div className={classes.textFieldContainer}>
          <TextField
            className={classes.textFieldShort}
            size="small"
            type="number"
            value={unit !== undefined ? unit : parseMeasurement(selectedEntry?.score, units).unit}
            InputProps={{
              inputProps: { min: 0 },
            }}
            onChange={(e) => this.updateUnit(e)}
          />
          <div className={classes.unitContainer}>
            <FormattedMessageWrapper id={units === Units.METRIC ? 'tournament.unitM' : 'tournament.unitYards'} />
          </div>
        </div>
      )
    }

    return (
      <div className={classes.textFieldContainer}>
        <TextField
          className={classes.textFieldShort}
          size="small"
          type="number"
          value={unit !== undefined ? unit : parseMeasurement(selectedEntry?.score || 0, units).unit}
          InputProps={{
            inputProps: { min: 0 },
          }}
          onChange={(e) => this.updateUnit(e)}
        />
        <div className={classes.unitContainer}>
          <FormattedMessageWrapper id={units === Units.METRIC ? 'tournament.unitM' : 'tournament.unitFt'} />
        </div>
        <TextField
          className={classes.textFieldShort}
          size="small"
          type="number"
          value={subUnit !== undefined ? subUnit : parseMeasurement(selectedEntry?.score || 0, units).subUnit}
          InputProps={{
            inputProps: { min: 0 },
          }}
          onChange={(e) => this.updateSubUnit(e)}
        />
        <div className={classes.unitContainer}>
          <FormattedMessageWrapper id={units === Units.METRIC ? 'tournament.unitCm' : 'tournament.unitIn'} />
        </div>
      </div>
    )
  }

  private isEditMode(): boolean {
    const { selectedEntry } = this.props
    return selectedEntry !== undefined
  }

  private dialogTitleId(): string {
    return !this.isEditMode() ? 'tournament.addContestResult' : 'tournament.editContestResult'
  }

  private getContestName(type: ContestResultType) {
    switch (type) {
      case ContestResultType.CLOSEST_TO_PIN:
        return <FormattedMessageWrapper id="tournament.closestToPin" />
      case ContestResultType.LONGEST_DRIVE:
        return <FormattedMessageWrapper id="tournament.longestDrive" />
      case ContestResultType.STRAIGHTEST_DRIVE:
        return <FormattedMessageWrapper id="tournament.straightestDrive" />
      default:
        return <></>
    }
  }

  private playerFullName(): string {
    const { selectedEntry } = this.props
    return `${selectedEntry?.user.firstName} ${selectedEntry?.user.lastName}`
  }

  private onPlayerChange(e) {
    this.setState({ selectedPlayer: e.target.value })
  }

  private saveButtonCaptionId(): string {
    return !this.isEditMode() ? 'buttons.addResult' : 'buttons.save'
  }

  private updateUnit(e) {
    this.setState({ unit: e.target.value && Number(e.target.value) })
  }

  private updateSubUnit(e) {
    this.setState({ subUnit: e.target.value && Number(e.target.value) })
  }

  private handleSave() {
    const { contest, selectedEntry, units } = this.props
    const { selectedPlayer } = this.state

    if (contest) {
      if (!this.isEditMode() && selectedPlayer === '0') {
        // No player selected, no action then
        return
      }

      if (this.isEditMode() && selectedEntry === undefined) {
        // This situation should never happen, but checking just in case
        // that in edit mode selected entry must be defined
        return
      }

      if (contest?.measured) {
        const playerId = Number(this.isEditMode() ? selectedEntry?.user.profileId : selectedPlayer)

        const unit =
          this.state.unit !== undefined
            ? this.state.unit
            : this.isEditMode()
            ? parseMeasurement(selectedEntry?.score, units).unit
            : 0
        const subUnit =
          this.state.subUnit !== undefined
            ? this.state.subUnit
            : this.isEditMode()
            ? parseMeasurement(selectedEntry?.score, units).subUnit
            : 0
        const score = createMeasurement(unit, subUnit, units, contest.type === ContestResultType.LONGEST_DRIVE)

        this.props.sendMeasuredContestResults({
          tournamentId: this.props.tournamentId,
          roundId: this.props.selectedRoundId,
          holeNumber: contest?.holeNumber,
          body: { userId: playerId, inches: score },
          onSuccess: () => this.handleSuccess(),
        })
      } else {
        const originalPlayerIdOrder = contest.entries
          .filter((entrie) => entrie.score)
          .map((a) => Number(a.user.profileId))
        const playerIds = originalPlayerIdOrder.concat([Number(selectedPlayer)])
        this.props.sendNonMeasuredContestResults({
          tournamentId: this.props.tournamentId,
          roundId: this.props.selectedRoundId,
          holeNumber: contest?.holeNumber,
          body: { userIds: playerIds, originialPlayerIdOrder: originalPlayerIdOrder },
          onSuccess: () => {
            this.handleSuccess()
          },
        })
      }
    }

    this.handleClose()
  }

  private handleRemoveResult() {
    const { contest, selectedEntry } = this.props

    if (contest) {
      if (!this.isEditMode() || selectedEntry === undefined) {
        // This situation should never happen, but checking just in case
        // that removing score happens only in edit mode and entry is defined
        return
      }

      this.props.deleteContestResult({
        tournamentId: this.props.tournamentId,
        roundId: this.props.selectedRoundId,
        holeNumber: contest?.holeNumber,
        playerId: Number(selectedEntry?.user.profileId),
        onSuccess: () => this.handleSuccess(),
      })
    }

    this.handleClose()
  }

  private handleClose() {
    const { onClose } = this.props

    if (onClose) {
      onClose()
    }

    this.setState({
      selectedPlayer: '0',
      unit: undefined,
      subUnit: undefined,
    })
  }

  private handleSuccess() {
    const { onContestUpdate } = this.props

    if (onContestUpdate) {
      onContestUpdate()
    }
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    tournamentId: getTournamentId(state),
    selectedRoundId: selectTournamentConfig(state).selectedRoundId,
  }),
  {
    sendNonMeasuredContestResults: tournamentContestApi.endpoints.sendNonMeasuredContestResults.initiate,
    sendMeasuredContestResults: tournamentContestApi.endpoints.sendMeasuredContestResults.initiate,
    deleteContestResult: tournamentContestApi.endpoints.deleteContestResult.initiate,
  },
)(withStyles(styles)(AddEditContestResultDialog as any))
