import makeStyles from '@mui/styles/makeStyles'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { useIntl } from 'react-intl'
import { SingleRoundIcon } from '@assets/icons/single-round'
import { TournamentTypeSelectionItem, TournamentTypeSelectionItemProps } from './TournamentTypeSelectionItem'
import { MultiRoundIcon } from '@assets/icons'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'

export interface TournamentTypeSelectionProps {
  onChange: (type: TournamentTypes) => void
  value: TournamentTypes
  lockSelections: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
}))

type TournamentTypeOptions = Record<TournamentTypes, TournamentTypeSelectionItemProps>

export const TournamentTypeSelection: React.FC<TournamentTypeSelectionProps> = ({
  value,
  onChange,
  lockSelections,
}) => {
  const classes = useStyles()
  const intl = useIntl()

  const onItemClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onChange(event.currentTarget.value as TournamentTypes)
  }

  const renderOptions = () => {
    const items: TournamentTypeOptions = {
      singleRound: {
        icon: <SingleRoundIcon />,
        title: intl.formatMessage({ id: 'options.tournamentType.singleRound.title' }),
        description: intl.formatMessage({ id: 'options.tournamentType.singleRound.description' }),
        value: TournamentTypes.singleRound,
      },
      multiRound: {
        icon: <MultiRoundIcon />,
        title: intl.formatMessage({ id: 'options.tournamentType.multiRound.title' }),
        description: intl.formatMessage({ id: 'options.tournamentType.multiRound.description' }),
        value: TournamentTypes.multiRound,
      },
      weekly: {
        icon: <DateRangeIcon width="3em" height="3em" />,
        title: intl.formatMessage({ id: 'options.tournamentType.weekly.title' }),
        description: intl.formatMessage({ id: 'options.tournamentType.weekly.description' }),
        value: TournamentTypes.weekly,
      },
      //TODO: Commented as part of TM-2944, going to be added back at some point
      /*matchplayBracket: {
        icon: <MatchplayIcon />,
        title: intl.formatMessage({ id: 'options.tournamentType.matchplayBracket.title' }),
        description: intl.formatMessage({ id: 'options.tournamentType.matchplayBracket.description' }),
        disabled: true,
        value: TournamentTypes.matchplayBracket,
      },
      redsVsBlues: {
        icon: <RedsVsBluesIcon />,
        title: intl.formatMessage({ id: 'options.tournamentType.redsVsBlues.title' }),
        description: intl.formatMessage({ id: 'options.tournamentType.redsVsBlues.description' }),
        disabled: true,
        value: TournamentTypes.redsVsBlues,
      },*/
    }

    return Object.keys(items).map((key) => (
      <TournamentTypeSelectionItem
        key={key}
        {...items[key]}
        selected={value === items[key].value}
        onChange={onItemClick}
        disabled={items[key].disabled || lockSelections}
      />
    ))
  }

  return <div className={classes.root}>{renderOptions()}</div>
}
