import React from 'react'
import { FormLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import InfoTooltip from './InfoTooltip'

type Props = {
  text: React.ReactNode
  infoText?: React.ReactNode
  style?: React.CSSProperties
  required?: boolean
  disabled?: boolean
  error?: boolean
  size?: 'small' | 'large'
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 1,
  },
}))

const FormLabelWithInfo = ({
  text,
  infoText,
  style,
  required = false,
  disabled = false,
  error = false,
  size = 'small',
}: Props) => {
  const classes = useStyles()

  return (
    <FormLabel className={classes.root} style={style} required={required} disabled={disabled} error={error}>
      {text}
      <InfoTooltip text={infoText || ''} size={size} style={{ marginLeft: 10 }} />
    </FormLabel>
  )
}

export default FormLabelWithInfo
