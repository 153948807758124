import { takeEvery, put, all, select, takeLatest } from 'redux-saga/effects'
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_CHECK,
} from './constants'
import { API_ROOT, APIRoute, getAppDomain, LOCALSTORAGE_ROUTE_NAME } from '../../config'
import { LoginAction } from './actions'
import { apiClientRequest } from '../../utils/sagaHelpers'
import { enqueueNotification } from '../notifications/actions'
import get from 'lodash/get'
import Cookies from 'js-cookie'
import { RootState } from '..'
import { mixpanelActions } from '@app/utils/mixpanel'
import { getUserData } from '@app/utils/mixpanelData'

const SESSION_KEY = 'ggb-office-session-auth-token'

function setSessionToken(token: any): void {
  Cookies.set(SESSION_KEY, token, { domain: getAppDomain(), expires: 1 })
}

function getSessionToken(): string | undefined {
  return Cookies.get(SESSION_KEY)
}

function destroySessionData() {
  Cookies.remove(SESSION_KEY, { domain: getAppDomain() })
  localStorage.removeItem(LOCALSTORAGE_ROUTE_NAME)
}

function initMixpanel(data: AuthenticationState) {
  if (data.mixpanelToken && data.id) {
    mixpanelActions.initAndIdentify(data.mixpanelToken, String(data.id))
    mixpanelActions.addProfileData(getUserData(data))
  }
}

const getTokenFromState = (state: RootState) => state.authenticationReducer.token

export function* getToken() {
  // Use store token if available
  const stateToken = yield select(getTokenFromState)

  if (stateToken) {
    return stateToken
  }

  // Fallback to cookie token in case of emergency
  return getSessionToken()
}

function* doLogoutSuccess() {
  yield destroySessionData()
}

function* doCheckTokenValidity() {
  try {
    const token = yield getToken()
    if (token) {
      const { data } = yield apiClientRequest({
        url: `${API_ROOT}${APIRoute.GET_CHECK_TOKEN(token)}`,
        method: 'get',
        token,
      })

      yield put({
        type: AUTH_LOGIN_SUCCESS,
        payload: {
          ...data,
        },
      })

      initMixpanel(data)
    } else {
      yield put({
        type: AUTH_LOGOUT_SUCCESS,
      })
    }
  } catch (error: any) {
    yield put(enqueueNotification('Session expired. Please log in again', 'default'))
    yield put({
      type: AUTH_LOGOUT_SUCCESS,
    })
  }
}

function* doLogin(action: LoginAction) {
  try {
    const data = new FormData()
    data.set('username', action.payload.username)
    data.set('password', action.payload.password)

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.POST_LOGIN}`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    })
    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: {
        ...res.data,
      },
    })

    initMixpanel(res.data)

    yield setSessionToken(get(res, 'data.token'))
  } catch (error: any) {
    yield put({
      type: AUTH_LOGIN_FAILURE,
      error: error.message,
    })
  }
}

function* doLogout() {
  try {
    const token = yield getToken()
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.POST_LOGOUT}`,
      method: 'post',
      token,
    })
    yield put({
      type: AUTH_LOGOUT_SUCCESS,
    })
  } catch (error: any) {
    yield put({
      type: AUTH_LOGOUT_SUCCESS,
    })
  }
}

export function* watchAuthentication() {
  yield all([
    takeEvery(AUTH_LOGIN, doLogin),
    takeLatest(AUTH_LOGOUT, doLogout),
    takeLatest(AUTH_CHECK, doCheckTokenValidity),
    takeLatest(AUTH_LOGOUT_SUCCESS, doLogoutSuccess),
  ])
}
