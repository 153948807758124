import React, { PureComponent } from 'react'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import createStyles from '@mui/styles/createStyles'
import { Button, Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { getUnassignedPlayers, getUnassignedTeamPlayers } from '@app/store/api/selectors/tournamentStartListsSelectors'
import { PlayerDialogChildrenArgs } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import ListPlayer from './ListPlayer'
import { rem } from '@app/theme/materialUITheme'
import ExtraInfoSelect from '@components/tournament/ExtraInfoSelect'
import { ExtraInfo } from '@app/store/api/enums/tournamentEnums'
import { tournamentPlayersChangeSort } from '@store/api/thunks/tournamentPlayersThunks'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { RootState } from '@app/store'
import { tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { PlayerListStatus } from '@app/scenes/tournament/players/enums'

const styles = (theme: Theme) =>
  createStyles({
    unassignedPlayerBlock: {
      padding: theme.spacing(0.8),
      backgroundColor: '#ECECEC',
      boxSizing: 'border-box',
      height: 'calc(100vh - 250px)',
      minHeight: 500,
      overflowY: 'auto' as const,
      width: '100%',
    },
    poolHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: rem(12),
      alignItems: 'center',
      padding: `${theme.spacing(0.5)} ${theme.spacing(0.8)}`,
    },
    extraInfoSelect: {
      color: theme.palette.common.white,
      fontSize: rem(12),
      '& .MuiSelect-outlined': {
        paddingTop: 2,
        paddingBottom: 2,
      },
    },
    sortButton: {
      margin: 0,
      padding: 0,
      minWidth: 0,
      color: theme.palette.common.white,
      fontSize: rem(12),
    },
  })

interface UnassignedPlayersInnerListProps {
  players: TournamentPlayer[]
  openPlayerDialog: (player: TournamentPlayer) => () => void
  onClosePlayerDialog: () => void
  handleRemove: (playerId: any) => void
}

class UnassignedPlayersInnerList extends PureComponent<UnassignedPlayersInnerListProps> {
  render() {
    const { players, openPlayerDialog, onClosePlayerDialog, handleRemove } = this.props
    return players.map((player: TournamentPlayer, index: number) => {
      if (player.team) {
        return null
      }
      return (
        <ListPlayer
          key={player.userId}
          tournamentPlayer={player}
          index={index}
          animationDisabled
          openPlayerDialog={openPlayerDialog}
          onClosePlayerDialog={onClosePlayerDialog}
          confirmPlayerRemove={false}
          removeAction={() => handleRemove(player.id)}
          hideDragHandle={false}
          usedByComponent="UnassignedPlayersInnerList"
        />
      )
    })
  }
}

interface OwnProps extends PlayerDialogChildrenArgs {
  teamContext?: boolean
}

interface StateProps {
  unassignedPlayers: TournamentPlayer[]
  unassignedTeamPlayers: any
  isTeamFormat: boolean
  playerExtraInfo: string
  sortBy: PoolOrderBy
  sortDirection: SortDirection
  tournamentId: number
  isLoading: boolean
}

interface DispatchProps {
  movePlayers: (payload: MovePlayersPayload) => void
  tournamentPlayersChangeSort: (sortBy: PoolOrderBy, sortDirection: SortDirection) => void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & PlayerDialogChildrenArgs

class UnconnectedUnassignedPlayers extends React.PureComponent<Props> {
  render() {
    const { classes, teamContext, unassignedTeamPlayers, unassignedPlayers } = this.props

    return (
      <>
        <this.PoolHeader />
        <Droppable droppableId="unassigned" type="droppablePlayer">
          {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
            <div
              ref={dropProvided.innerRef}
              style={{
                backgroundColor: dropSnapshot.isDraggingOver ? '#ddd' : undefined,
              }}
              className={classes.unassignedPlayerBlock}
            >
              <UnassignedPlayersInnerList
                players={teamContext ? unassignedTeamPlayers : unassignedPlayers}
                openPlayerDialog={this.props.openPlayerDialog}
                onClosePlayerDialog={this.props.onClosePlayerDialog}
                handleRemove={(playerId) =>
                  this.props.movePlayers({
                    tournamentId: this.props.tournamentId,
                    playerIds: [playerId],
                    list: PlayerListStatus.REMOVED,
                  })
                }
              />
            </div>
          )}
        </Droppable>
      </>
    )
  }

  private PoolHeader = () => {
    const { classes, playerExtraInfo } = this.props

    return (
      <Grid container className={classes.poolHeader}>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          {this.renderSortButton('firstName', 'tournament.firstNameShort')}
        </Grid>
        <Grid item xs={3}>
          {this.renderSortButton('lastName', 'tournament.lastNameShort')}
        </Grid>
        <Grid item xs={1}>
          {this.renderSortButton('hcp', 'tournaments.hcpBadge')}
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex' }}>
          <ExtraInfoSelect className={classes.extraInfoSelect} default={ExtraInfo.HOME_CLUB} />
          {this.renderSortButton(playerExtraInfo)}
        </Grid>
      </Grid>
    )
  }

  private renderSortButton = (id: string, text?: string) => {
    const { classes, sortDirection, sortBy } = this.props

    const sortIcon =
      sortDirection === 'asc' ? (
        <ArrowUpwardIcon style={{ fontSize: 12 }} />
      ) : (
        <ArrowDownwardIcon style={{ fontSize: 12 }} />
      )

    return (
      <Button
        size="small"
        onClick={this.onClickSort}
        className={classes.sortButton}
        id={id}
        endIcon={sortBy === id && sortIcon}
      >
        {text && (
          <span style={{ textTransform: 'uppercase' }}>
            <FormattedMessageWrapper id={text} />
          </span>
        )}
      </Button>
    )
  }

  private onClickSort = (e) => {
    const { tournamentPlayersChangeSort, sortBy, sortDirection } = this.props
    const newSortBy = e.currentTarget.id
    /**
     * Pressed twice same column, just change sort direction.
     */
    let newSortDirection = sortDirection
    if (sortBy === newSortBy) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }
    tournamentPlayersChangeSort(newSortBy, newSortDirection)
  }
}

const UnassignedPlayers = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => {
    const tournamentConfig = selectTournamentConfig(state)
    return {
      unassignedPlayers: getUnassignedPlayers(state),
      unassignedTeamPlayers: getUnassignedTeamPlayers(state),
      isTeamFormat: selectTournament(state).isTeamFormat,
      playerExtraInfo: tournamentConfig.playerExtraInfo,
      sortBy: tournamentConfig.sortBy,
      sortDirection: tournamentConfig.sortDirection,
      tournamentId: getTournamentId(state),
      isLoading:
        tournamentConfig.status.isTournamentPlayersLoading || tournamentConfig.status.isTournamentStartListsLoading,
    }
  },
  {
    movePlayers: tournamentPlayersApi.endpoints.movePlayers.initiate,
    tournamentPlayersChangeSort,
  },
)(UnconnectedUnassignedPlayers)

export default withStyles(styles)(UnassignedPlayers)
