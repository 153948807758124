import React from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import ContentWrap from '../../components/layout/ContentWrap'
import { WithStyles } from '@mui/styles'
import { fetchUsers } from '../../store/organizationUsers/actions'
import queryString from 'query-string'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import BorderButton from '../../components/ui/BorderButton'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import OrganizationUserDialog from '../../components/dialogs/organizationUser/OrganizationUserDialog'
import { isRoleInfoProviderAdmin } from '../../utils/authUtils'
import HeadHelmet from '../../components/layout/HeadHelmet'
import UsersTable from '../../components/user/UsersTable'
import DefaultPagedTableFooter from '../../components/tables/DefaultPagedTableFooter'
import TopBar from '../../components/layout/TopBar'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { RootState } from '@app/store'

const styles = () =>
  createStyles({
    table: {
      marginTop: 24,
    },
  })

interface StateProps {
  roleInfo?: RoleInfo
  isLoading: boolean
}

interface DispatchProps {
  fetchUsers(organizationId: number, page?: number, onComplete?: (args: APICallResult) => void): any
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & WithRouterProps

interface State {
  page: number
  totalCount: number
  users: OrganizationUser[]
  userToEdit?: OrganizationUser
  modalOpen: boolean
}

class UserManagement extends React.Component<Props, State> {
  readonly state: State = {
    page: 0,
    totalCount: 0,
    users: [],
    userToEdit: undefined,
    modalOpen: false,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const params = queryString.parse(props.location.search)
    const page = params.page ? parseInt(params.page as string, 10) : 1

    if (page !== state.page) {
      return {
        page,
      }
    }

    return null
  }

  componentDidMount(): void {
    this._triggerFetch()
  }

  componentDidUpdate(_, prevState: State): void {
    if (prevState.page !== this.state.page) {
      this._triggerFetch()
    }
  }

  render() {
    const { roleInfo } = this.props

    if (!isRoleInfoProviderAdmin(roleInfo)) {
      return null
    }

    return (
      <>
        <HeadHelmet titleId={'navigation.users'} />
        <TopBar titleId="navigation.users" />

        <OverlayLoader visible={this.props.isLoading} />

        <ContentWrap>
          <BorderButton filled buttonProps={{ onClick: this._showCreateNewModal }}>
            <FormattedMessageWrapper id="buttons.createNew" />
          </BorderButton>

          <UsersTable
            showRoleColumn={true}
            showUserIdColumn={true}
            showOrganizationColumn={true}
            users={this.state.users}
            onUserEdit={(user: OrganizationUser) => this.setState({ userToEdit: user, modalOpen: true })}
            tableFooter={<DefaultPagedTableFooter count={this.state.totalCount} page={this.state.page} />}
          />
        </ContentWrap>

        <OrganizationUserDialog
          showOrganizationPicker={true}
          open={this.state.modalOpen}
          user={this.state.userToEdit}
          onClose={(refetch) => {
            this.setState({ modalOpen: false, userToEdit: undefined }, () => {
              if (refetch) {
                this._triggerFetch()
              }
            })
          }}
        />
      </>
    )
  }

  public _triggerFetch = () => {
    this.props.fetchUsers(0, this.state.page, this._setFetchPayload)
  }

  public _setFetchPayload = ({ data, error }: APICallResult) => {
    if (!error) {
      this.setState({ ...data })
    }
  }

  public _showCreateNewModal = () => {
    this.setState({
      userToEdit: undefined,
      modalOpen: true,
    })
  }
}

const RoutedUserManagement = withRouter(UserManagement)
const StyledUserManagement = withStyles(styles)(RoutedUserManagement)
export default connect<StateProps, DispatchProps, {}, RootState>(
  (store): StateProps => ({
    roleInfo: store.authenticationReducer.roleInfo,
    isLoading: store.organizationUsersReducer.loading,
  }),
  {
    fetchUsers,
  },
)(StyledUserManagement as any)
