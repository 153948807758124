import { Action } from 'typesafe-actions'
import {
  ORGANIZATION_USER_CREATE,
  ORGANIZATION_USER_DELETE,
  ORGANIZATION_USER_SAVE,
  SEND_USER_ACTIVATION_EMAIL,
} from './constants'

export interface OrganizationUserCreateAction extends Action, APICall {
  type: 'ORGANIZATION_USER_CREATE'
  user: UserPayload
  organizationId: number
}

export interface OrganizationUserCreateSuccessAction extends Action, SuccessAction {
  type: 'ORGANIZATION_USER_CREATE_SUCCESS'
}

export interface OrganizationUserCreateErrorAction extends Action {
  type: 'ORGANIZATION_USER_CREATE_ERROR'
  error: ErrorPayload
}

export const createUser = (
  user: UserPayload,
  organizationId: number,
  onComplete?: (args: APICallResult) => void,
): OrganizationUserCreateAction => ({
  type: ORGANIZATION_USER_CREATE,
  organizationId,
  user,
  onComplete,
})

export interface OrganizationUserUpdateAction extends Action, APICall {
  type: 'ORGANIZATION_USER_SAVE'
  organizationId: number
  userId: number
  user: UserPayload
}

export interface OrganizationUserUpdateSuccessAction extends Action, SuccessAction {
  type: 'ORGANIZATION_USER_SAVE_SUCCESS'
  user: {
    id: number
    name: string
    perishableUpdated: string
    timeCreated: string
    timeUpdated: string
    timeZone: string
    userStatus: string
    username: string
  }
}

export interface OrganizationUserUpdateErrorAction extends Action {
  type: 'ORGANIZATION_USER_SAVE_ERROR'
  error: ErrorPayload
}

export const updateUser = (
  userId: number,
  organizationId: number,
  user: UserPayload,
  onComplete?: (args: APICallResult) => void,
): OrganizationUserUpdateAction => ({
  type: ORGANIZATION_USER_SAVE,
  userId,
  organizationId,
  user,
  onComplete,
})

export interface OrganizationUserDeleteAction extends Action, APICall {
  type: 'ORGANIZATION_USER_DELETE'
  organizationId: number
  userId: number
}

export interface OrganizationUserDeleteSuccessAction extends Action, SuccessAction {
  type: 'ORGANIZATION_USER_DELETE_SUCCESS'
}

export interface OrganizationUserDeleteErrorAction extends Action {
  type: 'ORGANIZATION_USER_DELETE_ERROR'
  error: ErrorPayload
}

export const deleteUser = (
  organizationId: number,
  userId: number,
  onComplete?: (args: APICallResult) => void,
): OrganizationUserDeleteAction => ({
  type: ORGANIZATION_USER_DELETE,
  organizationId,
  userId,
  onComplete,
})

export interface SendUserActivationEmailAction extends Action {
  type: 'SEND_USER_ACTIVATION_EMAIL'
  organizationId: number
  userId: number
}

export const sendActivationEmail = (organizationId: number, userId: number): SendUserActivationEmailAction => ({
  type: SEND_USER_ACTIVATION_EMAIL,
  organizationId,
  userId,
})
