import React from 'react'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import { Save, Edit } from '@mui/icons-material'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { rem } from '@app/theme/materialUITheme'

const useStyles = makeStyles((theme: Theme) => ({
  teamHeader: {
    marginRight: rem(10),
    fontSize: rem(14),
    fontWeight: 'bold',
    overflow: 'hidden',
    paddingRight: '4px',
    color: theme.palette.primary.main,
  },
  actionItem: {
    cursor: 'pointer',
    display: 'flex',
  },
  popupContainer: {
    padding: '10px',
  },
}))

interface OwnProps {
  name?: string
  showEdit?: boolean
  onChange?(value: string): void
  style?: React.CSSProperties
}

type Props = OwnProps

export const TeamName: React.FC<Props> = ({ name, style, showEdit = true, onChange }) => {
  const classes = useStyles()

  return (
    <>
      <Typography style={style} className={classes.teamHeader}>
        {name}
      </Typography>
      {showEdit && (
        <PopupState variant="popover" popupId="team-name-popup">
          {(popupState) => (
            <>
              <a className={classes.actionItem} {...bindTrigger(popupState)}>
                <Edit />
              </a>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className={classes.popupContainer}>
                  <form
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                      e.preventDefault()
                      popupState.close()
                      if (onChange) {
                        const {
                          currentTarget: { elements },
                        } = e
                        const teamNameInput = elements.namedItem('teamName')
                        const { value } = teamNameInput as HTMLInputElement

                        if (value !== name && value) {
                          onChange(value)
                        }
                      }
                    }}
                    style={{
                      display: 'flex',
                      alignContent: 'flex-end',
                    }}
                  >
                    <TextField
                      id={'teamName'}
                      label={<FormattedMessageWrapper id="options.teamName" />}
                      defaultValue={name}
                      autoFocus={true}
                      autoComplete={'off'}
                      inputProps={{
                        maxLength: 27,
                      }}
                    />
                    <IconButton type={'submit'} color={'primary'} style={{ marginLeft: 8 }} size="large">
                      <Save fontSize={'small'} />
                    </IconButton>
                  </form>
                </div>
              </Popover>
            </>
          )}
        </PopupState>
      )}
    </>
  )
}
