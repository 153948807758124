export const ORGANIZATION_USER_CREATE = 'ORGANIZATION_USER_CREATE'
export const ORGANIZATION_USER_CREATE_SUCCESS = 'ORGANIZATION_USER_CREATE_SUCCESS'
export const ORGANIZATION_USER_CREATE_ERROR = 'ORGANIZATION_USER_CREATE_ERROR'

export const ORGANIZATION_USER_SAVE = 'ORGANIZATION_USER_SAVE'
export const ORGANIZATION_USER_SAVE_SUCCESS = 'ORGANIZATION_USER_SAVE_SUCCESS'
export const ORGANIZATION_USER_SAVE_ERROR = 'ORGANIZATION_USER_SAVE_ERROR'

export const ORGANIZATION_USER_DELETE = 'ORGANIZATION_USER_DELETE'
export const ORGANIZATION_USER_DELETE_SUCCESS = 'ORGANIZATION_USER_DELETE_SUCCESS'
export const ORGANIZATION_USER_DELETE_ERROR = 'ORGANIZATION_USER_DELETE_ERROR'

export const SEND_USER_ACTIVATION_EMAIL = 'SEND_USER_ACTIVATION_EMAIL'
