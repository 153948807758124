import { RootState } from '@app/store'
import { selectTournamentSettings, tournamentSettingsApi } from '../endpoints/tournamentSettingsApi'
import { setTournamentDirty } from '../slices/configSlice'

export const updateTournamentSettingsField = (tournamentId: number, payload: FieldUpdatePayload) => {
  return (dispatch, getState: () => RootState) => {
    const currentSettings = selectTournamentSettings(getState())
    const tournamentSettings = { ...currentSettings, [payload.fieldName]: payload.value }
    dispatch(saveTournamentSettingsData(tournamentId, tournamentSettings))
    dispatch(setTournamentDirty(true))
  }
}

export const saveTournamentSettingsData = (tournamentId: number, data: TournamentSettings) => {
  return (dispatch) => {
    dispatch(tournamentSettingsApi.util.upsertQueryData('getTournamentSettings', tournamentId, data))
  }
}
