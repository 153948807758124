import React from 'react'
import SimpleMDE from 'react-simplemde-editor'

export interface MDEditorProps {
  initialValue?: string | null
  saveFn: (value: string) => void
  maxHeight?: string
  placeholder?: string
}

const autofocusNoSpellcheckerOptions: EasyMDE.Options = {
  toolbarTips: false,
  status: false,
  spellChecker: false,
  hideIcons: ['guide', 'heading', 'fullscreen', 'preview', 'side-by-side', 'image', 'quote'],
}

export const MDEditor: React.FC<MDEditorProps> = ({ initialValue, saveFn, maxHeight, placeholder }) => {
  const [value, setValue] = React.useState<string>('')

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value)
      saveFn(value)
    },
    [saveFn],
  )

  const options = React.useMemo((): EasyMDE.Options => {
    if (maxHeight) {
      return { ...autofocusNoSpellcheckerOptions, maxHeight }
    }
    return autofocusNoSpellcheckerOptions
  }, [maxHeight])

  return (
    <SimpleMDE
      id="info"
      value={initialValue || value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
    />
  )
}
