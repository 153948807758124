import React from 'react'
import CourseGuideTable from './CourseGuideTable'
import { WrappedComponentProps, injectIntl, IntlProvider } from 'react-intl'
import { confirm } from '../confirm/Confirm'
import { connect } from 'react-redux'
import { getCourses } from '@app/store/api/thunks/clubsAndCoursesThunks'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectClubsAndCoursesConfig, selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectRoundCourseOptions } from '@app/store/api/slices/clubsAndCoursesSlice'
import { getSelectedRound } from '@app/store/api/selectors/tournamentSelectors'
import { getRoundStartTime } from '@app/store/api/utils/tournamentRoundUtils'

interface ConfirmProps {
  course: RoundCourse
  cancelText: string
  intl: any
  tournament?: TournamentState
  roundIndex?: number
}

const showCourseGuideDialog = ({ course, cancelText, intl, tournament, roundIndex }: ConfirmProps): any => {
  return confirm({
    options: {
      cancelText,
      title: (
        <span style={{ display: 'flex', alignItems: 'center' }}>{`${course.clubName} / ${course.courseName}`}</span>
      ),
    },
    contentComponent: (
      <IntlProvider {...intl}>
        <CourseGuideTable tournament={tournament} course={course} showTees={false} roundIndex={roundIndex} />
      </IntlProvider>
    ),
    maxWidth: 'lg',
  })
}

export interface ChildrenPropsArgs {
  showCourseGuide: (clubId: number, courseId: number) => any
  isLoading: boolean
}

interface OwnProps {
  children: (args: ChildrenPropsArgs) => any
  roundIndex?: number
}

interface DispatchProps {
  getCourses: (payload: CoursesPayload) => void
}

interface StateProps {
  selectedRound: Round
  roundCourses: RoundCourse[]
  tournament: TournamentState
  tournamentConfig: TournamentConfig
  clubsAndCoursesConfig: ClubsAndCoursesConfig
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps

interface State {
  loading: boolean
}

class CourseGuideHelper extends React.PureComponent<Props, State> {
  readonly state: State = {
    loading: false,
  }

  render() {
    return this.props.children({
      showCourseGuide: this._handleShow,
      isLoading: this.state.loading,
    })
  }

  public _handleShow = (clubId: number, courseId: number): void => {
    const { intl, getCourses, roundCourses, tournament, selectedRound, roundIndex, clubsAndCoursesConfig } = this.props
    const { selectedCourseVersion } = clubsAndCoursesConfig
    const selectedCourse = roundCourses.find((item) => item.id === courseId && item.version === selectedCourseVersion)
    const timestamp = getRoundStartTime(selectedRound)

    if (!this.state.loading && !selectedCourse) {
      this.setState({ loading: true }, () => {
        getCourses({
          clubId,
          timestamp,
          onComplete: ({ data }) => {
            this.setState({ loading: false }, () => {
              const foundedCourse = data?.courses?.find((item) => item.id === courseId) as RoundCourse | undefined
              if (foundedCourse) {
                showCourseGuideDialog({
                  course: foundedCourse,
                  cancelText: intl.formatMessage({ id: 'buttons.close' }),
                  intl,
                  tournament,
                  roundIndex,
                })
              }
            })
          },
        })
      })
    } else if (selectedCourse) {
      showCourseGuideDialog({
        course: selectedCourse,
        cancelText: intl.formatMessage({ id: 'buttons.close' }),
        intl,
        tournament,
        roundIndex,
      })
    }
  }
}

export default injectIntl(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (state) => {
      return {
        selectedRound: getSelectedRound(state),
        clubsAndCoursesConfig: selectClubsAndCoursesConfig(state),
        roundCourses: selectRoundCourseOptions(state),
        tournament: selectTournament(state),
        tournamentConfig: selectTournamentConfig(state),
      }
    },
    {
      getCourses,
    },
  )(CourseGuideHelper),
)
