import React from 'react'
import TourProgressLogic, { ProgressChildrenArgs, ProgressStep } from '../headless/TourProgressLogic'
import { CheckCircle } from '@mui/icons-material'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { rem } from '../../theme/materialUITheme'

const styles = (theme: Theme) =>
  createStyles({
    stepWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    step: {
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 0px',
      width: 0,
      borderBottom: `6px solid ${theme.customPalette.mediumGray}`,
      margin: '0 10px',
      textAlign: 'center',
      fontSize: rem(14),
      lineHeight: 1.1,
      '@global': {
        svg: {
          marginRight: 5,
        },
      },
    },
    link: {
      cursor: 'pointer',
    },
    current: {
      borderBottom: `6px solid ${theme.palette.primary.main}`,
    },
    completed: {
      borderBottom: `6px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    stepBtn: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.customPalette.mediumGray,
    },
  })

interface Props {
  classes: {
    stepWrapper: string
    step: string
    link: string
    current: string
    completed: string
    stepBtn: string
  }
}

class ProgressBar extends React.Component<Props, {}> {
  render() {
    const { classes } = this.props

    return (
      <>
        <TourProgressLogic>
          {({ steps, getActiveStepIndex, handleOnClick, tourName }: ProgressChildrenArgs) => {
            const activeStep = getActiveStepIndex()
            return (
              <div className={classes.stepWrapper}>
                {steps.map((step: ProgressStep, idx: number) => (
                  <div
                    className={classNames(
                      classes.step,
                      idx === activeStep && classes.current,
                      idx < activeStep && classes.completed,
                      Boolean(tourName) && classes.link,
                    )}
                    onClick={tourName ? handleOnClick(idx) : null}
                    key={`${idx}_${step.path}`}
                  >
                    <div className={classes.stepBtn}>
                      <CheckCircle color={idx <= activeStep ? 'primary' : 'disabled'} />
                      {step.label}
                    </div>
                  </div>
                ))}
              </div>
            )
          }}
        </TourProgressLogic>
      </>
    )
  }
}

export default withStyles(styles)(ProgressBar)
