import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const useCardStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    width: '250px',
    height: '60px',
    background: theme.customPalette.light,
    boxShadow: '0px 8px 10px rgba(2, 3, 3, 0.03), 0px 3px 14px rgba(2, 3, 3, 0.02), 0px 5px 5px rgba(2, 3, 3, 0.04)',
    borderRadius: '12px',
    fontFamily: theme.fonts.secondary,
    margin: '2px 0',
    position: 'relative',
    zIndex: 11,
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    padding: '0 10px 0 20px',
  },
  info: {
    width: '80%',
    textAlign: 'left',
  },
  score: {
    width: '20%',
    textAlign: 'right',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '160%',
    color: theme.customPalette.black,
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: '700',
    fontSize: 17,
  },
  hcp: {
    color: '#404040',
    fontWeight: '400',
    fontSize: 12,
    '&.loser': {
      color: '#fff',
    },
  },
  droppable: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #D9D9D9',
    color: '#747474',
  },
  droppableContainer: {
    height: '60px',
  },
  finalTitle: {
    position: 'absolute',
    left: '270px',
    top: '40%',
    textAlign: 'left',

    '& > h3': {
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '40px',
      color: '#fff',
      margin: 0,
      textTransform: 'uppercase',
    },
    '& > span': {
      fontWeight: 400,
      fontSize: '16px',
      color: '#fff',

      '& svg': {
        cursor: 'pointer',
        width: '16px',
        height: '16px',
        marginLeft: '5px',
        marginBottom: '-2px',
      },
    },
  },
  bronzeTitle: {
    position: 'absolute',
    top: '-75px',
    textAlign: 'center',
    width: '85%',

    '& > h3': {
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '40px',
      color: '#fff',
      margin: 0,
      textTransform: 'uppercase',
    },
    '& > span': {
      fontWeight: 400,
      fontSize: '16px',
      color: '#fff',

      '& svg': {
        cursor: 'pointer',
        width: '16px',
        height: '16px',
        marginLeft: '5px',
        marginBottom: '-2px',
      },
    },
  },
  stageDescriptionInput: {
    display: 'flex',
    position: 'absolute',
    zIndex: '12',
    height: '30px',
    width: '200px',
    top: '50px',
    left: '20px',

    '& > input': {
      height: '30px',
      width: '170px',
    },
    '& > button': {
      height: '30px',
      width: '30px',
      backgroundColor: theme.palette.primary.main,
      color: theme.customPalette.light,
      border: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  emptyCard: {
    display: 'flex',
    width: '250px',
    height: '60px',
    backgroundColor: 'rgba(116, 116, 116, 0.5)',
    boxShadow: '0px 8px 10px rgba(2, 3, 3, 0.03), 0px 3px 14px rgba(2, 3, 3, 0.02), 0px 5px 5px rgba(2, 3, 3, 0.04)',
    backdropFilter: 'blur(10px)',
    borderRadius: '12px',
    margin: '2px 0',
    position: 'relative',
    zIndex: 11,
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404040',
  },
  right: {
    width: '20%',
    textAlign: 'right',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '160%',
    color: theme.customPalette.black,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& svg': {
      cursor: 'pointer',
      width: '18px',
      height: '18px',
      marginLeft: '5px',
      marginBottom: '-2px',
    },
  },
  loser: {
    backgroundColor: 'rgba(116, 116, 116, 0.5)',
    boxShadow: '0px 8px 10px rgba(2, 3, 3, 0.03), 0px 3px 14px rgba(2, 3, 3, 0.02), 0px 5px 5px rgba(2, 3, 3, 0.04)',
    backdropFilter: 'blur(10px)',
  },
  finalOrBronzeCard: {
    margin: '10px 0',
  },
  addDate: {
    textDecoration: 'underline',
  },
}))
