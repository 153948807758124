import { Navigate, Route } from 'react-router-dom'
import LoginGuard from '@app/components/authentication/LoginGuard'
import Login from '@app/scenes/authentication/Login'
import Loading from '@app/scenes/loading/Loading'
import Register from '@app/scenes/register/Register'
import ForgotPassword from '@app/scenes/authentication/ForgotPassword'
import ResetPassword from '@app/scenes/authentication/ResetPassword'
import { MatchPlayBracketViewPublic } from '@app/scenes/match-play-brackets/MatchPlayBracketViewPublic'
import { Division } from '@app/scenes/divisions/Division'
import { Divisions } from '@app/scenes/divisions/Divisions'
import HcpRounds from '@app/scenes/hcp-rounds/HcpRounds'
import { MatchPlayBracketView } from '@app/scenes/match-play-brackets/MatchPlayBracketView'
import OrganizationManagement from '@app/scenes/organizationManagement/OrganizationManagement'
import OrganizationTournaments from '@app/scenes/tournaments/OrganizationTournaments'
import { OrganizationProducts } from '@app/scenes/payment-products'
import Profile from '@app/scenes/profile/Profile'
import TourAndRanking from '@app/scenes/tour-and-ranking/TourAndRanking'
import Tournament from '@app/scenes/tournament'
import Tour from '@app/scenes/tour'
import UserManagement from '@app/scenes/userManagement/UserManagement'
import Tournaments from '@app/scenes/tournaments/Tournaments'
import { Reports } from '@app/scenes/reports'
import Dashboard from '@app/scenes/dashboard/Dashboard'
import { Setup as TournamentSetup } from '@app/scenes/tournament/setup'
import { RoundSetup } from '@app/scenes/tournament/round-setup'
import { TournamentSite } from '@app/scenes/tournament/tournament-site'
import { Entries } from '@app/scenes/tournament/entries'
import { Players } from '@app/scenes/tournament/players'
import { GroupsAndStartList } from '@app/scenes/tournament/groups-and-start-list'
import { ResultsAndOptions } from '@app/scenes/tournament/results-and-options'
import TournamentBranding from '@app/scenes/tournament/Branding'
import TourSetup from '@app/scenes/tour/Setup'
import TourBranding from '@app/scenes/tour/Branding'
import { TournamentPages } from '@app/store/api/enums/tournamentEnums'

export const renderRoutes = () => {
  return (
    <>
      <Route path={'/'} element={<Login />} />
      <Route path={'/loading'} element={<Loading />} />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/apply'} element={<Register />} />
      <Route path={'/reset-password/:resetToken'} element={<ResetPassword />} />
      <Route path={'/reset-password'} element={<ForgotPassword />} />
      <Route path={'/match-play-brackets/:id/view'} element={<MatchPlayBracketViewPublic />} />
      <Route element={<LoginGuard />}>
        <Route path={`/dashboard`} element={<Dashboard />} />
        <Route element={<Tournament />}>
          <Route path={`/tournaments/create`} element={<Navigate to={TournamentPages.SETUP} />} />
          <Route path={`/tournaments/:id`} element={<Navigate to={TournamentPages.SETUP} />} />
          <Route path={`/tournaments/create/${TournamentPages.SETUP}`} element={<TournamentSetup />} />
          <Route path={`/tournaments/:id/${TournamentPages.SETUP}`} element={<TournamentSetup />} />
          <Route path={`/tournaments/:id/${TournamentPages.ROUND_SETUP}`} element={<RoundSetup />} />
          <Route path={`/tournaments/:id/${TournamentPages.TOURNAMENT_SITE}`} element={<TournamentSite />} />
          <Route path={`/tournaments/:id/${TournamentPages.ENTRIES}`} element={<Entries />} />
          <Route path={`/tournaments/:id/${TournamentPages.PLAYERS}`} element={<Players />} />
          <Route path={`/tournaments/:id/${TournamentPages.GROUPS_AND_START_LIST}`} element={<GroupsAndStartList />} />
          <Route path={`/tournaments/:id/${TournamentPages.RESULTS_AND_OPTIONS}`} element={<ResultsAndOptions />} />
          <Route path={`/tournaments/:id/${TournamentPages.BRANDING}`} element={<TournamentBranding />} />
        </Route>
        <Route path={`/tournaments`} element={<Tournaments />} />
        <Route path={`/organizations/applications`} element={<OrganizationManagement />} />
        <Route path={`/organizations/:id/tournaments`} element={<OrganizationTournaments />} />
        <Route path={`/organizations/:id`} element={<Profile />} />
        <Route path={`/profile`} element={<Profile />} />
        <Route path={`/users`} element={<UserManagement />} />
        <Route path={`/organizations`} element={<OrganizationManagement />} />
        <Route element={<Tour />}>
          <Route path={`/tour-and-ranking/tour/new-tour`} element={<Navigate to={`setup`} />} />
          <Route path={`/tour-and-ranking/tour/:id`} element={<Navigate to={`setup`} />} />
          <Route path={`/tour-and-ranking/tour/new-tour/setup`} element={<TourSetup />} />
          <Route path={`/tour-and-ranking/tour/new-tour/branding`} element={<TourBranding />} />
          <Route path={`/tour-and-ranking/tour/:id/setup`} element={<TourSetup />} />
          <Route path={`/tour-and-ranking/tour/:id/branding`} element={<TourBranding />} />
        </Route>
        <Route path={`/tour-and-ranking/tour/:id`} element={<Tour />} />
        <Route path={`/tour-and-ranking`} element={<TourAndRanking />} />
        <Route path={`/divisions`} element={<Divisions />} />
        <Route path={`/divisions/create`} element={<Division />} />
        <Route path={`/divisions/:id`} element={<Division />} />
        <Route path={`/payment-products`} element={<OrganizationProducts />} />
        <Route path={`/hcp-rounds`} element={<HcpRounds />} />
        <Route path={`/match-play-brackets/:id`} element={<MatchPlayBracketView />} />
        <Route path={`/analytics-and-reports`} element={<Reports />} />
      </Route>
    </>
  )
}
