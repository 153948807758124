import { Action } from 'redux'
import {
  FETCH_TOURS,
  FETCH_RANKINGS,
  CREATE_RANKING,
  UPDATE_RANKING,
  DELETE_RANKING,
  FETCH_TOUR,
  RESET_TOUR,
  SAVE_TOUR,
  DELETE_TOUR,
  UPDATE_TOUR_FIELD,
  UPLOAD_TOUR_IMAGE,
  DELETE_TOUR_IMAGE,
  SEND_RANKING_POINTS,
} from './constants'

/** FETCH TOURS */

export interface FetchToursAction extends Action {
  type: 'FETCH_TOURS'
  organizationId: number
  onComplete?: () => void
}

export interface FetchToursSuccessAction extends Action, SuccessAction {
  type: 'FETCH_TOURS_SUCCESS'
  tours: Tour[]
}

export interface FetchToursFailureAction extends Action, ErrorAction {
  type: 'FETCH_TOURS_FAILURE'
}

export const fetchTours = (organizationId: number, onComplete?: () => void) => {
  return {
    type: FETCH_TOURS,
    organizationId,
    onComplete,
  }
}

/** FETCH TOUR */

export interface FetchTourAction extends Action {
  type: 'FETCH_TOUR'
  organizationId: number
  id: number
}

export interface FetchTourSuccessAction extends Action, SuccessAction {
  type: 'FETCH_TOUR_SUCCESS'
  tour: Tour
}

export interface FetchTourFailureAction extends Action, ErrorAction {
  type: 'FETCH_TOUR_FAILURE'
}

export const fetchTour = (organizationId: number, id: number) => {
  return {
    type: FETCH_TOUR,
    organizationId,
    id,
  }
}

/** RESET TOUR */

export interface ResetTourAction extends Action {
  type: 'RESET_TOUR'
}

export const resetTour = () => {
  return {
    type: RESET_TOUR,
  }
}

/** SAVE TOUR */

export interface SaveTourAction extends Action {
  type: 'SAVE_TOUR'
  onComplete?: () => void
}

export interface SaveTourSuccessAction extends Action, SuccessAction {
  type: 'SAVE_TOUR_SUCCESS'
  tour: Tour
}

export interface SaveTourFailureAction extends Action, ErrorAction {
  type: 'SAVE_TOUR_FAILURE'
}

export const saveTour = (onComplete?: () => void) => {
  return {
    type: SAVE_TOUR,
    onComplete,
  }
}

/** UPDATE TOUR FIELD */

export interface UpdateTourFieldAction extends Action {
  type: 'UPDATE_TOUR_FIELD'
  payload: FieldUpdatePayload
}

export const updateTourField = (payload: FieldUpdatePayload) => {
  return {
    type: UPDATE_TOUR_FIELD,
    payload,
  }
}

/** DELETE TOUR */

export interface DeleteTourAction extends Action {
  type: 'DELETE_TOUR'
  organizationId: number
  id: number
  onComplete?: () => void
}

export interface DeleteTourSuccessAction extends Action, SuccessAction {
  type: 'DELETE_TOUR_SUCCESS'
  tourId: number
}

export interface DeleteTourFailureAction extends Action, ErrorAction {
  type: 'DELETE_TOUR_FAILURE'
}

export const deleteTour = (organizationId: number, id: number, onComplete?: () => void) => {
  return {
    type: DELETE_TOUR,
    organizationId,
    id,
    onComplete,
  }
}

/** UPLOAD TOUR IMAE */

export interface UploadTourImagePayload extends APICall {
  organizationId: number
  tourId: number
  file: File | string
  fileType: 'logo' | 'ad' | 'hero' | 'sponsorLogo'
}

export interface UploadTourImageAction extends Action, UploadTourImagePayload {
  type: 'UPLOAD_TOUR_IMAGE'
}

export interface UploadTourImageFinishedAction extends Action {
  type: 'UPLOAD_TOUR_IMAGE_FINISHED'
}

export const uploadTourImage = ({
  organizationId,
  tourId,
  file,
  fileType,
  onComplete,
}: UploadTourImagePayload): UploadTourImageAction => ({
  type: UPLOAD_TOUR_IMAGE,
  organizationId,
  tourId,
  file,
  fileType,
  onComplete,
})

/** DELETE TOUR IMAGE */

export interface DeleteTourImagePayload extends APICall {
  organizationId: number
  tourId: number
  id: number
}

export interface DeleteTourImageAction extends Action, DeleteTourImagePayload {
  type: 'DELETE_TOUR_IMAGE'
}

export interface DeleteTourImageFinishedAction extends Action {
  type: 'DELETE_TOUR_IMAGE_FINISHED'
}

export const deleteTourImage = ({
  organizationId,
  tourId,
  id,
  onComplete,
}: DeleteTourImagePayload): DeleteTourImageAction => ({
  type: DELETE_TOUR_IMAGE,
  organizationId,
  tourId,
  id,
  onComplete,
})

/** FETCH RANKINGS */

export interface FetchRankingsAction extends Action {
  type: 'FETCH_RANKINGS'
  organizationId: number
  onComplete?: () => void
}

export interface FetchRankingsSuccessAction extends Action, SuccessAction {
  type: 'FETCH_RANKINGS_SUCCESS'
  rankings: Ranking[]
}

export interface FetchRankingsFailureAction extends Action, ErrorAction {
  type: 'FETCH_RANKINGS_FAILURE'
}

export const fetchRankings = (organizationId: number, onComplete?: () => void) => {
  return {
    type: FETCH_RANKINGS,
    organizationId,
    onComplete,
  }
}

/** CREATE RANKING */

export interface RankingPayload {
  name: string
  active: boolean
  configTemplate: RankingTemplateItem[]
  logo?: File
  bestResultsCount: number | null
}

export interface CreateRankingAction extends Action {
  type: 'CREATE_RANKING'
  organizationId: number
  payload: RankingPayload
  onComplete?: () => void
}

export interface CreateRankingSuccessAction extends Action, SuccessAction {
  type: 'CREATE_RANKING_SUCCESS'
  ranking: Ranking
}

export interface CreateRankingFailureAction extends Action, ErrorAction {
  type: 'CREATE_RANKING_FAILURE'
}

export const createRanking = (organizationId: number, payload: RankingPayload, onComplete?: () => void) => {
  return {
    type: CREATE_RANKING,
    organizationId,
    payload,
    onComplete,
  }
}

/** UPDATE RANKING */

export interface UpdateRankingAction extends Action {
  type: 'UPDATE_RANKING'
  organizationId: number
  id: number
  payload: RankingPayload
  onComplete?: () => void
}

export interface UpdateRankingSuccessAction extends Action, SuccessAction {
  type: 'UPDATE_RANKING_SUCCESS'
  ranking: Ranking
}

export interface UpdateRankingFailureAction extends Action, ErrorAction {
  type: 'UPDATE_RANKING_FAILURE'
}

export const updateRanking = (organizationId: number, id: number, payload: RankingPayload, onComplete?: () => void) => {
  return {
    type: UPDATE_RANKING,
    organizationId,
    id,
    payload,
    onComplete,
  }
}

/** DELETE RANKING */

export interface DeleteRankingAction extends Action {
  type: 'DELETE_RANKING'
  organizationId: number
  id: number
  onComplete?: () => void
}

export interface DeleteRankingSuccessAction extends Action, SuccessAction {
  type: 'DELETE_RANKING_SUCCESS'
  rankingId: number
}

export interface DeleteRankingFailureAction extends Action, ErrorAction {
  type: 'DELETE_RANKING_FAILURE'
}

export const deleteRanking = (organizationId: number, id: number, onComplete?: () => void) => {
  return {
    type: DELETE_RANKING,
    organizationId,
    id,
    onComplete,
  }
}

/** SEND RANKING POINTS */

export interface SendRankingPointsAction extends Action {
  type: 'SEND_RANKING_POINTS'
  tournamentId: number
  onComplete?: () => void
}

export interface SendRankingPointsSuccessAction extends Action, SuccessAction {
  type: 'SEND_RANKING_POINTS_SUCCESS'
}

export interface SendRankingPointsFailureAction extends Action, ErrorAction {
  type: 'SEND_RANKING_POINTS_FAILURE'
}

export const sendRankingPoints = (tournamentId: number, onComplete?: () => void): SendRankingPointsAction => {
  return {
    type: SEND_RANKING_POINTS,
    tournamentId,
    onComplete,
  }
}
