import { formatDate } from './dates'

export const createReferenceIdForGroup = (group: Pick<StartListGroup, 'teeNumber' | 'startTime'>): string =>
  `hole-${group.teeNumber}_${formatDate(group.startTime, 'time', 'metric')}`

export const createReferenceIdForGroups = (groups: StartListGroup[] = []): StartListGroup[] =>
  groups.map((group) => ({
    ...group,
    referenceId: createReferenceIdForGroup(group),
  }))

export const createReferenceIdsForGroupsInStartListRound = (round: StartListRound): StartListRound => ({
  ...round,
  groups: createReferenceIdForGroups(round.groups),
})

/**
 * Calculate amount of needed groups to fill shotgun holes
 * @param isTeamFormat
 * @param isTwoManTeamFormat
 * @param basicGroupSize
 * @param unassignedTeamsCount
 * @param unassignedPlayersCount
 * @param defaultTeamSize
 * @returns amount of groups
 */
export const calculateShotgunNeededGroupsToFill = (
  isTeamFormat: boolean,
  isTwoManTeamFormat: boolean,
  basicGroupSize: number,
  unassignedTeamsCount: number,
  unassignedPlayersCount: number,
  defaultTeamSize: number,
): number => {
  /**
   * Max players in a team depending on format
   */
  const maxPlayersInTeam = isTwoManTeamFormat ? 2 : defaultTeamSize

  /**
   * Check how many teams can fit in a group
   */
  const teamsToFitInGroup = basicGroupSize / maxPlayersInTeam >= 2 ? unassignedTeamsCount / 2 : unassignedTeamsCount

  /**
   * Check how many players can fit in a group
   */
  const playersToFitInGroup = unassignedPlayersCount / basicGroupSize

  /**
   * Calculate groups needed
   */
  const groupsCount = isTeamFormat ? teamsToFitInGroup : playersToFitInGroup

  /**
   * Round up to nearest integer
   */
  return Math.ceil(groupsCount)
}
