import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { FormGroup, FormControl, Select, MenuItem, Grid, Tooltip, Switch, FormLabel, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TieBreakMethod } from '@app/store/api/enums/tournamentEnums'
import SectionTitle from '@app/components/ui/SectionTitle'
import InfoTooltip from '@app/components/ui/InfoTooltip'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { RootState } from '@app/store'
import { updateMainGameFormat, updateSideGameFormat } from '@app/store/api/thunks/tournamentThunks'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    color: theme.palette.text.secondary,
  },
  sectionTitleDisabled: {
    color: theme.palette.text.disabled,
  },
  wrapper: { alignItems: 'center', marginTop: 10 },
}))

interface OwnProps {
  disabled?: boolean
  gameType: string
}
interface StateProps {
  tournament: TournamentState
  tournamentConfig: TournamentConfig
}

interface DispatchProps {
  updateMainGameFormat: (payload: GameFormatPayload) => void
  updateSideGameFormat: (payload: GameFormatPayload) => void
}

type TieBreakMethodSelectorComponentProps = StateProps & DispatchProps & OwnProps

const TieBreakMethodSelectorComponent: React.FC<TieBreakMethodSelectorComponentProps> = ({
  tournament,
  tournamentConfig,
  updateMainGameFormat,
  updateSideGameFormat,
  gameType,
  disabled,
}) => {
  const classes = useStyles()
  const { selectedRoundIndex } = tournamentConfig

  const currentRound = tournament.rounds[selectedRoundIndex]

  const gameFormatOptions: GameFormatOptions =
    gameType === 'primary' ? currentRound.primaryGameOptions : currentRound.sideGameOptions

  const primaryTieBreakMethodVal = gameFormatOptions.primaryTieBreakMethod
  const showTiedPositionsVal = gameFormatOptions.showTiedPositions

  const _onChange = (event): void => {
    const fieldName = event.target.name
    const value = fieldName === 'showTiedPositions' ? event.currentTarget.checked : event.target.value

    const action = gameType === 'primary' ? updateMainGameFormat : updateSideGameFormat

    action({
      index: selectedRoundIndex,
      fieldName,
      value,
    })
  }

  return (
    <Grid container spacing={2} className={classes.wrapper}>
      <Grid item xs={12}>
        <SectionTitle className={disabled ? classes.sectionTitleDisabled : classes.sectionTitle}>
          <FormattedMessageWrapper id="tournament.primaryTieBreakMethod" />
          <InfoTooltip
            style={{ marginLeft: 10 }}
            text={<FormattedMessageWrapper id="tournament.tieBreakMethodInfo" />}
          />
        </SectionTitle>
      </Grid>
      <Grid item xs={6}>
        <FormGroup>
          <FormControl variant="outlined" margin="none" fullWidth>
            <Select
              value={primaryTieBreakMethodVal}
              name="primaryTieBreakMethod"
              onChange={_onChange}
              disabled={selectedRoundIndex !== 0 || disabled}
            >
              <MenuItem value={TieBreakMethod.handicap}>
                <FormattedMessageWrapper id="tournament.lowestHcp" />
              </MenuItem>
              <MenuItem value={TieBreakMethod.playingHandicap}>
                <FormattedMessageWrapper id="tournament.lowestPhcp" />
              </MenuItem>
              {tournament.tournamentType !== TournamentTypes.weekly && (
                <MenuItem value={TieBreakMethod.countback}>
                  <FormattedMessageWrapper id="tournament.countback" />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </FormGroup>
      </Grid>
      <Grid item xs={6}>
        <Tooltip title={<FormattedMessageWrapper id="tournament.showTiedPositionsTooltip" />}>
          <FormControl margin={'none'} className="SwitchControl">
            <Switch
              checked={showTiedPositionsVal}
              name="showTiedPositions"
              color="primary"
              onChange={_onChange}
              disabled={selectedRoundIndex !== 0 || disabled}
            />
            <FormLabel className="SwitchLabel">
              <FormattedMessageWrapper id="tournament.showTiedPositions" />
            </FormLabel>
          </FormControl>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

export const TieBreakMethodSelector = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    tournament: selectTournament(state),
    tournamentConfig: selectTournamentConfig(state),
  }),
  {
    updateMainGameFormat,
    updateSideGameFormat,
  },
)(TieBreakMethodSelectorComponent)
