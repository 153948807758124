export const ORGANIZATIONS_FETCH = 'ORGANIZATIONS_FETCH'
export const ORGANIZATIONS_FETCH_SUCCESS = 'ORGANIZATIONS_FETCH_SUCCESS'
export const ORGANIZATIONS_FETCH_ERROR = 'ORGANIZATIONS_FETCH_ERROR'

export const ORGANIZATION_APPLICATIONS_FETCH = 'ORGANIZATION_APPLICATIONS_FETCH'
export const ORGANIZATION_APPLICATIONS_FETCH_SUCCESS = 'ORGANIZATION_APPLICATIONS_FETCH_SUCCESS'
export const ORGANIZATION_APPLICATIONS_FETCH_ERROR = 'ORGANIZATION_APPLICATIONS_FETCH_ERROR'

export const CONDENSED_ORGANIZATIONS_FETCH = 'CONDENSED_ORGANIZATIONS_FETCH'
export const CONDENSED_ORGANIZATIONS_FETCH_SUCCESS = 'CONDENSED_ORGANIZATIONS_FETCH_SUCCESS'
export const CONDENSED_ORGANIZATIONS_FETCH_ERROR = 'CONDENSED_ORGANIZATIONS_FETCH_ERROR'

export const FETCH_ORGANIZATIONS_TOURNAMENTS = 'FETCH_ORGANIZATIONS_TOURNAMENTS'
export const FETCH_ORGANIZATIONS_TOURNAMENTS_SUCCESS = 'FETCH_ORGANIZATIONS_TOURNAMENTS_SUCCESS'
export const FETCH_ORGANIZATIONS_TOURNAMENTS_FAILURE = 'FETCH_ORGANIZATIONS_TOURNAMENTS_FAILURE'
