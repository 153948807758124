import { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  FormControl,
  FormLabel,
  Theme,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { OverlayLoader } from '@app/components/ui/OverlayLoader'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessageWrapper } from '@components/ui/FormattedMessageWrapper'
import { fetchReports, fetchWeeklyReports, clearReports } from '@store/reports/actions'
import { isValidDate, pickerDateFormats } from '@app/utils/dates'
import { differenceInDays } from 'date-fns'
import HeadHelmet from '@components/layout/HeadHelmet'
import TopBar from '@app/components/layout/TopBar'
import ContentWrap from '@app/components/layout/ContentWrap'
import { RootState } from '@app/store'
import InfoTooltip from '@app/components/ui/InfoTooltip'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: 27,
  },
  tableContainer: {
    border: `1px solid ${theme.customPalette.lightGray}`,
  },
  table: {
    borderWidth: 1,
  },
  tableHead: {
    '& .MuiTableCell-head': {
      backgroundColor: theme.customPalette.main,
      color: 'white',
      padding: 13,
    },
  },
  tableTotalRow: {
    backgroundColor: theme.customPalette.lightGray,
  },
  tableTotalCellTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  tableTotalCell: {
    fontWeight: 'bold',
  },
  gridContainer: {
    display: 'grid',
  },
  select: {
    width: 320,
  },
  searchButton: {
    alignSelf: 'center',
    marginTop: 17,
    marginLeft: 15,
  },
  separatorLine: {
    alignSelf: 'center',
    marginTop: 15,
  },
  separatorLineTop: {
    marginTop: 15,
  },
  infoText: {
    textAlign: 'end',
  },
  dateInfoText: {
    fontSize: 'smaller',
    marginTop: 10,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

enum DateType {
  FROM = 'from',
  TO = 'to',
}

enum Category {
  TOURNAMENT = 'tournament',
  WEEKLY = 'weekly_tournament',
}

export const Reports = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { customerInfo } = useSelector((store: RootState) => store.authenticationReducer)
  const { units } = useSelector((store: RootState) => store.organizationReducer)
  const { reports, loading } = useSelector((store: RootState) => store.reportsReducer)
  const { guests, members, tournaments, roundsPerCourse } = reports

  const date = new Date()
  const defaultStartDate = new Date(date.getFullYear(), date.getMonth(), 1)
  const defaultEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  const [fromDate, setFromDate] = useState(defaultStartDate)
  const [toDate, setToDate] = useState(defaultEndDate)
  const [category, updateCategory] = useState<Category>(Category.TOURNAMENT)
  const [toError, setToError] = useState<boolean>(false)

  const [totalGuests, updateTotalGuests] = useState<number>(0)
  const [totalRounds, updateTotalRounds] = useState<number>(0)
  const [totalMembers, updateTotalMembers] = useState<number>(0)

  const getTotalValue = useCallback(
    (value: string) => roundsPerCourse.reduce((prev, next) => prev + next[value], 0),
    [roundsPerCourse],
  )

  useEffect(() => {
    updateTotalGuests(getTotalValue('guests'))
    updateTotalMembers(getTotalValue('members'))
    updateTotalRounds(getTotalValue('rounds'))
  }, [getTotalValue])

  const checkRange = (laterDate: Date, earlierDate: Date) =>
    differenceInDays(laterDate, earlierDate) > 365 || differenceInDays(laterDate, earlierDate) < 0

  if (!customerInfo?.isClub && !!customerInfo?.idCustomer) {
    return null
  }

  const handleDateChange = (newDate, type: DateType) => {
    if (type === DateType.FROM) {
      const rangeDate = new Date(newDate)
      checkRange(toDate, rangeDate) ? setToError(true) : setToError(false)
      setFromDate(newDate)
      if (newDate > toDate) {
        setToError(true)
      }
    } else {
      const rangeDate = new Date(newDate)
      checkRange(rangeDate, fromDate) ? setToError(true) : setToError(false)
      setToDate(newDate)
    }
  }

  const handleCategoryChange = (e) => {
    dispatch(clearReports())
    updateCategory(e.target.value)
  }

  const handleSearchClick = () => {
    if (category === Category.TOURNAMENT) {
      dispatch(fetchReports(fromDate, toDate))
      return
    }
    dispatch(fetchWeeklyReports(fromDate, toDate))
  }

  return (
    <>
      <HeadHelmet titleId="navigation.analyticsAndReports" />
      <TopBar titleId="navigation.analyticsAndReports" />
      <OverlayLoader visible={loading} />

      <ContentWrap>
        <Typography variant="h1" className={classes.title}>
          <FormattedMessageWrapper id="reports.reportsTitle" />
        </Typography>

        <Grid container direction="column" spacing={8}>
          <Grid item>
            <Grid container spacing={4} style={{ alignItems: 'center' }}>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <div className={classes.gridContainer}>
                      <FormLabel htmlFor="select">
                        <FormattedMessageWrapper id="reports.fromText" />
                      </FormLabel>
                      <DatePicker
                        format={pickerDateFormats[units]['date']}
                        value={fromDate}
                        onChange={(e) => handleDateChange(e, DateType.FROM)}
                        slotProps={{ textField: { error: !isValidDate(fromDate) } }}
                      />
                    </div>
                  </Grid>
                  <Grid item className={classes.separatorLine}>
                    -
                  </Grid>
                  <Grid item>
                    <div className={classes.gridContainer}>
                      <FormLabel htmlFor="select">
                        <FormattedMessageWrapper id="reports.toText" />
                      </FormLabel>
                      <DatePicker
                        format={pickerDateFormats[units]['date']}
                        value={toDate}
                        onChange={(e) => handleDateChange(e, DateType.TO)}
                        maxDate={
                          fromDate && new Date(fromDate.getFullYear() + 1, fromDate.getMonth(), fromDate.getDate())
                        }
                        slotProps={{ textField: { error: toError || !isValidDate(toDate) } }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <div style={{ display: 'flex' }}>
                  <FormControl className={classes.gridContainer}>
                    <FormLabel htmlFor="select">
                      <FormattedMessageWrapper id="reports.category" />
                    </FormLabel>
                    <RadioGroup className={classes.radioGroup} value={category} onChange={handleCategoryChange}>
                      <FormControlLabel
                        value={Category.TOURNAMENT}
                        control={<Radio color="primary" />}
                        label={<FormattedMessageWrapper id="reports.tournaments" />}
                      />
                      <FormControlLabel
                        value={Category.WEEKLY}
                        control={<Radio color="primary" />}
                        label={<FormattedMessageWrapper id="reports.weeklyTournaments" />}
                      />
                    </RadioGroup>
                  </FormControl>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleSearchClick}
                    className={classes.searchButton}
                    disabled={toError || !isValidDate(fromDate) || !isValidDate(toDate)}
                  >
                    <FormattedMessageWrapper id="buttons.search" />
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid item className={classes.dateInfoText}>
              <FormattedMessageWrapper id="reports.validReportDateRangeText" />
            </Grid>
          </Grid>

          <Grid item>
            <TableContainer component={Paper} elevation={0} className={classes.tableContainer}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <VeryDenseTableCell width={'20%'}>
                      <FormattedMessageWrapper id="reports.playersInTournament" />
                      <InfoTooltip
                        text={<FormattedMessageWrapper id="reports.playersInTournamentInfo" />}
                        style={{ marginLeft: 5, color: 'white' }}
                      />
                    </VeryDenseTableCell>
                    <VeryDenseTableCell size="small" width={'15%'}>
                      <FormattedMessageWrapper id="reports.tournaments" />
                    </VeryDenseTableCell>
                    <VeryDenseTableCell size="small" width={'15%'}>
                      <FormattedMessageWrapper id="reports.members" />
                      <InfoTooltip
                        text={<FormattedMessageWrapper id="reports.membersInfo" />}
                        style={{ marginLeft: 5, color: 'white' }}
                      />
                    </VeryDenseTableCell>
                    <VeryDenseTableCell size="small" width={'15%'}>
                      <FormattedMessageWrapper id="reports.guests" />
                    </VeryDenseTableCell>
                    <VeryDenseTableCell size="small" width={'15%'}>
                      <FormattedMessageWrapper id="reports.totalPlayers" />
                      <InfoTooltip
                        text={<FormattedMessageWrapper id="reports.totalPlayersInfo" />}
                        style={{ marginLeft: 5, color: 'white' }}
                      />
                    </VeryDenseTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell>{tournaments}</TableCell>
                    <TableCell>{members}</TableCell>
                    <TableCell>{guests}</TableCell>
                    <TableCell>{members + guests}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {category !== Category.WEEKLY && (
            <Grid item>
              <TableContainer component={Paper} elevation={0} className={classes.tableContainer}>
                <Table className={classes.table}>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <VeryDenseTableCell width={'20%'}>
                        <FormattedMessageWrapper id="reports.roundsPerCourse" />
                        <InfoTooltip
                          text={<FormattedMessageWrapper id="reports.roundsPerCourseInfo" />}
                          style={{ marginLeft: 5, color: 'white' }}
                        />
                      </VeryDenseTableCell>
                      <VeryDenseTableCell size="small" width={'15%'}>
                        <FormattedMessageWrapper id="reports.tournamentRounds" />
                        <InfoTooltip
                          text={<FormattedMessageWrapper id="reports.tournamentRoundsInfo" />}
                          style={{ marginLeft: 5, color: 'white' }}
                        />
                      </VeryDenseTableCell>
                      <VeryDenseTableCell size="small" width={'15%'}>
                        <FormattedMessageWrapper id="reports.members" />
                        <InfoTooltip
                          text={<FormattedMessageWrapper id="reports.membersInfo" />}
                          style={{ marginLeft: 5, color: 'white' }}
                        />
                      </VeryDenseTableCell>
                      <VeryDenseTableCell size="small" width={'15%'}>
                        <FormattedMessageWrapper id="reports.guests" />
                      </VeryDenseTableCell>
                      <VeryDenseTableCell size="small" width={'15%'}>
                        <FormattedMessageWrapper id="reports.totalRounds" />
                        <InfoTooltip
                          text={<FormattedMessageWrapper id="reports.totalRoundsInfo" />}
                          style={{ marginLeft: 5, color: 'white' }}
                        />
                      </VeryDenseTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {roundsPerCourse?.map(({ courseName, guests, members, rounds }, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{courseName}</TableCell>
                        <TableCell>{rounds}</TableCell>
                        <TableCell>{members}</TableCell>
                        <TableCell>{guests}</TableCell>
                        <TableCell>{guests + members}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow className={classes.tableTotalRow}>
                      <TableCell className={classes.tableTotalCellTitle} />
                      <TableCell className={classes.tableTotalCell}>{totalRounds}</TableCell>
                      <TableCell className={classes.tableTotalCell}>{totalMembers}</TableCell>
                      <TableCell className={classes.tableTotalCell}>{totalGuests}</TableCell>
                      <TableCell className={classes.tableTotalCell}>{totalMembers + totalGuests}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          <Grid item className={classes.infoText}>
            <FormattedMessageWrapper id="reports.infoText" />
          </Grid>
        </Grid>
      </ContentWrap>
    </>
  )
}
