import { MatchPlayBracketPlayer } from '@app/components/match-play-brackets/MatchPlayBracketPlayer'
import makeStyles from '@mui/styles/makeStyles'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(() => ({
  poolContainer: {
    margin: '35px 50px',
  },
  poolTitle: {
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '30px',
    margin: '0 0 20px 0',
    color: '#fff',
    display: 'inline-block',
  },
}))

interface Props {
  players: MatchPlayBracketPlayer[]
}

export const MatchPlayBracketPoolPublic: React.FC<Props> = ({ players }) => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <div className={classes.poolContainer} id="bracket-pool-container">
      {players.length > 0 && (
        <>
          <h3 className={classes.poolTitle}>{intl.formatMessage({ id: 'matchPlayBracket.playerPool' })}</h3>
          {players.map((player: MatchPlayBracketPlayer, index: number) => (
            <MatchPlayBracketPlayer player={player} key={`POOL-${index}`} />
          ))}
        </>
      )}
    </div>
  )
}
