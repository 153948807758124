import React from 'react'
import { useTheme } from '@mui/system'

export const contentPadding = 40

type ContentWrapProps = {
  style?: React.CSSProperties
  children?: any
  divider?: boolean
}

const ContentWrap = ({ children, style = {}, divider = false }: ContentWrapProps) => {
  const theme = useTheme()

  return (
    <div
      style={{
        margin: `32px ${contentPadding}px`,
        ...style,
        ...(divider && {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          paddingBottom: `${contentPadding}px`,
        }),
      }}
    >
      {children}
    </div>
  )
}

export default ContentWrap
