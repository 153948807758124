import { AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT } from './constants'
import { Action } from 'redux'

export interface LoginPayload {
  username: string
  password: string
}

export interface LoginReturnPayload {
  id: number
  name: string
  email: string
  role: string
  token: string
}

export interface LoginAction extends Action {
  type: 'AUTH_LOGIN'
  payload: LoginPayload
}
export const login = (loginData: LoginPayload) => ({
  type: AUTH_LOGIN,
  payload: loginData,
})

export interface LoginSuccessAction extends Action {
  type: 'AUTH_LOGIN_SUCCESS'
  payload: LoginReturnPayload
}

export interface LoginFailureAction extends Action, ErrorPayload {
  type: 'AUTH_LOGIN_FAILURE'
}

export const logout = () => ({
  type: AUTH_LOGOUT,
})

export interface LogOutSuccessAction extends Action, SuccessAction {
  type: 'AUTH_LOGOUT_SUCCESS'
}

export interface AuthCheckAction extends Action {
  type: 'AUTH_CHECK'
}
export const checkAuth = () => ({
  type: AUTH_CHECK,
})
