import React from 'react'
import { connect } from 'react-redux'
import { fetchUsers } from '../../store/organizationUsers/actions'
import { OverlayLoader } from '../ui/OverlayLoader'
import OrganizationUserDialog from '../dialogs/organizationUser/OrganizationUserDialog'
import BorderButton from '../ui/BorderButton'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import TableRow from '@mui/material/TableRow'
import CustomTablePagination from '../tables/CustomTablePagination'
import UsersTable from './UsersTable'
import { TableFooter } from '@mui/material'
import { RootState } from '@app/store'

interface OwnProps {
  organizationId: number
}

interface StateProps {
  isLoading: boolean
}

interface DispatchProps {
  fetchUsers(organizationId: number, page?: number, onComplete?: (args: APICallResult) => void): any
}

type Props = OwnProps & StateProps & DispatchProps

interface State {
  page: number
  totalCount: number
  users: OrganizationUser[]
  userToEdit?: OrganizationUser
  modalOpen: boolean
}

class OrganizationUserManagement extends React.Component<Props, State> {
  readonly state: State = {
    page: 1,
    totalCount: 0,
    users: [],
    userToEdit: undefined,
    modalOpen: false,
  }

  componentDidMount(): void {
    this._triggerFetch()
  }

  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        <OverlayLoader visible={this.props.isLoading} />

        <BorderButton buttonProps={{ onClick: this._showCreateNewModal }}>
          <FormattedMessageWrapper id="buttons.createNew" />
        </BorderButton>

        <UsersTable
          showRoleColumn={true}
          users={this.state.users}
          onUserEdit={(user: OrganizationUser) => this.setState({ userToEdit: user, modalOpen: true })}
          tableFooter={
            <TableFooter>
              <TableRow>
                <CustomTablePagination
                  count={this.state.totalCount}
                  page={this.state.page - 1}
                  onChangePage={(event, page) => {
                    if (event !== null) {
                      this.setState({ page: page + 1 }, this._triggerFetch)
                    }
                  }}
                />
              </TableRow>
            </TableFooter>
          }
        />

        <OrganizationUserDialog
          organizationId={this.props.organizationId}
          open={this.state.modalOpen}
          user={this.state.userToEdit}
          onClose={(refetch) => {
            this.setState({ modalOpen: false, userToEdit: undefined }, () => {
              if (refetch) {
                this._triggerFetch()
              }
            })
          }}
        />
      </div>
    )
  }

  public _triggerFetch = () => {
    this.props.fetchUsers(this.props.organizationId, this.state.page, this._setFetchPayload)
  }

  public _setFetchPayload = ({ data, error }: APICallResult) => {
    if (!error) {
      this.setState({ ...data })
    }
  }

  public _showCreateNewModal = () => {
    this.setState({
      userToEdit: undefined,
      modalOpen: true,
    })
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store): StateProps => ({
    isLoading: store.organizationUsersReducer.loading,
  }),
  {
    fetchUsers,
  },
)(OrganizationUserManagement)
