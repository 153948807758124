import * as React from 'react'
import { connect, Provider } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import dateFnsLocaleFi from 'date-fns/locale/fi'
import dateFnsLocaleEn from 'date-fns/locale/en-US'
import dateFnsLocaleEnGB from 'date-fns/locale/en-GB'
import dateFnsLocaleSv from 'date-fns/locale/sv'
import { RootState, store } from './store'
import localization, { Localization } from './localization'
import CssBaseline from '@mui/material/CssBaseline'
import materialUITheme from './theme/materialUITheme'
import { flattenMessages } from './utils/flattenMessages'
import { PaletteMode, Theme } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { checkAuth } from './store/authentication/actions'
import { SnackbarProvider } from 'notistack'
import SiteFooter from './components/layout/SiteFooter'
import 'intl-pluralrules'
import { UpdateChangesBanner } from '@components/tournament/UpdateChangesBanner'
import { removeStorageItems, storageImportId, storageTempImportId } from '@utils/tournamentUtils'
import { renderRoutes } from './routes'
import { useLazyGitImportStatusQuery } from './store/api/endpoints/tournamentGitApi'
import { selectTournament } from './store/api/endpoints/tournamentApi'
import { useMixpanel } from './hooks/useMixpanel'

/*
  Unholy fi-SV hack due to the fact that window.Intl does not respoect sv-FI
 */
const dateFnsLocales: Localization = {
  'fi-FI': {
    ...dateFnsLocaleFi,
    options: {
      weekStartsOn: 1,
    },
  },
  'fi-SV': {
    ...dateFnsLocaleSv,
    formatLong: {
      ...dateFnsLocaleSv.formatLong,
      date: (dateFnsLocaleFi.formatLong as any).date,
    },
    options: {
      weekStartsOn: 1,
    },
  },
  'sv-SE': {
    ...dateFnsLocaleSv,
    options: {
      weekStartsOn: 1,
    },
  },
  'en-US': {
    ...dateFnsLocaleEn,
    options: {
      weekStartsOn: 1,
    },
  },
  'en-GB': {
    ...dateFnsLocaleEnGB,
    options: {
      weekStartsOn: 1,
    },
  },
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: 'calc(100vh - 177px)',
      zIndex: 1,
      position: 'relative',
      background: theme.customPalette.bodyBackground,
      minWidth: '100%',
      '@media only screen and (min-width: 1280px)': {
        minWidth: 1280,
      },
    },
  })

type UnconnectedAppProps = WithStyles<typeof styles>

class AppUnconnected extends React.Component<UnconnectedAppProps> {
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Outlet />
        </div>
        <UpdateChangesBanner />
        <SiteFooter />
      </React.Fragment>
    )
  }
}

const StyledApp = withStyles(styles)(AppUnconnected)

interface AppStateProps {
  appLanguage: LanguageOption
  theme: PaletteMode
  tournament: TournamentState
}

interface AppDispatchProps {
  checkAuth: () => void
}

type AppProps = AppStateProps & AppDispatchProps

const App: React.FC<AppProps> = ({ theme, appLanguage, tournament, checkAuth }) => {
  const [storage, SyncStorage] = React.useState(localStorage)
  const importId = storage[storageImportId]
  const interval = React.useRef<any>(null)
  const tournamentId = tournament.id
  const [fetchGitImportStatus] = useLazyGitImportStatusQuery()
  useMixpanel()

  const storageEventListener = () => {
    SyncStorage({ ...localStorage })
  }

  React.useEffect(() => {
    checkAuth()

    window.addEventListener('storage', storageEventListener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!importId) {
      clearInterval(interval.current)
    }
  }, [importId])

  /**
   * Poll every 3s until 100%
   */
  const doPolling = React.useCallback(() => {
    if (importId) {
      fetchGitImportStatus({
        tournamentId,
        importId,
        onComplete: (progress: number) => {
          if (progress === 100) {
            localStorage.setItem(storageTempImportId, importId)
            localStorage.removeItem(storageImportId)
            clearInterval(interval.current)
            window.dispatchEvent(new Event('storage'))
          }
        },
      })
    }
  }, [importId])

  /**
   * Stop git import status check after 30min
   */
  const startTimer = () => {
    setTimeout(() => {
      clearInterval(interval.current)
      removeStorageItems([storageImportId, storageTempImportId])
      window.dispatchEvent(new Event('storage'))
    }, 1800000)
  }

  React.useEffect(() => {
    if (importId) {
      startTimer()
      interval.current = setInterval(doPolling, 3000)
    }
  }, [importId, doPolling])

  const code = appLanguage.code
  return (
    <IntlProvider locale={code} messages={flattenMessages(localization[code])}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={materialUITheme(theme)}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocales[code]}>
            <SnackbarProvider>
              <StyledApp />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
  )
  // }
}

const LocalizedApp = connect<AppStateProps, AppDispatchProps, {}, RootState>(
  (state): AppStateProps => ({
    appLanguage: state.localeReducer.appLanguage,
    theme: state.themeReducer.theme as PaletteMode,
    tournament: selectTournament(state),
  }),
  { checkAuth },
)(App)

export const router = createBrowserRouter(
  createRoutesFromElements(<Route element={<LocalizedApp />}>{renderRoutes()}</Route>),
)

const AppRoot = () => (
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
)

export default AppRoot
