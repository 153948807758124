import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'

interface FormattedMessageWrapperProps {
  id: string
  hasLink?: boolean
  values?: Record<string, any>
}

/**
 *
 * FormattedMessage doesn't allow HTML tags without sanitization.
 * https://github.com/formatjs/formatjs/releases/tag/v4.0.0
 * Set hasLink to true if id contains link (a) tags
 */
export const FormattedMessageWrapper = injectIntl(
  ({ id, hasLink, values, intl }: FormattedMessageWrapperProps & WrappedComponentProps) => {
    const sanitizedValues = {
      br: <br />,
      i: (...chunk) => <i>{chunk}</i>,
      strong: (...chunk) => <strong>{chunk}</strong>,
      b: (...chunk) => <b>{chunk}</b>,
      ul: (...chunk) => <ul>{chunk}</ul>,
      li: (...chunk) => <li>{chunk}</li>,
      h5: (...chunk) => <h5>{chunk}</h5>,
      // A tags can be sanitized this way but data needs to be changed. Disabled unless needed.
      // a: (...chunks) => <a href={link}>{chunks.join('')}</a>,
    }

    // A tags are passed through without sanitization.
    if (hasLink) {
      return <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id }) }} />
    }

    return <FormattedMessage id={id} values={{ ...values, ...sanitizedValues }} />
  },
)
