import { SET_THEME } from './constants'
import { Action } from 'redux'

export interface SetTheme extends Action {
  type: 'SET_THEME'
  theme: string
}

export const setTheme = (theme: string) => ({
  type: SET_THEME,
  theme,
})
