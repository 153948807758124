import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { LinearProgress, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'

export interface ArrivalIndicatorProps {
  arrivedCount: number
  totalCount: number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    overflow: 'hidden',
    flexGrow: 1,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: rem(14),
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    display: 'block',
    textAlign: 'right',
  },
  progress: {
    height: '10px',
    borderRadius: theme.shape.borderRadius,
  },
}))

const ArrivalIndicatorComponent: React.FC<ArrivalIndicatorProps> = ({ arrivedCount, totalCount }) => {
  const classes = useStyles()

  const value = Math.floor((arrivedCount / totalCount) * 100)

  return (
    <div className={classes.root}>
      <span className={classes.title}>
        <FormattedMessageWrapper id="tournament.arrivedCount" values={{ count: arrivedCount, total: totalCount }} />
      </span>
      <LinearProgress className={classes.progress} variant="determinate" value={value} />
    </div>
  )
}

export const ArrivalIndicator = ArrivalIndicatorComponent
