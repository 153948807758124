export enum PlayerStatus {
  OK = 'ok',
  DQ = 'dq',
  DNS = 'dns',
  RTD = 'rtd',
  CUT = 'cut',
}

export enum PlayerTarget {
  POOL = 'pool',
  RESERVE = 'reserve',
}

export enum TeamTarget {
  POOL = 'POOL',
  RESERVED = 'RESERVED',
}

export enum PlayerGender {
  MALE = 'male',
  FEMALE = 'female',
}
