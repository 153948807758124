import { sortPlayers } from '@app/utils/playerUtils'
import { selectTournamentPlayers, tournamentPlayersApi } from '../endpoints/tournamentPlayersApi'
import { selectTournamentTeams } from '../endpoints/tournamentTeamsApi'
import { setPlayerExtraInfo, setPlayersSortParams } from '../slices/configSlice'
import { RootState } from '../..'
import { arrayMove } from '@app/utils/dragUtils'
import { ExtraInfo } from '../enums/tournamentEnums'
import { getTournamentId } from '../slices/configSlice'
import { api } from '../baseApi'
import { CacheTag } from '../cacheTags'
import { selectTournament } from '../endpoints/tournamentApi'
import { selectTournamentSite } from '../endpoints/tournamentSiteApi'
import { isSignUpEnabled } from '../utils/tournamentSiteUtils'

export const sortTournamentPlayers = (sortBy: PoolOrderBy, sortDirection?: SortDirection) => {
  return (dispatch, getState: () => RootState) => {
    const state = getState()
    const tournamentPlayers: TournamentPlayers | undefined = selectTournamentPlayers(state).data
    const teams: TournamentTeam[] = selectTournamentTeams(state).data?.teams || []
    if (!tournamentPlayers) {
      return
    }
    const players = [...tournamentPlayers.players]
    const reserveList = [...tournamentPlayers.reserveList]

    sortPlayers(players, teams, sortBy, sortDirection)
    sortPlayers(reserveList, teams, sortBy, sortDirection)

    dispatch(saveTournamentPlayersData({ players, reserveList }))
  }
}

export const tournamentPlayersChangeSort = (sortBy: PoolOrderBy, sortDirection: SortDirection = 'asc') => {
  return (dispatch) => {
    dispatch(setPlayersSortParams({ sortBy, sortDirection }))
    dispatch(sortTournamentPlayers(sortBy, sortDirection))
  }
}

export const tournamentPlayersReorderUnassigned = (oldIndex: number, newIndex: number) => {
  return (dispatch, getState: () => RootState) => {
    const tournamentId = getTournamentId(getState())
    dispatch(
      tournamentPlayersApi.util.updateQueryData('getPlayers', tournamentId, (data) => {
        const reorderedPlayers = arrayMove(data.players, oldIndex, newIndex)
        return {
          ...data,
          players: reorderedPlayers,
        }
      }),
    )
  }
}

export const tournamentPlayersChangeExtraInfo = (playerExtraInfo: string) => {
  return (dispatch) => {
    dispatch(setPlayerExtraInfo(playerExtraInfo as ExtraInfo))
  }
}

export const updatePlayersSelection = (playerIds: number[], isSelected: boolean) => {
  return (dispatch, getState: () => RootState) => {
    const tournamentId = getTournamentId(getState())
    dispatch(
      tournamentPlayersApi.util.updateQueryData('getPlayers', tournamentId, (data) => {
        const players = data.players.map((player) =>
          playerIds.includes(player.userId) ? { ...player, isSelected } : player,
        )
        const reserveList = data.reserveList.map((player) =>
          playerIds.includes(player.userId) ? { ...player, isSelected } : player,
        )
        return {
          players,
          reserveList,
        }
      }),
    )
  }
}

export const clearSelectedPlayers = () => {
  return (dispatch, getState: () => RootState) => {
    const tournamentId = getTournamentId(getState())
    dispatch(
      tournamentPlayersApi.util.updateQueryData('getPlayers', tournamentId, (data) => {
        const players = data.players.map((player) => ({ ...player, isSelected: false }))
        const reserveList = data.reserveList.map((player) => ({ ...player, isSelected: false }))
        return {
          players,
          reserveList,
        }
      }),
    )
  }
}

export const saveTournamentPlayersData = (payload: TournamentPlayers) => {
  return (dispatch, getState: () => RootState) => {
    const tournamentId = getTournamentId(getState())
    dispatch(tournamentPlayersApi.util.updateQueryData('getPlayers', tournamentId, () => payload))
  }
}

export const maybeRefetchTournamentPlayers = (tournamentId: number) => {
  return (dispatch, getState: () => RootState) => {
    const tournament = selectTournament(getState())
    const tournamentSite = selectTournamentSite(getState())

    const tournamentPlayers: TournamentPlayers | undefined = selectTournamentPlayers(getState()).data
    if (tournamentPlayers === undefined) {
      dispatch(tournamentPlayersApi.endpoints.getPlayers.initiate(tournamentId))
      return
    }

    const signUpDisabled = isSignUpEnabled(tournament, tournamentSite) === false
    if (signUpDisabled) {
      return
    }

    dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_PLAYERS]))
  }
}
