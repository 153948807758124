import { api } from '../baseApi'
import { APIRoute } from '../../../config'
import { RootState } from '@app/store'

export const paymentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query<PaymentCurrency[], void>({
      query: () => APIRoute.GET_CURRENCIES,
    }),
  }),
})

export const selectCurrencies = (state: RootState) => paymentsApi.endpoints.getCurrencies.select()(state).data || []

export const { useGetCurrenciesQuery } = paymentsApi
