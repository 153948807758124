import { FormLabel } from '@mui/material'
import ManagerHelp from '../ui/ManagerHelp'
import React from 'react'

interface Props {
  children: any
  helpTitleId?: string
  helpBodyId?: string
  htmlFor?: string
  style?: React.CSSProperties
  className?: string
}

export const HelpFormLabel = ({
  children,
  helpTitleId,
  helpBodyId,
  htmlFor = `${helpTitleId}-${helpBodyId}`,
  style = {},
  className,
}: Props) => (
  <FormLabel
    htmlFor={htmlFor}
    style={{ position: 'relative', display: 'block', ...style }}
    component={'span'}
    className={className}
  >
    {children}
    {helpTitleId && helpBodyId && (
      <ManagerHelp titleId={helpTitleId} bodyId={helpBodyId} style={{ position: 'absolute', top: -14 }} />
    )}
  </FormLabel>
)
