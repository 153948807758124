import React from 'react'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { Theme, Table, TableHead, TableRow, TableBody } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { rem } from '@theme/materialUITheme'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { showStablefordPoints } from '@utils/tournamentUtils'
import { formatHandicap } from '@utils/playerUtils'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import classNames from 'classnames'
import { formatDate, formatDateToIsoWithTimeZone } from '@utils/dates'

const styles = (theme: Theme) =>
  createStyles({
    table: {
      marginTop: 10,
      background: '#FFFFFF',
      '& > thead > tr > th': {
        color: '#FFFFFF',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: rem(12),
        background: '#1AA85D',
        border: '1px solid #1AA85D',
        '&:first-of-type': {
          textAlign: 'left',
        },
      },
      '& > tbody > tr > td': {
        textAlign: 'center',
        fontSize: rem(12),
        border: '1px solid #B9B9B9',
        '&:first-of-type': {
          textAlign: 'left',
        },
      },
      '& > tbody > tr.ownScores > td': {
        fontSize: rem(18),
      },
      '& > tbody > tr.parRow > td': {
        background: '#F2F2F2',
      },
      '& .doublebogey': {
        background: '#004E8F',
        width: 25,
        display: 'block',
        margin: '0 auto',
        color: theme.palette.primary.contrastText,
      },
      '& .bogey': {
        background: '#4A90E2',
        width: 25,
        display: 'block',
        margin: '0 auto',
        color: theme.palette.primary.contrastText,
      },
      '& .birdie': {
        background: '#FF001F',
        width: 25,
        borderRadius: 25,
        display: 'block',
        margin: '0 auto',
        color: theme.palette.primary.contrastText,
      },
      '& .eagle': {
        background: '#F5A623',
        width: 25,
        borderRadius: 25,
        display: 'block',
        margin: '0 auto',
        color: theme.palette.primary.contrastText,
      },
      '& .par': {
        width: 25,
        display: 'block',
        margin: '0 auto',
      },
    },
    emptyCell: {
      width: 25,
    },
    scoreCell: {
      paddingLeft: 0,
    },
    emptyScoreCell: {
      paddingLeft: 0,
      backgroundColor: '#F2F2F2',
    },
    scoreCellHiddenHole: {
      backgroundColor: 'black',
      color: 'white',
    },
    emptyRow: {
      backgroundColor: 'white !important',
      border: 'none !important',
    },
    weeklyAdditionalInfo: {
      float: 'right',
      marginLeft: 15,
      color: '#9A9A9A',
    },
    weeklyAdditionalInfoRight: {
      float: 'right',
      marginLeft: 15,
      marginRight: 15,
      color: '#9A9A9A',
    },
  })

interface LeaderboardScoreTableProps {
  player?: TournamentLeaderboardPlayer
  tournament?: TournamentState
  leaderboard?: TournamentLeaderboardState
  roundCourses?: RoundCourse[]
  showName?: boolean
  gameType?: string
  isSpecialTeamFormat?: boolean
}

type Props = LeaderboardScoreTableProps & WithStyles<typeof styles>

class LeaderboardScoreTable extends React.Component<Props> {
  render() {
    const { player, tournament, leaderboard } = this.props

    if (!tournament || !player || !player.rounds || !leaderboard) {
      return null
    }

    return this._renderHeader()
  }

  private _renderHeader = () => {
    const { tournament, player, classes, showName, leaderboard } = this.props

    if (!player?.name || !leaderboard?.data?.rounds || !tournament) {
      return null
    }

    const leaderboardRounds = leaderboard.data.rounds
    const playerId = player?.teamId || player?.userId || '0'

    const colspanMax = 22

    const phcp =
      (tournament.rounds.length === 1 &&
        player.rounds[0].playingHcp &&
        `(${formatHandicap(player.rounds[0].playingHcp.toString())})`) ||
      ''

    return (
      <Table className={classes.table} key={`scores-${playerId}`}>
        <TableHead>
          {showName && (
            <TableRow>
              <VeryDenseTableCell colSpan={colspanMax}>
                {player.name} {phcp}
              </VeryDenseTableCell>
            </TableRow>
          )}
          <TableRow>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="tournament.hole" />
            </VeryDenseTableCell>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((hole: number) => (
              <VeryDenseTableCell key={`hole-${hole}-${playerId}`} className={classes.scoreCell}>
                {hole}
              </VeryDenseTableCell>
            ))}
            {
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="tournament.out" />
              </VeryDenseTableCell>
            }
            {[10, 11, 12, 13, 14, 15, 16, 17, 18].map((hole: number) => (
              <VeryDenseTableCell key={`hole-${hole}-${playerId}`} className={classes.scoreCell}>
                {hole}
              </VeryDenseTableCell>
            ))}
            {
              <VeryDenseTableCell className={classes.scoreCell}>
                <FormattedMessageWrapper id="tournament.in" />
              </VeryDenseTableCell>
            }
            <VeryDenseTableCell className={classes.scoreCell}>
              <FormattedMessageWrapper id="tournament.total" />
            </VeryDenseTableCell>
          </TableRow>
        </TableHead>
        {leaderboardRounds.map((round: TournamentLeaderboardRound, index: number) => this._renderRound(round, index))}
      </Table>
    )
  }

  private _renderRound = (leaderboardRound: TournamentLeaderboardRound, roundIndex: number) => {
    const { tournament, player, classes, leaderboard, gameType, roundCourses } = this.props

    if (!player?.name || !leaderboard?.data?.rounds || !tournament || !roundCourses || !tournament.rounds[roundIndex]) {
      return null
    }

    const currentTournamentRound = tournament.rounds[roundIndex]
    const playerId = player?.teamId || player?.userId || '0'

    const f9 = ['f9', '18'].includes(currentTournamentRound.holes || '18')
    const b9 = ['b9', '18'].includes(currentTournamentRound.holes || '18')

    const colspanMax = 22

    const isMultiRound = tournament?.tournamentType === TournamentTypes.multiRound
    const phcp =
      tournament.rounds.length > 1 && `(PHCP ${formatHandicap(player.rounds[roundIndex].playingHcp.toString())})`

    const courseId = currentTournamentRound.courseId
    const currentCourseGuide = roundCourses.find((course) => course && course.id === courseId)
    const holeInfo = currentCourseGuide?.holes || []
    const f9Holes = holeInfo && holeInfo.slice(0, 9)
    const b9Holes = holeInfo && holeInfo.slice(9)
    const playerRoundScore: TournamentLeaderboardPlayerRound = player.rounds[roundIndex]
    const playerHoles = player.rounds[roundIndex] ? player.rounds[roundIndex].strokes : null
    const startListPublished = !!currentTournamentRound.status?.isConfigured
    const roundDate = formatDateToIsoWithTimeZone(
      playerRoundScore.startTime ?? new Date(),
      tournament?.rounds[roundIndex ?? 0].club?.timeZone,
    )

    if (!playerHoles) {
      return null
    }
    const showPoints =
      tournament &&
      showStablefordPoints(
        gameType === 'primary' ? tournament.rounds[roundIndex].primaryGameId : tournament.rounds[roundIndex].sideGameId,
      )

    return (
      <TableBody key={`${playerId}-${roundIndex}`}>
        <TableRow>
          <VeryDenseTableCell colSpan={colspanMax}>
            {currentCourseGuide?.courseName || 'Unknown course'} {startListPublished && isMultiRound && phcp}
            {tournament?.tournamentType === TournamentTypes.weekly &&
              roundIndex !== undefined &&
              playerRoundScore.startTime && (
                <>
                  <span className={classes.weeklyAdditionalInfoRight}>Tee: {player?.teeBox}</span>
                  <span className={classes.weeklyAdditionalInfo}>
                    HCP {formatHandicap(playerRoundScore.hcp?.toFixed(1).toString() ?? '')}
                  </span>
                  <span className={classes.weeklyAdditionalInfo}>
                    {roundDate
                      ? formatDate(roundDate, 'date', tournament?.tournamentOrganization?.units ?? 'metric')
                      : ''}
                  </span>
                </>
              )}
          </VeryDenseTableCell>
        </TableRow>
        <TableRow className="parRow">
          <VeryDenseTableCell>
            <FormattedMessageWrapper id="tournament.par" />
          </VeryDenseTableCell>
          {f9Holes &&
            f9Holes.map((hole: CourseHole) => (
              <VeryDenseTableCell key={`hole-${hole.holeNumber}-${playerId}`} className={classes.scoreCell}>
                {hole.par}
              </VeryDenseTableCell>
            ))}
          {<VeryDenseTableCell className={classes.scoreCell}>{leaderboardRound.parOut}</VeryDenseTableCell>}
          {b9Holes &&
            b9Holes.map((hole: CourseHole) => (
              <VeryDenseTableCell key={`hole-${hole.holeNumber}-${playerId}`} className={classes.scoreCell}>
                {hole.par}
              </VeryDenseTableCell>
            ))}
          {<VeryDenseTableCell className={classes.scoreCell}>{leaderboardRound.parIn}</VeryDenseTableCell>}
          <VeryDenseTableCell className={classes.scoreCell}>{leaderboardRound.parTotal}</VeryDenseTableCell>
        </TableRow>
        <TableRow className="ownScores">
          <VeryDenseTableCell>
            <strong>
              <FormattedMessageWrapper id="tournament.roundCount" values={{ round: roundIndex + 1 }} />
            </strong>
          </VeryDenseTableCell>
          {f9Holes &&
            f9Holes.map((hole: CourseHole, i: number) =>
              f9 ? (
                <VeryDenseTableCell
                  key={`hole-${hole.holeNumber}-${playerId}`}
                  className={classNames(
                    classes.scoreCell,
                    playerRoundScore.strokes[i] && playerRoundScore.hidden[i] ? classes.scoreCellHiddenHole : '',
                  )}
                >
                  {playerRoundScore ? this._getPlayerStrokesForHole(playerRoundScore, hole) : ''}
                </VeryDenseTableCell>
              ) : (
                <VeryDenseTableCell key={`hole-${hole.holeNumber}-${playerId}`} className={classes.emptyScoreCell}>
                  <div className={classes.emptyCell} />
                </VeryDenseTableCell>
              ),
            )}
          {f9 ? (
            <VeryDenseTableCell className={classes.scoreCell}>
              <strong>{playerRoundScore ? playerRoundScore.strokesOut : ''}</strong>
            </VeryDenseTableCell>
          ) : (
            <VeryDenseTableCell className={classes.emptyScoreCell} />
          )}
          {b9Holes &&
            b9Holes.map((hole: CourseHole, i: number) =>
              b9 ? (
                <VeryDenseTableCell
                  key={`hole-${hole.holeNumber}-${playerId}`}
                  className={classNames(
                    classes.scoreCell,
                    playerRoundScore.strokes[i + 9] && playerRoundScore.hidden[i + 9]
                      ? classes.scoreCellHiddenHole
                      : '',
                  )}
                >
                  {playerRoundScore ? this._getPlayerStrokesForHole(playerRoundScore, hole) : ''}
                </VeryDenseTableCell>
              ) : (
                <VeryDenseTableCell key={`hole-${hole.holeNumber}-${playerId}`} className={classes.emptyScoreCell}>
                  <div className={classes.emptyCell} />
                </VeryDenseTableCell>
              ),
            )}
          {b9 ? (
            <VeryDenseTableCell className={classes.scoreCell}>
              <strong>{playerRoundScore ? playerRoundScore.strokesIn : ''}</strong>
            </VeryDenseTableCell>
          ) : (
            <VeryDenseTableCell className={classes.emptyScoreCell}>
              <div className={classes.emptyCell} />
            </VeryDenseTableCell>
          )}
          <VeryDenseTableCell className={classes.scoreCell}>
            <strong>{playerRoundScore ? playerRoundScore.strokesTotal : ''}</strong>
          </VeryDenseTableCell>
        </TableRow>
        <TableRow className="netScore">
          <VeryDenseTableCell>
            <strong>
              <FormattedMessageWrapper id={showPoints ? 'tournament.points' : 'tournament.net'} />
            </strong>
          </VeryDenseTableCell>
          {f9Holes &&
            f9Holes.map((hole: CourseHole, i: number) =>
              f9 ? (
                <VeryDenseTableCell key={`net-${hole.holeNumber}-${playerId}`} className={classes.scoreCell}>
                  {playerRoundScore ? this._getNetScoreForHole(i, playerRoundScore) : ''}
                </VeryDenseTableCell>
              ) : (
                <VeryDenseTableCell key={`net-${hole.holeNumber}-${playerId}`} className={classes.emptyScoreCell} />
              ),
            )}
          {f9 ? (
            <VeryDenseTableCell className={classes.scoreCell}>
              <strong>{playerRoundScore ? playerRoundScore.scoreOut : ''}</strong>
            </VeryDenseTableCell>
          ) : (
            <VeryDenseTableCell className={classes.emptyScoreCell} />
          )}
          {b9Holes &&
            b9Holes.map((hole: CourseHole, i: number) =>
              b9 ? (
                <VeryDenseTableCell key={`net-${hole.holeNumber}-${playerId}`} className={classes.scoreCell}>
                  {playerRoundScore ? this._getNetScoreForHole(9 + i, playerRoundScore) : ''}
                </VeryDenseTableCell>
              ) : (
                <VeryDenseTableCell key={`hole-${hole.holeNumber}-${playerId}`} className={classes.emptyScoreCell} />
              ),
            )}
          {b9 ? (
            <VeryDenseTableCell className={classes.scoreCell}>
              <strong>{playerRoundScore ? playerRoundScore.scoreIn : ''}</strong>
            </VeryDenseTableCell>
          ) : (
            <VeryDenseTableCell className={classes.emptyScoreCell} />
          )}
          <VeryDenseTableCell className={classes.scoreCell}>
            <strong>{playerRoundScore ? playerRoundScore.scoreTotal : ''}</strong>
          </VeryDenseTableCell>
        </TableRow>
      </TableBody>
    )
  }

  private _getPlayerStrokesForHole = (playerScore: TournamentLeaderboardPlayerRound, hole) => {
    const foundedStrokes = playerScore.strokes[hole.holeNumber - 1] ? playerScore.strokes[hole.holeNumber - 1] : null
    const foundedToPar = playerScore.toParRaw[hole.holeNumber - 1] ? playerScore.toParRaw[hole.holeNumber - 1] : null

    let resultClass = 'par'
    if (foundedToPar) {
      if (foundedToPar >= 2) {
        resultClass = 'doublebogey'
      }
      if (foundedToPar === 1) {
        resultClass = 'bogey'
      }
      if (foundedToPar === -1) {
        resultClass = 'birdie'
      }
      if (foundedToPar <= -2) {
        resultClass = 'eagle'
      }
      if (foundedToPar === 0) {
        resultClass = 'par'
      }
    }

    let strokes = foundedStrokes ? foundedStrokes : ''
    if (foundedStrokes === '0') {
      strokes = '-'
    }

    return <div className={resultClass}>{strokes}</div>
  }

  private _getNetScoreForHole = (hole: number, playerScore: TournamentLeaderboardPlayerRound) => {
    const netScore = playerScore.score[hole] ? playerScore.score[hole] : ''
    return netScore
  }
}

export default withStyles(styles)(LeaderboardScoreTable)
