export const processTournamentPayload = (tournament: TournamentState): TournamentSavePayload => {
  return {
    name: tournament.name,
    hcpRound: tournament.hcpRound,
    tournamentType: tournament.tournamentType,
    tour: tournament.tour,
    public: tournament.public,
    primaryRanking: tournament.primaryRanking,
    primaryRankingConfig: tournament.primaryRankingConfig,
    sideRanking: tournament.sideRanking,
    sideRankingConfig: tournament.sideRankingConfig,
    divisions: tournament.divisions,
    weeklyRounds: tournament.weeklyRounds,
    divisionsEnabled: tournament.divisionsEnabled,
    individualHcpIndexLimitEnabled: tournament.individualHcpIndexLimitEnabled,
    individualHcpIndexLimit: tournament.individualHcpIndexLimit,
  }
}
