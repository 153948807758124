import React from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogActions, Table, TableRow, TableBody, TableHead } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { emptyRanking } from '../../../store/api/emptyObjects'
import { OverlayLoader } from '../../ui/OverlayLoader'
import { sendScoresForHandicapping, fetchScoreSendingStatus } from '../../../store/tournamentLeaderboard/actions'
import { VeryDenseTableCell } from '../../tables/tableComponents'
import { Close, Check } from '@mui/icons-material'
import { RootState } from '@app/store'

interface OwnProps {
  tournamentId: number
  open: boolean
  onClose(): void
}

interface StateProps {
  isLoading: boolean
  scoreStatus?: ScoreStatus[]
}

interface DispatchProps {
  sendScoresForHandicapping(id: number): void
  fetchScoreSendingStatus(id: number): void
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps

class HandicappingDialog extends React.Component<Props> {
  componentDidMount() {
    const { scoreStatus } = this.props
    !scoreStatus && this.props.fetchScoreSendingStatus(this.props.tournamentId)
  }

  render() {
    const { open = false } = this.props

    return (
      <>
        <Dialog
          open={open}
          onClose={(_, reason) => reason !== 'backdropClick' && this._handleDismiss()}
          aria-labelledby="sendScores"
        >
          <BaseDialogTitle
            style={{ marginBottom: 10 }}
            id={'sendScores-dialog'}
            title={<FormattedMessageWrapper id={'buttons.sendScoresForHandicapping'} />}
            onClose={this._handleDismiss}
          />

          <DialogContent>{this._renderPlayerStatus()}</DialogContent>

          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={this._handleDismiss} disabled={this.props.isLoading}>
              <FormattedMessageWrapper id={'buttons.cancel'} />
            </Button>
            <div>
              <ButtonLoaderWrap loading={this.props.isLoading}>
                <Button
                  disabled={this.props.isLoading}
                  onClick={this._handleSend}
                  color="primary"
                  variant={'contained'}
                >
                  <FormattedMessageWrapper id={'buttons.send'} />
                </Button>
              </ButtonLoaderWrap>
            </div>
          </DialogActions>
        </Dialog>
        <OverlayLoader visible={this.props.isLoading} />
      </>
    )
  }

  public _renderPlayerStatus = () => {
    if (!this.props.scoreStatus) {
      return null
    }
    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <VeryDenseTableCell style={{ minWidth: 300 }}>
                <FormattedMessageWrapper id="tournament.name" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="tournament.scoreSendingStatus" />
              </VeryDenseTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.scoreStatus.map((status) => (
              <TableRow key={status.roundId + status.id}>
                <VeryDenseTableCell>
                  {status.firstName} {status.lastName}
                </VeryDenseTableCell>
                <VeryDenseTableCell style={{ textAlign: 'center' }}>
                  {status.success ? (
                    <Check color="primary" fontSize={'small'} style={{ marginTop: 5 }} />
                  ) : (
                    <Close color="error" fontSize={'small'} style={{ marginTop: 5 }} />
                  )}
                </VeryDenseTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    )
  }

  public _handleSend = () => {
    this.props.sendScoresForHandicapping(this.props.tournamentId)
  }

  public _handleDismiss = (): void => {
    this._handleClose()
  }

  public _handleClose = (): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
      this.setState({ errors: [], ranking: emptyRanking, tempLogo: undefined })
    }
  }
}

export default injectIntl(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (store) => ({
      isLoading: store.tournamentLeaderboardReducer.loading,
      scoreStatus: store.tournamentLeaderboardReducer.scoreStatus,
    }),
    {
      sendScoresForHandicapping,
      fetchScoreSendingStatus,
    },
  )(HandicappingDialog),
)
