export const ORGANIZATION_FETCH = 'ORGANIZATION_FETCH'
export const ORGANIZATION_FETCH_SUCCESS = 'ORGANIZATION_FETCH_SUCCESS'
export const ORGANIZATION_FETCH_FAILURE = 'ORGANIZATION_FETCH_FAILURE'

export const CREATE_ORGANIZATION_APPLICATION_REQUESTING = 'CREATE_ORGANIZATION_APPLICATION_REQUESTING'
export const CREATE_ORGANIZATION_APPLICATION_SUCCESS = 'CREATE_ORGANIZATION_APPLICATION_SUCCESS'
export const CREATE_ORGANIZATION_APPLICATION_ERROR = 'CREATE_ORGANIZATION_APPLICATION_ERROR'

export const UPDATE_ORGANIZATION_APPLICATION_REQUESTING = 'UPDATE_ORGANIZATION_APPLICATION_REQUESTING'

export const UPLOAD_ORGANIZATION_LOGO = 'UPLOAD_ORGANIZATION_LOGO'
export const UPLOAD_ORGANIZATION_LOGO_SUCCESS = 'UPLOAD_ORGANIZATION_LOGO_SUCCESS'
export const UPLOAD_ORGANIZATION_LOGO_FAILURE = 'UPLOAD_ORGANIZATION_LOGO_FAILURE'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE'

export const UPLOAD_ORGANIZATION_COVER_IMAGE = 'UPLOAD_ORGANIZATION_COVER_IMAGE'
export const UPLOAD_ORGANIZATION_COVER_IMAGE_SUCCESS = 'UPLOAD_ORGANIZATION_COVER_IMAGE_SUCCESS'
export const UPLOAD_ORGANIZATION_COVER_IMAGE_FAILURE = 'UPLOAD_ORGANIZATION_COVER_IMAGE_FAILURE'

export const ADD_ORGANIZATION_LICENSE = 'ADD_ORGANIZATION_LICENSE'
export const ADD_ORGANIZATION_LICENSE_SUCCESS = 'ADD_ORGANIZATION_LICENSE_SUCCESS'
export const ADD_ORGANIZATION_LICENSE_FAILURE = 'ADD_ORGANIZATION_LICENSE_FAILURE'

export const ADD_ORGANIZATION_SUBSCRIPTION = 'ADD_ORGANIZATION_SUBSCRIPTION'
export const ADD_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'ADD_ORGANIZATION_SUBSCRIPTION_SUCCESS'
export const ADD_ORGANIZATION_SUBSCRIPTION_FAILURE = 'ADD_ORGANIZATION_SUBSCRIPTION_FAILURE'

export const FETCH_ORGANIZATION_LICENSE_HISTORY = 'FETCH_ORGANIZATION_LICENSE_HISTORY'
export const FETCH_ORGANIZATION_LICENSE_HISTORY_SUCCESS = 'FETCH_ORGANIZATION_LICENSE_HISTORY_SUCCESS'
export const FETCH_ORGANIZATION_LICENSE_HISTORY_FAILURE = 'FETCH_ORGANIZATION_LICENSE_HISTORY_FAILURE'

export const FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY = 'FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY'
export const FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_SUCCESS = 'FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_SUCCESS'
export const FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_FAILURE = 'FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_FAILURE'
