import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material'
import { Link } from 'react-router-dom'

interface Props {
  to: string
  openInNewTab?: boolean
  children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export const StyledLink: React.FC<Props> = ({ to, openInNewTab = false, children }) => {
  const classes = useStyles()

  return (
    <Link to={to} target={openInNewTab ? '_blank' : undefined} className={classes.link}>
      {children}
    </Link>
  )
}
