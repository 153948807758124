import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { processTeamsResponse } from '../utils/tournamentTeamUtils'
import { RootState } from '@app/store'
import { getTournamentId } from '../slices/configSlice'
import { getActiveStartListCacheTags } from '../utils/tournamentStartListsUtils'

export const tournamentTeamsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTeams: build.query<TournamentTeamsResponse, number>({
      query: (tournamentId: number) => APIRoute.GET_TEAMS(tournamentId),
      providesTags: [CacheTag.TOURNAMENT_TEAMS],
      transformResponse: (response: TournamentTeamsResponse) => {
        return processTeamsResponse(response)
      },
    }),
    addTeams: build.mutation<TournamentTeamsResponse, AddTeamsPayload>({
      query: (payload) => ({
        url: APIRoute.POST_TEAMS(payload.tournamentId),
        method: HttpMethod.POST,
        body: {
          baseName: payload.baseName,
        },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            tournamentTeamsApi.util.updateQueryData('getTeams', payload.tournamentId, () => {
              return processTeamsResponse(data)
            }),
          )
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_TEAMS]))
          console.error('Error adding teams')
        }
      },
    }),
    deleteTeams: build.mutation<void, DeleteTeamsPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_TEAMS(payload.tournamentId),
        method: HttpMethod.DELETE,
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_TEAMS, CacheTag.TOURNAMENT_PLAYERS],
    }),
    addTeam: build.mutation<void, AddTeamPayload>({
      query: (payload) => ({
        url: APIRoute.POST_TEAM(payload.tournamentId),
        method: HttpMethod.POST,
        body: {
          name: payload.name,
        },
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_TEAMS],
    }),
    updateTeam: build.mutation<void, UpdateTeamPayload>({
      query: (payload) => ({
        url: APIRoute.EDIT_TEAM(payload.tournamentId, payload.teamId),
        method: HttpMethod.PUT,
        body: {
          name: payload.name,
        },
      }),
      invalidatesTags: () => [CacheTag.TOURNAMENT_TEAMS, ...getActiveStartListCacheTags()],
    }),
    deleteTeam: build.mutation<void, DeleteTeamPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_TEAM(payload.tournamentId, payload.teamId),
        method: HttpMethod.DELETE,
      }),
      invalidatesTags: () => [CacheTag.TOURNAMENT_TEAMS, CacheTag.TOURNAMENT_PLAYERS, ...getActiveStartListCacheTags()],
    }),
    addTeamToPool: build.mutation<void, AddTeamToPoolPayload>({
      query: (payload) => ({
        url: APIRoute.POST_ADD_TEAM_TO_POOL(payload.tournamentId, payload.teamId),
        method: HttpMethod.PUT,
        body: {
          target: payload.targetPool,
        },
      }),
      invalidatesTags: [CacheTag.TOURNAMENT_TEAMS, CacheTag.TOURNAMENT_PLAYERS],
    }),
    createTeamsAutofill: build.mutation<TournamentTeamsResponse, CreateTeamsAutofillPayload>({
      query: (payload) => ({
        url: APIRoute.POST_CREATE_TEAMS_AUTOFILL(payload.tournamentId),
        method: HttpMethod.POST,
        body: {
          fillTeamsByType: payload.fillTeamsByType,
          playersOrder: payload.playersOrder,
        },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            tournamentTeamsApi.util.updateQueryData('getTeams', payload.tournamentId, () => {
              return processTeamsResponse(data)
            }),
          )
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_TEAMS]))
          console.error('Error autofilling teams')
        }
      },
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS],
    }),
    addPlayerToTeam: build.mutation<TournamentTeamsResponse, EditTeamPlayerPayload>({
      query: (payload) => ({
        url: APIRoute.POST_ADD_PLAYER_TO_TEAM(payload.tournamentId, payload.teamId, payload.playerId),
        method: HttpMethod.POST,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            tournamentTeamsApi.util.updateQueryData('getTeams', payload.tournamentId, () => {
              return processTeamsResponse(data)
            }),
          )
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_TEAMS]))
          console.error('Error adding teams')
        }
      },
      invalidatesTags: () => [CacheTag.TOURNAMENT_PLAYERS, ...getActiveStartListCacheTags()],
    }),
    removePlayerFromTeam: build.mutation<TournamentTeamsResponse, EditTeamPlayerPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_PLAYER_FROM_TEAM(payload.tournamentId, payload.teamId, payload.playerId),
        method: HttpMethod.DELETE,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            tournamentTeamsApi.util.updateQueryData('getTeams', payload.tournamentId, () => {
              return processTeamsResponse(data)
            }),
          )
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_TEAMS]))
          console.error('Error removing teams')
        }
      },
      invalidatesTags: () => [CacheTag.TOURNAMENT_PLAYERS, ...getActiveStartListCacheTags()],
    }),
  }),
})

export const selectTournamentTeams = (state: RootState) =>
  tournamentTeamsApi.endpoints.getTeams.select(getTournamentId(state))(state)

export const {
  useGetTeamsQuery,
  useAddTeamsMutation,
  useDeleteTeamsMutation,
  useAddTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useAddTeamToPoolMutation,
  useCreateTeamsAutofillMutation,
} = tournamentTeamsApi
