import localization from '../../localization'
import { getSelectedLanguageFromStorage, storeSelectedLanguage } from './utils'
import { SetLanguage } from './actions'
import { createReducer } from 'typesafe-actions'

const storedCode = getSelectedLanguageFromStorage()

const localeCodes = Object.keys(localization)
const availableLanguages = localeCodes.map((code) => ({ code }))

const initialState: LocaleState = {
  availableLanguages,
  appLanguage: (availableLanguages.find(({ code }) => code === storedCode) as LanguageOption) || { code: 'en-US' },
}

const setLanguage = (state: LocaleState, action: SetLanguage) => {
  const { language } = action

  storeSelectedLanguage(language.code)

  return {
    ...state,
    appLanguage: state.availableLanguages.find(({ code }) => code === language.code) as LanguageOption,
  }
}

export const localeReducer = createReducer(initialState, {
  SET_LANGUAGE: setLanguage,
})
