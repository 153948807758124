import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { ListItemButton, Theme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import DeleteConfirm, { DeleteConfirmChildren } from '../deleteConfirm/DeleteConfirm'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { confirm } from '../confirm/Confirm'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { isCustomerEventUser } from '../../../utils/authUtils'
import { Delete, LiveTv, Description, Reply } from '@mui/icons-material'
import { currentlyOngoingRoundIndex } from '../../../utils/tournamentUtils'
import { updateTournamentRoundStatus } from '@app/store/api/thunks/tournamentRoundThunks'
import { formTournamentSiteUrl } from '../../../config'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    list: {
      color: theme.palette.primary.main,
    },
    listItem: {
      borderBottom: `1px solid ${theme.customPalette.border}`,
      paddingLeft: 24,
      paddingRight: 24,
      '@global': {
        span: {
          fontWeight: 500,
        },
      },
    },
    warningListItem: {
      color: theme.customPalette.warning,
    },
    errorListItem: {
      color: theme.customPalette.error,
    },
    icon: {
      marginRight: 15,
    },
    flipIcon: {
      transform: 'scaleX(-1)',
    },
  })

export type TournamentTuneDialogAction = 'copy' | 'remove' | 'printouts' | 'endScoring' | 'note'
export type TournamentTuneDialogCloseArgs = {
  action: TournamentTuneDialogAction
  tournament: Tournament
}

interface OwnProps {
  open?: boolean
  tournament?: Tournament
  onClose(args?: TournamentTuneDialogCloseArgs): void
}

interface StateProps {
  roleInfo?: RoleInfo
}

interface DispatchProps {
  updateTournamentRoundStatus(payload: UpdateRoundStatusPayload): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps & WithRouterProps

class TournamentTuneDialog extends React.Component<Props> {
  render() {
    const { open = false, tournament, onClose, intl, classes } = this.props

    if (open && !tournament) {
      console.warn('trying to open tournament tune without a tournament: Tournament prop')
      return null
    }

    if (!tournament) {
      return null
    }

    const clubName =
      tournament.rounds && tournament.rounds[0] && tournament.rounds[0].club ? tournament.rounds[0].club?.name : ''

    return (
      <Dialog maxWidth={'xs'} fullWidth open={open} aria-labelledby={'tournament-tune-dialog-title'}>
        <BaseDialogTitle
          id={'tournament-tune-dialog-title'}
          subtitle={clubName}
          title={tournament.name}
          style={{ marginBottom: 0 }}
          onClose={() => onClose()}
          largeTitleText
        />
        <div>
          <List className={classes.list}>
            <ListItem className={classes.listItem} button={true} onClick={this._handleClose.bind(this, 'note')}>
              <Description className={classes.icon} />
              <ListItemText primary={intl.formatMessage({ id: 'tournaments.addNotes' })} />
            </ListItem>
            <ListItem className={classes.listItem} button={true} onClick={this._handleClose.bind(this, 'copy')}>
              <Reply className={classNames(classes.icon, classes.flipIcon)} />
              <ListItemText primary={intl.formatMessage({ id: 'tournaments.copy' })} />
            </ListItem>
            <ListItemButton
              className={classes.listItem}
              onClick={() => window.open(`${formTournamentSiteUrl(tournament.id)}/screen`)}
            >
              <LiveTv className={classes.icon} />
              <ListItemText primary={intl.formatMessage({ id: 'tournaments.leaderboardScreenView' })} />
            </ListItemButton>
            {!isCustomerEventUser(this.props.roleInfo) && (
              <DeleteConfirm titleId={'tournament.removeTournament'} subtitle={tournament.name}>
                {({ showConfirm }: DeleteConfirmChildren) => (
                  <ListItemButton
                    onClick={() => showConfirm({ callback: this._handleClose.bind(this, 'remove') })}
                    className={classNames(classes.listItem, classes.errorListItem)}
                  >
                    <Delete className={classes.icon} color="error" />
                    <ListItemText primary={intl.formatMessage({ id: 'tournaments.remove' })} />
                  </ListItemButton>
                )}
              </DeleteConfirm>
            )}
          </List>
        </div>
        <DialogActions>
          <Button onClick={this._handleClose.bind(this, undefined)}>
            <FormattedMessageWrapper id={'buttons.close'} />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private _handleClose = (action?: TournamentTuneDialogAction): void => {
    const { onClose, tournament } = this.props
    if (onClose) {
      if (action && tournament) {
        onClose({
          action,
          tournament,
        })
      } else {
        onClose()
      }
    }
  }

  public _endAppScoring = () => {
    const { intl, tournament, updateTournamentRoundStatus } = this.props

    if (tournament) {
      confirm({
        message: intl.formatMessage({ id: 'tournament.scoringEndWarning' }),
        options: {
          title: intl.formatMessage(
            { id: 'tournament.endAppScoringFromPlayers' },
            { round: currentlyOngoingRoundIndex(tournament.rounds) + 1 },
          ),
          subtitle: tournament.name,
          cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
          okText: intl.formatMessage({ id: 'buttons.endScoring' }),
        },
      }).then(
        () => {
          const roundIndex = currentlyOngoingRoundIndex(tournament.rounds)

          updateTournamentRoundStatus({
            tournamentId: tournament.id,
            roundId: tournament.rounds[roundIndex].id,
            body: { isScoringDisabled: true },
          })
        },
        () => {
          this._handleClose('endScoring')
        },
      )
    }
  }
}

const RoutedTournamentTuneDialog = withRouter(TournamentTuneDialog)
const LocalizedTournamentTuneDialog = injectIntl(RoutedTournamentTuneDialog)
const StyledTournamentTuneDialog = withStyles(styles)(LocalizedTournamentTuneDialog)
export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store): StateProps => ({
    roleInfo: store.authenticationReducer.roleInfo,
  }),
  {
    updateTournamentRoundStatus,
  },
)(StyledTournamentTuneDialog as any)
