import { RootState } from '@app/store'
import { selectClub } from '../endpoints/clubsAndCoursesApi'
import { selectClubs } from '../slices/clubsAndCoursesSlice'

export const processRoundCourse = (course: Course, roundIndex: number): RoundCourse => {
  return {
    roundIndex,
    id: course.id,
    courseName: course.courseName,
    clubName: course.clubName,
    clubId: course.clubId,
    par: course.par,
    holes: course.holes,
    teeBoxes: course.teeBoxes,
    version: course.version,
  }
}

export const getSelectedClub = (state: RootState): Club | undefined => {
  const selectedClubId = state.configReducer.clubsAndCourses.selectedClubId
  const selectedClub = selectClub(state, selectedClubId)
  const clubs = selectClubs(state)
  if (selectedClubId === selectedClub?.id) {
    return selectedClub
  }
  return clubs.find((c) => c.id === selectedClubId)
}
