import { Action } from 'redux'
import {
  CREATE_NEW_ORGANIZATION_PRODUCT,
  DELETE_ORGANIZATION_PRODUCT,
  EDIT_ORGANIZATION_PRODUCT,
  EDIT_TOURNAMENT_PRODUCTS,
  FETCH_ORGANIZATION_PRODUCTS,
  FETCH_TOURNAMENT_PRODUCTS,
  FETCH_REPORT,
} from './constants'

// FETCH PRODUCTS

export interface FetchOrganizationProductsAction extends Action {
  type: 'FETCH_ORGANIZATION_PRODUCTS'
  organizationId: number
  onComplete?: () => void
}

export interface FetchOrganizationProductsSuccessAction extends Action {
  type: 'FETCH_ORGANIZATION_PRODUCTS_SUCCESS'
  products: OrganizationProduct[]
}

export interface FetchOrganizationProductsFailureAction extends Action {
  type: 'FETCH_ORGANIZATION_PRODUCTS_FAILURE'
  error: ErrorPayload
}

export const fetchOrganizationProducts = (
  organizationId: number,
  onComplete?: () => void,
): FetchOrganizationProductsAction => ({
  type: FETCH_ORGANIZATION_PRODUCTS,
  organizationId,
  onComplete,
})

// CREATE NEW PRODUCT

export interface CreateNewOrganizationProductPayload {
  product: OrganizationProduct
  onComplete?: () => void
}

export interface CreateNewOrganizationProductAction extends Action {
  type: 'CREATE_NEW_ORGANIZATION_PRODUCT'
  product: OrganizationProduct
  onComplete?: () => void
}

export interface CreateNewOrganizationProductSuccessAction extends Action {
  type: 'CREATE_NEW_ORGANIZATION_PRODUCT_SUCCESS'
}

export interface CreateNewOrganizationProductFailureAction extends Action {
  type: 'CREATE_NEW_ORGANIZATION_PRODUCT_FAILURE'
  error: ErrorPayload
}

export const createNewOrganizationProduct = (
  product: OrganizationProduct,
  onComplete?: () => void,
): CreateNewOrganizationProductAction => ({
  type: CREATE_NEW_ORGANIZATION_PRODUCT,
  product,
  onComplete,
})

// EDIT PRODUCT

export interface EditOrganizationProductPayload {
  product: OrganizationProduct
  onComplete?: () => void
}

export interface EditOrganizationProductAction extends Action {
  type: 'EDIT_ORGANIZATION_PRODUCT'
  product: OrganizationProduct
  onComplete?: () => void
}

export interface EditOrganizationProductSuccessAction extends Action {
  type: 'EDIT_ORGANIZATION_PRODUCT_SUCCESS'
}

export interface EditOrganizationProductFailureAction extends Action {
  type: 'EDIT_ORGANIZATION_PRODUCT_FAILURE'
  error: ErrorPayload
}

export const editOrganizationProduct = (
  product: OrganizationProduct,
  onComplete?: () => void,
): EditOrganizationProductAction => ({
  type: EDIT_ORGANIZATION_PRODUCT,
  product,
  onComplete,
})

// DELETE PRODUCT

export interface DeleteOrganizationProductPayload {
  product: OrganizationProduct
  onComplete?: () => void
}

export interface DeleteOrganizationProductAction extends Action {
  type: 'DELETE_ORGANIZATION_PRODUCT'
  product: OrganizationProduct
  onComplete?: () => void
}

export interface DeleteOrganizationProductSuccessAction extends Action {
  type: 'DELETE_ORGANIZATION_PRODUCT_SUCCESS'
}

export interface DeleteOrganizationProductFailureAction extends Action {
  type: 'DELETE_ORGANIZATION_PRODUCT_FAILURE'
  error: ErrorPayload
}

export const deleteOrganizationProduct = (
  product: OrganizationProduct,
  onComplete?: () => void,
): DeleteOrganizationProductAction => ({
  type: DELETE_ORGANIZATION_PRODUCT,
  product,
  onComplete,
})

// FETCH TOURNAMENT PRODUCTS

export interface FetchTournamentProductsAction extends Action {
  type: 'FETCH_TOURNAMENT_PRODUCTS'
  tournamentId: number
  onComplete?: () => void
}

export interface FetchTournamentProductsSuccessAction extends Action {
  type: 'FETCH_TOURNAMENT_PRODUCTS_SUCCESS'
  products: TournamentProduct[]
}

export interface FetchTournamentProductsFailureAction extends Action {
  type: 'FETCH_TOURNAMENT_PRODUCTS_FAILURE'
  error: any
}

export const fetchTournamentProducts = (
  tournamentId: number,
  onComplete?: () => void,
): FetchTournamentProductsAction => ({
  type: FETCH_TOURNAMENT_PRODUCTS,
  tournamentId,
  onComplete,
})

// EDIT TOURNAMENT PRODUCT

export interface EditTournamentProductsPayload {
  products: TournamentProduct[]
  onComplete?: () => void
}

export interface EditTournamentProductsAction extends Action {
  type: 'EDIT_TOURNAMENT_PRODUCTS'
  products: TournamentProduct[]
  onComplete?: () => void
}

export interface EditTournamentProductsSuccessAction extends Action {
  type: 'EDIT_TOURNAMENT_PRODUCTS_SUCCESS'
  products: TournamentProduct[]
}

export interface EditTournamentProductsFailureAction extends Action {
  type: 'EDIT_TOURNAMENT_PRODUCTS_FAILURE'
  error: any
}

export const editTournamentProducts = (
  products: TournamentProduct[],
  onComplete?: () => void,
): EditTournamentProductsAction => ({
  type: EDIT_TOURNAMENT_PRODUCTS,
  products,
  onComplete,
})

// FETCH REPORT

export interface FetchReportPayload {
  startTimestamp: string
  endTimestamp: string
  onComplete?: () => void
}

export interface FetchReportAction extends FetchReportPayload {
  type: 'FETCH_REPORT'
}

export interface FetchReportSuccessAction extends Action {
  type: 'FETCH_REPORT_SUCCESS'
}

export interface FetchReportFailureAction extends Action {
  type: 'FETCH_REPORT_FAILURE'
  error: ErrorPayload
}

export const fetchReport = (args: FetchReportPayload): FetchReportAction => ({
  type: FETCH_REPORT,
  ...args,
})
