import React from 'react'
import { enqueueSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { removeNotification } from '../../store/notifications/actions'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { RootState } from '@app/store'

interface StateProps {
  notifications: Notification[]
}

interface DispatchProps {
  removeNotification: (key: number) => void
}

type Props = StateProps & DispatchProps & WrappedComponentProps

interface State {
  displayed: number[]
}

const initialState = {
  displayed: [] as number[],
}

class Notifier extends React.Component<Props, State> {
  readonly state = initialState

  public render() {
    return null
  }

  public shouldComponentUpdate({ notifications: newSnacks }) {
    const { notifications: currentSnacks } = this.props
    let notExists = false
    for (let i = 0; i < newSnacks.length; i += 1) {
      if (notExists) {
        continue
      }
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length
    }
    return notExists
  }

  public componentDidUpdate() {
    const { notifications, intl } = this.props

    notifications.forEach((notification) => {
      // Do nothing if snackbar is already displayed
      if (this.state.displayed.includes(notification.key)) {
        return
      }

      // Translate message if includes _
      let message = notification.message.replace('Error: ', '')
      if (notification.message.includes('_')) {
        message = intl.formatMessage({ id: `apiErrors.${message}` })
      }

      // Display snackbar using notistack
      enqueueSnackbar(message, notification.options)

      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key)

      // Dispatch action to remove snackbar from redux store
      setTimeout(() => {
        this.props.removeNotification(notification.key)
      }, 100)
    })
  }

  private storeDisplayed = (id) => {
    this.setState({
      ...this.state,
      displayed: [...this.state.displayed, id],
    })
  }
}

export default connect<StateProps, DispatchProps, {}, RootState>(
  (store) => ({
    notifications: store.notificationsReducer.notifications,
  }),
  { removeNotification },
)(injectIntl(Notifier))
