import {
  CONDENSED_TOURNAMENTS_FETCH_SUCCESS,
  TOURNAMENT_REMOVE,
  TOURNAMENT_REMOVE_FAILURE,
  TOURNAMENT_REMOVE_SUCCESS,
  TOURNAMENTS_FETCH,
  TOURNAMENTS_FETCH_FAILURE,
  TOURNAMENTS_FETCH_SUCCESS,
  TOURNAMENT_CALENDAR_TOGGLE,
  TOURNAMENT_CALENDAR_TOGGLE_FAILURE,
  TOURNAMENT_CALENDAR_TOGGLE_SUCCESS,
  FETCH_FILTER_YEARS,
  FETCH_FILTER_YEARS_SUCCESS,
  FETCH_FILTER_YEARS_FAILURE,
  CONDENSED_TOURNAMENTS_FETCH,
  TOURNAMENTS_CLEAR_ALL,
} from './constants'
import {
  FetchCondensedTournamentsAction,
  FetchCondensedTournamentsSuccessAction,
  FetchTournamentsAction,
  FetchTournamentsFailureAction,
  FetchTournamentsSuccessAction,
  TournamentRemoveAction,
  TournamentRemoveFailureAction,
  TournamentRemoveSuccessAction,
  TournamentCalendarToggleAction,
  TournamentCalendarToggleSuccessAction,
  TournamentCalendarToggleFailureAction,
  FetchFilterYearsAction,
  FetchFilterYearsSuccessAction,
  FetchFilterYearsFailureAction,
  ClearAllTournamentsAction,
} from './actions'
import { formatDate } from '@app/utils/dates'

const initialState: TournamentsState = {
  tournaments: [],
  error: null,
  loading: true,
  allTournaments: [],
  years: [],
}

type TournamentActions =
  | FetchTournamentsSuccessAction
  | FetchTournamentsFailureAction
  | FetchTournamentsAction
  | TournamentRemoveAction
  | TournamentRemoveSuccessAction
  | TournamentRemoveFailureAction
  | FetchCondensedTournamentsAction
  | FetchCondensedTournamentsSuccessAction
  | TournamentCalendarToggleAction
  | TournamentCalendarToggleSuccessAction
  | TournamentCalendarToggleFailureAction
  | FetchFilterYearsAction
  | FetchFilterYearsSuccessAction
  | FetchFilterYearsFailureAction
  | ClearAllTournamentsAction

const tournamentsReducer = (state: TournamentsState = initialState, action: TournamentActions) => {
  switch (action.type) {
    case TOURNAMENT_REMOVE:
    case TOURNAMENTS_FETCH:
    case TOURNAMENT_CALENDAR_TOGGLE:
    case FETCH_FILTER_YEARS:
    case CONDENSED_TOURNAMENTS_FETCH:
      return {
        ...state,
        loading: true,
      }
    case TOURNAMENTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        tournaments: action.tournaments,
        calendarUrl: action.calendarUrl,
      }
    case FETCH_FILTER_YEARS_FAILURE:
    case TOURNAMENT_REMOVE_FAILURE:
    case TOURNAMENTS_FETCH_FAILURE:
    case TOURNAMENT_CALENDAR_TOGGLE_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    case TOURNAMENT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        allTournaments: [],
      }
    case TOURNAMENT_CALENDAR_TOGGLE_SUCCESS: {
      const tournamentIndex = state.tournaments.findIndex((t) => t.id === action.id)
      return {
        ...state,
        loading: false,
        tournaments: [
          ...state.tournaments.slice(0, tournamentIndex),
          {
            ...state.tournaments[tournamentIndex],
            showInCalendar: action.showInCalendar,
          },
          ...state.tournaments.slice(tournamentIndex + 1),
        ],
      }
    }
    case CONDENSED_TOURNAMENTS_FETCH_SUCCESS: {
      const allTournaments = action.data.map((condensedTournament) => ({
        ...condensedTournament,
        label:
          `${condensedTournament.name}` +
          (condensedTournament.startTime !== null
            ? ` (${formatDate(condensedTournament.startTime, 'year', 'metric')})`
            : ``),
      }))
      return {
        ...state,
        loading: false,
        allTournaments,
      }
    }
    case TOURNAMENTS_CLEAR_ALL:
      return {
        ...state,
        allTournaments: [],
      }
    case FETCH_FILTER_YEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        years: action.years,
      }
    default:
      return state
  }
}

export { tournamentsReducer }
