import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from '@app/store'
import { isValueNumeric } from '@app/utils/numbers'
import { mixpanelActions } from '@app/utils/mixpanel'

export const useMixpanel = () => {
  const location = useLocation()
  const { loggedIn } = useSelector((store: RootState) => store.authenticationReducer)
  useEffect(() => {
    if (loggedIn) {
      const isTournamentPage = location.pathname.includes('tournaments/')
      if (!isTournamentPage) {
        mixpanelActions.trackPageView()
      }

      if (isTournamentPage) {
        const pathArray = location.pathname.split('/')
        let page = pathArray[pathArray.length - 1]
        page = isValueNumeric(page) ? 'redirect' : page
        if (page === 'redirect') {
          return
        }
        let tournamentId = pathArray[pathArray.length - 2]
        tournamentId = isValueNumeric(tournamentId) ? tournamentId : ''

        mixpanelActions.trackPageView(page, tournamentId)
      }
    }
  }, [loggedIn, location.pathname])
}
