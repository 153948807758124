import Helmet from 'react-helmet'
import { injectIntl, IntlShape, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { SITE_TITLE, TITLE_DIVIDER } from '../../config'
import get from 'lodash/get'
import { RootState } from '@app/store'

const renderTitleField = (titleId?: string, intl?: IntlShape, values: any = {}) => {
  if (titleId && intl) {
    return `${intl.formatMessage({ id: titleId }, values)}${TITLE_DIVIDER}${SITE_TITLE}`
  }

  return SITE_TITLE
}

interface StateProps {
  locale: LocaleState
}

const HeadHelmet = ({ titleId, intl, locale, values }: HelmetHead & WrappedComponentProps) => {
  const langCode = get(locale.appLanguage, 'code')
  return (
    <Helmet htmlAttributes={{ lang: langCode }}>
      <title>{renderTitleField(titleId, intl, values)}</title>
    </Helmet>
  )
}

export default connect<StateProps, {}, Pick<HelmetHead, 'titleId' | 'values'>, RootState>((state) => ({
  locale: state.localeReducer,
}))(injectIntl(HeadHelmet))
