import Button, { ButtonProps } from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '../../theme/materialUITheme'
import classNames from 'classnames'
import { Theme } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: '6px 31px',
    '&.dense': {
      padding: 0,
      minWidth: 40,
      cursor: 'auto',
      '& span': {
        fontSize: '80%',
      },
    },
    '&.filled': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.filled:not(".dense"):hover': {
      background: theme.customPalette.mediumGray,
      borderColor: theme.customPalette.mediumGray,
    },
    '&.disabled': {
      opacity: 0.5,
    },
    '&.filled.disabled': {
      background: theme.customPalette.mediumGray,
      borderColor: theme.customPalette.mediumGray,
    },
    '&.filled.secondary': {
      background: theme.customPalette.darkGray2,
      borderColor: `rgba(0, 0, 0, 0.26)`,
    },
    '&.filled:not(".dense").secondary:hover': {
      background: theme.customPalette.mediumGray,
      borderColor: `rgba(0, 0, 0, 0.26)`,
    },
    '&.filled.fgu': {
      background: theme.customPalette.darkBlue,
      borderColor: theme.customPalette.darkBlue,
    },
    '&.filled.fgu:not(".dense"):hover': {
      background: theme.customPalette.mediumGray,
      borderColor: theme.customPalette.mediumGray,
    },
  },
  jumboRoot: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: '13px 32px',
    minWidth: 198,
    '&.filled': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.disabled': {
      borderColor: `rgba(0, 0, 0, 0.26)`,
    },
    '&.filled.disabled': {
      background: theme.customPalette.mediumGray,
      borderColor: `rgba(0, 0, 0, 0.26)`,
    },
    '&.filled.secondary': {
      background: theme.customPalette.darkGray2,
      borderColor: `rgba(0, 0, 0, 0.26)`,
    },
    '&.filled.fgu': {
      background: theme.customPalette.darkBlue,
      borderColor: theme.customPalette.darkBlue,
    },
  },
  label: {
    textTransform: 'none',
    fontSize: rem(15),
  },
}))

interface Props {
  children: React.ReactNode
  buttonProps?: ButtonProps | any
  jumbo?: boolean
  filled?: boolean
  secondary?: boolean
  fgu?: boolean
  dense?: boolean
}

const BorderButton = (props: Props) => {
  const { children, buttonProps = {}, jumbo = false, filled, secondary, dense, fgu } = props
  const classes = useStyles()
  const { jumboRoot, ...rest } = classes

  return (
    <Button
      className={classNames({
        filled: filled,
        disabled: buttonProps.disabled,
        secondary: secondary,
        dense: dense,
        fgu: fgu,
      })}
      classes={jumbo ? { ...rest, root: jumboRoot } : rest}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

export default BorderButton
