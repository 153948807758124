import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ImportIcon from '@mui/icons-material/Download'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { sortByOrderKey } from '@app/utils/sortUtils'
import { Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { MatchPlayBracketPoolCard } from '@app/components/match-play-brackets/MatchPlayBracketPoolCard'
import { generateRandomString } from '@app/utils/stringUtilts'
import { MatchPlayBracketImport } from '@app/components/dialogs/matchPlayBracketImport/MatchPlayBracketImport'
import { matchPlayBracketsMap } from '@app/utils/matchPlayBracketUtils'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
// eslint-disable-next-line max-len
import { MatchPlayBracketPlayerDialog } from '@app/components/dialogs/matchPlayBracketPlayerDialog/MatchPlayBracketPlayerDialog'

const useStyles = makeStyles(() => ({
  poolContainer: {
    margin: '35px 50px',
    backgroundColor: 'rgba(223, 223, 223, 0.83)',
    borderRadius: 20,
    padding: '10px 15px',
  },
  poolTitle: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '35px',
    margin: 0,
    color: '#000',
    padding: '0 15px',
  },
  iconsContainer: {
    marginTop: 5,
    marginLeft: 15,
    marginBottom: 25,
  },
  poolIcon: {
    color: '#000',
    cursor: 'pointer',
    marginRight: 5,
  },
  poolButtons: {
    display: 'block',
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '20px',
  },
}))

interface Props {
  bracket: MatchPlayBracket
  players: MatchPlayBracketPlayer[]
  updatePlayers: (players: MatchPlayBracketPlayer[], noDelay?: boolean, disableSave?: boolean) => void
  editPlayer: (player: MatchPlayBracketPlayer, deletePlayer?: boolean) => void
}

export const MatchPlayBracketPool: React.FC<Props> = ({ bracket, players, updatePlayers, editPlayer }) => {
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [newPlayerDialogOpen, setNewPlayerDialogOpen] = useState<boolean>(false)
  const [newPlayer, setNewPlayer] = useState<MatchPlayBracketPlayer | null>(null)

  const intl = useIntl()
  const classes = useStyles()

  const getPoolPlayers = (): MatchPlayBracketPlayer[] => {
    return players
      .filter((player: MatchPlayBracketPlayer) => player.stage === MatchPlayBracketStages.POOL)
      .sort((a, b) => sortByOrderKey(a.order, b.order))
  }

  const addNewPlayer = (): void => {
    const newPlayer: MatchPlayBracketPlayer = {
      id: null,
      playerBracketId: generateRandomString(),
      name: '',
      hcp: '',
      score: '',
      winner: false,
      stage: MatchPlayBracketStages.POOL,
      order: 0,
    }
    const noDelay = false
    const disableSave = true
    updatePlayers([...players, newPlayer], noDelay, disableSave)
    setNewPlayer(newPlayer)
    setNewPlayerDialogOpen(true)
  }

  const getFirstStage = (): BracketStage => {
    const numberOfPlayers = bracket?.options?.numberOfPlayers || 0
    let stageNames = []
    if (numberOfPlayers > 0) {
      stageNames = Object.values(matchPlayBracketsMap[numberOfPlayers])
    }
    return stageNames.length > 0 ? stageNames[0] : MatchPlayBracketStages.ROUND_1
  }

  const shufflePoolPlayers = (): void => {
    const originalPlayers = [...players]
    const poolPlayers = getPoolPlayers()
    poolPlayers.sort(() => Math.random() - 0.5)

    poolPlayers.forEach((player: MatchPlayBracketPlayer, index: number) => {
      const playerIndex = originalPlayers.findIndex(
        (p: MatchPlayBracketPlayer) => p.playerBracketId === player.playerBracketId,
      )
      originalPlayers[playerIndex] = { ...player, order: index + 1 }
    })
    updatePlayers(originalPlayers)
  }

  const moveToFirstRound = (): void => {
    const originalPlayers = [...players]
    const poolPlayers = getPoolPlayers()

    const firstStage = getFirstStage()
    const numberOfPlayers = bracket.options?.numberOfPlayers || 0

    poolPlayers.forEach((player: MatchPlayBracketPlayer, index: number) => {
      const playerIndex = originalPlayers.findIndex(
        (p: MatchPlayBracketPlayer) => p.playerBracketId === player.playerBracketId,
      )
      const order = index + 1
      if (order <= numberOfPlayers) {
        originalPlayers[playerIndex] = { ...player, stage: firstStage, order }
      }
    })
    updatePlayers(originalPlayers)
  }

  return (
    <>
      {importDialogOpen && (
        <MatchPlayBracketImport
          players={players}
          close={() => setImportDialogOpen(false)}
          updatePlayers={updatePlayers}
        />
      )}
      {newPlayerDialogOpen && newPlayer && (
        <MatchPlayBracketPlayerDialog
          player={newPlayer}
          close={() => setNewPlayerDialogOpen(false)}
          editPlayer={editPlayer}
        />
      )}
      <div className={classes.poolContainer} id="bracket-pool-container">
        <h3 className={classes.poolTitle}>{intl.formatMessage({ id: 'matchPlayBracket.playerPool' })}</h3>
        <div className={classes.iconsContainer}>
          <Tooltip title={intl.formatMessage({ id: 'matchPlayBracket.importPlayersFromTournament' })}>
            <ImportIcon className={classes.poolIcon} onClick={() => setImportDialogOpen(true)} />
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'matchPlayBracket.addNewPlayer' })}>
            <PersonAddIcon className={classes.poolIcon} onClick={addNewPlayer} />
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'matchPlayBracket.shufflePoolPlayers' })}>
            <ShuffleIcon className={classes.poolIcon} onClick={shufflePoolPlayers} />
          </Tooltip>
        </div>
        {getPoolPlayers().length > 0 && getPoolPlayers().length === players.length && (
          <Button
            className={classes.poolButtons}
            color="primary"
            variant="contained"
            onClick={() => moveToFirstRound()}
          >
            <FormattedMessageWrapper id="matchPlayBracket.moveToFirstRound" />
          </Button>
        )}
        <Droppable droppableId={MatchPlayBracketStages.POOL}>
          {(provided: DroppableProvided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {getPoolPlayers().map((player: MatchPlayBracketPlayer, index: number) => (
                <MatchPlayBracketPoolCard
                  player={player}
                  key={`${MatchPlayBracketStages.POOL}-${player.playerBracketId}`}
                  index={index}
                  draggableId={`${MatchPlayBracketStages.POOL}-${player.playerBracketId}`}
                  editPlayer={editPlayer}
                />
              ))}
            </div>
          )}
        </Droppable>
      </div>
    </>
  )
}
