import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { DivisionType } from '@app/store/api/enums/tournamentEnums'
import { rem } from '@app/theme/materialUITheme'
import { formatHandicap } from '@app/utils/playerUtils'
import { Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { getTournamentSelectedDivisions } from '@scenes/tournament/utils'
import { TypeLabel } from './TypeLabel'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

interface StateProps {
  divisions: DivisionState[]
  tournamentDivisions: number[]
}

type DivisionsTableProps = StateProps

const HeaderTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      textTransform: 'uppercase',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: rem(14),
      fontWeight: 600,
      padding: `${rem(8)} ${rem(16)}`,
    },
  }),
)(TableCell)

const useStyles = makeStyles((theme: Theme) => ({
  root: { marginTop: rem(20) },
  tableRowHeader: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  tableRow: {
    '& > td': { padding: `${rem(8)} ${rem(16)}` },
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
}))

const DivisionsTableComponent: React.FC<DivisionsTableProps> = ({ divisions, tournamentDivisions }) => {
  const classes = useStyles()

  const selectedDivisions: DivisionState[] = getTournamentSelectedDivisions(divisions, tournamentDivisions)

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRowHeader}>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'divisions.activeDivisions'} />
            </HeaderTableCell>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'divisions.gender'} />
            </HeaderTableCell>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'divisions.age'} />
            </HeaderTableCell>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'tournaments.hcpBadge'} />
            </HeaderTableCell>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'divisions.type'} />
            </HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedDivisions.map((division, index) => {
            const { name, gender, minAge, maxAge, minHcp, maxHcp, type } = division
            const emDash = '\u2014'
            const gte = '\u2264'
            const values = {
              name: (
                <TableCell key={`${index}_a_${division.id}`} style={{ fontWeight: 'bold' }}>
                  {name || '-'}
                </TableCell>
              ),
              gender: (
                <TableCell key={`${index}_b_${division.id}`} style={{ textTransform: 'capitalize' }}>
                  {gender || '-'}
                </TableCell>
              ),
              age: (
                <TableCell key={`${index}_c_${division.id}`}>
                  {minAge} {minAge && maxAge && emDash} {!minAge && !maxAge && '-'}
                  {((minAge && !maxAge) || (!minAge && maxAge)) && gte} {maxAge}
                </TableCell>
              ),
              hcp: (
                <TableCell key={`${index}_d_${division.id}`}>
                  {minHcp && formatHandicap(String(minHcp))} {minHcp && maxHcp && emDash} {!minHcp && !maxHcp && '-'}
                  {((minHcp && !maxHcp) || (!minHcp && maxHcp)) && gte} {maxHcp && formatHandicap(String(maxHcp))}
                </TableCell>
              ),
              type: (
                <TableCell key={`${index}_e_${division.id}`}>
                  <TypeLabel type={type as DivisionType} />
                </TableCell>
              ),
            }
            return (
              <TableRow key={`${index}_${division.id}`} className={classes.tableRow}>
                {Object.values(values).map((value) => value)}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export const DivisionsTable = connect<StateProps, {}, {}, RootState>(
  (state) => ({
    divisions: state.divisionsReducer.divisions,
    tournamentDivisions: selectTournament(state).divisions,
  }),
  {},
)(DivisionsTableComponent)
