import { put, all, takeEvery, select } from 'redux-saga/effects'
import { API_ROOT, APIRoute } from '@app/config'
import { getToken } from '../authentication/sagas'
import { enqueueNotification } from '../notifications/actions'
import { apiClientRequest, defaultOnCompleteCall } from '@utils/sagaHelpers'

import { SEND_PLAYER_SCORES, SEND_PLAYER_SCORES_FAILURE } from './constants'
import { UpdatePlayerScoresAction } from './actions'
import { RootState } from '..'

const getOrganizationId = (state: RootState) => state.organizationReducer.id

function* doSendHcpRounds(action: UpdatePlayerScoresAction) {
  const { onComplete } = action

  try {
    const { providerPlayerIdentifier, courseId, teeboxId, gender, marker, holes, date, numberOfHoles } = action.args

    const organizationId: number = yield select(getOrganizationId)

    const payload = {
      providerPlayerIdentifier,
      courseId,
      teeboxId,
      gender,
      marker,
      holes,
      date,
      numberOfHoles,
    }

    const token = yield getToken()
    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.SEND_HCP_ROUNDS(organizationId)}`,
      method: 'put',
      token,
      data: payload,
    })

    defaultOnCompleteCall(onComplete, response)
  } catch (error: any) {
    yield put({
      type: SEND_PLAYER_SCORES_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
    defaultOnCompleteCall(onComplete, null, error)
  }
}

export function* watchHcpRounds() {
  yield all([takeEvery(SEND_PLAYER_SCORES, doSendHcpRounds)])
}
