import { Action } from 'redux'
import { TIME_ZONES_FETCH, TIME_ZONES_FETCH_FAILURE, TIME_ZONES_FETCH_SUCCESS } from './constants'

export interface FetchTimeZonesAction extends Action, APICall {
  type: 'TIME_ZONES_FETCH'
}

export interface FetchTimeZonesSuccessAction extends Action {
  type: 'TIME_ZONES_FETCH_SUCCESS'
  timeZones: TimeZone[]
}

export interface FetchTimeZonesFailureAction extends Action {
  type: 'TIME_ZONES_FETCH_FAILURE'
  error: ErrorPayload
}

export const fetchTimeZones = (onComplete): FetchTimeZonesAction => ({
  type: TIME_ZONES_FETCH,
  onComplete,
})

export const fetchTimeZonesSuccess = (timeZones: TimeZone[]) => ({
  type: TIME_ZONES_FETCH_SUCCESS,
  timeZones,
})

export const fetchTimeZonesFailure = (error: ErrorPayload) => ({
  type: TIME_ZONES_FETCH_FAILURE,
  error,
})
