import React from 'react'
import { get } from 'lodash'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import 'easymde/dist/easymde.min.css'
import { addRoundsToField } from '../../utils/gameFormatUtils'
import { MDEditor } from '@app/scenes/tour/MDEditor'

interface OwnProps {
  fieldName: string
  tournamentSite: TournamentSite
  round?: number
  numberOfRounds?: number
  style?: React.CSSProperties
  maxWidth?: number | string
  error?: boolean
  updateAction: (payload: FieldUpdatePayload) => void
}

type Props = OwnProps & WrappedComponentProps

interface State {
  siteId?: number
}

class ContentBlock extends React.Component<Props, State> {
  readonly state: State = {
    siteId: undefined,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.tournamentSite.id !== state.siteId) {
      return {
        siteId: props.tournamentSite.id,
      }
    }

    return null
  }

  onChange = (value: any, fieldName) => {
    // Save tournament info by round
    if (fieldName === 'tournamentInformationText') {
      const { tournamentSite, numberOfRounds = 1, round = 0 } = this.props
      let info = [...tournamentSite.tournamentInformationText]

      // Info needs to contain same amount of elements as rounds
      if (info.length !== numberOfRounds) {
        info = addRoundsToField(info, numberOfRounds, '')
      }

      // Add new value to info
      info[round] = value
      value = info
    }

    this.props.updateAction({
      fieldName,
      value,
    })
  }

  get contentValue() {
    const { tournamentSite, fieldName, round = 0 } = this.props
    if (fieldName === 'tournamentInformationText') {
      const info = get(tournamentSite, fieldName)
      return info[round] && info[round].length > 0 ? info[round] : ''
    } else {
      return get(tournamentSite, fieldName)
    }
  }

  get innerStyle() {
    return {
      maxWidth: this.props.maxWidth || 1000,
      border: `${this.props.error ? '1px solid #E03333' : ''}`,
    }
  }

  render() {
    const { fieldName, style } = this.props

    return (
      <>
        <div style={style}>
          <div style={this.innerStyle}>
            <MDEditor
              initialValue={this.contentValue}
              saveFn={(value) => {
                this.onChange(value, fieldName)
              }}
            />
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(ContentBlock)
