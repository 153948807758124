import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { rem } from '../../theme/materialUITheme'
import { contentPadding } from '../../components/layout/ContentWrap'

export interface DashboardStyleClasses extends Record<string, string> {
  hero: string
  heroWrapper: string
  heroOrganization: string
  organizationImage: string
  table: string
  notification: string
  lift: string
  amount: string
}

const dashboardStyles = (theme: Theme) =>
  createStyles({
    hero: {
      height: 355,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      display: 'flex',
      alignItems: 'flex-end',
    },
    heroWrapper: {
      height: 192,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      padding: `45px ${contentPadding}px`,
      color: theme.palette.primary.contrastText,
      background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)',
      '@global': {
        h2: {
          fontSize: rem(36),
          color: theme.palette.primary.contrastText,
        },
      },
    },
    heroOrganization: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flex: 1,
    },
    organizationImage: {
      width: 108,
      height: 108,
      borderRadius: '50%',
      marginLeft: 20,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: theme.customPalette.light,
    },
    table: {
      marginTop: 20,
      '@global': {
        td: {
          fontSize: rem(16),
          padding: '10px 0',
          border: 0,
        },
      },
    },
    notification: {
      display: 'flex',
      flexDirection: 'column',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.palette.primary.contrastText,
      padding: 20,
      height: '100%',
      position: 'relative',
      '@global': {
        '> div': {
          margin: 10,
        },
        '> svg': {
          margin: 10,
          fontSize: '2.3rem',
        },
      },
    },
    lift: {
      background: theme.customPalette.background,
      padding: 30,
      marginTop: 70,
      marginBottom: 32,
      '@global': {
        h2: {
          color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.palette.primary.contrastText,
        },
        a: {},
        p: {
          marginTop: 10,
          marginBottom: 15,
          fontSize: rem(14),
          color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.palette.primary.contrastText,
        },
      },
    },
    amount: {
      fontWeight: 'bold',
      fontSize: '120%',
    },
  })

export default dashboardStyles
