import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'
import { rem } from '../../theme/materialUITheme'

const styles = () =>
  createStyles({
    root: {},
    multiTitleWrap: {
      '@global': {
        span: {
          display: 'block',
          fontSize: rem(12),
          paddingBottom: rem(2),
          '&:last-child': {
            fontSize: rem(16),
            paddingBottom: 0,
          },
        },
      },
    },
    highlightLast: {
      '@global': {
        span: {
          '&:last-child': {
            fontWeight: 500,
          },
        },
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  labels: JSX.Element[]
  highlightLast?: boolean
}

export default withStyles(styles)(({ labels, highlightLast, classes }: Props) => (
  <span
    className={classNames({
      [classes.multiTitleWrap]: labels.length > 1,
      [classes.highlightLast]: highlightLast,
    })}
  >
    {labels.map((label, idx) => (
      <span key={idx} style={{ paddingLeft: idx * 8 }}>
        {label}
      </span>
    ))}
  </span>
))
