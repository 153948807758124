import { RefObject, useEffect } from 'react'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'

export const useBracketCardDistances = (
  elementRef: RefObject<HTMLDivElement | null>,
  stage: MatchPlayBracketStage,
  stages: MatchPlayBracketStage[],
) => {
  useEffect(() => {
    if (stage.stage === MatchPlayBracketStages.POOL) {
      return
    }

    const element = elementRef.current as HTMLDivElement
    const nextElement = element?.nextElementSibling as HTMLDivElement
    const parentElement = element?.parentElement as HTMLDivElement

    if (element && nextElement) {
      const distance = nextElement.offsetTop - element.offsetTop - element.offsetHeight
      element.style.setProperty('--distance-to-next', `${distance}px`)
    }

    if (element && parentElement && stage.stage === MatchPlayBracketStages.FINAL) {
      const elementHeight = element.clientHeight
      const distanceP1 = (element.offsetTop - parentElement.offsetTop - elementHeight) / 2
      const distanceP2 = (element.offsetTop - parentElement.offsetTop - elementHeight) / 2
      element.style.setProperty('--final-stage-line-p1-height', `${distanceP1}px`)
      element.style.setProperty('--final-stage-line-p1-top', `-${distanceP1}px`)
      element.style.setProperty('--final-stage-line-p2-height', `${distanceP2}px`)
    }
  }, [elementRef, stages, stage])
  // stages is here only for forcing recalculation after changes in stages (e.g. expand/collapse)
}
