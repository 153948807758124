import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { matchPlayBracketsMap } from '@app/utils/matchPlayBracketUtils'
import { sortByOrderKey } from '@app/utils/sortUtils'
import { useBracketStyles } from './styles'
import { MatchPlayBracketCardPublic } from '@app/components/match-play-brackets/MatchPlayBracketCardPublic'

interface Props {
  bracket: MatchPlayBracket
  players: MatchPlayBracketPlayer[]
}

export const MatchPlayBracketPublic: React.FC<Props> = ({ bracket, players }) => {
  const [stages, setStages] = useState<MatchPlayBracketStage[]>([])
  const classes = useBracketStyles()
  const intl = useIntl()

  useEffect(() => {
    const numberOfPlayers = bracket?.options?.numberOfPlayers || 0
    const playBronzeMatch = bracket?.options?.playBronzeMatch || false
    const stageDescriptions = bracket?.options?.stageDescriptions || []
    let stageNames: BracketStage[] = []
    if (numberOfPlayers > 0) {
      stageNames = Object.values(matchPlayBracketsMap[numberOfPlayers])
    }

    if (playBronzeMatch) {
      stageNames.push(MatchPlayBracketStages.BRONZE_MATCH)
    }

    const bracketStages: MatchPlayBracketStage[] = stageNames.map((stage: BracketStage, i: number) => {
      const stageDescription = stageDescriptions.find(
        (description: MatchPlayBracketStageDescription) => description.stage === stage,
      )
      const stagePlayers = stage === MatchPlayBracketStages.BRONZE_MATCH ? 2 : numberOfPlayers / Math.pow(2, i)
      return {
        stage,
        numberOfPlayers: stagePlayers,
        visible: true,
        description: stageDescription?.description || '',
      }
    })

    setStages(bracketStages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const container = document.getElementById('bracket-container') as HTMLElement
    if (container) {
      const maxPlayers = bracket.options?.numberOfPlayers || 0
      const playerHeight = 100
      container.style.width = window.innerWidth - container?.offsetLeft - 30 + 'px'
      container.style.height = maxPlayers * playerHeight + 'px'
      container.style.minHeight = '1200px'
    }
  }, [bracket])

  const getStagePlayers = (stage: BracketStage): MatchPlayBracketPlayer[] => {
    return players
      .filter((player: MatchPlayBracketPlayer) => player.stage === stage)
      .sort((a, b) => sortByOrderKey(a.order, b.order))
  }

  const getPlayerInStage = (stage: BracketStage, order: number): MatchPlayBracketPlayer => {
    return getStagePlayers(stage).filter((player: MatchPlayBracketPlayer) => player.order === order)[0] || null
  }

  const getBracketClasses = (stage: MatchPlayBracketStage): string => {
    if (stage.stage === MatchPlayBracketStages.FINAL) {
      return `${classes.bracket} ${classes.final}`
    } else if (stage.stage === MatchPlayBracketStages.BRONZE_MATCH) {
      return `${classes.bracket} ${classes.bronzeMatch}`
    }
    return classes.bracket
  }

  const updateStageVisibility = (stage: MatchPlayBracketStage) => () => {
    const wasVisible = stage.visible
    const stageIndex = stages.findIndex((s: MatchPlayBracketStage) => s.stage === stage.stage)

    const updatedStages = stages.map((s: MatchPlayBracketStage, i: number) => {
      const clickedStage = s.stage === stage.stage
      const shouldMakeVisible = !wasVisible && !s.visible && i > stageIndex
      if (clickedStage || shouldMakeVisible) {
        return { ...s, visible: !s.visible }
      }
      return s
    })
    setStages(updatedStages)
    const brackets = document.getElementById('bracket-container') as HTMLElement
    brackets.scrollLeft += wasVisible ? 310 : -310
  }

  const maybeShowExpandCollapseIcon = (stage: MatchPlayBracketStage): React.ReactElement | undefined => {
    const stageIndex = stages.findIndex((s: MatchPlayBracketStage) => s.stage === stage.stage)
    const previousStagesIsVisible = stages[stageIndex - 1]?.visible
    const isAtLeastQuarterFinal = stage.stage.toString().startsWith('ROUND') === false
    if (isAtLeastQuarterFinal || previousStagesIsVisible) {
      return
    }
    const Icon = stage.visible ? ExpandLessIcon : ExpandMoreIcon
    return (
      <div className={classes.expandCollapse} onClick={updateStageVisibility(stage)}>
        <Icon />
      </div>
    )
  }

  const getStages = (): MatchPlayBracketStage[] => {
    return stages.slice().filter((s: MatchPlayBracketStage) => s.stage !== MatchPlayBracketStages.BRONZE_MATCH)
  }

  const renderStage = (stage: MatchPlayBracketStage): React.ReactElement => {
    return (
      <div className={getBracketClasses(stage)} key={stage.stage} id={stage.stage}>
        {stage.visible &&
          Array.from({ length: stage.numberOfPlayers }, (_, index: number) => {
            const player = getPlayerInStage(stage.stage, index + 1)
            const key = `${stage.stage}-${index}`
            return (
              <MatchPlayBracketCardPublic
                key={key}
                player={player}
                players={players}
                index={index}
                stage={stage}
                stages={stages}
              />
            )
          })}
        {maybeRenderBronzeMatchBracket(stage)}
      </div>
    )
  }

  const maybeRenderBronzeMatchBracket = (stage: MatchPlayBracketStage): React.ReactElement | undefined => {
    const bronzeMatchStage = stages.find((s: MatchPlayBracketStage) => s.stage === MatchPlayBracketStages.BRONZE_MATCH)
    if (stage.stage !== MatchPlayBracketStages.FINAL || !bronzeMatchStage) {
      return
    }

    return renderStage(bronzeMatchStage)
  }

  return (
    <div className={classes.bracketContainer} id="bracket-container">
      <div className={classes.tournamentHeaders}>
        {stages
          .filter((stage: MatchPlayBracketStage) => stage.stage !== MatchPlayBracketStages.BRONZE_MATCH)
          .map((stage: MatchPlayBracketStage) => (
            <div>
              <h3>
                {intl.formatMessage({ id: `matchPlayBracket.${stage.stage}` })}
                {maybeShowExpandCollapseIcon(stage)}
              </h3>
              <span>{stage.description}</span>
            </div>
          ))}
      </div>

      <div className={classes.tournamentBrackets}>
        {getStages().map((stage: MatchPlayBracketStage) => renderStage(stage))}
      </div>
    </div>
  )
}
