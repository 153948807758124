import { MenuItem } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import SelectBox from '../ui/SelectBox'
import { getHourList, getMinuteList } from '@app/utils/dates'

interface SimpleSelectTimePickerProps {
  margin?: 'normal' | 'none' | 'dense' | undefined
  units: OrganizationUnits
  onChange: (hours: number, minutes: number, abbreviation: string) => void
  hoursWithLocalization: string // i.e. '10' or '10 AM' or '10 PM'
  minutes: string
  disabled?: boolean
}

export const SimpleSelectTimePicker = ({
  hoursWithLocalization,
  minutes,
  margin,
  disabled,
  units,
  onChange,
}: SimpleSelectTimePickerProps) => {
  const getHours = (hours: string): number => {
    const hPart = hours.split(' ')
    return parseInt(hPart[0] ?? hPart)
  }

  const getMinutes = (minutes: string): number => {
    return parseInt(minutes)
  }

  const getAbbreviation = (hours: string): string => {
    const hPart = hours.split(' ')
    return hPart[1] ?? ''
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <SelectBox
          margin={margin}
          style={{ width: 100 }}
          id="hours"
          name="hours"
          value={hoursWithLocalization}
          disabled={disabled}
          inputElement={OutlinedInput}
          onChange={(event) => {
            const { target } = event
            hoursWithLocalization = target.value
            onChange(getHours(target.value), getMinutes(minutes), getAbbreviation(target.value))
          }}
        >
          {getHourList(units).map((val: string) => {
            return (
              <MenuItem value={val} key={val}>
                {val}
              </MenuItem>
            )
          })}
        </SelectBox>

        <SelectBox
          margin={margin}
          style={{ width: 68, marginLeft: 9 }}
          id="minutes"
          name="minutes"
          value={minutes}
          disabled={disabled}
          inputElement={OutlinedInput}
          onChange={({ target }) => {
            minutes = target.value
            onChange(getHours(hoursWithLocalization), getMinutes(target.value), getAbbreviation(hoursWithLocalization))
          }}
        >
          {getMinuteList().map((val: string) => {
            return (
              <MenuItem value={val} key={val}>
                {val}
              </MenuItem>
            )
          })}
        </SelectBox>
      </div>
    </>
  )
}
