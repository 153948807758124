import ReactDOM from 'react-dom/client'
import App from './App'
import * as serviceWorker from './serviceWorker'
// import { addLocaleData } from 'react-intl';
// import * as fi from 'react-intl/locale-data/fi';
// import * as en from 'react-intl/locale-data/en';
import 'react-image-crop/dist/ReactCrop.css'

// addLocaleData([...fi, ...en]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)

// create "dev frame" for non-production environments
;((env) => {
  if (!env || env === 'production') {
    return
  }
  const frame = document.createElement('div')
  frame.id = 'dev-frame'
  frame.style.cssText = `
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    border: 4px solid #e9675b;
    pointer-events: none;
  `
  const label = document.createElement('div')
  label.innerText = env
  label.style.cssText = `
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 6px 14px;
	background-color: #e9675b;
	color: black;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 18px;
	font-weight: bold;
  `
  frame.appendChild(label)
  document.body.appendChild(frame)
})(process.env.REACT_APP_ENV || process.env.NODE_ENV)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
