import React from 'react'
import { Select, MenuItem, OutlinedInput, FormLabel, Divider, TextField, Button } from '@mui/material'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import BorderButton from '../ui/BorderButton'
import UploadedImage from '../ui/UploadedImage'
import FilePicker from '../ui/FilePicker'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { OverlayLoader } from '../ui/OverlayLoader'
import Typography from '@mui/material/Typography'
import { CloudUpload } from '@mui/icons-material'
import { enqueueNotification } from '../../store/notifications/actions'
import { ChromePicker } from 'react-color'
import InfoTooltip from '../ui/InfoTooltip'
import { ColorTheme, TournamentTypes } from '../../store/api/enums/tournamentEnums'
import { DEFAULT_TOURNAMENT_SITE_BRANDING_COLOR, validatePrimaryColor } from '@utils/tournamentUtils'
import { DraggableImages } from '@components/ui/DraggableImages'
import { setTournamentDirty } from '@app/store/api/slices/configSlice'
import { RootState } from '@app/store'
import { selectTournamentFeedPictures, tournamentSiteApi } from '@app/store/api/endpoints/tournamentSiteApi'
import { updateTournamentSiteField } from '@app/store/api/thunks/tournamentSiteThunks'
import { TournamentSiteActionButtons } from '@app/scenes/tournament/tournament-site/TournamentSiteComponents'

const styles = () =>
  createStyles({
    textField: {
      maxWidth: 99,
      '& .MuiFormHelperText-root': {
        width: 230,
      },
    },
    feedPicturesDesc: {
      marginTop: 20,
      width: '90%',
    },
  })

interface OwnProps {
  showAppearance?: boolean
  showAds?: boolean
  showHero?: boolean
  showSponsorLogos?: boolean
  showSummaryPictures?: boolean
  showHeroSummary?: boolean
  tournament: TournamentState
  tournamentSite: TournamentSite
}

interface StateProps {
  feedPictures: TournamentImage[]
}

interface DispatchProps {
  uploadTournamentImage(payload: UploadTournamentImagePayload): void
  deleteTournamentImage(payload: DeleteTournamentImagePayload): void
  getTournamenSite(id: number): void
  enqueueNotification(error: any, variant?: string): void
  updateTournamentSiteField: (payload: FieldUpdatePayload) => void
  fetchFeedPictures: (tournamentId: number) => void
  deleteFeedPicture: (payload: DeleteFeedPicturePayload) => void
  sortFeedPictures: (payload: SortPicturesPayload) => void
  sortSummaryPictures: (payload: SortPicturesPayload) => void
  fetchFeedPictureList: (tournamentId: number) => void
  setTournamentDirty(dirty: boolean): void
}

type Props = OwnProps & StateProps & DispatchProps & WrappedComponentProps & WithStyles<typeof styles>

enum ImageType {
  feed = 'feed',
  summary = 'summary',
}

interface State {
  loading: boolean
  showColorPicker: boolean
  isColorCodeValid: boolean
}

class TournamentCustomization extends React.Component<Props, State> {
  readonly state: State = {
    loading: false,
    showColorPicker: false,
    isColorCodeValid: false,
  }

  componentDidMount() {
    const { tournament } = this.props
    tournament.id && this.props.fetchFeedPictureList(tournament.id)
  }

  componentDidUpdate(prevProps) {
    const { tournament } = this.props
    if (prevProps.tournament.id !== tournament.id) {
      tournament.id && this.props.fetchFeedPictureList(tournament.id)
    }
  }

  render() {
    const {
      showAppearance = true,
      showAds = true,
      showHero = true,
      showSponsorLogos = true,
      showSummaryPictures = true,
      showHeroSummary = false,
    } = this.props

    return (
      <>
        <div>
          {showAppearance && <>{this._renderAppearanceSection()}</>}
          {showHero && <>{this._renderSiteHeroSection()}</>}
          {showHeroSummary && <>{this._renderSummaryHeroSection()}</>}
          {showAds && <>{this._renderAdsSection()}</>}
          {showSponsorLogos && <>{this._renderSponsorLogosSection()}</>}
          {showSummaryPictures && this._renderSummaryPicturesSection()}
        </div>
        <OverlayLoader visible={this.state.loading} />
      </>
    )
  }

  get _images() {
    return this.props.tournamentSite.images
  }

  private _doUploadImage = (files, idx, id, name) => {
    const { uploadTournamentImage } = this.props

    if (idx > files.length - 1) {
      this._handleLoadingDone()
      return
    }

    const file = files[idx]
    uploadTournamentImage({
      tournamentId: id,
      file,
      fileType: name as any,
      onSuccess: () => this._doUploadImage(files, ++idx, id, name),
    })
  }

  public _onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      tournament: { id },
    } = this.props
    const { name, files } = e.target

    this.setState({ loading: true })

    if (files && files.length > 0 && id) {
      if (files[0] && files[0].size < 8000000) {
        this._doUploadImage(files, 0, id, name)
      } else {
        this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.tooLargeFile' }), 'error')
        this.setState({ loading: false })
      }
    }
  }

  public _handleLoadingDone = () => {
    const { tournament } = this.props
    this.setState({ loading: false })
    tournament.id && this.props.getTournamenSite(tournament.id)
  }

  private _renderUploadButton = ({
    id,
    name,
    label,
    multiple = false,
    disabled = false,
  }: {
    name: string
    id: string
    label: React.ReactNode
    multiple?: boolean
    disabled?: boolean
  }) => (
    <FilePicker
      name={name}
      id={id}
      onChange={this._onFileInputChange}
      accept="image/*"
      multiple={multiple}
      disabled={disabled}
    >
      <BorderButton
        buttonProps={{
          component: 'span',
          disabled,
        }}
      >
        {label}
        <CloudUpload style={{ marginLeft: 10 }} />
      </BorderButton>
    </FilePicker>
  )

  private checkPickerColor = () => this.props.tournamentSite.primaryColor || DEFAULT_TOURNAMENT_SITE_BRANDING_COLOR

  private _renderAppearanceSection = () => {
    const { classes, tournamentSite, tournament } = this.props
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 20 }}>
          <FormattedMessageWrapper id={'tournament.tournamentLogo'} />
        </Typography>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={4}>
            {this._renderUploadButton({
              id: 'tournament-logo',
              name: 'logo',
              label: <FormattedMessageWrapper id={'buttons.uploadLogo'} />,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteLogoInfo'} />
            </Typography>
          </Grid>
          <Grid item={true} xs={3}>
            <div style={{ maxWidth: '200px' }}>
              <UploadedImage image={this._getImage('logoImage')} onDelete={this._deleteImage} />
            </div>
          </Grid>
          <Grid item xs={5}>
            {tournament.id && <TournamentSiteActionButtons id={tournament.id} />}
          </Grid>
          <Grid item={true} xs={12}>
            <FormLabel>
              <FormattedMessageWrapper id={'tournament.tournamentSiteTheme'} />
            </FormLabel>
            <div>
              <Select
                value={this.props.tournamentSite.colorTheme}
                input={<OutlinedInput name="colorTheme" />}
                onChange={(event) => {
                  const value = event.target.value ? event.target.value : ColorTheme.LIGHT
                  this._updateSiteSettings('colorTheme', value)
                }}
              >
                <MenuItem value={ColorTheme.LIGHT}>
                  <FormattedMessageWrapper id={'tournament.tournamentSiteThemeLight'} />
                </MenuItem>
                <MenuItem value={ColorTheme.DARK}>
                  <FormattedMessageWrapper id={'tournament.tournamentSiteThemeDark'} />
                </MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item={true} xs={12}>
            <FormLabel>
              <FormattedMessageWrapper id={'tournament.tournamentSitePrimaryColor'} />
            </FormLabel>

            <div style={{ position: 'relative' }}>
              <TextField
                className={classes.textField}
                error={tournamentSite.primaryColor ? !validatePrimaryColor(tournamentSite.primaryColor) : false}
                helperText={
                  tournamentSite.primaryColor &&
                  !validatePrimaryColor(tournamentSite.primaryColor) && (
                    <FormattedMessageWrapper id={'tournament.invalidColor'} />
                  )
                }
                style={{ maxWidth: 99 }}
                variant="outlined"
                value={this.checkPickerColor()}
                onChange={(e) => this._updateSiteSettings('primaryColor', e.target.value)}
                onClick={() =>
                  this.setState((prevState) => {
                    return { showColorPicker: !prevState.showColorPicker }
                  })
                }
                inputProps={{
                  style: {
                    width: 70,
                    borderRadius: 4,
                    backgroundColor: this.checkPickerColor(),
                  },
                }}
              />
              {this.state.showColorPicker && (
                <div style={{ paddingTop: 10, position: 'absolute', zIndex: 100 }}>
                  <div
                    onClick={() => this.setState({ showColorPicker: false })}
                    style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
                  />
                  <ChromePicker
                    color={this.checkPickerColor()}
                    disableAlpha
                    onChangeComplete={(color) => this._updateSiteSettings('primaryColor', color.hex)}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        <Divider />
      </>
    )
  }

  private _updateSiteSettings = (name: string, color: string) => {
    const pattern = /#[A-Za-z0-9]{6}/
    this.setState({
      isColorCodeValid: !pattern.test(color),
    })
    this.props.updateTournamentSiteField({
      fieldName: name,
      value: color ? color : DEFAULT_TOURNAMENT_SITE_BRANDING_COLOR,
    })
  }

  private _renderAdsSection = () => {
    const adAmount = this._images && this._images.ads ? this._images.ads.length : 0
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 20 }}>
          <FormattedMessageWrapper id={'tournament.tournamentSiteAds'} />
        </Typography>

        <Grid container={true} spacing={2}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'tournament-ad',
              name: 'ad',
              label: <FormattedMessageWrapper id={'buttons.uploadAd'} />,
              multiple: true,
              disabled: adAmount >= 4,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteAdsInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container={true} spacing={2}>
              {this._images &&
                this._images.ads &&
                this._images.ads.map((ad) => (
                  <Grid item={true} xs={3} key={ad.id as any}>
                    <UploadedImage image={ad} onDelete={this._deleteImage} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>

        <Divider />
      </>
    )
  }

  public _deleteFeedImage = ({ id }: TournamentImage) => {
    const { deleteFeedPicture, fetchFeedPictureList, tournament } = this.props
    this.setState({ loading: true }, () => {
      id &&
        deleteFeedPicture({
          tournamentId: tournament.id,
          imageId: id,
          onSuccess: () => {
            this.setState({ loading: false })
            tournament.id && fetchFeedPictureList(tournament.id)
          },
        })
    })
  }

  public _deleteImage = (image: TournamentImage) => {
    const { deleteTournamentImage, tournament } = this.props
    const { id } = image

    this.setState({ loading: true }, () => {
      if (id && tournament && tournament.id) {
        deleteTournamentImage({
          imageId: id,
          tournamentId: tournament.id,
          onSuccess: this._handleLoadingDone,
        })
      }
    })
  }

  public _getImage = (imageName: string) => {
    if (this._images && Object.prototype.hasOwnProperty.call(this._images, imageName)) {
      return this._images[imageName]
    }

    return { id: null, url: '' }
  }

  private _renderSiteHeroSection = () => {
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 20 }}>
          <FormattedMessageWrapper id={'tournament.tournamentSiteHeroImage'} />
        </Typography>

        <Grid container={true} spacing={2}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'tournament-hero-image',
              name: 'hero',
              label: <FormattedMessageWrapper id={'buttons.uploadHero'} />,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteSiteHeroInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {/* Show image once it has been selected */}
            <UploadedImage image={this._getImage('heroImage')} onDelete={this._deleteImage} />
          </Grid>
        </Grid>

        <Divider />
      </>
    )
  }

  private _renderSponsorLogosSection = () => {
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20, marginBottom: 20 }}>
          <FormattedMessageWrapper id={'tournament.sponsorLogos'} />
        </Typography>

        <Grid container={true} spacing={2}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'tournament-sponsor-logo',
              name: 'sponsorLogo',
              label: <FormattedMessageWrapper id={'buttons.uploadLogos'} />,
              multiple: true,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteSponsorLogosInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container={true} spacing={2}>
              {this._images &&
                this._images.sponsorLogos &&
                this._images.sponsorLogos.map((sponsorLogo) => (
                  <Grid item={true} xs={3} key={sponsorLogo.id as any}>
                    <UploadedImage image={sponsorLogo} onDelete={this._deleteImage} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>

        <Divider />
      </>
    )
  }

  private _renderSummaryPicturesSection = () => {
    const { tournament } = this.props
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20 }}>
          <FormattedMessageWrapper id={'tournament.siteSummaryPictures'} />
          <InfoTooltip
            text={<FormattedMessageWrapper id="tournament.summaryPicturesInfo" />}
            style={{ marginLeft: 10 }}
          />
        </Typography>

        <Grid container={true} spacing={2} style={{ paddingBottom: 20 }}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'tournament-summary-pictures',
              name: 'summaryPicture',
              label: <FormattedMessageWrapper id={'buttons.uploadPictures'} />,
              multiple: true,
            })}
            <br />
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteSummaryPicturesInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Grid container={true} direction="column">
              <DraggableImages
                images={this._images && this._images.summaryPictures ? this._images.summaryPictures : []}
                onDelete={this._deleteImage}
                onDragEnd={this.onDragEnd}
              />
            </Grid>
          </Grid>
        </Grid>
        {tournament.tournamentType !== TournamentTypes.weekly && this._renderFeedPicturesSection()}
      </>
    )
  }

  private _renderSummaryHeroSection = () => {
    return (
      <>
        <Typography variant="h4" style={{ marginTop: 20 }}>
          <FormattedMessageWrapper id={'tournament.tournamentSiteSummaryHeroImage'} />
          <InfoTooltip text={<FormattedMessageWrapper id="tournament.summaryHeroInfo" />} style={{ marginLeft: 10 }} />
        </Typography>

        <Grid container={true} spacing={2}>
          <Grid item xs={4} style={{ marginTop: 10 }}>
            {this._renderUploadButton({
              id: 'tournament-summary-hero-image',
              name: 'summaryHero',
              label: <FormattedMessageWrapper id={'buttons.uploadSummaryHero'} />,
            })}
            <Typography variant={'body1'} style={{ marginTop: 20 }}>
              <FormattedMessageWrapper id={'tournament.siteSiteHeroInfo'} />
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {/* Show image once it has been selected */}
            <UploadedImage image={this._getImage('summaryHeroImage')} onDelete={this._deleteImage} />
          </Grid>
        </Grid>
      </>
    )
  }

  private onDragEnd = (movedImageId: number, imageType: string, order: number) => {
    this.props.setTournamentDirty(true)
    if (imageType === ImageType.feed) {
      this.props.sortFeedPictures({ tournamentId: this.props.tournament.id, order, imageId: movedImageId })
      return
    }
    this.props.sortSummaryPictures({ tournamentId: this.props.tournament.id, order, imageId: movedImageId })
  }

  private handleOnClick = () => {
    const { tournament } = this.props
    tournament.id && this.props.fetchFeedPictures(tournament.id)
  }

  private isFetchDisabled = () => {
    const { tournament } = this.props
    return !tournament.rounds.some((round) => {
      return round?.status?.isConfigured
    })
  }

  private _renderFeedPicturesSection = () => {
    const { classes } = this.props
    return (
      <>
        <Typography variant="h4">
          <FormattedMessageWrapper id="tournament.feedPicturesTitle" />
        </Typography>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Grid container direction="column">
              <Grid item style={{ marginTop: 20 }}>
                <Button variant="contained" disabled={this.isFetchDisabled()} onClick={this.handleOnClick}>
                  <FormattedMessageWrapper id="tournament.feedPicturesFetchBtn" />
                </Button>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.feedPicturesDesc}>
                  <FormattedMessageWrapper
                    id={this.isFetchDisabled() ? 'tournament.feedPicturesDisabled' : 'tournament.feedPicturesDesc'}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="column">
              <DraggableImages
                images={this.props.feedPictures}
                onDelete={this._deleteFeedImage}
                onDragEnd={this.onDragEnd}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    feedPictures: selectTournamentFeedPictures(state),
  }),
  {
    uploadTournamentImage: tournamentSiteApi.endpoints.uploadTournamentImage.initiate,
    deleteTournamentImage: tournamentSiteApi.endpoints.deleteTournamentImage.initiate,
    getTournamenSite: tournamentSiteApi.endpoints.getTournamentSite.initiate,
    enqueueNotification,
    updateTournamentSiteField,
    fetchFeedPictures: tournamentSiteApi.endpoints.fetchTournamentFeedPictures.initiate,
    deleteFeedPicture: tournamentSiteApi.endpoints.deleteFeedPicture.initiate,
    sortFeedPictures: tournamentSiteApi.endpoints.sortFeedPictures.initiate,
    sortSummaryPictures: tournamentSiteApi.endpoints.sortAdPictures.initiate,
    fetchFeedPictureList: tournamentSiteApi.endpoints.getTournamentFeedPictureList.initiate,
    setTournamentDirty,
  },
)(withStyles(styles)(injectIntl(TournamentCustomization as any)))
