import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import TournamentCustomization from '../../tournament/TournamentCustomization'
import DialogContent from '@mui/material/DialogContent'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'

interface OwnProps {
  open?: boolean
  summaryMode?: boolean
  onClose?(): void
}

interface StateProps {
  tournamentSite: TournamentSite
  tournament: TournamentState
}

type Props = OwnProps & StateProps

class TournamentCustomizationDialog extends React.Component<Props> {
  render() {
    const { open = false, summaryMode = false, tournament, tournamentSite } = this.props

    if (open && !tournament) {
      console.warn('trying to open tournament customization without a tournament: Tournament prop')
      // return null;
    }

    return (
      <Dialog
        maxWidth={'lg'}
        open={open}
        aria-labelledby={'tournament-customization-dialog-title'}
        scroll={'body'}
        fullWidth
      >
        <BaseDialogTitle
          id={'tournament-customization-dialog-title'}
          title={<FormattedMessageWrapper id={'tournament.customizationModalTitle'} />}
          subtitle={tournament.name}
          onClose={() => this._handleClose()}
        />
        <DialogContent>
          <TournamentCustomization
            tournament={tournament}
            tournamentSite={tournamentSite}
            showAppearance={!summaryMode}
            showAds={!summaryMode}
            showHero={!summaryMode}
            showSponsorLogos={!summaryMode}
            showSummaryPictures={summaryMode}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this._handleClose}>
            <FormattedMessageWrapper id={'buttons.close'} />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private _handleClose = (): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
}

export default connect<StateProps, {}, OwnProps, RootState>((state) => ({
  tournamentSite: selectTournamentSite(state),
  tournament: selectTournament(state),
}))(TournamentCustomizationDialog)
