import { ConfirmDialogProps, confirmable, createConfirmation } from 'react-confirm'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import materialUITheme from '../../../theme/materialUITheme'
import { FormControl, FormControlLabel, PaletteMode, Radio, RadioGroup } from '@mui/material'
import BaseDialogTitle from '../ui/BaseDialogTitle'

export interface OptionsProps {
  cancelText?: string | JSX.Element | any
  okText?: string | JSX.Element | any
  removeExisting?: string | JSX.Element | any
  switchGroups?: string | JSX.Element | any
  addTeetimeInterval?: string | JSX.Element | any
}

export interface Props {
  options: OptionsProps
  title?: string | JSX.Element | any
}

const StartGroupConflictDialog: React.FC<ConfirmDialogProps<Props, string>> = ({
  show,
  proceed,
  dismiss,
  options,
  title,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {
      currentTarget: { elements },
    } = e
    const action = (elements.namedItem('groupAction') as HTMLInputElement).value
    proceed(action)
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={materialUITheme((localStorage.getItem('theme') || 'light') as PaletteMode)}>
        <Dialog
          open={show}
          onClose={(event, reason) => reason !== 'backdropClick' && dismiss()}
          maxWidth={'sm'}
          fullWidth
        >
          <form onSubmit={handleSubmit}>
            <BaseDialogTitle id={'dialog-title'} title={title} />
            <DialogContent>
              <FormControl component="fieldset">
                <RadioGroup name="groupAction" defaultValue={'removeExisting'}>
                  <FormControlLabel value="removeExisting" control={<Radio />} label={options.removeExisting} />
                  <FormControlLabel value="switchGroups" control={<Radio />} label={options.switchGroups} />
                  <FormControlLabel value="addTeetimeInterval" control={<Radio />} label={options.addTeetimeInterval} />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              {Boolean(options.cancelText) && <Button onClick={dismiss}>{options.cancelText}</Button>}
              {Boolean(options.okText) && (
                <Button type={'submit'} color="secondary" autoFocus={true}>
                  {options.okText}
                </Button>
              )}
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
const ConfirmableDialog = confirmable(StartGroupConflictDialog)
const confirmation = createConfirmation(ConfirmableDialog)

interface ConfirmProps {
  title?: string
  options?: OptionsProps
}

export const startGroupConflictDialog = ({ title, options = {} }: ConfirmProps) => {
  return confirmation({
    options,
    title,
  })
}
