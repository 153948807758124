import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { rem } from '@app/theme/materialUITheme'
import { Contest, ContestOptionItem } from './Contest'
import { updateContests } from '@app/store/api/thunks/tournamentThunks'
import { RootState } from '@app/store'
import { selectSelectedRoundCourse } from '@app/store/api/slices/clubsAndCoursesSlice'

export interface ContestsProps {
  round: Round
}

interface DispatchProps {
  updateContests: (payload: ContestsPayload) => void
}

interface StateProps {
  courseHoles?: CourseHole[]
}

type Props = ContestsProps & WrappedComponentProps & DispatchProps & StateProps & WithStyles<typeof styles>

enum ContestType {
  closestToPin = 'closestToPin',
  longestDrive = 'longestDrive',
  straightestDrive = 'straightestDrive',
}

const contestTypes = Object.keys(ContestType) as ContestType[]

const styles = () =>
  createStyles({
    contestWrapper: {
      maxWidth: rem(220),
    },
  })

class ContestsComponent extends React.Component<Props> {
  private filterHoleByNumber = (holeNumber: number): boolean => {
    const { round } = this.props
    switch (round.holes) {
      case 'f9':
        return holeNumber <= 9
      case 'b9':
        return holeNumber > 9
      default:
        return true
    }
  }

  private getOptions = (contestType: ContestType): ContestOptionItem[] => {
    const { round, courseHoles } = this.props
    if (!courseHoles || !round.holes || !round.contestOptions) {
      return []
    }

    let includedHoles = courseHoles
    let disabledHoles: number[] = []

    switch (contestType) {
      case ContestType.closestToPin:
        includedHoles = courseHoles.filter((hole) => hole.par === 3 && this.filterHoleByNumber(hole.holeNumber))
        return includedHoles.map((hole) => ({
          holeNumber: hole.holeNumber,
          disabled: false,
        }))

      case ContestType.longestDrive:
        includedHoles = courseHoles.filter((hole) => hole.par > 3 && this.filterHoleByNumber(hole.holeNumber))
        disabledHoles = round.contestOptions.straightestDrive
        return includedHoles.map((hole) => ({
          holeNumber: hole.holeNumber,
          disabled: disabledHoles.includes(hole.holeNumber),
        }))

      case ContestType.straightestDrive:
        includedHoles = courseHoles.filter((hole) => hole.par > 3 && this.filterHoleByNumber(hole.holeNumber))
        disabledHoles = round.contestOptions.longestDrive
        return includedHoles.map((hole) => ({
          holeNumber: hole.holeNumber,
          disabled: disabledHoles.includes(hole.holeNumber),
        }))
      default:
        return []
    }
  }

  private getMeasureValue = (contestType: ContestType): boolean => {
    const { round } = this.props
    const { contestOptions } = round
    switch (contestType) {
      case ContestType.longestDrive:
        return contestOptions.measureResultsLongestDrive
      case ContestType.straightestDrive:
        return contestOptions.measureResultsStraightestDrive
      default:
        return false
    }
  }

  private onMeasureChange = (contestType: ContestType, value: boolean): void => {
    let fieldName = ''
    switch (contestType) {
      case ContestType.longestDrive:
        fieldName = 'measureResultsLongestDrive'
        break
      case ContestType.straightestDrive:
        fieldName = 'measureResultsStraightestDrive'
        break
      default:
        return
    }
    this.props.updateContests({
      index: 0,
      fieldName: fieldName,
      value: value,
    })
  }

  private onContestValueChange = (contestType: ContestType, value: number): void => {
    const { round } = this.props
    let contestValue: number[] = []

    if (round.contestOptions[contestType].includes(value)) {
      contestValue = round.contestOptions[contestType].filter((val) => val !== value)
    } else {
      contestValue = [...round.contestOptions[contestType], value]
    }

    this.props.updateContests({
      index: 0,
      fieldName: contestType,
      value: contestValue,
    })
  }

  public render() {
    const { classes, round } = this.props
    const { contestOptions } = round
    return (
      <Grid container>
        {contestTypes.map((contestType) => (
          <Grid item xs={4} key={contestType} className={classes.contestWrapper}>
            <Contest
              onItemClick={(val) => this.onContestValueChange(contestType, val)}
              titleId={`tournament.${contestType}`}
              value={contestOptions[contestType]}
              options={this.getOptions(contestType)}
              measureValue={this.getMeasureValue(contestType)}
              isMeasurable={contestType !== ContestType.closestToPin}
              onMeasureChange={(val) => this.onMeasureChange(contestType, val)}
            />
          </Grid>
        ))}
      </Grid>
    )
  }
}

const UnconnectedContests = injectIntl(withStyles(styles)(ContestsComponent))
export const Contests = connect<any, DispatchProps, any, RootState>(
  (state) => ({
    courseHoles: selectSelectedRoundCourse(state)?.holes || [],
  }),
  {
    updateContests,
  },
)(UnconnectedContests)
