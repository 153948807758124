import React from 'react'
import { connect } from 'react-redux'
import { EditPlayerDialog } from './EditPlayerDialog'
import { PlayerPoolEditContext, PlayerTeamPoolEditAction } from '../enums'
import { PlayerTarget, TeamTarget } from '@app/store/api/enums/tournamentPlayersEnums'
import { RootState } from '@app/store'
import { tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { tournamentStartListGroupApi } from '@store/api/endpoints/tournamentStartListGroupApi'

interface OwnProps {
  roundIndex?: number
  children: (args: PlayerDialogChildrenArgs) => any
  editContext?: PlayerPoolEditContext
}

interface StateProps {
  tournament: TournamentState
  divisions: DivisionState[]
}

interface DispatchProps {
  deletePlayer: (payload: DeletePlayerPayload) => void
  deletePlayers: (payload: DeletePlayersPayload) => void
  addPlayerToPool: (payload: AddPlayerToPoolPayload) => void
  addTeamToPool: (payload: AddTeamToPoolPayload) => void
  updatePlayer: (payload: UpdatePlayerPayload) => void
  updateStartListPlayer: (payload: EditRoundPlayerPayload) => void
}

type Props = OwnProps & StateProps & DispatchProps

interface State {
  dialogPlayer?: TournamentPlayer
  dialogOpen: boolean
}

export interface PlayerDialogChildrenArgs {
  openPlayerDialog: (player: TournamentPlayer) => () => void
  onClosePlayerDialog: () => void
}

const initialState: State = {
  dialogOpen: false,
  dialogPlayer: undefined,
}

class UnconnectedEditPlayerDialogWrapper extends React.Component<Props, State> {
  readonly state = initialState

  render() {
    const { children } = this.props
    return (
      <>
        {this.renderDialog()}
        {children({
          openPlayerDialog: this.openDialog,
          onClosePlayerDialog: this.handleDialogClose,
        })}
      </>
    )
  }

  private renderDialog = () => {
    const { tournament, roundIndex = 0, editContext = PlayerPoolEditContext.POOL, divisions } = this.props
    const { dialogPlayer, dialogOpen } = this.state

    return (
      <EditPlayerDialog
        editContext={editContext}
        open={dialogOpen}
        player={dialogPlayer}
        roundIndex={roundIndex}
        tournament={tournament}
        divisions={divisions}
        onClose={this.handleDialogClose}
      />
    )
  }

  private openDialog = (player: TournamentPlayer): (() => void) => {
    return () => {
      this.setState({
        dialogOpen: true,
        dialogPlayer: player,
      })
    }
  }

  private handleDialogClose = (action?: string, player?: TournamentPlayerUpdatePayload) => {
    const { tournament } = this.props
    const tournamentId = tournament.id
    const { dialogPlayer } = this.state
    if (dialogPlayer) {
      switch (action) {
        case PlayerTeamPoolEditAction.REMOVE:
          this.props.deletePlayer({ tournamentId, playerId: dialogPlayer.userId })
          break
        case PlayerTeamPoolEditAction.MOVE_TO_RESERVED:
          this.props.addPlayerToPool({
            tournamentId,
            body: { playerId: dialogPlayer.userId, target: PlayerTarget.RESERVE },
          })
          break
        case PlayerTeamPoolEditAction.CHANGE_TEAM_LIST:
          this.props.addTeamToPool({ tournamentId, teamId: dialogPlayer.team?.id || 0, targetPool: TeamTarget.POOL })
          break
        case PlayerTeamPoolEditAction.MOVE_TO_PLAYER_POOL:
          this.props.addPlayerToPool({
            tournamentId,
            body: { playerId: dialogPlayer.userId, target: PlayerTarget.POOL },
          })
          break
        case PlayerTeamPoolEditAction.DELETE:
          this.props.deletePlayers({ tournamentId, playerIds: [dialogPlayer.id] })
          break
        case PlayerTeamPoolEditAction.UPDATE_PLAYER_INFO:
          // changed info in parameter
          if (player && tournament.id) {
            if (PlayerPoolEditContext.GROUP === this.props.editContext) {
              this.props.updateStartListPlayer({
                tournamentId: tournament.id,
                roundId: player.roundId || 0,
                playerId: dialogPlayer.id,
                handicap: Number(player.handicap),
                teeBoxId: player.teeboxId || 0,
              })
            } else {
              this.props.updatePlayer({ tournamentId, playerId: dialogPlayer.userId, body: player })
            }
          }
          break
      }
    }
    const closedState = {
      dialogOpen: false,
      dialogPlayer: undefined,
    }

    this.setState(closedState)
  }
}

export const EditPlayerDialogWrapper = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    tournament: selectTournament(store),
    divisions: store.divisionsReducer.divisions,
  }),
  {
    deletePlayer: tournamentPlayersApi.endpoints.deletePlayer.initiate,
    addPlayerToPool: tournamentPlayersApi.endpoints.addPlayerToPool.initiate,
    updatePlayer: tournamentPlayersApi.endpoints.updatePlayer.initiate,
    deletePlayers: tournamentPlayersApi.endpoints.deletePlayers.initiate,
    addTeamToPool: tournamentTeamsApi.endpoints.addTeamToPool.initiate,
    updateStartListPlayer: tournamentStartListGroupApi.endpoints.updateStartListPlayer.initiate,
  },
)(UnconnectedEditPlayerDialogWrapper)
