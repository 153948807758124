import React from 'react'
import { connect } from 'react-redux'
import GameFormatAdditionalInput from './GameFormatAdditionalInput'
import { Grid, SelectChangeEvent } from '@mui/material'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

interface OwnProps {
  disabled?: boolean
  roundItemIndex: number
  gameFormatOptions: GameFormatOptions
  currentGameFormat?: GameFormatItem
  onChange: (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
  onSelectChange: (event: SelectChangeEvent<any>) => void
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface StateProps {
  roundHoleCount?: string | number
  gameFormatAdditionalOptions: GameFormatAdditionalOptions
  tournamentState: TournamentState
}

type Props = OwnProps & StateProps

class UnconnectedGameFormatAdditionalInputs extends React.Component<Props> {
  public render() {
    const options = this.currentAdditionalOptions

    if (!options) {
      return null
    }

    return this._handleFields(options)
  }

  private _handleFields(options: GameFormatAdditionalOptionSet | GameFormatAdditionalOptionSet[]) {
    const { gameFormatOptions, onChange, onSelectChange, onCheckboxChange, roundHoleCount = 18 } = this.props

    if (Array.isArray(options)) {
      return (
        <Grid container spacing={2} style={{ padding: '10px 0' }}>
          {options.map((option, index) => (
            <GameFormatAdditionalInput
              gameFormatOptions={gameFormatOptions}
              fieldData={option}
              key={index}
              holeCount={roundHoleCount}
              onChange={onChange}
              onSelectChange={onSelectChange}
              onCheckboxChange={onCheckboxChange}
              disabled={this.props.disabled}
            />
          ))}
        </Grid>
      )
    }

    return (
      <Grid container spacing={2} style={{ padding: '10px 0' }}>
        <GameFormatAdditionalInput
          gameFormatOptions={gameFormatOptions}
          fieldData={options}
          holeCount={roundHoleCount}
          onChange={onChange}
          onSelectChange={onSelectChange}
          onCheckboxChange={onCheckboxChange}
          disabled={this.props.disabled}
        />
      </Grid>
    )
  }

  private get currentAdditionalOptions() {
    const { currentGameFormat, gameFormatAdditionalOptions } = this.props

    if (!currentGameFormat || !currentGameFormat.additionalOptions) {
      return null
    }

    const { additionalOptions } = currentGameFormat

    const optionSet = gameFormatAdditionalOptions[additionalOptions]

    if (Array.isArray(optionSet)) {
      return optionSet.filter((set) => !set.label.includes('gameFormats.defaultTeamSize'))
    } else {
      return optionSet.label.includes('defaultTeamSize') ? [] : optionSet
    }
  }
}

const GameFormatAdditionalInputs = connect<StateProps, {}, OwnProps, RootState>((state, ownProps) => ({
  gameFormatAdditionalOptions: state.gameFormatsReducer.gameFormatAdditionalOptions,
  roundHoleCount: selectTournament(state).rounds[ownProps.roundItemIndex]?.holes || 0,
  tournamentState: selectTournament(state),
}))(UnconnectedGameFormatAdditionalInputs)

export default GameFormatAdditionalInputs
