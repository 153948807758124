import { Switch as MUISwitch, SwitchProps, Theme, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'

import { PlainCard, PlainCardProps } from '../plain-card'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
export interface SettingCardProps extends PlainCardProps {
  titleId: string
  descriptionId: string
  switchProps?: SwitchProps
  tooltipTitle?: React.ReactElement<any> | false
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  switchBase: {
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
    '&$disabled + $track': {
      backgroundColor: '#cdcdcd',
    },
    '&$disabled .MuiSwitch-thumb': {
      backgroundColor: '#666',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  },
  switchBaseDisabled: {
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
    '&$disabled + $track': {
      backgroundColor: '#178669',
    },
    '&$disabled .MuiSwitch-thumb': {
      backgroundColor: '#e4e4e4',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    },
  },
  track: {
    backgroundColor: '#cdcdcd',
    opacity: '1 !important',
    boxShadow: '0 1px 3px 0 #777',
  },
  thumb: {
    boxShadow: '0 0 3px 0 #777',
    backgroundColor: '#fff',
  },
  checked: {},
  disabled: {},
  title: {
    display: 'block',
    fontFamily: theme.fonts.primary,
    fontSize: rem(20),
    fontWeight: 800,
    paddingBottom: theme.spacing(1),
    color: theme.palette.common.white,
  },
  top: {
    display: 'flex',
    height: '67%',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '33%',
  },
  infoCol: {
    flex: 0.9,
    padding: theme.spacing(3),
    justifyContent: 'center',
    textAlign: 'left',
    color: theme.palette.common.white,

    '& p': {
      textAlign: 'left',
    },
  },
  switchCol: {
    flex: 0.1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const SettingCard: React.FC<SettingCardProps> = ({
  titleId,
  descriptionId,
  switchProps,
  tooltipTitle,
  children,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <PlainCard {...rest}>
      <div className={classes.top}>
        <div className={classes.infoCol}>
          <Typography variant="caption" className={classes.title}>
            <FormattedMessageWrapper id={titleId} />
          </Typography>
          <p>
            <FormattedMessageWrapper id={descriptionId} />
          </p>
        </div>
        <div className={classes.switchCol}>
          <Tooltip
            disableFocusListener={!switchProps?.disabled}
            disableHoverListener={!switchProps?.disabled}
            disableTouchListener={!switchProps?.disabled}
            title={tooltipTitle || ''}
          >
            <MUISwitch
              size="medium"
              color="primary"
              classes={{
                root: classes.root,
                switchBase:
                  switchProps && switchProps.disabled && switchProps.checked
                    ? classes.switchBaseDisabled
                    : classes.switchBase,
                track: classes.track,
                thumb: classes.thumb,
                checked: classes.checked,
                disabled: classes.disabled,
              }}
              {...switchProps}
            />
          </Tooltip>
        </div>
      </div>
      <div className={classes.bottom}>{children}</div>
    </PlainCard>
  )
}

export { SettingCard }
