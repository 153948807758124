import React from 'react'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import ButtonBase from '@mui/material/ButtonBase'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    buttonBase: {
      backgroundColor: 'transparent',
      padding: '6px 12px',
    },
    label: {
      color: theme.palette.primary.main,
      letterSpacing: 0,
      display: 'flex',
      flexDirection: 'row',
      textDecoration: 'underline',
      alignItems: 'center',
      '&:hover, &:active': {
        textDecoration: 'none',
      },
    },
  })

interface Props extends WithStyles<typeof styles> {
  children?: any
  component?: any
  onClick?: any
  style?: React.CSSProperties
  labelStyle?: React.CSSProperties
  disabled?: boolean
  color?: string
}

class TextButton extends React.Component<Props> {
  render(): React.ReactNode {
    const { children, classes, component, onClick, style, labelStyle, disabled, color } = this.props

    return (
      <ButtonBase
        color={color}
        style={style}
        className={classes.buttonBase}
        component={component as any}
        onClick={onClick}
        disabled={disabled}
        disableRipple={true}
      >
        <span className={classes.label} style={labelStyle}>
          {children}
        </span>
      </ButtonBase>
    )
  }
}

export default withStyles(styles)(TextButton)
