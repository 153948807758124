import { VeryDenseTableCell } from '@app/components/tables/tableComponents'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Grid, IconButton, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { useGetCurrenciesQuery } from '@app/store/api/endpoints/paymentsApi'
import { currencyFractionsToMains } from '@app/utils/paymentUtils'

export interface OrganizationProductsListProps {
  products: OrganizationProduct[]
  editFn(product: OrganizationProduct): void
  deleteFn(product: OrganizationProduct): void
}

export const OrganizationProductsList: React.FC<OrganizationProductsListProps> = ({ products, editFn, deleteFn }) => {
  const { data: currencies = [] } = useGetCurrenciesQuery()

  const renderProductRows = (product: OrganizationProduct, index: number) => {
    const currency = currencies.find((currency) => currency.code === product.defaultCurrency)
    const defaultPrice = currencyFractionsToMains(product.defaultPrice, currency?.minorDivider || 100)

    return (
      <TableRow key={`OrganizationProducts_${index}`}>
        <VeryDenseTableCell>{product.name}</VeryDenseTableCell>
        <VeryDenseTableCell>{product.description}</VeryDenseTableCell>
        <VeryDenseTableCell>
          {defaultPrice} <span style={{ color: 'grey' }}>{product.defaultCurrency}</span>
        </VeryDenseTableCell>
        <VeryDenseTableCell>
          {product.defaultVat} <span style={{ color: 'grey' }}>%</span>
        </VeryDenseTableCell>
        <VeryDenseTableCell>{product.created}</VeryDenseTableCell>
        <VeryDenseTableCell style={{ textAlign: 'right' }}>
          <IconButton onClick={() => editFn(product)} size="large">
            <Edit />
          </IconButton>
          <IconButton onClick={() => deleteFn(product)} size="large">
            <Delete />
          </IconButton>
        </VeryDenseTableCell>
      </TableRow>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" style={{ margin: '2rem 0' }}>
          <FormattedMessageWrapper id="payments.organizationProducts" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="payments.productsDetails.productName" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="payments.productsDetails.productDescription" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="payments.productsDetails.productDefaultPrice" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="payments.productsDetails.productTax" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="payments.productsDetails.productCreated" />
              </VeryDenseTableCell>
              <VeryDenseTableCell />
            </TableRow>
          </TableHead>
          <TableBody>{products.map(renderProductRows)}</TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
