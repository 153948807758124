import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { FormControl, FormLabel, Select, TextField, MenuItem, Checkbox, ListItemText, Button } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import ContentWrap from '../../components/layout/ContentWrap'
import { getNextUrl } from '../../utils/nextUrlHelper'
import Grid from '@mui/material/Grid'
import SectionTitle from '../../components/ui/SectionTitle'
import OutlinedInput from '@mui/material/OutlinedInput'
import { scrollToTop } from '../../components/ui'
import HeadHelmet from '../../components/layout/HeadHelmet'
import get from 'lodash/get'
import { enqueueNotification } from '../../store/notifications/actions'
import BorderButton from '../../components/ui/BorderButton'
import { ArrowRightAlt } from '@mui/icons-material'
import { saveTour, updateTourField } from '../../store/tourAndRanking/actions'
import InfoTooltip from '../../components/ui/InfoTooltip'
import { MDEditor } from './MDEditor'
import { RootState } from '@app/store'

interface StateProps {
  tour: Tour
  rankings: Ranking[]
}

interface DispatchProps {
  saveTour(onComplete?: () => void): void
  updateTourField: (payload: FieldUpdatePayload) => void
  enqueueNotification(error: any, variant?: string): void
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps

class UnroutedSetup extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    scrollToTop()
  }

  public render() {
    const { name, info, infoTitle, rankingIds } = this.props.tour

    return (
      <>
        <HeadHelmet titleId={'progress.tourSetup'} />

        <ContentWrap>
          <SectionTitle>
            <FormattedMessageWrapper id="tourAndRanking.basicDetails" />
            <InfoTooltip
              text={<FormattedMessageWrapper id="tourAndRanking.basicDetailsInfo" />}
              style={{ marginLeft: 10 }}
            />
          </SectionTitle>

          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <FormLabel>
                <FormattedMessageWrapper id="tourAndRanking.name" />
              </FormLabel>
              <TextField
                name="name"
                type="text"
                id="name"
                variant="outlined"
                onChange={this._onChange}
                value={name}
                fullWidth
              />
            </Grid>

            <Grid item={true} xs={6}>
              <FormLabel style={{ display: 'block', margin: '4px 0' }}>
                <FormattedMessageWrapper id="tourAndRanking.rankings" />
              </FormLabel>
              <FormControl variant="filled" style={{ width: '100%' }}>
                <Select
                  labelId="rankingIds"
                  name="rankingIds"
                  id="rankingIds"
                  multiple
                  value={rankingIds}
                  onChange={this._onSelectChange}
                  input={<OutlinedInput name="rankingIds" id="rankingIds" fullWidth />}
                  SelectDisplayProps={{
                    style: { padding: '10px 24px 10px 14px', minHeight: 30 },
                  }}
                  renderValue={(selected) => (
                    <>
                      {(selected as number[]).map((value) => (
                        <Button
                          key={value}
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{ minWidth: 'auto', marginRight: 5 }}
                        >
                          <span>{this._getRankingName(value)}</span>
                        </Button>
                      ))}
                    </>
                  )}
                >
                  {this.props.rankings
                    .filter((r) => r.active)
                    .map((r) => (
                      <MenuItem key={r.name} value={r.id}>
                        <Checkbox color="primary" checked={rankingIds && rankingIds.indexOf(r.id ? r.id : 0) > -1} />
                        <ListItemText primary={r.name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel style={{ display: 'block', margin: '4px 0' }}>
                <FormattedMessageWrapper id="tourAndRanking.infoTextTitle" />
              </FormLabel>
              <TextField
                name="infoTitle"
                type="text"
                id="name"
                variant="outlined"
                onChange={this._onChange}
                value={infoTitle}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel style={{ display: 'block', margin: '4px 0' }}>
                <FormattedMessageWrapper id="tourAndRanking.infoText" />
              </FormLabel>
              <MDEditor initialValue={info} saveFn={this._onChangeInfo} />
            </Grid>
          </Grid>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 60 }}>
            <BorderButton
              buttonProps={{
                onClick: this._saveTour,
                size: 'large',
                style: { marginRight: 10 },
              }}
            >
              <FormattedMessageWrapper id="buttons.save" />
            </BorderButton>

            <BorderButton
              filled
              buttonProps={{
                onClick: this.clickNext,
              }}
            >
              <FormattedMessageWrapper id={'progress.branding'} /> <ArrowRightAlt style={{ marginLeft: 5 }} />
            </BorderButton>
          </div>
        </ContentWrap>
      </>
    )
  }

  private _getRankingName = (rankingId: number) => {
    const ranking = this.props.rankings && this.props.rankings.find((r) => r.id === rankingId)
    return ranking ? ranking.name : ''
  }

  public clickNext = () => {
    if (this.props.tour.name === '') {
      this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.noTourName' }), 'error')
      return
    }

    const nextUrl = getNextUrl(this.props.location.pathname, 'branding')
    this.props.navigate(nextUrl)
    this.props.saveTour(() => {
      scrollToTop()
    })
  }

  public _saveTour = () => {
    if (this.props.tour.name === '') {
      this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.noTourName' }), 'error')
      return
    }
    this.props.saveTour()
  }

  private _onChangeInfo = (value: string) => {
    this.props.updateTourField({
      fieldName: 'info',
      value,
    })
  }

  private _onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.props.updateTourField({
      fieldName: e.currentTarget.name,
      value: e.currentTarget.value,
    })
  }

  private _onSelectChange = (event) => {
    const fieldName = get(event, 'target.name')
    if (fieldName) {
      this.props.updateTourField({
        fieldName,
        value: event.target.value,
      })
    }
  }
}

const RoutedSetup = injectIntl(withRouter(UnroutedSetup))

const Setup = connect<StateProps, DispatchProps, {}, RootState>(
  (state) => ({
    tour: state.tourAndRankingReducer.tour,
    rankings: state.tourAndRankingReducer.rankings,
  }),
  {
    saveTour,
    updateTourField,
    enqueueNotification,
  },
)(RoutedSetup)
export default Setup
