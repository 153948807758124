export const setProtocol = (url: string) => {
  if (!url) {
    return url
  }

  if (url.search(/^http[s]?:\/\//) === -1) {
    url = 'http://' + url
  }
  return url.replace(/\s+/g, '')
}
