import { useState } from 'react'
import { useSelector } from 'react-redux'
import ContentWrap from '../../components/layout/ContentWrap'
import { Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { Organizations } from './Organizations'
import OrganizationApplications from './OrganizationApplications'
import { isRoleInfoProviderAdmin } from '../../utils/authUtils'
import TopBar from '../../components/layout/TopBar'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { OrganizationTournaments } from '../organizationManagement/OrganizationTournaments'
import { InternalRoute } from '@config/index'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  tableWrap: {
    marginTop: 28,
  },
}))

enum TabEnums {
  organization = 'organization',
  application = 'application',
  tournaments = 'tournaments',
}

const OrganizationManagement = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<TabEnums>(TabEnums.organization)
  const { roleInfo } = useSelector((store: RootState) => store.authenticationReducer)

  if (!isRoleInfoProviderAdmin(roleInfo)) {
    return null
  }

  const renderContest = () => {
    switch (activeTab) {
      case TabEnums.organization:
        return <Organizations />
      case TabEnums.application:
        return <OrganizationApplications />
      case TabEnums.tournaments:
        return <OrganizationTournaments />
      default:
        return <Organizations />
    }
  }

  const onTabChange = (_: any, newValue: TabEnums) => {
    if (newValue !== activeTab) {
      navigate(InternalRoute.ORGANIZATIONS)
    }
    setActiveTab(newValue)
  }

  return (
    <>
      <TopBar titleId="navigation.organizations" />
      <ContentWrap>
        <Tabs value={activeTab} onChange={onTabChange} indicatorColor="primary" textColor="primary">
          <Tab label={<FormattedMessageWrapper id="tournament.organizations" />} value={TabEnums.organization} />
          <Tab label={<FormattedMessageWrapper id="tournament.applications" />} value={TabEnums.application} />
          <Tab label={'Tournaments'} value={TabEnums.tournaments} />
        </Tabs>

        <div className={classes.tableWrap}>{renderContest()}</div>
      </ContentWrap>
    </>
  )
  // }
}

export default OrganizationManagement
