import React from 'react'
import {
  Theme,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  TableContainer,
  DialogContent,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  Checkbox,
  IconButton,
  TextField,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { withStyles, WithStyles } from '@mui/styles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { rem } from '@app/theme/materialUITheme'
import { connect } from 'react-redux'
import { flag } from '@components/layout/LanguageSwitcher'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import { CheckCircle } from '@mui/icons-material'
import { maxNoteLength } from '@utils/tournamentUtils'
import { EmailPreviewDialog } from '@app/scenes/tournament/groups-and-start-list/email-preview-dialog'
import { localizationTable } from '@app/localization'
import { RootState } from '@app/store'
import { selectTournamentPlayers } from '@app/store/api/endpoints/tournamentPlayersApi'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        width: rem(700),
      },
    },
    dialogTitle: {
      color: theme.customPalette.darkGray,
    },
    dialogContent: {
      marginBottom: rem(36),
    },
    table: {
      maxHeight: rem(300),
      marginTop: rem(34),
    },
    tableBody: {
      marginBottom: rem(36),
    },
    firstContainer: {
      justifyContent: 'space-around',
    },
    flagButtonsContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    flagTitle: {
      display: 'flex',
      justifyContent: 'center',
    },
    flagButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    flagImage: {
      width: rem(40),
    },
    circle: {
      position: 'absolute',
      zIndex: 999,
      top: rem(0),
      right: rem(-5),
    },
    addNoteTitle: {
      marginBottom: rem(10),
    },
    buttonContainer: {
      justifyContent: 'space-between',
    },
    prevAndSendButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    previewButton: {
      marginRight: rem(15),
    },
  })

interface OwnProps {
  open: boolean
  textAreaTitle: React.ReactNode
  previewEmailBody?: string
  htmlBase64?: string
  showStartTimes?: boolean
  listItems: EmailPlayersList[]
  sendEmailFn: (language: string, playerIds: number[], extraText: string) => void
  fetchPreviewEmailFn: (language: string) => void
  onClose: () => void
}

interface SelectedPlayer {
  id: number
  selected: boolean
}

interface EmailPlayersList {
  id: number
  name: string
  startTime?: string
  teeNumber?: number
  email?: string
  hasEmail: boolean
}

interface StateProps {
  players: TournamentPlayer[]
  startList: StartListRound[]
  languages: LanguageOption[]
  units: OrganizationUnits
  appLanguage: string
}

interface State {
  note: string
  selectedPlayers?: SelectedPlayer[]
  sortBy: string
  sortDirection: 'asc' | 'desc'
  selectedLang: string
  noteLength: number
  previewOpen: boolean
  playersList: EmailPlayersList[]
}

const initialState: State = {
  note: '',
  selectedPlayers: undefined,
  sortBy: 'name',
  sortDirection: 'desc',
  selectedLang: '',
  noteLength: 0,
  previewOpen: false,
  playersList: [],
}

type Props = OwnProps & StateProps & WithStyles<typeof styles>

class SendEmailDialogComponent extends React.Component<Props, State> {
  readonly state: State = initialState

  componentDidMount() {
    const { appLanguage, listItems } = this.props
    const { selectedPlayers } = this.state

    !selectedPlayers && this.setSelectedPlayers(listItems)

    this.setState({
      playersList: listItems,
      selectedLang: appLanguage,
    })
  }

  /**
   * Returns first value from fi-FI type language format
   * @param lang
   * @returns string
   */
  private getShortLangugage = (lang: string): string => {
    const conversionTable = localizationTable
    return conversionTable[lang] || 'en'
  }

  private handleClose = () => {
    const { onClose } = this.props
    onClose && onClose()
  }

  private handleChange = (state: string, value: string | boolean, maxNoteLength?: number) => {
    if (state === 'note') {
      if (typeof value === 'string' && maxNoteLength && value.length <= maxNoteLength) {
        this.setState({
          ...this.state,
          [state]: value,
          noteLength: value.length,
        })
      }
      return
    }

    this.setState({
      ...this.state,
      [state]: value,
    })
  }

  /**
   * Handles checkbox changes, updates list with selected players
   * @param event
   * @param id
   */
  private handleCbChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { selectedPlayers } = this.state

    if (selectedPlayers && selectedPlayers.map((player) => player.id).includes(id)) {
      const index = selectedPlayers.findIndex((elem) => elem.id === id)
      selectedPlayers.splice(index, 1)

      this.setState({
        selectedPlayers,
      })
      return
    }

    if (selectedPlayers) {
      this.setState({
        selectedPlayers: [...selectedPlayers, { id: id, selected: event.currentTarget.checked }],
      })
    }
  }

  private unSelectAll = () => {
    this.setState({
      selectedPlayers: [],
    })
  }

  private selectAll = () => {
    const { listItems } = this.props
    this.setSelectedPlayers(listItems)
  }

  private handleSelectOrUnselectAll = () => {
    const fn = this.state.selectedPlayers && this.state.selectedPlayers.length > 0 ? this.unSelectAll : this.selectAll
    fn()
  }

  private selectOrUnselectAllTranslation = () =>
    this.state.selectedPlayers && this.state.selectedPlayers.length > 0 ? 'buttons.unselectAll' : 'buttons.selectAll'

  private handleSort = (sortBy: string, sortDirection: 'asc' | 'desc') => {
    const { listItems } = this.props
    this.setState({
      sortBy,
      sortDirection,
      playersList: listItems.reverse(),
    })
  }

  private handleSendEmail = () => {
    const { sendEmailFn } = this.props
    const { selectedLang, selectedPlayers, note } = this.state

    const allPlayerIds = selectedPlayers && selectedPlayers.map((player) => player.id)

    if (selectedLang && allPlayerIds) {
      sendEmailFn(this.getShortLangugage(selectedLang), allPlayerIds, note)
    }
  }

  private handlePreview = () => {
    this.setState({ previewOpen: !this.state.previewOpen })
  }

  private renderLanguageButtons = () => {
    const { classes, languages } = this.props
    const { selectedLang } = this.state

    return languages.map(({ code }: LanguageOption, index: number) => (
      <IconButton
        key={index}
        className={classes.flagButton}
        onClick={() => this.handleChange('selectedLang', code)}
        size="large"
      >
        <img src={flag(code)} className={classes.flagImage} alt="" />
        {code === selectedLang && <CheckCircle color="primary" className={classes.circle} />}
      </IconButton>
    ))
  }

  private isCheckboxChecked = (id: number, selectedPlayers?: SelectedPlayer[]) =>
    selectedPlayers && !!selectedPlayers.find((player) => player.id === id)?.selected

  private setSelectedPlayers = (emailPlayers: EmailPlayersList[]) => {
    this.setState({
      selectedPlayers: emailPlayers
        .filter((player) => player.hasEmail)
        .map((players) => ({ id: players.id, selected: true })),
    })
  }

  render() {
    const { open = false, classes, textAreaTitle, showStartTimes = false, listItems } = this.props
    const { note, selectedPlayers, noteLength, previewOpen, selectedLang } = this.state

    return (
      <Dialog fullWidth open={open} maxWidth="md" className={classes.dialog}>
        <BaseDialogTitle
          title={
            <Typography variant="h3" className={classes.dialogTitle}>
              <FormattedMessageWrapper id="tournament.sendEmailDialogTitle" />
            </Typography>
          }
          onClose={this.handleClose}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={6} className={classes.firstContainer}>
            <Grid item>
              <Grid item className={classes.flagTitle}>
                <Typography variant="subtitle2">
                  <FormattedMessageWrapper id="tournament.chooseLanguage" />
                </Typography>
              </Grid>
              <Grid item>
                <div className={classes.flagButtonsContainer}>{this.renderLanguageButtons()}</div>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.addNoteTitle}>
                {textAreaTitle}
              </Typography>
              <TextField
                variant="outlined"
                multiline={true}
                minRows={3}
                fullWidth
                value={note}
                onChange={({ target }) => this.handleChange('note', target.value, maxNoteLength)}
              />
              <Typography
                variant="caption"
                className={classes.dialogTitle}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {noteLength}/{maxNoteLength}
              </Typography>
            </Grid>
          </Grid>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <VeryDenseTableCell padding="checkbox" size="small"></VeryDenseTableCell>
                  <VeryDenseTableCell size="small" sortDirection={this.state.sortDirection}>
                    <TableSortLabel
                      direction={this.state.sortDirection}
                      onClick={() =>
                        this.handleSort(this.state.sortBy, this.state.sortDirection === 'asc' ? 'desc' : 'asc')
                      }
                    >
                      <FormattedMessageWrapper id="tournament.name" />
                    </TableSortLabel>
                  </VeryDenseTableCell>
                  {showStartTimes && (
                    <>
                      <VeryDenseTableCell size="small">
                        <FormattedMessageWrapper id="tournament.startTime" />
                      </VeryDenseTableCell>
                      <VeryDenseTableCell size="small">
                        <FormattedMessageWrapper id="tournament.startTee" />
                      </VeryDenseTableCell>
                    </>
                  )}
                  <VeryDenseTableCell size="small">Email</VeryDenseTableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {listItems.map(({ id, name, email, hasEmail, teeNumber, startTime }: EmailPlayersList) => (
                  <TableRow key={id} selected={!hasEmail}>
                    <VeryDenseTableCell size="small">
                      {hasEmail && (
                        <Checkbox
                          color="primary"
                          checked={this.isCheckboxChecked(id, selectedPlayers)}
                          onChange={(event) => this.handleCbChange(event, id)}
                        />
                      )}
                    </VeryDenseTableCell>
                    <VeryDenseTableCell size="small">{name}</VeryDenseTableCell>
                    {showStartTimes && (
                      <>
                        <VeryDenseTableCell size="small">{startTime}</VeryDenseTableCell>
                        <VeryDenseTableCell size="small">{teeNumber}</VeryDenseTableCell>
                      </>
                    )}
                    <VeryDenseTableCell size="small">
                      {hasEmail ? email : <FormattedMessageWrapper id="tournament.noEmail" />}
                    </VeryDenseTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={5} className={classes.buttonContainer}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={this.handleSelectOrUnselectAll}>
                <FormattedMessageWrapper id={this.selectOrUnselectAllTranslation()} /> (
                {selectedPlayers ? selectedPlayers.length : 0})
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.prevAndSendButtonContainer}>
              <Button variant="outlined" color="primary" className={classes.previewButton} onClick={this.handlePreview}>
                <FormattedMessageWrapper id="buttons.preview" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={this.handleSendEmail}
                disabled={!selectedPlayers?.length}
              >
                <FormattedMessageWrapper id="buttons.send" />
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
        <EmailPreviewDialog
          open={previewOpen}
          language={this.getShortLangugage(selectedLang)}
          onClose={this.handlePreview}
          fetchPreviewEmailFn={this.props.fetchPreviewEmailFn}
          emailBody={this.props.previewEmailBody || ''}
          htmlBase64={this.props.htmlBase64 || ''}
        />
      </Dialog>
    )
  }
}

export const SendEmailDialog = connect<StateProps, {}, OwnProps, RootState>((store) => ({
  players: selectTournamentPlayers(store).data?.players || [],
  startList: selectTournamentStartLists(store).rounds,
  languages: store.localeReducer.availableLanguages,
  units: store.authenticationReducer.units,
  appLanguage: store.localeReducer.appLanguage.code,
}))(withStyles(styles)(SendEmailDialogComponent as any))
