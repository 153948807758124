import React from 'react'
import { FormControl, FormLabel, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { countriesApi, selectCountries } from '@app/store/api/endpoints/countriesApi'
import { RootState } from '@app/store'

interface OwnProps {
  disabled?: boolean
  raised?: boolean
  error?: boolean
  required?: boolean
  showLabel?: boolean
  showInnerLabel?: boolean
  countryId?: number
  handleStatesFetch?: boolean
  enableAllOption?: boolean
  style?: React.CSSProperties
  margin?: 'normal' | 'none' | 'dense' | undefined
  onChange?: (event: SelectChangeEvent<any>) => void
  variant?: 'standard' | 'outlined' | 'filled'
}

interface StateProps {
  countries: Country[]
  countriesLoading: boolean
}

interface DispatchProps {
  fetchCountries(): void
  fetchCountryStates(countryId: number): void
}

type Props = OwnProps & StateProps & DispatchProps

class CountryPicker extends React.Component<Props> {
  componentDidMount(): void {
    const { countries = [], countryId, fetchCountryStates } = this.props

    if (!countries?.length) {
      this.props.fetchCountries()
    }

    if (countryId) {
      fetchCountryStates(countryId)
    }
  }

  render() {
    const {
      error,
      showLabel = false,
      showInnerLabel = true,
      countryId = '',
      required,
      enableAllOption = false,
      disabled = false,
      margin = 'none',
      variant = 'outlined',
    } = this.props

    return (
      <>
        {showLabel && (
          <FormLabel required={required} error={error}>
            <FormattedMessageWrapper id="tournament.chooseCountry" />
          </FormLabel>
        )}
        <FormControl variant={variant} margin={margin} fullWidth={true} required={required} error={error}>
          {showInnerLabel && (
            <InputLabel id="tournament.chooseCountry.inner">
              <FormattedMessageWrapper id="tournament.country" />
            </InputLabel>
          )}
          <Select
            name="country-picker"
            id="country-picker"
            labelId="tournament.chooseCountry.inner"
            disabled={disabled}
            error={error}
            required={required}
            value={countryId}
            onChange={this._handleOnChange}
            displayEmpty={enableAllOption}
          >
            {enableAllOption && (
              <MenuItem value="0" selected={countryId === 0}>
                <FormattedMessageWrapper id="tournament.selectCountry" />
              </MenuItem>
            )}
            {this._renderCountryItems()}
          </Select>
        </FormControl>
      </>
    )
  }

  public _handleOnChange = (event) => {
    const { onChange, handleStatesFetch = false, fetchCountryStates } = this.props

    if (handleStatesFetch) {
      const countryId = parseInt(event.target.value, 10)
      fetchCountryStates(countryId)
    }

    if (onChange) {
      onChange(event)
    }
  }

  private _renderCountryItems = () => {
    const countries = this.props.countries || []
    return countries.map((country) => (
      <MenuItem key={country.id} value={country.id}>
        {country.name}
      </MenuItem>
    ))
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state): StateProps => ({
    countries: selectCountries(state).data || [],
    countriesLoading: selectCountries(state).isLoading,
  }),
  {
    fetchCountries: countriesApi.endpoints.getCountries.initiate,
    fetchCountryStates: countriesApi.endpoints.getCountryStates.initiate,
  },
)(CountryPicker)
