import { FetchGameFormatsAction, FetchGameFormatsFailureAction, FetchGameFormatsSuccessAction } from './actions'
import { GAME_FORMATS_FETCH, GAME_FORMATS_FETCH_FAILURE, GAME_FORMATS_FETCH_SUCCESS } from './constants'

const initialState: GameFormatsState = {
  gameFormats: [],
  gameFormatAdditionalOptions: {},
  gameFormatPrimaryIdMap: {},
  defaultPerPlayerHandicapPercentages: [20, 15, 10, 5],
  error: null,
  loading: false,
}

type GameFormatActions = FetchGameFormatsAction | FetchGameFormatsFailureAction | FetchGameFormatsSuccessAction

const gameFormatsReducer = (state: GameFormatsState = initialState, action: GameFormatActions) => {
  switch (action.type) {
    case GAME_FORMATS_FETCH:
      return {
        ...state,
        loading: true,
      }
    case GAME_FORMATS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        gameFormats: action.gameFormats,
        gameFormatAdditionalOptions: action.gameFormatAdditionalOptions,
        defaultPerPlayerHandicapPercentages: action.defaultPerPlayerHandicapPercentages,
      }
    case GAME_FORMATS_FETCH_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    default:
      return state
  }
}

export { gameFormatsReducer }
