export enum PlayerPoolEditContext {
  POOL = 'pool',
  TEAM = 'team',
  RESERVED = 'reserved',
  REMOVED = 'removed',
  GROUP = 'group',
  GROUP_POOL = 'groupPool',
  UNASSIGNED = 'unassigned',
}

export enum PlayerTeamPoolEditAction {
  MOVE_TO_PLAYER_POOL = 'moveToPlayerPool',
  MOVE_TO_RESERVED = 'moveToReserved',
  CHANGE_TEAM_LIST = 'changeTeamList',
  REMOVE = 'remove',
  DELETE = 'delete',
  UPDATE_PLAYER_INFO = 'updatePlayerInfo',
}

export enum PlayerListStatus {
  POOL = 'POOL',
  RESERVED = 'RESERVED',
  REMOVED = 'REMOVED',
  ARCHIVED = 'ARCHIVED',
  UNPAID = 'UNPAID',
}

export enum PlayerHcpSource {
  GGB = 'GGB',
  FGU = 'FGU',
  SGF = 'SGF',
  MANUAL = 'MANUAL',
  UNCHANGED = 'UNCHANGED',
}
