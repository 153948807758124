import { setProtocol } from '@app/utils/domainUtils'
import { ColorTheme, EntryMethods, TournamentTypes } from '../enums/tournamentEnums'
import { formatDateToIsoWithTimeZone, removeTimeZoneFromDate } from '@app/utils/dates'
import { selectClub } from '../endpoints/clubsAndCoursesApi'
import { store } from '@app/store'
import { selectClubsAndCoursesConfig } from '../slices/configSlice'
import { RootState } from '@app/store'
import { isWithinInterval } from 'date-fns'

export const processTournamentSiteResponse = (response: TournamentSite): TournamentSite => {
  return {
    ...response,
    /**
     * We need to remove the time zone from time values,
     * otherwise browser will automatically adjust the time
     * to match user's current timezone.
     *
     * We'll put time zone back to time values when saving settings information.
     */
    registrationStartsTime:
      response.registrationStartsTime && new Date(removeTimeZoneFromDate(response.registrationStartsTime)),
    registrationEndsTime:
      response.registrationEndsTime && new Date(removeTimeZoneFromDate(response.registrationEndsTime)),
    registrationCancellingStartsTime:
      response.registrationCancellingStartsTime &&
      new Date(removeTimeZoneFromDate(response.registrationCancellingStartsTime)),
    registrationCancellingEndsTime:
      response.registrationCancellingEndsTime &&
      new Date(removeTimeZoneFromDate(response.registrationCancellingEndsTime)),
  }
}

export const processUpdateSitePayload = (payload: TournamentSite) => {
  const state: RootState = store.getState()
  const clubId: number = selectClubsAndCoursesConfig(state).selectedClubId
  const club: Club | undefined = selectClub(state, clubId)

  return {
    ...payload,
    registrationModuleEnabled: payload.registrationModuleEnabled || false,
    calendarPublished: payload.calendarPublished || false,
    tournamentInvitationText: payload.tournamentInvitationText || '',
    termsAndConditionsUrl: setProtocol(payload.termsAndConditionsUrl || ''),
    primaryColor: payload.primaryColor && /^#[0-9A-F]{3,6}$/i.test(payload.primaryColor) ? payload.primaryColor : null,
    colorTheme: payload.colorTheme || ColorTheme.LIGHT,
    maximumPlayerAmount:
      payload.entryMethod === EntryMethods.INDIVIDUAL
        ? payload.maximumPlayerAmount && Number(payload.maximumPlayerAmount)
        : null,
    maximumTeamAmount:
      payload.entryMethod === EntryMethods.TEAM ? payload.maximumTeamAmount && Number(payload.maximumTeamAmount) : null,
    registrationStartsTime:
      payload.registrationStartsTime && formatDateToIsoWithTimeZone(payload.registrationStartsTime, club?.timeZone),
    registrationEndsTime:
      payload.registrationEndsTime && formatDateToIsoWithTimeZone(payload.registrationEndsTime, club?.timeZone),
    registrationCancellingStartsTime:
      payload.registrationCancellingStartsTime &&
      formatDateToIsoWithTimeZone(payload.registrationCancellingStartsTime, club?.timeZone),
    registrationCancellingEndsTime:
      payload.registrationCancellingEndsTime &&
      formatDateToIsoWithTimeZone(payload.registrationCancellingEndsTime, club?.timeZone),
  }
}

export const processTournamentImageUpload = (payload: UploadTournamentImagePayload): FormData => {
  const data = new FormData()
  data.append('file', payload.file)
  data.append('type', payload.fileType)
  if (payload.extraInfo) {
    data.append('extraInfo', JSON.stringify(payload.extraInfo))
  }
  return data
}

export const processTournamentFileUpload = (file: File): FormData => {
  const data = new FormData()
  data.append('file', file, file.name)
  return data
}

export const isSignUpEnabled = (tournament: TournamentState, tournamentSite: TournamentSite): boolean => {
  if (!tournamentSite.onlineEntriesEnabled) {
    return false
  }

  if (tournament.tournamentType === TournamentTypes.weekly) {
    return isRegistrationOrCancelRegistrationOpen(tournamentSite)
  }

  const isScoringEnabled = tournament.rounds.some((round) => round.status?.isScoringDisabled === false)
  const isRoundCompleted = tournament.rounds.some((round) => round.status?.isCompleted === true)
  return !isScoringEnabled && !isRoundCompleted && isRegistrationOrCancelRegistrationOpen(tournamentSite)
}

const isRegistrationOrCancelRegistrationOpen = (tournamentSite: TournamentSite): boolean => {
  return isRegistrationOpen(tournamentSite) || isCancelRegistrationOpen(tournamentSite)
}

const isRegistrationOpen = (tournamentSite: TournamentSite): boolean => {
  return isCurrentDateBetweenSelections(
    tournamentSite.registerAndCancelTimeFramesEnabled,
    tournamentSite.registrationStartsTime,
    tournamentSite.registrationEndsTime,
  )
}

const isCancelRegistrationOpen = (tournamentSite: TournamentSite): boolean => {
  return isCurrentDateBetweenSelections(
    tournamentSite.registerAndCancelTimeFramesEnabled,
    tournamentSite.registrationCancellingStartsTime,
    tournamentSite.registrationCancellingEndsTime,
  )
}

const isCurrentDateBetweenSelections = (
  registerAndCancelTimeFramesEnabled: boolean,
  startTime?: Date,
  endTime?: Date,
) => {
  if (!registerAndCancelTimeFramesEnabled) {
    return true
  }

  if (startTime && endTime && startTime < endTime) {
    return isWithinInterval(new Date(), { start: startTime, end: endTime })
  }

  return true
}
