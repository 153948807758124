import { Action } from 'redux'
import { FETCH_TOURNAMENT_DIVISION_LEADERBOARD } from './constants'

/*
 * FETCH TOURNAMENT LEADERBOAD BY DIVISION
 */

export interface FetchTournamentDivisionLeaderboardArgs extends APICall {
  id: number
  divisionId: number
}

export interface FetchTournamentDivisionLeaderboardAction extends Action, FetchTournamentDivisionLeaderboardArgs {
  type: 'FETCH_TOURNAMENT_DIVISION_LEADERBOARD'
}

export interface FetchTournamentDivisionLeaderboardSuccessAction extends SuccessAction {
  type: 'FETCH_TOURNAMENT_DIVISION_LEADERBOARD_SUCCESS'
  data: TournamentLeaderboard
}

export interface FetchTournamentDivisionLeaderboardFailureAction extends Action {
  type: 'FETCH_TOURNAMENT_DIVISION_LEADERBOARD_FAILURE'
  error: ErrorPayload
}

export const fetchTournamentDivisionLeaderboard = ({
  id,
  divisionId,
  onComplete,
}: FetchTournamentDivisionLeaderboardArgs): FetchTournamentDivisionLeaderboardAction => ({
  type: FETCH_TOURNAMENT_DIVISION_LEADERBOARD,
  id,
  divisionId,
  onComplete,
})
