import axios, { AxiosRequestConfig } from 'axios'
import { omit, get, cloneDeep } from 'lodash'
import { emptyGameOptions, emptyRoundConfig, emptyTournament, emptyContestOptions } from '../store/api/emptyObjects'
import { getFieldNameFromLabel } from './gameFormatUtils'
import { tryToCapitalize } from '../store/notifications/actions'
import { store } from '../store'
import { AUTH_LOGOUT } from '../store/authentication/constants'
import { removeTimeZoneFromDate, formatDateToIsoWithTimeZone } from './dates'

const getDefaultHeaders = (token?: string): Record<string, string> => ({
  'Content-type': 'application/json',
  Authorization: token ? `Bearer ${token}` : '',
})

export const apiClientRequest = (config: AxiosRequestConfig & { token?: string }) => {
  const { headers = {}, token = '', data, ...rest } = config

  const contentType = data instanceof FormData ? 'multipart/form-data' : 'application/json'
  const contentTypeHeader = { 'Content-type': contentType }

  return axios
    .request({
      headers: {
        ...getDefaultHeaders(token),
        ...headers,
        ...contentTypeHeader,
      },
      data,
      ...rest,
    })
    .catch(function (error) {
      // Log user out if status is unauthorised
      if (error.response.status === 401) {
        store.dispatch({ type: AUTH_LOGOUT })
      }

      throw Object.assign(new Error(handleErrorResponse(error.toJSON(), error.response)), {
        status: error.response.status,
      })
    })
}

export const apiClientDownloadRequest = (config: AxiosRequestConfig & { token?: string }) => {
  const { headers = {}, responseType = 'blob', token = '', ...rest } = config

  return axios
    .request({
      responseType: responseType,
      headers: {
        ...getDefaultHeaders(token),
        ...headers,
      },
      ...rest,
    })
    .catch(function (error) {
      // Log user out if status is unauthorised
      if (error.response.status === 401) {
        store.dispatch({ type: AUTH_LOGOUT })
      }

      throw Object.assign(new Error(handleErrorResponse(error.toJSON(), error.response)), {
        status: error.response.status,
      })
    })
}

const transformRound = (round: Round) => {
  const roundPayload = { ...round }
  if (roundPayload.club) {
    roundPayload.clubId = { value: roundPayload.club.id, label: roundPayload.club.name }
    roundPayload.countryId = roundPayload.club.countryId
  } else {
    roundPayload.clubId = { value: 0, label: '' }
  }

  if (roundPayload.course) {
    roundPayload.courseId = roundPayload.course.id
  }

  const primaryGameId = get(roundPayload, 'primaryGame.id')
  if (primaryGameId) {
    roundPayload.primaryGameId = primaryGameId
  }

  const sideGameId = get(roundPayload, 'sideGame.id')
  if (sideGameId) {
    roundPayload.sideGameId = sideGameId
  }

  const teeBoxMen = get(roundPayload, 'teeBoxMen.id')
  if (teeBoxMen) {
    roundPayload.menTeeBoxId = teeBoxMen
  }

  const teeBoxWomen = get(roundPayload, 'teeBoxWomen.id')
  if (teeBoxWomen) {
    roundPayload.womenTeeBoxId = teeBoxWomen
  }
  roundPayload.primaryGameOptions = Object.assign({}, emptyGameOptions, roundPayload.primaryGameOptions)
  roundPayload.sideGameOptions = Object.assign({}, emptyGameOptions, roundPayload.sideGameOptions)
  roundPayload.contestOptions = Object.assign({}, emptyContestOptions, roundPayload.contestOptions)
  roundPayload.scoringStarted = (roundPayload.status && roundPayload.status.isScoringDisabled === false) || false

  /**
   * We need to remove the time zone from start and end time,
   * otherwise browser will automatically adjust the time
   * to match user's current timezone.
   *
   * We'll put time zone back to start and end times when saving round information.
   */
  roundPayload.startTime = new Date(removeTimeZoneFromDate(roundPayload.startTime))
  roundPayload.endTime = roundPayload.endTime && new Date(removeTimeZoneFromDate(roundPayload.endTime))

  const findId = roundPayload.course?.holes?.find((hole) => hole.holeNumber === 1)
  roundPayload.defaultStartingHole =
    roundPayload.defaultStartingHole || ({ id: findId?.id, holeNumber: 1 } as DefaultStartingHole)
  return roundPayload
}

export const preProcessTournament = (tournamentPayload: TournamentState): TournamentState => {
  const strippedPayload = omit(tournamentPayload, ['rounds'])
  const tournamentObject: TournamentState = Object.assign({}, emptyTournament, strippedPayload)

  /**
   * If tournament has existing rounds, merge those with default values.
   */
  if (tournamentPayload.rounds.length > 0) {
    tournamentObject.rounds = tournamentPayload.rounds.map((round: Round) =>
      Object.assign({}, emptyRoundConfig, transformRound(round)),
    )
  } else {
    /**
     * By default tournament should have one round in state.
     */

    const organizationClub = tournamentObject.tournamentOrganization?.club

    let defaultRoundConfig: Round = {
      ...emptyRoundConfig,
    }
    if (organizationClub) {
      defaultRoundConfig = {
        ...defaultRoundConfig,
        countryId: organizationClub.countryId,
        club: organizationClub,
        clubId: {
          label: organizationClub.name,
          value: organizationClub.id,
        },
      }
    }
    tournamentObject.rounds = [defaultRoundConfig]
  }

  tournamentObject.startTime = new Date(removeTimeZoneFromDate(tournamentPayload.startTime))
  tournamentObject.endTime = tournamentPayload.endTime && new Date(removeTimeZoneFromDate(tournamentPayload.endTime))
  tournamentObject.handicapsUpdatedAt =
    tournamentPayload.handicapsUpdatedAt && new Date(tournamentPayload.handicapsUpdatedAt)
  return tournamentObject
}

export const getRandomId = () => {
  return Math.floor(Math.random() * 10000)
}

export const preProcessRoundForSending = (round: Round) => {
  const roundCopy = cloneDeep(round)
  // eslint-disable-next-line max-len
  const payload = omit(roundCopy, ['clubId', 'course', 'primaryGame', 'sideGame']) as any // Refactor to use Round interface
  if (round.clubId) {
    payload.clubId = get(round.clubId, 'value')
  }

  if (round.primaryGameOptions?.perPlayerHandicapPercentages) {
    payload.primaryGameOptions.perPlayerHandicapPercentages = round.primaryGameOptions.perPlayerHandicapPercentages.map(
      (value) => Number(value),
    )
  }

  if (round.sideGameOptions?.perPlayerHandicapPercentages) {
    payload.sideGameOptions.perPlayerHandicapPercentages = round.sideGameOptions.perPlayerHandicapPercentages.map(
      (value) => Number(value),
    )
  }

  /**
   * Add club time zone back to round.
   */
  payload.startTime = formatDateToIsoWithTimeZone(round.startTime, round.club?.timeZone)
  payload.endTime = round.endTime && formatDateToIsoWithTimeZone(round.endTime, round.club?.timeZone)
  if (round.id && round.id < 0) {
    payload.id = undefined
  }
  return payload
}

export const defaultOnCompleteCall = (fn?: (params: APICallResult) => any, data?: any, error?: any) => {
  if (fn) {
    fn({
      data,
      error,
    })
  }
}

const getAllFields = (options: GameFormatAdditionalOptionSet | GameFormatAdditionalOptionSet[]) => {
  if (Array.isArray(options)) {
    // multiple
    return options.map((option) => getFieldNameFromLabel(option.label))
  }
  return [getFieldNameFromLabel(options.label)]
}

export const getToBeDeletedFields = (
  allOptions: GameFormatAdditionalOptions,
  selected: GameFormatAdditionalOptionSet | GameFormatAdditionalOptionSet[],
) => {
  const selectedFields = getAllFields(selected)
  return Object.keys(allOptions)
    .map((key) => getAllFields(allOptions[key]))
    .reduce((acc, o) => acc.concat(o), [])
    .filter((fieldName) => !selectedFields.includes(fieldName))
}

const handleErrorResponse = (error: any, response: any) => {
  try {
    if (response && response.data.status) {
      return tryToCapitalize(response.data.status)
    } else if (response && response.data.error.message) {
      return tryToCapitalize(response.data.error.message.toString())
    }
    return tryToCapitalize(error.message)
  } catch (except) {
    return tryToCapitalize(error.message)
  }
}

/**
 * Adds tournament team info to start list groups
 * @param groups
 * @param tournamentTeams
 * @param action
 * @returns StartListGroup[]
 */
export const processGroups = (
  groups: StartListGroup[],
  tournamentTeams: TournamentTeam[],
  roundId: number,
): StartListGroup[] => {
  return groups.map((group) => {
    // Modify startListGroupTeams to include players from tournamentTeams
    const processedTeams = group.teams.map((startListGroupTeam) => {
      const tournamentTeam = tournamentTeams.find(
        (tournamentTeam) => tournamentTeam.id === startListGroupTeam.tournamentTeamId,
      )

      // Pick correct startListPlayer from group
      const tournamentPlayers = tournamentTeam?.players.map((tPlayer) => {
        const findPlayer = group.startListPlayers.find((sPlayer) => sPlayer.tournamentPlayerId === tPlayer.id)

        return {
          ...tPlayer,
          startListPlayers: { [roundId]: findPlayer },
        }
      })

      return {
        ...startListGroupTeam,
        players: (tournamentPlayers as TournamentPlayer[]) || [],
        name: tournamentTeam?.name || '',
      }
    })

    /**
     * Remove time zone from group start time. This will allow us to show
     * start time "as it is". Without this, browser will automatically adjust
     * the time based on user's time zone.
     */
    const startTimeWithoutTimeZone = removeTimeZoneFromDate(group.startTime)
    return { ...group, teams: processedTeams || [], startTime: new Date(startTimeWithoutTimeZone) }
  })
}

export const handleNextHour = (hours: number): number => {
  if (hours >= 23) {
    return 0
  }

  return hours + 1
}
