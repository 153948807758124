import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Button, Dialog, DialogContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import { useEffect } from 'react'
import { rem } from '@app/theme/materialUITheme'

interface OwnProps {
  open?: boolean
  onClose?: () => void
  language: string
  fetchPreviewEmailFn: (language: string) => void
  emailBody: string
  htmlBase64: string
}

interface StateProps {
  emailBody?: string
}

type EmailPreviewDialogProps = OwnProps & StateProps

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  dialogContent: {
    position: 'relative',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: rem(20),
    marginBottom: rem(20),
  },
  iframe: {
    width: '100%',
    height: '100%',
    margin: `0 auto ${rem(40)} auto`,
    position: 'absolute',
    top: 0,
    left: 0,
    padding: `0 ${rem(20)}`,
  },
}))

export const EmailPreviewDialog: React.FC<EmailPreviewDialogProps> = ({
  open = true,
  language,
  emailBody,
  htmlBase64,
  onClose = () => undefined,
  fetchPreviewEmailFn,
}) => {
  const classes = useStyles()

  useEffect(() => {
    open && fetchPreviewEmailFn(language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, language])
  return (
    <Dialog fullWidth open={open} classes={{ paper: classes.dialogPaper }}>
      <BaseDialogTitle largeTitleText title={<FormattedMessageWrapper id="tournaments.preview" />} />
      <DialogContent className={classes.dialogContent}>
        <iframe
          id="ggb-tm-email-preview"
          title="emailPreview"
          srcDoc={emailBody !== '' ? emailBody : undefined}
          className={classes.iframe}
          allowFullScreen
          src={htmlBase64 !== '' ? `data:text/html;base64,${htmlBase64}` : undefined}
        ></iframe>
      </DialogContent>
      <div className={classes.buttonContainer}>
        <Button color="primary" variant="outlined" onClick={onClose}>
          <FormattedMessageWrapper id="buttons.close" />
        </Button>
      </div>
    </Dialog>
  )
}
