import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { defaultOnCompleteCall, preProcessTournament } from '@app/utils/sagaHelpers'
import { processTournamentPayload } from '../utils/tournamentUtils'
import { checkIfShouldLoadCourses } from '../thunks/countriesThunks'
import { clearAllTournaments } from '@app/store/tournaments/actions'
import { RootState } from '@app/store'
import { emptyTournament } from '../emptyObjects'
import { getTournamentId, setTournamentId } from '../slices/configSlice'
import { updateTournamentRoundCourses } from '../thunks/clubsAndCoursesThunks'
import { getActiveStartListCacheTags } from '../utils/tournamentStartListsUtils'

export const tournamentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTournament: build.query<TournamentState, TournamentPayload>({
      query: (payload) => APIRoute.GET_TOURNAMENT(payload.id),
      transformResponse: (response: TournamentState) => {
        return preProcessTournament(response)
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setTournamentId(payload.id))
          dispatch(updateTournamentRoundCourses(data.rounds))
          dispatch(checkIfShouldLoadCourses())
        } catch {
          console.error('Error fetching tournament')
        }
      },
      providesTags: [CacheTag.TOURNAMENT_SINGLE],
    }),
    getTournamentList: build.query<TournamentListResponse, TournamentListPayload>({
      query: (payload) => {
        const { startDate, limit, organizationId, ids } = payload
        return { url: APIRoute.GET_TOURNAMENTS(organizationId), params: { type: 'condensed', startDate, limit, ids } }
      },
      providesTags: [CacheTag.TOURNAMENT_LIST],
    }),
    updateTournament: build.mutation<TournamentState, UpdateTournamentPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_TOURNAMENT(payload.id),
        method: HttpMethod.PUT,
        body: processTournamentPayload(payload.body),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(clearAllTournaments())
        } catch {
          console.error('Error updating tournament')
        }
      },
      invalidatesTags: () => [...getActiveStartListCacheTags()],
    }),
    createTournament: build.mutation<TournamentState, CreateTournamentPayload>({
      query: (payload) => ({
        url: APIRoute.POST_TOURNAMENT,
        method: HttpMethod.POST,
        body: processTournamentPayload(payload.body),
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: tournament } = await queryFulfilled
          dispatch(setTournamentId(tournament.id))
          dispatch(clearAllTournaments())
          dispatch(tournamentApi.endpoints.getTournament.initiate({ id: tournament.id }))
          payload.onComplete(tournament)
        } catch {
          console.error('Error creating tournament')
        }
      },
    }),
    copyTournament: build.mutation<TournamentState, CopyTournamentPayload>({
      query: (payload) => ({
        url: APIRoute.COPY_TOURNAMENT(payload.tournamentId),
        method: HttpMethod.POST,
        body: { extent: payload.extent },
      }),
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data: tournament } = await queryFulfilled
          defaultOnCompleteCall(payload.onComplete, tournament)
          dispatch(clearAllTournaments())
        } catch {
          console.error('Error copying tournament')
        }
      },
    }),
    calculateTournamentCut: build.mutation<void, CalculateTournamentCutPayload>({
      query: (payload) => ({
        url: APIRoute.CALCULATE_CUT(payload.tournamentId),
        method: HttpMethod.POST,
      }),
      invalidatesTags: () => [CacheTag.TOURNAMENT_PLAYERS, CacheTag.TOURNAMENT_TEAMS],
    }),
    updateTournamentNotes: build.mutation<void, UpdateTournamentNotePayload>({
      query: (payload) => ({
        url: APIRoute.UPDATE_NOTES(payload.tournamentId),
        method: HttpMethod.PUT,
        body: { internalNote: payload.internalNote },
      }),
    }),
    syncTournament: build.mutation<void, number>({
      query: (tournamentId) => ({
        url: APIRoute.SYNC_TOURNAMENT(tournamentId),
        method: HttpMethod.PATCH,
      }),
    }),
  }),
})

export const selectTournament = (state: RootState) =>
  tournamentApi.endpoints.getTournament.select({ id: getTournamentId(state) })(state).data || emptyTournament

export const {
  useGetTournamentQuery,
  useGetTournamentListQuery,
  useLazyGetTournamentQuery,
  useUpdateTournamentMutation,
  useCreateTournamentMutation,
  useCopyTournamentMutation,
  useCalculateTournamentCutMutation,
  useSyncTournamentMutation,
} = tournamentApi
