import React, { useState } from 'react'
import { Grid, Button, IconButton, TextField, Typography } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import makeStyles from '@mui/styles/makeStyles'
import { licenseName } from '@utils/organizationUtils'

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'left',
  },
  historyButtonContainer: {
    textAlign: 'left',
  },
  textFieldNormal: {
    width: 400,
  },
  textFieldShort: {
    width: 73,
  },
  licenseDeltaSelector: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface OwnProps {
  handleShowHistory(): void
  updateLicenseDeltaCallback(delta: number): void
  updateNotesCallback(notes: string): void
}

enum CountType {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
}

export const LicenseAddView: React.FC<OwnProps> = ({
  handleShowHistory,
  updateLicenseDeltaCallback,
  updateNotesCallback,
}) => {
  const classes = useStyles()

  const [licenseDelta, setLicenseDelta] = useState(0)
  const [notes, setNotes] = useState('')

  const handleDeltaChange = (type: CountType) => {
    let newLicenseDelta = licenseDelta
    if (type === CountType.INCREMENT) {
      newLicenseDelta++
    } else {
      newLicenseDelta--
    }
    setLicenseDelta(newLicenseDelta)
    updateLicenseDeltaCallback(newLicenseDelta)
  }

  const updateDelta = (e) => {
    setLicenseDelta(e.target.value)
    updateLicenseDeltaCallback(parseInt(e.target.value))
  }

  const updateNotes = (e) => {
    setNotes(e.target.value)
    updateNotesCallback(e.target.value)
  }

  return (
    <div className={classes.wrap}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary">
            <FormattedMessageWrapper id="organizations.productName" />
          </Typography>
          <TextField className={classes.textFieldNormal} value={licenseName} disabled />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary">
            <FormattedMessageWrapper id="organizations.addLicenses" />
          </Typography>
          <div className={classes.licenseDeltaSelector}>
            <IconButton size="large" onClick={() => handleDeltaChange(CountType.DECREMENT)}>
              <RemoveCircleIcon color="primary" fontSize="large" />
            </IconButton>
            <TextField
              className={classes.textFieldShort}
              type="number"
              value={licenseDelta}
              onChange={(e) => updateDelta(e)}
            ></TextField>
            <IconButton size="large" onClick={() => handleDeltaChange(CountType.INCREMENT)}>
              <AddCircleIcon color="primary" fontSize="large" />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="primary">
            <FormattedMessageWrapper id="organizations.notes" />
          </Typography>
          <TextField className={classes.textFieldNormal} value={notes} onChange={(e) => updateNotes(e)}></TextField>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.historyButtonContainer}>
            <Button variant="text" onClick={() => handleShowHistory()}>
              <FormattedMessageWrapper id="organizations.showHistory" />
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
