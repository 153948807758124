import { TournamentTypes } from '../enums/tournamentEnums'
import { tournamentRoundApi } from '../endpoints/tournamentRoundApi'
import { isBoolean } from 'lodash'
import { selectClub } from '../endpoints/clubsAndCoursesApi'
import { selectClubsAndCoursesConfig, selectTournamentConfig } from '../slices/configSlice'
import { selectTournament } from '../endpoints/tournamentApi'
import { RootState } from '@app/store'
import { preProcessTournament } from '@app/utils/sagaHelpers'
import { saveTournamentData } from './tournamentThunks'
import { api } from '../baseApi'
import { CacheTag, CacheTagSingle } from '../cacheTags'
import { updateTournamentRoundCourses } from './clubsAndCoursesThunks'
import { setVerifyCourseVersionAfterStartListPublish } from '../slices/tournamentStartListsSlice'
import { setSelectedRound } from './configThunks'

export const updateTournamentRoundStatus = (payload: UpdateRoundStatusPayload, isStartListPublish = false) => {
  return (dispatch, getState: () => RootState) => {
    const tournament: TournamentState = selectTournament(getState())

    if (tournament.tournamentType === TournamentTypes.weekly) {
      dispatch(
        tournamentRoundApi.endpoints.updateTournamentRoundStatusWeekly.initiate({
          tournamentId: tournament.id,
          onSuccess: payload.onSuccess,
          onFailure: payload.onFailure,
        }),
      )
    } else {
      const body: Partial<RoundStatus> = {
        ...(isBoolean(payload.body.isCompleted) && { isCompleted: payload.body.isCompleted }),
        ...(isBoolean(payload.body.isConfigured) && { isConfigured: payload.body.isConfigured }),
        ...(isBoolean(payload.body.isScoringDisabled) && { isScoringDisabled: payload.body.isScoringDisabled }),
      }
      dispatch(
        tournamentRoundApi.endpoints.updateTournamentRoundStatus.initiate({
          tournamentId: tournament.id,
          roundId: payload.roundId,
          body,
          onSuccess: payload.onSuccess,
          onFailure: payload.onFailure,
        }),
      ).then(() => {
        if (isStartListPublish) {
          dispatch(setVerifyCourseVersionAfterStartListPublish(true))
        }
      })
    }
  }
}

export const resetRoundSettingsAfterUnsavedChanges = () => {
  return (dispatch, getState: () => RootState) => {
    const tournament: TournamentState = selectTournament(getState())
    const tournamentConfig: TournamentConfig = selectTournamentConfig(getState())
    const filteredRounds = tournament.rounds.filter((round) => round.id !== -1)
    if (tournamentConfig.selectedRoundId === -1 && filteredRounds.length > 0) {
      const lastRound = filteredRounds[filteredRounds.length - 1]
      dispatch(setSelectedRound(lastRound.id))
    }
  }
}

export const saveTournamentRound = (payload: TournamentRoundPayload) => {
  return (dispatch, getState: () => RootState) => {
    const state = getState()
    const { tournamentId, body } = payload
    let round: Round = { ...body }

    /**
     * Check if Club has changed
     */
    const selectedClubId = selectClubsAndCoursesConfig(state).selectedClubId
    const selectedClub: Club | undefined = selectClub(state, selectedClubId)

    /**
     * If round club differs from one selected in round config page..
     */
    if (selectedClub && (!round.club || round.club.id !== selectedClub.id)) {
      round = { ...round, club: selectedClub }
    }

    if (round.id > 0) {
      dispatch(
        tournamentRoundApi.endpoints.updateTournamentRound.initiate({
          tournamentId,
          body: round,
          onSuccess: payload.onSuccess,
        }),
      )
    } else {
      dispatch(
        tournamentRoundApi.endpoints.createTournamentRound.initiate({
          tournamentId,
          body: round,
          onSuccess: payload.onSuccess,
        }),
      )
    }
  }
}

export const syncTournamentDataAfterRoundUpdate = (data: TournamentRoundResponse) => {
  return (dispatch) => {
    const { tournament, tournamentPlayersUpdated, roundGroupsUpdated, startListsDeleted } = data

    const updatedTournament = preProcessTournament(tournament)
    dispatch(saveTournamentData(updatedTournament))
    dispatch(updateTournamentRoundCourses(tournament.rounds))

    const tagsToInvalidate: (CacheTagSingle | CacheTag)[] = []

    tagsToInvalidate.push(CacheTag.TOURNAMENT_SINGLE)

    if (tournamentPlayersUpdated) {
      tagsToInvalidate.push(CacheTag.TOURNAMENT_PLAYERS)
    }

    if (roundGroupsUpdated || startListsDeleted) {
      const tournamentRoundIds = updatedTournament.rounds.map((round) => round.id).flat()
      tournamentRoundIds.forEach((roundId) => {
        tagsToInvalidate.push({ type: CacheTag.TOURNAMENT_STARTLISTS, id: roundId })
      })
    }

    dispatch(api.util.invalidateTags(tagsToInvalidate))
  }
}
