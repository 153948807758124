import {
  CreateOrganizationApplication,
  CreateOrganizationApplicationError,
  CreateOrganizationApplicationSuccess,
  FetchOrganizationAction,
  FetchOrganizationFailureAction,
  FetchOrganizationSuccessAction,
  UpdateOrganizationAction,
  UpdateOrganizationActionFailure,
  UpdateOrganizationActionSuccess,
  UploadOrganizationCoverImageAction,
  UploadOrganizationCoverImageActionFailure,
  UploadOrganizationCoverImageActionSuccess,
  UploadOrganizationLogoImageAction,
  UploadOrganizationLogoImageActionFailure,
  UploadOrganizationLogoImageActionSuccess,
  AddOrganizationLicenseAction,
  AddOrganizationLicenseActionSuccess,
  AddOrganizationLicenseActionFailure,
  FetchOrganizationLicenseHistoryAction,
  FetchOrganizationLicenseHistoryActionSuccess,
  FetchOrganizationLicenseHistoryActionFailure,
  AddOrganizationSubscriptionAction,
  AddOrganizationSubscriptionActionSuccess,
  AddOrganizationSubscriptionActionFailure,
  FetchOrganizationSubscriptionHistoryAction,
  FetchOrganizationSubscriptionHistoryActionSuccess,
  FetchOrganizationSubscriptionHistoryActionFailure,
} from './actions'
import {
  CREATE_ORGANIZATION_APPLICATION_ERROR,
  CREATE_ORGANIZATION_APPLICATION_REQUESTING,
  CREATE_ORGANIZATION_APPLICATION_SUCCESS,
  ORGANIZATION_FETCH,
  ORGANIZATION_FETCH_FAILURE,
  ORGANIZATION_FETCH_SUCCESS,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_SUCCESS,
  UPLOAD_ORGANIZATION_COVER_IMAGE,
  UPLOAD_ORGANIZATION_COVER_IMAGE_FAILURE,
  UPLOAD_ORGANIZATION_COVER_IMAGE_SUCCESS,
  UPLOAD_ORGANIZATION_LOGO,
  UPLOAD_ORGANIZATION_LOGO_FAILURE,
  UPLOAD_ORGANIZATION_LOGO_SUCCESS,
  ADD_ORGANIZATION_LICENSE,
  ADD_ORGANIZATION_LICENSE_SUCCESS,
  ADD_ORGANIZATION_LICENSE_FAILURE,
  FETCH_ORGANIZATION_LICENSE_HISTORY,
  FETCH_ORGANIZATION_LICENSE_HISTORY_SUCCESS,
  FETCH_ORGANIZATION_LICENSE_HISTORY_FAILURE,
  FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY,
  ADD_ORGANIZATION_SUBSCRIPTION,
  ADD_ORGANIZATION_SUBSCRIPTION_FAILURE,
  FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_FAILURE,
  ADD_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_SUCCESS,
} from './constants'

const initialState: OrganizationState = {
  addressInfo: undefined,
  error: null,
  loading: false,
  name: '',
  nameMarketing: '',
  additionalInfo: '',
  description: '',
  logoImageUrl: '',
  organizationAdmin: undefined,
  email: '',
  phone: '',
  homepage: '',
  country: '',
  isClub: false,
  createdAt: '',
  updatedAt: '',
  licenses: [],
  subscriptions: [],
  units: 'metric',
  countryId: -1,
  id: -1,
  businessId: '',
  streetAddress1: '',
  streetAddress2: '',
  paymentCompanyId: '',
  poBox: '',
  city: '',
  region: '',
  zip: '',
  adManagerOrganizationId: -1,
  history: [],
  nameCompany: '',
  subHistory: [],
}

type OrganizationAction =
  | FetchOrganizationAction
  | FetchOrganizationSuccessAction
  | FetchOrganizationFailureAction
  | CreateOrganizationApplication
  | CreateOrganizationApplicationError
  | CreateOrganizationApplicationSuccess
  | UploadOrganizationLogoImageAction
  | UploadOrganizationLogoImageActionSuccess
  | UploadOrganizationLogoImageActionFailure
  | UpdateOrganizationAction
  | UpdateOrganizationActionSuccess
  | UpdateOrganizationActionFailure
  | UploadOrganizationCoverImageAction
  | UploadOrganizationCoverImageActionSuccess
  | UploadOrganizationCoverImageActionFailure
  | AddOrganizationLicenseAction
  | AddOrganizationLicenseActionSuccess
  | AddOrganizationLicenseActionFailure
  | FetchOrganizationLicenseHistoryAction
  | FetchOrganizationLicenseHistoryActionSuccess
  | FetchOrganizationLicenseHistoryActionFailure
  | AddOrganizationSubscriptionAction
  | AddOrganizationSubscriptionActionSuccess
  | AddOrganizationSubscriptionActionFailure
  | FetchOrganizationSubscriptionHistoryAction
  | FetchOrganizationSubscriptionHistoryActionSuccess
  | FetchOrganizationSubscriptionHistoryActionFailure

export const organizationReducer = (
  state: OrganizationState = initialState,
  action: OrganizationAction,
): OrganizationState => {
  switch (action.type) {
    case UPLOAD_ORGANIZATION_COVER_IMAGE:
    case UPDATE_ORGANIZATION:
    case CREATE_ORGANIZATION_APPLICATION_REQUESTING:
    case UPLOAD_ORGANIZATION_LOGO:
    case ORGANIZATION_FETCH:
    case ADD_ORGANIZATION_LICENSE:
    case FETCH_ORGANIZATION_LICENSE_HISTORY:
    case ADD_ORGANIZATION_SUBSCRIPTION:
    case FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY: {
      return {
        ...state,
        loading: true,
      }
    }
    case ORGANIZATION_FETCH_SUCCESS: {
      return {
        ...initialState,
        ...action.organization,
        loading: false,
      }
    }
    case FETCH_ORGANIZATION_LICENSE_HISTORY_SUCCESS: {
      return {
        ...initialState,
        history: action.history,
        loading: false,
      }
    }
    case FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_SUCCESS: {
      return {
        ...initialState,
        subHistory: action.subHistory,
        loading: false,
      }
    }
    case UPLOAD_ORGANIZATION_COVER_IMAGE_FAILURE:
    case UPDATE_ORGANIZATION_FAILURE:
    case UPLOAD_ORGANIZATION_LOGO_FAILURE:
    case CREATE_ORGANIZATION_APPLICATION_ERROR:
    case ORGANIZATION_FETCH_FAILURE:
    case ADD_ORGANIZATION_LICENSE_FAILURE:
    case FETCH_ORGANIZATION_LICENSE_HISTORY_FAILURE:
    case ADD_ORGANIZATION_SUBSCRIPTION_FAILURE:
    case FETCH_ORGANIZATION_SUBSCRIPTION_HISTORY_FAILURE: {
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    }
    case UPDATE_ORGANIZATION_SUCCESS: {
      return {
        ...initialState,
        ...action.organization,
        loading: false,
      }
    }
    case UPLOAD_ORGANIZATION_COVER_IMAGE_SUCCESS:
    case UPLOAD_ORGANIZATION_LOGO_SUCCESS:
    case CREATE_ORGANIZATION_APPLICATION_SUCCESS:
    case ADD_ORGANIZATION_LICENSE_SUCCESS:
    case ADD_ORGANIZATION_SUBSCRIPTION_SUCCESS: {
      const { loading = false } = action
      return {
        ...state,
        loading: loading,
      }
    }
    default:
      return state
  }
}
