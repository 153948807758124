import {
  FETCH_REPORTS,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  FETCH_WEEKLY_REPORTS,
  FETCH_WEEKLY_REPORTS_SUCCESS,
  FETCH_WEEKLY_REPORTS_FAILURE,
  CLEAR_REPORTS,
} from './constans'
import {
  FetchReportsAction,
  FetchReportsSuccessAction,
  FetchReportsFailureAction,
  FetchWeeklyReportsAction,
  FetchWeeklyReportsSuccessAction,
  FetchWeeklyReportsFailureAction,
  ClearReportsAction,
} from './actions'

const initialState: ReportsState = {
  loading: false,
  reports: {
    guests: 0,
    members: 0,
    tournaments: 0,
    roundsPerCourse: [],
  },
}

type ReportsActions =
  | FetchReportsAction
  | FetchReportsSuccessAction
  | FetchReportsFailureAction
  | FetchWeeklyReportsAction
  | FetchWeeklyReportsSuccessAction
  | FetchWeeklyReportsFailureAction
  | ClearReportsAction

const reportsReducer = (state: ReportsState = initialState, action: ReportsActions) => {
  switch (action.type) {
    case FETCH_REPORTS:
    case FETCH_WEEKLY_REPORTS: {
      return {
        ...state,
        loading: true,
      }
    }
    case FETCH_REPORTS_SUCCESS:
    case FETCH_WEEKLY_REPORTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        reports: action.reports,
      }
    }
    case FETCH_REPORTS_FAILURE:
    case FETCH_WEEKLY_REPORTS_FAILURE: {
      return {
        ...state,
        loading: false,
      }
    }
    case CLEAR_REPORTS: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export { reportsReducer }
