import { takeEvery, put, all } from 'redux-saga/effects'
import {
  FETCH_TOURNAMENT_DIVISION_LEADERBOARD,
  FETCH_TOURNAMENT_DIVISION_LEADERBOARD_SUCCESS,
  FETCH_TOURNAMENT_DIVISION_LEADERBOARD_FAILURE,
} from './constants'
import { FetchTournamentDivisionLeaderboardAction } from './actions'
import { getToken } from '../authentication/sagas'
import { API_ROOT, APIRoute } from '../../config'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import { enqueueNotification } from '../notifications/actions'

function* doFetchTournamentDivisionLeaderboard({
  id,
  divisionId,
  onComplete,
}: FetchTournamentDivisionLeaderboardAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_LEADERBOARD_WITH_DIVISIONS(id, divisionId)}`,
      method: 'get',
      token,
    })
    yield put({
      type: FETCH_TOURNAMENT_DIVISION_LEADERBOARD_SUCCESS,
      data: res.data.data,
    })
    defaultOnCompleteCall(onComplete, res.data)
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, null, error)
    yield put({
      type: FETCH_TOURNAMENT_DIVISION_LEADERBOARD_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchTournamentDivisionLeaderboard() {
  yield all([takeEvery(FETCH_TOURNAMENT_DIVISION_LEADERBOARD, doFetchTournamentDivisionLeaderboard)])
}
