import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

interface PathObject {
  pathname: string
  search?: string
}

/** @deprecated Use `React Router hooks` instead */
export interface WithRouterProps {
  location: ReturnType<typeof useLocation>
  params: Record<string, string>
  navigate: ReturnType<typeof useNavigate>
  historyReplace: (pathObject: PathObject) => void
}

/** @deprecated Use `React Router hooks` instead */
export const withRouter = <Props extends WithRouterProps>(Component: React.ComponentType<Props>) => {
  return (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const historyReplace = (pathObject: PathObject): void => {
      const { pathname, search } = pathObject
      const url = search ? `${pathname}?${search}` : pathname
      navigate(url, { replace: true })
    }

    return (
      <Component
        {...(props as Props)}
        location={location}
        params={params}
        navigate={navigate}
        historyReplace={historyReplace}
      />
    )
  }
}
