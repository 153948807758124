import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  IconButton,
  Grid,
  Input,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material'
import { Edit, List, Search, Clear, AssignmentInd } from '@mui/icons-material'
import { fetchOrganizations } from '@app/store/organizations/actions'
import { OverlayLoader } from '@components/ui/OverlayLoader'
import HeadHelmet from '@components/layout/HeadHelmet'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import DefaultPagedTableFooter from '@components/tables/DefaultPagedTableFooter'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { pickBy } from 'lodash'
import { formatDate, formatDayUTC } from '@app/utils/dates'
import LicenseSubscriptionStatusDialog from '@components/dialogs/licenses/LicenseSubscriptionStatusDialog'
import { isRoleInfoProviderAdmin } from '@app/utils/authUtils'
import { navigateToOrganizationUrl, navigateToOrganizationTournamentUrl } from '@app/utils/organizationUtils'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { makeStyles } from '@mui/styles'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  label: {
    lineHeight: 'initial',
    fontSize: 'initial',
    backgroundColor: 'white',
  },
  searchContainer: {
    display: 'flex',
    marginBottom: 30,
    justifyContent: 'flex-end',
  },
  inputContainer: {
    flexBasis: 305,
    marginRight: 14,
    alignSelf: 'center',
  },
  searchButtonContainer: {
    alignSelf: 'flex-end',
  },
  formContainer: {
    width: 140,
    marginLeft: 10,
  },
}))

export const Organizations = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const [page, setPage] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [openLicenseSubscriptionsDialog, setOpenLicenseSubscriptionsDialog] = useState<boolean>(false)
  const [targetOrganization, setTargetOrganization] = useState<OrganizationsItem | undefined>(undefined)
  const [selectedFilterItem, setSelectedFilterItem] = useState<string>('')

  const organization = useSelector((store: RootState) => store.organizationReducer)
  const organizations = useSelector((store: RootState) => store.organizationsReducer)
  const { units, roleInfo } = useSelector((store: RootState) => store.authenticationReducer)

  const triggerFetch = useCallback(() => {
    if (page > 0) {
      const filterType = selectedFilterItem === 'all' ? '' : selectedFilterItem
      dispatch(fetchOrganizations(page, searchTerm, filterType, setFetchPayload))
    }
  }, [page, searchTerm, selectedFilterItem, dispatch])

  useEffect(() => {
    if (!organizations?.organizations.length && !organizations?.loading && !searchTerm) {
      triggerFetch()
    }
  }, [organizations, searchTerm, triggerFetch])

  useEffect(() => {
    const params = queryString.parse(location.search)
    const paramPage = params.page ? parseInt(params.page as string, 10) : 1

    if (paramPage !== page) {
      setPage(paramPage)
      triggerFetch()
    }
  }, [page, location.search, triggerFetch])

  const setFetchPayload = ({ data, error }: APICallResult) => {
    if (!error) {
      setTotalCount(Number(data.totalCount))
    }
  }

  const navigateToOrganization = (id: number) => {
    navigate(navigateToOrganizationUrl(id))
  }

  const navigateToOrganizationTournaments = (id: number) => {
    navigate(navigateToOrganizationTournamentUrl(id))
  }

  const renderOrganizations = () => (
    <>
      {getSearch()}
      <Table>
        <TableHead>
          <TableRow>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="organizations.marketingName" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="organizations.club" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="organizations.email" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="organizations.phone" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="organizations.country" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="organizations.subscriptionLicenses" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="organizations.createdAt" />
            </VeryDenseTableCell>
            <VeryDenseTableCell />
            <VeryDenseTableCell />
            <VeryDenseTableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {organizations?.organizations.map((organization) => (
            <TableRow key={organization.idCustomer}>
              <VeryDenseTableCell>{organization.nameMarketing || organization.nameCompany}</VeryDenseTableCell>
              <VeryDenseTableCell>{organization.club?.name}</VeryDenseTableCell>
              <VeryDenseTableCell>{organization.addressInfo.email1}</VeryDenseTableCell>
              <VeryDenseTableCell>{organization.addressInfo.phone1}</VeryDenseTableCell>
              <VeryDenseTableCell>{organization.addressInfo.country}</VeryDenseTableCell>
              <VeryDenseTableCell align={'center'}>
                {formatSubscriptionLicenses(organization.subscriptionValidTo, organization.licenseCount)}
              </VeryDenseTableCell>
              <VeryDenseTableCell>{formatDate(organization.timeCreated, 'datetime', units)}</VeryDenseTableCell>
              <VeryDenseTableCell align={'right'}>
                <IconButton
                  title={'Edit Licenses'}
                  onClick={() => handleLicenseSubscriptionDialogOpenClose(organization)}
                  size="large"
                >
                  <AssignmentInd color={'primary'} />
                </IconButton>
              </VeryDenseTableCell>
              <VeryDenseTableCell align={'right'}>
                <IconButton
                  title={'Edit organization'}
                  onClick={() => navigateToOrganization(organization.idCustomer)}
                  size="large"
                >
                  <Edit color={'primary'} />
                </IconButton>
              </VeryDenseTableCell>
              <VeryDenseTableCell align={'right'}>
                <IconButton
                  title={'Organization tournaments'}
                  onClick={() => navigateToOrganizationTournaments(organization.idCustomer)}
                  size="large"
                >
                  <List color={'primary'} />
                </IconButton>
              </VeryDenseTableCell>
            </TableRow>
          ))}
        </TableBody>

        <DefaultPagedTableFooter count={totalCount} page={page} />
      </Table>

      <LicenseSubscriptionStatusDialog
        organization={targetOrganization}
        open={openLicenseSubscriptionsDialog}
        onUpdate={() => triggerFetch()}
        onClose={() => handleLicenseSubscriptionDialogOpenClose()}
      />
    </>
  )

  const formatSubscriptionLicenses = (subscriptionValidTo, licenseCount) => {
    if (
      subscriptionValidTo !== undefined &&
      subscriptionValidTo !== null &&
      new Date(subscriptionValidTo).getMilliseconds() < Date.now()
    ) {
      return formatDayUTC(new Date(subscriptionValidTo || ''))
    } else if (licenseCount > 0) {
      return licenseCount
    } else {
      return ''
    }
  }

  const handleLicenseSubscriptionDialogOpenClose = (organization?: OrganizationsItem) => {
    setTargetOrganization(organization)
    setOpenLicenseSubscriptionsDialog(!openLicenseSubscriptionsDialog)
  }

  const handleFilterChange = (e) => setSelectedFilterItem(e.target.value)

  const getSearch = () => (
    <form onSubmit={(e) => searchOrganizations(e)}>
      <Grid item={true} xs={12} xl={12}>
        <div className={classes.searchContainer}>
          <div className={classes.inputContainer}>
            <Input
              autoComplete={'off'}
              disabled={organizations?.loading}
              value={searchTerm || ''}
              fullWidth={true}
              name="searchTerm"
              placeholder={intl.formatMessage({ id: 'tournament.searchOrganizations' })}
              inputProps={{
                'aria-label': 'Description',
              }}
              onChange={(e) => onSearchTermChange(e)}
            />
          </div>
          <div className={classes.searchButtonContainer}>
            <IconButton
              disabled={organizations?.loading || searchTerm === ''}
              type="submit"
              color="primary"
              size="large"
            >
              {!organizations?.loading && <Search />}
              {organizations?.loading && <CircularProgress size={24} />}
            </IconButton>
            {searchTerm !== '' && (
              <IconButton onClick={() => onClickClear()} size="large">
                <Clear />
              </IconButton>
            )}
          </div>
          <div>
            <FormControl className={classes.formContainer}>
              <InputLabel id="filter" className={classes.label}>
                <FormattedMessageWrapper id="organizations.filter" />
              </InputLabel>
              <Select id="filter" labelId="filter" value={selectedFilterItem || ''} onChange={handleFilterChange}>
                <MenuItem value="all">
                  <FormattedMessageWrapper id="options.all" />
                </MenuItem>
                <MenuItem value="organization">
                  <FormattedMessageWrapper id="options.organization" />
                </MenuItem>
                <MenuItem value="tmUser">
                  <FormattedMessageWrapper id="options.tmUser" />
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Grid>
    </form>
  )

  const onSearchTermChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.currentTarget.value
    setSearchTerm(value)
  }

  const onClickClear = () => {
    setSearchTerm('')
    navigate('/organizations', { replace: true })
    triggerFetch()
  }

  const searchOrganizations = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const params = queryString.parse(location.search)
    const searchParams = queryString.stringify(
      pickBy({
        ...params,
        searchTerm,
        page: 1,
      }),
    )
    navigate(`${location.pathname}?${searchParams}`, { replace: true })

    triggerFetch()
  }

  if (!isRoleInfoProviderAdmin(roleInfo)) {
    return null
  }

  return (
    <>
      <HeadHelmet titleId={'navigation.organizations'} />
      <OverlayLoader visible={organizations?.loading || organization.loading} />
      {renderOrganizations()}
    </>
  )
}
