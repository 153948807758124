import React, { useRef, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { TimePicker as MUITimePicker } from '@mui/x-date-pickers/TimePicker'
import classNames from 'classnames'
import { pickerDateFormats } from '@app/utils/dates'
import { TimeValidationError, renderTimeViewClock } from '@mui/x-date-pickers'
import { useIntl } from 'react-intl'

type ValidationErrorMessages = {
  invalidDate?: string | JSX.Element
  minutesStep?: string | JSX.Element
  minTime?: string | JSX.Element
  maxTime?: string | JSX.Element
  'shouldDisableTime-hours'?: string | JSX.Element
  'shouldDisableTime-minutes'?: string | JSX.Element
  'shouldDisableTime-seconds'?: string | JSX.Element
}
export interface TimePickerProps {
  id?: string
  name?: string
  inputProps?: {}
  className?: string
  units: OrganizationUnits
  noBorder?: boolean
  onChange?: (value: DateTimeValue) => void
  onAccept?: (value: DateTimeValue) => void
  value: DateTimeValue
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  errorMessages?: ValidationErrorMessages
}

const useStyles = makeStyles(() => ({
  metric: {
    '& .MuiOutlinedInput-root': {
      borderWidth: 0,
    },
  },
  us: {
    '& .MuiOutlinedInput-root': {},
  },
  noBorder: {
    color: '#fff',
    width: '130px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderWidth: '0px',
    },
    '& .MuiButtonBase-root': {
      color: '#fff',
    },
  },
}))

export const TimePicker: React.FC<TimePickerProps> = ({
  value,
  inputProps,
  units,
  noBorder,
  name,
  id,
  disabled,
  onChange,
  onAccept,
  minDate,
  maxDate,
  errorMessages,
}) => {
  const intl = useIntl()
  const classes = useStyles()

  const [error, setError] = useState<TimeValidationError>(null)
  const selectedDate = useRef<DateTimeValue>(null)
  const ref = useRef<HTMLInputElement | null>(null)

  /**
   * OnAccept is used when the user clicks on the renderTimeViewClock.
   * Prevents saving the value before user has selected the minutes from renderTimeViewClock.
   */

  /**
   * Emulate onAccept on blur.
   */
  const onBlur = () => {
    if (onAccept && selectedDate.current) {
      onAccept(selectedDate.current)
    }
  }

  const onDateChange = (value: DateTimeValue) => {
    selectedDate.current = value
    if (onChange) {
      onChange(value)
    }
  }

  /**
   * Blur on enter press
   * @param event
   */
  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      ref.current?.blur()
    }
  }

  return (
    <MUITimePicker
      slotProps={{
        textField: {
          name,
          required: true,
          id,
          fullWidth: true,
          className: classNames(classes[units], classes, noBorder && classes['noBorder']),
          onBlur: onBlur,
          onKeyPress: onKeyPress,
          helperText:
            error && errorMessages
              ? errorMessages[error] || intl.formatMessage({ id: 'tournament.invalidTime' })
              : undefined,
          ...inputProps,
        },
        actionBar: { actions: [] },
      }}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
      }}
      ampm={units === 'metric' ? false : true}
      inputRef={ref}
      format={pickerDateFormats[units]['time']}
      minutesStep={1}
      value={value}
      onError={setError}
      disableIgnoringDatePartForTimeValidation={true}
      minTime={minDate}
      maxTime={maxDate}
      onChange={onDateChange}
      onAccept={onAccept}
      disabled={disabled}
    />
  )
}
