import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../../components/layout/TopBar'
import ContentWrap from '../../components/layout/ContentWrap'
import {
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  CircularProgress,
  Link,
  TableSortLabel,
} from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { fetchRankings, fetchTours, deleteTour } from '../../store/tourAndRanking/actions'
import { Edit, OpenInNew, Delete } from '@mui/icons-material'
import { VeryDenseTableCell } from '../../components/tables/tableComponents'
import RankingDialog from '../../components/dialogs/rankingDialog/RankingDialog'
import BorderButton from '../../components/ui/BorderButton'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { formTourUrl } from '../../config'
import InfoTooltip from '../../components/ui/InfoTooltip'
import { formatDate } from '../../utils/dates'
import DeleteConfirm, { DeleteConfirmChildren } from '../../components/dialogs/deleteConfirm/DeleteConfirm'
import { MatchPlayBrackets } from '@app/scenes/match-play-brackets/MatchPlayBrackets'
import { RootState } from '@app/store'
import { isCustomerEventUser } from '@app/utils/authUtils'
import { cloneDeep } from 'lodash'

interface StateProps {
  auth: AuthenticationState
  tourAndRanking: TourAndRankingState
  units?: OrganizationUnits
}

interface DispatchProps {
  fetchRankings: (organizationId: number, onComplete?: () => void) => void
  fetchTours: (organizationId: number, onComplete?: () => void) => void
  deleteTour: (organizationId: number, id: number, onComplete?: () => void) => void
}

type Props = StateProps & DispatchProps & WithRouterProps

interface State {
  currentTab: string
  rankingDialogOpen: boolean
  ranking?: Ranking
  sortBy: string
  direction: 'asc' | 'desc'
  tours: Tour[]
  rankings: Ranking[]
}

class TourAndRanking extends React.Component<Props, State> {
  state: State = {
    currentTab: 'tours',
    rankingDialogOpen: false,
    ranking: undefined,
    sortBy: 'createdAt',
    direction: 'desc',
    tours: [],
    rankings: [],
  }

  componentDidMount(): void {
    const organizationId = this.props.auth.customerInfo?.idCustomer
    if (organizationId) {
      this.props.fetchRankings(organizationId, () => this._createSortHandler(this.state.sortBy, this.state.direction))
      this.props.fetchTours(organizationId, () => this._createSortHandler(this.state.sortBy, this.state.direction))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.tours.length === 0 && this.props.tourAndRanking.tours.length > 0) ||
      (prevState.rankings.length === 0 && this.props.tourAndRanking.rankings.length > 0) ||
      (!this.state.rankingDialogOpen && prevState.rankingDialogOpen)
    ) {
      this._createSortHandler(this.state.sortBy, this.state.direction)
    }

    if (prevProps.tourAndRanking.tours !== this.props.tourAndRanking.tours) {
      this.setState({ tours: this.props.tourAndRanking.tours })
    }
  }

  getTopBarTitleId = (): string => {
    return this.state.currentTab === 'matchPlay' ? 'matchPlayBracket.title' : 'navigation.toursAndRanking'
  }

  render() {
    const organizationId = this.props.auth.customerInfo?.idCustomer
    const isEventManager = isCustomerEventUser(this.props.auth.roleInfo)

    return (
      <>
        <TopBar titleId={this.getTopBarTitleId()} />

        <ContentWrap>
          <Tabs indicatorColor="primary" textColor="primary" value={this.state.currentTab}>
            <Tab
              label={<FormattedMessageWrapper id="tourAndRanking.tours" />}
              value={'tours'}
              onClick={() => this._handleTabChange('tours')}
            />
            <Tab
              label={<FormattedMessageWrapper id="tourAndRanking.rankings" />}
              value={'rankings'}
              onClick={() => this._handleTabChange('rankings')}
            />
            {!isEventManager && (
              <Tab
                label={<FormattedMessageWrapper id="matchPlayBracket.title" />}
                value={'matchPlay'}
                onClick={() => this._handleTabChange('matchPlay')}
              />
            )}
          </Tabs>

          <div style={{ marginTop: 20 }}>
            {this.state.currentTab === 'tours' && this._renderTours()}
            {this.state.currentTab === 'rankings' && this._renderRankings()}
            {this.state.currentTab === 'matchPlay' && this._renderMatchPlay()}
          </div>
        </ContentWrap>

        <RankingDialog
          open={this.state.rankingDialogOpen}
          organizationId={organizationId}
          ranking={this.state.ranking}
          onClose={() => this.setState({ rankingDialogOpen: false })}
        />
      </>
    )
  }

  private get _units() {
    const { units = 'metric' } = this.props
    return units
  }

  private _handleTabChange = (tab: string) => {
    const { currentTab } = this.state
    if (currentTab === 'tours') {
      this.setState({ tours: this.props.tourAndRanking.tours })
    }
    if (currentTab === 'rankings') {
      this.setState({ rankings: this.props.tourAndRanking.rankings })
    }
    this.setState({ currentTab: tab })
  }

  private _renderTours = () => {
    const organizationId: number | undefined = this.props.auth.customerInfo?.idCustomer
    const { sortBy, direction } = this.state

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BorderButton
            filled
            buttonProps={{
              onClick: () => {
                this.props.navigate('/tour-and-ranking/tour/new-tour/setup')
              },
              style: { margin: '15px 0' },
            }}
          >
            <FormattedMessageWrapper id="tourAndRanking.createNewTour" />
          </BorderButton>
          <InfoTooltip
            text={<FormattedMessageWrapper id="tourAndRanking.createNewTourInfo" />}
            style={{ marginLeft: 5 }}
          />
        </div>

        {this.props.tourAndRanking.loading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
            <CircularProgress size={50} thickness={2} />
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <VeryDenseTableCell sortDirection={sortBy === 'name' ? direction : false}>
                  {this._wrapWithSorting('name', <FormattedMessageWrapper id="tourAndRanking.name" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell sortDirection={sortBy === 'createdAt' ? direction : false}>
                  {this._wrapWithSorting('createdAt', <FormattedMessageWrapper id="tourAndRanking.createdAt" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell sortDirection={sortBy === 'updatedAt' ? direction : false}>
                  {this._wrapWithSorting('updatedAt', <FormattedMessageWrapper id="tourAndRanking.updatedAt" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell></VeryDenseTableCell>
                <VeryDenseTableCell></VeryDenseTableCell>
                <VeryDenseTableCell></VeryDenseTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tours.map((tour) => (
                <TableRow key={tour.id}>
                  <VeryDenseTableCell>{tour.name}</VeryDenseTableCell>
                  <VeryDenseTableCell>
                    {tour.createdAt && formatDate(tour.createdAt, 'datetime', this._units)}
                  </VeryDenseTableCell>
                  <VeryDenseTableCell>
                    {tour.updatedAt && formatDate(tour.updatedAt, 'datetime', this._units)}
                  </VeryDenseTableCell>
                  <VeryDenseTableCell style={{ width: 50 }}>
                    <IconButton
                      onClick={() => {
                        this.props.navigate(`/tour-and-ranking/tour/${tour.id}`)
                      }}
                      size="large"
                    >
                      <Edit color="primary" />
                    </IconButton>
                  </VeryDenseTableCell>
                  <VeryDenseTableCell style={{ width: 50 }}>
                    <DeleteConfirm>
                      {({ showConfirm }: DeleteConfirmChildren) => (
                        <IconButton
                          onClick={() => {
                            showConfirm({
                              messageId: 'tournament.removeTourConfirm',
                              values: { tourName: tour.name },
                              callback: () => {
                                if (tour.id && organizationId) {
                                  const customerOrganizationId: number | undefined =
                                    this.props.auth.customerInfo?.idCustomer

                                  this.props.deleteTour(
                                    organizationId,
                                    tour.id,
                                    () =>
                                      customerOrganizationId &&
                                      this.props.fetchTours(customerOrganizationId, () =>
                                        this._createSortHandler(this.state.sortBy, this.state.direction),
                                      ),
                                  )
                                }
                              },
                            })
                          }}
                          size="large"
                        >
                          <Delete color="error" />
                        </IconButton>
                      )}
                    </DeleteConfirm>
                  </VeryDenseTableCell>
                  <VeryDenseTableCell style={{ width: 50 }}>
                    {tour.id && (
                      <Link href={formTourUrl(tour.id)} target="_blank">
                        <IconButton size="large">
                          <OpenInNew color="primary" />
                        </IconButton>
                      </Link>
                    )}
                  </VeryDenseTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </>
    )
  }

  private _renderRankings = () => {
    const { sortBy, direction } = this.state

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BorderButton
            filled
            buttonProps={{
              onClick: () => {
                this.setState({ rankingDialogOpen: true })
              },
              style: { margin: '15px 0' },
            }}
          >
            <FormattedMessageWrapper id="tourAndRanking.createNewRanking" />
          </BorderButton>
          <InfoTooltip
            text={<FormattedMessageWrapper id="tourAndRanking.createNewRankingInfo" />}
            style={{ marginLeft: 5 }}
          />
        </div>

        {this.props.tourAndRanking.loading ? (
          <CircularProgress size={28} thickness={2} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <VeryDenseTableCell sortDirection={sortBy === 'name' ? direction : false}>
                  {this._wrapWithSorting('name', <FormattedMessageWrapper id="tourAndRanking.name" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell>
                  <FormattedMessageWrapper id="tourAndRanking.active" />
                </VeryDenseTableCell>
                <VeryDenseTableCell sortDirection={sortBy === 'createdAt' ? direction : false}>
                  {this._wrapWithSorting('createdAt', <FormattedMessageWrapper id="tourAndRanking.createdAt" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell sortDirection={sortBy === 'updatedAt' ? direction : false}>
                  {this._wrapWithSorting('updatedAt', <FormattedMessageWrapper id="tourAndRanking.updatedAt" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell></VeryDenseTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.rankings.map((ranking) => (
                <TableRow key={ranking.id}>
                  <VeryDenseTableCell>{ranking.name}</VeryDenseTableCell>
                  <VeryDenseTableCell>
                    <FormattedMessageWrapper id={ranking.active ? 'tourAndRanking.yes' : 'tourAndRanking.no'} />
                  </VeryDenseTableCell>
                  <VeryDenseTableCell>
                    {ranking.createdAt && formatDate(ranking.createdAt, 'datetime', this._units)}
                  </VeryDenseTableCell>
                  <VeryDenseTableCell>
                    {ranking.updatedAt && formatDate(ranking.updatedAt, 'datetime', this._units)}
                  </VeryDenseTableCell>
                  <VeryDenseTableCell style={{ width: 50 }}>
                    <IconButton
                      onClick={() => {
                        this.setState({ rankingDialogOpen: true, ranking })
                      }}
                      size="large"
                    >
                      <Edit color="primary" />
                    </IconButton>
                  </VeryDenseTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </>
    )
  }

  private _renderMatchPlay = () => {
    return <MatchPlayBrackets />
  }

  private _wrapWithSorting = (sortByKey: string, content: any) => {
    const { sortBy = 'createdAt', direction = 'desc' } = this.state

    return (
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortBy === sortByKey ? direction : 'desc'}
        onClick={() => this._createSortHandler(sortByKey, direction === 'asc' ? 'desc' : 'asc')}
      >
        {content}
      </TableSortLabel>
    )
  }

  public _createSortHandler = (sortBy: string, direction: 'asc' | 'desc') => {
    const tours = cloneDeep(this.props.tourAndRanking.tours).sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
    const rankings = cloneDeep(this.props.tourAndRanking.rankings).sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
    this.setState({
      sortBy,
      direction,
      tours: direction === 'desc' ? tours.reverse() : tours,
      rankings: direction === 'desc' ? rankings.reverse() : rankings,
    })
  }
}

export default connect<StateProps, DispatchProps, {}, RootState>(
  (store): StateProps => ({
    auth: store.authenticationReducer,
    tourAndRanking: store.tourAndRankingReducer,
    units: store.authenticationReducer.units,
  }),
  {
    fetchRankings,
    fetchTours,
    deleteTour,
  },
)(withRouter(TourAndRanking))
