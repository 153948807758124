import React from 'react'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ToggleButton as MuiToggleButton } from '@mui/material'
import { ToggleButtonProps } from '@mui/lab'
import { rem } from '@app/theme/materialUITheme'

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    fontSize: rem(14),
    fontWeight: 500,
    borderRadius: Number(theme.shape.borderRadius) * 6,
    padding: `${rem(4)} ${rem(20)}`,
    textTransform: 'none',
    color: theme.palette.primary.main,
    height: rem(40),
    border: `${rem(2)} solid ${theme.palette.primary.main}`,
    '&.Mui-disabled': {
      border: `${rem(2)} solid ${theme.palette.action.disabled}`,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

export const ToggleButton: React.FC<ToggleButtonProps> = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <MuiToggleButton className={classes.toggleButton} {...props}>
      {children}
    </MuiToggleButton>
  )
}
