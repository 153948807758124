import { all, takeEvery, put, select } from 'redux-saga/effects'
import { getToken } from '../authentication/sagas'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import { API_ROOT, APIRoute } from '../../config'
import { pick } from 'lodash'
import {
  FETCH_TOURS,
  FETCH_TOURS_SUCCESS,
  FETCH_TOURS_FAILURE,
  FETCH_RANKINGS,
  FETCH_RANKINGS_SUCCESS,
  FETCH_RANKINGS_FAILURE,
  CREATE_RANKING_SUCCESS,
  CREATE_RANKING_FAILURE,
  CREATE_RANKING,
  UPDATE_RANKING_SUCCESS,
  UPDATE_RANKING_FAILURE,
  UPDATE_RANKING,
  DELETE_RANKING,
  DELETE_RANKING_SUCCESS,
  DELETE_RANKING_FAILURE,
  SAVE_TOUR,
  SAVE_TOUR_SUCCESS,
  SAVE_TOUR_FAILURE,
  UPLOAD_TOUR_IMAGE,
  UPLOAD_TOUR_IMAGE_FINISHED,
  DELETE_TOUR_IMAGE,
  DELETE_TOUR_IMAGE_FINISHED,
  FETCH_TOUR,
  FETCH_TOUR_SUCCESS,
  FETCH_TOUR_FAILURE,
  DELETE_TOUR_SUCCESS,
  DELETE_TOUR_FAILURE,
  DELETE_TOUR,
  SEND_RANKING_POINTS,
  SEND_RANKING_POINTS_SUCCESS,
  SEND_RANKING_POINTS_FAILURE,
} from './constants'
import { enqueueNotification } from '../notifications/actions'
import {
  FetchToursAction,
  FetchRankingsAction,
  CreateRankingAction,
  UpdateRankingAction,
  DeleteRankingAction,
  SaveTourAction,
  UploadTourImageAction,
  DeleteTourImageAction,
  FetchTourAction,
  DeleteTourAction,
  SendRankingPointsAction,
} from './actions'
import { Method } from 'axios'
import { RootState } from '..'

function* doFetchTours({ organizationId, onComplete }: FetchToursAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.TOURS(organizationId)}`,
      method: 'get',
      token,
    })

    const { data } = res

    yield put({
      type: FETCH_TOURS_SUCCESS,
      tours: data,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: FETCH_TOURS_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchTour({ organizationId, id }: FetchTourAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.TOUR(organizationId, id)}`,
      method: 'get',
      token,
    })

    const { data } = res

    yield put({
      type: FETCH_TOUR_SUCCESS,
      tour: data,
    })
  } catch (error: any) {
    yield put({
      type: FETCH_TOUR_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doDeleteTour({ organizationId, id, onComplete }: DeleteTourAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.TOUR(organizationId, id)}`,
      method: 'delete',
      token,
    })

    const { data } = res

    yield put({
      type: DELETE_TOUR_SUCCESS,
      tourId: data.id,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: DELETE_TOUR_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchRankings({ organizationId, onComplete }: FetchRankingsAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.RANKINGS(organizationId)}`,
      method: 'get',
      token,
    })

    const { data } = res

    yield put({
      type: FETCH_RANKINGS_SUCCESS,
      rankings: data,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: FETCH_RANKINGS_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doCreateRankings({ organizationId, payload, onComplete }: CreateRankingAction) {
  try {
    const token = yield getToken()

    const data = new FormData()
    data.append('name', payload.name)
    data.append('active', String(payload.active))
    data.append('configTemplate', JSON.stringify(payload.configTemplate))
    if (payload.bestResultsCount) {
      data.append('bestResultsCount', String(payload.bestResultsCount))
    }
    if (payload.logo) {
      data.append('logo', payload.logo)
    }

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.RANKINGS(organizationId)}`,
      method: 'post',
      token,
      data,
    })

    yield put({
      type: CREATE_RANKING_SUCCESS,
      ranking: res.data,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: CREATE_RANKING_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doUpdateRanking({ organizationId, id, payload, onComplete }: UpdateRankingAction) {
  try {
    const token = yield getToken()

    const data = new FormData()
    data.append('name', payload.name)
    data.append('active', String(payload.active))
    data.append('configTemplate', JSON.stringify(payload.configTemplate))
    data.append('bestResultsCount', String(payload.bestResultsCount))

    if (payload.logo) {
      data.append('logo', payload.logo)
    }

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.RANKING(organizationId, id)}`,
      method: 'post',
      token,
      data,
    })

    yield put({
      type: UPDATE_RANKING_SUCCESS,
      ranking: res.data,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: UPDATE_RANKING_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doDeleteRanking({ organizationId, id, onComplete }: DeleteRankingAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.RANKING(organizationId, id)}`,
      method: 'delete',
      token,
    })

    yield put({
      type: DELETE_RANKING_SUCCESS,
      rankingId: res.data.id,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: DELETE_RANKING_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doSendRankingPoints({ tournamentId, onComplete }: SendRankingPointsAction) {
  try {
    const token = yield getToken()
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.SEND_RANKING_POINTS(tournamentId)}`,
      method: 'post',
      token,
    })

    yield put({
      type: SEND_RANKING_POINTS_SUCCESS,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: SEND_RANKING_POINTS_FAILURE,
      error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

const getTour = (store: RootState) => store.tourAndRankingReducer.tour
const getOrganizationId = (store: RootState) =>
  store.authenticationReducer.customerInfo && store.authenticationReducer.customerInfo.idCustomer
function* doSaveTour({ onComplete }: SaveTourAction) {
  try {
    // check if new
    const tour = yield select(getTour)
    const organizationId = yield select(getOrganizationId)
    let url = `${API_ROOT}${APIRoute.TOURS(organizationId)}`
    let method: Method = 'post'
    if (tour.id) {
      url = `${API_ROOT}${APIRoute.TOUR(organizationId, tour.id)}`
      method = 'put'
    }

    const fields = ['name', 'rankingIds', 'info', 'infoTitle']
    const payload = pick(tour, fields)

    const token = yield getToken()
    const res = yield apiClientRequest({
      url,
      method,
      token,
      data: payload,
    })

    yield put({
      type: SAVE_TOUR_SUCCESS,
      tour: res.data,
    })

    if (onComplete) {
      onComplete()
    }
  } catch (error: any) {
    yield put({
      type: SAVE_TOUR_FAILURE,
      error: error,
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doUploadTourImage({ organizationId, tourId, file, fileType, onComplete }: UploadTourImageAction) {
  try {
    const token = yield getToken()

    const data = new FormData()
    data.append('file', file)
    data.append('type', fileType)

    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.POST_TOUR_IMAGE(organizationId, tourId)}`,
      method: 'post',
      token,
      data,
    })

    yield put({
      type: UPLOAD_TOUR_IMAGE_FINISHED,
    })

    defaultOnCompleteCall(onComplete, response)
  } catch (e) {
    defaultOnCompleteCall(onComplete, null, e)
    yield put(enqueueNotification(e, 'error'))
    yield put({
      type: UPLOAD_TOUR_IMAGE_FINISHED,
    })
  }
}

function* doDeleteTourImage({ organizationId, tourId, id, onComplete }: DeleteTourImageAction) {
  try {
    const token = yield getToken()
    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.DELETE_TOUR_IMAGE(organizationId, tourId, id)}`,
      method: 'delete',
      token,
    })

    yield put({
      type: DELETE_TOUR_IMAGE_FINISHED,
    })

    defaultOnCompleteCall(onComplete, response)
  } catch (e) {
    defaultOnCompleteCall(onComplete, null, e)
    yield put(enqueueNotification(e, 'error'))
    yield put({
      type: DELETE_TOUR_IMAGE_FINISHED,
    })
  }
}

export function* watchTourAndRanking() {
  yield all([
    takeEvery(FETCH_TOURS, doFetchTours),
    takeEvery(FETCH_RANKINGS, doFetchRankings),
    takeEvery(CREATE_RANKING, doCreateRankings),
    takeEvery(UPDATE_RANKING, doUpdateRanking),
    takeEvery(DELETE_RANKING, doDeleteRanking),
    takeEvery(SAVE_TOUR, doSaveTour),
    takeEvery(UPLOAD_TOUR_IMAGE, doUploadTourImage),
    takeEvery(DELETE_TOUR_IMAGE, doDeleteTourImage),
    takeEvery(FETCH_TOUR, doFetchTour),
    takeEvery(DELETE_TOUR, doDeleteTour),
    takeEvery(SEND_RANKING_POINTS, doSendRankingPoints),
  ])
}
