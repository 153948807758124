import { Action } from 'redux'
import { USERS_FETCH, USERS_FETCH_ERROR, USERS_FETCH_SUCCESS } from './constants'

export interface FetchUsersAction extends Action, PagedAction, APICall {
  type: 'USERS_FETCH'
  organizationId: number
}

export const fetchUsers = (
  organizationId: number,
  page?: number,
  onComplete?: (args: APICallResult) => void,
): FetchUsersAction => ({
  type: USERS_FETCH,
  organizationId,
  page,
  onComplete,
})

export interface UsersFetchSuccessAction extends Action, SuccessAction {
  type: 'USERS_FETCH_SUCCESS'
  users: OrganizationUser[]
}

export const putUsersSuccess = (users: OrganizationUser[]): UsersFetchSuccessAction => ({
  type: USERS_FETCH_SUCCESS,
  users,
})

export interface UsersFetchErrorAction extends Action {
  type: 'USERS_FETCH_ERROR'
  error: ErrorPayload
}

export const putUsersError = (error: string): UsersFetchErrorAction => ({
  type: USERS_FETCH_ERROR,
  error: {
    error,
  },
})
