import React from 'react'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import {
  IconCenterArrow,
  IconLeftArrow,
  IconRightArrow,
} from '../../assets/images/initialStrokeIcons/InitialStrokeIcons'

interface Props {
  disabled?: boolean
  value?: string
  onChange?(event: SelectChangeEvent<string>): void
}

class InitialStrokePicker extends React.Component<Props> {
  render() {
    const { value = '', onChange, disabled = false } = this.props

    const sharedStyles: React.CSSProperties = {
      width: 20,
      height: 20,
    }

    return (
      <FormControl disabled={disabled}>
        <Select
          value={value !== null ? value : ''}
          onChange={onChange}
          inputProps={{
            name: 'initialStroke',
            id: 'initial-stroke',
          }}
          style={{
            minHeight: 37,
            minWidth: 44,
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <MenuItem value={''} />
          <MenuItem value={'left'}>
            <img style={sharedStyles} src={IconLeftArrow} alt={'fairway-left'} />
          </MenuItem>
          <MenuItem value={'fairway'}>
            <img style={sharedStyles} src={IconCenterArrow} alt={'fairway'} />
          </MenuItem>
          <MenuItem value={'right'}>
            <img style={sharedStyles} src={IconRightArrow} alt={'fairway-right'} />
          </MenuItem>
        </Select>
      </FormControl>
    )
  }
}

export default InitialStrokePicker
