import React from 'react'
import { connect } from 'react-redux'
import { Menu, Fab, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import MenuItem from '@mui/material/MenuItem'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { logout } from '../../store/authentication/actions'
import get from 'lodash/get'
import OwnInformationDialog from '../dialogs/organizationUser/OwnInformationDialog'
import { setTheme } from '../../store/theme/actions'
import { Person, Brightness6, ExitToApp } from '@mui/icons-material'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    fab: {
      height: 40,
      width: 40,
      marginRight: 15,
      background: theme.customPalette.light,
      fontFamily: ['Exo', 'sans-serif'].join(','),
      fontWeight: 800,
      fontStyle: 'italic',
      color: theme.palette.primary.main,
      lineHeight: '43px',
      textTransform: 'uppercase',
      boxShadow: 'none',
    },
    icon: {
      marginRight: 10,
    },
  })

interface StateProps {
  auth: AuthenticationState
  theme: ThemeState
}

interface DispatchProps {
  logout: () => void
  setTheme: (theme: string) => any
}

type Props = StateProps & DispatchProps & WithRouterProps & WithStyles<typeof styles>

interface State {
  anchorEl: any
  modalOpen: boolean
}

class NavToolbarProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      anchorEl: null,
      modalOpen: false,
    }
  }

  render() {
    const { auth, theme, classes } = this.props
    const { anchorEl } = this.state

    if (!auth.loggedIn) {
      return null
    }

    const name = get(auth, 'name')

    return (
      <>
        {name && (
          <Fab aria-haspopup="true" onClick={this._handleClick} className={classes.fab}>
            {this._createAcronym(name)}
          </Fab>
        )}
        <Menu id="profile-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this._handleClose}>
          {/* no my info so lets remove it */}
          <MenuItem onClick={this._handleSettingsClick}>
            <Person color={'primary'} className={classes.icon} />
            <FormattedMessageWrapper id="buttons.myInfo" />
          </MenuItem>
          <MenuItem onClick={this._changeTheme}>
            <Brightness6 color={'primary'} className={classes.icon} />
            <FormattedMessageWrapper id={theme.theme === 'light' ? 'buttons.useDarkTheme' : 'buttons.useLightTheme'} />
          </MenuItem>
          <MenuItem onClick={this._handleLogout}>
            <ExitToApp color={'primary'} className={classes.icon} />
            <FormattedMessageWrapper id="buttons.logout" />
          </MenuItem>
        </Menu>

        <OwnInformationDialog
          open={this.state.modalOpen}
          onClose={() => {
            this.setState({ modalOpen: false })
          }}
        />
      </>
    )
  }

  private _createAcronym = (name) => {
    return name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '')
  }

  private _handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  private _handleClose = () => {
    this.setState({ anchorEl: null })
  }

  private _handleSettingsClick = (): void => {
    this._handleClose()
    this.setState({ modalOpen: true })
  }

  private _handleLogout = (): void => {
    this._handleClose()
    this.props.logout()
  }

  private _changeTheme = (): void => {
    const { setTheme } = this.props
    let theme = 'dark'
    if (localStorage.theme) {
      theme = localStorage.theme === 'light' ? 'dark' : 'light'
    }
    setTheme(theme)
  }
}

const RoutedNavToolbarProfile = withRouter(NavToolbarProfile)
const StyledNavToolbarProfile = withStyles(styles)(RoutedNavToolbarProfile)
export default connect<StateProps, DispatchProps, {}, RootState>(
  (state) => ({
    auth: state.authenticationReducer,
    theme: state.themeReducer,
  }),
  { logout, setTheme },
)(StyledNavToolbarProfile as any)
