export const DIVISIONS_FETCH = 'DIVISIONS_FETCH'
export const DIVISIONS_FETCH_SUCCESS = 'DIVISIONS_FETCH_SUCCESS'
export const DIVISIONS_FETCH_FAILURE = 'DIVISIONS_FETCH_FAILURE'

export const DIVISION_FETCH = 'DIVISION_FETCH'
export const DIVISION_FETCH_SUCCESS = 'DIVISION_FETCH_SUCCESS'
export const DIVISION_FETCH_FAILURE = 'DIVISION_FETCH_FAILURE'

export const DIVISION_CREATE = 'DIVISION_CREATE'
export const DIVISION_CREATE_SUCCESS = 'DIVISION_CREATE_SUCCESS'
export const DIVISION_CREATE_FAILURE = 'DIVISION_CREATE_FAILURE'

export const DIVISION_UPDATE = 'DIVISION_UPDATE'
export const DIVISION_UPDATE_SUCCESS = 'DIVISION_UPDATE_SUCCESS'
export const DIVISION_UPDATE_FAILURE = 'DIVISION_UPDATE_FAILURE'

export const DIVISION_DELETE = 'DIVISION_DELETE'
export const DIVISION_DELETE_SUCCESS = 'DIVISION_DELETE_SUCCESS'
export const DIVISION_DELETE_FAILURE = 'DIVISION_DELETE_FAILURE'

export const DIVISIONS_SORT = 'DIVISIONS_SORT'

export const SET_SELECTED_DIVISION = 'SET_SELECTED_DIVISION'
