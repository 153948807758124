import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { Button, Theme, Tooltip, FormControl, FormLabel, Divider } from '@mui/material'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { rem } from '../../theme/materialUITheme'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { LoginBg } from '../../assets/images'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { createOrganizationApplication, CreateOrganizationApplicationPayload } from '../../store/organization/actions'
import CountryPicker from '../../components/tournament/CountryPicker'
import InputsValidator from '../../components/forms/InputsValidator'
import TextField from '@mui/material/TextField'
import ButtonLoaderWrap from '../../components/ui/ButtonLoaderWrap'
import { confirm } from '../../components/dialogs/confirm/Confirm'
import CountryStatePicker from '../../components/tournament/CountryStatePicker'
import HeadHelmet from '../../components/layout/HeadHelmet'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import debounce from 'lodash/debounce'
import FormLabelWithInfo from '../../components/ui/FormLabelWithInfo'
import InfoTooltip from '../../components/ui/InfoTooltip'
import { Autocomplete } from '@app/components/forms/Autocomplete'
import { getSiteLogo, getSiteLogo2x } from '@app/utils/logoUtils'
import LanguageSwitcher from '@app/components/layout/LanguageSwitcher'
import { RootState } from '@app/store'
import { searchClubs } from '@app/store/api/thunks/clubsAndCoursesThunks'
import { selectClubs } from '@app/store/api/slices/clubsAndCoursesSlice'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      padding: '50px 0 280px 0',
    },
    form: {
      maxWidth: rem(760),
      margin: '0 auto',
    },
    paper: {
      padding: '16px 24px',
    },
    actionWrap: {
      justifyContent: 'flex-end',
      paddingLeft: 12,
      paddingRight: 12,
      display: 'flex',
    },
    acceptTerms: {
      '@global': {
        'a, a:link': {
          color: theme.palette.primary.main,
        },
      },
    },
    languageSwitcherContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  })

interface StateProps {
  isLoading: boolean
  clubs?: Club[]
}

interface DispatchProps {
  createOrganizationApplication(args: CreateOrganizationApplicationPayload): void
  searchClubs: (payload: SearchClubsPayload, publicSearch?: boolean) => void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps & WithRouterProps

interface State {
  name?: string
  homepage?: string
  isGolfClub: boolean
  club?: number
  clubVal?: { value: number; label: string }
  clubSearchTerm: string
  countryId?: number
  stateId?: number
  contactPersonName?: string
  contactPersonTitle?: string
  contactPersonEmail?: string
  acceptTerms: boolean
  error: string
}

const initialState: State = {
  name: '',
  homepage: '',
  isGolfClub: false,
  club: undefined,
  clubVal: undefined,
  clubSearchTerm: '',
  countryId: undefined,
  stateId: undefined,
  contactPersonName: '',
  contactPersonTitle: '',
  contactPersonEmail: '',
  acceptTerms: false,
  error: '',
}

class Register extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  render() {
    const {
      name,
      homepage,
      isGolfClub,
      clubVal,
      clubSearchTerm,
      countryId,
      stateId,
      contactPersonName,
      contactPersonTitle,
      contactPersonEmail,
    } = this.state

    return (
      <>
        <HeadHelmet titleId={'navigation.apply'} />

        <div className={this.props.classes.root} style={{ backgroundImage: `url(${LoginBg})` }}>
          <div className={this.props.classes.languageSwitcherContainer}>
            <LanguageSwitcher />
          </div>
          <InputsValidator
            values={{
              name,
              countryId,
              contactPersonName,
              contactPersonTitle,
              contactPersonEmail,
            }}
            options={{
              contactPersonEmail: 'email',
            }}
          >
            {({ errorInputs, validateThenApply, validateValues }) => (
              <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault()
                  validateThenApply(() => this._submit())
                }}
                className={this.props.classes.form}
                noValidate={true}
              >
                <Paper className={this.props.classes.paper}>
                  <Grid container={true} spacing={3}>
                    <Grid item xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <img
                          style={{
                            width: 265,
                            height: 65,
                          }}
                          alt={'GGB'}
                          src={getSiteLogo()}
                          srcSet={`${getSiteLogo2x()} 2x`}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Typography variant="h2" style={{ justifyContent: 'center' }}>
                        <FormattedMessageWrapper id="register.accountApplication" />
                      </Typography>
                      <Divider />
                    </Grid>

                    <Grid item={true} xs={12} style={{ paddingBottom: 10 }}>
                      <Typography variant={'h4'}>
                        <FormattedMessageWrapper id={'register.organization'} />
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <FormLabelWithInfo
                        text={<FormattedMessageWrapper id={'register.name'} />}
                        infoText={<FormattedMessageWrapper id={'register.nameInfo'} />}
                        required={true}
                        error={errorInputs.name}
                      />
                      <TextField
                        id="name"
                        helperText={<FormattedMessageWrapper id={'register.nameHelp'} />}
                        value={name}
                        required={true}
                        fullWidth={true}
                        error={errorInputs.name}
                        onChange={this._onChange('name', validateValues)}
                      />
                    </Grid>
                    <Grid item={true} xs={6}>
                      <FormLabel>
                        <FormattedMessageWrapper id={'register.homepage'} />
                      </FormLabel>
                      <TextField
                        fullWidth={true}
                        id="homepage"
                        value={homepage}
                        onChange={this._onChange('homepage', validateValues)}
                      />
                    </Grid>

                    <Grid item={true} xs={6}>
                      <CountryPicker
                        required={true}
                        countryId={countryId}
                        onChange={this._selectCountry}
                        error={errorInputs.countryId}
                        handleStatesFetch={true}
                      />
                    </Grid>
                    <Grid item={true} xs={6}>
                      <CountryStatePicker
                        hideIfEmpty={true}
                        required={false}
                        countryId={countryId}
                        stateId={stateId}
                        onChange={(event) => {
                          this.setState({
                            stateId: parseInt(String(event.target.value), 10),
                          })
                        }}
                      />
                    </Grid>

                    <Grid item={true} xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="isGolfClub"
                            checked={isGolfClub}
                            onChange={(_, isGolfClub) => {
                              this.setState({ isGolfClub })
                            }}
                          />
                        }
                        label={
                          <>
                            <FormattedMessageWrapper id={'register.organizationIsGolfClub'} />
                            <InfoTooltip
                              text={<FormattedMessageWrapper id={'register.organizationIsGolfClubInfo'} />}
                              style={{ position: 'relative', top: 4, left: 5 }}
                            />
                          </>
                        }
                      />

                      {isGolfClub && (
                        <Tooltip
                          disableTouchListener={!!countryId}
                          disableHoverListener={!!countryId}
                          disableFocusListener={!!countryId}
                          title={<FormattedMessageWrapper id="register.chooseCountryFirst" />}
                        >
                          <FormControl margin="dense" fullWidth>
                            <FormLabel style={{ margin: '0 0 10px 0' }}>
                              <FormattedMessageWrapper id="register.club" />
                            </FormLabel>
                            <Autocomplete
                              value={clubVal}
                              onChange={(val) => this.setState({ clubVal: val, club: val ? val.value : undefined })}
                              options={this._clubItems}
                              inputValue={clubSearchTerm}
                              onInputChange={(val) => {
                                this.setState({ clubSearchTerm: val })
                                this._fetchClubs()
                              }}
                              disabled={!countryId}
                            />
                          </FormControl>
                        </Tooltip>
                      )}
                    </Grid>

                    <Grid item={true} xs={12} style={{ marginTop: 28, paddingBottom: 0 }}>
                      <Typography variant={'h4'}>
                        <FormattedMessageWrapper id={'register.person'} />
                      </Typography>
                    </Grid>

                    <Grid item={true} xs={6}>
                      <FormLabel required={true} error={errorInputs.contactPersonName}>
                        <FormattedMessageWrapper id={'register.contactPersonName'} />
                      </FormLabel>
                      <TextField
                        fullWidth={true}
                        id="name"
                        value={contactPersonName}
                        required={true}
                        error={errorInputs.contactPersonName}
                        onChange={this._onChange('contactPersonName', validateValues)}
                      />
                    </Grid>
                    <Grid item={true} xs={6}>
                      <FormLabel required={true} error={errorInputs.contactPersonEmail}>
                        <FormattedMessageWrapper id={'register.contactPersonEmail'} />
                      </FormLabel>
                      <TextField
                        fullWidth={true}
                        id="name"
                        value={contactPersonEmail}
                        required={true}
                        error={errorInputs.contactPersonEmail}
                        onChange={this._onChange('contactPersonEmail', validateValues)}
                        helperText={<FormattedMessageWrapper id={'register.contactPersonEmailHelp'} />}
                      />
                    </Grid>

                    <Grid item={true} xs={6}>
                      <FormLabel required={true} error={errorInputs.contactPersonTitle}>
                        <FormattedMessageWrapper id={'register.contactPersonTitle'} />
                      </FormLabel>
                      <TextField
                        fullWidth={true}
                        id="name"
                        value={contactPersonTitle}
                        required={true}
                        error={errorInputs.contactPersonTitle}
                        onChange={this._onChange('contactPersonTitle', validateValues)}
                        helperText={<FormattedMessageWrapper id={'register.contactPersonTitleHelp'} />}
                      />
                    </Grid>

                    <Grid item={true} xs={12}>
                      <div style={{ textAlign: 'right' }}>
                        <Typography variant={'body1'} className={this.props.classes.acceptTerms}>
                          <FormattedMessageWrapper id={'register.acceptTerms'} hasLink />
                          <Checkbox
                            checked={this.state.acceptTerms}
                            onChange={(_, acceptTerms) => this.setState({ acceptTerms })}
                            color="primary"
                          />
                        </Typography>
                      </div>
                    </Grid>

                    {this.state.error.length > 0 && (
                      <Grid item={true} xs={6}>
                        <Typography variant="body1" color="error">
                          {this.state.error}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item={true}
                      xs={this.state.error.length > 0 ? 6 : 12}
                      className={this.props.classes.actionWrap}
                    >
                      <ButtonLoaderWrap loading={this.props.isLoading}>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={this.props.isLoading || !this.state.acceptTerms}
                        >
                          <FormattedMessageWrapper id={'buttons.submit'} />
                        </Button>
                      </ButtonLoaderWrap>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            )}
          </InputsValidator>
        </div>
      </>
    )
  }

  public _submit = () => {
    this.props.createOrganizationApplication({
      organizationApplication: this.state as OrganizationApplicationForm,
      onComplete: this._handleOnComplete,
    })
  }

  public _onChange =
    (inputName: string, fn?: () => any) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      this.setState(
        {
          [inputName]: event.target.value,
        } as any,
        fn,
      )
    }

  public _selectCountry = (event) => {
    const value = parseInt(event.target.value, 10)
    this.setState({
      countryId: value,
    })
  }

  public _handleOnComplete = ({ error }: APICallResult) => {
    if (error) {
      this.setState({ error: String(error) })
      return
    }

    this.setState(
      {
        ...initialState,
      },
      () => {
        confirm({
          message: this.props.intl.formatMessage({ id: 'register.success' }),
          options: {
            okText: this.props.intl.formatMessage({ id: 'buttons.ok' }),
          },
        }).then(() => {
          this.props.navigate('/login')
        })
      },
    )
  }

  private get _clubItems() {
    if (!this.props.clubs) {
      return []
    }
    return this.props.clubs.map((club) => ({
      value: club.id,
      label: club.name,
    }))
  }

  private _fetchClubs = debounce(() => {
    const { countryId, clubSearchTerm } = this.state

    if (countryId && clubSearchTerm) {
      const isPublicSearch = true
      this.props.searchClubs({ searchTerm: clubSearchTerm, countryId }, isPublicSearch)
    }
  }, 400)
}

export default injectIntl(
  withStyles(styles)(
    connect<StateProps, DispatchProps, {}, RootState>(
      (store) => ({
        isLoading: store.organizationReducer.loading,
        clubs: selectClubs(store),
      }),
      {
        createOrganizationApplication,
        searchClubs,
      },
    )(withRouter(Register)),
  ),
)
