import React from 'react'
import StartGroupHeader from './StartGroupHeader'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { PLAYER_LIST_ITEM_HEIGHT } from './ListPlayer'
import { StartGroupPlayerItems } from './StartGroupPlayerItems'
import { PlayerDialogChildrenArgs } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import ErrorTooltip from '../ui/ErrorTooltip'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { isSpecialTeamFormatId } from '../../utils/gameFormatUtils'
import { DragIndicator } from '@mui/icons-material'

interface OwnProps {
  group: StartListGroup
  index: number
  basicGroupSize: number
  confirmPlayerRemove?: boolean
  primaryGameFormatId: number
  selectedRoundId: number
  classes: {
    dropBox: string
    outerBox: string
  }
  removePlayerAction?: (playerId: number) => void
  isTwoManTeamFormat: boolean
  groupTeamIndex: number
  disableTeamPlayerEdit?: boolean
  disableTee?: boolean
}

interface State {
  collapsedGroups: number[]
}

class StartGroupTeam extends React.Component<OwnProps & PlayerDialogChildrenArgs, State> {
  constructor(props) {
    super(props)

    this.state = {
      collapsedGroups: [],
    }
  }

  render() {
    const { classes, groupTeamIndex, basicGroupSize, isTwoManTeamFormat, selectedRoundId } = this.props
    const minHeight = (basicGroupSize + (isTwoManTeamFormat ? 2.5 : 1.5)) * PLAYER_LIST_ITEM_HEIGHT
    return (
      <>
        <StartGroupHeader
          index={this.props.index}
          group={this.props.group}
          expanded={this._isGroupCollapsed(this.props.index)}
        />
        <Droppable droppableId={`${this.draggableId}-groupTeam-${groupTeamIndex}`} type="droppableTeam">
          {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
            <div
              ref={dropProvided.innerRef}
              className={classes.dropBox}
              style={{ minHeight: `${minHeight}px`, backgroundColor: dropSnapshot.isDraggingOver ? '#ddd' : undefined }}
              {...dropProvided.droppableProps}
            >
              {this._teams.map((team: StartListGroupTeam, idx: number) => {
                const tooManyPlayers = this._tooManyPlayers(team.players)
                const border = tooManyPlayers ? '1px solid #f44336' : ''
                const padding = tooManyPlayers ? '5px 5px 0' : ''
                const startListPlayers = team.players.map(
                  (player) => player.startListPlayers && player.startListPlayers[selectedRoundId],
                )

                return (
                  <Draggable
                    draggableId={`${this.draggableId}-team-${team.tournamentTeamId}`}
                    index={groupTeamIndex + idx}
                    key={`${team.tournamentTeamId}${idx}`}
                  >
                    {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
                      const content = (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          style={dragProvided.draggableProps.style}
                          key={idx}
                        >
                          <div
                            className={classes.dropBox}
                            style={{
                              border,
                              padding,
                              borderRadius: 4,
                              opacity: dragSnapshot.isDragging ? 0.7 : 1,
                              width: dragSnapshot.isDragging ? 400 : 'auto',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div {...dragProvided.dragHandleProps}>
                                <DragIndicator color="primary" />
                              </div>
                              <StartGroupHeader
                                index={this.props.index}
                                group={this.props.group}
                                hideGroupInfo={true}
                                team={team}
                                players={startListPlayers as StartListPlayer[]}
                              />
                            </div>
                            {this._renderTeam(team.players, idx)}
                          </div>
                        </div>
                      )

                      if (tooManyPlayers) {
                        return (
                          <ErrorTooltip
                            title={<FormattedMessageWrapper id={'tournament.tooManyPlayersInTeam'} />}
                            placement={'bottom'}
                          >
                            {content}
                          </ErrorTooltip>
                        )
                      }

                      return content
                    }}
                  </Draggable>
                )
              })}
            </div>
          )}
        </Droppable>
      </>
    )
  }

  private _tooManyPlayers = (players: TournamentPlayer[]) => {
    return this.props.isTwoManTeamFormat ? players.length > 2 : players.length > 5
  }

  private _isGroupCollapsed = (i: number) => {
    if (this.state.collapsedGroups.includes(i)) {
      return false
    } else {
      return true
    }
  }

  private get _teams() {
    const { group } = this.props
    return group.teams || []
  }

  private _renderTeam = (players: TournamentPlayer[], teamIndex) => {
    const { classes, basicGroupSize, selectedRoundId } = this.props
    const minHeight = !this._isTwoTeamGroup
      ? basicGroupSize * (PLAYER_LIST_ITEM_HEIGHT + 1)
      : 2.5 * PLAYER_LIST_ITEM_HEIGHT

    const startListPlayers = players.map(
      (player) => player.startListPlayers && player.startListPlayers[selectedRoundId],
    )

    startListPlayers.sort((a, b) => (a && b ? a.groupOrder - b.groupOrder : 0))

    return (
      <Droppable droppableId={`${this.draggableId}-team-${teamIndex}`} type="droppablePlayer">
        {(dropProvided: DroppableProvided) => (
          <div
            className={classes.dropBox}
            ref={dropProvided.innerRef}
            style={{
              minHeight,
              // color: dragSnapshot.isDragging ? 'rgba(255, 255, 255, 0.1)' : undefined,
            }}
            {...dropProvided.droppableProps}
          >
            <StartGroupPlayerItems
              players={startListPlayers as StartListPlayer[]}
              openPlayerDialog={this.props.openPlayerDialog}
              onClosePlayerDialog={this.props.onClosePlayerDialog}
              confirmPlayerRemove={this.props.confirmPlayerRemove}
              removeAction={this._removePlayerAction}
              isSpecialTeamFormat={isSpecialTeamFormatId(this.props.primaryGameFormatId)}
              hideDrag={true}
              hideDeleteIcon={true}
              disableTee={this.props.disableTee}
            />
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }

  get _isTwoTeamGroup() {
    const { isTwoManTeamFormat, basicGroupSize } = this.props
    return isTwoManTeamFormat && basicGroupSize > 3
  }

  private get draggableId() {
    const {
      group: { id },
    } = this.props

    if (id < 0) {
      // the id's are transformed into positive integers here as they're used
      // in string based id's
      return `newgroup-${id * -1}`
    }
    return `group-${id}`
  }

  public _removePlayerAction = (playerId: number) => {
    if (this.props.removePlayerAction) {
      this.props.removePlayerAction(playerId)
    }
  }
}

export default StartGroupTeam
