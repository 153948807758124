import React from 'react'
import { Button, Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import AddIcon from '@mui/icons-material/Add'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { addRound } from '@app/store/api/thunks/tournamentThunks'
import { setSelectedRound } from '@app/store/api/thunks/configThunks'
import SectionTitle from '@app/components/ui/SectionTitle'
import { rem } from '@app/theme/materialUITheme'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { MAX_AMOUNT_OF_ROUNDS } from '@app/config'
import NoWrapTooltip from '@app/components/ui/NoWrapTooltip'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
    },
    roundItem: {
      width: rem(50),
      height: rem(50),
      minWidth: 0,
      color: '#fff',
      fontWeight: 'bold',
      fontSize: rem(20),
      marginRight: rem(10),

      '&.MuiButton-outlined': {
        color: theme.palette.secondary.main,
      },
    },
    roundWrapper: {
      display: 'inline-block',
      marginRight: rem(40),
    },
    addRoundButton: {
      borderRadius: rem(20),
    },
  })

interface OwnProps {
  isAddRoundVisible?: boolean
}

interface StateProps {
  tournamentState: TournamentState
  tournamentConfig: TournamentConfig
}

interface DispatchProps {
  setSelectedRound: (roundId: number) => void
  addRound: () => void
}

interface State {
  loading: boolean
}

const initialState = {
  loading: false,
}

type RoundSelectionComponentProps = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WrappedComponentProps

class RoundSelectionComponent extends React.Component<RoundSelectionComponentProps, State> {
  readonly state = initialState

  componentDidMount() {
    const { tournamentState, tournamentConfig, isAddRoundVisible } = this.props
    const { rounds } = tournamentState
    const { selectedRoundId } = tournamentConfig
    const isGroupsAndStartListPage = isAddRoundVisible === false
    if (isGroupsAndStartListPage) {
      const ongoingRounds = rounds.filter((r) => r.status?.isCompleted !== true)
      const currentRoundId = ongoingRounds[0]?.id
      if (currentRoundId && currentRoundId !== selectedRoundId) {
        this.props.setSelectedRound(currentRoundId)
      }
    }
  }

  public render() {
    const { classes, tournamentState, tournamentConfig, isAddRoundVisible = true } = this.props

    const { rounds } = tournamentState
    const { selectedRoundId, isDirty } = tournamentConfig
    if (this.state.loading) {
      return null
    }

    return (
      <div className={classes.root}>
        <Grid container={true} alignItems="center">
          <Grid item xs={2}>
            <SectionTitle style={{ marginBottom: 'auto' }}>
              <FormattedMessageWrapper id="tournament.selectRound" />
            </SectionTitle>
          </Grid>
          <Grid item xs={10}>
            <div className={classes.roundWrapper}>
              {rounds.map((round, idx) => (
                <Button
                  disabled={isDirty}
                  key={round.id}
                  onClick={() => this.onRoundClick(round.id)}
                  className={classes.roundItem}
                  variant={selectedRoundId === round.id ? 'contained' : 'outlined'}
                  color="secondary"
                >
                  {idx + 1}
                </Button>
              ))}
            </div>
            {isAddRoundVisible && this.renderAddRoundButton()}
          </Grid>
        </Grid>
      </div>
    )
  }

  get isAddRoundDisabled(): boolean {
    const { rounds } = this.props.tournamentState
    return rounds.some((round) => round.id === -1) || rounds.length >= MAX_AMOUNT_OF_ROUNDS
  }

  onRoundClick = (roundId: number): void => {
    this.props.setSelectedRound(roundId)
  }

  renderOnlyButton = (): React.ReactNode => {
    const { classes, tournamentConfig } = this.props
    return (
      <Button
        disabled={this.isAddRoundDisabled || tournamentConfig.isDirty}
        onClick={this.props.addRound}
        className={classes.addRoundButton}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
      >
        <FormattedMessageWrapper id="tournament.addRound" />
      </Button>
    )
  }

  renderAddRoundButton = (): React.ReactNode => {
    const { intl, tournamentState } = this.props
    const savedTournamentRounds = tournamentState.rounds.filter((round) => round.id !== -1)
    if (savedTournamentRounds.length >= MAX_AMOUNT_OF_ROUNDS) {
      return (
        <NoWrapTooltip title={intl.formatMessage({ id: 'tournament.maximumNumberOfRoundsReached' })} arrow>
          <span>{this.renderOnlyButton()}</span>
        </NoWrapTooltip>
      )
    }
    return this.renderOnlyButton()
  }
}

export const RoundSelection = connect<StateProps, DispatchProps, {}, RootState>(
  (store) => ({
    tournamentState: selectTournament(store),
    tournamentConfig: selectTournamentConfig(store),
  }),
  {
    setSelectedRound,
    addRound,
  },
)(withStyles(styles)(injectIntl(RoundSelectionComponent as any)))
