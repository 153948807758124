import { SetTheme } from './actions'
import { createReducer } from 'typesafe-actions'

const initialState: ThemeState = {
  theme: localStorage.getItem('theme') || 'light',
}

const storeInit = (state: ThemeState) => {
  return {
    ...state,
  }
}

const setTheme = (state: ThemeState, action: SetTheme) => {
  const { theme } = action
  localStorage.setItem('theme', theme)

  return {
    ...state,
    theme,
  }
}

export const themeReducer = createReducer(initialState, {
  '@@INIT': storeInit,
  SET_THEME: setTheme,
})
