import React from 'react'
import { TextField } from '@mui/material'
import MUIAutocomplete, {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteProps as MUIAutocompleteProps,
} from '@mui/material/Autocomplete'
import { SearchIcon } from '@app/assets/icons'

export type AutocompleteItem = {
  value: number
  label: string
}

/**
 * Simplify change events payload.
 */
interface AutocompleteProps
  extends Omit<MUIAutocompleteProps<any, any, any, any>, 'onChange' | 'renderInput' | 'onInputChange'> {
  onChange: (item?: AutocompleteItem, reason?: AutocompleteChangeReason) => void
  onInputChange: (value: string, reason?: AutocompleteInputChangeReason) => void
  placeholder?: string
  startAdornment?: React.ReactNode
}

export const Autocomplete = ({
  onChange,
  onInputChange,
  options,
  inputValue,
  value,
  placeholder,
  startAdornment,
  ...rest
}: AutocompleteProps) => {
  return (
    <MUIAutocomplete
      disablePortal
      fullWidth
      {...rest}
      options={options || []}
      inputValue={inputValue || ''}
      value={value || ''}
      onChange={(_, val, reason) => onChange(val, reason)}
      onInputChange={(_, val, reason) => onInputChange(val, reason)}
      getOptionLabel={(option) => option.label || ''}
      renderOption={(props, option) => (
        <span {...props}>
          {startAdornment && <SearchIcon stroke="#BBBBBD" style={{ marginLeft: 5, marginRight: 10 }} />} {option?.label}
        </span>
      )}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} InputProps={{ ...params.InputProps, startAdornment }} />
      )}
    />
  )
}
