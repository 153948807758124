import React from 'react'
import Dialog from '@mui/material/Dialog'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import PlayerScore from './PlayerScore'
import TeamScores from './TeamScores'
import { IntlShape } from 'react-intl'
import { formatDate } from '../../../utils/dates'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'

interface OwnProps {
  open?: boolean
  onClose?(refetch?: boolean): void
  playerScore?: TournamentLeaderboardPlayer
  teamScores?: TournamentLeaderboardPlayer[]
  roundCourses?: RoundCourse[]
  tournament: TournamentState
  startlists?: TournamentStartListState
  team?: TournamentLeaderboardPlayer
  intl: IntlShape
  onTeamUpdate?(teamId: number, status: string, name: string): void
  isSpecialTeam?: boolean
  units: OrganizationUnits
}

class ScoreDialog extends React.Component<OwnProps, {}> {
  render() {
    const {
      open = false,
      playerScore,
      teamScores,
      roundCourses,
      tournament,
      team,
      onClose,
      startlists,
      intl,
      units,
    } = this.props
    if ((!playerScore && !teamScores) || !roundCourses) {
      return null
    }

    let title = ''
    let subtitle = ''

    const playerName = playerScore?.name || ''
    title = playerName
    subtitle = tournament.name

    if (team) {
      title = team.name || 'Team'
    }

    if (startlists && startlists.rounds[0] && playerScore && tournament.tournamentType !== TournamentTypes.multiRound) {
      const playerId = Number(playerScore.userId)
      const startListGroup = startlists.rounds[0].groups.find((g) => g.startListPlayers.find((p) => p.id === playerId))
      const startListPlayer = startListGroup ? startListGroup.startListPlayers.find((p) => p.id === playerId) : null

      if (startListGroup && startListPlayer) {
        const hcp = startListPlayer.hcp ? ` - HCP ${startListPlayer.hcp}` : ''
        title = playerName + hcp
        subtitle = [
          tournament.name,
          `${intl.formatMessage({ id: 'tournament.phcp' })}: ${startListPlayer.playingHandicap}`,
          `${intl.formatMessage({ id: 'tournament.tee' })}: ${startListPlayer.teeBox?.teeboxName || ''}`,
          `${intl.formatMessage({ id: 'tournament.startHole' })}: ${startListGroup.teeNumber}`,
          `${intl.formatMessage({ id: 'tournament.startTime' })}: ${formatDate(
            startListGroup.startTime,
            'time',
            units,
          )}`,
        ].join(', ')
      }
    }

    return (
      <Dialog
        maxWidth={'lg'}
        open={open}
        aria-labelledby={'player-score-dialog-title'}
        scroll={'body'}
        fullWidth
        onClose={(_, reason) => reason !== 'backdropClick' && onClose && onClose(true)}
      >
        <BaseDialogTitle
          id={'player-score-dialog-title'}
          title={title}
          subtitle={subtitle}
          onClose={() => onClose && onClose(true)}
        />
        {this._renderScore()}
      </Dialog>
    )
  }

  private _renderScore = () => {
    const { playerScore, teamScores, tournament, onClose, isSpecialTeam, team, intl, units } = this.props

    if (teamScores && !isSpecialTeam) {
      return (
        <TeamScores
          scores={teamScores}
          onClose={() => onClose && onClose(true)}
          tournament={tournament}
          teamId={team?.teamId}
        />
      )
    } else {
      return (
        <PlayerScore
          onClose={() => onClose && onClose(true)}
          score={playerScore}
          isTeam={false}
          teamId={team?.teamId}
          intl={intl}
          units={units}
        />
      )
    }
  }
}

export default ScoreDialog
