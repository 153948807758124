import React, { ReactNode } from 'react'
import { Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { get } from 'lodash'
import { connect } from 'react-redux'
import {
  tournamentPlayersChangeExtraInfo,
  updatePlayersSelection,
  clearSelectedPlayers,
} from '@store/api/thunks/tournamentPlayersThunks'
import { ExtraInfo, TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { rem } from '@app/theme/materialUITheme'
import { PlayerPoolTabs } from './PlayerPoolTabs'
import { PlayerPoolTeam } from './teams/PlayerPoolTeam'
import { ExtraInfoSelect } from './ExtraInfoSelect'
import { PlayerPoolTableRow } from './PlayerPoolTableRow'
import { ArrivalIndicator } from './ArrivalIndicator'
import {
  EditPlayerDialogWrapper,
  PlayerDialogChildrenArgs,
} from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import { PlayerPoolEditContext } from '../enums'
import { getCustomQuestionIdMap } from '@app/store/api/selectors/tournamentSiteSelectors'
import { fetchOrganizationProducts } from '@app/store/payments/actions'
import { UpdateHandicapsButton } from '@app/components/tournament/UpdateHandicapsButton'
import { isCountryWithPlayerRegistry } from '@app/utils/organizationUtils'
import { isTournamentPublished } from '@app/utils/tournamentUtils'
import { RootState } from '@app/store'
import { selectTournamentTeams } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentPlayers, tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'

interface StateProps {
  customQuestionsIdMap: { [questionId: number]: Question }
  playerExtraInfo: string
  players: TournamentPlayer[]
  reservedPlayers: TournamentPlayer[]
  teams: TournamentTeam[]
  tournament: TournamentState
  tournamentStartListRounds: StartListRound[]
  tournamentSite: TournamentSite
  units: OrganizationUnits
  divisions: DivisionState[]
  payment: PaymentsState
  organizationId: number
  isCountryWithRegistry?: boolean
}

interface DispatchProps {
  loadTournamentPlayers(tournamentId: number): void
  updateTournamentPlayer: (payload: UpdatePlayerPayload) => void
  fetchOrganizationProducts(organizationId: number): void
  updatePlayersSelection: (playerIds: number[], isSelected: boolean) => void
  clearSelectedPlayers: () => void
  updateHcps(payload: UpdateHandicapsPayload): void
  tournamentPlayersChangeExtraInfo(value: string): void
}

export enum PlayerPoolTableField {
  SELECTION = 0,
  COUNTRY = 1,
  FIRST_NAME = 2,
  LAST_NAME = 3,
  HCP = 4,
  CLUB = 5,
  EXTRA = 6,
  TEAM = 7,
  PAID = 8,
  GENDER = 9,
  ARRIVED = 10,
  ACTIONS = 11,
}

export interface PlayerPoolTableColumn {
  id: PlayerPoolTableField
  label?: any
  sortable: boolean
  hidden?: boolean
  align: 'center' | 'inherit' | 'justify' | 'left' | 'right'
  width: string
  content?: JSX.Element
}

interface ExtraFieldTable {
  yearOfBirth: string
  entryTime: string
  email: string
  division: string
}

interface State {
  loading: boolean
  activeTab: PlayerPoolEditContext
  tableSortOrder: {
    field: PlayerPoolTableField
    direction: 'asc' | 'desc' | undefined
  }
  isSelectAllChecked: boolean
}

const initialState: State = {
  loading: false,
  activeTab: PlayerPoolEditContext.POOL,
  tableSortOrder: {
    field: PlayerPoolTableField.HCP,
    direction: 'asc',
  },
  isSelectAllChecked: false,
}

interface OwnProps {
  onTabChange: (tab: PlayerPoolEditContext) => void
}

type PlayerPoolTableProps = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

const styles = (theme: Theme) =>
  createStyles({
    customSelect: {
      width: '100%',
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        paddingTop: rem(6),
        paddingBottom: rem(5),
        paddingLeft: rem(10),
        width: '100%',
        color: theme.palette.common.white,
        fontSize: rem(12),
      },
      '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.white,
        borderWidth: '1px',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.white,
        borderWidth: '1px',
      },
      '& .MuiSelect-icon': {
        color: theme.palette.common.white,
      },
    },
    tableSortLabel: {
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.common.white,
      },
      '&.MuiTableSortLabel-root.Mui-active': {
        color: theme.palette.common.white,
        '&. MuiTableSortLabel-icon': {
          color: `${theme.palette.common.white} !important`,
        },
      },
      '& .MuiTableSortLabel-icon': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    actionBar: { display: 'flex', justifyContent: 'flex-end' },
  })

const HeaderTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      textTransform: 'uppercase',
      backgroundColor: theme.customPalette.darkGreen,
      color: theme.palette.common.white,
      fontSize: rem(12),
      fontWeight: 600,
      padding: `${rem(8)} ${rem(8)} ${rem(8)} 0`,

      '& svg': {
        width: '1em',
        color: theme.palette.common.white,
      },
    },
  }),
)(TableCell)

class PlayerPoolTableComponent extends React.Component<PlayerPoolTableProps, State> {
  readonly state = initialState

  public componentDidMount(): void {
    const tournamentId = get(this.props, 'params.id')
    const { organizationId } = this.props

    if (tournamentId) {
      this.props.loadTournamentPlayers(tournamentId)
      this.props.fetchOrganizationProducts(organizationId)
    }
  }

  private onSelectAllChange = (_: any, checked: boolean) => {
    let visiblePlayerIds: number[] = []
    /**
     * Maintain "Select all" -checkbox selection state by active tab basis
     */
    this.setState({ isSelectAllChecked: checked })
    switch (this.state.activeTab) {
      case PlayerPoolEditContext.POOL:
        visiblePlayerIds = this.props.players.map((item) => item.userId)
        break
      case PlayerPoolEditContext.RESERVED:
        visiblePlayerIds = this._reserveListPlayers.map((item) => item.userId)
        break
      case PlayerPoolEditContext.REMOVED:
        visiblePlayerIds = this._removedPlayersList.map((item) => item.userId)
        break
      default:
        break
    }
    this.props.updatePlayersSelection(visiblePlayerIds, checked)
  }

  private get _playerPoolTableColumns(): Record<PlayerPoolTableField, PlayerPoolTableColumn> {
    const { isTeamFormat } = this.props.tournament
    // 12 fields

    return {
      [PlayerPoolTableField.SELECTION]: {
        id: PlayerPoolTableField.SELECTION,
        sortable: false,
        align: 'center',
        width: '2%',
        content: (
          <Checkbox
            onChange={this.onSelectAllChange}
            checked={this.state.isSelectAllChecked}
            size="medium"
            color="secondary"
          />
        ),
      },
      [PlayerPoolTableField.COUNTRY]: {
        id: PlayerPoolTableField.COUNTRY,
        label: <FormattedMessageWrapper id="tournament.country" />,
        sortable: true,
        align: 'center',
        width: '2%',
      },
      [PlayerPoolTableField.FIRST_NAME]: {
        id: PlayerPoolTableField.FIRST_NAME,
        label: <FormattedMessageWrapper id="tournament.firstNameShort" />,
        sortable: true,
        align: 'left',
        width: '10%',
      },
      [PlayerPoolTableField.LAST_NAME]: {
        id: PlayerPoolTableField.LAST_NAME,
        label: <FormattedMessageWrapper id="tournament.lastNameShort" />,
        sortable: true,
        align: 'left',
        width: '10%',
      },
      [PlayerPoolTableField.HCP]: {
        id: PlayerPoolTableField.HCP,
        label: <FormattedMessageWrapper id="tournaments.hcpBadge" />,
        sortable: true,
        align: 'left',
        width: '10%',
      },
      [PlayerPoolTableField.CLUB]: {
        id: PlayerPoolTableField.CLUB,
        label: <FormattedMessageWrapper id="options.homeClub" />,
        sortable: true,
        align: 'left',
        width: '20%',
      },
      [PlayerPoolTableField.EXTRA]: {
        id: PlayerPoolTableField.EXTRA,
        label: this._renderExtraFieldSelection(),
        sortable: true,
        align: 'left',
        width: '35%',
      },
      [PlayerPoolTableField.TEAM]: {
        id: PlayerPoolTableField.TEAM,
        label: <FormattedMessageWrapper id="options.team" />,
        sortable: true,
        hidden: !isTeamFormat,
        align: 'left',
        width: '5%',
      },
      [PlayerPoolTableField.PAID]: {
        id: PlayerPoolTableField.PAID,
        label: <FormattedMessageWrapper id="payments.paid" />,
        sortable: true,
        hidden: !this.props.tournamentSite.paymentEnabled,
        align: 'left',
        width: '5%',
      },
      [PlayerPoolTableField.GENDER]: {
        id: PlayerPoolTableField.GENDER,
        label: <FormattedMessageWrapper id="tournament.gender" />,
        sortable: true,
        align: 'center',
        width: '1%',
      },
      [PlayerPoolTableField.ARRIVED]: {
        id: PlayerPoolTableField.ARRIVED,
        label: <FormattedMessageWrapper id="tournament.arrived" />,
        sortable: true,
        hidden: this.state.activeTab !== PlayerPoolEditContext.POOL,
        align: 'center',
        width: '1%',
      },
      [PlayerPoolTableField.ACTIONS]: {
        id: PlayerPoolTableField.ACTIONS,
        sortable: false,
        align: 'center',
        width: '1%',
      },
    }
  }

  _sortCompare = (a: any, b: any, isHcp = false) => {
    const { tableSortOrder } = this.state
    if (isHcp) {
      if (a.toString().startsWith('+')) {
        a = -a
      }
      if (b.toString().startsWith('+')) {
        b = -b
      }
      return tableSortOrder.direction === 'asc' ? b - a : a - b
    }

    // Empty values should be last.
    // localeCompare puts empty values first.
    if (!a && !b) {
      return 0
    }

    if (!a) {
      return tableSortOrder.direction === 'asc' ? -1 : 1
    }

    if (!b) {
      return tableSortOrder.direction === 'asc' ? 1 : -1
    }

    return tableSortOrder.direction === 'asc' ? String(b).localeCompare(String(a)) : String(a).localeCompare(String(b))
  }

  _extraFieldLookup = (player: TournamentPlayer, playerExtraInfo: string): string => {
    const extraFieldTable: ExtraFieldTable = {
      yearOfBirth: 'dateOfBirth',
      entryTime: 'entryTime',
      email: 'email',
      division: 'divisionId',
    }

    if (Object.keys(extraFieldTable).includes(playerExtraInfo)) {
      return player[extraFieldTable[playerExtraInfo]]
    }

    if (playerExtraInfo.includes('customQuestion:')) {
      const questionId = playerExtraInfo.replace('customQuestion:', '')
      if (questionId) {
        const questionAnswer = player.customQuestionAnswers.find(
          (cqa) => cqa.registrationQuestionId === parseInt(questionId),
        )

        return questionAnswer?.answer || ''
      }
    }

    return playerExtraInfo
  }

  _sortCompareProducts = (a: number[], b: number[]): number => {
    const { tableSortOrder } = this.state

    if (!a.length && !b.length) {
      return 0
    }

    // Empty values should be last.
    // localeCompare puts empty values first.
    if (!a.length) {
      return tableSortOrder.direction === 'asc' ? -1 : 1
    }

    if (!b.length) {
      return tableSortOrder.direction === 'asc' ? 1 : -1
    }

    // Get the product names by the product ids
    const { organizationProducts, tournamentProducts } = this.props.payment

    const aProducts = a.map((id) =>
      organizationProducts.find((organizationProduct) =>
        tournamentProducts.find(
          (tournamentProduct) =>
            organizationProduct.id === tournamentProduct.organizationProductId && id === tournamentProduct.id,
        ),
      ),
    )
    const bProducts = b.map((id) =>
      organizationProducts.find((organizationProduct) =>
        tournamentProducts.find(
          (tournamentProduct) =>
            organizationProduct.id === tournamentProduct.organizationProductId && id === tournamentProduct.id,
        ),
      ),
    )

    // Multiple products are be sorted by their names
    const aNames = aProducts
      .filter((item) => !!item)
      .map((item) => item!.name)
      .sort((a, b) => a.localeCompare(b))
    const bNames = bProducts
      .filter((item) => !!item)
      .map((item) => item!.name)
      .sort((a, b) => a.localeCompare(b))

    // Then we compare the product name strings
    return tableSortOrder.direction === 'asc'
      ? bNames.join('').localeCompare(aNames.join(''))
      : aNames.join('').localeCompare(bNames.join(''))
  }

  _sortRows = (rows: TournamentPlayer[]): TournamentPlayer[] => {
    const { tableSortOrder } = this.state
    const { playerExtraInfo } = this.props

    /**
     * Use cloned rows so we don't modify the original object (array).
     */
    const clonedRows = [...rows]
    switch (tableSortOrder.field) {
      case PlayerPoolTableField.COUNTRY:
        return clonedRows.sort((a, b) => this._sortCompare(a.countryCode, b.countryCode))
      case PlayerPoolTableField.FIRST_NAME:
        return clonedRows.sort((a, b) => this._sortCompare(a.firstName, b.firstName))
      case PlayerPoolTableField.LAST_NAME:
        return clonedRows.sort((a, b) => this._sortCompare(a.lastName, b.lastName))
      case PlayerPoolTableField.HCP:
        return clonedRows.sort((a, b) => this._sortCompare(a.hcp, b.hcp, true))
      case PlayerPoolTableField.CLUB:
        return clonedRows.sort((a, b) => this._sortCompare(a.club?.name, b.club?.name))
      case PlayerPoolTableField.EXTRA:
        return clonedRows.sort((a, b) =>
          this._sortCompare(this._extraFieldLookup(a, playerExtraInfo), this._extraFieldLookup(b, playerExtraInfo)),
        )
      case PlayerPoolTableField.GENDER:
        return clonedRows.sort((a, b) => this._sortCompare(a.gender, b.gender))
      case PlayerPoolTableField.TEAM:
        return clonedRows.sort((a, b) => this._sortCompare(a.team?.name, b.team?.name))
      case PlayerPoolTableField.ARRIVED:
        return clonedRows.sort((a, b) => this._sortCompare(a.isArrived, b.isArrived))
      case PlayerPoolTableField.PAID:
        return clonedRows.sort((a, b) =>
          this._sortCompareProducts(a.purchasedTournamentProductIds, b.purchasedTournamentProductIds),
        )
    }
    return clonedRows
  }

  _findPlayerById = (playerId: number): TournamentPlayer | undefined => {
    switch (this.state.activeTab) {
      case PlayerPoolEditContext.POOL:
        return this.props.players.find((item) => item.userId === playerId)
      case PlayerPoolEditContext.RESERVED:
        return this._reserveListPlayers.find((item) => item.userId === playerId)
      case PlayerPoolEditContext.REMOVED:
        return this._removedPlayersList.find((item) => item.userId === playerId)
      default:
        return undefined
    }
  }

  private onSelectPlayer = (playerId: number, selected: boolean) => {
    this.props.updatePlayersSelection([playerId], selected)
  }

  /**
   * Render player rows.
   */
  _renderRows = (playerDialogArgs: PlayerDialogChildrenArgs): React.ReactElement[] => {
    let playersList: TournamentPlayer[] = []
    switch (this.state.activeTab) {
      case PlayerPoolEditContext.POOL:
        playersList = this.props.players
        break
      case PlayerPoolEditContext.RESERVED:
        playersList = this._reserveListPlayers
        break
      case PlayerPoolEditContext.REMOVED:
        playersList = this._removedPlayersList
        break
    }

    const onPlayerEditClick = (playerId: number): void => {
      const player: TournamentPlayer | undefined = this._findPlayerById(playerId)
      if (player) {
        const dialogFn = playerDialogArgs.openPlayerDialog(player)
        dialogFn()
      }
    }

    return this._sortRows(playersList).map((player) => (
      <PlayerPoolTableRow
        key={player.userId}
        tournament={this.props.tournament}
        startListRounds={this.props.tournamentStartListRounds}
        player={player}
        teams={this.props.teams}
        units={this.props.units}
        customQuestionsIdMap={this.props.customQuestionsIdMap}
        columns={this._playerPoolTableColumns}
        onEditClick={onPlayerEditClick}
        onArrivedChange={this._onArrivedChange}
        extraInfoField={this.props.playerExtraInfo}
        divisions={this.props.divisions}
        players={this.props.players}
        payment={this.props.payment}
        paymentsEnabled={this.props.tournamentSite.paymentEnabled}
        onSelect={this.onSelectPlayer}
        intl={this.props.intl}
      />
    ))
  }

  _onArrivedChange = (playerId: number, checked: boolean): void => {
    const tournamentId = this.props.tournament.id
    const player: TournamentPlayer | undefined = this.props.players.find((item) => item.userId === playerId)
    if (tournamentId && player) {
      const payload = {
        isArrived: checked,
      }
      this.props.updateTournamentPlayer({ tournamentId, playerId, body: payload })
    }
  }

  _onTabChange = (value: PlayerPoolEditContext): void => {
    this.props.clearSelectedPlayers()
    this.setState({
      activeTab: value,
      isSelectAllChecked: false,
    })
    // Sort Reserved list by default with entry time (oldest time first)
    if (value === PlayerPoolEditContext.RESERVED) {
      this.setState({
        tableSortOrder: { field: PlayerPoolTableField.EXTRA, direction: 'desc' },
      })
      this.props.tournamentPlayersChangeExtraInfo('entryTime')
    } else {
      this.setState({
        tableSortOrder: initialState.tableSortOrder,
      })
    }
    this.props.onTabChange(value)
  }

  private get _reserveListPlayers() {
    return this.props.reservedPlayers.filter((player) => !player.removedAt)
  }

  private get _removedPlayersList() {
    const { reserveListEnabled } = this.props.tournamentSite
    return reserveListEnabled
      ? this.props.reservedPlayers.filter((player) => player.removedAt)
      : this.props.reservedPlayers
  }

  private _renderExtraFieldSelection = (): ReactNode => {
    const { tournament, isCountryWithRegistry, classes } = this.props
    return (
      <ExtraInfoSelect
        className={classes.customSelect}
        default={isCountryWithRegistry ? ExtraInfo.EMAIL : ExtraInfo.ENTRY_TIME}
        showTeebox={tournament.tournamentType === TournamentTypes.weekly}
      />
    )
  }

  private _onColumnSortClick = (fieldId: PlayerPoolTableField) => {
    const { tableSortOrder } = this.state

    /**
     * If same field, just change sort direction
     */
    if (tableSortOrder.field && tableSortOrder.field === fieldId) {
      this.setState({
        tableSortOrder: { field: fieldId, direction: tableSortOrder.direction === 'asc' ? 'desc' : 'asc' },
      })
    } else {
      this.setState({
        tableSortOrder: { field: fieldId, direction: tableSortOrder.direction },
      })
    }
  }

  get _arrivedPlayers(): TournamentPlayer[] {
    return this.props.players.filter((player) => player.isArrived === true)
  }

  render() {
    const { tableSortOrder } = this.state
    const { classes, players, tournament, updateHcps } = this.props
    const isUpdateHcpButtonVisible = players.length > 0
    return (
      <>
        <Grid container>
          <Grid item xs={isUpdateHcpButtonVisible ? 8 : 9} xl={isUpdateHcpButtonVisible ? 7 : 8}>
            <PlayerPoolTabs
              onChange={this._onTabChange}
              value={this.state.activeTab}
              players={this.props.players}
              teams={this.props.teams}
              reservedPlayers={this._reserveListPlayers}
              removedPlayers={this._removedPlayersList}
              tournamentSite={this.props.tournamentSite}
              tournament={this.props.tournament}
            />
          </Grid>
          <Grid
            item
            xs={isUpdateHcpButtonVisible ? 2 : 3}
            xl={isUpdateHcpButtonVisible ? 3 : 4}
            className={classes.actionBar}
          >
            <ArrivalIndicator arrivedCount={this._arrivedPlayers.length} totalCount={this.props.players.length} />
          </Grid>
          {isUpdateHcpButtonVisible && (
            <Grid item xs={2}>
              <UpdateHandicapsButton
                style={{ margin: '0 0 0 8px', padding: '6px', width: '95%', fontSize: 14, borderRadius: 8 }}
                onClick={() => {
                  tournament.id &&
                    updateHcps({ tournamentId: tournament.id, isPublished: isTournamentPublished(tournament) })
                }}
              />
            </Grid>
          )}
        </Grid>
        {this.state.activeTab === PlayerPoolEditContext.TEAM ? (
          <PlayerPoolTeam />
        ) : (
          <EditPlayerDialogWrapper editContext={this.state.activeTab}>
            {(editDialogArgs) => (
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.values(this._playerPoolTableColumns)
                      .filter((field) => field.hidden !== true)
                      .map((field) => (
                        <HeaderTableCell
                          variant="head"
                          sortDirection={tableSortOrder.direction}
                          key={field.id}
                          style={{ width: field.width }}
                        >
                          {field.sortable ? (
                            <TableSortLabel
                              active={tableSortOrder.field === field.id}
                              direction={tableSortOrder.direction}
                              className={this.props.classes.tableSortLabel}
                              onClick={() => this._onColumnSortClick(field.id)}
                            >
                              {field.label}
                            </TableSortLabel>
                          ) : (
                            <>{field.label || field.content}</>
                          )}
                        </HeaderTableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>{this._renderRows(editDialogArgs)}</TableBody>
              </Table>
            )}
          </EditPlayerDialogWrapper>
        )}
      </>
    )
  }
}

export const PlayerPoolTable = connect<StateProps, DispatchProps, {}, RootState>(
  (state) => {
    const tournament = selectTournament(state)
    return {
      tournament: tournament,
      tournamentStartListRounds: selectTournamentStartLists(state).rounds,
      teams: selectTournamentTeams(state).data?.teams || [],
      tournamentSite: selectTournamentSite(state),
      units: state.authenticationReducer.units,
      players: selectTournamentPlayers(state).data?.players || [],
      playerExtraInfo: selectTournamentConfig(state).playerExtraInfo,
      reservedPlayers: selectTournamentPlayers(state).data?.reserveList || [],
      customQuestionsIdMap: getCustomQuestionIdMap(state),
      divisions: state.divisionsReducer.divisions,
      payment: state.paymentsReducer,
      organizationId: state.authenticationReducer.roleInfo.organizationId,
      isCountryWithRegistry: isCountryWithPlayerRegistry(tournament.tournamentOrganization),
    }
  },
  {
    loadTournamentPlayers: tournamentPlayersApi.endpoints.getPlayers.initiate,
    updateTournamentPlayer: tournamentPlayersApi.endpoints.updatePlayer.initiate,
    fetchOrganizationProducts,
    updatePlayersSelection,
    clearSelectedPlayers,
    updateHcps: tournamentPlayersApi.endpoints.updateHandicaps.initiate,
    tournamentPlayersChangeExtraInfo,
  },
)(withStyles(styles)(injectIntl(PlayerPoolTableComponent as any)))
