import * as React from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { SplitDateTimePicker } from '@components/date-pickers'
import { convertToDate, createDate } from '@app/utils/dates'
import { DateUnits } from '@app/utils/tournamentUtils'
import { FormLabel, Grid } from '@mui/material'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { DateValidationError } from '@mui/x-date-pickers'
import { setInvalidStartTime } from '@app/store/api/slices/configSlice'
import { useAppDispatch } from '@app/store'

export interface RoundTimingProps {
  endTime: DateTimeValue
  maxDate?: DateTimeValue
  isWeekly?: boolean
  onDateChange: (fieldName: string) => (date: DateTimeValue) => void
  startTime: DateTimeValue
  units: DateUnits
  startTimeDisabled?: boolean
  endTimeDisabled?: boolean
}

export const RoundTiming: React.FC<RoundTimingProps> = ({
  endTime,
  maxDate,
  isWeekly = false,
  onDateChange,
  startTime,
  units,
  startTimeDisabled = false,
  endTimeDisabled = false,
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const minDate = useMemo((): DateTimeValue => {
    const date = startTime && startTime < new Date() ? new Date(startTime) : createDate({ hours: 0, minutes: 0 })
    if (date.getFullYear() < 2000) {
      date.setFullYear(new Date().getFullYear())
    }
    return date
  }, [startTime])

  const onError = (error: DateValidationError) => {
    dispatch(setInvalidStartTime(Boolean(error)))
  }

  const errorMessages = {
    minDate: intl.formatMessage({ id: 'errors.dateIsInThePast' }),
    maxDate: intl.formatMessage({ id: 'errors.noValidLicense' }),
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <FormLabel>
          <FormattedMessageWrapper id="tournament.startDateAndTime" />
        </FormLabel>
        <SplitDateTimePicker
          maxDate={maxDate || undefined}
          minDate={minDate || undefined}
          onChange={onDateChange('startTime')}
          onError={onError}
          units={units}
          value={startTime}
          errorMessages={errorMessages}
          disabled={startTimeDisabled}
        />
      </Grid>

      {isWeekly && (
        <Grid item xs={8}>
          <FormLabel>
            <FormattedMessageWrapper id="tournament.endDateAndTime" />
          </FormLabel>
          <SplitDateTimePicker
            minDate={startTime || undefined}
            onChange={onDateChange('endTime')}
            units={units}
            value={(endTime && convertToDate(endTime)) || null}
            disabled={endTimeDisabled}
          />
        </Grid>
      )}
    </Grid>
  )
}
