import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { rem } from '../../theme/materialUITheme'

const styles = (theme: Theme) =>
  createStyles({
    popper: {},
    tooltip: {
      padding: rem(8),
      maxWidth: 360,
      '@global': {
        '.tooltip-text': {
          whiteSpace: 'pre-line',
          fontSize: rem(14),
          lineHeight: 1.45,
          fontFamily: theme.typography.body2.fontFamily,
        },
      },
    },
  })

type Props = WithStyles<typeof styles>

const GameFormatTooltip = ({ classes, children, title, enterDelay = 200, ...rest }: Props & TooltipProps) => {
  if (!title) {
    return children
  }

  return (
    <Tooltip classes={classes} title={<p className={'tooltip-text'}>{title}</p>} enterDelay={enterDelay} {...rest}>
      {children}
    </Tooltip>
  )
}

export default withStyles(styles)(GameFormatTooltip)
