import { AuthCheckAction, LoginAction, LoginFailureAction, LoginSuccessAction, LogOutSuccessAction } from './actions'
import { AUTH_CHECK, AUTH_LOGIN, AUTH_LOGIN_FAILURE, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS } from './constants'
import { OrganizationUserUpdateSuccessAction } from '../organizationUser/actions'
import { ORGANIZATION_USER_SAVE_SUCCESS } from '../organizationUser/constants'

const initialState: AuthenticationState = {
  id: null,
  name: null,
  email: null,
  token: null,
  role: null,
  error: null,
  loggedIn: false,
  loading: false,
  checkLoading: true,
  roleInfo: {
    id: -1,
    idRole: -1,
    name: '',
    role: '',
    scope: '',
    organizationId: -1,
  },
  permittedRoles: [],
  subscription: {
    active: false,
    daysLeft: 0,
    validFrom: undefined,
    validTo: undefined,
  },
  license: {
    active: false,
    tournamentsLeft: 0,
  },
  units: 'us',
}

type AuthenticationActions =
  | LoginSuccessAction
  | LogOutSuccessAction
  | LoginFailureAction
  | LoginAction
  | OrganizationUserUpdateSuccessAction
  | AuthCheckAction

const authenticationReducer = (state: AuthenticationState = initialState, action: AuthenticationActions) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        loading: true,
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        loggedIn: true,
        loading: false,
        checkLoading: false,
      }
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        error: action.error,
        loading: false,
        checkLoading: false,
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        name: null,
        email: null,
        loggedIn: false,
        loading: false,
        checkLoading: false,
      }
    case ORGANIZATION_USER_SAVE_SUCCESS:
      {
        const { user } = action

        if (user.id === state.id) {
          return {
            ...state,
            name: user.name,
          }
        }
      }

      return state
    case AUTH_CHECK:
      return {
        ...state,
        checkLoading: true,
      }
    default:
      return state
  }
}

export { authenticationReducer }
