import { SEND_PLAYER_SCORES, SEND_PLAYER_SCORES_SUCCESS, SEND_PLAYER_SCORES_FAILURE } from './constants'
import { UpdatePlayerScoresAction, UpdatePlayerScoresSuccessAction, UpdatePlayerScoresFailureAction } from './actions'

interface HcpRoundsState {
  hcpRound?: HcpRound
  loading: boolean
  error?: string
}

const initialState: HcpRoundsState = {
  hcpRound: undefined,
  loading: false,
  error: undefined,
}

type HcpRoundActions = UpdatePlayerScoresAction | UpdatePlayerScoresSuccessAction | UpdatePlayerScoresFailureAction

const hcpRoundReducer = (state: HcpRoundsState = initialState, action: HcpRoundActions) => {
  switch (action.type) {
    case SEND_PLAYER_SCORES:
      return {
        ...state,
        loading: true,
      }
    case SEND_PLAYER_SCORES_SUCCESS:
      return {
        loading: false,
      }
    case SEND_PLAYER_SCORES_FAILURE:
      return {
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export { hcpRoundReducer }
