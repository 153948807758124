import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { getNextUrl } from '@utils/nextUrlHelper'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { connect } from 'react-redux'
import { selectTournamentConfig, setTournamentDirty } from '@app/store/api/slices/configSlice'
import { confirm } from '../dialogs/confirm/Confirm'
import { TournamentPages, TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { api } from '@app/store/api/baseApi'
import { CacheTag } from '@app/store/api/cacheTags'
import { resetRoundSettingsAfterUnsavedChanges } from '@app/store/api/thunks/tournamentRoundThunks'

export type ProgressStep = {
  path: string
  label: React.ReactNode
}

const STEPS: ProgressStep[] = [
  {
    path: TournamentPages.SETUP,
    label: <FormattedMessageWrapper id="progress.setup" />,
  },
  {
    path: TournamentPages.ROUND_SETUP,
    label: <FormattedMessageWrapper id="progress.roundSetup" />,
  },
  {
    path: TournamentPages.TOURNAMENT_SITE,
    label: <FormattedMessageWrapper id="progress.tournamentSite" />,
  },
  {
    path: TournamentPages.BRANDING,
    label: <FormattedMessageWrapper id="progress.branding" />,
  },
  {
    path: TournamentPages.ENTRIES,
    label: <FormattedMessageWrapper id="progress.registration" />,
  },
  {
    path: TournamentPages.PLAYERS,
    label: <FormattedMessageWrapper id="progress.players" />,
  },
  {
    path: TournamentPages.GROUPS_AND_START_LIST,
    label: <FormattedMessageWrapper id="progress.groups" />,
  },
  {
    path: TournamentPages.RESULTS_AND_OPTIONS,
    label: <FormattedMessageWrapper id="progress.otherSettings" />,
  },
]

export interface ProgressChildrenArgs {
  steps: ProgressStep[]
  tournamentId?: number
  roundId?: number
  getActiveStepIndex(): number
  handleOnClick(stepIndex: number): any
}

interface OwnProps {
  children: (args: ProgressChildrenArgs) => any
}

interface StateProps {
  tournament: TournamentState
  tournamentConfig: TournamentConfig
}

interface DispatchProps {
  setTournamentDirty(dirty: boolean): void
  invalidateCache: (tags: CacheTag[]) => void
  resetRoundSettingsAfterUnsavedChanges: () => void
}

type Props = OwnProps & StateProps & DispatchProps & WithRouterProps & WrappedComponentProps

class TournamentProgressLogic extends React.Component<Props> {
  render() {
    return this.props.children({
      tournamentId: this._getTournamentId(),
      roundId: this._getRoundId(),
      steps: this._filterSteps(STEPS),
      getActiveStepIndex: this._getActiveStepIndex,
      handleOnClick: this._handleOnClick,
    })
  }

  private _filterSteps = (steps: ProgressStep[]): ProgressStep[] => {
    if (this.props.tournament?.tournamentType === TournamentTypes.weekly) {
      return steps.filter((step) => step.path !== TournamentPages.GROUPS_AND_START_LIST)
    }
    return steps
  }

  private _getActiveStepIndex = () => {
    const pathParts = this.props.location.pathname.split('/')
    const partsCombined = `${pathParts[1]}--${pathParts.pop()}`
    const idx = STEPS.findIndex((item) => `tournaments--${item.path}` === partsCombined)

    if (idx >= 0) {
      return idx
    }

    return 0
  }

  private _handleOnClick = (stepIndex: number) => {
    return () => {
      const { location, tournament, tournamentConfig, intl, invalidateCache, resetRoundSettingsAfterUnsavedChanges } =
        this.props

      const filteredSteps = this._filterSteps(STEPS)
      const step = filteredSteps[stepIndex]
      const url = getNextUrl(location.pathname, step.path)

      if (tournament && tournamentConfig.isDirty) {
        confirm({
          message: intl.formatMessage({ id: 'tournament.safeNavigationMessage' }),
          options: {
            title: intl.formatMessage({ id: 'tournament.safeNavigationTitle' }),
            cancelText: intl.formatMessage({ id: 'tournament.safeNavigationCancel' }),
            okText: intl.formatMessage({ id: 'tournament.safeNavigationOk' }),
          },
        }).then(() => {
          this._goToNewPage(url)
          invalidateCache([CacheTag.TOURNAMENT_SINGLE])
          invalidateCache([CacheTag.TOURNAMENT_SITE])
          invalidateCache([CacheTag.TOURNAMENT_SETTINGS])
          const isRoundSetupPage = location.pathname.includes('round-setup')
          if (tournamentConfig.selectedRoundId === -1 && isRoundSetupPage) {
            resetRoundSettingsAfterUnsavedChanges()
          }
        })
      } else {
        this._goToNewPage(url)
      }

      return
    }
  }

  private _getTournamentId = (): number | undefined => {
    const { tournament } = this.props

    if (!tournament || !tournament.id) {
      return undefined
    }

    return tournament.id
  }

  private _getRoundId = (): number | undefined => {
    const { tournament } = this.props

    return tournament && tournament.rounds[0] ? tournament.rounds[0].id : undefined
  }

  private _goToNewPage = (url: string) => {
    this.props.setTournamentDirty(false)
    this.props.navigate(url)
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    tournament: selectTournament(state),
    tournamentConfig: selectTournamentConfig(state),
  }),
  {
    setTournamentDirty,
    invalidateCache: api.util.invalidateTags,
    resetRoundSettingsAfterUnsavedChanges,
  },
)(injectIntl(withRouter(TournamentProgressLogic)))
