import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Theme, IconButton, Grid } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import TeamPool from '@components/tournament/TeamPool'
import UnassignedPlayers from '@components/tournament/UnassignedPlayers'
import SectionTitle from '@components/ui/SectionTitle'
import { EditPlayerDialogWrapper } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import {
  getDroppedOutPlayers,
  getUnassignedPlayers,
  getUnassignedTeamsCount,
} from '@app/store/api/selectors/tournamentStartListsSelectors'
import { Print } from '@mui/icons-material'
import { DroppedOutPlayers } from '@app/components/tournament/DroppedOutPlayers'
import { PlayerPoolEditContext } from '../players/enums'
import { PrintoutsDialog } from '@app/components/dialogs/printouts-dialog'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'

interface OwnProps {
  isStartListPublished: boolean
  currentRound: StartListRound
}

interface StateProps {
  tournamentStartLists: TournamentStartListState
  isTeamFormat: boolean
  unassignedPlayers: TournamentPlayer[]
  unassignedTeamsCount: number
  tournament: TournamentState
  droppedOutPlayers: TournamentPlayer[]
}

type Props = OwnProps & StateProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    teamFormat: {
      cursor: 'pointer',
    },
    playerPoolContainer: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '5px',
    },
    titleContainer: {
      justifyContent: 'space-between',
    },
    printIcon: {
      color: theme.palette.primary.main,
    },
  })

interface State {
  showPoolIdx: number
  printoutsDialogOpen: boolean
}

class PlayerPoolComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showPoolIdx: props.isTeamFormat ? 1 : 0,
      printoutsDialogOpen: false,
    }
  }

  render() {
    const { unassignedPlayers, isTeamFormat, classes, unassignedTeamsCount, tournament, droppedOutPlayers } = this.props

    return (
      <>
        <Grid container className={classes.titleContainer}>
          <Grid item>
            <SectionTitle>
              {!isTeamFormat && (
                <span
                  style={{
                    cursor: 'pointer',
                    color: this.state.showPoolIdx === 0 ? 'inherit' : '#979797',
                  }}
                  onClick={() => this.setState({ showPoolIdx: 0 })}
                >
                  <FormattedMessageWrapper
                    id="tournament.playerPoolWithCount"
                    values={{
                      count: unassignedPlayers.length,
                    }}
                  />
                </span>
              )}
              {isTeamFormat && (
                <span
                  className={classes.teamFormat}
                  style={{ color: this.state.showPoolIdx === 1 ? 'inherit' : '#979797' }}
                  onClick={() => this.setState({ showPoolIdx: 1 })}
                >
                  <FormattedMessageWrapper
                    id="tournament.teamPoolWithCount"
                    values={{
                      count: unassignedTeamsCount,
                    }}
                  />
                </span>
              )}
            </SectionTitle>
          </Grid>
          {droppedOutPlayers.length > 0 && (
            <Grid item>
              <SectionTitle>
                <span
                  style={{
                    cursor: 'pointer',
                    color: this.state.showPoolIdx === 2 ? 'inherit' : '#979797',
                  }}
                  onClick={() => this.setState({ showPoolIdx: 2 })}
                >
                  <FormattedMessageWrapper
                    id="tournament.playerPoolDroppedOut"
                    values={{
                      count: droppedOutPlayers.length,
                    }}
                  />
                </span>
              </SectionTitle>
            </Grid>
          )}
          <Grid item>
            <IconButton
              size="small"
              className={classes.printIcon}
              onClick={() => this.setState({ printoutsDialogOpen: true })}
            >
              <Print />
            </IconButton>
            {this.state.printoutsDialogOpen && (
              <PrintoutsDialog
                defaultPrintoutType="STARTLIST"
                tournament={tournament}
                onClose={() => this.setState({ printoutsDialogOpen: false })}
              />
            )}
          </Grid>
        </Grid>
        {!isTeamFormat && this.state.showPoolIdx === 0 && (
          <div className={classes.playerPoolContainer}>
            <EditPlayerDialogWrapper editContext={PlayerPoolEditContext.GROUP_POOL}>
              {(args) => <UnassignedPlayers {...args} />}
            </EditPlayerDialogWrapper>
          </div>
        )}

        {this.state.showPoolIdx === 1 && (
          <div className={classes.playerPoolContainer}>
            <EditPlayerDialogWrapper editContext={PlayerPoolEditContext.GROUP_POOL}>
              {(editDialogArgs) => <TeamPool showTitle={true} isStartlist {...editDialogArgs} />}
            </EditPlayerDialogWrapper>
          </div>
        )}

        {this.state.showPoolIdx === 2 && (
          <div className={classes.playerPoolContainer}>
            <EditPlayerDialogWrapper editContext={PlayerPoolEditContext.GROUP_POOL}>
              {(args) => <DroppedOutPlayers {...args} />}
            </EditPlayerDialogWrapper>
          </div>
        )}
      </>
    )
  }
}

export const PlayerPool = connect<StateProps, {}, OwnProps, RootState>((state) => {
  const tournament = selectTournament(state)
  return {
    tournamentStartLists: selectTournamentStartLists(state),
    isTeamFormat: tournament.isTeamFormat,
    unassignedPlayers: getUnassignedPlayers(state),
    unassignedTeamsCount: getUnassignedTeamsCount(state),
    tournament: tournament,
    droppedOutPlayers: getDroppedOutPlayers(state),
  }
})(withStyles(styles)(PlayerPoolComponent as any))
