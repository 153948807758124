import { combineReducers } from 'redux'
import { authenticationReducer } from './authentication/reducer'
import { tournamentsReducer } from './tournaments/reducer'
import { playersReducer } from './players/reducer'
import { localeReducer } from './locale/reducer'
import { gameFormatsReducer } from './gameFormats/reducer'
import { organizationReducer } from './organization/reducer'
import { organizationsReducer } from './organizations/reducer'
import { organizationUsersReducer } from './organizationUsers/reducer'
import { organizationUserReducer } from './organizationUser/reducer'
import { notificationsReducer } from './notifications/reducer'
import { tournamentLeaderboardReducer } from './tournamentLeaderboard/reducer'
import { tourAndRankingReducer } from './tourAndRanking/reducer'
import { themeReducer } from './theme/reducer'
import { divisionsReducer } from './divisions/reducer'
import { hcpRoundReducer } from './hcpRounds/reducer'
import { paymentsReducer } from './payments/reducer'
import { timeZonesReducer } from './timeZones/reducer'
import { tournamentDivisionLeaderboardReducer } from './tournamentDivisionLeaderboard/reducer'
import { reportsReducer } from './reports/reducer'
import { api } from './api/baseApi'
import configReducer from './api/slices/configSlice'
import tournamentStartListsReducer from './api/slices/tournamentStartListsSlice'
import clubsAndCoursesReducer from './api/slices/clubsAndCoursesSlice'

const indexReducer = () =>
  combineReducers({
    authenticationReducer,
    tournamentsReducer,
    playersReducer,
    localeReducer,
    gameFormatsReducer,
    organizationReducer,
    organizationsReducer,
    organizationUsersReducer,
    organizationUserReducer,
    notificationsReducer,
    themeReducer,
    tournamentLeaderboardReducer,
    tourAndRankingReducer,
    divisionsReducer,
    hcpRoundReducer,
    paymentsReducer,
    timeZonesReducer,
    tournamentDivisionLeaderboardReducer,
    reportsReducer,
    // Refactored reducers
    tournamentStartListsReducer,
    clubsAndCoursesReducer,
    configReducer,
    [api.reducerPath]: api.reducer,
  })

export default indexReducer
