import React from 'react'
import { Grid, Theme, IconButton, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { connect } from 'react-redux'
import ListPlayer, { PLAYER_LIST_ITEM_HEIGHT } from './ListPlayer'
import { PlayerDialogChildrenArgs } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Droppable, DroppableProvided, Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { TeamName } from './TeamName'
import Button from '@mui/material/Button'
import { AddCircle, Delete, DragIndicator } from '@mui/icons-material'
import BorderButton from '../ui/BorderButton'
import { deleteTeamConfirmation } from '../dialogs/deleteTeamConfirm/DeleteTeamConfirm'
import { getUnassignedTeamPlayers } from '../../store/api/selectors/tournamentStartListsSelectors'
import classNames from 'classnames'
import { rem } from '../../theme/materialUITheme'
import { EntryMethods, ExtraInfo } from '../../store/api/enums/tournamentEnums'
import { disablePlayerListEdit } from '../../store/api/selectors/tournamentSelectors'
import { sortTournamentTeams } from '@store/api/thunks/tournamentTeamsThunks'
import TeamHeader from './TeamHeader'
import ExtraInfoSelect from './ExtraInfoSelect'
import { PlayerStatus } from '@app/store/api/enums/tournamentPlayersEnums'
import { sortTeamPlayers } from '@app/utils/playerUtils'
import { RootState } from '@app/store'
import { selectTournamentTeams, tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

interface OwnProps extends PlayerDialogChildrenArgs {
  confirmPlayerRemove?: boolean
  showTitle?: boolean
  isStartlist?: boolean
}

interface StateProps {
  defaultTeamSize: number
  disablePlayerListEdit: boolean
  isTwoManTeamFormat: boolean
  playerExtraInfo: string
  rounds: Round[]
  selectedRoundIndex: number
  tournamentSite: TournamentSite
  sortBy: TeamPoolOrderBy
  sortDirection: SortDirection
  startlist: TournamentStartListState
  teams: TournamentTeam[]
  tournamentId: number
  unassignedTeamPlayers: TournamentPlayer[]
}

interface DispatchProps {
  addNewTeam(payload: AddTeamPayload): void
  deleteTeam(args: DeleteTeamPayload): void
  sortTournamentTeams: (sortBy: TeamPoolOrderBy, sortDirection?: SortDirection) => void
  updateTeam(payload: UpdateTeamPayload): void
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: '10px 10px 24px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.customPalette.lightGray,
      height: 'calc(100vh - 250px)',
      minHeight: 500,
      overflowY: 'auto' as const,
    },
    dropBlock: {
      position: 'relative',
      marginBottom: 7,
      padding: 5,
      backgroundColor: theme.customPalette.lightGray,
      border: `2px solid transparent`,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.3)',
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    teamDropBlock: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flex: 1,
      minHeight: '100%',
      minWidth: '100%',
    },
    error: {
      border: `2px solid ${theme.customPalette.error}`,
    },
    errorText: {
      color: theme.customPalette.error,
      fontSize: rem(12),
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    btn: {
      color: theme.palette.primary.contrastText,
    },
    delete: {
      color: theme.customPalette.error,
    },
    poolHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: rem(12),
      borderRadius: `${theme.shape.borderRadius} ${theme.shape.borderRadius} 0 0`,
      alignItems: 'center',
      padding: `${theme.spacing(0.5)} 0`,
    },
    sortButton: {
      margin: 0,
      padding: 0,
      minWidth: 0,
      color: theme.palette.common.white,
      fontSize: rem(12),
    },
    extraInfoSelect: {
      color: theme.palette.common.white,
      fontSize: rem(12),
    },
  })

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

class TeamPool extends React.Component<Props> {
  render() {
    const { classes, showTitle = true, isStartlist = false, disablePlayerListEdit } = this.props
    const teams = isStartlist ? this._filterStartListTeams : this.props.teams
    if (isStartlist) {
      teams.sort((a, b) => (a.players.length > b.players.length ? 1 : -1))
    }

    return (
      <>
        {showTitle && this.poolHeader()}

        <div className={classes.paper}>
          <Droppable droppableId={`teampool`} type="droppableTeam">
            {(dropProvided: DroppableProvided) => (
              <div ref={dropProvided.innerRef} {...dropProvided.droppableProps} className={classes.teamDropBlock}>
                {!isStartlist && !disablePlayerListEdit && (
                  <Grid
                    container={true}
                    style={{
                      justifyContent: 'space-between',
                      marginBottom: 10,
                    }}
                  >
                    <Grid item={true}>
                      <Button onClick={this._onCreateTeam} className={classes.btn}>
                        <AddCircle style={{ marginRight: 10 }} />
                        <FormattedMessageWrapper id="buttons.addTeam" />
                      </Button>
                    </Grid>
                    <Grid item={true}>
                      {teams.length > 0 && (
                        <Button onClick={this._clearAllTeams} className={classes.btn}>
                          <FormattedMessageWrapper id="buttons.clearAll" />
                        </Button>
                      )}
                      {teams.length === 0 && (
                        <BorderButton
                          buttonProps={{
                            disabled: teams.length > 0,
                            style: {
                              color: '#FFFFFF',
                              borderColor: '#FFFFFF',
                              marginLeft: 10,
                            },
                            onClick: this._autoFillTeams,
                          }}
                        >
                          <FormattedMessageWrapper id="buttons.autoFill" />
                        </BorderButton>
                      )}
                    </Grid>
                  </Grid>
                )}
                {this._renderTeams()}

                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </>
    )
  }

  private _isInvalid = (team: TournamentTeam) => {
    if (this.props.isTwoManTeamFormat && team.players.length > 2) {
      return true
    } else if (team.players.length > 5) {
      return true
    } else {
      return false
    }
  }

  private get _filterStartListTeams() {
    return this.props.teams.filter((team) => {
      const currentRound = this.props.startlist.rounds[this.props.selectedRoundIndex]
      const groups = currentRound.groups
      const teamFound = groups.find((g) => g.teams && g.teams.find((t) => t.tournamentTeamId === team.id))

      return teamFound ? false : true
    })
  }

  private _renderTeams = () => {
    const { classes, isStartlist, disablePlayerListEdit } = this.props

    const teams = isStartlist ? this._filterStartListTeams : this.props.teams

    return teams.map((team, idx) => (
      <Draggable draggableId={`TeampoolTeam-${idx}-Team-${team.id}`} index={idx} key={`${team.id}${idx}`}>
        {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
          <div
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            style={{ ...dragProvided.draggableProps.style, width: dragSnapshot.isDragging ? '300px' : 'auto' }}
            key={idx}
          >
            <Droppable
              droppableId={`teampoolTeam-${idx}-team-${team.id}`}
              type="droppablePlayer"
              key={`${team.id}-${team.name}`}
            >
              {(dropProvided: DroppableProvided) => (
                <div
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                  className={classNames(classes.dropBlock, this._isInvalid(team) ? classes.error : '')}
                >
                  <TeamHeader>
                    <div {...dragProvided.dragHandleProps} style={{ display: 'flex' }}>
                      {isStartlist && <DragIndicator />}
                      <TeamName
                        name={team.name}
                        showEdit={false}
                        onChange={(name) => {
                          if (team.id) {
                            this._updateTeam(team.id, name)
                          }
                        }}
                      />
                    </div>
                    <div style={{ display: 'flex' }}>
                      {!isStartlist && !disablePlayerListEdit && (
                        <IconButton
                          size={'small'}
                          style={{ marginLeft: 10 }}
                          onClick={() => this._showConfirmDelete(team)}
                        >
                          <Delete className={classes.delete} />
                        </IconButton>
                      )}
                    </div>
                  </TeamHeader>
                  {!dragSnapshot.isDragging && (
                    <div style={{ minHeight: team.players.length === 0 ? this._teamDropZoneMinHeight : 'auto' }}>
                      {this._renderPlayers(team.players)}
                      {this._isInvalid(team) && (
                        <Typography variant="body1" className={classes.errorText}>
                          <FormattedMessageWrapper id="tournament.tooManyPlayersInTeam" />
                        </Typography>
                      )}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    ))
  }

  private _renderPlayers = (players: TournamentPlayer[]) => {
    return sortTeamPlayers(players).map((player, index) => (
      <ListPlayer
        tournamentPlayer={player}
        key={player.userId}
        index={index}
        openPlayerDialog={this.props.openPlayerDialog}
        onClosePlayerDialog={this.props.onClosePlayerDialog}
        hideDragHandle={true}
        hideDeleteIcon={true}
        usedByComponent="TeamPool"
      />
    ))
  }

  private poolHeader = () => {
    const { classes } = this.props

    return (
      <Grid container className={classes.poolHeader}>
        <Grid item xs={1}></Grid>
        <Grid item xs={4}>
          {this.renderSortButton('name', 'options.teamName')}
        </Grid>
        <Grid item xs={2}>
          <span style={{ textTransform: 'uppercase' }}>
            <FormattedMessageWrapper id="tournaments.hcpBadge" />
          </span>
        </Grid>
        <Grid item xs={5}>
          <ExtraInfoSelect className={classes.extraInfoSelect} default={ExtraInfo.HOME_CLUB} />
        </Grid>
      </Grid>
    )
  }

  private renderSortButton = (id: string, text?: string) => {
    const { classes, sortDirection, sortBy } = this.props

    const sortIcon =
      sortDirection === 'asc' ? (
        <ArrowUpwardIcon style={{ fontSize: 12 }} />
      ) : (
        <ArrowDownwardIcon style={{ fontSize: 12 }} />
      )

    return (
      <Button
        size="small"
        onClick={this.onClickSort}
        className={classes.sortButton}
        id={id}
        endIcon={sortBy === id && sortIcon}
      >
        {text && (
          <span style={{ textTransform: 'uppercase' }}>
            <FormattedMessageWrapper id={text} />
          </span>
        )}
      </Button>
    )
  }

  public _updateTeam = (teamId: number, name = '') => {
    const { updateTeam, tournamentId } = this.props
    if (tournamentId) {
      updateTeam({
        tournamentId,
        teamId,
        name,
      })
    }
  }

  public _onCreateTeam = () => {
    this.props.addNewTeam({ tournamentId: this.props.tournamentId, name: 'test' })
  }

  public _clearAllTeams = () => {
    deleteTeamConfirmation({
      title: this.props.intl.formatMessage({ id: 'tournament.removeAll' }),
      options: {
        okText: this.props.intl.formatMessage({ id: 'buttons.delete' }),
        cancelText: this.props.intl.formatMessage({ id: 'buttons.cancel' }),
        deleteText: this.props.intl.formatMessage({ id: 'options.deleteTeamAndPlayers' }),
        moveToPoolText: this.props.intl.formatMessage({ id: 'options.deleteTeamAndMovePlayersToPool' }),
      },
    }).then(() => {
      this.props.teams.map(
        (team) =>
          team.id &&
          this.props.deleteTeam({
            tournamentId: this.props.tournamentId,
            teamId: team.id,
          }),
      )
    })
  }

  public _showConfirmDelete = (team: TournamentTeam) => {
    const { name, id, players } = team

    if (id && players.length === 0) {
      this.props.deleteTeam({ tournamentId: this.props.tournamentId, teamId: id })
    } else if (id) {
      deleteTeamConfirmation({
        title: this.props.intl.formatMessage({ id: 'tournament.removeWithName' }, { name }),
        options: {
          okText: this.props.intl.formatMessage({ id: 'buttons.delete' }),
          cancelText: this.props.intl.formatMessage({ id: 'buttons.cancel' }),
          deleteText: this.props.intl.formatMessage({ id: 'options.deleteTeamAndPlayers' }),
          moveToPoolText: this.props.intl.formatMessage({ id: 'options.deleteTeamAndMovePlayersToPool' }),
        },
      }).then(() => {
        this.props.deleteTeam({
          tournamentId: this.props.tournamentId,
          teamId: id,
        })
      })
    }
  }

  public _autoFillTeams = () => {
    const { unassignedTeamPlayers, defaultTeamSize, isTwoManTeamFormat, tournamentId } = this.props
    const { onlineEntriesEnabled, entryMethod, maximumEntryAmountEnabled, maximumPlayerAmount } =
      this.props.tournamentSite
    const teamSize = isTwoManTeamFormat ? 2 : defaultTeamSize

    let maxTeamAmount = 0
    if (onlineEntriesEnabled && maximumEntryAmountEnabled && entryMethod === EntryMethods.TEAM) {
      maxTeamAmount = maximumPlayerAmount
    }

    const newTeams: TournamentTeam[] = []
    for (let i = 0, j = unassignedTeamPlayers.length; i < j; i += teamSize) {
      const teamPlayers: TournamentPlayer[] = unassignedTeamPlayers.slice(i, i + teamSize)
      const team: TournamentTeam = {
        id: 0,
        status: PlayerStatus.OK,
        tournamentId: tournamentId || 0,
        name: `Team ${teamPlayers[0].lastName}`,
        players: [],
      }
      //TODO: refactor
      // eslint-disable-next-line no-loop-func
      teamPlayers.map((player: TournamentPlayer) => team.players.push(player))
      newTeams.push(team)
      if (maxTeamAmount && newTeams.length >= maxTeamAmount) {
        break
      }
    }
  }

  private onClickSort = (e) => {
    const { sortTournamentTeams, sortBy, sortDirection } = this.props
    const newSortBy = e.currentTarget.id
    /**
     * Pressed twice same column, just change sort direction.
     */
    let newSortDirection = sortDirection
    if (sortBy === newSortBy) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }
    sortTournamentTeams(newSortBy, newSortDirection)
  }

  private get _teamDropZoneMinHeight() {
    const { defaultTeamSize, isTwoManTeamFormat } = this.props

    if (isTwoManTeamFormat) {
      return PLAYER_LIST_ITEM_HEIGHT * 2.5
    }

    return PLAYER_LIST_ITEM_HEIGHT * (defaultTeamSize + 1)
  }
}

export default connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => {
    const tournament = selectTournament(state)
    const tournamentConfig = selectTournamentConfig(state)
    return {
      defaultTeamSize: tournament.rounds[0]?.primaryGameOptions?.defaultTeamSize || 4,
      disablePlayerListEdit: disablePlayerListEdit(state),
      isTwoManTeamFormat: tournament.isTwoManTeamFormat,
      playerExtraInfo: tournamentConfig.playerExtraInfo,
      rounds: tournament.rounds,
      tournamentSite: selectTournamentSite(state),
      selectedRoundIndex: tournamentConfig.selectedRoundIndex,
      sortBy: tournamentConfig.teamsSortBy,
      sortDirection: tournamentConfig.teamsSortDirection,
      startlist: selectTournamentStartLists(state),
      teams: selectTournamentTeams(state).data?.teams || [],
      tournamentId: tournament.id,
      unassignedTeamPlayers: getUnassignedTeamPlayers(state),
    }
  },
  {
    updateTeam: tournamentTeamsApi.endpoints.updateTeam.initiate,
    addNewTeam: tournamentTeamsApi.endpoints.addTeam.initiate,
    deleteTeam: tournamentTeamsApi.endpoints.deleteTeam.initiate,
    sortTournamentTeams,
  },
)(withStyles(styles)(injectIntl(TeamPool as any)))
