import { useSelector } from 'react-redux'
import { useNotification } from '.'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

export const useSelectedRound = (showErrors = true): Round | undefined => {
  const { selectedRoundIndex, status } = useSelector(selectTournamentConfig)
  const { isTournamentLoading } = status
  const tournament = useSelector(selectTournament)
  const { rounds } = tournament
  const sendNotification = useNotification()

  const round = rounds[selectedRoundIndex] ? rounds[selectedRoundIndex] : undefined

  if (isTournamentLoading === false && !round && showErrors) {
    sendNotification('Round not found', 'error')
  }

  return round
}
