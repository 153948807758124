import { all, takeEvery, put } from 'redux-saga/effects'
import { getToken } from '../authentication/sagas'
import { API_ROOT, APIRoute } from '../../config'
import { apiClientRequest } from '../../utils/sagaHelpers'
import { enqueueNotification } from '../notifications/actions'
import {
  DIVISIONS_FETCH,
  DIVISIONS_FETCH_FAILURE,
  DIVISIONS_FETCH_SUCCESS,
  DIVISION_CREATE,
  DIVISION_CREATE_FAILURE,
  DIVISION_CREATE_SUCCESS,
  DIVISION_DELETE,
  DIVISION_DELETE_FAILURE,
  DIVISION_DELETE_SUCCESS,
  DIVISION_FETCH,
  DIVISION_FETCH_FAILURE,
  DIVISION_FETCH_SUCCESS,
  DIVISION_UPDATE,
  DIVISION_UPDATE_FAILURE,
  DIVISION_UPDATE_SUCCESS,
} from './constants'
import {
  DivisionCreateAction,
  DivisionDeleteAction,
  DivisionFetchAction,
  DivisionsFetchAction,
  DivisionUpdateAction,
} from './actions'
import { omit } from 'lodash'
import { formatHandicap } from '@app/utils/playerUtils'

/**
 * GET ALL
 */

function* doFetchDivisions({ organizationId }: DivisionsFetchAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.DIVISIONS(organizationId)}`,
      method: 'get',
      token,
    })

    const divisions = res.data.divisions

    yield put({
      type: DIVISIONS_FETCH_SUCCESS,
      divisions,
    })
  } catch (error: any) {
    yield put({
      type: DIVISIONS_FETCH_FAILURE,
      error: error.toString(),
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

/**
 * GET ONE
 */

function* doFetchDivision({ organizationId, divisionId, onSuccess }: DivisionFetchAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.DIVISION(organizationId, divisionId)}`,
      method: 'get',
      token,
    })

    const division: DivisionState = res.data.division

    /**
     * Format HCP values (remove "-" sign, add "+" sign)
     */
    division.minHcp = typeof division.minHcp == 'number' ? formatHandicap(division.minHcp.toString()) : ''
    division.maxHcp = typeof division.maxHcp == 'number' ? formatHandicap(division.maxHcp.toString()) : ''

    onSuccess(division)

    yield put({
      type: DIVISION_FETCH_SUCCESS,
    })
  } catch (error: any) {
    yield put({
      type: DIVISION_FETCH_FAILURE,
      error: error.toString(),
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

/**
 * DELETE
 */

function* doDeleteDivision({ organizationId, divisionId }: DivisionDeleteAction) {
  try {
    const token = yield getToken()
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.DIVISION(organizationId, divisionId)}`,
      method: 'delete',
      token,
    })

    yield put({
      type: DIVISION_DELETE_SUCCESS,
    })
    yield put({
      type: DIVISIONS_FETCH,
      organizationId,
    })
    yield put({
      type: DIVISIONS_FETCH,
      organizationId,
    })
  } catch (error: any) {
    yield put({
      type: DIVISION_DELETE_FAILURE,
      error: error.toString(),
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

/**
 * CREATE
 */

function* doCreateDivision({ organizationId, division, onSuccess }: DivisionCreateAction) {
  try {
    const token = yield getToken()

    const payload = division

    const processedPayload = {
      ...payload,
      maxHcp: payload.maxHcp ? Number(payload.maxHcp) : null,
      minHcp: payload.minHcp ? Number(payload.minHcp) : null,
    }

    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.DIVISIONS(organizationId)}`,
      method: 'post',
      token,
      data: processedPayload,
    })

    yield put({
      type: DIVISION_CREATE_SUCCESS,
    })

    if (onSuccess) {
      onSuccess()
    }
  } catch (error: any) {
    yield put({
      type: DIVISION_CREATE_FAILURE,
      error: error.toString(),
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

/**
 * UPDATE
 */

function* doUpdateDivision({ organizationId, division, onSuccess }: DivisionUpdateAction) {
  try {
    const token = yield getToken()

    const payload = omit(division, 'id')

    const processedPayload = {
      ...payload,
      maxHcp: payload.maxHcp ? Number(payload.maxHcp) : null,
      minHcp: payload.minHcp ? Number(payload.minHcp) : null,
    }

    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.DIVISION(organizationId, division.id)}`,
      method: 'put',
      token,
      data: processedPayload,
    })

    yield put({
      type: DIVISION_UPDATE_SUCCESS,
    })

    if (onSuccess) {
      onSuccess()
    }
  } catch (error: any) {
    yield put({
      type: DIVISION_UPDATE_FAILURE,
      error: error.toString(),
    })
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchDivisions() {
  yield all([takeEvery(DIVISION_FETCH, doFetchDivision)])
  yield all([takeEvery(DIVISIONS_FETCH, doFetchDivisions)])
  yield all([takeEvery(DIVISION_CREATE, doCreateDivision)])
  yield all([takeEvery(DIVISION_UPDATE, doUpdateDivision)])
  yield all([takeEvery(DIVISION_DELETE, doDeleteDivision)])
}
