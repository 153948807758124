import React from 'react'
import { AppBar, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import NavToolbar from './NavToolbar'
import createStyles from '@mui/styles/createStyles'

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      boxShadow: 'none',
      height: 64,
      zIndex: theme.zIndex.drawer - 1,
    },
  })

interface OwnProps {
  titleId?: string
  title?: string
}

type Props = WithStyles<typeof styles> & OwnProps

class TopBar extends React.Component<Props> {
  render() {
    const { classes, titleId, title } = this.props

    return (
      <AppBar position="relative" className={classes.appBar}>
        <NavToolbar titleId={titleId} title={title} />
      </AppBar>
    )
  }
}

export default withStyles(styles)(TopBar)
