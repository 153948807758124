import { ConfirmDialogProps, confirmable, createConfirmation } from 'react-confirm'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import materialUITheme from '../../../theme/materialUITheme'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { PaletteMode } from '@mui/material'

export interface OptionsProps {
  title?: string | JSX.Element | any
  subtitle?: string | JSX.Element | any
  cancelText?: string | JSX.Element | any
  okText?: string | JSX.Element | any
}

export interface Props {
  contentComponent?: React.ReactNode
  message?: string
  messageHTML?: string
  options: OptionsProps
  maxWidth?: DialogProps['maxWidth']
}

const ConfirmDialog: React.FC<ConfirmDialogProps<Props, boolean>> = ({
  show,
  proceed,
  dismiss,
  contentComponent,
  message,
  messageHTML,
  options,
  maxWidth = 'xs',
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={materialUITheme((localStorage.getItem('theme') || 'light') as PaletteMode)}>
      <Dialog
        open={show}
        onClose={(event, reason) => reason !== 'backdropClick' && dismiss()}
        maxWidth={maxWidth}
        fullWidth={true}
      >
        {options.title && <BaseDialogTitle id={'dialog-title'} title={options.title} subtitle={options.subtitle} />}
        <DialogContent data-testid="confirmMessage">
          {contentComponent && contentComponent}
          {messageHTML && (
            <DialogContentText>
              <span dangerouslySetInnerHTML={{ __html: messageHTML }} />
            </DialogContentText>
          )}
          {message && <DialogContentText>{message}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          {Boolean(options.cancelText) && (
            <Button onClick={dismiss} data-testid="confirmCancel">
              {options.cancelText}
            </Button>
          )}
          {Boolean(options.okText) && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => proceed(true)}
              autoFocus={true}
              data-testid="confirmOk"
            >
              {options.okText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  </StyledEngineProvider>
)

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
const ConfirmableDialog = confirmable(ConfirmDialog)
const confirmation = createConfirmation(ConfirmableDialog)

interface ConfirmProps {
  contentComponent?: React.ReactNode
  message?: string
  messageHTML?: string
  options?: OptionsProps
  maxWidth?: DialogProps['maxWidth']
}

export const confirm = ({ contentComponent, message, messageHTML, options = {}, maxWidth }: ConfirmProps) => {
  return confirmation({
    contentComponent,
    message,
    messageHTML,
    options,
    maxWidth,
  })
}
