import React from 'react'
import { connect } from 'react-redux'
import ListPlayer from './ListPlayer'
import { RootState } from '@app/store'
import { selectTournamentPlayers } from '@app/store/api/endpoints/tournamentPlayersApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

interface OwnProps {
  players: StartListPlayer[]
  openPlayerDialog: (player: TournamentPlayer) => () => void
  onClosePlayerDialog: () => void
  confirmPlayerRemove?: boolean
  disableTee?: boolean
  removeAction: (playerId: number) => void
  isSpecialTeamFormat: boolean
  hideDrag: boolean
  hideDeleteIcon?: boolean
}

interface StateProps {
  tournamentPlayers: TournamentPlayer[]
  selectedRoundId: number
}

type Props = OwnProps & StateProps

class StartGroupPlayerItemsComponent extends React.Component<Props> {
  render() {
    const {
      players,
      tournamentPlayers,
      openPlayerDialog,
      onClosePlayerDialog,
      confirmPlayerRemove,
      disableTee,
      removeAction,
      isSpecialTeamFormat,
      hideDrag,
      hideDeleteIcon,
      selectedRoundId,
    } = this.props

    const sortedPlayers = [...players].sort((a, b) => a.groupOrder - b.groupOrder)

    return sortedPlayers.map((player, index) => {
      if (!player) {
        return null
      }
      /**
       * Find from tournamentPlayers the matching startListPlayer
       */
      let convertedTournamentPlayer = tournamentPlayers.find((tPlayer) => player.tournamentPlayerId === tPlayer.id)

      if (!convertedTournamentPlayer) {
        return null
      }

      convertedTournamentPlayer = { ...convertedTournamentPlayer, startListPlayers: { [selectedRoundId]: player } }

      return (
        <ListPlayer
          key={player.tournamentPlayerId}
          tournamentPlayer={convertedTournamentPlayer as TournamentPlayer}
          index={index}
          openPlayerDialog={openPlayerDialog}
          onClosePlayerDialog={onClosePlayerDialog}
          confirmPlayerRemove={confirmPlayerRemove}
          showTee={!disableTee}
          removeAction={removeAction}
          showPHCP={!isSpecialTeamFormat}
          hideDragHandle={hideDrag ? hideDrag : false}
          hideDeleteIcon={hideDeleteIcon || false}
          usedByComponent="StartGroupPlayerItems"
          animationDisabled={false}
        />
      )
    })
  }
}

export const StartGroupPlayerItems = connect<StateProps, {}, OwnProps, RootState>((state) => ({
  tournamentPlayers: selectTournamentPlayers(state).data?.players || [],
  selectedRoundId: selectTournamentConfig(state).selectedRoundId,
}))(StartGroupPlayerItemsComponent)
