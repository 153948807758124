import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { Theme } from '@mui/material'
import Fade from '@mui/material/Fade'
import { useEffect, useRef, useState } from 'react'

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(255,255,255,0.8)' : 'rgba(68,68,68,0.8)',
      zIndex: 10000,
    },
    absoluteWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.mode === 'light' ? 'rgba(255,255,255,0.8)' : 'rgba(68,68,68,0.8)',
      zIndex: 10000,
    },
  })

interface LoaderOverlayProps extends WithStyles<typeof styles> {
  visible?: boolean
  debounceTimeout?: number
  positionAbsolute?: boolean
}

export const OverlayLoader = withStyles(styles)(({
  visible = true,
  positionAbsolute = false,
  debounceTimeout = 0,
  classes,
}: LoaderOverlayProps) => {
  const loaderSize = 64
  const className = positionAbsolute ? classes.absoluteWrapper : classes.wrapper
  const timer = useRef<number>()
  const [isLoading, setLoading] = useState(visible)

  useEffect(() => {
    if (!debounceTimeout) {
      return
    }
    if (visible === false) {
      if (timer.current) {
        window.clearTimeout(timer.current)
      }
      /**
       * Use debounce to prevent loader flickering.
       */
      timer.current = window.setTimeout(() => {
        setLoading(false)
      }, debounceTimeout)
    } else {
      setLoading(true)
    }
    return () => window.clearTimeout(timer.current)
  }, [visible, debounceTimeout])

  return (
    <Fade in={debounceTimeout > 0 ? isLoading : visible} unmountOnExit={true}>
      <div className={className}>
        <CircularProgress
          size={loaderSize}
          thickness={2.6}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: `${-(loaderSize / 2)}px`,
            marginTop: `${-(loaderSize / 2)}px`,
          }}
        />
      </div>
    </Fade>
  )
})
