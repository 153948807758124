import React, { useMemo, useState } from 'react'
import {
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Button from '@mui/material/Button'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'
import { useSelector } from 'react-redux'
import { VeryDenseTableCell } from '@app/components/tables/tableComponents'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { OverlayLoader } from '../../ui/OverlayLoader'
import { sendRankingPoints } from '@app/store/tourAndRanking/actions'
import { isTeamFormatId } from '@app/utils/gameFormatUtils'
import { useSelectedRound } from '@app/hooks'
import { formatOutputNumber, formatInputNumber } from '@utils/numbers'
import { updateTournamentField } from '@app/store/api/thunks/tournamentThunks'
import { useUpdateTournamentMutation } from '@app/store/api/endpoints/tournamentApi'
import { RootState, useAppDispatch } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

export interface SendRankingPointsDialogProps {
  tournament: TournamentState
  open: boolean
  onClose(): void
}

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    marginBottom: theme.spacing(2),
    '& .MuiTab-root': {
      textTransform: 'none',
      fontFamily: theme.fonts.primary,
      fontSize: rem(16),
    },
  },
  title: {
    display: 'block',
    fontSize: rem(15),
    fontWeight: 600,
    maxWidth: '540px',
    marginBottom: theme.spacing(2),
  },
  note: {
    display: 'block',
    fontSize: rem(14),
    fontWeight: 600,
    maxWidth: '540px',
    color: theme.customPalette.error,
    marginBottom: theme.spacing(2),
  },
  tableRow: {
    '&:nth-of-type(even)': {
      background: darken(theme.palette.background.default, 0.02),
    },
  },
  teamName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}))

enum RankingDialogTab {
  primary = 'primary',
  side = 'side',
}

interface RankingListItem extends RankingTemplateItem {
  playerName: string
  teamName?: string
}

interface Error {
  list: RankingDialogTab
  index: number
}

export const SendRankingPointsDialog: React.FC<SendRankingPointsDialogProps> = (props) => {
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [isLoading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState<RankingDialogTab>(RankingDialogTab.primary)
  const [errors, setErrors] = useState<Error[]>([])
  const [dataIsStale, setDataIsStale] = useState(false)

  const leaderboard = useSelector((state: RootState) => state.tournamentLeaderboardReducer)
  const tournament = useSelector(selectTournament)
  const [saveTournament] = useUpdateTournamentMutation()

  const { primaryRanking, primaryRankingConfig, sideRanking, sideRankingConfig } = tournament
  const selectedRound = useSelectedRound()

  const primaryRankingEnabled = !!(primaryRanking && primaryRankingConfig)
  const sideRankingEnabled = selectedRound?.sideGameEnabled && !!(sideRanking && sideRankingConfig)

  const activeConfig =
    activeTab === RankingDialogTab.primary ? tournament?.primaryRankingConfig : tournament?.sideRankingConfig

  const tabs = useMemo(() => {
    const enabledTabs: { id: RankingDialogTab; label: string }[] = []

    if (primaryRankingEnabled) {
      enabledTabs.push({
        id: RankingDialogTab.primary,
        label: intl.formatMessage({ id: 'tournament.primaryGame' }),
      })
    }

    if (sideRankingEnabled) {
      enabledTabs.push({
        id: RankingDialogTab.side,
        label: intl.formatMessage({ id: 'tournament.sideGame' }),
      })
    }

    return enabledTabs
  }, [primaryRankingEnabled, sideRankingEnabled, intl])

  const closeDialog = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const onBackDropClick = () => {
    closeDialog()
  }

  const onCloseClick = () => {
    closeDialog()
  }

  const onSaveClick = async () => {
    if (!tournament?.id) {
      return
    }
    const tournamentId = tournament?.id
    setLoading(true)
    try {
      /**
       * If changes made to ranking points, we need to save tournament first.
       */
      if (dataIsStale) {
        saveTournament({
          id: tournamentId,
          body: tournament,
          onSuccess: () => {
            dispatch(
              sendRankingPoints(tournamentId, () => {
                setLoading(false)
                closeDialog()
              }),
            )
          },
        })
      } else {
        /**
         * Nochanges to ranking points, just trigger calculation.
         */
        dispatch(
          sendRankingPoints(tournamentId, () => {
            setLoading(false)
            closeDialog()
          }),
        )
      }
    } catch (_) {
      setLoading(false)
    }
  }

  const onTabChange = (_: any, value: RankingDialogTab) => {
    setActiveTab(value)
    setErrors([])
  }

  const onPointsChange = (i: number) => (e: React.FocusEvent<HTMLInputElement>) => {
    const value = formatInputNumber(e.target.value)
    let currentErrors: Error[] = []

    if (isNaN(value)) {
      currentErrors = [...errors, { list: activeTab, index: i }]
      setErrors(currentErrors)
      return
    } else {
      currentErrors = errors.filter((e) => e.list !== activeTab && e.index !== i)
      setErrors(currentErrors)
    }

    if (!activeConfig || currentErrors.length > 0) {
      return
    }

    const config = activeConfig.map((row, idx) => (i === idx ? Object.assign({}, row, { points: value }) : row))

    setDataIsStale(true)

    dispatch(
      updateTournamentField({
        fieldName: `${activeTab}RankingConfig`,
        value: config,
      }),
    )
  }

  const getRankingListItems = (): RankingListItem[] => {
    if (!activeConfig) {
      return []
    }

    const mapLeaderboardData = (tab: RankingDialogTab) => {
      const data = tab === RankingDialogTab.primary ? leaderboard.data?.primary : leaderboard.data?.secondary
      if (!data) {
        return null
      }

      const gameIdFieldName = activeTab === RankingDialogTab.primary ? 'primaryGameId' : 'sideGameId'
      const isTeamFormat = tournament?.rounds.some((_, index) =>
        isTeamFormatId(tournament.rounds[index][gameIdFieldName]),
      )

      return data.players.reduce((result, cur) => {
        const pos = parseInt(cur.position.replace(/[.T]/g, ''), 10)
        if (!isNaN(pos)) {
          if (result[pos]) {
            result[pos] = {
              teamName: isTeamFormat && `${result[pos].teamName}, ${cur.name}`,
              playerName: isTeamFormat
                ? `${result[pos].playerName}, ${cur.team.map((teamPlayer) => teamPlayer.name).join(', ')}`
                : `${result[pos].playerName}, ${cur.name}`,
            }
          } else {
            result[pos] = {
              teamName: isTeamFormat && cur.name,
              playerName: isTeamFormat ? cur.team.map((teamPlayer) => teamPlayer.name).join(', ') : cur.name,
            }
          }
        }
        return result
      }, {})
    }

    const mappedLeaderboard = mapLeaderboardData(activeTab)

    if (!mappedLeaderboard) {
      return []
    }

    return [...activeConfig]
      .sort((itemA, itemB) => itemA.position - itemB.position)
      .map((item) => ({ ...item, ...(mappedLeaderboard[item.position] ?? { playerName: '-', points: item.points }) }))
  }

  const renderActiveTabContent = () => {
    const items = getRankingListItems()

    return (
      <Table>
        <TableHead>
          <TableRow>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="tournament.position" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="tournament.name" />
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="tournament.points" />
            </VeryDenseTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, idx) => (
            <TableRow key={`${activeTab}-${item.position}-${idx}`} className={classes.tableRow}>
              <VeryDenseTableCell>
                <Typography>#{item.position}</Typography>
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                {item.teamName && <Typography className={classes.teamName}>{item.teamName}</Typography>}
                <Typography>{item.playerName}</Typography>
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  defaultValue={formatOutputNumber(item.points)}
                  onBlur={onPointsChange(idx)}
                  style={{ width: 60 }}
                  inputProps={{ style: { textAlign: 'center' } }}
                  error={errors.some((e) => e.list === activeTab && e.index === idx)}
                />
              </VeryDenseTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={(_, reason) => reason !== 'backdropClick' && onBackDropClick}
        aria-labelledby="rankingResults"
        maxWidth={'md'}
      >
        <BaseDialogTitle
          style={{ marginBottom: 10 }}
          id={'ranking-results-dialog'}
          title={<FormattedMessageWrapper id={'tournament.sendRankingPoints'} />}
          onClose={onCloseClick}
        />

        <DialogContent style={{ margin: 16 }}>
          <Typography variant="caption" className={classes.title}>
            <FormattedMessage id="tournament.sendRankingPointsDialogDescription" />
          </Typography>
          <Typography variant="caption" className={classes.note}>
            <FormattedMessage id="tournament.sendRankingPointsDialogNote" />
          </Typography>
          <Tabs
            value={activeTab}
            onChange={onTabChange}
            variant="standard"
            scrollButtons={false}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab key={tab.id} value={tab.id} label={tab.label} />
            ))}
          </Tabs>
          {renderActiveTabContent()}
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onCloseClick} disabled={isLoading}>
            <FormattedMessageWrapper id={'buttons.cancel'} />
          </Button>
          <ButtonLoaderWrap loading={isLoading}>
            <Button
              disabled={isLoading || errors.length > 0}
              onClick={onSaveClick}
              color="primary"
              variant={'contained'}
            >
              <FormattedMessageWrapper id={'buttons.send'} />
            </Button>
          </ButtonLoaderWrap>
        </DialogActions>
      </Dialog>
      <OverlayLoader visible={isLoading} />
    </>
  )
}
