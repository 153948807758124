import { IconButton, Theme } from '@mui/material'
import { WithStyles, WithTheme } from '@mui/styles'
import { KeyboardArrowLeft, KeyboardArrowRight, LastPage, FirstPage } from '@mui/icons-material'
import * as React from 'react'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

const actionsStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2),
    },
  })

class TablePaginationActions extends React.Component<TablePaginationActionsProps & WithStyles<any> & WithTheme<Theme>> {
  handleFirstPageButtonClick = (event) => {
    this.props.onPageChange(event, 0)
  }

  handleBackButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page - 1)
  }

  handleNextButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page + 1)
  }

  handleLastPageButtonClick = (event) => {
    this.props.onPageChange(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1))
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    const prevDisabled = page === 0
    const nextDisabled = page >= Math.ceil(count / rowsPerPage) - 1

    const prevColor = prevDisabled ? 'disabled' : 'primary'
    const nextColor = nextDisabled ? 'disabled' : 'primary'

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={prevDisabled}
          aria-label="First Page"
          size="large"
        >
          {theme.direction === 'rtl' ? <LastPage color={prevColor} /> : <FirstPage color={prevColor} />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={prevDisabled}
          aria-label="Previous Page"
          size="large"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight color={prevColor} />
          ) : (
            <KeyboardArrowLeft color={prevColor} />
          )}
        </IconButton>
        <IconButton onClick={this.handleNextButtonClick} disabled={nextDisabled} aria-label="Next Page" size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft color={nextColor} />
          ) : (
            <KeyboardArrowRight color={nextColor} />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={nextDisabled}
          aria-label="Last Page"
          size="large"
        >
          {theme.direction === 'rtl' ? <FirstPage color={nextColor} /> : <LastPage color={nextColor} />}
        </IconButton>
      </div>
    )
  }
}

export default withStyles(actionsStyles, { withTheme: true })(TablePaginationActions)
