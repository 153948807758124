import React from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Typography } from '@mui/material'
import HeadHelmet from '@components/layout/HeadHelmet'
import ContentWrap from '@components/layout/ContentWrap'
import SectionTitle from '@components/ui/SectionTitle'
import TournamentFormActions from '@components/tournament/TournamentFormActions'
import { SectionWrapper } from '@components/layout/SectionWrapper'
interface Props {
  onClickNext: () => void
}

class NoStartTimesComponent extends React.Component<Props> {
  render() {
    const { onClickNext } = this.props

    return (
      <SectionWrapper>
        <HeadHelmet titleId={'progress.groups'} />

        <ContentWrap>
          <SectionTitle>
            <FormattedMessageWrapper id={'tournament.startListForRound'} />
          </SectionTitle>

          <Typography variant="body1">
            <strong>
              <FormattedMessageWrapper id="tournament.startlistNoStartTimes" />
            </strong>
          </Typography>
        </ContentWrap>

        <ContentWrap style={{ background: 'transparent' }}>
          <TournamentFormActions onClickNext={onClickNext} nextLabelId={'buttons.resultsAndOptions'} />
        </ContentWrap>
      </SectionWrapper>
    )
  }
}

export const NoStartTimes = NoStartTimesComponent
