import React, { Component } from 'react'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { Theme, Switch, Typography, TextField, FormLabel, Button, Grid } from '@mui/material'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import { updateOrganization, UpdateOrganizationPayload } from '@store/organization/actions'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { isRoleAdmin, isRoleInfoProviderAdmin } from '@utils/authUtils'
import { FI_COUNTRY_ID, PaymentProvider, SV_COUNTRY_ID } from '@app/config'
import { RootState } from '@app/store'
import SectionTitle from '../ui/SectionTitle'
import { omit } from 'lodash'

interface State {
  paymentCompanyId: string
  paymentCompanyName: string
  paymentTaxNumber: string
  paymentAddress1: string
  paymentAddress2: string
  paymentZipCode: string
  paymentCountry: string
  paymentCity: string
  netsEnabled: boolean
  stripeEnabled: boolean
  stripe: {
    paymentMerchantId: string
    paymentToken: string
    paymentCallbackAPIKey: string
  }
  nets: {
    paymentMerchantId: string
    paymentToken: string
    paymentCallbackAPIKey: string
  }
  validationErrors: string[]
}

interface StateProps {
  auth: AuthenticationState
  organization: OrganizationState
}

interface DispatchProps {
  updateOrganization(payload: UpdateOrganizationPayload): void
}

type Props = DispatchProps & StateProps & WithStyles<typeof styles> & WrappedComponentProps

const styles = (theme: Theme) =>
  createStyles({
    providerTitleContainer: {
      marginTop: 20,
      marginBottom: 20,
    },
    paymentProviders: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 40,
    },
    paymentProviderTitle: {
      marginRight: 10,
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    formLabel: {
      marginBottom: 15,
      marginTop: 0,
    },
    middleContainer: {
      marginBottom: 20,
      marginTop: 20,
    },
    sendBtn: {
      marginTop: 50,
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  })

const initialState: State = {
  paymentCompanyId: '',
  paymentCompanyName: '',
  paymentTaxNumber: '',
  paymentAddress1: '',
  paymentAddress2: '',
  paymentZipCode: '',
  paymentCountry: '',
  paymentCity: '',
  netsEnabled: false,
  stripeEnabled: false,
  stripe: {
    paymentMerchantId: '',
    paymentToken: '',
    paymentCallbackAPIKey: '',
  },
  nets: {
    paymentMerchantId: '',
    paymentToken: '',
    paymentCallbackAPIKey: '',
  },
  validationErrors: [],
}

const HIDDEN_SAVED_VALUE = '*****'

class OrganizationPaymentSettings extends Component<Props, State> {
  readonly state: State = initialState

  componentDidMount() {
    const { organization } = this.props

    const isStripeEnabled = organization.paymentEnabled && organization.paymentProvider === PaymentProvider.STRIPE
    const isNetsEnabled = organization.paymentEnabled && organization.paymentProvider === PaymentProvider.NETS
    const merchantId = organization.paymentMerchantId || ''

    this.setState({
      stripeEnabled: isStripeEnabled || false,
      netsEnabled: isNetsEnabled || false,
      paymentCompanyId: organization.paymentCompanyId || '',
      paymentTaxNumber: organization.paymentTaxNumber || '',
      paymentCompanyName: organization.paymentCompanyName || '',
      paymentCity: organization?.addressInfo?.paymentCity || '',
      paymentCountry: organization?.addressInfo?.paymentCountry || '',
      paymentZipCode: organization?.addressInfo?.paymentZipCode || '',
      paymentAddress1: organization?.addressInfo?.paymentAddress1 || '',
      paymentAddress2: organization?.addressInfo?.paymentAddress2 || '',
      stripe: {
        paymentMerchantId: isStripeEnabled ? merchantId : '',
        paymentToken: isStripeEnabled ? HIDDEN_SAVED_VALUE : '',
        paymentCallbackAPIKey: '',
      },
      nets: {
        paymentMerchantId: isNetsEnabled ? merchantId : '',
        paymentToken: isNetsEnabled ? HIDDEN_SAVED_VALUE : '',
        paymentCallbackAPIKey: isNetsEnabled ? HIDDEN_SAVED_VALUE : '',
      },
    })
  }

  private onHandleChange = (e: React.ChangeEvent<{ name?: string; value: string | number }>) => {
    const { name = '', value } = e.target
    this.setState({
      ...this.state,
      [name]: value,
      validationErrors: this.state.validationErrors.filter((error) => error !== name),
    })
  }

  private handleProviderDataChange = (e: React.ChangeEvent<{ name?: string; value: string | number }>) => {
    const { name = '', value } = e.target
    const { stripeEnabled, netsEnabled, stripe, nets } = this.state

    this.setState({
      stripe: {
        ...stripe,
        [name]: stripeEnabled ? value : stripe[name],
      },
      nets: {
        ...nets,
        [name]: netsEnabled ? value : nets[name],
      },
    })
  }

  private onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isStripe = e.currentTarget.name === PaymentProvider.STRIPE
    const isNets = e.currentTarget.name === PaymentProvider.NETS
    const checked = e.currentTarget.checked

    this.setState({
      stripeEnabled: isStripe ? checked : false,
      netsEnabled: isNets ? checked : false,
      validationErrors: [],
    })
  }

  private validatePaymentFields = (payload: UpdateOrganizationPayload) => {
    const payloadKeys = Object.keys(payload)
    const errors: string[] = []
    payloadKeys.forEach((key) => {
      if (key.startsWith('payment') || key.startsWith('stripe')) {
        let value = payload[key]
        value = typeof value === 'string' ? value.replace(HIDDEN_SAVED_VALUE, '') : value

        const isEmptyString =
          typeof value === 'string' && value.trim() === '' && !['paymentAddress2', 'paymentTaxNumber'].includes(key)

        const notNeeded = this.state.stripeEnabled
          ? key === 'paymentCallbackAPIKey' || key === 'paymentToken'
          : key === 'stripeApiKey'

        const isNullOrUndefined = value === undefined || value === null

        if ((isEmptyString || isNullOrUndefined) && !notNeeded) {
          errors.push(key)
        }
      }
    })
    this.setState({ validationErrors: errors })
    return errors.length === 0
  }

  private handleSave = () => {
    const { organization, updateOrganization } = this.props
    const { stripeEnabled, netsEnabled, stripe, nets } = this.state
    const paymentEnabled = stripeEnabled || netsEnabled

    let paymentMerchantId = stripeEnabled ? stripe.paymentMerchantId : undefined
    paymentMerchantId = netsEnabled ? nets.paymentMerchantId : paymentMerchantId

    const statePayload = omit(this.state, ['stripe', 'nets', 'stripeEnabled', 'netsEnabled', 'validationErrors'])

    const organizationObj: UpdateOrganizationPayload = {
      organizationId: organization.id,
      ...statePayload,
      paymentMerchantId: paymentMerchantId,
      paymentToken: netsEnabled ? nets.paymentToken : undefined,
      paymentCallbackAPIKey: netsEnabled ? nets.paymentCallbackAPIKey : undefined,
      stripeApiKey: stripeEnabled ? stripe.paymentToken : undefined,
      paymentEnabled,
      paymentProvider: stripeEnabled ? PaymentProvider.STRIPE : PaymentProvider.NETS,
      timeZoneId: organization.addressInfo?.timeZoneId,
    }

    if (paymentEnabled && this.validatePaymentFields(organizationObj) === false) {
      return
    }

    //No need for other payment values, if payment is disabled
    if (!paymentEnabled) {
      const data: UpdateOrganizationPayload = {
        paymentEnabled,
        organizationId: organization.id,
        paymentProvider: organization.paymentProvider,
      }
      updateOrganization(data)
    } else {
      updateOrganization(organizationObj)
    }
  }

  private isDisabled = (disableIfPaymentsNotEnabled?: boolean) => {
    const { auth } = this.props
    const { stripeEnabled, netsEnabled } = this.state
    const paymentEnabled = stripeEnabled || netsEnabled

    const isCountryIdCorrect = (): boolean =>
      typeof auth.customerInfo?.countryId !== 'undefined' &&
      [null, FI_COUNTRY_ID, SV_COUNTRY_ID].includes(auth.customerInfo?.countryId)

    if (!isRoleInfoProviderAdmin(auth.roleInfo) || !isRoleAdmin(auth.roleInfo) || !isCountryIdCorrect()) {
      return true
    }

    if (!paymentEnabled && disableIfPaymentsNotEnabled) {
      return true
    }

    return false
  }

  private maybeRenderPaymentProviderFields = () => {
    const { stripeEnabled, netsEnabled } = this.state

    if (stripeEnabled || netsEnabled) {
      return this.renderPaymentProviderFields()
    }

    return
  }

  private getPaymentProviderFieldLabels = () => {
    const { stripeEnabled } = this.state
    if (stripeEnabled) {
      return {
        merchantIdLabel: 'payments.paymentSettings.accountId',
        tokenLabel: 'payments.paymentSettings.secretApiKey',
        callbackApiKeyLabel: '',
      }
    }

    return {
      merchantIdLabel: 'payments.paymentSettings.merchantID',
      tokenLabel: 'payments.paymentSettings.token',
      callbackApiKeyLabel: 'payments.paymentSettings.callbackApiKey',
    }
  }

  private getPaymentProviderFieldValues = () => {
    const { stripeEnabled, stripe, nets } = this.state
    if (stripeEnabled) {
      return stripe
    }
    return nets
  }

  private renderPaymentProviderFields = () => {
    const { classes, intl } = this.props
    const { merchantIdLabel, tokenLabel, callbackApiKeyLabel } = this.getPaymentProviderFieldLabels()
    const { paymentMerchantId, paymentToken, paymentCallbackAPIKey } = this.getPaymentProviderFieldValues()

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FormLabel>
              <FormattedMessageWrapper id={merchantIdLabel} />
            </FormLabel>
            <TextField
              id="paymentMerchantId"
              name="paymentMerchantId"
              variant="standard"
              fullWidth
              value={paymentMerchantId}
              disabled={this.isDisabled(true)}
              onChange={this.handleProviderDataChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={this.state.validationErrors.includes('paymentMerchantId')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FormLabel>
              <FormattedMessageWrapper id={tokenLabel} />
            </FormLabel>
            <TextField
              id="paymentToken"
              name="paymentToken"
              variant="standard"
              fullWidth
              value={paymentToken}
              disabled={this.isDisabled(true)}
              onChange={this.handleProviderDataChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={
                this.state.validationErrors.includes('paymentToken') ||
                this.state.validationErrors.includes('stripeApiKey')
              }
            />
          </Grid>
        </Grid>
        {this.state.netsEnabled && (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <FormLabel>
                <FormattedMessageWrapper id={callbackApiKeyLabel} />
              </FormLabel>
              <TextField
                id="paymentCallbackAPIKey"
                name="paymentCallbackAPIKey"
                variant="standard"
                fullWidth
                value={paymentCallbackAPIKey}
                disabled={this.isDisabled(true)}
                onChange={this.handleProviderDataChange}
                className={classes.formLabel}
                placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
                error={this.state.validationErrors.includes('paymentCallbackAPIKey')}
              />
            </Grid>
          </Grid>
        )}
      </>
    )
  }

  render() {
    const { classes, intl } = this.props
    const {
      paymentCompanyId,
      paymentCompanyName,
      paymentTaxNumber,
      paymentAddress1,
      paymentAddress2,
      paymentCity,
      paymentZipCode,
      paymentCountry,
      stripeEnabled,
      netsEnabled,
    } = this.state

    return (
      <>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="payments.paymentSettings.companyID" />
            </FormLabel>
            <TextField
              id="paymentCompanyId"
              name="paymentCompanyId"
              variant="standard"
              fullWidth
              value={paymentCompanyId}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={this.state.validationErrors.includes('paymentCompanyId')}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="payments.paymentSettings.taxNumber" />
            </FormLabel>
            <TextField
              id="paymentTaxNumber"
              name="paymentTaxNumber"
              variant="standard"
              fullWidth
              value={paymentTaxNumber}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={this.state.validationErrors.includes('paymentTaxNumber')}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="payments.paymentSettings.companyName" />
            </FormLabel>
            <TextField
              id="paymentCompanyName"
              name="paymentCompanyName"
              variant="standard"
              fullWidth
              value={paymentCompanyName}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={this.state.validationErrors.includes('paymentCompanyName')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.middleContainer}>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="payments.paymentSettings.address1" />
            </FormLabel>
            <TextField
              id="paymentAddress1"
              name="paymentAddress1"
              variant="standard"
              fullWidth
              value={paymentAddress1}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={this.state.validationErrors.includes('paymentAddress1')}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="payments.paymentSettings.address2" />
            </FormLabel>
            <TextField
              id="paymentAddress2"
              name="paymentAddress2"
              variant="standard"
              fullWidth
              value={paymentAddress2}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.city" />
            </FormLabel>
            <TextField
              id="paymentCity"
              name="paymentCity"
              variant="standard"
              fullWidth
              value={paymentCity}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={this.state.validationErrors.includes('paymentCity')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.middleContainer}>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="payments.paymentSettings.zipCode" />
            </FormLabel>
            <TextField
              id="paymentZipCode"
              name="paymentZipCode"
              variant="standard"
              fullWidth
              value={paymentZipCode}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={'00000'}
              error={this.state.validationErrors.includes('paymentZipCode')}
            />
          </Grid>
          <Grid item xs={3}>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.country" />
            </FormLabel>
            <TextField
              id="paymentCountry"
              name="paymentCountry"
              variant="standard"
              fullWidth
              value={paymentCountry}
              disabled={this.isDisabled()}
              onChange={this.onHandleChange}
              className={classes.formLabel}
              placeholder={intl.formatMessage({ id: 'payments.paymentSettings.placeholder' })}
              error={this.state.validationErrors.includes('paymentCountry')}
            />
          </Grid>
        </Grid>
        <div className={classes.providerTitleContainer}>
          <SectionTitle>
            <FormattedMessageWrapper id={'profile.paymentProvider'} />
          </SectionTitle>
        </div>
        <div className={classes.paymentProviders}>
          <Typography variant="h5" className={classes.paymentProviderTitle}>
            {PaymentProvider.STRIPE}
          </Typography>
          <Switch
            checked={stripeEnabled}
            onChange={this.onSwitchChange}
            name="stripe"
            color="primary"
            disabled={this.isDisabled()}
          />
          <Typography variant="h5" className={classes.paymentProviderTitle}>
            {PaymentProvider.NETS}
          </Typography>
          <Switch
            checked={netsEnabled}
            onChange={this.onSwitchChange}
            name="nets"
            color="primary"
            disabled={this.isDisabled()}
          />
        </div>
        {this.maybeRenderPaymentProviderFields()}
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleSave}
          className={classes.sendBtn}
          disabled={this.isDisabled()}
        >
          <FormattedMessageWrapper id="buttons.save" />
        </Button>
      </>
    )
  }
}

export default injectIntl(
  withStyles(styles)(
    connect<StateProps, DispatchProps, {}, RootState>(
      (store) => ({
        auth: store.authenticationReducer,
        organization: store.organizationReducer,
      }),
      {
        updateOrganization,
      },
    )(OrganizationPaymentSettings),
  ),
)
