export const TOURNAMENTS_FETCH = 'TOURNAMENTS_FETCH'
export const TOURNAMENTS_FETCH_SUCCESS = 'TOURNAMENTS_FETCH_SUCCESS'
export const TOURNAMENTS_FETCH_FAILURE = 'TOURNAMENTS_FETCH_FAILURE'

export const CONDENSED_TOURNAMENTS_FETCH = 'CONDENSED_TOURNAMENTS_FETCH'
export const CONDENSED_TOURNAMENTS_FETCH_SUCCESS = 'CONDENSED_TOURNAMENTS_FETCH_SUCCESS'

export const TOURNAMENT_REMOVE = 'TOURNAMENT_REMOVE'
export const TOURNAMENT_REMOVE_SUCCESS = 'TOURNAMENT_REMOVE_SUCCESS'
export const TOURNAMENT_REMOVE_FAILURE = 'TOURNAMENT_REMOVE_FAILURE'

export const TOURNAMENT_CALENDAR_TOGGLE = 'TOURNAMENT_CALENDAR_TOGGLE'
export const TOURNAMENT_CALENDAR_TOGGLE_SUCCESS = 'TOURNAMENT_CALENDAR_TOGGLE_SUCCESS'
export const TOURNAMENT_CALENDAR_TOGGLE_FAILURE = 'TOURNAMENT_CALENDAR_TOGGLE_FAILURE'

export const FETCH_FILTER_YEARS = 'FETCH_FILTER_YEARS'
export const FETCH_FILTER_YEARS_SUCCESS = 'FETCH_FILTER_YEARS_SUCCESS'
export const FETCH_FILTER_YEARS_FAILURE = 'FETCH_FILTER_YEARS_FAILURE'

export const TOURNAMENTS_CLEAR_ALL = 'TOURNAMENTS_CLEAR_ALL'
