import React from 'react'
import { Grid, TextField, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { updateScheduleEntry } from '@store/api/thunks/tournamentSiteThunks'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import RemoveRowAction from '../ui/RemoveRowAction'
import { setTournamentDirty } from '@app/store/api/slices/configSlice'
import { SimpleSelectTimePicker } from '../date-pickers'
import { convertTo12hFormat, convertTo24hFormat, formatDate, maybeAddLeadingZero } from '@app/utils/dates'
import { Units } from '@app/store/api/enums/tournamentEnums'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: 768,
      marginBottom: 15,
    },
    time: {
      alignSelf: 'center',
      marginRight: 25,
    },
    remove: {
      alignSelf: 'center',
      marginLeft: 25,
      color: theme.customPalette.darkGray2,
    },
  })

interface OwnProps {
  index: number
  scheduleEntry: ScheduleEntry
  round: number
  onRemoveClick?: (idx: number) => void
}

interface StateProps {
  units: OrganizationUnits
}

interface DispatchProps {
  updateScheduleEntry(payload: ScheduleEntryPayload): void
  setTournamentDirty(dirty: boolean): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

class UnconnectedScheduleEntry extends React.Component<Props> {
  onChange = (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const fieldName = e.currentTarget.name
    const value = e.currentTarget.value
    this.props.updateScheduleEntry({
      index: this.props.index,
      round: this.props.round,
      fieldName,
      value,
    })
    this.props.setTournamentDirty(true)
  }

  onTimeChange = (hours: number, minutes: number, abbreviation: string) => {
    this.props.updateScheduleEntry({
      index: this.props.index,
      round: this.props.round,
      fieldName: 'hours',
      value: convertTo24hFormat(hours, abbreviation),
    })
    this.props.updateScheduleEntry({
      index: this.props.index,
      round: this.props.round,
      fieldName: 'minutes',
      value: minutes,
    })
    this.props.setTournamentDirty(true)
  }

  getScheduleHoursWithLocalization = (): string => {
    const {
      scheduleEntry: { time, hours },
      units,
    } = this.props

    if (hours >= 0) {
      return units === Units.US ? convertTo12hFormat(hours) : maybeAddLeadingZero(hours)
    }

    // We need to support an old Date object too
    return formatDate(time ?? new Date(), 'hours', units)
  }

  getScheduleMinutes = (): string => {
    const {
      scheduleEntry: { time, minutes },
      units,
    } = this.props
    // We need to support an old Date object too
    return minutes?.toString().padStart(2, '0') ?? formatDate(time ?? '00', 'minutes', units)
  }

  render() {
    const {
      intl,
      scheduleEntry: { description },
      classes,
      onRemoveClick,
      setTournamentDirty,
      index,
      units,
    } = this.props

    return (
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <div className={classes.root}>
            <div className={classes.time}>
              <SimpleSelectTimePicker
                hoursWithLocalization={this.getScheduleHoursWithLocalization()}
                minutes={this.getScheduleMinutes()}
                margin={'none'}
                onChange={this.onTimeChange}
                units={units}
              />
            </div>
            <TextField
              fullWidth={true}
              margin={'dense'}
              placeholder={intl.formatMessage({ id: 'tournament.scheduleDescription' })}
              name="description"
              value={description}
              onChange={this.onChange}
            />
            <div className={classes.remove}>
              <RemoveRowAction index={index} onClick={onRemoveClick} setDirtyFn={setTournamentDirty} />
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

const ScheduleEntryBlock = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    units: store.authenticationReducer.units,
  }),
  {
    updateScheduleEntry,
    setTournamentDirty,
  },
)(withStyles(styles)(injectIntl(UnconnectedScheduleEntry as any)))

export default ScheduleEntryBlock
