import { get } from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '..'

export const getAuthToken = (store: RootState) => get(store, 'authenticationReducer.token', null)

export const getUserOrganizationId = (store: RootState) => get(store, 'authenticationReducer.customerInfo.idCustomer')

export const getPermittedRoles = (store: RootState) => store.authenticationReducer.permittedRoles

export const getPermittedRolesIdMap = createSelector(getPermittedRoles, (permittedRoles: RoleInfo[]) =>
  permittedRoles.reduce(
    (obj, item) => ({
      ...obj,
      [item.idRole]: item,
    }),
    {},
  ),
)
