import React from 'react'
import { connect } from 'react-redux'
import { Grid, IconButton, Theme, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { DeleteOutline } from '@mui/icons-material'

import ListPlayer, { PLAYER_LIST_ITEM_HEIGHT_TOTAL } from '@app/components/tournament/ListPlayer'
import { TeamName } from '@app/components/tournament/TeamName'
import { PlayerDialogChildrenArgs } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'

import { rem } from '@app/theme/materialUITheme'
import classNames from 'classnames'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DeleteConfirm, { DeleteConfirmChildren } from '@app/components/dialogs/deleteConfirm/DeleteConfirm'
import { sortTeamPlayers } from '@app/utils/playerUtils'
import { tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { RootState } from '@app/store'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentSettings } from '@app/store/api/endpoints/tournamentSettingsApi'

interface OwnProps extends PlayerDialogChildrenArgs {
  team: TournamentTeam
  onOpenTeamReserveDialog: (teamId: number | undefined) => void
}

interface StateProps {
  tournamentSite: TournamentSite
  settings: TournamentSettings
  tournamentId: number
}

interface DispatchProprs {
  deleteTeam(payload: DeleteTeamPayload): void
  updateTeam(payload: UpdateTeamPayload): void
  removePlayerFromTeam: (payload: EditTeamPlayerPayload) => void
}

export type TeamProps = DispatchProprs & StateProps & React.HtmlHTMLAttributes<HTMLDivElement> & OwnProps

const useStyles = makeStyles((theme: Theme) => ({
  dropBox: {
    position: 'relative',
    transition: 'min-height 0.2s ease',
  },
  dropBoxItemHoveringOver: {
    backgroundColor: '#ddd',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  outerBox: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    flexBasis: '49%',
    position: 'relative',
    padding: 5,
    backgroundColor: theme.customPalette.lightGray,
    border: `2px solid transparent`,
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.3)',
    borderRadius: 4,
    '&.hasError': {
      borderColor: theme.customPalette.error,
    },
    '@media only screen and (max-width: 1819px)': {
      flexGrow: 1,
    },
  },
  headerWrapper: {
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.contrastText,
  },
  headerWrapperFull: {
    background: theme.palette.primary.main,
  },
  headerWrapperPartial: {
    background: theme.customPalette.warning,
  },
  teamHeaderName: {
    whiteSpace: 'nowrap',
    paddingRight: theme.spacing(3),
    display: 'flex',
  },
  teamHeaderHCP: {
    display: 'flex',
    alignItems: 'center',
  },
  teamHeaderDelete: {
    textAlign: 'end',
  },
  deleteIcon: {
    color: theme.palette.primary.contrastText,
  },
  teamFull: {
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: '100%',
    backgroundColor: '#a83528',
    opacity: 0,
    visibility: 'hidden',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.7rem',
    color: 'black',

    //animation
    transition: 'opacity 0.2s ease, visibility 0.2s ease',
  },
  teamFullActive: {
    opacity: 0.9,
    visibility: 'visible',
  },
  teamEmpty: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#ddd',
    border: '1px solid #ccc',
    opacity: 0,
    visibility: 'hidden',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.1rem',
    color: '#888',

    //animation
    transition: 'opacity 0.2s ease, visibility 0.2s ease',
  },
  teamEmptyActive: {
    opacity: 0.9,
    visibility: 'visible',
  },
}))

const TeamComponent: React.FC<TeamProps> = ({
  team,
  deleteTeam,
  updateTeam,
  removePlayerFromTeam,
  openPlayerDialog,
  onClosePlayerDialog,
  onOpenTeamReserveDialog,
  tournamentSite,
  settings,
  tournamentId,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const draggableId = () => {
    if (team.id && team.id < 0) {
      return `newTeam-${team.id * -1}`
    }
    return `team-${team.id}`
  }

  const handleEditTeam = (value: string) => {
    updateTeam({ tournamentId, name: value, teamId: team.id })
  }

  const removeTeamPlayer = (playerId: number) => {
    removePlayerFromTeam({ tournamentId: tournamentId, teamId: team.id, playerId: playerId })
  }

  const RenderDeleteButton = () => {
    const { reserveListEnabled } = tournamentSite

    return (
      <>
        {reserveListEnabled ? (
          <IconButton size="small" className={classes.deleteIcon} onClick={() => onOpenTeamReserveDialog(team.id)}>
            <DeleteOutline fontSize="small" />
          </IconButton>
        ) : (
          <DeleteConfirm>
            {({ showConfirm }: DeleteConfirmChildren) => (
              <IconButton
                size="small"
                className={classes.deleteIcon}
                onClick={() => {
                  showConfirm({
                    messageId: 'tournament.removeTourConfirm',
                    values: { tourName: team.name },
                    callback: () => deleteTeam({ tournamentId, teamId: team.id }),
                  })
                }}
              >
                <DeleteOutline fontSize="small" />
              </IconButton>
            )}
          </DeleteConfirm>
        )}
      </>
    )
  }

  const RenderTeamHeader = () => {
    const teamNameStyle = {
      fontSize: rem(18),
      fontStyle: 'italic',
      fontFamily: 'Exo',
      color: theme.palette.common.white,
    }

    const headerWrapperStyle =
      team.players.length < settings.defaultTeamSize ? classes.headerWrapperPartial : classes.headerWrapperFull

    return (
      <Grid container className={classNames([classes.headerWrapper, headerWrapperStyle])}>
        <Grid item xs={9} className={classes.teamHeaderName}>
          <TeamName style={teamNameStyle} onChange={handleEditTeam} name={team.name} />
        </Grid>
        <Grid item xs={2} className={classes.teamHeaderHCP}>
          <div>HCP</div>
        </Grid>
        <Grid item xs={1} className={classes.teamHeaderDelete}>
          <RenderDeleteButton />
        </Grid>
      </Grid>
    )
  }

  const TeamFullComponent = ({ isVisible }) => {
    return (
      <div className={classNames([classes.teamFull, isVisible && classes.teamFullActive])}>
        <FormattedMessageWrapper id="notifications.teamFull" />
      </div>
    )
  }

  const TeamEmptyComponent = ({ isVisible }) => {
    return (
      <div className={classNames([classes.teamEmpty, isVisible && classes.teamEmptyActive])}>
        <FormattedMessageWrapper id="notifications.teamEmpty" />
      </div>
    )
  }

  const teamBoxMinHeight =
    team.players?.length > 1 && team.players?.length < 5
      ? `${(team.players.length + 1) * PLAYER_LIST_ITEM_HEIGHT_TOTAL}px`
      : `${2 * PLAYER_LIST_ITEM_HEIGHT_TOTAL}px`

  return (
    <div className={classes.outerBox}>
      <RenderTeamHeader />
      <Droppable droppableId={draggableId()} type="droppablePlayer">
        {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
          <div
            className={classNames([classes.dropBox, dropSnapshot.isDraggingOver && classes.dropBoxItemHoveringOver])}
            ref={dropProvided.innerRef}
            style={{
              minHeight:
                dropSnapshot.isDraggingOver && team.players.length > 1 && team.players.length < 5
                  ? `${(team.players.length + 1) * PLAYER_LIST_ITEM_HEIGHT_TOTAL}px`
                  : teamBoxMinHeight,
            }}
            {...dropProvided.droppableProps}
          >
            <TeamFullComponent
              isVisible={team.players.length > 4 && !dropSnapshot.draggingFromThisWith && dropSnapshot.draggingOverWith}
            />

            <TeamEmptyComponent isVisible={team.players.length === 0 && !dropSnapshot.isDraggingOver} />

            {sortTeamPlayers(team.players).map((player, index) => (
              <ListPlayer
                key={player.userId}
                tournamentPlayer={player}
                index={index}
                animationDisabled
                openPlayerDialog={openPlayerDialog}
                onClosePlayerDialog={onClosePlayerDialog}
                team={team}
                removeAction={removeTeamPlayer}
                usedByComponent="Team"
              />
            ))}
          </div>
        )}
      </Droppable>
    </div>
  )
}

/**
 * Optimisation to prevent rendering every team if something changes. Only rerender a team if an action affects it.
 */
const memoWrappedTeamComponent = React.memo(TeamComponent, (prevProps: any, nextProps: any) => {
  const prevTeam: TournamentTeam = prevProps.team
  const nextTeam: TournamentTeam = nextProps.team

  // Rerender if player handicaps change
  for (let i = 0; i < prevTeam.players.length; i++) {
    if (prevTeam.players[i]?.hcp !== nextTeam.players[i]?.hcp) {
      return false
    }
  }

  // Rerender if team size or name changes
  if (prevTeam.players.length !== nextTeam.players.length || prevTeam.name !== nextTeam.name) {
    return false
  }

  // In other cases don't rerender
  return true
})

export const Team = connect<StateProps, DispatchProprs, {}, RootState>(
  (state) => ({
    tournamentSite: selectTournamentSite(state),
    settings: selectTournamentSettings(state),
    tournamentId: getTournamentId(state),
  }),
  {
    deleteTeam: tournamentTeamsApi.endpoints.deleteTeam.initiate,
    updateTeam: tournamentTeamsApi.endpoints.updateTeam.initiate,
    removePlayerFromTeam: tournamentTeamsApi.endpoints.removePlayerFromTeam.initiate,
  },
)(memoWrappedTeamComponent)
