import { GameFormats } from '../store/api/enums/tournamentEnums'

const twoManFormats = [
  GameFormats.STROKE_PLAY_BETTER_BALL,
  GameFormats.STABLEFORD_BETTER_BALL,
  GameFormats.STROKE_PLAY_SCRAMBLE_2_MAN,
  GameFormats.STROKE_PLAY_GREENSOME,
  GameFormats.STABLEFORD_GREENSOME,
  GameFormats.STROKE_PLAY_FOURSOME,
  GameFormats.STABLEFORD_FOURSOME,
]

const threeOrMoreManFormats = [
  GameFormats.STROKE_PLAY_BEST_BALL,
  GameFormats.STROKE_PLAY_SCRAMBLE,
  GameFormats.STROKE_PLAY_LOW_SCRATCH_NET,
  GameFormats.STABLEFORD_BEST_BALL,
  GameFormats.STABLEFORD_SCRAMBLE_DUPLICATE,
  GameFormats.IRISH_RUMBLE_BEST_BALL,
  GameFormats.TEAM_PAR_IS_YOUR_FRIEND,
]

const specialTeamFormats = [
  GameFormats.STROKE_PLAY_SCRAMBLE,
  GameFormats.MATCH_PLAY_SCRAMBLE,
  GameFormats.STROKE_PLAY_SCRAMBLE_2_MAN,
  GameFormats.STROKE_PLAY_GREENSOME,
  GameFormats.STROKE_PLAY_FOURSOME,
  GameFormats.STABLEFORD_SCRAMBLE_DUPLICATE,
  GameFormats.STABLEFORD_GREENSOME,
  GameFormats.STABLEFORD_FOURSOME,
  GameFormats.MATCH_PLAY_GREENSOME,
  GameFormats.MATCH_PLAY_FOURSOME,
]

const teamScoredTeamFormats = [
  GameFormats.STROKE_PLAY_SCRAMBLE,
  GameFormats.STROKE_PLAY_SCRAMBLE_2_MAN,
  GameFormats.STROKE_PLAY_GREENSOME,
  GameFormats.STROKE_PLAY_FOURSOME,
  GameFormats.STABLEFORD_GREENSOME,
  GameFormats.STABLEFORD_FOURSOME,
]

const separatelyScoredTeamFormats = [
  GameFormats.STROKE_PLAY_BETTER_BALL,
  GameFormats.STABLEFORD_BETTER_BALL,
  GameFormats.STROKE_PLAY_BEST_BALL,
  GameFormats.STABLEFORD_BEST_BALL,
  GameFormats.IRISH_RUMBLE_BEST_BALL,
  GameFormats.TEAM_PAR_IS_YOUR_FRIEND,
  GameFormats.STROKE_PLAY_LOW_SCRATCH_NET,
]

export const gameFormatsStrings = {
  2: 'Stroke play individual',
  4: 'Stroke play better ball',
  5: 'Stroke play best ball',
  8: 'Stroke play scramble',
  11: 'Stroke play scramble 2 man',
  6: 'Stroke play erado',
  13: 'Stroke play skins',
  14: 'Stroke play low scratch net',
  17: 'Stroke play greensome',
  19: 'Stroke play foursome',
  22: 'Stroke play college',

  3: 'Stableford individual',
  9: 'Stableford individual duplicate',
  15: 'Stableford better ball',
  7: 'Stableford best ball',
  12: 'Stableford scramble duplicate',
  16: 'Stableford greensome',
  18: 'Stableford foursome',

  23: 'Match play individual',
  24: 'Match play better ball',
  25: 'Match play scramble',
  27: 'Match play greensome',
  28: 'Match play foursome',

  20: 'Irish rumble best ball',
  21: 'Team par is your friend',
}

export enum GameFormatBase {
  INDIVIDUAL = 'Individual',
  TEAM_2_PLAYERS = 'Team 2 players',
  TEAM_3_OR_MORE_PLAYERS = 'Team 3 or more players',
}

export const isIndividualFormatId = (formatId: number): boolean =>
  !twoManFormats.includes(formatId) && !threeOrMoreManFormats.includes(formatId)

export const isTeamFormatId = (formatId: number): boolean =>
  twoManFormats.includes(formatId) || threeOrMoreManFormats.includes(formatId)

export const isTwoPlayerFormatId = (formatId: number) => twoManFormats.includes(formatId)

export const isThreeOrMorePlayerFormatId = (formatId: number) => threeOrMoreManFormats.includes(formatId)

export const isSpecialTeamFormatId = (formatId: number) => specialTeamFormats.includes(formatId)

export const isIndividualTeamFormatId = (formatId: number) => separatelyScoredTeamFormats.includes(formatId)

export const isTeamScoreTeamFormatId = (formatId: number) => teamScoredTeamFormats.includes(formatId)

/**
 * User Defined Type Guard!
 */
function isGameFormat(arg: GameFormat | GameFormatItem): arg is GameFormat {
  return Object.prototype.hasOwnProperty.call(arg, 'options')
}

const reducePossibleItems = (parents: string[]) => {
  return (acc, item) => {
    if (item.options) {
      const currentParents = parents.concat(item.key)
      return acc.concat([item, ...item.options.reduce(reducePossibleItems(currentParents), [])])
    } else {
      const updatedItem = { ...item, parents }
      return acc.concat([updatedItem])
    }
  }
}

export function flattenGameFormats(gameFormats: GameFormat[]): GameFormatItem[] {
  return gameFormats.reduce(reducePossibleItems([]), []).reduce((acc, item) => acc.concat(item), []) // flatten
}

export function getFieldNameFromLabel(label: string) {
  return label.split('.').slice(-1).pop() || ''
}

export function gameFormatGuardExecutor(values: any, guard?: GameFormatOptionsGuardClause) {
  if (!guard) {
    return true
  }

  const fieldValue = values[guard.field]

  if (!fieldValue) {
    return false
  }

  // guard present, handle
  switch (guard.comparator) {
    case 'eq':
      return fieldValue === guard.value
    case 'ne':
      return fieldValue !== guard.value
    case 'gt':
      return fieldValue > guard.value
    case 'lt':
      return fieldValue < guard.value
    default:
      return false
  }
}

export const getGameFormatPrimaryIdMap = (gameFormats: GameFormat[]): GameFormatPrimaryIdMap => {
  const allFormats = flattenGameFormats(gameFormats)
  return allFormats.reduce(
    (acc: GameFormatPrimaryIdMap, format: GameFormatItem) => ({
      ...acc,
      [format.primaryId]: format.id,
    }),
    {},
  )
}

const reverseGameFormatPrimaryIdsToIds = (
  allowedSideGameFormats: number[],
  primaryIdMap: GameFormatPrimaryIdMap,
): number[] => allowedSideGameFormats.map((primaryId) => primaryIdMap[primaryId]).filter(Boolean)

export const replaceAllowedSideGamePrimaryIds = (
  gameFormats: (GameFormat | GameFormatItem)[],
  primaryIdMap: GameFormatPrimaryIdMap,
) => {
  return gameFormats.map((gameFormat: GameFormat | GameFormatItem) => {
    if (isGameFormat(gameFormat)) {
      return {
        ...gameFormat,
        options: replaceAllowedSideGamePrimaryIds(gameFormat.options, primaryIdMap),
      }
    } else {
      return {
        ...gameFormat,
        _allowedSideGameFormats: gameFormat.allowedSideGameFormats,
        allowedSideGameFormats: reverseGameFormatPrimaryIdsToIds(gameFormat.allowedSideGameFormats, primaryIdMap),
      }
    }
  })
}

export const verifyGameHandicapPercentage = (value: any) => {
  if (value === undefined || value === '') {
    return value
  }

  const val = parseInt(value, 10)

  if (isNaN(val)) {
    return ''
  }

  if (val < 0) {
    return 0
  }

  if (val > 200) {
    return 200
  }

  return val
}

export const MAX_NINE_HOLES_GROUP_COUNT = 90
export const MAX_EIGHTEEN_HOLE_GROUP_COUNT = MAX_NINE_HOLES_GROUP_COUNT * 2

export const addRoundsToField = (value: any, rounds: number, defaultValue: any) => {
  const finalField: any[] = []
  Array.from({ length: rounds }).forEach((_, index) => {
    finalField.push(value && value[index] ? value[index] : defaultValue)
  })
  return finalField
}

export const gameFormatIdToStringLookup = (gameFormatId: number) => gameFormatsStrings[gameFormatId]
