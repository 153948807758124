import { GameFormatTypes } from '@app/store/api/enums/tournamentEnums'
import { formatDate } from '@app/utils/dates'
import { gameFormatIdToStringLookup, isSpecialTeamFormatId, isTeamFormatId } from '@app/utils/gameFormatUtils'
import { formatHandicap } from '@app/utils/playerUtils'
import { Style } from '@react-pdf/types'
import { Page, Text, Document, StyleSheet, View } from '@react-pdf/renderer'
import { Footer, TableHeader, TableHeaderProps, TableRow, TableRowProps } from '../../elements'
import { useMemo } from 'react'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '16pt',
  },
  h1: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16pt',
  },
  h2: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14pt',
  },
  header: {
    flexDirection: 'row',
    margin: 0,
  },
  headerLeft: {
    flex: 0.7,
  },
  headerRight: {
    flex: 0.3,
  },
  p: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12pt',
    marginTop: '4pt',
  },
  content: {
    flex: 1,
    flexDirection: 'column',
  },
  bold: {
    fontWeight: 700,
  },
})
export interface ResultsPrintoutProps {
  units: AuthenticationState['units']
  tournamentName: string
  rounds: Round[]
  gameType: GameFormatTypes
  leaderboardRows: TournamentLeaderboardPlayer[]
  divisions: DivisionsState
  roundIndex: number
}

export const ResultsPrintout = ({
  units,
  tournamentName,
  rounds,
  gameType,
  leaderboardRows,
  divisions,
  roundIndex,
}: ResultsPrintoutProps) => {
  const round = rounds.find((round) => round)
  const renderClubAndCourse = () => {
    const isSameCourseInAllRounds = rounds.every((round) => round.courseId === round?.courseId)
    const roundStartTime = round && formatDate(round.startTime, 'date', units)
    const clubAndCourse = `${round?.club?.name || ''} - ${round?.course?.courseName || ''}`

    if (!isSameCourseInAllRounds) {
      return null
    }

    return (
      <>
        {roundStartTime} {clubAndCourse}
      </>
    )
  }

  const renderDivisionName = () => {
    if (divisions.selectedDivisionId === 0) {
      return
    }

    const divisionName =
      divisions?.divisions?.find((division) => division.id === divisions.selectedDivisionId)?.name || ''
    return divisionName
  }

  const headerItems: TableHeaderProps['columns'] = useMemo(() => {
    /**
     * Keep same field width values in headers
     * as in data fields.
     */
    if (rounds.length === 1) {
      return [
        { title: 'Pos', width: 0.1, align: 'center' },
        { title: 'Name', width: 0.7 },
        { title: 'To Par', width: 0.1 },
        { title: 'Score', width: 0.1, align: 'center' },
      ]
    }
    return [
      { title: 'Pos', width: 0.1, align: 'center' },
      { title: 'Name', width: 0.5 },
      { title: 'To Par', width: 0.1, align: 'center' },
      ...rounds.map((round) => ({ title: `R${round.roundNumber}`, width: 0.2 / rounds.length, align: 'center' })),
      { title: 'Total', width: 0.1, align: 'center' },
    ] as TableHeaderProps['columns']
  }, [rounds])

  const renderTeams = () => {
    return leaderboardRows.map((team, idx) => {
      const { position, toPar, score, rounds } = team

      const teamPlayerNames = team.team
        ?.map(
          (player) =>
            `${player.name}${
              player?.rounds ? ' (' + formatHandicap(String(player.rounds[roundIndex]?.playingHcp)) + ')' : ''
            }`,
        )
        .join(', ')

      const teamHcp = team.rounds[roundIndex].playingHcp

      const fomattedHcp = isSpecialTeamFormatId(gameId) ? `(${formatHandicap(String(teamHcp))})` : ''

      const getMultiroundFields = () => {
        if (rounds.length > 1) {
          const fieldWidth = 0.2 / rounds.length
          return team.rounds.map((playerRound) => ({
            value: playerRound.scoreTotal,
            width: fieldWidth,
            style: { textAlign: 'center' } as Style,
          }))
        }
        return []
      }

      const fields: TableRowProps['fields'] = [
        { value: position, width: 0.1, style: { textAlign: 'center' } },
        {
          title: `${team.name} ${fomattedHcp}`,
          value: teamPlayerNames,
          width: rounds.length === 1 ? 0.7 : 0.5,
          style: { textAlign: 'left' },
        },
        { value: toPar, width: 0.1, style: { textAlign: 'center' } },
        ...getMultiroundFields(),
        { value: score, width: 0.1, style: { textAlign: 'center' } },
      ]

      const rowBaseStyle: Style = { alignItems: 'flex-start', height: 90, paddingTop: 15 }
      const style: Style = idx % 2 === 0 ? rowBaseStyle : { ...rowBaseStyle, backgroundColor: '#fafafa' }
      return <TableRow style={style} key={idx + teamPlayerNames} fields={fields} />
    })
  }

  const renderPlayers = () => {
    return leaderboardRows.map((player, idx) => {
      const { position, toPar, score, userId, rounds } = player

      const formattedHcp = `${formatHandicap(String(rounds[roundIndex]?.hcp))}/${formatHandicap(
        String(rounds[roundIndex]?.playingHcp),
      )}`

      const getMultiroundFields = () => {
        if (rounds.length > 1) {
          const fieldWidth = 0.2 / rounds.length
          return player.rounds.map((playerRound) => ({
            value: playerRound.scoreTotal,
            width: fieldWidth,
            style: { textAlign: 'center' } as Style,
          }))
        }
        return []
      }

      const fields: TableRowProps['fields'] = [
        { value: position, width: 0.1, style: { textAlign: 'center' } },
        {
          value: `${player.name} (${formattedHcp})`,
          width: rounds.length === 1 ? 0.7 : 0.5,
          style: { textAlign: 'left' },
        },
        { value: toPar, width: 0.1, style: { textAlign: 'center' } },
        ...getMultiroundFields(),
        { value: score, width: 0.1, style: { textAlign: 'center' } },
      ]

      const style: Style = idx % 2 === 0 ? {} : { backgroundColor: '#fafafa' }

      return <TableRow style={style} key={`${position}-${toPar}-${userId}`} fields={fields} />
    })
  }

  const gameId: number = round
    ? round?.sideGameEnabled && gameType === GameFormatTypes.SIDE
      ? round?.sideGameId
      : round?.primaryGameId
    : 0

  return (
    <Document title={tournamentName}>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View style={styles.headerLeft}>
            <Text style={styles.h1}>{tournamentName}</Text>
            <Text style={styles.p}>{renderClubAndCourse()}</Text>
            {divisions.selectedDivisionId ? (
              <Text style={styles.p}>
                Division: <span style={styles.bold}>{renderDivisionName()}</span>
              </Text>
            ) : null}
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.h2} render={({ pageNumber, totalPages }) => `Results (${pageNumber}/${totalPages})`} />
            <Text style={styles.p}>{gameFormatIdToStringLookup(gameId)}</Text>
          </View>
        </View>
        <View style={styles.content}>
          <TableHeader fixed={true} columns={headerItems} style={{ marginTop: '15pt', marginBottom: '2pt' }} />
          {isTeamFormatId(gameId) ? renderTeams() : renderPlayers()}
        </View>
        <Footer />
      </Page>
    </Document>
  )
}
