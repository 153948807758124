import {
  FETCH_TOURNAMENT_DIVISION_LEADERBOARD,
  FETCH_TOURNAMENT_DIVISION_LEADERBOARD_SUCCESS,
  FETCH_TOURNAMENT_DIVISION_LEADERBOARD_FAILURE,
} from './constants'

import {
  FetchTournamentDivisionLeaderboardAction,
  FetchTournamentDivisionLeaderboardSuccessAction,
  FetchTournamentDivisionLeaderboardFailureAction,
} from './actions'

const initialState: TournamentDivisionLeaderboardState = {
  data: undefined,
  error: undefined,
  loading: false,
}

type TournamentLeaderboardActions =
  | FetchTournamentDivisionLeaderboardAction
  | FetchTournamentDivisionLeaderboardSuccessAction
  | FetchTournamentDivisionLeaderboardFailureAction

const tournamentDivisionLeaderboardReducer = (
  state: TournamentDivisionLeaderboardState = initialState,
  action: TournamentLeaderboardActions,
) => {
  switch (action.type) {
    case FETCH_TOURNAMENT_DIVISION_LEADERBOARD:
      return {
        ...state,
        loading: true,
      }
    case FETCH_TOURNAMENT_DIVISION_LEADERBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case FETCH_TOURNAMENT_DIVISION_LEADERBOARD_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.error.error,
      }
    default:
      return state
  }
}

export { tournamentDivisionLeaderboardReducer }
