export const convertHcpToString = (hcp: number | undefined): string => {
  let hcpString = hcp?.toFixed(1) || ''
  if (hcp) {
    hcpString = hcp > 0 ? `+${hcpString}` : hcpString.replace('-', '')
  }
  return hcpString
}

export const convertHcpToNumber = (hcp: string | undefined): number | undefined => {
  if (!hcp) {
    return
  }
  const value = Number(hcp)
  if (hcp.includes('+')) {
    return value
  }
  return value - value * 2
}
