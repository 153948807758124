import {
  TOURNAMENT_LOAD_LEADERBOARD,
  TOURNAMENT_LOAD_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_LEADERBOARD_FAILURE,
  TOURNAMENT_RESET_LEADERBOARD,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD,
  TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE,
  TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING,
  TOURNAMENT_FETCH_SCORE_SENDING_STATUS_FAILURE,
  TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_FAILURE,
  TOURNAMENT_FETCH_SCORE_SENDING_STATUS,
  TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_SUCCESS,
  TOURNAMENT_FETCH_SCORE_SENDING_STATUS_SUCCESS,
} from './constants'
import {
  LoadTournamentLeaderboardAction,
  LoadTournamentLeaderboardFailureAction,
  LoadTournamentLeaderboardSuccessAction,
  ResetTournamentLeaderboardAction,
  LoadTournamentContestLeaderboardAction,
  LoadTournamentContestLeaderboardSuccessAction,
  LoadTournamentContestLeaderboardFailureAction,
  SendScoresForHandicappingAction,
  SendScoresForHandicappingSuccessAction,
  SendScoresForHandicappingFailureAction,
  FetchScoreSendingStatusAction,
  FetchScoreSendingStatusSuccessAction,
  FetchScoreSendingStatusFailureAction,
} from './actions'

const initialState: TournamentLeaderboardState = {
  data: undefined,
  contestLeaderboard: undefined,
  scoreStatus: undefined,
  error: undefined,
  loading: false,
}

type TournamentLeaderboardActions =
  | LoadTournamentLeaderboardAction
  | LoadTournamentLeaderboardSuccessAction
  | LoadTournamentLeaderboardFailureAction
  | ResetTournamentLeaderboardAction
  | LoadTournamentContestLeaderboardAction
  | LoadTournamentContestLeaderboardSuccessAction
  | LoadTournamentContestLeaderboardFailureAction
  | SendScoresForHandicappingAction
  | SendScoresForHandicappingSuccessAction
  | SendScoresForHandicappingFailureAction
  | FetchScoreSendingStatusAction
  | FetchScoreSendingStatusSuccessAction
  | FetchScoreSendingStatusFailureAction

const tournamentLeaderboardReducer = (
  state: TournamentLeaderboardState = initialState,
  action: TournamentLeaderboardActions,
) => {
  switch (action.type) {
    case TOURNAMENT_LOAD_LEADERBOARD:
    case TOURNAMENT_LOAD_CONTEST_LEADERBOARD:
    case TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING:
    case TOURNAMENT_FETCH_SCORE_SENDING_STATUS:
      return {
        ...state,
        loading: true,
      }
    case TOURNAMENT_LOAD_LEADERBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case TOURNAMENT_LOAD_LEADERBOARD_FAILURE:
    case TOURNAMENT_LOAD_CONTEST_LEADERBOARD_FAILURE:
      return {
        ...initialState,
        loading: false,
        error: action.error.error,
      }
    case TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_FAILURE:
    case TOURNAMENT_FETCH_SCORE_SENDING_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    case TOURNAMENT_RESET_LEADERBOARD:
      return {
        ...initialState,
      }
    case TOURNAMENT_LOAD_CONTEST_LEADERBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        contestLeaderboard: action.contestLeaderboard,
      }
    case TOURNAMENT_SEND_SCORES_FOR_HANDICAPPING_SUCCESS:
    case TOURNAMENT_FETCH_SCORE_SENDING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        scoreStatus: action.scoreStatus,
      }
    default:
      return state
  }
}

export { tournamentLeaderboardReducer }
