import { RootState } from '../..'
import { setTeamsSortParams } from '../slices/configSlice'
import { selectTournamentTeams, tournamentTeamsApi } from '../endpoints/tournamentTeamsApi'
import { getTournamentId } from '../slices/configSlice'
import { selectTournament } from '../endpoints/tournamentApi'
import { selectTournamentSite } from '../endpoints/tournamentSiteApi'
import { isSignUpEnabled } from '../utils/tournamentSiteUtils'
import { api } from '../baseApi'
import { CacheTag } from '../cacheTags'

export const sortTournamentTeams = (sortBy: TeamPoolOrderBy, sortDirection?: SortDirection) => {
  return (dispatch, getState: () => RootState) => {
    const teams: TournamentTeam[] = selectTournamentTeams(getState()).data?.teams || []
    if (teams.length === 0) {
      return
    }

    teams.sort((teamA, teamB) =>
      sortDirection === 'asc' ? teamA[sortBy].localeCompare(teamB[sortBy]) : teamB[sortBy].localeCompare(teamA[sortBy]),
    )

    dispatch(
      tournamentTeamsApi.util.updateQueryData('getTeams', getTournamentId(getState()), () => ({
        teams,
      })),
    )
  }
}

export const tournamentTeamsChangeSort = (teamsSortBy: TeamPoolOrderBy, teamsSortDirection: SortDirection = 'asc') => {
  return (dispatch) => {
    dispatch(setTeamsSortParams({ teamsSortBy, teamsSortDirection }))
    dispatch(sortTournamentTeams(teamsSortBy, teamsSortDirection))
  }
}

export const maybeRefetchTournamentTeams = (tournamentId: number) => {
  return (dispatch, getState: () => RootState) => {
    const tournament = selectTournament(getState())
    const tournamentSite = selectTournamentSite(getState())

    const teams: TournamentTeam[] | undefined = selectTournamentTeams(getState()).data?.teams
    if (teams === undefined) {
      dispatch(tournamentTeamsApi.endpoints.getTeams.initiate(tournamentId))
      return
    }

    const signUpDisabled = isSignUpEnabled(tournament, tournamentSite) === false
    if (signUpDisabled) {
      return
    }

    dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_TEAMS]))
  }
}
