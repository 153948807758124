import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import TournamentsTable from '../../components/dashboard/TournamentsTable'
import { fetchTournaments, FetchTournamentsPayload } from '../../store/tournaments/actions'
import { fetchOrganization } from '../../store/organization/actions'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import ContentWrap from '../../components/layout/ContentWrap'
import queryString from 'query-string'
import Grid from '@mui/material/Grid'
import HeadHelmet from '../../components/layout/HeadHelmet'
import TopBar from '../../components/layout/TopBar'
import { pickBy } from 'lodash'
import get from 'lodash/get'
import { Typography } from '@mui/material'
import { TournamentSearch } from './tournament-search'
import { TournamentFilter } from './tournament-filter'
import { fetchTimeZones } from '@app/store/timeZones/actions'
import { formatDateToIsoWithTimeZone } from '@app/utils/dates'
import { RootState } from '@app/store'

interface StateProps {
  isLoading: boolean
  organization?: OrganizationState
  intl?: any
  timeZones: TimeZone[]
}

interface DispatchProps {
  fetchTournaments(payload: FetchTournamentsPayload): any
  fetchOrganization: (id: number, force?: boolean, onComplete?: () => void) => void
  fetchTimeZones: (onComplete?: () => void) => void
}

type Props = StateProps & DispatchProps & WithRouterProps & WrappedComponentProps

interface State {
  organizationId: number
  page: number
  totalCount: number
  searchTerm: string
  tournaments: Tournament[]
  sortBy: TournamentSortOption
  direction: 'asc' | 'desc'
  startDate?: Date
  endDate?: Date
}

class UnconnectedDashboard extends React.Component<Props, State> {
  readonly state: State = {
    organizationId: 0,
    page: 0,
    totalCount: 0,
    searchTerm: '',
    tournaments: [],
    sortBy: 'date',
    direction: 'desc',
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const id = get(props, 'params.id')
    const organizationId = id ? parseInt(id as string, 10) : 0
    const params = queryString.parse(props.location.search)
    const page = params.page ? parseInt(params.page as string, 10) : 1
    const searchTerm = get(params, 'searchTerm', '')
    const sortBy = get(params, 'sortBy', 'date')
    const direction = get(params, 'direction', 'desc')

    if (page !== state.page) {
      return {
        organizationId,
        page,
        searchTerm,
        sortBy,
        direction,
      }
    }

    return null
  }

  componentDidMount(): void {
    this.props.fetchOrganization(this.state.organizationId)
    this._triggerFetch()
  }

  componentDidUpdate(_, prevState: State): void {
    if (prevState.organizationId !== this.state.organizationId) {
      this.props.fetchOrganization(this.state.organizationId)
    }
    if (prevState.page !== this.state.page || prevState.organizationId !== this.state.organizationId) {
      this._triggerFetch()
    }
  }

  render() {
    const { isLoading } = this.props
    const { tournaments, page, totalCount, sortBy, direction, searchTerm } = this.state

    const organizationName = get(this.props, 'organization.name')

    return (
      <>
        <HeadHelmet titleId={'tournaments.title'} />
        <TopBar title={organizationName} />

        <OverlayLoader visible={isLoading} />

        <ContentWrap>
          <Typography variant={'h2'}>
            <FormattedMessageWrapper id={'tournaments.title'} />
          </Typography>
          <Grid container>
            <Grid item xs={8}>
              <TournamentFilter isLoading={isLoading} onChange={this._onFiltersChange} />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <TournamentSearch onSubmit={this._searchTournaments} isLoading={isLoading} defaultValue={searchTerm} />
            </Grid>
          </Grid>
          <TournamentsTable
            page={page - 1}
            sortBy={sortBy}
            direction={direction}
            totalCount={totalCount}
            tournaments={tournaments}
            triggerFetch={this._triggerFetch}
            onChangePage={this._handlePageChange}
            onRequestSort={this._handleSortChange}
          />
        </ContentWrap>
      </>
    )
  }

  private _searchTournaments = (searchTerm: string) => {
    const params = queryString.parse(this.props.location.search)
    this.props.historyReplace({
      pathname: this.props.location.pathname,
      search: queryString.stringify(
        pickBy({
          ...params,
          searchTerm,
          page: 1,
        }),
      ),
    })
    this.setState({ searchTerm }, this._triggerFetch)
  }

  private _fetchTournaments = () => {
    const { organizationId, page, sortBy, direction, searchTerm, startDate, endDate } = this.state
    const organizationTimeZone = this._getOrganizationTimeZone()

    this.props.fetchTournaments({
      organizationId,
      page,
      searchTerm,
      sortBy,
      direction,
      startDate: startDate && formatDateToIsoWithTimeZone(startDate, organizationTimeZone?.zone),
      endDate: endDate && formatDateToIsoWithTimeZone(endDate, organizationTimeZone?.zone),
      onComplete: this._setFetchPayload,
    })
  }

  public _triggerFetch = () => {
    const { organizationId } = this.state

    this.props.fetchOrganization(organizationId, false, () => {
      if (this.props.timeZones.length === 0) {
        this.props.fetchTimeZones(() => {
          this._fetchTournaments()
        })
      } else {
        this._fetchTournaments()
      }
    })
  }

  public _setFetchPayload = ({ data, error }: APICallResult) => {
    if (!error) {
      this.setState({ ...data })
    }
  }

  private _getOrganizationTimeZone = (): TimeZone | undefined => {
    const { organization, timeZones } = this.props
    const timeZoneId = get(organization, 'addressInfo.timeZoneId')

    if (!timeZoneId || timeZones.length === 0) {
      return
    }

    return timeZones.find((zone) => zone.id === timeZoneId)
  }

  private _onFiltersChange = (startDate: Date | undefined, endDate: Date | undefined) => {
    if (this.state.startDate === startDate && this.state.endDate === endDate) {
      return
    }
    const { searchTerm } = this.state
    const params = queryString.parse(this.props.location.search)
    this.setState({ startDate, endDate }, () => {
      this.setState({ page: 1 })
      this.props.historyReplace({
        pathname: this.props.location.pathname,
        search: queryString.stringify(
          pickBy({
            ...params,
            searchTerm,
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
            page: 1,
          }),
        ),
      })
      this._triggerFetch()
    })
  }

  public _handlePageChange = (page: number) => {
    const { searchTerm } = this.state
    const params = queryString.parse(this.props.location.search)
    this.props.historyReplace({
      pathname: this.props.location.pathname,
      search: queryString.stringify(
        pickBy({
          ...params,
          searchTerm,
          page: `${page + 1}`,
        }),
      ),
    })
  }

  public _handleSortChange = (e, property) => {
    const isDesc = this.state.sortBy === property && this.state.direction === 'desc'
    const direction = isDesc ? 'asc' : 'desc'
    const params = queryString.parse(this.props.location.search)
    this.setState(
      {
        sortBy: property,
        direction,
      },
      () => {
        this.props.historyReplace({
          pathname: this.props.location.pathname,
          search: queryString.stringify(
            pickBy({
              ...params,
              sortBy: property,
              direction,
            }),
          ),
        })

        this._triggerFetch()
      },
    )
  }
}

const RoutedDashboard = withRouter(UnconnectedDashboard)
export default connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => ({
    isLoading: state.tournamentsReducer.loading,
    organization: state.organizationReducer,
    timeZones: state.timeZonesReducer.timeZones,
  }),
  { fetchTournaments, fetchOrganization, fetchTimeZones },
)(injectIntl(RoutedDashboard))
