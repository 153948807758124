import * as dateFns from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { Units, USTimeAbbreviations } from '@app/store/api/enums/tournamentEnums'

export type DatePart = 'date' | 'time' | 'datetime' | 'year' | 'hours' | 'minutes'
export type DateUnit = OrganizationUnits

type CreateDateOptions = {
  year?: number
  month?: number
  day?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
  originalDate?: string | Date | number | null | undefined
  addDays?: number
  addHours?: number
  addMinutes?: number
}

const defaultDateFormats: Record<DateUnit, Record<DatePart, string>> = {
  metric: {
    date: 'd.M.yyyy',
    time: 'HH:mm',
    datetime: 'd.M.yyyy HH:mm',
    year: 'yyyy',
    hours: 'HH',
    minutes: 'mm',
  },
  us: {
    date: 'MM/dd/yyyy',
    time: 'hh:mm a',
    datetime: 'MM/dd/yyyy hh:mm a',
    year: 'yyyy',
    hours: 'hh a',
    minutes: 'mm',
  },
}

export const pickerDateFormats: Record<OrganizationUnits, Record<'date' | 'time', string>> = {
  metric: {
    date: 'dd.MM.yyyy',
    time: 'HH:mm',
  },
  us: {
    date: 'MM/dd/yyyy',
    time: 'hh:mm a',
  },
}

export const defaultFormat = (part: DatePart, unit: DateUnit) => {
  return defaultDateFormats[unit][part]
}

export const formatDate = (date: Date | number | string, part: DatePart, unit: DateUnit) => {
  const minDate = dateFns.parseISO('1800-01-01')
  const givenDate = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date

  if (dateIsInvalid(givenDate) || dateFns.isBefore(givenDate, minDate)) {
    return ''
  }
  const value = convertToDate(date)
  return dateFns.format(value, defaultFormat(part, unit))
}

export const getHourList = (unit: DateUnit): Array<string> => {
  if (unit === Units.US) {
    const am = Array.from({ length: 11 }).map((_: any, idx: number) => {
      return (idx + 1).toString().padStart(2, '0') + USTimeAbbreviations.AM.padStart(3, ' ')
    })

    const pm = Array.from({ length: 11 }).map((_: any, idx: number) => {
      return (idx + 1).toString().padStart(2, '0') + USTimeAbbreviations.PM.padStart(3, ' ')
    })
    return ['12 ' + USTimeAbbreviations.AM, ...am, '12 ' + USTimeAbbreviations.PM, ...pm]
  }

  return Array.from({ length: 24 }).map((_: any, idx: number) => {
    return idx.toString().padStart(2, '0')
  })
}

export const getMinuteList = (): Array<string> => {
  return Array.from({ length: 60 }).map((_: any, idx: number) => {
    return idx > 9 ? `${idx}` : `0${idx}`
  })
}

export const formatDayUTC = (date: Date) => {
  return dateFns.format(utcToZonedTime(date, 'UTC'), 'd.M.y')
}

export const convertToDate = (value: string | Date | number) => {
  // ISO 8601 formatted string or epoch timestamp
  if (typeof value === 'string' || typeof value === 'number') {
    return new Date(value)
  }
  return value
}

export const convertTo24hFormat = (hours: number, abbreviation: string): number => {
  if (abbreviation === USTimeAbbreviations.PM) {
    return hours < 12 ? hours + 12 : 12
  } else if (abbreviation === USTimeAbbreviations.AM && hours === 12) {
    return 0
  }
  return hours
}

export const convertTo12hFormat = (hours: number): string => {
  let abbreviation = USTimeAbbreviations.AM

  if (hours === 0) {
    hours = 12
  } else if (hours > 12) {
    hours = hours - 12
    abbreviation = USTimeAbbreviations.PM
  }

  return maybeAddLeadingZero(hours) + abbreviation.padStart(3, ' ')
}

export const maybeAddLeadingZero = (value: number): string => {
  return value.toString().padStart(2, '0')
}

export const createDate = (options: CreateDateOptions): Date => {
  let value = dateFns.set(options.originalDate ? convertToDate(options.originalDate) : new Date(), {
    year: options.year,
    month: options.month,
    date: options.day,
    hours: options.hours,
    minutes: options.minutes,
    seconds: options.seconds,
    milliseconds: options.milliseconds,
  })

  if (options.addDays || options.addHours || options.addMinutes) {
    value = dateFns.add(value, {
      days: options.addDays,
      hours: options.addHours,
      minutes: options.addMinutes,
    })
  }

  return value
}

export const parseDate = (value: string, format: string, referenceDate: Date | null = null) => {
  return dateFns.parse(value, format, referenceDate || new Date())
}

export const isValidDate = (date: any): boolean => {
  if (!date) {
    return false
  }
  try {
    return dateFns.isValid(date)
  } catch {
    return false
  }
}

/**
 * Remove time zone from string
 *
 * @param date date as string
 * @returns date string without time zone
 */
export const removeTimeZoneFromDate = (date: any): string => {
  return String(date).substr(0, 19)
}

/**
 * Add club time zone to date
 * @param date
 * @param timeZone Europe/Helsinki
 * @returns 2021-03-22T20:00:00+03:00
 */
export const formatDateToIsoWithTimeZone = (date: Date | string, timeZone?: string): string => {
  const value = createDate({
    originalDate: convertToDate(date),
    seconds: 0,
    milliseconds: 0,
  })

  if (!timeZone) {
    const utcDate = dateFns.formatISO(value)
    return utcDate.includes('+') ? utcDate.split('+')[0] + 'Z' : utcDate
  }

  /**
   * This produces value like: 2021-03-22T20:00:00+03:00
   */
  const timeWithUserTimeZone: string = dateFns.formatISO(value)

  /**
   * This produces value like: 2021-03-22T20:00:00
   */
  const timeWithoutTimeZone: string = removeTimeZoneFromDate(timeWithUserTimeZone)

  /**
   * Timezone offset string like: +03:00
   */
  const timeZoneOffsetString = formatInTimeZone(date, timeZone, 'XXX')

  /**
   * Full time string with offset
   */
  return `${timeWithoutTimeZone}${timeZoneOffsetString}`
}

const dateIsInvalid = (date: Date): boolean => {
  const timestamp = date.getTime()
  return isNaN(timestamp)
}
