import React from 'react'
import { connect } from 'react-redux'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  CircularProgress,
  TableSortLabel,
  Typography,
  Checkbox,
  Tooltip,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { fetchDivisions, deleteDivision, sortDivisions } from '@store/divisions/actions'
import { Edit, Delete } from '@mui/icons-material'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import get from 'lodash/get'
import BorderButton from '@components/ui/BorderButton'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import DeleteConfirm, { DeleteConfirmChildren } from '@components/dialogs/deleteConfirm/DeleteConfirm'
import { formatHandicap } from '@app/utils/playerUtils'
import { CreateNewDivisionDialog } from '@components/dialogs/createNewDivisionDialog/CreateNewDivision'
import { DivisionType } from '@app/store/api/enums/tournamentEnums'
import { RootState } from '@app/store'

interface OwnProps {
  isEditable?: boolean
  isSelecteable?: boolean
  onDivisionSelect?: (divisionId: number, selected: boolean) => void
  selectedDivisions?: number[]
}

interface StateProps {
  auth: AuthenticationState
  divisions: DivisionState[]
  isLoading: boolean
  sortBy: string
  sortDirection: 'asc' | 'desc'
}

interface DispatchProps {
  fetchDivisions: (organizationId: number) => void
  deleteDivision: (organizationId: number, divisionId: number) => void
  sortDivisions: (sortBy: string, sortDirection: 'desc' | 'asc') => void
}

interface State {
  divisionDialogOpen: boolean
}

const styles = () =>
  createStyles({
    iconButton: {
      '&.Mui-disabled': {
        opacity: 0.5,
      },
    },
  })

type Props = WithStyles<typeof styles> & OwnProps & StateProps & DispatchProps & WithRouterProps
class DivisionsListComponent extends React.Component<Props, State> {
  readonly state: State = {
    divisionDialogOpen: false,
  }

  componentDidMount(): void {
    const organizationId = get(this.props.auth, 'customerInfo.idCustomer')
    if (organizationId) {
      this.props.fetchDivisions(organizationId)
    }
  }

  handleDialogToggle = () => {
    const organizationId = this.props.auth.customerInfo?.idCustomer
    this.setState({
      divisionDialogOpen: !this.state.divisionDialogOpen,
    })
    this.props.fetchDivisions(organizationId || 0)
  }

  render() {
    const organizationId = this.props.auth.customerInfo?.idCustomer
    const { sortBy, sortDirection, classes } = this.props
    const { isEditable, isSelecteable, onDivisionSelect } = this.props
    const { divisionDialogOpen } = this.state

    return (
      <>
        <CreateNewDivisionDialog open={divisionDialogOpen} onClose={this.handleDialogToggle} />
        {isEditable && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BorderButton
                filled
                buttonProps={{
                  onClick: this.handleDialogToggle,
                }}
              >
                <FormattedMessageWrapper id="divisions.createNewDivision" />
              </BorderButton>
            </div>

            <Typography variant="h2" style={{ margin: '2rem 0' }}>
              <FormattedMessageWrapper id="divisions.title" />
            </Typography>
          </>
        )}
        {this.props.isLoading ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
            <CircularProgress size={50} thickness={2} />
          </div>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {isSelecteable && (
                  <VeryDenseTableCell>
                    <FormattedMessageWrapper id="divisions.active" />
                  </VeryDenseTableCell>
                )}
                <VeryDenseTableCell sortDirection={sortBy === 'name' ? sortDirection : false}>
                  {this._wrapWithSorting('name', <FormattedMessageWrapper id="divisions.name" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell sortDirection={sortBy === 'gender' ? sortDirection : false}>
                  {this._wrapWithSorting('gender', <FormattedMessageWrapper id="divisions.gender" />)}
                </VeryDenseTableCell>
                <VeryDenseTableCell>
                  <FormattedMessageWrapper id="divisions.ageRange" />
                </VeryDenseTableCell>
                <VeryDenseTableCell>
                  <FormattedMessageWrapper id="divisions.hcpRange" />
                </VeryDenseTableCell>
                {isEditable && (
                  <>
                    <VeryDenseTableCell></VeryDenseTableCell>
                    <VeryDenseTableCell></VeryDenseTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.divisions.map((division) => (
                <TableRow key={division.id}>
                  {isSelecteable && (
                    <VeryDenseTableCell>
                      <Checkbox
                        color={'primary'}
                        checked={division.id && this._isDivisionSelected(division.id) ? true : false}
                        onChange={(_, checked: boolean) =>
                          division.id && onDivisionSelect && onDivisionSelect(division.id, checked)
                        }
                      />
                    </VeryDenseTableCell>
                  )}
                  <VeryDenseTableCell>{division.name}</VeryDenseTableCell>
                  <VeryDenseTableCell>{division.gender}</VeryDenseTableCell>
                  <VeryDenseTableCell>{this._formatRange(division.minAge, division.maxAge)}</VeryDenseTableCell>
                  <VeryDenseTableCell>
                    {this._formatRange(
                      typeof division.minHcp == 'number' ? formatHandicap(division.minHcp.toString()) : '',
                      typeof division.maxHcp == 'number' ? formatHandicap(division.maxHcp.toString()) : '',
                    )}
                  </VeryDenseTableCell>
                  {isEditable && (
                    <>
                      <VeryDenseTableCell style={{ width: 50 }}>
                        <Tooltip
                          title={
                            this.checkManualDivisions(division) ? (
                              <FormattedMessageWrapper id="divisions.tooltipErrorMsg" />
                            ) : (
                              ''
                            )
                          }
                        >
                          <span>
                            <IconButton
                              className={classes.iconButton}
                              disabled={this.checkManualDivisions(division)}
                              onClick={() => this.props.navigate(`/divisions/${division.id}`)}
                              size="large"
                            >
                              <Edit color="primary" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </VeryDenseTableCell>
                      <VeryDenseTableCell style={{ width: 50 }}>
                        <DeleteConfirm>
                          {({ showConfirm }: DeleteConfirmChildren) => (
                            <Tooltip
                              title={
                                this.checkManualDivisions(division) ? (
                                  <FormattedMessageWrapper id="divisions.tooltipErrorMsg" />
                                ) : (
                                  ''
                                )
                              }
                            >
                              <span>
                                <IconButton
                                  className={classes.iconButton}
                                  disabled={this.checkManualDivisions(division)}
                                  onClick={() => {
                                    showConfirm({
                                      messageId: 'divisions.removeDivisionConfirm',
                                      values: { divisionName: division.name },
                                      callback: () => {
                                        if (division.id) {
                                          this.props.deleteDivision(organizationId || 0, division.id)
                                        }
                                      },
                                    })
                                  }}
                                  size="large"
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </DeleteConfirm>
                      </VeryDenseTableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </>
    )
  }

  checkManualDivisions = (division: DivisionState) => {
    if (division.tournamentIds && division.type === DivisionType.MANUAL) {
      return division.tournamentIds.length > 0
    }
    return false
  }

  private _isDivisionSelected = (divisionId: number): boolean => {
    return Array.isArray(this.props.selectedDivisions) ? this.props.selectedDivisions.includes(divisionId) : false
  }

  private _formatRange = (minValue?: null | number | string, maxValue?: null | number | string): string => {
    if (minValue && maxValue) {
      return `${minValue} => ${maxValue}`
    } else if (minValue) {
      return `${minValue} =>`
    } else if (maxValue) {
      return `<= ${maxValue}`
    } else {
      return '-'
    }
  }

  private _wrapWithSorting = (sortByKey: string, content: any) => {
    const { sortBy, sortDirection } = this.props

    return (
      <TableSortLabel
        active={sortBy === sortByKey}
        direction={sortBy === sortByKey ? sortDirection : 'desc'}
        onClick={() => this._createSortHandler(sortByKey, sortDirection === 'asc' ? 'desc' : 'asc')}
      >
        {content}
      </TableSortLabel>
    )
  }

  public _createSortHandler = (sortBy: string, direction: 'asc' | 'desc') => {
    this.props.sortDivisions(sortBy, direction)
  }
}

export const DivisionsList = connect<StateProps, DispatchProps, {}, RootState>(
  (store): StateProps => ({
    auth: store.authenticationReducer,
    divisions: store.divisionsReducer.divisions,
    sortBy: store.divisionsReducer.sortBy,
    sortDirection: store.divisionsReducer.sortDirection,
    isLoading: store.divisionsReducer.loading,
  }),
  {
    fetchDivisions,
    deleteDivision,
    sortDivisions,
  },
)(withStyles(styles)(withRouter(DivisionsListComponent as any)))
