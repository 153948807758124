export const HomeIcon = ({
  style = {},
  fill = '#1AA85D',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 55 55',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
  >
    <path
      d="M52.24,30l-8.69-8.7V9.72a1.17,1.17,0,0,0-1.16-1.16H37.75a1.17,1.17,0,0,
    0-1.16,1.16v4.59L27.5,5.22,2.76,30a.85.85,0,0,0,0,1.23A.87.87,0,0,0,4,31.2l3.9-3.91V48.62a1.16,
    1.16,0,0,0,1.16,1.16H22.57V34.68a1.17,1.17,0,0,1,1.16-1.16h7.54a1.17,1.17,0,0,1,1.16,1.16v15.1H46a1.16,
    1.16,0,0,0,1.16-1.16V27.29L51,31.2a.88.88,0,0,0,.62.25A.87.87,0,0,0,52.24,30Z"
    />
  </svg>
)
