import { Page, Text, View, Document, StyleSheet, Image as PDFImage } from '@react-pdf/renderer'
import ggbLogo from '@assets/images/GGB-logo-bw.png'
import { formatDate } from '@app/utils/dates'
import { useEffect, useMemo, useState } from 'react'
import { chunk, uniqueId } from 'lodash'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '0pt',
  },
  header: {
    flexDirection: 'row',
    marginTop: '20pt',
  },
  headerSide: {
    flex: 0.2,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerCenter: {
    flex: 0.6,
  },
  sign: {
    height: '148.5mm', // A4 height 297mm / 2
    padding: '16pt',
    alignContent: 'space-between',
  },
  signFlipped: {
    height: '148.5mm', // A4 height 297mm / 2
    padding: '16pt',
    alignContent: 'space-between',
    transform: 'rotate(180deg)',
  },
  main: {
    flex: 1,
    paddingTop: '20pt',
    flexDirection: 'column',
    height: '100%',
  },
  teamName: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '24pt',
    textAlign: 'center',
  },
  playerName: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '42pt',
    textAlign: 'center',
    paddingTop: '5pt',
  },
  h1: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16pt',
    textAlign: 'center',
  },
  h2: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '14pt',
  },
  p: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12pt',
    marginTop: '4pt',
    textAlign: 'center',
  },
  content: {
    flex: 1,
    flexDirection: 'column',
  },
  footerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '40pt',
    marginBottom: '10pt',
  },
  footerLeft: {
    flex: 0.5,
    flexDirection: 'column',
  },
  footerRight: {
    flex: 0.5,
    flexDirection: 'column',
  },
  startingHoleTitle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20pt',
    marginTop: '4pt',
    textAlign: 'center',
  },
  startingHole: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '44pt',
    marginTop: '4pt',
    textAlign: 'center',
  },
  teetimeTitle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '20pt',
    marginTop: '4pt',
    textAlign: 'center',
  },
  teetime: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '44pt',
    marginTop: '4pt',
    textAlign: 'center',
  },
})

export interface CartSignsPrintoutProps {
  tournamentName: string
  clubName: string
  courseName: string
  startListGroups: StartListGroup[]
  logoUrl?: string
}

type SignItem = {
  id: string
  players: string[]
  team?: string
  startingHole: number
  teetime: string
}

// Create Document Component
export const CartSignsPrintout = ({
  startListGroups,
  clubName,
  courseName,
  tournamentName,
  logoUrl,
}: CartSignsPrintoutProps) => {
  const clubAndCourse = `${clubName} - ${courseName}`
  const [resizedLogo, setResizedLogo] = useState<string | null>(null)

  useEffect(() => {
    resizeLogo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLogo = () => {
    if (resizedLogo) {
      return <PDFImage style={{ width: '80%' }} src={resizedLogo} />
    }
    return null
  }

  const resizeLogo = () => {
    if (!logoUrl) {
      return
    }
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.src = logoUrl
    image.crossOrigin = 'anonymous'

    const newWidth = 800

    if (ctx) {
      image.onload = () => {
        if (image.width < newWidth) {
          setResizedLogo(logoUrl)
          return
        }
        const aspectRatio = image.width / image.height
        const newHeight = newWidth / aspectRatio
        canvas.width = newWidth
        canvas.height = newHeight
        ctx.drawImage(image, 0, 0, newWidth, newHeight)
        setResizedLogo(canvas.toDataURL('image/png'))
      }
    }
  }

  const renderSign = (item: SignItem, i: number) => {
    const signStyles = i === 0 ? styles.sign : styles.signFlipped
    return (
      <View style={signStyles} key={item.id}>
        <View style={styles.header}>
          <View style={styles.headerSide}>{renderLogo()}</View>
          <View style={styles.headerCenter}>
            <Text style={styles.h1}>{tournamentName}</Text>
            <Text style={styles.p}>{clubAndCourse}</Text>
          </View>
          <View style={styles.headerSide}></View>
        </View>
        <View style={styles.main}>
          {item.team && <Text style={styles.teamName}>{item.team}</Text>}
          {item.players.map((player, idx) => (
            <Text style={styles.playerName} key={idx}>
              {player}
            </Text>
          ))}
        </View>
        <View style={styles.footerWrapper}>
          <View style={styles.footerLeft}>
            <Text style={styles.startingHoleTitle}>Starting hole</Text>
            <Text style={styles.startingHole}>{item.startingHole}</Text>
          </View>
          <View style={styles.footerRight}>
            <Text style={styles.teetimeTitle}>Teetime</Text>
            <Text style={styles.teetime}>{item.teetime}</Text>
          </View>
        </View>
        <View style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <PDFImage src={ggbLogo} style={{ width: '100pt' }} />
        </View>
      </View>
    )
  }

  const renderPage = (items: SignItem[], idx: number) => {
    return (
      <Page size="A4" style={styles.page} key={idx}>
        {items.map((sign, i) => renderSign(sign, i))}
      </Page>
    )
  }

  const pageData = useMemo(() => {
    const items: SignItem[] = []

    startListGroups.forEach((group) => {
      // Team games
      if (group.teams.length > 0) {
        group.teams.forEach((team) => {
          const item: SignItem = {
            id: '',
            team: team.name,
            startingHole: group.teeNumber,
            teetime: formatDate(group.startTime, 'time', 'metric'),
            players: [],
          }
          team.players.forEach((player) => {
            item.players.push(`${player.firstName} ${player.lastName}`)
            if (item.players.length === 2) {
              items.push({ ...item, id: uniqueId() })
              item.players = []
            }
            if (item.players.length > 0) {
              items.push({ ...item, id: uniqueId() })
            }
          })
          if (items.length % 2 !== 0) {
            items.push({ ...item, id: uniqueId() })
          }
        })
      } else {
        // Individual games
        const item: SignItem = {
          id: '',
          startingHole: group.teeNumber,
          teetime: formatDate(group.startTime, 'time', 'metric'),
          players: [],
        }
        const sortedPlayers = group.startListPlayers.sort((a, b) => a.groupOrder - b.groupOrder)
        sortedPlayers.forEach((player) => {
          item.players.push(`${player.firstName} ${player.lastName}`)
          if (item.players.length === 2) {
            items.push({ ...item, id: uniqueId() })
            item.players = []
          }
          if (item.players.length > 0) {
            items.push({ ...item, id: uniqueId() })
          }
        })
        if (items.length % 2 !== 0) {
          items.push({ ...item, id: uniqueId() })
        }
      }
    })

    // Split into two signs / page
    return chunk(items, 2)
  }, [startListGroups])

  return <Document title={tournamentName}>{pageData.map((item, idx) => renderPage(item, idx))}</Document>
}
