import React from 'react'
import { NavLink } from 'react-router-dom'
import { List, Theme, Typography, Button } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import withTheme from '@mui/styles/withTheme'
import createStyles from '@mui/styles/createStyles'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { connect } from 'react-redux'
import { TMWideNav, TMWideNav2x, TMWideNavDark, TMWideNavDark2x } from '../../assets/images'
import AppDrawerItems from './AppDrawerItems'
import classNames from 'classnames'
import { WithRouterProps, withRouter } from '@app/hoc/withRouter'
import { RootState } from '@app/store'

const DRAWER_WIDTH = 256

const styles = (theme: Theme) =>
  createStyles({
    drawerWrapper: {
      display: 'flex',
      width: DRAWER_WIDTH,
      marginLeft: 0,
      transition: 'margin-left 500ms ease-in-out',
      transform: 'translateZ(0)',
      '&.closed': {
        marginLeft: -(DRAWER_WIDTH - 10),
      },
      zIndex: 1200,
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      backgroundColor: theme.customPalette.background,
      boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.5)',
      zIndex: theme.zIndex.drawer,
    },
    drawerShutter: {
      width: 23,
      height: 27,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'sticky',
      left: DRAWER_WIDTH,
      zIndex: 1,
      top: 17,
      backgroundColor: theme.customPalette.background,
      borderRadius: '0 2px 2px 0',
      transform: 'translateZ(0)',
      transition: 'left 500ms ease-in-out',
      cursor: 'pointer',
      '&.closed': {
        left: 10,
      },
      '@global': {
        '> svg': {
          transition: 'transform 500ms ease-in-out',
          transform: 'rotate(180deg)',
        },
        '> svg.closed': {
          transform: 'rotate(0)',
        },
      },
    },
    logo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexBasis: DRAWER_WIDTH,
      height: 65,
      borderBottom: `1px solid ${theme.customPalette.border}`,
      background: theme.palette.mode === 'light' ? theme.customPalette.light : theme.customPalette.darkGray2,
    },
    list: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: theme.customPalette.background,
    },
  })

interface StateProps {
  auth: AuthenticationState
}

interface ThemeProps {
  theme: Theme
}

interface State {
  drawerClosed: boolean
}

type Props = WithStyles<typeof styles> & StateProps & WithRouterProps & ThemeProps

class AppDrawer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      drawerClosed: false,
    }
  }

  render() {
    const { classes, theme } = this.props

    return (
      <div className={classNames(classes.drawerWrapper, this.state.drawerClosed ? 'closed' : '')}>
        <div className={classes.drawer}>
          <List style={{ padding: 0 }} className={classes.list}>
            <div className={classes.logo}>
              <Typography variant="h5" style={{ display: 'inline-block' }}>
                <Button component={this._logoLink}>
                  {theme.palette.mode === 'light' ? (
                    <img width={'160px'} alt={'GGB'} src={TMWideNav} srcSet={`${TMWideNav2x} 2x`} />
                  ) : (
                    <img width={'160px'} alt={'GGB'} src={TMWideNavDark} srcSet={`${TMWideNavDark2x} 2x`} />
                  )}
                </Button>
              </Typography>
            </div>
            <AppDrawerItems />
          </List>
        </div>
        <div
          className={classNames(classes.drawerShutter, this.state.drawerClosed ? 'closed' : '')}
          onClick={() => this._toggleDrawer()}
        >
          <ChevronRight color={'primary'} fontSize={'small'} className={this.state.drawerClosed ? 'closed' : ''} />
        </div>
      </div>
    )
  }

  get _logoLink() {
    const { auth } = this.props
    const url = auth.loggedIn ? '/dashboard' : '/'
    return React.forwardRef((props: any, ref: any) => <NavLink to={url} ref={ref} {...props} />)
  }

  private _toggleDrawer = () => {
    this.setState((prevState) => {
      return { drawerClosed: !prevState.drawerClosed }
    })
  }
}

const RoutedAppDrawer = withRouter(AppDrawer)
const StyledAppDrawer = withStyles(styles)(RoutedAppDrawer)
const ThemedAppDrawer = withTheme(StyledAppDrawer as any)
export default withStyles(styles)(
  connect<StateProps, {}, {}, RootState>(
    (store): StateProps => ({
      auth: store.authenticationReducer,
    }),
  )(ThemedAppDrawer as any),
)
