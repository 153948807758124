export const PasswordToggleIcon = ({ className = '', onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    className={className}
    onClick={onClick}
  >
    <path
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.667"
      // eslint-disable-next-line max-len
      d="M1.502 1.5l2.991 2.991M16.503 16.5l-2.991-2.99m-2.947 1.177A8.337 8.337 0 011.05 9c.289-.92.733-1.772 1.302-2.524m4.881.756a2.5 2.5 0 013.536 3.536M7.234 7.232l3.536 3.536M7.234 7.232l-2.74-2.741m6.276 6.277L4.493 4.49m6.277 6.277l2.742 2.741M4.493 4.491a8.337 8.337 0 0112.461 4.51 8.353 8.353 0 01-3.442 4.508"
    ></path>
  </svg>
)
