import React from 'react'
import { AnswerTypes } from '../../store/api/enums/tournamentEnums'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import get from 'lodash/get'
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'

type Props = {
  readOnly?: boolean
  question: Question
  questionAnswer?: QuestionAnswer
  onChange?(answer: QuestionAnswer): void
  index?: number
}

class CustomQuestionAnswer extends React.Component<Props> {
  render() {
    const { readOnly = false, question, index } = this.props
    const qIndex = typeof index === 'number' ? index + 1 : ''
    if (readOnly) {
      return this._renderReadOnlyResponse()
    }

    return (
      <div style={{ marginBottom: 10 }}>
        <Typography variant={'subtitle1'}>
          Q{qIndex}: {question.question}
        </Typography>
        {this._renderQuestionResponse()}
      </div>
    )
  }

  private _renderReadOnlyResponse = () => {
    const { question, questionAnswer } = this.props
    const { answerType } = question
    const answer = get(questionAnswer, 'answer', '-')

    if (answerType === AnswerTypes.FREE_TEXT || answer === '-') {
      return <span>{answer}</span>
    } else if (answerType === AnswerTypes.YES_NO && answer) {
      return <FormattedMessageWrapper id={`options.${answer}`} />
    }

    return null
  }

  private _renderQuestionResponse = () => {
    const { question, questionAnswer } = this.props
    const { answerType } = question
    const value = get(questionAnswer, 'answer', '')

    if (answerType === AnswerTypes.YES_NO) {
      return (
        <FormControl>
          <Select value={value} onChange={this._handleOnChange}>
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
          </Select>
        </FormControl>
      )
    } else if (answerType === AnswerTypes.FREE_TEXT) {
      return <TextField multiline={true} fullWidth={true} value={value} onChange={this._handleOnChange} />
    }

    return null
  }

  _handleOnChange = ({ target: { value } }: React.ChangeEvent<any> | SelectChangeEvent<any>) => {
    const { question, onChange } = this.props
    if (onChange) {
      onChange({
        registrationQuestionId: question.id,
        answer: value,
      })
    }
  }
}

export default CustomQuestionAnswer
