import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'
import { connect } from 'react-redux'

export interface ActiveRoundArgs {
  is18Holes: boolean // is active round 18 holes
  isFrontNine: boolean // is active round f9 holes
  isBackNine: boolean // is active round b9 holes
  rounds: Round[]
  startLists: StartListRound[]
  holeNumberDisabled(holeNumber: number): boolean
}

interface OwnProps {
  roundIndex?: number
  children(args: ActiveRoundArgs)
}

interface StateProps {
  tournamentRounds: Round[]
  startListRounds: StartListRound[]
}

type Props = OwnProps & StateProps

function ActiveRound({ roundIndex, tournamentRounds, startListRounds, children }: Props) {
  const tournamentRound = tournamentRounds[roundIndex || 0]
  const isFrontNine = tournamentRound.holes === 'f9'
  const isBackNine = tournamentRound.holes === 'b9'

  return children({
    is18Holes: tournamentRound.holes === '18',
    isFrontNine,
    isBackNine,
    holeNumberDisabled: holeNumberDisabled(isBackNine, isFrontNine),
    rounds: tournamentRounds,
    startLists: startListRounds,
  })
}

const holeNumberDisabled =
  (isBackNine: boolean, isFrontNine: boolean) =>
  (holeNumber: number): boolean => {
    return (holeNumber < 10 && isBackNine) || (holeNumber > 9 && isFrontNine)
  }

export default connect<StateProps, {}, {}, RootState>((state) => ({
  tournamentRounds: selectTournament(state).rounds,
  startListRounds: selectTournamentStartLists(state).rounds,
}))(ActiveRound)
