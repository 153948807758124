import {
  internalSetSelectedClubId,
  internalSetSelectedCourseId,
  internalSetSelectedCourseVersion,
  selectClubsAndCoursesConfig,
  setSelectedRoundId,
  setSelectedRoundIndex,
} from '../slices/configSlice'
import { clubsAndCoursesApi, selectClub } from '../endpoints/clubsAndCoursesApi'
import { RootState } from '@app/store'
import { selectTournament } from '../endpoints/tournamentApi'

export const setSelectedRound = (roundId: number) => {
  return (dispatch, getState: () => RootState) => {
    const tournament: TournamentState = selectTournament(getState())
    const roundIndex = tournament.rounds.findIndex((r) => r.id === roundId)
    if (roundIndex !== undefined) {
      dispatch(setSelectedRoundIndex(roundIndex))
      dispatch(setSelectedRoundId(roundId))
      dispatch(maybeUpdateSelectedCourseClubData(tournament, roundIndex))
    }
  }
}

export const setSelectedClubId = (clubId: number) => {
  return (dispatch, getState: () => RootState) => {
    dispatch(internalSetSelectedClubId(clubId))
    const club = selectClub(getState(), clubId)
    const noClubOrClubChanged = !club || club.id !== clubId
    if (clubId > 0 && noClubOrClubChanged) {
      dispatch(clubsAndCoursesApi.endpoints.getClub.initiate(clubId))
    }
  }
}

export const maybeUpdateSelectedCourseClubData = (tournament: TournamentState, roundIndex: number) => {
  return (dispatch, getState: () => RootState) => {
    const clubsAndCourseConfig = selectClubsAndCoursesConfig(getState())
    const tournamentRoundCourse = tournament.rounds[roundIndex].course

    const invalidCourse = tournamentRoundCourse?.id !== clubsAndCourseConfig.selectedCourseId
    if (tournamentRoundCourse && invalidCourse) {
      dispatch(internalSetSelectedCourseId(tournamentRoundCourse.id))
      dispatch(internalSetSelectedCourseVersion(tournamentRoundCourse.version))
    }

    const invalidCourseVersion =
      tournamentRoundCourse?.id === clubsAndCourseConfig.selectedCourseId &&
      tournamentRoundCourse?.version !== clubsAndCourseConfig.selectedCourseVersion

    if (tournamentRoundCourse && invalidCourseVersion) {
      dispatch(internalSetSelectedCourseVersion(tournamentRoundCourse.version))
    }

    if (tournamentRoundCourse && tournamentRoundCourse.clubId !== clubsAndCourseConfig.selectedClubId) {
      dispatch(setSelectedClubId(tournamentRoundCourse.clubId))
    }
  }
}
