import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'
import createStyles from '@mui/styles/createStyles'
import { DialogContent, FormControl, Grid, MenuItem, Select, TableRow, TextField } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { AddCircle } from '@mui/icons-material'
import { injectIntl, IntlShape, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import CourseGuideTable, { CourseGuideTableChildren } from '../courseGuideDialog/CourseGuideTable'
import { VeryDenseTableCell } from '@components/tables/tableComponents'
import TableCell from '@mui/material/TableCell'
import ButtonLoaderWrap from '@components/ui/ButtonLoaderWrap'
import classNames from 'classnames'
import { Check } from '@mui/icons-material'
import Divider from '@mui/material/Divider'
import { isGIR } from '@utils/clubAndCourseUtils'
import { rem } from '@theme/materialUITheme'
import InitialStrokePicker from '@components/ui/InitialStrokePicker'
import ActiveRound, { ActiveRoundArgs } from '@components/headless/ActiveRound'
import { ResultStatus, TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { PlayerStatus } from '@app/store/api/enums/tournamentPlayersEnums'
import { convertLBIdStringToNumber } from '@app/utils/tournamentUtils'
import { convertHcpToNumber } from '@app/utils/hcpUtils'
import { selectWeeklyScoreRounds } from '@app/store/api/endpoints/tournamentWeeklyApi'
import { tournamentScoringApi } from '@app/store/api/endpoints/tournamentScoringApi'
import { tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'
import { selectRoundCoursesForAllRounds } from '@app/store/api/slices/clubsAndCoursesSlice'

interface HoleScore {
  roundId: number
  hole: number
  strokes: number | string
  putts: number | string
  initialStroke: string
}

const defaultHoleScore: HoleScore = {
  roundId: 0,
  hole: 0,
  strokes: '',
  putts: '',
  initialStroke: '',
}

const styles = () =>
  createStyles({
    inputCell: {
      borderColor: 'transparent',
    },
    bigText: {
      fontSize: rem(18),
      width: 58,
    },
    actionContainer: {
      justifyContent: 'space-between',
    },
    actionItem: {
      display: 'flex',
      alignItems: 'center',
    },
    actionItemBottom: {
      display: 'flex',
      alignItems: 'end',
    },
    statusDropdown: {
      marginLeft: rem(10),
    },
  })

interface OwnProps {
  onClose?(refetch?: boolean): void
  score?: TournamentLeaderboardPlayer
  isTeam?: boolean
  teamId: string | undefined
  intl?: IntlShape
  units?: OrganizationUnits
  addRound?: boolean
}

interface StateProps {
  saving: boolean
  tournamentType: TournamentType
  weeklyRoundId?: number | null
  playerId?: number | null
  isTeamFormat: boolean
  startlists: TournamentStartListState
  tournament: TournamentState
  weeklyScoreRounds: ScoreRounds[] | undefined
  roundCourses: RoundCourse[]
}

interface DispatchProps {
  updatePlayerTournamentScores(payload: UpdatePlayerTournamentScoresPayload): void
  sendPlayerScoresToHandicapping(payload: SendWeeklyHcpScoresPayload): void
  updatePlayerStatus(payload: UpdatePlayerStatusPayload): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

interface State {
  showStats?: boolean
  score?: TournamentLeaderboardPlayer
  resultStatus?: string
  holeScores: Array<HoleScore>
  isSendScoresConfirmationVisible: boolean
  allowToSendScores: boolean
  allowToSave: boolean
  playerStatus: PlayerStatus
  weeklyGgbRoundIds: string[]
  addRound: boolean
  roundHcps: Map<string, string>
  roundTeeBoxIds: Map<string, number>
}

class PlayerScore extends React.Component<Props, State> {
  readonly state: State = {
    showStats: false,
    score: undefined,
    resultStatus: '',
    holeScores: [],
    isSendScoresConfirmationVisible: false,
    allowToSendScores: false,
    allowToSave: false,
    playerStatus: PlayerStatus.OK,
    weeklyGgbRoundIds: [],
    addRound: false,
    roundHcps: new Map(),
    roundTeeBoxIds: new Map(),
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.score && JSON.stringify(props.score) !== JSON.stringify(state.score)) {
      const holeScores: HoleScore[] = []

      if (!props.score?.rounds[0]) {
        Object.assign(props.score, { rounds: [] })
        props.score.rounds.push({
          id: ':' + props.tournament.rounds[0].id,
          strokes: ['', '', '', '', '', '', '', '', ''],
          strokesIn: '',
          strokesOut: '',
          strokesTotal: '',
          thru: '',
          toParRaw: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          totalToPar: '',
          score: ['', '', '', '', '', '', '', '', ''],
          scoreOut: '',
          scoreIn: '',
          scoreTotal: '',
          playingHcp: '0',
          hidden: [false, false, false, false, false, false, false, false, false],
          played: [false, false, false, false, false, false, false, false, false],
        })
      }

      // Map weekly scores to HoleScore
      if (props.tournament.tournamentType === 'weekly') {
        const { weeklyScoreRounds } = props

        if (weeklyScoreRounds) {
          weeklyScoreRounds.forEach((weeklyRound) => {
            // We need always to have 18 holeScores for round.
            // Weekly scores has only 9 values in case f9/b9 selected for round length.
            // Fill missing values with empty ones.
            Array.from({ length: 18 }).forEach((_, index) => {
              const holeNumber = index + 1
              const holeScore: HoleScore = {
                ...defaultHoleScore,
                roundId: Number(weeklyRound.ggbRoundId),
                hole: holeNumber,
              }
              weeklyRound.holes.forEach((weeklyHoleScore) => {
                if (weeklyHoleScore.hole === holeNumber) {
                  holeScore.putts = weeklyHoleScore.putts?.toString() || ''
                  holeScore.strokes = weeklyHoleScore.strokes.toString()
                }
              })
              holeScores.push(holeScore)
            })
          })
        }
      } else {
        props.score.rounds.forEach((round) => {
          // round.id is forced to number cos' we want to match it to
          // the other structures in frontend. tm:<roundId> -> <roundId>.
          const roundId = convertLBIdStringToNumber(round.id)

          let index = 0
          for (const holeStrokes of round.strokes) {
            index++
            holeScores.push({
              roundId,
              hole: Number(index),
              strokes: holeStrokes,
              putts: '',
              initialStroke: '',
            })
          }

          // Loop through all the holeScores and fill with default state where needed
          Array.from({ length: 18 }).forEach((_, index) => {
            const holeNumber = index + 1
            if (!holeScores.find((hole) => hole.roundId === roundId && hole.hole === holeNumber)) {
              holeScores.push({ ...defaultHoleScore, roundId, hole: holeNumber })
            }
          })
        })
      }

      const playerStatus = props.score?.status

      holeScores.sort((a, b) => (a.hole > b.hole ? 1 : -1))

      return {
        holeScores,
        score: props.score,
        resultStatus: Number.isInteger(Number(props.score.score)) ? ResultStatus.OK : props.score.score,
        playerStatus: playerStatus || PlayerStatus.OK,
      }
    }

    return null
  }

  render() {
    const {
      score,
      roundCourses,
      classes,
      isTeam,
      saving,
      tournamentType,
      weeklyRoundId,
      tournament,
      intl,
      startlists,
      units,
      isTeamFormat,
      weeklyScoreRounds,
    } = this.props

    if (!score || !roundCourses) {
      return null
    }

    const { addRound } = this.state

    let scoreRounds = score.rounds

    if (weeklyScoreRounds && !addRound) {
      scoreRounds = weeklyScoreRounds.map((scoreRound: ScoreRounds): TournamentLeaderboardPlayerRound => {
        return {
          ...score.rounds[0],
          id: scoreRound?.ggbRoundId || '-1',
          strokes: scoreRound.holes.map((hole) => hole.strokes.toString()),
          putts: scoreRound.holes.map((hole) => hole?.putts?.toString() || '-1'),
        }
      })
    }

    return (
      <>
        <DialogContent>
          <form>
            {scoreRounds.map((round: TournamentLeaderboardPlayerRound, i: number) => (
              <CourseGuideTable
                course={this._getCourseGuide(i)}
                showSlopes={false}
                showScoreIndex={false}
                showTees={false}
                key={`score-round-${i}`}
                tournament={tournament}
                roundIndex={i}
                weeklyScore={weeklyScoreRounds && !addRound ? weeklyScoreRounds[i] : undefined}
                intl={intl}
                playerScore={score}
                startlists={startlists}
                units={units}
                syncRoundHcp={this._syncRoundHcp}
                syncRoundTeeBox={this._syncRoundTeeBox}
              >
                {({ titleCellClassName }: CourseGuideTableChildren) => (
                  <>
                    <TableRow>
                      <TableCell
                        align={'right'}
                        className={classNames([titleCellClassName, classes.inputCell, classes.bigText])}
                      >
                        {tournamentType !== TournamentTypes.weekly && (
                          <FormattedMessageWrapper id={'tournament.roundWithNumberShort'} values={{ round: i + 1 }} />
                        )}
                      </TableCell>

                      {this._renderFrontNineInputs(round, this._isInputDisabled(i))}

                      <TableCell
                        align={'center'}
                        className={classNames([titleCellClassName, classes.inputCell, classes.bigText])}
                      >
                        {this._frontNineTotalShots(convertLBIdStringToNumber(round.id))}
                      </TableCell>

                      {this._renderBackNineInputs(round, this._isInputDisabled(i))}

                      <TableCell
                        align={'center'}
                        className={classNames([titleCellClassName, classes.inputCell, classes.bigText])}
                      >
                        {this._backNineTotalShots(convertLBIdStringToNumber(round.id))}
                      </TableCell>

                      <TableCell
                        align={'center'}
                        className={classNames([titleCellClassName, classes.inputCell, classes.bigText])}
                      >
                        {this._totalShots(convertLBIdStringToNumber(round.id))}
                      </TableCell>
                    </TableRow>

                    {/*
                      STATS
                    */}
                    {this.state.showStats && (
                      <>
                        {this._renderStatsRow(
                          titleCellClassName,
                          'tournament.puts',
                          this._renderFrontNinePuttInputs(round, this._isInputDisabled(i)),
                          this._renderBackNinePuttInputs(round, this._isInputDisabled(i)),
                        )}

                        {this._renderStatsRow(
                          titleCellClassName,
                          'tournament.gir',
                          this._renderFrontNineGIRs(round, this._getCourseGuide(i)),
                          this._renderBackNineGIRs(round, this._getCourseGuide(i)),
                        )}

                        {this._renderStatsRow(
                          titleCellClassName,
                          'tournament.fairwayHit',
                          this._renderFrontNineInitialStrokes(round, this._getCourseGuide(i), this._isInputDisabled(i)),
                          this._renderBackNineInitialStrokes(round, this._getCourseGuide(i), this._isInputDisabled(i)),
                        )}

                        <TableRow>
                          <VeryDenseTableCell colSpan={22} style={{ border: 'none' }}>
                            <Divider />
                          </VeryDenseTableCell>
                        </TableRow>
                      </>
                    )}
                  </>
                )}
              </CourseGuideTable>
            ))}
          </form>
        </DialogContent>

        <DialogActions>
          <Grid container className={classes.actionContainer}>
            <Grid item className={classes.actionItem}>
              {tournamentType === TournamentTypes.weekly ? (
                <div style={{ display: 'grid' }}>
                  <Button
                    startIcon={<AddCircle color="primary" />}
                    color="primary"
                    onClick={() => this._handleOnClick()}
                  >
                    <FormattedMessageWrapper id="tournament.addRound" />
                  </Button>
                </div>
              ) : (
                <Button disabled={saving} onClick={this._clearResults}>
                  <FormattedMessageWrapper id={'tournament.clearResults'} />
                </Button>
              )}
              {tournamentType !== TournamentTypes.weekly && !isTeamFormat && (
                <FormControl disabled={false} variant="outlined" className={classes.statusDropdown}>
                  <Select value={this.state.playerStatus} name="playerStatus" onChange={this._setStatus}>
                    <MenuItem value={PlayerStatus.OK}>
                      <FormattedMessageWrapper id={'tournament.playerStatus.active'} />
                    </MenuItem>
                    <MenuItem value={PlayerStatus.CUT}>
                      <FormattedMessageWrapper id={'tournament.playerStatus.cut'} />
                    </MenuItem>
                    <MenuItem value={PlayerStatus.DQ}>
                      <FormattedMessageWrapper id={'tournament.playerStatus.dq'} />
                    </MenuItem>
                    <MenuItem value={PlayerStatus.DNS}>
                      <FormattedMessageWrapper id={'tournament.playerStatus.dns'} />
                    </MenuItem>
                    <MenuItem value={PlayerStatus.RTD}>
                      <FormattedMessageWrapper id={'tournament.playerStatus.rtd'} />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {this.isWeeklyHcpTournament && (
                <ButtonLoaderWrap loading={saving}>
                  <Button
                    disabled={saving || !weeklyRoundId || !this.state.allowToSendScores}
                    variant={'contained'}
                    onClick={this._onSendScoresForHandicappingClick}
                    color="secondary"
                    style={{ marginLeft: 20 }}
                  >
                    <FormattedMessageWrapper id={'buttons.sendScoresForHandicapping'} />
                  </Button>
                </ButtonLoaderWrap>
              )}
            </Grid>
            <Grid
              item
              className={tournamentType === TournamentTypes.weekly ? classes.actionItemBottom : classes.actionItem}
            >
              {!isTeam && (
                <Button disabled={saving} onClick={this._handleClose.bind(this, undefined)} style={{ marginRight: 10 }}>
                  <FormattedMessageWrapper id={'buttons.close'} />
                </Button>
              )}
              <ButtonLoaderWrap loading={saving}>
                <Button
                  disabled={saving || !this.state.allowToSave}
                  color="primary"
                  variant={'contained'}
                  onClick={this._onClickSave}
                >
                  <FormattedMessageWrapper id={'buttons.save'} />
                </Button>
              </ButtonLoaderWrap>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    )
  }

  private _isInputDisabled = (roundIndex: number): boolean => {
    const { tournament } = this.props
    const round = tournament.rounds[roundIndex]
    const isPublic = round && !!round.status?.isConfigured

    return tournament.tournamentType !== TournamentTypes.weekly && !isPublic
  }

  public _renderStatsRow = (titleCellClassName: string, titleId: string, frontFn: any, backFn: any) => {
    const { classes } = this.props

    return (
      <TableRow>
        <TableCell align={'right'} className={classNames([titleCellClassName, classes.inputCell])}>
          <FormattedMessageWrapper id={titleId} />
        </TableCell>

        {frontFn}

        <TableCell className={classNames([titleCellClassName, classes.inputCell])} />

        {backFn}

        <TableCell className={classNames([titleCellClassName, classes.inputCell])} />
        <TableCell className={classNames([titleCellClassName, classes.inputCell])} />
      </TableRow>
    )
  }

  public _onSendScoresForHandicappingClick = (): void => {
    const { sendPlayerScoresToHandicapping, tournament, weeklyRoundId, playerId, intl } = this.props

    const successMessage = intl.formatMessage({ id: 'notifications.hcpSendSuccess' })
    const errorMessage = intl.formatMessage({ id: 'notifications.failure' })

    if (tournament.id && playerId && weeklyRoundId) {
      sendPlayerScoresToHandicapping({
        tournamentId: tournament.id,
        playerId,
        weeklyRoundId,
        successMessage,
        errorMessage,
      })
      this.setState({ allowToSendScores: false })
    }
  }

  public _setStatus = (event): void => {
    const { value } = event.target

    value && this.setState({ playerStatus: value as PlayerStatus, allowToSave: true })
  }

  public _onClickSave = (): void => {
    this.sendScores()
  }

  private sendScores = (): void => {
    const {
      updatePlayerTournamentScores,
      updatePlayerStatus,
      tournament,
      isTeam,
      isTeamFormat,
      tournamentType,
      weeklyScoreRounds,
    } = this.props
    const { score, holeScores, resultStatus, weeklyGgbRoundIds } = this.state

    if (score) {
      const scores = holeScores.map((_, i: number) => {
        const hole: HoleScore = holeScores[i]
        const roundIndex = tournament.rounds.findIndex((round) => round.id === hole.roundId)
        const roundCourse = this._getCourseGuide(roundIndex)
        let strokes = -1

        switch (hole.strokes) {
          case '-': {
            strokes = 0
            break
          }
          case '': {
            strokes = -1
            break
          }
          default: {
            strokes = Number(hole.strokes)
            break
          }
        }

        const course = roundCourse

        return {
          ...hole,
          strokes: strokes,
          putts: -1,
          againstPar:
            hole.strokes === '-'
              ? 0
              : course
              ? parseInt(String(hole.strokes), 10) - course.holes[hole.hole - 1].par
              : null,
        }
      })

      if (tournament.id) {
        const ggbTeamId = convertLBIdStringToNumber(this.props.teamId || undefined)
        const playerId = convertLBIdStringToNumber(
          this.props.score?.userId ? this.props.score.userId : this.props.score?.teamId,
        )

        let value
        if (weeklyScoreRounds && weeklyGgbRoundIds.length) {
          for (let i = 0; i < weeklyGgbRoundIds.length; i++) {
            value = scores.filter((score) => weeklyGgbRoundIds[i].includes(String(score.roundId)))
            updatePlayerTournamentScores({
              tournamentId: tournament.id,
              playerId,
              body: {
                ggbTeamId,
                holes: value,
                resultStatus,
                ggbRoundId:
                  weeklyScoreRounds && !this.state.addRound && weeklyGgbRoundIds[i] ? weeklyGgbRoundIds[i] : undefined,
                hcp: this._getUpdatedRoundHcp(weeklyGgbRoundIds[i]),
                teeBoxId: this._getUpdatedRoundTeeBoxId(weeklyGgbRoundIds[i]),
              },
              onSuccess: () => {
                this.setState({ allowToSendScores: true, allowToSave: tournamentType !== TournamentTypes.weekly })
                if (!isTeam && !this.isWeeklyHcpTournament) {
                  this._handleClose(true)
                }
              },
            })
          }
        } else {
          updatePlayerTournamentScores({
            tournamentId: tournament.id,
            playerId,
            body: {
              ggbTeamId,
              holes: scores,
              resultStatus,
            },
            onSuccess: () => {
              this.setState({ allowToSendScores: true, allowToSave: tournamentType !== TournamentTypes.weekly })
              if (!isTeam && !this.isWeeklyHcpTournament) {
                this._handleClose(true)
              }
            },
          })
        }

        if (tournamentType !== TournamentTypes.weekly && !isTeamFormat) {
          updatePlayerStatus({
            tournamentId: tournament.id,
            playerId: Number(this.props.score?.userId),
            status: this.state.playerStatus,
          })
        }
      }
    }
  }

  private get isWeeklyHcpTournament(): boolean {
    const { tournament } = this.props
    return tournament.tournamentType === TournamentTypes.weekly && tournament.hcpRound ? true : false
  }

  private _syncRoundHcp = (roundIndex: number, roundHcp: string) => {
    const roundId = this._maybeAllowToSaveRound(roundIndex)
    const roundHcps = this.state.roundHcps
    if (roundId) {
      roundHcps.set(roundId, roundHcp)
      this.setState({
        roundHcps,
      })
    }
  }

  private _syncRoundTeeBox = (roundIndex: number, roundTeeBoxId: number) => {
    const roundId = this._maybeAllowToSaveRound(roundIndex)
    const roundTeeBoxIds = this.state.roundTeeBoxIds
    if (roundId) {
      roundTeeBoxIds.set(roundId, roundTeeBoxId)
      this.setState({
        roundTeeBoxIds,
      })
    }
  }

  private _maybeAllowToSaveRound = (roundIndex: number): string | undefined => {
    const { weeklyScoreRounds } = this.props
    const roundId = weeklyScoreRounds?.[roundIndex]?.ggbRoundId
    const weeklyGgbRoundIds = this.state.weeklyGgbRoundIds
    if (roundId && !weeklyGgbRoundIds.includes(String(roundId))) {
      this.setState({ weeklyGgbRoundIds: [...weeklyGgbRoundIds, String(roundId)] })
      this.setState({ allowToSave: true })
    }

    return roundId
  }

  private _getUpdatedRoundHcp = (roundId: string | undefined): number | undefined => {
    return roundId ? convertHcpToNumber(this.state.roundHcps.get(roundId)) : undefined
  }

  private _getUpdatedRoundTeeBoxId = (roundId: string | undefined): number | undefined => {
    return roundId ? this.state.roundTeeBoxIds.get(roundId) : undefined
  }

  public _handleClose = (refetch = false): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose(refetch)
      this.setState({ score: undefined })
    }
  }

  public _numberFormatter = (
    property: string,
    roundId: number,
    holeNumber: number,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): any => {
    if (!event.target.value) {
      return event.target.value
    }

    const intVal = parseInt(event.target.value, 10)

    if (!isNaN(intVal)) {
      return intVal
    } else if (event.target.value === '-') {
      return event.target.value
    }

    return ''
  }

  public _stringFormatter = (
    property: string,
    roundId: number,
    holeNumber: number,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): any => {
    return event.target.value
  }

  public _puttsNumberFormatter = (
    property: string,
    roundId: number,
    holeNumber: number,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): any => {
    if (!event.target.value) {
      return event.target.value
    }

    const value = Math.abs(parseInt(event.target.value, 10))
    const holeScore = this._getHoleScore(roundId, holeNumber)

    if (!holeScore) {
      return null
    }

    const holeStrokes = typeof holeScore.strokes === 'string' ? parseInt(holeScore.strokes, 10) : holeScore.strokes
    if (value < holeStrokes) {
      return value
    }

    return holeStrokes - 1
  }

  public _onValueChange =
    (property: string, formatter = this._stringFormatter) =>
    (roundId: number, holeNumber: number) =>
    (event) => {
      let value = formatter(property, roundId, holeNumber, event)
      if (typeof value === 'number' && value < 0) {
        value = Math.abs(value)
      }

      const { weeklyGgbRoundIds } = this.state
      if (!weeklyGgbRoundIds?.includes(String(roundId))) {
        this.setState({ weeklyGgbRoundIds: [...weeklyGgbRoundIds, String(roundId)] })
      }

      const holeScore = this._getHoleScore(roundId, holeNumber)
      if (!holeScore) {
        return false
      }

      const putts = holeScore.putts

      const holePayload = {
        ...holeScore,
        [property]: value,
      }

      if (property === 'strokes') {
        if (putts && putts >= value) {
          holePayload.putts = value - 1
        }

        if (value > 29) {
          holePayload.strokes = 29
        }

        if (value === '-' || value === 0) {
          holePayload.putts = ''
        }
      }

      this.setState((prevState) => {
        const holeIndex = this._getHoleIndex(roundId, holeNumber)
        prevState.holeScores[holeIndex] = holePayload

        return {
          holeScores: [...prevState.holeScores],
        }
      })

      if (this.state.allowToSave === false) {
        this.setState({ allowToSave: true })
      }

      return true
    }

  private _getCourseGuide = (roundIndex: number) => {
    if (roundIndex < 0) {
      return undefined
    }

    const { roundCourses } = this.props

    if (this.props.weeklyScoreRounds) {
      roundIndex = 0
    }

    const courseId = this.props.tournament.rounds[roundIndex].courseId
    return roundCourses && roundCourses.find((course) => course.id === courseId)
  }

  private _frontNineTotalShots(roundId: number) {
    const { holeScores } = this.state
    return holeScores
      .filter((hole) => hole.roundId === roundId && hole.hole <= 9)
      .reduce((acc, hole) => {
        const value = parseInt(String(hole.strokes), 10)
        if (!isNaN(value)) {
          return acc + value
        }

        return acc
      }, 0)
  }

  private _backNineTotalShots(roundId: number) {
    const { holeScores } = this.state
    return holeScores
      .filter((hole) => hole.roundId === roundId && hole.hole > 9)
      .reduce((acc, hole) => {
        const value = parseInt(String(hole.strokes), 10)
        if (!isNaN(value)) {
          return acc + value
        }

        return acc
      }, 0)
  }

  private _totalShots(roundId: number) {
    return this._frontNineTotalShots(roundId) + this._backNineTotalShots(roundId)
  }

  /*
    FRONT NINE RENDERS
  */

  private _renderFrontNineInputs = (round: TournamentLeaderboardPlayerRound, disabled?: boolean) => {
    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole <= 9)
      .map((hole, index) => this._renderHoleStrokeInput(hole.roundId, hole.hole, index, disabled))
  }

  private _renderFrontNinePuttInputs = (round: TournamentLeaderboardPlayerRound, disabled?: boolean) => {
    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole <= 9)
      .map((hole, index) => this._renderHolePuttInput(hole.roundId, hole.hole, index, disabled))
  }

  private _renderFrontNineGIRs = (round: TournamentLeaderboardPlayerRound, roundCourse: RoundCourse | undefined) => {
    if (!roundCourse) {
      return null
    }

    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole <= 9)
      .map((hole) => this._renderHoleGIR(hole.roundId, hole.hole, roundCourse))
  }

  private _renderFrontNineInitialStrokes = (
    round: TournamentLeaderboardPlayerRound,
    roundCourse: RoundCourse | undefined,
    disabled?: boolean,
  ) => {
    if (!roundCourse) {
      return null
    }

    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole <= 9)
      .map((hole) => this._renderInitialStroke(hole.roundId, hole.hole, roundCourse, disabled))
  }

  /*
    BACK NINE RENDERS
  */

  private _renderBackNineInputs = (round: TournamentLeaderboardPlayerRound, disabled?: boolean) => {
    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole > 9)
      .map((hole, index) => this._renderHoleStrokeInput(hole.roundId, hole.hole, index, disabled))
  }

  private _renderBackNinePuttInputs = (round: TournamentLeaderboardPlayerRound, disabled?: boolean) => {
    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole > 9)
      .map((hole, index) => this._renderHolePuttInput(hole.roundId, hole.hole, index, disabled))
  }

  private _renderBackNineGIRs = (round: TournamentLeaderboardPlayerRound, roundCourse: RoundCourse | undefined) => {
    if (!roundCourse) {
      return null
    }

    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole > 9)
      .map((hole) => this._renderHoleGIR(hole.roundId, hole.hole, roundCourse))
  }

  private _renderBackNineInitialStrokes = (
    round: TournamentLeaderboardPlayerRound,
    roundCourse: RoundCourse | undefined,
    disabled?: boolean,
  ) => {
    if (!roundCourse) {
      return null
    }

    return this.state.holeScores
      .filter((hole) => hole.roundId === convertLBIdStringToNumber(round.id) && hole.hole > 9)
      .map((hole) => this._renderInitialStroke(hole.roundId, hole.hole, roundCourse, disabled))
  }

  /*
    RENDER UTILS
  */

  private _renderHoleStrokeInput = (roundId: number, holeNumber: number, index: number, disabled?: boolean) => {
    return this._renderHoleInput(roundId, holeNumber, 'strokes', index, disabled)
  }

  private _renderHolePuttInput = (roundId: number, holeNumber: number, index: number, disabled?: boolean) => {
    return this._renderHoleInput(roundId, holeNumber, 'putts', index, disabled)
  }

  private _getHoleIndex = (roundId: number, holeNumber: number) => {
    return this.state.holeScores.findIndex((hole) => roundId === hole.roundId && holeNumber === hole.hole)
  }

  private _getHoleScore = (roundId: number, holeNumber: number) => {
    return this.state.holeScores.find((hole) => roundId === hole.roundId && holeNumber === hole.hole)
  }

  private _getHole = (roundCourse: RoundCourse, holeNumber: number) => {
    return roundCourse.holes[holeNumber - 1]
  }

  private _renderHoleInput = (
    roundId: number,
    holeNumber: number,
    property: string,
    index: number,
    disabled?: boolean,
  ) => {
    const { classes } = this.props
    const { tournament } = this.props
    const holeScore = this._getHoleScore(roundId, holeNumber)

    if (!holeScore) {
      return null
    }

    const puttsDisabled = property === 'putts' && (!holeScore.strokes || holeScore.strokes === '-')
    const formatter = property === 'putts' ? this._puttsNumberFormatter : this._numberFormatter
    const currentRound = tournament.rounds.find((round) => round.id === roundId)
    const roundIndex = currentRound && currentRound.roundNumber - 1

    return (
      <VeryDenseTableCell
        key={`${roundId}-${holeNumber}-${index}`}
        style={{ paddingLeft: 0 }}
        className={classNames(classes.inputCell, classes.bigText)}
      >
        <ActiveRound roundIndex={roundIndex}>
          {({ holeNumberDisabled }: ActiveRoundArgs) => {
            const fieldDisabled = puttsDisabled || holeNumberDisabled(holeNumber) || disabled

            return (
              <TextField
                disabled={fieldDisabled}
                value={holeScore[property]}
                variant={'outlined'}
                style={{
                  margin: '4px 6px 0',
                  opacity: fieldDisabled ? 0.5 : 1,
                }}
                autoComplete={'off'}
                inputProps={{
                  style: {
                    padding: '10px 0',
                    textAlign: 'center',
                  },
                  onFocus: (event) => {
                    const { target } = event
                    target.select()
                  },
                  onKeyUp: (event: any) => {
                    if (property === 'strokes') {
                      const form = event.target.form
                      const index = Array.prototype.indexOf.call(form, event.target)
                      const value = parseInt(event.target.value)
                      if (value > 1 || event.target.value === '-') {
                        form.elements[index + 2] && form.elements[index + 2].focus()
                      }
                    }
                    event.preventDefault()
                  },
                }}
                onChange={this._onValueChange(property, formatter)(roundId, holeNumber)}
              />
            )
          }}
        </ActiveRound>
      </VeryDenseTableCell>
    )
  }

  private _renderHoleGIR = (roundId: number, holeNumber: number, roundCourse: RoundCourse) => {
    const holeScore = this._getHoleScore(roundId, holeNumber)
    const hole = this._getHole(roundCourse, holeNumber)
    let GIR = false

    if (hole && holeScore) {
      const strokes = parseInt(`${holeScore.strokes}`, 10)
      const putts = parseInt(`${holeScore.putts}`, 10)
      const { par } = hole

      GIR = isGIR(strokes, putts, par)
    }

    return (
      <VeryDenseTableCell
        align={'center'}
        key={`${roundId}-${holeNumber}`}
        className={this.props.classes.inputCell}
        style={{
          padding: `8px 4px`,
          visibility: GIR ? 'visible' : 'hidden',
        }}
      >
        <Check color={'primary'} />
      </VeryDenseTableCell>
    )
  }

  private _renderInitialStroke = (
    roundId: number,
    holeNumber: number,
    roundCourse: RoundCourse,
    disabled?: boolean,
  ) => {
    const holeScore = this._getHoleScore(roundId, holeNumber)
    const hole = this._getHole(roundCourse, holeNumber)

    if (!holeScore || !hole) {
      return null
    }

    return (
      <VeryDenseTableCell
        align={'center'}
        key={`${roundId}-${holeNumber}`}
        style={{
          border: 'none',
          padding: 2,
        }}
      >
        <ActiveRound>
          {({ holeNumberDisabled }) => (
            <InitialStrokePicker
              disabled={hole.par === 3 || holeNumberDisabled(holeNumber) || disabled}
              value={holeScore.initialStroke}
              onChange={this._onValueChange('initialStroke')(roundId, holeNumber)}
            />
          )}
        </ActiveRound>
      </VeryDenseTableCell>
    )
  }

  private _handleOnClick = () => {
    this.setState({ addRound: true })
    this._addRound()
  }

  private _clearResults = () => {
    const { score } = this.props
    const holeScores: HoleScore[] = []

    score &&
      score.rounds.forEach((round) => {
        const roundId = convertLBIdStringToNumber(round.id)
        return Array.from({ length: 18 }).forEach((_, index) => {
          const holeNumber = index + 1
          holeScores.push({ ...defaultHoleScore, roundId, hole: holeNumber })
        })
      })

    this.setState({ holeScores, allowToSave: true })
  }

  private _addRound = () => {
    const holeScores: HoleScore[] = []
    Array.from({ length: 18 }).forEach((_, index) => {
      const holeNumber = index + 1
      holeScores.push({ ...defaultHoleScore, roundId: -1, hole: holeNumber })
    })
    this.setState({ holeScores, weeklyGgbRoundIds: [] })
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => {
    const tournament = selectTournament(state)
    const tournamentConfig = selectTournamentConfig(state)
    const playerId = tournamentConfig.weeklyScores.playerId
    return {
      saving: tournamentConfig.status.isTournamentScoringLoading,
      weeklyRoundId: tournamentConfig.weeklyScores.weeklyRoundId,
      playerId: playerId,
      tournamentType: tournament.tournamentType as TournamentType,
      isTeamFormat: tournament.isTeamFormat,
      startlists: selectTournamentStartLists(state),
      tournament: tournament,
      weeklyScoreRounds: selectWeeklyScoreRounds(state, { tournamentId: tournament.id, playerId }),
      roundCourses: selectRoundCoursesForAllRounds(state),
    }
  },
  {
    updatePlayerTournamentScores: tournamentScoringApi.endpoints.updatePlayerScores.initiate,
    sendPlayerScoresToHandicapping: tournamentScoringApi.endpoints.sendWeeklyHcpScores.initiate,
    updatePlayerStatus: tournamentPlayersApi.endpoints.updatePlayerStatus.initiate,
  },
)(injectIntl(withStyles(styles)(PlayerScore)))
