import React from 'react'
import { Theme } from '@mui/material'

import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

const styles = (theme: Theme) =>
  createStyles({
    teamWrap: {
      marginBottom: 2,
      display: 'flex',
      overflow: 'hidden',
      justifyContent: 'space-between',
      color: theme.palette.primary.main,
      flex: 1,
    },
  })

type Props = React.ComponentProps<any> & WithStyles<typeof styles>

function TeamHeader({ classes, children }: Props) {
  return <div className={classes.teamWrap}>{children}</div>
}

export default withStyles(styles)(TeamHeader)
