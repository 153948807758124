import { PaletteMode } from '@mui/material'
import { createTheme, Theme, PaletteOptions, ThemeOptions } from '@mui/material/styles'

const round = (value: number) => {
  return Math.round(value * 1e5) / 1e5
}

const PLAIN_FONT = ['Helvetica', 'Arial', 'sans-serif'].join(',')

const FONTS = {
  roboto: ['Roboto', PLAIN_FONT].join(','),
  exo: ['Exo', PLAIN_FONT].join(','),
  plain: PLAIN_FONT,
}

export const rem = (value: number): string => {
  return `${round(value / 16)}rem`
}

interface ThemeColors {
  light: string
  lightGray: string
  lightGray2: string
  lightGray3: string
  mediumGray: string
  mediumGray2: string
  darkGray: string
  darkGray2: string
  darkGreen: string
  contrastGrey: string
  dark: string
  lightBlue: string
  darkBlue: string
  warning: string
  error: string
  border: string
  pageBackground: string
  background: string
  bodyBackground: string
  greyBackground: string
  blueBackground: string
  loginBackground: string
  applyBackground: string
  applyText: string
  footer: string
  main: string
  black: string
}

interface ThemeFonts {
  primary: string
  secondary: string
  plain: string
}

declare module '@mui/material/styles' {
  interface Theme {
    customPalette: ThemeColors
    fonts: ThemeFonts
  }

  interface ThemeOptions {
    customPalette?: ThemeColors
    fonts?: ThemeFonts
  }
}

const customPalette = (mode: PaletteMode): ThemeColors => {
  return {
    light: '#FFFFFF',
    lightGray: '#F2F2F2',
    lightGray2: '#D8D8D8',
    lightGray3: '#A4A4A4',
    mediumGray: '#C4C4C4',
    mediumGray2: '#A5A5A5',
    darkGray: '#686868',
    darkGray2: '#555555',
    darkGreen: '#0B8E50',
    contrastGrey: mode === 'light' ? '#555555' : '#FFFFFF',
    dark: '#4A4A4A',
    lightBlue: '#4990E2',
    darkBlue: '#003478',
    warning: '#F5A623',
    error: '#E03333',
    border: mode === 'light' ? '#F2F2F2' : '#777777',
    background: mode === 'light' ? '#F2F2F2' : '#686868',
    pageBackground: mode === 'light' ? '#CFCFCF' : '#262626',
    bodyBackground: mode === 'light' ? '#FFFFFF' : '#444444',
    greyBackground: mode === 'light' ? '#F8F8F8' : '#686868',
    loginBackground: mode === 'light' ? '#FFFFFF' : '#383838',
    applyBackground: mode === 'light' ? '#F5F5F7' : '#4A4A4A',
    applyText: mode === 'light' ? '#404040' : '#FFFFFF',
    blueBackground: '#185D96',
    footer: '#9b9b9b',
    main: '#1AA85D',
    black: '#000000',
  }
}

const createMyTheme = (mode: PaletteMode, options: ThemeOptions) => {
  return createTheme({
    customPalette: customPalette(mode),
    ...options,
    fonts: {
      primary: FONTS.exo,
      secondary: FONTS.roboto,
      plain: FONTS.plain,
    },
  })
}

const palette = (mode: PaletteMode): PaletteOptions => {
  return {
    mode: mode as any,
    primary: {
      main: customPalette(mode).main,
      dark: customPalette(mode).darkGreen,
      contrastText: customPalette(mode).light,
    },
    secondary: {
      main: customPalette(mode).lightBlue,
    },
  }
}

const materialUITheme = (mode: PaletteMode): Theme => {
  return createMyTheme(mode, {
    palette: palette(mode),
    typography: {
      fontFamily: FONTS.roboto,
      body1: {
        letterSpacing: '0.01071em',
        fontFamily: FONTS.roboto,
        fontSize: rem(15),
        color: customPalette(mode).contrastGrey,
      },
      body2: {
        letterSpacing: '0.01071em',
        fontFamily: FONTS.roboto,
        fontSize: rem(15),
        color: customPalette(mode).contrastGrey,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          b: {
            fontWeight: 500,
          },
          p: {
            margin: 0,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: rem(22),
            lineHeight: 1.375,
            fontWeight: 800,
            fontStyle: 'italic',
            fontFamily: FONTS.exo,
            letterSpacing: '-0.00833em',
          },
          h2: {
            fontSize: rem(22),
            lineHeight: 1.375,
            fontWeight: 800,
            fontStyle: 'italic',
            color: customPalette(mode).main,
            display: 'flex',
            alignItems: 'center',
            fontFamily: FONTS.exo,
            letterSpacing: '-0.00833em',
          },
          h3: {
            fontSize: rem(20),
            lineHeight: 1.375,
            fontWeight: 800,
            fontStyle: 'italic',
            color: customPalette(mode).main,
            display: 'flex',
            alignItems: 'center',
            fontFamily: FONTS.exo,
            letterSpacing: '0px',
          },
          h4: {
            fontSize: rem(18),
            lineHeight: 1.375,
            fontWeight: 800,
            fontStyle: 'bold',
            color: customPalette(mode).contrastGrey,
            display: 'flex',
            alignItems: 'center',
            letterSpacing: '0px',
          },
          h5: {
            fontSize: rem(20),
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0px',
          },
          h6: {
            fontSize: rem(16),
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: '0px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            margin: '24px 0',
            backgroundColor: customPalette(mode).main,
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '&.leaderboard': {
              tableLayout: 'auto',
              '& > thead > tr > th': {
                color: 'inherit',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: rem(15),
                paddingBottom: 10,
                borderBottom: 0,
              },
              '& > tbody > tr > td': {
                borderBottom: 0,
                textAlign: 'center',
                fontSize: rem(14),
              },

              '.MuiTableBody-root > .MuiTableRow-root.withBg': {
                background: 'rgba(26,168,93,0.08)',
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontSize: rem(15),
            color: customPalette(mode).lightGray3,
            svg: {
              width: '0.7em',
            },
          },
          body: {
            fontSize: rem(14),
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: customPalette(mode).lightGray3,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: rem(12),
            color: customPalette(mode).main,
            letterSpacing: '0px',
            lineHeight: 1.1,
            marginBottom: 5,
            '&.SwitchLabel': {
              fontSize: rem(14),
              color: customPalette(mode).contrastGrey,
              marginBottom: 0,
              display: 'flex',
              alignItems: 'center',
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          marginNormal: {
            marginTop: 6,
          },
          root: {
            '&.SwitchControl': {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            left: -12,
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          alternativeLabel: {
            top: 10,
            left: '-50%',
            right: '50%',
            zIndex: 0,
          },
          lineHorizontal: {
            borderTopWidth: 4,
            borderColor: customPalette(mode).main,
          },
        },
      },
      MuiStepButton: {
        styleOverrides: {
          root: {
            zIndex: 1,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          root: {
            zIndex: 1,
          },
          alternativeLabel: {
            fontSize: rem(16),
            color: `${customPalette(mode).main} !important`,
            fontWeight: 300,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: customPalette(mode).background,
            marginBottom: 16,
            h6: {
              fontSize: rem(24),
              lineHeight: 1.4,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: rem(15),
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          adornedEnd: {
            paddingRight: 14,
          },
          input: {
            paddingTop: 16,
            paddingBottom: 16,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: undefined,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '16px 24px 24px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: rem(15),
            fontWeight: 400,
            minWidth: 110,

            '&.Mui-disabled': {
              opacity: 0.5,
            },
          },
          contained: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              color: '#fff',
            },
          },
          containedPrimary: {
            '&.Mui-disabled': {
              color: '#fff',
              backgroundColor: customPalette(mode).main,
            },
          },
          outlined: {
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          underlineHover: {
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'none',
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          colorDisabled: {
            color: customPalette(mode).mediumGray,
          },
        },
      },
      MuiCollapse: {
        //TODO: refactor if needed
        /* container: {
          borderBottomColor: customPalette(mode).main,
          borderBottomStyle: 'solid',
          '&.MuiCollapse-hidden': {
            display: 'none',
          },
        }, */
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            color: customPalette(mode).light,
            backgroundColor: customPalette(mode).darkGray2,
            minHeight: 30,
          },
          sizeSmall: {
            width: 30,
            height: 30,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: 'inherit',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          noOptions: {
            padding: 0,
          },
        },
      },
    },
  })
}

export default materialUITheme
