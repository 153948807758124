import React from 'react'
import { Button, Theme, Tooltip } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import BorderButton from '../ui/BorderButton'
import { connect } from 'react-redux'
import { rem } from '../../theme/materialUITheme'
import { ArrowRightAlt } from '@mui/icons-material'
import { RootState } from '@app/store'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

const SaveButton = ({
  disabled,
  onClick,
  style = {},
  className,
}: {
  disabled?: boolean
  onClick?(): void
  style?: React.CSSProperties
  className?: string
}) => (
  <BorderButton
    buttonProps={{
      disabled,
      onClick,
      size: 'large',
      style: {
        ...style,
      },
      className,
    }}
  >
    <FormattedMessageWrapper id="buttons.save" />
  </BorderButton>
)

export const UpdateChangesButton = ({
  disabled,
  tooltipDisabled,
  onClick,
  style = {},
  filled = true,
  ...rest
}: {
  disabled?: boolean
  tooltipDisabled?: boolean
  onClick?(): void
  style?: React.CSSProperties
  filled?: boolean
}) => (
  <Tooltip
    disableTouchListener={tooltipDisabled}
    disableHoverListener={tooltipDisabled}
    disableFocusListener={tooltipDisabled}
    title={<FormattedMessageWrapper id="tournament.updateChanges" />}
  >
    <div>
      <BorderButton
        fgu
        filled={filled}
        buttonProps={{
          disabled,

          onClick,
          style: {
            ...style,
          },
        }}
        {...rest}
      >
        <FormattedMessageWrapper id={'buttons.updateChanges'} />
      </BorderButton>
    </div>
  </Tooltip>
)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: 60,
    },
    secondaryActions: {
      alignSelf: 'center',
    },
    priorityActions: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      '@global': {
        '> *': {
          marginLeft: rem(16),
        },
      },
    },
    removeButton: {
      border: `2px solid ${theme.customPalette.error}`,
      color: theme.customPalette.error,
    },
  })

interface StateProps {
  tournamentConfig: TournamentConfig
}

interface OwnProps extends WithStyles<typeof styles> {
  disableNext?: boolean
  disableSave?: boolean
  nextLabelId?: string
  style?: React.CSSProperties
  onClickSaveAndPublish?(): any
  onClickNext?(): any
  onClickBack?(): any
  onClickRemoveRound?: () => void
  secondaryActions?: JSX.Element
}

type Props = OwnProps & StateProps

class UnconnectedTournamentFormActions extends React.Component<Props> {
  render(): React.ReactNode {
    const {
      classes,
      style,
      onClickSaveAndPublish,
      onClickNext,
      onClickBack,
      onClickRemoveRound,
      disableNext = false,
      disableSave = false,
      nextLabelId = 'buttons.saveAndContinue',
      tournamentConfig,
      secondaryActions,
    } = this.props

    return (
      <div style={style} className={classes.root}>
        <div>
          {Boolean(onClickBack) && (
            <Button onClick={onClickBack}>
              <FormattedMessageWrapper id="buttons.back" />
            </Button>
          )}
          {!!onClickRemoveRound && (
            <BorderButton buttonProps={{ onClick: onClickRemoveRound, className: classes.removeButton }}>
              <FormattedMessageWrapper id="buttons.removeRound" />
            </BorderButton>
          )}
        </div>
        {secondaryActions && <div className={classes.secondaryActions}>{secondaryActions}</div>}
        <div className={classes.priorityActions}>
          {Boolean(onClickSaveAndPublish) && (
            <Tooltip
              disableTouchListener={tournamentConfig.isDirty}
              disableHoverListener={tournamentConfig.isDirty}
              disableFocusListener={tournamentConfig.isDirty}
              title={<FormattedMessageWrapper id={'buttons.savingDisabled'} />}
              placement="top"
            >
              <div>
                <SaveButton disabled={disableSave || !tournamentConfig.isDirty} onClick={onClickSaveAndPublish} />
              </div>
            </Tooltip>
          )}
          {Boolean(onClickNext) && (
            <BorderButton
              filled
              buttonProps={{
                onClick: onClickNext,
                disabled: disableNext,
              }}
            >
              <FormattedMessageWrapper id={nextLabelId} /> <ArrowRightAlt style={{ marginLeft: 5 }} />
            </BorderButton>
          )}
        </div>
      </div>
    )
  }
}

const TournamentFormActions = connect<StateProps, OwnProps, {}, RootState>(
  (state): StateProps => ({
    tournamentConfig: selectTournamentConfig(state),
  }),
)(UnconnectedTournamentFormActions)

export default withStyles(styles)(TournamentFormActions)
