//LIBRARIES
import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { FormControl, FormLabel, Switch, Grid, Theme, TextField } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import withTheme from '@mui/styles/withTheme'
import get from 'lodash/get'
import Collapse from '@mui/material/Collapse'

//COMPONENTS
import ContentWrap from '@components/layout/ContentWrap'
import { SectionWrapper } from '@app/components/layout/SectionWrapper'
import SectionTitle from '@components/ui/SectionTitle'
import { scrollToTop } from '@components/ui'
import HeadHelmet from '@components/layout/HeadHelmet'
import { TournamentTypeSelection } from '@components/tournament'
import TournamentFormActions from '@components/tournament/TournamentFormActions'
import { BasicDetails } from './BasicDetails'
import { Rankings } from './Rankings'
import { confirm } from '@components/dialogs/confirm/Confirm'

//ACTIONS
import { enqueueNotification } from '@store/notifications/actions'
import { updateGlobalHoleConfig } from '@app/store/api/thunks/tournamentThunks'
import { updateTournamentSettingsField } from '@app/store/api/thunks/tournamentSettingsThunks'
import { tournamentApi, selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { updateTournamentField } from '@app/store/api/thunks/tournamentThunks'
import { selectTournamentSettings, tournamentSettingsApi } from '@app/store/api/endpoints/tournamentSettingsApi'

//ENUMS & TYPES & UTILS & OTHER
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { getNextUrl } from '@utils/nextUrlHelper'
import { isCountryWithPlayerRegistry } from '@utils/organizationUtils'
import { ViewDivisions } from '@app/scenes/tournament/setup/divisions/ViewDivisions'
import { validateHandicapValue } from '@app/utils/playerUtils'
import { RootState } from '@app/store'
import { loadTournamentLeaderboard, LoadTournamentLeaderboardArgs } from '@store/tournamentLeaderboard/actions'

interface StateProps {
  auth: AuthenticationState
  organization: OrganizationState
  rankings: Ranking[]
  tournament: TournamentState
  tours: Tour[]
  tournamentSettings: TournamentSettings
}

interface DispatchProps {
  enqueueNotification(error: any, variant?: string): void
  createTournament(payload: CreateTournamentPayload): void
  saveTournament(payload: UpdateTournamentPayload): void
  saveTournamentSettings: (payload: UpdateTournamentSettingsPayload) => void
  updateGlobalHoleConfig: (payload: UpdateRoundFieldPayload) => void
  updateTournamentField(payload: FieldUpdatePayload): void
  updateTournamentSettingsField(tournamentId: number, payload: FieldUpdatePayload): void
  loadTournamentLeaderboard: (args: LoadTournamentLeaderboardArgs) => void
}

export enum Prefix {
  ADD = 'ADD',
  SUBSTRACT = 'SUBSTRACT',
}

interface ThemeProps {
  theme: Theme
}

type Props = StateProps & DispatchProps & ThemeProps & WrappedComponentProps & WithRouterProps

interface State {
  hcpVisible: boolean
  hcpLimit?: string
  isLeaderboardFetched: boolean
  isIndividualHcpIndexLimitDisabled: boolean
}

class UnroutedSetup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      hcpVisible: false,
      hcpLimit: undefined,
      isLeaderboardFetched: false,
      isIndividualHcpIndexLimitDisabled: false,
    }

    this.onTournamentTypeChange = this.onTournamentTypeChange.bind(this)
    scrollToTop()
  }

  componentDidMount() {
    if (!this.hasLicenseOrSubscription) {
      this.props.navigate('/tournaments')
      return
    }
    this.maybeSetHcpToggleVisible()
    this._maybeFetchLeaderboard()
  }

  componentDidUpdate() {
    this.maybeSetHcpToggleVisible()
    this._maybeFetchLeaderboard()
  }

  public render() {
    const { tournament } = this.props
    const activeRankings = this.props.rankings.filter((r) => r.active)
    const tourActive = Number.isInteger(tournament.tour)
    const tournamentType = tournament.tournamentType

    return (
      <SectionWrapper>
        <HeadHelmet titleId={'progress.setup'} />

        {/*
         * Tournament type
         */}
        <ContentWrap>
          <SectionTitle>
            <FormattedMessageWrapper id="tournament.tournamentType" />
          </SectionTitle>
          <p style={{ marginBottom: this.props.theme.spacing(2) }}>
            <FormattedMessageWrapper id="tournament.selectTournamentType" />
          </p>
          <TournamentTypeSelection
            onChange={this.onTournamentTypeChange}
            value={tournamentType as TournamentTypes}
            lockSelections={!!tournament.createdAt}
          />
        </ContentWrap>

        {/*
         * Basic details
         */}
        <BasicDetails
          onSwitchChange={this.onSwitchChange}
          onInputChange={this.onInputChange}
          onSelectChange={this.onSelectChange}
          onSelectSettingsChange={this.onSelectSettingsChange}
          onSwitchSettingsChange={this.onSwitchSettingsChange}
          onWeeklyRoundChange={this.onWeeklyRoundChange}
          changeWeeklyRoundsValue={this.changeWeeklyRoundsValue}
          onInputSettingsChange={this.onInputSettingsChange}
          isHCPVisible={this.state.hcpVisible}
          tours={this.props.tours}
        />

        {/*
         * Rankings
         */}
        {tourActive && (
          <ContentWrap>
            <Grid container spacing={2}>
              {/*
               * Primary game rankings
               */}
              <Grid item xs={4}>
                <Rankings
                  tournamentState={tournament}
                  activeRankings={activeRankings}
                  gameType="primary"
                  updateTournamentField={this.props.updateTournamentField}
                ></Rankings>
              </Grid>

              {/*
               * Side game rankings
               */}
              <Grid item xs={4}>
                <Rankings
                  tournamentState={tournament}
                  activeRankings={activeRankings}
                  gameType="side"
                  updateTournamentField={this.props.updateTournamentField}
                ></Rankings>
              </Grid>

              <Grid item xs={4} />
            </Grid>
          </ContentWrap>
        )}

        {/**
         * Set individual HCP index limit
         */}

        <ContentWrap>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle>
                <FormattedMessageWrapper id="tournament.setIndividualHcpIndexLimit" />
                <FormControl style={{ marginLeft: 15 }}>
                  <Switch
                    checked={tournament.individualHcpIndexLimitEnabled}
                    onChange={this.onSwitchChange}
                    name="individualHcpIndexLimitEnabled"
                    value="true"
                    color="primary"
                    disabled={this.state.isIndividualHcpIndexLimitDisabled}
                  />
                </FormControl>
              </SectionTitle>
              <FormattedMessageWrapper id="tournament.setIndividualHcpIndexLimitDescription" />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={tournament.individualHcpIndexLimitEnabled}>
                <FormControl>
                  <TextField
                    type="text"
                    required={tournament.individualHcpIndexLimitEnabled}
                    variant={'outlined'}
                    name="individualHcpIndexLimit"
                    onChange={this.onIndividualHcpIndexLimitChange}
                    value={this.formattedIndividualHcpIndexLimit}
                    disabled={this.state.isIndividualHcpIndexLimitDisabled}
                  />
                </FormControl>
              </Collapse>
            </Grid>
          </Grid>
        </ContentWrap>

        {/**
         * Divisions
         */}

        <ContentWrap>
          <Grid container spacing={2}>
            {/*
             * Title
             */}
            <Grid item xs={12}>
              <SectionTitle>
                <FormattedMessageWrapper id="divisions.divisions" />
                <FormControl style={{ marginLeft: 15 }}>
                  <Switch
                    checked={tournament.divisionsEnabled}
                    onChange={this.onSwitchChange}
                    name="divisionsEnabled"
                    value="true"
                    color="primary"
                  />
                </FormControl>
              </SectionTitle>
              <FormattedMessageWrapper id="tournament.tournamentDivisionDescription" />
            </Grid>

            {/*
             * Divisions list
             */}
            <Grid item xs={12}>
              <Collapse in={tournament.divisionsEnabled}>
                <ViewDivisions divisionsCount={tournament.divisions.length || 0} />
              </Collapse>
            </Grid>
          </Grid>
        </ContentWrap>

        {/*
         * Application setup
         */}
        <ContentWrap>
          <Grid container spacing={2}>
            {/*
             * Title
             */}
            <Grid item xs={12}>
              <SectionTitle>
                <FormattedMessageWrapper id="tournament.applicationSetup" />
              </SectionTitle>
            </Grid>

            {/*
             * Private tournament switch
             */}
            <Grid item xs={4}>
              <FormControl className="SwitchControl">
                <Switch
                  checked={tournament.public === false}
                  onChange={this.onSwitchChange}
                  name="public"
                  value="true"
                  color="primary"
                />
                <FormLabel className="SwitchLabel">
                  <FormattedMessageWrapper id={'tournament.privateTournament'} />
                </FormLabel>
              </FormControl>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </ContentWrap>

        {/*
         * Save and next page
         */}
        <ContentWrap>
          <TournamentFormActions
            nextLabelId={'buttons.roundSetup'}
            onClickNext={this.clickNext}
            onClickSaveAndPublish={this.saveTournament}
          />
        </ContentWrap>
      </SectionWrapper>
    )
  }

  private maybeSetHcpToggleVisible = () => {
    const { tournament, organization: userOrganization } = this.props
    const organization = tournament.id > 0 ? tournament.tournamentOrganization : userOrganization
    if (!this.state.hcpVisible && isCountryWithPlayerRegistry(organization)) {
      this.setState({ hcpVisible: true })
    }
  }

  private validateForm = (): boolean => {
    const { tournament } = this.props

    if (tournament.name === '') {
      this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.noTournamentName' }), 'error')
      return false
    }
    if (tournament.individualHcpIndexLimitEnabled && tournament.individualHcpIndexLimit === undefined) {
      this.props.enqueueNotification(
        this.props.intl.formatMessage({ id: 'errors.individualHcpIndexLimitCannotBeEmpty' }),
        'error',
      )
      return false
    }
    return true
  }

  public clickNext = () => {
    if (this.validateForm()) {
      const nextUrl = getNextUrl(this.props.location.pathname, 'round-setup')
      this.props.navigate(nextUrl)
      this.saveOrCreateTournament(() => {
        this.props.tournament.id > 0 && this.saveExistingTournamentSettings()
        scrollToTop()
      })
    }
  }

  public saveTournament = () => {
    if (this.validateForm()) {
      this.saveOrCreateTournament(() => {
        this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'notifications.saveSuccessful' }), 'success')
        this.props.tournament.id > 0 && this.saveExistingTournamentSettings()
      })
    }
  }

  public saveOrCreateTournament = (onSuccess: () => void) => {
    const { tournament } = this.props
    if (tournament.id) {
      this.props.saveTournament({ id: tournament.id, body: tournament, onSuccess })
    } else {
      this.props.createTournament({ body: tournament, onComplete: this.saveNewTournamentSettings, onSuccess })
    }
  }

  public onTournamentTypeChange(value: TournamentTypes): void {
    this.props.updateTournamentField({
      fieldName: 'tournamentType',
      value,
    })
  }

  public onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e
    const { intl, tournament } = this.props

    if (currentTarget.name === 'divisionsEnabled' && tournament.divisionsEnabled) {
      confirm({
        message: intl.formatMessage({ id: 'tournament.divisionEnabledMsg' }),
        options: {
          okText: intl.formatMessage({ id: 'tournament.confirm' }),
          cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        },
      }).then(() => {
        this.props.updateTournamentField({
          fieldName: currentTarget.name,
          value: !currentTarget.checked,
        })
      })
      return
    }

    if (currentTarget.name === 'public') {
      this.props.updateTournamentField({
        fieldName: currentTarget.name,
        value: !currentTarget.checked,
      })
      return
    }

    this.props.updateTournamentField({
      fieldName: currentTarget.name,
      value: currentTarget.checked,
    })
  }

  public onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateTournamentField({
      fieldName: e.currentTarget.name,
      value: e.currentTarget.value,
    })
  }

  public onInputSettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateTournamentSettingsField(this.props.tournament.id, {
      fieldName: e.currentTarget.name,
      value: e.currentTarget.value,
    })
  }

  public onSelectChange = (event) => {
    const fieldName = get(event, 'target.name')
    if (fieldName) {
      this.props.updateTournamentField({
        fieldName,
        value: event.target.value,
      })
    }
  }

  public onSelectSettingsChange = (event) => {
    const fieldName = get(event, 'target.name')
    if (fieldName) {
      this.props.updateTournamentSettingsField(this.props.tournament.id, {
        fieldName,
        value: event.target.value,
      })
    }
  }

  public onSwitchSettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.updateTournamentSettingsField(this.props.tournament.id, {
      fieldName: e.currentTarget.name,
      value: e.currentTarget.checked,
    })
  }

  public onIndividualHcpIndexLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(',', '.')
    const formattedHcp = validateHandicapValue(value)
    this.setState({ hcpLimit: value })
    this.props.updateTournamentField({
      fieldName: 'individualHcpIndexLimit',
      value: formattedHcp.value ? parseFloat(formattedHcp.value) : undefined,
    })
  }

  private _maybeFetchLeaderboard = () => {
    const isWeeklyTournament = this.props.tournament.tournamentType === TournamentTypes.weekly
    if (this.props.tournament.id > 0 && isWeeklyTournament && !this.state.isLeaderboardFetched) {
      this.props.loadTournamentLeaderboard({
        id: this.props.tournament.id,
        onComplete: this._maybeDisableIndividualHcpIndexLimit,
      })
      this.setState({ isLeaderboardFetched: true })
    }
  }

  private _maybeDisableIndividualHcpIndexLimit = (result: APICallResult) => {
    const leaderboard: TournamentLeaderboard = result.data.data
    const isWeeklyTournament = this.props.tournament.tournamentType === TournamentTypes.weekly

    if (isWeeklyTournament && leaderboard && this.state.isIndividualHcpIndexLimitDisabled === false) {
      const players = leaderboard?.primary?.players || []
      const shouldDisableForm = players.some((p: TournamentLeaderboardPlayer) => p.toPar !== '')
      if (shouldDisableForm) {
        this.setState({ isIndividualHcpIndexLimitDisabled: true })
      }
    }
  }

  private get hasLicenseOrSubscription() {
    const { license, subscription } = this.props.auth
    return license.active || subscription.active
  }

  private get formattedIndividualHcpIndexLimit() {
    const { tournament } = this.props
    if (tournament.individualHcpIndexLimit === undefined) {
      return ''
    }
    /**
     * + handicaps not supported for index limit
     */
    return this.state.hcpLimit || Math.abs(tournament.individualHcpIndexLimit)
  }

  /**
   * Change weekly round value manually. Prevents values under 1 and changes 0 to null
   * @param e
   */
  public onWeeklyRoundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    const valueAsNumber = Number(value) > 0 ? Number(value) : null

    if (name) {
      this.props.updateTournamentField({
        fieldName: name,
        value: valueAsNumber,
      })
    }
  }

  /**
   * Adds or substracts 1 from weeklyRounds
   * @param prefix ADD|SUBSTRACT
   */
  public changeWeeklyRoundsValue = (prefix: Prefix) => {
    const { weeklyRounds } = this.props.tournament

    if (typeof weeklyRounds === 'undefined' || !prefix) {
      return
    }

    let value: number | null = weeklyRounds

    if (prefix === Prefix.ADD) {
      value += 1
      // Converts null to number
      if (weeklyRounds === null) {
        value = 1
      }
    }

    if (prefix === Prefix.SUBSTRACT) {
      // Prevents substracting from null
      if (weeklyRounds === null) {
        return
      }
      value -= 1
    }

    // 0 is converted to null
    if (value === 0) {
      value = null
    }

    this.props.updateTournamentField({
      fieldName: 'weeklyRounds',
      value,
    })
  }

  private saveNewTournamentSettings = (tournament: TournamentState) => {
    this.props.saveTournamentSettings({ id: tournament.id, body: this.props.tournamentSettings })
  }

  private saveExistingTournamentSettings = () => {
    const { tournamentSettings, tournament } = this.props
    this.props.saveTournamentSettings({ id: tournament.id, body: tournamentSettings })
  }
}

const IntlSetup = injectIntl(UnroutedSetup)
const ThemedSetup = withTheme(IntlSetup as any)
export const Setup = withRouter(
  connect<StateProps, DispatchProps, WithRouterProps, RootState>(
    (state): StateProps => {
      return {
        auth: state.authenticationReducer,
        organization: state.organizationReducer,
        rankings: state.tourAndRankingReducer.rankings,
        tournament: selectTournament(state),
        tours: state.tourAndRankingReducer.tours,
        tournamentSettings: selectTournamentSettings(state),
      }
    },
    {
      enqueueNotification,
      saveTournament: tournamentApi.endpoints.updateTournament.initiate,
      createTournament: tournamentApi.endpoints.createTournament.initiate,
      updateGlobalHoleConfig,
      updateTournamentField,
      updateTournamentSettingsField,
      saveTournamentSettings: tournamentSettingsApi.endpoints.updateTournamentSettings.initiate,
      loadTournamentLeaderboard,
    },
  )(ThemedSetup),
)
