import React, { useRef, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { useIntl } from 'react-intl'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { FormattedMessageWrapper } from '../ui/FormattedMessageWrapper'
import { MatchPlayBracketPlayer } from './MatchPlayBracketPlayer'
import { useCardStyles } from './cardStyles'
import { useBracketCardDistances } from '@app/hooks/useBracketCardDistances'

interface Props {
  player: MatchPlayBracketPlayer | null
  players: MatchPlayBracketPlayer[]
  index: number
  draggableId: string
  draggable: boolean
  droppable: boolean
  stage: MatchPlayBracketStage
  stages: MatchPlayBracketStage[]
  editPlayer: (player: MatchPlayBracketPlayer, deletePlayer?: boolean) => void
  updateStageDescription: (e: React.ChangeEvent<HTMLInputElement>) => void
  submitStageDescription: () => void
}

export const MatchPlayBracketCard: React.FC<Props> = ({
  player,
  players,
  index,
  draggableId,
  draggable,
  droppable,
  stage,
  stages,
  editPlayer,
  updateStageDescription,
  submitStageDescription,
}) => {
  const classes = useCardStyles()
  const elementRef = useRef<HTMLDivElement | null>(null)
  const [showDescriptionField, setShowDescriptionField] = useState<BracketStage | null>(null)
  const intl = useIntl()

  useBracketCardDistances(elementRef, stage, stages)

  const isFinalOrBronzeMatch: boolean =
    MatchPlayBracketStages.FINAL === stage.stage || MatchPlayBracketStages.BRONZE_MATCH === stage.stage

  const renderDroppable = (): React.ReactElement => {
    const secondaryClass = shouldShowDroppableCard() ? classes.droppable : classes.emptyCard
    return (
      <div className={`${classes.card} ${secondaryClass}`}>
        <div>{shouldShowDroppableCard() && <FormattedMessageWrapper id="matchPlayBracket.dragPlayerHere" />}</div>
      </div>
    )
  }

  const shouldShowDroppableCard = (): boolean => {
    if (stages[0] === stage) {
      return true
    }
    const currentStageIndex = stages.findIndex((s: MatchPlayBracketStage) => s.stage === stage.stage)
    let previousStageIndex = currentStageIndex - 1
    if (stage.stage === MatchPlayBracketStages.BRONZE_MATCH) {
      previousStageIndex = currentStageIndex - 2
    }
    const previousStage = stages[previousStageIndex]
    const previousStagePlayers = players.filter((p: MatchPlayBracketPlayer) => p.stage === previousStage.stage)
    const neededPreviousStagePositions = [index * 2, index * 2 + 1]
    const matchingPlayers = previousStagePlayers.filter((p: MatchPlayBracketPlayer) =>
      neededPreviousStagePositions.includes(p.order - 1),
    )
    return matchingPlayers.length === 2
  }

  const renderPlayer = (): React.ReactElement => {
    return player ? <MatchPlayBracketPlayer player={player} editPlayer={editPlayer} players={players} /> : <></>
  }

  const shouldRenderDroppable = (droppableSnapshot: DroppableStateSnapshot): boolean => {
    return droppable && !droppableSnapshot.isDraggingOver
  }

  const shouldRenderPlayer = (): boolean => {
    return draggable || player?.stage === MatchPlayBracketStages.FINAL
  }

  const getFinalOrBronzeTitleKey = (): string => {
    return stage.stage === MatchPlayBracketStages.FINAL
      ? `matchPlayBracket.${MatchPlayBracketStages.FINAL}`
      : `matchPlayBracket.${MatchPlayBracketStages.BRONZE_MATCH}`
  }

  const getFinalOrBronzeClass = (): string => {
    return stage.stage === MatchPlayBracketStages.FINAL ? classes.finalTitle : classes.bronzeTitle
  }

  const getElementClasses = (): string => {
    return stages[0]?.visible === false
      ? `${classes.droppableContainer} first-stage-hidden`
      : classes.droppableContainer
  }

  const setRefs = (el: HTMLDivElement, provided: DroppableProvided) => {
    provided.innerRef(el)
    elementRef.current = el
  }

  const submitStageUpdate = (): void => {
    setShowDescriptionField(null)
    submitStageDescription()
  }

  return (
    <Droppable droppableId={draggableId} isDropDisabled={droppable === false}>
      {(provided: DroppableProvided, droppableSnapshot: DroppableStateSnapshot) => (
        <div
          {...provided.droppableProps}
          className={getElementClasses()}
          ref={(el: HTMLDivElement) => setRefs(el, provided)}
        >
          {droppableSnapshot.isDraggingOver && renderDroppable()}
          <Draggable
            key={draggableId}
            draggableId={draggableId}
            index={index}
            isDragDisabled={!draggable || player?.winner === true}
          >
            {(provided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) => (
              <>
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                  {isFinalOrBronzeMatch && index === 0 && (
                    <div className={getFinalOrBronzeClass()}>
                      <h3>
                        <FormattedMessageWrapper id={getFinalOrBronzeTitleKey()} />
                      </h3>
                      <span className={!stage.description ? classes.addDate : ''}>
                        {stage.description || intl.formatMessage({ id: 'matchPlayBracket.addDateHere' })}
                        <EditIcon onClick={() => setShowDescriptionField(stage.stage)} />
                      </span>
                      {showDescriptionField === stage.stage && (
                        <div className={classes.stageDescriptionInput}>
                          <input
                            type="text"
                            name={stage.stage}
                            value={stage.description}
                            onChange={updateStageDescription}
                          />
                          <button onClick={submitStageUpdate}>
                            <CheckIcon />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {shouldRenderPlayer() && renderPlayer()}
                  {shouldRenderDroppable(droppableSnapshot) && renderDroppable()}
                </div>
                {draggableSnapshot.isDragging && renderPlayer()}
              </>
            )}
          </Draggable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
