import React from 'react'
import { Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'

type Props = {
  text: NonNullable<React.ReactNode>
  style?: React.CSSProperties
  size?: 'small' | 'large'
  className?: string
  arrow?: boolean
}
const useStyles = makeStyles(() => ({
  popper: {},
  tooltip: {
    padding: 10,
    backgroundColor: '#222A35',
    '@global': {
      '.MuiTooltip-arrow': {
        color: '#222A35',
      },
      ul: {
        paddingLeft: 20,
      },
      h5: {
        fontSize: 12,
        fontWeight: 700,
        paddingLeft: 10,
        marginBottom: 0,
        marginTop: 10,
      },
    },
  },
}))

const InfoTooltip = ({ text, style, size, className, arrow = false }: Props) => {
  const classes = useStyles()
  return (
    <Tooltip title={text} arrow={arrow} classes={classes} data-testid="info-tooltip">
      <InfoOutlined color="primary" fontSize={size} style={style} className={className} />
    </Tooltip>
  )
}

export default InfoTooltip
