import React from 'react'
import { connect } from 'react-redux'
import { selectTournamentStartLists, updateStartListField } from '@store/api/slices/tournamentStartListsSlice'
import { Theme, Grid } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import { VeryDenseTableCell } from '../tables/tableComponents'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Clear } from '@mui/icons-material'
import { getUnassignedPlayers, getUnassignedTeamsCount } from '@app/store/api/selectors/tournamentStartListsSelectors'
import { emptyGroupStartTees } from '@app/store/api/emptyObjects'
import Link from '@mui/material/Link'
import { MAX_EIGHTEEN_HOLE_GROUP_COUNT, MAX_NINE_HOLES_GROUP_COUNT } from '@utils/gameFormatUtils'
import ActiveRound, { ActiveRoundArgs } from '../headless/ActiveRound'
import { head, tail, reverse } from 'lodash'
import SectionTitle from '../ui/SectionTitle'
import { calculateShotgunNeededGroupsToFill } from '@app/utils/startListUtils'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

const styles = (theme: Theme) =>
  createStyles({
    titleCell: {
      border: 'none',
      paddingRight: 12,
      fontWeight: 'bold',
    },
    tableCell: {
      border: 'none',
      textAlign: 'center',
    },
    groupButton: {
      minWidth: 0,
      backgroundColor: theme.customPalette.light,
    },
    infoContainer: {
      flex: 1,
      alignSelf: 'center',
    },
  })

interface StateProps {
  tournamentStartLists: TournamentStartListState
  rounds: StartListRound[]
  tournamentRounds: Round[]
  tournament: TournamentState
  unassignedPlayers: TournamentPlayer[]
  unassignedTeamsCount: number
  activeRoundIdx: number
}

interface DispatchProps {
  updateStartListField(payload: UpdateRoundFieldPayload): void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps & WithRouterProps

class ShotgunStartHolePicker extends React.Component<Props> {
  componentDidMount(): void {
    if (!this._groupStartTees || this._groupStartTees.length === 0) {
      this._autoFillGroups()
    }
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <SectionTitle>
            <FormattedMessageWrapper id={'tournament.startListShotgun'} />
          </SectionTitle>
        </Grid>
        <Grid item>
          <Table>
            <TableBody>
              <TableRow>
                <VeryDenseTableCell className={this.props.classes.titleCell} style={{ minWidth: 78 }}>
                  <FormattedMessageWrapper id={'course.hole'} />
                </VeryDenseTableCell>
                {this._holes}
                <VeryDenseTableCell className={this.props.classes.titleCell} />
              </TableRow>
              <TableRow>
                <VeryDenseTableCell className={this.props.classes.titleCell}>
                  <FormattedMessageWrapper id={'tournament.groups'} />
                </VeryDenseTableCell>
                {this._groups}
                <VeryDenseTableCell className={this.props.classes.tableCell} colSpan={2} />
              </TableRow>
              {!this._hasGroups && (
                <TableRow>
                  <VeryDenseTableCell className={this.props.classes.titleCell} style={{ paddingTop: 0 }}>
                    {/* Show Clear All once some selections are made */}
                  </VeryDenseTableCell>
                  {this._decreaseActions}
                  <VeryDenseTableCell style={{ border: 'none', textAlign: 'left', paddingLeft: 20 }} align={'left'}>
                    {/* Show Auto Fill when no selections have been made */}
                  </VeryDenseTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
        {!this._hasGroups && (
          <Grid item className={this.props.classes.infoContainer}>
            {this._renderUnassignedInfo()}
            {this._numberOfGroupsAssigned > 0 && (
              <Link onClick={this._clearAllGroups} style={{ cursor: 'pointer' }}>
                <FormattedMessageWrapper id={'buttons.clearAll'} />
              </Link>
            )}
            {this._numberOfGroupsAssigned === 0 && (
              <Link onClick={() => this._autoFillGroups(this._numberOfGroups)} style={{ cursor: 'pointer' }}>
                <FormattedMessageWrapper id={'buttons.autoFill'} />
              </Link>
            )}
          </Grid>
        )}
      </Grid>
    )
  }

  public _updateHoleGroupCount = (action: 'add' | 'remove', idx: number) => {
    const groupsCounts = this._groupStartTees.length ? this._groupStartTees : emptyGroupStartTees
    const value = groupsCounts.map((count, index) => {
      if (idx === index) {
        if (action === 'add') {
          if (count === 10) {
            return count
          } else if (this._groupCountNeeded <= 0) {
            return count
          } else {
            return count + 1
          }
        } else {
          return count - 1
        }
      } else {
        return count
      }
    })

    this.props.updateStartListField({
      index: this.props.activeRoundIdx,
      fieldName: 'groupStartTees',
      value,
    })
  }

  private _renderUnassignedInfo = () => {
    if (this._groupCountNeeded > 0) {
      return (
        <div>
          <FormattedMessageWrapper id={'tournament.unassignedGroups'} />:{' '}
          <b style={{ fontWeight: 600 }}>{this._groupCountNeeded}</b>
        </div>
      )
    } else if (this._groupCountNeeded < 0) {
      return (
        <div style={{ color: '#f44336' }}>
          <FormattedMessageWrapper id={'tournament.tooManyGroups'} />:{' '}
          <b style={{ fontWeight: 600 }}>{Math.abs(this._groupCountNeeded)}</b>
        </div>
      )
    }

    return null
  }

  private _autoFillGroups = (groupCount: number = this._groupCountNeeded) => {
    let value = [...emptyGroupStartTees]
    const holes = this._roundHoles

    if (holes === 'f9' || holes === 'b9') {
      value = emptyGroupStartTees.slice(9)
    }

    while (groupCount > 0) {
      // eslint-disable-next-line no-loop-func
      value = value.map((num: number) => {
        if (groupCount > 0) {
          groupCount -= 1
          return num + 1
        }

        return num
      })
    }

    if (this._startType === 'shotgunReversed') {
      value = [head(value) as number, ...reverse(tail(value))]
    }

    if (holes === 'f9') {
      value = [...value, ...emptyGroupStartTees.slice(9)]
    } else if (holes === 'b9') {
      value = [...emptyGroupStartTees.slice(9), ...value]
    }

    this.props.updateStartListField({
      index: this.props.activeRoundIdx,
      fieldName: 'groupStartTees',
      value,
    })
  }

  private _clearAllGroups = () => {
    this.props.updateStartListField({
      index: this.props.activeRoundIdx,
      fieldName: 'groupStartTees',
      value: [...emptyGroupStartTees],
    })
  }

  private get _holes() {
    const course = this._currentTournamentRound.course
    const returnVal: React.ReactNode[] = []
    if (course) {
      for (let i = 1; i <= course.holes.length; i++) {
        returnVal.push(
          <VeryDenseTableCell key={i} className={this.props.classes.tableCell}>
            {i}
          </VeryDenseTableCell>,
        )
      }

      return returnVal
    }

    return null
  }

  private get _groups() {
    const course = this._currentTournamentRound.course
    const groupCounts = this._groupStartTees
    const returnVal: React.ReactNode[] = []

    if (course) {
      for (let i = 0; i < course.holes.length; i++) {
        returnVal.push(
          <VeryDenseTableCell
            key={i}
            className={this.props.classes.tableCell}
            style={{ ...(this._hasGroups && { cursor: 'not-allowed' }) }}
          >
            <ActiveRound>
              {({ isBackNine, isFrontNine }: ActiveRoundArgs) => {
                const disabled =
                  this._hasGroups || groupCounts[i] === 10 || (i < 9 && isBackNine) || (i > 8 && isFrontNine)

                return (
                  <Button
                    className={this.props.classes.groupButton}
                    variant="outlined"
                    size="small"
                    color={groupCounts[i] > 0 ? 'primary' : undefined}
                    disabled={disabled}
                    onClick={() => this._updateHoleGroupCount('add', i)}
                  >
                    {groupCounts[i] || 0}
                  </Button>
                )
              }}
            </ActiveRound>
          </VeryDenseTableCell>,
        )
      }
      return returnVal
    }
    return null
  }

  private get _decreaseActions() {
    const course = this._currentTournamentRound.course
    const groupCounts = this._groupStartTees
    const returnVal: React.ReactNode[] = []

    if (course) {
      for (let i = 0; i < course.holes.length; i++) {
        returnVal.push(
          <VeryDenseTableCell key={i} className={this.props.classes.tableCell} style={{ paddingTop: 3 }}>
            {groupCounts[i] > 0 && (
              <IconButton
                aria-label="Decrease"
                disabled={this._hasGroups}
                style={{ padding: 2 }}
                onClick={() => this._updateHoleGroupCount('remove', i)}
                size="large"
              >
                <Clear fontSize="small" />
              </IconButton>
            )}
          </VeryDenseTableCell>,
        )
      }
      return returnVal
    }

    return null
  }

  private get _hasGroups() {
    return this._currentRound.groups.length > 0
  }

  private get _currentRound() {
    return this.props.rounds[this.props.activeRoundIdx]
  }

  private get _currentTournamentRound() {
    return this.props.tournamentRounds[this.props.activeRoundIdx]
  }

  private get _unassignedPlayerCount() {
    return this.props.unassignedPlayers.length
  }

  private get _groupCountNeeded() {
    return this._numberOfGroups - this._numberOfGroupsAssigned
  }

  private get _numberOfGroups() {
    const { unassignedTeamsCount, tournament } = this.props
    const { isTeamFormat, isTwoManTeamFormat } = tournament

    const defaultTeamSize =
      tournament.rounds[0].primaryGameOptions?.defaultTeamSize ||
      tournament.rounds[0].sideGameOptions?.defaultTeamSize ||
      4

    const amountOfGroupsToFill = calculateShotgunNeededGroupsToFill(
      isTeamFormat,
      isTwoManTeamFormat,
      this._currentRound.basicGroupSize,
      unassignedTeamsCount,
      this._unassignedPlayerCount,
      Number(defaultTeamSize),
    )

    return amountOfGroupsToFill < this._maxGroupCount ? amountOfGroupsToFill : this._maxGroupCount
  }

  private get _groupStartTees() {
    return this._currentRound.groupStartTees
  }

  private get _numberOfGroupsAssigned() {
    return this._groupStartTees.reduce((acc, count) => count + acc, 0)
  }

  private get _roundHoles() {
    const { tournamentRounds, activeRoundIdx } = this.props
    const round = tournamentRounds[activeRoundIdx]
    return round.holes
  }

  private get _maxGroupCount() {
    const holes = this._roundHoles
    if (holes === 'f9' || holes === 'b9') {
      return MAX_NINE_HOLES_GROUP_COUNT
    }

    return MAX_EIGHTEEN_HOLE_GROUP_COUNT
  }

  private get _startType() {
    return this._currentRound.startType
  }
}

export default connect<StateProps, DispatchProps, {}, RootState>(
  (state) => {
    const tournament = selectTournament(state)
    return {
      tournamentStartLists: selectTournamentStartLists(state),
      rounds: selectTournamentStartLists(state).rounds,
      tournamentRounds: tournament.rounds,
      tournament: tournament,
      unassignedPlayers: getUnassignedPlayers(state),
      unassignedTeamsCount: getUnassignedTeamsCount(state),
      activeRoundIdx: selectTournamentConfig(state).selectedRoundIndex,
    }
  },
  {
    updateStartListField,
  },
)(withStyles(styles)(withRouter(injectIntl(ShotgunStartHolePicker as any) as any)))
