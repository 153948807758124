import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { gameFormatGuardExecutor, getFieldNameFromLabel } from '../../utils/gameFormatUtils'
import GameFormatTooltip from './GameFormatTooltip'
import { Grid } from '@mui/material'

interface Props {
  holeCount: string | number
  gameFormatOptions: GameFormatOptions
  fieldData: GameFormatAdditionalOptionSet
  onChange: (e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
  onSelectChange: (event: SelectChangeEvent<any>) => void
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

class GameFormatAdditionalInput extends React.Component<Props> {
  public render() {
    if (this._handleGuard()) {
      return this._handleField()
    }

    return null
  }

  private _handleGuard() {
    const { guard } = this.props.fieldData

    // executor handles situations when the guard is null
    return gameFormatGuardExecutor({ holeCount: this.props.holeCount }, guard)
  }

  private _handleField() {
    const { fieldData } = this.props
    const fieldName = getFieldNameFromLabel(fieldData.label)
    switch (fieldData.type) {
      case 'numeric':
        return this._renderNumeric(fieldData, fieldName)
      case 'boolean':
        return this._renderBoolean(fieldData, fieldName)
      case 'select':
        return this._renderSelect(fieldData, fieldName)
      default:
        return null
    }
  }

  private _renderBoolean(fieldData, fieldName) {
    const { onCheckboxChange, gameFormatOptions, holeCount, disabled } = this.props

    return this._renderMaybeWithTooltip(
      <Grid item xs={4}>
        <FormControl fullWidth className="SwitchControl" style={{ marginTop: 10 }}>
          <Switch
            checked={gameFormatOptions[fieldName] as boolean}
            onChange={onCheckboxChange}
            name={fieldName}
            value="true"
            color="primary"
            disabled={disabled}
          />
          <FormLabel className="SwitchLabel">
            <FormattedMessageWrapper id={fieldData.label} values={{ holeCount }} />
          </FormLabel>
        </FormControl>
      </Grid>,
      fieldData.description,
    )
  }

  private _renderNumeric(fieldData, fieldName) {
    const { onChange, gameFormatOptions, holeCount, disabled } = this.props

    return this._renderMaybeWithTooltip(
      <Grid item xs={4}>
        <FormControl fullWidth>
          <FormLabel>
            <FormattedMessageWrapper id={fieldData.label} values={{ holeCount }} />
          </FormLabel>
          <TextField
            type="number"
            required={true}
            margin="normal"
            variant={'outlined'}
            name={fieldName}
            onChange={onChange}
            value={gameFormatOptions[fieldName]}
            disabled={disabled}
          />
        </FormControl>
      </Grid>,
      fieldData.description,
    )
  }

  private _renderSelect(fieldData, fieldName) {
    const { onSelectChange, gameFormatOptions, holeCount, disabled } = this.props
    const value = gameFormatOptions[fieldName]
    const gameOptions = fieldData.options ?? fieldData.optionKeys

    return this._renderMaybeWithTooltip(
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormLabel htmlFor="options">
            <FormattedMessageWrapper id={fieldData.label} values={{ holeCount }} />
          </FormLabel>
          <Select
            value={value}
            onChange={onSelectChange}
            name={fieldName}
            disabled={disabled}
            input={<OutlinedInput name={fieldName} id={fieldName} />}
          >
            {gameOptions.map((option, index) => (
              <MenuItem value={option} key={index}>
                {this._renderOption(option, fieldData)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>,
      fieldData.description,
    )
  }

  private _renderOption = (option: any, fieldData: any) => {
    if (fieldData.optionKeys) {
      return <FormattedMessageWrapper id={fieldData.label + '_' + option} />
    }
    return option
  }

  private _renderMaybeWithTooltip = (component: any, description?: string) => {
    if (!description) {
      return component
    }

    return (
      <GameFormatTooltip title={description} placement={'right'}>
        {component}
      </GameFormatTooltip>
    )
  }
}

export default GameFormatAdditionalInput
