import { store } from '@app/store'
import { getSelectedClub } from './clubsAndCoursesUtils'

export const getRoundStartTime = (round: Round): number => {
  let club = getSelectedClub(store.getState())
  if (!club?.id) {
    club = round.club
  }
  const timeZone = club?.timeZone || 'GMT'
  const startTime = new Date(round.startTime).toLocaleString('en-US', { timeZone })
  return new Date(startTime).getTime() / 1000
}
