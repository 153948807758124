import React from 'react'
import { connect } from 'react-redux'
import {
  addOrganizationLicense,
  AddOrganizationLicensePayload,
  addOrganizationSubscription,
  AddOrganizationSubscriptionPayload,
} from '@app/store/organization/actions'
import Dialog from '@mui/material/Dialog'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { DialogContent, Button, Theme, Typography, Grid, Paper, Breakpoint } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogActions from '@mui/material/DialogActions'
import { withStyles, WithStyles } from '@mui/styles'
import classNames from 'classnames'
import { LicenseAddView } from './LicenseAddView'
import { LicenseSubscriptionHistoryView } from './LicenseSubscriptionHistoryView'
import { SubscriptionAddEditView } from './SubscriptionAddEditView'
import { formatDayUTC } from '@app/utils/dates'
import { RootState } from '@app/store'

export enum DialogView {
  VIEW_STATUS,
  VIEW_MANAGE_LICENSES,
  VIEW_MANAGE_SUBSCRIPTION,
  VIEW_LICENSE_HISTORY,
  VIEW_SUBSCRIPTION_HISTORY,
}
interface OwnProps {
  open: boolean
  organization?: OrganizationsItem
  onUpdate(): void
  onClose(): void
}

interface DispatchProps {
  addOrganizationLicense(args: AddOrganizationLicensePayload, onSuccess: () => void): void
  addOrganizationSubscription(args: AddOrganizationSubscriptionPayload, onSuccess: () => void): void
}

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    title: {
      color: theme.customPalette.dark,
      fontSize: 20,
    },
    subtitle: {
      fontSize: 22,
      fontStyle: 'normal',
      fontColor: theme.customPalette.black,
    },
    paper: {
      margin: '35px 25px 15px 25px',
      padding: '30px 0 40px 0',
    },
    paperLeft: {
      marginRight: 5,
    },
    paperRight: {
      marginLeft: 5,
    },
    paperContent: {
      marginBottom: 40,
    },
    subscriptionInfoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    active: {
      color: theme.customPalette.darkGreen,
      margin: '15px 0 15px 0',
    },
    licenseCount: {
      color: theme.customPalette.darkGreen,
    },
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      alignItems: 'center',
      height: 170,
    },
  })

interface State {
  activeView: DialogView
  licenseCount?: number
  licenseDelta: number
  validFrom?: Date
  validTo?: Date
  notes: string
  subscriptionValidFrom?: Date
  subscriptionValidTo?: Date
}

const initialState: State = {
  activeView: DialogView.VIEW_STATUS,
  licenseCount: undefined,
  licenseDelta: 0,
  validFrom: new Date(),
  validTo: new Date(),
  notes: '',
  subscriptionValidFrom: undefined,
  subscriptionValidTo: undefined,
}

class LicenseSubscriptionStatusDialog extends React.Component<Props, State> {
  readonly state: State = initialState

  componentDidUpdate() {
    const { organization } = this.props
    const { licenseCount, subscriptionValidFrom, subscriptionValidTo } = this.state

    if (licenseCount === undefined && organization?.licenseCount !== undefined) {
      this.setState({ licenseCount: organization?.licenseCount || 0 })
    }

    if (subscriptionValidFrom === undefined && organization?.subscriptionValidFrom !== undefined) {
      this.setState({ subscriptionValidFrom: organization?.subscriptionValidFrom })
    }

    if (subscriptionValidTo === undefined && organization?.subscriptionValidTo !== undefined) {
      this.setState({ subscriptionValidTo: organization?.subscriptionValidTo })
    }
  }

  render() {
    const { open = false, classes, organization } = this.props

    return (
      <Dialog maxWidth={this.dialogWidth()} fullWidth open={open} aria-labelledby="tournament-notes-title">
        <BaseDialogTitle
          id="tournament-notes-title"
          title={
            <Typography variant="h1" className={classes.title}>
              <FormattedMessageWrapper
                id={this.dialogTitleId()}
                values={{ clubName: organization?.nameMarketing || '' }}
              />
            </Typography>
          }
          onClose={() => this.handleClose()}
        />
        <DialogContent>{this.renderDialogContent()}</DialogContent>
        <DialogActions>{this.renderDialogActions()}</DialogActions>
      </Dialog>
    )
  }

  private dialogWidth(): Breakpoint {
    const { activeView } = this.state

    if (activeView === DialogView.VIEW_MANAGE_LICENSES || activeView === DialogView.VIEW_MANAGE_SUBSCRIPTION) {
      return 'xs'
    } else {
      return 'md'
    }
  }

  private dialogTitleId(): string {
    const { activeView } = this.state

    switch (activeView) {
      case DialogView.VIEW_MANAGE_LICENSES:
        return 'organizations.manageLicensesTitle'
      case DialogView.VIEW_MANAGE_SUBSCRIPTION:
        return 'organizations.manageSubscriptionTitle'
      case DialogView.VIEW_LICENSE_HISTORY:
        return 'organizations.licenseHistory'
      case DialogView.VIEW_SUBSCRIPTION_HISTORY:
        return 'organizations.subscriptionHistory'
      case DialogView.VIEW_STATUS:
      default:
        return 'organizations.licenseSubscriptionsTitle'
    }
  }

  private renderDialogContent() {
    const { organization } = this.props
    const { activeView, validFrom, validTo } = this.state

    switch (activeView) {
      case DialogView.VIEW_MANAGE_LICENSES:
        return (
          <LicenseAddView
            handleShowHistory={() => this.setState({ activeView: DialogView.VIEW_LICENSE_HISTORY })}
            updateLicenseDeltaCallback={(c) => {
              this.setState({ licenseDelta: c })
            }}
            updateNotesCallback={(n) => {
              this.setState({ notes: n })
            }}
          />
        )
      case DialogView.VIEW_MANAGE_SUBSCRIPTION:
        return (
          <SubscriptionAddEditView
            handleShowSubscriptionHistory={() => this.setState({ activeView: DialogView.VIEW_SUBSCRIPTION_HISTORY })}
            updateValidFromCallback={(c) => {
              this.setState({ validFrom: c })
            }}
            updateValidToCallback={(c) => {
              this.setState({ validTo: c })
            }}
            updateNotesCallback={(n) => {
              this.setState({ notes: n })
            }}
            initialValidFrom={validFrom}
            initialValidTo={validTo}
          />
        )
      case DialogView.VIEW_LICENSE_HISTORY:
      case DialogView.VIEW_SUBSCRIPTION_HISTORY:
        return <LicenseSubscriptionHistoryView organizationId={organization?.idCustomer} activeView={activeView} />
    }

    return this.renderMainContent()
  }

  private renderDialogActions() {
    const { activeView, validFrom, validTo } = this.state

    switch (activeView) {
      case DialogView.VIEW_MANAGE_LICENSES:
      case DialogView.VIEW_MANAGE_SUBSCRIPTION:
        return (
          <>
            <Button color="primary" variant="outlined" onClick={() => this.handleCancel()}>
              <FormattedMessageWrapper id="buttons.cancel" />
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleSave()}
              disabled={!validFrom || !validTo}
            >
              <FormattedMessageWrapper id="buttons.save" />
            </Button>
          </>
        )
      case DialogView.VIEW_LICENSE_HISTORY:
      case DialogView.VIEW_SUBSCRIPTION_HISTORY:
        return (
          <Button color="primary" variant="contained" onClick={() => this.handleReturn()}>
            <FormattedMessageWrapper id="buttons.return" />
          </Button>
        )
    }

    return <></>
  }

  private handleManageSubscription() {
    const { subscriptionValidFrom, subscriptionValidTo } = this.state
    const subscriptionValidFromDate = new Date(subscriptionValidFrom || '')
    const subscriptionValidToDate = new Date(subscriptionValidTo || '')

    this.setState({
      validFrom:
        subscriptionValidFrom !== undefined
          ? new Date(
              subscriptionValidFromDate.getUTCFullYear(),
              subscriptionValidFromDate.getUTCMonth(),
              subscriptionValidFromDate.getUTCDate(),
            )
          : new Date(),
      validTo:
        subscriptionValidTo !== undefined
          ? new Date(
              subscriptionValidToDate.getUTCFullYear(),
              subscriptionValidToDate.getUTCMonth(),
              subscriptionValidToDate.getUTCDate(),
            )
          : new Date(),
      notes: '',
      activeView: DialogView.VIEW_MANAGE_SUBSCRIPTION,
    })
  }

  private handleManageLicenses() {
    this.setState({
      licenseDelta: 0,
      notes: '',
      activeView: DialogView.VIEW_MANAGE_LICENSES,
    })
  }

  private handleSave() {
    const { onUpdate, addOrganizationLicense, addOrganizationSubscription } = this.props
    const { activeView, licenseCount, licenseDelta, validFrom, validTo, notes } = this.state

    if (this.props.organization !== undefined) {
      switch (activeView) {
        case DialogView.VIEW_MANAGE_LICENSES:
          if (licenseCount !== undefined && (licenseDelta !== 0 || notes !== '')) {
            let newLicenseCount = licenseCount + licenseDelta
            if (newLicenseCount < 0) {
              newLicenseCount = 0
            }
            const payload: AddOrganizationLicensePayload = {
              organizationId: this.props.organization.idCustomer,
              licenseCount: newLicenseCount,
              notes: notes,
            }
            addOrganizationLicense(payload, () => {
              onUpdate()
              this.setState({
                licenseCount: newLicenseCount,
              })
            })
          }
          break
        case DialogView.VIEW_MANAGE_SUBSCRIPTION:
          if (validFrom !== undefined && validFrom !== null && validTo !== undefined && validTo !== null) {
            const validFromNormalized = new Date(
              Date.UTC(validFrom?.getFullYear(), validFrom?.getMonth(), validFrom?.getDate(), 0, 0, 0),
            )
            const validToNormalized = new Date(
              Date.UTC(validTo?.getFullYear(), validTo?.getMonth(), validTo?.getDate(), 23, 59, 59),
            )
            const payload: AddOrganizationSubscriptionPayload = {
              organizationId: this.props.organization.idCustomer,
              validFrom: validFromNormalized,
              validTo: validToNormalized,
              notes: notes,
            }
            addOrganizationSubscription(payload, () => {
              onUpdate()
              this.setState({
                subscriptionValidFrom: validFromNormalized,
                subscriptionValidTo: validToNormalized,
              })
            })
          }
          break
      }

      this.setState({
        activeView: DialogView.VIEW_STATUS,
      })
    }
  }

  private handleCancel() {
    const { organization } = this.props
    this.setState({
      activeView: DialogView.VIEW_STATUS,
      licenseCount: organization?.licenseCount || 0,
    })
  }

  private handleReturn() {
    const { activeView } = this.state
    this.setState({
      activeView:
        activeView === DialogView.VIEW_LICENSE_HISTORY
          ? DialogView.VIEW_MANAGE_LICENSES
          : DialogView.VIEW_MANAGE_SUBSCRIPTION,
    })
  }

  private handleClose() {
    const { onClose } = this.props

    this.setState({
      activeView: DialogView.VIEW_STATUS,
      licenseCount: undefined,
      subscriptionValidFrom: undefined,
      subscriptionValidTo: undefined,
    })

    if (onClose) {
      onClose()
    }
  }

  private isActiveLicenses(): boolean {
    const { licenseCount } = this.state
    return licenseCount !== undefined && licenseCount > 0
  }

  private isActiveSubscription(): boolean {
    const { subscriptionValidTo } = this.state
    return (
      subscriptionValidTo !== undefined &&
      subscriptionValidTo !== null &&
      new Date(subscriptionValidTo).getMilliseconds() < Date.now()
    )
  }

  private renderMainContent() {
    const { classes } = this.props
    const { subscriptionValidTo } = this.state
    return (
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper elevation={7} className={classNames([classes.paper, classes.paperLeft])}>
            <div className={classes.wrap}>
              <Typography variant="h3" className={classes.subtitle}>
                <FormattedMessageWrapper id="organizations.subscription" />
              </Typography>
              <div className={classes.paperContent}>
                {this.isActiveSubscription() ? (
                  <div className={classes.subscriptionInfoContainer}>
                    <div className={classes.active}>
                      <FormattedMessageWrapper id="organizations.activeSubscription" />
                    </div>
                    <div>
                      <FormattedMessageWrapper
                        id="organizations.validUntil"
                        values={{ validTo: formatDayUTC(new Date(subscriptionValidTo || '')) || '' }}
                      />
                    </div>
                  </div>
                ) : (
                  <FormattedMessageWrapper id="organizations.noActiveSubscription" />
                )}
              </div>
              <Button variant="contained" color="primary" size="medium" onClick={() => this.handleManageSubscription()}>
                <FormattedMessageWrapper id="buttons.manage" />
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={7} className={classNames([classes.paper, classes.paperRight])}>
            <div className={classes.wrap}>
              <Typography variant="h3" className={classes.subtitle}>
                <FormattedMessageWrapper id="organizations.licenses" />
              </Typography>
              <div className={classes.paperContent}>
                {this.isActiveLicenses() ? (
                  <>
                    <span className={classes.licenseCount}>{this.state.licenseCount}</span>
                    &nbsp;
                    <FormattedMessageWrapper id="organizations.activeLicenses" />
                  </>
                ) : (
                  <FormattedMessageWrapper id="organizations.noActiveLicenses" />
                )}
              </div>
              <Button variant="contained" color="primary" size="medium" onClick={() => this.handleManageLicenses()}>
                <FormattedMessageWrapper id="buttons.manage" />
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default connect<{}, DispatchProps, OwnProps, RootState>(null, {
  addOrganizationLicense,
  addOrganizationSubscription,
})(withStyles(styles)(LicenseSubscriptionStatusDialog as any))
