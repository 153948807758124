import { all, spawn } from 'redux-saga/effects'
import { watchAuthentication } from './authentication/sagas'
import { watchTournaments } from './tournaments/sagas'
import { watchPlayers } from './players/sagas'
import { watchGameFormats } from './gameFormats/sagas'
import { watchOrganization } from './organization/sagas'
import { watchOrganizations } from './organizations/sagas'
import { watchOrganizationUsers } from './organizationUsers/sagas'
import { watchOrganizationUser } from './organizationUser/sagas'
import { watchTournamentLeaderboard } from './tournamentLeaderboard/sagas'
import { watchTourAndRanking } from './tourAndRanking/sagas'
import { watchDivisions } from './divisions/sagas'
import { watchHcpRounds } from './hcpRounds/sagas'
import { watchPayments } from './payments/sagas'
import { watchTimeZones } from './timeZones/sagas'
import { watchTournamentDivisionLeaderboard } from './tournamentDivisionLeaderboard/sagas'
import { watchReports } from './reports/sagas'

export default function* sagas() {
  yield all([
    spawn(watchAuthentication),
    spawn(watchTournaments),
    spawn(watchPlayers),
    spawn(watchGameFormats),
    spawn(watchOrganization),
    spawn(watchOrganizations),
    spawn(watchOrganizationUsers),
    spawn(watchOrganizationUser),
    spawn(watchTournamentLeaderboard),
    spawn(watchTourAndRanking),
    spawn(watchDivisions),
    spawn(watchHcpRounds),
    spawn(watchPayments),
    spawn(watchTimeZones),
    spawn(watchTournamentDivisionLeaderboard),
    spawn(watchReports),
  ])
}
