import React from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { DragIndicator } from '@mui/icons-material'
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { PlayerDialogChildrenArgs } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import createStyles from '@mui/styles/createStyles'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { getCustomQuestionIdMap } from '@app/store/api/selectors/tournamentSiteSelectors'
import { rem } from '@theme/materialUITheme'
import { getExtraInfo } from './ExtraInfoSelect'
import { formatHandicap } from '@app/utils/playerUtils'
import { RootState } from '@app/store'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

export const PLAYER_LIST_ITEM_HEIGHT = 28
const PLAYER_LIST_ITEM_MARGIN = 4
export const PLAYER_LIST_ITEM_HEIGHT_TOTAL = PLAYER_LIST_ITEM_MARGIN + PLAYER_LIST_ITEM_HEIGHT

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontSize: rem(13),
      height: PLAYER_LIST_ITEM_HEIGHT,
      background: theme.customPalette.bodyBackground,
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    dragBlock: {
      width: 30,
      color: theme.customPalette.mediumGray2,
    },
    removeBlock: {
      textAlign: 'right',
    },
    text: {
      color: 'inherit',
      fontSize: 'inherit',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    descText: {
      color: 'inherit',
      fontSize: 'inherit',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    playerName: {
      color: 'inherit',
      fontSize: 'inherit',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
    },
    playerStatus: {
      color: 'red',
      fontSize: 'inherit',
      whiteSpace: 'pre',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    gridItem: {
      cursor: 'pointer',
      overflow: 'hidden',
      paddingRight: '2px',
    },
    removePlayer: {
      textAlign: 'right',
      paddingRight: theme.spacing(1),
    },
    removeIcon: {
      color: theme.palette.action.active,
      fontSize: rem(24),
      width: '1em',
      height: '1em',
      overflow: 'hidden',
    },
    sliceHazard: {
      width: 16,
      height: 15,
      marginRight: 5,
      verticalAlign: 'text-bottom',
    },
    containerWhileDragging: {
      color: theme.palette.primary.main,
    },
    disableAnimation: {
      transform: 'none !important',
    },
  })

interface OwnProps {
  index: number
  tournamentPlayer: TournamentPlayer
  showAdd?: boolean
  showTee?: boolean
  confirmPlayerRemove?: boolean
  openPlayerDialog: (player: TournamentPlayer) => () => void
  hideDragHandle?: boolean
  hideDeleteIcon?: boolean
  team?: TournamentTeam
  showPHCP?: boolean
  addAction?: (playerId: number) => void
  removeAction?: (playerId: number) => void
  trashIcon?: boolean
  usedByComponent: string
  animationDisabled?: boolean
}

interface StateProps {
  playerExtraInfo: string
  customQuestionsIdMap: { [questionId: number]: Question }
  units: OrganizationUnits
  appLanguageCode: string
  selectedRoundId: number
  divisions: DivisionState[]
}

type Props = OwnProps & StateProps & WrappedComponentProps & PlayerDialogChildrenArgs & WithStyles<typeof styles>

class ListPlayer extends React.PureComponent<Props> {
  public render() {
    const {
      tournamentPlayer,
      selectedRoundId,
      classes,
      index,
      units,
      usedByComponent,
      hideDragHandle,
      hideDeleteIcon,
      animationDisabled,
      playerExtraInfo,
      customQuestionsIdMap,
      divisions,
    } = this.props

    if (!tournamentPlayer) {
      return null
    }

    const extraInfo = getExtraInfo(tournamentPlayer, playerExtraInfo, customQuestionsIdMap, units, divisions)

    const getStyle = (style: any, snapshot: DraggableStateSnapshot) => {
      if (!snapshot.isDropAnimating) {
        return style
      }
      return {
        ...style,
        // cannot be 0, but make it super tiny
        transitionDuration: `0.001s`,
      }
    }

    const renderGridType = (dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
      if (dragSnapshot.isDragging) {
        return (
          <Grid container className={classes.root}>
            <Grid item xs={1} className={classNames(classes.dragBlock, classes.gridItem)}>
              <div style={{ display: 'flex' }} {...dragProvided.dragHandleProps}>
                <DragIndicator />
              </div>
            </Grid>
            <Grid item xs={11} className={classes.gridItem}>
              <Typography className={classes.playerName}>
                {tournamentPlayer.firstName} {tournamentPlayer.lastName}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      switch (usedByComponent) {
        case 'StartGroupPlayerItems': {
          const currentRoundPlayer =
            tournamentPlayer.startListPlayers && tournamentPlayer.startListPlayers[selectedRoundId]

          return (
            <Grid container className={classes.root}>
              {!hideDragHandle && (
                <Grid item xs={1}>
                  <div style={{ display: 'flex' }} {...dragProvided.dragHandleProps}>
                    <DragIndicator />
                  </div>
                </Grid>
              )}
              <Grid item xs={6} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName} style={{ paddingLeft: hideDragHandle ? '24px' : 'auto' }}>
                  {tournamentPlayer.firstName} {tournamentPlayer.lastName}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.text}>
                  {currentRoundPlayer && formatHandicap(String(currentRoundPlayer.hcp))}
                  <span>/</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {currentRoundPlayer && formatHandicap(String(currentRoundPlayer.playingHandicap))}
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                className={classes.gridItem}
                onClick={this.props.openPlayerDialog(tournamentPlayer)}
                style={{ textAlign: 'center' }}
              >
                <Typography className={classes.text}>
                  {currentRoundPlayer && currentRoundPlayer.teeBox && currentRoundPlayer.teeBox?.teeboxName}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.removePlayer}>
                {!hideDeleteIcon && (
                  <IconButton size="small" className={classes.removeIcon} onClick={this._removePlayerFromPool}>
                    &times;
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )
        }

        case 'Team':
          return (
            <Grid container className={classes.root}>
              <Grid item xs={1} className={classNames(classes.dragBlock, classes.gridItem)}>
                <div style={{ display: 'flex' }} {...dragProvided.dragHandleProps}>
                  <DragIndicator />
                </div>
              </Grid>
              <Grid item xs={8} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName}>
                  {tournamentPlayer.firstName} {tournamentPlayer.lastName}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.text}>{formatHandicap(String(tournamentPlayer.hcp))}</Typography>
              </Grid>
              <Grid item xs={1} className={classes.removePlayer}>
                <IconButton size="small" className={classes.removeIcon} onClick={this._removePlayerFromPool}>
                  &times;
                </IconButton>
              </Grid>
            </Grid>
          )

        case 'TeamPool':
          return (
            <Grid container className={classes.root}>
              <Grid item xs={5} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName} style={{ paddingLeft: '24px' }}>
                  {tournamentPlayer.firstName} {tournamentPlayer.lastName}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.text}>{formatHandicap(String(tournamentPlayer.hcp))}</Typography>
              </Grid>
              <Grid item xs={5} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName}>{extraInfo}</Typography>
              </Grid>
            </Grid>
          )

        case 'DroppedOutPlayersInnerList':
          return (
            <Grid container className={classes.root}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName}>{tournamentPlayer.firstName}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName}>{tournamentPlayer.lastName}</Typography>
              </Grid>
              <Grid item xs={1} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.text}>{formatHandicap(String(tournamentPlayer.hcp))}</Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName}>{tournamentPlayer.preferredTeeBox?.teeboxName}</Typography>
              </Grid>
              <Grid item xs={2} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerStatus}>{tournamentPlayer.status}</Typography>
              </Grid>
            </Grid>
          )

        case 'UnassignedPlayersInnerList':
        default:
          return (
            <Grid container className={classes.root}>
              {!hideDragHandle && (
                <Grid item xs={1} className={classNames(classes.dragBlock, classes.gridItem)}>
                  <div style={{ display: 'flex' }} {...dragProvided.dragHandleProps}>
                    <DragIndicator />
                  </div>
                </Grid>
              )}
              <Grid
                item
                xs={3}
                className={classes.gridItem}
                style={{ marginLeft: hideDragHandle ? '2px' : 'auto' }}
                onClick={this.props.openPlayerDialog(tournamentPlayer)}
              >
                <Typography className={classes.playerName}>{tournamentPlayer.firstName}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName}>{tournamentPlayer.lastName}</Typography>
              </Grid>
              <Grid item xs={1} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.text}>{formatHandicap(String(tournamentPlayer.hcp))}</Typography>
              </Grid>
              <Grid item xs={3} className={classes.gridItem} onClick={this.props.openPlayerDialog(tournamentPlayer)}>
                <Typography className={classes.playerName}>{extraInfo}</Typography>
              </Grid>
              <Grid item xs={1} className={classes.removePlayer}>
                {!hideDeleteIcon && (
                  <IconButton size="small" className={classes.removeIcon} onClick={this._removePlayerFromPool}>
                    &times;
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )
      }
    }

    return (
      <Draggable draggableId={`player-${tournamentPlayer.userId}`} index={index} isDragDisabled={hideDragHandle}>
        {(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => (
          <div
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            key={index}
            style={getStyle(dragProvided.draggableProps.style, dragSnapshot)}
            className={classNames([
              animationDisabled && !dragSnapshot.isDragging ? classes.disableAnimation : undefined,
              dragSnapshot.isDragging ? classes.containerWhileDragging : undefined,
            ])}
          >
            {renderGridType(dragProvided, dragSnapshot)}
          </div>
        )}
      </Draggable>
    )
  }

  private _removePlayerFromPool = () => {
    const { removeAction } = this.props
    if (removeAction) {
      removeAction(this.props.tournamentPlayer.id)
    }
  }
}

const ConnectedListPlayer = connect<StateProps, {}, OwnProps, RootState>((state) => ({
  units: state.authenticationReducer.units,
  playerExtraInfo: selectTournamentConfig(state).playerExtraInfo,
  customQuestionsIdMap: getCustomQuestionIdMap(state),
  appLanguageCode: state.localeReducer.appLanguage.code,
  selectedRoundId: selectTournamentConfig(state).selectedRoundId,
  divisions: state.divisionsReducer.divisions,
}))(ListPlayer)

export default injectIntl(withStyles(styles)(ConnectedListPlayer))
