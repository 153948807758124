import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Grid, TextField, Collapse, FormControl, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import SectionTitle from '@components/ui/SectionTitle'
import SectionTitleWithCollapse from '@components/ui/SectionTitleWithCollapse'
import BorderButton from '@components/ui/BorderButton'
import InfoTooltip from '@components/ui/InfoTooltip'
import { addTempPlayer } from '@store/players/actions'
import { isEmailValid } from '@utils/validateEmail'
import { RootState, useAppDispatch } from '@app/store'
import { CacheTag } from '@app/store/api/cacheTags'
import { api } from '@app/store/api/baseApi'
import { FI_COUNTRY_ID, SV_COUNTRY_ID } from '@app/config'

const useStyles = makeStyles(() => ({
  actionContainer: {
    alignSelf: 'flex-end',
  },
  infoTooltip: {
    marginLeft: 10,
  },
}))

export const AddPlayerByEmail: React.FC = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const { isClub, countryId } = useSelector((state: RootState) => state.organizationReducer)
  const isFinClub = isClub && countryId === FI_COUNTRY_ID
  const isSweClub = isClub && countryId === SV_COUNTRY_ID

  const handleSubmit = () => {
    dispatch(addTempPlayer({ email }, handleComplete))
  }

  const handleComplete = (success, error) => {
    if (error) {
      return
    }

    setEmail('')
    dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_PLAYERS]))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const getInfoText = () => {
    const globalText = <FormattedMessageWrapper id="tournament.addPlayerByEmailInfo" />
    if (isFinClub) {
      return (
        <>
          {globalText}
          <br />
          <br />
          <FormattedMessageWrapper id="tournament.addPlayerByEmailFinland" />
        </>
      )
    }

    if (isSweClub) {
      return (
        <>
          {globalText}
          <br />
          <br />
          <FormattedMessageWrapper id="tournament.addPlayerByEmailSweden" />
        </>
      )
    }

    return globalText
  }

  const renderSectionContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs>
          <FormControl fullWidth>
            <TextField
              tabIndex={5}
              name="email"
              type="email"
              id="email"
              variant="outlined"
              margin="none"
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'tournament.emailPlaceholder' })}
              value={email}
              inputProps={{ 'data-testid': 'add-player-email' }}
            />
          </FormControl>
        </Grid>

        <Grid item xs className={classes.actionContainer}>
          <BorderButton
            filled
            buttonProps={{
              onClick: handleSubmit,
              disabled: isEmailValid(email) === false,
              style: { padding: '10px 31px' },
              'data-testid': 'add-player-button',
            }}
          >
            <FormattedMessageWrapper id={'buttons.add'} />
          </BorderButton>
        </Grid>
        <Grid item xs={12}>
          {expanded && <Divider />}
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <SectionTitleWithCollapse expanded={expanded} onChange={(expanded) => setExpanded(expanded)}>
        <SectionTitle>
          <FormattedMessageWrapper id="tournament.addPlayersByEmail" />
          <InfoTooltip text={getInfoText()} className={classes.infoTooltip} />
        </SectionTitle>
      </SectionTitleWithCollapse>
      <Collapse in={expanded}>{renderSectionContent()}</Collapse>
    </>
  )
}
