import React from 'react'
import { connect } from 'react-redux'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogContent from '@mui/material/DialogContent'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import { RootState } from '@app/store'
import { tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'

const styles = () =>
  createStyles({
    temporary: {
      backgroundColor: 'rgba(171, 163, 163, 0.1)',
    },
  })

interface OwnProps {
  open?: boolean
  onClose?(): void
  preview?: ImportPlayerPreviews
  excelFile?: File
}

interface StateProps {
  tournamentId?: number
}

interface DispatchProps {
  importPlayersExcel(payload: TournamentPlayerExcelImportPayload): void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>

interface State {
  loading: boolean
}

class PlayerImportPreviewDialog extends React.Component<Props, State> {
  readonly state: State = {
    loading: false,
  }

  render() {
    const { open = false } = this.props

    return (
      <Dialog maxWidth="md" fullWidth open={open} aria-labelledby={'player-import-dialog-title'}>
        <BaseDialogTitle
          id={'player-import-dialog-title'}
          title={<FormattedMessageWrapper id={'tournament.playerImportPreview'} />}
          subtitle={this._subtitle}
        />
        <DialogContent>
          {this._hasProblematic && (
            <Typography>
              <FormattedMessageWrapper id={'tournament.problematicHelpText'} />
            </Typography>
          )}
          <Table>{this._hasProblematic ? this._renderProblematic() : this._renderPlayers()}</Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._handleClose} disabled={this.state.loading}>
            <FormattedMessageWrapper id={'buttons.close'} />
          </Button>
          <div>
            <ButtonLoaderWrap loading={this.state.loading}>
              <Button
                disabled={this._isImportDisabled}
                color="primary"
                variant={'contained'}
                onClick={this._onClickSave}
              >
                <FormattedMessageWrapper id={'buttons.import'} />
              </Button>
            </ButtonLoaderWrap>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  public _onClickSave = () => {
    const { excelFile, tournamentId, importPlayersExcel } = this.props

    if (tournamentId && excelFile) {
      this.setState({ loading: true }, () =>
        importPlayersExcel({
          tournamentId,
          file: excelFile,
          onSuccess: this._handleClose,
        }),
      )
    }
  }

  private _handleClose = (): void => {
    this.setState({ loading: false }, () => {
      const { onClose } = this.props
      if (onClose) {
        onClose()
      }
    })
  }

  public get _isImportDisabled() {
    const { loading } = this.state
    return loading || this._hasProblematic || this._players.length === 0
  }

  private get _subtitle() {
    if (this._hasProblematic) {
      return (
        <Typography color={'error'}>
          <FormattedMessageWrapper
            id={'tournament.foundProblematicPlayersCount'}
            values={{ count: this._problematic.length }}
          />
        </Typography>
      )
    }

    return <FormattedMessageWrapper id={'tournament.foundPlayersCount'} values={{ count: this._players.length }} />
  }

  private get _players() {
    const { preview } = this.props

    if (preview) {
      return preview.players
    }

    return []
  }

  private get _hasProblematic() {
    return this._problematic.length > 0
  }

  private get _problematic() {
    const { preview } = this.props

    if (preview) {
      return preview.problematic
    }

    return []
  }

  private _renderPlayers = () => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessageWrapper id={'tournament.firstName'} />
            </TableCell>
            <TableCell>
              <FormattedMessageWrapper id={'tournament.lastName'} />
            </TableCell>
            <TableCell>
              <FormattedMessageWrapper id={'tournament.hcp'} />
            </TableCell>
            <TableCell>
              <FormattedMessageWrapper id={'tournament.gender'} />
            </TableCell>
            <TableCell>
              <FormattedMessageWrapper id={'tournament.email'} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this._playerTableRows}</TableBody>
      </>
    )
  }

  private get _playerTableRows() {
    return this._players.map(({ firstName, lastName, handicap, gender, temporary, email }, idx) => (
      <TableRow key={`${firstName}${lastName}${idx}`} className={temporary ? this.props.classes.temporary : ''}>
        <TableCell>{firstName}</TableCell>
        <TableCell>{lastName}</TableCell>
        <TableCell>{handicap}</TableCell>
        <TableCell>{gender}</TableCell>
        <TableCell>{email}</TableCell>
      </TableRow>
    ))
  }

  private _renderProblematic = () => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessageWrapper id={'tournament.fileRow'} />
            </TableCell>
            <TableCell>
              <FormattedMessageWrapper id={'tournament.validation'} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this._problematicRows}</TableBody>
      </>
    )
  }

  public get _problematicRows() {
    return this._problematic.map(({ row, reason }, idx) => (
      <TableRow key={`${row}${idx}`}>
        <TableCell>{row}</TableCell>
        <TableCell>{reason}</TableCell>
      </TableRow>
    ))
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (store) => ({
      tournamentId: getTournamentId(store),
    }),
    {
      importPlayersExcel: tournamentPlayersApi.endpoints.importPlayers.initiate,
    },
  )(PlayerImportPreviewDialog),
)
