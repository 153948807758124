//LIBRARIES
import React, { RefObject } from 'react'
import { Grid, Typography, Theme, Collapse, SelectChangeEvent } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { InfoOutlined } from '@mui/icons-material'

//COMPONENTS
import ContentWrap from '@components/layout/ContentWrap'
import { SectionWrapper } from '@app/components/layout/SectionWrapper'
import TournamentFormActions from '@components/tournament/TournamentFormActions'
import { scrollToTop } from '@components/ui'
import HeadHelmet from '@components/layout/HeadHelmet'
import SiteHome from '@components/tournament/SiteHome'
import SiteSummary from '@components/tournament/SiteSummary'
import {
  TournamentEnabledSwitch,
  CountryFlagsSwitch,
  NameDisplayModeDropdown,
  TournamentSiteActionButtons,
} from './TournamentSiteComponents'

//ACTIONS
import { updateTournamentSiteField } from '@app/store/api/thunks/tournamentSiteThunks'
import { selectTournamentSite, tournamentSiteApi } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

//OTHER
import { getNextUrl } from '@utils/nextUrlHelper'
import { rem } from '@theme/materialUITheme'
import { UrlParams } from '@app/store/api/enums/tournamentEnums'
import { RootState } from '@app/store'
import { setTournamentNeedsUpdate } from '@store/api/slices/configSlice'
import { YesOrNo } from '@app/config'

const styles = (theme: Theme) =>
  createStyles({
    notification: {
      display: 'flex',
      flexDirection: 'row',
      border: `${rem(2)} solid ${theme.palette.primary.main}`,
      color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.palette.primary.contrastText,
      padding: theme.spacing(1),
      '@global': {
        '> div': {
          margin: theme.spacing(1),
        },
        '> svg': {
          margin: theme.spacing(1),
          fontSize: '2.3rem',
        },
      },
    },
    leftMargin: {
      marginLeft: theme.spacing(2),
    },
    bottomMargin: {
      marginBottom: theme.spacing(2),
    },
    topMargin: {
      marginTop: theme.spacing(2),
    },
    infoWrapper: {
      maxWidth: rem(650),
      paddingRight: theme.spacing(3),
    },
  })

interface StateProps {
  tournamentSite: TournamentSite
  tournament: TournamentState
}

interface DispatchProps {
  updateTournamentSiteField: (payload: FieldUpdatePayload) => void
  saveTournamentSite: (payload: TournamentSiteUpdatePayload) => void
  getTournamentSite: (id: number) => void
  setTournamentNeedsUpdate: (needsUpdate: boolean) => void
}

export type Props = StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps & WithRouterProps

interface State {
  siteId?: number
  editSummary: boolean
}

class UnconnectedTournamentSite extends React.Component<Props, State> {
  private summaryRef: RefObject<HTMLDivElement>
  readonly state: State = {
    siteId: undefined,
    editSummary: false,
  }

  constructor(props: Props) {
    super(props)
    this.updateTournamentStateSwitch = this.updateTournamentStateSwitch.bind(this)
    this.summaryRef = React.createRef()
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.tournamentSite.id !== state.siteId) {
      return {
        siteId: props.tournamentSite.id,
      }
    }

    return null
  }

  public componentDidMount(): void {
    const searchParams = new URLSearchParams(window.location.search)
    const isEditingSummary =
      searchParams.get(UrlParams.EDIT_SUMMARY) !== null && searchParams.get(UrlParams.EDIT_SUMMARY) !== undefined
    if (isEditingSummary) {
      this.setState({ editSummary: true })
      const summaryContainer = this.summaryRef.current
      summaryContainer?.scrollIntoView()
    }
    // Fetch tournament site info if it is not in store already
    const id = get(this.props.params, 'id')
    const paramsTournamentId = typeof id === 'string' ? parseInt(id, 10) : id
    const storeTournamentId = get(this.props.tournamentSite, 'tournamentId')
    if (!storeTournamentId || paramsTournamentId !== storeTournamentId) {
      this.props.getTournamentSite(paramsTournamentId)
    }
  }

  public render() {
    const {
      tournamentSite: { countryFlagForPlayersEnabled, enabled, nameDisplayMode },
      classes,
      tournament,
    } = this.props

    return (
      <SectionWrapper>
        <HeadHelmet titleId={'progress.tournamentSite'} />

        <ContentWrap>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TournamentEnabledSwitch
                tournamentSiteEnabled={enabled}
                onEnableTournamentSite={this.onEnableTournamentSite}
                classes={classes}
              />
            </Grid>
          </Grid>

          <Collapse in={!enabled}>
            <div className={classes.notification}>
              <InfoOutlined color={'primary'} />
              <div>
                <Typography variant={'body2'} className={classes.bottomMargin}>
                  <FormattedMessageWrapper id={'tournament.tournamentSiteDisabled'} />
                </Typography>
                <Typography variant={'body2'} className={classes.bottomMargin}>
                  <FormattedMessageWrapper id={'tournament.tournamentSiteDisabledInfo1'} />
                </Typography>
                <Typography variant={'body2'}>
                  <FormattedMessageWrapper id={'tournament.tournamentSiteDisabledInfo2'} />
                </Typography>
              </div>
            </div>
          </Collapse>

          <Collapse in={enabled} style={{ background: 'transparent', boxShadow: 'none', border: 'none' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className={classes.infoWrapper}>
                  <Typography variant={'body2'} className={classes.bottomMargin}>
                    <FormattedMessageWrapper id={'tournament.tournamentSiteDisabledInfo1'} />
                  </Typography>
                  <Typography variant={'body2'}>
                    <FormattedMessageWrapper id={'tournament.tournamentSiteDisabledInfo2'} />
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                {tournament.id && <TournamentSiteActionButtons id={tournament.id} />}
              </Grid>
              <Grid item xs={6}>
                <NameDisplayModeDropdown
                  onNameDisplayModeChange={this.onNameDisplayModeChange}
                  nameDisplayMode={nameDisplayMode}
                  classes={classes}
                />
                <CountryFlagsSwitch
                  updateTournamentStateSwitch={this.updateTournamentStateSwitch}
                  countryFlagForPlayersEnabled={countryFlagForPlayersEnabled}
                  classes={classes}
                />
              </Grid>
            </Grid>

            {this.renderExpansionPanelContents()}
          </Collapse>

          <TournamentFormActions
            nextLabelId={'buttons.branding'}
            onClickNext={this.clickNext}
            onClickSaveAndPublish={() => this.tournamentSiteSave()}
          />
        </ContentWrap>
      </SectionWrapper>
    )
  }

  private renderExpansionPanelContents = () => {
    return (
      <div className={this.props.classes.topMargin}>
        {/* Homepage */}
        <SiteHome tournamentSite={this.props.tournamentSite} tournament={this.props.tournament} />

        {/* Summary */}
        <SiteSummary
          tournamentSite={this.props.tournamentSite}
          tournament={this.props.tournament}
          open={this.state.editSummary}
          containerRef={this.summaryRef}
        />
      </div>
    )
  }

  private updateTournamentStateInput(e: React.ChangeEvent<any>) {
    this.props.updateTournamentSiteField({
      fieldName: e.target.name,
      value: e.target.value,
    })
  }

  private updateTournamentStateSwitch(e: SelectChangeEvent<HTMLSelectElement>) {
    this.props.updateTournamentSiteField({
      fieldName: e.target.name,
      value: e.target.value === YesOrNo.YES,
    })
  }

  private onEnableTournamentSite = (value: boolean) => {
    this.props.updateTournamentSiteField({
      fieldName: 'enabled',
      value,
    })
    this.props.setTournamentNeedsUpdate(true)
    const updatedSite = { ...this.props.tournamentSite, enabled: value }
    this.tournamentSiteSave({ updatedSite })
  }

  private onNameDisplayModeChange = (event) => {
    const { name } = event.target
    name && this.updateTournamentStateInput(event)
  }

  private clickNext = () => {
    this.tournamentSiteSave({
      onSuccess: () => {
        const nextUrl = getNextUrl(this.props.location.pathname, 'branding')
        this.props.navigate(nextUrl)
        scrollToTop()
      },
    })
  }

  private tournamentSiteSave = (params?: { onSuccess?: () => void; updatedSite?: TournamentSite }) => {
    const { onSuccess, updatedSite } = params || {}
    const body = updatedSite || this.props.tournamentSite
    this.props.saveTournamentSite({ id: this.props.tournament.id, body, onSuccess })
  }
}

export const TournamentSite = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => ({
    tournamentSite: selectTournamentSite(state),
    tournament: selectTournament(state),
  }),
  {
    updateTournamentSiteField,
    saveTournamentSite: tournamentSiteApi.endpoints.updateTournamentSite.initiate,
    getTournamentSite: tournamentSiteApi.endpoints.getTournamentSite.initiate,
    setTournamentNeedsUpdate,
  },
)(withStyles(styles)(withRouter(injectIntl(UnconnectedTournamentSite as any) as any)))
