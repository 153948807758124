import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { fetchReport } from '@app/store/payments/actions'
import { formatDateToIsoWithTimeZone, createDate } from '@app/utils/dates'
import { RootState } from '@app/store'

export interface PaymentProductsReportDialogProps {
  open: boolean
  closeReportsDialogFn(): void
  startTimestamp?: Date
  endTimestamp?: Date
}

interface ReportProps {
  startTimestamp: Date
  endTimestamp: Date
}

const initialState: ReportProps = {
  startTimestamp: new Date(),
  endTimestamp: createDate({ addDays: 7 }),
}

export const PaymentProductsReportDialog: React.FC<PaymentProductsReportDialogProps> = ({
  open,
  closeReportsDialogFn,
  startTimestamp,
  endTimestamp,
}) => {
  const [formData, setFormData] = React.useState<ReportProps>(initialState)

  // const intl = useIntl()
  const dispatch = useDispatch()
  const units = useSelector((state: RootState) => state.authenticationReducer.units)
  const timeZone = useSelector((state: RootState) => state.authenticationReducer.customerInfo?.club?.timeZone)

  const handleOnClose = () => {
    setFormData(initialState)
    closeReportsDialogFn()
  }

  const handleOnChangeStartTime = (date: any) => {
    setFormData({ ...formData, startTimestamp: date })
  }

  const handleOnChangeEndTime = (date: any) => {
    setFormData({ ...formData, endTimestamp: date })
  }

  const handleGetReport = () => {
    dispatch(
      fetchReport({
        startTimestamp: formatDateToIsoWithTimeZone(
          createDate({
            originalDate: new Date(formData.startTimestamp as any),
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
          timeZone,
        ),
        endTimestamp: formatDateToIsoWithTimeZone(
          createDate({
            originalDate: new Date(formData.endTimestamp as any),
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
            addDays: 1,
          }),
          timeZone,
        ),
        onComplete: handleOnClose,
      }),
    )
  }

  return (
    <Dialog open={open} onClose={handleOnClose} aria-labelledby="paymentProductsReportsDialog">
      <BaseDialogTitle
        style={{ marginBottom: 20 }}
        id={'payment-products-reports-dialog'}
        title={<FormattedMessageWrapper id={'navigation.analyticsAndReports'} />}
        onClose={handleOnClose}
        largeTitleText
      />

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              value={startTimestamp || formData.startTimestamp}
              onChange={handleOnChangeStartTime}
              format={units === 'metric' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'}
              label={
                <Typography variant="caption">
                  <FormattedMessageWrapper id="tournament.startDate" />
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              value={endTimestamp || formData.endTimestamp}
              onChange={handleOnChangeEndTime}
              format={units === 'metric' ? 'dd.MM.yyyy' : 'MM/dd/yyyy'}
              label={
                <Typography variant="caption">
                  <FormattedMessageWrapper id="tournament.endDate" />
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button color="primary" onClick={handleGetReport} variant="contained">
          <FormattedMessageWrapper id={'buttons.save'} />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
