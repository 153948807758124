import React from 'react'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { PlayerDialogChildrenArgs } from '@scenes/tournament/players/player-edit-dialog/EditPlayerDialogWrapper'
import { PLAYER_LIST_ITEM_HEIGHT } from './ListPlayer'
import StartGroupHeader from './StartGroupHeader'
import classNames from 'classnames'
import ErrorTooltip from '../ui/ErrorTooltip'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import StartGroupTeam from './StartGroupTeam'
import { StartGroupPlayerItems } from './StartGroupPlayerItems'
import { isSpecialTeamFormatId } from '../../utils/gameFormatUtils'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

const styles = (theme: Theme) =>
  createStyles({
    dropBox: {
      zIndex: 10,
    },
    outerBox: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      flexBasis: '49%',
      position: 'relative',
      padding: 5,
      backgroundColor: theme.customPalette.lightGray,
      border: `2px solid transparent`,
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.3)',
      borderRadius: 4,
      '&.hasError': {
        borderColor: theme.customPalette.error,
      },
      '@media only screen and (max-width: 1819px)': {
        flexGrow: 1,
      },
    },
  })

interface OwnProps {
  group: StartListGroup
  index: number
  basicGroupSize: number
  confirmPlayerRemove?: boolean
  primaryGameFormatId: number
  groupTeamIndex: number
  disableTee?: boolean
  isStartListPublished?: boolean
  disableTeamPlayerEdit?: boolean
  removePlayerAction?: (playerId: number) => void
}

interface StateProps {
  isTeamFormat: boolean
  isTwoManTeamFormat: boolean
  appLanguageCode: string
  selectedRoundId: number
}

type Props = OwnProps & StateProps & PlayerDialogChildrenArgs & WithStyles<typeof styles>

class StartGroup extends React.PureComponent<Props> {
  render() {
    const { classes } = this.props
    const groupSettingsInvalid = this._isGroupInvalid
    const content = (
      <div className={classNames(classes.outerBox, { hasError: groupSettingsInvalid })}>{this._renderGroups()}</div>
    )

    if (groupSettingsInvalid) {
      return (
        <ErrorTooltip title={<FormattedMessageWrapper id={'tournament.tooManyPlayersInGroup'} />} placement={'bottom'}>
          {content}
        </ErrorTooltip>
      )
    }

    return content
  }

  private _renderGroups = () => {
    if (this._isTeamFormat) {
      return this._renderTeamGroupFormat()
    }

    return this._renderDefaultGroupFormat()
  }

  private _renderTeamGroupFormat = () => {
    return <StartGroupTeam {...this.props} />
  }

  private _renderDefaultGroupFormat = () => {
    const { index, classes, basicGroupSize, group } = this.props

    return (
      <>
        <StartGroupHeader index={index} group={group} />

        <Droppable droppableId={this.draggableId} type="droppablePlayer">
          {(dropProvided: DroppableProvided, dropSnapshot: DroppableStateSnapshot) => (
            <div
              className={classes.dropBox}
              ref={dropProvided.innerRef}
              style={{
                minHeight: `${basicGroupSize * PLAYER_LIST_ITEM_HEIGHT}px`,
                backgroundColor: dropSnapshot.isDraggingOver ? '#ddd' : undefined,
              }}
              {...dropProvided.droppableProps}
            >
              <StartGroupPlayerItems
                players={this._players}
                openPlayerDialog={this.props.openPlayerDialog}
                onClosePlayerDialog={this.props.onClosePlayerDialog}
                confirmPlayerRemove={this.props.confirmPlayerRemove}
                removeAction={this._removePlayerAction}
                isSpecialTeamFormat={isSpecialTeamFormatId(this.props.primaryGameFormatId)}
                hideDrag={false}
                disableTee={this.props.disableTee}
              />
            </div>
          )}
        </Droppable>
      </>
    )
  }

  private get _isGroupInvalid() {
    return this._players.length > 5
  }

  private get _isTeamFormat() {
    const { isTeamFormat } = this.props
    return isTeamFormat
  }

  get _players() {
    return this.props.group.startListPlayers
  }

  private get draggableId() {
    const {
      group: { id },
    } = this.props

    if (id < 0) {
      // the id's are transformed into positive integers here as they're used
      // in string based id's
      return `newgroup-${id * -1}`
    }
    return `group-${id}`
  }

  public _removePlayerAction = (playerId: number) => {
    if (this.props.removePlayerAction) {
      this.props.removePlayerAction(playerId)
    }
  }
}

export default connect<StateProps, {}, OwnProps, RootState>((state) => ({
  isTeamFormat: selectTournament(state).isTeamFormat,
  isTwoManTeamFormat: selectTournament(state).isTwoManTeamFormat,
  appLanguageCode: state.localeReducer.appLanguage.code,
  selectedRoundId: selectTournamentConfig(state).selectedRoundId,
}))(withStyles(styles)(StartGroup as any))
