import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import { selectTournamentSite, useUpdateTournamentSiteMutation } from '@app/store/api/endpoints/tournamentSiteApi'
import { MDEditor } from '@app/scenes/tour/MDEditor'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import SaveButton from '@app/components/ui/SaveButton'
import { useAppDispatch } from '@app/store'
import { enqueueNotification } from '@app/store/notifications/actions'

const useStyles = makeStyles(() => ({
  dialogContainer: {
    margin: 'auto',
    '& .MuiPaper-root': {
      minWidth: 680,
      maxWidth: 680,
      borderRadius: 8,
    },
  },
  dialogTitle: {
    fontFamily: 'Exo',
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: 800,
    color: '#555555',
    padding: '24px 36px',
  },
  dialogContent: {
    padding: '0 36px',
    marginTop: 30,
  },
  editorContainer: {
    marginTop: 0,
    marginLeft: 0,
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
  actionContainer: {
    justifyContent: 'flex-end',
    margin: '20px 12px 10px',
  },
  description: {
    color: '#555555',
    fontSize: 14,
    marginBottom: 30,
    width: '75%',
  },
}))

interface Props {
  close: () => void
}

export const TournamentLeaderboardInfoDialog = ({ close }: Props): JSX.Element => {
  const classes = useStyles()
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const tournamentId = useSelector(getTournamentId)
  const tournamentSite = useSelector(selectTournamentSite)
  const [updateTournamentSite, { isLoading: isSaving }] = useUpdateTournamentSiteMutation()
  const [leaderboardInfo, setLeaderboardInfo] = useState<string>(tournamentSite?.leaderboardInfo || '')

  const updateLeaderboardInfo = (value: string): void => {
    // Remove extra space before closing asterisk(s)
    const trimmedValue = value.replace(/\*([^\s*][^*]*?)\s*\*/g, '*$1*')
    setLeaderboardInfo(trimmedValue)
  }

  const onSuccess = (): void => {
    dispatch(enqueueNotification(intl.formatMessage({ id: 'notifications.saveSuccessful' }), 'success'))
    close()
  }

  const saveData = (): void => {
    updateTournamentSite({
      id: tournamentId,
      body: { ...tournamentSite, leaderboardInfo: leaderboardInfo || null },
      onSuccess,
    })
  }

  return (
    <Dialog open onClose={close} fullWidth keepMounted={false} className={classes.dialogContainer}>
      <BaseDialogTitle
        id="form-dialog-title"
        title={<FormattedMessageWrapper id={'tournament.additionalInfoLeaderboardTitle'} />}
        onClose={close}
        className={classes.dialogTitle}
      />

      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2} className={classes.editorContainer}>
          <p className={classes.description}>
            <FormattedMessageWrapper id={'tournament.additionalInfoLeaderboardDescription'} />
          </p>
          <MDEditor
            initialValue={tournamentSite?.leaderboardInfo}
            saveFn={updateLeaderboardInfo}
            maxHeight="100px"
            placeholder={intl.formatMessage({ id: 'tournament.additionalInfoLeaderboardPlaceholder' })}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <SaveButton onClick={saveData} isSaving={isSaving}>
          {intl.formatMessage({ id: 'buttons.save' })}
        </SaveButton>
      </DialogActions>
    </Dialog>
  )
}
