export const TournamentIcon = ({
  style = {},
  fill = '#1AA85D',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 55 55',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
  >
    <path
      d="M41.6,5.06V2.5H13.4V5.06H2.5V7c0,12.07,7.89,15.76,12.06,16v-.14a14.1,14.1,
    0,0,0,25.86,0V23c4.17-.26,12.06-4,12.06-16V5.06ZM10.54,17.42c-2.4-1.79-3.78-4.65-4.11-8.51h7v9.62h.06c0,
    .12,0,.24,0,.37A9.35,9.35,0,0,1,10.54,17.42Zm31,1.49c0-.13,0-.26,0-.38h.06V8.91h7C47.93,16.1,43.7,18.26,
    41.5,18.91Z"
    />
    <path
      d="M31.35,39.62V33.27h-7.7v6.35c0,6.34-8.33,7.75-8.33,7.75V52.5H39.68V47.37S31.35,
    46,31.35,39.62Z"
    />
  </svg>
)
