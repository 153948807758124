import { Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ToggleButton } from '@mui/material'
import { ToggleButtonProps } from '@mui/lab'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { rem } from '@theme/materialUITheme'
import classNames from 'classnames'

export interface TournamentTypeSelectionItemProps extends ToggleButtonProps {
  title: string
  description: string
  icon: JSX.Element
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    margin: `0 ${theme.spacing(3)} ${theme.spacing(3)} 0`,
    minHeight: rem(180),
    fontSize: rem(16),
    fontWeight: 'bold',
    maxWidth: rem(400),
    borderRadius: (theme.shape.borderRadius as number) * 3,
    textTransform: 'none',
    position: 'relative',
    color: theme.palette.text.primary,
    '&.Mui-disabled': {
      border: `${rem(2)} solid ${theme.palette.action.disabled}`,
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      border: `${rem(3)} solid ${theme.palette.primary.main}`,
    },
    '& > .MuiToggleButton-label': {
      height: '100%',
    },
    boxShadow: theme.shadows[3],
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0.25',
    '& > svg': {
      width: rem(60),
      height: rem(60),
      fill: theme.palette.primary.main,
    },
    '&.disabled > svg': {
      fill: theme.palette.action.disabled,
    },
  },
  content: {
    flex: '0.75',
    height: '100%',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  selectionIconHolder: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  checkIconSelected: {
    width: '1.6em',
    height: '1.6em',
    color: theme.palette.primary.main,
  },
  checkIconUnselected: {
    width: '1.6em',
    height: '1.6em',
    color: theme.palette.action.disabled,
  },
  title: {
    display: 'block',
    fontFamily: theme.fonts.primary,
    fontSize: rem(20),
    fontWeight: 800,
    paddingBottom: theme.spacing(1),
  },
}))

export const TournamentTypeSelectionItem: React.FC<TournamentTypeSelectionItemProps> = ({
  title,
  description,
  icon,
  ...rest
}) => {
  const classes = useStyles()
  const { disabled, selected } = rest
  return (
    <ToggleButton aria-label={title} className={classes.button} {...rest}>
      <div className={classNames([classes.iconWrapper, { disabled: disabled }])}>{icon}</div>
      <div className={classes.content}>
        <Typography variant="inherit" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
      <div className={classes.selectionIconHolder}>
        {selected && <CheckCircleIcon className={classes.checkIconSelected} />}
      </div>
    </ToggleButton>
  )
}
