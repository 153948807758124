import React from 'react'
import { connect } from 'react-redux'
import { FormControl, Select, Theme, FormLabel, ListItemButton } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Popover from '@mui/material/Popover'
import createStyles from '@mui/styles/createStyles'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { rem } from '../../theme/materialUITheme'
import SwipeableViews from 'react-swipeable-views'
import GameFormatTooltip from './GameFormatTooltip'
import GameFormatSelectionLabel from './GameFormatSelectionLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { updateTournamentSettingsField } from '@app/store/api/thunks/tournamentSettingsThunks'
import {
  isIndividualFormatId,
  isIndividualTeamFormatId,
  isSpecialTeamFormatId,
  isTwoPlayerFormatId,
  isTeamScoreTeamFormatId,
  isThreeOrMorePlayerFormatId,
  GameFormatBase,
} from '@app/utils/gameFormatUtils'
import { RootState } from '@app/store'
import { selectTournamentSettings } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'

const styles = (theme: Theme) =>
  createStyles({
    wrap: {
      padding: 0,
      width: 360,
    },
    topBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.customPalette.lightGray}`,
      minHeight: '48px',
    },
    titleElement: {
      paddingLeft: '12px',
      fontSize: rem(16),
    },
    multiTitleWrap: {
      '@global': {
        span: {
          display: 'block',
          '&:nth-child(1)': {
            fontSize: rem(12),
          },
          '&:nth-child(2)': {
            paddingLeft: rem(8),
          },
        },
      },
    },
  })

interface OwnProps {
  disabled?: boolean
  selectedGameFormatId?: number
  currentGameFormat?: GameFormatItem
  sideGamePicker?: boolean
  primaryGameFormat?: GameFormatItem
  onChange: (gameFormatId: number, item: GameFormatItem) => void
}

interface StateProps {
  gameFormats: GameFormat[]
  tournament: TournamentState
  tournamentStartLists: TournamentStartListState
  hcpRound?: boolean
  rounds: Round[]
  tournamentSettings: TournamentSettings
  tournamentConfig: TournamentConfig
}

interface DispatchProps {
  updateTournamentSettingsField(tournamentId: number, payload: FieldUpdatePayload): void
}

type Props = OwnProps & StateProps & WithStyles<typeof styles> & DispatchProps

interface State {
  selectedItem?: string
  value?: number
  open?: boolean
  renderQueue: GameFormat[]
  anchorEl: (EventTarget & HTMLDivElement) | null
}

class GameFormatPicker extends React.Component<Props, State> {
  readonly state: State = {
    selectedItem: undefined,
    renderQueue: [],
    anchorEl: null,
  }

  render() {
    const open = Boolean(this.state.anchorEl)
    return (
      <>
        <FormLabel>
          <FormattedMessageWrapper id="tournament.chooseGameFormat" />
        </FormLabel>
        <FormControl
          fullWidth
          onClick={(event) => {
            this.props.tournamentConfig.selectedRoundIndex === 0 &&
              !this.props.disabled &&
              this.setState({ anchorEl: event.currentTarget })
          }}
        >
          <Select
            readOnly // readOnly disables default behavior
            value={this.props.selectedGameFormatId || ''}
            disabled={this.props.tournamentConfig.selectedRoundIndex > 0 || this.props.disabled}
            input={<OutlinedInput name={'gameformat-picker'} id={'gameformat-picker'} />}
            renderValue={() => {
              return this.gameFormatName
            }}
          />
        </FormControl>

        <Popover
          id={`game-format-popover-container`}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({ anchorEl: null })
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={this.props.classes.wrap}>
            {this._renderTopBarByState()}
            {this._renderOptionsContainer()}
          </div>
        </Popover>
      </>
    )
  }

  _handleSelection = (gameFormatId: number, item: GameFormatItem) => {
    const { tournamentSettings, tournament, onChange, updateTournamentSettingsField } = this.props
    if (onChange) {
      onChange(gameFormatId, item)
    }

    if (isTwoPlayerFormatId(gameFormatId)) {
      updateTournamentSettingsField(tournament.id, {
        fieldName: 'defaultTeamSize',
        value: 2,
      })
    }

    if (
      tournamentSettings.defaultTeamSize === 2 &&
      !isTwoPlayerFormatId(gameFormatId) &&
      !isIndividualFormatId(gameFormatId)
    ) {
      updateTournamentSettingsField(tournament.id, {
        fieldName: 'defaultTeamSize',
        value: 4,
      })
    }

    this.setState({ anchorEl: null })
  }

  _pushToNavQueue = (item: GameFormat) => {
    this.setState({
      renderQueue: [...this.state.renderQueue, item],
    })
  }

  _stepBack = () => {
    const { renderQueue } = this.state
    const rest = renderQueue.slice(0, renderQueue.length - 1)
    this.setState({ renderQueue: rest })
  }

  private _renderOptionsContainer = () => {
    const { renderQueue } = this.state

    return (
      <SwipeableViews index={renderQueue.length} disabled animateHeight>
        <div>{this._renderRootOptions()}</div>
        {renderQueue.map((item, idx) => {
          return <div key={idx}>{this._renderQueueItem(item)}</div>
        })}
      </SwipeableViews>
    )
  }

  private _renderTopBarByState = () => {
    const { renderQueue } = this.state

    if (renderQueue.length) {
      return this._renderTopBar(
        <GameFormatSelectionLabel
          labels={renderQueue.map((item) => (
            <FormattedMessageWrapper id={item.key} />
          ))}
        />,
        true,
      )
    }

    return this._renderTopBar(<FormattedMessageWrapper id="tournament.gameFormats" />)
  }

  private _listItemShouldBeDisabled = (item: GameFormatItem): boolean => {
    const { tournament, primaryGameFormat, sideGamePicker } = this.props
    const { id } = item
    const isIndividualOrIndiFormat = isIndividualFormatId(id) || isIndividualTeamFormatId(id)

    let primaryFormatScoreType: 'team' | 'individual' | undefined = undefined
    if (sideGamePicker && primaryGameFormat) {
      primaryFormatScoreType =
        isIndividualFormatId(primaryGameFormat.id) || isIndividualTeamFormatId(primaryGameFormat.id)
          ? 'individual'
          : 'team'
    }

    if (primaryFormatScoreType === 'team' && isIndividualOrIndiFormat) {
      return true
    }

    if (primaryFormatScoreType === 'individual' && isTeamScoreTeamFormatId(id)) {
      return true
    }

    if (sideGamePicker && primaryGameFormat && isTwoPlayerFormatId(id)) {
      return isTwoPlayerFormatId(primaryGameFormat.id) === false && isIndividualFormatId(primaryGameFormat.id) === false
    }

    if (sideGamePicker && primaryGameFormat && isThreeOrMorePlayerFormatId(id)) {
      return (
        isThreeOrMorePlayerFormatId(primaryGameFormat.id) === false &&
        isIndividualFormatId(primaryGameFormat.id) === false
      )
    }

    return (
      tournament.tournamentType === TournamentTypes.multiRound &&
      !isIndividualTeamFormatId(id) &&
      !isIndividualFormatId(id) &&
      !isSpecialTeamFormatId(id)
    )
  }

  private _renderQueueItem = (item: GameFormat) => {
    const { selectedGameFormatId } = this.props

    return (
      <List component="nav">
        {item.options.map((item: GameFormatItem | GameFormat, idx: number) => {
          if (this._isGameFormatItem(item)) {
            const { format, id, description } = item
            return (
              <GameFormatTooltip
                title={description}
                placement="right"
                key={`${format}_${idx}`}
                hidden={!this.state.anchorEl}
              >
                <ListItemButton
                  disabled={this._listItemShouldBeDisabled(item)}
                  key={`${format}_${idx}`}
                  onClick={() => {
                    this._handleSelection(id, item)
                  }}
                >
                  <Typography
                    variant={'body1'}
                    style={{
                      color: selectedGameFormatId === id ? '#0b8e50' : undefined,
                    }}
                  >
                    <FormattedMessageWrapper id={`gameFormats.type${id}`} />
                  </Typography>
                </ListItemButton>
              </GameFormatTooltip>
            )
          } else {
            return this._renderParentListItem(item as GameFormat)
          }
        })}
      </List>
    )
  }

  private _isGameFormatItem = (item: GameFormatItem | GameFormat): item is GameFormatItem => {
    return (item as GameFormatItem).format !== undefined
  }

  private _renderTopBar = (title: any, showBack = false) => {
    if (showBack) {
      return (
        <List className={this.props.classes.topBar}>
          <ListItemButton onClick={this._stepBack}>
            <ListItemIcon style={{ marginRight: 0 }}>
              <ArrowLeft color={'primary'} />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        </List>
      )
    } else {
      return (
        <List className={this.props.classes.topBar}>
          <ListItem>
            <ListItemText primary={title} />
          </ListItem>
        </List>
      )
    }
  }

  private _renderRootOptions = () => {
    const formats = this.props.gameFormats

    return <List component="nav">{formats.map(this._renderParentListItem)}</List>
  }

  private _renderParentListItem = (item: GameFormat) => {
    return (
      <GameFormatTooltip title={item.description || ''} placement="right" key={item.name} hidden={!this.state.anchorEl}>
        <ListItemButton
          disabled={this._disableParent(item.name)}
          onClick={() => {
            this._pushToNavQueue(item)
          }}
        >
          <ListItemText primary={<FormattedMessageWrapper id={item.key} />} />
          <ListItemIcon style={{ marginRight: 0 }}>
            <ArrowRight color={'primary'} />
          </ListItemIcon>
        </ListItemButton>
      </GameFormatTooltip>
    )
  }

  private _disableParent = (name: string) => {
    const { tournament, primaryGameFormat, sideGamePicker } = this.props

    // Only individual game formats accepted for primary game in hcp round
    if (!this.props.sideGamePicker && this.props.hcpRound) {
      return name === GameFormatBase.INDIVIDUAL ? false : true
    }

    // Only individual formats accepted for both primary and side game with no start times setting
    if (tournament.tournamentType === TournamentTypes.weekly) {
      return name === GameFormatBase.INDIVIDUAL ? false : true
    }

    if (sideGamePicker && primaryGameFormat && name === GameFormatBase.INDIVIDUAL) {
      return isTeamScoreTeamFormatId(primaryGameFormat.id)
    }

    if (sideGamePicker && primaryGameFormat && name === GameFormatBase.TEAM_2_PLAYERS) {
      return isTwoPlayerFormatId(primaryGameFormat.id) === false && isIndividualFormatId(primaryGameFormat.id) === false
    }

    if (sideGamePicker && primaryGameFormat && name === GameFormatBase.TEAM_3_OR_MORE_PLAYERS) {
      return (
        isThreeOrMorePlayerFormatId(primaryGameFormat.id) === false &&
        isIndividualFormatId(primaryGameFormat.id) === false
      )
    }

    return false
  }

  private get gameFormatName() {
    const { currentGameFormat } = this.props
    if (currentGameFormat) {
      const labels =
        currentGameFormat !== undefined
          ? [
              <FormattedMessageWrapper
                id={currentGameFormat.parents !== undefined ? currentGameFormat.parents[0] : ''}
              />,
              <FormattedMessageWrapper id={`gameFormats.type${currentGameFormat.id}`} />,
            ]
          : []
      return <GameFormatSelectionLabel labels={labels} highlightLast />
    } else {
      return (
        <span style={{ opacity: 0.55 }}>
          <FormattedMessageWrapper id={'tournament.gameFormats'} />
        </span>
      )
    }
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    (state) => {
      const tournament = selectTournament(state)
      return {
        gameFormats: state.gameFormatsReducer.gameFormats,
        hcpRound: tournament.hcpRound,
        rounds: tournament.rounds,
        tournamentStartLists: selectTournamentStartLists(state),
        tournament: tournament,
        tournamentSettings: selectTournamentSettings(state),
        tournamentConfig: selectTournamentConfig(state),
      }
    },
    {
      updateTournamentSettingsField,
    },
  )(GameFormatPicker),
)
