import { Action } from 'redux'
import { SEND_PLAYER_SCORES } from './constants'

export interface UpdatePlayerScoresPayload {
  args: HcpRound
  onComplete?: () => void
}

export interface UpdatePlayerScoresAction extends Action {
  type: 'SEND_PLAYER_SCORES'
  args: HcpRound
  onComplete?: () => void
}

export interface UpdatePlayerScoresSuccessAction extends Action {
  type: 'SEND_PLAYER_SCORES_SUCCESS'
}

export interface UpdatePlayerScoresFailureAction extends Action {
  type: 'SEND_PLAYER_SCORES_FAILURE'
  error: any
}

export const sendPlayerScores = (args: HcpRound, onComplete?: () => void): UpdatePlayerScoresAction => ({
  type: SEND_PLAYER_SCORES,
  args,
  onComplete,
})
