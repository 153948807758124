import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { RootState } from '@app/store'

export const tournamentStartListsEmailApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStartListEmailPreview: build.query<string, StartListEmailPreviewPayload>({
      query: (payload) => ({
        url: APIRoute.EMAIL_PREVIEW_FETCH(payload.tournamentId, payload.roundId),
        params: { language: payload.language },
        responseHandler: (response) => response.text(),
      }),
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          payload.onComplete(data)
        } catch {
          console.error('Error fetching start list email preview')
        }
      },
    }),
    sendStartListEmail: build.mutation<void, StartListEmailPayload>({
      query: (payload) => ({
        url: APIRoute.EMAIL_SEND(payload.tournamentId, payload.roundId),
        method: HttpMethod.POST,
        body: payload.body,
      }),
    }),
    getSummaryPageEmailPreview: build.query<{ htmlBase64Content: string }, SummaryPageEmailPreviewPayload>({
      query: (payload) => ({
        url: APIRoute.GET_SUMMARY_PAGE_EMAIL_PREVIEW(payload.tournamentId),
        params: { language: payload.language },
      }),
      async onQueryStarted(payload, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          payload.onComplete(data.htmlBase64Content)
        } catch {
          console.error('Error fetching summary page email preview')
        }
      },
    }),
    sendSummaryPageEmail: build.mutation<void, SummaryPageEmailPayload>({
      query: (payload) => ({
        url: APIRoute.SEND_SUMMARY_PAGE_EMAIL(payload.tournamentId),
        method: HttpMethod.POST,
        body: payload.body,
      }),
    }),
  }),
})

export const selectStartListEmailPreview = (state: RootState, payload: StartListEmailPreviewPayload) =>
  tournamentStartListsEmailApi.endpoints.getStartListEmailPreview.select(payload)(state).data

export const selectSummaryPageEmaillPreview = (state: RootState, payload: SummaryPageEmailPreviewPayload) =>
  tournamentStartListsEmailApi.endpoints.getSummaryPageEmailPreview.select(payload)(state).data

export const {
  useGetStartListEmailPreviewQuery,
  useSendStartListEmailMutation,
  useGetSummaryPageEmailPreviewQuery,
  useSendSummaryPageEmailMutation,
} = tournamentStartListsEmailApi
