import {
  FetchOrganizationApplicationsAction,
  FetchOrganizationsAction,
  OrganizationApplicationsFetchErrorAction,
  OrganizationApplicationsFetchSuccessAction,
  OrganizationsFetchErrorAction,
  OrganizationsFetchSuccessAction,
  PutCondensedOrganizationsSuccessAction,
  FetchOrganizationTournaments,
  FetchOrganizationTournamentsSuccess,
  FetchOrganizationTournamentsFailure,
} from './actions'
import {
  CONDENSED_ORGANIZATIONS_FETCH_SUCCESS,
  ORGANIZATION_APPLICATIONS_FETCH,
  ORGANIZATION_APPLICATIONS_FETCH_ERROR,
  ORGANIZATION_APPLICATIONS_FETCH_SUCCESS,
  ORGANIZATIONS_FETCH,
  ORGANIZATIONS_FETCH_ERROR,
  ORGANIZATIONS_FETCH_SUCCESS,
  FETCH_ORGANIZATIONS_TOURNAMENTS,
  FETCH_ORGANIZATIONS_TOURNAMENTS_SUCCESS,
  FETCH_ORGANIZATIONS_TOURNAMENTS_FAILURE,
} from './constants'

const initialState: OrganizationsState = {
  loading: false,
  error: null,
  organizations: [],
  organizationApplications: [],
  organizationsCondensed: [],
  organizationTournaments: undefined,
}

type OrganizationsAction =
  | FetchOrganizationsAction
  | OrganizationsFetchSuccessAction
  | OrganizationsFetchErrorAction
  | FetchOrganizationApplicationsAction
  | OrganizationApplicationsFetchSuccessAction
  | OrganizationApplicationsFetchErrorAction
  | PutCondensedOrganizationsSuccessAction
  | FetchOrganizationTournaments
  | FetchOrganizationTournamentsSuccess
  | FetchOrganizationTournamentsFailure

export const organizationsReducer = (
  state: OrganizationsState = initialState,
  action: OrganizationsAction,
): OrganizationsState => {
  switch (action.type) {
    case ORGANIZATION_APPLICATIONS_FETCH:
    case ORGANIZATIONS_FETCH:
    case FETCH_ORGANIZATIONS_TOURNAMENTS:
      return {
        ...state,
        loading: true,
      }
    case ORGANIZATION_APPLICATIONS_FETCH_ERROR:
    case ORGANIZATIONS_FETCH_ERROR:
    case FETCH_ORGANIZATIONS_TOURNAMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    case ORGANIZATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: action.organizations,
      }
    case ORGANIZATION_APPLICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationApplications: action.organizationApplications,
      }
    case CONDENSED_ORGANIZATIONS_FETCH_SUCCESS:
      return {
        ...state,
        organizationsCondensed: action.organizations,
      }
    case FETCH_ORGANIZATIONS_TOURNAMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationTournaments: action.organizationTournaments,
      }
    default:
      return state
  }
}
