import { takeEvery, put, all, select } from 'redux-saga/effects'
import {
  CONDENSED_TOURNAMENTS_FETCH,
  CONDENSED_TOURNAMENTS_FETCH_SUCCESS,
  TOURNAMENT_REMOVE,
  TOURNAMENT_REMOVE_FAILURE,
  TOURNAMENT_REMOVE_SUCCESS,
  TOURNAMENTS_FETCH,
  TOURNAMENTS_FETCH_FAILURE,
  TOURNAMENTS_FETCH_SUCCESS,
  TOURNAMENT_CALENDAR_TOGGLE,
  TOURNAMENT_CALENDAR_TOGGLE_FAILURE,
  TOURNAMENT_CALENDAR_TOGGLE_SUCCESS,
  FETCH_FILTER_YEARS,
  FETCH_FILTER_YEARS_SUCCESS,
  FETCH_FILTER_YEARS_FAILURE,
} from './constants'
import { getToken } from '../authentication/sagas'
import { API_ROOT, APIRoute, ROWS_PER_PAGE } from '../../config'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import {
  FetchTournamentsAction,
  TournamentRemoveAction,
  TournamentCalendarToggleAction,
  FetchFilterYearsAction,
  FetchCondensedTournamentsAction,
} from './actions'
import { enqueueNotification } from '../notifications/actions'
import { RootState } from '..'

const getOrganizationId = (store: RootState) => store.organizationReducer.id

function* doFetchTournaments({
  organizationId,
  page = 1,
  sortBy = 'date',
  direction = 'desc',
  searchTerm = '',
  startDate,
  endDate,
  withoutDate,
  onComplete,
}: FetchTournamentsAction) {
  try {
    const token = yield getToken()

    const { data } = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_TOURNAMENTS(organizationId)}`,
      method: 'get',
      token,
      params: {
        offset: ROWS_PER_PAGE * (page - 1),
        limit: ROWS_PER_PAGE,
        name: searchTerm,
        sortBy,
        direction,
        startDate,
        endDate,
        withoutDate,
      },
    })

    defaultOnCompleteCall(onComplete, data)
    yield put({
      type: TOURNAMENTS_FETCH_SUCCESS,
      tournaments: data.tournaments,
      calendarUrl: data.calendarUrl,
    })
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, undefined, error)
    yield put({ type: TOURNAMENTS_FETCH_FAILURE, error: error.toString() })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doRemoveTournament({ tournamentId, triggerFetch }: TournamentRemoveAction) {
  try {
    const token = yield getToken()
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.DELETE_TOURNAMENT(tournamentId)}`,
      method: 'delete',
      token,
    })

    yield put({
      type: TOURNAMENT_REMOVE_SUCCESS,
    })
    // refetch tournaments after delete
    // yield put(fetchTournaments());
    triggerFetch()
  } catch (error: any) {
    yield put({ type: TOURNAMENT_REMOVE_FAILURE, error: error.toString() })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchCondensedTournaments({ id, startDate, endDate }: FetchCondensedTournamentsAction) {
  try {
    const token = yield getToken()
    const organizationId = id ? id : yield select(getOrganizationId)
    const { data } = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_TOURNAMENTS(organizationId)}`,
      token,
      params: {
        type: 'condensed',
        startDate,
        endDate,
      },
    })
    yield put({ type: CONDENSED_TOURNAMENTS_FETCH_SUCCESS, data: data.tournaments })
  } catch (error: any) {
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doToggleCalendarTournament({ id, showInCalendar }: TournamentCalendarToggleAction) {
  try {
    const token = yield getToken()
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.TOURNAMENT_CALENDAR(id)}`,
      method: 'put',
      token,
      data: {
        showInCalendar,
      },
    })
    yield put({
      type: TOURNAMENT_CALENDAR_TOGGLE_SUCCESS,
      id,
      showInCalendar,
    })
  } catch (error: any) {
    yield put({ type: TOURNAMENT_CALENDAR_TOGGLE_FAILURE, error })
    yield put(enqueueNotification(error, 'error'))
  }
}

function* doFetchFilterYears({ organizationId }: FetchFilterYearsAction) {
  try {
    const token = yield getToken()
    const result = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.FILTER_YEARS(organizationId)}`,
      method: 'get',
      token,
    })
    const { years } = result.data
    yield put({ type: FETCH_FILTER_YEARS_SUCCESS, years })
  } catch (error: any) {
    yield put({ type: FETCH_FILTER_YEARS_FAILURE, error })
  }
}

export function* watchTournaments() {
  yield all([
    takeEvery(TOURNAMENTS_FETCH, doFetchTournaments),
    takeEvery(TOURNAMENT_REMOVE, doRemoveTournament),
    takeEvery(CONDENSED_TOURNAMENTS_FETCH, doFetchCondensedTournaments),
    takeEvery(TOURNAMENT_CALENDAR_TOGGLE, doToggleCalendarTournament),
    takeEvery(FETCH_FILTER_YEARS, doFetchFilterYears),
  ])
}
