import React from 'react'
import { NavLink } from 'react-router-dom'
import { ListItemButton, Theme, Icon } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import withTheme from '@mui/styles/withTheme'
import createStyles from '@mui/styles/createStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { rem } from '../../theme/materialUITheme'
import ChevronRight from '@mui/icons-material/ChevronRight'
import LayersIcon from '@mui/icons-material/Layers'
import { AttachMoney } from '@mui/icons-material'
import { connect } from 'react-redux'
import { isRoleInfoProviderAdmin, isRoleAdmin } from '../../utils/authUtils'
import {
  OrganizationIcon,
  HomeIcon,
  TournamentIcon,
  RankingIcon,
  ReportsIcon,
  SupportIcon,
  SettingsIcon,
  UpdatesIcon,
} from '../../assets/icons'
import { Public } from '@mui/icons-material'
import classNames from 'classnames'
import { showPublicEmailWarning } from '../../utils/organizationUtils'
import { ErrorOutline } from '@mui/icons-material'
import get from 'lodash/get'
import { FI_COUNTRY_ID } from '@config/index'
import { WithRouterProps, withRouter } from '@app/hoc/withRouter'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
      fontSize: rem(15),
      lineHeight: 1,
      textTransform: 'uppercase',
      marginTop: -1,
      padding: 0,

      '& > a': {
        width: '100%',
        background: theme.palette.mode === 'light' ? theme.customPalette.light : theme.customPalette.darkGray2,
        borderTop: `1px solid ${theme.customPalette.border}`,
        borderBottom: `1px solid ${theme.customPalette.border}`,
        paddingTop: 13,
        paddingBottom: 11,
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.customPalette.lightGray2,
        paddingLeft: 16,
        paddingRight: 16,
        textDecoration: 'none',
        transition: 'background 0.2s, color 0.2s',
        '&:hover, &:active, &focus': {
          background: theme.customPalette.mediumGray,
          color: theme.customPalette.darkGray2,
        },
        '&.active': {
          color: theme.palette.primary.contrastText,
          background: theme.palette.primary.main,
          borderBottomColor: theme.palette.primary.main,
          borderTopColor: theme.palette.primary.main,
          '& + ul': {
            display: 'block',
          },
        },
      },
    },
    listItemExternal: {
      '& > a': {
        background: theme.palette.mode === 'light' ? theme.customPalette.light : theme.customPalette.mediumGray2,
        color: theme.customPalette.darkBlue,
        textDecoration: 'none',
        textTransform: 'none',
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    listItemIcon: {
      fontSize: rem(22),
      marginRight: 15,
    },
    listItemLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    externalItemWrapper: {
      marginTop: 19,
      paddingBottom: 19,
      'span.title': {
        fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
        fontSize: rem(15),
        lineHeight: 2.5,
        textTransform: 'uppercase',
        color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.customPalette.lightGray2,
        paddingLeft: 15,
      },
    },
    settingsItemMargin: {
      marginTop: 19,
    },
  })

interface StateProps {
  roleInfo?: RoleInfo
  customerInfo?: CustomerInfo
  permittedRoles?: RoleInfo[]
  organization?: OrganizationState
  auth?: AuthenticationState
}

interface ThemeProps {
  theme: Theme
}

interface DrawerItem {
  disabled: boolean
  to: string
  label: string
  icon: any
  type?: string
  className?: string | undefined
  warning?: boolean
  target?: string
}

type Props = WithStyles<typeof styles> & WithRouterProps & StateProps & ThemeProps & WrappedComponentProps

class AppDrawerItems extends React.Component<Props> {
  render() {
    return (
      <React.Fragment>
        {this.getBasicDrawerItems.map((item: DrawerItem, index: number) => {
          if (!item) {
            return null
          }
          return this.getLinkComponent(
            `keyNavItem-${index}`,
            item.disabled,
            item.to,
            item.label,
            item.icon,
            item.className,
            item.type,
            item.warning,
            item.target,
          )
        })}
        {this._maybeRenderAdminItems()}
      </React.Fragment>
    )
  }

  public get hasOrganization() {
    const { customerInfo } = this.props

    if (!customerInfo) {
      return false
    }

    return !!customerInfo.idCustomer
  }

  private isCountryFinland = (countryId?: number) => countryId === FI_COUNTRY_ID

  private get getBasicDrawerItems() {
    const { classes, roleInfo, organization, auth, intl } = this.props
    const items: DrawerItem[] = [
      {
        disabled: !this.hasOrganization,
        to: '/dashboard',
        label: 'navigation.dashboard',
        icon: <HomeIcon />,
      },
      {
        disabled: !this.hasOrganization,
        to: '/tournaments',
        label: 'navigation.tournaments',
        icon: <TournamentIcon />,
      },
      {
        disabled: !this.hasOrganization,
        to: '/tour-and-ranking',
        label: 'navigation.toursAndRanking',
        icon: <RankingIcon />,
      },
      {
        disabled: !this.hasOrganization,
        to: '/divisions',
        label: 'navigation.divisions',
        icon: <LayersIcon />,
      },
      {
        disabled: !this.hasOrganization || !auth?.customerInfo?.isClub,
        to: '/analytics-and-reports',
        label: 'navigation.analyticsAndReports',
        icon: <ReportsIcon />,
      },
      {
        disabled: !this.hasOrganization || !organization?.paymentEnabled,
        to: '/payment-products',
        label: 'navigation.organizationProducts',
        icon: <AttachMoney />,
      },
    ]

    if (isRoleAdmin(roleInfo)) {
      items.push({
        disabled: false,
        to: '/profile',
        label: 'navigation.profileSettings',
        icon: <SettingsIcon />,
        type: 'settings',
        className: classes.settingsItemMargin,
        warning: this._showProfileWarning(),
      })
    }

    if (this.isCountryFinland(organization?.club?.countryId)) {
      items.push({
        disabled: false,
        to: '/hcp-rounds',
        label: 'navigation.hcpRounds',
        icon: <Public />,
        type: 'settings',
      })
    }

    items.push({
      disabled: false,
      to: 'https://tournamentmanager.zendesk.com/hc/en-us',
      label: 'navigation.support',
      icon: <SupportIcon />,
      type: 'settings',
      className: !isRoleAdmin(roleInfo) ? classes.settingsItemMargin : undefined,
      target: '_blank',
    })

    items.push({
      disabled: false,
      to: intl.formatMessage({ id: 'navigation.updatesLink' }),
      label: 'navigation.updates',
      icon: <UpdatesIcon />,
      type: 'settings',
      className: !isRoleAdmin(roleInfo) ? classes.settingsItemMargin : undefined,
      target: '_blank',
    })

    return items
  }

  private _showProfileWarning(): boolean {
    const ownOrganizationId = get(this.props, 'customerInfo.idCustomer')
    const organizationId = get(this.props, 'organization.id')
    if (ownOrganizationId !== organizationId) {
      return false
    }
    return showPublicEmailWarning(this.props.organization)
  }

  private _maybeRenderAdminItems = () => {
    const { roleInfo, classes } = this.props

    if (!isRoleInfoProviderAdmin(roleInfo)) {
      return null
    }

    return (
      <div className={classes.settingsItemMargin}>
        {this.getLinkComponent(
          'keyOrganizations',
          false,
          '/organizations',
          'navigation.organizations',
          <OrganizationIcon />,
          'settings',
        )}
      </div>
    )
  }

  private getLinkComponent = (
    key: string,
    disabled: boolean,
    to: string,
    label: string,
    icon: any,
    className: string | undefined,
    type = 'basic',
    warning?: boolean,
    target?: string,
  ) => {
    const { classes, location } = this.props

    if (location.pathname.indexOf(to) === 0) {
      type = 'selected'
    }
    const externalUrl = to.includes('http')

    return (
      <ListItemButton
        component="li"
        className={classNames(className, classes.listItem, type === 'external' && classes.listItemExternal)}
        key={key}
        disabled={disabled}
      >
        {externalUrl ? (
          <a href={to} role="button" aria-disabled="true" target={target || '_self'} rel="noreferrer">
            {this._getLinkComponentContent(label, icon, type, warning)}
          </a>
        ) : (
          <NavLink to={to}>{this._getLinkComponentContent(label, icon, type, warning)}</NavLink>
        )}
      </ListItemButton>
    )
  }

  private _getLinkComponentContent = (label: string, icon: any, type: string, warning?: boolean) => {
    const { classes } = this.props
    const iconColor = this._getIconColor(type)

    return (
      <React.Fragment>
        <div className={classes.listItemLabel}>
          <Icon className={classes.listItemIcon}>
            {type === 'external' ? (
              <img style={{ width: '100%' }} src={icon} alt={label} />
            ) : (
              React.cloneElement(icon, { fill: iconColor, style: { color: iconColor } })
            )}
          </Icon>
          <span>
            <FormattedMessageWrapper id={label} />
          </span>
          {warning && <ErrorOutline htmlColor={this._getIconColor('warning')} style={{ marginLeft: 10 }} />}
        </div>
        <ChevronRight htmlColor={iconColor} />
      </React.Fragment>
    )
  }

  private _getIconColor = (type) => {
    const { theme } = this.props

    let iconColor = theme.palette.primary.main
    switch (type) {
      case 'settings':
        iconColor = theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.customPalette.mediumGray2
        break
      case 'external':
        iconColor = theme.customPalette.darkBlue
        break
      case 'selected':
        iconColor = theme.palette.primary.contrastText
        break
      case 'warning':
        iconColor = theme.customPalette.error
        break
    }

    return iconColor
  }
}

const RoutedAppDrawerItems = withRouter(AppDrawerItems)
const StyledAppDrawerItems = withStyles(styles)(RoutedAppDrawerItems)
const ThemedAppDrawerItems = withTheme(StyledAppDrawerItems as any)
export default connect<StateProps, {}, {}, RootState>((store) => ({
  roleInfo: store.authenticationReducer.roleInfo,
  permittedRoles: store.authenticationReducer.permittedRoles,
  customerInfo: store.authenticationReducer.customerInfo,
  organization: store.organizationReducer,
  auth: store.authenticationReducer,
}))(injectIntl(withStyles(styles)(ThemedAppDrawerItems)))
