import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { updateStartListRounds } from '../slices/tournamentStartListsSlice'
import {
  getActiveStartListCacheTags,
  processCreateStartListBody,
  processStartListRound,
} from '../utils/tournamentStartListsUtils'
import { RootState } from '@app/store'
import { saveStartListData } from '../thunks/tournamentStartListsThunks'

export const tournamentStartListsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStartList: build.query<StartListRound, StartListPayload>({
      query: (payload) => APIRoute.START_LIST(payload.tournamentId, payload.roundId),
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          const round = processStartListRound(payload, data)
          dispatch(updateStartListRounds(round))
        } catch {
          console.error('Error fetching start list')
        }
      },
      providesTags: (result, error, payload) => [{ type: CacheTag.TOURNAMENT_STARTLISTS, id: payload.roundId }],
    }),
    updateStartList: build.mutation<StartListRound, UpdateStartListPayload>({
      query: (payload) => ({
        url: APIRoute.START_LIST(payload.tournamentId, payload.roundId),
        method: HttpMethod.PUT,
        body: payload.body,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { tournamentId, roundId } = payload
          dispatch(saveStartListData({ tournamentId, roundId }, data))
        } catch {
          console.error('Error updating start list')
        }
      },
    }),
    deleteStartList: build.mutation<void, StartListPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_START_LIST(payload.tournamentId, payload.roundId),
        method: HttpMethod.DELETE,
      }),
      invalidatesTags: () => getActiveStartListCacheTags(),
    }),
    createStartList: build.mutation<StartListRoundCreated, CreateStartListPayload>({
      query: (payload) => ({
        url: APIRoute.POST_CREATE_START_LIST(payload.tournamentId, payload.roundId),
        method: HttpMethod.POST,
        body: { ...processCreateStartListBody(), autofill: payload.autofill },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { tournamentId, roundId } = payload
          dispatch(saveStartListData({ tournamentId, roundId }, data.startList))
        } catch {
          console.error('Error creating start list')
        }
      },
    }),
    copyStartList: build.mutation<StartListRound, CopyStartListPayload>({
      query: (payload) => ({
        url: APIRoute.POST_COPY_START_LIST(payload.tournamentId, payload.roundId),
        method: HttpMethod.POST,
        body: payload.body,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { tournamentId, roundId } = payload
          dispatch(saveStartListData({ tournamentId, roundId }, data))
        } catch {
          console.error('Error copying start list')
        }
      },
    }),
  }),
})

export const selectTournamentStartList = (state: RootState, payload: StartListPayload): StartListRound | undefined =>
  tournamentStartListsApi.endpoints.getStartList.select(payload)(state)?.data

export const {
  useGetStartListQuery,
  useUpdateStartListMutation,
  useDeleteStartListMutation,
  useCreateStartListMutation,
  useCopyStartListMutation,
} = tournamentStartListsApi
