import { LOCALSTORAGE_ROUTE_NAME } from '@app/config'
import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import AppDrawer from '../layout/AppDrawer'
import Notifier from '../notifications/Notifier'
import AutoSaveIndicator from '../ui/AutoSaveIndicator'
import { parseJwt } from '@app/utils/authUtils'
import { useDispatch } from 'react-redux'
import { checkAuth } from '@app/store/authentication/actions'
import { useCallback, useEffect } from 'react'
import { RootState } from '@app/store'
import Loading from '@app/scenes/loading/Loading'

const LoginGuard: React.FC = () => {
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const auth = useSelector((store: RootState) => store.authenticationReducer)
  const { loggedIn, loading, checkLoading, token } = auth

  const checkLoginStatus = useCallback(() => {
    if (token) {
      const payload = parseJwt(token)
      const expirestAt = payload.expirestAt
      if (expirestAt >= Date.now()) {
        dispatch(checkAuth())
      }
    }
  }, [pathname])

  useEffect(() => {
    checkLoginStatus()
  }, [pathname])

  useEffect(() => {
    const currentRoute = `${pathname}${search}`
    try {
      window.localStorage.setItem(LOCALSTORAGE_ROUTE_NAME, currentRoute)
    } catch (e) {
      window.console.log('could not set', LOCALSTORAGE_ROUTE_NAME, 'to localStorage')
    }
  }, [pathname, search])

  if (loading || (checkLoading && !loggedIn)) {
    return <Loading />
  }

  if (!loggedIn) {
    return <Navigate to={'/login'} />
  }

  return (
    <>
      <AppDrawer />
      <Notifier />
      <main style={{ flexGrow: 1, boxSizing: 'border-box' }}>
        <Outlet />
      </main>
      <AutoSaveIndicator />
    </>
  )
}

export default LoginGuard
