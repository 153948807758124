import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { getActiveStartListCacheTags, processStartListGroupPayload } from '../utils/tournamentStartListsUtils'
import { addStartListGroupsToStore } from '../thunks/tournamentStartListsThunks'

export const tournamentStartListGroupApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateStartListGroup: build.mutation<StartListGroup[], UpdateStartListGroupPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_EDIT_START_LIST_GROUP(payload.tournamentId, payload.roundId, payload.groupId),
        method: HttpMethod.PUT,
        body: processStartListGroupPayload(payload.roundId, payload.body),
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error updating start list group')
        }
      },
    }),
    swapStartListGroups: build.mutation<StartListGroup[], SwapStartListGroupsPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_SWAP_START_LIST_GROUP(payload.tournamentId, payload.roundId, payload.groupId),
        method: HttpMethod.PUT,
        body: payload.body,
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error swapping start list groups')
        }
      },
    }),
    deleteStartListGroup: build.mutation<StartListGroup[], DeleteStartListGroupPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_START_LIST_GROUP(payload.tournamentId, payload.roundId, payload.groupId),
        method: HttpMethod.DELETE,
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error deleting start list group')
        }
      },
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS],
    }),
    createStartListGroup: build.mutation<StartListRound, CreateStartListGroupPayload>({
      query: (payload) => ({
        url: APIRoute.POST_CREATE_START_GROUP(payload.tournamentId, payload.roundId),
        method: HttpMethod.POST,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data.groups))
        } catch {
          console.error('Error creating start list group')
        }
      },
    }),
    addTeamToGroup: build.mutation<StartListGroup[], TeamGroupPayload>({
      query: (payload) => ({
        url: APIRoute.START_LIST_TEAM_GROUP(payload.tournamentId, payload.roundId, payload.groupId, payload.teamId),
        method: HttpMethod.POST,
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error adding team to group')
        }
      },
    }),
    moveTeamToGroup: build.mutation<StartListGroup[], TeamGroupPayload>({
      query: (payload) => ({
        url: APIRoute.START_LIST_TEAM_GROUP(payload.tournamentId, payload.roundId, payload.groupId, payload.teamId),
        method: HttpMethod.PUT,
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error moving team to group')
        }
      },
    }),
    deleteTeamFromGroup: build.mutation<void, TeamGroupPayload>({
      query: (payload) => ({
        url: APIRoute.START_LIST_TEAM_GROUP(payload.tournamentId, payload.roundId, payload.groupId, payload.teamId),
        method: HttpMethod.DELETE,
      }),
      invalidatesTags: () => getActiveStartListCacheTags(),
    }),
    updatePlayerGroupOrder: build.mutation<StartListGroup[], UpdatePlayerGroupOrderPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_UPDATE_PLAYER_GROUP_ORDER(payload.tournamentId, payload.roundId, payload.playerId),
        method: HttpMethod.PUT,
        body: { order: payload.order },
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error updating group players order')
        }
      },
    }),
    addPlayerToGroup: build.mutation<StartListGroup[], EditGroupMemberPayload>({
      query: (payload) => ({
        url: APIRoute.POST_ADD_PLAYER_TO_GROUP(
          payload.tournamentId,
          payload.roundId,
          payload.groupId,
          payload.playerId,
        ),
        method: HttpMethod.POST,
        body: { groupOrder: payload.groupOrder },
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error adding player to group')
        }
      },
    }),
    updateStartListPlayer: build.mutation<StartListGroup[], EditRoundPlayerPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_UPDATE_START_LIST_PLAYER(payload.tournamentId, payload.roundId, payload.playerId),
        method: HttpMethod.PUT,
        body: { handicap: payload.handicap, teeBoxId: payload.teeBoxId },
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error updating start list player')
        }
      },
    }),
    removePlayerFromGroup: build.mutation<StartListGroup[], EditGroupMemberPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_PLAYER_FROM_GROUP(
          payload.tournamentId,
          payload.roundId,
          payload.groupId,
          payload.playerId,
        ),
        method: HttpMethod.DELETE,
      }),
      transformResponse: (response: { groups: StartListGroup[] }) => {
        return response.groups
      },
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addStartListGroupsToStore(payload, data))
        } catch {
          console.error('Error removing player from group')
        }
      },
      invalidatesTags: [CacheTag.TOURNAMENT_PLAYERS],
    }),
  }),
})

export const {
  useUpdateStartListGroupMutation,
  useDeleteStartListGroupMutation,
  useCreateStartListGroupMutation,
  useAddTeamToGroupMutation,
  useMoveTeamToGroupMutation,
  useDeleteTeamFromGroupMutation,
  useUpdatePlayerGroupOrderMutation,
} = tournamentStartListGroupApi
