import React from 'react'
import { MenuItem, Table, TextField, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { get } from 'lodash'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'
import { IntlShape } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { rem } from '../../../theme/materialUITheme'
import { formatDate, formatDateToIsoWithTimeZone } from '@app/utils/dates'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { formatHandicap } from '@app/utils/playerUtils'
import { Select } from '@app/components/forms/Select'
import { validFemaleTeeBoxes, validMaleTeeBoxes } from '@utils/clubAndCourseUtils'
import { PlayerGender } from '@app/store/api/enums/tournamentPlayersEnums'

const styles = (theme: Theme) =>
  createStyles({
    contentCell: {
      padding: `6px 24px 6px 16px`,
      borderColor: 'transparent',
      borderBottom: 0,
    },
    titleCell: {
      fontWeight: 600,
      borderColor: 'transparent',
      '&.highlight': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
    slopeTable: {
      width: 'auto',
    },
    tableHeader: {
      background: theme.palette.primary.main,
      '& > td': {
        color: theme.palette.primary.contrastText,
        padding: '5px 16px',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: rem(12),
        borderBottom: 0,
      },
    },
    tableContent: {
      '& > td': {
        padding: `6px 16px`,
        borderColor: 'transparent',
        borderBottom: 0,
        textAlign: 'center',
        fontSize: rem(12),
      },
    },
    support: {
      textAlign: 'center',
      marginTop: 30,
      '@global': {
        a: {
          color: theme.palette.primary.main,
        },
      },
    },
    courseVersion: {
      textAlign: 'center',
      marginTop: 30,
      fontSize: rem(12),
    },
    weeklyAdditionalInfo: {
      float: 'right',
      marginLeft: 15,
      paddingTop: 5,
    },
    weeklyAdditionalInputInfo: {
      float: 'right',
      marginLeft: 15,
      background: 'white',
      width: 40,
    },
  })

export interface CourseGuideTableChildren {
  titleCellClassName: string
  highlightClassName: string
}

interface Props extends WithStyles<typeof styles> {
  course?: RoundCourse
  showSlopes?: boolean
  showScoreIndex?: boolean
  showTees?: boolean
  children?(args: CourseGuideTableChildren): any
  tournament?: TournamentState
  roundIndex?: number
  weeklyScore?: ScoreRounds
  units?: OrganizationUnits
  intl?: IntlShape
  playerScore?: TournamentLeaderboardPlayer
  startlists?: TournamentStartListState
  syncRoundHcp?: (roundIndex: number, roundHcp: string) => void
  syncRoundTeeBox?: (roundIndex: number, roundTeeBoxId: number) => void
}

interface State {
  roundHcp?: string
  roundTeeBoxId?: number
}

const initialState: State = {
  roundHcp: undefined,
  roundTeeBoxId: undefined,
}

class CourseGuideTable extends React.Component<Props, State> {
  readonly state: State = initialState

  render() {
    const { course, showSlopes = true, children, classes } = this.props

    if (!course) {
      return null
    }

    return (
      <>
        <Table>
          <TableBody>
            {this._renderRows(course)}
            {children &&
              children({
                titleCellClassName: this.props.classes.titleCell,
                highlightClassName: this._titleClassName,
              })}
          </TableBody>
        </Table>

        {showSlopes && (
          <>
            <Typography variant={'h2'} style={{ marginTop: 32, marginBottom: 4 }}>
              <FormattedMessageWrapper id={'course.ratingAndSlope'} />
            </Typography>

            <Table className={this.props.classes.slopeTable}>
              <TableBody>{this._renderCourseSlopes(course)}</TableBody>
            </Table>

            <Typography variant="body1" className={classes.support}>
              <FormattedMessageWrapper id={'tournament.scoreCardSupport'} hasLink />
            </Typography>
            <Typography variant="body1" className={classes.courseVersion}>
              <FormattedMessageWrapper id={'course.version'} hasLink />: {course.teeBoxes[0]?.version}
            </Typography>
          </>
        )}
      </>
    )
  }

  private additionalMultiroundRowInfo = (roundIndex?: number) => {
    const { tournament, intl, units, playerScore, startlists } = this.props

    if (
      tournament &&
      roundIndex &&
      startlists?.rounds[roundIndex] &&
      playerScore &&
      tournament.tournamentType === TournamentTypes.multiRound
    ) {
      const playerId = Number(playerScore.userId)
      const startListGroup = startlists.rounds[roundIndex].groups.find((g) =>
        g.startListPlayers.find((p) => p.id === playerId),
      )
      const startListPlayer = startListGroup ? startListGroup.startListPlayers.find((p) => p.id === playerId) : null

      if (startListGroup && startListPlayer && intl && units) {
        const hcp = startListPlayer.hcp ? `HCP ${startListPlayer.hcp}` : ''
        const info = [
          `${intl.formatMessage({ id: 'tournament.phcp' })}: ${startListPlayer.playingHandicap}`,
          `${intl.formatMessage({ id: 'tournament.tee' })}: ${startListPlayer.teeBox?.teeboxName || ''}`,
          `${intl.formatMessage({ id: 'tournament.startHole' })}: ${startListGroup.teeNumber}`,
          `${intl.formatMessage({ id: 'tournament.startTime' })}: ${formatDate(
            startListGroup.startTime,
            'time',
            units,
          )}`,
        ].join(', ')

        return ` - ${info} ${hcp}`
      }
    }

    return
  }

  private _renderRows = (course: RoundCourse) => {
    const { showScoreIndex = true, showTees = true, classes, roundIndex } = this.props

    const frontNineHoles = this._getHoles('f9')
    const backNineHoles = this._getHoles('b9')

    return (
      <>
        <TableRow className={classes.tableHeader}>
          <TableCell colSpan={22} style={{ textAlign: 'left' }}>
            {course.clubName}
            {course.courseName && ' - '}
            {course.courseName}
            {this.additionalMultiroundRowInfo(roundIndex)}
            {this._maybeRenderAdditionalWeeklyInfo()}
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableHeader}>
          <TableCell>
            <FormattedMessageWrapper id={'course.hole'} />
          </TableCell>
          {frontNineHoles.map(this._renderCell('holeNumber'))}
          <TableCell>
            <FormattedMessageWrapper id={'course.out'} />
          </TableCell>
          {backNineHoles.map(this._renderCell('holeNumber'))}
          <TableCell>
            <FormattedMessageWrapper id={'course.in'} />
          </TableCell>
          <TableCell>
            <FormattedMessageWrapper id={'course.total'} />
          </TableCell>
        </TableRow>

        <TableRow className={classes.tableContent}>
          <TableCell>
            <FormattedMessageWrapper id={'course.par'} />
          </TableCell>
          {frontNineHoles.map(this._renderCell('par'))}
          <TableCell>{this._getParTotal(frontNineHoles)}</TableCell>
          {backNineHoles.map(this._renderCell('par'))}
          <TableCell>{this._getParTotal(backNineHoles)}</TableCell>
          <TableCell>{this._getParTotal(course.holes)}</TableCell>
        </TableRow>

        {showScoreIndex && (
          <TableRow className={classes.tableContent}>
            <TableCell>
              <FormattedMessageWrapper id={'course.hcpMen'} />
            </TableCell>
            {frontNineHoles.map(this._renderCell('scoreIndexMen'))}
            <TableCell />
            {backNineHoles.map(this._renderCell('scoreIndexMen'))}
            <TableCell />
            <TableCell />
          </TableRow>
        )}

        {showScoreIndex && (
          <TableRow className={classes.tableContent}>
            <TableCell>
              <FormattedMessageWrapper id={'course.hcpWomen'} />
            </TableCell>
            {frontNineHoles.map(this._renderCell('scoreIndexWomen'))}
            <TableCell />
            {backNineHoles.map(this._renderCell('scoreIndexWomen'))}
            <TableCell />
            <TableCell />
          </TableRow>
        )}

        {showTees &&
          course.teeBoxes.map((tee) => {
            const f9Lengths = this._padArrayCount(tee.lengths.slice(0, 9))
            const b9Lengths = this._padArrayCount(tee.lengths.slice(9))
            return (
              <TableRow className={classes.tableContent} key={tee.id}>
                <TableCell>{tee.teeboxName}</TableCell>
                {f9Lengths.map(this._renderCell('length'))}
                <TableCell>{this._getLengthTotal(f9Lengths)}</TableCell>
                {b9Lengths.map(this._renderCell('length'))}
                <TableCell>{this._getLengthTotal(b9Lengths)}</TableCell>
                <TableCell>{this._getLengthTotal(tee.lengths)}</TableCell>
              </TableRow>
            )
          })}
      </>
    )
  }

  private _renderCourseSlopes = (course: RoundCourse) => {
    return (
      <>
        <TableRow className={this.props.classes.tableContent}>
          <TableCell>
            <FormattedMessageWrapper id={'course.tee'} />
          </TableCell>
          <TableCell>
            <FormattedMessageWrapper id={'course.slopeMen'} />
          </TableCell>
          <TableCell>
            <FormattedMessageWrapper id={'course.slopeWomen'} />
          </TableCell>
        </TableRow>
        {course.teeBoxes.map((tee) => (
          <TableRow className={this.props.classes.tableContent} key={tee.id}>
            <TableCell>{tee.teeboxName}</TableCell>
            <TableCell>
              {this._formatRatingOrSlope(tee.crMen)} / {this._formatRatingOrSlope(tee.slopeMen)}
            </TableCell>
            <TableCell>
              {this._formatRatingOrSlope(tee.crWomen)} / {this._formatRatingOrSlope(tee.slopeWomen)}
            </TableCell>
          </TableRow>
        ))}
      </>
    )
  }

  private _maybeRenderAdditionalWeeklyInfo = () => {
    const { classes, roundIndex, tournament, units, weeklyScore } = this.props
    const roundDate = formatDateToIsoWithTimeZone(
      weeklyScore?.startTime ?? new Date(),
      tournament?.rounds[roundIndex ?? 0]?.club?.timeZone,
    )
    const minDate = '01/01/1900'

    return (
      tournament?.tournamentType === TournamentTypes.weekly &&
      roundIndex !== undefined &&
      weeklyScore?.startTime &&
      new Date(weeklyScore?.startTime ?? minDate) > new Date(minDate) && (
        <>
          <Select
            id="teeBoxId"
            name="teeBoxId"
            onChange={this._handleRoundTeeBoxChange}
            value={this._getRoundTeeBoxId()}
            margin="none"
            disabled={false}
            size="small"
            style={{
              float: 'right',
              marginLeft: 15,
              border: 0,
              width: 120,
              height: 23,
              backgroundColor: 'white',
              fontSize: '0.8em',
            }}
          >
            {this._courseTees(weeklyScore.gender)}
          </Select>
          <TextField
            style={{
              float: 'right',
              marginLeft: 15,
              width: 40,
              border: 0,
            }}
            inputProps={{
              style: {
                background: 'white',
                padding: '2px 0',
                textAlign: 'center',
                borderRadius: 4,
                fontSize: '0.8em',
              },
              pattern: '[0-9+]*',
            }}
            type="string"
            variant="outlined"
            name="roundHcp"
            size="small"
            onChange={this._handleRoundHcpChange}
            value={this._getRoundHcp()}
          />
          <span className={classes.weeklyAdditionalInfo}>HCP</span>
          <span className={classes.weeklyAdditionalInfo}>
            {roundDate && units ? formatDate(roundDate, 'date', units) : ''}
          </span>
        </>
      )
    )
  }

  private _getRoundHcp = (): string => {
    if (this.state.roundHcp !== undefined) {
      return this.state.roundHcp
    }
    return formatHandicap(this.props.weeklyScore?.hcp?.toString() ?? '')
  }

  private _getRoundTeeBoxId = (): number => {
    if (this.state.roundTeeBoxId !== undefined) {
      return this.state.roundTeeBoxId
    }
    return this.props.weeklyScore?.teeBoxId ? this.props.weeklyScore.teeBoxId : 0
  }

  private _handleRoundHcpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { roundIndex, syncRoundHcp } = this.props
    const roundHcp = this._validateRoundHcpInput(e?.target?.value)
    this.setState({ roundHcp })
    if (syncRoundHcp && roundIndex !== undefined) {
      syncRoundHcp(roundIndex, roundHcp)
    }
  }

  private _handleRoundTeeBoxChange = (e) => {
    const { roundIndex, syncRoundTeeBox } = this.props
    const roundTeeBoxId = e?.target?.value
    this.setState({ roundTeeBoxId })
    if (syncRoundTeeBox && roundIndex !== undefined) {
      syncRoundTeeBox(roundIndex, roundTeeBoxId)
    }
  }

  private _validateRoundHcpInput = (input: string): string => {
    const regex = /^[0-9+.,]*$/
    const isValid = regex.test(input)
    const hcpValue = isValid ? input : input.replace(/[^0-9+.,]/g, '')
    const hcp = hcpValue.replace(',', '.')

    const minHcp = 10
    const maxHcp = 54
    const belowMin = hcp.includes('+') && Number(hcp) > minHcp
    const overMax = Number(hcp) > maxHcp
    const tooManyDecimals = hcp.split('.')[1]?.length > 1
    const invalidDecimalPoint = (hcp.length === 1 && hcp.includes('.')) || hcp.split('.').length > 2
    if (belowMin || overMax || tooManyDecimals || invalidDecimalPoint) {
      return hcp.slice(0, -1)
    }
    return hcp
  }

  get _holeCount(): number {
    const { course } = this.props
    if (course) {
      return course.holes.length
    }

    return 0
  }

  private _formatRatingOrSlope(value) {
    if (value === -1) {
      return '-'
    }
    return value
  }

  get _titleClassName(): string {
    return classNames('highlight', this.props.classes.titleCell)
  }

  private _getParTotal = (holes: CourseHole[]): number => {
    return holes.reduce((acc: number, hole: CourseHole) => acc + hole.par, 0)
  }

  private _getLengthTotal = (teeLengths: CourseTeeLength[]): number => {
    return teeLengths.reduce((acc: number, teeLength: CourseTeeLength) => acc + teeLength.length, 0)
  }

  private _getHoles = (niner: 'f9' | 'b9'): CourseHole[] => {
    const { course } = this.props
    if (course) {
      let holes: any[] = []
      if (niner === 'f9') {
        holes = course.holes.slice(0, 9)
      } else {
        holes = course.holes.slice(9)
      }

      return this._padArrayCount(holes)
    }

    return []
  }

  private _padArrayCount = (arr: any[]) => {
    if (arr.length < 9) {
      return arr.concat(
        Array.from({ length: 9 - arr.length }, () => ({
          length: 0,
          lengths: [],
        })),
      )
    }

    return arr
  }

  private _renderCell = (property: string) => (item: any, idx: number) => {
    const prop = get(item, property)
    return <TableCell key={idx}>{prop}</TableCell>
  }

  private _courseTees(gender: string | undefined) {
    const { course } = this.props
    const filterFn = gender === PlayerGender.MALE ? validMaleTeeBoxes : validFemaleTeeBoxes
    if (course) {
      const tees = filterFn(course?.teeBoxes).map((tee: CourseTee) => (
        <MenuItem key={tee.id} value={tee.id}>
          {tee.teeboxName}
        </MenuItem>
      ))

      if (!course.teeBoxes.find((tee: CourseTee) => tee.id === this._getRoundTeeBoxId())) {
        tees.unshift(
          <MenuItem key={this._getRoundTeeBoxId()} value={this._getRoundTeeBoxId()}>
            {this.props.weeklyScore?.teeBox ?? ''} (<FormattedMessageWrapper id={'course.oldVersion'} />)
          </MenuItem>,
        )
      }
      return tees
    }
    return
  }
}

export default withStyles(styles)(CourseGuideTable)
