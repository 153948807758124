import {
  CONDENSED_TOURNAMENTS_FETCH,
  TOURNAMENT_REMOVE,
  TOURNAMENTS_FETCH,
  TOURNAMENTS_FETCH_FAILURE,
  TOURNAMENTS_FETCH_SUCCESS,
  TOURNAMENT_CALENDAR_TOGGLE,
  FETCH_FILTER_YEARS,
  TOURNAMENTS_CLEAR_ALL,
} from './constants'
import { Action } from 'redux'

export interface FetchTournamentsSuccessAction extends Action {
  type: 'TOURNAMENTS_FETCH_SUCCESS'
  tournaments: Tournament[]
  calendarUrl: string
}
export interface FetchTournamentsFailureAction extends Action {
  type: 'TOURNAMENTS_FETCH_FAILURE'
  error: ErrorPayload
}

export interface FetchTournamentsAction extends Action, PagedAction, APICall {
  type: 'TOURNAMENTS_FETCH'
  organizationId: number
  searchTerm?: string
  sortBy?: TournamentSortOption
  direction?: string
  startDate?: Date | string
  endDate?: Date | string
  withoutDate?: number
}

export interface FetchTournamentsPayload {
  organizationId: number
  page?: number
  sortBy?: TournamentSortOption
  direction?: string
  searchTerm?: string
  startDate?: Date | string
  endDate?: Date | string
  withoutDate?: number
  onComplete?: (args: APICallResult) => void
}

export interface FetchCondensedTournamentsPayload {
  id?: number
  startDate?: Date | string
  endDate?: Date | string
}

export const fetchTournaments = ({
  organizationId,
  page,
  sortBy,
  direction,
  startDate,
  endDate,
  searchTerm,
  withoutDate,
  onComplete,
}: FetchTournamentsPayload): FetchTournamentsAction => {
  return {
    type: TOURNAMENTS_FETCH,
    organizationId,
    page,
    sortBy,
    direction,
    startDate,
    endDate,
    searchTerm,
    withoutDate,
    onComplete,
  }
}

export const fetchTournamentsSuccess = (tournaments: Tournament[], calendarUrl: string | null) => {
  return {
    type: TOURNAMENTS_FETCH_SUCCESS,
    tournaments,
    calendarUrl,
  }
}

export const fetchTournamentsFailure = (error: ErrorPayload) => {
  return {
    type: TOURNAMENTS_FETCH_FAILURE,
    error,
  }
}

/*
 * Tournament remove
 */
export interface TournamentRemoveAction extends Action {
  type: 'TOURNAMENT_REMOVE'
  tournamentId: number
  triggerFetch: () => void
}

export const removeTournament = (tournamentId: number, triggerFetch: () => void) => ({
  type: TOURNAMENT_REMOVE,
  tournamentId,
  triggerFetch,
})

export interface TournamentRemoveSuccessAction extends Action, SuccessAction {
  type: 'TOURNAMENT_REMOVE_SUCCESS'
}
export interface TournamentRemoveFailureAction extends Action {
  type: 'TOURNAMENT_REMOVE_FAILURE'
  error: ErrorPayload
}

/*
 * Tournament remove
 */
export interface FetchCondensedTournamentsAction extends Action {
  type: 'CONDENSED_TOURNAMENTS_FETCH'
  id?: number
  startDate?: Date | string
  endDate?: Date | string
}

export const fetchCondensedTournaments = (payload?: FetchCondensedTournamentsPayload) => ({
  type: CONDENSED_TOURNAMENTS_FETCH,
  id: payload?.id,
  startDate: payload?.startDate,
  endDate: payload?.endDate,
})

export interface FetchCondensedTournamentsSuccessAction extends Action, SuccessAction {
  type: 'CONDENSED_TOURNAMENTS_FETCH_SUCCESS'
  data: TournamentCondensed[]
}

/** CALENDAR */

export interface TournamentCalendarToggleAction extends Action {
  type: 'TOURNAMENT_CALENDAR_TOGGLE'
  id: number
  showInCalendar: boolean
}

export interface TournamentCalendarToggleSuccessAction extends SuccessAction {
  type: 'TOURNAMENT_CALENDAR_TOGGLE_SUCCESS'
  id: number
  showInCalendar: boolean
}

export interface TournamentCalendarToggleFailureAction extends Action {
  type: 'TOURNAMENT_CALENDAR_TOGGLE_FAILURE'
  error: ErrorPayload
}

export const toggleCalendarTournament = (id: number, showInCalendar: boolean) => {
  return {
    type: TOURNAMENT_CALENDAR_TOGGLE,
    id,
    showInCalendar,
  }
}

/**
 * FETCH YEARS FOR FILTER
 */

export interface FetchFilterYearsAction extends Action {
  type: 'FETCH_FILTER_YEARS'
  organizationId: number
}

export interface FetchFilterYearsSuccessAction extends Action {
  type: 'FETCH_FILTER_YEARS_SUCCESS'
  years: string[]
}

export interface FetchFilterYearsFailureAction extends Action {
  type: 'FETCH_FILTER_YEARS_FAILURE'
  error: ErrorPayload
}

export const fetchFilterYears = (organizationId): FetchFilterYearsAction => ({
  type: FETCH_FILTER_YEARS,
  organizationId,
})

export interface ClearAllTournamentsAction extends Action {
  type: 'TOURNAMENTS_CLEAR_ALL'
}
export const clearAllTournaments = () => {
  return {
    type: TOURNAMENTS_CLEAR_ALL,
  }
}
