/**
 * TODO: PHASE:MULTIROUND Current implementation might not work in multiround
 * - Publish info is round specific, not tournament specific
 * - Refactor Redux needUpdate state to use startlistReducer
 * - Banner must know which round it is publishing
 * - Add round info to banner text
 */

import React, { useMemo } from 'react'
import { Drawer, Grid, Theme, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { unstable_usePrompt as usePrompt } from 'react-router-dom'
import { UpdateChangesButton } from '@app/components/tournament/TournamentFormActions'
import { updateTournamentRoundStatus } from '@app/store/api/thunks/tournamentRoundThunks'
import { enqueueNotification } from '@store/notifications/actions'
import { TournamentPages, TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { selectTournamentConfig, setTournamentNeedsUpdate } from '@app/store/api/slices/configSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { loadTournamentLeaderboard } from '@app/store/tournamentLeaderboard/actions'

const checkWindowLocation = (location: string, regex: RegExp): boolean => {
  return location.match(regex) !== null
}

const useClasses = makeStyles((theme: Theme) => ({
  root: {
    // Drawer
    zIndex: 900000,
  },
  paper: {
    // Drawer
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  container: {
    // Grid
    justifyContent: 'center',
    alignItems: 'baseline',
  },
}))

const useStyles = () => {
  const theme = useTheme()
  return {
    updateButton: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  }
}

export const UpdateChangesBanner: React.FC = () => {
  const { root, paper, container } = useClasses()
  const styles = useStyles()
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const windowLocation = window.location.pathname

  const [needsUpdate, setNeedsUpdate] = React.useState<boolean>(false)
  const [isUpdateChangesButtonDisabled, setIsUpdateChangesButtonDisabled] = React.useState<boolean>(false)

  const tournament = useSelector(selectTournament)
  const tournamentConfig = useSelector(selectTournamentConfig)

  const isStartListPublished = (selectedRoundIndex: number): boolean => {
    const { rounds } = tournament
    if (rounds.length < 1) {
      return false
    }
    const currentRound = rounds[selectedRoundIndex]

    return currentRound && !!currentRound.status?.isConfigured
  }

  /**
   * Confirm if user wants to navigate away from tournament before updating changes
   * Can't leave if
   * - update is needed
   * - start list is published
   * - target window is not a tournament
   */
  const shouldShowPrompt = () => {
    const cantFollowLink =
      needsUpdate &&
      isStartListPublished(tournamentConfig.selectedRoundIndex) &&
      !checkWindowLocation(windowLocation, regexTournamentPath) &&
      !tournament.resultsPublished
    return cantFollowLink
  }
  usePrompt({ when: shouldShowPrompt(), message: intl.formatMessage({ id: 'notifications.updateNeededAlert' }) })

  const firstRound = () => {
    return tournament.rounds[0]
  }

  /**
   * Write to browser local storage
   * @param value boolean
   */
  const writeToLocalStorage = (value: boolean) => window.localStorage.setItem('needsUpdate', String(value))

  /**
   * Checks if location path matches a tournament
   */
  const regexTournamentPath = useMemo(() => /tournament.?\/\d+/, [])

  /**
   * Is update banner visible
   */
  const isOpen =
    tournament.tournamentType === TournamentTypes.weekly
      ? needsUpdate && checkWindowLocation(windowLocation, regexTournamentPath) && firstRound().id > 0
      : needsUpdate &&
        checkWindowLocation(windowLocation, regexTournamentPath) &&
        isStartListPublished(tournamentConfig.selectedRoundIndex)

  /**
   * Gets state from local storage if browser is reloaded
   */
  React.useEffect(() => {
    const getLocalStorage = window.localStorage.getItem('needsUpdate') === 'true'
    dispatch(setTournamentNeedsUpdate(getLocalStorage))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Updates state if location is correct
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (checkWindowLocation(windowLocation, regexTournamentPath) && needsUpdate !== tournamentConfig.needsUpdate) {
      setNeedsUpdate(tournamentConfig.needsUpdate)
      writeToLocalStorage(tournamentConfig.needsUpdate)
    }

    /**
     * Confirm if user wants to close browser before updating changes
     * Can't exit if
     * - update is needed
     * - start list is published
     * - window location is in a tournament
     */
    const confirmExit = () => (isOpen ? intl.formatMessage({ id: 'notifications.updateNeededAlert' }) : null)
    window.onbeforeunload = confirmExit
  }, [tournamentConfig.needsUpdate, intl, regexTournamentPath, windowLocation, isOpen, needsUpdate])

  /**
   * Update button handling
   */
  const handleOnClick = () => {
    if (tournament.id) {
      setIsUpdateChangesButtonDisabled(true)
      dispatch(
        updateTournamentRoundStatus({
          tournamentId: tournament.id,
          roundId: firstRound().id,
          body: {
            isConfigured: true,
          },
          onSuccess: () => {
            dispatch(setTournamentNeedsUpdate(false))
            writeToLocalStorage(false)
            setIsUpdateChangesButtonDisabled(false)
            dispatch(enqueueNotification(intl.formatMessage({ id: 'notifications.updatedSuccess' }), 'success'))
            const isResultsAndOptionsPage = windowLocation.includes(TournamentPages.RESULTS_AND_OPTIONS)
            if (isResultsAndOptionsPage) {
              dispatch(loadTournamentLeaderboard({ id: tournament.id }))
            }
          },
          onFailure: () => {
            setIsUpdateChangesButtonDisabled(false)
            dispatch(enqueueNotification(intl.formatMessage({ id: 'notifications.updatedFailure' }), 'error'))
          },
        }),
      )
    }
  }

  return (
    <Drawer anchor="bottom" variant="persistent" open={isOpen} classes={{ root, paper }}>
      <Grid container spacing={4} classes={{ container }}>
        <Grid item>
          <FormattedMessageWrapper id="notifications.updateNeededBanner" />
        </Grid>
        <Grid item>
          <UpdateChangesButton
            filled
            onClick={handleOnClick}
            disabled={isUpdateChangesButtonDisabled}
            style={styles.updateButton}
          />
        </Grid>
      </Grid>
    </Drawer>
  )
}
