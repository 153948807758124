export const PrintIcon = ({ className = '', fill = '#1AA85D' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" fill="none" viewBox="0 0 26 25" className={className}>
    <path
      fill={fill}
      fillRule="evenodd"
      // eslint-disable-next-line max-len
      d="M6.492 16.466v8.232h12.985v-8.232H6.492zm1.623 3.292h9.739v-1.646h-9.74v1.646zm0 3.293h9.739v-1.646h-9.74v1.646zM6.492 0v8.233h12.985V0H6.492z"
      clipRule="evenodd"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      // eslint-disable-next-line max-len
      d="M22.724 4.94h-1.623v4.94H4.869V4.94H3.247C1.624 4.94 0 6.586 0 8.233v8.233c0 1.646 1.624 3.292 3.247 3.292h1.622V14.82h16.232v4.939h1.623c1.624 0 3.247-1.646 3.247-3.292V8.233c0-1.647-1.623-3.293-3.247-3.293z"
      clipRule="evenodd"
    ></path>
  </svg>
)
