import { emptyStartListRound } from '../emptyObjects'
import { formatDateToIsoWithTimeZone } from '@app/utils/dates'
import { store } from '@app/store'
import { selectTournamentTeams } from '../endpoints/tournamentTeamsApi'
import { processGroups } from '@app/utils/sagaHelpers'
import { createReferenceIdsForGroupsInStartListRound } from '@app/utils/startListUtils'
import { pick } from 'lodash'
import { selectTournamentPlayers } from '../endpoints/tournamentPlayersApi'
import { PlayerStatus } from '../enums/tournamentPlayersEnums'
import { RootState } from '@app/store'
import { selectTournament } from '../endpoints/tournamentApi'
import { CacheTag, CacheTagSingle } from '../cacheTags'

export const processStartListRound = (payload: StartListPayload, data: StartListRound) => {
  const state: RootState = store.getState()
  const { roundId } = payload
  const tournamentTeams: TournamentTeam[] = selectTournamentTeams(state).data?.teams || []
  const resultData: StartListRound = {
    ...data,
    tournamentRoundId: roundId,
  }
  const processedGroups = processGroups(resultData.groups, tournamentTeams, roundId)
  resultData.groups = processedGroups

  return createReferenceIdsForGroupsInStartListRound({ ...emptyStartListRound, ...resultData })
}

export const processStartListGroupPayload = (
  roundId: number,
  payload: UpdateStartListGroupBody,
): UpdateStartListGroupBody => {
  const tournament = selectTournament(store.getState())
  const round = tournament?.rounds?.find((round) => round.id === roundId)
  return { ...payload, startTime: formatDateToIsoWithTimeZone(payload.startTime, round?.club?.timeZone) }
}

export const processCreateStartListBody = (): CreateStartListBody => {
  const state: RootState = store.getState()
  const tournament = selectTournament(state)
  const startListRounds: StartListRound[] = state.tournamentStartListsReducer.rounds
  const unassignedTeams: TournamentTeam[] = selectTournamentTeams(state).data?.teams || []
  const { selectedRoundIndex } = state.configReducer.tournament
  const currentRound = startListRounds[selectedRoundIndex]
  let data: CreateStartListBody = pick(currentRound, [
    'startType',
    'basicGroupSize',
    'teeInterval',
    'fillStartListByType',
    'groupStartTees',
  ])
  const teamsOrder: PlayersOrder = unassignedTeams.reduce((acc, team, index) => ({ ...acc, [team.id]: index }), {})
  if (tournament.isTeamFormat) {
    data.teamsOrder = teamsOrder
  }
  if (data.fillStartListByType === 'poolOrder') {
    /**
     * Send players in exact
     * same order that they are in pool.
     */
    const players: TournamentPlayer[] = selectTournamentPlayers(state).data?.players || []

    const playersOrder = {}
    players
      .filter((player) => !player.status || player.status === PlayerStatus.OK)
      .forEach((player, idx) => {
        playersOrder[player.userId] = idx
      })

    data = {
      ...data,
      playersOrder: playersOrder,
    }
  }

  return data
}

export const mapTournamentPlayerToNewStartListPlayer = (player: TournamentPlayer): StartListPlayer => {
  return {
    id: -1,
    tournamentPlayerId: player.id,
    teeBox: player.preferredTeeBox || undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    hcp: player.hcp,
    groupOrder: 0,
    playingHandicap: player.hcp,
    firstName: player.firstName,
    lastName: player.lastName,
  }
}

export const getActiveStartListCacheTags = (): CacheTagSingle[] => {
  const tagsToInvalidate: CacheTagSingle[] = []

  if (store) {
    const state: RootState = store.getState()
    const tournament = selectTournament(state)

    const tournamentRoundIds = tournament.rounds.map((round) => round.id).flat()
    tournamentRoundIds.forEach((roundId) => {
      tagsToInvalidate.push({ type: CacheTag.TOURNAMENT_STARTLISTS, id: roundId })
    })
  }
  return tagsToInvalidate
}
