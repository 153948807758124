import { store } from '@app/store'
import { sortPlayers } from '@app/utils/playerUtils'
import { selectTournamentTeams } from '../endpoints/tournamentTeamsApi'
import { RootState } from '@app/store'

export const processPlayer = (player: UnprocessedTournamentPlayer): TournamentPlayer => {
  return {
    ...player,
    firstName: player.firstName === null ? '' : player.firstName,
    lastName: player.lastName === null ? '' : player.lastName,
    resultStatus: player.resultStatus === null ? '' : player.resultStatus,
    country: player.country === null ? '' : player.country,
    countryCode: player.countryCode === null ? '' : player.countryCode,
    isSelected: false,
  }
}

export const processPlayersResponse = (response: TournamentPlayersUnprocessed): TournamentPlayers => {
  const state: RootState = store.getState()
  const tournamentConfig: TournamentConfig = state.configReducer.tournament
  const teams = selectTournamentTeams(state).data?.teams || []

  const processedPlayers: TournamentPlayer[] = response.players.map(processPlayer)
  const processedReserveList: TournamentPlayer[] = response.reserveList.map(processPlayer)

  sortPlayers(processedPlayers, teams, tournamentConfig.sortBy, tournamentConfig.sortDirection)
  sortPlayers(processedReserveList, teams, tournamentConfig.sortBy, tournamentConfig.sortDirection)

  return {
    players: processedPlayers,
    reserveList: processedReserveList,
  }
}

export const processPlayersImportFile = (file: File): FormData => {
  const formData = new FormData()
  formData.append('excel', file, file.name)
  return formData
}
