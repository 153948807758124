import React, { LegacyRef } from 'react'
import Dialog from '@mui/material/Dialog'
import BaseDialogTitle from '../ui/BaseDialogTitle'
import Button from '@mui/material/Button'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import DialogActions from '@mui/material/DialogActions'
import { DialogContent } from '@mui/material'
import ButtonLoaderWrap from '../../ui/ButtonLoaderWrap'
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop'

interface OwnProps {
  open?: boolean
  src?: null | string
  round?: boolean
  crop: Crop
  aspect?: number
  onClose?(image?: string): void
}

interface State {
  crop: Crop | undefined
}

class CropImageDialog extends React.Component<OwnProps, State> {
  _imageRef: LegacyRef<HTMLImageElement> | undefined

  state: State = {
    crop: undefined,
  }

  constructor(props: OwnProps) {
    super(props)
    this._imageRef = React.createRef()
  }

  render() {
    const { open = false, round = false, src } = this.props
    return (
      <Dialog maxWidth={'lg'} open={open} scroll={'body'} fullWidth keepMounted={false}>
        <BaseDialogTitle id={'crop-image-dialog-title'} title={<FormattedMessageWrapper id="tournament.cropImage" />} />

        <DialogContent>
          <div
            style={{
              textAlign: 'center',
              background: '#dddddd',
              padding: '1em',
            }}
          >
            {src && (
              <ReactCrop
                circularCrop={round}
                crop={{ ...this.props.crop, ...this.state.crop }}
                onChange={this._onCropChange}
                aspect={this.props.aspect}
              >
                <img ref={this._imageRef} src={src} onLoad={this._onImageLoaded} />
              </ReactCrop>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={this._handleClose.bind(this, undefined)} style={{ marginRight: 10 }}>
            <FormattedMessageWrapper id={'buttons.close'} />
          </Button>
          <ButtonLoaderWrap loading={false}>
            <Button disabled={false} color="primary" variant={'contained'} onClick={this._onClickSave}>
              <FormattedMessageWrapper id={'buttons.save'} />
            </Button>
          </ButtonLoaderWrap>
        </DialogActions>
      </Dialog>
    )
  }

  public _onCropChange = (crop) => {
    this.setState({ crop })
  }

  public _onClickSave = async () => {
    const croppedImg = await this._getCroppedImg(this._imageRef, this.state.crop, 'cover.jpeg')
    this._handleClose(croppedImg)
  }

  public _handleClose = (image?: string): void => {
    const { onClose } = this.props
    if (onClose) {
      onClose(image)
      this.setState({ crop: undefined })
    }
  }

  private _onImageLoaded = (e: any) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget
    this._imageRef = e.currentTarget

    if (this.props.aspect) {
      const crop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 90,
          },
          this.props.aspect,
          width,
          height,
        ),
        width,
        height,
      )

      this.setState({ crop })
    }
  }

  private _getCroppedImg = (image, crop, fileName): any => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      )

      // As Base64 string
      // return canvas.toDataURL('image/jpeg');
      return new Promise((resolve) => {
        const format = this.props.src && this.props.src.includes('png') ? 'image/png' : 'image/jpeg'
        canvas.toBlob((blob) => {
          if (!blob) {
            console.error('Canvas is empty')
            return
          }
          ;(blob as any).name = fileName
          resolve(blob)
        }, format)
      })
    }
  }
}

export default CropImageDialog
