import { FetchUsersAction, UsersFetchErrorAction, UsersFetchSuccessAction } from './actions'
import { USERS_FETCH, USERS_FETCH_ERROR, USERS_FETCH_SUCCESS } from './constants'

const initialState: OrganizationUsersState = {
  loading: false,
  error: null,
  users: [],
}

type UsersAction = FetchUsersAction | UsersFetchSuccessAction | UsersFetchErrorAction

export const organizationUsersReducer = (
  state: OrganizationUsersState = initialState,
  action: UsersAction,
): OrganizationUsersState => {
  switch (action.type) {
    case USERS_FETCH:
      return {
        ...state,
        loading: true,
      }
    case USERS_FETCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    case USERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users,
      }
    default:
      return state
  }
}
