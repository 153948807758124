export const BracketIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="21" fill="none" viewBox="0 0 31 21">
    <mask id="path-1-inside-1_1914_1324" fill="#fff">
      <rect width="13" height="9" x="9" rx="1"></rect>
    </mask>
    <rect
      width="13"
      height="9"
      x="9"
      stroke="#fff"
      strokeWidth="4"
      mask="url(#path-1-inside-1_1914_1324)"
      rx="1"
    ></rect>
    <mask id="path-2-inside-2_1914_1324" fill="#fff">
      <rect width="13" height="9" y="12" rx="1"></rect>
    </mask>
    <rect
      width="13"
      height="9"
      y="12"
      stroke="#fff"
      strokeWidth="4"
      mask="url(#path-2-inside-2_1914_1324)"
      rx="1"
    ></rect>
    <mask id="path-3-inside-3_1914_1324" fill="#fff">
      <rect width="13" height="9" x="18" y="12" rx="1"></rect>
    </mask>
    <rect
      width="13"
      height="9"
      x="18"
      y="12"
      stroke="#fff"
      strokeWidth="4"
      mask="url(#path-3-inside-3_1914_1324)"
      rx="1"
    ></rect>
    <path stroke="#fff" d="M15.5 9L15.5 11"></path>
    <path stroke="#fff" d="M6.5 11L6.5 13"></path>
    <path stroke="#fff" d="M24.5 10L24.5 12"></path>
    <path stroke="#fff" d="M6 10.5L15 10.5"></path>
    <path stroke="#fff" d="M16 10.5L24 10.5"></path>
  </svg>
)
