import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { get } from 'lodash'
import { Typography, Grid, Table, TableHead, TableRow, TableCell, CircularProgress, TableBody } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import ContentWrap from '../../components/layout/ContentWrap'
import HeadHelmet from '../../components/layout/HeadHelmet'
import BorderButton from '../../components/ui/BorderButton'
import TopBar from '../../components/layout/TopBar'
import { confirm } from '../../components/dialogs/confirm/Confirm'
import { isRoleAdmin } from '../../utils/authUtils'
import { DefaultProfileBg } from '../../assets/images'
import { fetchOrganization } from '../../store/organization/actions'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import dashboardStyles from './styles'
import { fetchTournaments, FetchTournamentsPayload } from '../../store/tournaments/actions'
import { LaunchOutlined } from '@mui/icons-material'
import { formTournamentSiteUrl } from '../../config'
import { checkAuth } from '../../store/authentication/actions'
import ManagerFeaturesLift from '../../components/layout/ManagerFeaturesLift'
import InfoTooltip from '../../components/ui/InfoTooltip'
import { formatDate } from '../../utils/dates'
import { RootState } from '@app/store'

interface StateProps {
  auth: AuthenticationState
  organization: OrganizationState
  tournaments: TournamentsState
}

interface DispatchProps {
  fetchOrganization: (id: number) => void
  fetchTournaments(payload: FetchTournamentsPayload): any
  checkAuth: () => void
}

type Props = StateProps & DispatchProps & WithStyles<typeof dashboardStyles> & WrappedComponentProps

interface State {
  editModalOpen: boolean
}

class Dashboard extends React.Component<Props, State> {
  readonly state: State = {
    editModalOpen: false,
  }

  componentDidMount(): void {
    const { fetchOrganization, fetchTournaments, auth } = this.props

    const organizationId = get(auth, 'customerInfo.idCustomer')

    if (organizationId) {
      fetchOrganization(organizationId)
      fetchTournaments({
        organizationId,
        page: 1,
        sortBy: 'date',
        startDate: new Date(),
        direction: 'asc',
        searchTerm: '',
      })
    }

    // Check auth to update license info
    this.props.checkAuth()
  }

  render(): React.ReactNode {
    const { organization, auth, classes } = this.props

    return (
      <>
        <HeadHelmet titleId={'navigation.dashboard'} />
        <TopBar />

        <OverlayLoader visible={organization.loading} />

        {this._renderHero()}

        <ContentWrap>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <div className={classes.notification}>
                <div style={{ position: 'absolute', right: 0, marginTop: 0 }}>
                  <InfoTooltip text={<FormattedMessageWrapper id="dashboard.licenseInfo" />} size={'large'} />
                </div>
                {this._renderSubscriptions()}
                {this._renderLicenses()}

                {isRoleAdmin(auth.roleInfo) && (
                  <BorderButton
                    filled={true}
                    buttonProps={{
                      onClick: () => {
                        const { intl } = this.props
                        confirm({
                          messageHTML: intl.formatMessage({ id: 'tournament.contactSalesPerson' }),
                          options: {
                            cancelText: intl.formatMessage({ id: 'buttons.ok' }),
                          },
                        })
                      },
                      style: { marginTop: 10, maxWidth: 200 },
                    }}
                  >
                    <FormattedMessageWrapper id={'buttons.purchaseMore'} />
                  </BorderButton>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.notification}>
                <Typography variant={'h3'}>
                  <FormattedMessageWrapper id={'dashboard.upcomingTournaments'} />
                </Typography>

                <Table size={'small'} style={{ marginTop: 10 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ paddingLeft: 0 }}>
                        <FormattedMessageWrapper id={'dashboard.name'} />
                      </TableCell>
                      <TableCell>
                        <FormattedMessageWrapper id={'dashboard.date'} />
                      </TableCell>
                      <TableCell>
                        <FormattedMessageWrapper id={'dashboard.status'} />
                      </TableCell>
                      <TableCell style={{ width: 50 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{this._renderUpcomingTournaments()}</TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>
        </ContentWrap>
        <ContentWrap>
          <ManagerFeaturesLift />
        </ContentWrap>
      </>
    )
  }

  get tournamentsLink() {
    return React.forwardRef((props: any, ref: any) => <NavLink to={'/tournaments'} ref={ref} {...props} />)
  }

  private _renderHero() {
    const { organization, classes, auth, intl } = this.props
    const userName = get(auth, 'name') || intl.formatMessage({ id: 'dashboard.visitor' })
    const organizationName = get(organization, 'nameMarketing') || get(organization, 'name')
    const logoImageUrl = get(organization, 'logoImageUrl')
    const heroBg = get(organization, 'coverImageUrl') || DefaultProfileBg

    return (
      <div className={classes.hero} style={{ backgroundImage: `url(${heroBg})` }}>
        <div className={classes.heroWrapper}>
          <div>
            <Typography variant={'h2'}>
              <FormattedMessageWrapper id={'dashboard.hello'} values={{ name: userName }} />
            </Typography>
            <Typography variant={'subtitle1'}>
              <FormattedMessageWrapper id={'dashboard.welcome'} />
            </Typography>
          </div>
          <div className={classes.heroOrganization}>
            {organizationName && <Typography variant={'subtitle1'}>{organizationName}</Typography>}
            {logoImageUrl && (
              <div className={classes.organizationImage} style={{ backgroundImage: `url(${logoImageUrl})` }} />
            )}
          </div>
        </div>
      </div>
    )
  }

  private _renderLicenses() {
    const { classes, auth } = this.props
    const amount = auth.license.tournamentsLeft

    return (
      <React.Fragment>
        <Typography variant={'h3'}>
          <FormattedMessageWrapper id={'dashboard.licenses'} />
        </Typography>

        <Typography variant="body1" style={{ marginBottom: 20 }}>
          <span className={classes.amount}>{amount}</span> <FormattedMessageWrapper id="dashboard.tournaments" />
        </Typography>
      </React.Fragment>
    )
  }

  private _renderSubscriptions() {
    const { classes, auth } = this.props
    const amount = auth.subscription.daysLeft

    return (
      <React.Fragment>
        <Typography variant={'h3'}>
          <FormattedMessageWrapper id={'dashboard.subscription'} />
        </Typography>

        <Typography variant="body1" style={{ marginBottom: 20 }}>
          {amount ? (
            <>
              <span className={classes.amount}>{amount}</span> <FormattedMessageWrapper id="dashboard.daysRemaining" />
            </>
          ) : (
            <FormattedMessageWrapper id="dashboard.noSubscription" />
          )}
        </Typography>
      </React.Fragment>
    )
  }

  private _renderUpcomingTournaments() {
    const { tournaments, auth } = this.props
    const loading = tournaments.loading
    const upcoming = tournaments.tournaments.slice(0, 4)

    return (
      <React.Fragment>
        {loading ? (
          <TableRow>
            <TableCell colSpan={4}>
              <div style={{ textAlign: 'center', width: '100%', margin: '30px 0' }}>
                <CircularProgress size={28} thickness={2} />
              </div>
            </TableCell>
          </TableRow>
        ) : (
          <>
            {upcoming.map((t) => (
              <TableRow key={t.id}>
                <TableCell style={{ paddingLeft: 0 }}>{t.name}</TableCell>
                <TableCell>{t.startTime ? formatDate(t.startTime, 'datetime', auth.units) : '-'}</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {t.id && t.tournamentSiteEnabled && (
                    <a
                      style={{ lineHeight: 0, padding: '0 5px' }}
                      href={formTournamentSiteUrl(t.id)}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <LaunchOutlined color={'primary'} fontSize={'small'} />
                    </a>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
      </React.Fragment>
    )
  }
}

export default connect<StateProps, DispatchProps, {}, RootState>(
  (store) => ({
    auth: store.authenticationReducer,
    organization: store.organizationReducer,
    tournaments: store.tournamentsReducer,
  }),
  {
    fetchOrganization,
    fetchTournaments,
    checkAuth,
  },
)(injectIntl(withStyles(dashboardStyles)(Dashboard)))
