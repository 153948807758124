export const RefreshIcon = ({ className = '', fill = '#fff' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" fill="none" viewBox="0 0 17 19" className={className}>
    <path
      fill={fill}
      fillRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9.472 14.645l-2.792 1.7c-.402.245-.401.64.001.882l2.767 1.667c.403.242.731.056.733-.416l.015-3.42c.001-.473-.323-.657-.724-.413zM7.552 4.356L10.32 2.69c.402-.243.403-.638.002-.882L7.529.108c-.402-.244-.726-.06-.724.412l.015 3.421c.001.472.329.658.732.415zm6.493-1.337c-.87-.79-2.145.492-1.31 1.128a6.838 6.838 0 012.564 5.35 6.83 6.83 0 01-5.1 6.616v1.763c3.88-.793 6.8-4.243 6.8-8.38 0-2.59-1.145-4.91-2.954-6.477zM4.24 14.827a6.838 6.838 0 01-2.54-5.33 6.832 6.832 0 015.1-6.618V1.116C2.92 1.91 0 5.36 0 9.496a8.55 8.55 0 002.882 6.416c1.368 1.281 2.218-.43 1.358-1.085z"
      clipRule="evenodd"
    ></path>
  </svg>
)
