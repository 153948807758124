import { Switch, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'

export interface EndRoundSwitchComponentProps {
  checked: boolean
  disabled?: boolean
  onChange: (roundIndex: number, selected: boolean) => void
  roundIndex: number
  style: React.CSSProperties
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#4e4e4e',
    width: rem(100),
    height: rem(130),
    borderRadius: rem(5),
  },
  roundNumber: {
    color: '#fff',
    fontSize: rem(28),
    fontWeight: 'bold',
  },
  switch: {
    left: 'auto',
  },
}))

const EndRoundSwitchComponent: React.FC<EndRoundSwitchComponentProps> = ({
  checked,
  disabled = false,
  onChange,
  roundIndex,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root} {...rest}>
      <Typography variant="subtitle1" className={classes.roundNumber}>
        {roundIndex + 1}
      </Typography>
      <Switch
        checked={checked}
        classes={{ root: classes.switch }}
        color="primary"
        disabled={disabled}
        onChange={(event) => onChange(roundIndex, event.currentTarget.checked)}
        size="medium"
      />
    </div>
  )
}

export const EndRoundSwitch = EndRoundSwitchComponent
