import React from 'react'
import { TableCell, Checkbox, Tooltip, TableRow } from '@mui/material'
import { VeryDenseTableCell } from '../tables/tableComponents'
import { Theme } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { Settings, LaunchOutlined, DescriptionOutlined } from '@mui/icons-material'
import NoWrapTooltip from '../ui/NoWrapTooltip'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import TextButton from '../ui/TextButton'
import { formTournamentSiteUrl } from '../../config'
import { formatDate } from '../../utils/dates'
import { Link } from 'react-router-dom'
import BorderButton from '../ui/BorderButton'
import { format } from 'date-fns'

type Props = {
  tournament: Tournament
  joinCode: string
  viewCode: string
  units: OrganizationUnits
  showCalendarCheckbox?: boolean
  toggleCalendar: (tournamentId: number, checked: boolean) => void
  getTournamentStatus: () => string
  tuneTournament: (tournament: Tournament) => void
  openNoteDialog: (tournament: Tournament) => void
}

const styles = (theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.customPalette.light,
      color: theme.customPalette.black,
      fontSize: 12,
      padding: 26,
      border: '1px solid ' + theme.customPalette.main,
      borderRadius: 0,
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.4)',
      fontFamily: theme.fonts.secondary,
    },
  })

const TournamentsTableRow = ({
  tournament,
  units,
  joinCode,
  viewCode,
  classes,
  showCalendarCheckbox = true,
  toggleCalendar,
  getTournamentStatus,
  tuneTournament,
  openNoteDialog,
}: WithStyles<typeof styles> & Props) => {
  return (
    <>
      <TableRow key={tournament.id}>
        {showCalendarCheckbox && (
          <VeryDenseTableCell>
            {/* calendar checkbox */}
            <Tooltip
              title={
                <FormattedMessageWrapper
                  id={tournament.showInCalendar ? 'tournaments.removeFromCalendar' : 'tournaments.addToCalendar'}
                />
              }
            >
              <div>
                <Checkbox
                  color={'primary'}
                  checked={tournament.showInCalendar}
                  onChange={(_, checked: boolean) => toggleCalendar(tournament.id, checked)}
                />
              </div>
            </Tooltip>
          </VeryDenseTableCell>
        )}
        <VeryDenseTableCell>
          {/* date */}
          {tournament.startTime
            ? format(new Date(tournament.startTime), units === 'metric' ? 'd.M.' : 'M/d')
            : units === 'metric'
            ? '-.-'
            : '-/-'}
        </VeryDenseTableCell>

        <VeryDenseTableCell>
          {/* year */}
          {tournament.startTime ? formatDate(tournament.startTime, 'year', units) : '-'}
        </VeryDenseTableCell>
        <TableCell size={'small'}>
          <TextButton
            style={{ padding: '10px 0' }}
            component={React.forwardRef((props: any, ref: any) => (
              <Link to={`/tournaments/${tournament.id}`} {...props} ref={ref} />
            ))}
          >
            {tournament.name}
          </TextButton>
        </TableCell>
        <TableCell>
          {tournament.hcpRound && (
            <BorderButton fgu filled dense>
              <span>
                <FormattedMessageWrapper id="tournaments.hcpBadge" />
              </span>
            </BorderButton>
          )}
        </TableCell>
        <TableCell size={'small'}>
          {/* course */}
          {tournament.course}
        </TableCell>
        <TableCell size={'small'}>
          {/* created by */}
          <NoWrapTooltip
            title={
              <FormattedMessageWrapper
                id={'tournaments.updatedBy'}
                values={{
                  updatedBy: tournament.updatedBy || 'unknown',
                }}
              />
            }
          >
            <span>{tournament.createdBy}</span>
          </NoWrapTooltip>
        </TableCell>
        <TableCell size={'small'}>
          {/* Status */}
          {getTournamentStatus()}
        </TableCell>
        <TableCell size={'small'}>
          {joinCode} / {viewCode}
        </TableCell>
        <VeryDenseTableCell>
          {/* Notes */}
          {tournament.internalNote && (
            <Tooltip
              title={<div style={{ whiteSpace: 'pre-line' }}>{tournament.internalNote}</div>}
              classes={classes}
              placement="bottom-end"
            >
              <div style={{ padding: '0 10px' }}>
                <DescriptionOutlined color={'primary'} onClick={() => openNoteDialog(tournament)} />
              </div>
            </Tooltip>
          )}
        </VeryDenseTableCell>
        <VeryDenseTableCell>
          {/* www */}
          {tournament.id && tournament.tournamentSiteEnabled ? (
            <a
              style={{ lineHeight: 0, padding: '0 5px' }}
              href={formTournamentSiteUrl(tournament.id)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <LaunchOutlined color={'primary'} />
            </a>
          ) : (
            <div style={{ padding: '0 5px' }}>
              <LaunchOutlined color={'disabled'} />
            </div>
          )}
        </VeryDenseTableCell>
        <VeryDenseTableCell>
          <Settings
            color={'primary'}
            style={{ lineHeight: 0, margin: '0 10px', cursor: 'pointer' }}
            onClick={() => tuneTournament(tournament)}
          />
        </VeryDenseTableCell>
      </TableRow>
    </>
  )
}

export default withStyles(styles)(TournamentsTableRow)
