import { api } from '../baseApi'
import { APIRoute } from '../../../config'
import { RootState } from '@app/store'
import { CacheTag } from '../cacheTags'
import { setWeeklyScoreParams } from '../slices/configSlice'

export const tournamentWeeklyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWeeklyPlayerScores: build.query<ScoreRounds[], TournamentWeeklyScoresPayload>({
      query: (payload) => APIRoute.GET_WEEKLY_PLAYER_SCORES(payload.tournamentId, payload.playerId),
      transformResponse: (response: { scoreRounds: ScoreRounds[] }) => {
        return response.scoreRounds
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(setWeeklyScoreParams({ playerId: payload.playerId }))
        } catch {
          console.error('Error fetching weekly player scores')
        }
      },
      providesTags: [CacheTag.TOURNAMENT_WEEKLY_SCORES],
    }),
  }),
})

export const selectWeeklyScoreRounds = (state: RootState, payload: TournamentWeeklyScoresPayload) =>
  tournamentWeeklyApi.endpoints.getWeeklyPlayerScores.select(payload)(state).data

export const { useGetWeeklyPlayerScoresQuery } = tournamentWeeklyApi
