export const RankingIcon = ({
  style = {},
  fill = '#1AA85D',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 55 55',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
  >
    <path d="M16.92,16.85H50.37a2.13,2.13,0,1,0,0-4.26H16.92a2.13,2.13,0,1,0,0,4.26Z" />
    <path d="M50,25.38H16.59a2.13,2.13,0,0,0,0,4.26H50a2.13,2.13,0,1,0,0-4.26Z" />
    <path d="M50,38.16H16.59a2.14,2.14,0,0,0,0,4.27H50a2.14,2.14,0,0,0,0-4.27Z" />
    <polygon
      points="5.87 19.96 8.05 19.96 8.05 8.99 7.81 8.99 3.27 10.61 3.27 12.38 5.87
    11.58 5.87 19.96"
    />
    <path
      d="M8.05,28.69a8.82,8.82,0,0,0,1.45-2,3.67,3.67,0,0,0,.42-1.65A2.87,2.87,
    0,0,0,9,22.78,3.89,3.89,0,0,0,6.35,22a4.08,4.08,0,0,0-2,.47A3.32,3.32,0,0,0,3,23.74a3.55,
    3.55,0,0,0-.47,1.82H4.73a2,2,0,0,1,.42-1.33,1.44,1.44,0,0,1,1.16-.51,1.34,1.34,0,0,1,1.06.42,
    1.71,1.71,0,0,1,.37,1.15,2.24,2.24,0,0,1-.35,1.12,9.34,9.34,0,0,1-1.08,1.4L2.76,
    31.58v1.49h7.51V31.32H5.56Z"
    />
    <path
      d="M8.27,40.23a3,3,0,0,0,1.21-1,2.32,2.32,0,0,0,.45-1.38,2.81,2.81,0,0,
    0-1-2.29,4.09,4.09,0,0,0-2.73-.83,4.29,4.29,0,0,0-1.83.38A3.08,3.08,0,0,0,3.1,36.2a2.68,2.68,
    0,0,0-.46,1.54H4.81a1.1,1.1,0,0,1,.42-.89,1.6,1.6,0,0,1,1-.34,1.49,1.49,0,0,1,1.08.37,1.31,1.31,
    0,0,1,.4,1,1.52,1.52,0,0,1-.4,1.1,1.66,1.66,0,0,1-1.23.41H5v1.7H6.13c1.2,0,1.8.55,1.8,1.64a1.4,
    1.4,0,0,1-.44,1.09,1.72,1.72,0,0,1-1.21.41,1.71,1.71,0,0,1-1.14-.39,1.27,1.27,0,0,1-.47-1H2.5a2.84,
    2.84,0,0,0,1,2.29A4.1,4.1,0,0,0,6.22,46,4.27,4.27,0,0,0,9,45.13a2.9,2.9,0,0,0,1.07-2.34,2.58,2.58,
    0,0,0-1.84-2.56Z"
    />
  </svg>
)
