import { createDate } from '@app/utils/dates'
import { getRandomId, handleNextHour } from '@app/utils/sagaHelpers'
import { RootState } from '../..'
import { emptyQuestion, emptyScheduleEntry } from '../emptyObjects'
import { selectTournamentSite, tournamentSiteApi } from '../endpoints/tournamentSiteApi'
import { setTournamentDirty } from '../slices/configSlice'
import { getTournamentId } from '../slices/configSlice'

export const updateTournamentFeedPictureList = (tournamentId: number, gameFeedImages: GameFeedImage[]) => {
  return (dispatch) => {
    dispatch(
      tournamentSiteApi.util.updateQueryData('getTournamentFeedPictureList', tournamentId, () => {
        return gameFeedImages
      }),
    )
  }
}

export const updateTournamentSiteImages = (tournamentId: number, images: TournamentImages) => {
  return (dispatch) => {
    dispatch(
      tournamentSiteApi.util.updateQueryData('getTournamentSite', tournamentId, (draft) => {
        return { ...draft, images }
      }),
    )
  }
}

export const updateTournamentSiteImageField = (payload: UploadTournamentImagePayload, image: TournamentImage) => {
  return (dispatch, getState: () => RootState) => {
    const { images: oldImages } = selectTournamentSite(getState())

    // Map the image type to the correct key in the images object
    const imageTypeMap = {
      summaryHero: 'summaryHeroImage',
      logo: 'logoImage',
      hero: 'heroImage',
    }

    if (Object.keys(imageTypeMap).includes(payload.fileType)) {
      const newImageObject = { [imageTypeMap[payload.fileType]]: image }
      const images = { ...oldImages, ...newImageObject }
      dispatch(updateTournamentSiteImages(payload.tournamentId, images))
      return
    }

    // These image types are lists of images
    const imageListMap = {
      ad: 'ads',
      sponsorLogo: 'sponsorLogos',
      summaryPicture: 'summaryPictures',
    }

    if (Object.keys(imageListMap).includes(payload.fileType)) {
      const oldImageList = oldImages[imageListMap[payload.fileType]] || []
      const newImageList = [...oldImageList, image]
      const images = { ...oldImages, [imageListMap[payload.fileType]]: newImageList }
      dispatch(updateTournamentSiteImages(payload.tournamentId, images))
      return
    }

    console.error('Invalid image type')
  }
}

// Manual cache updates start here
export const updateTournamentSiteField = (payload: FieldUpdatePayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournamentSite(getState())
    const updatedData = {
      ...data,
      [payload.fieldName]: payload.value,
    }
    dispatch(saveTournamentSiteData(updatedData))
    dispatch(setTournamentDirty(true))
  }
}

export const addScheduleEntry = (round: number) => {
  return (dispatch, getState: () => RootState) => {
    const tournamentId = getTournamentId(getState())
    dispatch(
      tournamentSiteApi.util.updateQueryData('getTournamentSite', tournamentId, (data) => {
        const entry = { ...emptyScheduleEntry }

        if (data.tournamentSchedule[round].length) {
          const lastItem = data.tournamentSchedule[round]?.at(-1)
          // We need to support an old date object
          const newTime = createDate({ originalDate: lastItem?.time })
          entry.hours = handleNextHour(lastItem?.hours ?? newTime.getHours() ?? 9)
          entry.minutes = lastItem?.minutes ?? newTime.getMinutes() ?? 0
        }

        const addTournamentSchedule: Array<ScheduleEntry[]> = [
          ...data.tournamentSchedule.slice(0, round),
          [...data.tournamentSchedule[round], entry],
          ...data.tournamentSchedule.slice(round + 1),
        ]

        return {
          ...data,
          tournamentSchedule: addTournamentSchedule,
        }
      }),
    )
  }
}

export const updateScheduleEntry = (payload: ScheduleEntryPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournamentSite(getState())

    const updateTournamentSchedule: Array<ScheduleEntry[]> = [
      ...data.tournamentSchedule.slice(0, payload.round),
      [
        ...data.tournamentSchedule[payload.round].slice(0, payload.index),
        {
          ...data.tournamentSchedule[payload.round][payload.index],
          [payload.fieldName]: payload.value,
        },
        ...data.tournamentSchedule[payload.round].slice(payload.index + 1),
      ],
      ...data.tournamentSchedule.slice(payload.round + 1),
    ]

    const updatedData = {
      ...data,
      tournamentSchedule: updateTournamentSchedule,
    }

    dispatch(saveTournamentSiteData(updatedData))
  }
}

export const removeScheduleEntry = (entryIndex: number, round: number) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournamentSite(getState())

    const removeTournamentSchedule: Array<ScheduleEntry[]> = [
      ...data.tournamentSchedule.slice(0, round),
      [...data.tournamentSchedule[round].filter((_, idx) => idx !== entryIndex)],
      ...data.tournamentSchedule.slice(round + 1),
    ]

    const updatedData = {
      ...data,
      tournamentSchedule: removeTournamentSchedule,
    }

    dispatch(saveTournamentSiteData(updatedData))
  }
}

export const addNewCustomQuestion = () => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournamentSite(getState())
    const updatedData = {
      ...data,
      customQuestions: data.customQuestions.concat([
        Object.assign({}, emptyQuestion, { id: -getRandomId(), questionNumber: data.customQuestions.length + 1 }),
      ]),
    }
    dispatch(saveTournamentSiteData(updatedData))
  }
}

export const updateCustomQuestionField = (payload: UpdateRoundFieldPayload) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournamentSite(getState())
    const updatedData = {
      ...data,
      customQuestions: [
        ...data.customQuestions.slice(0, payload.index),
        {
          ...data.customQuestions[payload.index],
          [payload.fieldName]: payload.value,
        },
        ...data.customQuestions.slice(payload.index + 1),
      ],
    }
    dispatch(saveTournamentSiteData(updatedData))
    dispatch(setTournamentDirty(true))
  }
}

export const deleteCustomQuestion = (questionId: number) => {
  return (dispatch, getState: () => RootState) => {
    const data = selectTournamentSite(getState())
    const tournamentId: number = getTournamentId(getState())
    const updatedQuestions = data.customQuestions.filter((question) => question.id !== questionId)
    const updatedData: TournamentSite = {
      ...data,
      customQuestionsEnabled: updatedQuestions.length > 0,
      customQuestions: updatedQuestions,
    }
    dispatch(tournamentSiteApi.endpoints.updateTournamentSite.initiate({ id: tournamentId, body: updatedData }))
  }
}

export const saveTournamentSiteData = (data: TournamentSite) => {
  return (dispatch, getState: () => RootState) => {
    const id = getTournamentId(getState())
    dispatch(tournamentSiteApi.util.updateQueryData('getTournamentSite', id, () => data))
  }
}
