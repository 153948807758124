import { Action } from 'redux'
import {
  HIDE_AUTO_SAVE_IN_PROGRESS,
  NOTIFICATION_ENQUEUE,
  NOTIFICATION_REMOVE,
  SHOW_AUTO_SAVE_IN_PROGRESS,
} from './constants'
import get from 'lodash/get'
import { OptionsObject } from 'notistack'

export interface NotificationEnqueueAction extends Action {
  type: 'NOTIFICATION_ENQUEUE'
  notification: Notification
}
export interface NotificationRemoveAction extends Action {
  type: 'NOTIFICATION_REMOVE'
  key: number
}

export const tryToCapitalize = (s: any) => {
  if (typeof s !== 'string') {
    return s
  }

  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const enqueueNotification = (error: any, variant: OptionsObject['variant'] = 'warning') => {
  // Try if the error has Axios refined error data
  let message = get(error, 'response.data.error.message')

  // Default to error toString
  if (!message) {
    message = error.toString()
  } else {
    message = tryToCapitalize(message)
  }

  return {
    type: NOTIFICATION_ENQUEUE,
    notification: {
      key: new Date().getTime() + Math.random(),
      message,
      options: { variant },
    },
  }
}

export const removeNotification = (key: number) => ({
  type: NOTIFICATION_REMOVE,
  key,
})

export interface ShowAutoSaveInProgressAction extends Action {
  type: 'SHOW_AUTO_SAVE_IN_PROGRESS'
}
export const showAutoSave = () => ({
  type: SHOW_AUTO_SAVE_IN_PROGRESS,
})
export interface HideAutoSaveInProgressAction extends Action {
  type: 'HIDE_AUTO_SAVE_IN_PROGRESS'
}
export const hideAutoSave = () => ({
  type: HIDE_AUTO_SAVE_IN_PROGRESS,
})
