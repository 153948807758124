import React from 'react'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import { MatchPlayBracketPlayer } from './MatchPlayBracketPlayer'

interface Props {
  player: MatchPlayBracketPlayer
  index: number
  draggableId: string
  editPlayer: (player: MatchPlayBracketPlayer, deletePlayer?: boolean) => void
}

export const MatchPlayBracketPoolCard: React.FC<Props> = ({ player, index, draggableId, editPlayer }) => {
  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index}>
      {(provided: DraggableProvided) => (
        <>
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
            <MatchPlayBracketPlayer player={player} editPlayer={editPlayer} />
          </div>
        </>
      )}
    </Draggable>
  )
}
