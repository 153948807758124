import { useEffect, useState } from 'react'
import { TimePicker } from './TimePicker'
import { Grid } from '@mui/material'
import { convertToDate, createDate, isValidDate } from '@app/utils/dates'
import { DatePicker, ValidationErrorMessages } from './DatePicker'
import { DateValidationError } from '@mui/x-date-pickers'

type SplitDateTimePickerProps = {
  units: OrganizationUnits
  disabled?: boolean
  value?: DateTimeValue
  onChange?: (date: DateTimeValue) => void
  onError?: (error: DateValidationError) => void
  maxDate?: DateTimeValue
  minDate?: DateTimeValue
  errorMessages?: ValidationErrorMessages
}

export const SplitDateTimePicker = (props: SplitDateTimePickerProps) => {
  const { value = new Date(), units, disabled } = props
  const time = value ? convertToDate(value) : createDate({ hours: 23, minutes: 59 })

  const [selectedDateTime, setSelectedDateTime] = useState<Date>()

  useEffect(() => {
    setSelectedDateTime(time)
  }, [time])

  // Check that year is correct length, year value is short if day/month is missing
  const isDateYearValid = (date: Date) => String(date.getFullYear()).length === 4

  const onTimeChange = (date: DateTimeValue) => {
    if (selectedDateTime && isValidDate(selectedDateTime)) {
      date?.setMonth(selectedDateTime?.getMonth())
      date?.setDate(selectedDateTime?.getDate())
    }
    props.onChange && props.onChange(date)
  }

  const onDateChange = (value: DateTimeValue) => {
    const date = value
    if (date && isValidDate(time)) {
      date.setMinutes(time.getMinutes())
      date.setHours(time.getHours())
    }

    if (date && isValidDate(date)) {
      if (date && isDateYearValid(date)) {
        setSelectedDateTime(date as Date)
      }
      props.onChange && props.onChange(date)
    }
  }

  // Only prevent setting value if maxDate error
  const datePickerValidationFunction = (date: Date) => {
    const dateFormatIsValid = isValidDate(date) && isDateYearValid(date)
    const isMaxDateError = props.maxDate && date > props.maxDate
    return dateFormatIsValid && !isMaxDateError
  }

  const onError = (error: DateValidationError) => {
    props.onError && props.onError(error)
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <DatePicker
          style={{ marginRight: 18 }}
          value={value}
          units={units}
          onAccept={onDateChange}
          onError={onError}
          minDate={props.minDate || undefined}
          maxDate={props.maxDate || undefined}
          errorMessages={props.errorMessages}
          disabled={disabled}
          isDateValid={datePickerValidationFunction}
        />
      </Grid>
      <Grid item>
        <TimePicker
          units={units}
          onAccept={onTimeChange}
          value={time}
          disabled={disabled}
          minDate={props.minDate || undefined}
          maxDate={props.maxDate || undefined}
        />
      </Grid>
    </Grid>
  )
}
