import { format } from 'date-fns'

export const getUserData = (auth: AuthenticationState) => {
  return {
    $name: auth.name || '',
    $email: auth.email || '',
    Privilege: auth.roleInfo.name,
    'User ID': String(auth.id),
    'Organization ID': String(auth.customerInfo?.idCustomer),
    'Organization Country': auth.customerInfo?.addressInfo?.country || '',
    'Home Club Country': auth.customerInfo?.club?.countryName || '',
  }
}

export const getGameCreatedEventData = (tournament: TournamentState, round: Round, playersCount: number) => {
  return {
    'Tournament ID': tournament.id,
    'Primary game': round.primaryGame?.name,
    'Secondary game': round.sideGame?.name,
    'Playing start date': format(round.startTime, 'yyyy-MM-dd'),
    'Number of players': playersCount,
    'Tournament type': tournament.tournamentType,
    'Tournament country': round.club?.countryName,
    Course: round.course?.courseName,
    Club: round.club?.name,
  }
}
