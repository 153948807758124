import {
  OrganizationUserCreateAction,
  OrganizationUserCreateErrorAction,
  OrganizationUserCreateSuccessAction,
  OrganizationUserUpdateAction,
  OrganizationUserUpdateErrorAction,
  OrganizationUserUpdateSuccessAction,
} from './actions'
import {
  ORGANIZATION_USER_CREATE,
  ORGANIZATION_USER_CREATE_ERROR,
  ORGANIZATION_USER_CREATE_SUCCESS,
  ORGANIZATION_USER_SAVE,
  ORGANIZATION_USER_SAVE_ERROR,
  ORGANIZATION_USER_SAVE_SUCCESS,
} from './constants'

const initialState: OrganizationUserState = {
  loading: false,
  error: null,
  user: undefined,
}

type UserAction =
  | OrganizationUserCreateAction
  | OrganizationUserCreateSuccessAction
  | OrganizationUserCreateErrorAction
  | OrganizationUserUpdateAction
  | OrganizationUserUpdateSuccessAction
  | OrganizationUserUpdateErrorAction

export const organizationUserReducer = (
  state: OrganizationUserState = initialState,
  action: UserAction,
): OrganizationUserState => {
  switch (action.type) {
    case ORGANIZATION_USER_CREATE:
    case ORGANIZATION_USER_SAVE:
      return {
        ...state,
        loading: true,
      }
    case ORGANIZATION_USER_CREATE_ERROR:
    case ORGANIZATION_USER_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.error,
      }
    case ORGANIZATION_USER_CREATE_SUCCESS:
    case ORGANIZATION_USER_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
