export const SupportIcon = ({
  style = {},
  fill = '#1AA85D',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 55 55',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
  >
    <circle cx="27.5" cy="27.5" r="25" />
    <path
      style={{ fill: '#fff' }}
      d="M24.7,32.9a11.35,11.35,0,0,1,.64-4.22,8.64,8.64,0,0,
    1,2.35-3.08A12.06,12.06,0,0,0,30,23.12a3.83,3.83,0,0,0,.57-2q0-3.19-3-3.19a3,
    3,0,0,0-2.23.86,3.39,3.39,0,0,0-.88,2.37H19a7.3,7.3,0,0,1,2.33-5.65,9.06,9.06,
    0,0,1,6.25-2,9.11,9.11,0,0,1,6.21,1.94A6.89,6.89,0,0,1,36,20.87a6.63,6.63,0,0,
    1-.72,3,12.45,12.45,0,0,1-2.51,3.16l-1.53,1.45a5.23,5.23,0,0,0-1.64,3.23l-.07,
    1.16Zm-.55,5.78A2.77,2.77,0,0,1,25,36.6a3.35,3.35,0,0,1,4.39,0,2.93,2.93,0,0,
    1,0,4.13,3,3,0,0,1-2.21.81A3.06,3.06,0,0,1,25,40.73,2.72,2.72,0,0,1,24.15,38.68Z"
    />
  </svg>
)
