import React from 'react'
import { ExtraInfo } from '@app/store/api/enums/tournamentEnums'
import { FormControl, MenuItem, OutlinedInput } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { tournamentPlayersChangeExtraInfo } from '@store/api/thunks/tournamentPlayersThunks'
import CustomQuestionAnswer from '@components/tournament/CustomQuestionAnswer'
import find from 'lodash/find'
import get from 'lodash/get'
import { formatDate } from '@utils/dates'
import { Select } from '@app/components/forms/Select'
import { IntlShape } from 'react-intl'
import { RootState } from '@app/store'

import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { fetchAllStartLists } from '@app/store/api/thunks/tournamentStartListsThunks'
import { isCountryWithPlayerRegistry } from '@app/utils/organizationUtils'

interface OwnProps {
  disabled?: boolean
  showTeam?: boolean
  showTeebox?: boolean
  style?: React.CSSProperties
  default?: string
  className?: string
}

interface StateProps {
  tournament: TournamentState
  playerExtraInfo: string
  customQuestionsEnabled: boolean
  customQuestions: Question[]
  divisions: DivisionState[]
  tournamentConfig: TournamentConfig
  isCountryWithRegistry?: boolean
}

interface DispatchProps {
  tournamentPlayersChangeExtraInfo(value: string): void
  fetchAllStartLists(): void
}

type Props = OwnProps & StateProps & DispatchProps

class ExtraInfoSelectComponent extends React.Component<Props> {
  componentDidMount() {
    if (this.props.default) {
      this.props.tournamentPlayersChangeExtraInfo(this.props.default)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.playerExtraInfo.startsWith(ExtraInfo.ROUND)) {
      this.props.fetchAllStartLists()
    }
    if (this.props.default && prevProps.default !== this.props.default) {
      this.props.tournamentPlayersChangeExtraInfo(this.props.default)
    }
  }

  render() {
    const { disabled = false, showTeam = false, showTeebox = false, style = {}, tournament } = this.props
    return (
      <FormControl variant="outlined" margin="dense" sx={{ m: 0, p: 0 }} className={this.props.className}>
        <Select
          noBorder
          readOnly={disabled}
          style={{ fontWeight: 'normal', ...style }}
          value={this.props.playerExtraInfo}
          onChange={this._onChange}
          size="small"
          input={<OutlinedInput id={'extra-info-select'} margin="dense" />}
        >
          {this.props.isCountryWithRegistry && (
            <MenuItem value={ExtraInfo.EMAIL}>
              {this._preLabel}
              <FormattedMessageWrapper id={'tournament.email'} />
            </MenuItem>
          )}
          <MenuItem value={ExtraInfo.YEAR_OF_BIRTH}>
            {this._preLabel}
            <FormattedMessageWrapper id={'options.yearOfBirth'} />
          </MenuItem>
          <MenuItem value={ExtraInfo.ENTRY_TIME}>
            {this._preLabel}
            <FormattedMessageWrapper id={'options.entryTime'} />
          </MenuItem>
          <MenuItem value={ExtraInfo.DIVISION}>
            {this._preLabel}
            <FormattedMessageWrapper id={'options.division'} />
          </MenuItem>
          {showTeebox && (
            <MenuItem value={ExtraInfo.TEEBOX}>
              {this._preLabel}
              <FormattedMessageWrapper id={'options.teebox'} />
            </MenuItem>
          )}
          {showTeam && (
            <MenuItem value={ExtraInfo.TEAM_NAME}>
              {this._preLabel}
              <FormattedMessageWrapper id={'options.teamName'} />
            </MenuItem>
          )}
          {tournament.rounds.map((round, index) => (
            <MenuItem value={ExtraInfo.ROUND + '_' + (index + 1)} key={index}>
              {this._preLabel}
              <FormattedMessageWrapper id={'tournament.roundInfo'} /> {index + 1}
            </MenuItem>
          ))}
          {this._renderCustomQuestions()}
        </Select>
      </FormControl>
    )
  }

  public get _preLabel() {
    return (
      <>
        <FormattedMessageWrapper id={'tournament.extraInfo'} />
        &nbsp;-&nbsp;
      </>
    )
  }

  public _onChange = (event) => {
    const { tournamentPlayersChangeExtraInfo } = this.props
    const { value } = event.target

    if (typeof value === 'string') {
      tournamentPlayersChangeExtraInfo(value)
    }
  }

  private _renderCustomQuestions() {
    const { customQuestionsEnabled, customQuestions } = this.props

    if (!customQuestionsEnabled) {
      return null
    }

    return customQuestions.map(this._renderCustomQuestionOption)
  }

  private _renderCustomQuestionOption = (customQuestion: Question, index: number) => (
    <MenuItem value={`customQuestion:${customQuestion.id}`} key={customQuestion.id}>
      Q{index + 1}:&nbsp;{customQuestion.question}
    </MenuItem>
  )
}

export const getExtraInfo = (
  player: TournamentPlayer,
  tournament: TournamentState,
  tournamentConfig: TournamentConfig,
  startListRounds: StartListRound[],
  playerExtraInfo,
  customQuestionsIdMap,
  teams,
  units,
  divisions,
  players,
  intl: IntlShape,
): string => {
  let extraInfo: any = ''

  if (startListRounds.length > 0 && player && playerExtraInfo.startsWith(ExtraInfo.ROUND)) {
    extraInfo = getStartInfo(extraInfo, player, tournament, startListRounds, playerExtraInfo, units, intl)
  }

  switch (playerExtraInfo) {
    case ExtraInfo.YEAR_OF_BIRTH: {
      const dateOfBirth = get(player, 'dateOfBirth', undefined)
      if (dateOfBirth) {
        extraInfo = formatDate(dateOfBirth, 'year', units)
      }
      break
    }
    case ExtraInfo.ENTRY_TIME: {
      const entryTime = get(player, 'entryTime', undefined)
      if (entryTime) {
        extraInfo = formatDate(entryTime, 'datetime', units)
      }
      break
    }
    case ExtraInfo.TEAM_NAME: {
      if (teams) {
        let team = teams.find((team) => team.players.some((p) => p.id === player.id))
        if (!team) {
          team = player.invitationTeam
        }
        extraInfo = get(team, 'name', '')
      } else if (player.team?.name) {
        extraInfo = player.team?.name
      } else if (player.team && player.team.name) {
        extraInfo = player.team.name
      } else {
        extraInfo = ''
      }
      break
    }
    case ExtraInfo.TEEBOX: {
      const currentRound = tournament.rounds[tournamentConfig.selectedRoundIndex]
      const defaultTeebox =
        currentRound &&
        (player.gender === 'male' ? currentRound.teeBoxMen?.teeboxName : currentRound.teeBoxWomen?.teeboxName)

      extraInfo = player.preferredTeeBox?.teeboxName || defaultTeebox
      break
    }
    case ExtraInfo.EMAIL: {
      extraInfo = get(player, 'email', undefined)
      break
    }
    case ExtraInfo.DIVISION: {
      extraInfo = divisions?.find((division: DivisionState) => division.id === player.divisionId)?.name
      break
    }
    default: {
      if (playerExtraInfo.substr(0, 14) === 'customQuestion') {
        const questionId = get(playerExtraInfo.split(':'), '[1]')
        if (questionId) {
          const customQuestion = customQuestionsIdMap[questionId]
          const questionAnswer = find(player.customQuestionAnswers, {
            registrationQuestionId: parseInt(questionId, 10),
          })
          extraInfo = customQuestion && (
            <span>
              Q:&nbsp;
              <CustomQuestionAnswer readOnly={true} question={customQuestion} questionAnswer={questionAnswer} />
            </span>
          )
        }
      }
    }
  }

  return extraInfo
}

const getStartInfo = (
  extraInfo: string,
  player: TournamentPlayer,
  tournament: TournamentState,
  startListRounds: StartListRound[],
  playerExtraInfo,
  units,
  intl: IntlShape,
): string => {
  const roundIndex = parseInt(playerExtraInfo.split('_')[1], 10)
  const round = tournament.rounds[roundIndex - 1]

  let currentRoundPlayer: StartListPlayer | undefined
  let currentGroup: StartListGroup | undefined
  if (round && round.id) {
    currentRoundPlayer = player.startListPlayers && player.startListPlayers[round.id]
    currentGroup = startListRounds[roundIndex - 1].groups.find((group: StartListGroup) => {
      return group.startListPlayers.some((p: StartListPlayer) => {
        return p.tournamentPlayerId === player.id
      })
    })
  }

  if (currentRoundPlayer && currentGroup) {
    const hole = intl.formatMessage({ id: 'course.hole' }) + ` ${currentGroup.teeNumber} | `
    const time =
      intl.formatMessage({ id: 'tournament.time' }) + ` ${formatDate(currentGroup.startTime, 'time', units)} | `
    const teeBox = ` ${currentRoundPlayer?.teeBox?.teeboxName} `
    extraInfo = hole + time + teeBox
  }
  return extraInfo
}

export const ExtraInfoSelect = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => {
    const tournament = selectTournament(state)
    return {
      tournament: tournament,
      playerExtraInfo: selectTournamentConfig(state).playerExtraInfo,
      customQuestionsEnabled: selectTournamentSite(state).customQuestionsEnabled,
      customQuestions: selectTournamentSite(state).customQuestions,
      divisions: state.divisionsReducer.divisions,
      tournamentConfig: selectTournamentConfig(state),
      isCountryWithRegistry: isCountryWithPlayerRegistry(tournament.tournamentOrganization),
    }
  },
  {
    tournamentPlayersChangeExtraInfo,
    fetchAllStartLists,
  },
)(ExtraInfoSelectComponent)
