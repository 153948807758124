import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DeleteConfirm, { DeleteConfirmChildren } from '@app/components/dialogs/deleteConfirm/DeleteConfirm'
import { VeryDenseTableCell } from '@app/components/tables/tableComponents'
import BorderButton from '@app/components/ui/BorderButton'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Edit, Delete, OpenInNew } from '@mui/icons-material'
import { CircularProgress, IconButton, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { MatchPlayBracketDialog } from '@app/components/dialogs/matchPlayBracketDialog/MatchPlayBracketDialog'
import {
  useDeleteMatchPlayBracketMutation,
  useGetOrganizationMatchPlayBracketsQuery,
} from '@app/store/api/endpoints/matchPlayBracketsApi'
import { formatDate } from '@app/utils/dates'
import HeadHelmet from '@app/components/layout/HeadHelmet'
import { StyledLink } from '@app/components/ui/StyledLink'
import { makeStyles } from '@mui/styles'
import { RootState } from '@app/store'

const useStyles = makeStyles(() => ({
  topActionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
  },
  actionCell: {
    width: 50,
  },
  deletingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export const MatchPlayBrackets: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [currentBracket, setCurrentBracket] = useState<MatchPlayBracket | null>(null)
  const { units, customerInfo } = useSelector((store: RootState) => store.authenticationReducer)
  const { idCustomer: organizationId } = customerInfo ? customerInfo : { idCustomer: 0 }
  const { data: brackets, isLoading, isFetching } = useGetOrganizationMatchPlayBracketsQuery(organizationId)
  const [deleteMatchPlayBracket, { isLoading: isDeleting, originalArgs: deletingId }] =
    useDeleteMatchPlayBracketMutation()

  const classes = useStyles()
  const isRefetching: boolean = isFetching && !isLoading

  const editBracket = (bracket: MatchPlayBracket): void => {
    setCurrentBracket(bracket)
    setModalOpen(true)
  }

  const isDeletingBracket = (bracket: MatchPlayBracket): boolean => {
    return isDeleting && deletingId === bracket.id
  }

  const closeModal = (): void => {
    setModalOpen(false)
    setCurrentBracket(null)
  }

  return (
    <>
      <HeadHelmet titleId={'matchPlayBracket.title'} />
      {modalOpen && <MatchPlayBracketDialog close={closeModal} bracket={currentBracket} brackets={brackets} />}
      <div className={classes.topActionContainer}>
        <BorderButton
          filled
          buttonProps={{
            onClick: () => setModalOpen(true),
            style: { margin: '15px 0' },
          }}
        >
          <FormattedMessageWrapper id="buttons.createNew" />
        </BorderButton>
      </div>

      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="tourAndRanking.name" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="matchPlayBracket.size" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="tourAndRanking.createdAt" />
              </VeryDenseTableCell>
              <VeryDenseTableCell>
                <FormattedMessageWrapper id="tourAndRanking.updatedAt" />
              </VeryDenseTableCell>
              <VeryDenseTableCell colSpan={3}></VeryDenseTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {brackets?.map((bracket: MatchPlayBracket, i: number) => (
              <TableRow key={i}>
                <VeryDenseTableCell>
                  <StyledLink to={`/match-play-brackets/${bracket.id}`}>{bracket.name}</StyledLink>
                </VeryDenseTableCell>
                <VeryDenseTableCell>{bracket.options?.numberOfPlayers}</VeryDenseTableCell>
                <VeryDenseTableCell>
                  {bracket.createdAt && formatDate(bracket.createdAt, 'datetime', units)}
                </VeryDenseTableCell>
                <VeryDenseTableCell>
                  {bracket.updatedAt && formatDate(bracket.updatedAt, 'datetime', units)}
                </VeryDenseTableCell>
                <VeryDenseTableCell className={classes.actionCell}>
                  <IconButton
                    onClick={() => {
                      editBracket(bracket)
                    }}
                    size="large"
                  >
                    <Edit color="primary" />
                  </IconButton>
                </VeryDenseTableCell>
                <VeryDenseTableCell className={classes.actionCell}>
                  {isDeletingBracket(bracket) && (
                    <div className={classes.deletingContainer}>
                      <CircularProgress size={20} thickness={2} />
                    </div>
                  )}
                  {!isDeletingBracket(bracket) && (
                    <DeleteConfirm>
                      {({ showConfirm }: DeleteConfirmChildren) => (
                        <IconButton
                          onClick={() => {
                            showConfirm({
                              messageId: 'matchPlayBracket.deleteConfirm',
                              values: { name: bracket.name },
                              callback: () => {
                                if (bracket.id) {
                                  deleteMatchPlayBracket(bracket.id)
                                }
                              },
                            })
                          }}
                          size="large"
                        >
                          <Delete color="error" />
                        </IconButton>
                      )}
                    </DeleteConfirm>
                  )}
                </VeryDenseTableCell>
                <VeryDenseTableCell className={classes.actionCell}>
                  <Link to={`/match-play-brackets/${bracket.id}/view`} target="_blank">
                    <IconButton size="large">
                      <OpenInNew color="primary" />
                    </IconButton>
                  </Link>
                </VeryDenseTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {isRefetching && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={50} thickness={2} />
        </div>
      )}
    </>
  )
}
