export const SearchIcon = ({
  style = {},
  stroke = '#1AA85D',
  width = '24px',
  height = '24px',
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox={viewBox}
    style={style}
    className={className}
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      // eslint-disable-next-line max-len
      d="M11.11 17.16c3.928 0 7.111-2.935 7.111-6.557 0-3.622-3.183-6.558-7.11-6.558C7.183 4.045 4 6.98 4 10.603s3.184 6.558 7.11 6.558zM20.001 18.8l-3.866-3.566"
    ></path>
  </svg>
)
