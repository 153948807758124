// LIBRARIES
import React from 'react'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { connect } from 'react-redux'
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl'

// COMPONENTS
import { SearchAndAddPlayers } from './search-and-add-players'
import { AddTempPlayer } from './search-and-add-players'
import { PlayerPoolTable } from './player-pool-table'
import ContentWrap from '@components/layout/ContentWrap'
import { SectionWrapper } from '@app/components/layout/SectionWrapper'
import TournamentFormActions from '@components/tournament/TournamentFormActions'
import { scrollToTop } from '@components/ui'
import HeadHelmet from '@components/layout/HeadHelmet'
import DeleteIcon from '@mui/icons-material/Delete'

// ACTIONS & UTILS
import { RootState } from '@app/store'
import { scoringStartedOnAnyRound, disablePlayerListEdit } from '@app/store/api/selectors/tournamentSelectors'
import { enqueueNotification } from '@store/notifications/actions'
import { getUnassignedTeamPlayers } from '@app/store/api/selectors/tournamentStartListsSelectors'
import { getNextUrl } from '@utils/nextUrlHelper'
import { TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { fetchDivisions } from '@store/divisions/actions'
import { fetchOrganizationProducts, fetchTournamentProducts } from '@app/store/payments/actions'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { PlayerPoolEditContext } from './enums'
import { getSelectedPlayerIds } from '@app/store/api/selectors/tournamentPlayersSelectors'
import { Button, Stack, Typography, LinearProgress, Divider } from '@mui/material'
import { SV_COUNTRY_ID } from '@app/config'
import { createStyles, WithStyles, withStyles } from '@mui/styles'
import {
  getStorageGolferIds,
  storageGitResults,
  storageImportId,
  storageTempImportId,
  storageTournamentId,
} from '@utils/tournamentUtils'
import { setGitDialogOpen } from '@app/store/api/slices/configSlice'
import { selectGitImportStatus, tournamentGitApi } from '@app/store/api/endpoints/tournamentGitApi'
import { selectTournamentSettings, tournamentSettingsApi } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournamentPlayers, tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { fetchAllStartLists } from '@app/store/api/thunks/tournamentStartListsThunks'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { maybeRefetchTournamentPlayers } from '@app/store/api/thunks/tournamentPlayersThunks'
import { maybeRefetchTournamentTeams } from '@app/store/api/thunks/tournamentTeamsThunks'
import { AddPlayerByEmail } from './search-and-add-players/AddPlayerByEmail'

const styles = () =>
  createStyles({
    gitProgressContainer: {
      display: 'flex',
    },
    gitProgressBarContainer: {
      width: '50%',
      marginRight: 10,
    },
    gitProgressBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    gitProgressBarText: {
      display: 'grid',
      marginBottom: 10,
    },
    divider: {
      marginTop: 0,
      marginBottom: 10,
    },
  })

interface StateProps {
  disablePlayerListEdit: boolean
  isTeamFormat: boolean
  isTwoManTeamFormat: boolean
  numberOfRounds: number
  players: TournamentPlayer[]
  reserveList: TournamentPlayer[]
  reserveListEnabled: boolean
  scoringStarted: boolean
  tournament: TournamentState
  tournamentSettings: TournamentSettings
  tournamentType: TournamentType
  unassignedTeamPlayers: TournamentPlayer[]
  selectedPlayers: number[]
  auth: AuthenticationState
  countryId: number
  gitImportData: GitImport
}

interface DispatchProps {
  enqueueNotification(error: any, variant?: string): void
  maybeRefetchTournamentPlayers(tournamentId: number): void
  maybeRefetchTournamentTeams(tournamentId: number): void
  removeSelectedPlayers(payload: DeletePlayersPayload): void
  moveSelectedPlayers(payload: MovePlayersPayload): void
  fetchAllStartLists(): void
  fetchTournamentSettings(id: number): void
  fetchDivisions: (organizationId: number) => void
  fetchOrganizationProducts(organizationId: number): void
  fetchTournamentProducts(tournamentId: number): void
  gitImportCancel(payload: GitImportCancelPayload): void
  setGitDialogOpen(openGitDialog: boolean): void
}

interface State {
  showPoolIdx: number
  activeTab: PlayerPoolEditContext
  openDialog: boolean
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps & WithStyles<typeof styles>

class UnconnectedPlayers extends React.Component<Props> {
  readonly state: State = {
    showPoolIdx: 0,
    activeTab: PlayerPoolEditContext.POOL,
    openDialog: false,
  }

  public componentDidMount(): void {
    const {
      fetchTournamentSettings,
      maybeRefetchTournamentPlayers,
      maybeRefetchTournamentTeams,
      fetchAllStartLists,
      fetchDivisions,
      fetchOrganizationProducts,
      fetchTournamentProducts,
      auth,
    } = this.props
    const { params } = this.props
    const tournamentId = Number(params.id)

    const { organizationId } = auth.roleInfo

    if (tournamentId) {
      fetchTournamentSettings(tournamentId)
      maybeRefetchTournamentPlayers(tournamentId)
      maybeRefetchTournamentTeams(tournamentId)
      fetchAllStartLists()
      fetchTournamentProducts(tournamentId)
    }

    if (organizationId) {
      fetchDivisions(organizationId)
      fetchOrganizationProducts(organizationId)
    }
  }

  get secondaryActions() {
    const { activeTab } = this.state
    const { reserveListEnabled, selectedPlayers } = this.props

    const selectedCount = selectedPlayers.length
    const actionsDisabled = selectedCount === 0

    if (activeTab === PlayerPoolEditContext.TEAM) {
      return undefined
    }

    return (
      <>
        <Typography variant="subtitle1" sx={{ marginBottom: '1rem', fontSize: '0.9rem', fontWeight: 600 }}>
          <FormattedMessage id="tournament.actionsForSelectedPlayersCount" values={{ selectedCount }} />
        </Typography>
        <Stack gap={2} direction="row">
          {[PlayerPoolEditContext.REMOVED, PlayerPoolEditContext.RESERVED].includes(activeTab) && (
            <Button
              disabled={actionsDisabled}
              variant="contained"
              onClick={() => this.moveSelectedPlayers('POOL')}
              sx={{
                '&.MuiButton-root': {
                  color: 'white',
                  backgroundColor: 'primary.main',
                  opacity: actionsDisabled ? 0.38 : 1,
                },
              }}
            >
              <FormattedMessageWrapper id="buttons.moveToPool" />
            </Button>
          )}
          {reserveListEnabled && [PlayerPoolEditContext.POOL, PlayerPoolEditContext.REMOVED].includes(activeTab) && (
            <Button
              disabled={actionsDisabled}
              variant="contained"
              onClick={() => this.moveSelectedPlayers('RESERVED')}
              sx={{
                '&.MuiButton-root': {
                  color: 'white',
                  backgroundColor: 'warning.main',
                  opacity: actionsDisabled ? 0.38 : 1,
                },
              }}
            >
              <FormattedMessageWrapper id="buttons.moveToReserved" />
            </Button>
          )}
          {[PlayerPoolEditContext.POOL, PlayerPoolEditContext.RESERVED].includes(activeTab) && (
            <Button
              disabled={actionsDisabled}
              variant="contained"
              onClick={() => this.moveSelectedPlayers('REMOVED')}
              sx={{
                '&.MuiButton-root': {
                  color: 'white',
                  backgroundColor: 'error.main',
                  opacity: actionsDisabled ? 0.38 : 1,
                },
              }}
            >
              <FormattedMessageWrapper id="buttons.moveToRemovedPlayers" />
            </Button>
          )}
          {activeTab === PlayerPoolEditContext.REMOVED && (
            <Button
              disabled={actionsDisabled}
              variant="contained"
              onClick={this.removeSelectedPlayers}
              endIcon={<DeleteIcon />}
              sx={{
                '&.MuiButton-root': {
                  color: 'white',
                  backgroundColor: 'error.main',
                  opacity: actionsDisabled ? 0.38 : 1,
                },
              }}
            >
              <FormattedMessageWrapper id="buttons.deletePermanently" />
            </Button>
          )}
        </Stack>
      </>
    )
  }

  private removeSelectedPlayers = () => {
    const { params, selectedPlayers } = this.props
    const tournamentId = Number(params.id)
    this.props.removeSelectedPlayers({ tournamentId, playerIds: selectedPlayers })
  }

  private moveSelectedPlayers = (list: PlayerListType) => {
    const { params, selectedPlayers } = this.props
    const tournamentId = Number(params.id)
    this.props.moveSelectedPlayers({ tournamentId, playerIds: selectedPlayers, list })
  }

  private handleCancelClick = () => {
    const { params } = this.props
    const tournamentId = Number(params.id)
    const { gitImportCancel } = this.props
    const importId = localStorage.getItem(storageImportId) || ''
    gitImportCancel({ tournamentId, importId })
  }

  private handleOpenDialog = () => {
    this.props.setGitDialogOpen(true)
  }

  public render() {
    const { tournamentType, countryId, classes, tournament, gitImportData } = this.props

    const nextLabelId =
      tournamentType === TournamentTypes.weekly ? 'buttons.resultsAndOptions' : 'buttons.groupsAndStartLists'

    const gitImportDataStorage = JSON.parse(localStorage.getItem(storageGitResults) || '{}')
    const { result, progress } = Object.keys(gitImportDataStorage).length ? gitImportDataStorage : gitImportData

    const golferIds = getStorageGolferIds()
    const missingAmount = result ? golferIds.length - Object.keys(result).length : 0

    const gitTournamentId = localStorage.getItem(storageTournamentId)
    const tempImportId = localStorage.getItem(storageTempImportId)
    const progressStatus = tempImportId ? 100 : progress

    return (
      <>
        <SectionWrapper>
          <HeadHelmet titleId={'progress.players'} />
          <>
            <ContentWrap>
              <SearchAndAddPlayers />
            </ContentWrap>

            <ContentWrap>
              <Divider className={this.props.classes.divider} />
              <AddPlayerByEmail />
              <AddTempPlayer />
            </ContentWrap>
          </>
        </SectionWrapper>
        {countryId === SV_COUNTRY_ID && gitTournamentId && tournament?.id === Number(gitTournamentId) && (
          <SectionWrapper>
            <ContentWrap>
              <div className={classes.gitProgressContainer}>
                <div className={classes.gitProgressBarContainer}>
                  <div className={classes.gitProgressBar}>
                    <div className={classes.gitProgressBarText}>
                      {progressStatus < 100 ? (
                        <FormattedMessageWrapper id="tournament.searchProgressText" />
                      ) : (
                        <FormattedMessageWrapper id="tournament.playerSearchCompletedText" />
                      )}
                      {progressStatus === 100 && missingAmount > 0 && (
                        <Typography variant="caption" color="error">
                          <FormattedMessage id="tournament.playersNotFound" values={{ missingAmount }} />
                        </Typography>
                      )}
                    </div>
                    <p>{progressStatus}%</p>
                  </div>
                  <LinearProgress variant="determinate" value={progressStatus} />
                </div>
                {progressStatus && golferIds.length ? (
                  <Button onClick={this.handleOpenDialog}>
                    <FormattedMessageWrapper id="buttons.viewResults" />
                  </Button>
                ) : (
                  <Button onClick={this.handleCancelClick}>
                    <FormattedMessageWrapper id="buttons.cancel" />
                  </Button>
                )}
              </div>
            </ContentWrap>
          </SectionWrapper>
        )}
        <SectionWrapper>
          <ContentWrap>
            <PlayerPoolTable onTabChange={(tab) => this.setState({ activeTab: tab })} />
          </ContentWrap>

          <ContentWrap style={{ background: 'transparent' }}>
            {this.secondaryActions}
            <TournamentFormActions onClickNext={this.clickNext} nextLabelId={nextLabelId} />
          </ContentWrap>
        </SectionWrapper>
      </>
    )
  }

  private clickNext = () => {
    const { tournamentType } = this.props
    const nextUrl = getNextUrl(
      this.props.location.pathname,
      tournamentType === TournamentTypes.weekly ? 'results-and-options' : 'groups-and-start-list',
    )
    this.props.navigate(nextUrl)
    scrollToTop()
  }
}

export const Players = withRouter(
  connect<StateProps, DispatchProps, {}, RootState>(
    (state) => {
      const tournament = selectTournament(state)
      const importId = localStorage.getItem(storageImportId) || localStorage.getItem(storageTempImportId) || ''
      return {
        disablePlayerListEdit: disablePlayerListEdit(state),
        isTeamFormat: tournament.isTeamFormat,
        isTwoManTeamFormat: tournament.isTwoManTeamFormat,
        numberOfRounds: tournament.rounds.length,
        players: selectTournamentPlayers(state).data?.players || [],
        reserveList: selectTournamentPlayers(state).data?.reserveList || [],
        scoringStarted: scoringStartedOnAnyRound(state),
        tournament: tournament,
        reserveListEnabled: selectTournamentSite(state).reserveListEnabled,
        tournamentSettings: selectTournamentSettings(state),
        tournamentType: selectTournament(state).tournamentType,
        unassignedTeamPlayers: getUnassignedTeamPlayers(state),
        auth: state.authenticationReducer,
        selectedPlayers: getSelectedPlayerIds(state),
        countryId: state.organizationReducer.countryId,
        gitImportData: selectGitImportStatus(state, { tournamentId: tournament.id, importId }),
      }
    },
    {
      enqueueNotification,
      maybeRefetchTournamentPlayers,
      maybeRefetchTournamentTeams,
      removeSelectedPlayers: tournamentPlayersApi.endpoints.deletePlayers.initiate,
      moveSelectedPlayers: tournamentPlayersApi.endpoints.movePlayers.initiate,
      fetchAllStartLists,
      fetchTournamentSettings: tournamentSettingsApi.endpoints.getTournamentSettings.initiate,
      fetchDivisions,
      fetchOrganizationProducts,
      fetchTournamentProducts,
      gitImportCancel: tournamentGitApi.endpoints.cancelGitImport.initiate,
      setGitDialogOpen,
    },
  )(injectIntl(withStyles(styles)(UnconnectedPlayers))),
)
