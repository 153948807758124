import {
  MatchPlayBracket_8_Players,
  MatchPlayBracket_16_Players,
  MatchPlayBracket_32_Players,
  MatchPlayBracket_64_Players,
  MatchPlayBracket_128_Players,
  MatchPlayBracket_256_Players,
} from '../store/api/enums/matchPlayBracketEnums'

export const matchPlayBracketsMap = {
  8: MatchPlayBracket_8_Players,
  16: MatchPlayBracket_16_Players,
  32: MatchPlayBracket_32_Players,
  64: MatchPlayBracket_64_Players,
  128: MatchPlayBracket_128_Players,
  256: MatchPlayBracket_256_Players,
}
