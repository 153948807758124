import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Button, FormControl, TextField } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { login, LoginPayload } from '../../store/authentication/actions'
import { Navigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { LOCALSTORAGE_ROUTE_NAME } from '../../config'
import { LoginBg } from '../../assets/images'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import authStyles from './styles'
import HeadHelmet from '../../components/layout/HeadHelmet'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import SubmitButton from '@app/components/ui/SubmitButton'
import { getSiteLogo, getSiteLogo2x } from '@app/utils/logoUtils'
import { PasswordToggleIcon } from '@app/assets/icons/PasswordToggleIcon'
import LanguageSwitcher from '@app/components/layout/LanguageSwitcher'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { RootState } from '@app/store'

interface StateProps {
  auth: AuthenticationState
}

interface DispatchProps {
  login(loginData: LoginPayload): void
}

interface State {
  username: string
  password: string
  passwordFieldType: string
}

type Props = WithStyles<typeof authStyles> & StateProps & DispatchProps & WithRouterProps & WrappedComponentProps

class Login extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      passwordFieldType: 'password',
    }
  }

  submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.props.login(this.state)
  }

  onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      ...this.state,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  render() {
    const {
      auth: { loggedIn, error, checkLoading },
      classes,
      intl,
    } = this.props

    if (loggedIn) {
      const path = window.localStorage.getItem(LOCALSTORAGE_ROUTE_NAME)

      if (path) {
        window.localStorage.removeItem(LOCALSTORAGE_ROUTE_NAME)
        return <Navigate to={path} />
      }

      return <Navigate to="/dashboard" />
    }

    return (
      <>
        <HeadHelmet titleId={'login.login'} />

        <OverlayLoader visible={checkLoading} />

        <div className={classes.wrapper} style={{ backgroundImage: `url(${LoginBg})` }}>
          <div className={classes.languageSwitcherContainer}>
            <LanguageSwitcher />
          </div>
          <Card className={classes.loginCard}>
            <CardContent className={classes.loginCardContent}>
              <div className={classes.logoContainer}>
                <img className={classes.logo} alt={'GGB'} src={getSiteLogo()} srcSet={`${getSiteLogo2x()} 2x`} />
              </div>

              <form onSubmit={this.submit} className={classes.loginForm}>
                <FormControl fullWidth={true}>
                  <label htmlFor="username">
                    <FormattedMessageWrapper id="login.username" />
                  </label>
                  <TextField
                    name="username"
                    type="username"
                    id="username"
                    onChange={this.onChange}
                    placeholder={intl.formatMessage({ id: 'login.usernamePlaceholder' })}
                    variant="outlined"
                    className={classes.loginInput}
                  />
                </FormControl>

                <FormControl fullWidth={true}>
                  <label htmlFor="password">
                    <FormattedMessageWrapper id="login.password" />
                  </label>
                  <TextField
                    name="password"
                    type={this.state?.passwordFieldType || 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={this.onChange}
                    variant="outlined"
                    className={classes.loginInput}
                  />
                  <PasswordToggleIcon
                    className={classes.passwordToggleIcon}
                    onClick={() => this._togglePasswordFieldType()}
                  />
                </FormControl>
                {error && <div className={classes.alertBox}>{error}</div>}
                <CardActions className={classes.actionWrap}>
                  <SubmitButton
                    isLoading={this.props.auth.loading}
                    disabled={this.props.auth.loading}
                    fullWidth
                    className={classes.submitButton}
                  >
                    <FormattedMessageWrapper id="login.login" />
                  </SubmitButton>
                  <Button
                    disabled={this.props.auth.loading}
                    onClick={this._handleForgot}
                    className={classes.forgotPassword}
                    fullWidth
                  >
                    <FormattedMessageWrapper id={'buttons.requestForgotPassword'} />
                  </Button>
                </CardActions>
              </form>
            </CardContent>
          </Card>

          <div className={classes.apply}>
            <Typography variant={'body1'}>
              <FormattedMessageWrapper id={'login.applyText'} />
              <Link to={'/apply'}>
                <FormattedMessageWrapper id={'buttons.organizationApply'} />
              </Link>
            </Typography>
          </div>
        </div>
      </>
    )
  }

  private _handleForgot = () => {
    const { navigate } = this.props
    navigate('/reset-password')
  }

  private _togglePasswordFieldType = () => {
    const passwordFieldType = this.state.passwordFieldType === 'password' ? 'text' : 'password'
    this.setState({ passwordFieldType })
  }
}
const RoutedLogin = injectIntl(withRouter(Login))
const StyledLogin = withStyles(authStyles)(RoutedLogin)
const ConnectedLogin = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => ({ auth: state.authenticationReducer }),
  { login },
)(StyledLogin as any)

export default ConnectedLogin
