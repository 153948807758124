import get from 'lodash/get'

const testHandicapValueString = (value: number | string): RegExpMatchArray | null => {
  const valToString = String(value)
  return valToString.match(/^\+?\d\d?[.|,]?\d?/)
}

export interface ValidateHandicapValueResult {
  value?: string
  valid: boolean
  test: RegExpMatchArray | null
}

const validateHandicapValueError: ValidateHandicapValueResult = {
  value: undefined,
  valid: false,
  test: null,
}

export const validateHandicapValue = (value: undefined | string): ValidateHandicapValueResult => {
  if (!value) {
    return validateHandicapValueError
  }

  const test = testHandicapValueString(value)

  if (test === null) {
    return validateHandicapValueError
  }

  const hcpValue = test[0].replace(/,/g, '.')
  const isPlusHandicap = hcpValue.startsWith('+')

  const hcpIsTooLow = isPlusHandicap && Number(value) > 10
  const hcpIsTooHigh = !isPlusHandicap && Number(value) > 54
  const hcpNotInRange = hcpIsTooLow || hcpIsTooHigh
  if (hcpNotInRange) {
    return validateHandicapValueError
  }

  let hcpFloatValue
  if (isPlusHandicap) {
    hcpFloatValue = parseFloat(hcpValue)
  } else {
    hcpFloatValue = -1 * parseFloat(hcpValue)
  }

  return {
    value: String(hcpFloatValue),
    valid: true,
    test,
  }
}

const getTeamName = (player: TournamentPlayer, teams: TournamentTeam[]) => {
  let teamName = ''
  if (teams) {
    let team: any = teams.find((team) => team.players && team.players.some((p) => p.userId === player.userId))
    if (!team) {
      team = player.invitationTeam
    }
    teamName = get(team, 'name', '')
  } else if (player.team && player.team.name) {
    teamName = player.team.name
  }

  return teamName
}

const getCustomQuestionAnswer = (player: TournamentPlayer, questionId: number) => {
  const answer = Object.values(player.customQuestionAnswers).find((q) => q.registrationQuestionId === questionId)
  return answer ? answer.answer : ''
}

type PlayerPoolOrder = { [key: string]: number }

/**
 * Sorts player array in place
 *
 * @param players
 * @param teams
 * @param sortBy
 * @param sortDirection
 */
export const sortPlayers = (
  players: TournamentPlayer[],
  teams: TournamentTeam[],
  sortBy: string,
  sortDirection: SortDirection = 'asc',
): PlayerPoolOrder => {
  const strippedSortBy: PoolOrderBy = sortBy.split(':')[0] as PoolOrderBy

  let compareFn = (a: TournamentPlayer, b: TournamentPlayer): number =>
    a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1
  const maybeDateToTime = (date) => (date ? new Date(date).getTime() : 0)

  switch (strippedSortBy) {
    case 'teamName':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) => (getTeamName(a, teams) > getTeamName(b, teams) ? 1 : -1)
      break
    case 'club':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) =>
        get(a, 'club.name', '') > get(b, 'club.name', '') ? 1 : -1
      break
    case 'customQuestion':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) => {
        const questionId = parseInt(sortBy.split(':')[1])
        return getCustomQuestionAnswer(a, questionId) > getCustomQuestionAnswer(b, questionId) ? 1 : -1
      }
      break
    case 'hcp':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) => {
        const hcpA = a.hcp
        const hcpB = b.hcp

        if (hcpA === undefined || hcpB === undefined) {
          return 0
        }

        return hcpB - hcpA
      }
      break
    case 'phcp':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) => {
        const phcpA = a.hcp
        const phcpB = b.hcp

        if (phcpA === undefined || phcpB === undefined) {
          return 0
        }

        return phcpB - phcpA
      }
      break
    case 'teeBox':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) =>
        get(a, 'teebox.teeboxName', '') > get(b, 'teebox.teeboxName', '') ? 1 : -1
      break
    case 'firstName':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer): number => {
        if (!a.firstName || !b.firstName) {
          return 0
        }
        return a.firstName.localeCompare(b.firstName)
      }
      break
    case 'lastName':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer): number => {
        if (!a.lastName || !b.lastName) {
          return 0
        }
        return a.lastName.localeCompare(b.lastName)
      }
      break
    case 'dateOfBirth':
    case 'yearOfBirth':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) =>
        maybeDateToTime(a.dateOfBirth) - maybeDateToTime(b.dateOfBirth)
      break
    case 'entryTime':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) =>
        maybeDateToTime(a.entryTime) - maybeDateToTime(b.entryTime)
      break
    case 'division':
      compareFn = (a: TournamentPlayer, b: TournamentPlayer) =>
        maybeDateToTime(a.divisionId) - maybeDateToTime(b.divisionId)
      break
  }

  players.sort(compareFn)

  if (sortDirection === 'desc') {
    players.reverse()
  }

  /**
   * Update players poolOrder
   */
  const poolOrder: PlayerPoolOrder = {}
  players.forEach((player, idx) => {
    player = { ...player, poolOrder: idx }
    poolOrder[player.userId] = idx
  })

  return poolOrder
}

export const formatHandicap = (hcp: string): string => {
  if (hcp === '0') {
    return hcp
  }
  return hcp.includes('-') ? hcp.replace('-', '') : `+${hcp}`
}

export const sortTeamPlayers = (players: TournamentPlayer[]) => {
  const sortedPlayers = [...players]
  sortedPlayers.sort((a, b) => (a.teamPlayerOrder && b.teamPlayerOrder ? a.teamPlayerOrder - b.teamPlayerOrder : 0))
  return sortedPlayers
}

export const trimHcpDecimals = (hcp: string): string => {
  // Allow optional + sign, 2 digits, optional decimal point, optional 1 digit
  const regex = /^(?:\+)?(\d{0,2})([.,](\d)?)?$/
  const value = hcp.replace(',', '.')

  if (!regex.test(value)) {
    return value.substring(0, value.length - 1)
  }

  return value
}
