import React from 'react'
import { Button, FormControl, FormLabel, Grid, InputLabel, MenuItem, TextField, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'

import { createDivision, fetchDivision, updateDivision } from '@store/divisions/actions'
import { OverlayLoader } from '@components/ui/OverlayLoader'
import HeadHelmet from '@app/components/layout/HeadHelmet'
import TopBar from '@app/components/layout/TopBar'
import ContentWrap from '@app/components/layout/ContentWrap'
import SectionTitle from '@app/components/ui/SectionTitle'
import BorderButton from '@app/components/ui/BorderButton'
import get from 'lodash/get'
import { validateHandicapValue } from '@app/utils/playerUtils'
import { DivisionType } from '@app/store/api/enums/tournamentEnums'
import { Select } from '@app/components/forms/Select'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      background: theme.palette.background.default,
    },
  })

interface OwnProps {
  title?: string
}

interface StateProps {
  auth: AuthenticationState
  isLoading: boolean
}

interface DispatchProps {
  createDivision: (organizationId: number, division: DivisionPayload, onSuccess: () => void) => void
  updateDivision: (organizationId: number, division: DivisionState, onSuccess: () => void) => void
  fetchDivision: (organizationId: number, divisionId: number, onSuccess: (division: DivisionState) => void) => void
}

interface ValidationErrors {
  [key: string]: string
}

interface State extends DivisionState {
  isLoading: boolean
  errors: ValidationErrors
}

const initialState: State = {
  id: 0,
  isLoading: false,
  errors: {},
  name: '',
  gender: null,
  minHcp: '',
  maxHcp: '',
  minAge: null,
  maxAge: null,
  type: DivisionType.AUTOMATIC,
}

type DivisionComponentProps = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WrappedComponentProps &
  WithRouterProps

class DivisionComponent extends React.Component<DivisionComponentProps, State> {
  readonly state = initialState

  public componentDidMount(): void {
    const divisionId = this.getDivisionIdFromUrl()

    if (divisionId) {
      const organizationId = get(this.props.auth, 'customerInfo.idCustomer')
      if (organizationId) {
        this.props.fetchDivision(organizationId, Number(divisionId), (division: DivisionState) => {
          this.setState({ ...division } as any)
        })
      }
    }
  }

  public getDivisionIdFromUrl = (): string => {
    const { params } = this.props
    return get(params, 'id')
  }

  public render() {
    const { intl, isLoading } = this.props
    const { name } = this.state
    const titleId = 'divisions.editDivision'
    const title = intl.formatMessage({ id: titleId })

    return (
      <>
        <HeadHelmet titleId={titleId} />
        <TopBar title={title} />

        <OverlayLoader visible={isLoading} />

        <ContentWrap>
          <SectionTitle>
            <FormattedMessageWrapper id="divisions.basicDetails" />
          </SectionTitle>

          <Grid container={true} spacing={6}>
            <Grid item={true} xs={6}>
              <FormLabel>
                <FormattedMessageWrapper id="divisions.name" />
              </FormLabel>
              <TextField
                name="name"
                type="text"
                id="name"
                variant="outlined"
                onChange={this.onNameChange}
                value={name}
                fullWidth
              />
            </Grid>

            <Grid item={true} xs={6}></Grid>

            {this.state.type === DivisionType.AUTOMATIC && (
              <>
                <Grid item xs={3}>
                  <FormControl fullWidth={true} variant="standard" disabled={this.state.isLoading}>
                    <InputLabel id="gender.label">
                      <FormattedMessageWrapper id={'divisions.gender'} />
                    </InputLabel>
                    <Select
                      name={'gender'}
                      labelId="gender.label"
                      value={this.state.gender || ''}
                      onChange={this.onSelectChange}
                      inputProps={{
                        id: 'gender',
                      }}
                    >
                      <MenuItem value={''} key={'empty'}>
                        -
                      </MenuItem>
                      <MenuItem value={'male'} key={'male'}>
                        Male
                      </MenuItem>
                      <MenuItem value={'female'} key={'female'}>
                        Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item={true} xs={9}></Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth={true} variant="standard" disabled={this.state.isLoading}>
                    <InputLabel id="minAge.label">
                      <FormattedMessageWrapper id={'divisions.minAge'} />
                    </InputLabel>
                    <Select
                      labelId="minAge.label"
                      name={'minAge'}
                      value={this.state.minAge || ''}
                      onChange={this.onSelectChange}
                      inputProps={{
                        id: 'minAge',
                      }}
                    >
                      <MenuItem value={''} key={'none'}>
                        -
                      </MenuItem>
                      {this.getAgeValues().map((val) => (
                        <MenuItem value={val} key={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth={true} variant="standard" disabled={this.state.isLoading}>
                    <InputLabel id="maxAge.label">
                      <FormattedMessageWrapper id={'divisions.maxAge'} />
                    </InputLabel>
                    <Select
                      name={'maxAge'}
                      labelId="maxAge.label"
                      value={this.state.maxAge || ''}
                      onChange={this.onSelectChange}
                      inputProps={{
                        id: 'maxAge',
                      }}
                    >
                      <MenuItem value={''} key={'none'}>
                        -
                      </MenuItem>
                      {this.getAgeValues().map((val) => (
                        <MenuItem value={val} key={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item={true} xs={6}></Grid>

                <Grid item xs={3}>
                  <FormLabel>
                    <FormattedMessageWrapper id="divisions.minHcp" />
                  </FormLabel>
                  <TextField
                    name="minHcp"
                    type="text"
                    id="minHcp"
                    variant="outlined"
                    onChange={this.onHcpChange}
                    value={this.state.minHcp}
                    error={!!this.state.errors.minHcp}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormLabel>
                    <FormattedMessageWrapper id="divisions.maxHcp" />
                  </FormLabel>
                  <TextField
                    name="maxHcp"
                    type="text"
                    id="maxHcp"
                    variant="outlined"
                    onChange={this.onHcpChange}
                    value={this.state.maxHcp}
                    error={!!this.state.errors.maxHcp}
                    fullWidth
                  />
                </Grid>

                <Grid item={true} xs={6}></Grid>
              </>
            )}
          </Grid>

          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 60 }}>
            <Button
              onClick={() => {
                this.props.navigate(`/divisions`)
              }}
              size="large"
              style={{ marginRight: 10, color: '#b50303' }}
            >
              <FormattedMessageWrapper id="buttons.cancel" />
            </Button>
            <BorderButton
              buttonProps={{
                disabled: !this.state.name.trim(),
                onClick: this.onClickSave,
                size: 'large',
                style: { marginRight: 10 },
              }}
            >
              <FormattedMessageWrapper id="buttons.save" />
            </BorderButton>
          </div>
        </ContentWrap>
      </>
    )
  }

  getAgeValues = (): number[] => {
    return Array.from(Array(100).keys()).map((val) => val + 1)
  }

  onSelectChange = (event): void => {
    const { name, value } = event.target
    if (name) {
      this.setState({
        [name]: value,
      } as any)
    }
  }

  onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      name: event.currentTarget.value,
    })
  }

  onHcpChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    const errors = this.state.errors
    if (value) {
      const validated = validateHandicapValue(value)

      if (!validated.valid) {
        errors[name] = 'Invalid HCP'
      } else {
        delete errors[name]
      }
    } else {
      delete errors[name]
    }
    this.setState({
      [name]: value,
      errors,
    } as any)
  }

  onClickSave = (): void => {
    const organizationId = get(this.props.auth, 'customerInfo.idCustomer')
    const { name, gender, minHcp, maxHcp, minAge, maxAge, id, type } = this.state

    const divisionId = this.getDivisionIdFromUrl()
    const validatedMinHcp = validateHandicapValue(minHcp as string)
    const validatedMaxHcp = validateHandicapValue(maxHcp as string)

    const division: DivisionState = {
      id,
      name,
      gender: gender || null,
      minHcp: validatedMinHcp.valid ? validatedMinHcp.value : null,
      maxHcp: validatedMaxHcp.valid ? validatedMaxHcp.value : null,
      minAge: minAge || null,
      maxAge: maxAge || null,
      type,
    }

    if (organizationId) {
      if (divisionId) {
        division.id = Number(divisionId)
        this.props.updateDivision(organizationId, division, () => {
          this.props.navigate(`/divisions`)
        })
      } else {
        this.props.createDivision(organizationId, division, () => {
          this.props.navigate(`/divisions`)
        })
      }
    }
  }
}

const RoutedDivisionComponent = withRouter(DivisionComponent)

export const Division = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    auth: store.authenticationReducer,
    isLoading: store.divisionsReducer.loading,
  }),
  {
    createDivision,
    updateDivision,
    fetchDivision,
  },
)(withStyles(styles)(injectIntl(RoutedDivisionComponent as any)))
