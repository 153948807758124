import { MatchPlayBracketBackground } from '@app/assets/images'
import {
  useDeleteMatchPlayBracketPlayerMutation,
  useGetMatchPlayBracketQuery,
  useUpdateMatchPlayBracketMutation,
} from '@app/store/api/endpoints/matchPlayBracketsApi'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import React, { useRef } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import TopBar from '../../components/layout/TopBar'
import { MatchPlayBracket } from './MatchPlayBracket'
import { MatchPlayBracketPool } from './MatchPlayBracketPool'
import { makeStyles } from '@mui/styles'
import SaveButton from '@app/components/ui/SaveButton'
import { CircularProgress } from '@mui/material'
import { matchPlayBracketsMap } from '@app/utils/matchPlayBracketUtils'
import { useSyncBracketFinalAndBronzeMatchPosition } from '@app/hooks/useSyncBracketFinalAndBronzeMatchPosition'
import { RootState, useAppDispatch } from '@app/store'
import { updateBracketPlayersToCache } from '@app/store/api/thunks/matchPlayBracketsThunks'
import { isCustomerEventUser } from '@app/utils/authUtils'
import { useSelector } from 'react-redux'

interface ParamTypes {
  id: string
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    backgroundImage: `url(${MatchPlayBracketBackground})`,
    padding: '50px 0px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.7)',
    minHeight: '100vh',
  },
  saveButton: {
    position: 'absolute',
    left: '300px',
    top: '80px',
  },
  loadingContainer: {
    position: 'absolute',
    right: '50%',
    top: '100px',
  },
}))

export const MatchPlayBracketView: React.FC = () => {
  const { id } = useParams<keyof ParamTypes>() as ParamTypes
  const { data: bracket, isLoading, isFetching } = useGetMatchPlayBracketQuery(parseInt(id))
  const [updateMatchPlayBracket, { isLoading: isUpdating }] = useUpdateMatchPlayBracketMutation()
  const [deleteMatchPlayBracketPlayer] = useDeleteMatchPlayBracketPlayerMutation()

  const dispatch = useAppDispatch()
  const intl = useIntl()
  const classes = useStyles()
  const navigate = useNavigate()
  const timeoutRef = useRef<any>(null)
  useSyncBracketFinalAndBronzeMatchPosition()

  const auth = useSelector((state: RootState) => state.authenticationReducer)
  const isEventManager = isCustomerEventUser(auth.roleInfo)

  if (isEventManager) {
    navigate('/dashboard')
  }

  const isRefetching: boolean = isFetching && !isLoading

  const handleOnDragEnd = (result: DropResult): void => {
    if (result.destination === null || result.destination === undefined) {
      return
    }
    const { source, destination, draggableId } = result

    const sourceStage = source.droppableId.split('-')[0] as BracketStage
    const destinationStage = destination.droppableId.split('-')[0] as BracketStage

    if (sourceStage === MatchPlayBracketStages.POOL && destinationStage === MatchPlayBracketStages.POOL) {
      sortPoolPlayers(result)
    }

    const numberOfPlayers = bracket?.options?.numberOfPlayers || 0
    const stageNames = Object.values(matchPlayBracketsMap[numberOfPlayers])
    const sourceStageIndex = stageNames.indexOf(sourceStage)
    const destinationStageIndex = stageNames.indexOf(destinationStage)
    const isMovingToEarlierStage =
      sourceStageIndex > destinationStageIndex && destinationStage !== MatchPlayBracketStages.BRONZE_MATCH

    if (
      sourceStage === destinationStage ||
      destinationStage === MatchPlayBracketStages.POOL ||
      isMovingToEarlierStage
    ) {
      return
    }

    const sourceIsPool = sourceStage === MatchPlayBracketStages.POOL

    const originalPlayers: MatchPlayBracketPlayer[] = bracket?.players ? [...bracket.players] : []

    const draggablePlayerId = draggableId.split('-').splice(1).join('-')
    const sourcePlayerIndex: number = originalPlayers.findIndex(
      (player: MatchPlayBracketPlayer) => player.playerBracketId === draggablePlayerId && player.stage === sourceStage,
    )

    const originalPlayer = originalPlayers[sourcePlayerIndex]

    if (sourceIsPool) {
      originalPlayers[sourcePlayerIndex] = {
        ...originalPlayer,
        stage: destinationStage,
        order: destination.index + 1,
      }
      updatePlayers(originalPlayers)
    } else {
      originalPlayers[sourcePlayerIndex] = { ...originalPlayer, winner: true }
      const newPlayer: MatchPlayBracketPlayer = {
        ...originalPlayer,
        order: destination.index + 1,
        id: null,
        score: '',
        stage: destinationStage,
        winner: false,
      }
      updatePlayers([...originalPlayers, newPlayer])
    }
  }

  const sortPoolPlayers = (result: DropResult): void => {
    const { destination, draggableId } = result

    if (!destination) {
      return
    }

    const originalPlayers: MatchPlayBracketPlayer[] = bracket?.players ? [...bracket.players] : []
    const poolPlayers = originalPlayers.filter((p: MatchPlayBracketPlayer) => p.stage === MatchPlayBracketStages.POOL)

    const sourcePlayerBracketId = draggableId.split('-').splice(1).join('-')
    const destinationIndex = destination.index

    const playerIndex = originalPlayers.findIndex(
      (p: MatchPlayBracketPlayer) => p.playerBracketId === sourcePlayerBracketId,
    )

    if (playerIndex !== -1) {
      const newOrder = destinationIndex + 1
      originalPlayers[playerIndex] = { ...originalPlayers[playerIndex], order: newOrder }
    }

    let order = 1
    poolPlayers
      .filter((p: MatchPlayBracketPlayer) => p.playerBracketId !== sourcePlayerBracketId)
      .forEach((p: MatchPlayBracketPlayer, i: number) => {
        if (i === destinationIndex) {
          order++
        }

        const playerIndex = originalPlayers.findIndex(
          (player: MatchPlayBracketPlayer) => player.playerBracketId === p.playerBracketId,
        )

        if (playerIndex !== -1) {
          originalPlayers[playerIndex] = { ...p, order }
        }

        order++
      })

    updatePlayers(originalPlayers)
  }

  const getPlayers = (): MatchPlayBracketPlayer[] => {
    return bracket?.players || []
  }

  const updatePlayers = (players: MatchPlayBracketPlayer[], noDelay = false, disableSave = false): void => {
    updatePlayersToCache(players)
    clearTimeout(timeoutRef.current)
    if (noDelay && !disableSave) {
      submitPlayersUpdate(players)
      return
    }
    if (!disableSave) {
      timeoutRef.current = setTimeout(() => submitPlayersUpdate(players), 2500)
    }
  }

  const updateBracket = (newBracket?: MatchPlayBracket, newPlayers?: MatchPlayBracketPlayer[]): void => {
    clearTimeout(timeoutRef.current)
    const bracketToUpdate = newBracket || bracket
    const players = newPlayers || getPlayers()
    if (bracketToUpdate) {
      const payload = {
        successMessage: intl.formatMessage({ id: 'notifications.saveSuccessful' }),
        body: {
          name: bracketToUpdate.name || '',
          infoText: bracketToUpdate.infoText || '',
          options: bracketToUpdate.options || null,
          players: players,
        },
      }

      updateMatchPlayBracket({ ...payload, id: bracketToUpdate.id })
    }
  }

  const editPlayer = (player: MatchPlayBracketPlayer, deletePlayer = false): void => {
    const playerIndex = getPlayers().findIndex(
      (p: MatchPlayBracketPlayer) => p.playerBracketId === player.playerBracketId && p.stage === player.stage,
    )
    const newPlayers = [...getPlayers()]

    if (deletePlayer) {
      newPlayers.splice(playerIndex, 1)
    } else {
      newPlayers[playerIndex] = player
    }

    if (!deletePlayer) {
      const noDelay = true
      updatePlayers(newPlayers, noDelay)
    }

    if (bracket && deletePlayer && player.id) {
      updatePlayersToCache(newPlayers)
      deleteMatchPlayBracketPlayer({ bracketId: bracket.id, playerId: player.id })
    }
  }

  const submitPlayersUpdate = (players: MatchPlayBracketPlayer[]): void => {
    updateBracket(undefined, players)
  }

  const updatePlayersToCache = (players: MatchPlayBracketPlayer[]): void => {
    if (bracket?.id) {
      dispatch(updateBracketPlayersToCache(bracket.id, players))
    }
  }

  return (
    <>
      <TopBar titleId="matchPlayBracket.title" />
      <SaveButton
        isSaving={isUpdating}
        onClick={() => updateBracket()}
        className={classes.saveButton}
        disabled={isUpdating}
      >
        {intl.formatMessage({ id: 'buttons.save' })}
      </SaveButton>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className={classes.container}>
          {(isLoading || isRefetching) && (
            <div className={classes.loadingContainer}>
              <CircularProgress size={32} />
            </div>
          )}
          {bracket && (
            <MatchPlayBracketPool
              bracket={bracket}
              players={getPlayers()}
              updatePlayers={updatePlayers}
              editPlayer={editPlayer}
            />
          )}
          {bracket && (
            <MatchPlayBracket
              bracket={bracket}
              players={getPlayers()}
              editPlayer={editPlayer}
              updateBracket={updateBracket}
            />
          )}
        </div>
      </DragDropContext>
    </>
  )
}
