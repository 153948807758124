import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { emptyTournamentSettings } from '../emptyObjects'
import { RootState } from '@app/store'
import { getTournamentId } from '../slices/configSlice'
import { processTournamentSettingsPayload } from '../utils/tournamentSettingsUtils'
import { saveTournamentSettingsData } from '../thunks/tournamentSettingsThunks'

export const tournamentSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTournamentSettings: build.query<TournamentSettings, number>({
      query: (tournamentId: number) => APIRoute.GET_TOURNAMENT_SETTINGS(tournamentId),
      providesTags: [CacheTag.TOURNAMENT_SETTINGS],
    }),
    updateTournamentSettings: build.mutation<TournamentSettings, UpdateTournamentSettingsPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_TOURNAMENT_SETTINGS(payload.id),
        method: HttpMethod.PUT,
        body: processTournamentSettingsPayload(payload.body),
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: tournamentSettings } = await queryFulfilled
          dispatch(saveTournamentSettingsData(payload.id, tournamentSettings))
        } catch {
          dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_SETTINGS]))
          console.error('Error updating tournament settings')
        }
      },
    }),
  }),
})

export const selectTournamentSettings = (state: RootState) =>
  tournamentSettingsApi.endpoints.getTournamentSettings.select(getTournamentId(state))(state).data ||
  emptyTournamentSettings

export const { useGetTournamentSettingsQuery, useLazyGetTournamentSettingsQuery, useUpdateTournamentSettingsMutation } =
  tournamentSettingsApi
