import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.customPalette.bodyBackground,
    margin: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    boxShadow: theme.shadows[10],
    borderRadius: theme.shape.borderRadius,
  },
}))

interface SectionWrapperProps {
  style?: React.CSSProperties
  className?: string
  children?: any
}

export const SectionWrapper: React.FC<SectionWrapperProps> = ({ children, className, style }) => {
  const classes = useStyles()
  return (
    <div style={style} className={classNames([classes.root, className])}>
      {children}
    </div>
  )
}
