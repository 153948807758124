import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../..'

const initialState: ClubsAndCoursesState = {
  clubs: [],
  roundCourses: [],
}

const slice = createSlice({
  name: 'clubsAndCourses',
  initialState,
  reducers: {
    resetClubsAndCourses: () => initialState,
    setClubs: (state, action: PayloadAction<Club[]>) => {
      state.clubs = action.payload
    },
    internalRemoveTournamentRoundCourses: (state, action: PayloadAction<number>) => {
      const filteredCourses = state.roundCourses.filter((c) => c.roundIndex !== action.payload)
      return {
        ...state,
        roundCourses: filteredCourses,
      }
    },
    internalUpdateTournamentCourse: (state, action: PayloadAction<RoundCourse>) => {
      const { id: courseId, clubId, roundIndex, version } = action.payload
      const filteredCourses = state.roundCourses.filter((c) => {
        const duplicateCourse = c.id === courseId && c.roundIndex === roundIndex && c.version === version
        const clubHasChanged = c.clubId !== clubId && c.roundIndex === roundIndex
        return !clubHasChanged && !duplicateCourse
      })
      return {
        ...state,
        roundCourses: [...filteredCourses, action.payload],
      }
    },
    internalUpdateTournamentRoundCourses: (state, action: PayloadAction<RoundCourse[]>) => {
      return {
        ...state,
        roundCourses: action.payload,
      }
    },
  },
})

export const {
  resetClubsAndCourses,
  setClubs,
  internalRemoveTournamentRoundCourses,
  internalUpdateTournamentCourse,
  internalUpdateTournamentRoundCourses,
} = slice.actions

export const selectClubs = (state: RootState): Club[] => state.clubsAndCoursesReducer.clubs

export const selectSelectedRoundCourse = (state: RootState): RoundCourse | undefined => {
  const roundIndex = state.configReducer.tournament.selectedRoundIndex
  const selectedCourseId = state.configReducer.clubsAndCourses.selectedCourseId
  const courseVersion = state.configReducer.clubsAndCourses.selectedCourseVersion
  return (
    state.clubsAndCoursesReducer.roundCourses.filter(
      (c) => c.roundIndex === roundIndex && c.id === selectedCourseId && c.version === courseVersion,
    )[0] || selectRoundCourseOptions(state)[0]
  )
}

export const selectRoundCourseOptions = (state: RootState): RoundCourse[] => {
  const roundIndex = state.configReducer.tournament.selectedRoundIndex
  const selectedCourseId = state.configReducer.clubsAndCourses.selectedCourseId
  const courseVersion = state.configReducer.clubsAndCourses.selectedCourseVersion
  return state.clubsAndCoursesReducer.roundCourses.filter(
    (c) => c.roundIndex === roundIndex && (c.id === selectedCourseId ? c.version === courseVersion : true),
  )
}

export const selectAllRoundCourseOptions = (state: RootState): RoundCourse[] => {
  const roundIndex = state.configReducer.tournament.selectedRoundIndex
  return state.clubsAndCoursesReducer.roundCourses.filter((c) => c.roundIndex === roundIndex)
}

export const selectRoundCoursesForAllRounds = (state: RootState): RoundCourse[] => {
  return state.clubsAndCoursesReducer.roundCourses
}

export default slice.reducer
