import React from 'react'
import {
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  Tooltip,
  Theme,
  Chip,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { connect } from 'react-redux'
import ContentWrap from '@components/layout/ContentWrap'
import { getNextUrl } from '@utils/nextUrlHelper'
import TournamentFormActions from '@components/tournament/TournamentFormActions'
import { rem } from '@theme/materialUITheme'
import SectionTitle from '@components/ui/SectionTitle'
import { scrollToTop } from '@components/ui'
import HeadHelmet from '@components/layout/HeadHelmet'
import Collapse from '@mui/material/Collapse'
import CustomQuestionsBlock from '@components/tournament/CustomQuestionsBlock'
import { CapOverflowMethods, DivisionType, EntryMethods, TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { createDate } from '@utils/dates'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { updateTournamentSiteField } from '@app/store/api/thunks/tournamentSiteThunks'
import { InfoOutlined } from '@mui/icons-material'
import { SectionWrapper } from '@app/components/layout/SectionWrapper'
import { isTeamFormatId } from '@app/utils/gameFormatUtils'
import { Payments } from '@scenes/tournament/entries/Payments'
import { fetchOrganization } from '@app/store/organization/actions'
import { SplitDateTimePicker } from '@app/components/date-pickers'
import { Select } from '@app/components/forms/Select'
import { selectTournamentSite, tournamentSiteApi } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentPlayers, tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { getTournament } from '@app/store/api/thunks/tournamentThunks'
import { enqueueNotification } from '@app/store/notifications/actions'
import InfoTooltip from '@app/components/ui/InfoTooltip'

const styles = (theme: Theme) =>
  createStyles({
    notification: {
      display: 'flex',
      flexDirection: 'row',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.mode === 'light' ? theme.customPalette.darkGray2 : theme.palette.primary.contrastText,
      padding: 10,
      '@global': {
        '> div': {
          margin: 10,
        },
        '> svg': {
          margin: 10,
          fontSize: '2.3rem',
        },
      },
    },
    division: {
      marginRight: 24,
      '&.MuiChip-outlinedPrimary': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `1px 1px 2px ${theme.customPalette.lightGray3}`,
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontWeight: 'bold',
        padding: `${rem(12)}`,
      },
    },
    divisionsContainer: {
      padding: `${rem(20)} 0 ${rem(20)} ${rem(20)}`,
    },
    formMargin: {
      marginLeft: rem(15),
    },
    manualSelectionRequiredControl: {
      marginLeft: rem(30),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    manualSelectionRequiredLabel: {
      fontSize: rem(14),
      color: `${theme.customPalette.contrastGrey} !important`,
      marginBottom: 0,
      marginLeft: `-${rem(10)}`,
      display: 'flex',
      alignItems: 'center',
    },
  })

interface StateProps {
  tournament: TournamentState
  tournamentPlayers: TournamentPlayers
  tournamentSite: TournamentSite
  units?: OrganizationUnits
  divisions: DivisionState[]
  organization: OrganizationState
  auth: AuthenticationState
  tournamentProducts: TournamentProduct[]
}

interface DispatchProps {
  updateTournamentSiteField(payload: FieldUpdatePayload): void
  saveTournamentSite(payload: TournamentSiteUpdatePayload): void
  getTournament(payload: TournamentPayload): void
  getTournamentSite(id: number): void
  loadTournamentPlayers(tournamentId: number): void
  fetchOrganization: (id: number) => void
  enqueueNotification(error: any, variant?: string): void
}

type Props = StateProps & DispatchProps & WrappedComponentProps & WithRouterProps & WithStyles<typeof styles>

interface State {
  termsAndConditionsEnabled: boolean
}

class UnconnectedEntries extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      termsAndConditionsEnabled: !!this.props.tournamentSite.termsAndConditionsUrl,
    }
  }

  public componentDidMount(): void {
    const { getTournamentSite, loadTournamentPlayers, getTournament, fetchOrganization, auth } = this.props
    const { params } = this.props
    const tournamentId = Number(params.id)

    const idCustomer = auth.customerInfo?.idCustomer

    if (idCustomer) {
      fetchOrganization(idCustomer)
    }

    if (tournamentId) {
      getTournament({ id: tournamentId })
      getTournamentSite(tournamentId)
      loadTournamentPlayers(tournamentId)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.tournamentSite.termsAndConditionsUrl !== prevProps.tournamentSite.termsAndConditionsUrl &&
      this.props.tournamentSite.termsAndConditionsUrl !== ''
    ) {
      this.setState({ termsAndConditionsEnabled: !!this.props.tournamentSite.termsAndConditionsUrl })
    }
  }

  public render() {
    const {
      tournamentSite: {
        enabled,
        termsAndConditionsUrl,
        onlineEntriesEnabled,
        entryMethod,
        maximumPlayerAmount,
        maximumTeamAmount,
        maximumEntryAmountEnabled,
        registerAndCancelTimeFramesEnabled,
        customQuestions,
        customQuestionsEnabled,
        teeboxSelectionEnabled,
        manualDivisionSelectionEnabled,
        manualDivisionSelectionRequired,
        partialSignupEnabled,
      },
      classes,
      tournament,
    } = this.props

    /**
     * Don't try to render if tournament hasn't loaded yet
     */
    if (tournament.rounds.length < 1) {
      return null
    }

    const isFirstRoundTeamGame =
      isTeamFormatId(tournament.rounds[0].primaryGameId) || isTeamFormatId(tournament.rounds[0].sideGameId)

    return (
      <SectionWrapper>
        <HeadHelmet titleId={'progress.registration'} />

        <ContentWrap>
          <SectionTitle>
            <FormattedMessageWrapper id="tournament.registrationAndPlayers" />
            <FormControl className={classes.formMargin}>
              <Switch
                checked={onlineEntriesEnabled}
                onChange={this.onToggleChange}
                name="onlineEntriesEnabled"
                value="true"
                color="primary"
                disabled={!enabled}
              />
            </FormControl>
          </SectionTitle>

          {!onlineEntriesEnabled && (
            <div className={classes.notification}>
              <InfoOutlined color={'primary'} />
              <div>
                <FormattedMessageWrapper id={'tournament.entriesSectionInfo1'} />
              </div>
            </div>
          )}

          <Divider style={{ marginBottom: 30 }} />

          <Collapse in={onlineEntriesEnabled}>
            <Grid container={true} spacing={2}>
              <Grid item xs={12}>
                <SectionTitle>
                  <FormattedMessageWrapper id="tournament.entriesSettings" />
                </SectionTitle>
              </Grid>

              {this._renderEntryMethodSelector()}

              <Grid item xs={4} style={{ marginTop: 10 }}>
                <FormControl className="SwitchControl">
                  <Switch
                    checked={maximumEntryAmountEnabled}
                    onChange={this.onToggleChange}
                    name="maximumEntryAmountEnabled"
                    value="true"
                    color="primary"
                  />
                  <FormLabel htmlFor={'maximumPlayerAmountEnabled'} className="SwitchLabel">
                    <FormattedMessageWrapper
                      id={`tournament.${
                        this.entryMethod === EntryMethods.INDIVIDUAL
                          ? 'maximumPlayerAmountEnabled'
                          : 'maximumTeamAmountEnabled'
                      }`}
                    />
                  </FormLabel>
                </FormControl>
                {this.entryMethod === EntryMethods.TEAM && (
                  <FormControl className="SwitchControl">
                    <Switch
                      checked={partialSignupEnabled}
                      onChange={this.onToggleChange}
                      name="partialSignupEnabled"
                      value="true"
                      color="primary"
                    />
                    <FormLabel htmlFor={'allowPartialSignUp'} className="SwitchLabel">
                      <FormattedMessageWrapper id={`tournament.allowPartialSignUp`} />
                    </FormLabel>
                  </FormControl>
                )}
              </Grid>

              <Grid item xs={5}>
                <Collapse in={maximumEntryAmountEnabled}>
                  <FormLabel style={{ display: 'block' }}>
                    <FormattedMessageWrapper
                      id={`tournament.${
                        this.entryMethod === EntryMethods.INDIVIDUAL
                          ? 'howManyPlayersCanRegister'
                          : 'howManyTeamsCanRegister'
                      }`}
                    />
                  </FormLabel>
                  <TextField
                    type="number"
                    required={true}
                    variant={'outlined'}
                    name={this.entryMethod === EntryMethods.INDIVIDUAL ? 'maximumPlayerAmount' : 'maximumTeamAmount'}
                    onChange={this.onChange}
                    value={this.entryMethod === EntryMethods.INDIVIDUAL ? maximumPlayerAmount : maximumTeamAmount}
                    inputProps={{
                      style: {
                        width: 46,
                        padding: 12,
                      },
                    }}
                  />

                  {this._renderOverflowSelection()}
                </Collapse>
              </Grid>

              <Grid item xs={4} style={{ marginTop: 10 }}>
                <FormControl className="SwitchControl">
                  <Switch
                    checked={this.state.termsAndConditionsEnabled}
                    onChange={(e) => {
                      this.setState({ termsAndConditionsEnabled: e.currentTarget.checked })
                      if (!e.currentTarget.checked) {
                        this.props.updateTournamentSiteField({
                          fieldName: 'termsAndConditionsUrl',
                          value: null,
                        })
                      }
                    }}
                    name="termsAndConditionsEnabled"
                    value="true"
                    color="primary"
                  />
                  <FormLabel htmlFor={'termsAndConditionsEnabled'} className="SwitchLabel">
                    <FormattedMessageWrapper id="tournament.termsAndConditionsEnabled" />
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginTop: 10 }}>
                <Collapse in={this.state.termsAndConditionsEnabled}>
                  <FormLabel style={{ display: 'block' }}>
                    <FormattedMessageWrapper id="tournament.termsAndConditionsUrl" />
                  </FormLabel>
                  <TextField
                    type="text"
                    variant={'outlined'}
                    name="termsAndConditionsUrl"
                    onChange={(e) => {
                      this.props.updateTournamentSiteField({
                        fieldName: 'termsAndConditionsUrl',
                        value: e.target.value,
                      })
                    }}
                    value={termsAndConditionsUrl ? termsAndConditionsUrl : ''}
                    fullWidth
                  />
                </Collapse>
              </Grid>
            </Grid>

            <Divider style={{ marginBottom: 40 }} />

            {/*
              TIME FRAMES FOR REGISTRATION AND CANCELLATION
            */}
            <SectionTitle>
              <FormattedMessageWrapper id="tournament.timeFramesTitle" />
              <FormControl className={classes.formMargin}>
                <Switch
                  checked={registerAndCancelTimeFramesEnabled}
                  onChange={this.onToggleChange}
                  name="registerAndCancelTimeFramesEnabled"
                  value="true"
                  color="primary"
                />
              </FormControl>
            </SectionTitle>

            <Collapse in={registerAndCancelTimeFramesEnabled}>{this._renderRegisterAndCancelTimeFrames()}</Collapse>

            <Divider style={{ marginBottom: 40 }} />

            {/*
              TEEBOX SELECTION
            */}
            {this.props.tournament.rounds.length === 1 && !isFirstRoundTeamGame && (
              <>
                <SectionTitle>
                  <FormattedMessageWrapper id="tournament.teeboxSelectionTitle" />
                  <FormControl className={classes.formMargin}>
                    <Switch
                      checked={teeboxSelectionEnabled ? teeboxSelectionEnabled : false}
                      onChange={this.onToggleChange}
                      name="teeboxSelectionEnabled"
                      value="true"
                      color="primary"
                    />
                  </FormControl>
                </SectionTitle>

                <div style={{ paddingBottom: 20 }}>
                  <Typography variant="body1">
                    <FormattedMessageWrapper id="tournament.teeboxSelectionInfo" />
                  </Typography>
                </div>

                <Divider style={{ marginBottom: 40 }} />
              </>
            )}

            {/*
              DIVISION TOGGLE AND LIST
            */}
            {this.props.tournament.divisionsEnabled && (
              <>
                <SectionTitle>
                  <FormattedMessageWrapper id="tournament.askPlayersToSelectDivisions" />
                  <FormControl className={classes.formMargin}>
                    <Switch
                      checked={manualDivisionSelectionEnabled}
                      onChange={this.onToggleChange}
                      name="manualDivisionSelectionEnabled"
                      value="true"
                      color="primary"
                    />
                  </FormControl>
                </SectionTitle>

                <div style={{ paddingBottom: 20 }}>
                  <Typography variant="body1">
                    <FormattedMessageWrapper id="tournament.askPlayersToSelectDivisionsDesc" />
                  </Typography>
                </div>

                <div className={classes.divisionsContainer}>{this._renderDivisions()}</div>

                {manualDivisionSelectionEnabled && (
                  <FormControl className={classes.manualSelectionRequiredControl}>
                    <Switch
                      checked={manualDivisionSelectionRequired}
                      onChange={this.onToggleChange}
                      name="manualDivisionSelectionRequired"
                      value="true"
                      color="primary"
                    />
                    <FormLabel className={classes.manualSelectionRequiredLabel}>
                      <FormattedMessageWrapper id="tournament.mustAnswer" />
                      <InfoTooltip
                        size={'small'}
                        style={{ marginLeft: 10 }}
                        text={<FormattedMessageWrapper id="tournament.mustAnswerInfo" />}
                      />
                    </FormLabel>
                  </FormControl>
                )}

                <Divider style={{ marginBottom: 40 }} />
              </>
            )}

            {/*
              PAYMENTS
            */}
            {this.props.organization.paymentEnabled && <Payments />}

            {/*
              CUSTOM QUESTIONS
            */}
            <SectionTitle>
              <FormattedMessageWrapper id="tournament.customQuestions" />
              <Tooltip
                disableTouchListener={entryMethod !== EntryMethods.TEAM}
                disableHoverListener={entryMethod !== EntryMethods.TEAM}
                disableFocusListener={entryMethod !== EntryMethods.TEAM}
                title={<FormattedMessageWrapper id="tournament.disabledWithTeamEntryMethod" />}
              >
                <div>
                  <FormControl className={classes.formMargin}>
                    <Switch
                      checked={customQuestionsEnabled}
                      onChange={this.onToggleChange}
                      name="customQuestionsEnabled"
                      value="true"
                      color="primary"
                    />
                  </FormControl>
                </div>
              </Tooltip>
            </SectionTitle>

            <Collapse in={customQuestionsEnabled}>
              <CustomQuestionsBlock customQuestions={customQuestions} />
            </Collapse>

            <Divider style={{ marginTop: 30 }} />
          </Collapse>

          <TournamentFormActions
            nextLabelId={'buttons.players'}
            onClickNext={this.onClickNext}
            onClickSaveAndPublish={this.onClickSave}
          />
        </ContentWrap>
      </SectionWrapper>
    )
  }

  private get entryMethod() {
    const {
      tournament: { isTeamFormat },
      tournamentSite: { entryMethod },
    } = this.props

    if (!isTeamFormat) {
      return EntryMethods.INDIVIDUAL
    }

    return entryMethod
  }

  private onClickSave = () => {
    this._saveTournamentSite()
  }

  private onClickNext = () => {
    this._saveTournamentSite(() => {
      const nextUrl = getNextUrl(this.props.location.pathname, 'players')
      this.props.navigate(nextUrl)
      scrollToTop()
    })
  }

  private _saveTournamentSite = (onSuccess?: () => void) => {
    const { params, tournamentSite } = this.props
    const tournamentId = Number(params.id)

    if (this._validatePage() === false) {
      return
    }

    this.props.saveTournamentSite({
      id: tournamentId,
      body: tournamentSite,
      onSuccess,
    })
  }

  private _validatePage = () => {
    const { tournamentSite, tournamentProducts, intl, enqueueNotification } = this.props
    let valid = true

    if (tournamentSite.paymentEnabled && !tournamentSite.termsAndConditions) {
      enqueueNotification(intl.formatMessage({ id: 'errors.termsAndConditionsError' }), 'error')
      valid = false
    }

    if (tournamentSite.paymentEnabled && tournamentProducts.length === 0) {
      enqueueNotification(intl.formatMessage({ id: 'errors.noProductsSelected' }), 'error')
      valid = false
    }

    return valid
  }

  private onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const number = Math.abs(parseInt(e.currentTarget.value, 10))

    if (!isNaN(number)) {
      this.props.updateTournamentSiteField({
        fieldName: e.currentTarget.name,
        value: number,
      })
    } else {
      this.props.updateTournamentSiteField({
        fieldName: e.currentTarget.name,
        value: e.currentTarget.value,
      })
    }
  }

  public onSelectChange = (event) => {
    if (event.target.name) {
      this.props.updateTournamentSiteField({
        fieldName: event.target.name,
        value: event.target.value,
      })

      if (event.target.name === 'entryMethod' && event.target.value === 'team') {
        // Disable reserve list temporarily if entry method is team
        this.props.updateTournamentSiteField({
          fieldName: 'maximumCapOverflowMethod',
          value: CapOverflowMethods.ENTRIES_CLOSED,
        })
      }
    }
  }

  private onToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.currentTarget.name
    const value = e.currentTarget.checked

    this.props.updateTournamentSiteField({
      fieldName,
      value,
    })

    if (fieldName === 'registerAndCancelTimeFramesEnabled' && value) {
      this._recalculateRegisterAndCancelTimeFrames()
    }
  }

  private onDateChange = (name: string) => {
    return (newDate: DateTimeValue) => {
      if (newDate) {
        this.props.updateTournamentSiteField({
          fieldName: name,
          value: newDate,
        })
      }
    }
  }

  public _renderDivisions = () => {
    const { divisions, classes, tournament } = this.props

    return divisions.map(
      (division) =>
        tournament.divisions.find(
          (divisionId) => divisionId === division.id && division.type === DivisionType.MANUAL,
        ) && (
          <Chip
            key={division.id}
            color="primary"
            variant="outlined"
            label={division.name}
            className={classes.division}
          />
        ),
    )
  }

  private _renderEntryMethodSelector = () => {
    const {
      tournamentSite: { entryMethod },
      tournament,
      tournamentPlayers,
    } = this.props

    if (!tournament.isTeamFormat) {
      return null
    }

    return (
      <Grid item xs={3}>
        <Tooltip
          disableTouchListener={tournamentPlayers.players.length === 0}
          disableHoverListener={tournamentPlayers.players.length === 0}
          disableFocusListener={tournamentPlayers.players.length === 0}
          title={<FormattedMessageWrapper id="tournament.disabledAfterEntries" />}
        >
          <div>
            <FormLabel htmlFor={'entryMethod'} style={{ display: 'block' }}>
              <FormattedMessageWrapper id="tournament.entryMethodInfo" />
            </FormLabel>
            <Select
              name={'entryMethod'}
              value={entryMethod}
              onChange={this.onSelectChange}
              input={<OutlinedInput />}
              disabled={tournamentPlayers.players.length > 0}
            >
              <MenuItem value={EntryMethods.INDIVIDUAL}>
                <FormattedMessageWrapper id="tournament.entryMethodIndividual" />
              </MenuItem>
              <MenuItem value={EntryMethods.TEAM}>
                <FormattedMessageWrapper id="tournament.entryMethodTeam" />
              </MenuItem>
            </Select>
          </div>
        </Tooltip>
      </Grid>
    )
  }

  private _renderOverflowSelection = () => {
    const {
      tournamentSite: { maximumCapOverflowMethod },
    } = this.props

    return (
      <Grid container={true} style={{ paddingTop: 20 }}>
        <Grid item={true}>
          <RadioGroup name="maximumCapOverflowMethod" value={maximumCapOverflowMethod} onChange={this.onSelectChange}>
            <FormControlLabel
              value={CapOverflowMethods.RESERVE_LIST}
              control={<Radio color={'primary'} />}
              label={<FormattedMessageWrapper id="tournament.maximumCapOverflowMethodReserveList" />}
            />
            <FormControlLabel
              disabled={this._doesReserveListExist()}
              value={CapOverflowMethods.ENTRIES_CLOSED}
              control={<Radio color={'primary'} />}
              label={
                <>
                  <FormattedMessageWrapper id="tournament.maximumCapOverflowMethodEntriesClosed" />
                  {this._doesReserveListExist() && (
                    <FormHelperText error style={{ marginTop: 0 }}>
                      <FormattedMessageWrapper id="tournament.maximumCapOverflowError" />
                    </FormHelperText>
                  )}
                </>
              }
            />
          </RadioGroup>
        </Grid>
      </Grid>
    )
  }

  private _doesReserveListExist = () => {
    return this.props.tournamentPlayers.reserveList.filter((player) => !player.removedAt).length > 0 ? true : false
  }

  private _calculateDate = (
    date: number | Date | undefined,
    addDays: number,
    maxDate?: number | Date,
    minDate?: number | Date,
    time?: 'start' | 'end',
  ) => {
    if (!date) {
      date = new Date()
    }
    if (!time) {
      time = 'start'
    }

    let calculatedDate: Date =
      time === 'start'
        ? createDate({ originalDate: date, hours: 0, minutes: 0, addDays })
        : createDate({ originalDate: date, hours: 23, minutes: 59, addDays })

    if (maxDate) {
      const maxDateToDate = createDate({ originalDate: maxDate, hours: 0, minutes: 0 })
      if (calculatedDate > maxDateToDate) {
        calculatedDate = maxDateToDate
      }
    } else if (minDate) {
      const minDateToDate = createDate({ originalDate: minDate, hours: 0, minutes: 0 })
      if (calculatedDate < minDateToDate) {
        calculatedDate = minDateToDate
      }
    }

    return calculatedDate
  }

  private _recalculateRegisterAndCancelTimeFrames = () => {
    const {
      tournament: { startTime, endTime, createdAt, tournamentType },
    } = this.props

    const isWeekly = tournamentType === TournamentTypes.weekly

    this.props.updateTournamentSiteField({
      fieldName: 'registrationStartsTime',
      value: isWeekly
        ? this._calculateDate(startTime, 0, undefined, undefined)
        : this._calculateDate(createdAt, 1, startTime, undefined),
    })
    this.props.updateTournamentSiteField({
      fieldName: 'registrationEndsTime',
      value:
        isWeekly && endTime
          ? this._calculateDate(endTime, 0, undefined, undefined, 'end')
          : this._calculateDate(startTime, -2, startTime, new Date(), 'end'),
    })
    this.props.updateTournamentSiteField({
      fieldName: 'registrationCancellingStartsTime',
      value: isWeekly
        ? this._calculateDate(startTime, 0, undefined, undefined)
        : this._calculateDate(createdAt, 1, startTime, undefined),
    })
    this.props.updateTournamentSiteField({
      fieldName: 'registrationCancellingEndsTime',
      value:
        isWeekly && endTime
          ? this._calculateDate(endTime, 0, undefined, undefined, 'end')
          : this._calculateDate(startTime, -1, startTime, new Date(), 'end'),
    })
  }

  private _renderRegisterAndCancelTimeFrames = () => {
    const {
      tournament: { startTime, endTime, createdAt, tournamentType },
      tournamentSite: {
        registrationStartsTime,
        registrationEndsTime,
        registrationCancellingStartsTime,
        registrationCancellingEndsTime,
      },
      units = 'metric',
      intl,
    } = this.props

    const isWeekly = tournamentType === TournamentTypes.weekly

    const defaultRegistrationStartDate =
      registrationStartsTime !== null
        ? registrationStartsTime
        : isWeekly
        ? this._calculateDate(startTime, 0, undefined, undefined)
        : this._calculateDate(createdAt, 1, startTime, undefined)
    const defaultRegistrationEndsTime =
      registrationEndsTime !== null
        ? registrationEndsTime
        : isWeekly && endTime
        ? this._calculateDate(endTime, 0, undefined, undefined, 'end')
        : this._calculateDate(startTime, -2, startTime, new Date(), 'end')
    const defaultRegistrationCancellingStartsTime =
      registrationCancellingStartsTime !== null
        ? registrationCancellingStartsTime
        : isWeekly
        ? this._calculateDate(startTime, 0, undefined, undefined)
        : this._calculateDate(createdAt, 1, startTime, undefined)
    const defaultRegistrationCancellingEndsTime =
      registrationCancellingEndsTime !== null
        ? registrationCancellingEndsTime
        : isWeekly && endTime
        ? this._calculateDate(endTime, 0, undefined, undefined, 'end')
        : this._calculateDate(startTime, -1, startTime, new Date(), 'end')

    return (
      <>
        <FormGroup style={{ marginBottom: 16 }}>
          <Typography variant="h4" style={{ margin: '20px 0 10px' }}>
            <FormattedMessageWrapper id="tournament.registration" />
          </Typography>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <Grid container={true} style={{ maxWidth: 768 }} spacing={3}>
                <Grid item={true} xs={6}>
                  <FormLabel>
                    <FormattedMessageWrapper id={'tournament.registrationStartDate'} />
                  </FormLabel>
                  <SplitDateTimePicker
                    value={defaultRegistrationStartDate}
                    maxDate={startTime}
                    errorMessages={{ maxDate: intl.formatMessage({ id: 'tournament.openingTimeMaxError' }) }}
                    onChange={this.onDateChange('registrationStartsTime')}
                    units={units}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <FormLabel>
                    <FormattedMessageWrapper id={'tournament.registrationEndDate'} />
                  </FormLabel>
                  <SplitDateTimePicker
                    value={defaultRegistrationEndsTime}
                    minDate={registrationStartsTime !== null ? registrationStartsTime : undefined}
                    maxDate={isWeekly ? endTime : startTime}
                    onChange={this.onDateChange('registrationEndsTime')}
                    errorMessages={{ maxDate: intl.formatMessage({ id: 'tournament.openingTimeMaxError' }) }}
                    units={units}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormGroup>

        <FormGroup style={{ marginBottom: 30 }}>
          <Typography variant="h4" style={{ margin: '20px 0 10px' }}>
            <FormattedMessageWrapper id="tournament.cancellation" />
          </Typography>
          <Grid container={true} style={{ maxWidth: rem(768) }} spacing={3}>
            <Grid item={true} xs={6}>
              <FormLabel>
                <FormattedMessageWrapper id={'tournament.cancellationStartDate'} />
              </FormLabel>
              <SplitDateTimePicker
                value={defaultRegistrationCancellingStartsTime}
                minDate={registrationStartsTime !== null ? registrationStartsTime : undefined}
                maxDate={startTime}
                onChange={this.onDateChange('registrationCancellingStartsTime')}
                errorMessages={{ minDate: intl.formatMessage({ id: 'tournament.cancelingTimeMinError' }) }}
                units={units}
              />
            </Grid>
            <Grid item={true} xs={6}>
              <FormLabel>
                <FormattedMessageWrapper id={'tournament.cancellationEndDate'} />
              </FormLabel>
              <SplitDateTimePicker
                value={defaultRegistrationCancellingEndsTime}
                minDate={registrationCancellingStartsTime !== null ? registrationCancellingStartsTime : undefined}
                maxDate={isWeekly ? endTime : startTime}
                onChange={this.onDateChange('registrationCancellingEndsTime')}
                errorMessages={{ minDate: intl.formatMessage({ id: 'tournament.closingTimeMinError' }) }}
                units={units}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </>
    )
  }
}

const RoutedEntries = injectIntl(withRouter(UnconnectedEntries))

export const Entries = withStyles(styles)(
  connect<StateProps, DispatchProps, {}, RootState>(
    (state): StateProps => {
      return {
        tournament: selectTournament(state),
        tournamentPlayers: selectTournamentPlayers(state).data || {
          players: [],
          reserveList: [],
        },
        tournamentSite: selectTournamentSite(state),
        units: state.authenticationReducer.units,
        divisions: state.divisionsReducer.divisions,
        organization: state.organizationReducer,
        auth: state.authenticationReducer,
        tournamentProducts: state.paymentsReducer.tournamentProducts,
      }
    },
    {
      getTournamentSite: tournamentSiteApi.endpoints.getTournamentSite.initiate,
      getTournament,
      loadTournamentPlayers: tournamentPlayersApi.endpoints.getPlayers.initiate,
      saveTournamentSite: tournamentSiteApi.endpoints.updateTournamentSite.initiate,
      updateTournamentSiteField,
      fetchOrganization,
      enqueueNotification,
    },
  )(RoutedEntries),
)
