import { HttpMethod, api } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { setWeeklyScoreParams } from '../slices/configSlice'
import { RootState } from '@app/store'
import { selectTournament } from '../endpoints/tournamentApi'
import { preProcessTournament } from '@app/utils/sagaHelpers'
import { saveTournamentData } from '../thunks/tournamentThunks'
import { TournamentTypes } from '../enums/tournamentEnums'

export const tournamentScoringApi = api.injectEndpoints({
  endpoints: (build) => ({
    updatePlayerScores: build.mutation<TournamentScoringState, UpdatePlayerTournamentScoresPayload>({
      query: (payload) => ({
        url: APIRoute.PLAYER_SCORES(payload.tournamentId, payload.playerId),
        method: HttpMethod.PUT,
        body: payload.body,
      }),
      transformResponse: (response: UpdatePlayerTournamentScoresResponse): TournamentScoringState => {
        return { playerId: response.playerId, weeklyRoundId: response.roundId ? Number(response.roundId) : null }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled
          const tournament = selectTournament(getState() as RootState)
          if (!tournament.rounds[0].scoringStarted) {
            dispatch(api.util.invalidateTags([CacheTag.TOURNAMENT_SINGLE]))
          }

          if (tournament.tournamentType === TournamentTypes.weekly) {
            dispatch(setWeeklyScoreParams(data))
          }
        } catch {
          console.error('Error updating player scores')
        }
      },
    }),
    updateResultsPublishedStatus: build.mutation<TournamentState, UpdateResultsPublishedStatusPayload>({
      query: (payload) => ({
        url: APIRoute.PUT_EDIT_TOURNAMENT_RESULTS_PUBLISHED(payload.tournamentId),
        method: HttpMethod.PUT,
        body: { resultsPublished: payload.isResultsPublished },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const updatedTournament = preProcessTournament(data)
          dispatch(saveTournamentData(updatedTournament))
        } catch {
          console.error('Error updating results published status')
        }
      },
    }),
    sendWeeklyHcpScores: build.mutation<void, SendWeeklyHcpScoresPayload>({
      query: (payload) => ({
        url: APIRoute.WEEKLY_PLAYER_HCP(payload.tournamentId, payload.playerId),
        method: HttpMethod.POST,
        body: { weeklyRoundId: String(payload.weeklyRoundId) },
      }),
    }),
  }),
})

export const {
  useUpdatePlayerScoresMutation,
  useUpdateResultsPublishedStatusMutation,
  useSendWeeklyHcpScoresMutation,
} = tournamentScoringApi
