import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { RootState } from '@app/store'

export const scoringStartedOnAnyRound = (store: RootState) => {
  const rounds = selectTournament(store).rounds
  return rounds.some((r) => r.scoringStarted)
}

export const disablePlayerListEdit = (store: RootState) => {
  const rounds = selectTournament(store).rounds

  // Disable edit if any startlist is published
  if (rounds.some((round) => round.status?.isConfigured)) {
    return true
  }

  // Disable edit permanently if scoring has started for second round
  if (rounds[1] && rounds[1].scoringStarted) {
    return true
  }

  return false
}

/**
 * Get currently selected tournament round
 *
 * @param store
 * @returns Selected tournament round object
 */
export const getSelectedRound = (store: RootState): Round => {
  const rounds = selectTournament(store).rounds
  const selectedRoundId = store.configReducer.tournament.selectedRoundId
  return rounds.find((round) => round.id === selectedRoundId) as Round
}
