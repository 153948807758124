import { Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { rem } from '../../theme/materialUITheme'

export interface AuthStyleClasses extends Record<string, string> {
  wrapper: string
  logoContainer: string
  logo: string
  loginCard: string
  loginCardContent: string
  loginForm: string
  loginInput: string
  alertBox: string
  actionWrap: string
  actionsFooter: string
  submitButton: string
  forgotPassword: string
  passwordToggleIcon: string
  apply: string
  languageSwitcherContainer: string
}

const authStyles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      padding: '50px 0 280px 0',
      '@media only screen and (min-height: 1000px)': {
        padding: '180px 0 280px 0',
      },
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: 42,
      marginBottom: 29,
    },
    logo: {
      width: 280,
    },
    loginCard: {
      display: 'flex',
      width: 474,
      borderRadius: '4px 4px 0 0',
      backgroundColor: theme.customPalette.loginBackground,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        width: 360,
        '@global': {
          '> div': {
            width: '100%',
          },
        },
      },
    },
    loginCardContent: {
      padding: '0 64px',
    },
    loginForm: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      '@global': {
        '> div': {
          marginTop: 22,
        },
        label: {
          fontFamily: ['Exo', 'sans-serif'].join(','),
          fontWeight: 800,
          fontSize: rem(16),
          fontStyle: 'italic',
          marginBottom: 6,
        },
        'label + .MuiInput-formControl': {
          marginTop: 10,
        },
      },
    },
    loginInput: {
      backgroundColor: '#F5F5F7',
      borderRadius: 4,
      height: 35,
      '& input': {
        color: 'initial',
        padding: '0 10px',
        height: 35,
        '&::placeholder': {
          fontSize: 14,
        },
      },
      '& fieldset': {
        border: 0,
      },
    },
    alertBox: {
      backgroundColor: theme.palette.error.main,
      color: theme.customPalette.light,
      padding: theme.spacing(2),
    },
    actionWrap: {
      padding: 0,
      marginTop: '54px !important',
      flexWrap: 'wrap',
    },
    submitButton: {
      height: 50,
      fontSize: 18,
      lineHeight: '21px',
      fontWeight: 500,
    },
    forgotPassword: {
      margin: '34px 0 25px 0 !important',
      padding: 0,
      fontSize: 16,
      lineHeight: '19px',
      height: 19,
      textDecoration: 'underline',
      pointerEvents: 'none',
      '& span': {
        pointerEvents: 'auto',
      },
    },
    passwordToggleIcon: {
      position: 'absolute',
      bottom: 8,
      right: 30,
      cursor: 'pointer',
    },
    apply: {
      textAlign: 'center',
      marginTop: 0,
      backgroundColor: theme.customPalette.applyBackground,
      height: 84,
      width: 474,
      borderRadius: '0 0 4px 4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 5px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        width: 360,
      },
      '& p': {
        color: theme.customPalette.applyText,
        fontWeight: 500,
      },
      '& a': {
        color: theme.customPalette.applyText,
        fontWeight: 500,
        marginLeft: 5,
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    actionsFooter: {
      textAlign: 'center',
      marginTop: '2rem',
    },
    languageSwitcherContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  })

export default authStyles
