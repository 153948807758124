// LIBRARIES
import React, { RefObject } from 'react'
import { connect } from 'react-redux'
import { Grid, TextField, Theme, IconButton, CircularProgress, Typography, FormControl } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { Clear, Search } from '@mui/icons-material'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { IMaskInput } from 'react-imask'

// COMPONENTS
import SectionTitle from '@components/ui/SectionTitle'
import InfoTooltip from '@components/ui/InfoTooltip'
import MiniPlayerCard from '@components/tournament/MiniPlayerCard'

// ACTIONS
import { clearPlayersSearch, searchPlayers, updatePlayersField } from '@store/players/actions'
import { getCustomQuestionIdMap } from '@app/store/api/selectors/tournamentSiteSelectors'
import { FI_COUNTRY_ID, SV_COUNTRY_ID } from '@app/config'
import { RootState } from '@app/store'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { getTournamentId, selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'

const styles = (theme: Theme) =>
  createStyles({
    searchResults: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
      maxHeight: 400,
      overflowX: 'auto',
    },
    searchButton: {
      '&&': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        width: 68,
        height: 54,
        '& > span, & > svg': {
          color: '#fff',
        },
        '&:disabled': {
          opacity: 0.4,
        },
      },
    },
    infoTooltip: {
      marginLeft: 10,
      '& .Mui-paper': {
        padding: 20,
      },
    },
  })

interface StateProps {
  playersState: PlayersState
  playerExtraInfo: string
  customQuestionsIdMap: { [questionId: number]: Question }
  organizationCountryId?: number
  tournamentId: number
}

interface DispatchProps {
  searchPlayers(onComplete?: (args: APICallResult<SearchPlayer[]>) => void): void
  updatePlayersField(payload: FieldUpdatePayload): void
  clearPlayersSearch(): void
  addPlayerToTournament: (payload: AddPlayerToTournamentPayload) => void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

interface State {
  loading: boolean
  searchActive: boolean
  addingPlayer: boolean
  addingByEnterActive: boolean
  validationErrors: {
    firstName: boolean
    lastName: boolean
    golferId: boolean
  }
}

const initialState = {
  loading: false,
  searchActive: false,
  addingPlayer: false,
  addingByEnterActive: false,
  validationErrors: { firstName: false, lastName: false, golferId: false },
}

class SearchAndAddPlayersComponent extends React.Component<Props, State> {
  readonly state = initialState
  _golferIdRef: RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    this._golferIdRef = React.createRef()
  }

  componentDidMount(): void {
    this.props.updatePlayersField({
      fieldName: 'searchCountryId',
      value: this.props.organizationCountryId,
    })
    document.addEventListener('keydown', this._handleKeyDown)
  }

  componentWillUnmount(): void {
    this.props.clearPlayersSearch()
    document.removeEventListener('keydown', this._handleKeyDown)
  }

  private _handleKeyDown = (e: KeyboardEvent) => {
    const { golferId, searchResults } = this.props.playersState
    const { addingByEnterActive, loading } = this.state
    const shouldAddPlayerToTournament = golferId && addingByEnterActive && !loading && searchResults.length === 1
    if (e.key === 'Enter' && shouldAddPlayerToTournament) {
      this.setState({ addingPlayer: true })
      this.props.addPlayerToTournament({
        tournamentId: this.props.tournamentId,
        providerPlayerIdentifier: searchResults[0].providerPlayerIdentifier,
        onSuccess: () => {
          this.props.clearPlayersSearch()
          this.setState({ searchActive: false, addingPlayer: false, addingByEnterActive: false })
          this._golferIdRef?.current?.focus()
        },
        onFailure: () => {
          this.setState({ addingPlayer: false, addingByEnterActive: false })
        },
      })
    }
  }

  private _getSectionTitle = () => {
    switch (this.props.organizationCountryId) {
      case FI_COUNTRY_ID:
        return 'tournament.addPlayersFromEbirdie'
      case SV_COUNTRY_ID:
        return 'tournament.addPlayersByNameOrGolfId'
      default:
        return 'tournament.addPlayersByName'
    }
  }

  private _getInfoTooltipText = () => {
    switch (this.props.organizationCountryId) {
      case FI_COUNTRY_ID:
        return 'tournament.addPlayersForEbirdieInfo'
      case SV_COUNTRY_ID:
        return 'tournament.addPlayersByNameOrGolfIdInfo'
      default:
        return 'tournament.searchAndAddPlayersInfo'
    }
  }

  private _getNoResultsMessage = () => {
    switch (this.props.organizationCountryId) {
      case FI_COUNTRY_ID:
        return 'tournament.noSearchResultsEbirdie'
      case SV_COUNTRY_ID:
        return 'tournament.noSearchResultsGit'
      default:
        return 'tournament.noSearchResults'
    }
  }

  render() {
    const {
      playersState: { searchTerm, firstName, lastName, golferId },
    } = this.props

    return (
      <>
        <SectionTitle>
          <FormattedMessageWrapper id={this._getSectionTitle()} />
          <InfoTooltip
            arrow
            text={<FormattedMessageWrapper id={this._getInfoTooltipText()} />}
            className={this.props.classes.infoTooltip}
          />
        </SectionTitle>
        <form onSubmit={this._doSearchPlayers}>
          <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
            {this._renderSearchBoxes()}
            <Grid item xs={2}>
              <IconButton
                className={this.props.classes.searchButton}
                disabled={
                  this.state.loading || (searchTerm === '' && firstName === '' && lastName === '' && golferId === '')
                }
                type="submit"
                color="primary"
                size="large"
                data-testid="search-players-button"
              >
                {!this.state.loading && <Search />}
                {this.state.loading && <CircularProgress size={24} />}
              </IconButton>
              {this.state.searchActive && (
                <IconButton disabled={this.state.loading} onClick={this._handleSearchClear} size="large">
                  <Clear />
                </IconButton>
              )}
            </Grid>
            {this._renderResults()}
          </Grid>
        </form>
      </>
    )
  }

  MaskInput2 = React.forwardRef<HTMLElement, { placeholder }>(function MaskInput2(props, ref: any) {
    return (
      <IMaskInput {...props} inputRef={ref} mask="000000-000" radix="-" placeholder={props.placeholder} overwrite />
    )
  })

  public _renderSearchBoxes = () => {
    const { organizationCountryId } = this.props
    const {
      playersState: { searchTerm, firstName, lastName, golferId },
    } = this.props

    if (organizationCountryId === SV_COUNTRY_ID) {
      return (
        <>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                autoComplete={'off'}
                type="text"
                disabled={this.state.loading}
                error={this.state.validationErrors.firstName}
                value={firstName || ''}
                name="firstName"
                placeholder={this.props.intl.formatMessage({ id: 'tournament.firstName' })}
                onChange={this._onChangeSearchValue}
                onFocus={() => this.setState({ addingByEnterActive: false })}
                inputProps={{ 'data-testid': 'search-players-first-name' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                autoComplete={'off'}
                type="text"
                disabled={this.state.loading}
                error={this.state.validationErrors.lastName}
                value={lastName || ''}
                name="lastName"
                placeholder={this.props.intl.formatMessage({ id: 'tournament.lastName' })}
                onChange={this._onChangeSearchValue}
                onFocus={() => this.setState({ addingByEnterActive: false })}
                inputProps={{ 'data-testid': 'search-players-last-name' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                autoComplete={'off'}
                type="text"
                disabled={this.state.loading}
                error={this.state.validationErrors.golferId}
                value={golferId || ''}
                name="golferId"
                placeholder={this.props.intl.formatMessage({ id: 'tournament.id' })}
                InputProps={{
                  inputComponent: this.MaskInput2 as any,
                }}
                inputProps={{ 'data-testid': 'search-players-golfer-id' }}
                inputRef={this._golferIdRef}
                onChange={this._onChangeSearchValue}
                onFocus={() => this.setState({ addingByEnterActive: false })}
                onPaste={this._onPasteGolferId}
              />
            </FormControl>
          </Grid>
        </>
      )
    }

    return (
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            autoComplete={'off'}
            type="text"
            disabled={this.state.loading}
            value={searchTerm || ''}
            name="searchTerm"
            placeholder={this.props.intl.formatMessage({ id: 'tournament.searchByName' })}
            onChange={this._onChangeSearchValue}
            inputProps={{ 'data-testid': 'search-players-search-term' }}
          />
        </FormControl>
      </Grid>
    )
  }

  private _doSearchPlayers = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { firstName, lastName, golferId } = this.props.playersState
    const validation = {
      firstName: /^\S$/.test(firstName) || !!(lastName && !firstName),
      lastName: /^\S$/.test(lastName) || !!(firstName && !lastName),
      golferId: /^\S{1,5}$/.test(golferId),
    }

    const validationError = Object.keys(validation).some((item) => validation[item])

    if (validationError) {
      this.setState({ validationErrors: validation })
    } else {
      this.setState({ loading: true })
      this.setState({ validationErrors: initialState.validationErrors })
      this.props.searchPlayers(this._handleSearchDone)
    }
  }

  private _onChangeSearchValue = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this._updatePlayersField(e.currentTarget.name, e.currentTarget.value)
  }

  private _onPasteGolferId = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const value = e.clipboardData.getData('Text')
    const regex = /^\d{9}$/
    const golferIdWithoutDash = regex.test(value)
    if (golferIdWithoutDash) {
      const formattedValue = value.substring(0, 6) + '-' + value.substring(6)
      this._updatePlayersField('golferId', formattedValue)
    }
  }

  private _updatePlayersField = (fieldName: string, value: string) => {
    this.props.updatePlayersField({ fieldName, value })
  }

  private _handleSearchDone = () => {
    this.setState({ loading: false, searchActive: true, addingByEnterActive: true })
  }

  private _handleSearchClear = () => {
    this.setState({ searchActive: false }, this.props.clearPlayersSearch)
  }

  private _renderResults = () => {
    if (this.state.loading) {
      return null
    }

    if (this.state.searchActive && !this.props.playersState.searchResults.length) {
      return this._renderSearchResultGridRoot(
        <Grid item xs={12}>
          <Typography variant={'body2'} data-testid="search-players-no-results">
            <FormattedMessageWrapper id={this._getNoResultsMessage()} />
          </Typography>
        </Grid>,
      )
    }

    return this._renderSearchResultGridRoot(this._renderSearchResults())
  }

  private _renderSearchResultGridRoot = (content: React.ReactNode) => {
    return (
      <Grid container spacing={2} className={this.props.classes.searchResults}>
        {content}
      </Grid>
    )
  }

  private _renderSearchResults = () => {
    return this.props.playersState.searchResults.map((player, index) => (
      <MiniPlayerCard
        key={`${player.firstName}_${player.lastName}_${index}`}
        player={player}
        playerExtraInfo={this.props.playerExtraInfo}
        customQuestionsIdMap={this.props.customQuestionsIdMap}
        addingPlayer={this.state.addingPlayer}
      />
    ))
  }
}

export const SearchAndAddPlayers = withStyles(styles)(
  connect<StateProps, DispatchProps, {}, RootState>(
    (state) => ({
      playersState: state.playersReducer,
      playerExtraInfo: selectTournamentConfig(state).playerExtraInfo,
      customQuestionsIdMap: getCustomQuestionIdMap(state),
      organizationCountryId: selectTournament(state).tournamentOrganization?.club?.countryId,
      tournamentId: getTournamentId(state),
    }),
    {
      searchPlayers,
      updatePlayersField,
      clearPlayersSearch,
      addPlayerToTournament: tournamentPlayersApi.endpoints.addPlayerToTournament.initiate,
    },
  )(injectIntl(SearchAndAddPlayersComponent)),
)
