interface PlaceholderProps {
  children: React.ReactElement
}

export const Placeholder: React.FC<PlaceholderProps> = ({ children }) => (
  <span style={{ color: 'grey', fontStyle: 'italic' }}>{children}</span>
)

export const getTournamentSelectedDivisions = (
  divisions: DivisionState[],
  selectedDivisionIDs: number[],
): DivisionState[] =>
  divisions.reduce(
    (acc, division) => (selectedDivisionIDs.find((value) => division.id === value) ? [...acc, division] : [...acc]),
    [] as DivisionState[],
  )
