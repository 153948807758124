import { Remove } from '@mui/icons-material'
import Fab from '@mui/material/Fab'

interface RemoveRowActionProps {
  index: number
  onClick?: (index: number) => void
  setDirtyFn?: (dirty: boolean) => void
}

const RemoveRowAction = ({ index, onClick, setDirtyFn }: RemoveRowActionProps) => (
  <Fab
    aria-label="remove"
    size={'small'}
    onClick={() => {
      if (onClick) {
        onClick(index)
        if (setDirtyFn) {
          setDirtyFn(true)
        }
      }
    }}
  >
    <Remove />
  </Fab>
)

export default RemoveRowAction
