import { Action } from 'redux'
import { FETCH_REPORTS, FETCH_WEEKLY_REPORTS, CLEAR_REPORTS } from './constans'

/**
 * FETCH REPORTS
 */

export interface FetchReportsAction extends Action {
  type: 'FETCH_REPORTS'
  startDate: Date
  endDate: Date
}

export interface FetchReportsSuccessAction extends Action {
  type: 'FETCH_REPORTS_SUCCESS'
  reports: Reports
}

export interface FetchReportsFailureAction extends Action {
  type: 'FETCH_REPORTS_FAILURE'
}

export const fetchReports = (startDate, endDate): FetchReportsAction => ({
  type: FETCH_REPORTS,
  startDate,
  endDate,
})

/**
 * FETCH WEEKLY REPORTS
 */

export interface FetchWeeklyReportsAction extends Action {
  type: 'FETCH_WEEKLY_REPORTS'
  startDate: Date
  endDate: Date
}

export interface FetchWeeklyReportsSuccessAction extends Action {
  type: 'FETCH_WEEKLY_REPORTS_SUCCESS'
  reports: Reports
}

export interface FetchWeeklyReportsFailureAction extends Action {
  type: 'FETCH_WEEKLY_REPORTS_FAILURE'
}

export const fetchWeeklyReports = (startDate, endDate): FetchWeeklyReportsAction => ({
  type: FETCH_WEEKLY_REPORTS,
  startDate,
  endDate,
})

/**
 * CLEAR REPORTS STATE
 */

export interface ClearReportsAction {
  type: 'CLEAR_REPORTS'
}

export const clearReports = (): ClearReportsAction => ({
  type: CLEAR_REPORTS,
})
