export const PLAYERS_SEARCH = 'PLAYERS_SEARCH'
export const PLAYERS_SEARCH_SUCCESS = 'PLAYERS_SEARCH_SUCCESS'
export const PLAYERS_SEARCH_FAILURE = 'PLAYERS_SEARCH_FAILURE'

export const UPDATE_PLAYERS_FIELD = 'UPDATE_PLAYERS_FIELD'

export const ADD_TEMP_PLAYER = 'ADD_TEMP_PLAYER'
export const ADD_TEMP_PLAYER_SUCCESS = 'ADD_TEMP_PLAYER_SUCCESS'
export const ADD_TEMP_PLAYER_FAILURE = 'ADD_TEMP_PLAYER_FAILURE'

export const CLEAR_PLAYERS_SEARCH = 'CLEAR_PLAYERS_SEARCH'

export const PLAYERS_SEARCH_ORGANIZATION = 'PLAYERS_SEARCH_ORGANIZATION'
export const PLAYERS_SEARCH_ORGANIZATION_SUCCESS = 'PLAYERS_SEARCH_ORGANIZATION_SUCCESS'
export const PLAYERS_SEARCH_ORGANIZATION_FAILURE = 'PLAYERS_SEARCH_ORGANIZATION_FAILURE'
