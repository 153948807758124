import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { makeStyles } from '@mui/styles'
import { CircularProgress, Theme } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { MatchPlayBracketStages } from '@app/store/api/enums/matchPlayBracketEnums'
import { MatchPlayBracketBackground } from '@app/assets/images'
import { useGetMatchPlayBracketQuery } from '@app/store/api/endpoints/matchPlayBracketsApi'
import { MatchPlayBracketPublic } from './MatchPlayBracketPublic'
import { MatchPlayBracketPoolPublic } from './MatchPlayBracketPoolPublic'
import { S3_ASSETS_URL } from '@app/config'
import Markdown from '@app/components/layout/Markdown'
import { BracketIcon } from '@app/assets/icons/bracket'
import { InfoIcon } from '@app/assets/icons/info'
import LanguageSwitcher from '@app/components/layout/LanguageSwitcher'

enum CurrentView {
  BRACKET = 'bracket',
  INFO = 'info',
}

interface ParamTypes {
  id: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundImage: `url(${MatchPlayBracketBackground})`,
    padding: '50px 0px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.7)',
    minHeight: '100vh',
    minWidth: '100vw',
  },
  bracketContainer: {
    display: 'flex',
  },
  infoContainer: {
    padding: '0 120px',
    marginTop: '-30px',
    '& > h3': {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '160%',
      color: theme.customPalette.light,
      margin: '0',
    },
    '& > div': {
      backgroundColor: theme.customPalette.light,
      borderRadius: '20px',
      padding: '50px',
      marginTop: '30px',
      maxWidth: '1200px',
    },
  },
  loadingContainer: {
    position: 'absolute',
    right: '50%',
    top: '100px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '180px',
    width: '100%',
    margin: '30px 0 80px 0',
    padding: '0 120px',
    backgroundColor: theme.customPalette.darkGreen,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: theme.customPalette.light,
    fontFamily: 'Roboto',
    height: '100%',
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '90%',

    '& > h1': {
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '160%',
      margin: '0',
    },
    '& > div:first-of-type': {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '160%',

      '& > svg': {
        marginBottom: '-5px',
        marginRight: '10px',
      },
    },
  },
  navigation: {
    fontFamily: 'Exo',
    fontStyle: 'italic',
    fontWeight: 800,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    justifyContent: 'flex-start',

    '& > div': {
      paddingRight: '20px',
      cursor: 'pointer',
      borderBottom: '5px solid transparent',
      display: 'flex',
      '&.active': {
        borderColor: '#FCC224',
      },
      '& > svg': {
        marginRight: '10px',
        height: '20px',
      },
    },
  },
  headerLogo: {
    '& > img': {
      maxHeight: '100px',
    },
  },
}))

export const MatchPlayBracketViewPublic: React.FC = () => {
  const { id } = useParams<keyof ParamTypes>() as ParamTypes
  const [currentView, setCurrentView] = useState<CurrentView>(CurrentView.BRACKET)
  const { data: bracket, isLoading } = useGetMatchPlayBracketQuery(parseInt(id))
  const classes = useStyles()
  const intl = useIntl()

  const getPlayers = (): MatchPlayBracketPlayer[] => {
    return bracket?.players || []
  }

  const getPoolPlayers = (): MatchPlayBracketPlayer[] => {
    return getPlayers().filter((player: MatchPlayBracketPlayer) => player.stage === MatchPlayBracketStages.POOL)
  }

  const getLogo = (): string | undefined => {
    if (bracket?.options?.logo) {
      return `${S3_ASSETS_URL}${bracket.options.logo}`
    }
    return
  }

  return (
    <>
      <div className={classes.container}>
        <LanguageSwitcher style={{ float: 'right', marginTop: '-30px' }} />

        {isLoading && (
          <div className={classes.loadingContainer}>
            <CircularProgress size={32} />
          </div>
        )}
        {bracket && (
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <div className={classes.headerInfo}>
                <h1>{bracket?.name}</h1>
                <div>
                  <PersonIcon />
                  <span>
                    {bracket?.options?.numberOfPlayers} {intl.formatMessage({ id: 'matchPlayBracket.players' })}
                  </span>
                </div>
              </div>
              <div className={classes.navigation}>
                <div
                  className={currentView === CurrentView.BRACKET ? 'active' : ''}
                  onClick={() => setCurrentView(CurrentView.BRACKET)}
                >
                  <BracketIcon />
                  {intl.formatMessage({ id: 'matchPlayBracket.bracket' })}
                </div>
                <div
                  className={currentView === CurrentView.INFO ? 'active' : ''}
                  onClick={() => setCurrentView(CurrentView.INFO)}
                >
                  <InfoIcon />
                  {intl.formatMessage({ id: 'matchPlayBracket.infoText' })}
                </div>
              </div>
            </div>
            <div className={classes.headerLogo}>{getLogo() && <img src={getLogo()} alt="Logo" />}</div>
          </div>
        )}
        {bracket && currentView === CurrentView.INFO && (
          <div className={classes.infoContainer}>
            <h3>{intl.formatMessage({ id: 'matchPlayBracket.infoText' })}</h3>
            <div>
              <Markdown>{bracket?.infoText}</Markdown>
            </div>
          </div>
        )}
        {bracket && currentView === CurrentView.BRACKET && (
          <div className={classes.bracketContainer}>
            <MatchPlayBracketPoolPublic players={getPoolPlayers()} />
            <MatchPlayBracketPublic bracket={bracket} players={getPlayers()} />
          </div>
        )}
      </div>
    </>
  )
}
