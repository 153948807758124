import React from 'react'
import { Table, TableHead, TableRow, TableBody, TextField, IconButton } from '@mui/material'
import { VeryDenseTableCell } from '../../tables/tableComponents'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Delete, Add } from '@mui/icons-material'
import { formatOutputNumber, formatInputNumber } from '@utils/numbers'

interface Props {
  template: RankingTemplateItem[]
  updateRankingConfig: (config: RankingTemplateItem[]) => void
}

interface Error {
  field: string
  index: number
}

const RankingConfig = ({ template, updateRankingConfig }: Props) => {
  const [errors, setErrors] = React.useState<Error[]>([])

  const deletePointRow = (index: number) => {
    const config = [...template]
    config.splice(index, 1)
    updateRankingConfig(config)
  }

  const addPointRow = () => {
    const newPosition = template[template.length - 1].position
    const config = [...template]
    config.push({ position: newPosition ? newPosition + 1 : 1, points: 0 })
    updateRankingConfig(config)
  }

  const updatePointRow = (i: number, name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = formatInputNumber(e.target.value)

    if (isNaN(value)) {
      errors.push({ field: name, index: i })
      setErrors(errors)
    } else {
      setErrors(errors.filter((e) => e.field !== name && e.index !== i))
    }

    const config = template.map((row, idx) => (i === idx ? Object.assign({}, row, { [name]: value }) : row))
    updateRankingConfig(config)
  }

  return (
    <div
      style={{
        maxHeight: 300,
        overflowY: 'auto',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        padding: 5,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="tourAndRanking.positionsAwarded" /> = {template.length}
            </VeryDenseTableCell>
            <VeryDenseTableCell>
              <FormattedMessageWrapper id="tourAndRanking.points" /> =&nbsp;
              {template.reduce((acc, p) => {
                return !isNaN(p.points) ? acc + p.points : 0
              }, 0)}
            </VeryDenseTableCell>
            <VeryDenseTableCell></VeryDenseTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {template
            .slice()
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((row, i) => (
              <TableRow key={`${row.position}-${i}`}>
                <VeryDenseTableCell>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    defaultValue={row.position}
                    error={errors.some((e) => e.field === 'position' && e.index === i)}
                    onChange={updatePointRow(i, 'position')}
                    style={{ width: 60 }}
                    inputProps={{ style: { textAlign: 'center' } }}
                  />
                </VeryDenseTableCell>
                <VeryDenseTableCell>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    defaultValue={formatOutputNumber(row.points)}
                    error={errors.some((e) => e.field === 'points' && e.index === i)}
                    onChange={updatePointRow(i, 'points')}
                    style={{ width: 60 }}
                    inputProps={{ style: { textAlign: 'center' } }}
                  />
                </VeryDenseTableCell>
                <VeryDenseTableCell>
                  <IconButton onClick={() => deletePointRow(i)} size="large">
                    <Delete color="error" fontSize="small" />
                  </IconButton>
                </VeryDenseTableCell>
              </TableRow>
            ))}
          <TableRow>
            <VeryDenseTableCell></VeryDenseTableCell>
            <VeryDenseTableCell></VeryDenseTableCell>
            <VeryDenseTableCell>
              <IconButton onClick={() => addPointRow()} size="large">
                <Add color="primary" fontSize="small" />
              </IconButton>
            </VeryDenseTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default RankingConfig
