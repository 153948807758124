import {
  HIDE_AUTO_SAVE_IN_PROGRESS,
  NOTIFICATION_ENQUEUE,
  NOTIFICATION_REMOVE,
  SHOW_AUTO_SAVE_IN_PROGRESS,
} from './constants'
import {
  HideAutoSaveInProgressAction,
  NotificationEnqueueAction,
  NotificationRemoveAction,
  ShowAutoSaveInProgressAction,
} from './actions'

const initialState: NotificationsState = {
  notifications: [],
  autoSaveInProgress: false,
}

type NotificationActions =
  | NotificationEnqueueAction
  | NotificationRemoveAction
  | ShowAutoSaveInProgressAction
  | HideAutoSaveInProgressAction

const notificationsReducer = (state: NotificationsState = initialState, action: NotificationActions) => {
  switch (action.type) {
    case NOTIFICATION_ENQUEUE: {
      // Check if state already includes notification with same message
      const exists = state.notifications.find((n) => n.message === action.notification.message)
      if (exists) {
        return { ...state }
      }

      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      }
    }
    case NOTIFICATION_REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.key !== action.key),
      }
    case SHOW_AUTO_SAVE_IN_PROGRESS:
      return {
        ...state,
        autoSaveInProgress: true,
      }
    case HIDE_AUTO_SAVE_IN_PROGRESS:
      return {
        ...state,
        autoSaveInProgress: false,
      }
    default:
      return state
  }
}

export { notificationsReducer }
