import { RootState } from '@app/store'
import { createSelector } from 'reselect'
import { selectTournamentSite } from '../endpoints/tournamentSiteApi'

const getCustomQuestions = (store: RootState) => selectTournamentSite(store)?.customQuestions

export const getCustomQuestionIdMap = createSelector(getCustomQuestions, (customQuestions: Question[]) => {
  return customQuestions.reduce(
    (acc, customQuestion) => ({
      ...acc,
      [customQuestion.id]: customQuestion,
    }),
    {},
  )
})
