import React, { RefObject } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { updateTournamentSiteField } from '@store/api/thunks/tournamentSiteThunks'
import SectionTitleWithCollapse from '../ui/SectionTitleWithCollapse'
import SectionTitle from '../ui/SectionTitle'
import { Collapse, Typography, Link, Tooltip, IconButton } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import ContentBlock from './ContentBlock'
import FilePicker from '../ui/FilePicker'
import BorderButton from '../ui/BorderButton'
import { CloudUpload, Reply, OpenInNew, Delete } from '@mui/icons-material'
import TournamentCustomizationDialog from '../dialogs/tournamentCustomizationDialog/TournamentCustomizationDialog'
import TournamentCustomization from './TournamentCustomization'
import { enqueueNotification } from '../../store/notifications/actions'
import { formTournamentSiteUrl } from '../../config'
import InfoTooltip from '../ui/InfoTooltip'
import { RootState } from '@app/store'
import { tournamentSiteApi } from '@app/store/api/endpoints/tournamentSiteApi'
import { api } from '@app/store/api/baseApi'
import { CacheTag } from '@app/store/api/cacheTags'

const styles = () =>
  createStyles({
    hidden: {
      // When the collapse hides its content, the padding of the item
      // is still taking up space, creating an unwanted space.
      // The 'hidden' style rule is only applied when the collapse finishes
      // hiding its content
      display: 'none !important',
    },
  })

interface OwnProps {
  tournamentSite: TournamentSite
  tournament: TournamentState
  open?: boolean
  containerRef?: RefObject<HTMLDivElement>
}

interface DispatchProps {
  updateTournamentSiteField: (payload: FieldUpdatePayload) => void
  uploadTournamentSummaryFile: (payload: UploadTournamentFilePayload) => void
  enqueueNotification(error: any, variant?: string): void
  deleteTournamentImage: (payload: DeleteTournamentImagePayload) => void
  invalidateCache: (tags: CacheTag[]) => void
}

type Props = OwnProps & DispatchProps & WrappedComponentProps & WithStyles<typeof styles>

interface State {
  expanded: boolean
  customizationModalOpen: boolean
}

class UnconnectedSiteSummary extends React.Component<Props, State> {
  readonly state: State = {
    expanded: false,
    customizationModalOpen: false,
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.open && !prevProps.open) {
      this.setState({ expanded: true })
    }
  }

  render() {
    const { classes, containerRef } = this.props
    return (
      <div ref={containerRef}>
        <SectionTitleWithCollapse
          expanded={this.state.expanded}
          onChange={(expanded) => this.setState({ expanded: expanded })}
        >
          <SectionTitle>
            <FormattedMessageWrapper id="tournament.summary" />
          </SectionTitle>
        </SectionTitleWithCollapse>

        <Collapse
          in={this.state.expanded}
          style={{ borderBottomWidth: '1px' }}
          classes={this.state.expanded ? undefined : { wrapper: classes.hidden }}
        >
          {this.props.tournament.id && (
            <Link
              href={`${formTournamentSiteUrl(this.props.tournament.id)}?preview=summary`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <BorderButton>
                <FormattedMessageWrapper id={'tournament.viewSummaryPage'} />
                <OpenInNew color="primary" style={{ marginLeft: 10 }} />
              </BorderButton>
            </Link>
          )}

          <Typography variant={'body1'} style={{ margin: '20px 0' }}>
            <FormattedMessageWrapper id="tournament.summaryVisibleText" />
          </Typography>

          {/* Summary text */}
          <Typography variant="h4" style={{ marginBottom: 20 }}>
            <FormattedMessageWrapper id="tournament.tournamentSummaryHeader" />
          </Typography>

          {this.state.expanded && (
            <ContentBlock
              fieldName="tournamentSummaryText"
              updateAction={this.props.updateTournamentSiteField}
              tournamentSite={this.props.tournamentSite}
              style={{ marginBottom: 30 }}
            />
          )}

          {/* Summary file upload */}
          <Typography variant="h4" style={{ marginBottom: 10, marginTop: 20 }}>
            <FormattedMessageWrapper id="tournament.tournamentSummaryFile" />
            <InfoTooltip text={<FormattedMessageWrapper id="tournament.noteInfo" />} style={{ marginLeft: 10 }} />
          </Typography>
          {this._renderUploadSummary()}

          <TournamentCustomization
            tournament={this.props.tournament}
            tournamentSite={this.props.tournamentSite}
            showHeroSummary={true}
            showSummaryPictures={true}
            showAds={false}
            showAppearance={false}
            showHero={false}
            showSponsorLogos={false}
          />
        </Collapse>

        <TournamentCustomizationDialog
          summaryMode={true}
          open={this.state.customizationModalOpen}
          onClose={() => this.setState({ customizationModalOpen: false })}
        />
      </div>
    )
  }

  private _renderUploadSummary() {
    const { summaryFile } = this.props.tournamentSite

    return (
      <div style={{ display: 'flex' }}>
        <FilePicker name="summaryFile" id="summaryFile" onChange={this._onSummaryFileInputChange}>
          <BorderButton
            buttonProps={{
              component: 'span',
            }}
          >
            <FormattedMessageWrapper id="tournament.uploadSummary" />
            <CloudUpload color="primary" style={{ marginLeft: 10 }} />
          </BorderButton>
          <Typography variant="body1" style={{ marginTop: 10 }}>
            <FormattedMessageWrapper id="tournament.uploadSummaryInfo" />
          </Typography>
        </FilePicker>
        {summaryFile && (
          <div>
            <Tooltip title={<FormattedMessageWrapper id={'tournament.clickToOpenSummary'} />}>
              <a href={summaryFile.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <IconButton style={{ marginLeft: 10 }} size="large">
                  <Reply color="primary" style={{ transform: 'scaleX(-1)' }} />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title={<FormattedMessageWrapper id={'buttons.delete'} />}>
              <IconButton onClick={() => this._handleDelete(summaryFile.id)} size="large">
                <Delete color={'error'} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }

  private _onSummaryFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    const tournamentId = this.props.tournament.id

    if (files && files.length > 0) {
      const file = files[0]
      if (file.size > 5000000) {
        this.props.enqueueNotification(this.props.intl.formatMessage({ id: 'errors.tooLargeFile' }), 'error')
        return
      }
      this.props.uploadTournamentSummaryFile({ tournamentId, file })
    }
  }

  private _handleDelete = (imageId: number | null) => {
    const tournamentId = this.props.tournament.id
    if (tournamentId && imageId) {
      this.props.deleteTournamentImage({
        tournamentId,
        imageId,
        onSuccess: () => {
          this.props.invalidateCache([CacheTag.TOURNAMENT_SITE])
        },
      })
    }
  }
}

const SiteSummary = injectIntl(UnconnectedSiteSummary)

export default connect<{}, DispatchProps, OwnProps, RootState>(null, {
  updateTournamentSiteField,
  uploadTournamentSummaryFile: tournamentSiteApi.endpoints.uploadTournamentSummaryFile.initiate,
  enqueueNotification,
  deleteTournamentImage: tournamentSiteApi.endpoints.deleteTournamentImage.initiate,
  invalidateCache: api.util.invalidateTags,
})(withStyles(styles)(SiteSummary as any))
