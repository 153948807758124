import classNames from 'classnames'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { Tab, Tabs, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@app/theme/materialUITheme'
import { PlayerPoolEditContext } from '../enums'
import { EntryMethods } from '@app/store/api/enums/tournamentEnums'

export interface OwnProps {
  onChange: (value: PlayerPoolEditContext) => void
  value: PlayerPoolEditContext
  tournamentSite: TournamentSite
  tournament: TournamentState
  players: TournamentPlayer[]
  teams: TournamentTeam[]
  removedPlayers: TournamentPlayer[]
  reservedPlayers: TournamentPlayer[]
}

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    marginBottom: 0,
    '& .MuiTab-root': {
      textTransform: 'none',
      fontFamily: theme.fonts.primary,
      fontSize: rem(16),
      background: theme.palette.grey['300'],
      color: '#333',
      borderRadius: '10px 10px 0 0',
      marginRight: '3px',
      padding: '12px 22px',
      [theme.breakpoints.down('xl')]: {
        padding: '12px 12px',
      },
      '&.Mui-selected': {
        background: theme.customPalette.darkGreen,
        color: theme.palette.common.white,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.customPalette.darkGreen,
    },
  },
  warningHeader: {
    color: theme.palette.error.main,
  },
}))

const PlayerPoolTabsComponent: React.FC<OwnProps> = ({
  onChange,
  value,
  players,
  teams,
  tournamentSite,
  tournament,
  removedPlayers,
  reservedPlayers,
}) => {
  const classes = useStyles()

  const getPlayerPoolLabel = () => {
    const { onlineEntriesEnabled, maximumEntryAmountEnabled, maximumPlayerAmount, entryMethod } = tournamentSite
    const playerCount = players.length
    if (onlineEntriesEnabled && maximumEntryAmountEnabled && entryMethod === EntryMethods.INDIVIDUAL) {
      const maxPlayerCount = maximumPlayerAmount
      return (
        <span className={classNames({ [classes.warningHeader]: playerCount > maxPlayerCount })}>
          <FormattedMessageWrapper
            id="tournament.playerPoolWithCountMax"
            values={{ count: playerCount, max: maxPlayerCount }}
          />
        </span>
      )
    }
    return <FormattedMessageWrapper id="tournament.playerPoolWithCount" values={{ count: playerCount }} />
  }

  const getTeamsLabel = () => {
    const teamCount = teams.length

    return <FormattedMessageWrapper id="tournament.teamPoolWithCount" values={{ count: teamCount }} />
  }

  const onTabChange = (_: any, value: PlayerPoolEditContext): void => {
    onChange(value)
  }

  const getVisibleTabs = () => {
    const { reserveListEnabled } = tournamentSite
    const { isTeamFormat } = tournament
    const tabs = [
      {
        id: 1,
        value: PlayerPoolEditContext.POOL,
        label: getPlayerPoolLabel(),
        visible: true,
      },
      {
        id: 2,
        value: PlayerPoolEditContext.TEAM,
        label: getTeamsLabel(),
        visible: isTeamFormat,
      },
      {
        id: 3,
        value: PlayerPoolEditContext.RESERVED,
        label: (
          <FormattedMessageWrapper id="tournament.reserveListWithCount" values={{ count: reservedPlayers.length }} />
        ),
        visible: reserveListEnabled,
      },
      {
        id: 4,
        value: PlayerPoolEditContext.REMOVED,
        label: (
          <FormattedMessageWrapper
            id="tournament.removedPlayersListWithCount"
            values={{ count: removedPlayers.length }}
          />
        ),
        visible: true,
      },
    ]
    return tabs.filter((tab) => tab.visible === true)
  }

  return (
    <Tabs
      value={value}
      onChange={onTabChange}
      variant="standard"
      scrollButtons={false}
      indicatorColor="primary"
      textColor="primary"
      className={classes.tabs}
    >
      {getVisibleTabs().map((tab) => (
        <Tab key={tab.id} value={tab.value} label={tab.label} />
      ))}
    </Tabs>
  )
}

export const PlayerPoolTabs = PlayerPoolTabsComponent
