import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

const styles = StyleSheet.create({
  tableHead: {
    padding: '5pt',
    flexDirection: 'row',
    backgroundColor: '#dedede',
  },
  tableHeadTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '12pt',
  },
})

export type TableHeaderColumn = {
  title: string
  width: number
  align?: Style['textAlign']
}

export type TableHeaderProps = {
  columns: TableHeaderColumn[]
  style?: Style | Style[]
  fixed?: boolean
}

export const TableHeader = ({ columns, fixed, style }: TableHeaderProps) => {
  const arrayStyle = Array.isArray(style) ? style : style ? [style] : []
  return (
    <View fixed={fixed} style={[styles.tableHead, ...arrayStyle]}>
      {columns.map((col) => (
        <Text key={col.title} style={[styles.tableHeadTitle, { flex: col.width, textAlign: col.align || 'left' }]}>
          {col.title}
        </Text>
      ))}
    </View>
  )
}
