import { RootState } from '../..'
import { setSelectedCountryId } from '../slices/configSlice'
import { setSelectedClubId } from './configThunks'
import { setSelectedCourseId } from './clubsAndCoursesThunks'
import { selectTournament } from '../endpoints/tournamentApi'

export const checkIfShouldLoadCourses = () => {
  return (dispatch, getState: () => RootState) => {
    const tournament: TournamentState = selectTournament(getState())
    const { tournament: tournamentConfig, clubsAndCourses: clubsAndCoursesConfig } = getState().configReducer

    const currentRoundIndex = tournamentConfig.selectedRoundIndex
    const roundCountryId = tournament.rounds[currentRoundIndex]?.countryId
    const roundClubId = tournament.rounds[currentRoundIndex]?.clubId?.value
    const roundCourseId = tournament.rounds[currentRoundIndex]?.courseId
    const currentSelectedClubId = clubsAndCoursesConfig.selectedClubId

    if (roundCountryId) {
      dispatch(setSelectedCountryId(roundCountryId))
    }

    if (roundClubId && roundClubId !== currentSelectedClubId) {
      dispatch(setSelectedClubId(roundClubId))
    }

    if (roundCourseId) {
      dispatch(setSelectedCourseId(roundCourseId))
    }
  }
}
