import React from 'react'
import { styled } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'

const LOADER_SIZE = 24
interface ButtonLoaderWrapProps {
  loading?: boolean
  children: React.ReactNode
}

const Root = styled('div')({
  position: 'relative',
  display: 'inline-flex',
})

const ButtonLoaderWrap: React.FC<ButtonLoaderWrapProps> = (props) => {
  const { loading, children } = props

  return (
    <Root>
      {children}
      {loading && (
        <CircularProgress
          size={LOADER_SIZE}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -(LOADER_SIZE / 2),
            marginLeft: -(LOADER_SIZE / 2),
          }}
        />
      )}
    </Root>
  )
}

export default ButtonLoaderWrap
