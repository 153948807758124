import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { confirm } from '../confirm/Confirm'

type ShowDeleteConfirmDialog = {
  messageId?: string
  values?: any
  callback: (bool: boolean) => void
}

export type DeleteConfirmChildren = {
  showConfirm: (args: ShowDeleteConfirmDialog) => any
}

type Props = {
  titleId?: string
  subtitle?: string
  children: (args: DeleteConfirmChildren) => any
}

class DeleteConfirm extends React.PureComponent<Props & WrappedComponentProps, {}> {
  render() {
    return this.props.children({
      showConfirm: this._showDeleteConfirmDialog,
    })
  }

  private _showDeleteConfirmDialog = ({
    callback,
    messageId = 'strings.defaultDeleteConfirm',
    values = {},
  }: ShowDeleteConfirmDialog) => {
    const { intl, titleId, subtitle } = this.props

    confirm({
      message: intl.formatMessage(
        {
          id: messageId,
        },
        values,
      ),
      options: {
        title: titleId ? intl.formatMessage({ id: titleId }) : undefined,
        subtitle,
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      },
    }).then(
      (result: any) => {
        callback(result)
      },
      () => {
        callback(false)
      },
    )
  }
}

export default injectIntl(DeleteConfirm)
