import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import makeStyles from '@mui/styles/makeStyles'
import BorderButton from '@app/components/ui/BorderButton'
export interface OrganizationProductsActionsProps {
  openCreateNewDialogFn(): void
  openReportsDialogFn(): void
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const OrganizationProductsActions: React.FC<OrganizationProductsActionsProps> = ({
  openCreateNewDialogFn,
  openReportsDialogFn,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <BorderButton
        filled
        buttonProps={{
          onClick: openCreateNewDialogFn,
        }}
      >
        <FormattedMessageWrapper id="buttons.createNew" />
      </BorderButton>
      <BorderButton
        buttonProps={{
          onClick: openReportsDialogFn,
        }}
      >
        <FormattedMessageWrapper id="navigation.analyticsAndReports" />
      </BorderButton>
    </div>
  )
}
