import { all, takeEvery, put } from 'redux-saga/effects'
import {
  ORGANIZATION_USER_CREATE,
  ORGANIZATION_USER_CREATE_ERROR,
  ORGANIZATION_USER_CREATE_SUCCESS,
  ORGANIZATION_USER_DELETE,
  ORGANIZATION_USER_DELETE_ERROR,
  ORGANIZATION_USER_DELETE_SUCCESS,
  ORGANIZATION_USER_SAVE,
  ORGANIZATION_USER_SAVE_ERROR,
  ORGANIZATION_USER_SAVE_SUCCESS,
  SEND_USER_ACTIVATION_EMAIL,
} from './constants'
import { getToken } from '../authentication/sagas'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import { API_ROOT, APIRoute } from '../../config'
import {
  OrganizationUserCreateAction,
  OrganizationUserDeleteAction,
  OrganizationUserUpdateAction,
  SendUserActivationEmailAction,
} from './actions'
import { enqueueNotification, hideAutoSave, showAutoSave } from '../notifications/actions'

function* doCreateUser({ user, organizationId, onComplete }: OrganizationUserCreateAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ORGANIZATION_USER(organizationId)}`,
      method: 'post',
      token,
      data: {
        ...user,
      },
    })

    defaultOnCompleteCall(onComplete, res)
    yield put({ type: ORGANIZATION_USER_CREATE_SUCCESS })
  } catch (error: any) {
    yield put(enqueueNotification(error, 'error'))
    yield put({ type: ORGANIZATION_USER_CREATE_ERROR, error })
  }
}

function* doSaveUser({ user, userId, onComplete, organizationId }: OrganizationUserUpdateAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ORGANIZATION_USER(organizationId, userId)}`,
      method: 'put',
      token,
      data: {
        ...user,
      },
    })

    defaultOnCompleteCall(onComplete, res)
    yield put({ type: ORGANIZATION_USER_SAVE_SUCCESS, user: res.data })
  } catch (error: any) {
    yield put(enqueueNotification(error, 'error'))
    yield put({ type: ORGANIZATION_USER_SAVE_ERROR, error })
  }
}

function* doDeleteUser({ organizationId, userId, onComplete }: OrganizationUserDeleteAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ORGANIZATION_USER(organizationId, userId)}`,
      method: 'delete',
      token,
    })

    defaultOnCompleteCall(onComplete, res)
    yield put({ type: ORGANIZATION_USER_DELETE_SUCCESS })
  } catch (error: any) {
    yield put(enqueueNotification(error, 'error'))
    yield put({ type: ORGANIZATION_USER_DELETE_ERROR, error })
  }
}

function* doSendActivationEmail({ organizationId, userId }: SendUserActivationEmailAction) {
  try {
    yield put(showAutoSave())

    const token = yield getToken()
    yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.ORGANIZATION_USER(organizationId, userId)}/activation-mail`,
      method: 'post',
      token,
    })

    yield put(hideAutoSave())
  } catch (error: any) {
    yield put(hideAutoSave())
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchOrganizationUser() {
  yield all([
    takeEvery(ORGANIZATION_USER_CREATE, doCreateUser),
    takeEvery(ORGANIZATION_USER_SAVE, doSaveUser),
    takeEvery(ORGANIZATION_USER_DELETE, doDeleteUser),
    takeEvery(SEND_USER_ACTIVATION_EMAIL, doSendActivationEmail),
  ])
}
