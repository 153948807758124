import { TMWideNav, TMWideNav2x, TMWideNavDark, TMWideNavDark2x } from '@app/assets/images'

const getTheme = (): 'light' | 'dark' => {
  return window.localStorage.theme || 'light'
}

export const getSiteLogo = (): string => {
  return getTheme() === 'light' ? TMWideNav : TMWideNavDark
}

export const getSiteLogo2x = (): string => {
  return getTheme() === 'light' ? TMWideNav2x : TMWideNavDark2x
}
