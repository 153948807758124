import {
  DivisionCreateAction,
  DivisionCreateFailureAction,
  DivisionCreateSuccessAction,
  DivisionDeleteAction,
  DivisionDeleteFailureAction,
  DivisionDeleteSuccessAction,
  DivisionFetchAction,
  DivisionFetchFailureAction,
  DivisionFetchSuccessAction,
  DivisionsFetchAction,
  DivisionsFetchFailureAction,
  DivisionsFetchSuccessAction,
  DivisionsSortAction,
  DivisionUpdateAction,
  DivisionUpdateFailureAction,
  DivisionUpdateSuccessAction,
  SetSelectedDivisionAction,
} from './actions'
import {
  DIVISIONS_FETCH,
  DIVISIONS_FETCH_FAILURE,
  DIVISIONS_FETCH_SUCCESS,
  DIVISIONS_SORT,
  DIVISION_CREATE,
  DIVISION_CREATE_FAILURE,
  DIVISION_CREATE_SUCCESS,
  DIVISION_DELETE,
  DIVISION_DELETE_FAILURE,
  DIVISION_DELETE_SUCCESS,
  DIVISION_FETCH,
  DIVISION_FETCH_FAILURE,
  DIVISION_FETCH_SUCCESS,
  DIVISION_UPDATE,
  DIVISION_UPDATE_FAILURE,
  DIVISION_UPDATE_SUCCESS,
  SET_SELECTED_DIVISION,
} from './constants'

const initialState: DivisionsState = {
  divisions: [],
  loading: false,
  sortBy: 'name',
  sortDirection: 'desc',
  selectedDivisionId: 0,
}

type DivisionActions =
  | DivisionFetchAction
  | DivisionFetchSuccessAction
  | DivisionFetchFailureAction
  | DivisionsFetchAction
  | DivisionsFetchSuccessAction
  | DivisionsFetchFailureAction
  | DivisionDeleteAction
  | DivisionDeleteFailureAction
  | DivisionDeleteSuccessAction
  | DivisionCreateAction
  | DivisionCreateSuccessAction
  | DivisionCreateFailureAction
  | DivisionUpdateAction
  | DivisionUpdateSuccessAction
  | DivisionUpdateFailureAction
  | DivisionsSortAction
  | SetSelectedDivisionAction

const sortDivisions = (divisions: DivisionState[], sortBy: string, sortDirection: 'asc' | 'desc' = 'desc') => {
  const sortedDivisions = divisions.sort((a, b) => String(a[sortBy]).localeCompare(String(b[sortBy])))
  if (sortDirection === 'asc') {
    sortedDivisions.reverse()
  }
  return sortedDivisions
}

const divisionsReducer = (state: DivisionsState = initialState, action: DivisionActions) => {
  switch (action.type) {
    case DIVISION_FETCH:
    case DIVISIONS_FETCH:
    case DIVISION_DELETE:
    case DIVISION_CREATE:
    case DIVISION_UPDATE:
      return {
        ...state,
        loading: true,
      }
    case DIVISIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        divisions: sortDivisions(action.divisions, state.sortBy, state.sortDirection),
      }
    case DIVISION_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case DIVISION_DELETE_SUCCESS:
    case DIVISION_CREATE_SUCCESS:
    case DIVISION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case DIVISIONS_FETCH_FAILURE:
    case DIVISION_FETCH_FAILURE:
    case DIVISION_DELETE_FAILURE:
    case DIVISION_CREATE_FAILURE:
    case DIVISION_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error.error,
        loading: false,
      }
    case DIVISIONS_SORT:
      return {
        ...state,
        divisions: sortDivisions(state.divisions, action.sortBy, action.sortDirection),
        sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      }
    case SET_SELECTED_DIVISION:
      return {
        ...state,
        selectedDivisionId: action.divisionId,
      }
    default:
      return state
  }
}

export { divisionsReducer }
