import { Theme } from '@mui/material'
import { useTheme } from '@mui/system'
import ContentWrap from '../layout/ContentWrap'
import ProgressBar from './ProgressBar'

const TourFlowProgress = () => {
  const theme: Theme = useTheme()
  return (
    <>
      <ContentWrap
        style={{
          padding: '5px 0px 10px 0',
          margin: '0 10px',
          position: 'sticky',
          top: 0,
          zIndex: 2,
          background: theme.customPalette.bodyBackground,
        }}
      >
        <ProgressBar />
      </ContentWrap>
    </>
  )
}

export default TourFlowProgress
