import React, { ChangeEvent } from 'react'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import withStyles from '@mui/styles/withStyles'
import authStyles, { AuthStyleClasses } from './styles'
import { LoginBg } from '../../assets/images'
import { Card } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import TextField from '@mui/material/TextField'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { API_ROOT, APIRoute } from '../../config'
import get from 'lodash/get'
import { getSiteLogo, getSiteLogo2x } from '@app/utils/logoUtils'
import LanguageSwitcher from '@app/components/layout/LanguageSwitcher'

interface Props extends WithRouterProps {
  classes: AuthStyleClasses
}

interface State {
  loading: boolean
  success: boolean
  error: boolean
  password: string
  passwordConfirm: string
  tokenValidityCheckFailed: boolean
  response: any
  passwordRetypedInvalid: boolean
}

const initialState: State = {
  loading: false,
  success: false,
  error: false,
  password: '',
  passwordConfirm: '',
  tokenValidityCheckFailed: false,
  response: undefined,
  passwordRetypedInvalid: false,
}

const errorState = {
  loading: false,
  error: true,
  success: false,
}

class ResetPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  componentDidMount(): void {
    this._handleTokeValidityCheck()
  }

  render() {
    const { classes } = this.props
    const { success, error, loading, password, passwordConfirm, tokenValidityCheckFailed, passwordRetypedInvalid } =
      this.state

    return (
      <div className={classes.wrapper} style={{ backgroundImage: `url(${LoginBg})` }}>
        <div className={classes.languageSwitcherContainer}>
          <LanguageSwitcher />
        </div>
        <Card style={{ padding: '1em' }}>
          <CardContent>
            <div style={{ textAlign: 'center' }}>
              <img
                style={{
                  width: 265,
                  margin: `0 0 2rem`,
                }}
                alt={'GGB'}
                src={getSiteLogo()}
                srcSet={`${getSiteLogo2x()} 2x`}
              />
            </div>
            <Typography component="h2" style={{ margin: 0 }}>
              <FormattedMessageWrapper id={'login.resetPassword.helpText'} />
            </Typography>
            <TextField
              style={{ marginTop: '1em' }}
              name={'password'}
              disabled={loading || success || tokenValidityCheckFailed}
              fullWidth={true}
              label={<FormattedMessageWrapper id={'login.resetPassword.passwordFieldLabel'} />}
              type={'password'}
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {
                  currentTarget: { value },
                } = e
                this.setState({ password: value })
              }}
            />
            <TextField
              style={{ marginTop: '1em' }}
              name={'passwordConfirm'}
              disabled={loading || success || tokenValidityCheckFailed}
              fullWidth={true}
              label={<FormattedMessageWrapper id={'login.resetPassword.passwordFieldLabelConfirm'} />}
              type={'password'}
              value={passwordConfirm}
              error={passwordRetypedInvalid}
              helperText={
                passwordRetypedInvalid ? <FormattedMessageWrapper id={'login.resetPassword.inputMismatch'} /> : ''
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {
                  currentTarget: { value },
                } = e
                this.setState({ passwordConfirm: value })
              }}
            />
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              disabled={this._isSubmitDisabled()}
              variant="contained"
              color="primary"
              onClick={this._handleActivation}
            >
              <FormattedMessageWrapper id={'login.resetPassword.submitButtonLabel'} />
            </Button>
          </CardActions>

          <Collapse in={loading} timeout={'auto'} unmountOnExit={true}>
            <div style={{ margin: '2em 0', textAlign: 'center' }}>
              <CircularProgress size={28} thickness={2} />
            </div>
          </Collapse>

          <Collapse in={success} timeout={'auto'} unmountOnExit={true}>
            <CardContent>
              <Typography component={'p'}>
                <FormattedMessageWrapper id={'login.resetPassword.success'} />
              </Typography>
              <div className={classes.actionsFooter} style={{ paddingTop: '2em' }}>
                <Button onClick={this._handleToLogin}>
                  <FormattedMessageWrapper id={'login.resetPassword.successCTA'} />
                  <NavigateNext />
                </Button>
              </div>
            </CardContent>
          </Collapse>

          <Collapse in={error && !tokenValidityCheckFailed} timeout={'auto'} unmountOnExit={true}>
            <CardContent>
              <Typography variant={'body1'} color={'error'}>
                <FormattedMessageWrapper id={'login.resetPassword.error'} />
              </Typography>
            </CardContent>
          </Collapse>

          <Collapse in={tokenValidityCheckFailed && error} timeout={'auto'} unmountOnExit={true}>
            <CardContent>
              <Typography variant={'body1'} color={'error'}>
                <FormattedMessageWrapper id={'login.resetPassword.invalidToken'} />
              </Typography>
              <div className={classes.actionsFooter} style={{ paddingTop: '2em' }}>
                <Button onClick={this._handleToReset}>
                  <NavigateBefore />
                  <FormattedMessageWrapper id={'login.resetPassword.invalidTokenCTA'} />
                </Button>
              </div>
            </CardContent>
          </Collapse>
        </Card>
      </div>
    )
  }

  private _isSubmitDisabled = () => {
    const { success, loading, password, passwordConfirm, tokenValidityCheckFailed } = this.state

    return loading || success || password.length < 4 || tokenValidityCheckFailed || passwordConfirm.length < 4
  }

  private _handleToLogin = () => {
    const { navigate } = this.props
    navigate('/login')
  }

  private _handleToReset = () => {
    const { navigate } = this.props
    navigate('/reset-password')
  }

  private _handleActivation = () => {
    const { password, passwordConfirm } = this.state
    const resetToken = get(this.props, 'params.resetToken', '')

    if (password !== passwordConfirm) {
      this.setState({
        passwordRetypedInvalid: true,
      })

      return
    } else {
      this.setState({
        passwordRetypedInvalid: false,
      })
    }

    const data = new FormData()
    data.append('token', resetToken)
    data.append('password', password)
    data.append('passwordConfirm', passwordConfirm)

    this.setState({ loading: true, error: false }, () => {
      const options: RequestInit = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
        },
        body: data,
      }

      fetch(`${API_ROOT}${APIRoute.POST_ACTIVATE_ACCOUNT()}`, options)
        .then((response) => response.json())
        .then((json) => {
          if (json.error) {
            this.setState({
              ...errorState,
              response: json,
            })
          } else {
            this.setState({
              loading: false,
              success: true,
              error: false,
              response: json,
            })
          }
        })
        .catch((error) => {
          this.setState({
            ...errorState,
            response: error,
          })
        })
    })
  }

  private _handleTokeValidityCheck = () => {
    const resetToken = get(this.props, 'params.resetToken', '')

    const data = new FormData()
    data.append('perishableToken', resetToken)

    this.setState({ ...initialState, loading: true }, () => {
      const options: RequestInit = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          Accept: 'application/json',
        },
        body: data,
      }

      fetch(`${API_ROOT}${APIRoute.POST_VALIDATE_TOKEN()}`, options)
        .then((response) => response.json())
        .then((json) => {
          if (json.error) {
            this.setState({
              ...errorState,
              tokenValidityCheckFailed: true,
              response: json,
            })
          } else {
            this.setState({
              loading: false,
              success: false,
              error: false,
              tokenValidityCheckFailed: false,
              response: json,
            })
          }
        })
        .catch((error) => {
          this.setState({
            ...errorState,
            response: error,
          })
        })
    })
  }
}

export default withStyles(authStyles)(withRouter(ResetPassword))
