import { Autocomplete, CircularProgress, FormLabel, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { useGetTournamentListQuery } from '@app/store/api/endpoints/tournamentApi'
import { useSelector } from 'react-redux'
import { RootState } from '@app/store'

type Props = {
  disabled?: boolean
  value: number[]
  margin?: 'normal' | 'none' | 'dense' | undefined
  onChange?(item: TournamentCondensed[], value: number[]): void
  error?: boolean
}

export const TournamentPicker = (props: Props) => {
  const organizationId = useSelector((store: RootState) => store.organizationReducer.id)

  // Get tournaments less than a year old
  const yearAgo = new Date()
  yearAgo.setFullYear(yearAgo.getFullYear() - 1)
  const startDate = yearAgo.toISOString().slice(0, 10) // 2023-06-18
  const limit = 10000 // Arbitrary large number

  const { data, error, isLoading } = useGetTournamentListQuery({ organizationId, startDate, limit })

  const tournaments = data?.tournaments ?? []

  // Check if user's selected tournaments are missing from the list
  const missingTournamentIds = props.value.filter((id) => !tournaments.find((t) => t.id === id))

  // Get the missing tournaments, if any
  const {
    data: selectionQueryData,
    error: selectionQueryError,
    isLoading: selectionQueryIsLoading,
  } = useGetTournamentListQuery(
    { organizationId, ids: missingTournamentIds.join(',') },
    { skip: !missingTournamentIds.length || isLoading },
  )

  if (isLoading || selectionQueryIsLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    )
  }

  if (error || selectionQueryError) {
    console.error('Error fetching tournaments', error, selectionQueryError)
    return (
      <div>
        <FormattedMessageWrapper id={'notifications.failure'} />
      </div>
    )
  }

  const autoCompleteOptions = selectionQueryData?.tournaments
    ? [...tournaments, ...selectionQueryData.tournaments]
    : tournaments

  const selection = props.value.map((tournamentId) =>
    autoCompleteOptions.find((tournament) => tournament.id === tournamentId),
  )

  const handleChange = (_event, newValue): void => {
    if (props.onChange) {
      if (newValue instanceof Array) {
        props.onChange(
          newValue,
          newValue.map((t) => t.id),
        )
      } else {
        props.onChange(newValue, newValue.id)
      }
    }
  }

  return (
    <FormControl fullWidth={true} disabled={props.disabled} margin={props.margin} error={props.error}>
      <FormLabel htmlFor="tournaments-picker">
        <FormattedMessageWrapper id={'tournament.tournaments'} /> *
      </FormLabel>
      {autoCompleteOptions && (
        <Autocomplete
          multiple
          id="tournaments-picker"
          getOptionLabel={(option) => `${option!.name} (${option!.startTime.slice(0, 4)})`}
          options={autoCompleteOptions}
          value={selection.filter((s) => s !== undefined) as TournamentCondensed[]}
          renderInput={(params) => <TextField {...params} variant="standard" />}
          onChange={handleChange}
          renderOption={(props, option: TournamentCondensed) => (
            <li {...props} key={option.id}>
              {`${option!.name} (${option!.startTime.slice(0, 4)})`}
            </li>
          )}
        />
      )}
    </FormControl>
  )
}
