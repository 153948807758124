import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../..'
import {
  gitImportAddErrorMatcher,
  gitImportClearErrorMatcher,
  isClubsAndCoursesLoadingFalseMatcher,
  isClubsAndCoursesLoadingTrueMatcher,
  isDirtyFalseMatcher,
  isTournamentLoadingFalseMatcher,
  isTournamentLoadingTrueMatcher,
  isTournamentPlayersLoadingFalseMatcher,
  isTournamentPlayersLoadingTrueMatcher,
  isTournamentScoringLoadingFalseMatcher,
  isTournamentScoringLoadingTrueMatcher,
  isTournamentSettingsLoadingFalseMatcher,
  isTournamentSettingsLoadingTrueMatcher,
  isTournamentSiteLoadingFalseMatcher,
  isTournamentSiteLoadingTrueMatcher,
  isTournamentStartListsLoadingFalseMatcher,
  isTournamentStartListsLoadingTrueMatcher,
  needsUpdateTrueMatcher,
} from '../matchers/configMatchers'
import { ExtraInfo } from '../enums/tournamentEnums'

const initialState: ConfigState = {
  tournament: {
    id: 0,
    selectedRoundIndex: 0,
    selectedRoundId: -1,
    needsUpdate: false,
    isDirty: false,
    invalidStartTime: false,
    contestEditMode: false,
    gitDialogOpen: false,
    gitImportError: false,
    sortBy: 'firstName',
    sortDirection: 'asc',
    teamsSortBy: 'name',
    teamsSortDirection: 'asc',
    playerExtraInfo: ExtraInfo.EMAIL,
    status: {
      isTournamentLoading: false,
      isTournamentSiteLoading: false,
      isTournamentStartListsLoading: false,
      isTournamentPlayersLoading: false,
      isTournamentSettingsLoading: false,
      isTournamentScoringLoading: false,
    },
    weeklyScores: {
      playerId: -1,
      weeklyRoundId: null,
    },
  },
  clubsAndCourses: {
    selectedCountryId: -1,
    selectedClubId: -1,
    selectedCourseId: -1,
    selectedCourseVersion: -1,
    isLoading: false,
  },
}

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    resetConfigs: () => initialState,
    setTournamentId: (state, action: PayloadAction<number>) => {
      state.tournament.id = action.payload
    },
    setTournamentNeedsUpdate: (state, action: PayloadAction<boolean>) => {
      state.tournament.needsUpdate = action.payload
    },
    setTournamentDirty: (state, action: PayloadAction<boolean>) => {
      state.tournament.isDirty = action.payload
    },
    setInvalidStartTime: (state, action: PayloadAction<boolean>) => {
      state.tournament.invalidStartTime = action.payload
    },
    setSelectedRoundIndex(state, action: PayloadAction<number>) {
      state.tournament.selectedRoundIndex = action.payload
    },
    setSelectedRoundId(state, action: PayloadAction<number>) {
      state.tournament.selectedRoundId = action.payload
    },
    setContestEditMode(state, action: PayloadAction<boolean>) {
      state.tournament.contestEditMode = action.payload
    },
    setGitDialogOpen(state, action: PayloadAction<boolean>) {
      state.tournament.gitDialogOpen = action.payload
    },
    setPlayersSortParams(state, action: PayloadAction<TournamentPlayersSortParams>) {
      state.tournament.sortBy = action.payload.sortBy
      state.tournament.sortDirection = action.payload.sortDirection
    },
    setTeamsSortParams(state, action: PayloadAction<TournamentTeamsSortParams>) {
      state.tournament.teamsSortBy = action.payload.teamsSortBy
      state.tournament.teamsSortDirection = action.payload.teamsSortDirection
    },
    setPlayerExtraInfo(state, action: PayloadAction<ExtraInfo>) {
      state.tournament.playerExtraInfo = action.payload
    },
    setSelectedCountryId(state, action: PayloadAction<number>) {
      state.clubsAndCourses.selectedCountryId = action.payload
    },
    internalSetSelectedClubId(state, action: PayloadAction<number>) {
      state.clubsAndCourses.selectedClubId = action.payload
    },
    internalSetSelectedCourseId(state, action: PayloadAction<number>) {
      state.clubsAndCourses.selectedCourseId = action.payload
    },
    internalSetSelectedCourseVersion(state, action: PayloadAction<number>) {
      state.clubsAndCourses.selectedCourseVersion = action.payload
    },
    setWeeklyScoreParams(state, action: PayloadAction<Partial<TournamentScoringState>>) {
      state.tournament.weeklyScores = { ...state.tournament.weeklyScores, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(needsUpdateTrueMatcher, (state) => {
        state.tournament.needsUpdate = true
      })
      .addMatcher(isDirtyFalseMatcher, (state) => {
        state.tournament.isDirty = false
      })
      .addMatcher(isTournamentLoadingTrueMatcher, (state) => {
        state.tournament.status.isTournamentLoading = true
      })
      .addMatcher(isTournamentLoadingFalseMatcher, (state) => {
        state.tournament.status.isTournamentLoading = false
      })
      .addMatcher(isTournamentSiteLoadingTrueMatcher, (state) => {
        state.tournament.status.isTournamentSiteLoading = true
      })
      .addMatcher(isTournamentSiteLoadingFalseMatcher, (state) => {
        state.tournament.status.isTournamentSiteLoading = false
      })
      .addMatcher(isTournamentStartListsLoadingTrueMatcher, (state) => {
        state.tournament.status.isTournamentStartListsLoading = true
      })
      .addMatcher(isTournamentStartListsLoadingFalseMatcher, (state) => {
        state.tournament.status.isTournamentStartListsLoading = false
      })
      .addMatcher(isTournamentPlayersLoadingTrueMatcher, (state) => {
        state.tournament.status.isTournamentPlayersLoading = true
      })
      .addMatcher(isTournamentPlayersLoadingFalseMatcher, (state) => {
        state.tournament.status.isTournamentPlayersLoading = false
      })
      .addMatcher(isTournamentSettingsLoadingTrueMatcher, (state) => {
        state.tournament.status.isTournamentSettingsLoading = true
      })
      .addMatcher(isTournamentSettingsLoadingFalseMatcher, (state) => {
        state.tournament.status.isTournamentSettingsLoading = false
      })
      .addMatcher(isTournamentScoringLoadingTrueMatcher, (state) => {
        state.tournament.status.isTournamentScoringLoading = true
      })
      .addMatcher(isTournamentScoringLoadingFalseMatcher, (state) => {
        state.tournament.status.isTournamentScoringLoading = false
      })
      .addMatcher(isClubsAndCoursesLoadingTrueMatcher, (state) => {
        state.clubsAndCourses.isLoading = true
      })
      .addMatcher(isClubsAndCoursesLoadingFalseMatcher, (state) => {
        state.clubsAndCourses.isLoading = false
      })
      .addMatcher(gitImportAddErrorMatcher, (state) => {
        state.tournament.gitImportError = true
      })
      .addMatcher(gitImportClearErrorMatcher, (state) => {
        state.tournament.gitImportError = false
      })
  },
})

export const {
  resetConfigs,
  setTournamentId,
  setTournamentNeedsUpdate,
  setTournamentDirty,
  setInvalidStartTime,
  setSelectedRoundIndex,
  setSelectedRoundId,
  setContestEditMode,
  setGitDialogOpen,
  setPlayersSortParams,
  setTeamsSortParams,
  setPlayerExtraInfo,
  setSelectedCountryId,
  internalSetSelectedClubId,
  internalSetSelectedCourseId,
  internalSetSelectedCourseVersion,
  setWeeklyScoreParams,
} = slice.actions

export const selectTournamentConfig = (state: RootState): TournamentConfig => state.configReducer.tournament
export const selectClubsAndCoursesConfig = (state: RootState): ClubsAndCoursesConfig =>
  state.configReducer.clubsAndCourses
export const getTournamentId = (state: RootState): number => state.configReducer.tournament.id

export default slice.reducer
