import mixpanelLib from 'mixpanel-browser'

const env = process.env.REACT_APP_ENV || 'development'

export enum MixpanelEvents {
  TOURNAMENT_SETUP = 'Tournament setup',
  STARTLIST_PUBLISHED = 'TM game created',
}

export const mixpanelActions: MixpanelActions = {
  initAndIdentify: (projectToken: string, userId: string) => {
    mixpanelLib.init(projectToken, { ignore_dnt: true })
    mixpanelLib.identify(`${userId}-${env}`)
  },
  addProfileData: (data: Record<string, string>) => {
    mixpanelLib.people.set({ ...data, Environment: env })
  },
  trackPageView: (page?: string, tournamentId?: string) => {
    mixpanelLib.track_pageview({ Environment: env, Page: page, TournamentId: tournamentId })
  },
  trackEvent: (eventName: string, data?: Record<string, string | number | undefined>) => {
    mixpanelLib.track(eventName, { ...data, Environment: env })
  },
}
