import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import { Save } from '@mui/icons-material'
import PopupState, { bindPopover } from 'material-ui-popup-state'
import { rem } from '@app/theme/materialUITheme'
import { formatTeamHcp } from '@app/utils/teamFormatUtils'

const styles = () =>
  createStyles({
    teamHeader: {
      marginRight: rem(10),
      fontSize: rem(14),
      fontWeight: 'bold',
      flex: 0.5,
    },
    popupContainer: {
      padding: '10px',
    },
  })

interface OwnProps {
  id?: number | string | null
  hcp?: string | number | null
  gameFormats?: (number | undefined)[]
  onChange?(value: string): void
}

type Props = OwnProps & WithStyles<typeof styles>

function TeamHCP({ hcp, gameFormats, classes, onChange }: Props) {
  if (hcp === null) {
    return null
  }

  const allowedGameFormats = [11, 17, 16, 18, 19, 8, 12]
  if (!gameFormats || !gameFormats.find((gameFormat) => gameFormat && allowedGameFormats.includes(gameFormat))) {
    return null
  }

  const formattedHcp = formatTeamHcp(hcp as any)

  return (
    <PopupState variant="popover" popupId="team-hcp-popup">
      {(popupState) => (
        <>
          <Typography className={classes.teamHeader}>
            <span>
              <FormattedMessageWrapper id={'tournament.teamHCP'} values={{ hcp: formattedHcp }} />
            </span>
          </Typography>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.popupContainer}>
              <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault()
                  popupState.close()

                  if (onChange) {
                    const {
                      currentTarget: { elements },
                    } = e
                    const teamNameInput = elements.namedItem('teamHCP')
                    const { value } = teamNameInput as HTMLInputElement
                    if (value) {
                      onChange(value)
                    }
                  }
                }}
                style={{
                  display: 'flex',
                  alignContent: 'flex-end',
                }}
              >
                <TextField
                  id={'teamHCP'}
                  label={<FormattedMessageWrapper id="options.teamHcp" />}
                  defaultValue={hcp}
                  autoFocus={true}
                  autoComplete={'off'}
                  disabled
                />
                <IconButton type={'submit'} color={'primary'} style={{ marginLeft: 8 }} size="large">
                  <Save fontSize={'small'} />
                </IconButton>
              </form>
            </div>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export default withStyles(styles)(TeamHCP)
