import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { updateTournamentSiteField } from '@store/api/thunks/tournamentSiteThunks'
import SectionTitleWithCollapse from '../ui/SectionTitleWithCollapse'
import SectionTitle from '../ui/SectionTitle'
import { Collapse, Typography, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import ContentBlock from './ContentBlock'
import Schedule from './Schedule'
import classNames from 'classnames'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    roundPicker: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 27,
      height: 30,
      border: `1px solid ${theme.customPalette.darkGray}`,
      borderRadius: 4,
      padding: '4px 8px',
      fontFamily: ['Exo', 'sans-serif'].join(','),
      margin: '0 5px',
      cursor: 'pointer',
      '&:hover, &.active': {
        border: `1px solid ${theme.customPalette.lightBlue}`,
        background: theme.customPalette.lightBlue,
        color: theme.palette.primary.contrastText,
      },
    },
    hidden: {
      // When the collapse hides its content, the padding of the item
      // is still taking up space, creating an unwanted space.
      // The 'hidden' style rule is only applied when the collapse finishes
      // hiding its content
      display: 'none !important',
    },
  })

interface OwnProps {
  tournamentSite: TournamentSite
  tournament: TournamentState
}

interface DispatchProps {
  updateTournamentSiteField: (payload: FieldUpdatePayload) => void
}

type Props = OwnProps & DispatchProps & WrappedComponentProps & WithStyles<typeof styles>

interface State {
  expanded: boolean
  round: number
}

class UnconnectedSiteHome extends React.Component<Props, State> {
  readonly state: State = {
    expanded: false,
    round: 0,
  }

  render() {
    const numberOfRounds = this.props.tournament.rounds.length
    const { classes } = this.props

    return (
      <>
        <SectionTitleWithCollapse
          expanded={this.state.expanded}
          onChange={(expanded) => this.setState({ expanded: expanded })}
        >
          <SectionTitle>
            <FormattedMessageWrapper id="tournament.home" />
          </SectionTitle>
        </SectionTitleWithCollapse>

        <Collapse
          in={this.state.expanded}
          style={{ borderBottomWidth: '1px' }}
          classes={this.state.expanded ? undefined : { wrapper: classes.hidden }}
        >
          {numberOfRounds > 1 && (
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
              <Typography variant="h2" color="secondary" style={{ flexShrink: 0, margin: '0 10px 10px 0' }}>
                <FormattedMessageWrapper id={'tournament.selectRound'} />
              </Typography>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {Array.from({ length: numberOfRounds }).map((_, index) => (
                  <div
                    key={index + 1}
                    className={classNames(this.props.classes.roundPicker, index === this.state.round && 'active')}
                    onClick={() => this.setState({ round: index })}
                  >
                    {index + 1}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Schedule */}
          <Schedule
            tournament={this.props.tournament}
            tournamentSchedule={this.props.tournamentSite.tournamentSchedule}
            round={this.state.round}
            updateAction={this.props.updateTournamentSiteField}
          />

          {/* Tournament info */}
          <Typography variant="h4" style={{ marginBottom: 20 }}>
            <FormattedMessageWrapper id="tournament.tournamentInformationHeader" />
          </Typography>

          {this.state.expanded && (
            <ContentBlock
              fieldName="tournamentInformationText"
              updateAction={this.props.updateTournamentSiteField}
              tournamentSite={this.props.tournamentSite}
              style={{ marginBottom: 30 }}
              numberOfRounds={numberOfRounds}
              round={this.state.round}
            />
          )}
        </Collapse>
      </>
    )
  }
}

const SiteHome = withStyles(styles)(injectIntl(UnconnectedSiteHome))

export default connect<{}, DispatchProps, OwnProps, RootState>(null, {
  updateTournamentSiteField,
})(SiteHome as any)
