import { processPlayer } from './tournamentPlayersUtils'

export const processTeamsResponse = (response: TournamentTeamsResponse): TournamentTeamsResponse => {
  const unprocessedTeams: TournamentTeam[] = response.teams
  const processedTeams = unprocessedTeams.map((team) => {
    return {
      ...team,
      players: team.players.map((player) => processPlayer(player as unknown as UnprocessedTournamentPlayer)),
    }
  })
  return {
    teams: processedTeams,
  }
}
