export const SettingsIcon = ({
  style = {},
  fill = '#1AA85D',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 55 55',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    className={className}
    style={style}
  >
    <path
      d="M52.35,23.48v0L46,21.63a18.72,18.72,0,0,0-1.47-3.51l3.24-5.84h0a.86.86,
    0,0,0-.14-1L43.78,7.43a.86.86,0,0,0-1.05-.14h0l-5.83,3.24A18,18,0,0,0,33.36,
    9L31.54,2.65h0A.87.87,0,0,0,30.68,2H25.32a.87.87,0,0,0-.84.65h0L22.64,9a18.58,18.58,0,0,0-3.35,
    1.38L13.36,7.2h0a.9.9,0,0,0-1.07.16L8.37,11.28a.9.9,0,0,0-.16,1.07h0l3.22,5.93a19.93,19.93,0,0,
    0-1.32,3.15L3.65,23.35h0a.92.92,0,0,0-.65.87v5.54a.91.91,0,0,0,.65.87h0l6.45,1.91a19.93,19.93,
    0,0,0,1.32,3.15L8.2,41.64h0a.9.9,0,0,0,.16,1.07l3.91,3.91a.9.9,0,0,0,1.07.16h0l5.92-3.22a18.46,
    18.46,0,0,0,3.17,1.33l1.9,6.44h0a.91.91,0,0,0,.87.65h5.54a.91.91,0,0,0,.87-.65h0l1.9-6.44a18.72,
    18.72,0,0,0,3.35-1.42l5.82,3.23h0a.86.86,0,0,0,1.05-.14l3.79-3.79a.86.86,0,0,0,
    .14-1.05h0l-3.24-5.83A19.1,19.1,0,0,0,46,32.37l6.4-1.83v0a.87.87,0,0,0,.65-.84V24.32a.87.87,0,
    0,0-.65-.84M28,34.61A7.61,7.61,0,1,1,35.61,27,7.61,7.61,0,0,1,28,34.61"
    />
  </svg>
)
