import { all, takeEvery, put } from 'redux-saga/effects'
import { USERS_FETCH } from './constants'
import { FetchUsersAction } from './actions'
import { getToken } from '../authentication/sagas'
import { apiClientRequest, defaultOnCompleteCall } from '../../utils/sagaHelpers'
import { API_ROOT, APIRoute, ROWS_PER_PAGE } from '../../config'
import { putUsersSuccess, putUsersError } from './actions'
import { enqueueNotification } from '../notifications/actions'

function* doFetchUsers({ page = 1, onComplete, organizationId }: FetchUsersAction) {
  try {
    const token = yield getToken()
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_ORGANIZATION_USERS(organizationId)}`,
      method: 'get',
      token,
      params: {
        offset: ROWS_PER_PAGE * (page - 1),
        limit: ROWS_PER_PAGE,
      },
    })
    const { data } = res
    defaultOnCompleteCall(onComplete, data)
    yield put(putUsersSuccess(data.organizations))
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, undefined, error)
    yield put(putUsersError(error))
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchOrganizationUsers() {
  yield all([takeEvery(USERS_FETCH, doFetchUsers)])
}
