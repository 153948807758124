export const EditIcon = ({ className = '', fill = '#1AA85D' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className={className}>
    <path
      fill={fill}
      // eslint-disable-next-line max-len
      d="M16.519 16.501c.175-.136.334-.295.651-.612l3.957-3.958c.096-.095.052-.26-.075-.305a4.332 4.332 0 01-1.644-1.034 4.332 4.332 0 01-1.034-1.644c-.045-.127-.21-.171-.305-.075L14.11 12.83c-.317.317-.476.476-.612.651-.161.207-.3.43-.412.666-.095.2-.166.414-.308.84l-.184.55-.292.875-.273.82a.584.584 0 00.738.738l.82-.273.875-.292.55-.184c.426-.142.64-.212.84-.308.236-.113.46-.25.666-.412zM22.366 10.692a2.163 2.163 0 10-3.058-3.059l-.127.128a.524.524 0 00-.148.465c.02.107.055.265.12.452.13.375.376.867.839 1.33.462.463.955.709 1.33.839.188.065.345.1.452.12.171.03.342-.025.465-.148l.127-.127z"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      // eslint-disable-next-line max-len
      d="M4.172 3.172C3 4.343 3 6.229 3 10v4c0 3.771 0 5.657 1.172 6.828C5.343 22 7.229 22 11 22h2c3.771 0 5.657 0 6.828-1.172C20.981 19.676 21 17.832 21 14.18l-2.818 2.818c-.27.27-.491.491-.74.686a5.107 5.107 0 01-.944.583 8.163 8.163 0 01-.944.355l-2.312.771a2.083 2.083 0 01-2.635-2.635l.274-.82.475-1.426.021-.066c.121-.362.22-.658.356-.944.16-.335.355-.651.583-.943.195-.25.416-.47.686-.74l4.006-4.007L18.12 6.7l.127-.127A3.651 3.651 0 0120.838 5.5c-.151-1.03-.444-1.763-1.01-2.328C18.657 2 16.771 2 13 2h-2C7.229 2 5.343 2 4.172 3.172zM7.25 9A.75.75 0 018 8.25h6.5a.75.75 0 010 1.5H8A.75.75 0 017.25 9zm0 4a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75zm0 4a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5H8a.75.75 0 01-.75-.75z"
      clipRule="evenodd"
    ></path>
  </svg>
)
