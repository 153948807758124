import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Theme,
  Typography,
  Chip,
  Switch,
} from '@mui/material'
import { WithStyles, withStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { find, debounce } from 'lodash'
import DialogContent from '@mui/material/DialogContent'
import { Cancel } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { HelpFormLabel } from '@components/forms/HelpFormLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import ButtonLoaderWrap from '@components/ui/ButtonLoaderWrap'
import SelectBox from '@components/ui/SelectBox'
import { connect } from 'react-redux'
import { formatHandicap, trimHcpDecimals, validateHandicapValue } from '@utils/playerUtils'
import CustomQuestionAnswer from '@components/tournament/CustomQuestionAnswer'
import { disablePlayerListEdit } from '@app/store/api/selectors/tournamentSelectors'
import { rem } from '@app/theme/materialUITheme'
import DeleteConfirm, { DeleteConfirmChildren } from '@app/components/dialogs/deleteConfirm/DeleteConfirm'
import { PlayerTeamPoolEditAction, PlayerPoolEditContext, PlayerHcpSource } from '../enums'
import classNames from 'classnames'
import { TournamentTypes, DivisionType } from '@app/store/api/enums/tournamentEnums'
import { validFemaleTeeBoxes, validMaleTeeBoxes } from '@app/utils/clubAndCourseUtils'
import { searchClubs, getCourses } from '@store/api/thunks/clubsAndCoursesThunks'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import { AddPlayersDialog } from '@scenes/tournament/players/add-players-dialog'
import { DatePicker } from '@app/components/date-pickers'
import { Autocomplete, AutocompleteItem } from '@app/components/forms/Autocomplete'
import InfoTooltip from '@app/components/ui/InfoTooltip'
import { RootState } from '@app/store'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentTeams } from '@app/store/api/endpoints/tournamentTeamsApi'
import { selectTournamentPlayers } from '@app/store/api/endpoints/tournamentPlayersApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'
import { selectRoundCourseOptions } from '@app/store/api/slices/clubsAndCoursesSlice'
import { getRoundStartTime } from '@app/store/api/utils/tournamentRoundUtils'
import { cloneDeep } from 'lodash'

interface OwnProps {
  open: boolean
  player?: TournamentPlayer
  roundIndex: number
  tournament: TournamentState
  divisions: DivisionState[]
  editContext: PlayerPoolEditContext
  onClose: (action?: string, player?: TournamentPlayerUpdatePayload) => void
}

interface StateProps {
  customQuestionsEnabled: boolean
  customQuestions: Question[]
  tournamentStartLists: TournamentStartListState
  disablePlayerListEdit: boolean
  tournament: TournamentState
  tournamentSite: TournamentSite
  roundCourses: RoundCourse[]
  organization: OrganizationState
  units: OrganizationUnits
  auth: AuthenticationState
  teams: TournamentTeam[]
  reservePlayers: TournamentPlayer[]
  tournamentConfig: TournamentConfig
}

interface DispatchProps {
  getCourses(payload: CoursesPayload): void
  searchClubs: (payload: SearchClubsPayload, isPublicSearch?: boolean) => void
}

interface State {
  countryName: string
  courseTees: CourseTee[]
  customQuestionAnswers: { [questionId: number]: QuestionAnswer }
  firstName: string
  gender: string
  hcp: string
  userId: number
  isLoading: boolean
  lastName: string
  roundIndex: number
  teeboxId: number
  temporary: boolean
  homeClub: { value: number; label: string } | undefined
  dateOfBirth: Date | undefined
  clubSearchTerm: string
  clubs: Club[]
  loading: boolean
  error?: string
  selectedDivisionId: number | null
  teamAddPlayersDialogOpen: boolean
  isHcpUpdateAllowed: boolean
}

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      background: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.common.white,
      fontFamily: theme.fonts.primary,
      fontSize: rem(18),
      fontWeight: 600,
    },
    actionButtonsWrapper: {
      display: 'flex',
      '& .MuiButton-root:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
    playerStatusWrapper: {
      color: theme.palette.primary.main,
      padding: `${theme.spacing(2)} 0 ${theme.spacing(1)}`,
      overflow: 'hidden',
      fontSize: rem(16),
    },
    playerStatusLabel: {
      fontFamily: theme.fonts.primary,
      fontWeight: 600,
    },
    playerStatusValue: {
      display: 'block',
      marginTop: theme.spacing(1),
      textTransform: 'capitalize',
      fontWeight: 400,
      fontSize: rem(14),
      padding: `${theme.spacing(0.3)} ${theme.spacing(0.6)}`,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      '&.reserved': {
        color: theme.customPalette.warning,
        borderColor: theme.customPalette.warning,
      },
      '&.removed': {
        color: theme.customPalette.error,
        borderColor: theme.customPalette.error,
      },
    },
    reserveListButton: {
      background: theme.customPalette.warning,
      color: theme.palette.common.white,
    },
    rosterButton: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    removeButton: {
      background: theme.customPalette.error,
      color: theme.palette.common.white,
    },
    cancelIcon: {
      color: theme.palette.common.white,
    },
    removeMargin: {
      marginTop: 0,
    },
    division: {
      marginRight: 24,
      marginBottom: 15,
      '&.MuiChip-root': {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `1px 1px 2px ${theme.customPalette.lightGray3}`,
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontWeight: 'bold',
        padding: `${rem(12)}`,
      },
      '&.MuiChip-outlinedPrimary': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `1px 1px 2px ${theme.customPalette.lightGray3}`,
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        fontWeight: 'bold',
        padding: `${rem(12)}`,
      },
    },
    divisionTitle: {
      marginTop: rem(23),
      marginBottom: rem(15),
    },
    infoIcon: {
      position: 'absolute',
      marginLeft: 5,
      marginTop: -1,
      fontSize: rem(16),
    },
    dialog: {
      '& > .MuiDialog-container': {
        justifyContent: 'flex-end',
        paddingRight: '5%',

        '& > .MuiPaper-root': {
          width: '75%',
          maxWidth: 1080,
          borderRadius: 20,
        },
      },
    },
    hcpToggle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 14,
      height: '100%',
    },
    hcpToggleTitle: {
      marginLeft: 5,
      marginRight: 15,
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  })

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps

/**
 * TODO: This component should be broken down or organized for re-usability.
 * 1000 lines of code for one input field in some cases. Makes no sense.
 */
class EditPlayerDialogComponent extends React.Component<Props, State> {
  state: State = {
    countryName: '',
    courseTees: [],
    customQuestionAnswers: {},
    firstName: '',
    gender: 'male',
    hcp: '0',
    userId: -1,
    isLoading: false,
    lastName: '',
    roundIndex: -1,
    teeboxId: -1,
    temporary: false,
    homeClub: undefined,
    dateOfBirth: new Date(),
    clubSearchTerm: '',
    clubs: [],
    loading: false,
    error: undefined,
    selectedDivisionId: null,
    teamAddPlayersDialogOpen: false,
    isHcpUpdateAllowed: false,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { player, roundIndex, customQuestions, tournament, editContext, tournamentConfig } = props

    if (player && (player.userId !== state.userId || roundIndex !== state.roundIndex)) {
      const {
        userId,
        country,
        firstName,
        lastName,
        hcp,
        temporary,
        gender,
        club,
        dateOfBirth,
        startListPlayers,
        preferredTeeBox,
        divisionId,
        hcpSource,
      } = player

      const customQuestionAnswers = customQuestions.reduce((acc, customQuestion) => {
        const questionAnswer = find(player.customQuestionAnswers, { registrationQuestionId: customQuestion.id })

        if (questionAnswer) {
          acc[customQuestion.id] = questionAnswer
        } else {
          acc[customQuestion.id] = { registrationQuestionId: customQuestion.id, answer: '' }
        }

        return acc
      }, {})

      const { menTeeBoxId, womenTeeBoxId } = tournament.rounds[roundIndex]
      const roundTeeboxId = gender === 'female' ? womenTeeBoxId : menTeeBoxId

      const startListTeeboxId = startListPlayers && startListPlayers[tournamentConfig.selectedRoundId]?.teeBox?.id
      const startListHcp = startListPlayers && startListPlayers[tournamentConfig.selectedRoundId]?.hcp

      const teebox =
        editContext === PlayerPoolEditContext.GROUP
          ? startListTeeboxId || preferredTeeBox?.id || roundTeeboxId
          : preferredTeeBox?.id || roundTeeboxId

      return {
        userId,
        firstName,
        lastName,
        hcp: formatHandicap(String(startListHcp !== undefined ? startListHcp : hcp !== undefined ? hcp : -54)),
        temporary,
        // First try teebox from start list, second try from tournament player, last try from round setup
        teeboxId: teebox,
        gender,
        countryName: country,
        roundIndex,
        customQuestionAnswers,
        homeClub: { value: club?.id, label: club?.name } || undefined,
        dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
        selectedDivisionId: divisionId,
        isHcpUpdateAllowed: hcpSource !== PlayerHcpSource.MANUAL,
      }
    }

    return null
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    const { roundIndex, courseTees } = this.state
    const { tournament, getCourses, open, roundCourses } = this.props
    // For different round we need to fetch new data about available teeboxes
    if (
      open &&
      (roundIndex !== prevState.roundIndex ||
        courseTees.length === 0 ||
        prevState.courseTees[0]?.version !== tournament.rounds[roundIndex]?.course?.teeBoxes[0].version)
    ) {
      const round = tournament.rounds[roundIndex]

      if (round && round.club && round.course && tournament.id) {
        const found = roundCourses.find(
          (c) =>
            round.course &&
            round.course.id &&
            c.id === round.course.id &&
            c.teeBoxes[0]?.version === round.course.teeBoxes[0]?.version,
        )

        if (!found) {
          getCourses({
            clubId: round.clubId?.value || 0,
            timestamp: getRoundStartTime(round),
          })
        } else {
          this._setCourseTees(found)
        }
      } else if (round) {
        console.warn('no club or course info in round data', round)
      }
    }
  }

  private _onRosterButtonClick = (): void => {
    const hasTeam = !!this.props.player?.team?.id

    hasTeam
      ? this.setState({ teamAddPlayersDialogOpen: true })
      : this._handleOnClose(PlayerTeamPoolEditAction.MOVE_TO_PLAYER_POOL)
  }

  private _onReserveButtonClick = (): void => {
    this._handleOnClose(PlayerTeamPoolEditAction.MOVE_TO_RESERVED)
  }

  private _onRemoveButtonClick = (): void => {
    this._handleOnClose(PlayerTeamPoolEditAction.REMOVE)
  }

  private _onDeleteButtonClick = (): void => {
    this._handleOnClose(PlayerTeamPoolEditAction.DELETE)
  }

  private _renderPlayerStatus = (): React.ReactElement<any> => {
    const { classes } = this.props

    const statusTextIdByContext = {
      [PlayerPoolEditContext.POOL]: 'tournament.playerPool',
      [PlayerPoolEditContext.RESERVED]: 'tournament.reserveList',
      [PlayerPoolEditContext.REMOVED]: 'tournament.removedPlayersList',
      [PlayerPoolEditContext.GROUP]: 'options.group',
      [PlayerPoolEditContext.GROUP_POOL]: 'options.group',
    }

    return (
      <div className={classes.playerStatusWrapper}>
        <span className={classes.playerStatusLabel}>Status: </span>
        <span className={classNames([classes.playerStatusValue, this.props.editContext])}>
          <FormattedMessageWrapper id={statusTextIdByContext[this.props.editContext]} />
        </span>
      </div>
    )
  }

  private _showHcpInfoIcon = (): boolean => {
    const { tournament, player } = this.props
    return tournament.tournamentType === TournamentTypes.weekly && player?.temporary === true
  }

  private _showTeeInfoIcon = (): boolean => {
    const { tournament } = this.props
    return tournament.tournamentType === TournamentTypes.weekly
  }

  public render() {
    const { player, open, classes, tournament, organization, units, editContext, divisions } = this.props

    if (!player) {
      return null
    }

    const isWeekly = tournament.tournamentType === TournamentTypes.weekly

    return (
      <Dialog className={classes.dialog} open={open} aria-labelledby="tournament-player-dialog-title">
        {editContext === PlayerPoolEditContext.GROUP || editContext === PlayerPoolEditContext.GROUP_POOL ? (
          <BaseDialogTitle
            id={'tournament-player-dialog-title'}
            title={`${player.firstName} ${player.lastName}`}
            subtitle={this._playerClubName}
            onClose={() => this._handleOnClose()}
          />
        ) : (
          <DialogTitle id="tournament-player-dialog-title" className={classes.dialogTitle}>
            <FormattedMessageWrapper id="tournament.editPlayer" /> - {`${player.firstName} ${player.lastName}`}
            <IconButton style={{ marginLeft: rem(20) }} onClick={() => this._handleOnClose()} size="small">
              <Cancel className={classes.cancelIcon} />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent>
          <Grid container spacing={2}>
            {editContext !== PlayerPoolEditContext.GROUP && editContext !== PlayerPoolEditContext.GROUP_POOL && (
              <>
                <Grid item>
                  <HelpFormLabel>
                    <FormattedMessageWrapper id={'tournament.firstName'} />
                  </HelpFormLabel>
                  <OutlinedInput
                    disabled={!player.temporary}
                    value={this.state.firstName}
                    onChange={this._handleChange('firstName')}
                  />
                </Grid>
                <Grid item>
                  <HelpFormLabel>
                    <FormattedMessageWrapper id={'tournament.lastName'} />
                  </HelpFormLabel>
                  <OutlinedInput
                    disabled={!player.temporary}
                    value={this.state.lastName}
                    onChange={this._handleChange('lastName')}
                  />
                </Grid>
                <Grid item>
                  <HelpFormLabel>
                    <FormattedMessageWrapper id={'tournament.gender'} />
                  </HelpFormLabel>
                  <SelectBox
                    margin="none"
                    name="gender"
                    id="gender"
                    value={this.state.gender}
                    inputElement={OutlinedInput}
                    onChange={this._onChange}
                    disabled={!organization.isClub && !player.temporary}
                  >
                    <MenuItem value={'male'}>
                      <FormattedMessageWrapper id={'options.male'} />
                    </MenuItem>
                    <MenuItem value={'female'}>
                      <FormattedMessageWrapper id={'options.female'} />
                    </MenuItem>
                  </SelectBox>
                </Grid>
              </>
            )}
            <Grid item>
              <HelpFormLabel>
                <FormattedMessageWrapper id={'tournament.hcp'} />
                {this._showHcpInfoIcon() && (
                  <InfoTooltip
                    text={<FormattedMessageWrapper id={'tournament.changesOnlyValidForUpcomingRounds'} />}
                    className={classes.infoIcon}
                  />
                )}
              </HelpFormLabel>
              <OutlinedInput
                style={{ width: 60 }}
                value={this.state.hcp}
                onChange={this._handleChange('hcp')}
                disabled={isWeekly && !player.temporary}
                error={this.state.hcp ? validateHandicapValue(this.state.hcp).valid === false : false}
              />
            </Grid>
            {editContext !== PlayerPoolEditContext.GROUP && editContext !== PlayerPoolEditContext.GROUP_POOL && (
              <>
                <Grid item>
                  <div className={classes.hcpToggle}>
                    <div className={classes.hcpToggleTitle}>
                      <FormattedMessageWrapper id={'tournament.allowHcpUpdate'} />
                    </div>
                    <Switch
                      checked={this.state.isHcpUpdateAllowed}
                      onChange={() => this.setState({ isHcpUpdateAllowed: !this.state.isHcpUpdateAllowed })}
                      name="isHcpUpdateAllowed"
                      color="primary"
                      disabled={player.hcpSource !== PlayerHcpSource.MANUAL}
                    />
                    <InfoTooltip text={this._getHcpUpdateInfoText()} arrow />
                  </div>
                </Grid>
                <Grid item>
                  <HelpFormLabel>
                    <FormattedMessageWrapper id={'tournament.country'} />
                  </HelpFormLabel>
                  <OutlinedInput
                    style={{ width: 200 }}
                    value={this.state.countryName}
                    onChange={this._handleChange('countryName')}
                    disabled={true /* !organization.isClub && !player.temporary */}
                  />
                </Grid>
                <Grid item xs={3}>
                  <HelpFormLabel>
                    <FormattedMessageWrapper id={'options.homeClub'} />
                  </HelpFormLabel>
                  <Autocomplete
                    value={this.state.homeClub || undefined}
                    onChange={this._onClubChange}
                    options={this._clubItems}
                    inputValue={
                      organization.countryId
                        ? this.state.clubSearchTerm
                        : this.props.intl.formatMessage({ id: 'tournament.selectCountryFirst' })
                    }
                    onInputChange={this._updateClubSearchTerm}
                    disabled={!organization.isClub && !player.temporary}
                  />
                </Grid>
                <Grid item>
                  <HelpFormLabel>
                    <FormattedMessageWrapper id={'tournament.dateOfBirth'} />
                  </HelpFormLabel>
                  <DatePicker
                    units={units}
                    value={this.state.dateOfBirth || null}
                    onChange={this._onDateOfBirthChange}
                    disabled={!organization.isClub && !player.temporary}
                  />
                </Grid>
              </>
            )}
            {(tournament.tournamentType === TournamentTypes.weekly || editContext === PlayerPoolEditContext.GROUP) && (
              <Grid item>
                <HelpFormLabel>
                  <FormattedMessageWrapper id="tournament.tee" />
                  {this._showTeeInfoIcon() && (
                    <InfoTooltip
                      text={<FormattedMessageWrapper id={'tournament.changesOnlyValidForUpcomingRounds'} />}
                      className={classes.infoIcon}
                    />
                  )}
                </HelpFormLabel>
                <SelectBox
                  margin="none"
                  id="teeboxId"
                  name="teeboxId"
                  value={this.state.teeboxId}
                  inputElement={OutlinedInput}
                  onChange={this._onChange}
                >
                  {this._courseTees(this.state.gender)}
                </SelectBox>
              </Grid>
            )}
          </Grid>

          {editContext !== PlayerPoolEditContext.GROUP && (
            <Grid container spacing={2}>
              <Grid item>
                {divisions.some(({ id, type }: DivisionState) => this.checkDivisions(id, type)) && (
                  <>
                    <Typography variant="h3" className={classes.divisionTitle}>
                      <FormattedMessageWrapper id="tournament.division" />
                    </Typography>
                    {this.renderDivisions()}
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {this._renderCustomQuestionAnswers()}

          {editContext !== PlayerPoolEditContext.GROUP && editContext !== PlayerPoolEditContext.GROUP_POOL && (
            <Grid container spacing={2}>
              <Grid item>{this._renderPlayerStatus()}</Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: this._showDeletePlayer ? 'space-between' : 'flex-end' }}
        >
          {this._renderActionButtons()}
          <div>
            <Button onClick={() => this._handleOnClose()} style={{ marginRight: 10 }}>
              <FormattedMessageWrapper id={'buttons.close'} />
            </Button>

            <ButtonLoaderWrap loading={this.state.isLoading}>
              <Button disabled={this.state.isLoading} color="primary" variant={'contained'} onClick={this._onClickSave}>
                <FormattedMessageWrapper id={'buttons.save'} />
              </Button>
            </ButtonLoaderWrap>
          </div>
        </DialogActions>
        <AddPlayersDialog
          isOpen={this.state.teamAddPlayersDialogOpen}
          onClose={this.handleAddPlayersDialogOnClose}
          player={player}
          team={this.getPlayerTeam()}
          onSave={this.handleAddPlayersDialogSave}
        />
      </Dialog>
    )
  }

  private handleAddPlayersDialogSave = (mode: string) => {
    this._handleOnClose(
      mode === 'individual' ? PlayerTeamPoolEditAction.MOVE_TO_PLAYER_POOL : PlayerTeamPoolEditAction.CHANGE_TEAM_LIST,
    )
    this.handleAddPlayersDialogOnClose()
  }

  private get _clubItems() {
    return (
      this.clubs &&
      this.clubs.map((club) => ({
        value: club.id,
        label: club.name,
      }))
    )
  }

  private get clubs() {
    return this.state.clubs
  }

  private _getHcpUpdateInfoText = () => {
    return this.state.isHcpUpdateAllowed ? (
      <FormattedMessageWrapper id="tournament.hcpUpdateOnInfo" />
    ) : (
      <FormattedMessageWrapper id="tournament.hcpUpdateOffInfo" />
    )
  }

  public _updateClubSearchTerm = (clubSearchTerm: string) => {
    this.setState({
      ...this.state,
      clubSearchTerm,
    })
    this._fetchClubs()
  }

  private _fetchClubs = debounce(() => {
    const { countryId } = this.props.organization
    const { clubSearchTerm } = this.state

    if (countryId && clubSearchTerm) {
      this.setState({
        ...this.state,
        loading: true,
      })
      this.props.searchClubs({ searchTerm: clubSearchTerm, countryId, onComplete: this._handleOnComplete })
    }
  }, 400)

  public _handleOnComplete = (result: ClubsAndCoursesAPICallResult) => {
    if (result.error) {
      this.setState({
        ...this.state,
        error: result.error.message,
      })
    }

    this.setState({
      ...this.state,
      loading: false,
      ...result.data,
    })
  }

  private handleAddPlayersDialogOnClose = () => {
    this.setState({ teamAddPlayersDialogOpen: false })
  }

  public _onClubChange = (item?: AutocompleteItem) => {
    if (!item) {
      return
    }
    const { value, label } = item
    this.setState({ homeClub: { value, label } })
  }

  public _onDateOfBirthChange = (date: DateTimeValue) => {
    this.setState({ dateOfBirth: date || undefined })
  }

  _renderActionButtons = () => {
    const { classes } = this.props
    const { reserveListEnabled } = this.props.tournamentSite

    return (
      <div className={classes.actionButtonsWrapper}>
        {[PlayerPoolEditContext.REMOVED, PlayerPoolEditContext.RESERVED].includes(this.props.editContext) && (
          <Button onClick={this._onRosterButtonClick} variant="contained" className={classes.rosterButton}>
            <FormattedMessageWrapper id={'tournament.addToPlayerPool'} />
          </Button>
        )}
        {reserveListEnabled &&
          [PlayerPoolEditContext.REMOVED, PlayerPoolEditContext.POOL].includes(this.props.editContext) && (
            <Button onClick={this._onReserveButtonClick} variant="contained" className={classes.reserveListButton}>
              <FormattedMessageWrapper id={'tournament.moveToReserveList'} />
            </Button>
          )}
        {[PlayerPoolEditContext.RESERVED, PlayerPoolEditContext.POOL].includes(this.props.editContext) && (
          <Button onClick={this._onRemoveButtonClick} variant="contained" className={classes.removeButton}>
            <FormattedMessageWrapper id={'tournament.removePlayer'} />
          </Button>
        )}
        {[PlayerPoolEditContext.REMOVED].includes(this.props.editContext) && (
          <DeleteConfirm>
            {({ showConfirm }: DeleteConfirmChildren) => (
              <Button
                onClick={() => showConfirm({ callback: this._onDeleteButtonClick })}
                variant="contained"
                className={classes.removeButton}
              >
                <FormattedMessageWrapper id={'tournament.deletePlayer'} />
              </Button>
            )}
          </DeleteConfirm>
        )}
      </div>
    )
  }

  get _showDeletePlayer() {
    // If scoring has started, do not show delete button
    if (this.props.disablePlayerListEdit) {
      return false
    }

    // Disable if startlist is created
    if (this.props.tournamentStartLists.rounds.length > 0) {
      const hasStartList = this.props.tournamentStartLists.rounds.some((startlist) => startlist.groups.length > 0)
      if (hasStartList) {
        return false
      }
    }

    return true
  }

  get _isReserveListEdit() {
    const { editContext } = this.props
    return editContext === PlayerPoolEditContext.RESERVED
  }

  get _playerClubName() {
    return this.props.player?.club?.name || ''
  }

  public _setCourseTees = (data: RoundCourse) => {
    this.setState({ courseTees: data.teeBoxes })
  }

  private _courseTees(gender: string) {
    let validTeeBoxes: CourseTee[] = []
    switch (gender) {
      case 'female':
        validTeeBoxes = validFemaleTeeBoxes(this.state.courseTees)
        break
      case 'male':
        validTeeBoxes = validMaleTeeBoxes(this.state.courseTees)
        break
    }
    return validTeeBoxes.map((teeBox) => (
      <MenuItem key={teeBox.id} value={teeBox.id}>
        {teeBox.teeboxName}
      </MenuItem>
    ))
  }

  private _handleOnClose = (action?: string, player?: TournamentPlayerUpdatePayload) => {
    this.props.onClose(action, player)
    this.setState({ userId: -1 })
  }

  private _onClickSave = () => {
    const { tournament, roundIndex, player: originalPlayer } = this.props
    const {
      firstName,
      lastName,
      hcp,
      teeboxId,
      gender,
      homeClub,
      dateOfBirth,
      selectedDivisionId,
      isHcpUpdateAllowed,
    } = this.state

    const player: TournamentPlayerUpdatePayload = {
      firstName,
      lastName,
      handicap: hcp,
      teeboxId,
      gender,
      homeClubId: homeClub?.value,
      birthday: dateOfBirth,
      divisionId: selectedDivisionId,
      resetHcpSource: isHcpUpdateAllowed && originalPlayer?.hcpSource === PlayerHcpSource.MANUAL,
    }
    /**
     * Omit round id when editing player in player pool
     */
    if (this.props.editContext !== PlayerPoolEditContext.POOL) {
      player.roundId = tournament.rounds[roundIndex].id
    }
    player.teamId = this.props.player && this.props.player.team ? this.props.player.team.id : null

    const hcpValid = validateHandicapValue(player.handicap)

    if (hcpValid.valid) {
      const questionAnswers = Object.keys(this.state.customQuestionAnswers).map(
        (key) => this.state.customQuestionAnswers[key],
      )
      this._handleOnClose(PlayerTeamPoolEditAction.UPDATE_PLAYER_INFO, {
        ...player,
        handicap: hcpValid.value,
        questionAnswers,
      })
    }
  }

  public _handleChange = (name: string) => (event) => {
    const { value } = event.currentTarget
    if (name === 'hcp') {
      this.setState({ hcp: trimHcpDecimals(value.toString()) })
      return
    }
    this.setState({ [name]: value } as any)
  }

  private _onChange = (event) => {
    const { value, name } = event.target

    if (name === 'gender') {
      const { tournament } = this.props
      const round = tournament.rounds[0]
      const initialTeeboxId = value === 'female' ? round.womenTeeBoxId : round.menTeeBoxId
      this.setState({ teeboxId: initialTeeboxId })
    }

    this.setState({ [name as any]: value } as any)
  }

  private _renderCustomQuestionAnswers = () => {
    const { customQuestionsEnabled, customQuestions } = this.props

    if (!customQuestionsEnabled) {
      return null
    }

    return (
      <div style={{ marginTop: 24 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HelpFormLabel>
              <FormattedMessageWrapper id={'tournament.entryQuestionAnswers'} />
            </HelpFormLabel>
          </Grid>

          {customQuestions.map(this._renderCustomQuestionAnswer)}
        </Grid>
      </div>
    )
  }

  private _renderCustomQuestionAnswer = (customQuestion: Question, index: number) => {
    return (
      <Grid item xs={12} key={customQuestion.id}>
        <CustomQuestionAnswer
          question={customQuestion}
          questionAnswer={this.state.customQuestionAnswers[customQuestion.id]}
          onChange={(answer) => {
            this.setState({
              customQuestionAnswers: {
                ...this.state.customQuestionAnswers,
                [customQuestion.id]: answer,
              },
            })
          }}
          index={index}
        />
      </Grid>
    )
  }

  private handleDivisionSelect = (id: number) => {
    const { selectedDivisionId } = this.state
    this.setState({
      selectedDivisionId: selectedDivisionId !== id ? id : -1,
    })
  }

  private checkDivisions = (id: number, type: string) => {
    const { tournament } = this.props
    return tournament.divisions.includes(id) && type === DivisionType.MANUAL
  }

  private renderDivisions = () => {
    const { divisions, classes } = this.props
    const { selectedDivisionId } = this.state

    const divisionsList = divisions.map(({ id, name, type }: DivisionState) => {
      const isSelected = id === selectedDivisionId

      return (
        this.checkDivisions(id, type) && (
          <Chip
            key={id}
            clickable
            color="primary"
            variant={isSelected ? 'filled' : 'outlined'}
            label={name}
            className={classes.division}
            onClick={() => id && this.handleDivisionSelect(id)}
          />
        )
      )
    })

    return divisionsList
  }

  /**
   * Try to retrieve reserve team from teams store.
   * If teams don't exist try to create a team from individual reserve players.
   */
  private getPlayerTeam = () => {
    const { player, teams, reservePlayers } = this.props
    const playerTeam = teams.find((team) => team.id === player?.team?.id)
    const filteredReservePlayers = reservePlayers.filter((rPlayer) => rPlayer.team?.id === player?.team?.id)
    const reserveTeam = cloneDeep(filteredReservePlayers)[0]?.team
    if (reserveTeam) {
      reserveTeam.players = filteredReservePlayers
    }
    return playerTeam || reserveTeam
  }
}

const ConnectedComponent = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => {
    return {
      auth: state.authenticationReducer,
      customQuestionsEnabled: selectTournamentSite(state).customQuestionsEnabled,
      customQuestions: selectTournamentSite(state).customQuestions,
      tournamentStartLists: selectTournamentStartLists(state),
      disablePlayerListEdit: disablePlayerListEdit(state),
      tournament: selectTournament(state),
      tournamentSite: selectTournamentSite(state),
      roundCourses: selectRoundCourseOptions(state),
      organization: state.organizationReducer,
      units: state.authenticationReducer.units,
      teams: selectTournamentTeams(state).data?.teams || [],
      reservePlayers: selectTournamentPlayers(state).data?.reserveList || [],
      tournamentConfig: selectTournamentConfig(state),
    }
  },
  {
    getCourses,
    searchClubs,
  },
)(EditPlayerDialogComponent)

const EditPlayerDialog = injectIntl(withStyles(styles)(ConnectedComponent))

export { EditPlayerDialog }
