export enum AnswerTypes {
  YES_NO = 'yesNo',
  FREE_TEXT = 'freeText',
}

export enum EntryMethods {
  INDIVIDUAL = 'individual',
  TEAM = 'team',
}

export enum GameFormatTypes {
  PRIMARY = 'primary',
  SIDE = 'side',
  CONTESTS = 'contests',
}

export enum GameFormats {
  UNDEFINED = 0,
  NO_SIDE = 1,

  STROKE_PLAY_INDIVIDUAL = 2,
  STROKE_PLAY_BETTER_BALL = 4,
  STROKE_PLAY_BEST_BALL = 5,
  STROKE_PLAY_SCRAMBLE = 8,
  STROKE_PLAY_SCRAMBLE_2_MAN = 11,
  STROKE_PLAY_ERADO = 6,
  STROKE_PLAY_SKINS = 13,
  STROKE_PLAY_LOW_SCRATCH_NET = 14,
  STROKE_PLAY_GREENSOME = 17,
  STROKE_PLAY_FOURSOME = 19,
  STROKE_PLAY_COLLEGE = 22,

  STABLEFORD_INDIVIDUAL = 3,
  STABLEFORD_INDIVIDUAL_DUPLICATE = 9,
  STABLEFORD_BETTER_BALL = 15,
  STABLEFORD_BEST_BALL = 7,
  STABLEFORD_SCRAMBLE_DUPLICATE = 12,
  STABLEFORD_GREENSOME = 16,
  STABLEFORD_FOURSOME = 18,

  MATCH_PLAY_INDIVIDUAL = 23,
  MATCH_PLAY_BETTER_BALL = 24,
  MATCH_PLAY_SCRAMBLE = 25,
  MATCH_PLAY_GREENSOME = 27,
  MATCH_PLAY_FOURSOME = 28,

  IRISH_RUMBLE_BEST_BALL = 20,
  TEAM_PAR_IS_YOUR_FRIEND = 21,
}

export enum CapOverflowMethods {
  RESERVE_LIST = 'reserveList',
  ENTRIES_CLOSED = 'entriesClosed',
}

export enum Units {
  METRIC = 'metric',
  US = 'us',
}

export enum USTimeAbbreviations {
  AM = 'AM',
  PM = 'PM',
}

export enum ExtraInfo {
  HOME_CLUB = 'club',
  GENDER = 'gender',
  YEAR_OF_BIRTH = 'yearOfBirth',
  ENTRY_TIME = 'entryTime',
  CUSTOM_QUESTIONS = 'customQuestions',
  TEAM_NAME = 'teamName',
  TEEBOX = 'teebox',
  EMAIL = 'email',
  DIVISION = 'division',
  ROUND = 'round',
}

export enum ResultStatus {
  NONE = '-',
  OK = 'ok',
  DQ = 'dq',
  NF = 'nf',
  DQ_HCP = 'dqHcp',
  RTD = 'rtd',
  DNS = 'dns',
  CUT = 'cut',
  MDF = 'mdf',
}

export enum ColorTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export enum TournamentTypes {
  singleRound = 'singleRound',
  multiRound = 'multiRound',
  weekly = 'weekly',
  //TODO: Commented as part of TM-2944, going to be added back at some point
  //redsVsBlues = 'redsVsBlues',
  //matchplayBracket = 'matchplayBracket',
}

// Contest types below should be in the order in which they are displayed on R&O page
export enum ContestResultType {
  CLOSEST_TO_PIN = 'closestToPin',
  LONGEST_DRIVE = 'longestDrive',
  STRAIGHTEST_DRIVE = 'straightestDrive',
}

export enum TieBreakMethod {
  handicap = 'handicap',
  playingHandicap = 'playingHandicap',
  countback = 'countback',
}

export enum DownloadPrintTypes {
  STARTLIST = 'startList',
  RESULTS = 'results',
}

export enum TournamentCopy {
  TOURNAMENT = 'TOURNAMENT',
  ROUND = 'ROUND',
  SITE = 'SITE',
  PLAYERS = 'PLAYERS',
  GROUPS = 'GROUPS',
}

export enum DivisionType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export enum DateType {
  createdAt = 'createdAt',
  startTime = 'startTime',
}

export enum SortType {
  asc = 'asc',
  desc = 'desc',
}

export enum UrlParams {
  EDIT_SUMMARY = 'editSummary',
}

export enum TournamentPages {
  SETUP = 'setup',
  ROUND_SETUP = 'round-setup',
  TOURNAMENT_SITE = 'tournament-site',
  BRANDING = 'branding',
  ENTRIES = 'entries',
  PLAYERS = 'players',
  GROUPS_AND_START_LIST = 'groups-and-start-list',
  RESULTS_AND_OPTIONS = 'results-and-options',
}
