import React from 'react'
import { connect } from 'react-redux'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import LinearProgress from '@mui/material/LinearProgress'
import createStyles from '@mui/styles/createStyles'
import classNames from 'classnames'
import { RootState } from '@app/store'

const styles = () =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: -6,
      width: '100%',
      zIndex: 10000000,
      transitionProperty: 'bottom',
      transitionDuration: '125ms',
      transitionTimingFunction: 'ease-in-out',
      '&.show': {
        bottom: 0,
      },
    },
  })

interface StateProps {
  autoSaveInProgress: boolean
}

type Props = StateProps & WithStyles<typeof styles>

class AutoSaveIndicator extends React.Component<Props> {
  render() {
    const { classes, autoSaveInProgress } = this.props
    return (
      <div className={classNames(classes.root, { show: autoSaveInProgress })}>
        <LinearProgress variant={'indeterminate'} color={'primary'} />
      </div>
    )
  }
}

export default withStyles(styles)(
  connect<StateProps, {}, {}, RootState>((store) => ({
    autoSaveInProgress: store.notificationsReducer.autoSaveInProgress,
  }))(AutoSaveIndicator),
)
