import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { FormControl, MenuItem, Select, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BorderButton from '@components/ui/BorderButton'
import { rem } from '@app/theme/materialUITheme'
import { connect } from 'react-redux'
import { RootState } from '@app/store'
import { tournamentTeamsApi } from '@app/store/api/endpoints/tournamentTeamsApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import { selectTournamentPlayers } from '@app/store/api/endpoints/tournamentPlayersApi'

interface OwnProps {
  createTeam: () => void
  createTeams: () => void
}

interface DispatchProps {
  createTeamsAutofill: (payload: CreateTeamsAutofillPayload) => void
}

interface StateProps {
  players: TournamentPlayer[]
  tournamentId: number
}

export type NoTeamsProps = React.HtmlHTMLAttributes<HTMLDivElement> & OwnProps & DispatchProps & StateProps

const useStyles = makeStyles((theme: Theme) => ({
  createStartGroupsButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: rem(50),
    alignContent: 'center',
    '& .Mui-disabled': {
      color: 'grey',
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  selectText: {
    paddingTop: rem(40),
    paddingBottom: rem(20),
    color: theme.palette.common.white,
    margin: '0 auto',
  },
  startListButton: {
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    width: rem(300),
    margin: '0 auto',
  },
  selectFillCriteria: {
    display: 'flex',
    margin: 'auto',
    width: rem(300),
    paddingTop: rem(50),
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      color: theme.palette.common.white,
      fontSize: rem(16),
    },
    '& .Mui-disabled': {
      color: 'grey',
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '& .MuiInputBase-root.Mui-disabled .MuiSelect-outlined.MuiSelect-outlined': {
      color: 'grey',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },

    '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.white,
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.white,
      borderWidth: '2px',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.common.white,
    },
  },
}))

const NoTeamsComponent: React.FC<NoTeamsProps> = ({
  createTeam,
  createTeams,
  createTeamsAutofill,
  players,
  tournamentId,
}) => {
  const classes = useStyles()

  const onTeamSelectFillCriteria = (event) => {
    const { value } = event.target

    const playersOrder = players.reduce((acc, player, index) => ({ ...acc, [player.userId]: index }), {})

    createTeamsAutofill({
      tournamentId,
      fillTeamsByType: value,
      playersOrder,
    })
  }

  return (
    <>
      <div className={classes.createStartGroupsButton}>
        <div className={classes.selectText}>
          <FormattedMessageWrapper id="tournament.createTeamSelect" />
        </div>
        <BorderButton
          buttonProps={{
            className: classes.startListButton,
            onClick: createTeam,
          }}
          jumbo
        >
          <FormattedMessageWrapper id="tournament.createTeamOneByOne" />
        </BorderButton>

        <BorderButton
          buttonProps={{
            className: classes.startListButton,
            style: { marginTop: rem(50) },
            onClick: createTeams,
          }}
          jumbo
        >
          <FormattedMessageWrapper id="tournament.createTeamAll" />
        </BorderButton>
      </div>

      <FormControl variant="outlined" className={classes.selectFillCriteria}>
        <Select value="none" name="teamSelectFillCriteria" onChange={onTeamSelectFillCriteria}>
          <MenuItem key={1} value="none">
            <FormattedMessageWrapper id="tournament.selectFillCriteria" />
          </MenuItem>
          <MenuItem value="poolOrder">
            <FormattedMessageWrapper id={'options.poolOrder'} />
          </MenuItem>
          <MenuItem value="random">
            <FormattedMessageWrapper id={'options.random'} />
          </MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

export const NoTeams = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    players: selectTournamentPlayers(state).data?.players || [],
    tournamentId: getTournamentId(state),
  }),
  {
    createTeamsAutofill: tournamentTeamsApi.endpoints.createTeamsAutofill.initiate,
  },
)(NoTeamsComponent)
