import { api } from '../baseApi'
import { APIRoute } from '../../../config'
import { CacheTag } from '../cacheTags'
import { setClubs } from '../slices/clubsAndCoursesSlice'
import { RootState } from '@app/store'
import { updateTournamentRoundCourseOptions } from '../thunks/clubsAndCoursesThunks'

export const clubsAndCoursesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClub: build.query<Club, number>({
      query: (clubId: number) => APIRoute.GET_CLUB(clubId),
    }),
    searchClubs: build.query<Club[], SearchClubsPayload>({
      query: (payload) => ({
        url: APIRoute.GET_SEARCH_CLUBS,
        params: { searchTerm: payload.searchTerm, countryId: payload.countryId, type: 'condensed', limit: 50 },
      }),
      transformResponse: (response: { clubs: Club[] }) => {
        return response.clubs
      },
      async onQueryStarted(payload, { queryFulfilled, dispatch }) {
        try {
          const { data: clubs } = await queryFulfilled
          dispatch(setClubs(clubs))
          if (payload.onComplete) {
            payload.onComplete({ data: { clubs, currentCountryId: payload.countryId } })
          }
        } catch {
          console.error('Error fetching clubs')
        }
      },
    }),
    searchClubsPublic: build.query<Club[], SearchClubsPublicPayload>({
      query: (payload) => ({
        url: APIRoute.GET_SEARCH_CLUBS_PUBLIC,
        params: { searchTerm: payload.searchTerm, countryId: payload.countryId },
      }),
      transformResponse: (response: { clubs: Club[] }) => {
        return response.clubs
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data: clubs } = await queryFulfilled
          dispatch(setClubs(clubs))
        } catch {
          console.error('Error fetching clubs')
        }
      },
    }),
    getCourses: build.query<Course[], CoursesPayload>({
      query: (payload) => ({
        url: APIRoute.GET_COURSES(payload.clubId),
        params: { timestamp: payload.timestamp },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(updateTournamentRoundCourseOptions(data))
          if (payload.onComplete) {
            payload.onComplete({ data: { courses: data, currentClubId: payload.clubId } })
          }
        } catch {
          console.error('Error fetching courses')
        }
      },
      transformResponse: (response: { courses: Course[] }) => {
        return response.courses
      },
      providesTags: [CacheTag.COURSES],
    }),
  }),
})

export const selectClub = (state: RootState, clubId: number): Club | undefined =>
  clubsAndCoursesApi.endpoints.getClub.select(clubId)(state).data

export const selectCourses = (state: RootState, payload: CoursesPayload): Course[] =>
  clubsAndCoursesApi.endpoints.getCourses.select(payload)(state).data || []

export const selectClubSearch = (state: RootState, payload: SearchClubsPayload): Club[] =>
  clubsAndCoursesApi.endpoints.searchClubs.select(payload)(state).data || []

export const selectClubSearchPublic = (state: RootState, payload: SearchClubsPublicPayload): Club[] =>
  clubsAndCoursesApi.endpoints.searchClubsPublic.select(payload)(state).data || []

export const { useGetClubQuery, useSearchClubsQuery, useSearchClubsPublicQuery, useGetCoursesQuery } =
  clubsAndCoursesApi
