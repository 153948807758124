import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import TournamentsTable from '../../components/dashboard/TournamentsTable'
import { fetchTournaments, FetchTournamentsPayload } from '../../store/tournaments/actions'
import { Link } from 'react-router-dom'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import BorderButton from '../../components/ui/BorderButton'
import ContentWrap from '../../components/layout/ContentWrap'
import queryString from 'query-string'
import HeadHelmet from '../../components/layout/HeadHelmet'
import TopBar from '../../components/layout/TopBar'
import { pickBy } from 'lodash'
import { isCustomerEventUser } from '../../utils/authUtils'
import get from 'lodash/get'
import { Typography, Tooltip, Grid } from '@mui/material'
import { fetchOrganization } from '../../store/organization/actions'
import CalendarDialog from '../../components/dialogs/calendarDialog/CalendarDialog'
import InfoTooltip from '../../components/ui/InfoTooltip'
import { TournamentSearch } from './tournament-search'
import { TournamentFilter } from './tournament-filter'
import { fetchTimeZones } from '@app/store/timeZones/actions'
import { formatDateToIsoWithTimeZone } from '@app/utils/dates'
import { WithRouterProps, withRouter } from '@app/hoc/withRouter'
import { RootState } from '@app/store'
import { MixpanelEvents, mixpanelActions } from '@app/utils/mixpanel'

interface StateProps {
  isLoading: boolean
  auth: AuthenticationState
  organization: OrganizationState
  intl?: any
  tournaments: Tournament[]
  timeZones: TimeZone[]
}

interface DispatchProps {
  fetchTournaments(payload: FetchTournamentsPayload): any
  fetchOrganization: (id: number, force?: boolean, onComplete?: () => void) => void
  fetchTimeZones: (onComplete?: () => void) => void
}

type Props = StateProps & DispatchProps & WithRouterProps & WrappedComponentProps

interface State {
  organizationId: number
  page: number
  totalCount: number
  searchTerm: string
  tournaments: Tournament[]
  sortBy: TournamentSortOption
  direction: 'asc' | 'desc'
  startDate?: Date
  endDate?: Date
  calendarDialogOpen: boolean
  showAllTournaments: boolean
}

class UnconnectedDashboard extends React.Component<Props, State> {
  readonly state: State = {
    organizationId: 0,
    page: 0,
    totalCount: 0,
    searchTerm: '',
    tournaments: [],
    sortBy: 'date',
    direction: 'asc',
    calendarDialogOpen: false,
    showAllTournaments: false,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const organizationId = get(props.auth, 'roleInfo.organizationId', 0)
    const params = queryString.parse(props.location.search)
    const page = params.page ? parseInt(params.page as string, 10) : 1
    const searchTerm = get(params, 'searchTerm', '')
    const sortBy = get(params, 'sortBy', 'date')
    const direction = get(params, 'direction', 'asc')
    const startDate = get(params, 'startDate')
    const endDate = get(params, 'endDate')

    if (page !== state.page) {
      return {
        organizationId,
        page,
        searchTerm,
        sortBy,
        direction,
        startDate,
        endDate,
      }
    }

    return null
  }

  componentDidMount(): void {
    this._triggerFetch()
  }

  componentDidUpdate(prevProps, prevState: State): void {
    if (prevState.page !== this.state.page) {
      this._triggerFetch()
    }

    if (this.props.isLoading !== prevProps.isLoading && !this.props.isLoading) {
      this.setState({ tournaments: this.props.tournaments })
    }
  }

  render() {
    const { isLoading } = this.props
    const { tournaments, page, totalCount, sortBy, direction, searchTerm } = this.state

    return (
      <>
        <HeadHelmet titleId={'tournaments.title'} />
        <TopBar titleId={'tournaments.title'} />

        <OverlayLoader visible={isLoading} />

        <ContentWrap>
          <div style={{ display: 'flex', marginBottom: 32 }}>{this._getCreateActions}</div>

          <Typography variant={'h2'}>
            <FormattedMessageWrapper id={'tournaments.listTitle'} />
          </Typography>
          <Grid container>
            <Grid item xs={8}>
              <TournamentFilter isLoading={isLoading} onChange={this._onFiltersChange} />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <TournamentSearch onSubmit={this._searchTournaments} isLoading={isLoading} defaultValue={searchTerm} />
            </Grid>
          </Grid>
          <TournamentsTable
            page={page - 1}
            sortBy={sortBy}
            direction={direction}
            totalCount={totalCount}
            tournaments={tournaments}
            triggerFetch={this._triggerFetch}
            onChangePage={this._handlePageChange}
            onRequestSort={this._handleSortChange}
          />
        </ContentWrap>
      </>
    )
  }

  private get _hasLicenseOrSubscription() {
    const { license, subscription } = this.props.auth
    if (license.active || subscription.active) {
      return true
    }

    return false
  }

  private get _getCreateActions() {
    const { roleInfo } = this.props.auth

    if (isCustomerEventUser(roleInfo)) {
      return null
    }

    return (
      <>
        <Tooltip
          disableTouchListener={this._hasLicenseOrSubscription}
          disableHoverListener={this._hasLicenseOrSubscription}
          disableFocusListener={this._hasLicenseOrSubscription}
          title={<FormattedMessageWrapper id="tournament.disabledPurchaseLicense" />}
          placement="top"
        >
          <div>
            <BorderButton
              filled
              buttonProps={{
                disabled: !this._hasLicenseOrSubscription,
                component: this.createNewLink,
                onClick: () => mixpanelActions.trackEvent(MixpanelEvents.TOURNAMENT_SETUP),
                style: {
                  marginRight: 22,
                },
              }}
            >
              <FormattedMessageWrapper id="buttons.createNew" />
            </BorderButton>
          </div>
        </Tooltip>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <BorderButton
            buttonProps={{
              onClick: () => this.setState({ calendarDialogOpen: true }),
            }}
          >
            <FormattedMessageWrapper id="tournaments.calendar" />
          </BorderButton>
          <InfoTooltip text={<FormattedMessageWrapper id="tournament.calendarInfo" />} style={{ marginLeft: 5 }} />
        </div>
        <CalendarDialog
          open={this.state.calendarDialogOpen}
          onClose={() => this.setState({ calendarDialogOpen: false })}
        />
      </>
    )
  }

  private get createNewLink() {
    return React.forwardRef((props: any, ref: any) => <Link to="/tournaments/create" {...props} ref={ref} />)
  }

  private _searchTournaments = (searchTerm: string) => {
    const params = queryString.parse(this.props.location.search)

    const search = queryString.stringify(
      pickBy({
        ...params,
        searchTerm,
        page: 1,
      }),
    )
    this.props.navigate(`${this.props.location.pathname}?${search}`, { replace: true })
    this.setState({ searchTerm, direction: 'desc' }, this._triggerFetch)
  }

  private _fetchTournaments = () => {
    const { organizationId, page, sortBy, direction, searchTerm, startDate, endDate, showAllTournaments } = this.state
    const organizationTimeZone = this._getOrganizationTimeZone()

    const defaultStartDate = new Date()
    defaultStartDate.setDate(defaultStartDate.getDate() - 3)
    const defaultEndDate = new Date(new Date().getFullYear(), 11, 31)

    let params: FetchTournamentsPayload = {
      organizationId,
      page,
      searchTerm,
      sortBy,
      direction,
      onComplete: this._setFetchPayload,
    }

    if (showAllTournaments || searchTerm) {
      params = {
        ...params,
        startDate:
          !showAllTournaments && startDate
            ? formatDateToIsoWithTimeZone(startDate, organizationTimeZone?.zone)
            : undefined,
        endDate:
          !showAllTournaments && endDate ? formatDateToIsoWithTimeZone(endDate, organizationTimeZone?.zone) : undefined,
        withoutDate: showAllTournaments ? 1 : 0,
      }
    } else {
      params = {
        ...params,
        startDate: startDate ? formatDateToIsoWithTimeZone(startDate, organizationTimeZone?.zone) : defaultStartDate,
        endDate: endDate ? formatDateToIsoWithTimeZone(endDate, organizationTimeZone?.zone) : defaultEndDate,
      }
    }

    this.props.fetchTournaments(params)
  }

  public _triggerFetch = () => {
    const { organizationId } = this.state

    this.props.fetchOrganization(organizationId, false, () => {
      if (this.props.timeZones.length === 0) {
        this.props.fetchTimeZones(() => {
          this._fetchTournaments()
        })
      } else {
        this._fetchTournaments()
      }
    })
  }

  public _setFetchPayload = ({ data, error }: APICallResult) => {
    if (!error) {
      this.setState({ ...data })
    }
  }

  private _getOrganizationTimeZone = (): TimeZone | undefined => {
    const { organization, timeZones } = this.props
    const timeZoneId = get(organization, 'addressInfo.timeZoneId')

    if (!timeZoneId || timeZones.length === 0) {
      return
    }

    return timeZones.find((zone) => zone.id === timeZoneId)
  }

  private _onFiltersChange = (
    startDate: Date | undefined,
    endDate: Date | undefined,
    showAllTournaments = false,
    upcoming = false,
  ) => {
    if (this.state.startDate === startDate && this.state.endDate === endDate && !showAllTournaments && !upcoming) {
      return
    }
    const { searchTerm } = this.state
    const params = queryString.parse(this.props.location.search)
    this.setState({ startDate, endDate, showAllTournaments }, () => {
      this.setState({ page: 1 })
      this.props.historyReplace({
        pathname: this.props.location.pathname,
        search: queryString.stringify(
          pickBy({
            ...params,
            searchTerm,
            startDate: showAllTournaments ? ' ' : startDate?.toISOString(),
            endDate: showAllTournaments ? ' ' : endDate?.toISOString(),
            page: 1,
          }),
        ),
      })
      this._triggerFetch()
    })
  }

  public _handlePageChange = (page: number) => {
    const { searchTerm } = this.state
    const params = queryString.parse(this.props.location.search)
    this.props.historyReplace({
      pathname: this.props.location.pathname,
      search: queryString.stringify(
        pickBy({
          ...params,
          searchTerm,
          page: `${page + 1}`,
        }),
      ),
    })
  }

  public _handleSortChange = (e, property) => {
    const isDesc = this.state.sortBy === property && this.state.direction === 'desc'
    const direction = isDesc ? 'asc' : 'desc'
    const params = queryString.parse(this.props.location.search)
    this.setState(
      {
        sortBy: property,
        direction,
      },
      () => {
        this.props.historyReplace({
          pathname: this.props.location.pathname,
          search: queryString.stringify(
            pickBy({
              ...params,
              sortBy: property,
              direction,
            }),
          ),
        })

        this._triggerFetch()
      },
    )
  }
}

const RoutedDashboard = withRouter(UnconnectedDashboard)
export default connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => ({
    isLoading: state.tournamentsReducer.loading,
    auth: state.authenticationReducer,
    organization: state.organizationReducer,
    tournaments: state.tournamentsReducer.tournaments,
    timeZones: state.timeZonesReducer.timeZones,
  }),
  { fetchTournaments, fetchOrganization, fetchTimeZones },
)(injectIntl(RoutedDashboard))
