import { takeEvery, put, all, select } from 'redux-saga/effects'
import {
  ADD_TEMP_PLAYER,
  ADD_TEMP_PLAYER_FAILURE,
  ADD_TEMP_PLAYER_SUCCESS,
  CLEAR_PLAYERS_SEARCH,
  PLAYERS_SEARCH,
  PLAYERS_SEARCH_SUCCESS,
  UPDATE_PLAYERS_FIELD,
  PLAYERS_SEARCH_ORGANIZATION,
  PLAYERS_SEARCH_ORGANIZATION_SUCCESS,
  PLAYERS_SEARCH_ORGANIZATION_FAILURE,
} from './constants'
import { apiClientRequest, defaultOnCompleteCall } from '@utils/sagaHelpers'
import { getToken } from '@app/store/authentication/sagas'
import { API_ROOT, APIRoute } from '@config/index'
import { AddTempPlayerAction } from '@store/players/actions'
import { enqueueNotification } from '@store/notifications/actions'
import { getCurrentOrganizationCountry } from '@store/organization/sagas'
import { RootState } from '..'

const getSearchTerms = (state: RootState) => state.playersReducer
const getTournamentId = (state: RootState) => state.configReducer.tournament.id
const getOrganizationId = (state: RootState) => state.organizationReducer.id

function* doSearchPlayers({
  onComplete,
}: {
  type: string
  onComplete?: (args: APICallResult<{ players: TournamentPlayer[] }>) => void
}) {
  try {
    const searchTerms: PlayersState = yield select(getSearchTerms)
    const token: string = yield getToken()
    const tournamentId: number = yield select(getTournamentId)

    const { searchTerm, firstName, lastName, golferId } = searchTerms

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_PLAYER_SEARCH_REGISTRY(tournamentId)}`,
      method: 'get',
      token,
      params: {
        searchTerm,
        firstName,
        lastName,
        golferId,
      },
    })

    const players: SearchPlayer[] = res.data.users

    defaultOnCompleteCall(onComplete, players)
    yield put({
      type: PLAYERS_SEARCH_SUCCESS,
      players,
    })
  } catch (error: any) {
    defaultOnCompleteCall(onComplete, undefined, error)
    yield put(enqueueNotification(error, 'error'))
  }
}

function* handleAddTempPlayer({ player, onComplete }: AddTempPlayerAction) {
  try {
    const token = yield getToken()
    const tournamentId = yield select(getTournamentId)
    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.POST_TEMP_PLAYER(tournamentId)}`,
      method: 'post',
      token,
      data: player,
    })

    onComplete(res.data, null)
    yield put({
      type: ADD_TEMP_PLAYER_SUCCESS,
    })
  } catch (error: any) {
    if (error?.status === 404) {
      yield put(enqueueNotification('NO_GGB_PROFILE_FOUND_WITH_EMAIL', 'error'))
    } else {
      yield put(enqueueNotification(error, 'error'))
    }

    onComplete(null, error)
    yield put({
      type: ADD_TEMP_PLAYER_FAILURE,
    })
  }
}

function* doClearPlayersSearch() {
  const currentOrganizationCountry = yield select(getCurrentOrganizationCountry)
  yield put({
    type: UPDATE_PLAYERS_FIELD,
    payload: {
      fieldName: 'searchCountryId',
      value: currentOrganizationCountry,
    },
  })
}

function* doOrganizationSearchPlayers({
  onComplete,
}: {
  type: string
  onComplete?: (args: APICallResult<{ players: TournamentPlayer[] }>) => void
}) {
  try {
    const searchTerms: PlayersState = yield select(getSearchTerms)
    const token: string = yield getToken()
    const organizationId: number = yield select(getOrganizationId)
    const offset = 0
    const limit = 10

    const { searchTerm } = searchTerms

    const res = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_PLAYER_SEARCH_ORGANIZATION(organizationId)}`,
      method: 'get',
      token,
      params: {
        searchTerm,
        offset,
        limit,
      },
    })

    const clubId: number = res.data.clubId
    const players: SearchPlayer[] = res.data.users.map((player) => ({ ...player, clubId }))

    yield put({
      type: PLAYERS_SEARCH_ORGANIZATION_SUCCESS,
      players,
      clubId,
    })

    defaultOnCompleteCall(onComplete, players, clubId)
  } catch (error: any) {
    yield put({
      type: PLAYERS_SEARCH_ORGANIZATION_FAILURE,
      error,
    })
    defaultOnCompleteCall(onComplete, undefined, error)
    yield put(enqueueNotification(error, 'error'))
  }
}

export function* watchPlayers() {
  yield all([
    takeEvery(PLAYERS_SEARCH, doSearchPlayers),
    takeEvery(PLAYERS_SEARCH_ORGANIZATION, doOrganizationSearchPlayers),
    takeEvery(ADD_TEMP_PLAYER, handleAddTempPlayer),
    takeEvery(CLEAR_PLAYERS_SEARCH, doClearPlayersSearch),
  ])
}
