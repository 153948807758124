import { Select as MUISelect, SelectProps as MUISelectProps } from '@mui/material'

type SelectProps = {
  noBorder?: boolean
} & MUISelectProps

export const Select: React.FC<SelectProps> = ({ children, noBorder, ...rest }: SelectProps) => {
  const sx = noBorder ? { '& .MuiOutlinedInput-notchedOutline': { border: 0 } } : undefined

  return (
    <MUISelect {...rest} sx={sx}>
      {children}
    </MUISelect>
  )
}
