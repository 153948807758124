/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo } from 'react'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { FormLabel, Select, OutlinedInput, MenuItem, FormControl } from '@mui/material'
import { GameFormatTypes, TournamentTypes } from '@app/store/api/enums/tournamentEnums'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTournamentDivisionLeaderboard } from '@store/tournamentDivisionLeaderboard/actions'
import { setSelectedDivision } from '@store/divisions/actions'
import { useIntl } from 'react-intl'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { RootState } from '@app/store'

export type PrintoutType = 'RESULTS' | 'STARTLIST' | 'CONTESTS' | 'CART_SIGNS'

export type PrintoutDialogOptions = {
  printoutType: PrintoutType
  gameType: GameFormatTypes
  divisionId?: number
  roundIndex: number
}

type PrintoutOptionsProps = {
  onChange: (options: PrintoutDialogOptions) => void
  options: PrintoutDialogOptions
}

type PrintoutTypeOption = {
  label: string
  value: PrintoutType
}

export const PrintoutOptions: React.FC<PrintoutOptionsProps> = ({ onChange, options }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const tournament = useSelector(selectTournament)
  const divisions = useSelector((state: RootState) => state.divisionsReducer)

  useEffect(() => {
    onChange(options)
  }, [onChange, options])

  const onPrintoutTypeChange = (event) => {
    onChange({ ...options, printoutType: event.target.value as PrintoutType })
  }

  const onGameTypeChange = (event) => {
    onChange({ ...options, gameType: event.target.value as GameFormatTypes })
  }

  const onDivisionChange = (event) => {
    const divisionId = event.target.value as number
    onChange({ ...options, divisionId })
    dispatch(setSelectedDivision(divisionId))
    dispatch(fetchTournamentDivisionLeaderboard({ id: tournament?.id || 0, divisionId }))
  }

  const onRoundIndexChange = (event) => {
    onChange({ ...options, roundIndex: event.target.value as number })
  }

  const printoutTypeOptions = useMemo(() => {
    const round = tournament?.rounds[options.roundIndex]
    const items: PrintoutTypeOption[] =
      tournament?.tournamentType !== TournamentTypes.weekly
        ? [
            { label: intl.formatMessage({ id: 'tournament.startList' }), value: 'STARTLIST' },
            { label: intl.formatMessage({ id: 'tournament.printoutresults' }), value: 'RESULTS' },
            { label: intl.formatMessage({ id: 'tournament.printoutcartSigns' }), value: 'CART_SIGNS' },
          ]
        : [{ label: intl.formatMessage({ id: 'tournament.printoutresults' }), value: 'RESULTS' }]

    if (tournament?.tournamentType === TournamentTypes.singleRound && round?.contestsEnabled) {
      items.push({ label: intl.formatMessage({ id: 'tournament.printoutcontestsResults' }), value: 'CONTESTS' })
    }

    return items
  }, [intl, options.roundIndex, tournament?.rounds, tournament?.tournamentType])

  const gameTypeOptions = useMemo(() => {
    return [
      { label: 'Primary', value: GameFormatTypes.PRIMARY },
      { label: 'Side', value: GameFormatTypes.SIDE },
    ]
  }, [])

  const divisionOptions = useMemo(() => {
    const noneOption = { label: '-', value: 0 }
    if (tournament?.id && divisions) {
      return [noneOption].concat(
        divisions.divisions
          .filter((division) => division.tournamentIds?.includes(tournament.id!))
          .map((division) => ({ label: division.name, value: division.id })),
      )
    }
    return [noneOption]
  }, [divisions, tournament?.id])

  const printResultOptions = () => {
    return (
      <>
        <div style={{ flex: 0.33, marginLeft: '20px' }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="gameType">
              <FormattedMessageWrapper id="tournament.gameType" />
            </FormLabel>
            <Select
              variant="filled"
              name="gameType"
              id="gameType"
              fullWidth
              value={options.gameType}
              input={<OutlinedInput name="gameType" id="gameType" />}
              onChange={onGameTypeChange}
            >
              {gameTypeOptions.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
        {tournament?.divisionsEnabled && (
          <div style={{ flex: 0.33, marginLeft: '20px' }}>
            <FormControl fullWidth>
              <FormLabel htmlFor="division">
                <FormattedMessageWrapper id="tournament.division" />
              </FormLabel>
              <Select
                variant="filled"
                name="division"
                id="division"
                fullWidth
                value={options.divisionId}
                input={<OutlinedInput name="division" id="division" />}
                onChange={onDivisionChange}
              >
                {divisionOptions.map((option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        )}
      </>
    )
  }

  const printStartListOptions = () => {
    return (
      <div style={{ flex: 0.33, marginLeft: '20px' }}>
        <FormControl fullWidth>
          <FormLabel htmlFor="roundIndex">
            <FormattedMessageWrapper id="tournament.selectRound" />
          </FormLabel>
          <Select
            variant="filled"
            name="roundIndex"
            id="roundIndex"
            fullWidth
            value={options.roundIndex}
            input={<OutlinedInput name="roundIndex" id="roundIndex" />}
            onChange={onRoundIndexChange}
          >
            {tournament?.rounds.map((_, roundIdx) => {
              return (
                <MenuItem key={roundIdx} value={roundIdx}>
                  {roundIdx + 1}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', width: '100%', padding: '20px' }}>
      <div style={{ flex: 0.33 }}>
        <FormControl fullWidth>
          <FormLabel htmlFor="printoutType">
            <FormattedMessageWrapper id="tournament.printoutType" />
          </FormLabel>
          <Select
            variant="filled"
            name="printoutType"
            id="printoutType"
            fullWidth
            value={options.printoutType}
            input={<OutlinedInput name="printoutType" id="printoutType" />}
            onChange={onPrintoutTypeChange}
          >
            {printoutTypeOptions.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      {options.printoutType === 'RESULTS' ? printResultOptions() : printStartListOptions()}
    </div>
  )
}
