import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import {
  Checkbox,
  FormControl,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { rem } from '@app/theme/materialUITheme'
import { formatHandicap } from '@app/utils/playerUtils'
import { TypeLabel } from './TypeLabel'
import { DivisionType } from '@app/store/api/enums/tournamentEnums'
import BaseDialogTitle from '@app/components/dialogs/ui/BaseDialogTitle'
import { Add, Search } from '@mui/icons-material'
import classNames from 'classnames'
import { CreateNewDivisionDialog } from '@app/components/dialogs/createNewDivisionDialog/CreateNewDivision'
import { fetchDivisions } from '@app/store/divisions/actions'
import DeleteConfirm, { DeleteConfirmChildren } from '@app/components/dialogs/deleteConfirm/DeleteConfirm'
import { updateTournamentField } from '@app/store/api/thunks/tournamentThunks'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { RootState } from '@app/store'
import { selectTournamentPlayers, tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'

const styles = (theme: Theme) =>
  createStyles({
    root: { marginTop: rem(20) },
    tableRowHeader: {},
    tableRow: {
      '& > td': { padding: `${rem(8)} ${rem(16)}` },
    },
    createNewButton: {
      borderWidth: 2,
      fontWeight: 'bold',
      '&:hover': {
        borderWidth: 2,
      },
    },
    filteredOut: {
      '& > td': { color: theme.customPalette.lightGray2 },
    },
  })

const HeaderTableCell = withStyles(() =>
  createStyles({
    head: {
      fontSize: rem(14),
      fontWeight: 600,
      padding: `${rem(8)} ${rem(16)}`,
    },
  }),
)(TableCell)

interface OwnProps {
  open: boolean
  onClose: () => void
}

interface DispatchProps {
  updateTournamentField: (payload: FieldUpdatePayload) => void
  fetchDivisions(organizationId: number): void
  loadTournamentPlayers(tournamentId: number): void
}

interface StateProps {
  divisions: DivisionState[]
  tournamentDivisions: number[]
  organizationId?: number
  players: TournamentPlayer[]
}

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles> & WithRouterProps

interface State {
  selectedDivisions: number[]
  filter: string
  createNewDivisionDialogOpen: boolean
}

class SelectDivisionsDialogComponent extends React.Component<Props, State> {
  readonly state: State = {
    selectedDivisions: [],
    filter: '',
    createNewDivisionDialogOpen: false,
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.open !== this.props.open &&
      this.props.tournamentDivisions.length > 0 &&
      this.state.selectedDivisions.length === 0
    ) {
      this.setState({ selectedDivisions: this.props.tournamentDivisions })
    }
  }

  componentDidMount() {
    const { id } = this.props.params
    Number(id) > 0 && this.props.loadTournamentPlayers(Number(id))
  }

  render() {
    const { open, classes } = this.props

    return (
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={this._handleOnClose}
        aria-labelledby={'select-divisions-title'}
      >
        <BaseDialogTitle
          id={'select-divisions-title'}
          title={<FormattedMessageWrapper id={'divisions.selectDivisions'} />}
          largeTitleText
          onClose={this._handleOnClose}
        />

        <DialogActions style={{ justifyContent: 'space-between', padding: `0 ${rem(50)}` }}>
          <Button
            color="primary"
            variant={'outlined'}
            className={classes.createNewButton}
            onClick={() => this.setState({ createNewDivisionDialogOpen: true })}
            startIcon={<Add />}
          >
            <FormattedMessageWrapper id={'buttons.createNew'} />
          </Button>

          <CreateNewDivisionDialog
            open={this.state.createNewDivisionDialogOpen}
            onClose={this._onCreateNewDialogClose}
          />

          <TextField
            variant="standard"
            placeholder="Search by Name"
            onChange={this._updateFilter}
            value={this.state.filter}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </DialogActions>

        <DialogContent>
          <FormControl fullWidth style={{ marginTop: rem(10) }}>
            {this._divisionsTable()}
          </FormControl>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'flex-start', paddingLeft: rem(50) }}>
          <Button color="primary" variant={'contained'} onClick={this._handleDone}>
            <FormattedMessageWrapper id={'buttons.done'} />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public _divisionsTable = () => {
    const { divisions, classes } = this.props
    const includedDivisions = divisions.filter((division) =>
      division.name.toLowerCase().includes(this.state.filter.toLowerCase()),
    )
    const excludedDivisions = divisions.filter(
      (division) => !division.name.toLowerCase().includes(this.state.filter.toLowerCase()),
    )

    return (
      <Table>
        <TableHead>
          <TableRow className={classes.tableRowHeader}>
            <HeaderTableCell />
            <HeaderTableCell>
              <FormattedMessageWrapper id={'divisions.name'} />
            </HeaderTableCell>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'divisions.gender'} />
            </HeaderTableCell>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'divisions.age'} />
            </HeaderTableCell>
            <HeaderTableCell>
              <FormattedMessageWrapper id={'tournaments.hcpBadge'} />
            </HeaderTableCell>
            <HeaderTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {includedDivisions.map((division: DivisionState, index: number) => this._renderTableCells(division, index))}
          {excludedDivisions.map((division: DivisionState, index: number) =>
            this._renderTableCells(division, index, true),
          )}
        </TableBody>
      </Table>
    )
  }

  public _renderTableCells = (division: DivisionState, index: number, filteredOut?: boolean) => {
    const { classes, players } = this.props
    const { id, name, gender, minAge, maxAge, minHcp, maxHcp, type } = division
    const emDash = '\u2014'
    const gte = '\u2264'
    const values = {
      selected: (
        <TableCell key={`${index}_a_${division.id}`} style={{ fontWeight: 'bold' }}>
          <DeleteConfirm>
            {({ showConfirm }: DeleteConfirmChildren) => {
              const selectFn =
                // if division is checked and is used by any player, invoke confirm dialog
                this._isChecked(id) && players.some((player) => player.divisionId === id)
                  ? () =>
                      showConfirm({
                        callback: () => this._handleSelect(id),
                        messageId: 'strings.divisionDeselectConfirm',
                      })
                  : // otherwise just select
                    () => this._handleSelect(id)

              return (
                <Checkbox checked={this._isChecked(id)} disabled={filteredOut} onChange={selectFn} color="primary" />
              )
            }}
          </DeleteConfirm>
        </TableCell>
      ),
      name: (
        <TableCell key={`${index}_b_${division.id}`} style={{ fontWeight: 'bold' }}>
          {name || '-'}
        </TableCell>
      ),
      gender: (
        <TableCell key={`${index}_c_${division.id}`} style={{ textTransform: 'capitalize' }}>
          {gender}
        </TableCell>
      ),
      age: (
        <TableCell key={`${index}_d_${division.id}`}>
          {minAge} {minAge && maxAge && emDash}
          {((minAge && !maxAge) || (!minAge && maxAge)) && gte} {maxAge}
        </TableCell>
      ),
      hcp: (
        <TableCell key={`${index}_e_${division.id}`}>
          {minHcp && formatHandicap(String(minHcp))} {minHcp && maxHcp && emDash}
          {((minHcp && !maxHcp) || (!minHcp && maxHcp)) && gte} {maxHcp && formatHandicap(String(maxHcp))}
        </TableCell>
      ),
      type: (
        <TableCell key={`${index}_f_${division.id}`}>
          <TypeLabel disabled={filteredOut} type={type as DivisionType} />
        </TableCell>
      ),
    }
    return (
      <TableRow
        key={`${index}_${division.id}`}
        className={classNames([classes.tableRow, filteredOut && classes.filteredOut])}
      >
        {Object.values(values).map((value) => value)}
      </TableRow>
    )
  }

  public _onCreateNewDialogClose = () => {
    this.setState({ createNewDivisionDialogOpen: false })
    this.props.organizationId && this.props.fetchDivisions(this.props.organizationId)
  }

  public _updateFilter = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ filter: e.currentTarget.value })

  public _isChecked = (id: number) => !!this.state.selectedDivisions.find((savedId) => savedId === id)

  public _handleOnClose = () => this.props.onClose()

  public _handleSelect = (id: number) => {
    this._isChecked(id)
      ? this.setState({ selectedDivisions: [...this.state.selectedDivisions.filter((division) => division !== id)] })
      : this.setState({ selectedDivisions: [...this.state.selectedDivisions, id] })
  }

  public _handleDone = () => {
    this.props.updateTournamentField({
      fieldName: 'divisions',
      value: this.state.selectedDivisions,
    })
    this._handleOnClose()
  }
}

export const SelectDivisionsDialog = connect<StateProps, DispatchProps, OwnProps, RootState>(
  (store) => ({
    divisions: store.divisionsReducer.divisions,
    tournamentDivisions: selectTournament(store).divisions,
    organizationId: store.authenticationReducer.customerInfo?.idCustomer,
    players: selectTournamentPlayers(store).data?.players || [],
  }),
  {
    updateTournamentField,
    fetchDivisions,
    loadTournamentPlayers: tournamentPlayersApi.endpoints.getPlayers.initiate,
  },
)(withStyles(styles)(withRouter(SelectDivisionsDialogComponent as any)))
