import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Dialog } from '@mui/material'
import { useIntl } from 'react-intl'
import { selectClubsAndCoursesConfig, selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { getRoundStartTime } from '@app/store/api/utils/tournamentRoundUtils'
import { getCourses, setSelectedCourseVersion } from '@app/store/api/thunks/clubsAndCoursesThunks'
import { useAppDispatch } from '@app/store'
import { useUpdateTournamentRoundMutation } from '@app/store/api/endpoints/tournamentRoundApi'
import { confirm } from '@app/components/dialogs/confirm/Confirm'
import { setInitialCourseDataToNewTournament } from '@app/store/api/thunks/tournamentThunks'
import {
  selectTournamentStartLists,
  setVerifyCourseVersionAfterStartListPublish,
} from '@app/store/api/slices/tournamentStartListsSlice'

const CourseVersionUpdater: React.FC = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const tournament = useSelector(selectTournament)
  const { selectedRoundIndex } = useSelector(selectTournamentConfig)
  const { selectedClubId, selectedCourseId, selectedCourseVersion } = useSelector(selectClubsAndCoursesConfig)
  const { verifyCourseVersionAfterStartListPublish } = useSelector(selectTournamentStartLists)
  const [updateTournamentRound] = useUpdateTournamentRoundMutation()

  const showConfirmDialog = () => {
    confirm({
      message: intl.formatMessage({ id: 'tournament.courseUpdated' }),
      options: {
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      },
    })
  }

  const onCoursesComplete = (result: ClubsAndCoursesAPICallResult) => {
    const courses = result.data?.courses || []
    const currentRound = getCurrentRound()
    const differentCourseVersion = courses.find(
      (c) => c.id === currentRound.course?.id && c.version !== currentRound.course?.version,
    )

    if (selectedCourseId === -1 && courses.length > 0) {
      dispatch(setInitialCourseDataToNewTournament(courses[0]))
    }

    if (differentCourseVersion && currentRound.id > 0) {
      updateTournamentRound({
        tournamentId: tournament.id,
        body: currentRound,
        onSuccess: () => {
          dispatch(setSelectedCourseVersion(differentCourseVersion.version))
          showConfirmDialog()
        },
      })
    }
  }

  const getCurrentRound = useCallback(() => {
    return tournament.rounds[selectedRoundIndex]
  }, [tournament, selectedRoundIndex])

  const getRoundTimestamp = () => {
    const currentRound = tournament.rounds[selectedRoundIndex]
    if (currentRound) {
      return getRoundStartTime(currentRound)
    }
    return new Date().getTime() / 1000
  }

  useEffect(() => {
    if (selectedClubId === -1) {
      return
    }
    // TODO: Remove this if. This is a temporary bubble gum fix for TM-5260.
    if (!window.location.href.includes('results-and-options')) {
      const payload = {
        clubId: selectedClubId,
        timestamp: getRoundTimestamp(),
        onComplete: onCoursesComplete,
      }
      dispatch(getCourses(payload))
    }
  }, [selectedClubId, selectedRoundIndex])

  useEffect(() => {
    if (verifyCourseVersionAfterStartListPublish) {
      const currentRound = getCurrentRound()
      if (
        currentRound.course?.id === selectedCourseId &&
        currentRound.course?.version &&
        currentRound.course.version !== selectedCourseVersion
      ) {
        dispatch(setSelectedCourseVersion(currentRound.course?.version))
        showConfirmDialog()
      }
      dispatch(setVerifyCourseVersionAfterStartListPublish(false))
    }
  }, [getCurrentRound, verifyCourseVersionAfterStartListPublish])

  return <Dialog open={false}></Dialog>
}

export default CourseVersionUpdater
