import React from 'react'
import { Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { Theme } from '@mui/material'
import { useTheme } from '@mui/system'
interface GroupsProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const Groups = ({ children, style }: GroupsProps) => {
  const theme: Theme = useTheme()

  return (
    <Droppable droppableId="groupsDroppable" type="droppableGroup">
      {(dropProvided: DroppableProvided) => (
        <div
          ref={dropProvided.innerRef}
          style={{
            padding: theme.spacing(2),
            backgroundColor: theme.customPalette.blueBackground,
            height: '100%',
            minHeight: '500px',
            maxHeight: 'calc(100vh - 250px)',
            overflowY: 'auto',
            borderRadius: '4px',
            ...style,
          }}
          {...dropProvided.droppableProps}
        >
          {children}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default Groups
