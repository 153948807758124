import React from 'react'
import { injectIntl } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { withRouter, WithRouterProps } from '@app/hoc/withRouter'
import { connect } from 'react-redux'
import { getTournament } from '@app/store/api/thunks/tournamentThunks'
import { updateTournamentRoundStatus } from '@app/store/api/thunks/tournamentRoundThunks'
import { getSummaryPageEmailPreview } from '@app/store/api/thunks/tournamentStartListsThunks'
import { loadTournamentLeaderboard, LoadTournamentLeaderboardArgs } from '@store/tournamentLeaderboard/actions'
import { WrappedComponentProps } from 'react-intl'
import ContentWrap from '@components/layout/ContentWrap'
import SectionTitle from '@components/ui/SectionTitle'
import TournamentFormActions from '@components/tournament/TournamentFormActions'
import get from 'lodash/get'
import { Button, Grid, SwitchProps, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { SectionWrapper } from '@app/components/layout/SectionWrapper'
import { SettingCard, InfoCard } from '@app/components/cards'
import InfoTooltip from '@app/components/ui/InfoTooltip'
import { TournamentTypes, UrlParams } from '@app/store/api/enums/tournamentEnums'
import { MultiRoundEndRounds } from './multi-round-end-rounds'
import { SendRankingPointsDialog } from '@app/components/dialogs/sendRankingPointsDialog'
import HandicappingDialog from '@app/components/dialogs/handicappingDialog/HandicappingDialog'
import { rem } from '@app/theme/materialUITheme'
import { verifyIfRoundIsCompleted } from '@app/utils/tournamentUtils'
import { Leaderboard } from './leaderboard'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { SendEmailDialog } from '@app/components/dialogs/sendEmailDialog/sendEmailDialog'
import { updateTournamentField } from '@app/store/api/thunks/tournamentThunks'
import { tournamentStartListsEmailApi } from '@app/store/api/endpoints/tournamentStartListsEmailApi'
import { RootState } from '@app/store'
import { selectTournamentSettings, tournamentSettingsApi } from '@app/store/api/endpoints/tournamentSettingsApi'
import { selectTournamentPlayers, tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { tournamentScoringApi } from '@app/store/api/endpoints/tournamentScoringApi'
import { selectTournament } from '@app/store/api/endpoints/tournamentApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'
import { selectTournamentStartLists } from '@app/store/api/slices/tournamentStartListsSlice'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('lg')]: {
        flexWrap: 'nowrap',
      },
    },
    cardItem: {
      minWidth: '20rem',
    },
    headerWrapper: {
      margin: theme.spacing(3),
    },
    resultsAndOptionsCard: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
    },
    card1: {
      backgroundColor: '#333',
    },
    card2: {
      backgroundColor: '#1F8E81',
    },
    card3: {
      backgroundColor: '#F2F2F2',
    },
    activeText: {
      color: theme.palette.primary.main,
    },
    hiddenHolesMessage: {
      color: '#404040',
      fontWeight: 400,
    },
    hiddenHolesDescriptionContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
    },
    hiddenHolesDescription: {
      color: 'black',
      lineHeight: 1.2,
      fontSize: rem(14),
      fontWeight: 400,
    },
    blackSquare: {
      width: 12,
      height: 12,
      minWidth: 15,
      minHeight: 15,
      backgroundColor: 'black',
      borderRadius: 3,
      marginRight: 9,
    },
    scoringButton: {
      boxShadow: '0 1px 3px 0 #777 !important',
      '&.Mui-disabled': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    rankingButton: {
      boxShadow: '0 1px 3px 0 #777 !important',
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
      },
      marginTop: rem(10),
    },
  })

interface StateProps {
  tournamentSettings: TournamentSettings
  tournament: TournamentState
  tournamentStartLists: TournamentStartListState
  tournamentPlayers: TournamentPlayer[]
  tournamentConfig: TournamentConfig
  organization: OrganizationState
}

interface DispatchProps {
  getTournament(payload: TournamentPayload): void
  getTournamentSettings: (tournamentId: number) => void
  loadTournamentLeaderboard: (args: LoadTournamentLeaderboardArgs) => void
  saveTournamentSettings: (payload: UpdateTournamentSettingsPayload) => void
  updateTournamentField: (payload: FieldUpdatePayload) => void
  updateTournamentResultsPublished(payload: UpdateResultsPublishedStatusPayload): void
  updateTournamentRoundStatus(payload: UpdateRoundStatusPayload): void
  sendSummaryPageEmail: (payload: SummaryPageEmailPayload) => void
  getSummaryPageEmailPreview: (payload: SummaryPageEmailPreviewPayload) => void
  loadTournamentPlayers: (tournamentId: number) => void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & WrappedComponentProps & WithRouterProps

interface State {
  editPlayerStatsExpanded: boolean
  handicappingDialogOpen: boolean
  rankingPointsDialogOpen: boolean
  summaryPageDialog: boolean
  emailBody: string
}

class UnroutedResultsAndOptions extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      editPlayerStatsExpanded: true,
      handicappingDialogOpen: false,
      rankingPointsDialogOpen: false,
      summaryPageDialog: false,
      emailBody: '',
    }
  }

  public componentDidMount(): void {
    const id = get(this.props, 'params.id')
    const tournamentId = typeof id === 'string' ? parseInt(id, 10) : id
    if (tournamentId) {
      this.props.getTournament({ id: tournamentId })
      this.props.loadTournamentLeaderboard({ id: tournamentId })
      this.props.getTournamentSettings(tournamentId)
      this.props.loadTournamentPlayers(tournamentId)
    }
  }

  render() {
    const { classes, tournament, tournamentConfig, tournamentPlayers, organization } = this.props
    const { rankingPointsDialogOpen, summaryPageDialog } = this.state

    // TODO:PHASE:MULTIROUND Number of hidden holes should be taken from the last round,
    // alternatively this parameter should be moved outside of the rounds array
    const numHiddenHoles = tournament.rounds[0]?.hideResultsFromLastHoles || 0

    const sendRankingPointsEnabled =
      (tournament.primaryRankingConfig || tournament.sideRankingConfig) && this.isResultsPublished

    const endRoundSwitchProps: SwitchProps = {
      onChange: this._onScoringDoneChange,
      disabled: this.isStartListPublished === false || this.isResultsPublished || tournamentConfig.contestEditMode,
      checked: this.isRoundEnded,
    }

    const publishResultsSwitchProps: SwitchProps = {
      onChange: this._onPublishResultsChange,
      disabled: false || tournamentConfig.contestEditMode,
      checked: this.isResultsPublished,
    }

    const players = tournamentPlayers.map(({ id, firstName, lastName, hasEmail, email }) => ({
      id,
      name: `${firstName} ${lastName}`,
      email,
      hasEmail,
    }))

    return (
      <>
        <div className={classes.headerWrapper}>
          <Grid container className={classes.root} spacing={2}>
            {tournament.tournamentType === TournamentTypes.weekly ? (
              <Grid item xs={12} className={classes.cardItem}>
                <SettingCard
                  titleId="buttons.publishResults"
                  descriptionId={'tournament.publishResultsInfo'}
                  switchProps={publishResultsSwitchProps}
                  tooltipTitle={
                    publishResultsSwitchProps.disabled &&
                    !publishResultsSwitchProps.checked && (
                      <FormattedMessageWrapper id="tournament.switchDisabledTooltips.PublishResults" />
                    )
                  }
                  style={{ backgroundColor: '#1F8D80' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.isResultsPublished || tournamentConfig.contestEditMode}
                    className={classes.rankingButton}
                    onClick={this._onSendRankingPointsClick}
                  >
                    <FormattedMessageWrapper id="tournament.sendRankingPoints" />
                  </Button>
                  {
                    <SendRankingPointsDialog
                      tournament={tournament}
                      open={rankingPointsDialogOpen}
                      onClose={() => this.setState({ rankingPointsDialogOpen: false })}
                    />
                  }
                </SettingCard>
              </Grid>
            ) : (
              <>
                {tournament.tournamentType !== TournamentTypes.multiRound && (
                  <Grid item xs={6} lg={3} className={classes.cardItem}>
                    <SettingCard
                      className={classes.card1}
                      titleId="tournament.scoringDone"
                      descriptionId={'tournament.scoringDoneInfo'}
                      switchProps={endRoundSwitchProps}
                      tooltipTitle={
                        endRoundSwitchProps.disabled &&
                        !endRoundSwitchProps.checked && (
                          <FormattedMessageWrapper id="tournament.switchDisabledTooltips.EndRound" />
                        )
                      }
                    >
                      {tournament.hcpRound && organization.club && (
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={!this.isRoundEnded || tournamentConfig.contestEditMode}
                          onClick={this._onSendScoresForHandicappingClick}
                          className={classes.scoringButton}
                        >
                          <FormattedMessageWrapper id="buttons.sendScoresForHandicapping" />
                        </Button>
                      )}
                    </SettingCard>
                  </Grid>
                )}
                <Grid
                  item
                  xs={tournament.tournamentType === TournamentTypes.multiRound ? 12 : 6}
                  lg={tournament.tournamentType === TournamentTypes.multiRound ? 6 : 5}
                  className={classes.cardItem}
                >
                  <SettingCard
                    className={classes.card2}
                    titleId="buttons.publishResults"
                    descriptionId={'tournament.publishResultsInfo'}
                    switchProps={publishResultsSwitchProps}
                    tooltipTitle={
                      publishResultsSwitchProps.disabled &&
                      !publishResultsSwitchProps.checked && (
                        <FormattedMessageWrapper id="tournament.switchDisabledTooltips.PublishResults" />
                      )
                    }
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!sendRankingPointsEnabled || tournamentConfig.contestEditMode}
                      className={classes.rankingButton}
                      onClick={this._onSendRankingPointsClick}
                    >
                      <FormattedMessageWrapper id="tournament.sendRankingPoints" />
                    </Button>
                    <SendRankingPointsDialog
                      tournament={tournament}
                      open={rankingPointsDialogOpen}
                      onClose={() => this.setState({ rankingPointsDialogOpen: false })}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.rankingButton}
                      onClick={() =>
                        tournament.resultsPublished ? this._onShareSummaryPageClick() : this._onEditSummaryPageClick()
                      }
                    >
                      {tournament.resultsPublished ? (
                        <FormattedMessageWrapper id="tournament.shareSummaryPageBtnText" />
                      ) : (
                        <FormattedMessageWrapper id="tournament.editSummaryPageBtnText" />
                      )}
                    </Button>
                    <SendEmailDialog
                      open={summaryPageDialog}
                      onClose={this._handleEmailDialogClose}
                      textAreaTitle={<FormattedMessageWrapper id="tournament.introText" />}
                      sendEmailFn={this._sendSummaryPageEmail}
                      fetchPreviewEmailFn={this._fetchSummaryPageEmailPreview}
                      listItems={players}
                      htmlBase64={this.state.emailBody}
                    />
                  </SettingCard>
                </Grid>
                {numHiddenHoles > 0 && (
                  <Grid item xs={6} lg={4} className={classes.cardItem}>
                    <InfoCard
                      className={classes.card3}
                      cardTitle={
                        <FormattedMessageWrapper
                          id={'tournament.hiddenHolesActive'}
                          values={{
                            green: (text) => <strong className={classes.activeText}>{text}</strong>,
                          }}
                        />
                      }
                      cardDescription={
                        <>
                          <Typography variant="body2" className={classes.hiddenHolesMessage}>
                            <FormattedMessageWrapper
                              id={'tournament.hiddenHolesDescription'}
                              values={{
                                numHiddenHoles: numHiddenHoles,
                              }}
                            />
                          </Typography>
                          <div className={classes.hiddenHolesDescriptionContainer}>
                            <div className={classes.blackSquare}>&nbsp;</div>
                            <div>
                              <Typography variant="body2" className={classes.hiddenHolesDescription}>
                                <FormattedMessageWrapper id={'tournament.hiddenHolesDescription.marking'} />
                              </Typography>
                            </div>
                          </div>
                        </>
                      }
                      cardIcon={<VisibilityOffIcon />}
                    ></InfoCard>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </div>
        {tournament.tournamentType === TournamentTypes.multiRound && (
          <SectionWrapper style={{ backgroundColor: '#333' }}>
            <ContentWrap>
              <MultiRoundEndRounds />
            </ContentWrap>
          </SectionWrapper>
        )}
        <SectionWrapper>
          <ContentWrap>
            <SectionTitle>
              <FormattedMessageWrapper id={'tournament.leaderboard'} />
              <InfoTooltip
                text={<FormattedMessageWrapper id="tournament.editScoreInfo" />}
                style={{ marginLeft: rem(10) }}
              />
            </SectionTitle>

            {this.props.tournament.id && (
              <Leaderboard
                tournament={this.props.tournament}
                startlists={this.props.tournamentStartLists}
                settings={this.props.tournamentSettings}
                style={{ marginBottom: 30 }}
              />
            )}

            <TournamentFormActions
              nextLabelId={'buttons.finish'}
              onClickNext={this._clickNext}
              onClickSaveAndPublish={() => {
                this.props.saveTournamentSettings({ id: this.props.tournament.id, body: this.props.tournamentSettings })
              }}
              disableNext={tournamentConfig.contestEditMode}
            />
          </ContentWrap>

          {tournament.id && tournament.hcpRound && (
            <HandicappingDialog
              open={this.state.handicappingDialogOpen}
              onClose={() => this.setState({ handicappingDialogOpen: false })}
              tournamentId={tournament.id}
            />
          )}
        </SectionWrapper>
      </>
    )
  }

  public get isStartListPublished(): boolean {
    const { tournament } = this.props
    const round = tournament.rounds[0]
    return (round && round.status && round.status.isConfigured) || false
  }

  public get isScoringEnabled(): boolean {
    const { tournament } = this.props
    const round = tournament.rounds[0]
    return (round && round.status && round.status.isScoringDisabled === false) || false
  }

  public get isRoundEnded(): boolean {
    const { rounds } = this.props.tournament
    return verifyIfRoundIsCompleted(0, rounds)
  }

  public _clickNext = () => {
    this.props.saveTournamentSettings({
      id: this.props.tournament.id,
      body: this.props.tournamentSettings,
      onSuccess: () => this.props.navigate('/tournaments'),
    })
  }

  private _onScoringDoneChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const { tournament } = this.props
    if (tournament && tournament.id) {
      this.props.updateTournamentRoundStatus({
        tournamentId: tournament.id,
        roundId: tournament.rounds[0].id,
        body: {
          isCompleted: checked,
          ...(checked && { isScoringDisabled: true }),
        },
      })
    }
  }

  private _onPublishResultsChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const { tournament, updateTournamentResultsPublished } = this.props
    if (tournament && tournament.id) {
      updateTournamentResultsPublished({
        tournamentId: tournament.id,
        isResultsPublished: checked,
        onSuccess: () => {
          if (tournament.id) {
            //Update leaderboard to show hidden holes correctly
            this.props.loadTournamentLeaderboard({ id: tournament.id })
          }
        },
      })
    }
  }

  private _onSendScoresForHandicappingClick = () => {
    this.setState({ handicappingDialogOpen: true })
  }

  private _onSendRankingPointsClick = () => {
    this.setState({ rankingPointsDialogOpen: true })
  }

  private _onShareSummaryPageClick = () => {
    this.setState({ summaryPageDialog: true })
  }

  private _onEditSummaryPageClick = () => {
    this.props.navigate(`/tournaments/${this.props.tournament.id}/tournament-site?${UrlParams.EDIT_SUMMARY}`)
  }

  private _sendSummaryPageEmail = (language, tournamentPlayerIds, introText) => {
    const { tournament, intl } = this.props
    const tournamentId = tournament.id
    const body = {
      language,
      introText,
      tournamentPlayerIds,
    }
    const successMessage = intl.formatMessage({ id: 'tournament.emailsSendSuccessfullyText' })
    const errorMessage = intl.formatMessage({ id: 'errors.sendEmailError' })
    this.props.sendSummaryPageEmail({ tournamentId, body, successMessage, errorMessage })
    this._handleEmailDialogClose()
  }

  private _fetchSummaryPageEmailPreview = (language: string) => {
    const { tournament } = this.props
    const tournamentId = tournament.id
    this.props.getSummaryPageEmailPreview({
      tournamentId,
      language,
      onComplete: (emailBody: string) => {
        this.setState({ emailBody })
      },
    })
  }

  private _handleEmailDialogClose = () => {
    this.setState({ summaryPageDialog: false })
  }

  private get isResultsPublished(): boolean {
    const { tournament } = this.props
    return tournament.resultsPublished || false
  }

  public get _rounds(): Round[] {
    const { tournament } = this.props
    return get(tournament, 'rounds')
  }
}

const RoutedResultsAndOptions = withRouter(UnroutedResultsAndOptions)
const LocalizedResultsAndOptions = injectIntl(RoutedResultsAndOptions)
const StyledResultsAndOptions = withStyles(styles)(LocalizedResultsAndOptions)

export const ResultsAndOptions = connect<StateProps, DispatchProps, {}, RootState>(
  (state): StateProps => {
    return {
      tournamentSettings: selectTournamentSettings(state),
      tournament: selectTournament(state),
      tournamentStartLists: selectTournamentStartLists(state),
      tournamentPlayers: selectTournamentPlayers(state).data?.players || [],
      tournamentConfig: selectTournamentConfig(state),
      organization: state.organizationReducer,
    }
  },
  {
    getTournament,
    getTournamentSettings: tournamentSettingsApi.endpoints.getTournamentSettings.initiate,
    loadTournamentLeaderboard,
    saveTournamentSettings: tournamentSettingsApi.endpoints.updateTournamentSettings.initiate,
    updateTournamentField,
    updateTournamentResultsPublished: tournamentScoringApi.endpoints.updateResultsPublishedStatus.initiate,
    updateTournamentRoundStatus,
    sendSummaryPageEmail: tournamentStartListsEmailApi.endpoints.sendSummaryPageEmail.initiate,
    getSummaryPageEmailPreview,
    loadTournamentPlayers: tournamentPlayersApi.endpoints.getPlayers.initiate,
  },
)(StyledResultsAndOptions as any)
