import React from 'react'
import { FormControl, MenuItem } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import find from 'lodash/find'
import CustomQuestionAnswer from './CustomQuestionAnswer'
import {
  tournamentPlayersChangeExtraInfo,
  tournamentPlayersChangeSort,
} from '@store/api/thunks/tournamentPlayersThunks'
import { ExtraInfo } from '@app/store/api/enums/tournamentEnums'
import { formatDate } from '@utils/dates'
import { Select } from '../forms/Select'
import { RootState } from '@app/store'
import { selectTournamentSite } from '@app/store/api/endpoints/tournamentSiteApi'
import { selectTournamentConfig } from '@app/store/api/slices/configSlice'

interface OwnProps {
  disabled?: boolean
  showTeebox?: boolean
  style?: React.CSSProperties
  default?: string
  className?: string
}

interface StateProps {
  playerExtraInfo: string
  customQuestionsEnabled: boolean
  customQuestions: Question[]
}

interface DispatchProps {
  tournamentPlayersChangeExtraInfo(value: string): void
  tournamentPlayersChangeSort: (sortBy: PoolOrderBy) => void
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>

const styles = () =>
  createStyles({
    extraInfoContainer: {
      maxWidth: '90%',
      padding: 0,
      margin: 0,
      border: 'none',
    },
  })

class ExtraInfoSelect extends React.Component<Props> {
  componentDidMount() {
    if (this.props.default) {
      this.props.tournamentPlayersChangeExtraInfo(this.props.default)
    }
  }

  render() {
    const { disabled = false, style = {}, className, classes } = this.props

    return (
      <FormControl className={classes.extraInfoContainer}>
        <Select
          noBorder
          readOnly={disabled}
          style={{ fontWeight: 'normal', textTransform: 'uppercase', ...style }}
          value={this.props.playerExtraInfo}
          className={className}
          onChange={this._onChange}
        >
          <MenuItem value={ExtraInfo.HOME_CLUB}>
            <FormattedMessageWrapper id={'options.homeClub'} />
          </MenuItem>
          <MenuItem value={ExtraInfo.GENDER}>
            <FormattedMessageWrapper id={'options.gender'} />
          </MenuItem>
          <MenuItem value={ExtraInfo.YEAR_OF_BIRTH}>
            <FormattedMessageWrapper id={'options.yearOfBirth'} />
          </MenuItem>
          <MenuItem value={ExtraInfo.ENTRY_TIME}>
            <FormattedMessageWrapper id={'options.entryTime'} />
          </MenuItem>
          <MenuItem value={ExtraInfo.DIVISION}>
            <FormattedMessageWrapper id={'options.division'} />
          </MenuItem>
          {this._renderCustomQuestions()}
        </Select>
      </FormControl>
    )
  }

  public get _preLabel() {
    return (
      <>
        <FormattedMessageWrapper id={'tournament.extraInfo'} />
        &nbsp;-&nbsp;
      </>
    )
  }

  public _onChange = (event) => {
    const { tournamentPlayersChangeExtraInfo, tournamentPlayersChangeSort } = this.props
    const { value } = event.target

    if (typeof value === 'string') {
      tournamentPlayersChangeExtraInfo(value)
    }

    tournamentPlayersChangeSort(value)
  }

  private _renderCustomQuestions() {
    const { customQuestionsEnabled, customQuestions } = this.props

    if (!customQuestionsEnabled) {
      return null
    }

    return customQuestions.map(this._renderCustomQuestionOption)
  }

  private _renderCustomQuestionOption = (customQuestion: Question, index: number) => (
    <MenuItem value={`customQuestion:${customQuestion.id}`} key={customQuestion.id}>
      Q{index + 1}:&nbsp;{customQuestion.question}
    </MenuItem>
  )
}

//TODO: Refactor. Remove lodash and restore types
export const getExtraInfo = (player: TournamentPlayer, playerExtraInfo, customQuestionsIdMap, units, divisions) => {
  let extraInfo: any = ''

  switch (playerExtraInfo) {
    case ExtraInfo.EMAIL: {
      extraInfo = player.email || ''
      break
    }
    case ExtraInfo.HOME_CLUB: {
      extraInfo = player.club?.name || ''
      break
    }
    case ExtraInfo.GENDER: {
      const gender = player.gender || ''
      if (gender) {
        extraInfo = <FormattedMessageWrapper id={`options.${gender}`} />
      }
      break
    }
    case ExtraInfo.YEAR_OF_BIRTH: {
      const dateOfBirth = player.dateOfBirth
      if (dateOfBirth) {
        extraInfo = formatDate(dateOfBirth, 'year', units)
      }
      break
    }
    case ExtraInfo.ENTRY_TIME: {
      const entryTime = player.entryTime
      if (entryTime) {
        extraInfo = formatDate(entryTime, 'datetime', units)
      }
      break
    }
    case ExtraInfo.TEEBOX: {
      extraInfo = player.preferredTeeBox?.teeboxName || undefined
      break
    }
    case ExtraInfo.DIVISION: {
      extraInfo = divisions?.find((division: DivisionState) => division.id === player.divisionId)?.name
      break
    }
    default: {
      if (playerExtraInfo.substr(0, 14) === 'customQuestion') {
        const questionId = playerExtraInfo.split(':')[1]
        if (questionId) {
          const customQuestion = customQuestionsIdMap[questionId]
          const questionAnswer = find(player.customQuestionAnswers, {
            registrationQuestionId: parseInt(questionId, 10),
          })
          extraInfo = customQuestion && (
            <span>
              Q:&nbsp;
              <CustomQuestionAnswer readOnly={true} question={customQuestion} questionAnswer={questionAnswer} />
            </span>
          )
        }
      }
    }
  }

  return extraInfo
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    playerExtraInfo: selectTournamentConfig(state).playerExtraInfo,
    customQuestionsEnabled: selectTournamentSite(state).customQuestionsEnabled,
    customQuestions: selectTournamentSite(state).customQuestions,
  }),
  {
    tournamentPlayersChangeExtraInfo,
    tournamentPlayersChangeSort,
  },
)(withStyles(styles)(ExtraInfoSelect as any))
