import { Action } from 'redux'
import {
  DIVISIONS_FETCH,
  DIVISIONS_SORT,
  DIVISION_CREATE,
  DIVISION_DELETE,
  DIVISION_FETCH,
  DIVISION_UPDATE,
  SET_SELECTED_DIVISION,
} from './constants'

/**
 * FETCH ALL
 */

export interface DivisionsFetchSuccessAction extends Action, SuccessAction {
  type: 'DIVISIONS_FETCH_SUCCESS'
  divisions: DivisionState[]
}

export interface DivisionsFetchFailureAction extends Action, ErrorAction {
  type: 'DIVISIONS_FETCH_FAILURE'
  error: ErrorPayload
}

export interface DivisionsFetchAction extends Action {
  type: 'DIVISIONS_FETCH'
  organizationId: number
}

export const fetchDivisions = (organizationId: number): DivisionsFetchAction => {
  return {
    type: DIVISIONS_FETCH,
    organizationId,
  }
}

/**
 * FETCH ONE
 */

export interface DivisionFetchSuccessAction extends Action, SuccessAction {
  type: 'DIVISION_FETCH_SUCCESS'
  division: DivisionState
}

export interface DivisionFetchFailureAction extends Action, ErrorAction {
  type: 'DIVISION_FETCH_FAILURE'
  error: ErrorPayload
}

export interface DivisionFetchAction extends Action {
  type: 'DIVISION_FETCH'
  organizationId: number
  divisionId: number
  onSuccess: (division: DivisionState) => void
}

export const fetchDivision = (
  organizationId: number,
  divisionId: number,
  onSuccess: (division: DivisionState) => void,
): DivisionFetchAction => {
  return {
    type: DIVISION_FETCH,
    organizationId,
    divisionId,
    onSuccess,
  }
}

/**
 * CREATE
 */

export interface DivisionCreateSuccessAction extends Action, SuccessAction {
  type: 'DIVISION_CREATE_SUCCESS'
}

export interface DivisionCreateFailureAction extends Action, ErrorAction {
  type: 'DIVISION_CREATE_FAILURE'
  error: ErrorPayload
}

export interface DivisionCreateAction extends Action {
  type: 'DIVISION_CREATE'
  organizationId: number
  division: DivisionPayload
  onSuccess: () => void
}

export const createDivision = (
  organizationId: number,
  division: DivisionPayload,
  onSuccess: () => void,
): DivisionCreateAction => {
  return {
    type: DIVISION_CREATE,
    organizationId,
    division,
    onSuccess,
  }
}

/**
 * UPDATE
 */

export interface DivisionUpdateSuccessAction extends Action, SuccessAction {
  type: 'DIVISION_UPDATE_SUCCESS'
}

export interface DivisionUpdateFailureAction extends Action, ErrorAction {
  type: 'DIVISION_UPDATE_FAILURE'
  error: ErrorPayload
}

export interface DivisionUpdateAction extends Action {
  type: 'DIVISION_UPDATE'
  organizationId: number
  division: DivisionState
  onSuccess: () => void
}

export const updateDivision = (
  organizationId: number,
  division: DivisionState,
  onSuccess: () => void,
): DivisionUpdateAction => {
  return {
    type: DIVISION_UPDATE,
    organizationId,
    division,
    onSuccess,
  }
}

/**
 * DELETE
 */

export interface DivisionDeleteSuccessAction extends Action, SuccessAction {
  type: 'DIVISION_DELETE_SUCCESS'
}

export interface DivisionDeleteFailureAction extends Action, ErrorAction {
  type: 'DIVISION_DELETE_FAILURE'
  error: ErrorPayload
}

export interface DivisionDeleteAction extends Action {
  type: 'DIVISION_DELETE'
  organizationId: number
  divisionId: number
}

export const deleteDivision = (organizationId: number, divisionId: number): DivisionDeleteAction => {
  return {
    type: DIVISION_DELETE,
    organizationId,
    divisionId,
  }
}

/**
 * SORT
 */

export interface DivisionsSortAction extends Action {
  type: 'DIVISIONS_SORT'
  sortBy: string
  sortDirection: 'desc' | 'asc'
}

export const sortDivisions = (sortBy: string, sortDirection: 'desc' | 'asc'): DivisionsSortAction => {
  return {
    type: DIVISIONS_SORT,
    sortBy,
    sortDirection,
  }
}

/**
 * SELECTED DIVISION
 */

export interface SetSelectedDivisionAction extends Action {
  type: 'SET_SELECTED_DIVISION'
  divisionId: number
}

export const setSelectedDivision = (divisionId: number): SetSelectedDivisionAction => ({
  type: SET_SELECTED_DIVISION,
  divisionId,
})
