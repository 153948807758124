import React, { useEffect } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Theme,
  CircularProgress,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrganizationLicenseHistory, fetchOrganizationSubscriptionHistory } from '@store/organization/actions'
import { FormattedMessage } from 'react-intl'
import makeStyles from '@mui/styles/makeStyles'
import { formatDate, formatDayUTC } from '@utils/dates'
import { licenseName, productName } from '@utils/organizationUtils'
import { DialogView } from './LicenseSubscriptionStatusDialog'
import { RootState } from '@app/store'

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    maxHeight: 350,
  },
  tableHeaderCell: {
    color: 'white',
    backgroundColor: theme.customPalette.darkGreen,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface OwnProps {
  organizationId?: number
  activeView: DialogView
}

export const LicenseSubscriptionHistoryView: React.FC<OwnProps> = ({ organizationId, activeView }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { history, subHistory, loading } = useSelector((store: RootState) => store.organizationReducer)
  const { units } = useSelector((store: RootState) => store.authenticationReducer)

  useEffect(() => {
    if (organizationId) {
      switch (activeView) {
        case DialogView.VIEW_LICENSE_HISTORY:
          dispatch(fetchOrganizationLicenseHistory(organizationId))
          break
        case DialogView.VIEW_SUBSCRIPTION_HISTORY:
          dispatch(fetchOrganizationSubscriptionHistory(organizationId))
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderHeaderRow = (activeView: DialogView) => {
    return activeView === DialogView.VIEW_LICENSE_HISTORY ? (
      <TableRow>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.productName" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.initialBalance" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.balanceChange" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.balanceResult" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.notes" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.createdAt" />
        </TableCell>
      </TableRow>
    ) : (
      <TableRow>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.productName" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.subscriptionValidFrom" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.subscriptionValidTo" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.notes" />
        </TableCell>
        <TableCell className={classes.tableHeaderCell}>
          <FormattedMessage id="organizations.createdAt" />
        </TableCell>
      </TableRow>
    )
  }

  const renderBodyRows = (activeView: DialogView) => {
    if (activeView === DialogView.VIEW_LICENSE_HISTORY) {
      if (!history.length) {
        return <></>
      }
      const sortedHistory = history.sort((h1, h2) => {
        return h1.created < h2.created ? 1 : -1
      })
      return sortedHistory.map((license, i) => (
        <TableRow key={i}>
          <TableCell>{licenseName}</TableCell>
          <TableCell>{license.balanceInitial}</TableCell>
          <TableCell>{license.balanceChange}</TableCell>
          <TableCell>{license.balanceResult}</TableCell>
          <TableCell>{license.notes}</TableCell>
          <TableCell>{formatDate(license.created, 'datetime', units)}</TableCell>
        </TableRow>
      ))
    } else {
      if (!subHistory.length) {
        return <></>
      }
      const sortedHistory = subHistory.sort((h1, h2) => {
        return h1.created < h2.created ? 1 : -1
      })
      return sortedHistory.map((subscription, i) => (
        <TableRow key={i}>
          <TableCell>{productName}</TableCell>
          <TableCell>{formatDayUTC(new Date(subscription.validFrom))}</TableCell>
          <TableCell>{formatDayUTC(new Date(subscription.validTo))}</TableCell>
          <TableCell>{subscription.notes}</TableCell>
          <TableCell>{formatDate(subscription.created, 'datetime', units)}</TableCell>
        </TableRow>
      ))
    }
  }

  return (
    <Paper elevation={3}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>{renderHeaderRow(activeView)}</TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className={classes.spinner}>
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              renderBodyRows(activeView)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
