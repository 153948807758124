import { GameFormatTypes } from '@app/store/api/enums/tournamentEnums'
import { getSelectedRound } from '@app/store/api/selectors/tournamentSelectors'
import { Theme, Tab, Tabs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { rem } from '@theme/materialUITheme'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    marginBottom: 0,
    '& .MuiTab-root': {
      textTransform: 'none',
      fontFamily: theme.fonts.primary,
      fontSize: rem(16),
      background: theme.palette.grey['300'],
      color: '#333',
      borderRadius: '10px 10px 0 0',
      marginRight: '3px',
      padding: '12px 22px',
      '&.Mui-selected': {
        background: theme.customPalette.darkGreen,
        color: theme.palette.common.white,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.customPalette.darkGreen,
    },
  },
}))

interface LeaderBoardTabsProps {
  completed: boolean
  sideGameEnabled: boolean
  contestsEnabled: boolean
  gameType: GameFormatTypes
  onGameTypeChange: (gameType: GameFormatTypes) => void
  isTeamFormat: boolean
}

export const LeaderboardTabs = (props: LeaderBoardTabsProps) => {
  const { gameType, onGameTypeChange, sideGameEnabled, contestsEnabled } = props

  const classes = useStyles()
  const intl = useIntl()
  const selectedRound = useSelector(getSelectedRound)

  const primaryGameName = selectedRound?.primaryGame?.name || intl.formatMessage({ id: 'tournament.primaryGame' })
  const sideGameName =
    sideGameEnabled && selectedRound?.sideGame
      ? selectedRound.sideGame.name
      : intl.formatMessage({ id: 'tournament.sideGame' })

  const visibleTabs = useMemo(() => {
    const tabs: any[] = [
      {
        id: 'primary',
        value: 'primary',
        label: primaryGameName,
      },
    ]

    if (sideGameEnabled) {
      tabs.push({
        id: 'side-game',
        value: 'side',
        label: sideGameName,
      })
    }

    if (contestsEnabled) {
      tabs.push({
        id: 'contests',
        value: 'contests',
        label: intl.formatMessage({ id: 'tournament.contests' }),
      })
    }

    return tabs
  }, [primaryGameName, sideGameEnabled, contestsEnabled, sideGameName, intl])

  const onTabChange = (_: any, value: GameFormatTypes): void => {
    onGameTypeChange(value)
  }

  return (
    <Tabs
      value={gameType}
      onChange={onTabChange}
      variant="standard"
      scrollButtons={false}
      indicatorColor="primary"
      textColor="primary"
      className={classes.tabs}
    >
      {visibleTabs.map((tab) => (
        <Tab key={tab.id} value={tab.value} label={tab.label} />
      ))}
    </Tabs>
  )
}
