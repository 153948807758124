import { API_ROOT, APIRoute } from '@app/config'
import { getToken } from '../authentication/sagas'
import { apiClientRequest } from '@utils/sagaHelpers'
import { all, put, select, takeEvery } from 'redux-saga/effects'
import {
  FETCH_REPORTS,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_SUCCESS,
  FETCH_WEEKLY_REPORTS,
  FETCH_WEEKLY_REPORTS_FAILURE,
  FETCH_WEEKLY_REPORTS_SUCCESS,
} from './constans'
import { FetchReportsAction, FetchWeeklyReportsAction } from './actions'
import { RootState } from '..'

const getAuth = (store: RootState) => store.authenticationReducer

function* doFetchReports({ startDate, endDate }: FetchReportsAction) {
  try {
    const token = yield getToken()
    const auth = yield select(getAuth)
    const { organizationId } = auth.roleInfo

    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_REPORTS(organizationId)}`,
      method: 'GET',
      token,
      params: {
        startDate,
        endDate,
      },
    })

    yield put({
      type: FETCH_REPORTS_SUCCESS,
      reports: response.data,
    })
  } catch (error) {
    yield put({
      type: FETCH_REPORTS_FAILURE,
    })
  }
}

function* doFetchWeeklyReports({ startDate, endDate }: FetchWeeklyReportsAction) {
  try {
    const token = yield getToken()
    const auth = yield select(getAuth)
    const { organizationId } = auth.roleInfo

    const response = yield apiClientRequest({
      url: `${API_ROOT}${APIRoute.GET_WEEKLY_REPORTS(organizationId)}`,
      method: 'GET',
      token,
      params: {
        startDate,
        endDate,
      },
    })

    yield put({
      type: FETCH_WEEKLY_REPORTS_SUCCESS,
      reports: response.data,
    })
  } catch (error) {
    yield put({
      type: FETCH_WEEKLY_REPORTS_FAILURE,
    })
  }
}

export function* watchReports() {
  yield all([takeEvery(FETCH_REPORTS, doFetchReports)])
  yield all([takeEvery(FETCH_WEEKLY_REPORTS, doFetchWeeklyReports)])
}
