/**
 * No functionality yet, added in TM-3573
 */
export const formatOutputNumber = (value: number) => value

/**
 * Format number for input
 * @param value
 * @returns number
 */
export const formatInputNumber = (value: number | string) => parseFloat(String(value).replace(',', '.'))

export const isValueNumeric = (value: string) => {
  return !isNaN(Number(value))
}
