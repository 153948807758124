import { api, HttpMethod } from '../baseApi'
import { APIRoute } from '../../../config'

export const tournamentContestApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendMeasuredContestResults: build.mutation<void, MeasuredContestPayload>({
      query: (payload) => ({
        url: APIRoute.SEND_MEASURED_CONTEST_RESULTS(payload.tournamentId, payload.roundId, payload.holeNumber),
        method: HttpMethod.PUT,
        body: payload.body,
      }),
    }),
    sendNonMeasuredContestResults: build.mutation<void, NonMeasuredContestPayload>({
      query: (payload) => ({
        url: APIRoute.SEND_NON_MEASURED_CONTEST_RESULTS(payload.tournamentId, payload.roundId, payload.holeNumber),
        method: HttpMethod.PUT,
        body: payload.body,
      }),
    }),
    deleteContestResult: build.mutation<void, DeleteContestResultPayload>({
      query: (payload) => ({
        url: APIRoute.DELETE_CONTEST_RESULT(
          payload.tournamentId,
          payload.roundId,
          payload.holeNumber,
          payload.playerId,
        ),
        method: HttpMethod.DELETE,
      }),
    }),
  }),
})

export const {
  useSendMeasuredContestResultsMutation,
  useSendNonMeasuredContestResultsMutation,
  useDeleteContestResultMutation,
} = tournamentContestApi
