import withStyles from '@mui/styles/withStyles'
import TableCell from '@mui/material/TableCell'

export const VeryDenseTableCell = withStyles({
  root: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 0,
    paddingLeft: 8,
  },
})(TableCell)
