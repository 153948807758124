import React from 'react'
import { Theme, Toolbar, Typography, Button } from '@mui/material'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import NavToolbarProfile from './NavToolbarProfile'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { NavLink } from 'react-router-dom'
import LanguageSwitcher from './LanguageSwitcher'
import { RootState } from '@app/store'

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: 0,
      paddingRight: 0,
      height: 64,
      background: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      marginLeft: 32,
    },
    loginBtn: {
      color: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.primary.contrastText}`,
      marginRight: 10,
    },
  })

interface OwnProps {
  titleId?: string
  title?: string
}

interface StateProps {
  auth: AuthenticationState
}

type Props = StateProps & WithStyles<typeof styles> & OwnProps & WrappedComponentProps

class NavToolbar extends React.Component<Props> {
  render(): React.ReactElement<any> | null {
    const { classes, titleId, title } = this.props

    return (
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          {(titleId || title) && (
            <Typography variant={'h1'}>
              {titleId && <FormattedMessageWrapper id={titleId} />}
              {title && <span>{title}</span>}
            </Typography>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LanguageSwitcher />

          {this._renderUser()}
        </div>
      </Toolbar>
    )
  }

  get _loginLink() {
    return React.forwardRef((props: any, ref: any) => <NavLink to={'/login'} ref={ref} {...props} />)
  }

  private _renderUser = (): React.ReactNode => {
    const { auth } = this.props
    return auth.loggedIn ? <NavToolbarProfile /> : this._renderLogIn()
  }

  private _renderLogIn = (): React.ReactNode => {
    return (
      <Button component={this._loginLink} className={this.props.classes.loginBtn}>
        <FormattedMessageWrapper id={'login.login'} />
      </Button>
    )
  }
}

const StyledNavToolbar = withStyles(styles)(NavToolbar)
export default connect<StateProps, {}, {}, RootState>((state) => ({
  auth: state.authenticationReducer,
}))(injectIntl(StyledNavToolbar))
