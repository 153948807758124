// LIBRARIES
import React from 'react'
import { Grid, MenuItem, Select, TextField, FormLabel, Collapse, OutlinedInput, FormControl } from '@mui/material'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'

// COMPONENTS
import SectionTitle from '@components/ui/SectionTitle'
import SectionTitleWithCollapse from '@components/ui/SectionTitleWithCollapse'
import BorderButton from '@components/ui/BorderButton'
import InfoTooltip from '@components/ui/InfoTooltip'

// ACTIONS
import { addTempPlayer } from '@store/players/actions'
import { trimHcpDecimals, validateHandicapValue } from '@utils/playerUtils'
import { Placeholder } from '@scenes/tournament/utils'
import { isEmailValid } from '@utils/validateEmail'
import { RootState } from '@app/store'
import { CacheTag } from '@app/store/api/cacheTags'
import { api } from '@app/store/api/baseApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import ExcelImportPlayers from '@app/components/tournament/ExcelImportPlayers'
import { SV_COUNTRY_ID } from '@app/config'

interface StateProps {
  tournamentId?: number
  isSweClubOrg: boolean
}

interface DispatchProps {
  addTempPlayer: (player: TempPlayer, onComplete: (success, error?) => void) => void
  invalidateCache: (tags: CacheTag[]) => void
}

type Props = StateProps & DispatchProps

interface State {
  formInputData: {
    firstName: string
    lastName: string
    gender: string
    handicap: number | string
    email: string
  }
  expanded: boolean
  validationError: {
    firstName: boolean
    lastName: boolean
    gender: boolean
    handicap: boolean
    email: boolean
  }
}

enum validationTypes {
  STRING = 'string',
  HANDICAP = 'handicap',
  EMAIL = 'email',
}

const initialState: State = {
  formInputData: {
    firstName: '',
    lastName: '',
    gender: '',
    handicap: '',
    email: '',
  },
  expanded: false,
  validationError: {
    firstName: false,
    lastName: false,
    handicap: false,
    gender: false,
    email: false,
  },
}

class AddTempPlayerComponent extends React.Component<Props, State> {
  readonly state: State = initialState

  public render() {
    const { expanded } = this.state

    return (
      <>
        <SectionTitleWithCollapse expanded={expanded} onChange={(expanded) => this.setState({ expanded })}>
          <SectionTitle>
            <FormattedMessageWrapper id={this._getTitle()} />
            <InfoTooltip
              text={<FormattedMessageWrapper id="tournament.unregisteredPlayerInfo" />}
              style={{ marginLeft: 10 }}
            />
          </SectionTitle>
        </SectionTitleWithCollapse>
        <Collapse in={expanded}>
          {this._renderForm()}
          <ExcelImportPlayers />
        </Collapse>
      </>
    )
  }

  private _getTitle = () => {
    return this.props.isSweClubOrg ? 'tournament.addUnregisteredOrGitPlayers' : 'tournament.addUnregisteredPlayers'
  }

  private _renderForm = () => {
    const { firstName, lastName, gender, handicap, email } = this.state.formInputData

    return (
      <Grid container spacing={2}>
        <Grid item xs>
          <FormControl fullWidth>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.firstName" />
            </FormLabel>
            <TextField
              tabIndex={1}
              name="firstName"
              error={this.state.validationError.firstName}
              type="text"
              id="firstName"
              variant="outlined"
              margin="none"
              onChange={this._onChange}
              value={firstName}
            />
          </FormControl>
        </Grid>

        <Grid item xs>
          <FormControl fullWidth>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.lastName" />
            </FormLabel>
            <TextField
              tabIndex={2}
              name="lastName"
              error={this.state.validationError.lastName}
              type="text"
              id="lastName"
              variant="outlined"
              margin="none"
              onChange={this._onChange}
              value={lastName}
            />
          </FormControl>
        </Grid>

        <Grid item xs>
          <FormControl error={this.state.validationError.gender} fullWidth>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.gender" />
            </FormLabel>
            <Select
              tabIndex={3}
              name="gender"
              id="gender"
              value={gender}
              input={<OutlinedInput name="gender" id="gender" />}
              onChange={this._onSelectChange}
              displayEmpty
              fullWidth
              renderValue={
                gender.length > 0
                  ? undefined
                  : () => (
                      <Placeholder>
                        <FormattedMessageWrapper id="tournament.chooseGender" />
                      </Placeholder>
                    )
              }
            >
              <MenuItem value="" style={{ fontStyle: 'italic' }}>
                <FormattedMessageWrapper id="tournament.chooseGender" />
              </MenuItem>
              <MenuItem value={'male'}>
                <FormattedMessageWrapper id={'options.male'} />
              </MenuItem>
              <MenuItem value={'female'}>
                <FormattedMessageWrapper id={'options.female'} />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={1}>
          <FormControl fullWidth>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.hcp" />
            </FormLabel>
            <TextField
              tabIndex={4}
              name="handicap"
              error={this.state.validationError.handicap}
              id="handicap"
              variant="outlined"
              margin="none"
              onChange={this._onChange}
              value={handicap}
            />
          </FormControl>
        </Grid>

        <Grid item xs>
          <FormControl fullWidth>
            <FormLabel>
              <FormattedMessageWrapper id="tournament.email" />
            </FormLabel>
            <TextField
              tabIndex={5}
              name="email"
              error={this.state.validationError.email}
              type="email"
              id="email"
              variant="outlined"
              margin="none"
              onChange={this._onChange}
              value={email}
            />
          </FormControl>
        </Grid>

        <Grid item xs style={{ alignSelf: 'flex-end' }}>
          <BorderButton
            filled
            buttonProps={{
              onClick: this._handleSubmit,
              disabled: this._isAddDisabled(),
              style: { padding: '10px 31px' },
            }}
          >
            <FormattedMessageWrapper id={'buttons.add'} />
          </BorderButton>
        </Grid>
      </Grid>
    )
  }

  private _isAddDisabled = () => {
    // These fields must contain data
    const requiredFields =
      this.state.formInputData.email !== '' ? ['email'] : ['firstName', 'lastName', 'gender', 'handicap']

    // Check if any required field is empty
    const isEachRequiredFieldFilled = !requiredFields
      .map((item) => this.state.formInputData[item] === '')
      .some((item) => item)

    // Check that data entered in all fields is valid
    const isEachFieldValidated = !Object.values(this.state.validationError).some((item) => item)

    // Disable button if either check returns false
    return !(isEachRequiredFieldFilled && isEachFieldValidated)
  }

  private _handleSubmit = () => {
    let values = {}

    // Only send values that are not empty strings
    for (const key in this.state.formInputData) {
      if (this.state.formInputData[key]) {
        values = { ...values, [key]: this.state.formInputData[key] }
      }
    }

    this.props.addTempPlayer(values, this._handleApiComplete)
  }

  private _handleApiComplete = (success, error) => {
    if (error) {
      return
    }

    this.setState({ ...initialState, expanded: true })

    const { tournamentId } = this.props
    if (tournamentId) {
      this.props.invalidateCache([CacheTag.TOURNAMENT_PLAYERS])
    }
  }

  private _onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    const updatedValue = name === 'handicap' ? trimHcpDecimals(value) : value
    this.setState({
      formInputData: {
        ...this.state.formInputData,
        [name]: updatedValue,
      } as any,
    })

    let $validationType: validationTypes

    switch (name) {
      case 'handicap':
        $validationType = validationTypes.HANDICAP
        break
      case 'email':
        $validationType = validationTypes.EMAIL
        break
      default:
        $validationType = validationTypes.STRING
    }
    this.validateFormFields(name, updatedValue, $validationType)
  }

  private _onSelectChange = (event) => {
    this.setState({
      formInputData: {
        ...this.state.formInputData,
        [event.target.name]: event.target.value,
      } as any,
    })

    this.validateFormFields(event.target.name, String(event.target.value), validationTypes.STRING)
  }

  private validateFormFields = (fieldName: string, fieldValue: string, validationType: validationTypes) => {
    let isNotValid = false

    switch (validationType) {
      case 'string': {
        isNotValid =
          typeof fieldValue !== 'string' || (fieldValue.trim() === '' && this.state.formInputData.email.trim() === '')
        break
      }
      case 'handicap': {
        const testResult = validateHandicapValue(fieldValue)
        isNotValid = fieldValue !== '' && !testResult.valid
        break
      }
      case 'email': {
        isNotValid = fieldValue !== '' && !isEmailValid(fieldValue)
        break
      }
      default: {
        return
      }
    }

    if (this.state.validationError[fieldName] !== isNotValid) {
      this.setState({
        validationError: {
          ...this.state.validationError,
          [fieldName]: isNotValid,
        },
      })
    }

    return isNotValid
  }
}

export const AddTempPlayer = connect<StateProps, DispatchProps, {}, RootState>(
  (store): StateProps => ({
    tournamentId: getTournamentId(store),
    isSweClubOrg: store.organizationReducer.club?.countryId === SV_COUNTRY_ID,
  }),
  { addTempPlayer, invalidateCache: api.util.invalidateTags },
)(AddTempPlayerComponent)
