import React from 'react'
import SectionTitle from '../../components/ui/SectionTitle'
import { FormattedMessageWrapper } from '@app/components/ui/FormattedMessageWrapper'
import { connect } from 'react-redux'
import { Divider, Grid, createStyles } from '@mui/material'
import BorderButton from '../ui/BorderButton'
import { API_ROOT, APIRoute, getDownloadMimeType } from '../../config'
import download from 'downloadjs'
import { CloudUpload, Cached } from '@mui/icons-material'
import FilePicker from '../ui/FilePicker'
import { OverlayLoader } from '../ui/OverlayLoader'
import PlayerImportPreviewDialog from '../dialogs/playerImportPreviewDialog/PlayerImportPreviewDialog'
import { enqueueNotification } from '../../store/notifications/actions'
import InfoTooltip from '../ui/InfoTooltip'
import { GitPlayerImportDialog } from '@app/components/dialogs/gitPlayerImportDialog/GitPlayerImportDialog'
import { SV_COUNTRY_ID } from '@app/config'
import { storageTournamentId } from '@app/utils/tournamentUtils'
import { setGitDialogOpen } from '@app/store/api/slices/configSlice'
import { RootState } from '@app/store'
import { tournamentPlayersApi } from '@app/store/api/endpoints/tournamentPlayersApi'
import { getTournamentId } from '@app/store/api/slices/configSlice'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'

const styles = () =>
  createStyles({
    container: {
      marginBottom: 30,
    },
    lightDivider: {
      margin: '50px 0 30px',
      backgroundColor: '#CFCFCF',
    },
    infoTooltip: {
      marginLeft: 10,
    },
    icon: {
      marginLeft: 10,
    },
  })

// eslint-disable-next-line max-len
const ACCEPT_EXCEL_FILES = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel`

interface StateProps {
  tournamentId?: number
  authToken: string | null
  countryId: number
  isClub: boolean
}

interface DispatchProps {
  importPlayersExcelPreview(payload: ImportPlayerPreviewPayload): void
  enqueueNotification(error: any, variant?: string): void
  setGitDialogOpen(openGitDialog: boolean): void
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles>

interface State {
  downloadingTemplate: boolean
  importLoading: boolean
  previewDialogOpen: boolean
  playerPreviews?: ImportPlayerPreviews
  excelUploadFile?: File
}

class ExcelImportPlayers extends React.Component<Props, State> {
  readonly state: State = {
    downloadingTemplate: false,
    importLoading: false,
    previewDialogOpen: false,
    playerPreviews: undefined,
    excelUploadFile: undefined,
  }

  public render() {
    const { countryId, isClub, tournamentId } = this.props

    const gitTournamentId = Number(localStorage.getItem(storageTournamentId))

    return (
      <>
        <OverlayLoader visible={this.state.importLoading} />
        <Divider className={this.props.classes.lightDivider} />
        <>
          <SectionTitle>
            <FormattedMessageWrapper id="tournament.importPlayers" />
            <InfoTooltip
              text={<FormattedMessageWrapper id="tournament.importPlayersInfo" />}
              className={this.props.classes.infoTooltip}
            />
          </SectionTitle>

          <Grid container={true} spacing={2} className={this.props.classes.container}>
            <Grid item={true}>
              <BorderButton
                buttonProps={{
                  onClick: this._downloadTemplate,
                }}
              >
                <FormattedMessageWrapper id={'tournament.downloadExcelTemplate'} />
                <CloudUpload className={this.props.classes.icon} />
              </BorderButton>
            </Grid>
            <Grid item={true}>
              <FilePicker accept={ACCEPT_EXCEL_FILES} id={'import-excel-file'} onChange={this._handleImport}>
                <BorderButton
                  buttonProps={{
                    component: 'span',
                  }}
                >
                  <FormattedMessageWrapper id={'tournament.importExcelFile'} />
                  <CloudUpload className={this.props.classes.icon} />
                </BorderButton>
              </FilePicker>
            </Grid>
            {countryId === SV_COUNTRY_ID && isClub && (
              <Grid item={true}>
                <BorderButton
                  buttonProps={{
                    onClick: () => this._handleGitImportStatus(true),
                    disabled: !!gitTournamentId && !(tournamentId === gitTournamentId),
                  }}
                >
                  <FormattedMessageWrapper id="buttons.gitImportBtnText" />
                  <Cached className={this.props.classes.icon} />
                </BorderButton>
              </Grid>
            )}
          </Grid>
        </>

        <PlayerImportPreviewDialog
          open={this.state.previewDialogOpen}
          preview={this.state.playerPreviews}
          excelFile={this.state.excelUploadFile}
          onClose={() => {
            this.setState({ previewDialogOpen: false, playerPreviews: undefined })
          }}
        />

        <GitPlayerImportDialog onClose={() => this._handleGitImportStatus(false)} />
      </>
    )
  }

  public _handleImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { tournamentId = 1 } = this.props

    if (e.target.files) {
      const file = e.target.files.item(0)

      if (file) {
        this.setState({ importLoading: true, excelUploadFile: file }, () =>
          this.props.importPlayersExcelPreview({
            tournamentId,
            file,
            onComplete: this._handlePreviewImportDone,
          }),
        )
      }
    }
  }

  public _handlePreviewImportDone = (data: ImportPlayerPreviews) => {
    this.setState({
      importLoading: false,
      playerPreviews: data,
      previewDialogOpen: true,
    })
  }

  private _downloadTemplate = () => {
    const { authToken, tournamentId = 1 } = this.props
    try {
      const options: any = {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }

      fetch(`${API_ROOT}${APIRoute.GET_EXCEL_TEMPLATE(tournamentId)}`, options)
        .then((response) => response.blob())
        .then((blob) => {
          download(blob, 'tm_player_import_template.xlsx', getDownloadMimeType('xlsx'))
        })
    } catch (e) {
      this.props.enqueueNotification(e, 'error')
    }
  }

  private _handleGitImportStatus = (status: boolean) => {
    this.props.setGitDialogOpen(status)
  }
}

export default withStyles(styles)(
  connect<StateProps, DispatchProps, {}, RootState>(
    (store): StateProps => ({
      authToken: store.authenticationReducer.token,
      tournamentId: getTournamentId(store),
      countryId: store.organizationReducer.countryId,
      isClub: store.organizationReducer.isClub,
    }),
    {
      importPlayersExcelPreview: tournamentPlayersApi.endpoints.importPlayersPreview.initiate,
      enqueueNotification,
      setGitDialogOpen,
    },
  )(ExcelImportPlayers),
)
