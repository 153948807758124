import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { OverlayLoader } from '../../components/ui/OverlayLoader'
import TourFlowProgress from '../../components/tour/TourFlowProgress'
import TopBar from '../../components/layout/TopBar'
import { fetchTour, resetTour, fetchRankings } from '../../store/tourAndRanking/actions'
import { WithRouterProps, withRouter } from '@app/hoc/withRouter'
import { RootState } from '@app/store'

interface StateProps {
  organizationId?: number
  tourId?: number
  tourName?: string
  isLoading: boolean
}

interface DispatchProps {
  fetchTour: (organizationId: number, id: number, onComplete?: () => void) => void
  fetchRankings: (organizationId: number) => void
  resetTour: () => void
}

type Props = StateProps & DispatchProps & WithRouterProps

class UnroutedTourIndex extends React.Component<Props> {
  public componentDidMount(): void {
    const { params, organizationId } = this.props

    if (organizationId) {
      this.props.fetchRankings(organizationId)
    }

    const idParam = get(params, 'id')
    if (idParam && organizationId) {
      const tourId = parseInt(idParam, 10)
      this.props.fetchTour(organizationId, tourId)
    } else {
      this.props.resetTour()
    }
  }

  public componentWillUnmount(): void {
    this.props.resetTour()
  }

  public render() {
    const {
      location: { pathname },
      tourId,
      tourName,
      isLoading,
    } = this.props

    if (tourId && pathname.includes('new-tour')) {
      const newPath = pathname.replace('new-tour', tourId.toString())
      return <Navigate to={newPath} />
    }

    if (!tourId && pathname.includes('branding') && !isLoading) {
      const newPath = pathname.replace('branding', 'setup')
      return <Navigate to={newPath} />
    }

    return (
      <>
        {this.isLoading && <OverlayLoader />}
        <TopBar title={tourName} />
        <TourFlowProgress />
        <Outlet />
      </>
    )
  }

  private get isLoading() {
    return this.props.isLoading
  }
}

const TourIndex = connect<StateProps, DispatchProps, {}, RootState>(
  (store) => ({
    organizationId: get(store.authenticationReducer, 'customerInfo.idCustomer'),
    tourId: store.tourAndRankingReducer.tour.id,
    tourName: store.tourAndRankingReducer.tour.name,
    isLoading: store.tourAndRankingReducer.loading,
  }),
  {
    fetchTour,
    resetTour,
    fetchRankings,
  },
)(withRouter(UnroutedTourIndex))
export default TourIndex
